import React from 'react';
import { StyleSheet, ImageBackground } from 'react-native';
import { getTheme } from '../../../themes/new/theme';
import View from '../basic/View/View';
import LightThemeWrapper from '../../../screens/new/wrapperComp/LightThemeWrapper';

const bannerBgSrc = 'https://ik.imagekit.io/32ndhub/assets/bg_ontime_blur.png';

const OverviewBanner = ({ children }) => {
  return (
    <LightThemeWrapper>
      <View
        bg="primary.500"
        borderBottomLeftRadius={getTheme().radii.lg + getTheme().radii.sm}
        borderBottomRightRadius={getTheme().radii.lg + getTheme().radii.sm}
      >
        <ImageBackground source={{ uri: bannerBgSrc }} style={styles.bannerImg}>
          {children}
        </ImageBackground>
      </View>
    </LightThemeWrapper>
  );
};

const styles = StyleSheet.create({
  bannerImg: {
    overflow: 'hidden',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});

export default OverviewBanner;
