/* eslint-disable import/prefer-default-export */

import { useMemo } from 'react';

export const useNodeFromPagination = (data) => {
  /** using `useMemo` as `taskNodes` is mapped to new array on every render of this hook despite `findTasks` being same */
  const nodes = useMemo(() => data?.edges.map((edge) => edge.node), [data]);

  return nodes;
};
