import { useRefetchableFragment } from 'react-relay';
import { useCallback } from 'react';
import { transactionsRevenueReportFragment } from './transactionsRevenueReportFragment';
import { transactionsRevenueReportFragment$key } from '../transactionsRevenueReportFragment/__generated__/transactionsRevenueReportFragment.graphql';
import { buildTransactionsRevenueReportViewData } from '../../../utils';
import { network } from '../../../../../utilities/Constant';

const useTransactionsRevenueReport = (
  fragmentRef: transactionsRevenueReportFragment$key,
) => {
  const [data, refetch] = useRefetchableFragment(
    transactionsRevenueReportFragment,
    fragmentRef,
  );

  const revenueReports = buildTransactionsRevenueReportViewData(data.reports);

  const refetchInner = useCallback(
    (onCompleteCb, variables = {}) => {
      refetch(variables, {
        fetchPolicy: network.pullToRefreshFetchPolicy,
        onComplete: onCompleteCb,
      });
    },
    [refetch],
  );

  return {
    revenueReports,
    refetchReports: refetchInner,
  };
};

export default useTransactionsRevenueReport;
