import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import CashierTabBar from './cahierTabBar';
import CashierCarSearchScreen from '../screens/cashier/CashierCarSearchScreen';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import AccountScreenWrapper from '../screens/new/account/AccountScreen';
import TeamScreenWrapper from '../screens/Team/TeamScreen';
import CashierPaymentCollectionScreen from '../screens/cashier/CashierPaymentCollectionScreen';
import CashierHomeScreen from '../screens/cashier/CashierHomeScreen';
import PaymentScreen from '../screens/cashier/CashierPaymentScreen';
import CashierOverviewScreen from '../screens/cashier/CashierOverviewScreen';
import EditAmountScreen from '../screens/cashier/EditAmountScreen';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import TaskCompletionScreen from '../screens/TaskCompletionScreen';
import StaffScanReedemScreen from '../screens/user/staffScan&ReedemScreen';
import StaffBarcodeScannerScreen from '../screens/user/staffBarcodeScannerScreen';
import StaffAddManuallyBarcodeScreen from '../screens/user/staffAddManuallyBarcodeScreen';
import StaffRewardScreen from '../screens/user/staffRewardScreen';
import CashierReceiptScreenWrapper from '../screens/cashier/CashierReceiptScreen';

const { Navigator, Screen } = createBottomTabNavigator();
const Stack = createStackNavigator();

const CashierHomeTab = () => (
  <Navigator
    // @ts-expect-error TS(2769): No overload matches this call.
    keyboardHidesTabBar="true"
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <CashierTabBar {...props} />}
  >
    <Screen name="home" component={CashierHomeScreen} />
    <Screen name="cashierOverview" component={CashierOverviewScreen} />
    {/* <Screen name="cashierAccount" component={CashierAccountScreen} /> */}
    <Screen name="cashierAccount" component={AccountScreenWrapper} />
  </Navigator>
);

const CashierTab = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="cashier" component={CashierHomeTab} />
      <Stack.Screen name="CashierPaymentScreen" component={PaymentScreen} />
      <Stack.Screen name="EditAmountScreen" component={EditAmountScreen} />
      <Stack.Screen name="editProfile" component={EditProfileScreen} />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen
        name="TaskCompletionScreen"
        component={TaskCompletionScreen}
      />
      <Stack.Screen
        name="CashierPaymentCollectionScreen"
        component={CashierPaymentCollectionScreen}
      />
      <Stack.Screen
        name="StaffScanRedeemScreen"
        component={StaffScanReedemScreen}
      />
      <Stack.Screen
        name="StaffBarcodeScannerScreen"
        component={StaffBarcodeScannerScreen}
      />
      <Stack.Screen
        name="StaffAddManuallyBarcodeScreen"
        component={StaffAddManuallyBarcodeScreen}
      />
      <Stack.Screen name="StaffRewardScreen" component={StaffRewardScreen} />
      <Stack.Screen name="CarSearchScreen" component={CashierCarSearchScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen name="team" component={TeamScreenWrapper} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
      <Stack.Screen
        name="cashierReceipt"
        component={CashierReceiptScreenWrapper}
      />
    </Stack.Navigator>
  );
};

export default CashierTab;
