/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import { TouchableWithoutFeedback, Keyboard } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { graphql, commitMutation } from 'react-relay';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Button,
  IconButton,
  Input,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import { EmailTypes, navigateBack } from '../../utilities/helper';
import { InputStates } from '../../components/new/primitive/MobileInput/helpers';
import validateWrapper from '../../utilities/validationWrapper';
import environment from '../../relay/relayEnvironment';
import useUserStore from '../../stores/userStore';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

type Params = {
  editMode: boolean;
  type: EmailTypes;
  email: string | undefined;
};

const sendOtpRequest = graphql`
  mutation EmailEditorScreenMutation($record: OtpRecordInput!) {
    generateOTP(record: $record) {
      error {
        message
      }
    }
  }
`;

const EmailEditorScreen = ({ navigation, route }) => {
  const { bottom } = useSafeAreaInsets();
  const userRole = useUserStore((state) => state.role);
  const { params }: any = route;
  const { email, type, editMode }: Params = params;
  const [newEmail, setNewEmail] = useState(email);
  const [emailApiError, setEmailApiError] = useState('');
  const [emailValidated, setEmailValidated] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const title = editMode
    ? type === EmailTypes.PERSONAL
      ? 'Change Primary Email'
      : 'Change Work Email'
    : type === EmailTypes.PERSONAL
    ? 'Add Primary Email'
    : 'Add Work Email';
  const subTitle =
    type === EmailTypes.PERSONAL
      ? 'Enter your updated primary email ID'
      : 'Enter your updated work email ID';

  const validatedEmail = (value: string) => {
    const result = validateWrapper('email', value);
    setEmailValidated(!result);
  };

  const handleEmailChange = (value: string) => {
    const lcEmail = value.toLowerCase();
    validatedEmail(lcEmail);
    setNewEmail(lcEmail);
  };

  const handleSubmit = () => {
    const variables = {
      record: {
        contact: {
          email: {
            addr: newEmail,
          },
        },
      },
    };

    setSubmitEnabled(false);

    commitMutation(environment, {
      mutation: sendOtpRequest,
      variables,
      onCompleted: () => {
        if (userRole === 'user') {
          firebaseEventLogger('editProfile__sendEmailOtp_Tap', {
            buttonName: 'sendEmailOtp',
            screenName: 'editProfile',
            userType: 'user',
            interactionType: 'tap',
            emailType: type,
          });
        }

        navigation.navigate('verifyEmailOtp', {
          email: newEmail,
          type,
        });
      },
      onError: (err) => {
        console.error(err);
        setSubmitEnabled(true);
        setEmailApiError(err);
      },
    });
  };

  /** using focus effect in case user comes back to this screen from OTP screen to again change email */
  useFocusEffect(
    useCallback(() => {
      if (emailValidated && newEmail !== email) setSubmitEnabled(true);
      else setSubmitEnabled(false);
    }, [emailValidated, newEmail, email]),
  );

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        textSize="xl"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
            }}
          />
        }
      />
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View flex={1} px="2xl">
          <View mt="4xl">
            <Text mb="sm" size="3xl" color="primary.200">
              {title}
            </Text>
            <Text size="sm" color="primary.200">
              {subTitle}
            </Text>
          </View>
          <View mt="42">
            <Input
              state={emailApiError ? InputStates.error : InputStates.default}
              inputMode="text"
              autoFocus
              autoCapitalize="none"
              label={
                type === EmailTypes.PERSONAL
                  ? 'Primary Email ID'
                  : 'Work Email ID'
              }
              returnKeyType="next"
              rightIconName="add-400"
              rightIconColor="success.500"
              placeholder="e.g robin.singh@example.com"
              value={newEmail}
              helperText={emailApiError}
              onChangeText={handleEmailChange}
              borderColor="primary.50"
              color="primary.100"
              style={{ borderColor: 'white' }}
            />
          </View>
          <View pb={bottom} flex={1} justifyContent="flex-end" bottom="2xl">
            <Button
              size="lg"
              appearance="filled"
              status="primary"
              state={submitEnabled ? 'active' : 'disabled'}
              onPress={handleSubmit}
            >
              Continue
            </Button>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Layout>
  );
};

export default EmailEditorScreen;
