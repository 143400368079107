import React, { memo, useRef } from 'react';
import { useWindowDimensions } from 'react-native';
import { View, Text, Button } from '../../../components/new';
import { State } from '../../../themes/new/helper';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';

const areEqual = (prevProps, newProps) => {
  if (prevProps?.recalling !== newProps?.recalling) return false;
  return true;
};

const ConfirmRecallBox = ({ onSubmit, onCancel, recalling = false }) => {
  const { width } = useWindowDimensions();
  const animation = useRef(null);
  return (
    <View>
      <View alignSelf="center">
        <CustomLottieView
          autoPlay
          ref={animation}
          loop
          source={require('../../../../assets/lottie/car_recall.json')}
          style={{ width: 200, height: 200, alignSelf: 'center' }}
          containerWidth={200}
        />
      </View>
      <Text
        color="primary.400"
        size="xl"
        mx="2xl"
        weight="medium"
        textAlign="center"
      >
        Are you sure you want to recall your car from the parking?
      </Text>
      <View
        flexDirection="row"
        justifyContent="space-between"
        px="2xl"
        my="7xl"
      >
        <Button
          style={{
            width: (width - 3 * 16) / 2,
            marginRight: 16,
          }}
          state={recalling ? State.DISABLED : State.ACTIVE}
          status="primary"
          appearance="outline"
          size="lg"
          onPress={onCancel}
        >
          Cancel
        </Button>
        <Button
          style={{ width: (width - 3 * 16) / 2 }}
          state={recalling ? State.DISABLED : State.ACTIVE}
          status="primary"
          appearance="filled"
          size="lg"
          loading={recalling}
          onPress={onSubmit}
        >
          Yes, Recall Now
        </Button>
      </View>
    </View>
  );
};

export default memo(ConfirmRecallBox, areEqual);
