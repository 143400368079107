/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, View, Text } from '../../components/new';
import {
  DateFormatsEnum,
  DeliveryStatus,
  PaymentGatewayType,
  getFormatedDate,
  maskedNumber,
  paymentOrderTypeEnum,
  PaymentStatusEnum,
} from '../../utilities/helper';
import TaskCard from '../../components/new/primitive/TaskCard/TaskCard';
import usePartnerTransactionStore from '../../stores/partner/partnerTransactionStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import useUserStore, { UserRole } from '../../stores/userStore';
import CancelTransactionModel from '../common/CancelTransactionModel';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useAuthStore from '../../stores/authStore';
import { updatePopupOrderAPI } from '../API/updatePopupOrder';
import { Appearance, Status } from '../../themes/new/helper';

const RenderItem = ({
  item,
  // valetWorkLog,
  refresh,
}) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const navigation = useNavigation<any>();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [modalVisible, setModalVisible] = useState(false);
  const { openedCard, setOpenedCard } = usePartnerTransactionStore(
    (state) => state,
  );

  const userId = useUserStore((state) => state.id);
  const userRole = useUserStore((state) => state.role);
  const prefix = item?.user?.contact?.phone?.prefix ?? {};
  const number = item?.user?.contact?.phone?.number ?? {};
  const isPaid = item?.status === PaymentStatusEnum.SUCCESSFUL;
  const heading =
    isPaid && item?.paymentType === PaymentGatewayType.PAYTMQR
      ? `#${item?.invoice[0]?.paymentDetails?.paytmQr?.transactionId.slice(-4)}`
      : item?.user
      ? `${prefix} ${maskedNumber(number)}`
      : `#${item?._id?.slice(0, 6)}`;
  const createdAt = new Date(item?.created_at);
  const deliveryStatus = item?.popUpStoreDetails?.deliveryStatus;

  const subHeading = `${
    item?.paymentType === PaymentGatewayType.PAYTMQR
      ? 'QR'
      : userRole === UserRole.MOMENT_STAFF_PARTNER && !isPaid
      ? `${getFormatedDate(createdAt, DateFormatsEnum.TIME)}`
      : `${item?.paymentType} | ${getFormatedDate(
          createdAt,
          DateFormatsEnum.TIME,
        )}`
  }`;

  const headerRightComponent = () => (
    <View flexDirection="row" alignItems="center">
      <View
        width="3xs"
        height="3xs"
        bg={isPaid ? 'success.500' : 'error.500'}
        borderRadius="full"
      />
      <Text size="xs" color={isPaid ? 'success.500' : 'error.500'} ml="sm">
        {isPaid ? 'Paid' : 'Unpaid'}
      </Text>
    </View>
  );

  const topRightCornerComponent = () => (
    <View
      borderRadius="sm"
      borderWidth="xs"
      borderColor={isPaid ? 'success.10' : 'error.10'}
      p="sm"
      bg={isPaid ? 'success.10' : 'error.10'}
    >
      <Text size="sm" color="primary.500">
        ₹{item?.amount}
      </Text>
    </View>
  );

  const onPressDelivered = () => {
    firebaseEventLogger('partnerHomescreen__delivered_Screen', {
      buttonName: 'delivered',
      screenName: 'partnerHomescreen',
      userType: UserRole.PARTNER,
      interactionType: InteractionType.TAP,
      isLoggedIn,
      partnerId: userId,
      paymentId: item?._id,
      paymentType: item?.paymentType,
      paymentStatus: item?.status,
      orderType: paymentOrderTypeEnum.POPUPSTORE,
      orderAmount: item?.amount,
      deliveryStatus,
    });
    const data = {
      _id: item?._id,
      record: {
        popUpStoreDetails: {
          deliveryStatus: DeliveryStatus.DELIVERED,
        },
      },
    };
    const handleUpdatePopUpInvoice = (response, error) => {
      if (response) {
        dispatchSnackbar({
          msg: 'Item delivered and moved to collections!',
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
        refresh();
      }
      if (error && error.length) {
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
        refresh();
      }
    };

    const updateApiErrorHandler = (error) => {
      dispatchSnackbar({
        msg: 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
        removeAfter: 4000,
      });
    };
    updatePopupOrderAPI(data, handleUpdatePopUpInvoice, updateApiErrorHandler);
  };
  const bottomLeftCTAComponent = () => {
    return userRole !== UserRole.MOMENT_STAFF_PARTNER ? (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        onPress={() => {
          if (!isPaid) {
            setModalVisible(true);
          }
          if (isPaid && deliveryStatus === DeliveryStatus.UNDELIVERED) {
            onPressDelivered();
          }
        }}
        shadow="sm"
      >
        {isPaid && deliveryStatus === DeliveryStatus.UNDELIVERED
          ? 'Mark As Delivered'
          : 'Cancel'}
      </Button>
    ) : !isPaid ? (
      <Button
        size="md"
        appearance={Appearance.OUTLINE}
        status={Status.PRIMARY}
        onPress={() => {
          if (!isPaid) {
            setModalVisible(true);
          }
        }}
        shadow="sm"
      >
        Cancel
      </Button>
    ) : (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        onPress={() => {
          if (isPaid && deliveryStatus === DeliveryStatus.UNDELIVERED) {
            onPressDelivered();
          }
        }}
        shadow="sm"
      >
        Mark As Delivered
      </Button>
    );
  };

  const bottomRightCTAComponent = () => {
    return userRole === UserRole.MOMENT_STAFF_PARTNER ? (
      !isPaid ? (
        <Button
          size="md"
          appearance={Appearance.FILLED}
          status={Status.PRIMARY}
          onPress={() => {
            if (!isPaid) {
              navigation.navigate('PartnerPaymentScreen', {
                amount: item?.amount,
                orderId: item?._id,
                assetId: item?.itemDetails,
              });
            }
          }}
          shadow="sm"
        >
          Collect Fee
        </Button>
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  return (
    <>
      <View px="2xl" my="lg">
        <TaskCard
          heading={heading}
          subHeading={subHeading}
          headingRight={headerRightComponent()}
          topRightCorner={topRightCornerComponent()}
          bottomLeftCTA={bottomLeftCTAComponent()}
          bottomRightCTA={bottomRightCTAComponent()}
          swipeEnabled={false}
          shadow="md"
          blur={item?.blur}
          preExpanded={openedCard === item?._id}
          onCardPressCB={() => {
            if (openedCard !== item?._id && !isPaid && !isPaid?.length) {
              setOpenedCard({ id: item?._id });
            } else {
              setOpenedCard({ id: '' });
            }
          }}
        />
      </View>
      <CancelTransactionModel
        item={item}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        refresh={refresh}
      />
    </>
  );
};

export default RenderItem;
