import React, { Suspense, useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { Layout, TopNavigation, IconButton } from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import TrackScreenInner from './TrackScreenInner';
import { Loading } from '../../../components';
import { navigateBack } from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';

const TrackDiscountScreen = ({ navigation, route }) => {
  const { role } = useUserStore((state) => state);
  const [netStatus, setNetStatus] = useState(true);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  return (
    <Layout level={1}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Track Discounts"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <TrackScreenInner
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
        bottomNavigator
      />
    </Layout>
  );
};

export default TrackDiscountScreen;
