/**
 * @generated SignedSource<<843f93b8433c933fb33f007214f13eb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type EnumvaletWorkLogWorkLogHistoryType = "break_end" | "break_start" | "punch_in" | "punch_out" | "%future added value";
export type SortFindManytaskInput = "CAMPUS___ID_ASC" | "CAMPUS___ID_DESC" | "CAMPUS___ID__ASSIGNEDTO_ASC" | "CAMPUS___ID__ASSIGNEDTO_DESC" | "CAMPUS___ID__ASSIGNEDTO__HISTORY__ACTION_ASC" | "CAMPUS___ID__ASSIGNEDTO__HISTORY__ACTION_DESC" | "CAMPUS___ID__ASSIGNEDTO__HISTORY__ACTION__CREATED_AT_ASC" | "CAMPUS___ID__ASSIGNEDTO__HISTORY__ACTION__CREATED_AT_DESC" | "CREATED_AT_DESC" | "DELAY_AT_ASC" | "DELAY_AT_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type findValetWorkLogFilterInput = {
  date?: any | null;
  valetID: any;
};
export type taskScreenQuery$variables = {
  logFilter: findValetWorkLogFilterInput;
  sort?: SortFindManytaskInput | null;
  taskFilter: findTaskFilterInput;
};
export type taskScreenQuery$data = {
  readonly findValetWorkLog: {
    readonly _id: any;
    readonly date: any;
    readonly workLogHistory: ReadonlyArray<{
      readonly time: any;
      readonly type: EnumvaletWorkLogWorkLogHistoryType | null;
    } | null> | null;
    readonly workSummary: {
      readonly tasksDone: number | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"taskScreenQueryFindTaskFragment">;
};
export type taskScreenQuery = {
  response: taskScreenQuery$data;
  variables: taskScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "logFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskFilter"
},
v3 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "taskFilter"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filter",
      "variableName": "logFilter"
    }
  ],
  "concreteType": "valetWorkLog",
  "kind": "LinkedField",
  "name": "findValetWorkLog",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "valetWorkLogWorkLogHistory",
      "kind": "LinkedField",
      "name": "workLogHistory",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "valetWorkLogWorkSummary",
      "kind": "LinkedField",
      "name": "workSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tasksDone",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "UserName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "UserContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserContactPhone",
      "kind": "LinkedField",
      "name": "phone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "taskScreenQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "taskScreenQueryFindTaskFragment"
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "taskScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "findTasks",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleBrand",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandLogo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskStartLocation",
            "kind": "LinkedField",
            "name": "taskStartLocation",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskStartLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                        "kind": "LinkedField",
                        "name": "bay",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskEndLocation",
            "kind": "LinkedField",
            "name": "taskEndLocation",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskEndLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                        "kind": "LinkedField",
                        "name": "bay",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "campusLocation",
            "kind": "LinkedField",
            "name": "campus",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskHistory",
            "kind": "LinkedField",
            "name": "history",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "event_at",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignedTo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "valet",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identityCode",
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskETA",
            "kind": "LinkedField",
            "name": "ETA",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "delayAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "originalPredictionTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pendingRecall",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "available",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "busy",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vehicleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingTag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "createdBy",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "8575ea4413f5f10a23124d8de35ade75",
    "id": null,
    "metadata": {},
    "name": "taskScreenQuery",
    "operationKind": "query",
    "text": "query taskScreenQuery(\n  $taskFilter: findTaskFilterInput!\n  $sort: SortFindManytaskInput\n  $logFilter: findValetWorkLogFilterInput!\n) {\n  ...taskScreenQueryFindTaskFragment_4gftZO\n  findValetWorkLog(filter: $logFilter) {\n    _id\n    date\n    workLogHistory {\n      time\n      type\n    }\n    workSummary {\n      tasksDone\n    }\n  }\n}\n\nfragment taskScreenQueryFindTaskFragment_4gftZO on Query {\n  findTasks(filter: $taskFilter, sort: $sort) {\n    _id\n    vehicle {\n      _id\n      brand {\n        name\n        brandLogo\n      }\n      registration {\n        plate\n      }\n      color\n    }\n    taskType\n    taskStartLocation {\n      locationId\n      name\n      parkingSpaces {\n        floor {\n          floorName\n          floorId\n          bay {\n            bayId\n            bayName\n          }\n        }\n      }\n    }\n    taskEndLocation {\n      locationId\n      name\n      parkingSpaces {\n        floor {\n          floorName\n          floorId\n          bay {\n            bayId\n            bayName\n          }\n        }\n      }\n    }\n    campus {\n      _id\n      name\n    }\n    history {\n      _id\n      action\n      actor\n      event_at\n    }\n    assignedTo\n    valet {\n      _id\n      name {\n        first\n        last\n      }\n      identityCode\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n    user {\n      name {\n        first\n        last\n      }\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n    ETA {\n      _id\n      startAt\n      delayAt\n      originalPredictionTime\n      pendingRecall\n      available\n      busy\n    }\n    vehicleId\n    ownerId\n    parkingId\n    parkingTag\n    createdBy {\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "043464211fe42591fc219199ec8baf60";

export default node;
