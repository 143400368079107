import React from 'react';
import { Image } from 'react-native';
import { Text, View } from '../../../../../components/new';
import DynamicAppQr from './DynamicAppQr';
import { getTheme, themeModes } from '../../../../../themes/new/theme';
import { kiosk } from '../../../../../utilities/Constant';
import { FontWeights } from '../../../../../themes/new/helper';

const AppleStoreBtnImg = require('../../../../../../assets/images/appstore-btn.png');
const PlayStoreBtnImg = require('../../../../../../assets/images/playstore-btn.png');

const darkTheme = getTheme(themeModes.dark);
const spacing = getTheme().space;
const { APP_QR_LINK } = kiosk;

const MemberInfo = ({ withStoreBtn = false, label }) => {
  return (
    <View>
      {!withStoreBtn && (
        <View
          zIndex={2}
          top={4}
          height={50}
          overflow="hidden"
          alignItems="flex-end"
        >
          <View position="absolute" top={20} right="18%">
            <TriangleWithBorder />
          </View>
        </View>
      )}
      <View
        p="xl"
        bg={darkTheme.colors.opacity[90]}
        borderWidth="md"
        borderRadius="xl"
        borderColor="gold.50"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <DynamicAppQr maxWidth={132} borderPadding="lg" url={APP_QR_LINK} />
        <View flex={1} ml="2xl">
          <View flexDirection="row">
            <Text
              textAlign="center"
              size="4xl"
              weight={FontWeights.MEDIUM}
              color="singletone.white"
            >
              {label}
            </Text>
          </View>
          {withStoreBtn && (
            <View mt="7xl">
              <View flexDirection="row" justifyContent="center">
                <Image
                  source={AppleStoreBtnImg}
                  style={{
                    width: 135,
                    height: 40,
                    marginRight: getTheme().space['sm+md'],
                  }}
                />
                <Image
                  source={PlayStoreBtnImg}
                  style={{
                    width: 135,
                    height: 40,
                    marginLeft: getTheme().space['sm+md'],
                  }}
                />
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default MemberInfo;

const TriangleWithBorder = () => {
  return (
    <View>
      <View
        style={[
          {
            height: 80,
            width: 80,
            backgroundColor: '#2b2c2c',
            borderColor: darkTheme.colors.gold[500],
            borderTopWidth: spacing.md,
            borderLeftWidth: spacing.md,
            alignItems: 'center',
            justifyContent: 'center',
          },
          {
            transform: [{ rotateY: '50deg' }, { rotateZ: '45deg' }],
          },
        ]}
      />
    </View>
  );
};
