/**
 * @generated SignedSource<<b225d5210a06d4bb26a35ab4255b2286>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Referer = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type getPaymentInfoQuery$variables = {
  amount?: number | null;
  orderId: string;
  orderType: Referer;
  useRewardPoints: boolean;
};
export type getPaymentInfoQuery$data = {
  readonly getNetPrice: {
    readonly cashbackAmount: number | null;
    readonly cashbackPercentage: number | null;
    readonly discountPercentage: number | null;
    readonly priceDetails: {
      readonly discountGiven: number;
      readonly grossAmount: number;
      readonly netAmount: number;
      readonly rewardsUsed: number;
    };
  } | null;
};
export type getPaymentInfoQuery = {
  response: getPaymentInfoQuery$data;
  variables: getPaymentInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useRewardPoints"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      },
      {
        "kind": "Variable",
        "name": "orderType",
        "variableName": "orderType"
      },
      {
        "kind": "Variable",
        "name": "useRewardPoints",
        "variableName": "useRewardPoints"
      }
    ],
    "concreteType": "invoiceModelPriceExtendedTC",
    "kind": "LinkedField",
    "name": "getNetPrice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "invoicePriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountGiven",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardsUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountPercentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cashbackPercentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cashbackAmount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getPaymentInfoQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "getPaymentInfoQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "c03c3cbd832963da22887c16ea083bf6",
    "id": null,
    "metadata": {},
    "name": "getPaymentInfoQuery",
    "operationKind": "query",
    "text": "query getPaymentInfoQuery(\n  $amount: Float\n  $useRewardPoints: Boolean!\n  $orderId: ID!\n  $orderType: Referer!\n) {\n  getNetPrice(amount: $amount, useRewardPoints: $useRewardPoints, orderId: $orderId, orderType: $orderType) {\n    priceDetails {\n      grossAmount\n      discountGiven\n      rewardsUsed\n      netAmount\n    }\n    discountPercentage\n    cashbackPercentage\n    cashbackAmount\n  }\n}\n"
  }
};
})();

(node as any).hash = "1638a0234fd8cf8d2aa2d193480c29b9";

export default node;
