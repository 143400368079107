import React, { memo } from 'react';
import { ImageBackground, useWindowDimensions } from 'react-native';
import {
  Button,
  Icon,
  Pressable,
  Text,
  View,
} from '../../../../components/new';
import { dynamicHeightMaker } from '../../../../utilities/helper';
import { colors, radii } from '../../../../themes/new/theme';
import { Appearance, Status } from '../../../../themes/new/helper';

const areEqual = () => {
  return true;
};

const InvestmentAssociates = ({ bgColor = '', openLegacy = () => {} }) => {
  const { width } = useWindowDimensions();
  return (
    <View py="9xl+9xl" px="2xl" bg={bgColor}>
      <Pressable onPress={openLegacy}>
        <ImageBackground
          source={require('../../../../../assets/images/associatesBg.webp')}
          style={{
            height: dynamicHeightMaker(width - 32, 1.38),
            borderRadius: radii['2xl'],
            backgroundColor:
              bgColor === 'background.primary.base' ? '#1c1c1e' : '#121212',
            overflow: 'hidden',
          }}
        >
          <Text
            size="xs"
            color="primary.300"
            pb="lg"
            textAlign="center"
            pt="4xl"
          >
            OUR ASSOCIATIONS
          </Text>
          <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
            Invest in Over 25+ Years of Established Excellence
          </Text>
          <Button
            appearance={Appearance.GHOST}
            status={Status.INFO}
            size="md"
            mt="lg"
            onPress={openLegacy}
          >
            <View flexDirection="row" alignItems="center">
              <Text color="info.500" size="md" mr="sm" mt={-2}>
                View Our Legacy
              </Text>
              <Icon name="forward-outline-300" color="info.500" size="xs" />
            </View>
          </Button>
        </ImageBackground>
      </Pressable>
    </View>
  );
};

export default memo(InvestmentAssociates, areEqual);
