/* eslint-disable import/prefer-default-export */
import { VehicleStatusTagEnum } from '../../../../components/new/ui/VehicleStatusTags/helpers/helpers';

const groupOrder = {
  [VehicleStatusTagEnum.recalled]: 0,
  [VehicleStatusTagEnum.parking]: 1,
  [VehicleStatusTagEnum.parked]: 2,
  [VehicleStatusTagEnum.out]: 3,
};

export const giveTags = (data) => {
  const newData = JSON.parse(JSON.stringify(data));
  newData.forEach((item) => {
    const lastTask = item.tasks.slice(-1)[0];
    const firstHistoryItem = lastTask?.history[0];
    const lastHistoryItem = lastTask?.history.slice(-1)[0];
    const taskHistoryCreateAtFE = firstHistoryItem?.event_at;

    const taskType = lastTask?.taskType;
    const lastAction = lastHistoryItem?.action;

    const delayedAt = lastTask?.ETA?.delayAt;
    const arrivedAt = lastTask?.history.slice(-2)[0].event_at;

    let isLate;
    if (arrivedAt > delayedAt) {
      isLate = true;
    } else {
      isLate = false;
    }

    let taskTag = '';
    let withTerminalStatusFe = false;
    if (
      lastAction === 'abort' ||
      lastAction === 'cancelled' ||
      lastAction === 'incomplete'
    ) {
      withTerminalStatusFe = true;
    } else if (taskType === 'recall' && lastAction === 'completed') {
      taskTag = VehicleStatusTagEnum.out;
    } else if (
      taskType === 'recall' &&
      lastAction !== 'completed' &&
      lastAction !== 'exited'
    ) {
      taskTag = VehicleStatusTagEnum.recalled;
    } else if (taskType === 'recall' && lastAction === 'exited') {
      taskTag = VehicleStatusTagEnum.out;
    } else if (taskType === 'park' && lastAction === 'completed') {
      taskTag = VehicleStatusTagEnum.parked;
    } else if (taskType === 'park' && lastAction !== 'completed') {
      taskTag = VehicleStatusTagEnum.parking;
    } else if (taskType === 'repark' && lastAction === 'completed') {
      taskTag = VehicleStatusTagEnum.parked;
    } else if (taskType === 'repark' && lastAction !== 'completed') {
      taskTag = VehicleStatusTagEnum.reParking;
    }

    item.isLateFE = isLate;
    item.taskTagFE = taskTag;
    item.taskTypeFE = taskType;
    item.taskHistoryCreateAtFE = taskHistoryCreateAtFE;
    item.withTerminalStatusFe = withTerminalStatusFe;

    return item;
  });

  return newData;
};

export const giveTagsToTasks = (data) => {
  const newData = JSON.parse(JSON.stringify(data));
  newData.forEach((item) => {
    const lastTask = item;
    const firstHistoryItem = lastTask?.history[0];
    const lastHistoryItem = lastTask?.history.slice(-1)[0];
    const taskHistoryCreateAtFE = firstHistoryItem?.event_at;

    const taskType = lastTask?.taskType;
    const lastAction = lastHistoryItem?.action;

    const delayedAt = lastTask?.ETA?.delayAt;
    const arrivedAt = lastTask?.history.slice(-2)[0].event_at;

    let isLate;
    if (arrivedAt > delayedAt) {
      isLate = true;
    } else {
      isLate = false;
    }

    let taskTag = '';
    let withTerminalStatusFe = false;
    if (
      lastAction === 'abort' ||
      lastAction === 'cancelled' ||
      lastAction === 'incomplete'
    ) {
      withTerminalStatusFe = true;
    } else if (taskType === 'recall' && lastAction === 'completed') {
      taskTag = VehicleStatusTagEnum.out;
      withTerminalStatusFe = true;
    } else if (
      taskType === 'recall' &&
      lastAction !== 'completed' &&
      lastAction !== 'exited'
    ) {
      taskTag = VehicleStatusTagEnum.recalled;
    } else if (taskType === 'recall' && lastAction === 'exited') {
      taskTag = VehicleStatusTagEnum.out;
    } else if (taskType === 'park' && lastAction === 'completed') {
      taskTag = VehicleStatusTagEnum.parked;
      withTerminalStatusFe = true;
    } else if (taskType === 'park' && lastAction !== 'completed') {
      taskTag = VehicleStatusTagEnum.parking;
    } else if (taskType === 'repark' && lastAction === 'completed') {
      taskTag = VehicleStatusTagEnum.parked;
      withTerminalStatusFe = true;
    } else if (taskType === 'repark' && lastAction !== 'completed') {
      taskTag = VehicleStatusTagEnum.reParking;
    }

    item.isLateFE = isLate;
    item.taskTagFE = taskTag;
    item.taskTypeFE = taskType;
    item.taskHistoryCreateAtFE = taskHistoryCreateAtFE;
    item.withTerminalStatusFe = withTerminalStatusFe;

    return item;
  });

  return newData;
};

const sortByDate = (data) => {
  data.sort((prev, curr) => {
    return (
      new Date(curr.taskHistoryCreateAtFE) -
      new Date(prev.taskHistoryCreateAtFE)
    );
  });
  return data;
};

const sortByGroup = (data, order) => {
  data.sort((prev, curr) => {
    return order[prev.taskTagFE] - order[curr.taskTagFE];
  });
  return data;
};

const showParkingTaskIfRecalledTaskIsCancelled = (data) => {
  const newData = JSON.parse(JSON.stringify(data));
  const n = newData.filter((item) => {
    const lastTask = item.tasks.slice(-1)[0];
    const lastHistoryItem = lastTask?.history.slice(-1)[0];
    const lastAction = lastHistoryItem?.action;
    const taskType = lastTask?.taskType;
    // remove parking from list if
    // last tasktype's last history action is terminal status(except completed) and
    // last Tasktype is park or repark
    if (
      (taskType === 'park' || taskType === 'repark') &&
      (lastAction === 'abort' ||
        lastAction === 'cancelled' ||
        lastAction === 'incomplete')
    ) {
      return false;
    }
    // edit parking tasks if
    // last tasktype's last history action is terminal status
    // last Tasktype is recall
    if (
      taskType === 'recall' &&
      (lastAction === 'abort' ||
        lastAction === 'cancelled' ||
        lastAction === 'incomplete')
    ) {
      // remove all tasks if last history action with terminal status because
      // here we only need its parking task to show which has index 0
      // we do not want user to see recall task , if recall task has been canceled show parking task
      const tempTasks = item?.tasks;
      const newTasks = [tempTasks[0]];
      // tempTasks.forEach((innerItem) => {
      //   const lastHistory = innerItem?.history.slice(-1)[0];
      //   if (lastHistory.action !== 'cancelled') {
      //     newTasks.push(innerItem);
      //   }
      // });
      item.tasks = newTasks;
    }
    return item;
  });
  return n;
};

export const sortTrackingCards = (data) => {
  const tempData = showParkingTaskIfRecalledTaskIsCancelled(data);
  const newData = giveTags(tempData);
  sortByDate(newData);
  sortByGroup(newData, groupOrder);
  return newData;
};

export const findRewardItemFromParkingDataItem = (item) => {
  return item.rewardData.find((rewardItem) => {
    if (
      rewardItem.rewardOrigin === 'trackScreen' ||
      rewardItem.rewardOrigin === 'trackCard'
    ) {
      return true;
    }
    return false;
  });
};
