import React from 'react';
import { ImageBackground, useWindowDimensions } from 'react-native';
import {
  Pressable,
  View,
  Text,
  IconButton,
  Button,
} from '../../../components/new';
import { Appearance, State, Status } from '../../../themes/new/helper';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import useUserStore, { UserRole } from '../../../stores/userStore';
import { dynamicHeightMaker } from '../../../utilities/helper';

interface DiscountCardProps {
  allUserDiscount: number | null;
  newUserDiscount: number | null;
  corporateDiscount: number | null;
  subTitle: string;
  subTitleColor?: string;
  onPress: () => void;
  widthProp?: number;
  aspectRatio?: number;
}

const DiscountCard = ({
  allUserDiscount = null,
  newUserDiscount = null,
  corporateDiscount = null,
  subTitle = '',
  subTitleColor = 'primary.10',
  onPress = () => {},
  widthProp = 0,
  aspectRatio = 1,
}: DiscountCardProps) => {
  const { width } = useWindowDimensions();

  const { role } = useUserStore((state) => state);

  return (
    <Pressable
      onPress={onPress}
      disabled={role !== UserRole.RESTAURANT_PARTNER}
    >
      <View
        bg="background.primary.elevation"
        borderRadius="md"
        // borderWidth="xs"
        // borderColor="transparent"
        shadow="md"
        width={widthProp || width}
        height={dynamicHeightMaker(widthProp || width, aspectRatio)}
      >
        {isValueNullOrEmpty(allUserDiscount) &&
        isValueNullOrEmpty(newUserDiscount) &&
        isValueNullOrEmpty(corporateDiscount) ? (
          <View alignItems="center" justifyContent="center">
            <View p="2xl">
              <IconButton
                size="lg"
                name="add-outline-300"
                state={State.ACTIVE}
                appearance={Appearance.GHOST}
                iconColor="info.500"
                onPress={onPress}
              />
            </View>
            <Button
              size="xs"
              status={Status.INFO}
              state={State.ACTIVE}
              appearance={Appearance.GHOST}
              onPress={onPress}
            >
              Create Discount
            </Button>
          </View>
        ) : (
          <View alignItems="center" justifyContent="center" p="2xl">
            <View>
              <Text
                size="lg"
                weight="medium"
                color={
                  isValueNullOrEmpty(allUserDiscount) || allUserDiscount === 0
                    ? 'primary.300'
                    : 'success.500'
                }
                textAlign="center"
              >
                {allUserDiscount && allUserDiscount > 0
                  ? allUserDiscount
                  : `+${allUserDiscount}`}
                %
              </Text>
              <Text size="xs" color="primary.100" mt="xs" textAlign="center">
                All Members
              </Text>
            </View>
            <View
              flexDirection="row"
              alignItems="center"
              mt="xl"
              justifyContent="space-between"
            >
              <View>
                <Text
                  size="sm"
                  color={
                    isValueNullOrEmpty(newUserDiscount) || newUserDiscount === 0
                      ? 'primary.300'
                      : 'success.500'
                  }
                  textAlign="center"
                >
                  {newUserDiscount && newUserDiscount > 0
                    ? newUserDiscount
                    : `+${newUserDiscount}`}
                  %
                </Text>
                <Text size="2xs" color="primary.100" mt="xs" textAlign="center">
                  New Members
                </Text>
              </View>
              <View ml="lg">
                <Text
                  size="sm"
                  color={
                    isValueNullOrEmpty(corporateDiscount) ||
                    corporateDiscount === 0
                      ? 'primary.300'
                      : 'success.500'
                  }
                  textAlign="center"
                >
                  {corporateDiscount && corporateDiscount > 0
                    ? corporateDiscount
                    : `+${corporateDiscount}`}
                  %
                </Text>
                <Text size="2xs" color="primary.100" mt="xs" textAlign="center">
                  Corporates
                </Text>
              </View>
            </View>
          </View>
        )}
        <View position="absolute" bottom={0} width="100%" overflow="hidden">
          <ImageBackground
            source={require('../../../../assets/images/gradient.png')}
            style={{
              width: '100%',
              height: 34,
            }}
            resizeMode="stretch"
          >
            <Text size="xs" mt="lg" color={subTitleColor} textAlign="center">
              {subTitle}
            </Text>
          </ImageBackground>
        </View>
      </View>
    </Pressable>
  );
};

export default DiscountCard;
