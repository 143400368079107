import { INVESTMENTKEYS } from './helper';

export const modifyStructuredData = (val, showApplication: boolean) => {
  const response = val?.findInvestmentProperty;
  return [
    {
      heading: '',
      subHeading: '',
      component: INVESTMENTKEYS.APPLICATION,
      isActive: !showApplication,
      propertyData: response,
      isFractional: true,
    },
    {
      footerTitle: response?.intro?.heading,
      footerSubTitle: response?.intro?.subHeading,
      component: INVESTMENTKEYS.INTRO,
      isActive: !!response?.intro?.media?.mediaURL,
      data: response?.intro?.media,
      aspectRatio: 1.57,
    },
    {
      heading: response?.investment?.heading,
      subHeading: response?.investment?.subHeading,
      component: INVESTMENTKEYS.INVESTMENT,
      isActive: !!response?.investment?.data?.investment,
      data: response?.investment?.data,
      isFractional: true,
    },
    {
      heading: response?.subscriptionDetails?.heading,
      subHeading: response?.subscriptionDetails?.subHeading,
      component: INVESTMENTKEYS.SUBSCRIPTIONV2,
      isActive: response?.subscriptionDetails?.values?.length > 0,
      data: response?.investment?.data,
      subscriptionDetails: response?.subscriptionDetails,
      isFractional: true,
    },
    {
      heading: 'DIVERSIFY, EARN & CREATE WEALTH',
      subHeading: 'Generate guaranteed Income',
      component: INVESTMENTKEYS.COMPAREINVESTMENTS,
      isActive: true,
      isFractional: true,
    },
    {
      heading: response?.subscriptionDetails?.heading,
      subHeading: `Limited to ${response?.investment?.data?.investment?.units?.totalUnits} elite investors`,
      component: INVESTMENTKEYS.SUBSCRIPTION,
      isActive: !!response?.investment?.data?.investment?.units,
      data: response?.investment?.data?.investment?.units,
      applicationData: response?.investment?.data?.application,
      investmentManagerDetails:
        response?.investment?.data?.investmentManagerDetails,
      isFractional: true,
    },
    {
      heading: '',
      subHeading: '',
      component: INVESTMENTKEYS.INVESTMENTSRECORD,
      isActive: true,
      isFractional: true,
    },
    {
      heading: '',
      subHeading: '',
      component: INVESTMENTKEYS.INVESTMENTASSOCIATES,
      isActive: true,
      isFractional: true,
    },
    {
      heading: '',
      subHeading: '',
      component: INVESTMENTKEYS.INVESTMENT_PARTNERS,
      isActive: true,
      isFractional: true,
    },
    {
      heading: response?.opportunity?.heading,
      subHeading: response?.opportunity?.subHeading,
      component: INVESTMENTKEYS.OPPORTUNITY,
      isActive: false,
      data: response?.opportunity?.usp,
      isFractional: true,
    },
    {
      heading: '',
      subHeading: '',
      component: INVESTMENTKEYS.HIGHLIGHTS,
      isActive: response?.about?.highlights?.length > 0,
      data: response?.about?.highlights,
    },
    {
      heading: response?.cta?.heading,
      subHeading: response?.cta?.subHeading,
      component: INVESTMENTKEYS.CTA,
      isActive: !!response?.cta?.heading,
    },
    {
      heading: response?.location?.heading,
      subHeading: response?.location?.subHeading,
      component: INVESTMENTKEYS.LOCATION,
      isActive: !!response?.location?.media?.mediaURL,
      data: response?.location?.media,
    },
    {
      heading: '',
      subHeading: '',
      component: INVESTMENTKEYS.NEAR_BY,
      isActive: response?.location?.nearbyPlaces?.length > 0,
      data: response?.location?.nearbyPlaces,
    },
    {
      heading: response?.project?.managedBy?.promise?.heading,
      subHeading: response?.project?.managedBy?.promise?.subHeading,
      component: INVESTMENTKEYS.PROMISE,
      isActive: response?.project?.managedBy?.promise?.values?.length > 0,
      data: response?.project?.managedBy?.promise?.values,
    },
    {
      heading: response?.gallery?.heading,
      subHeading: response?.gallery?.subHeading,
      component: INVESTMENTKEYS.GALLERY,
      isActive: response?.gallery?.media?.length > 0,
      data: response?.gallery?.media,
    },
    {
      heading: response?.amenities?.heading,
      subHeading: response?.amenities?.subHeading,
      component: INVESTMENTKEYS.AMENITIES,
      isActive: response?.amenities?.media?.length > 0,
      data: response?.amenities?.media,
    },
    {
      heading: response?.project?.managedBy?.about?.heading,
      subHeading: response?.project?.managedBy?.about?.subHeading,
      component: INVESTMENTKEYS.ABOUT_US,
      isActive: response?.project?.managedBy?.about?.media?.length,
      data: response?.project?.managedBy?.about?.media?.[0],
    },
    {
      heading: response?.process?.heading,
      subHeading: response?.process?.subHeading,
      component: INVESTMENTKEYS.PROCESS,
      isActive: response?.process?.flow?.length > 0,
      data: response?.process?.flow,
    },
    {
      heading: response?.press?.heading,
      subHeading: response?.press?.subHeading,
      component: INVESTMENTKEYS.PRESS,
      isActive: response?.press?.articles?.length > 0,
      data: response?.press?.articles,
    },
    {
      heading: 'OUR PARTNERS',
      subHeading: 'Join the esteemed legacy of our marquee partners.',
      component: INVESTMENTKEYS.PARTNERS,
      isActive: false,
      isFractional: true,
    },
    {
      heading: response?.downloads?.heading,
      subHeading: response?.downloads?.subHeading,
      component: INVESTMENTKEYS.DOCUMENTS,
      isActive: response?.downloads?.documents?.length > 0,
      data: response?.downloads?.documents,
    },
  ];
};

export const realEstate32ND = [
  {
    key: 'Returns',
    value: 'Guaranteed',
  },
  {
    key: 'Monthly Income',
    value: 'Yes, Rental Income',
  },
  {
    key: 'RoI',
    value: '13.2% Fixed',
  },
  {
    key: 'Volatility',
    value: 'No Volatility',
  },
  {
    key: 'Charges',
    value: 'No Charges',
  },
];

export const mf = [
  {
    key: 'Returns',
    value: 'Not Guaranteed',
  },
  {
    key: 'Monthly Income',
    value: 'Yes, SWP',
  },
  {
    key: 'RoI',
    value: '10%-15%',
  },
  {
    key: 'Volatility',
    value: 'Moderate',
  },
  {
    key: 'Charges',
    value: '0.1%-1.5%',
  },
];

export const fd = [
  {
    key: 'Returns',
    value: 'Guaranteed',
  },
  {
    key: 'Monthly Income',
    value: 'No',
  },
  {
    key: 'RoI',
    value: '6%-8%',
  },
  {
    key: 'Volatility',
    value: 'No Volatility',
  },
  {
    key: 'Charges',
    value: 'No Charges',
  },
];
export const others = [
  {
    key: 'Returns',
    value: 'Not Guaranteed',
  },
  {
    key: 'Monthly Income',
    value: 'No',
  },
  {
    key: 'RoI',
    value: '10%-20%',
  },
  {
    key: 'Volatility',
    value: 'Moderate',
  },
  {
    key: 'Charges',
    value: 'Stamp duty',
  },
];

export const comparisonArray = [
  {
    title: 'Mutual Funds',
    shortName: 'Mutual Funds',
    id: 'mf',
    data: mf,
  },
  {
    title: 'Fixed Deposit (FD)',
    shortName: 'Fixed Deposit',
    id: 'fd',
    data: fd,
  },
  {
    title: 'Other Real Estate',
    shortName: 'Real Estate',
    id: 'others',
    data: others,
  },
];

export const FAQs = [
  {
    question:
      'Is the investment in compliance with Indian laws and regulations?',
    answer:
      'Yes, the entire investment process complies with the Indian Companies Act, 2013. We ensure all legal requirements are met, including issuing an offer letter, a shareholder agreement, and allotting shares with an ISIN number.',
  },
  {
    question: 'What documentation will I receive upon making my investment?',
    answer:
      'During investment discussions, you will receive an offer letter and a shareholder agreement. Once signed, your shares will be allotted an ISIN number and deposited into your Demat account.',
  },
  {
    question: 'How are my shares secured?',
    answer:
      'Your shares are allotted an International Securities Identification Number (ISIN) and deposited in your DMAT account, providing a secure and regulated environment.',
  },
  {
    question: 'What measures are in place to protect my investment?',
    answer:
      'Company will appoint both a Grievance Officer and a Compliance Officer as per Government regulations. The Grievance Officer handles any issues or concerns, while the Compliance Officer ensures adherence to all regulatory requirements.',
  },
];
export const AssociatesArray = [
  {
    startingYear: 1999,
    endingYear: 2010,
    bg: require('../../assets/images/associatesBg1.webp'),
    descArray: [
      {
        statement: 'Launched 32nd Milestone, one of the',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement: 'most popular F&B space in Delhi NCR.',
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
      },
      {
        statement:
          'Built 1.1 million sq ft. Signature Towers in Delhi NCR, creating one of the',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement: 'first Grade A commercial campuses.',
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
      },
      {
        statement: 'Launched',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement:
            'India’s first microbrewery, and achieved the highest footfall',
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
        secondaryStatement: {
          statement:
            'in the capital region for oriental cuisine at 32nd Milestone.',
          size: 'md',
          color: 'primary.300',
        },
      },
    ],
  },
  {
    startingYear: 2011,
    endingYear: 2019,
    bg: require('../../assets/images/associatesBg2.webp'),
    descArray: [
      {
        statement: 'Leased 1 million square feet to',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement:
            'Google Inc. and MG motors for their corporate headquarters.',
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
      },
      {
        statement: 'Entered a private development',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement: 'license with the Goa government',
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
        secondaryStatement: {
          statement: 'for 32nd Beach on Vagator',
          size: 'md',
          color: 'primary.300',
        },
      },
      {
        statement: 'Launched 32nd Avenue with',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement: '30+ unique F&B partners.',
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
      },
    ],
  },
  {
    startingYear: 2020,
    endingYear: 2025,
    bg: require('../../assets/images/associatesBg3.webp'),
    descArray: [
      {
        statement: 'With',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement: `five new restaurant incubations`,
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
        secondaryStatement: {
          statement: `at 32nd, we continue to solidify our position as India's premier F&B innovator.`,
          size: 'md',
          color: 'primary.300',
        },
      },
      {
        statement: 'Introducing our',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement: 'unparalleled ultra-luxury estates in Goa,',
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
        secondaryStatement: {
          statement: 'setting a new benchmark in exclusive living',
          size: 'md',
          color: 'primary.300',
        },
      },
      {
        statement: 'Launching a revolutionary retail space featuring premium,',
        size: 'md',
        color: 'primary.300',
        primaryStatement: {
          statement: 'disruptive Indian fashion brands',
          size: 'md',
          color: 'primary.500',
          weight: 'medium',
        },
      },
    ],
  },
];
