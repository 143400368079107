import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SignUpPayload } from './loginModalStore';

export enum LoginFlowScreenSteps {
  SELECT_LOGIN_METHOD = 'select_login_method',
  ENTER_PHONE_NUMBER = 'enter_phone_number',
  ENTER_OTP = 'enter_otp',
  SIGN_UP = 'sign_up',
}

type InitialRouteParams = Record<string, any>;

export interface LoginFlowStoreInterface {
  currentScreen: LoginFlowScreenSteps;
  mobileNumber: string;
  signupData: SignUpPayload;
  countryCallingCode: string;
  isWifiFlowEnabled: boolean;
  wifiFlowSid: string;
  waId: string;
  secondarySignup: boolean;
  initialRoute: { name: string; params: InitialRouteParams };
  setSignupPayload: (payload: SignUpPayload) => void;
  setCountryCallingCode: (callingCode: string) => void;
  setMobileNumber: (mobileNumber: string) => void;
  setCurrentScreen: (currentScreen: LoginFlowScreenSteps) => void;
  setIsWifiFlowEnabled: (enabled: boolean) => void;
  setWifiFlowSid: (sid: string) => void;
  setWaId: (waId: string) => void;
  setSecondarySignup: (enabled: boolean) => void;
  setInitialRoute: (routeName: string, params: InitialRouteParams) => void;
}

const useLoginFlowStore = create<LoginFlowStoreInterface>()(
  devtools(
    (set, get) => ({
      currentScreen: LoginFlowScreenSteps.SELECT_LOGIN_METHOD,
      mobileNumber: '',
      signupData: { name: { first: '', last: '' } },
      isWifiFlowEnabled: false,
      wifiFlowSid: '',
      waId: '',
      secondarySignup: false,
      initialRoute: { name: '', params: {} },
      countryCallingCode: '',
      setCurrentScreen: (currentScreen: LoginFlowScreenSteps) =>
        set((state) => ({
          currentScreen,
        })),
      setMobileNumber: (mobileNumber: string) =>
        set((state) => ({
          mobileNumber,
        })),
      setSignupPayload: (payload: SignUpPayload) =>
        set((state) => ({
          signupData: payload,
        })),
      setCountryCallingCode: (callingCode: string) =>
        set(() => ({
          countryCallingCode: callingCode,
        })),
      setIsWifiFlowEnabled: (enabled: boolean) =>
        set((state) => ({
          isWifiFlowEnabled: enabled,
        })),
      setWifiFlowSid: (sid: string) =>
        set((state) => ({
          wifiFlowSid: sid,
        })),
      setWaId: (waId: string) =>
        set((state) => ({
          waId,
        })),
      setSecondarySignup: (enabled: boolean) =>
        set((state) => ({
          secondarySignup: enabled,
        })),
      setInitialRoute: (routeName: string, params: InitialRouteParams = {}) =>
        set((state) => ({
          initialRoute: { name: routeName, params },
        })),
    }),
    {
      name: 'LoginFlowStore',
    },
  ),
);

export default useLoginFlowStore;
