import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { formatTimeToHrsMinSec } from '../../../../utilities/helper';
import fetchTaskMetricsQuery from '../../API/taskMetricsQuery';
import BottomScoreCard from '../../../../components/new/primitive/ScoreCard/BottomScoreCard';
import { View } from '../../../../components/new';

const BottomScoreBoard = ({ isSupervisor }) => {
  // ===================

  const [res, setResponse] = React.useState(null);

  useFocusEffect(
    React.useCallback(() => {
      const startTimeStamp = new Date();
      const endTimeStamp = new Date();
      startTimeStamp.setHours(5);
      fetchTaskMetricsQuery({
        startTimeStamp: Math.round(startTimeStamp.getTime() / 1000),
        endTimeStamp: Math.round(endTimeStamp.getTime() / 1000),
      }).then((ans) => {
        const getTaskMetrics = ans?.getTaskMetrics;
        setResponse(getTaskMetrics);
      });
      // ===================
    }, []),
  );

  const getBottomScoreColor = (score) => {
    if (score >= 90) {
      return 'success.500';
    }
    if (score >= 75 && score < 90) {
      return 'warning.500';
    }
    if (score < 75 && score > 0) {
      return 'error.500';
    }
    return 'primary.500';
  };

  const showBottomScore = ({ onboardingScore, parkingScore }) => {
    if (onboardingScore > 0 && isSupervisor) {
      return true;
    }
    if (parkingScore > 0 && !isSupervisor) {
      return true;
    }
    return false;
  };

  return res &&
    res !== null &&
    showBottomScore({
      onboardingScore: res?.onboardingScore,
      parkingScore: res?.parkingScore,
    }) ? (
    <View justifyContent="flex-end">
      <BottomScoreCard
        scorecard1={{
          title1: isSupervisor ? 'Onboard Score' : 'Parking Score',
          title2: isSupervisor
            ? `${res?.onboardingScore ? res?.onboardingScore : 0}%`
            : `${res?.parkingScore ? res?.parkingScore : 0}%`,
          // title1Color: 'primary.100',
          title2Color: isSupervisor
            ? getBottomScoreColor(
                res?.onboardingScore ? res?.onboardingScore : 0,
              )
            : getBottomScoreColor(res?.parkingScore ? res?.parkingScore : 0),
        }}
        scorecard2={{
          title1: isSupervisor ? 'Onboard Time' : 'Parking Time',
          title2: isSupervisor
            ? formatTimeToHrsMinSec(
                res?.onboardingTime && res?.onboardingTime > 0
                  ? res?.onboardingTime
                  : 0,
              )
            : formatTimeToHrsMinSec(
                res?.parkingTime && res?.parkingTime > 0 ? res?.parkingTime : 0,
              ),
          // title1Color: 'primary.100',
          title2Color: isSupervisor
            ? getBottomScoreColor(
                res?.onboardingScore ? res?.onboardingScore : 0,
              )
            : getBottomScoreColor(res?.parkingScore ? res?.parkingScore : 0),
        }}
      />
    </View>
  ) : (
    <></>
  );
};

export default BottomScoreBoard;
