import React, { useRef } from 'react';
import { FlatList, useWindowDimensions } from 'react-native';
import { graphql, usePaginationFragment } from 'react-relay';
import { View, Text } from '../../components/new';
import RewardCard from '../../components/new/custom/RewardCard';
import theme, { space } from '../../themes/new/theme';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { dynamicHeightMaker } from '../../utilities/helper';
import { useDownloadAppStore } from '../../stores/downloadApp/downloadAppStore';
import useCommonStore from '../../stores/commonStore';
import { useNodeFromPagination } from '../../hooks/useNodeFromPagination';
import CachedImage from '../../components/new/custom/CachedImage';

const frag = graphql`
  fragment RewardList_findReward on Reward @relay(plural: true) {
    _id
    ownerId
    parkingId
    rewardType
    isUsed
    isExpired
    expireAt
    created_at
    updated_at
    rewardUniqueId
    rewardOrigin
  }
`;

const ticketQueryFragment = graphql`
  fragment RewardListTicketQueryFragment on Query
  @refetchable(queryName: "RewardsScreenTicketQuery")
  @argumentDefinitions(
    filter: { type: "FilterFindManyticketInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    tickets: findTickets(filter: $filter, first: $count, after: $cursor)
      @connection(key: "RewardListTicketQueryFragment__tickets") {
      edges {
        node {
          _id
          ticketType
          coverImg
          thumbnailImg
          uId
          expires_on
          title
          issuedBy {
            refType
            _id
          }
          status
          additionalFields {
            event {
              startTime
              endTime
            }
          }
          canBeClaimedBy {
            venue {
              geo {
                lat
                long
              }
              addressLine1
            }
          }
          rewardId
          updated_at
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const RewardList = (props) => {
  const { findRewardRef, navigation } = props;
  const { width, height } = useWindowDimensions();
  const screenOrientationValue = useCommonStore((state) => state.orientation);
  const { dispatchDownloadApp } = useDownloadAppStore((state) => state);

  const {
    data,
    loadNext: loadNextRewards,
    hasNext: hasMoreRewards,
  } = usePaginationFragment(ticketQueryFragment, findRewardRef);
  const rewardList = useNodeFromPagination(data.tickets);
  const filteredRewards = rewardList.filter((r) => {
    if (
      r?.status === 'ACTIVE' ||
      r?.status === 'CLAIMED' ||
      r?.status === 'EXPIRED'
    )
      return true;

    return false;
  });

  const loadMoreRewards = () => {
    if (hasMoreRewards) loadNextRewards(10);
  };

  // sort by created_at
  const sortFn = (a, b) => {
    if (a.created_at < b.created_at) {
      return -1;
    }
    if (a.created_at > b.created_at) {
      return 1;
    }
    return 0;
  };

  // sort by isUsed i.e redeemed or not
  const sortByStatus = (a, b) => {
    return b.isUsed - a.isUsed;
  };

  // const nonUsedAndExpiredData =
  //   filteredRewards && filteredRewards?.length
  //     ? filteredRewards
  //       .filter((item) => !item?.isUsed)
  //       .filter((item) => !item.isExpired)
  //       .sort(sortFn)
  //     : [];

  // const usedAndExpiredData =
  //   filteredRewards && filteredRewards?.length
  //     ? filteredRewards
  //       .filter((item) => item?.isUsed || item?.isExpired)
  //       .sort(sortByStatus)
  //     : [];
  // const sortedData = [...nonUsedAndExpiredData, ...usedAndExpiredData];

  const checkAction = (item) => {
    navigation.navigate('FreeParkingScreen', {
      rewardId: item?.uId,
    });
  };

  const renderItem = ({ item, index }) => (
    <View
      mt="2xl"
      width={
        width > 1200 && screenOrientationValue < 3
          ? (375 - 3 * 16) / 2
          : (width - 3 * 16) / 2
      }
      ml={index % 2 !== 0 ? '2xl' : 0}
    >
      <RewardCard
        imageURL={
          item?.coverImg ??
          'https://ik.imagekit.io/32ndhub/assets/rewards/free-parking.png?v-1'
        }
        reward={item?.title}
        isUsed={item?.status === 'CLAIMED'}
        isExpired={item?.status === 'EXPIRED'}
        expireAt={item?.expires_on}
        updatedAt={item?.updated_at}
        onPress={() => {
          // ===========
          firebaseEventLogger('rewardsScreen_reward_card_tap', {
            buttonName: 'card',
            screenName: 'rewardsScreen',
            userType: 'user',
            interactionType: 'tap',
            rewardType: item?.rewardType,
          });
          // ===========
          checkAction(item);
        }}
      />
    </View>
  );

  const flatListRef = useRef(null);

  return (
    <>
      {filteredRewards && filteredRewards?.length ? (
        <FlatList
          ref={flatListRef}
          contentContainerStyle={{
            paddingHorizontal: theme.space['2xl'],
            marginTop: theme.space.lg,
            marginBottom: theme.space['4xl'],
          }}
          showsVerticalScrollIndicator={false}
          data={filteredRewards}
          renderItem={renderItem}
          keyExtractor={(item) => item._id}
          numColumns={2}
          ListFooterComponent={() => <View height="2xl" />}
        />
      ) : (
        <View flex={1} alignItems="center" justifyContent="center">
          <View>
            <CachedImage
              source={require('../../../assets/images/no-data-found.webp')}
              style={{
                width:
                  width > 1200 && screenOrientationValue < 3
                    ? Math.round(375 * 0.64)
                    : Math.round(width * 0.64),
                height: dynamicHeightMaker(
                  width > 1200 && screenOrientationValue < 3
                    ? Math.round(375 * 0.64)
                    : Math.round(width * 0.64),
                  0.8,
                ),
                resizeMode: 'contain',
              }}
            />
            <Text
              textAlign="center"
              mt="lg"
              size="md"
              color="primary.500"
              weight="medium"
            >
              No Rewards Found
            </Text>
            <Text textAlign="center" mt="lg" size="md" color="primary.100">
              You don’t have any rewards yet
            </Text>
            <View height={space['9xl']} />
          </View>
        </View>
      )}
    </>
  );
};

export default RewardList;
