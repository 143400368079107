import React from 'react';
import { IconButton, Text, View } from '../../../../components/new';
import TransactionSummaryBanner from '../../../../components/new/custom/transaction/TransactionSummaryBanner';
import TransactionList from '../../components/TransactionList';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import ReportDetailsBottomSheet, { BSMode } from './ReportDetailsBottomSheet';
import { ITransaction } from '../../types';
import { ReportMode, Report } from './types';

interface ReportOverviewScreenInnerProps {
  reportMode: ReportMode;
  queryOptions: NewErrorBoundaryParentState;
  useBusinessLogic: (queryOptions: NewErrorBoundaryParentState) => {
    bsRef: { current: any };
    bsMode: BSMode;
    bannerSubTitle: string;
    listTitle: string;
    transactions: ITransaction[];
    clickedTransaction: ITransaction | null;
    report: Report;
    fetchMoreTransactions: () => void;
    handleBSDismiss: () => void;
    showReportDetailsBS: () => void;
    showTransactionDetailsBS: (orderId: ITransaction['orderId']) => void;
    handleBack: () => void;
  };
}

const ReportOverviewScreen = ({
  reportMode,
  queryOptions,
  useBusinessLogic,
}: ReportOverviewScreenInnerProps) => {
  const {
    bsRef,
    bsMode,
    report,
    bannerSubTitle,
    listTitle,
    transactions,
    clickedTransaction,
    handleBack,
    fetchMoreTransactions,
    showReportDetailsBS,
    showTransactionDetailsBS,
    handleBSDismiss,
  } = useBusinessLogic(queryOptions);

  const bannerAmount =
    reportMode === 'REVENUE'
      ? report.formated.revenueAmount
      : report.formated.collectedAmount;

  return (
    <View flex={1}>
      <TransactionSummaryBanner
        onBack={handleBack}
        amount={bannerAmount}
        status="SUCCESSFUL"
        BottomContent={
          <View flexDirection="row" alignItems="center">
            <Text size="sm" weight="regular" color="primary.10">
              {bannerSubTitle}
            </Text>
            <IconButton
              onPress={showReportDetailsBS}
              appearance="ghost"
              iconColor="primary.10"
              name="info-outline-300"
              size="md"
              iconSize="xl"
            />
          </View>
        }
      />
      <View flex={1}>
        <Text px="2xl" mt="2xl" size="md" weight="medium" color="primary.400">
          {listTitle}
        </Text>
        <TransactionList
          onScrollEnd={fetchMoreTransactions}
          data={transactions.map((txn) => ({
            orderId: txn.orderId,
            date: txn.formated.time,
            amount:
              reportMode === 'EARNINGS'
                ? txn.formated.netAmount
                : txn.formated.billAmount,
            paymentStatus: txn.paymentStatus,
          }))}
          onItemPress={(txn) => showTransactionDetailsBS(txn.orderId)}
        />
      </View>
      <ReportDetailsBottomSheet
        reportMode={reportMode}
        ref={bsRef}
        bsMode={bsMode}
        onDismiss={handleBSDismiss}
        transaction={clickedTransaction}
        report={report}
      />
    </View>
  );
};

export default ReportOverviewScreen;
