import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const printMomentsQuery = graphql`
  query printMomentsQuery($filter: findPopUpStoresFilterInput) {
    findPopUpStores(filter: $filter) {
      edges {
        node {
          _id
        }
      }
    }
  }
`;
