import React, { useCallback, useEffect, useMemo } from 'react';

export enum CountdownStatus {
  NOT_STARTED,
  RUNNING,
  STOPPED,
  PAUSED,
  COMPLETED,
}

interface DefaultOptionsType {
  onComplete?: () => void;
  onTick?: () => void;
  interval?: number;
}

interface Output {
  countdownStatus: CountdownStatus;
  countdown: number;
  startCountdown: () => void;
  stopCountdown: () => void;
  restartCountdown: (value?: number) => void;
  pauseCountdown: () => void;
  continueCountdown: () => void;
}

const defaultInterval = 1000;
const defaultOptions: DefaultOptionsType = {
  onComplete: () => {},
  onTick: () => {},
  interval: defaultInterval,
};

const useCountdown = (endTime = 10, passedOptions = defaultOptions): Output => {
  const [countdownStatus, setCountdownStatus] = React.useState(
    CountdownStatus.NOT_STARTED,
  );
  const [options] = React.useState(Object.freeze(passedOptions));
  const [countdown, setCountdown] = React.useState(endTime);
  const intervalIdRef = React.useRef(null);

  const onTick = useCallback(() => {
    setCountdown((c) => {
      if (c === 0) {
        disposeInterval();
        if (options?.onComplete) {
          options.onComplete();
        }
        return 0;
      }
      if (options?.onTick) {
        options.onTick();
      }
      return c - 1;
    });
  }, []);

  const disposeInterval = useCallback(() => {
    clearInterval(intervalIdRef.current);
  }, []);

  const assignInterval = useCallback(() => {
    if (intervalIdRef.current) disposeInterval(); // dispose first if accidentally not cleared;
    intervalIdRef.current = setInterval(
      onTick,
      options?.interval || defaultInterval,
    );
  }, []);

  useEffect(() => {
    return () => {
      disposeInterval();
    };
  }, []);

  const startCountdown = useCallback(() => {
    setCountdownStatus(CountdownStatus.RUNNING);
    assignInterval();
  }, []);

  const restartCountdown = useCallback((value?: number) => {
    setCountdownStatus(CountdownStatus.RUNNING);
    disposeInterval();
    setCountdown(value ?? endTime);
    assignInterval();
  }, []);

  const stopCountdown = useCallback(() => {
    setCountdownStatus(CountdownStatus.STOPPED);
    setCountdown(0);
    disposeInterval();
  }, []);

  const pauseCountdown = useCallback(() => {
    setCountdownStatus(CountdownStatus.PAUSED);
    disposeInterval();
  }, []);

  const continueCountdown = useCallback(() => {
    setCountdownStatus(CountdownStatus.RUNNING);
    assignInterval();
  }, []);

  return {
    countdownStatus,
    countdown,
    startCountdown,
    stopCountdown,
    restartCountdown,
    pauseCountdown,
    continueCountdown,
  };
};

export default useCountdown;
