/* eslint-disable react/require-default-props */
import React from 'react';
import { Text, View } from '../..';
import { FontWeights } from '../../../../themes/new/helper';

interface ScoreViewProps {
  title1?: string;
  title2?: string;
  title1Color?: string;
  title2Color?: string;
}

const Scoreview = ({
  title1 = '',
  title2 = '',
  title1Color = 'primary.100',
  title2Color = 'primary.500',
}: ScoreViewProps) => {
  return (
    <View
      py="sm"
      //   px="sm+md"
      borderWidth="xs"
      borderColor="primary.50"
      borderRadius="md"
    >
      <View flexDirection="row" alignItems="center" justifyContent="center">
        <Text size="xs" color={title1Color}>
          {title1}
        </Text>
        <Text size="md" color={title2Color} weight={FontWeights.MEDIUM} ml="lg">
          {title2}
        </Text>
      </View>
    </View>
  );
};

export default Scoreview;
