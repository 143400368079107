import React, { memo } from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { Text, View } from '../../../../components/new';
import { imageTransformation } from '../../../../utilities/helper';
import VideoWithThumbnail from '../../../../components/new/custom/VideoWithThumbnail';

const areEqual = () => {
  return true;
};

const InvestmentLocation = ({ data, bgColor = '' }) => {
  const { width } = useWindowDimensions();

  return (
    <View pt="9xl+9xl" bg={bgColor} alignItems="center">
      <Text size="xs" color="primary.300" pb="lg" textAlign="center">
        {data?.heading}
      </Text>
      <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
        {data?.subHeading}
      </Text>
      <View mt="9xl+lg">
        {data?.data?.mediaType === 'VIDEO' ? (
          <VideoWithThumbnail
            aspectRatio={1.23}
            thumbnailWidth={width - 32}
            thumbnailSource={imageTransformation(
              data?.data?.thumbnailURL,
              width - 32,
              `${width - 32}-${(width - 32) * 1.23}`,
            )}
            videoSource={data?.data?.mediaURL}
            onPress={() => null}
            videoTitle=""
            videoSubTitle=""
          />
        ) : (
          <Image
            source={{
              uri: imageTransformation(
                data?.data?.mediaURL,
                width,
                `${width}-480`,
              ),
            }}
            style={{
              height: 480,
              width,
            }}
          />
        )}
      </View>
    </View>
  );
};

export default memo(InvestmentLocation, areEqual);
