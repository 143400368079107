import React from 'react';
import { Platform } from 'react-native';
import { View, Text } from '../..';

type BonusIncentiveInfoBoxProps = {
  onTimeBonus: string;
  delayPenalty: string;
};

const BonusIncentiveInfoBox = ({
  onTimeBonus,
  delayPenalty,
}: BonusIncentiveInfoBoxProps) => {
  const shadowProps =
    Platform.OS === 'web' || Platform.OS === 'ios'
      ? { borderColor: 'primary.50', shadow: 'sm' }
      : { borderColor: 'primary.50' };

  const data = [
    {
      id: 0,
      title: 'Every On-Time Task = ',
      subTitle: onTimeBonus,
      description: `You receive ${onTimeBonus} on completion of every on-time task`,
    },
    {
      id: 1,
      title: 'Every Delayed Task = ',
      subTitle: delayPenalty,
      description: `You receive ${delayPenalty} on every delayed task`,
    },
  ];
  return (
    <View>
      <Text lineHeight="22" color="primary.500" size="md" mx="2xl" mt="xl">
        How to Earn Incentives?
      </Text>
      {data.map((item) => (
        <View
          borderRadius="lg"
          borderWidth="xs"
          backgroundColor="background.primary.elevation"
          mx="2xl"
          mt="4xl"
          p="2xl"
          flexDirection="row"
          alignItems="center"
          key={`time_guarantee_box${item?.id}`}
          // mb={item?.id === 1 && '9xl'}
          {...shadowProps}
        >
          <View flex={1}>
            <Text lineHieght="28" size="lg" color="primary.500">
              {item?.title}{' '}
              <Text color={item?.id === 0 ? 'success.500' : 'error.500'}>
                {item?.subTitle}
              </Text>
            </Text>
            <Text size="xs" color="primary.200" mt="sm">
              {item?.description}
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
};
export default BonusIncentiveInfoBox;
