/* eslint-disable import/prefer-default-export */
import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../../relay/relayEnvironment';

const query = graphql`
  query slotMachineQuery(
    $ticketFilter: FilterFindManyticketInput
    $userId: MongoID!
  ) {
    findTickets(filter: $ticketFilter, first: 200) {
      edges {
        node {
          ticketType
          status
        }
      }
    }
    findUserById(_id: $userId) {
      _id
      wallet {
        totalBalance
        currentBalance
      }
    }
    getSpinsCount {
      spinsLeft
      freezedTill
    }
  }
`;

export const fetchRewards = ({ userId, ticketType }) => {
  const variables = {
    ticketFilter: {
      ticketType,
    },
    userId,
  };
  return fetchQuery(environment, query, variables).toPromise();
};
