import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import View from './basic/View/View';
import { navigateBack } from '../../utilities/helper';
import TopNavigation from './composites/TopNavigation/TopNavigation';
import IconButton from './primitive/IconButton/IconButton';
import useUserStore from '../../stores/userStore';
import VideoPlayerWrapper from './custom/VideoPlayerWrapper';
import imageConfig from '../../../imagekit.config';

const imageKitURL = ({ logo, height, width }) =>
  imageConfig.url({
    src: logo,
    transformation: [
      {
        height: `${height * 2}`,
        width: `${width * 2}`,
      },
    ],
  });

const Gallery = (props) => {
  const navigation = useNavigation();
  const userRole = useUserStore((state) => state.role);
  const ref = useRef();
  const data = props?.route?.params?.data ?? [];
  const curIndex = props?.route?.params?.curIndex ?? 0;
  const title = props?.route?.params?.title ?? 'Gallery';
  const [selectedIndex, setSelectedIndex] = useState(curIndex);

  useEffect(() => {
    setTimeout(() => {
      ref?.current?.scrollToIndex({
        animated: true,
        index: selectedIndex,
      });
    }, 500);
  }, []);

  const RenderItem = ({ item }) => {
    return (
      <View mt="lg">
        {(item?.mediaType === 'VIDEO' || item?.mediaType === 'video') && (
          <VideoPlayerWrapper
            id={item?._id}
            isMuted
            isLooping
            url={item?.mediaURL}
            // height={507}
            aspectRatio={0.76}
            showFullScreen
          />
        )}
        {(item?.mediaType === 'IMAGE' || item?.mediaType === 'image') && (
          <Image
            source={{
              uri: imageKitURL({
                logo: item?.mediaURL,
                height: 507,
                width: 507,
              }),
            }}
            style={{ height: 507, width: 507 }}
            progressiveRenderingEnabled
          />
        )}
      </View>
    );
  };

  return (
    <View flex={1}>
      <TopNavigation
        appearance="ghost"
        level="1"
        title={title}
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, userRole)}
          />
        }
      />
      <FlashList
        ref={ref}
        data={data}
        scrollIndicatorInsets={{ right: 1 }}
        renderItem={(res) => <RenderItem {...res} />}
        estimatedItemSize={1257}
        drawDistance={500}
        estimatedFirstItemOffset={1000}
        contentContainerStyle={
          {
            // paddingTop: layout ? layout?.height : 107,
          }
        }
      />
    </View>
  );
};

export default Gallery;
