/**
 * @generated SignedSource<<14903cfef74bdc8f838c96bcf6bfb817>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumRestaurantDiscountGetTCDays = "Weekday" | "Weekend" | "%future added value";
export type EnumRestaurantDiscountGetTCMealTime = "dinner" | "lunch" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type restaurantDetail_getRestaurantpartnerDiscount$data = ReadonlyArray<{
  readonly _id: any;
  readonly days: EnumRestaurantDiscountGetTCDays;
  readonly discounts: {
    readonly allUser: number;
    readonly corporateUser: number;
    readonly newUser: number;
  };
  readonly isActive: boolean | null;
  readonly mealTime: EnumRestaurantDiscountGetTCMealTime;
  readonly restaurantId: any;
  readonly user: {
    readonly isEmployee: boolean | null;
    readonly isNewUser: boolean | null;
  } | null;
  readonly " $fragmentType": "restaurantDetail_getRestaurantpartnerDiscount";
}>;
export type restaurantDetail_getRestaurantpartnerDiscount$key = ReadonlyArray<{
  readonly " $data"?: restaurantDetail_getRestaurantpartnerDiscount$data;
  readonly " $fragmentSpreads": FragmentRefs<"restaurantDetail_getRestaurantpartnerDiscount">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "restaurantDetail_getRestaurantpartnerDiscount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "restaurantId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurantDiscountDiscounts",
      "kind": "LinkedField",
      "name": "discounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allUser",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newUser",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "corporateUser",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "days",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mealTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GetRestaurantDiscountUserTc",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isEmployee",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isNewUser",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RestaurantDiscountGetTC",
  "abstractKey": null
};

(node as any).hash = "a760d22bae753d44103dcddef2422ede";

export default node;
