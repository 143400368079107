/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, Suspense } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { FlatList } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, View, Text, Icon } from '../../components/new';
import { Loading } from '../../components';
import { useNodeFromPagination } from '../../hooks/useNodeFromPagination';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useUserStore, { UserRole } from '../../stores/userStore';
import { getTheme } from '../../themes/new/theme';
import SearchInput from '../../components/new/composites/SearchInput/SearchInput';
import {
  DeliveryStatus,
  navigateBack,
  PaymentStatusEnum,
} from '../../utilities/helper';
import RenderItem from './RenderItem';
import {
  auditorSearchCodeScreenQuery,
  paymentHistoryFragment,
} from './API/auditorSearchCodeScreenQuery';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useAuthStore from '../../stores/authStore';
import { pagination } from '../../utilities/Constant';
import CustomFlatList from '../../components/new/custom/CustomFlatList';
import PaginationLoader from '../../components/new/custom/PaginationLoader';
import useCommonStore from '../../stores/commonStore';

interface AuditorSearchCodeScreenProps {
  queryOptions: any;
  variables: any;
  setFilter: (text) => void;
  filter: any;
  fetchKey: any;
  refresh: () => void;
}

const AuditorSearchCodeScreen = ({
  queryOptions,
  variables,
  setFilter,
  filter,
  fetchKey,
  refresh,
}: AuditorSearchCodeScreenProps) => {
  const navigation = useNavigation<any>();
  const { role, id: userId } = useUserStore((state) => state);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [rightIcon, setRightIcon] = useState('search-outline-400');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getPaymentHistoryFragment = useLazyLoadQuery(
    auditorSearchCodeScreenQuery,
    {
      ...variables,
      count: 5,
    },
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const {
    data: getPaymentHistoryPaginated,
    hasNext,
    loadNext,
  } = usePaginationFragment(paymentHistoryFragment, getPaymentHistoryFragment);

  const getPaymentHistory = useNodeFromPagination(
    getPaymentHistoryPaginated?.findPopupOrders,
  );

  const [searchValue, setSearchValue] = useState('');

  const renderItem = ({ item, index }) => {
    return (
      <View mt={index !== 0 ? '2xl' : 0}>
        <RenderItem item={item} refresh={refresh} />
      </View>
    );
  };

  // ------ on press cross icon in input ------
  const onRightSearchIconPress = () => {
    if (searchValue?.length > 0) {
      setSearchValue('');
      setFilter({
        ...variables.filter,
        search: '',
      });
      setRightIcon('search-outline-400');
    }
  };

  // ------ handle text change for input ------
  const handleOnTextChange = (num) => {
    setSearchValue(num);
    if (num.length >= 4) {
      setFilter({
        ...variables.filter,
        search: num,
      });
      firebaseEventLogger('auditorHomeScreen__paid_Screen', {
        buttonName: 'paid',
        screenName: 'auditorHomeScreen',
        userType: UserRole.AUDITOR,
        interactionType: InteractionType.TAP,
        isLoggedIn,
        auditorId: userId,
        searchValue,
      });
    }
    if (num.length >= 1) {
      setRightIcon('close-outline-300');
    }
  };

  return (
    <View flex={1}>
      <View px="2xl" mt="4xl">
        <SearchInput
          rightIconName={rightIcon}
          placeholder="Search Code"
          onRightIconClick={onRightSearchIconPress}
          value={searchValue}
          onChangeText={handleOnTextChange}
          onLeftIconClick={() => navigateBack(navigation, role)}
          leftIconColor="primary.100"
          inputMode="numeric"
          keyboardType="numeric"
          autoFocus
          maxLength={10}
        />
      </View>
      {getPaymentHistory && getPaymentHistory?.length ? (
        <CustomFlatList
          scrollsToTop
          style={{
            marginTop: getTheme().space['4xl'],
            // paddingHorizontal: getTheme().space['2xl'],
          }}
          data={getPaymentHistory}
          renderItem={renderItem}
          ListFooterComponentStyle={{
            margin: 0,
            padding: 0,
          }}
          ListFooterComponent={() => {
            if (hasNext) return <PaginationLoader />;
            return null;
          }}
        />
      ) : searchValue?.length >= 4 ? (
        <View flex={1} alignItems="center" mt="9xl">
          <Icon size="3xl+3xl" color="primary.100" name="search-outline-400" />
          <Text
            size="xl"
            color="primary.100"
            weight="medium"
            textAlign="center"
            mt="2xl"
          >
            No Results for “{filter?.search}”
          </Text>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const AuditorSearchCodeScreenWrapper = ({ navigation, route }) => {
  const { campusId } = useCommonStore((state) => state);
  const [filter, setFilter] = useState({
    orderStatus: DeliveryStatus.UNDELIVERED,
    status: PaymentStatusEnum.PENDING,
    search: '',
    campus: {
      _id: campusId,
    },
  });
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  useFocusEffect(
    useCallback(() => {
      refresh();
      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  return (
    <Layout level={2} edges={['top']}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <AuditorSearchCodeScreen
            variables={{
              filter,
              limit: 100,
              sort: 'ASC',
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            setFilter={setFilter}
            filter={filter}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default AuditorSearchCodeScreenWrapper;
