import React, { useState } from 'react';
import { FlatList } from 'react-native';
import PropTypes from 'prop-types';
import Button from '../../primitive/Button/Button';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import { getTheme } from '../../../../themes/new/theme';

const ButtonGroup = ({ buttonData, setSelectedData, title, size }) => {
  const [selected, setSelected] = useState(null);
  const renderItem = ({ item, index }) => {
    return (
      <View
        flex={buttonData && buttonData?.length > 1 ? 1 : 0.5}
        ml={index % 2 !== 0 ? '2xl' : 0}
        mt={index > 1 ? 'lg' : 0}
      >
        <Button
          size={size}
          onPress={() => {
            //   item?.onPress();
            setSelected(index);
            setSelectedData({ ...item });
          }}
          appearance={
            selected === index || item?.disabled || item?.status === 'success'
              ? 'filled'
              : 'outline'
          }
          status={
            selected === index || item?.status === 'success'
              ? 'success'
              : 'primary'
          }
          state={item?.disabled ? 'disabled' : 'active'}
          {...item}
        >
          {item?.name}
        </Button>
      </View>
    );
  };

  return (
    <View>
      {title && title !== '' ? (
        <Text size="lg" color="primary.200">
          {title}
        </Text>
      ) : (
        <></>
      )}
      <FlatList
        data={buttonData}
        renderItem={renderItem}
        contentContainerStyle={{
          marginTop: title && title !== '' ? getTheme().space['2xl'] : 0,
        }}
        numColumns={2}
        keyExtractor={(item) => item._id}
        ListFooterComponent={() => <View height="lg" />}
      />
    </View>
  );
};

ButtonGroup.propTypes = {
  buttonData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setSelectedData: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.string,
};

ButtonGroup.defaultProps = {
  buttonData: [],
  setSelectedData: () => {},
  size: 'lg',
  title: '',
};

export default ButtonGroup;
