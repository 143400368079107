/**
 * @generated SignedSource<<2b129a62b9fabeb88d91aa470bf6ba5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DailyControllerTaskBonusFilter = {
  date: string;
};
export type MonthlyControllerTaskBonusFilter = {
  date: string;
};
export type controllerLast12MonthsBonusFilter = {
  date: string;
};
export type controllerOverviewQuery$variables = {
  dailyBonusFilter?: DailyControllerTaskBonusFilter | null;
  monthlyBonusFilter?: MonthlyControllerTaskBonusFilter | null;
  yearlyBonusFilter?: controllerLast12MonthsBonusFilter | null;
};
export type controllerOverviewQuery$data = {
  readonly controllerLast12MonthsBonus: ReadonlyArray<{
    readonly bonusAmount: number | null;
    readonly delayedTaskCount: number | null;
    readonly month: string | null;
    readonly monthName: string | null;
    readonly onTimeTaskCount: number | null;
    readonly year: string | null;
  } | null> | null;
  readonly dailyControllerTaskBonus: {
    readonly bonus: {
      readonly bonusAmount: number | null;
      readonly delayedTaskCount: number | null;
      readonly onTimeTaskCount: number | null;
    } | null;
    readonly date: string | null;
  } | null;
  readonly monthlyControllerTaskBonus: ReadonlyArray<{
    readonly bonusData: {
      readonly bonusAmount: number | null;
      readonly delayedTaskCount: number | null;
      readonly onTimeTaskCount: number | null;
    } | null;
    readonly date: string | null;
  } | null> | null;
};
export type controllerOverviewQuery = {
  response: controllerOverviewQuery$data;
  variables: controllerOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dailyBonusFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "monthlyBonusFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "yearlyBonusFilter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onTimeTaskCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "delayedTaskCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bonusAmount",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "dailyBonusFilter"
      }
    ],
    "concreteType": "DailyControllerBonusOutput",
    "kind": "LinkedField",
    "name": "dailyControllerTaskBonus",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ControllerBonus",
        "kind": "LinkedField",
        "name": "bonus",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "monthlyBonusFilter"
      }
    ],
    "concreteType": "ControllerMonthlyBonus",
    "kind": "LinkedField",
    "name": "monthlyControllerTaskBonus",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ControllerMonthTaskBonus__BonusData",
        "kind": "LinkedField",
        "name": "bonusData",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "yearlyBonusFilter"
      }
    ],
    "concreteType": "ControllerMonthBonus",
    "kind": "LinkedField",
    "name": "controllerLast12MonthsBonus",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "year",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "month",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "monthName",
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "controllerOverviewQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "controllerOverviewQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "cfd86a5a4972d3e7696c3fba76adede8",
    "id": null,
    "metadata": {},
    "name": "controllerOverviewQuery",
    "operationKind": "query",
    "text": "query controllerOverviewQuery(\n  $dailyBonusFilter: DailyControllerTaskBonusFilter\n  $monthlyBonusFilter: MonthlyControllerTaskBonusFilter\n  $yearlyBonusFilter: controllerLast12MonthsBonusFilter\n) {\n  dailyControllerTaskBonus(filter: $dailyBonusFilter) {\n    date\n    bonus {\n      onTimeTaskCount\n      delayedTaskCount\n      bonusAmount\n    }\n  }\n  monthlyControllerTaskBonus(filter: $monthlyBonusFilter) {\n    date\n    bonusData {\n      onTimeTaskCount\n      delayedTaskCount\n      bonusAmount\n    }\n  }\n  controllerLast12MonthsBonus(filter: $yearlyBonusFilter) {\n    year\n    month\n    monthName\n    onTimeTaskCount\n    delayedTaskCount\n    bonusAmount\n  }\n}\n"
  }
};
})();

(node as any).hash = "46b1b5032dcfb7fa9c2e1e98c520835d";

export default node;
