import React, { useState } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Pressable, Text, View } from '../../../components/new';
import { getTheme } from '../../../themes/new/theme';

const TabSwitcher = ({
  tabOneText = 'tab One',
  tabTwoText = 'tab Two',
  handleTabSwitch,
  tabOneNewItemAvailable = false,
  tabTwoNewItemAvailable = false,
  currentTab,
  mx,
}) => {
  const left = useSharedValue(0);
  const [cardWidth, setCardWidth] = useState(0);
  const theme = useTheme();

  const style = useAnimatedStyle(() => {
    return {
      left: withTiming(left.value, {
        duration: 150,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      }),
    };
  });

  const Dot = () => {
    return <View bg="error.500" borderRadius="full" width={8} height={8} />;
  };

  return (
    <View mx={mx} mb="4xl">
      <View bg="primary.50" borderRadius="3xl" p="xs">
        <View bg="primary.50" borderRadius="3xl" flexDirection="row">
          <View
            flex={1}
            borderRadius="3xl"
            position="absolute"
            width="50%"
            height="100%"
          >
            <Animated.View
              onLayout={(e) => {
                const tempCardWidth = e?.nativeEvent?.layout?.width;
                if (tempCardWidth > cardWidth) {
                  setCardWidth(tempCardWidth);
                }
              }}
              style={[
                {
                  flex: 1,
                  borderRadius: getTheme().radii['3xl'],
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: theme?.colors?.primary['10'],
                },
                style,
              ]}
            />
          </View>
          <View flex={1} p="lg">
            <Pressable
              onPress={() => {
                left.value = 0;
                handleTabSwitch(0);
              }}
              hitSlop={getTheme().space.lg}
            >
              <View flexDirection="row" alignSelf="center">
                <Text
                  size="sm"
                  textAlign="center"
                  color={currentTab === 0 ? 'primary.500' : 'primary.400'}
                >
                  {tabOneText}
                </Text>
                {!!tabOneNewItemAvailable && <Dot />}
              </View>
            </Pressable>
          </View>
          <View flex={1} borderRadius="3xl" p="lg">
            <Pressable
              onPress={() => {
                left.value = cardWidth;
                handleTabSwitch(1);
              }}
              hitSlop={getTheme().space.lg}
            >
              <View flexDirection="row" alignSelf="center">
                <Text
                  size="sm"
                  textAlign="center"
                  color={currentTab === 1 ? 'primary.500' : 'primary.400'}
                >
                  {tabTwoText}
                </Text>
                {!!tabTwoNewItemAvailable && <Dot />}
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
};

export default TabSwitcher;
