/**
 * @generated SignedSource<<da1e23e87682cda67003afce5797ba4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumorderPriceDetailsTaxTaxType = "CGST" | "SGST" | "VAT" | "%future added value";
export type EnumorderStatus = "ACCEPTED" | "CANCELLED" | "COMPLETED" | "CREATED" | "DELAYED" | "DELIVERED" | "DISPATCHED" | "PREPARED" | "PREPARING" | "%future added value";
export type findOrdersFilterInput = {
  _id?: any | null;
  campus?: FilterFindManyorderCampusInput | null;
  restaurantId?: any | null;
  status?: ReadonlyArray<EnumorderStatus | null> | null;
  userId?: any | null;
};
export type FilterFindManyorderCampusInput = {
  _id?: any | null;
};
export type orderSummaryQuery$variables = {
  filter?: findOrdersFilterInput | null;
};
export type orderSummaryQuery$data = {
  readonly findOrders: ReadonlyArray<{
    readonly _id: any;
    readonly menuItems: ReadonlyArray<{
      readonly addonItem: ReadonlyArray<{
        readonly _id: any | null;
        readonly name: string | null;
        readonly price: number | null;
        readonly quantity: number | null;
      } | null> | null;
      readonly defaultprice: number | null;
      readonly itemId: any;
      readonly itemName: string | null;
      readonly itemVariation: ReadonlyArray<{
        readonly _id: any | null;
        readonly name: string | null;
        readonly price: number | null;
      } | null> | null;
      readonly quantity: number | null;
    } | null> | null;
    readonly orderDate: any;
    readonly priceDetails: {
      readonly _id: any | null;
      readonly subTotal: number | null;
      readonly tax: ReadonlyArray<{
        readonly _id: any | null;
        readonly amount: number | null;
        readonly taxType: EnumorderPriceDetailsTaxTaxType | null;
      } | null> | null;
      readonly total: number | null;
    } | null;
    readonly restaurant: {
      readonly displayName: string;
    } | null;
    readonly tableNo: string | null;
  }>;
};
export type orderSummaryQuery = {
  response: orderSummaryQuery$data;
  variables: orderSummaryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "order",
    "kind": "LinkedField",
    "name": "findOrders",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tableNo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "orderMenuItems",
        "kind": "LinkedField",
        "name": "menuItems",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultprice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "orderMenuItemsItemVariation",
            "kind": "LinkedField",
            "name": "itemVariation",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "orderMenuItemsAddonItem",
            "kind": "LinkedField",
            "name": "addonItem",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "orderPriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subTotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "orderPriceDetailsTax",
            "kind": "LinkedField",
            "name": "tax",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurants2",
        "kind": "LinkedField",
        "name": "restaurant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "orderSummaryQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "orderSummaryQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "8d4a540d3ccd5b374cae4ebbc539ea5c",
    "id": null,
    "metadata": {},
    "name": "orderSummaryQuery",
    "operationKind": "query",
    "text": "query orderSummaryQuery(\n  $filter: findOrdersFilterInput\n) {\n  findOrders(filter: $filter) {\n    _id\n    tableNo\n    orderDate\n    menuItems {\n      itemId\n      quantity\n      defaultprice\n      itemName\n      itemVariation {\n        _id\n        name\n        price\n      }\n      addonItem {\n        _id\n        name\n        price\n        quantity\n      }\n    }\n    priceDetails {\n      _id\n      subTotal\n      total\n      tax {\n        _id\n        taxType\n        amount\n      }\n    }\n    restaurant {\n      displayName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b89108e6e7b2e2d9f21f3a90f73d6b23";

export default node;
