import React from 'react';
import { useWindowDimensions } from 'react-native';
import { useTheme } from 'styled-components/native';
import { imageTransformation, mediaTypes } from '../../../utilities/helper';
import VideoWithThumbnail from '../../../components/new/custom/VideoWithThumbnail';
import { Text, View } from '../../../components/new';
import GradientBackground from '../../../components/new/custom/GradientBackground';
import CachedImageBackground from '../../../components/new/custom/CachedImageBackground';

const VideoOrImageComp = ({
  aspectRatio,
  media,
  widthProp,
  onPress,
  videoRadius,
  imgStyleProps = null,
  title = '',
}) => {
  const { width: screenWidth } = useWindowDimensions();
  const theme = useTheme();

  const gradientColors = [
    { offset: '0', color: theme.colors.gradient[0], opacity: 0 },
    { offset: '1', color: theme.colors.gradient[90], opacity: 1 },
  ];

  return (
    <View>
      {!!(media?.mediaType === mediaTypes.image) && (
        <CachedImageBackground
          style={imgStyleProps || { aspectRatio }}
          source={{
            uri: imageTransformation(media?.mediaURL, screenWidth),
          }}
          imageStyle={{
            resizeMode: 'cover',
          }}
        >
          {title && (
            <View
              flex={1}
              justifyContent="flex-end"
              alignItems="flex-start"
              pb="2xl"
              px="2xl"
            >
              <GradientBackground
                backgroundOpacity={0.6}
                gradientColors={gradientColors}
                flex={1}
                id={media?.mediaURL}
                style={{
                  zindex: 0,
                }}
              />
              <Text
                size="md"
                weight="medium"
                color="static.white"
                numberOfLines={1}
              >
                {title}
              </Text>
            </View>
          )}
        </CachedImageBackground>
      )}
      {!!(media?.mediaType === mediaTypes.video) && (
        <View borderRadius={videoRadius ?? 0} overflow="hidden">
          <VideoWithThumbnail
            aspectRatio={aspectRatio}
            thumbnailWidth={widthProp || screenWidth}
            thumbnailSource={imageTransformation(
              media?.thumbnailURL,
              screenWidth,
              `${screenWidth}-${screenWidth * aspectRatio}`,
            )}
            videoSubTitle={title}
            subtitleSize="md"
            subtitleWeight="medium"
            subTitleColor="static.white"
            videoSource={media?.mediaURL}
            onPress={onPress}
          />
        </View>
      )}
    </View>
  );
};

export default VideoOrImageComp;
