import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  Fade,
} from 'rn-placeholder';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View } from '../../../../../components/new';

const InvestPagePlaceholder = () => {
  const { width } = useWindowDimensions();
  const { top } = useSafeAreaInsets();

  return (
    <Placeholder Animation={Fade}>
      <View px="2xl" pt={top}>
        <View flexDirection="row" justifyContent="space-between" marginTop={20}>
          <View width={40} />
          <PlaceholderMedia isRound size={40} />
        </View>
        <View top={20}>
          <PlaceholderLine
            height={400}
            style={{ width: width - 32, borderRadius: 16 }}
          />
        </View>
        <View top={-360}>
          <PlaceholderLine
            height={400}
            style={{ width: width - 32, borderRadius: 16 }}
          />
        </View>
      </View>
    </Placeholder>
  );
};

export default InvestPagePlaceholder;

const styles = StyleSheet.create({
  avatar: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 100,
    top: -50,
    left: '50%',
    transform: [{ translateX: -50 }],
  },
});
