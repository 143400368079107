import React from 'react';
import { Text, View } from '../../../components/new';
import { imageTransformation } from '../../../utilities/helper';
import VideoWithThumbnail from '../../../components/new/custom/VideoWithThumbnail';
import { colors, radii, space } from '../../../themes/new/theme';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import useUserStore, { UserRole } from '../../../stores/userStore';
import useAuthStore from '../../../stores/authStore';
import GradientBackground from '../../../components/new/custom/GradientBackground';
import CachedImageBackground from '../../../components/new/custom/CachedImageBackground';

const WorkStep = ({ index, title: heading = '', media = [], eventId }) => {
  const { id: userId } = useUserStore((state) => state);
  const { isLoggedIn } = useAuthStore((state) => state);

  const gradientColors = [
    { offset: '0', color: colors.gradient[0], opacity: 0 },
    { offset: '1', color: colors.gradient[90], opacity: 1 },
  ];

  return (
    <View ml={index !== 0 ? '2xl' : 0} width={200} height={158}>
      <View mt="lg" mb="2xl">
        {!!(media[0]?.mediaType === 'image') && (
          <View
            borderRadius={radii.md}
            overflow="hidden"
            width={200}
            height={150}
          >
            <CachedImageBackground
              style={{
                width: 200,
                height: 150,
              }}
              imageStyle={{ resizeMode: 'cover' }}
              source={{
                uri: imageTransformation(media[0]?.mediaURL, 200, '200-150'),
              }}
            >
              <GradientBackground
                backgroundOpacity={0.6}
                gradientColors={gradientColors}
                flex={1}
                id={media?.[0]?._id}
                style={{
                  zindex: 0,
                }}
              />
              <Text
                color="static.white"
                size="sm"
                mt="sm"
                numberOfLines={2}
                weight="medium"
                zIndex={1}
                style={{
                  position: 'absolute',
                  paddingHorizontal: space['2xl'],
                  bottom: space['2xl'],
                }}
              >
                {heading}
              </Text>
            </CachedImageBackground>
          </View>
        )}
        {!!(media[0]?.mediaType === 'video') && (
          <View
            borderRadius="md"
            overflow="hidden"
            alignItems="center"
            width={200}
            backgroundColor="background.secondary.base"
          >
            <VideoWithThumbnail
              aspectRatio={0.75}
              thumbnailWidth={200}
              thumbnailSource={imageTransformation(
                media[0]?.thumbnailURL,
                200,
                `200-150`,
              )}
              videoSource={media[0]?.mediaURL}
              videoSubTitle={heading}
              iconSize="3xl"
              subtitleWeight="medium"
              subTitleColor="static.white"
              onPress={() => {
                firebaseEventLogger('eventsHome__workStep__Tap', {
                  buttonName: 'workStep',
                  screenName: 'eventsHome',
                  userType: UserRole.USER,
                  interactionType: InteractionType.TAP,
                  eventId,
                  userId,
                  isLoggedIn,
                  sectionName: 'Work Step',
                  mediaId: media?.[0]?._id,
                });
              }}
            />
          </View>
        )}
      </View>
    </View>
  );
};

export default WorkStep;
