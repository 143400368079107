import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import { Easing, Platform } from 'react-native';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import BillUploadOverviewScreen from '../screens/billUploadReviewer/billUploadViewerOverview';
import PartnerAccountScreen from '../screens/partner/PartnerAccountScreen';
import OTPInputScreen from '../screens/auth/OTPInputScreen';
import About from '../screens/new/About';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import DayWiseCollectionScreen from '../screens/partner/PartnerOverview/DayWiseCollectionScreen';
import CustomWebview from '../screens/new/CustomWebview';
import BillUploadReviewerTabBar from './billUploadReviewerTabBar';
import BillUploadReviewerHomeScreenWrapper from '../screens/billUploadReviewer/BillUploadReviewerHomeScreen';
import ViewBillScreenWrapper from '../screens/billUploadReviewer/ViewBillScreen';
import BillActionScreen from '../screens/billUploadReviewer/BillActionScreen';

const Stack = createStackNavigator();
const { Navigator, Screen } = createBottomTabNavigator();

const BillUploadReviewerHomeTabBar = () => (
  <Navigator
    // @ts-expect-error TS(2769): No overload matches this call.
    keyboardHidesTabBar="true"
    tabBar={(props) => <BillUploadReviewerTabBar {...props} />}
    screenOptions={{ headerShown: false }}
  >
    <Screen name="home" component={BillUploadReviewerHomeScreenWrapper} />
    <Screen name="overview" component={BillUploadOverviewScreen} />
    <Screen name="billUploadReviewerAccount" component={PartnerAccountScreen} />
  </Navigator>
);

const BillUploadReviewerTabs = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="billUploadReviewer"
        component={BillUploadReviewerHomeTabBar}
      />
      <Stack.Screen name="phoneVerify" component={OTPInputScreen} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen
        name="editProfile"
        options={{ gestureEnabled: false }}
        component={EditProfileScreen}
      />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen
        name="dayWiseCollectionScreen"
        component={DayWiseCollectionScreen}
      />
      <Stack.Screen
        name="ViewBillScreenWrapper"
        component={ViewBillScreenWrapper}
      />
      <Stack.Screen name="BillActionScreen" component={BillActionScreen} />
      <Stack.Screen
        name="CustomWebview"
        component={CustomWebview}
        options={{
          animationTypeForReplace: 'push',
          animationEnabled: true,
          transitionSpec: {
            open: {
              animation: 'timing',
              config: {
                duration: 300,
                easing: Easing.inOut(Easing.ease),
              },
            },
            close: {
              animation: 'timing',
              config: {
                duration: 100,
                easing: Easing.inOut(Easing.ease),
              },
            },
          },
          cardStyleInterpolator:
            Platform.OS === 'android'
              ? CardStyleInterpolators.forRevealFromBottomAndroid
              : CardStyleInterpolators.forVerticalIOS,
        }}
      />
    </Stack.Navigator>
  );
};

export default BillUploadReviewerTabs;
