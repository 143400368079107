/* eslint-disable react/no-array-index-key, no-nested-ternary */
import React, { Suspense, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import moment from 'moment';
import Loading from '../../../components/loading.component';
import { View, Layout, Text, ChipGroup, Card } from '../../../components/new';
import theme from '../../../themes/new/theme';
import useParkingOverview from './useParkingOverview';
import DayPicker from './components/DayPicker';
import MetricsCard from './components/MetricsCard';
import TaskInfoCard from './components/TaskInfoCard';
import TaskTypeDropDown from './components/TaskTypeDropDown';
import SearchParkingList from '../searchParkingList';
import { filterComponentConfig, taskFilter } from './constants';
import { dateFormat } from './utils';
import NewErrorView from '../../../utilities/NewErrorView';
import { RefreshQueryOptions } from './types';
import NewErrorBoundary from '../../../utilities/NewErrorBoundary';

const ParkingOverviewScreen = ({ navigation, queryOptions, refresh }) => {
  const {
    state,
    filteredTasks,
    handleDaySelection,
    setTaskType,
    handleTaskfilterSelection,
    navigateToParkingOverviewScreen,
    findParkingLocation,
  } = useParkingOverview(queryOptions, refresh, navigation);
  const {
    dayPickerData,
    taskType,
    metricsData,
    taskFilter: filter,
    selectedDate,
  } = state;
  const onboarding = metricsData?.onboarding;
  const parking = metricsData?.parking;
  const assignment = metricsData?.assignment;
  const delivery = metricsData?.delivery;
  const isToday =
    selectedDate && moment(selectedDate, dateFormat).isSame(new Date(), 'day');
  const hasFilteredTasks = filteredTasks && filteredTasks.length > 0;
  const noTasksMessage =
    filter === taskFilter.ALL
      ? 'No tasks are available'
      : filter === taskFilter.DELAYED
      ? 'No delayed tasks are available'
      : filter === taskFilter.ON_TIME
      ? 'No on-time tasks are available'
      : '';

  return (
    <>
      <View py="4xl" px="2xl">
        <Text
          size="3xl"
          color="primary.400"
          fontFamily={theme.fonts.headingMedium}
        >
          Overview
        </Text>
      </View>
      {/* DayPicker section */}
      {dayPickerData && (
        <DayPicker
          days={dayPickerData}
          selectedDate={selectedDate}
          onSelect={(date: string) => {
            handleDaySelection(date);
          }}
        />
      )}

      <ScrollView>
        {metricsData && (
          <View px="lg" py="4xl">
            <View flexDirection="row" mb="2xl">
              <MetricsCard
                title={onboarding.label}
                status={onboarding.status}
                score={onboarding.score}
                time={onboarding.time}
              />
              <MetricsCard
                title={parking.label}
                status={parking.status}
                score={parking.score}
                time={parking.time}
              />
            </View>
            <View flexDirection="row">
              <MetricsCard
                title={assignment.label}
                status={assignment.status}
                score={assignment.score}
                time={assignment.time}
              />
              <MetricsCard
                title={delivery.label}
                status={delivery.status}
                score={delivery.score}
                time={delivery.time}
              />
            </View>
          </View>
        )}
        {isToday && (
          <View px="2xl" mb="2xl">
            <Card pt="4xl">
              <SearchParkingList findParkingLocation={findParkingLocation} />
            </Card>
          </View>
        )}
        <View flex={1}>
          <View
            px="2xl"
            py="lg"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text size="md" weight="medium">
              Task Overview
            </Text>
            <View>
              <TaskTypeDropDown
                taskSelected={taskType}
                onSelect={setTaskType}
              />
            </View>
          </View>
          <View py="2xl">
            <ChipGroup
              data={filterComponentConfig}
              selectedIndex={
                filterComponentConfig.find((f) => f.name === filter)?.id
              }
              setSelectedIndex={handleTaskfilterSelection}
            />
          </View>
          <View px="2xl">
            {hasFilteredTasks ? (
              filteredTasks.map((t, i) => <TaskInfoCard key={i} {...t} />)
            ) : (
              <View height="40" alignItems="center" justifyContent="center">
                <Text color="primary.100">{noTasksMessage}</Text>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

const ParkingOverview = ({ navigation }) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<RefreshQueryOptions>({
      fetchKey: 0,
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev: RefreshQueryOptions) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2} edges={['top']}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <ParkingOverviewScreen
            queryOptions={refreshedQueryOptions}
            navigation={navigation}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default ParkingOverview;
