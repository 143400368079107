/**
 * @generated SignedSource<<cf3071a1b5fc86a52d714082f29b1ae2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumInvestmentPropertyUserConsentTCStatus = "GIVEN" | "REVOKED" | "%future added value";
export type addInvestmentUserConsentsInput = {
  propertyId: any;
};
export type InvestmentPageQueryAddInvestmentUserConsentsMutation$variables = {
  record: addInvestmentUserConsentsInput;
};
export type InvestmentPageQueryAddInvestmentUserConsentsMutation$data = {
  readonly addInvestmentUserConsents: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly record: {
      readonly propertyId: any;
      readonly status: EnumInvestmentPropertyUserConsentTCStatus | null;
    } | null;
  } | null;
};
export type InvestmentPageQueryAddInvestmentUserConsentsMutation = {
  response: InvestmentPageQueryAddInvestmentUserConsentsMutation$data;
  variables: InvestmentPageQueryAddInvestmentUserConsentsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "record",
    "variableName": "record"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "InvestmentPropertyUserConsentTC",
  "kind": "LinkedField",
  "name": "record",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "propertyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvestmentPageQueryAddInvestmentUserConsentsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOneInvestmentPropertyUserConsentTCPayload",
        "kind": "LinkedField",
        "name": "addInvestmentUserConsents",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvestmentPageQueryAddInvestmentUserConsentsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOneInvestmentPropertyUserConsentTCPayload",
        "kind": "LinkedField",
        "name": "addInvestmentUserConsents",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2fd100ab60a3d1fead97faecc48348f",
    "id": null,
    "metadata": {},
    "name": "InvestmentPageQueryAddInvestmentUserConsentsMutation",
    "operationKind": "mutation",
    "text": "mutation InvestmentPageQueryAddInvestmentUserConsentsMutation(\n  $record: addInvestmentUserConsentsInput!\n) {\n  addInvestmentUserConsents(record: $record) {\n    record {\n      propertyId\n      status\n    }\n    error {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9860b36a2c4cad52626b38ea208b992c";

export default node;
