import styled, { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import { capitalize } from 'lodash';
import { SafeAreaView } from 'react-native-safe-area-context';
// import { BlurView } from 'expo-blur';
import { Platform } from 'react-native';
import View from '../../basic/View/View';
import Icon from '../Icon/Icon';
import Text from '../Typography/Text/Text';
import IconButton from '../IconButton/IconButton';
import { SnackbarStatus, SnackbarVersion } from './helpers/helpers';
// import BlurView from '../../basic/BlurView/BlurView';

import { getTheme } from '../../../../themes/new/theme';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import BlurView from '../../basic/BlurView/BlurView';
import { Appearance, Directions } from '../../../../themes/new/helper';

// these are attribute value to pass  #START
// ===================================================
const StyledIconNames = {
  [SnackbarStatus.error]: {
    name: 'warning-outline-400',
  },
  [SnackbarStatus.info]: {
    name: 'info-outline-300',
  },
  [SnackbarStatus.success]: {
    name: 'doubletick-400',
  },
  [SnackbarStatus.warning]: {
    name: 'hazard-outline-300',
  },
};

// these are attribute value to pass  #END
// ===================================================

const StyledView = styled(View)`
  background: ${({ theme, status }) =>
    status === SnackbarStatus.info
      ? theme.colors.background.secondary.elevation
      : theme.colors[status][10]};
  justify-content: space-between;
`;

const StyledTextPrefix = styled(Text)`
  color: ${({ theme, status }) =>
    status === SnackbarStatus.info
      ? theme.colors.primary[500]
      : theme.colors[status][500]};
`;

const StyledTextMsg = styled(Text)`
  color: ${({ theme, status }) => theme.colors.primary[500]};
`;

const StyledIcon = styled(Icon).attrs(({ theme, status }) => ({
  name: StyledIconNames[status].name,
  color: status === SnackbarStatus.info ? 'primary.500' : `${status}.500`,
}))``;

const StyledIconButton = styled(IconButton).attrs(({ theme, status }) => ({
  iconColor: 'primary.200',
}))``;

const SnackBar = () => {
  const { snackbarData, clearCurrentSnackbar } = useSnackbarStore(
    (state) => state,
  );

  if (!snackbarData) {
    return null;
  }

  const {
    status = SnackbarStatus.info,
    version = SnackbarVersion.v1,
    msg = '',
    position = Directions.BOTTOM,
    styleProps,
  } = snackbarData;

  const localProps = {};
  if (position === Directions.BOTTOM) {
    localProps.bottom = '9xl+5xl';
  }
  if (position === Directions.TOP) {
    localProps.top = 'sm+md';
  }

  const getVersionNumber = () => {
    if (version === SnackbarVersion.v2) {
      return 2;
    }
    if (version === SnackbarVersion.v3) {
      return 3;
    }
    return 1;
  };

  return (
    <View
      position="absolute"
      left={0}
      right={0}
      px="2xl"
      {...localProps}
      {...styleProps}
    >
      <SafeAreaView
        edges={[Directions.TOP, Directions.BOTTOM]}
        style={{ flex: 1 }}
      >
        <View
          style={{ borderRadius: 12 }}
          shadow={Platform.OS !== 'android' ? 'md' : ''}
        >
          <ThemeConsumer>
            {(theme) => {
              return (
                <ThemeProvider theme={theme}>
                  <BlurView
                    level={1}
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                      borderWidth: getTheme().borderWidths.xs,
                      borderRadius: 12,
                      borderColor: getTheme().colors.primary[20],
                    }}
                  >
                    <View>
                      <StyledView py="md" flexDirection="row" status={status}>
                        <View
                          justifyContent="center"
                          alignItems="center"
                          mr="xl"
                          ml="2xl"
                        >
                          <StyledIcon
                            appearance={Appearance.GHOST}
                            size="2xl"
                            status={status}
                          />
                        </View>
                        <View
                          alignItems="center"
                          flexDirection="row"
                          flex={1}
                          // maxWidth={242}
                          mr="sm+md"
                        >
                          <StyledTextMsg
                            numberOfLines={getVersionNumber()}
                            ellipsizeMode="tail"
                            status={status}
                          >
                            {version !== SnackbarVersion.v3 && (
                              <StyledTextPrefix
                                size="sm"
                                status={status}
                                textAlign={Directions.LEFT}
                                align={Directions.LEFT}
                              >
                                {`${capitalize(status)}! `}
                              </StyledTextPrefix>
                            )}
                            <StyledTextMsg
                              size="sm"
                              status={status}
                              textAlign={Directions.LEFT}
                              align={Directions.LEFT}
                            >
                              {msg}
                            </StyledTextMsg>
                          </StyledTextMsg>
                        </View>
                        <View
                          justifyContent="center"
                          alignItems="center"
                          mr="2xl"
                        >
                          <StyledIconButton
                            name="close-outline2-400"
                            appearance={Appearance.GHOST}
                            iconSize="xl"
                            onPress={() => clearCurrentSnackbar()}
                          />
                        </View>
                      </StyledView>
                    </View>
                  </BlurView>
                </ThemeProvider>
              );
            }}
          </ThemeConsumer>
        </View>
      </SafeAreaView>
    </View>
  );
};

export default SnackBar;
