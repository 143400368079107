/**
 * @generated SignedSource<<88a3ab3b7bdbe2b826ae6c7851733036>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnumbillUploadFindBillTCStatus = "CREATED" | "MATCHED" | "PROCESSED" | "PROCESSING" | "PROCESSING_FAILED" | "UNMATCHED" | "UNVERIFIED" | "VERIFICATION_FAILED" | "VERIFIED" | "%future added value";
export type EnumbillUploadFindBillTCUserInputsOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type billUploadReviewerHomeScreenQuery__findBillsFragment$data = {
  readonly findBills: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly created_at: any | null;
        readonly ocrResponseParsed: {
          readonly bill: {
            readonly billId: string | null;
            readonly date: any | null;
          } | null;
        } | null;
        readonly restaurant: {
          readonly displayName: string;
        } | null;
        readonly status: EnumbillUploadFindBillTCStatus | null;
        readonly user: {
          readonly contact: {
            readonly phone: {
              readonly number: string;
              readonly prefix: string;
            };
          };
        } | null;
        readonly userId: any;
        readonly userInputs: {
          readonly billImage: {
            readonly bucket: string;
            readonly key: string;
          } | null;
          readonly orderId: any;
          readonly orderType: EnumbillUploadFindBillTCUserInputsOrderType;
          readonly priceDetails: {
            readonly netAmount: number;
          } | null;
        } | null;
      };
    }>;
  } | null;
  readonly " $fragmentType": "billUploadReviewerHomeScreenQuery__findBillsFragment";
};
export type billUploadReviewerHomeScreenQuery__findBillsFragment$key = {
  readonly " $data"?: billUploadReviewerHomeScreenQuery__findBillsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"billUploadReviewerHomeScreenQuery__findBillsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "findBills"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./billUploadReviewerHomeScreenQuery__findBillsFragmentQuery.graphql')
    }
  },
  "name": "billUploadReviewerHomeScreenQuery__findBillsFragment",
  "selections": [
    {
      "alias": "findBills",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "billUploadFindBillTCConnection",
      "kind": "LinkedField",
      "name": "__billUploadReviewerHomeScreenQuery__findBillsFragmentQuery__findBills_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "billUploadFindBillTCEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "billUploadFindBillTC",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "billUploadFindBillTCUserInputs",
                  "kind": "LinkedField",
                  "name": "userInputs",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "orderType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "orderId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "billUploadFindBillTCUserInputsPriceDetails",
                      "kind": "LinkedField",
                      "name": "priceDetails",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "netAmount",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "billUploadFindBillTCUserInputsBillImage",
                      "kind": "LinkedField",
                      "name": "billImage",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bucket",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "key",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserContact",
                      "kind": "LinkedField",
                      "name": "contact",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserContactPhone",
                          "kind": "LinkedField",
                          "name": "phone",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "prefix",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "number",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "billUploadFindBillTCOcrResponseParsed",
                  "kind": "LinkedField",
                  "name": "ocrResponseParsed",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "billUploadFindBillTCOcrResponseParsedBill",
                      "kind": "LinkedField",
                      "name": "bill",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "date",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "restaurants2",
                  "kind": "LinkedField",
                  "name": "restaurant",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "50170cde7983e083388a78e2ef026d7a";

export default node;
