import React, { Suspense, useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import _ from 'lodash';
import { useTheme } from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import {
  View,
  Text,
  Layout,
  IconButton,
  Pressable,
  Icon,
} from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import ShimmerPlaceholder from '../shimmerPlaceholders/screens/PointsScreenPlaceholder';
import useUserStore from '../../../stores/userStore';
import {
  DateFormatsEnum,
  getFormatedDate,
  navigateBack,
} from '../../../utilities/helper';
import useCommonStore, { faqData } from '../../../stores/commonStore';
import PointsCard from '../../../components/new/custom/PointsCard';
import CustomSectionList from '../../../components/new/custom/CustomSectionList';
import { getTheme } from '../../../themes/new/theme';
import FaqBottomSheet from './FaqBottomSheet';
import { pointsScreenQuery } from './pointsScreenQuery';
import PointsListItem from './PointListItem';
import { FontWeights } from '../../../themes/new/helper';
import LightThemeWrapper from '../wrapperComp/LightThemeWrapper';
import CachedImageBackground from '../../../components/new/custom/CachedImageBackground';
import ParallaxAnimationPoints from './ParallaxAnimationPoints';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';

const RenderPoints = (props) => {
  const { variables, refreshedQueryOptions, backToHome } = props;
  const { role } = useUserStore((state) => state);
  const { setFaqScreenData: setFaqData, setFaqBottomSheetOpen } =
    useCommonStore((state) => state);
  const { top } = useSafeAreaInsets();
  const theme = useTheme();
  const { findUserById, getWalletHistory } = useLazyLoadQuery(
    pointsScreenQuery,
    variables,
    {
      ...refreshedQueryOptions,
      networkCacheConfig: { force: true },
    },
  );
  const navigation = useNavigation<any>();
  const { blackMembership } = useUserStore((state) => state);

  const { currentBalance, earnedAmount } = findUserById.wallet ?? 0;

  const [sectionResponse, setSectionResponse] = useState([]);

  useEffect(() => {
    const dayObj = {};
    if (getWalletHistory?.length) {
      getWalletHistory.forEach((item) => {
        const eachItem = { ...item };
        const selectedDate = getFormatedDate(
          new Date(eachItem?.created_at),
          DateFormatsEnum.MonthYear,
        );
        const j = selectedDate;
        if (!dayObj[j]) {
          dayObj[j] = { data: [eachItem] };
        } else {
          dayObj[j].data.push(eachItem);
        }
      });
      const resData = _.isEmpty(dayObj) ? [] : Object.values(dayObj);
      setSectionResponse(resData);
    }
  }, [findUserById, getWalletHistory]);

  useEffect(() => {
    firebaseEventLogger('pointsScreen__points_landing', {
      screen: 'pointsScreen',
      buttonName: 'points',
      interactionType: InteractionType.LANDING,
    });
  }, []);

  const RenderBlackMemberShipCard = () => {
    return (
      <Pressable
        onPress={() => {
          firebaseEventLogger('pointsScreen__saveMore_Tap', {
            screen: 'pointsScreen',
            buttonName: 'saveMore',
            interactionType: InteractionType.TAP,
          });
          navigation.navigate('blackMembershipScreen');
        }}
      >
        <CachedImageBackground
          source={require('../../../../assets/images/black_membership/mesh-bg-h.png')}
          imageStyle={{
            borderRadius: getTheme().radii.lg,
            borderColor: getTheme(theme?.currentThemeMode).colors.primary[50],
            borderWidth: getTheme().borderWidths.xs,
            resizeMode: 'cover',
          }}
          style={{ marginTop: getTheme().space['2xl'] }}
        >
          <View
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            px="2xl"
            py="lg"
          >
            <Text size="xs" color="static.white" mr="sm">
              {blackMembership?.active
                ? 'Save more with your 32nd Plus membership'
                : 'Earn upto ₹500 points on every visit to 32nd'}
            </Text>
            <Icon
              name="keyboard-arrow-right-300"
              size="xl"
              color="static.white"
            />
          </View>
        </CachedImageBackground>
      </Pressable>
    );
  };

  const RenderBottomContent = () => (
    <Pressable
      onPress={() => {
        navigation.navigate('blackMembershipScreen');
      }}
    >
      <CachedImageBackground
        source={require('../../../../assets/images/black_membership/mesh-bg-h.png')}
        imageStyle={{
          borderColor: getTheme(theme?.currentThemeMode).colors.primary[50],
          borderTopWidth: getTheme().borderWidths.xs,
          borderBottomWidth: getTheme().borderWidths.xs,
          resizeMode: 'cover',
        }}
      >
        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          px="2xl"
          py="lg"
        >
          <Text size="xs" color="static.white" mr="sm">
            {blackMembership?.active
              ? 'Save more with your 32nd Plus membership'
              : 'Earn upto ₹500 points on every visit to 32nd'}
          </Text>
          <Icon
            name="keyboard-arrow-right-300"
            size="xl"
            color="static.white"
          />
        </View>
      </CachedImageBackground>
    </Pressable>
  );

  const backActionHandler = () => {
    if (backToHome) {
      if (Platform.OS === 'web') {
        navigation.navigate(role);
      } else {
        navigation.popToTop();
      }
    } else {
      navigateBack(navigation, role);
    }
  };

  const RenderAnimationContent = () => (
    <View px="2xl" mt={top + 60}>
      <LightThemeWrapper>
        <PointsCard currentBalance={currentBalance} moneySaved={earnedAmount} />
      </LightThemeWrapper>
      {RenderBlackMemberShipCard()}
    </View>
  );

  return (
    <ParallaxAnimationPoints
      minHeight={0}
      onPressIcon={backActionHandler}
      leftIconName="back-outline-300"
      iconRight={
        <IconButton
          name="info-outline-300"
          size="sm"
          appearance="ghost"
          iconColor="primary.500"
          onPress={() => {
            firebaseEventLogger('pointsScreen__info_Tap', {
              screen: 'pointsScreen',
              buttonName: 'info',
              interactionType: InteractionType.TAP,
            });
            setFaqData(faqData);
            setFaqBottomSheetOpen(true);
          }}
        />
      }
      AnimationContent={RenderAnimationContent()}
      bottomContent={RenderBottomContent()}
    >
      <View bg="background.primary.base">
        {sectionResponse?.length ? (
          <CustomSectionList
            sections={sectionResponse}
            showsVerticalScrollIndicator={false}
            windowSize={5}
            stickySectionHeadersEnabled={false}
            renderItem={({ item }) => {
              return (
                <PointsListItem
                  item={item}
                  title={item?.source?.name}
                  isAvailable={item?.isAvailable}
                  subTitle={`${getFormatedDate(
                    item?.created_at,
                    DateFormatsEnum.DateMonthYear,
                  )} | ${getFormatedDate(
                    item?.created_at,
                    DateFormatsEnum.TIME,
                  )}`}
                />
              );
            }}
            renderSectionHeader={({ section: { data } }) => {
              const dateData = data?.[0];
              const selecteddate = getFormatedDate(
                new Date(dateData?.created_at),
                DateFormatsEnum.MonthYear,
              );
              return (
                <View pb="2xl" px="2xl">
                  <Text
                    size="sm"
                    color="primary.500"
                    weight={FontWeights.MEDIUM}
                  >
                    {selecteddate}
                  </Text>
                </View>
              );
            }}
          />
        ) : null}
      </View>
    </ParallaxAnimationPoints>
  );
};

const PointsScreen = ({ route }) => {
  const { backToHome } = route?.params ?? {};
  const { id } = useUserStore((state) => state);
  const { top } = useSafeAreaInsets();

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense
          fallback={
            <View mt={top + 60}>
              <ShimmerPlaceholder />
            </View>
          }
        >
          <RenderPoints
            variables={{
              // filter: { type: 'CREDIT' },
              groupSTW: true,
              sort: 'DESC',
              id,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            refreshedQueryOptions={refreshedQueryOptions}
            backToHome={backToHome}
          />
        </Suspense>
      </NewErrorBoundary>
      <FaqBottomSheet />
    </Layout>
  );
};

export default PointsScreen;
