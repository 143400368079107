import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const endTaskScreenQuery = graphql`
  query endTaskScreenQuery($filter: findStaffRewardsFilterInput) {
    findStaffRewards(filter: $filter) {
      _id
      value
      task {
        taskId
        taskStatus
      }
    }
  }
`;
