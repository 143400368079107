import React, { useEffect, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Layout, Text, View } from '../../components/new';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

const SentToAuditorScreen = (props) => {
  const navigation = useNavigation<any>();
  const [timerValue, setTimerValue] = useState(5); // 5 seconds timer
  const animation = useRef(null);

  const navigateToHome = () => {
    navigation.popToTop();
  };

  // ------ updating timer after every 1 sec for ------
  useEffect(() => {
    const interval = 1000;
    const timer = setInterval(() => {
      if (timerValue > 0) {
        setTimerValue(timerValue - 1);
      }
      if (timerValue <= 0) {
        navigateToHome();
      }
    }, interval);
    return () => {
      // Useful for cleanup functions
      clearInterval(timer);
    };
  }, [timerValue]);

  const RedirectComponent = () => {
    return (
      <Text size="sm" color="primary.100" my="9xl" textAlign="center">
        Redirecting in {timerValue}s
      </Text>
    );
  };

  return (
    <View flex={1} bg="primary.10" alignItems="center" justifyContent="center">
      <CustomLottieView
        loop
        autoPlay
        ref={animation}
        source={require('../../../assets/lottie/send.json')}
        style={{
          width: 150,
          height: 150,
        }}
        containerWidth={150}
      />
      <Text size="3xl" color="primary.500" textAlign="center">
        Sent to Auditor
      </Text>
      <View
        position="absolute"
        bottom="4xl"
        alingItems="center"
        justifyContent="center"
      >
        <RedirectComponent />
      </View>
    </View>
  );
};

const SentToAuditorScreenWrapper = () => {
  const [netStatus, setNetStatus] = useState(true);

  return (
    <Layout level={2}>
      <SentToAuditorScreen />
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};
export default SentToAuditorScreenWrapper;
