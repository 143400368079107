import React from 'react';
import { PlaceholderLine, PlaceholderMedia } from 'rn-placeholder';
import { View } from '../../../../components/new';

const RewardItemPlaceholder = () => {
  return (
    <View
      bg="primary.20"
      shadow="sm"
      borderRadius="md"
      p="2xl"
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        width: 140,
        marginBottom: 16,
      }}
    >
      <PlaceholderMedia style={{ marginBottom: 30, width: 90, height: 80 }} />
      <PlaceholderLine width={70} height={10} style={{ marginBottom: 12 }} />
      <PlaceholderLine width={90} height={10} style={{ marginBottom: 8 }} />
    </View>
  );
};

export default RewardItemPlaceholder;
