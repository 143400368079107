/* eslint-disable no-nested-ternary */
import React, { forwardRef, useState, useCallback, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-actions-sheet';
import { View, Text, Divider, Button } from '../../../components/new';
import { getOptions } from '../../../utilities/helper';
import useBoundStore from '../../../stores';
import VariationsGroup from './VariationsGroup.component';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';

const VariationsBottomSheet = forwardRef((props: any, ref) => {
  const {
    removeItem,
    selectedMenuItem,
    setSelectedMenuItem,
    AddButton,
    setRemoveItem,
    ctaText,
    repeatLast,
    setRepeatLast,
    restaurantId = '',
    setVisible = () => {},
    // restaurantName = '',
    restaurant,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [lastItem, setLastItem] = useState([]);

  const cart = useBoundStore((state) => state.cart);
  const addToCart = useBoundStore((state) => state.addToCart);
  const removeFromCart = useBoundStore((state) => state.removeFromCart);
  const updateCart = useBoundStore((state) => state.updateCart);
  // const setOrderDetails = useBoundStore((state) => state.setOrderDetails);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const setOtherItem = useBoundStore((state) => state.setOtherItem);

  useEffect(() => {
    if (repeatLast) {
      const items = cart.filter((item) => item.id === selectedMenuItem?.id);
      items.sort((a, b) => b.timestamp - a.timestamp);
      setLastItem(items[0]);
    }
  }, [selectedMenuItem]);

  const { height } = useWindowDimensions();

  // const getCount = () => {
  //   return cart.reduce((prev, curr) => {
  //     return prev + curr.quantity;
  //   }, 0);
  // };

  const getPrice = (options) => {
    let price = 0;
    // if only addon available, no variations then add defaultPrice
    if (selectedMenuItem?.itemPrice.length) {
      const variationsArr = selectedMenuItem.itemPrice.filter(
        (item) => item.type === 'variation',
      );
      if (variationsArr.length === 0) {
        price = selectedMenuItem.defaultPrice;
        // console.log('added defaultPrice');
      }
    }
    price += options.reduce((prev, curr) => {
      return prev + Number(curr.label);
    }, 0);
    return price;
  };

  const lastHandler = async () => {
    addToCart({
      id: lastItem.id,
      title: lastItem.title,
      itemPrice: lastItem.itemPrice,
      description: lastItem.description,
      options: lastItem.options,
      defaultPrice: lastItem.defaultPrice,
      tax: lastItem.tax,
      restaurant: lastItem.restaurant,
      timestamp: Date.now(),
      // restaurantId,
      // restaurantName,
      posId: lastItem.posId,
    });
    ref?.current?.hide();
  };

  const newHandler = () => {
    setRepeatLast(false);
    setSelectedMenuItem(selectedMenuItem.new);
  };

  const handleSheetChanges = useCallback((index: number) => {
    if (index < 1) {
      setSelectedOptions([]);
    }
  }, []);

  const optionsHandler = () => {
    const options = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const opt in selectedOptions) {
      if (Object.prototype.hasOwnProperty.call(selectedOptions, opt)) {
        const selectedArr = selectedOptions[opt].selected;
        if (selectedArr.length) {
          // eslint-disable-next-line no-restricted-syntax
          for (const element of selectedArr) {
            element.type = selectedOptions[opt].type;
            element.category = selectedOptions[opt].category;
            options.push(element);
          }
        }
      }
    }
    return options;
  };

  const pressHandler = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const opt in selectedOptions) {
      if (Object.prototype.hasOwnProperty.call(selectedOptions, opt)) {
        const selectedArr = selectedOptions[opt].selected;
        const { minSelect, maxSelect } = selectedOptions[opt];
        if (minSelect !== 0 && selectedArr.length < minSelect) {
          dispatchSnackbar({
            msg: `Select atleast ${minSelect}-${maxSelect} options from ${opt}`,
            status: SnackbarStatus.info,
            version: SnackbarVersion.v2,
          });
          return;
        }
      }
    }

    const options = optionsHandler();
    if (ctaText === 'Add Item') {
      for (let index = 0; index < cart.length; index += 1) {
        if (cart[index].restaurant?._id !== restaurantId) {
          ref?.current?.hide();
          setOtherItem({
            id: selectedMenuItem._id,
            title: selectedMenuItem.title,
            itemPrice: selectedMenuItem.itemPrice,
            description: selectedMenuItem.description,
            // options: selectedOptions,
            options,
            defaultPrice: selectedMenuItem.defaultPrice,
            tax: selectedMenuItem.tax || {},
            timestamp: Date.now(),
            // restaurantId,
            // restaurantName,
            restaurant,
            posId: selectedMenuItem.posId,
          });
          setVisible(true);
          return;
        }
      }
      addToCart({
        id: selectedMenuItem._id,
        title: selectedMenuItem.title,
        itemPrice: selectedMenuItem.itemPrice,
        description: selectedMenuItem.description,
        // options: selectedOptions,
        options,
        defaultPrice: selectedMenuItem.defaultPrice,
        tax: selectedMenuItem.tax || {},
        timestamp: Date.now(),
        // restaurantId,
        // restaurantName,
        restaurant,
        posId: selectedMenuItem.posId,
      });
      // setOrderDetails({
      //   restaurantId,
      //   restaurantName,
      //   restaurant,
      // });
      ref?.current?.hide();
    }

    if (ctaText === 'Update Item') {
      updateCart({
        id: selectedMenuItem.id,
        title: selectedMenuItem.title,
        itemPrice: selectedMenuItem.itemPrice,
        description: selectedMenuItem.description,
        quantity: selectedMenuItem.quantity,
        index: selectedMenuItem.index,
        // options: selectedOptions,
        options,
        defaultPrice: selectedMenuItem.defaultPrice,
        tax: selectedMenuItem.tax || {},
        timestamp: Date.now(),
        // restaurantId: selectedMenuItem.restaurantId,
        // restaurantName: selectedMenuItem.restaurantName,
        restaurant: selectedMenuItem.restaurant,
        posId: selectedMenuItem.posId,
      });
      ref?.current?.hide();
    }
  };

  return (
    <BottomSheetV2 ref={ref} onChange={handleSheetChanges} insets={false}>
      <View>
        {removeItem ? (
          <ScrollView style={{ maxHeight: height * 0.65 }}>
            <View px="2xl" pb="4xl">
              <View>
                <Text size="xl" color="primary.200">
                  Edit Items
                </Text>
                <View mt="lg" mb="2xl">
                  <Divider />
                </View>
              </View>
              <View mt="xs">
                {cart
                  .filter((item) => item.id === selectedMenuItem._id)
                  .map((item, index) => {
                    return (
                      <View
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        // mt="2xl"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <View flex={3}>
                          <View>
                            <Text size="md" weight="medium">
                              {item.title}
                            </Text>
                            <Text
                              size="xs"
                              color="primary.200"
                              numberOfLines={3}
                              ellipsizeMode="tail"
                            >
                              {item.options.length
                                ? getOptions(item.options)
                                : item.description.summary}
                            </Text>
                            <Text
                              size="md"
                              color="primary.300"
                              weight="medium"
                              mt="lg"
                              mb="2xl"
                            >
                              {/* &#8377; {item.itemPrice[0].price} */}
                              &#8377; {getPrice(item.options)}
                            </Text>
                          </View>
                        </View>
                        <View flex={2} alignItems="flex-end">
                          <AddButton
                            quantity={item.quantity}
                            menuItem={item}
                            setSelectedMenuItem={setSelectedMenuItem}
                            ref={ref}
                            addToCart={addToCart}
                            removeFromCart={removeFromCart}
                            setRemoveItem={setRemoveItem}
                            isEdit
                            btnState
                            cart={cart}
                            setVisible={setVisible}
                          />
                        </View>
                      </View>
                    );
                  })}
              </View>
            </View>
          </ScrollView>
        ) : repeatLast ? (
          <View px="2xl">
            <Text size="xl" color="primary.200" mb="lg">
              Repeat Last Customization?
            </Text>
            <View mb="3xl">
              <Divider />
            </View>
            <View mb="4xl">
              <Text size="lg" color="primary.500" weight="medium" mb="lg">
                {selectedMenuItem?.title}
              </Text>
              {/* <Text numberOfLines={3}>
                {lastItem && lastItem?.options && lastItem.options.length
                  ? lastItem.options.map((item, index) => {
                      return (
                        <>
                          <Text size="sm" color="primary.200">
                            <Text weight="medium" color="primary.200">
                              {item.category}
                            </Text>{' '}
                            : {item.name}
                            {index < lastItem.options.length - 1 ? ', ' : ''}
                          </Text>
                        </>
                      );
                    })
                  : null}
              </Text> */}
              <Text numberOfLines={3} color="primary.200" size="sm">
                {lastItem && lastItem?.options && lastItem.options.length
                  ? getOptions(lastItem.options)
                  : null}
              </Text>
            </View>
            <View>
              <Divider />
            </View>
            <View flexDirection="row" my="2xl">
              <View flex={1} mr="md">
                <Button
                  size="lg"
                  state=""
                  appearance="outline"
                  iconLeft=""
                  iconRight=""
                  onPress={newHandler}
                >
                  Add New
                </Button>
              </View>
              <View flex={1} ml="md">
                <Button
                  size="lg"
                  state=""
                  appearance="filled"
                  iconLeft=""
                  iconRight=""
                  onPress={lastHandler}
                >
                  Repeat Last
                </Button>
              </View>
            </View>
          </View>
        ) : (
          <>
            <ScrollView style={{ maxHeight: height * 0.65 }}>
              <View pl="3xl" pr="xl">
                <Text size="lg" mt="lg">
                  {selectedMenuItem?.title}
                </Text>
                <Text size="sm" color="primary.100" mt="lg">
                  {selectedMenuItem?.description.summary}
                </Text>
                <View mt="3xl" mb="2xl">
                  <Divider />
                </View>
                {selectedMenuItem?.itemPrice.map((options) => (
                  <View mb="4xl">
                    <VariationsGroup
                      key={options._id}
                      options={options}
                      setSelectedOptions={setSelectedOptions}
                      selectedMenuItem={selectedMenuItem}
                    />
                  </View>
                ))}
              </View>
            </ScrollView>
            <View>
              <Divider />
              <View
                p="2xl"
                pt="xl"
                flexDirection="row"
                alignItems="center"
                shadow="sm"
              >
                <View flex={1}>
                  <Text size="xl" weight="medium" color="primary.400">
                    &#8377; {getPrice(optionsHandler())}
                  </Text>
                  {/* <Text size="sm" color="primary.200">
                    {getCount() === 1
                      ? `${getCount()} Item`
                      : `${getCount()} Items`}
                  </Text> */}
                  <Text size="xs" color="primary.200">
                    Total Amount
                  </Text>
                </View>
                <View flex={1}>
                  <Button
                    size="lg"
                    state=""
                    appearance="filled"
                    iconLeft=""
                    iconRight=""
                    onPress={pressHandler}
                  >
                    {ctaText}
                  </Button>
                </View>
              </View>
            </View>
          </>
        )}
      </View>
    </BottomSheetV2>
  );
});

export default VariationsBottomSheet;
