import React, { useEffect } from 'react';
import { Icon, Text, View } from '../../../../components/new';
import useCountdown from '../../../../utilities/customHooks/useCountDown';
import RecallStatusInfo from './components/RecallStatusInfo';
import CommonLayout from './components/CommonLayout';
import BackButton from './components/BackButton';
import HiddenInput from './components/HiddenInput';
import { kiosk } from '../../../../utilities/Constant';

interface ParamsProps {
  isFreshRecall: boolean;
  parking: {
    ownerContact: string;
    isClubMember?: boolean;
    arrivalTime?: string;
  };
}

const { SESSION_TIMEOUT, VALET_DESK_NO } = kiosk;

const Divider = () => (
  <View flexGrow="1" justifyContent="center">
    {/* @ts-ignore */}
    <View borderTopWidth="xs" borderColor="primary.200" />
  </View>
);

const NoCarFoundScreen = ({ navigation, route }) => {
  const { parking }: ParamsProps = route.params;
  const { ownerContact } = parking;
  const { countdown, startCountdown } = useCountdown(SESSION_TIMEOUT, {
    onComplete: () => {
      navigateToHomeScreen();
    },
  });

  useEffect(() => {
    startCountdown();
  }, []);

  const navigateToHomeScreen = () => {
    navigation.replace('kioskRecall');
  };

  return (
    <CommonLayout>
      <HiddenInput />
      <View
        flex={1}
        p="9xl"
        width="100%"
        justifyContent="space-between"
        overflow="hidden"
      >
        <View>
          <View>
            <View position="absolute" top={0} left={1}>
              <BackButton onBack={navigateToHomeScreen} />
            </View>
            <View alignSelf="center">
              <RecallStatusInfo
                status="warning"
                icon="cancel_filled"
                textPri="No Car Found"
                textSec={`Desk contact details sent to ${ownerContact}`}
                showBorder
              />
            </View>
          </View>
          {/* CENTER CONTENT */}
          <View mt="9xl+9xl" alignItems="center">
            <Text
              mb="4xl"
              textAlign="center"
              size="3xl"
              weight="regular"
              color="primary.100"
            >
              Apologies for the inconvenience
            </Text>
            <Text
              textAlign="center"
              size="5xl"
              weight="regular"
              color="primary.500"
            >
              Reach out to the valet desk for further assistance
            </Text>
            {/* DIVIDER */}
            <View
              mt="9xl+3xl"
              mb="9xl+9xl"
              width="100%"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Divider />
              <View px={80}>
                <Text
                  textAlign="center"
                  size="2xl"
                  weight="medium"
                  color="primary.200"
                >
                  OR
                </Text>
              </View>
              <Divider />
            </View>
            <View
              mb="2xl"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Icon name="phonecall_outline" size="6xl" color="primary.100" />
              <Text
                pl="sm"
                textAlign="center"
                size="3xl"
                weight="regular"
                color="primary.100"
              >
                Call Valet Desk
              </Text>
            </View>
            <Text
              textAlign="center"
              size="5xl"
              weight="regular"
              color="primary.500"
            >
              +91-{VALET_DESK_NO}
            </Text>
          </View>
        </View>
        <View mt="4xl">
          <Text
            textAlign="center"
            size="2xl"
            weight="regular"
            color="primary.300"
          >
            Session ending in {countdown}s
          </Text>
        </View>
      </View>
    </CommonLayout>
  );
};

export default NoCarFoundScreen;
