import * as React from 'react';
import { LayoutAnimation } from 'react-native';
import { View, Text, Divider, Button } from '../../../../components/new';
import { updateTaskForSupervisor } from '../../../../relay/taskApi';
import useTaskStore from '../../../../stores/taskStore';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { taskHistoryActionTypes } from '../../../../utilities/helper';
import { updateParking } from '../../../../relay/parkingApi';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';

const markExitRecalledTask = (task, callBack) => {
  const parkingData = { record: { status: 'OUT' }, id: task.parkingId };
  updateParking(
    parkingData,
    (response) => {
      callBack(task);
    },
    (err) => {
      // console.log(err);
    },
  );
};

const tempData = [
  { name: 'Customer denied pick up' },
  { name: 'Customer is not coming' },
  {
    name: 'Customer is not reachable',
  },
  { name: 'Other reason' },
];

const MarkExitConfirmation = ({
  bottomSheetModalRef,
  handleOnDismiss,
  taskReadyToDelete,
  isSupervisor,
}) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { arrivedTasks, assignedTasks, setArrivedTasks, setAssignedTasks } =
    useTaskStore((state) => state);
  const layoutAnimConfig = {
    duration: 3000,
    update: {
      type: LayoutAnimation.Types.easeInEaseOut,
    },
    delete: {
      duration: 1000,
      type: LayoutAnimation.Types.easeInEaseOut,
      property: LayoutAnimation.Properties.opacity,
    },
  };

  const removeItem = (id) => {
    if (isSupervisor) {
      const arr = arrivedTasks.filter((item) => item._id !== id);
      setArrivedTasks({ data: arr });
    } else {
      const arr = assignedTasks.filter((item) => item._id !== id);
      setAssignedTasks({ data: arr });
    }
    // after removing the item, we start animation
    LayoutAnimation.configureNext(layoutAnimConfig);
  };
  const handleUpdateTask = () => {
    if (taskReadyToDelete && taskReadyToDelete?.action !== 'completed') {
      const data = {
        _id: taskReadyToDelete?._id,
        record: {
          history: { action: taskHistoryActionTypes.completed },
        },
      };
      updateTaskForSupervisor(
        data,
        (response, error) => {
          // refresh();
          if (response) {
            dispatchSnackbar({
              msg: `Car marked exited`,
              status: SnackbarStatus.success,
              version: SnackbarVersion.v2,
            });
            removeItem(taskReadyToDelete?._id);
          }
        },
        (error) => {
          // console.log(error);
        },
      );
    }
  };
  return (
    <View>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Mark Exited
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View mt="3xl" px="2xl">
            <Text size="md" color="primary.400">
              Are you sure you want to mark this car as exited?
            </Text>
          </View>
          <View px="2xl" mt="4xl" flexDirection="row" alignItems="center">
            <View flex={1}>
              <Button
                size="lg"
                appearance="outline"
                onPress={() => {
                  bottomSheetModalRef.current?.hide();
                }}
              >
                Cancel
              </Button>
            </View>
            <View flex={1}>
              <Button
                size="lg"
                appearance="filled"
                ml="2xl"
                onPress={() => {
                  bottomSheetModalRef.current?.hide();
                  markExitRecalledTask(taskReadyToDelete, handleUpdateTask);
                }}
              >
                Continue
              </Button>
            </View>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default MarkExitConfirmation;
