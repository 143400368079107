/* eslint-disable no-empty */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import RazorpayCheckout from 'react-native-razorpay';
import {
  Platform,
  useWindowDimensions,
  NativeEventEmitter,
  NativeModules,
  BackHandler,
  Linking,
} from 'react-native';
import uuid from 'react-native-uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import HyperSdkReact from 'hyper-sdk-react';
import { useFocusEffect } from '@react-navigation/native';
import { BlurView, Button, Text, View } from '../../../components/new';
import PaymentsImage from '../../../../assets/images/payments.webp';
import {
  paymentOrderTypeEnum,
  PaymentGatewayType,
  PaymentStatusEnum,
} from '../../../utilities/helper';
import {
  initiatePaymentAPI,
  updatePaymentAPI,
} from './api/paymentAmountScreenQuery';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import useUserStore from '../../../stores/userStore';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import { InputStates } from '../../../components/new/primitive/Input/helpers';
import useAuthStore from '../../../stores/authStore';
import useLoginModalStore from '../../../stores/loginModalStore';
import { useBottomNudges } from '../../../stores/nudges/bottomNudges/bottomNudgesStore';
import FacebookPixelLogger from '../../../utilities/FacebookPixelLogger';
import CachedImage from '../../../components/new/custom/CachedImage';
import AppConstants from '../../../utilities/AppConstants';
import transactionUpdatedSubscription from './api/transactionUpdatedSubscription';

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const PaymentFooter = forwardRef((props: any, ref) => {
  const {
    billAmount,
    grossAmount,
    netAmount,
    tipAmount = 0,
    charityId = null,
    charityAmount = 0,
    payWithPoints,
    navigation,
    route,
    discountAmount,
    rewardsAmountUsed,
    isTrackYourCar = false,
    parkingID,
    orderType = paymentOrderTypeEnum.restaurant,
    couponCode = '',
    membershipConf,
    allowPayOnPress,
    StripContent,
    paymentMethod = PaymentGatewayType.JUSPAY,
  } = props;
  const insets = useSafeAreaInsets();
  const [requestId, setRequestId] = useState(uuid.v4());
  const userStore = useUserStore((state) => state);
  const userContact = userStore?.contact;
  const fullName = `${userStore.firstName} ${userStore.lastName}`;
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { orderId: orderIdProp } = route?.params ?? {};
  const { isLoggedIn } = useAuthStore((state) => state);
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const { openBottomNudge } = useBottomNudges();
  const { width } = useWindowDimensions();
  const [invoiceIdState, setInvoiceIdState] = useState(null);
  const setBlackMembership = useUserStore((state) => state.setBlackMembership);
  const {
    promptToAddMembershipAndPay,
    membershipId,
    isBlackMembershipPurchasedInThisTransaction,
    isMembershipPurchasedStandalone,
  } = membershipConf;

  const applyBlackMembershipAddon =
    isBlackMembershipPurchasedInThisTransaction &&
    !isMembershipPurchasedStandalone;

  // ----- initiate JSUPAY SDK --------
  const initiateJuspayPayment = useCallback(() => {
    HyperSdkReact.createHyperServices();

    const initiatePayload = {
      requestId,
      service: 'in.juspay.hyperpay',
      payload: {
        action: 'initiate',
        merchantId: process.env.JUSPAY_MID,
        clientId: process.env.JUSPAY_CID,
        environment: AppConstants.APP_ENV,
      },
    };

    HyperSdkReact.initiate(JSON.stringify(initiatePayload));
  }, [requestId]);

  // ------- terminating JUSPAY SDK ---------
  const terminateJuspay = useCallback(() => {
    HyperSdkReact.terminate();
  }, []);

  // -------- JUSPAY response status -------
  const juspayStatus = {
    Failed: [
      'JP_001',
      'JP_002',
      'JP_003',
      'JP_004',
      'JP_007',
      'JP_008',
      'JP_009',
      'JP_010',
      'JP_011',
      'JP_012',
      'JP_016',
      'JP_018',
      'JP_019',
      'JP_020',
    ],
    SnackBar: ['JP_005', 'JP_015'],
    Pending: ['JP_006', 'JP_013', 'JP_014', 'JP_022'],
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      loadScript('https://checkout.razorpay.com/v1/checkout.js');
    }
    if (Platform.OS !== 'web') {
      initiateJuspayPayment();
    }

    return () => {
      if (Platform.OS !== 'web') {
        terminateJuspay();
      }
    };
  }, []);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', () => {
      return !HyperSdkReact.isNull() && HyperSdkReact.onBackPressed();
    });
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', () => null);
    };
  }, []);

  useEffect(() => {
    const eventEmitter = new NativeEventEmitter(NativeModules.HyperSdkReact);
    const eventListener = eventEmitter.addListener('HyperEvent', (resp) => {
      const data = JSON.parse(resp);
      const event = data.event || '';
      switch (event) {
        case 'process_result':
          const error = data.error || false;
          const innerPayload = data.payload || {};
          const status = innerPayload.status || '';
          if (error) {
            if (juspayStatus.Failed.includes(data?.errorCode)) {
              firebaseEventLogger(`payment_amount__paymentJuspay_screen`, {
                paymentType: PaymentGatewayType.JUSPAY,
                orderId: orderIdProp,
                invoiceId: invoiceIdState,
                orderType,
                paymentStatus: PaymentStatusEnum.FAILED,
                error: JSON.stringify(error),
              });
              handleRedirect({
                paymentStatus: PaymentStatusEnum.FAILED,
                invoiceId: invoiceIdState,
                orderIdProp,
              });
            }
            if (juspayStatus.Pending.includes(data?.errorCode)) {
              firebaseEventLogger(`payment_amount__paymentJuspay_screen`, {
                paymentType: PaymentGatewayType.JUSPAY,
                orderId: orderIdProp,
                invoiceId: invoiceIdState,
                orderType,
                paymentStatus: PaymentStatusEnum.PENDING,
                error: JSON.stringify(error),
              });
              handleRedirect({
                paymentStatus: PaymentStatusEnum.PENDING,
                invoiceId: invoiceIdState,
                orderIdProp,
              });
            }
            if (juspayStatus.SnackBar.includes(data?.errorCode)) {
              dispatchSnackbar({
                msg: 'Something went wrong!',
                status: SnackbarStatus.error,
                version: SnackbarVersion.v1,
              });
            }
            setButtonDisabled(false);
          }
          switch (status) {
            case 'backpressed':
              // user back-pressed from PP without initiating any txn
              firebaseEventLogger(`payment_amount__paymentJuspay_screen`, {
                paymentType: PaymentGatewayType.JUSPAY,
                orderId: orderIdProp,
                invoiceId: invoiceIdState,
                paymentStatus: PaymentStatusEnum.FAILED,
                juspayStatus: status,
                orderType,
              });
              handleRedirect({
                paymentStatus: PaymentStatusEnum.FAILED,
                invoiceId: null,
                orderIdProp,
              });
              setButtonDisabled(false);

              break;
            case 'user_aborted':
              // user initiated a txn and pressed back
              // poll order status
              firebaseEventLogger(`payment_amount__paymentJuspay_screen`, {
                paymentType: PaymentGatewayType.JUSPAY,
                orderId: orderIdProp,
                invoiceId: invoiceIdState,
                paymentStatus: PaymentStatusEnum.FAILED,
                juspayStatus: status,
                orderType,
              });
              handleRedirect({
                paymentStatus: PaymentStatusEnum.FAILED,
                orderIdProp,
                invoiceId: data?.payload?.orderId,
              });
              break;
            default:
              break;
          }
          break;

        // block:end:handle-process-result
        default:
          console.log('data-----', data);
      }
    });
    return () => {
      eventListener.remove();
    };
  }, []);

  const setCustomerAsBlackMember = () => {
    if (isBlackMembershipPurchasedInThisTransaction) {
      setBlackMembership(membershipId, true);
    }
  };

  const optionsMaker = ({ invoiceId, orderId }) => {
    const optionsBase = {
      // description: 'Credits towards consultation',
      image:
        'https://32nd.s3.ap-south-1.amazonaws.com/assets/icons/footer-logo.svg',
      currency: 'INR',
      key: process.env.RAZORPAY_KEY,
      amount: Number(netAmount) * 100,
      timeout: 60 * 10,
      name: '32nd',

      prefill: {
        contact: `${userContact?.phone?.number}`,
        name: fullName,
      },
      // eslint-disable-next-line camelcase
      order_id: orderId,
      theme: { color: '#111' },
      retry: false,
    };

    if (Platform.OS === 'web') {
      optionsBase.handler = (response) => {
        if (
          (response.razorpay_signature,
          response.razorpay_order_id,
          response.razorpay_payment_id)
        ) {
          updatePaymentAPI({
            pgPaymentId: response.razorpay_payment_id,
            pgOrderId: response.razorpay_order_id,
            pgSignature: response.razorpay_signature,
          })
            .then((resp) => {
              firebaseEventLogger(`payment_amount__paymentRazorpay_screen`, {
                paymentStatus: resp?.updatePayment.status,
                paymentType: PaymentGatewayType.RAZORPAY,
                orderId: orderIdProp,
                invoiceId,
                orderType,
              });
              handleRedirect({
                paymentStatus: resp?.updatePayment.status,
                orderIdProp,
                invoiceId,
              });
            })
            .catch((e) => {
              firebaseEventLogger(`payment_amount__paymentRazorpay_screen`, {
                paymentStatus: PaymentStatusEnum.PENDING,
                paymentType: PaymentGatewayType.RAZORPAY,
                error: JSON.stringify(e),
                orderId: orderIdProp,
                invoiceId,
                orderType,
              });
              handleRedirect({
                paymentStatus: PaymentStatusEnum.PENDING,
                orderIdProp,
                invoiceId,
              });
            });
        }
      };
    }
    return optionsBase;
  };

  const openRazorpay = ({ orderId }) => {
    const options = {
      // description: 'Credits towards consultation',
      image:
        'https://32nd.s3.ap-south-1.amazonaws.com/assets/icons/footer-logo.svg',
      currency: 'INR',
      key: process.env.RAZORPAY_KEY,
      amount: Number(netAmount) * 100,
      timeout: 60 * 10,
      name: '32nd',
      prefill: {
        contact: `${userContact?.phone?.number}`,
        name: fullName,
      },
      // eslint-disable-next-line camelcase
      order_id: orderId,
      theme: { color: '#111' },
      retry: false,
    };
    return RazorpayCheckout.open(options);
  };

  const openJuspay = async (processPayload, webURL) => {
    try {
      if (Platform.OS === 'web') {
        Linking.openURL(webURL, '_self');
      } else if (HyperSdkReact.isInitialised()) {
        HyperSdkReact.process(JSON.stringify(processPayload));
      }
    } catch (error) {
      console.log('error---', error);
    }
  };

  const handleRedirectEvent = ({
    paymentStatus,
    invoiceId,
    orderIdProp: resId,
  }) => {
    if (paymentStatus === PaymentStatusEnum.SUCCESSFUL) {
      FacebookPixelLogger('track', 'Purchase');
      setCustomerAsBlackMember();
      navigation.navigate('afterPaymentScreen', {
        eventId: orderIdProp,
        invoiceId,
        paymentStatus: PaymentStatusEnum.SUCCESSFUL,
        orderId: resId,
        isTrackYourCar,
        parkingID,
        tipAmount,
        charityAmount,
        rewardsAmountUsed,
        orderType,
      });
    } else if (paymentStatus === PaymentStatusEnum.FAILED) {
      navigation.navigate('afterPaymentScreen', {
        eventId: orderIdProp,
        invoiceId,
        paymentStatus: PaymentStatusEnum.FAILED,
        slotId: route?.params?.slotId,
        orderType,
      });
    } else if (paymentStatus === PaymentStatusEnum.PENDING) {
      navigation.navigate('afterPaymentScreen', {
        eventId: orderIdProp,
        invoiceId,
        paymentStatus: PaymentStatusEnum.PENDING,
        orderType,
      });
    }
  };

  const handleRedirect = ({ paymentStatus, invoiceId, orderIdProp: resId }) => {
    if (orderType === paymentOrderTypeEnum.EVENT) {
      handleRedirectEvent({
        paymentStatus,
        orderIdProp,
        invoiceId,
      });
    } else if (paymentStatus === PaymentStatusEnum.SUCCESSFUL) {
      setCustomerAsBlackMember();
      navigation.navigate('afterPaymentScreen', {
        invoiceId,
        paymentStatus: PaymentStatusEnum.SUCCESSFUL,
        orderId: resId,
        isTrackYourCar,
        parkingID,
        rewardsAmountUsed,
        orderType,
      });
    } else if (paymentStatus === PaymentStatusEnum.FAILED) {
      navigation.navigate('afterPaymentScreen', {
        invoiceId,
        paymentStatus: PaymentStatusEnum.FAILED,
        slotId: route?.params?.slotId,
        orderType,
      });
    } else if (paymentStatus === PaymentStatusEnum.PENDING) {
      navigation.navigate('afterPaymentScreen', {
        eventId: orderIdProp,
        invoiceId,
        paymentStatus: PaymentStatusEnum.PENDING,
        orderType,
      });
    }
  };

  const handleTransactionUpdatedSubscription = (res) => {
    // ============
    const transactionUpdated = res?.invoiceUpdated;
    if (transactionUpdated) {
      if (transactionUpdated?.status === PaymentStatusEnum.SUCCESSFUL) {
        firebaseEventLogger('payment_amount__paymentSuccess_screen', {
          paymentStatus: transactionUpdated?.status,
          paymentType: transactionUpdated?.paymentType,
          amount: transactionUpdated?.priceDetails?.grossAmount,
          orderType,
        });
        handleRedirect({
          paymentStatus: PaymentStatusEnum.SUCCESSFUL,
          invoiceId: transactionUpdated?._id,
          orderIdProp: transactionUpdated?.orderId,
        });
      }
      if (transactionUpdated?.status === PaymentStatusEnum.FAILED) {
        firebaseEventLogger('payment_amount__paymentFailed_screen', {
          paymentStatus: transactionUpdated?.status,
          paymentType: transactionUpdated?.paymentType,
          amount: transactionUpdated?.priceDetails?.grossAmount,
          orderType,
        });
        handleRedirect({
          paymentStatus: PaymentStatusEnum.FAILED,
          invoiceId: transactionUpdated?._id,
          orderIdProp: transactionUpdated?.orderId,
        });
      }

      if (transactionUpdated?.status === PaymentStatusEnum.PENDING) {
        firebaseEventLogger('payment_amount__paymentPending_screen', {
          paymentStatus: transactionUpdated?.status,
          paymentType: transactionUpdated?.paymentType,
          amount: transactionUpdated?.priceDetails?.grossAmount,
          orderType,
        });
        handleRedirect({
          paymentStatus: PaymentStatusEnum.PENDING,
          invoiceId: transactionUpdated?._id,
          orderIdProp: transactionUpdated?.orderId,
        });
      }
    }
  };

  const handleTransactionUpdationSubRef = useRef(
    handleTransactionUpdatedSubscription,
  );

  useFocusEffect(
    useCallback(() => {
      let cancelSubscriptionUpdateData = {
        dispose: () => {},
      };
      const cancelUpdateTaskSubscription = (disposable) => {
        cancelSubscriptionUpdateData = disposable;
      };
      const transactionUpdatedReq = {
        invoiceId: invoiceIdState,
      };

      transactionUpdatedSubscription(
        transactionUpdatedReq,
        (newData) => handleTransactionUpdationSubRef.current(newData),
        cancelUpdateTaskSubscription,
      );

      return () => {
        // Useful for cleanup functions
        cancelSubscriptionUpdateData.dispose();
      };
    }, [invoiceIdState]),
  );

  const initiatePayment = (data) => {
    initiatePaymentAPI(
      data,
      (res) => {
        setButtonDisabled(false);
        firebaseEventLogger('pay_now', {
          netAmount,
          grossAmount,
          discountAmount,
          rewardsAmountUsed,
          invoiceId: res?.initiatePayment?._id,
          orderType,
        });
        const status = res?.initiatePayment.status;
        if (status === PaymentStatusEnum.SUCCESSFUL) {
          setCustomerAsBlackMember();
          handleRedirect({
            paymentStatus: PaymentStatusEnum.SUCCESSFUL,
            orderIdProp,
            invoiceId: res?.initiatePayment?._id,
          });
          navigation.navigate('afterPaymentScreen', {
            paymentStatus: PaymentStatusEnum.SUCCESSFUL,
            invoiceId: res?.initiatePayment?._id,
            parkingID,
            orderType,
          });
        } else if (status === PaymentStatusEnum.PENDING) {
          if (
            Platform.OS === 'web' &&
            res?.initiatePayment?.paymentType !== PaymentGatewayType.JUSPAY
          ) {
            openRazorpayWeb({
              invoiceId: res?.initiatePayment?._id,
              orderId: res?.initiatePayment?.orderId,
            });
          } else if (
            res?.initiatePayment?.paymentType === PaymentGatewayType.JUSPAY
          ) {
            setInvoiceIdState(res?.initiatePayment?._id);
            openJuspay(
              {
                requestId,
                ...res?.initiatePayment?.paymentDetails?.juspayOrder
                  ?.sdkPayload,
              },
              res?.initiatePayment?.paymentDetails?.juspayOrder?.paymentLinks
                ?.web,
            );
          } else {
            openRazorpay({
              orderId: res?.initiatePayment?.orderId,
            })
              .then((dataInner) => {
                setButtonDisabled(false);
                updatePaymentAPI({
                  pgPaymentId: dataInner.razorpay_payment_id,
                  pgOrderId: dataInner.razorpay_order_id,
                  pgSignature: dataInner.razorpay_signature,
                })
                  .then((resp) => {
                    firebaseEventLogger(
                      `payment_amount__paymentRazorpay_screen`,
                      {
                        paymentStatus: resp?.updatePayment?.status,
                        paymentType: resp?.updatePayment?.paymentType,
                        amount: resp?.updatePayment?.priceDetails?.grossAmount,
                        orderId: orderIdProp,
                        invoiceId: res?.initiatePayment?._id,
                        orderType,
                      },
                    );
                    handleRedirect({
                      paymentStatus: resp?.updatePayment.status,
                      orderIdProp,
                      invoiceId: res?.initiatePayment?._id,
                    });
                  })
                  .catch((e) => {
                    firebaseEventLogger(
                      `payment_amount__paymentRazorpay_screen`,
                      {
                        paymentStatus: PaymentStatusEnum.PENDING,
                        paymentType: PaymentGatewayType.RAZORPAY,
                        error: JSON.stringify(e),
                        orderId: orderIdProp,
                        invoiceId: res?.initiatePayment?._id,
                        orderType,
                      },
                    );
                    handleRedirect({
                      paymentStatus: PaymentStatusEnum.PENDING,
                      orderIdProp,
                      invoiceId: res?.initiatePayment?._id,
                    });
                  });
              })
              .catch((error) => {
                setButtonDisabled(false);
                updatePaymentAPI({ pgOrderId: res?.initiatePayment?.orderId })
                  .then((resp) => {
                    firebaseEventLogger(
                      `payment_amount__paymentRazorpay_screen`,
                      {
                        paymentStatus: resp?.updatePayment?.status,
                        paymentType: resp?.updatePayment?.paymentType,
                        amount: resp?.updatePayment?.priceDetails?.grossAmount,
                        orderId: orderIdProp,
                        invoiceId: res?.initiatePayment?._id,
                        orderType,
                      },
                    );
                    handleRedirect({
                      paymentStatus: resp?.updatePayment.status,
                      orderIdProp,
                      invoiceId: res?.initiatePayment?._id,
                    });
                  })
                  .catch((e) => {
                    firebaseEventLogger(
                      `payment_amount__paymentRazorpay_screen`,
                      {
                        paymentStatus: PaymentStatusEnum.PENDING,
                        paymentType: PaymentGatewayType.RAZORPAY,
                        error: JSON.stringify(e),
                        orderId: orderIdProp,
                        invoiceId: res?.initiatePayment?._id,
                        orderType,
                      },
                    );
                    handleRedirect({
                      paymentStatus: PaymentStatusEnum.PENDING,
                      orderIdProp,
                      invoiceId: res?.initiatePayment?._id,
                    });
                  });
              });
          }
        } else if (status === PaymentStatusEnum.FAILED) {
          firebaseEventLogger(`payment_amount__paymentRazorpay_screen`, {
            paymentType: PaymentGatewayType.RAZORPAY,
            orderId: orderIdProp,
            invoiceId: res?.initiatePayment?._id,
            paymentStatus: PaymentStatusEnum.FAILED,
            orderType,
          });
          handleRedirect({
            paymentStatus: PaymentStatusEnum.FAILED,
            orderIdProp,
            invoiceId: res?.initiatePayment?._id,
          });
          dispatchSnackbar({
            msg: 'payment failed! Please try again',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
        }
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const openRazorpayWeb = async ({ invoiceId, orderId }) => {
    const options = optionsMaker({ invoiceId, orderId });
    // @ts-ignore
    const rzp1 = new window.Razorpay(options);

    rzp1.on('payment.failed', function (response) {
      try {
        rzp1.close();
        firebaseEventLogger(`payment_amount__paymentRazorpay_screen`, {
          paymentType: PaymentGatewayType.RAZORPAY,
          orderId: orderIdProp,
          invoiceId,
          paymentStatus: PaymentStatusEnum.FAILED,
          orderType,
        });
        handleRedirect({
          paymentStatus: PaymentStatusEnum.FAILED,
          orderIdProp,
          invoiceId,
        });
      } catch (e) {
        console.log('--->>', e);
      }
    });
    rzp1.open();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePress = () => {
    if (orderType === paymentOrderTypeEnum.EVENT) {
      firebaseEventLogger('event__slot__payment__Tap', {
        ...route?.params,
        ticketsInfo: JSON.stringify(route?.params?.ticketsInfo),
        eventId: orderIdProp,
        buttonName: 'proceed',
        screenName: 'eventConfirmation',
        netAmount,
        grossAmount,
        payWithPoints,
        orderType,
      });
      FacebookPixelLogger('track', 'InitiateCheckout');
      if (!isLoggedIn) {
        setAuthModalOpen(true);
        return;
      }
      const params = {
        amount: billAmount,
        orderType,
        orderId: orderIdProp,
        useRewardPoints: payWithPoints,
        tipAmount,
        workshopDetails: {
          slotId: route?.params?.slotId,
          tickets: Object.values(JSON.parse(route?.params?.tickets)),
        },
        couponCode,
        csrContributionId: charityAmount ? charityId : '',
        addons: applyBlackMembershipAddon
          ? {
              orderId: membershipId,
            }
          : null,
      };
      initiatePayment(params);
    } else {
      if (!isLoggedIn) {
        setAuthModalOpen(true);
        return;
      }
      if (orderType === paymentOrderTypeEnum.INVESTMENT_LEAD) {
        FacebookPixelLogger('track', 'InitiateCheckout');
      }
      setButtonDisabled(true);
      const params = {
        amount: billAmount,
        orderType,
        orderId: isTrackYourCar ? parkingID : orderIdProp,
        useRewardPoints: payWithPoints,
        tipAmount,
        couponCode,
        csrContributionId: charityAmount ? charityId : '',
        addons: applyBlackMembershipAddon
          ? {
              orderId: membershipId,
            }
          : null,
      };
      initiatePayment(params);
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        payNow: handlePress,
      };
    },
    [handlePress],
  );

  const [buttonDisabled, setButtonDisabled] = useState(false);

  return (
    <View>
      {StripContent}
      <BlurView level="4">
        <View pb={insets.bottom} bg="background.primary.elevation">
          <View>
            {isTrackYourCar && netAmount > 0 && (
              <View bg="primary.400" p="sm+md" flexDirection="row">
                <View mx="sm" alignItems="center" justifyContent="center">
                  <CachedImage
                    source={PaymentsImage}
                    style={{ height: 16, width: 16 }}
                  />
                </View>
                <View flex={1}>
                  <Text
                    color="primary.10"
                    textAlign="left"
                    numberOfLines={1}
                    ellipsizemode="tail"
                    size="xs"
                    flex={1}
                  >
                    Receive 10 visit points worth ₹10 upon payment.
                  </Text>
                </View>
              </View>
            )}
            <View
              flexDirection="row"
              p="2xl"
              borderTopWidth="xs"
              borderColor="primary.50"
            >
              <View flex={1}>
                <View flexDirection="row">
                  {!!(netAmount < grossAmount) && (
                    <View justifyContent="center">
                      <Text
                        size="md"
                        weight="medium"
                        color="primary.100"
                        style={{
                          textDecorationLine: 'line-through',
                        }}
                      >
                        ₹{grossAmount}
                      </Text>
                    </View>
                  )}
                  <View ml="sm">
                    <Text size="xl" weight="medium" color="primary.500">
                      ₹{netAmount}
                    </Text>
                  </View>
                </View>
                <Text size="xs" color="primary.200">
                  Payable Amount
                </Text>
              </View>
              <View flex={1}>
                <Button
                  state={
                    buttonDisabled ? InputStates.disabled : InputStates.active
                  }
                  size="lg"
                  onPress={() => {
                    if (typeof allowPayOnPress === 'function') {
                      const allowPay = allowPayOnPress();
                      if (!allowPay) return;
                    }
                    handlePress();
                  }}
                >
                  Pay Now
                </Button>
              </View>
            </View>
          </View>
        </View>
      </BlurView>
    </View>
  );
});

export default PaymentFooter;
