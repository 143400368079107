/**
 * @generated SignedSource<<7d6962e2b40fbad412d8728ea4d9d54a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type EnuminvoicePaymentType = "BILL_UPLOAD" | "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type transactionUpdatedSubscription$variables = {
  invoiceId: string;
};
export type transactionUpdatedSubscription$data = {
  readonly invoiceUpdated: {
    readonly _id: any;
    readonly paymentType: EnuminvoicePaymentType | null;
    readonly priceDetails: {
      readonly grossAmount: number;
    };
    readonly status: EnuminvoiceStatus | null;
  } | null;
};
export type transactionUpdatedSubscription = {
  response: transactionUpdatedSubscription$data;
  variables: transactionUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invoiceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "invoiceId",
        "variableName": "invoiceId"
      }
    ],
    "concreteType": "invoice",
    "kind": "LinkedField",
    "name": "invoiceUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "invoicePriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transactionUpdatedSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transactionUpdatedSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "182c6abb05189d8ac34c44d0547f50b3",
    "id": null,
    "metadata": {},
    "name": "transactionUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription transactionUpdatedSubscription(\n  $invoiceId: ID!\n) {\n  invoiceUpdated(invoiceId: $invoiceId) {\n    _id\n    status\n    paymentType\n    priceDetails {\n      grossAmount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f10ac97066203d1172c594f3d0527bbb";

export default node;
