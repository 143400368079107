import React from 'react';
import {
  Placeholder,
  PlaceholderMedia,
  PlaceholderLine,
  Fade,
} from 'rn-placeholder';
import { View } from '../../../../../components/new';

const CarIcon = () => (
  <View pr="2xl">
    <PlaceholderMedia size={40} isRound />
  </View>
);

const Tag = () => (
  <PlaceholderLine
    height={26}
    width={20}
    style={{ borderRadius: 2, marginTop: 4 }}
  />
);

const VehicleHistoryCardPlaceholder = () => {
  return (
    <View
      justifyContent="space-between"
      height={141}
      p="2xl"
      borderWidth="1"
      borderRadius="16"
      borderColor="primary.50"
    >
      <Placeholder Left={CarIcon} Right={Tag} Animation={Fade}>
        <PlaceholderLine
          height={14}
          width={50}
          style={{ marginBottom: 8, marginTop: 4 }}
        />
        <PlaceholderLine height={10} width={30} />
      </Placeholder>
      <Placeholder Animation={Fade}>
        <PlaceholderLine
          height={42}
          width={100}
          style={{ margin: 0, marginTop: 6, borderRadius: 8 }}
        />
      </Placeholder>
    </View>
  );
};

export default VehicleHistoryCardPlaceholder;
