/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useRef,
} from 'react';
import { Animated, Image, Platform, RefreshControl } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { IconButton, Text, View } from '../../../../components/new';
import TabSwitcher from '../TabSwitcher';
import Layout from '../../../../components/new/primitive/Layout/Layout';
import Loading from '../../../../components/loading.component';
import DisconnectedDropover from '../../../../components/disconnectedpopover.component';
import LocationDropDown from '../LocationDropDown';
import useCampusLocation from '../../../../stores/campusLocation';
import {
  isAlreadyInList,
  doingEntry,
  managerType,
  findAndBlurTask,
  findAndRemoveTask,
  findAndReplaceTask,
} from '../../helpers/helpers';
import {
  recallScreenQuery,
  recallScreenQueryFindTaskFragment,
} from '../../API/recallScreenQuery';
import RenderItem from './RenderItem';
import BottomScoreBoard from './BottomScoreBoard';
import useCommonStore from '../../../../stores/commonStore';
import useTaskStore from '../../../../stores/taskStore';
import {
  findTaskHistoryLastAction,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../../utilities/helper';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import RefreshBubble from '../../../../components/new/primitive/Bubble/RefreshBubble';
import supervisorTaskUpdatedSubscription from '../../API/supervisorTaskUpdatedSubscription';
import supervisorTaskCreatedSubscription from '../../API/supervisorTaskCreatedSubscription';
import useSound from '../../../../hooks/useSound';
import { getTheme } from '../../../../themes/new/theme';
import useUserStore from '../../../../stores/userStore';
import { network } from '../../../../utilities/Constant';

const { pullToRefreshFetchPolicy } = network;

const emptyTaskImg = require('../../../../../assets/images/task-empty.webp');

const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);

const recallScreenInfo = (data = null) => ({
  [managerType.supervisor]: {
    sub: {
      createSub: {
        history: [taskHistoryActionTypes.created],
      },
      updateSub: {
        history: [
          taskHistoryActionTypes.assigned,
          taskHistoryActionTypes.accepted,
          taskHistoryActionTypes.started,
          taskHistoryActionTypes.arrived,
          taskHistoryActionTypes.completed,
          taskHistoryActionTypes.cancelled,
          taskHistoryActionTypes.incomplete,
          taskHistoryActionTypes.EXITED,
        ],
      },
    },
    tabsData: [
      {
        name: 'Recalled',
        filter: {
          history: [
            { action: taskHistoryActionTypes.created },
            { action: taskHistoryActionTypes.assigned },
            { action: taskHistoryActionTypes.accepted },
            { action: taskHistoryActionTypes.started },
          ],
        },
      },
      {
        name: 'Arrived',
        filter: { history: [{ action: taskHistoryActionTypes.arrived }] },
      },
    ],
  },
  [managerType.controller]: {
    sub: {
      createSub: {
        history: ['created'],
      },
      updateSub: {
        history: [
          taskHistoryActionTypes.assigned,
          taskHistoryActionTypes.accepted,
          taskHistoryActionTypes.started,
          taskHistoryActionTypes.arrived,
          taskHistoryActionTypes.completed,
          taskHistoryActionTypes.cancelled,
          taskHistoryActionTypes.incomplete,
        ],
      },
    },
    tabsData: [
      {
        name: 'Unassigned',
        filter: {
          history: [{ action: taskHistoryActionTypes.created }],
          taskStartLocation: {
            locationId: data?.locationId,
          },
        },
      },
      {
        name: 'Assigned',
        filter: {
          history: [
            { action: taskHistoryActionTypes.assigned },
            { action: taskHistoryActionTypes.accepted },
          ],
          taskStartLocation: {
            locationId: data?.locationId,
          },
        },
      },
    ],
  },
});

const getManagerType = (isSupervisor) => {
  if (isSupervisor) {
    return managerType.supervisor;
  }
  return managerType.controller;
};

const RenderTabSwitcher = ({ isSupervisor, handleTabSwitch, selected }) => {
  return (
    <TabSwitcher
      tabOneText={`${
        recallScreenInfo(null)[getManagerType(isSupervisor)].tabsData[0].name
      }`}
      tabTwoText={`${
        recallScreenInfo(null)[getManagerType(isSupervisor)].tabsData[1].name
      }`}
      handleTabSwitch={handleTabSwitch}
      currentTab={selected}
      mx="2xl"
    />
  );
};

const RecallScreen = (props) => {
  const {
    queryOptions,
    variables,
    isSupervisor,
    selectedIndex,
    refresh,
    navigation,
  } = props;
  const {
    // ------ for arrived tab for 32nd Supervisor ------
    arrivedTasks,
    setArrivedTasks,
    newArrivedTasks, // for subscription bubble
    setNewArrivedTasks, // for subscription
    // ------- for recalled tab for 32nd Supervisor ------
    recalledTasks,
    setRecalledTasks,
    newRecalledTasks, // for subscription bubble
    setNewRecalledTasks, // for subscription
    // ------ for unassigned tab for Other Parking Controller ------
    unassignedTasks,
    setUnassignedTasks,
    newUnassignedTasks, // for subscription bubble
    setNewUnassignedTasks, // for subscription
    // ------ for assigned tab for Other Parking Controller ------
    assignedTasks,
    setAssignedTasks,
    newAssignedTasks, // for subscription bubble
    setNewAssignedTasks, // for subscription
  } = useTaskStore((state) => state);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const findTasksRef = useLazyLoadQuery(recallScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });

  const [{ findTasks }, refetchTasks] = useRefetchableFragment(
    recallScreenQueryFindTaskFragment,
    findTasksRef,
  );

  const [refreshing, setRefreshing] = useState(false);

  const setData = () => {
    if (isSupervisor) {
      if (selectedIndex === 0) {
        setRecalledTasks({ data: findTasks });
      } else {
        setArrivedTasks({ data: findTasks });
      }
    } else if (!isSupervisor && selectedIndex === 0) {
      setUnassignedTasks({ data: findTasks });
    } else if (!isSupervisor && selectedIndex === 1) {
      setAssignedTasks({ data: findTasks });
    }
  };

  useEffect(() => {
    // setTaskData({ data: findTasks });
    setData();
  }, [selectedIndex, isSupervisor, findTasks, refreshing]);

  const noTasks = () => (
    <View justifyContent="center" alignItems="center" mt="40%">
      <Image
        source={emptyTaskImg}
        style={{ width: 80, height: 80 }}
        resizeMode="contain"
      />
      <Text size="xl" color="grey.200" weight="medium" mt="2xl">
        No tasks available
      </Text>
    </View>
  );

  const onPullToRefresh = useCallback(() => {
    setRefreshing(true);
    refetchTasks(variables, {
      fetchPolicy: pullToRefreshFetchPolicy,
      onComplete: () => setRefreshing(false),
    });
  }, [variables]);

  const renderItem = ({ item }) => (
    <RenderItem
      item={item}
      isSupervisor={isSupervisor}
      selectedIndex={selectedIndex}
    />
  );

  let refreshBubbleLength = 0;

  if (isSupervisor && selectedIndex === 0) {
    refreshBubbleLength = newRecalledTasks.length;
  }
  if (isSupervisor && selectedIndex === 1) {
    refreshBubbleLength = newArrivedTasks.length;
  }
  if (!isSupervisor && selectedIndex === 0) {
    refreshBubbleLength = newUnassignedTasks.length;
  }
  if (!isSupervisor && selectedIndex === 1) {
    refreshBubbleLength = newAssignedTasks.length;
  }

  return (
    <>
      <View alignItems="center" zIndex={1}>
        <RefreshBubble
          onPress={() => {
            refresh();
            setNewRecalledTasks({ data: [] });
            setNewArrivedTasks({ data: [] });
            setNewUnassignedTasks({ data: [] });
            setNewAssignedTasks({ data: [] });
          }}
          length={refreshBubbleLength}
        />
      </View>
      <View flex={1}>
        <AnimatedFlashList
          style={{
            flex: 1,
            marginTop: getTheme().space['2xl'],
            paddingBottom: getTheme().space['4xl'],
          }}
          data={
            isSupervisor
              ? selectedIndex === 0
                ? recalledTasks
                : arrivedTasks
              : selectedIndex === 0
              ? unassignedTasks
              : assignedTasks
          }
          keyExtractor={(item) => item?._id}
          estimatedItemSize={200}
          renderItem={renderItem}
          showsVerticalScrollIndicator
          ListEmptyComponent={noTasks}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onPullToRefresh}
            />
          }
        />
      </View>
    </>
  );
};

const RecallScreenWrapper = ({ navigation }) => {
  const [selected, setSelected] = useState(0);
  const { id: userId, role } = useUserStore((state) => state);

  const campusID = useCommonStore((state) => state.campusId);
  const { bottom } = useSafeAreaInsets();
  const {
    // ------ for arrived tab for 32nd Supervisor ------
    arrivedTasks,
    setArrivedTasks,
    newArrivedTasks, // for subscription bubble
    setNewArrivedTasks, // for subscription
    // ------- for recalled tab for 32nd Supervisor ------
    recalledTasks,
    setRecalledTasks,
    newRecalledTasks, // for subscription bubble
    setNewRecalledTasks, // for subscription
    // ------ for unassigned tab for Other Parking Controller ------
    unassignedTasks,
    setUnassignedTasks,
    newUnassignedTasks, // for subscription bubble
    setNewUnassignedTasks, // for subscription
    // ------ for assigned tab for Other Parking Controller ------
    assignedTasks,
    setAssignedTasks,
    newAssignedTasks, // for subscription bubble
    setNewAssignedTasks, // for subscription
    // ------ for setting card opened ------
    setRecallTaskOpened,
  } = useTaskStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });
  const [netStatus, setNetStatus] = useState(true);
  const { campusLocations, currentLocation } = useCampusLocation(
    (state) => state,
  );
  // it can be supervisor or controller
  // supervisor - doing vehicle entry at the porch of 32nd
  // controller - doing vehicle entry in parkings
  const isSupervisor = doingEntry(campusLocations);

  const taskFilter = {
    campus: { _id: campusID },
    taskType: 'recall',
    ...recallScreenInfo({ locationId: currentLocation?._id })[
      getManagerType(isSupervisor)
    ].tabsData[selected].filter,
  };
  const sort = 'DELAY_AT_ASC';

  const refresh = () => {
    setRefreshedQueryOptions((prev) => {
      return {
        ...prev,
        fetchKey: (prev?.fetchKey || 0) + 1,
      };
    });
  };

  const { playSound: playTaskArrivedSound } = useSound({
    soundOrigin: require('../../../../../assets/notification.mp3'),
  });

  useEffect(() => {
    if (campusLocations && campusLocations?.length) {
      refresh();
    }
  }, [campusLocations]);

  const handleTaskCreationSub = (res) => {
    const taskCreated = res?.taskCreated;
    const lastAction = findTaskHistoryLastAction(taskCreated);

    // console.log(taskCreated);
    if (taskCreated && taskCreated.taskType === taskTypes.recall) {
      // FOR SUPERVISOR=======
      // ========================

      // --TAB 0--
      if (isSupervisor && selected === 0) {
        if (!isAlreadyInList(taskCreated, recalledTasks)) {
          playTaskArrivedSound();
          setNewRecalledTasks({
            data: [...newRecalledTasks, taskCreated],
          });
        }
      }

      // FOR CONTROLLER=======
      // ===========================

      // --TAB 0--

      if (!isSupervisor && selected === 0) {
        if (!isAlreadyInList(taskCreated, unassignedTasks)) {
          playTaskArrivedSound();
          setNewUnassignedTasks({
            data: [...newUnassignedTasks, taskCreated],
          });
        }
      }
      // ============
    }
  };
  const handleTaskUpdateSubscription = (res) => {
    // ============
    const taskUpdated = res?.taskUpdated;
    const lastAction = findTaskHistoryLastAction(taskUpdated);
    // ============
    if (taskUpdated && taskUpdated.taskType === taskTypes.recall) {
      // FOR SUPERVISOR=======
      // ========================

      // --TAB 0--
      if (isSupervisor && selected === 0) {
        if (
          lastAction === taskHistoryActionTypes.assigned ||
          lastAction === taskHistoryActionTypes.accepted ||
          lastAction === taskHistoryActionTypes.started
        ) {
          // if in rendered list , update it
          if (isAlreadyInList(taskUpdated, recalledTasks)) {
            const newList = findAndReplaceTask(taskUpdated, recalledTasks);
            setRecalledTasks({ data: newList });
          }
        }
        if (
          lastAction === taskHistoryActionTypes.arrived ||
          lastAction === taskHistoryActionTypes.incomplete ||
          lastAction === taskHistoryActionTypes.completed ||
          lastAction === taskHistoryActionTypes.cancelled
        ) {
          // blur card if in rendedred list, if not remove the item from new available list if its there
          if (isAlreadyInList(taskUpdated, recalledTasks)) {
            const newList = findAndBlurTask(taskUpdated, recalledTasks);
            setRecalledTasks({ data: newList });
          } else if (isAlreadyInList(taskUpdated, newRecalledTasks)) {
            const newBubbleList = findAndRemoveTask(
              taskUpdated,
              newRecalledTasks,
            );
            setNewRecalledTasks({ data: newBubbleList });
          }
        }
      }
    }
    // --TAB 1--
    if (isSupervisor && selected === 1) {
      if (lastAction === taskHistoryActionTypes.arrived) {
        // if not in rendered list  and not in bubble list , show bubble
        if (
          !isAlreadyInList(taskUpdated, arrivedTasks) &&
          !isAlreadyInList(taskUpdated, newArrivedTasks)
        ) {
          setNewArrivedTasks({ data: [...newArrivedTasks, taskUpdated] });
        }
      }
      if (
        lastAction === taskHistoryActionTypes.completed ||
        lastAction === taskHistoryActionTypes.incomplete ||
        lastAction === taskHistoryActionTypes.cancelled ||
        lastAction === taskHistoryActionTypes.EXITED
      ) {
        // blur card if in rendedred list, if not remove the item from new available list if its there
        if (isAlreadyInList(taskUpdated, arrivedTasks)) {
          const newList = findAndBlurTask(taskUpdated, arrivedTasks);
          setArrivedTasks({ data: newList });
        } else if (isAlreadyInList(taskUpdated, newArrivedTasks)) {
          const newBubbleList = findAndRemoveTask(taskUpdated, newArrivedTasks);
          setNewArrivedTasks({ data: newBubbleList });
        }
      }
    }
    // FOR SUPERVISOR:end=======

    // FOR CONTROLLER=======
    // ========================

    // --TAB 0--

    if (!isSupervisor && selected === 0) {
      // --
      if (
        lastAction === taskHistoryActionTypes.assigned ||
        lastAction === taskHistoryActionTypes.accepted
      ) {
        // blur card if in rendedred list, if not remove the item from new available list if its there
        if (isAlreadyInList(taskUpdated, unassignedTasks)) {
          const newList = findAndBlurTask(taskUpdated, unassignedTasks);
          setUnassignedTasks({ data: newList });
        } else if (isAlreadyInList(taskUpdated, newUnassignedTasks)) {
          const newBubbleList = findAndRemoveTask(
            taskUpdated,
            newUnassignedTasks,
          );
          setNewUnassignedTasks({ data: newBubbleList });
        }
      }
      // --
    }

    // --TAB 1--
    if (!isSupervisor && selected === 1) {
      if (
        lastAction === taskHistoryActionTypes.assigned ||
        lastAction === taskHistoryActionTypes.accepted
      ) {
        // check if in list, if yes, update it if not show in bubble if not in bubble
        if (isAlreadyInList(taskUpdated, assignedTasks)) {
          const newList = findAndReplaceTask(taskUpdated, assignedTasks);
          setAssignedTasks({ data: newList });
        } else if (!isAlreadyInList(taskUpdated, newAssignedTasks)) {
          // check if in bubble , if not add it.
          setNewAssignedTasks({ data: [...newAssignedTasks, taskUpdated] });
        }
      }

      if (
        lastAction === taskHistoryActionTypes.started ||
        lastAction === taskHistoryActionTypes.arrived ||
        lastAction === taskHistoryActionTypes.cancelled ||
        lastAction === taskHistoryActionTypes.completed ||
        lastAction === taskHistoryActionTypes.incomplete
      ) {
        // blur card if in rendedred list, if not remove the bubble item from new available list if its there
        if (isAlreadyInList(taskUpdated, assignedTasks)) {
          const newList = findAndBlurTask(taskUpdated, assignedTasks);
          setAssignedTasks({ data: newList });
        } else if (isAlreadyInList(taskUpdated, newAssignedTasks)) {
          const newBubbleList = findAndRemoveTask(
            taskUpdated,
            newAssignedTasks,
          );
          setNewAssignedTasks({ data: newBubbleList });
        }
      }
    }

    // FOR CONTROLLER:end=======

    // ===========================
  };

  const handleTaskCreationSubRef = useRef(handleTaskCreationSub);
  const handleTaskUpdationSubRef = useRef(handleTaskUpdateSubscription);

  useEffect(() => {
    handleTaskCreationSubRef.current = handleTaskCreationSub;
    handleTaskUpdationSubRef.current = handleTaskUpdateSubscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selected,
    recalledTasks,
    arrivedTasks,
    unassignedTasks,
    assignedTasks,
    // -----------
    newRecalledTasks,
    newArrivedTasks,
    newUnassignedTasks,
    newAssignedTasks,
  ]);

  useFocusEffect(
    useCallback(() => {
      refresh();
      setRecallTaskOpened('');
      // ================
      // TODO : apply subscription
      let cancelSubscriptionCreateData = {
        dispose: () => {},
      };
      let cancelSubscriptionUpdateData = {
        dispose: () => {},
      };
      const cancelCreateTaskSubscription = (disposable) => {
        cancelSubscriptionCreateData = disposable;
      };
      const cancelUpdateTaskSubscription = (disposable) => {
        cancelSubscriptionUpdateData = disposable;
      };
      const taskCreatedReq = {
        campusId: campusID,
        userId,
        taskType: taskTypes.recall,
        history:
          recallScreenInfo()[getManagerType(isSupervisor)].sub.updateSub
            .history,
        // event_at: new Date().toISOString(),
        parkingLocationId: isSupervisor ? null : currentLocation._id,
      };
      const taskUpdatedDataReq = {
        campusId: campusID,
        userId,
        taskType: taskTypes.recall,
        history:
          recallScreenInfo()[getManagerType(isSupervisor)].sub.updateSub
            .history,
        // event_at: new Date().toISOString(),
        parkingLocationId: isSupervisor ? null : currentLocation._id,
      };
      supervisorTaskCreatedSubscription(
        taskCreatedReq,
        (data) => handleTaskCreationSubRef.current(data),
        cancelCreateTaskSubscription,
      );
      supervisorTaskUpdatedSubscription(
        taskUpdatedDataReq,
        (data) => handleTaskUpdationSubRef.current(data),
        cancelUpdateTaskSubscription,
      );

      return () => {
        // Useful for cleanup functions
        cancelSubscriptionCreateData.dispose();
        cancelSubscriptionUpdateData.dispose();
      };
    }, []),
  );

  const handleTabSwitch = (val) => {
    refresh();
    // reset red dot on tab switch, turn off the flag
    if (isSupervisor) {
      if (val === 0) {
        setNewRecalledTasks({ data: [] });
      }
      if (val === 1) {
        setNewArrivedTasks({ data: [] });
      }
    } else {
      if (val === 0) {
        setNewUnassignedTasks({ data: [] });
      }
      if (val === 1) {
        setNewAssignedTasks({ data: [] });
      }
    }
    // =================
    setSelected(val);
  };

  const onPressScan = () => {
    if (Platform.OS !== 'web') {
      navigation.navigate('BarCodeScannerScreen');
    } else {
      navigation.navigate('SearchParkingScreen');
    }
  };

  return (
    <Layout level={2} edges={['top']}>
      <View
        flexDirection="row"
        mb="4xl"
        px="2xl"
        mt="2xl"
        alignItems="center"
        justifyContent="space-between"
      >
        <View py="xs">
          <Text size="2xl" color="primary.300">
            Recall Tasks
          </Text>
        </View>
        <View>{role !== 'controller' ? <LocationDropDown /> : null}</View>
      </View>
      <RenderTabSwitcher
        isSupervisor={isSupervisor}
        handleTabSwitch={handleTabSwitch}
        selected={selected}
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <RecallScreen
            variables={{ taskFilter, sort }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            isSupervisor={isSupervisor}
            selectedIndex={selected}
            refresh={refresh}
            navigation={navigation}
          />
          {isSupervisor ? (
            <View
              position="absolute"
              right="2xl"
              bottom={bottom + getTheme().space['5xl+6xl']}
            >
              <IconButton
                size="3xl"
                iconColor="primary.10"
                text="SCAN"
                iconSize="xl"
                name="scan-qr-outline-300"
                onPress={onPressScan}
              />
            </View>
          ) : (
            <></>
          )}
          <BottomScoreBoard key={selected} isSupervisor={isSupervisor} />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default RecallScreenWrapper;
