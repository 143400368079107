/**
 * @generated SignedSource<<fed5c1596187e19b8135dec5825c4eea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RestaurantPartnerAccountScreenQuery$variables = {
  userId: any;
};
export type RestaurantPartnerAccountScreenQuery$data = {
  readonly findUserById: {
    readonly " $fragmentSpreads": FragmentRefs<"RestaurantPartnerAccountScreenFindUserFragment">;
  } | null;
};
export type RestaurantPartnerAccountScreenQuery = {
  response: RestaurantPartnerAccountScreenQuery$data;
  variables: RestaurantPartnerAccountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_id",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RestaurantPartnerAccountScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "findUserById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RestaurantPartnerAccountScreenFindUserFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RestaurantPartnerAccountScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "findUserById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserName",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permission",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userSince",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visitCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkedCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileImage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2",
            "kind": "LinkedField",
            "name": "restaurants",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2PayBill",
                "kind": "LinkedField",
                "name": "payBill",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "restaurants2PayBillPointsEnabled",
                    "kind": "LinkedField",
                    "name": "pointsEnabled",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "redeem",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reward",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15b69f5fdba808364f0ea3035005b1f1",
    "id": null,
    "metadata": {},
    "name": "RestaurantPartnerAccountScreenQuery",
    "operationKind": "query",
    "text": "query RestaurantPartnerAccountScreenQuery(\n  $userId: MongoID!\n) {\n  findUserById(_id: $userId) {\n    ...RestaurantPartnerAccountScreenFindUserFragment\n  }\n}\n\nfragment RestaurantPartnerAccountScreenFindUserFragment on User {\n  _id\n  name {\n    first\n    last\n  }\n  role {\n    name\n    permission\n  }\n  userSince\n  visitCount\n  parkedCount\n  profileImage\n  restaurants {\n    _id\n    displayName\n    payBill {\n      pointsEnabled {\n        redeem\n        reward\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "27f469f3aea48f6fa144420f8c48f8d6";

export default node;
