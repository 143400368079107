/**
 * @generated SignedSource<<d0ee59b01bb43682cfa07d282ce6f5a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type EnumwallettransactionSourceType = "ADDONS" | "CAMPUS_VISIT" | "COUPON_POINTS" | "EVENT" | "EVENT_PROMOTION" | "FEEDBACK_GIVEN" | "FIRST_LOGIN" | "FIRST_TXN" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "OTHER" | "PARKING" | "PARK_DELAY" | "POINTS_EXPIRY" | "POPUP_ORDER" | "POPUP_STORE" | "REFUND" | "RESTAURANT" | "SLOT_SPIN" | "STORE" | "SUBSEQUENT_TXN" | "%future added value";
export type EnumwallettransactionType = "CREDIT" | "DEBIT" | "%future added value";
export type PaymentHistorySortEnum = "ASC" | "DESC" | "STATUS_ASC" | "STATUS_DESC" | "%future added value";
export type transactionsScreenQuery$variables = {
  sort?: ReadonlyArray<PaymentHistorySortEnum | null> | null;
  userId: any;
};
export type transactionsScreenQuery$data = {
  readonly findUserById: {
    readonly wallet: {
      readonly currentBalance: number | null;
      readonly earnedAmount: number | null;
      readonly moneySaved: number | null;
      readonly totalBalance: number | null;
    } | null;
  } | null;
  readonly getPaymentHistory: ReadonlyArray<{
    readonly _id: any;
    readonly couponCode: string | null;
    readonly created_at: any | null;
    readonly displayName: string;
    readonly orderId: any;
    readonly priceDetails: {
      readonly couponAmount: number;
      readonly couponCashbackAmount: number;
      readonly csrAmount: number;
      readonly discountGiven: number;
      readonly grossAmount: number;
      readonly netAmount: number;
      readonly rewardsUsed: number;
      readonly tipAmount: number | null;
    };
    readonly rewardPoints: ReadonlyArray<{
      readonly amount: number;
      readonly source: {
        readonly type: EnumwallettransactionSourceType;
      };
      readonly type: EnumwallettransactionType;
    } | null> | null;
    readonly status: EnuminvoiceStatus | null;
    readonly userId: any | null;
  } | null> | null;
};
export type transactionsScreenQuery = {
  response: transactionsScreenQuery$data;
  variables: transactionsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "findUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserWallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "moneySaved",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "earnedAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "invoice",
    "kind": "LinkedField",
    "name": "getPaymentHistory",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "invoicePriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountGiven",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardsUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tipAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "couponAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "couponCashbackAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "csrAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "couponCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "wallettransaction",
        "kind": "LinkedField",
        "name": "rewardPoints",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "wallettransactionSource",
            "kind": "LinkedField",
            "name": "source",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transactionsScreenQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transactionsScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "230ba1c22675c9131d7cbe8183faf2f6",
    "id": null,
    "metadata": {},
    "name": "transactionsScreenQuery",
    "operationKind": "query",
    "text": "query transactionsScreenQuery(\n  $sort: [PaymentHistorySortEnum]\n  $userId: MongoID!\n) {\n  findUserById(_id: $userId) {\n    wallet {\n      totalBalance\n      currentBalance\n      moneySaved\n      earnedAmount\n    }\n  }\n  getPaymentHistory(sort: $sort) {\n    userId\n    displayName\n    priceDetails {\n      grossAmount\n      discountGiven\n      rewardsUsed\n      netAmount\n      tipAmount\n      couponAmount\n      couponCashbackAmount\n      csrAmount\n    }\n    couponCode\n    rewardPoints {\n      type\n      amount\n      source {\n        type\n      }\n    }\n    status\n    _id\n    created_at\n    orderId\n  }\n}\n"
  }
};
})();

(node as any).hash = "11509744aca4c74a6bc87abfa6bd397d";

export default node;
