import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { IconButton, Layout, TopNavigation } from '../../components/new';
import ParkingCard from '../../components/new/custom/ParkingCard';

const StaffRewardScreen = ({ navigation, route }) => {
  const [netStatus, setNetStatus] = useState(true);
  const onPressLeftIcon = () => {
    navigation.pop(2);
  };
  const { data } = route?.params ?? {};
  const {
    isUsed,
    isExpired,
    rewardUniqueId,
    rewardType,
    expireAt,
    created_at,
    user,
    gradientColors,
    showReedemedStatus,
    showAlreadyRedeemedStatus,
    showExpiredStatus,
  } = data ?? {};
  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
      />
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ padding: 16 }}>
        <ParkingCard
          owner={user?.name}
          expireAt={expireAt}
          createdAt={created_at}
          isUsed={isUsed}
          isExpired={isExpired}
          reward={rewardType?.replace(/([a-z])([A-Z])/g, '$1 $2')}
          rewardType={rewardType}
          rewardId={rewardUniqueId}
          showReedemedStatus={showReedemedStatus}
          showAlreadyRedeemedStatus={showAlreadyRedeemedStatus}
          showExpiredStatus={showExpiredStatus}
          gradientColors={gradientColors}
        />
      </ScrollView>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default StaffRewardScreen;
