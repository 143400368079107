/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { RefreshControl, useWindowDimensions } from 'react-native';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Layout, View, Text, IconButton } from '../../components/new';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import RenderItem from './RenderItem';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useUserStore, { UserRole } from '../../stores/userStore';
import { getTheme } from '../../themes/new/theme';
import usePartnerTransactionStore from '../../stores/partner/partnerTransactionStore';
import {
  partnerHomeScreenQuery,
  paymentHistoryFragmentV2,
} from './API/partnerHomeScreenQuery';
import {
  DeliveryStatus,
  dynamicHeightMaker,
  PaymentStatusEnum,
} from '../../utilities/helper';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useAuthStore from '../../stores/authStore';
import { useNodeFromPagination } from '../../hooks/useNodeFromPagination';
import { pagination } from '../../utilities/Constant';
import CustomFlatList from '../../components/new/custom/CustomFlatList';
import PaginationLoader from '../../components/new/custom/PaginationLoader';
import CachedImage from '../../components/new/custom/CachedImage';
import useCommonStore from '../../stores/commonStore';
import { PaymentOrderHistorySortEnum } from './API/__generated__/partnerHomeScreenQuery.graphql';

const noTransactions = require('../../../assets/images/no-transaction.webp');

const PartnerHomeScreen = (props) => {
  const {
    // ------ for partner home screen ------
    transactions,
    setTransactions,
    newTransactions, // for subscription bubble
    setNewTransactions, // for subscription
  } = usePartnerTransactionStore((state) => state);
  const userId = useUserStore((state) => state.id);

  const { queryOptions, variables, refresh, navigation } = props;
  const [refreshing, setRefreshing] = useState(false);
  const { width } = useWindowDimensions();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getPaymentHistoryFragment = useLazyLoadQuery(
    partnerHomeScreenQuery,
    {
      ...variables,
      count: pagination.defaultPageSize,
    },
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const {
    data: getPaymentHistoryPaginated,
    hasNext,
    loadNext,
    refetch: refetchHistory,
  } = usePaginationFragment(
    paymentHistoryFragmentV2,
    getPaymentHistoryFragment,
  );

  const getPaymentHistory = useNodeFromPagination(
    getPaymentHistoryPaginated?.findPopupOrders,
  );

  const setData = () => {
    setTransactions({ data: getPaymentHistory });
  };

  useEffect(() => {
    setData();
  }, [getPaymentHistory, refreshing]);

  const noRecentTransactions = () => (
    <View justifyContent="center" alignItems="center" mt="50%" flex={1}>
      <CachedImage
        source={noTransactions}
        style={{
          width: width - 4 * getTheme().space['2xl'],
          height: dynamicHeightMaker(
            width - 4 * getTheme().space['2xl'],
            0.705,
          ),
          resizeMode: 'contain',
        }}
      />
      <Text size="2xl" weight="medium" color="primary.400" mt="4xl">
        No Transactions Yet!
      </Text>
      <Text size="md" color="primary.200" mt="lg">
        Scan to create a new transaction
      </Text>
    </View>
  );

  const onPullToRefresh = useCallback(() => {
    setRefreshing(true);
    refetchHistory(
      {},
      {
        fetchPolicy: 'store-and-network',
        onComplete: () => {
          setRefreshing(false);
        },
      },
    );
  }, []);
  const renderItem = ({ item }) => <RenderItem item={item} refresh={refresh} />;

  return (
    <>
      <View flex={1}>
        <CustomFlatList
          scrollsToTop
          style={{
            flex: 1,
            paddingBottom: hasNext ? 0 : getTheme().space['4xl'],
          }}
          onEndReachedThreshold={0.7}
          onEndReached={() => {
            if (hasNext) {
              loadNext(pagination.defaultPageSize);
            }
          }}
          data={transactions}
          keyExtractor={(item) => item?._id}
          renderItem={renderItem}
          showsVerticalScrollIndicator
          ListEmptyComponent={noRecentTransactions}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onPullToRefresh}
            />
          }
          ListFooterComponentStyle={{
            margin: 0,
            padding: 0,
          }}
          ListFooterComponent={() => {
            if (hasNext) return <PaginationLoader />;
            return null;
          }}
        />
      </View>
    </>
  );
};

const PartnerHomeScreenWrapper = ({ navigation }) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const userId = useUserStore((state) => state.id);
  const userRole = useUserStore((state) => state.role);
  const { campusId } = useCommonStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });
  const [netStatus, setNetStatus] = useState(true);
  const { bottom } = useSafeAreaInsets();

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'store-and-network',
      }));
    },
    [
      /* ... */
    ],
  );

  useFocusEffect(
    useCallback(() => {
      refresh();
      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  const filter = {
    status: [PaymentStatusEnum.PENDING, PaymentStatusEnum.SUCCESSFUL],
    orderStatus: DeliveryStatus.UNDELIVERED,
    campus: {
      _id: campusId,
    },
  };

  return (
    <Layout level={2} edges={['top']}>
      <View py="2xl" px="2xl">
        <Text size="2xl" color="primary.300">
          Transactions
        </Text>
      </View>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <PartnerHomeScreen
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
            variables={{
              filter,
              sort: ['STATUS_DESC', 'ASC'] as PaymentOrderHistorySortEnum[],
              limit: 100,
            }}
          />
          <View
            position="absolute"
            right="2xl"
            bottom={bottom + getTheme().space['2xl']}
          >
            <IconButton
              appearance="filled"
              size="3xl"
              iconColor="primary.10"
              // bg="primary.10"
              // text="SCAN"
              iconSize="xl"
              name={
                userRole === UserRole.MOMENT_STAFF_PARTNER
                  ? 'print-400'
                  : 'scan-qr-outline-300'
              }
              onPress={() => {
                firebaseEventLogger('partnerHomescreen__QrPress_Screen', {
                  buttonName: 'QrPress',
                  screenName: 'partnerHomescreen',
                  userType: userRole,
                  interactionType: InteractionType.TAP,
                  isLoggedIn,
                  partnerId: userId,
                });
                if (userRole === UserRole.MOMENT_STAFF_PARTNER) {
                  navigation.navigate('PrintMoments');
                } else {
                  navigation.navigate('AddAmountScreen');
                }
              }}
            />
          </View>
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default PartnerHomeScreenWrapper;
