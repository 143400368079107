import React, { useCallback } from 'react';
import { useFragment } from 'react-relay';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Pressable, Text, View } from '../../../../components/new';
import { homeScreenSpinCountFragment } from '../API/homeScreenQuery';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import { fetchRewards } from '../../games/slotMachine/API/slotMachineQuery';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import useUserStore from '../../../../stores/userStore';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';
import CountDown from '../../payment/CountDown';

const SpinAndWinWidget = ({ fragmentRef }) => {
  const userId = useUserStore((state) => state.id);
  const spinData = useFragment(homeScreenSpinCountFragment, fragmentRef);

  const { width } = useWindowDimensions();
  const seconds = spinData.freezedTill ?? 0;

  const navigation = useNavigation<any>();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const handleOnPress = useCallback(() => {
    firebaseEventLogger('PlayWinComponent__spin_Tap', {
      buttonName: 'spin',
      screenName: 'PlayWinComponent',
      userType: 'user',
      interactionType: 'tap',
    });

    fetchRewards({
      ownerId: userId,
      userId,
    }).then((r) => {
      // extracting spins left
      if (r?.getSpinsCount) {
        if (r?.getSpinsCount?.spinsLeft === 0) {
          const msg = 'No spins left, Try after 1 minute!';
          dispatchSnackbar({
            msg,
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
        } else {
          navigation.navigate('slotMachineScreen');
        }
      }
    });
  }, []);

  return (
    <Pressable disabled={seconds !== 0} onPress={handleOnPress}>
      <View
        shadow="sm"
        borderRadius="lg"
        bg="background.primary.elevation"
        borderColor="grey.50"
        borderWidth="xs"
        height={200}
        width={width / 2 - 24}
        justifyContent="center"
        alignItems="center"
      >
        <View mx="2xl" justifyContent="center" alignItems="center">
          <CustomLottieView
            autoPlay
            loop
            source={require('../../../../../assets/lottie/slot-machine.json')}
            style={{
              width: width / 3,
              height: 60,
            }}
            containerWidth={width / 3}
          />
          <Text size="xl" mt="2xl" color="primary.400" weight="medium">
            Spin & Win
          </Text>
        </View>
        {seconds > 0 && (
          <>
            <View
              borderRadius="lg"
              position="absolute"
              bg="opacity.80"
              height="100%"
              width="100%"
            />
            <View
              position="absolute"
              justifyContent="center"
              width="100%"
              height="100%"
            >
              <View
                py="lg"
                bg="primary.50"
                alignItems="center"
                top={0}
                bottom={0}
              >
                <CountDown countDown={seconds} onComplete={() => null} v2 />
              </View>
            </View>
          </>
        )}
      </View>
    </Pressable>
  );
};

export default SpinAndWinWidget;
