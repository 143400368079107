import React, { useCallback, useEffect, useRef } from 'react';
import { ActionSheetRef } from 'react-native-actions-sheet';
import useLoginModalStore from '../../stores/loginModalStore';
import { Button, Text, View } from '../new';
import { InputStates } from '../new/primitive/OTPInput/helpers';
import ClubMemberShipCarousel from '../new/custom/Carousel/ClubMemberShipCarousel';
import BottomSheetV2 from '../new/composites/BottomSheet/BottomSheetV2';
import { getTheme } from '../../themes/new/theme';

const ClubMembershipSheetModal = () => {
  const loginState = useLoginModalStore((state) => state);

  const bottomSheetModalRef = useRef<ActionSheetRef>(null);

  const handleOnDismiss = useCallback(() => {
    loginState.setShowWelcomeModal(false);
  }, []);

  useEffect(() => {
    if (loginState.showWelcomeModal) {
      bottomSheetModalRef?.current?.show();
    } else {
      bottomSheetModalRef?.current?.hide();
    }
  }, [loginState.showWelcomeModal]);

  if (!loginState.showWelcomeModal) {
    return null;
  }

  return (
    <BottomSheetV2
      ref={bottomSheetModalRef}
      onClose={handleOnDismiss}
      insets={false}
    >
      <View mt="xl" px="2xl">
        <Text color="primary.400" size="xl" textAlign="center" mb="4xl">
          32nd Club Membership Activated!
        </Text>
        <View>
          <ClubMemberShipCarousel />
        </View>
        <Button
          size="lg"
          status="primary"
          state={InputStates.active}
          onPress={handleOnDismiss}
          style={{ marginTop: getTheme().space['4xl'] }}
        >
          Explore The Club
        </Button>
      </View>
    </BottomSheetV2>
  );
};

export default ClubMembershipSheetModal;
