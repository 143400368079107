import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const searchUserScreenQuery = graphql`
  query searchUserScreenQuery($filter: searchUsersFilterInput!) {
    searchUsers(filter: $filter) {
      _id
      name {
        first
        last
      }
      contact {
        phone {
          prefix
          number
          verified
        }
      }
      profileImage
      parkingVisitCount
    }
  }
`;
