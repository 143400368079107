import React from 'react';
import { View, Text } from '../../../components/new';
import AutoBottomSheet from '../../../components/new/custom/AutoBottomSheet';
import { FontWeights } from '../../../themes/new/helper';
import CachedImage from '../../../components/new/custom/CachedImage';

const PointsEarnedBottomsheet = ({
  bottomSheetModalRef,
  totalPointsEarned,
  rewardName,
}) => {
  return (
    <AutoBottomSheet bottomSheetRef={bottomSheetModalRef}>
      <View px="2xl" my="7xl">
        <View borderBottomWidth="xs" borderBottomColor="grey.100" py="lg">
          <View
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text size="sm" color="primary.500" weight={FontWeights.MEDIUM}>
              {rewardName}
            </Text>
            <View flexDirection="row" alignItems="center">
              <Text size="sm" color="primary.500" weight={FontWeights.MEDIUM}>
                ₹{totalPointsEarned}
              </Text>
              <View>
                <CachedImage
                  source={require('../../../../assets/images/coins.webp')}
                  style={{
                    width: 20,
                    height: 20,
                    marginLeft: 1,
                    resizeMode: 'contain',
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </AutoBottomSheet>
  );
};

export default PointsEarnedBottomsheet;
