import { Mixpanel } from 'mixpanel-react-native';
import AppConstants from './AppConstants';

const trackAutomaticEvents = true;

const mixpanel = new Mixpanel(
  AppConstants.MIXPANEL_TOKEN,
  trackAutomaticEvents,
);

mixpanel
  .init()
  .then((res) => {
    console.log('Mixpanel initialized', res);
  })
  .catch((err) => {
    console.log('Mixpanel error', err);
  });

export default mixpanel;
