// eslint-disable-next-line import/prefer-default-export

export const firebaseConfig = {
  apiKey: 'AIzaSyC68dj32h2gvRykcEYstLDNqdnwIW8U4T4',
  authDomain: 'hub-app-ca6e5.firebaseapp.com',
  projectId: 'hub-app-ca6e5',
  storageBucket: 'hub-app-ca6e5.appspot.com',
  messagingSenderId: '354264869839',
  appId: '1:354264869839:web:5c31c7441d68f637d040ba',
  measurementId: 'G-N3TCQRZKPQ',
};

export const firebaseConfigStaging = {
  apiKey: 'AIzaSyC68dj32h2gvRykcEYstLDNqdnwIW8U4T4',
  authDomain: 'hub-app-ca6e5.firebaseapp.com',
  projectId: 'hub-app-ca6e5',
  storageBucket: 'hub-app-ca6e5.appspot.com',
  messagingSenderId: '354264869839',
  appId: '1:354264869839:web:224142afc419fcded040ba',
  measurementId: 'G-WVD3F6MBTV',
};

// plate recognizer, https://platerecognizer.com
export const anprConfig = {
  maxScans: 3,
  api: 'https://api.platerecognizer.com/v1/plate-reader/',
  token: 'f6ae4279c462f90952eeee8eb35458b03cb330eb',
};

export const valetBonusAmount = {
  onTime: 5,
  delayed: 0,
};

export const controllerBonusAmount = {
  onTime: 1,
  delayed: 0,
};

export const pagination = {
  defaultPageSize: 15,
};
export const network = {
  pullToRefreshFetchPolicy: 'store-and-network',
};
export const kiosk = {
  SESSION_TIMEOUT: 15, // seconds
  THEME_POLLING_INTERVAL: 300 * 1000, // MS
  VALET_DESK_NO: 7303293670,
  SCANNED_STRING_LENGTH: 61,
  APP_QR_LINK:
    'https://club.32nd.com/redirectToPlatformStore?deeplink=user/track&utm_source=qr&utm_medium=kiosk&utm_campaign=conversion',
};

export const blackMembership = {
  BASE_PRICE: 1999,
};

export const defaultConatactNumber32ND = '+919870209781';
