/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useCallback,
  Suspense,
  useMemo,
  useEffect,
} from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { FlatList } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { debounce } from 'lodash';
import {
  Layout,
  View,
  IconButton,
  TopNavigation,
  Text,
  Icon,
} from '../../components/new';
import Loading from '../../components/loading.component';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useCommonStore from '../../stores/commonStore';
import useUserStore, { UserRole } from '../../stores/userStore';
import ValetCard from '../../components/new/custom/OverviewSearchValetCard';
import { getTheme } from '../../themes/new/theme';
import SearchInput from '../../components/new/composites/SearchInput/SearchInput';
import unavailableValet from '../../../assets/images/assignTask.webp';
import assignedValet from '../../../assets/images/alert.webp';

import { getValetWorkLogStatus, navigateBack } from '../../utilities/helper';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import {
  searchValetScreenQuery,
  searchValetScreenQueryFragment,
} from './API/searchValetScreenQuery';
import AssignValetModal from './AssignValetModal';
import AppConstants from '../../utilities/AppConstants';
import { unwrapPagedData } from '../../utilities/paginationUtilities';
import { pagination } from '../../utilities/Constant';
import PaginationLoader from '../../components/new/custom/PaginationLoader';

interface SearchValetScreenProps {
  queryOptions: any;
  variables: any;
  taskId: string;
  setFilter: (val) => void;
  filter: any;
  fetchKey: any;
}

const SearchValetScreen = ({
  queryOptions,
  variables,
  taskId,
  setFilter,
  filter,
  fetchKey,
}: SearchValetScreenProps) => {
  const navigation = useNavigation<any>();
  const { role } = useUserStore((state) => state);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const searchValetRef = useLazyLoadQuery(searchValetScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });

  const {
    data: paginatedData,
    loadNext: fetchMoreUserList,
    hasNext: hasMoreUsers,
  } = usePaginationFragment(searchValetScreenQueryFragment, searchValetRef);
  const searchValet = unwrapPagedData(paginatedData?.searchValetV2?.edges);

  const [searchValue, setSearchValue] = useState('');
  const { campusId } = useCommonStore((state) => state);
  const [rightIconName, setRightIconName] = useState('search-outline-400');
  const [modalVisible, setModalVisible] = useState({
    state: false,
    status: '',
    valetName: '',
    contactNumber: '',
  });

  const { IMAGEKIT_PROD_URI } = AppConstants;

  const renderItem = ({ item, index }) => {
    const fullName = `${item?.name?.first} ${
      item?.name?.last !== null ? item?.name?.last : ''
    }`;
    const imageLink = item?.profileImage
      ? IMAGEKIT_PROD_URI + item?.profileImage
      : 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
    const contactNumber = `${item?.contact?.phone?.prefix} ${item?.contact?.phone?.number}`;

    const handleCardPress = async () => {
      firebaseEventLogger('searchValet__searchNumber_Tap', {
        buttonName: 'searchNumber',
        screenName: 'searchValet',
        userType: `${role}`,
        interactionType: InteractionType.TAP,
        taskId: `${taskId}`,
        customerName: `${fullName}`,
        visitCount: `${item?.parkingVisitCount}`,
        phoneNo: `${contactNumber}`,
      });
      if (item?.workLogStatus?.status === 'available') {
        navigation.navigate('assignTask', {
          taskId,
          phoneNumber: item?.contact?.phone?.number,
        });
      } else {
        setModalVisible({
          state: true,
          status: item?.workLogStatus?.status,
          valetName: fullName,
          contactNumber: item?.contact?.phone?.number,
        });
      }
    };

    const status = getValetWorkLogStatus(item?.workLogStatus?.status);

    return (
      <View mt={index !== 0 ? '2xl' : 0}>
        <ValetCard
          fullName={fullName}
          onPress={handleCardPress}
          imageLink={imageLink}
          status={status}
        />
      </View>
    );
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(setSearchValue, 300),
    [],
  );
  useEffect(
    () => () => {
      debouncedChangeHandler.cancel();
    },
    [],
  );

  // ------ on press cross icon in input ------
  const onRightSearchIconPress = () => {
    // if (searchValue?.length > 0) {
    setSearchValue('');
    setRightIconName('search-outline-400');
    setFilter({
      role: { name: UserRole.VALET, meta: { campus: { _id: campusId } } },
    });
    // }
  };

  // ------ handle text change for input ------
  const handleOnTextChange = (value) => {
    setSearchValue(value);
    if (value?.length > 0) {
      setRightIconName('close-outline-300');
    }
    if (value?.length <= 0) {
      setRightIconName('search-outline-400');
      setFilter({
        role: { name: UserRole.VALET, meta: { campus: { _id: campusId } } },
      });
    }
    if (value?.length >= 2) {
      setFilter({
        search: { nameOrPhoneOrCode: value },
        role: { name: UserRole.VALET, meta: { campus: { _id: campusId } } },
      });
    }
  };

  const modalCallBack = () => {
    if (modalVisible?.status === 'busy') {
      setModalVisible({
        state: false,
        status: '',
        valetName: '',
        contactNumber: '',
      });
    } else {
      navigation.navigate('assignTask', {
        taskId,
        phoneNumber: modalVisible?.contactNumber,
      });

      setModalVisible({
        state: false,
        status: '',
        valetName: '',
        contactNumber: '',
      });
    }
  };

  const cancelHandler = () => {
    setModalVisible({
      state: false,
      status: '',
      valetName: '',
      contactNumber: '',
    });
  };

  return (
    <View flex={1}>
      <View px="2xl" mt="4xl" mb="2xl">
        <SearchInput
          rightIconName={rightIconName}
          placeholder="Search by name or valet code"
          onRightIconClick={onRightSearchIconPress}
          value={searchValue}
          onChangeText={handleOnTextChange}
          leftIconName=""
          inputMode="words"
          keyboardType="words"
          autoFocus
          //   maxLength={10}
        />
      </View>
      {searchValet && searchValet?.length ? (
        <FlatList
          contentContainerStyle={{ paddingBottom: getTheme().space['4xl'] }}
          data={searchValet}
          renderItem={renderItem}
          onEndReachedThreshold={0.2}
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd < 0) return;
            if (hasMoreUsers) {
              fetchMoreUserList(pagination.defaultPageSize);
            }
          }}
          ListFooterComponent={() => {
            if (hasMoreUsers) return <PaginationLoader />;
            return null;
          }}
        />
      ) : searchValue?.length >= 2 ? (
        <View flex={1} alignItems="center" mt="9xl">
          <Icon size="3xl+3xl" color="primary.100" name="search-outline-400" />
          <Text
            size="xl"
            color="primary.100"
            weight="medium"
            textAlign="center"
            mt="2xl"
          >
            No Results for “{filter?.search?.nameOrPhoneOrCode}”
          </Text>
        </View>
      ) : (
        <></>
      )}
      <AssignValetModal
        modalVisible={modalVisible?.state}
        setModalVisible={setModalVisible}
        img={
          // eslint-disable-next-line no-nested-ternary
          modalVisible?.status === 'busy' ? assignedValet : unavailableValet
        }
        title={
          modalVisible?.status === 'busy'
            ? 'Valet already working!'
            : `${modalVisible?.valetName} is unavailable, do you want to assign task to him?`
        }
        subTitle={
          modalVisible?.status === 'busy' ? 'You cannot assign this task' : ``
        }
        firstCTAText={modalVisible.status === 'busy' ? 'Ok' : 'Yes'}
        secondCTAText="Cancel"
        firstCTATextHandler={modalCallBack}
        secondCTATextHandler={cancelHandler}
      />
    </View>
  );
};

const SearchValetScreenWrapper = ({ navigation, route }) => {
  const { taskId } = route?.params ?? {};
  const { campusId } = useCommonStore((state) => state);
  const [filter, setFilter] = useState({
    // search: { nameOrPhoneOrCode: 'jfk' },
    role: { name: UserRole.VALET, meta: { campus: { _id: campusId } } },
  });
  const { role } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  useFocusEffect(
    useCallback(() => {
      refresh();
      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  const searchFilter = {};

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Assign Task"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <SearchValetScreen
            variables={{
              count: pagination.defaultPageSize,
              filter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            taskId={taskId}
            setFilter={setFilter}
            filter={filter}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default SearchValetScreenWrapper;
