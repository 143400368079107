import React, { forwardRef } from 'react';
import { View } from '../../../components/new';
import TransactionDetailsPanel from './TransactionDetailsPanel';
import { ITransaction } from '../types';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';

interface Props {
  transaction: ITransaction | null;
  onDismiss: () => void;
  insets?: boolean;
}

const TransactionDetailsBottomSheet = forwardRef((props: Props, ref) => {
  const { transaction, onDismiss, insets } = props;

  return (
    <BottomSheetV2 ref={ref} onClose={onDismiss} insets={insets}>
      <View>
        {transaction && (
          <View px="2xl" pt="2xl">
            <TransactionDetailsPanel transaction={transaction} mode="REVENUE" />
          </View>
        )}
      </View>
    </BottomSheetV2>
  );
});

export default TransactionDetailsBottomSheet;
