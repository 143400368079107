import { Image } from 'react-native';
import React, { useState } from 'react';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useTheme } from 'styled-components/native';
import { get } from 'lodash';

import {
  Avatar,
  Button,
  Divider,
  Pressable,
  Text,
  View,
} from '../../../components/new';

import useUserStore, { UserRole } from '../../../stores/userStore';
import useProfileCardData from './hooks/useProfileCardData';
import S3Delete from '../../../utilities/S3Delete';
import ControllerBottomSheet from '../../../components/modals/ControllerBottomSheet.component';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';

const ProfileCard = ({ refreshAPI, apiData: parentAPIData }) => {
  const theme = useTheme();
  const { showActionSheetWithOptions } = useActionSheet();

  const {
    apiData,
    buttonActionText,
    addLog,
    handlePunch,
    isPunchedIn,
    bottomSheetModalRef,
    initialModalSnapPoints,
    handleOnDismissModal,
    onlineTime,
    openImagePickerAsync,
    userRole,
    controllerRole,
    roleText,
    controllerBottomSheetModalRef,
    location,
    openControllerBottomSheet,
  } = useProfileCardData({ refreshAPI, apiData: parentAPIData });

  const userId = useUserStore((state) => state.id);

  const [options, setOptions] = useState([
    'Choose Photo',
    'Take Photo',
    'Remove Photo',
    'Cancel',
  ]);

  const fullName = `${apiData?.name?.first || ''} ${apiData?.name?.last || ''}`;
  return (
    <View>
      <View justifyContent="center" alignItems="center" mb="2xl">
        <View>
          <Avatar
            shape="round"
            size="giant"
            level={3}
            // style={{ marginTop: 5 }}
            source={apiData?.profileImage || ''}
            name={fullName}
            resizeMode="cover"
          />
          <Pressable
            style={{ position: 'absolute', bottom: 0, right: 0 }}
            onPress={() => {
              // firebaseEventLogger('account__profilePhotoName_Tap', {
              //   buttonName: 'profilePhotoName',
              //   screenName: 'account',
              //   userType: 'user',
              //   interactionType: 'tap',
              // });
              showActionSheetWithOptions(
                {
                  options,
                  cancelButtonIndex: options?.length - 1,
                  destructiveButtonIndex: options?.length - 2,
                  destructiveColor: get(theme.colors, 'error.500'),
                  containerStyle: {
                    backgroundColor: get(theme.colors, 'primary.10'),
                  },
                  textStyle: {
                    color: get(theme.colors, 'info.500'),
                    textAlign: 'center',
                    paddingLeft: get(theme.space, '2xl'),
                  },
                },
                (buttonIndex) => {
                  if (buttonIndex === 0) {
                    openImagePickerAsync('gallery');
                  }
                  if (buttonIndex === 1) {
                    openImagePickerAsync('camera');
                  }
                  if (buttonIndex === 2 && apiData?.profileImage) {
                    S3Delete(apiData?.profileImage, userId, refreshAPI);
                  }
                },
              );
            }}
            hitSlop={{ top: 10, bottom: 0, left: 0, right: 0 }}
          >
            <Image
              style={{ width: 24, height: 24 }}
              source={require('../../../../assets/edit-pencil.png')}
            />
          </Pressable>
        </View>
      </View>
      <View mb="lg">
        <Text size="2xl" color="primary.500" textAlign="center">
          {fullName}
        </Text>
      </View>
      {userRole === UserRole.USER && (
        <View>
          <Text size="sm" color="primary.100" textAlign="center">
            Member Since {apiData?.userSince}
          </Text>
        </View>
      )}
      {!(userRole === UserRole.USER) && (
        <>
          <View>
            {userRole !== UserRole.CONTROLLER && (
              <Text size="sm" color="primary.100" textAlign="center">
                {roleText} Since {apiData?.userSince}
              </Text>
            )}
            {userRole === UserRole.CONTROLLER && controllerRole && (
              <Button
                size="md"
                appearance="ghost"
                status="info"
                onPress={openControllerBottomSheet}
              >
                {controllerRole === 'PARK' ? 'Parking' : 'Recall'} {roleText} -{' '}
                {location}
              </Button>
            )}
            {userRole === UserRole.CONTROLLER && !controllerRole && (
              <Button
                size="md"
                appearance="ghost"
                status="info"
                onPress={openControllerBottomSheet}
              >
                Select Role
              </Button>
            )}
          </View>
          <View my="4xl">
            <View flexDirection="row">
              <View flex={1}>
                <View>
                  <View mb="xs">
                    <Text size="xl" color="primary.500" textAlign="center">
                      {`${apiData?.workSummary?.tasksDone || '-'}`}
                    </Text>
                  </View>
                  <View>
                    <Text size="xs" color="primary.200" textAlign="center">
                      Tasks
                    </Text>
                  </View>
                </View>
              </View>
              <View>
                <Divider horizontal />
              </View>
              <View flex={1}>
                <View>
                  <View mb="xs">
                    <Text size="xl" color="primary.500" textAlign="center">
                      {onlineTime}
                    </Text>
                  </View>
                  <View>
                    <Text size="xs" color="primary.200" textAlign="center">
                      Online
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View>
            <Button
              size="lg"
              onPress={() => {
                // showModal();
                handlePunch();
              }}
              appearance={
                buttonActionText.toLocaleLowerCase() === 'punch-out'
                  ? 'outline'
                  : 'filled'
              }
            >
              {buttonActionText}
            </Button>
          </View>
          <BottomSheetV2 ref={bottomSheetModalRef}>
            <View mx="2xl">
              <View my="2xl">
                <Text size="xl" color="primary.200">
                  Are you sure you want to {buttonActionText}?
                </Text>
              </View>
              <View>
                <Divider />
              </View>
              <View mt="3xl">
                <Button
                  size="lg"
                  onPress={() => {
                    addLog('punch_out');
                  }}
                >
                  Yes, {buttonActionText}
                </Button>
              </View>
              <View mt="2xl">
                <Button
                  size="lg"
                  appearance="outline"
                  onPress={() => {
                    handleOnDismissModal();
                  }}
                >
                  Cancel
                </Button>
              </View>
            </View>
          </BottomSheetV2>
          <ControllerBottomSheet
            ref={controllerBottomSheetModalRef}
            punchInCallback={refreshAPI}
          />
        </>
      )}
    </View>
  );
};

export default ProfileCard;
