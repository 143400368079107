import { useCallback } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { trackDiscountApiDiscountScreenQuery$data } from './API/__generated__/trackDiscountApiDiscountScreenQuery.graphql';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { trackDiscountScreenQuery } from './API/trackDiscountApi';

/* eslint-disable-next-line import/prefer-default-export */
export const useTrackDiscount = (
  queryOptions: NewErrorBoundaryParentState,
  refresh: () => void,
) => {
  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );
  const {
    getRestaurantPartnerDiscountTrack,
  }: trackDiscountApiDiscountScreenQuery$data = useLazyLoadQuery(
    trackDiscountScreenQuery,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  return {
    getRestaurantPartnerDiscountTrack,
  };
};
