import React from 'react';
import { Text, View } from '../../../components/new';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';

const EventsListingEmptyPlaceholder = () => {
  return (
    <View height="100%" justifyContent="center" alignItems="center" px="2xl">
      <View width={301}>
        <CustomLottieView
          autoPlay
          loop
          style={{ width: 301, height: 301 }}
          source={require('../../../../assets/lottie/rocket.json')}
          containerWidth={301}
        />
      </View>
      <Text size="2xl" weight="medium" color="primary.400" mt="lg">
        Events coming soon!
      </Text>
      <Text
        size="md"
        weight="medium"
        color="primary.200"
        mt="lg"
        textAlign="center"
      >
        There are no events at the moment, Please check back later!
      </Text>
    </View>
  );
};

export default EventsListingEmptyPlaceholder;
