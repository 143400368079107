/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, Suspense, useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { FlatList } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  View,
  IconButton,
  TopNavigation,
  Button,
} from '../../../components/new';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useCommonStore from '../../../stores/commonStore';
import useUserStore, { UserRole } from '../../../stores/userStore';
import { selectCustomerScreenQuery } from './API/selectCustomerScreenQuery';
import UserCard from '../../../components/new/custom/UserCard';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { updateTaskMutation } from './API/updateTaskMutation';
import { findTaskQuery } from './API/findTaskQuery';
import { maskedNumber, navigateBack } from '../../../utilities/helper';
import { findVehicleQuery } from './API/findVehicleQuery';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import AppConstants from '../../../utilities/AppConstants';

const SelectCustomerScreen = (props) => {
  const { queryOptions, variables, taskId } = props;
  const { role } = useUserStore((state) => state);
  const navigation = useNavigation<any>();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { searchUsers } = useLazyLoadQuery(
    selectCustomerScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const { IMAGEKIT_PROD_URI } = AppConstants;

  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [userCardDisabled, setUserCardDisabled] = useState(false);

  const campusID = useCommonStore((state) => state.campusId);

  const renderItem = ({ item, index }) => {
    const fullName = `${item?.name?.first} ${
      item?.name?.last !== null ? item?.name?.last : ''
    }`;
    const verified = item?.contact?.phone?.verified || false;
    const imageLink = item?.profileImage
      ? IMAGEKIT_PROD_URI + item?.profileImage
      : 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
    const prefix = item?.contact?.phone?.prefix;
    const contactNumber = item?.contact?.phone?.number;

    const handleCardPress = async () => {
      firebaseEventLogger('selectCustomer__phoneNumber_Tap', {
        buttonName: 'phoneNumber',
        screenName: 'selectCustomer',
        userType: `${role}`,
        interactionType: InteractionType.TAP,
        taskId: `${taskId}`,
        customerName: `${fullName}`,
        visitCount: `${item?.parkingVisitCount}`,
        phoneNo: `${prefix} ${contactNumber}`,
      });
      setUserCardDisabled(true);
      try {
        // ------ step-1 -> finding task on the basis of taskId and campusId ------
        let task = await findTaskQuery({
          filter: { _id: taskId, campus: { _id: campusID } },
        });
        task = task?.findTasks[0];

        // ------ step-2 -> finding vehicle with vehicle number and ownerId ------
        let vehicles = await findVehicleQuery({
          filter: {
            registration: { plate: task?.vehicle?.registration?.plate },
            ownerID: item?._id,
          },
        });
        vehicles = vehicles?.findVehicles;

        // ------ step-3 -> updating task for vehicleId and ownerId ------
        await updateTaskMutation({
          _id: taskId,
          record: { vehicleId: vehicles[0]?._id, ownerId: item?._id },
        });
        setUserCardDisabled(false);

        // ------ step-4 -> navigating to start ride screen ------
        navigation.navigate('StartRideScreen', {
          taskId,
        });
      } catch (error) {
        setUserCardDisabled(false);
        dispatchSnackbar({
          msg: error?.res?.errors[0]?.message || 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
      }
    };
    return (
      <View mt={index !== 0 ? '2xl' : 0}>
        <UserCard
          fullName={verified ? fullName : ''}
          number={`${prefix} ${maskedNumber(contactNumber)}`}
          onPress={handleCardPress}
          isVerified={item?.contact?.phone?.verified}
          imageLink={imageLink}
          parkingCount={
            item?.parkingVisitCount < 9
              ? item?.parkingVisitCount <= 0
                ? 0
                : `0${item?.parkingVisitCount}`
              : item?.parkingVisitCount
          }
          disabled={userCardDisabled}
        />
      </View>
    );
  };

  useEffect(() => {
    if (searchUsers?.length <= 0) {
      navigation.replace('SearchUserScreen', {
        taskId,
      });
    }
  }, [searchUsers]);

  return (
    <View flex={1} mt="4xl">
      <FlatList
        data={searchUsers}
        renderItem={renderItem}
        ListFooterComponent={() => (
          <View mt="4xl" alignItems="center">
            <Button
              size="lg"
              appearance="ghost"
              status="info"
              onPress={() => {
                firebaseEventLogger('selectCustomer__addNewOrSearch_Tap', {
                  buttonName: 'addNewOrSearch',
                  screenName: 'selectCustomer',
                  userType: UserRole.VALET,
                  interactionType: InteractionType.TAP,
                  taskId: `${taskId}`,
                });
                navigation.navigate('SearchUserScreen', {
                  taskId,
                });
              }}
            >
              + Add new or Search
            </Button>
          </View>
        )}
      />
    </View>
  );
};

const SelectCustomerScreenWrapper = ({ navigation, route }) => {
  const { vehicleNumber, taskId } = route?.params ?? {};
  const { role } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  useFocusEffect(
    useCallback(() => {
      refresh();
      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  const filter = {
    registration: {
      plate: vehicleNumber,
    },
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Select Customer"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <SelectCustomerScreen
            variables={{
              filter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            taskId={taskId}
            vehicleNumber={vehicleNumber}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default SelectCustomerScreenWrapper;
