/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  Suspense,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Platform, Dimensions, FlatList } from 'react-native';
import { graphql, useLazyLoadQuery, useFragment } from 'react-relay';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useIsFocused } from '@react-navigation/native';
import {
  Layout,
  View,
  Text,
  Pressable,
  TopNavigation,
  Avatar,
} from '../../../components/new';
import DetailCard from '../../../components/new/custom/DetailCard';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import { getTheme } from '../../../themes/new/theme';
import {
  RestaurantType,
  SplitAndCapitalise,
  dynamicHeightMaker,
} from '../../../utilities/helper';
import useCommonStore from '../../../stores/commonStore';
import useUserStore from '../../../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useAuthStore from '../../../stores/authStore';
import EventBus, { EventBusEventsEnum } from '../../../utilities/EventBus';
import StoresScreenPlaceholder from '../shimmerPlaceholders/screens/StoresScreenPlaceholder';
import AppConstants from '../../../utilities/AppConstants';
import { Loading } from '../../../components';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import CachedImage from '../../../components/new/custom/CachedImage';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const restaurantsQuery = graphql`
  query storeListQuery(
    $filter: findRestaurantsFilterInput!
    $includeFindUser: Boolean!
    $id: MongoID!
    $sort: SortFindManyrestaurants2Input
  ) {
    findRestaurants2(filter: $filter, sort: $sort) {
      ...storeListFragment
    }

    findUserById(_id: $id) @include(if: $includeFindUser) {
      wallet {
        currentBalance
      }
    }
  }
`;

const RenderStoresScreen = (props) => {
  const { queryOptions, variables, navigation } = props;
  const { top, bottom } = useSafeAreaInsets();

  const [viewableItem, setViewableItem] = useState('');
  const viewabilityConfigRef = useRef({
    itemVisiblePercentThreshold: 100,
    minimumViewTime: 50,
  });

  const onViewableItemsChangedCallback = useCallback(({ viewableItems }) => {
    if (viewableItems[0]?.isViewable) {
      setViewableItem(viewableItems[0]?.key);
    }
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const res = useLazyLoadQuery(restaurantsQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });

  const { findRestaurants2, findUserById } = res;

  const sortFn = (a, b) => {
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    return 0;
  };

  const sortByPrimary = (a, b) => {
    return b.primary - a.primary;
  };

  const renderItem = ({ item, index }) => {
    const coverImages =
      item?.mediaGallery &&
      item?.mediaGallery?.length &&
      JSON.parse(JSON.stringify(item?.mediaGallery))
        .filter(
          (ele) =>
            ele?.sectionTitle !== 'Awards___Recognitions' &&
            ele?.sectionTitle !== 'Founder_Story',
        )
        .sort(sortFn)
        .map((ele) => ele?.media && ele?.media.sort(sortFn))
        .flat()
        .sort((a, b) => b.cover - a.cover);
    const tagLine =
      item?.description &&
      item?.description?.cusines?.length &&
      JSON.parse(JSON.stringify(item?.description?.cusines))
        .sort(sortByPrimary)
        .map((ele) => {
          return ele?.name === 'fastfood'
            ? 'Fast Food'
            : SplitAndCapitalise(ele.name);
        });

    const canUsePoints = item?.payBill?.pointsEnabled?.redeem;

    return (
      <DarkThemeWrapper>
        <View mt={item.index === 0 ? '2xl' : 'xl'} key={item?._id}>
          <DetailCard
            discountPercentage={item?.payBillPercentage}
            pointsEnabled={item?.payBill?.pointsEnabled}
            currentBalance={
              canUsePoints ? findUserById?.wallet?.currentBalance : 0
            }
            id={item?._id}
            key={item?._id}
            displayName={item?.displayName}
            tagLine={tagLine}
            viewableItem={viewableItem}
            // description={item?.description?.summary}
            coverImages={coverImages && coverImages?.length ? coverImages : []}
            ratingScore={item?.overAllRating > 0 ? item?.overAllRating : 0}
            onPress={() => {
              firebaseEventLogger('ShopCardClicked', {
                restaurantId: item._id,
              });
              navigation.navigate('RestaurantScreen', {
                restaurantId: item._id,
              });
            }}
          />
        </View>
      </DarkThemeWrapper>
    );
  };

  const restaurantLists = useFragment(
    graphql`
      fragment storeListFragment on restaurants2 @relay(plural: true) {
        _id
        displayName
        logo
        menuId
        description {
          summary
          tagLine
          cusines {
            _id
            name
            primary
          }
          tags {
            name
            icon {
              iconName
              color
            }
            _id
          }
          cost2pax
        }
        overAllRating
        mediaGallery {
          _id
          sectionTitle
          description
          media {
            cover
            prime
            mediaURL
            mediaType
            sectionCover
            displayOrder
          }
          displayOrder
        }
        payBillPercentage
        payBill {
          payEnabled
          pointsEnabled {
            redeem
            reward
          }
        }
      }
    `,
    findRestaurants2,
  );

  const flatListRef = useRef(null);

  useEffect(() => {
    EventBus.getInstance().on(EventBusEventsEnum.SCROLL_TO_TOP_TAB_2, () => {
      flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
    });
  }, []);

  return (
    <View px="2xl" flex={1}>
      <FlatList
        ref={flatListRef}
        contentContainerStyle={{
          paddingBottom: getTheme().space['4xl'],
        }}
        data={restaurantLists}
        keyExtractor={(item) => item?._id}
        renderItem={renderItem}
        showsVerticalScrollIndicator
        viewabilityConfig={viewabilityConfigRef.current}
        onViewableItemsChanged={onViewableItemsChangedCallback}
        ListEmptyComponent={() => (
          <View alignItems="center" justifyContent="center">
            <CachedImage
              source={require('../../../../assets/images/businessman-searching-for-an-answer.webp')}
              style={{
                width: Dimensions.get('screen').width * 0.497,
                height: dynamicHeightMaker(
                  Dimensions.get('screen').width * 0.497,
                  1,
                ),
                resizeMode: 'contain',
              }}
            />
            <View alignItems="center" justifyContent="center">
              <Text size="md" color="primary.100">
                Oh No!
              </Text>
              <Text size="md" color="primary.100">
                Sorry, no stores found
              </Text>
            </View>
          </View>
        )}
        scrollIndicatorInsets={{ top: 128 }}
        scrollEventThrottle={16}
      />
    </View>
  );
};

const StoreList = ({ navigation, route }) => {
  const campusID = useCommonStore((state) => state.campusId);
  const userId = useUserStore((state) => state.id);
  const { profileImage, firstName, lastName, blackMembership } = useUserStore(
    (state) => state,
  );
  const { active: isBlackMember } = blackMembership;

  const { IMAGEKIT_PROD_URI } = AppConstants;
  const imageLink = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const filter = {
    campus: { _id: campusID },
    type: RestaurantType.STORE,
  };
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-or-network',
    });

  const [netStatus, setNetStatus] = useState(true);

  const isFocused = useIsFocused();

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  }, []);

  useEffect(() => {
    if (isFocused) {
      refresh();
      firebaseEventLogger('ShopTabLanding');
    }
  }, [isFocused]);

  return (
    <NewErrorBoundary
      fetchKey={refreshedQueryOptions.fetchKey}
      fallback={
        <NewErrorView errorMsg="Sorry something went wrong" reload={refresh} />
      }
    >
      <Layout level={2}>
        <TopNavigation
          title="Shop"
          appearance="ghost"
          level="1"
          IconRight={
            <View flexDirection="row" alignItems="center">
              <Pressable
                onPress={() => {
                  navigation.navigate('account');
                }}
                hitSlop={{
                  top: 10,
                  bottom: 10,
                  left: 10,
                  right: 10,
                }}
              >
                <View
                  mr="lg"
                  borderWidth="sm"
                  borderColor={isBlackMember ? 'static.gold' : 'transparent'}
                  borderRadius="full"
                >
                  <Avatar
                    level={1}
                    source={imageLink}
                    resizeMode="cover"
                    name={`${firstName ?? ''} ${lastName ?? ''}`}
                  />
                </View>
              </Pressable>
            </View>
          }
        />
        <Suspense
          fallback={
            Platform.OS === 'web' ? <Loading /> : <StoresScreenPlaceholder />
          }
        >
          <RenderStoresScreen
            queryOptions={refreshedQueryOptions ?? {}}
            variables={{
              filter,
              includeFindUser: isLoggedIn,
              sort: 'DISCOUNT_DESC',
              id: userId,
            }}
            fetchKey={refreshedQueryOptions.fetchKey}
            navigation={navigation}
          />

          <DisconnectedDropover
            setNetStatus={setNetStatus}
            text="No Internet Connection"
            icon="wifi-off-outline"
          />
        </Suspense>
      </Layout>
    </NewErrorBoundary>
  );
};

export default StoreList;
