/**
 * @generated SignedSource<<07285d7b3c36ebb8e3b48fd5c88f2b15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type findLocationFilterInput = {
  _id?: any | null;
};
export type campusLocationApiQuery$variables = {
  filter?: findLocationFilterInput | null;
};
export type campusLocationApiQuery$data = {
  readonly findCampusLocation: ReadonlyArray<{
    readonly _id: any;
    readonly address: string | null;
    readonly city: string;
    readonly contact: {
      readonly _id: any | null;
      readonly email: {
        readonly addr: string | null;
      } | null;
      readonly phone: {
        readonly number: string | null;
        readonly prefix: string | null;
      } | null;
    } | null;
    readonly country: string | null;
    readonly geo: {
      readonly _id: any | null;
      readonly lat: string;
      readonly long: string;
    } | null;
    readonly name: string;
    readonly state: string;
  }>;
};
export type campusLocationApiQuery = {
  response: campusLocationApiQuery$data;
  variables: campusLocationApiQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "campusLocation",
    "kind": "LinkedField",
    "name": "findCampusLocation",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "country",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "campusLocationGeo",
        "kind": "LinkedField",
        "name": "geo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "long",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "campusLocationContact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "campusLocationContactPhone",
            "kind": "LinkedField",
            "name": "phone",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "campusLocationContactEmail",
            "kind": "LinkedField",
            "name": "email",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addr",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "campusLocationApiQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "campusLocationApiQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7fba7edb14c939f9f56e871bebaf4998",
    "id": null,
    "metadata": {},
    "name": "campusLocationApiQuery",
    "operationKind": "query",
    "text": "query campusLocationApiQuery(\n  $filter: findLocationFilterInput\n) {\n  findCampusLocation(filter: $filter) {\n    name\n    address\n    city\n    state\n    country\n    geo {\n      lat\n      long\n      _id\n    }\n    contact {\n      phone {\n        prefix\n        number\n      }\n      email {\n        addr\n      }\n      _id\n    }\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "67517f3e331d0c0b84570a6a348815a3";

export default node;
