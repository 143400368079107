/**
 * @generated SignedSource<<7ee655ad3ee7dd8f7f7390a6820b84e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type qrCodeScreenQuery$variables = {
  userId: any;
};
export type qrCodeScreenQuery$data = {
  readonly findUserById: {
    readonly _id: any;
    readonly identityCode: string | null;
    readonly name: {
      readonly first: string;
      readonly last: string | null;
    };
    readonly profileImage: string | null;
  } | null;
};
export type qrCodeScreenQuery = {
  response: qrCodeScreenQuery$data;
  variables: qrCodeScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "findUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserName",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identityCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "qrCodeScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "qrCodeScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "98fc2a63961ce417f988ec60897d7c44",
    "id": null,
    "metadata": {},
    "name": "qrCodeScreenQuery",
    "operationKind": "query",
    "text": "query qrCodeScreenQuery(\n  $userId: MongoID!\n) {\n  findUserById(_id: $userId) {\n    _id\n    name {\n      first\n      last\n    }\n    identityCode\n    profileImage\n  }\n}\n"
  }
};
})();

(node as any).hash = "a02a6b8af2c8bea4061e81351d8de5bf";

export default node;
