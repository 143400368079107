import { graphql, commitMutation } from 'react-relay';
import environment from './relayEnvironment';

const favoriteImageApi = (data) => {
  const mutation = graphql`
    mutation toggleImageFavForPhotoMomentsApiMutation(
      $data: ToggleImageFavInput
    ) {
      toggleImageFavForPhotoMoments(data: $data) {
        success
      }
    }
  `;

  const variables = { data };

  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        resolve(response);
      },
      onError: (err) => {
        reject(err);
      },
    });
  });
};
export default favoriteImageApi;
