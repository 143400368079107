import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const partnerCashPaymentScreenQuery = graphql`
  query partnerCashPaymentScreenQuery(
    $filter: findPopUpStoresFilterInput
    $searchFilter: searchUsersFilterInput!
  ) {
    findPopUpStores(filter: $filter) {
      edges {
        node {
          _id
        }
      }
    }
    searchUsers(filter: $searchFilter) {
      _id
      name {
        first
        last
      }
      contact {
        phone {
          prefix
          number
          verified
        }
      }
      profileImage
      parkingVisitCount
    }
  }
`;
