import { forwardRef, memo } from 'react';
import { MenuAddButton } from '../../../components/new';
import {
  menuAddButtonAppearanceEnum,
  menuAddButtonSizeEnum,
  menuAddButtonStateEnum,
} from '../../../components/new/primitive/MenuAddButton/varAndEnum';
import useBoundStore from '../../../stores';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const AddButton = forwardRef(
  (
    {
      menuItem,
      setSelectedMenuItem,
      setRemoveItem,
      isEdit,
      setRepeatLast,
      quantity,
      btnState,
      // restaurantName = '',
      restaurantId = '',
      setVisible = () => {},
      restaurant,
    },
    ref,
  ) => {
    const cart = useBoundStore((state) => state.cart);
    const addToCart = useBoundStore((state) => state.addToCart);
    const removeFromCart = useBoundStore((state) => state.removeFromCart);
    // const setOrderDetails = useBoundStore((state) => state.setOrderDetails);
    const clearOrderDetails = useBoundStore((state) => state.clearOrderDetails);
    const setOtherItem = useBoundStore((state) => state.setOtherItem);
    const { current: bottomSheetRef } = ref;

    // calculate count from cart if quantity not passed through props necessary in "edit items" section
    let quantityLocal = 0;
    if (!quantity) {
      quantityLocal = cart.reduce((prev, curr) => {
        if (curr.id === menuItem._id) {
          prev += curr.quantity;
        }
        return prev;
      }, 0);
    }

    return (
      <MenuAddButton
        appearance={menuAddButtonAppearanceEnum.outline}
        count={quantity || quantityLocal}
        size={isEdit ? menuAddButtonSizeEnum.md : menuAddButtonSizeEnum.lg}
        onLeftPress={() => {
          firebaseEventLogger('MenuButtonLeftPress', {
            itemId: menuItem._id,
          });
          if (cart.length === 1 && cart[0].quantity === 1) {
            clearOrderDetails();
          }
          const occuerenceIndexes = [];
          for (let index = 0; index < cart.length; index += 1) {
            if (cart[index].id === menuItem._id) {
              occuerenceIndexes.push(index);
            }
          }
          if (occuerenceIndexes.length === 1) {
            setRemoveItem(false);
            removeFromCart({
              id: menuItem._id,
              title: menuItem.title,
              itemPrice: menuItem.itemPrice,
              description: menuItem.description,
              options: cart[occuerenceIndexes[0]].options,
            });
            return;
          }
          if (isEdit) {
            removeFromCart({
              id: menuItem.id,
              title: menuItem.title,
              itemPrice: menuItem.itemPrice,
              description: menuItem.description,
              options: menuItem.options,
              ref,
            });
            return;
          }
          setSelectedMenuItem(menuItem);
          if (!menuItem.itemPrice.length) {
            setRemoveItem(false);
            removeFromCart({
              id: menuItem._id,
              title: menuItem.title,
              itemPrice: menuItem.defaultPrice,
              description: menuItem.description,
              options: [],
            });
            return;
          }
          bottomSheetRef?.show();
          setRemoveItem(true);
        }}
        onRightPress={() => {
          firebaseEventLogger('MenuButtonRightPress', {
            itemId: menuItem._id,
          });
          if (isEdit) {
            addToCart({
              id: menuItem.id,
              title: menuItem.title,
              itemPrice: menuItem.itemPrice,
              description: menuItem.description,
              options: menuItem.options,
              defaultPrice: menuItem.defaultPrice,
              tax: menuItem.tax || {},
              timestamp: Date.now(),
              // restaurantId,
              // restaurantName,
              restaurant,
              posId: menuItem.posId,
            });
            return;
          }
          const occuerenceIndexes = [];
          for (let index = 0; index < cart.length; index += 1) {
            if (cart[index].id === menuItem._id) {
              occuerenceIndexes.push(index);
            }
          }

          if (occuerenceIndexes.length && menuItem.itemPrice.length >= 1) {
            setRemoveItem(false);
            setRepeatLast(true);
            bottomSheetRef?.show();
            const lastIndex = occuerenceIndexes.slice(-1)[0];
            setSelectedMenuItem({ ...cart[lastIndex], new: menuItem });
            return;
          }
          setRepeatLast(false);
          setRemoveItem(false);
          setSelectedMenuItem(menuItem);
          if (menuItem.itemPrice.length >= 1) {
            bottomSheetRef?.show();
          } else {
            for (let index = 0; index < cart.length; index += 1) {
              if (cart[index].restaurant?._id !== restaurantId) {
                setOtherItem({
                  id: menuItem._id,
                  title: menuItem.title,
                  itemPrice: menuItem.defaultPrice,
                  description: menuItem.description,
                  options: [],
                  defaultPrice: menuItem.defaultPrice,
                  tax: menuItem.tax || {},
                  timestamp: Date.now(),
                  restaurant,
                  posId: menuItem.posId,
                });
                setVisible(true);
                return;
              }
            }
            addToCart({
              id: menuItem._id,
              title: menuItem.title,
              itemPrice: menuItem.defaultPrice,
              description: menuItem.description,
              options: [],
              defaultPrice: menuItem.defaultPrice,
              tax: menuItem.tax || {},
              timestamp: Date.now(),
              // restaurantId,
              // restaurantName,
              restaurant,
              posId: menuItem.posId,
            });
            // setOrderDetails({
            //   restaurantId,
            //   restaurantName,
            //   restaurant,
            // });
          }
        }}
        state={
          btnState
            ? menuAddButtonStateEnum.active
            : menuAddButtonStateEnum.disabled
        }
      />
    );
  },
);

export default memo(AddButton);
