/**
 * @generated SignedSource<<b009ffbfa7ac4d612c2e8a37ad812229>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type addVehicleRecordInput = {
  brand?: VehicleBrandInput | null;
  color?: string | null;
  isWhitelisted?: boolean | null;
  ownerID: any;
  registration: VehicleRegistrationInput;
};
export type VehicleBrandInput = {
  _id?: any | null;
  brandLogo?: string | null;
  name?: string | null;
};
export type VehicleRegistrationInput = {
  _id?: any | null;
  city?: string | null;
  plate: string;
  state?: string | null;
};
export type addVehicleMutation$variables = {
  record: addVehicleRecordInput;
};
export type addVehicleMutation$data = {
  readonly addVehicle: {
    readonly record: {
      readonly _id: any;
      readonly brand: {
        readonly _id: any | null;
        readonly brandLogo: string | null;
        readonly name: string | null;
      } | null;
    } | null;
  } | null;
};
export type addVehicleMutation = {
  response: addVehicleMutation$data;
  variables: addVehicleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOneVehiclePayload",
    "kind": "LinkedField",
    "name": "addVehicle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Vehicle",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VehicleBrand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandLogo",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addVehicleMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addVehicleMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a45757a62aeb71dd1f9e1cf3aa961a4b",
    "id": null,
    "metadata": {},
    "name": "addVehicleMutation",
    "operationKind": "mutation",
    "text": "mutation addVehicleMutation(\n  $record: addVehicleRecordInput!\n) {\n  addVehicle(record: $record) {\n    record {\n      brand {\n        _id\n        name\n        brandLogo\n      }\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0d10ddf715cc0d7b913a216ab8e3111";

export default node;
