/**
 * @generated SignedSource<<8b485c71c917b4aa793370a3c1fe72a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Enumrestaurants2Amenities = "Bar_Area" | "Buffet" | "Catering" | "Charging_Point" | "Coat_Check" | "Dance_Floor" | "Delivery" | "Event_Space" | "Family_Play_Area" | "Fine_Dining" | "Karaoke" | "Kids_Menu" | "Late_Night_Dining" | "Live_Cooking_Stations" | "Live_Sports_Screening" | "Meeting_Rooms" | "On_Site_Parking" | "Outdoor_Barbecue" | "Outdoor_Coolers" | "Outdoor_Heaters" | "Outdoor_Seating" | "Patio" | "Photobooth" | "Private_Dining" | "Pure_Veg" | "Reservations" | "Rooftop_Dining" | "Serves_Alcohol" | "Smoking_Area" | "Takeout" | "Valet_Parking" | "Wheelchair_Accessible" | "Wifi" | "%future added value";
export type Enumrestaurants2Occasion = "celebration" | "corporate" | "date_night" | "reunion" | "%future added value";
export type Enumrestaurants2Type = "RESTAURANT" | "STORE" | "%future added value";
export type findRestaurantsFilterInput = {
  _id?: any | null;
  amenities?: ReadonlyArray<Enumrestaurants2Amenities | null> | null;
  campus: FilterFindManyrestaurants2CampusInput;
  cusines?: ReadonlyArray<string | null> | null;
  imageSection?: string | null;
  occasion?: ReadonlyArray<Enumrestaurants2Occasion | null> | null;
  openHours?: string | null;
  outdoorAndEventFlag?: boolean | null;
  outdoorSeating?: boolean | null;
  overAllRating?: number | null;
  ownerId?: any | null;
  pureVeg?: boolean | null;
  search?: findRestaurantsFilterInputSearch | null;
  serveAlcohol?: boolean | null;
  type?: Enumrestaurants2Type | null;
  withOffer?: boolean | null;
};
export type FilterFindManyrestaurants2CampusInput = {
  _id: any;
};
export type findRestaurantsFilterInputSearch = {
  restaurantTitle?: string | null;
};
export type restaurants2ApiQuery$variables = {
  filter: findRestaurantsFilterInput;
};
export type restaurants2ApiQuery$data = {
  readonly findRestaurants2: ReadonlyArray<{
    readonly _id: any;
    readonly address: {
      readonly addressLine1: string;
    };
    readonly contact: {
      readonly phone: ReadonlyArray<{
        readonly _id: any | null;
        readonly number: string;
        readonly prefix: string;
      } | null> | null;
    } | null;
    readonly displayName: string;
    readonly owner: {
      readonly restaurants: {
        readonly payBill: {
          readonly pointsEnabled: {
            readonly redeem: boolean | null;
            readonly reward: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly ownerId: any | null;
    readonly posId: string | null;
  }>;
};
export type restaurants2ApiQuery = {
  response: restaurants2ApiQuery$data;
  variables: restaurants2ApiQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "restaurants2",
    "kind": "LinkedField",
    "name": "findRestaurants2",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "posId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurants2Address",
        "kind": "LinkedField",
        "name": "address",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressLine1",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ownerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurants2Contact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2ContactPhone",
            "kind": "LinkedField",
            "name": "phone",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "owner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2",
            "kind": "LinkedField",
            "name": "restaurants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2PayBill",
                "kind": "LinkedField",
                "name": "payBill",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "restaurants2PayBillPointsEnabled",
                    "kind": "LinkedField",
                    "name": "pointsEnabled",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "redeem",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reward",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "restaurants2ApiQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "restaurants2ApiQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3fffd2dda983caf598f14cfcdd2fbb68",
    "id": null,
    "metadata": {},
    "name": "restaurants2ApiQuery",
    "operationKind": "query",
    "text": "query restaurants2ApiQuery(\n  $filter: findRestaurantsFilterInput!\n) {\n  findRestaurants2(filter: $filter) {\n    _id\n    posId\n    displayName\n    address {\n      addressLine1\n    }\n    ownerId\n    contact {\n      phone {\n        _id\n        prefix\n        number\n      }\n    }\n    owner {\n      restaurants {\n        payBill {\n          pointsEnabled {\n            redeem\n            reward\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f8c4e26c715cae7fd0dd35f1fb700cd";

export default node;
