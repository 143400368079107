/**
 * @generated SignedSource<<9f3a3eab0fa9afafb0e92e8bd2a72785>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumTicketModelTCwoUserCanBeClaimedByRefType = "CAMPUS_LOCATION" | "RESTAURANT" | "%future added value";
export type EnumTicketModelTCwoUserIssuedByRefType = "CAMPUS_LOCATION" | "EVENT" | "POPUP_STORE" | "RESTAURANT" | "%future added value";
export type EnumticketDisplayType = "COUPON" | "QR" | "%future added value";
export type EnumticketStatus = "ACTIVE" | "CANCELLED" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumticketTicketType = "EVENT_TICKET" | "PRODUCT_TICKET" | "REWARD_TICKET" | "%future added value";
export type FilterFindManyticketInput = {
  AND?: ReadonlyArray<FilterFindManyticketInput> | null;
  OR?: ReadonlyArray<FilterFindManyticketInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyticketOperatorsInput | null;
  additionalFields?: FilterFindManyTicketModelTCwoUserAdditionalFieldsInput | null;
  canBeClaimedBy?: ReadonlyArray<FilterFindManyTicketModelTCwoUserCanBeClaimedByInput | null> | null;
  couponId?: any | null;
  coverImg?: string | null;
  displayType?: EnumticketDisplayType | null;
  expires_on?: any | null;
  finePrint?: FilterFindManyTicketModelTCwoUserFinePrintInput | null;
  invoiceId?: any | null;
  isPrimary?: boolean | null;
  issuedBy?: FilterFindManyTicketModelTCwoUserIssuedByInput | null;
  popUpImg?: string | null;
  rewardId?: any | null;
  status?: EnumticketStatus | null;
  thumbnailImg?: string | null;
  ticketType?: EnumticketTicketType | null;
  title?: string | null;
  uId?: number | null;
  updated_at?: any | null;
};
export type FilterFindManyTicketModelTCwoUserIssuedByInput = {
  _id?: any | null;
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserIssuedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserCanBeClaimedByInput = {
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserCanBeClaimedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsInput = {
  _id?: any | null;
  event?: FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput = {
  _id?: any | null;
  endTime?: any | null;
  invoicePdfPath?: string | null;
  spaces?: number | null;
  startTime?: any | null;
};
export type FilterFindManyTicketModelTCwoUserFinePrintInput = {
  _id?: any | null;
  details?: ReadonlyArray<string | null> | null;
  summary?: string | null;
};
export type FilterFindManyticketOperatorsInput = {
  _id?: FilterFindManyticket_idOperatorsInput | null;
};
export type FilterFindManyticket_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type slotMachineQuery$variables = {
  ticketFilter?: FilterFindManyticketInput | null;
  userId: any;
};
export type slotMachineQuery$data = {
  readonly findTickets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly status: EnumticketStatus | null;
        readonly ticketType: EnumticketTicketType;
      };
    }>;
  } | null;
  readonly findUserById: {
    readonly _id: any;
    readonly wallet: {
      readonly currentBalance: number | null;
      readonly totalBalance: number | null;
    } | null;
  } | null;
  readonly getSpinsCount: {
    readonly freezedTill: number | null;
    readonly spinsLeft: number | null;
  } | null;
};
export type slotMachineQuery = {
  response: slotMachineQuery$data;
  variables: slotMachineQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ticketFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "ticketFilter"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 200
      }
    ],
    "concreteType": "ticketConnection",
    "kind": "LinkedField",
    "name": "findTickets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ticketEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ticket",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ticketType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "findUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserWallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBalance",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "spinSlotsLeftTC",
    "kind": "LinkedField",
    "name": "getSpinsCount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "spinsLeft",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "freezedTill",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "slotMachineQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "slotMachineQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "916e0f4b2a6558279de5e20f80635e39",
    "id": null,
    "metadata": {},
    "name": "slotMachineQuery",
    "operationKind": "query",
    "text": "query slotMachineQuery(\n  $ticketFilter: FilterFindManyticketInput\n  $userId: MongoID!\n) {\n  findTickets(filter: $ticketFilter, first: 200) {\n    edges {\n      node {\n        ticketType\n        status\n      }\n    }\n  }\n  findUserById(_id: $userId) {\n    _id\n    wallet {\n      totalBalance\n      currentBalance\n    }\n  }\n  getSpinsCount {\n    spinsLeft\n    freezedTill\n  }\n}\n"
  }
};
})();

(node as any).hash = "13e57ba251ca3e476ba01084ca15776d";

export default node;
