import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface TaskStoreInterface {
  recalledTasks?: [];
  unassignedTasks?: [];
  arrivedTasks?: [];
  assignedTasks?: [];
  parkingNotOnboardedTasks?: [];
  parkingOnboardedTasks?: [];
  parkingTasks?: [];
}

interface useTaskStorePropTypes {
  (state?: object);
}
const useTaskStore = <useTaskStorePropTypes>create()(
  devtools(
    persist(
      (set) => ({
        // =========== for Recall Tab: start ========
        recalledTasks: [],
        arrivedTasks: [],
        unassignedTasks: [],
        assignedTasks: [],
        recallTaskOpened: '',
        // -----------
        newRecalledTasks: [],
        newArrivedTasks: [],
        newUnassignedTasks: [],
        newAssignedTasks: [],
        // ===========  for Recall Tab: end ========

        // =========== for Parking Tab: start ========
        parkingNotOnboardedTasks: [],
        parkingOnboardedTasks: [],
        parkingTasks: [],
        parkingTaskOpened: '',
        //------------
        newParkingNotOnboardedTasks: [],
        newParkingOnboardedTasks: [],
        newParkingTasks: [],
        // =========== for Parking Tab: end ========

        setRecalledTasks: ({ data }) => {
          set(() => {
            return { recalledTasks: data };
          });
        },
        setNewRecalledTasks: ({ data }) => {
          set(() => {
            return { newRecalledTasks: data };
          });
        },
        setUnassignedTasks: ({ data }) => {
          set(() => {
            return { unassignedTasks: data };
          });
        },
        setNewUnassignedTasks: ({ data }) => {
          set(() => {
            return { newUnassignedTasks: data };
          });
        },
        setArrivedTasks: ({ data }) => {
          set(() => {
            return { arrivedTasks: data };
          });
        },
        setNewArrivedTasks: ({ data }) => {
          set(() => {
            return { newArrivedTasks: data };
          });
        },
        setAssignedTasks: ({ data }) => {
          set(() => {
            return { assignedTasks: data };
          });
        },
        setNewAssignedTasks: ({ data }) => {
          set(() => {
            return { newAssignedTasks: data };
          });
        },
        setParkingNotOnboardedTasks: ({ data }) => {
          set(() => {
            return { parkingNotOnboardedTasks: data };
          });
        },
        setParkingOnboardedTasks: ({ data }) => {
          set(() => {
            return { parkingOnboardedTasks: data };
          });
        },
        setParkingTasks: ({ data }) => {
          set(() => {
            return { parkingTasks: data };
          });
        },
        setNewParkingTasks: ({ data }) => {
          set(() => {
            return { newParkingTasks: data };
          });
        },
        setNewParkingNotOnboardedTasks: ({ data }) => {
          set(() => {
            return { newParkingNotOnboardedTasks: data };
          });
        },
        setNewParkingOnboardedTasks: ({ data }) => {
          set(() => {
            return { newParkingOnboardedTasks: data };
          });
        },
        setRecallTaskOpened: ({ id }) => {
          set(() => {
            return { recallTaskOpened: id };
          });
        },
        setParkingTaskOpened: ({ id }) => {
          set(() => {
            return { parkingTaskOpened: id };
          });
        },
      }),
      {
        name: 'TaskStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'TaskStore',
    },
  ),
);

export default useTaskStore;
