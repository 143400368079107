import React, { useEffect, useState } from 'react';
import {
  ImageBackground,
  Platform,
  useWindowDimensions,
  Animated,
  PanResponder,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  IconButton,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import { fontSizes, space } from '../../../../themes/new/theme';
import { Appearance, Status } from '../../../../themes/new/helper';
import { AssociatesArray } from '../../../../utilities/InvestmentConstants';
import AnimatedNumber from '../../../../components/new/AnimatedNumber';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import { navigateBack } from '../../../../utilities/helper';

const InvestmentAssociatesJourney = () => {
  const { height } = useWindowDimensions();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const [fadeAnim] = useState(new Animated.Value(0));
  const [fadeAnimWrapper] = useState(new Animated.Value(0));
  const [selected, setSelected] = useState(0);
  const [isLastItem, setIsLastItem] = useState(false);
  const [startingYearNumber, setStartingYearNumber] = React.useState(
    AssociatesArray?.[0]?.startingYear,
  );
  const [endingYearNumber, setEndingYearNumber] = React.useState(
    AssociatesArray?.[0]?.endingYear,
  );

  const increase = (current) => {
    setTimeout(() => {
      setStartingYearNumber(AssociatesArray?.[current]?.startingYear);
      setEndingYearNumber(AssociatesArray?.[current]?.endingYear);
    }, 200);
  };

  useEffect(() => {
    if (selected === 0) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: true,
      }).start();
      Animated.timing(fadeAnimWrapper, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start();
    }
    if (AssociatesArray?.length - 1 === selected && !isLastItem) {
      setIsLastItem(true);
    } else if (AssociatesArray?.length - 1 !== selected && isLastItem) {
      setIsLastItem(false);
    }
  }, [selected]);

  const handleClose = () => {
    navigateBack(navigation, 'user');
  };

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: (evt, gestureState) => {
      return Math.abs(gestureState.dy) > 5;
    },
    onPanResponderRelease: (evt, gestureState) => {
      if (gestureState.dy > 0) {
        if (selected > 0) {
          Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 100,
            useNativeDriver: true,
          }).start();
          Animated.timing(fadeAnimWrapper, {
            toValue: 0,
            duration: 100,
            useNativeDriver: true,
          }).start();
          setTimeout(() => {
            Animated.timing(fadeAnim, {
              toValue: 1,
              duration: 2000,
              useNativeDriver: true,
            }).start();
            Animated.timing(fadeAnimWrapper, {
              toValue: 1,
              duration: 1000,
              useNativeDriver: true,
            }).start();
            setSelected((val) => {
              increase(val - 1);
              return val - 1;
            });
          }, 100);
        }
      } else if (gestureState.dy < 0) {
        if (selected < AssociatesArray?.length - 1) {
          Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 100,
            useNativeDriver: true,
          }).start();
          Animated.timing(fadeAnimWrapper, {
            toValue: 0,
            duration: 100,
            useNativeDriver: true,
          }).start();
          setTimeout(() => {
            Animated.timing(fadeAnim, {
              toValue: 1,
              duration: 2000,
              useNativeDriver: true,
            }).start();
            Animated.timing(fadeAnimWrapper, {
              toValue: 1,
              duration: 1000,
              useNativeDriver: true,
            }).start();
            setSelected((val) => {
              increase(val + 1);
              return val + 1;
            });
          }, 100);
        }
      }
    },
  });

  const smallDevice = height < 800;

  return (
    <DarkThemeWrapper>
      <View flex={1} bg="background.primary.elevation">
        <TopNavigation
          title=""
          appearance="ghost"
          level="1"
          IconRight={
            <IconButton
              name="close-outline-300"
              size="sm"
              appearance="ghost"
              iconColor="primary.500"
              onPress={handleClose}
            />
          }
        />
        <Animated.View
          style={{
            opacity: fadeAnimWrapper,
          }}
          {...panResponder.panHandlers}
        >
          <ImageBackground
            source={AssociatesArray?.[selected]?.bg}
            style={{
              height: isLastItem ? height - 150 : height - 50,
            }}
          >
            <View px="2xl" mt={smallDevice ? '5xl' : '9xl+9xl'}>
              <View flexDirection="row" alignItems="center">
                <AnimatedNumber
                  animateToNumber={startingYearNumber}
                  fontStyle={{
                    fontSize: fontSizes['6xl'],
                    fontWeight: '400',
                    color: '#ffffff',
                    fontFamily: 'CircularStd-Medium',
                  }}
                />
                <Text size="6xl" color="primary.500">
                  {` - `}
                </Text>
                <AnimatedNumber
                  animateToNumber={endingYearNumber}
                  fontStyle={{
                    fontSize: fontSizes['6xl'],
                    fontWeight: '400',
                    color: '#ffffff',
                    fontFamily: 'CircularStd-Medium',
                  }}
                />
              </View>
              <View mt={smallDevice ? '7xl' : '3xl+9xl'} flexDirection="row">
                <View flex={0.2} />
                <View flex={0.8}>
                  <Animated.View
                    style={{
                      opacity: fadeAnim,
                    }}
                  >
                    {AssociatesArray?.[selected]?.descArray?.map((item) => (
                      <View
                        mb={smallDevice ? '5xl' : '9xl'}
                        key={`associates_arr_${item?.statement}`}
                      >
                        <Text
                          size={item.size}
                          color={item.color}
                          weight={item?.weight ?? 'regular'}
                        >
                          {item?.statement}
                          {item?.primaryStatement ? (
                            <Text
                              size={item?.primaryStatement?.size}
                              color={item?.primaryStatement?.color}
                              weight={
                                item?.primaryStatement?.weight ?? 'regular'
                              }
                            >
                              {' '}
                              {item?.primaryStatement?.statement}
                            </Text>
                          ) : null}
                          {item?.secondaryStatement ? (
                            <Text
                              size={item?.secondaryStatement?.size}
                              color={item?.secondaryStatement?.color}
                              weight={
                                item?.secondaryStatement?.weight ?? 'regular'
                              }
                            >
                              {' '}
                              {item?.secondaryStatement?.statement}
                            </Text>
                          ) : null}
                        </Text>
                      </View>
                    ))}
                  </Animated.View>
                </View>
              </View>
            </View>
          </ImageBackground>
        </Animated.View>
        {isLastItem ? (
          <View position="absolute" bottom={space['2xl']} width="100%">
            <Button
              appearance={Appearance.OUTLINE}
              status={Status.PRIMARY}
              size="md"
              m="2xl"
              mb={Platform.OS === 'ios' ? insets.bottom : '2xl'}
              onPress={handleClose}
            >
              Go Back
            </Button>
          </View>
        ) : null}
      </View>
    </DarkThemeWrapper>
  );
};

export default InvestmentAssociatesJourney;
