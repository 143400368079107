import React from 'react';
import { Text, View } from '../../../../components/new';
import { FontWeights } from '../../../../themes/new/helper';

interface PriceRowProps {
  leftContent: string;
  rightContent: string;
  emphasized?: boolean;
}

const PriceRow = ({
  leftContent,
  rightContent,
  emphasized = false,
}: PriceRowProps) => {
  return (
    <View
      flexDirection="row"
      justifyContent="space-between"
      py={emphasized ? 'lg' : 'none'}
      borderTopWidth={emphasized ? 'xs' : 0}
      borderBottomWidth={emphasized ? 'xs' : 0}
      borderColor="grey.100"
    >
      <Text
        size="sm"
        weight={emphasized ? FontWeights.MEDIUM : FontWeights.REGULAR}
        color={emphasized ? 'primary.500' : 'primary.400'}
      >
        {leftContent}
      </Text>
      <Text
        size="sm"
        fontFamily={emphasized ? 'bodyMedium' : 'body'}
        color={emphasized ? 'primary.500' : 'primary.400'}
      >
        {rightContent}
      </Text>
    </View>
  );
};

export default PriceRow;
