import { useEffect } from 'react';
import { BackHandler } from 'react-native';

const useHardBackButton = (callback) => {
  useEffect(() => {
    const onBackPress = () => {
      if (callback) {
        callback();
        return true;
      }
      return false; // Allow default back button behavior
    };

    BackHandler.addEventListener('hardwareBackPress', onBackPress);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    };
  }, [callback]);
};

export default useHardBackButton;
