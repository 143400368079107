import React from 'react';
import { Icon, Text, View } from '../../../../components/new';

const ParkingTaskCompleteComp = ({ data }) => {
  const { text, value, state } = data;
  return (
    <View>
      <View mb="4xl">
        <View alignItems="center" mb="3xl">
          <Icon
            name="check-filled-400"
            size="3xl+3xl"
            color={state === 'success' ? 'success.500' : 'primary.400'}
          />
        </View>
        <Text size="2xl" textAlign="center" weight="medium" color="primary.300">
          {text}
        </Text>
        {value && value > 0 ? (
          <>
            <Text
              mt="2xl"
              size="3xl"
              weight="medium"
              color="primary.5000"
              textAlign="center"
            >
              ₹{value}
            </Text>
            <Text mt="sm" size="sm" color="primary.100" textAlign="center">
              Bonus Earned
            </Text>
          </>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

export default ParkingTaskCompleteComp;
