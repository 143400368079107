import React, { Suspense, useCallback, useState } from 'react';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { IconButton, Layout, TopNavigation } from '../../components/new';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import useUserStore from '../../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { RewardScreenPlaceholder } from './shimmerPlaceholders';
import { navigateBack } from '../../utilities/helper';
import BookingList from './MyBookingList';
import useAuthStore from '../../stores/authStore';
import { useNodeFromPagination } from '../../hooks/useNodeFromPagination';
import { pagination } from '../../utilities/Constant';
import NotLoggedInPlaceholderV2 from '../../components/new/custom/NotLoggedInPlaceholderV2';

const bookingScreenQuery = graphql`
  query MyBookingScreenQuery(
    $filter: FilterFindManyticketInput!
    $count: Int!
  ) {
    ...MyBookingScreenTicketQueryFragment
      @arguments(filter: $filter, count: $count)
  }
`;

export const ticketQueryFragment = graphql`
  fragment MyBookingScreenTicketQueryFragment on Query
  @refetchable(queryName: "ticketFragmentQuery")
  @argumentDefinitions(
    filter: { type: "FilterFindManyticketInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    tickets: findTickets(filter: $filter, first: $count, after: $cursor)
      @connection(key: "ticketQueryFragment__tickets") {
      edges {
        node {
          _id
          ticketType
          coverImg
          thumbnailImg
          uId
          expires_on
          invoiceId
          title
          issuedBy {
            refType
            _id
          }
          status
          additionalFields {
            event {
              startTime
              endTime
            }
          }
          canBeClaimedBy {
            venue {
              geo {
                lat
                long
              }
              addressLine1
            }
          }
          rewardId
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const RenderBookingsScreen = (props) => {
  const { variables, refreshedQueryOptions } = props;
  const ticketsFragmentRef = useLazyLoadQuery(bookingScreenQuery, variables, {
    ...refreshedQueryOptions,
    networkCacheConfig: { force: true },
  });

  const {
    data,
    loadNext: loadNextTickets,
    hasNext: hasMoreTickets,
  } = usePaginationFragment(ticketQueryFragment, ticketsFragmentRef);
  const tickets = useNodeFromPagination(data.tickets);

  const loadMoreBookings = () => {
    if (hasMoreTickets) loadNextTickets(10);
  };

  return (
    <>
      <BookingList bookings={tickets} loadMoreBookings={loadMoreBookings} />
    </>
  );
};

const MyBookingScreen = ({ navigation }) => {
  const [netStatus, setNetStatus] = useState(true);
  const { role } = useUserStore((state) => state);
  const { isLoggedIn } = useAuthStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  const onPressLeftIcon = () => {
    navigateBack(navigation, role);
  };

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="My Bookings" />;
  }

  return (
    <Layout level={2}>
      <TopNavigation
        title="My Bookings"
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<RewardScreenPlaceholder />}>
          <RenderBookingsScreen
            variables={{
              filter: {
                ticketType: 'EVENT_TICKET',
                displayType: 'QR',
                isPrimary: true,
              },
              count: pagination.defaultPageSize,
            }}
            fetchKey={refreshedQueryOptions.fetchKey}
            refreshedQueryOptions={refreshedQueryOptions}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default MyBookingScreen;
