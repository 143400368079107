import React from 'react';
import { FlashList } from '@shopify/flash-list';
import { View, Text } from '../..';
import BonusListItem from './BonusListItem';
import NoContentView from './NoContentView';
import BonusBadge from './BonusBadge';
import { toRupees } from '../../../../utilities/bonus';

type BonusPayoutList = {
  items: Array<{
    year: string;
    month: string;
    payout: number;
    isCurrentMonth: boolean;
  }>;
};

const BonusPayoutList = ({ items }: BonusPayoutList) => {
  return (
    <FlashList
      data={items}
      scrollsToTop
      renderItem={({ item }) => {
        const { month, year, payout, isCurrentMonth } = item;
        return (
          <View key={`${year}-${month}`} mb="2xl" px="2xl">
            <BonusListItem
              contentPrimary={`${month} ${year}`}
              contentSecondary={isCurrentMonth ? 'MTD' : 'Paid'}
              sizePri="sm"
              RightContent={
                <View minWidth="54">
                  <BonusBadge variant={payout > 0 ? 'gain' : 'loss'}>
                    <Text size="sm" color="primary.500">
                      {toRupees(Math.trunc(payout))}
                    </Text>
                  </BonusBadge>
                </View>
              }
            />
          </View>
        );
      }}
      ListEmptyComponent={
        <View>
          <NoContentView />
          <Text textAlign="center" color="primary.100">
            No Payouts yet
          </Text>
        </View>
      }
    />
  );
};

export default BonusPayoutList;
