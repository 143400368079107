import { graphql } from 'react-relay';

export const searchTeamQuery = graphql`
  query searchTeamQuery(
    $filter: searchValetFilterInput
    $count: Int
    $cursor: String
  ) {
    ...searchTeamQueryFragment
      @arguments(filter: $filter, count: $count, cursor: $cursor)
  }
`;

export const searchTeamQueryFragment = graphql`
  fragment searchTeamQueryFragment on Query
  @refetchable(queryName: "searchTeamFragmentQuery")
  @argumentDefinitions(
    filter: { type: "searchValetFilterInput" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    searchValetV2(filter: $filter, first: $count, after: $cursor)
      @connection(key: "searchTeamQueryFragment_searchValetV2") {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          _id
          name {
            first
            last
          }
          profileImage
          role {
            name
          }
          contact {
            phone {
              prefix
              number
            }
          }
          identityCode
          workLogStatus {
            status
            time
            lastStatus
            taskCount
          }
          deleted
        }
      }
    }
  }
`;
