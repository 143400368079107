import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Image, Pressable } from 'react-native';
import {
  IconButton,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import { navigateBack } from '../../../../utilities/helper';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import useUserStore, { UserRole } from '../../../../stores/userStore';
import useAuthStore from '../../../../stores/authStore';
import useLoginFlowStore from '../../../../stores/loginFlowStore';
import refreshTokenAPI from '../../../../utilities/refreshToken';
import { RefreshTokenPayload } from '../../../../relay/relayEnvironment';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { FontWeights } from '../../../../themes/new/helper';

const PreviewIcon = require('../../../../../assets/images/gallery-ph.png');
const RequestChangeIcon = require('../../../../../assets/images/messenger-icon.png');

const RestaurantConfigScreenInner = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const setInitialRoute = useLoginFlowStore((state) => state.setInitialRoute);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const { restaurantId, restaurantName } = (route?.params ?? {}) as Record<
    string,
    any
  >;
  const userRole = useUserStore((state) => state.role);
  const setRole = useUserStore((state) => state.setRole);

  const switchToCustomerRole = async () => {
    try {
      const data = {
        _id: useUserStore.getState().id || '',
        token: useAuthStore.getState().refreshToken,
        activeRole: UserRole.USER,
      };

      const tokenDetails = await refreshTokenAPI(data);

      if (tokenDetails && tokenDetails?.getRefreshToken) {
        const { token, refreshToken } = tokenDetails?.getRefreshToken ?? {};
        // set new access token and refresh token in loacal storage
        await AsyncStorage.multiSet([
          ['userToken', token],
          ['refreshToken', refreshToken],
        ]);

        await useAuthStore
          .getState()
          .setLoginDetails(true, token, refreshToken);
        try {
          setInitialRoute('RestaurantScreen', {
            restaurantId,
          });
          navigation?.popToTop();
          setRole(UserRole.USER);
        } catch (err) {
          console.log(err);
        }
        return token;
      }
    } catch (err) {
      dispatchSnackbar({
        msg: 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
    }
  };

  const navigateToRequestChangesScreen = () => {
    navigation.navigate('RequestRestaurantChanges');
  };

  return (
    <View>
      <TopNavigation
        appearance="ghost"
        title={restaurantName ?? ''}
        level="1"
        textSize="md"
        textColor="primary.500"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
            }}
          />
        }
      />
      <View p="2xl">
        <Pressable onPress={switchToCustomerRole}>
          <View p="2xl" mb="2xl" shadow="sm" borderRadius="lg" bg="primary.10">
            <Image
              source={PreviewIcon}
              resizeMode="contain"
              style={{ height: 117, width: 'auto' }}
            />
            <Text
              mt="2xl"
              textAlign="center"
              size="xl"
              color="primary.300"
              weight={FontWeights.MEDIUM}
            >
              Preview My Page
            </Text>
          </View>
        </Pressable>
        <Pressable onPress={navigateToRequestChangesScreen}>
          <View p="2xl" shadow="sm" borderRadius="lg" bg="primary.10">
            <Image
              source={RequestChangeIcon}
              resizeMode="contain"
              style={{ height: 117, width: 'auto' }}
            />
            <Text
              mt="2xl"
              textAlign="center"
              size="xl"
              color="primary.300"
              weight={FontWeights.MEDIUM}
            >
              Request Changes
            </Text>
          </View>
        </Pressable>
      </View>
    </View>
  );
};

export default RestaurantConfigScreenInner;
