/**
 * @generated SignedSource<<7c0ba6265b68e98d9a327f7460a1091f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumcollectionsTitle = "CAFES" | "DATE_NIGHT" | "FINE_DINING" | "OUTDOOR_SEATING" | "RECOMMENDED" | "SERVES_ALCOHOL" | "UNIQUE_DINEIN" | "%future added value";
export type Enumrestaurants2DescriptionCusinesName = "american" | "asian" | "bakery" | "bar" | "bengali" | "beverages" | "bihari" | "bubble_tea" | "cafe" | "chinese" | "coastal_cuisine" | "coffee" | "continental" | "desserts" | "european" | "fastfood" | "french" | "goan" | "greek" | "healthy" | "himalayan" | "ice_cream" | "indian" | "italian" | "japanese" | "kashmiri" | "korean" | "lebanese" | "malaysian" | "mediterranean" | "mexican" | "momos" | "mughlai" | "north_eastern" | "north_indian" | "pizza" | "punjabi" | "rajasthani" | "rolls" | "russian" | "salad" | "seafood" | "shake" | "south_indian" | "sushi" | "thai" | "turkish" | "vietnamese" | "waffle" | "%future added value";
export type Enumrestaurants2MediaGalleryMediaMediaType = "image" | "video" | "%future added value";
export type Enumrestaurants2MediaGallerySectionTitle = "Ambience" | "Awards___Recognitions" | "Food" | "Founder_Story" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type homeScreenLogOutQuery_collectionsFragment$data = ReadonlyArray<{
  readonly restaurants: ReadonlyArray<{
    readonly _id: any;
    readonly description: {
      readonly cusines: ReadonlyArray<{
        readonly name: Enumrestaurants2DescriptionCusinesName | null;
      } | null> | null;
    };
    readonly displayName: string;
    readonly logo: string | null;
    readonly mediaGallery: ReadonlyArray<{
      readonly media: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly mediaType: Enumrestaurants2MediaGalleryMediaMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly sectionTitle: Enumrestaurants2MediaGallerySectionTitle;
    } | null> | null;
    readonly overAllRating: number | null;
  } | null> | null;
  readonly title: EnumcollectionsTitle;
  readonly " $fragmentType": "homeScreenLogOutQuery_collectionsFragment";
}>;
export type homeScreenLogOutQuery_collectionsFragment$key = ReadonlyArray<{
  readonly " $data"?: homeScreenLogOutQuery_collectionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeScreenLogOutQuery_collectionsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "homeScreenLogOutQuery_collectionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2",
      "kind": "LinkedField",
      "name": "restaurants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overAllRating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2Description",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "restaurants2DescriptionCusines",
              "kind": "LinkedField",
              "name": "cusines",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2MediaGallery",
          "kind": "LinkedField",
          "name": "mediaGallery",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sectionTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "restaurants2MediaGalleryMedia",
              "kind": "LinkedField",
              "name": "media",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cover",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mediaType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mediaURL",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "collections",
  "abstractKey": null
};

(node as any).hash = "ae3cd9e2186315dacfbc5567726cef5f";

export default node;
