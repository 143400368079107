import React, { PropsWithChildren } from 'react';
import View from '../../basic/View/View';

export type BadgeProps = PropsWithChildren<{
  status: 'success' | 'error' | 'warning' | 'default';
}>;

const getBg = (status: BadgeProps['status']) => {
  switch (status) {
    case 'success':
      return 'success.10';
    case 'error':
      return 'error.10';
    case 'warning':
      return 'warning.50';
    case 'default':
      return 'grey.100';
    default:
      return 'grey.100';
  }
};

const Badge = ({ status, children }: BadgeProps) => {
  return (
    <View
      minWidth="2xl"
      p="sm"
      px="lg"
      bg={getBg(status)}
      borderRadius="md"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </View>
  );
};

export default Badge;
