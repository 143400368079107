import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from '../../../../components/new';
import { ShortDetailsCardList } from '../../../../components/new/custom/ShortDetailsCardList';
import { RestaurantCollectionViewData } from '../utils';
import {
  logFirebaseRestaurantExploreEvent,
  logFirebaseRestaurantSelectEvent,
} from '../analytics';
import { RestaurantCollectionCategory } from '../../../../utilities/helper';
import { RestaurantsFilterNames } from '../../resturants/resturantsData';

interface RestaurantCollectionViewProps {
  collection: RestaurantCollectionViewData[];
  category: RestaurantCollectionCategory;
  title: string;
}

const RestaurantCollectionView = ({
  collection: restaurantCollection,
  category,
  title,
}: RestaurantCollectionViewProps) => {
  const navigation = useNavigation<any>();

  const handleRestaurantCardClick = (
    restaurantData: RestaurantCollectionViewData,
    navigateToAllRestaurants: boolean,
  ) => {
    const { id, title: restaurantTitle } = restaurantData;
    if (navigateToAllRestaurants) {
      // if clicked on "Explore all"
      logFirebaseRestaurantExploreEvent(category);
      let additionalFilter = '';
      switch (category) {
        case RestaurantCollectionCategory.OUTDOOR_SEATING:
          additionalFilter = RestaurantsFilterNames.OUTDOOR_SEATING;
          break;
        case RestaurantCollectionCategory.SERVES_ALCOHOL:
          additionalFilter = RestaurantsFilterNames.SERVES_ALCOHOL;
          break;
        case RestaurantCollectionCategory.CAFES:
          additionalFilter = RestaurantsFilterNames.CAFES;
          break;
        default:
          additionalFilter = '';
      }
      navigation.navigate('dineIn', { filter: additionalFilter });
    } else {
      // if clicked on "restaurant card"
      logFirebaseRestaurantSelectEvent(restaurantTitle, category);
      navigation.navigate('RestaurantScreen', { restaurantId: id });
    }
  };

  return restaurantCollection.length ? (
    <View mb="2xl">
      <Text px="2xl" size="lg" weight="medium" color="primary.400">
        {title}
      </Text>
      <ShortDetailsCardList
        listItems={restaurantCollection}
        overlayAt={5}
        onCardPress={handleRestaurantCardClick}
      />
    </View>
  ) : (
    <></>
  );
};

export default RestaurantCollectionView;
