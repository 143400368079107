/**
 * @generated SignedSource<<b257d0f0b221e77e630de4218ea07a6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Enumrestaurants2MediaGalleryMediaMediaType = "image" | "video" | "%future added value";
export type Enumrestaurants2MediaGallerySectionTitle = "Ambience" | "Awards___Recognitions" | "Food" | "Founder_Story" | "%future added value";
export type Enumrestaurants2Type = "RESTAURANT" | "STORE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type homeScreenQuery_findRestaurantsFragment$data = ReadonlyArray<{
  readonly _id: any;
  readonly displayName: string;
  readonly mediaGallery: ReadonlyArray<{
    readonly media: ReadonlyArray<{
      readonly cover: boolean | null;
      readonly mediaType: Enumrestaurants2MediaGalleryMediaMediaType;
      readonly mediaURL: string;
    } | null> | null;
    readonly sectionTitle: Enumrestaurants2MediaGallerySectionTitle;
  } | null> | null;
  readonly payBillCashback: number | null;
  readonly payBillPercentage: number | null;
  readonly type: Enumrestaurants2Type;
  readonly " $fragmentType": "homeScreenQuery_findRestaurantsFragment";
}>;
export type homeScreenQuery_findRestaurantsFragment$key = ReadonlyArray<{
  readonly " $data"?: homeScreenQuery_findRestaurantsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeScreenQuery_findRestaurantsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "homeScreenQuery_findRestaurantsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2MediaGallery",
      "kind": "LinkedField",
      "name": "mediaGallery",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sectionTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2MediaGalleryMedia",
          "kind": "LinkedField",
          "name": "media",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cover",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaURL",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payBillCashback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payBillPercentage",
      "storageKey": null
    }
  ],
  "type": "restaurants2",
  "abstractKey": null
};

(node as any).hash = "600b998644adeaf1523d8e613273ce00";

export default node;
