/* eslint-disable no-unsafe-optional-chaining */
import React, {
  useEffect,
  useState,
  Suspense,
  useCallback,
  useRef,
} from 'react';
import { graphql, useLazyLoadQuery, readInlineData } from 'react-relay';

import { ScrollView, useWindowDimensions } from 'react-native';
import {
  Button,
  Icon,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import {
  StaffRewardCategory,
  TaskStatus,
  dynamicHeightMaker,
  formatPlateNo,
  formatTimeToHrsMinSec,
  getFullName,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../../utilities/helper';

import RectangularTimer from '../../../../components/new/primitive/Timer/RectangularTimer';
import TaskCard from '../../../../components/new/primitive/TaskCard/TaskCard';
import ParkingTaskCompleteComp from './ParkingTaskCompleteComp';
import useUserStore from '../../../../stores/userStore';
import useCampusLocation from '../../../../stores/campusLocation';
import useCommonStore from '../../../../stores/commonStore';
import Loading from '../../../../components/loading.component';
import DisconnectedDropover from '../../../../components/disconnectedpopover.component';
import AssignRecallTaskConfirmation from './AssignRecallTaskConfirmation';
import { updateTaskForSupervisor } from '../../../../relay/taskApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import useTaskStore from '../../../../stores/taskStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import {
  findParking,
  parkingApiFindParkingFragment,
} from '../../../../relay/parkingApi';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';
import { getTheme } from '../../../../themes/new/theme';
import { unwrapPagedData } from '../../../../utilities/paginationUtilities';
import { Appearance, State, Status } from '../../../../themes/new/helper';

const parkingTaskEndScreenQuery = graphql`
  query ParkingTaskEndScreenQuery(
    $taskFilter: findTaskFilterInput!
    $sort: SortFindManytaskInput
    $staffRewardFilter: findStaffRewardsFilterInput
  ) {
    findTasks(filter: $taskFilter, sort: $sort, limit: 5) {
      # ...supervisorTaskList_findTasks
      _id
      vehicle {
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
      }
      taskType
      taskStartLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      campus {
        _id
        name
      }
      history {
        _id
        action
        actor
        event_at
      }
      assignedTo
      valet {
        _id
        name {
          first
          last
        }
        identityCode
        contact {
          phone {
            prefix
            number
          }
        }
      }
      user {
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      ETA {
        _id
        startAt
        delayAt
        originalPredictionTime
        pendingRecall
        available
        busy
      }
      vehicleId
      ownerId
      parkingId
      parkingTag
    }
    findStaffRewards(filter: $staffRewardFilter) {
      _id
      value
      task {
        taskId
        taskStatus
      }
    }
  }
`;

const QuickAssignTask = (props) => {
  const { variables, navigation, queryOptions, userRole, task } = props ?? {};
  const [modalVisible, setModalVisible] = useState(false);
  const [assignTaskDisabled, setAssignTaskDisabled] = useState(false);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { unassignedTasks, setUnassignedTasks } = useTaskStore(
    (state) => state,
  );
  const { findTasks, findStaffRewards } = useLazyLoadQuery(
    parkingTaskEndScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const { _id, value, task: taskFromStaffRewards } = findStaffRewards[0] ?? {};

  const animation = useRef(null);
  const { width } = useWindowDimensions();

  const formattedVehicleNumber = findTasks?.length
    ? formatPlateNo(
        findTasks[findTasks?.length - 1]?.vehicle?.registration?.plate,
      )
    : '';

  const parkingTag = findTasks?.length
    ? `#${findTasks[findTasks?.length - 1]?.parkingTag ?? ''}`
    : '';
  const taskCreatedTime = findTasks?.length
    ? new Date(findTasks[findTasks?.length - 1]?.history[0]?.event_at).valueOf()
    : new Date().valueOf();
  const currentTime = new Date().valueOf();
  const difference = currentTime - taskCreatedTime;
  const timerObj = {
    title1: 'Wait Time',
    title2: formatTimeToHrsMinSec(difference / 1000),
    title2Color: 'primary.500',
  };
  if (difference / 1000 > 300) {
    timerObj.title2Color = 'error.500';
  }
  const bottomLeftCornerComponent = () => (
    <RectangularTimer
      title1={timerObj?.title1}
      title2={timerObj?.title2}
      title2Color={timerObj?.title2Color}
    />
  );

  const topRightCornerComponent = () => (
    <View borderRadius="full" borderWidth="xs" borderColor="primary.50" p="sm">
      <Icon size="xl" name="car-filled-400" color="primary.100" />
    </View>
  );

  const bottomLeftCTA = () => {
    const onPressAssignTask = () => {
      setModalVisible(true);
    };
    return (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        onPress={onPressAssignTask}
        shadow="sm"
        state={State.ACTIVE}
      >
        Assign Valet {`(${task?.valet?.identityCode})`}
      </Button>
    );
  };

  const onPressBackToHome = () => {
    navigation.navigate(
      userRole === 'controller' ? 'valetSupervisor' : userRole,
    );
  };

  if (findTasks?.length === 0) {
    return (
      <View flex={1}>
        <View position="absolute" top={0} zIndex={999}>
          <CustomLottieView
            loop
            autoPlay
            ref={animation}
            source={require('../../../../../assets/lottie/celebration.json')}
            style={{
              width,
              height: dynamicHeightMaker(width, 1),
            }}
            containerWidth={width}
          />
        </View>
        <View
          backgroundColor="background.primary.base"
          flex={1}
          justifyContent="space-between"
        >
          <View mt="9xl+9xl">
            <View bg="success.50" p="2xl" mx="2xl" borderRadius="xl">
              <ParkingTaskCompleteComp
                data={{
                  value,
                  text:
                    taskFromStaffRewards?.taskStatus === TaskStatus.ON_TIME &&
                    !isValueNullOrEmpty(value) &&
                    value !== 0
                      ? 'Task Completed On-time'
                      : 'Task Completed',
                  state: 'success',
                }}
              />
            </View>
          </View>
          <View alignSelf="center" mb="9xl">
            <Button
              appearance="ghost"
              status="info"
              size="md"
              onPress={onPressBackToHome}
            >
              Back To Parking
            </Button>
          </View>
        </View>
      </View>
    );
  }

  const handleKeyHandOffPress = ({ recallTask, valet }) => {
    setAssignTaskDisabled(true);
    const data = {
      _id: recallTask?._id,
      record: {
        history: [
          { action: taskHistoryActionTypes.assigned },
          { action: taskHistoryActionTypes.accepted },
        ],
        assignedTo: valet?._id,
      },
    };
    updateTaskForSupervisor(
      data,
      () => {
        setModalVisible(false);
        const arr = unassignedTasks.filter((i) => i._id !== recallTask?._id);
        setUnassignedTasks({ data: arr });
        onPressBackToHome();
        dispatchSnackbar({
          msg: `Task Assigned to ${getFullName(
            valet?.name?.first,
            valet?.name?.last,
          )}`,
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
          position: 'bottom',
        });
        setAssignTaskDisabled(false);
      },
      () => {
        setModalVisible(false);

        dispatchSnackbar({
          msg: `Something went wrong`,
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          position: 'top',
        });
        setAssignTaskDisabled(false);
      },
    );
  };

  return (
    <>
      <ScrollView
        style={{ backgroundColor: getTheme().colors.static.white, flex: 1 }}
      >
        <View position="absolute" top={0} zIndex={999}>
          <CustomLottieView
            loop
            autoPlay
            ref={animation}
            source={require('../../../../../assets/lottie/celebration.json')}
            style={{
              width,
              height: dynamicHeightMaker(width, 1),
            }}
          />
        </View>
        <View bg="success.50" p="2xl" mx="2xl" borderRadius="xl">
          <ParkingTaskCompleteComp
            data={{
              value,
              text:
                taskFromStaffRewards?.taskStatus === TaskStatus.ON_TIME &&
                !isValueNullOrEmpty(value) &&
                value !== 0
                  ? 'Task Completed On-time'
                  : 'Task Completed',
              state: 'success',
            }}
          />
        </View>
        {findTasks?.length ? (
          <View mt="9xl+3xl" px="2xl">
            <Text size="2xl" color="primary.500" textAlign="center">
              Quick Assign Recall Task
            </Text>
            <View
              mt="3xl"
              borderWidth="xs"
              borderRadius="md"
              borderColor="primary.50"
            >
              <TaskCard
                heading={parkingTag}
                subHeading={formattedVehicleNumber}
                bottomLeftCorner={bottomLeftCornerComponent()}
                topRightCorner={topRightCornerComponent()}
                bottomLeftCTA={bottomLeftCTA()}
                shadow="md"
                alwaysOpened
              />
            </View>
          </View>
        ) : (
          <></>
        )}
        <Button
          appearance="ghost"
          status="info"
          size="md"
          onPress={onPressBackToHome}
          mt="9xl+3xl"
          mb="9xl"
          px="2xl"
        >
          Back To Home
        </Button>
      </ScrollView>
      <AssignRecallTaskConfirmation
        task={findTasks[findTasks?.length - 1]}
        valet={task?.valet}
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        assignTaskDisabled={assignTaskDisabled}
        handleKeyHandOffPress={() =>
          handleKeyHandOffPress({
            recallTask: findTasks[findTasks?.length - 1],
            valet: task?.valet,
          })
        }
      />
    </>
  );
};

const ParkingTaskEndScreen = ({ navigation, route }) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);
  const { currentLocation } = useCampusLocation((state) => state);
  const campusID = useCommonStore((state) => state.campusId);
  const userRole = useUserStore((state) => state.role);
  const [parkingData, setParkingData] = useState({});
  const { task } = route?.params ?? {};

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  useEffect(() => {
    findParking({ _id: task?.parkingId, campus: { _id: campusID } }, 1).then(
      (findParkingFragmentRef) => {
        const { findParkingV2 } = readInlineData(
          parkingApiFindParkingFragment,
          findParkingFragmentRef,
        );

        const res = {
          findParking: unwrapPagedData(findParkingV2?.edges),
        };
        if (res && res?.findParking && res?.findParking?.length) {
          setParkingData(res?.findParking[0]);
        }
      },
    );
  }, []);

  const sort = 'DELAY_AT_ASC';

  const taskFilter = {
    campus: { _id: campusID },
    taskType: taskTypes.recall,
    history: [{ action: taskHistoryActionTypes.created }],
    taskStartLocation: {
      locationId: currentLocation?._id,
    },
  };

  const staffRewardFilter = {
    task: { taskId: task?._id },
    category:
      task?.taskType === taskTypes.repark
        ? StaffRewardCategory.CONTROLLER_REPARKING_COMPLETION
        : StaffRewardCategory.CONTROLLER_PARKING_COMPLETION,
  };

  return (
    <Layout level={2}>
      <TopNavigation
        // title="Add Parking Details"
        appearance="ghost"
        level="1"
        // textColor="primary.10"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            // iconColor="primary.10"
            onPress={() => navigation.popToTop()}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <QuickAssignTask
            variables={{ taskFilter, sort, staffRewardFilter }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            navigation={navigation}
            userRole={userRole}
            task={{ ...task, parkingTag: parkingData?.parkingTag }}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default ParkingTaskEndScreen;
