/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { SafeAreaView } from 'react-native';
import { Button, Text, View } from '../../components/new';
import { getTheme, themeModes } from '../../themes/new/theme';
import DarkThemeWrapper from '../new/wrapperComp/DarkThemeWrapper';
import CustomLottieView from '../../components/new/custom/CustomLottieView';
import { Appearance, State, Status } from '../../themes/new/helper';

const PMLanding = ({ checkPermissionFunction }) => {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: getTheme(themeModes.light).colors.primary[500],
      }}
    >
      <View mx="2xl" flex={1} justifyContent="center" alignItems="center">
        <CustomLottieView
          source={require('../../../assets/lottie/facescan.json')}
          style={{ height: 150, width: 150 }}
          autoPlay
          containerWidth={150}
        />
        <Text color="static.white" size="2xl" weight="medium">
          Welcome to Secure Access
        </Text>
        <Text color="primary.100" mt="lg" textAlign="center" size="sm">
          Secure and swift access to your photos with our two-step face
          recognition authentication
        </Text>
      </View>

      <View mx="2xl" mb="2xl">
        <DarkThemeWrapper>
          <Button
            status={Status.PRIMARY}
            appearance={Appearance.FILLED}
            state={State.ACTIVE}
            size="md"
            onPress={() => checkPermissionFunction(false)}
          >
            Scan Face & Access Photos
          </Button>
        </DarkThemeWrapper>
      </View>
    </SafeAreaView>
  );
};

export default PMLanding;
