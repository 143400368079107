/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, View, Text } from '../../components/new';
import {
  DateFormatsEnum,
  getFormatedDate,
  maskedNumber,
  PaymentStatusEnum,
} from '../../utilities/helper';
import TaskCard from '../../components/new/primitive/TaskCard/TaskCard';
import useAuditorTransactionStore from '../../stores/auditor/auditorTransactionStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import useUserStore, { UserRole } from '../../stores/userStore';
import CancelTransactionModel from '../common/CancelTransactionModel';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useAuthStore from '../../stores/authStore';
import { updatePopupOrderAPI } from '../API/updatePopupOrder';

const RenderItem = ({ item, refresh }) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const navigation = useNavigation<any>();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [modalVisible, setModalVisible] = useState(false);
  const { openedCard, setOpenedCard } = useAuditorTransactionStore(
    (state) => state,
  );

  const userId = useUserStore((state) => state.id);
  const prefix = item?.user?.contact?.phone?.prefix ?? {};
  const number = item?.user?.contact?.phone?.number ?? {};
  const heading = `${prefix} ${maskedNumber(number)}`;
  const isPaid = item?.status === PaymentStatusEnum.SUCCESSFUL;
  const createdAt = new Date(item?.created_at);
  const deliveryStatus = item?.popUpStoreDetails?.deliveryStatus;
  const partnerName = item?.popUpStoreDetails?.displayName;
  const subHeading = `${partnerName} | ${getFormatedDate(
    createdAt,
    DateFormatsEnum.TIME,
  )}`;

  const headerRightComponent = () => (
    <View flexDirection="row" alignItems="center">
      <View
        width="3xs"
        height="3xs"
        bg={isPaid ? 'success.500' : 'error.500'}
        borderRadius="full"
      />
      <Text size="xs" color={isPaid ? 'success.500' : 'error.500'} ml="sm">
        {isPaid ? 'Paid' : 'Unpaid'}
      </Text>
    </View>
  );

  const topRightCornerComponent = () => (
    <View
      borderRadius="sm"
      borderWidth="xs"
      borderColor={isPaid ? 'success.10' : 'error.10'}
      p="sm"
      bg={isPaid ? 'success.10' : 'error.10'}
    >
      <Text size="sm" color="primary.500">
        ₹{item?.amount}
      </Text>
    </View>
  );

  const bottomLeftCTAComponent = () => {
    return isPaid ? (
      <></>
    ) : (
      <Button
        size="md"
        appearance="outline"
        status="primary"
        onPress={() => {
          setModalVisible(true);
        }}
        shadow="sm"
      >
        Cancel
      </Button>
    );
  };

  const bottomRightCTAComponent = () => {
    const onPressMarkPaid = () => {
      firebaseEventLogger('auditorHomeScreen__paid_Screen', {
        buttonName: 'paid',
        screenName: 'auditorHomeScreen',
        userType: UserRole.AUDITOR,
        interactionType: InteractionType.TAP,
        isLoggedIn,
        auditorId: userId,
        paymentId: item?._id,
        paymentType: item?.paymentType,
        paymentStatus: item?.status,
        deliveryStatus,
      });
      const data = {
        _id: item?._id,
        record: {
          status: PaymentStatusEnum.SUCCESSFUL,
        },
      };
      const handleUpdatePopUpInvoice = (response, error) => {
        setModalVisible(false);
        if (response) {
          dispatchSnackbar({
            msg: 'Mark Paid Successfully!',
            status: SnackbarStatus.success,
            version: SnackbarVersion.v2,
            removeAfter: 4000,
          });
          refresh();
        }
        if (error && error.length) {
          dispatchSnackbar({
            msg: 'Something went wrong!',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
            removeAfter: 4000,
          });
          refresh();
        }
      };

      const updateApiErrorHandler = (error) => {
        setModalVisible(false);
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
      };
      updatePopupOrderAPI(
        data,
        handleUpdatePopUpInvoice,
        updateApiErrorHandler,
      );
    };
    return isPaid ? (
      <></>
    ) : (
      <Button
        size="md"
        appearance="filled"
        status="primary"
        onPress={onPressMarkPaid}
        shadow="sm"
      >
        Mark Paid
      </Button>
    );
  };

  return (
    <>
      <View px="2xl" my="lg">
        <TaskCard
          heading={heading}
          subHeading={subHeading}
          headingRight={headerRightComponent()}
          topRightCorner={topRightCornerComponent()}
          bottomLeftCTA={bottomLeftCTAComponent()}
          bottomRightCTA={bottomRightCTAComponent()}
          swipeEnabled={false}
          shadow="md"
          blur={item?.blur}
          preExpanded={openedCard === item?._id}
          onCardPressCB={() => {
            if (openedCard !== item?._id && !isPaid && !isPaid?.length) {
              setOpenedCard({ id: item?._id });
            } else {
              setOpenedCard({ id: '' });
            }
          }}
        />
      </View>
      <CancelTransactionModel
        item={item}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        refresh={refresh}
      />
    </>
  );
};

export default RenderItem;
