import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { View } from '../../index';
import theme from '../../../../themes/new/theme';

interface NewProgressBarProps {
  width: number;
  animationTime: number;
  activeColor?: string;
  inactiveColor?: string;
  borderRadius?: string;
  height?: number;
}

const NewProgressBar = ({
  width,
  animationTime,
  activeColor,
  inactiveColor,
  borderRadius,
  height,
}: NewProgressBarProps) => {
  const animatedWidth = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      width: animatedWidth.value,
    };
  });

  useEffect(() => {
    animatedWidth.value = withTiming(width, {
      duration: animationTime,
      easing: Easing.linear,
    });
  }, []);

  return (
    <View
      style={{
        width,
        height: height ?? 2,
        backgroundColor: inactiveColor ?? theme.colors.primary['300'],
        borderRadius: borderRadius ?? 0,
        overflow: 'hidden',
      }}
    >
      <Animated.View
        style={[
          {
            height: height ?? 2,
            backgroundColor: activeColor ?? theme.colors.primary['50'],
          },
          animatedStyles,
        ]}
      />
    </View>
  );
};

export default NewProgressBar;
