/* eslint-disable no-nested-ternary */
import React from 'react';
import { TopNavigation, IconButton, Layout } from '../../../../components/new';
import { ControllerScreen } from './ControllerScannerScreen';

const ReviewDetailScreen = ({ navigation }) => {
  return (
    <Layout level="2">
      <TopNavigation
        appearance="ghost"
        level="none"
        title="Review Details"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            onPress={() => {
              navigation.goBack();
            }}
          />
        }
      />
      <ControllerScreen showEdit />
    </Layout>
  );
};

export default ReviewDetailScreen;
