import React from 'react';
import { Image } from 'react-native';
import { View, Text, Button } from '../../../components/new';
import { FontWeights, State } from '../../../themes/new/helper';
import {
  NOTABLETOSENTMESSAGEOPTIONSENUM,
  dateDiffInDays,
  getFullName,
  maskedNumber,
} from '../../../utilities/helper';
import useRestaurantPartnerStore from '../../../stores/restaurantpartner/restaurantPartnerStore';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';

const SendMessageBottomSheet = ({
  bottomSheetModalRef,
  handleSendMessage,
  sendMessageDisabled,
  selectedUser,
  showError,
  showOption,
  handleOnDismiss,
}) => {
  const { restaurantName } = useRestaurantPartnerStore((state) => state);
  const { user, lastVisit, msgText, lastComunication } = selectedUser ?? {};
  const today = new Date();
  const lastVisitDate = new Date(lastVisit);
  const lastComunicationDate = new Date(lastComunication);

  const options = [
    {
      id: 0,
      title: 'Not enough loyalty points',
      description: `You can only send invite to customers with more than 100 loyalty points.`,
      key: NOTABLETOSENTMESSAGEOPTIONSENUM.NOT_ENOUGH_LOYALTY_POINTS,
      lottieLink: require('../../../../assets/lottie/nudgeOne.json'),
    },
    {
      id: 1,
      title: `Recently visited campus. You can send an invite after ${
        dateDiffInDays(lastVisitDate, today) <= 1
          ? 1
          : dateDiffInDays(lastVisitDate, today)
      } ${dateDiffInDays(lastVisitDate, today) <= 1 ? 'day' : 'days'}`,
      description: `Please note that you can only send an invite to a customer 7 days after their last visit.`,
      key: NOTABLETOSENTMESSAGEOPTIONSENUM.NOT_VISITED_CAMPUS,
      lottieLink: require('../../../../assets/lottie/moving-car-blue.json'),
    },
    {
      id: 2,
      title: `Invite already sent! You can send the invite again after ${
        30 - dateDiffInDays(lastComunicationDate, today) <= 1
          ? 1
          : 30 - dateDiffInDays(lastComunicationDate, today)
      } ${
        30 - dateDiffInDays(lastComunicationDate, today) <= 1 ? 'day' : 'days'
      }`,
      description: `Please note that you'll need to wait for 30 days before you can resend the invite.`,
      key: NOTABLETOSENTMESSAGEOPTIONSENUM.INVITE_ALREADY_SENT,
      lottieLink: require('../../../../assets/lottie/green-bell.json'),
    },
  ];
  const selectedOption = options.filter((item) => item.key === showOption);

  return (
    <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
      <View>
        {!showError ? (
          <>
            <View mt="2xl" px="2xl">
              <Text size="lg" weight={FontWeights.MEDIUM} color="primary.400">
                Preview Message
              </Text>
              <Text size="xs" color="primary.100" mt="lg">
                {`This message will be send to ${getFullName(
                  user?.name?.first,
                  user?.name?.last || null,
                )} on his registered mobile number ${
                  user?.contact?.phone?.prefix
                }-${maskedNumber(user?.contact?.phone?.number)}`}
              </Text>
              <View borderWidth="xs" borderColor="grey.100" mt="lg" />
              <View mt="2xl" borderRadius="md" bg="primary.400" p="2xl">
                <Text size="sm" color="primary.10">
                  {msgText?.text}
                </Text>
              </View>
            </View>
            <View px="2xl" mt="xl">
              <Button
                size="lg"
                appearance="filled"
                state={sendMessageDisabled ? State.DISABLED : State.ACTIVE}
                onPress={handleSendMessage}
                status="primary"
              >
                Send Message On WhatsApp
              </Button>
            </View>
          </>
        ) : (
          <View mt="xl" px="2xl" alignItems="center" justifyContent="center">
            {selectedOption[0]?.id === 0 ? (
              <Image
                source={require('../../../../assets/images/wallet.webp')}
                style={{ width: 200, height: 200, resizeMode: 'contain' }}
              />
            ) : (
              <CustomLottieView
                style={{ width: 200, height: 200 }}
                autoPlay
                loop
                source={selectedOption[0]?.lottieLink}
                containerWidth={200}
              />
            )}
            <Text
              size="xl"
              weight="medium"
              color="primary.400"
              mt="2xl"
              textAlign="center"
            >
              {selectedOption[0]?.title}
            </Text>
            <Text size="sm" color="primary.200" mt="sm" textAlign="center">
              {selectedOption[0]?.description}
            </Text>
          </View>
        )}
      </View>
    </BottomSheetV2>
  );
};

export default SendMessageBottomSheet;
