import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import StaffHomeScreen from '../screens/PhotoMomentsStaff/StaffHomeScreen';
import PhotoMomentsStaffTabBar from './photoMomentsStaffTabBar';
import UserAccountScreen from '../screens/user/userAccountScreen';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import VideoMoments from '../screens/VideoMoments';
import PrintMoments from '../screens/new/PrintMoments/PrintMoments';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import About from '../screens/new/About';
import VideoLibrary from '../screens/videoMoments/VideoLibrary';
import OTPInputScreen from '../screens/auth/OTPInputScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const PhotoMomentsStaffHomeTab = () => (
  <Tab.Navigator
    // @ts-ignore
    keyboardHidesTabBar="true"
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <PhotoMomentsStaffTabBar {...props} />}
  >
    <Tab.Screen name="home" component={StaffHomeScreen} />
    <Tab.Screen name="staffAccount" component={UserAccountScreen} />
  </Tab.Navigator>
);
const PhotoMomentsStaffNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="staff" component={PhotoMomentsStaffHomeTab} />
      <Stack.Screen name="VideoMoments" component={VideoMoments} />
      <Stack.Screen name="PrintMoments" component={PrintMoments} />
      <Stack.Screen
        name="editProfile"
        options={{ gestureEnabled: false }}
        component={EditProfileScreen}
      />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="VideoLibrary" component={VideoLibrary} />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="phoneVerify" component={OTPInputScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
    </Stack.Navigator>
  );
};

export default PhotoMomentsStaffNavigator;
