/**
 * @generated SignedSource<<9cd7523359cb3fcee3ab63d25631629f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type restaurantListingQuery_findUserFragment$data = {
  readonly wallet: {
    readonly currentBalance: number | null;
  } | null;
  readonly " $fragmentType": "restaurantListingQuery_findUserFragment";
};
export type restaurantListingQuery_findUserFragment$key = {
  readonly " $data"?: restaurantListingQuery_findUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"restaurantListingQuery_findUserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "restaurantListingQuery_findUserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserWallet",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentBalance",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4299f201320c3dd2533e3ecc16d4ad36";

export default node;
