/**
 * @generated SignedSource<<f00108807e8211c9739d9e761df1b014>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnumTicketModelTCwoUserIssuedByRefType = "CAMPUS_LOCATION" | "EVENT" | "POPUP_STORE" | "RESTAURANT" | "%future added value";
export type EnumticketStatus = "ACTIVE" | "CANCELLED" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumticketTicketType = "EVENT_TICKET" | "PRODUCT_TICKET" | "REWARD_TICKET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RewardListTicketQueryFragment$data = {
  readonly tickets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly additionalFields: {
          readonly event: {
            readonly endTime: any;
            readonly startTime: any;
          } | null;
        } | null;
        readonly canBeClaimedBy: ReadonlyArray<{
          readonly venue: {
            readonly addressLine1: string | null;
            readonly geo: {
              readonly lat: string;
              readonly long: string;
            } | null;
          } | null;
        } | null> | null;
        readonly coverImg: string;
        readonly expires_on: any | null;
        readonly issuedBy: {
          readonly _id: any | null;
          readonly refType: EnumTicketModelTCwoUserIssuedByRefType;
        };
        readonly rewardId: any | null;
        readonly status: EnumticketStatus | null;
        readonly thumbnailImg: string;
        readonly ticketType: EnumticketTicketType;
        readonly title: string;
        readonly uId: number | null;
        readonly updated_at: any | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "RewardListTicketQueryFragment";
};
export type RewardListTicketQueryFragment$key = {
  readonly " $data"?: RewardListTicketQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RewardListTicketQueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "tickets"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./RewardsScreenTicketQuery.graphql')
    }
  },
  "name": "RewardListTicketQueryFragment",
  "selections": [
    {
      "alias": "tickets",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "ticketConnection",
      "kind": "LinkedField",
      "name": "__RewardListTicketQueryFragment__tickets_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ticketEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ticket",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ticketType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coverImg",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnailImg",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expires_on",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TicketModelTCwoUserIssuedBy",
                  "kind": "LinkedField",
                  "name": "issuedBy",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "refType",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TicketModelTCwoUserAdditionalFields",
                  "kind": "LinkedField",
                  "name": "additionalFields",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TicketModelTCwoUserAdditionalFieldsEvent",
                      "kind": "LinkedField",
                      "name": "event",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endTime",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TicketModelTCwoUserCanBeClaimedBy",
                  "kind": "LinkedField",
                  "name": "canBeClaimedBy",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TicketModelTCwoUserCanBeClaimedByVenue",
                      "kind": "LinkedField",
                      "name": "venue",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TicketModelTCwoUserCanBeClaimedByVenueGeo",
                          "kind": "LinkedField",
                          "name": "geo",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lat",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "long",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "addressLine1",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rewardId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updated_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e4e1f1345b08ba1fa78f9dec0137389f";

export default node;
