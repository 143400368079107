import React from 'react';
import Animated from 'react-native-reanimated';
import { Platform, SectionList, SectionListProps } from 'react-native';

const CustomSectionList = <ItemT,>(props: SectionListProps<ItemT>) => {
  // Using this hack for support on web
  const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);

  if (Platform.OS === 'web') {
    return (
      <AnimatedSectionList {...props}>{props.children}</AnimatedSectionList>
    );
  }
  return <SectionList {...props}>{props.children}</SectionList>;
};

export default CustomSectionList;
