import React from 'react';
import { ImageBackground } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { radii } from '../../../../themes/new/theme';
import { View } from '../..';
import LightThemeWrapper from '../../../../screens/new/wrapperComp/LightThemeWrapper';
import { AppTheme } from '../../../../stores/commonStore';

const bannerBgSrc = 'https://ik.imagekit.io/32ndhub/assets/bg_ontime_blur.png';

type BonusOverviewBGProps = {
  borderRadius?: number;
  children: JSX.Element;
};

const BonusOverviewBG = ({
  borderRadius = radii.lg + radii.sm,
  children = <></>,
}: BonusOverviewBGProps) => {
  const StatusBarStyle = AppTheme.LIGHT;
  const { top } = useSafeAreaInsets();
  return (
    <LightThemeWrapper>
      <StatusBar style={StatusBarStyle} />
      <View
        bg="primary.500"
        borderBottomLeftRadius={borderRadius}
        borderBottomRightRadius={borderRadius}
      >
        <ImageBackground
          source={{ uri: bannerBgSrc }}
          style={{
            overflow: 'hidden',
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            marginTop: top,
          }}
        >
          {children}
        </ImageBackground>
      </View>
    </LightThemeWrapper>
  );
};

export default BonusOverviewBG;
