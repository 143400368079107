import React, { memo } from 'react';
import { Image } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Text, View } from '../../../../components/new';

const areEqual = () => {
  return true;
};

const InvestPartners = ({ isDetails = false, data = null, bgColor = '' }) => {
  const theme = useTheme();

  const partnersArray = [
    {
      img: require('../../../../../assets/images/google.webp'),
      _id: 'google',
    },
    { img: require('../../../../../assets/images/mg.webp'), _id: 'mg' },
    {
      img: require('../../../../../assets/images/starbucks.webp'),
      _id: 'startbucks',
    },
  ];

  return (
    <View
      pt={isDetails ? '9xl+9xl' : '9xl'}
      px="2xl"
      pb={isDetails ? '9xl+9xl' : '3xl+9xl'}
      bg={bgColor || 'transparent'}
    >
      {isDetails ? (
        <View mb="5xl">
          <Text size="xs" color="primary.300" pb="lg" textAlign="center">
            {data?.heading}
          </Text>
          <Text size="2xl" color="primary.500" textAlign="center">
            {data?.subHeading}
          </Text>
        </View>
      ) : (
        <Text color="primary.300" size="xs" textAlign="center">
          OUR PARTNERS
        </Text>
      )}
      <View pt="2xl" flexDirection="row" justifyContent="space-between">
        {partnersArray.map((item) => (
          <Image
            key={`INV_PARTNERS${item?._id}`}
            source={item?.img}
            style={{ height: 48, width: 88 }}
          />
        ))}
      </View>
    </View>
  );
};

export default memo(InvestPartners, areEqual);
