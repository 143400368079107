import { variant as variantStyle, border, space, color } from 'styled-system';
import styled from 'styled-components/native';
import View from '../../../../basic/View/View';
import Text from '../../../../primitive/Typography/Text/Text';
import { PaymentStatusEnum } from '../../../../../../utilities/helper';
import { resolveStatusText } from './heplers';

const OuterStyledViewVariant = ({ theme }) => {
  return {
    [PaymentStatusEnum.SUCCESSFUL]: {
      bg: theme.colors.success[500],
    },
    [PaymentStatusEnum.FAILED]: {
      bg: theme.colors.error[500],
    },
    [PaymentStatusEnum.PENDING]: {
      bg: theme.colors.warning[500],
    },
    [PaymentStatusEnum.REFUNDED]: {
      bg: theme.colors.grey[400],
    },
  };
};

const StyledTextVariant = ({ theme }) => {
  return {
    [PaymentStatusEnum.SUCCESSFUL]: {
      color: theme.colors.primary[10],
    },
    [PaymentStatusEnum.FAILED]: {
      color: theme.colors.primary[10],
    },
    [PaymentStatusEnum.PENDING]: {
      color: theme.colors.primary[10],
    },
    [PaymentStatusEnum.REFUNDED]: {
      color: theme.colors.primary[10],
    },
  };
};

const OuterStyledView = styled.View`
  ${border}
  ${space}
  ${color}
  ${(props) =>
    variantStyle({ prop: 'status', variants: OuterStyledViewVariant(props) })}
`;

const StyledText = styled(Text)`
  ${(props) =>
    variantStyle({ prop: 'status', variants: StyledTextVariant(props) })}
`;

interface PaymentStatusTagProps {
  status: keyof typeof PaymentStatusEnum;
}

const PaymentStatusTag = ({
  status = PaymentStatusEnum.SUCCESSFUL,
}: PaymentStatusTagProps) => {
  return (
    <View>
      <OuterStyledView status={status} borderRadius="xs" px="sm+md" py="sm">
        <StyledText size="xs" status={status}>
          {resolveStatusText(status)}
        </StyledText>
      </OuterStyledView>
    </View>
  );
};

export default PaymentStatusTag;
