import React from 'react';
import { Platform } from 'react-native';
import { View, Text } from '../..';

type BonusConversionProps = {
  onTime: boolean;
  tasks: number;
  onTimeBonus: number;
  delayPenalty: number;
};

const BonusConversionInfo = ({
  onTime = false,
  tasks = 0,
  onTimeBonus,
  delayPenalty,
}: BonusConversionProps) => {
  const shadowProps =
    Platform.OS === 'web' || Platform.OS === 'ios'
      ? { borderColor: 'primary.50', shadow: 'sm' }
      : { borderColor: 'primary.50' };
  const heading = onTime ? 'On-Time Tasks' : 'Delayed Tasks';

  const item = onTime
    ? {
        id: 0,
        title: `${tasks} Tasks x ₹${onTimeBonus} = `,
        subTitle: `₹${tasks * onTimeBonus}`,
      }
    : {
        id: 1,
        title: `${tasks} Tasks x ₹${delayPenalty} = `,
        subTitle: `₹${tasks * delayPenalty}`,
      };

  return (
    <View>
      <Text color="primary.500" lineHeight="22" size="md" mx="2xl" mt="xl">
        {heading}
      </Text>
      <View
        borderRadius="lg"
        borderWidth="xs"
        backgroundColor="primary.20"
        mx="2xl"
        mt="4xl"
        p="2xl"
        flexDirection="row"
        alignItems="center"
        key={`time_guarantee_box${item?.id}`}
        // mb="9xl"
        {...shadowProps}
      >
        <View flex={1}>
          <Text size="lg" color="primary.500">
            {item?.title}{' '}
            <Text color={item?.id === 0 ? 'success.500' : 'error.500'}>
              {item?.subTitle}
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );
};
export default BonusConversionInfo;
