import { graphql } from 'react-relay';

// eslint-disable-next-line import/prefer-default-export
export const eventsListingScreenQuery = graphql`
  query eventsListingQuery(
    $filter: findEventInput!
    $sort: SortConnectioneventEnum
    $id: MongoID!
    $includeFindUser: Boolean!
    $count: Int!
    $cursor: String
    $addonsFilter: FilterFindManyaddonInput
  ) {
    findUserById(_id: $id) @include(if: $includeFindUser) {
      wallet {
        currentBalance
      }
    }
    findAddons(filter: $addonsFilter) {
      _id
      name
      price
      type
      additionalCashback {
        refType
        cashback {
          value
          type
        }
      }
      additionalDiscount {
        refType
        discount {
          value
          type
        }
      }
      expiryDuration {
        days
      }
    }
    ...eventsListingQueryfindEventsV2Fragment
      @arguments(filter: $filter, sort: $sort, count: $count, cursor: $cursor)
  }
`;

export const eventsListingQueryfindEventsV2Fragment = graphql`
  fragment eventsListingQueryfindEventsV2Fragment on Query
  @refetchable(queryName: "eventsListingfindEventsV2Query")
  @argumentDefinitions(
    filter: { type: "findEventInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
    sort: { type: "SortConnectioneventEnum" }
  ) {
    findEventsV2(filter: $filter, sort: $sort, first: $count, after: $cursor)
      @connection(key: "eventsListingQueryFragment__findEventsV2") {
      edges {
        node {
          _id
          title
          media {
            cover {
              mediaURL
              mediaType
              cover
              displayOrder
            }
          }
          eventTiming {
            date
            slot {
              startTime
              endTime
              slotsLeft
              availableTickets {
                name
                price
                basePrice
                personAllowed
                isActive
                slotsLeft
                _id
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
