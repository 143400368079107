import React, { useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Icon, Text, View } from '../components/new';
import Pressable from '../components/new/basic/Pressable/Pressable';
import useAuthStore from '../stores/authStore';

interface BottomTabRoutes {
  title: string;
  activeIcon: string;
  inactiveIcon: string;
  navigateTo: string;
}

const RoutesArr: BottomTabRoutes[] = [
  {
    title: 'Home',
    activeIcon: 'home-filled-300',
    inactiveIcon: 'home-outline-300',
    navigateTo: 'home',
  },
  {
    title: 'Collections',
    activeIcon: 'grid-filled-300',
    inactiveIcon: 'grid-outline-300',
    navigateTo: 'overview',
  },
  {
    title: 'Account',
    activeIcon: 'account-filled-300',
    inactiveIcon: 'account-outline-300',
    navigateTo: 'valetAccount',
  },
];
const AuditorTabBar = ({ navigation, state }) => {
  const selectedState = state.index;
  const isLoggedIn = useAuthStore((authState) => authState.isLoggedIn);
  return (
    <View
      bg="background.primary.base"
      borderTopColor="primary.50"
      borderTopWidth="xs"
    >
      <SafeAreaView edges={['bottom']}>
        <View
          flexDirection="row"
          justifyContent="space-between"
          px="2xl"
          py="sm+md"
        >
          {RoutesArr.map((route, index) => {
            return (
              <Pressable
                style={{ flex: 1, alignItems: 'center' }}
                key={route.title}
                onPress={() => {
                  navigation.navigate(route.navigateTo);
                }}
              >
                <View p="xs">
                  <View alignItems="center">
                    <Icon
                      size="2xl"
                      shape="square"
                      color={
                        index === selectedState ? 'primary.500' : 'primary.100'
                      }
                      name={
                        index === selectedState
                          ? route.activeIcon
                          : route.inactiveIcon
                      }
                    />
                  </View>
                  <Text
                    color={
                      index === selectedState ? 'primary.500' : 'primary.100'
                    }
                    size="2xs"
                  >
                    {route.title}
                  </Text>
                </View>
              </Pressable>
            );
          })}
        </View>
      </SafeAreaView>
    </View>
  );
};

export default AuditorTabBar;
