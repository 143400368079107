import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import NoCarFoundScreen from '../screens/new/kiosk/recall/NoCarFoundScreen';
import RecallStatusScreen from '../screens/new/kiosk/recall/RecallStatusScreenV2';
import RecallPerksScreen from '../screens/new/kiosk/recall/RecallPerksScreen';
import { KioskTheme, useKioskStore } from '../stores/kiosk/kioskStore';
import LightThemeWrapper from '../screens/new/wrapperComp/LightThemeWrapper';
import DarkThemeWrapper from '../screens/new/wrapperComp/DarkThemeWrapper';
import { kiosk } from '../utilities/Constant';
import ReviewCarDetailsScreen from '../screens/new/kiosk/recall/ReviewCarDetailsScreen';
import RecallInitiateScreen from '../screens/new/kiosk/recall/WelcomeScreen';
import RecallCarScreen from '../screens/new/kiosk/recall/RecallCarScreen';

const { THEME_POLLING_INTERVAL } = kiosk;

const KioskNavigator = () => {
  const Stack = createStackNavigator();
  const theme = useKioskStore((state) => state.theme);
  const darkModeEnabled = useKioskStore((state) => state.darkModeEnabled);
  const enableDarkMode = useKioskStore((state) => state.enableDarkMode);
  const enableLightMode = useKioskStore((state) => state.enableLightMode);
  /**
   * =================================================================
   *                    AUTO THEME TOGGLE LOGIC
   *                6am to 6pm 'LIGHT' else 'DARK'
   * =================================================================
   */
  useEffect(() => {
    const checkTheme = () => {
      const date = new Date();
      const hours = date.getHours();
      if (hours < 6 || hours >= 18) {
        if (!darkModeEnabled) enableDarkMode();
      } else if (darkModeEnabled) enableLightMode();
    };

    checkTheme(); // on first run
    const interval = setInterval(() => {
      checkTheme();
    }, THEME_POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [theme, enableDarkMode, enableLightMode]);

  const ThemeWrapper =
    theme === KioskTheme.LIGHT ? LightThemeWrapper : DarkThemeWrapper;

  return (
    <ThemeWrapper>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="kioskRecall" component={RecallInitiateScreen} />
        <Stack.Screen
          name="reviewCarDetails"
          component={ReviewCarDetailsScreen}
        />
        <Stack.Screen name="noCarFound" component={NoCarFoundScreen} />
        <Stack.Screen name="recallStatus" component={RecallStatusScreen} />
        <Stack.Screen name="memberPerks" component={RecallPerksScreen} />
        <Stack.Screen name="recallCar" component={RecallCarScreen} />
      </Stack.Navigator>
    </ThemeWrapper>
  );
};

export default KioskNavigator;
