import React from 'react';
import { ImageBackground, useWindowDimensions } from 'react-native';
import { Layout } from '../../../../../components/new';
import { imageTransformation } from '../../../../../utilities/helper';
import {
  KioskTheme,
  useKioskStore,
} from '../../../../../stores/kiosk/kioskStore';

const BgDark = 'https://ik.imagekit.io/32ndhub/assets/kiosk/kiosk-bg-dark.png';
const BgLight =
  'https://ik.imagekit.io/32ndhub/assets/kiosk/kiosk-bg-light.png';

const resolveBgImage = (theme: KioskTheme, width: number) => {
  return imageTransformation(
    theme === KioskTheme.LIGHT ? BgLight : BgDark,
    width,
  );
};

const CommonLayout = ({ children }: { children: any }) => {
  const theme = useKioskStore((state) => state.theme);
  let { width } = useWindowDimensions();
  width = width >= 1024 ? 1024 : width;

  return (
    <Layout>
      <ImageBackground
        source={{
          uri: resolveBgImage(theme, width),
        }}
        resizeMode="cover"
        style={{ flex: 1, alignItems: 'center' }}
      >
        {children}
      </ImageBackground>
    </Layout>
  );
};

export default CommonLayout;
