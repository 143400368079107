import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Platform, ScrollView } from 'react-native';
import Constants from 'expo-constants';
import _ from 'lodash';
import {
  Button,
  Divider,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import useLoginModalStore from '../../stores/loginModalStore';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import OTAConfig from '../../../react-native.config';
import { isValueNullOrEmpty } from '../../utilities/Utility';
import CustomPressable from '../../components/new/primitive/CustomPressable/CustomPressable';
import { navigateBack } from '../../utilities/helper';

const NotLoggedInAccountScreen = () => {
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const { version } = Constants.expoConfig;
  const modifiedAppVersion =
    !isValueNullOrEmpty(OTAConfig.ota) && Platform.OS !== 'web'
      ? `${version}-${OTAConfig.ota}`
      : version;
  const onContinueButtonPress = useCallback(() => {
    firebaseEventLogger('account__continueLogin_Tap', {
      buttonName: 'continueLogin',
      screenName: 'account',
      userType: 'user',
      interactionType: 'tap',
    });
    setAuthModalOpen(true);
  }, []);

  const navigation = useNavigation<any>();

  const pastOrders = () => {
    firebaseEventLogger('account__32ndOrders_Tap', {
      buttonName: '32ndOrders',
      screenName: 'account',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn: false,
    });
    navigation.navigate('pastOrders');
  };

  const handleMoments = () => {
    firebaseEventLogger('account__32ndMoments_Tap', {
      buttonName: '32ndMoments',
      screenName: 'account',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn: false,
    });
    navigation.navigate('PhotoMoments');
  };

  const handleRewards = () => {
    firebaseEventLogger('account__32ndhandleRewards_Tap', {
      buttonName: '32ndhandleRewards',
      screenName: 'account',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn: false,
    });
    navigation.navigate('NotLoggedInRewardsScreen');
  };

  const handleAbout = () => {
    firebaseEventLogger('account__about_Tap', {
      buttonName: 'about',
      screenName: 'account',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn: false,
    });
    navigation.navigate('About');
  };

  const handleBackPress = useCallback(
    _.throttle(
      () => {
        navigateBack(navigation, '');
      },
      1000,
      { trailing: false },
    ),
    [],
  );

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="none"
        IconLeft={
          <IconButton
            name="back-outline-300"
            appearance="ghost"
            onPress={handleBackPress}
          />
        }
      />
      {/* <SafeAreaView edges={['top']}> */}
      <ScrollView
        contentContainerStyle={{ paddingHorizontal: 16 }}
        bounces={false}
      >
        <View
          // mx="2xl"
          my="4xl"
          px="2xl"
          py="2xl"
          borderRadius="md"
          shadow="lg"
          borderWidth="xs"
          borderColor="primary.50"
          bg="background.primary.base"
        >
          <Text size="xl" color="primary.300">
            Your Profile
          </Text>
          <Text size="xs" color="primary.100">
            Login or sign up to view your complete profile and unlock exciting
            features
          </Text>

          <Button
            mt="2xl"
            size="lg"
            appearance="filled"
            status="primary"
            state="active"
            onPress={onContinueButtonPress}
          >
            Continue
          </Button>
        </View>
        <View>
          <CustomPressable
            appearance="ghost"
            size="md"
            textSize="md"
            textColor="primary.500"
            iconSize="md"
            shadow="md"
            state="active"
            status="primary"
            rounded="lg"
            // leftStyle={{ width: 28, height: 28 }}
            iconColor="primary.400"
            leftContent={null}
            rightContent="forward-outline-300"
            // mb="2xl"
            onPress={() => {
              pastOrders();
            }}
          >
            32nd Orders
          </CustomPressable>
          <Divider />
        </View>
        <View>
          <CustomPressable
            appearance="ghost"
            size="md"
            textSize="md"
            textColor="primary.500"
            iconSize="md"
            shadow="md"
            state="active"
            status="primary"
            rounded="lg"
            // leftStyle={{ width: 28, height: 28 }}
            iconColor="primary.400"
            leftContent={null}
            rightContent="forward-outline-300"
            // mb="2xl"
            onPress={() => {
              handleAbout();
            }}
          >
            About
          </CustomPressable>
          <Divider />
        </View>
      </ScrollView>
      {/* </SafeAreaView> */}
      <View position="absolute" bottom="4xl" width="100%" alignItems="center">
        <Text size="sm" color="grey.300">
          Version {modifiedAppVersion}
        </Text>
      </View>
    </Layout>
  );
};

export default NotLoggedInAccountScreen;
