import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const placeOrder = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation orderApiMutation($record: PlaceOrderInput) {
      placeOrder(record: $record) {
        orderId
      }
    }
  `;
  const variables = {
    record: data,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};

export const findOrders = (data, sort = '', limit = 50) => {
  const query = graphql`
    query orderApiQuery(
      $filter: findOrdersFilterInput
      $sort: SortFindManyorderInput
      $limit: Int
    ) {
      findOrders(filter: $filter, sort: $sort, limit: $limit) {
        _id
        orderDate
        status
        menuItems {
          itemId
          quantity
          defaultprice
          itemName
          itemVariation {
            _id
            name
            price
          }
          addonItem {
            _id
            name
            price
            quantity
          }
        }
        restaurant {
          displayName
        }
      }
    }
  `;
  const variables = {
    limit,
    sort,
    filter: data,
  };

  return fetchQuery(environment, query, variables).toPromise();
};
