import React, { useEffect, useRef, useState } from 'react';
import {
  FlatList,
  Pressable,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import { isEmpty, keyBy } from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { useFragment } from 'react-relay';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { useTheme } from 'styled-components/native';
import Icon from './new/primitive/Icon/Icon';
import { colors, getTheme, space } from '../themes/new/theme';
import { View, Text, Avatar, Card, Button } from '../components/new';
import LocatePhotographer from './LocatePhotographer';
import useVideoMomentsStore from '../stores/VideoMomentsStore';
import useAuthStore from '../stores/authStore';
import { groupPhotosOnDate, isValueNullOrEmpty } from '../utilities/Utility';
import { Capitalise } from '../utilities/helper';
import { firebaseEventLogger } from '../utilities/firbaseAnalytics';
import { homeScreenPhotoMomentWidgetFragment } from '../screens/new/BannerHomeScreen/API/homeScreenQuery';
import getPhotoMomentsUserPhotosMappingApi from '../relay/getPhotoMomentsUserMappingApi';
import BottomSheetV2 from './new/composites/BottomSheet/BottomSheetV2';
import CustomLottieView from './new/custom/CustomLottieView';
import CachedImage from './new/custom/CachedImage';
import { AppTheme } from '../stores/commonStore';

const bottomSheetStateMap = {
  GET_CLICKED_NOW: 'GET_CLICKED_NOW',
  PHOTOSHOOT_DONE: 'PHOTOSHOOT_DONE',
};

const PhotoMomentWidget = ({ fragmentRef = null }) => {
  const theme = useTheme();
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const [bottomSheetState, setBottomSheetState] = useState(null);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const navigation = useNavigation<any>();
  const {
    photoMomentsForWidget,
    photoMomentsWidgetHeader,
    totalPhotoMoments,
    scannedS3Imagekey,
    setPhotoMomentsForWidget,
  } = useVideoMomentsStore((state) => state);
  const photoMomentsData = useFragment(
    homeScreenPhotoMomentWidgetFragment,
    fragmentRef,
  );

  const preparePhotoMomentWidgetData = async () => {
    // Get deleted images from API
    const getDeletedImagesResponse =
      await getPhotoMomentsUserPhotosMappingApi();
    const { deletedImages } =
      getDeletedImagesResponse?.getPhotoMomentsUserMapping;

    const photoMomentsMap = keyBy(photoMomentsData, 'assetId');
    deletedImages?.forEach((assetId) => {
      if (photoMomentsMap?.[assetId]) {
        delete photoMomentsMap[assetId];
      }
    });

    const filteredPhotoMoments = Object.values(photoMomentsMap);

    const {
      groupPhotoMomentsOnDate,
      photoMomentsWidgetHeader: newPhotoMomentsWidgetHeader,
    }: any = groupPhotosOnDate(filteredPhotoMoments);
    const newTotalPhotoMoments = filteredPhotoMoments?.length;
    setPhotoMomentsForWidget(
      groupPhotoMomentsOnDate,
      newPhotoMomentsWidgetHeader,
      newTotalPhotoMoments,
    );
  };

  useEffect(() => {
    preparePhotoMomentWidgetData();
  }, [photoMomentsData]);

  useEffect(() => {
    firebaseEventLogger('photoMomentWidget_landing', {
      photoMomentsWidget: photoMomentsData,
      scannedOrNot: !!scannedS3Imagekey,
      imagesLength: photoMomentsData?.length || 0,
    });
  }, []);

  const handleOnDismiss = () => {
    setBottomSheetState(null);
  };

  const showBottomSheet = () => {
    bottomSheetModalRef?.current?.show();
  };

  const renderButton = ({ title, subtitle, onPress }) => {
    return (
      <View>
        <Pressable
          style={{
            backgroundColor: getTheme(theme?.currentThemeMode).colors
              .primary[500],
            paddingHorizontal: space['4xl'],
            paddingTop: space['4xl'],
            borderRadius: 16,
          }}
          onPress={onPress}
        >
          <View flexDirection="row" justifyContent="space-between">
            <Text color="primary.10" weight="medium" size="lg">
              {title}
            </Text>
            <Icon
              name="keyboard-arrow-right-300"
              color="primary.10"
              size="xl"
            />
          </View>
          <Text color="primary.300" size="sm" pb="4xl" mt="sm" mr="lg">
            {subtitle}
          </Text>
        </Pressable>
      </View>
    );
  };

  const onPressGetClickedNow = () => {
    setBottomSheetState(bottomSheetStateMap.GET_CLICKED_NOW);
    firebaseEventLogger('photoMomentWidget_GetClicked_now_Tap', {
      screenName: 'HomeScreen',
      buttonName: 'GetClicked_now',
      userType: 'user',
      interactionType: 'Tap',
    });
    bottomSheetModalRef?.current?.show();
  };

  const onPressPhotoShootDone = () => {
    bottomSheetModalRef?.current?.hide();
    setBottomSheetState(bottomSheetStateMap.PHOTOSHOOT_DONE);
    firebaseEventLogger('photoMomentWidget_PhotoShootDone_Tap', {
      screenName: 'HomeScreen',
      buttonName: 'Photoshoot_done',
      userType: 'user',
      interactionType: 'Tap',
    });
    setTimeout(() => {
      navigation.navigate('PhotoMoments');
    }, 100);
  };

  const renderBottomSheet = () => {
    return (
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View mt="2xl" px="2xl">
          {!bottomSheetState && (
            <>
              {renderButton({
                title: 'Get Clicked Now',
                subtitle: 'Photographers available for your free photoshoot',
                onPress: onPressGetClickedNow,
              })}
              <View mt={space['2xl']} />
              {renderButton({
                title: 'Photoshoot done?',
                subtitle:
                  'Securely access all your photos on your own device via facial recognition!',
                onPress: onPressPhotoShootDone,
              })}
            </>
          )}

          {bottomSheetState === bottomSheetStateMap.GET_CLICKED_NOW && (
            <LocatePhotographer
              onPress={() => {
                bottomSheetModalRef?.current?.hide();
              }}
            />
          )}
        </View>
      </BottomSheetV2>
    );
  };

  const renderNoDataView = () => {
    return (
      <Pressable onPress={showBottomSheet}>
        <Card
          borderRadius="lg"
          mx="2xl"
          shadow="sm"
          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <View maxWidth="60%">
            <Text weight="medium" size="lg">
              Your 32nd Moments
            </Text>
            <Text size="xs" mt="sm" color="primary.200">
              Get Clicked for FREE and receive your picture in real time!
            </Text>

            <View alignItems="flex-start">
              <Button
                state="active"
                status="info"
                appearance="ghost"
                size="md"
                onPress={showBottomSheet}
                iconRight="keyboard-arrow-right-300"
                iconLeft=""
              >
                Get Started
              </Button>
            </View>
          </View>
          <CustomLottieView
            autoPlay
            loop
            style={{
              height: 86,
              width: 120,
            }}
            containerWidth={120}
            source={require('../../assets/lottie/camera.json')}
          />
        </Card>
      </Pressable>
    );
  };

  const styleObj =
    photoMomentsForWidget?.length >= 4
      ? {
          flexGrow: 1,
          justifyContent: 'space-between',
        }
      : {};

  const navigateToPhotoMoments = (item) => {
    firebaseEventLogger('photoMomentWidget_Photo_Tap', {
      screenName: 'HomeScreen',
      buttonName: 'Photo',
      userType: 'user',
      interactionType: 'Tap',
      assetData: item,
    });
    navigation.navigate('PhotoMoments');
  };

  const renderPhotoMomentWidget = () => {
    return (
      <Pressable
        onPress={() => {
          firebaseEventLogger('photoMomentWidget_Widget_Tap', {
            screenName: 'HomeScreen',
            buttonName: 'Photo',
            userType: 'user',
            interactionType: 'Tap',
          });
          navigation.navigate('PhotoMoments');
        }}
      >
        <Card shadow="sm" borderRadius="lg" mx="2xl" style={{ flex: 1 }}>
          <View flexDirection="row">
            <CachedImage
              style={{
                width: 40,
                height: 40,
                borderWidth: 1,
                borderColor: colors.primary[50],
                borderRadius: 50,
              }}
              source={
                getTheme(theme?.currentThemeMode).currentThemeMode ===
                AppTheme.LIGHT
                  ? require('../../assets/images/32nd_logo_round.webp')
                  : require('../../assets/images/32nd-logo-dark.webp')
              }
            />
            <View ml={8}>
              <Text color="primary.400" size="md">
                {photoMomentsWidgetHeader}
              </Text>
              <Text size="xs" color="primary.200">
                {`${totalPhotoMoments} ${
                  totalPhotoMoments > 1 ? 'moments' : 'moment'
                }`}
              </Text>
            </View>
          </View>

          <View mt="3xl+xs">
            <FlatList
              data={photoMomentsForWidget}
              keyExtractor={(item, index) => index.toString()}
              horizontal
              contentContainerStyle={{ ...styleObj }}
              bounces={false}
              style={{ flex: 1 }}
              showsHorizontalScrollIndicator={false}
              renderItem={({ item, index }) => {
                return (
                  <View
                    ml={
                      index === 0
                        ? 0
                        : photoMomentsForWidget?.length < 4
                        ? '4xl'
                        : 'lg'
                    }
                  >
                    <View>
                      {item?.photo ? (
                        <Pressable onPress={() => navigateToPhotoMoments(item)}>
                          <Avatar
                            resizeMode="cover"
                            source={item?.photo}
                            level={4}
                          />
                        </Pressable>
                      ) : (
                        <TouchableOpacity
                          style={{
                            height: 72,
                            width: 72,
                            backgroundColor: colors.primary[400],
                            borderRadius: 50,
                          }}
                          onPress={onPressGetClickedNow}
                        />
                      )}

                      {!item?.photo && (
                        <Pressable
                          style={{
                            ...StyleSheet.absoluteFillObject,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          onPress={onPressGetClickedNow}
                        >
                          <CachedImage
                            source={require('../../assets/images/plus-icon.png')}
                            style={{ height: 40, width: 40 }}
                          />
                        </Pressable>
                      )}

                      {index !== 3 && item?.assetType === 'VIDEO' && (
                        <Pressable
                          style={[
                            style.overlay,
                            { backgroundColor: 'transparent' },
                          ]}
                          onPress={() => navigateToPhotoMoments(item)}
                        >
                          <Icon
                            name="play-filled-300"
                            color="primary.10"
                            size="xl"
                          />
                        </Pressable>
                      )}

                      {index === 3 && <View style={style.overlay} />}

                      {index === 3 && (
                        <Pressable
                          style={{
                            ...StyleSheet.absoluteFillObject,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          onPress={() => navigateToPhotoMoments(item)}
                        >
                          <Text style={style.text}>
                            {/* photoMomentsForWidget[0]?.photo --> if this exists then photos are available for today */}
                            {`+${
                              isEmpty(photoMomentsForWidget[0]?.photo)
                                ? totalPhotoMoments - 2
                                : totalPhotoMoments - 3
                            }`}
                          </Text>
                          <Text style={style.text} mx={3}>
                            {`${
                              isEmpty(photoMomentsForWidget[0]?.photo)
                                ? totalPhotoMoments - 2 > 1
                                  ? 'Moments'
                                  : 'Moment'
                                : totalPhotoMoments - 3 > 1
                                ? 'Moments'
                                : 'Moment'
                            }`}
                          </Text>
                        </Pressable>
                      )}
                    </View>

                    <Text
                      size="2xs"
                      mt={8}
                      alignSelf="center"
                      textAlign="center"
                    >
                      {Capitalise(item?.title)}
                    </Text>
                  </View>
                );
              }}
            />
          </View>
        </Card>
      </Pressable>
    );
  };
  return (
    <View>
      {!isLoggedIn ||
      isValueNullOrEmpty(scannedS3Imagekey) ||
      isEmpty(photoMomentsForWidget)
        ? renderNoDataView()
        : renderPhotoMomentWidget()}

      {renderBottomSheet()}
    </View>
  );
};

const style = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 50,
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#ffffff',
    fontSize: 10,
  },
});

export default PhotoMomentWidget;
