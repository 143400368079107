import React from 'react';
import PriceRow from './PriceRow';
import { View } from '../../../../components/new';
import { toRupees } from '../../../../utilities/helper';

interface EventBillBreakUpProps {
  data: {
    slotId: string;
    tickets: object;
    billAmount: number;
    sourceDetails: {
      event: {
        eventTiming: {
          _id: string;
          slot: {
            availableTickets: {
              price: number;
              basePrice: number;
              personAllowed: number;
            }[];
          };
        }[];
      };
    };
  };
}

const getTicketPriceBreakUp = (availableTickets, tickets) => {
  const priceBreakups = [];
  const ticketsPurchased = Object.values(tickets);
  ticketsPurchased.forEach((tp: any) => {
    const { ticketId, spaces } = tp;
    const avT = availableTickets?.find((avt) => avt?._id === ticketId);
    if (avT) {
      const total = spaces * avT?.price;
      priceBreakups.push({
        perPrice: avT?.price,
        total,
        count: spaces,
      });
    }
  });

  return priceBreakups;
};

const EventBillBreakUp = ({ data }: EventBillBreakUpProps) => {
  const { slotId, tickets, sourceDetails, billAmount } = data;
  const timings = sourceDetails?.event?.eventTiming;

  if (!slotId || !timings?.length) return null;

  const slot = timings.find((t) => {
    return t?._id === slotId;
  });
  if (!slot) return null;
  const priceBreakup = getTicketPriceBreakUp(
    slot?.slot?.availableTickets,
    tickets,
  );

  if (!priceBreakup?.length) return null;

  return (
    <View>
      {priceBreakup.map((pb) => {
        return (
          <View mb="2xl">
            <PriceRow
              leftContent={`${toRupees(pb?.perPrice)} x ${pb?.count} ${
                pb?.count > 1 ? 'tickets' : 'ticket'
              }`}
              rightContent={toRupees(pb?.total)}
            />
          </View>
        );
      })}
      <PriceRow
        leftContent="Bill Amount"
        rightContent={toRupees(billAmount)}
        emphasized
      />
    </View>
  );
};

export default EventBillBreakUp;
