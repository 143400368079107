import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';
import { paymentAmountScreenQuery } from '../screens/new/payment/api/paymentAmountScreenQuery';

export const AddParking = (data, callback, errorCallBack) => {
  const mutation = graphql`
    mutation parkingApiAddMutation($record: addParkingRecordInput!) {
      addParking(record: $record) {
        record {
          _id
          vehicle {
            brand {
              name
              brandLogo
            }
            color
            registration {
              plate
            }
          }
          owner {
            name {
              first
              last
            }
          }
          status
          journey {
            taskID
          }
          ownerId
          vehicleId
        }
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallBack(err);
    },
  });
};
export const updateParking = (data, callback, errorCallBack) => {
  // const mutation = graphql`
  // mutation parkingApiUpdateMutation($id: MongoID!, $record: updateParkingJouneyRecordInput!) {
  //   updateParkingJourney(_id: $id, record: $record){
  //     recordId
  //     record {
  //       journey {
  //         taskID
  //       }
  //     }
  //   }
  //   }
  //   `;
  const mutation = graphql`
    mutation parkingApiUpdateMutation(
      $id: MongoID!
      $record: updateParkingRecordInput!
    ) {
      updateParking(_id: $id, record: $record) {
        recordId
        record {
          journey {
            taskID
          }
        }
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors, data);
    },
    onError: (err) => {
      errorCallBack(err);
    },
  });
};

export const searchParking = (data) => {
  const query = graphql`
    query parkingApiSearchParkingQuery(
      $filter: searchParkingsFilterInput!
      $limit: Int
      $sort: SortFindManyParkingsearchInput
    ) {
      searchParking(filter: $filter, limit: $limit, sort: $sort) {
        _id
        status
        vehicle {
          _id
          registration {
            plate
          }
        }
        owner {
          _id
          name {
            first
            last
          }
          contact {
            phone {
              prefix
              number
              verified
            }
          }
          vehicles {
            _id
            isWhitelisted
            registration {
              plate
            }
          }
        }
        tasks {
          _id
          isVehicleWhitelisted
          taskType
          vehicle {
            registration {
              plate
            }
            brand {
              brandLogo
            }
          }
          taskStartLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorId
                floorName
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          taskEndLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorId
                floorName
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          parkingActivity {
            _id
          }
          campus {
            _id
            name
            contact {
              phone {
                prefix
                number
              }
            }
          }
          history {
            _id
            action
            actor
            event_at
          }
          assignedTo
          valet {
            name {
              first
              last
            }
            contact {
              phone {
                prefix
                number
              }
            }
          }
          vehicleId
          ownerId
          parkingId
          parkingTag
          ETA {
            delayAt
          }
        }
        parkingTag
        parkingInvoice {
          charges {
            discountAmount
            totalPayable
          }
        }
      }
    }
  `;
  const variables = { filter: data, limit: 100, sort: '_ID_DESC' };
  return fetchQuery(environment, query, variables).toPromise();
};

export const parkingApiFindParkingFragment = graphql`
  fragment parkingApiFindParkingFragment on Query
  @inline
  @refetchable(queryName: "parkingApiFindParkingQueryRefechable")
  @argumentDefinitions(
    filter: { type: "findParkingsFilterInput!" }
    count: { type: "Int!" }
    cursor: { type: "String" }
  ) {
    findParkingV2(filter: $filter, first: $count, after: $cursor)
      @connection(key: "parkingApiFindParkingFragment_findParkingV2") {
      edges {
        node {
          _id
          status
          parkingTag
          parked_at
          journey {
            taskID
          }
          parkingLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorId
                floorName
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          tasks {
            _id
            parkingTag
            taskType
            vehicle {
              brand {
                name
                brandLogo
              }
              color
              registration {
                plate
              }
            }
            taskStartLocation {
              locationId
              name
              parkingSpaces {
                floor {
                  floorId
                  floorName
                  bay {
                    bayId
                    bayName
                  }
                }
              }
            }
            taskEndLocation {
              locationId
              name
              parkingSpaces {
                floor {
                  floorId
                  floorName
                  bay {
                    bayId
                    bayName
                  }
                }
              }
            }
            campus {
              _id
              name
            }
            history {
              _id
              event_at
              actor
              action
            }
            isVehicleWhitelisted
            assignedTo
            vehicleId
            ownerId
            parkingId
            valet {
              name {
                first
                last
              }
              contact {
                phone {
                  prefix
                  number
                }
              }
            }
            user {
              name {
                first
                last
              }
              contact {
                phone {
                  prefix
                  number
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const findParking = (data, count: number) => {
  const query = graphql`
    query parkingApiFindParkingQuery(
      $filter: findParkingsFilterInput!
      $count: Int!
    ) {
      ...parkingApiFindParkingFragment
        @arguments(filter: $filter, count: $count)
    }
  `;
  const variables = { filter: data, count };
  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};

// export const findParking = ""
export const getNetPriceApi = (variables) => {
  return fetchQuery(
    environment,
    paymentAmountScreenQuery,
    variables,
    {},
  ).toPromise();
};
