import React, { useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  View,
  Text,
  Button,
  RadioGroup,
  Layout,
  TopNavigation,
  IconButton,
  Input,
} from '../../components/new';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import useUserStore from '../../stores/userStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { updateBill } from './API/billUploadReviewerApi';
import { navigateBack, UPLOAD_BILL_STATUS } from '../../utilities/helper';
import { Appearance, State, Status } from '../../themes/new/helper';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { InputStates } from '../../components/new/primitive/Input/helpers';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

const approveReasonData = [
  { name: 'Reconciled', checked: true },
  { name: 'Not Reconciled', checked: false },
];

const rejectionReasonData = [
  { name: 'Mismatch in amount', checked: true },
  { name: 'Invalid restaurant', checked: false },
  {
    name: 'Bill older than 30 days',
    checked: false,
  },
  { name: 'Image not clear', checked: false },
  { name: 'Bill already uploaded', checked: false },
];

const BillActionScreen = ({ route, navigation }) => {
  const { action, _id, showInput } = route?.params ?? {};
  const { role } = useUserStore((state) => state);
  const { bottom } = useSafeAreaInsets();
  const [billNumber, setBillNumber] = useState('');
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [netStatus, setNetStatus] = useState(true);
  const [approveBillReason, setApproveBillReason] = useState(approveReasonData);
  const [rejectBillReason, setRejectBillReason] = useState(rejectionReasonData);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    firebaseEventLogger('BillActionScreen__billActionScreen_Landing', {
      action,
      _id,
    });
  }, []);

  // ------ handle text change for input ------
  const handleOnTextChange = (num) => {
    setBillNumber(num.replace(/\s/g, '').toString());
  };

  const selectApprovalReasonIndex = approveBillReason?.findIndex(
    (item) => item.checked,
  );

  const selectRejectionReasonIndex = rejectBillReason?.findIndex(
    (item) => item.checked,
  );

  /* ------ handleUpdateBill if response then navigate
  to home screen------
  */
  const handleUpdateBill = (response) => {
    setButtonDisabled(false);
    if (response?.updateBill !== null) {
      navigation.navigate(role);
    }
  };

  // ------ API error handler for update bill ------
  const updateBillApiErrorHandler = (error) => {
    setButtonDisabled(false);
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v2,
    });
  };

  const handlePress = () => {
    firebaseEventLogger('BillActionScreen__pressed_Tap', {
      action,
      _id,
      billNumber,
    });
    setButtonDisabled(true);
    const data = {};
    (data as any)._id = _id;
    (data as any).record = {};
    (data as any).record.status = action;
    (data as any).record.approvalNotes = {};
    (data as any).record.approvalNotes.message =
      action === UPLOAD_BILL_STATUS.VERIFICATION_FAILED
        ? rejectionReasonData[selectRejectionReasonIndex].name
        : approveReasonData[selectApprovalReasonIndex].name;
    if (showInput) {
      (data as any).record.approvalNotes.data = {};
      (data as any).record.approvalNotes.data.bill = {};
      (data as any).record.approvalNotes.data.bill.billId = billNumber;
    }
    updateBill(data, handleUpdateBill, updateBillApiErrorHandler);
  };

  const checkIfButtonDisabled = () => {
    if ((billNumber?.length <= 0 || buttonDisabled) && showInput) {
      return true;
    }
    return false;
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance={Appearance.FILLED}
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance={Appearance.GHOST}
            onPress={() => navigateBack(navigation, role)}
          />
        }
      />
      <View flex={1}>
        <View mt="2xl" px="2xl">
          {showInput ? (
            <Input
              state={InputStates.default}
              label="Enter Bill Number*"
              returnKeyType="next"
              placeholder="eg: 123456789"
              value={billNumber}
              onChangeText={handleOnTextChange}
              maxLength={20}
              autoFocus
            />
          ) : (
            <></>
          )}
          <View mt="4xl">
            <Text size="md" color="primary.300">
              Select reason for{' '}
              {action === UPLOAD_BILL_STATUS.VERIFICATION_FAILED
                ? 'rejection*'
                : 'approval*'}
            </Text>
            <View mt="lg">
              {action === UPLOAD_BILL_STATUS.VERIFICATION_FAILED ? (
                <RadioGroup
                  radio={rejectionReasonData}
                  setRadio={(arr) => setRejectBillReason(arr)}
                  title=""
                  isRequired
                  size="lg"
                  icon="right"
                  collapsedCount={5}
                />
              ) : (
                <RadioGroup
                  radio={approveBillReason}
                  setRadio={(arr) => setApproveBillReason(arr)}
                  title=""
                  isRequired
                  size="lg"
                  icon="right"
                  collapsedCount={5}
                />
              )}
            </View>
          </View>
        </View>
        <View
          position="absolute"
          bottom={0}
          borderTopWidth="xs"
          width="100%"
          borderColor="primary.50"
          bg="background.primary.base"
        >
          <View flexDirection="row" alignItems="center" p="2xl" mb={bottom}>
            <View flex={1}>
              <Button
                size="md"
                state={checkIfButtonDisabled() ? State.DISABLED : State.ACTIVE}
                status={Status.PRIMARY}
                appearance={Appearance.FILLED}
                onPress={handlePress}
              >
                {action === UPLOAD_BILL_STATUS.VERIFICATION_FAILED
                  ? 'Confirm Rejection'
                  : 'Confirm Approval'}
              </Button>
            </View>
          </View>
        </View>
      </View>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default BillActionScreen;
