/**
 * @generated SignedSource<<606697fe21f67e78061f60b1b8ac08d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PhotoMomentsInputV3 = {
  assetId: string;
  rescanning?: boolean | null;
};
export type findPhotosV3ApiQuery$variables = {
  data?: PhotoMomentsInputV3 | null;
};
export type findPhotosV3ApiQuery$data = {
  readonly getMatchingPhotosForPhotoMomentsV3: ReadonlyArray<{
    readonly assetId: string;
    readonly assetLink: string;
    readonly assetType: string;
    readonly clickedOn: any | null;
    readonly similarity: number | null;
    readonly thumbnail: string | null;
    readonly thumbnailId: string | null;
  } | null> | null;
};
export type findPhotosV3ApiQuery = {
  response: findPhotosV3ApiQuery$data;
  variables: findPhotosV3ApiQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "PhotoMomentsV3",
    "kind": "LinkedField",
    "name": "getMatchingPhotosForPhotoMomentsV3",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assetType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assetId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assetLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clickedOn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "similarity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "findPhotosV3ApiQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "findPhotosV3ApiQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0828a056e81b966f4e1ce368547b16a6",
    "id": null,
    "metadata": {},
    "name": "findPhotosV3ApiQuery",
    "operationKind": "query",
    "text": "query findPhotosV3ApiQuery(\n  $data: PhotoMomentsInputV3\n) {\n  getMatchingPhotosForPhotoMomentsV3(data: $data) {\n    assetType\n    assetId\n    assetLink\n    clickedOn\n    similarity\n    thumbnail\n    thumbnailId\n  }\n}\n"
  }
};
})();

(node as any).hash = "f293d8093e783a99fe43a64ba528765d";

export default node;
