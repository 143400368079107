/**
 * @generated SignedSource<<ac018dc6582a30fa9c49f29a8b986af6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type findVehiclesFilterInput = {
  _id?: any | null;
  ownerID: any;
  registration?: FilterFindManyVehicleRegistrationInput | null;
};
export type FilterFindManyVehicleRegistrationInput = {
  _id?: any | null;
  city?: string | null;
  plate?: string | null;
  state?: string | null;
};
export type findVehicleQuery$variables = {
  filter?: findVehiclesFilterInput | null;
};
export type findVehicleQuery$data = {
  readonly findVehicles: ReadonlyArray<{
    readonly _id: any;
    readonly brand: {
      readonly _id: any | null;
      readonly brandLogo: string | null;
      readonly name: string | null;
    } | null;
  }>;
};
export type findVehicleQuery = {
  response: findVehicleQuery$data;
  variables: findVehicleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "Vehicle",
    "kind": "LinkedField",
    "name": "findVehicles",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VehicleBrand",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brandLogo",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "findVehicleQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "findVehicleQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "415b80c3e3df746f91d08a79f1dd19d6",
    "id": null,
    "metadata": {},
    "name": "findVehicleQuery",
    "operationKind": "query",
    "text": "query findVehicleQuery(\n  $filter: findVehiclesFilterInput\n) {\n  findVehicles(filter: $filter) {\n    brand {\n      _id\n      name\n      brandLogo\n    }\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0bed5ccee8283299d0d968c72a9d870";

export default node;
