import React from 'react';
import { Card, Divider, Text, View } from '../../../components/new';
import PaymentsImage from '../../../../assets/images/payments.webp';
import CachedImage from '../../../components/new/custom/CachedImage';

const TicketBillingCard = ({
  netAmount = '',
  discountAmount = '',
  discountPercentage = 0,
  rewardsAmountUsed = '',
  paid = false,
  ticketsInfo = [],
}) => {
  return (
    <Card>
      {ticketsInfo?.length &&
        ticketsInfo?.map((item) => {
          return (
            <View>
              {item?.count > 0 ? (
                <View
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb="lg"
                >
                  <Text color="primary.200" size="xs">{`${
                    item?.name
                  } (₹${Math.ceil(item?.price / item?.personAllowed)} x ${
                    item?.count * item?.personAllowed
                  } ${item?.count > 1 ? 'tickets' : 'ticket'})`}</Text>
                  <Text color="primary.200" size="xs">{`₹${
                    item?.price * item?.count
                  }`}</Text>
                </View>
              ) : null}
            </View>
          );
        })}
      {Number(discountAmount) !== 0 && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="success.400">
            32nd Discount ({discountPercentage}%)
          </Text>
          <Text size="sm" color="success.400">
            -₹{discountAmount}
          </Text>
        </View>
      )}
      {!!(Number(rewardsAmountUsed) > 0) && (
        <View flexDirection="row" mb="2xl">
          <View flexDirection="row">
            <Text size="sm" color="success.400">
              Savings via 32nd
            </Text>
            <View ml="sm">
              <CachedImage
                source={PaymentsImage}
                style={{ height: 24, width: 24 }}
              />
            </View>
          </View>
          <View flex={1} alignItems="flex-end">
            <Text size="sm" color="success.400">
              -₹{rewardsAmountUsed}
            </Text>
          </View>
        </View>
      )}
      <Divider />
      <View flexDirection="row" mt="lg">
        <View>
          <Text size="sm" color="primary.400" weight="medium">
            You {paid ? 'Paid' : 'Pay'}
          </Text>
        </View>
        <View flex={1} alignItems="flex-end">
          <Text size="sm" color="primary.400" weight="medium">
            ₹{netAmount}
          </Text>
        </View>
      </View>
    </Card>
  );
};

export default TicketBillingCard;
