/* eslint-disable import/prefer-default-export */
export enum SnackbarStatus {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export enum SnackbarVersion {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3',
}
