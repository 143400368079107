/* eslint-disable no-nested-ternary */
import React, { ReactNode, ReactChild } from 'react';
import { variant, color, space, border } from 'styled-system';
import styled from 'styled-components/native';
import { Image } from 'react-native';
import StyledText from '../Typography/Text/Text';
import StyledIcon from '../Icon/Icon';
import View from '../../basic/View/View';
import Pressable from '../../basic/Pressable/Pressable';
import {
  Appearance,
  Shape,
  State,
  Status,
  ThemeVariables,
} from '../../../../themes/new/helper';

export type CustomPressablePropTypes = {
  children?: string;
  size?: string;
  iconSize?: string;
  textSize?: string;
  state?: string;
  appearance?: string;
  leftContent?: any;
  leftStyle?: any;
  rightStyle?: any;
  rightContent?: ReactNode | string;
  rounded?: string;
  shadow?: string;
  status?: string;
  [x: string]: any;
};

const StyledPressable = styled(Pressable).attrs(
  ({ shadow, appearance, state, status, theme }) => ({
    pressStyle: {
      backgroundColor:
        appearance === Appearance.FILLED
          ? theme.colors.grey[10]
          : 'transparent',
      borderColor:
        appearance === Appearance.FILLED
          ? theme.colors[status][50]
          : 'transparent',
      opacity: appearance === Appearance.FILLED ? 1 : 0.5,
    },
    shadow:
      appearance === Appearance.GHOST || state === State.DISABLED
        ? 'none'
        : shadow || 'md',
  }),
)`
  ${color}
  ${space}
    ${border}
    ${(props) => sizeVariants(props)}
    ${(props) => appearanceVariants(props)}
    ${(props) => roundedVariants(props)}
    ${(props) => statusVariants(props)}
    ${(props) => stateVariants(props)}
    ${({ shadow, theme }) =>
    shadow && shadow !== 'none' ? theme.boxShadows[shadow] : ''}
    ${({ shadow, theme }) => ({
    'shadow-color':
      shadow && shadow !== 'none'
        ? theme.colors.singletone.black
        : 'transparent',
  })}
`;

const stateVariants = ({ appearance, status, theme, state }) => {
  return variant({
    prop: ThemeVariables.STATE,
    variants: {
      active: {
        backgroundColor:
          appearance === Appearance.FILLED
            ? theme.colors[status][10]
            : 'transparent',
        borderColor:
          appearance === Appearance.FILLED
            ? theme.colors[status][50]
            : 'transparent',
        opacity:
          appearance === Appearance.FILLED
            ? 1
            : appearance === Appearance.GHOST && state !== State.DISABLED
            ? 1
            : 0.5,
      },
      pressed: {
        backgroundColor:
          appearance === Appearance.FILLED
            ? theme.colors.grey[10]
            : 'transparent',
        borderColor:
          appearance === Appearance.FILLED
            ? theme.colors[status][50]
            : 'transparent',
        opacity: appearance === Appearance.FILLED ? 1 : 0.5,
      },
      disabled:
        appearance === Appearance.FILLED
          ? {
              // opacity: 0.5,
              bg: theme.colors[status][50],
              borderColor: theme.colors[status][10],
            }
          : {
              // bg: 'transparent',
              // color: theme.colors[status][100],
              opacity: 0.4,
            },
    },
  });
};

const statusVariants = ({ theme }) => {
  return variant({
    prop: ThemeVariables.STATUS,
    variants: {
      primary: {},
      error: {},
      info: {},
    },
  });
};

const roundedVariants = ({ theme }) => {
  return variant({
    prop: Shape.ROUNDED,
    variants: {
      md: {
        borderRadius: theme.radii.md,
      },
      lg: {
        borderRadius: theme.radii.lg,
      },
      '4xl': {
        borderRadius: theme.radii['4xl'],
      },
    },
  });
};

const appearanceVariants = ({ status, theme }) => {
  return variant({
    prop: ThemeVariables.APPEARANCE,
    variants: {
      filled: {
        bg: theme.colors[status]['10'],
        color: theme.colors[status]['10'],
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors[status]['50'],
      },
      outline: {
        bg: 'transparent',
        color: theme.colors[status]['500'],
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors[status]['500'],
      },
      ghost: {
        // bg: theme.colors.primary[10],
        color: theme.colors[status]['500'],
        borderWidth: theme.borderWidths.xs,
        borderColor: 'transparent',
        paddingX: 0,
      },
    },
  });
};

const sizeVariants = ({ theme }) =>
  variant({
    prop: ThemeVariables.SIZE,
    variants: {
      sm: {
        paddingY: theme.space.sm,
        paddingX: 0,
      },
      md: {
        paddingY: theme.space['2xl'],
        paddingX: theme.space['2xl'],
      },
      lg: {
        paddingY: theme.space['sm+md'],
        paddingX: theme.space['2xl'],
      },
      xl: {
        paddingY: theme.space.xl,
        paddingX: theme.space['2xl'],
      },
    },
  });

const Text = styled(StyledText).attrs(({ textSize, textColor }) => ({
  // size: textSize === 'md' ? 'sm' : textSize === 'sm' ? 'xs' : 'md',
  size: textSize,
  color: textColor,
}))``;

const Icon = styled(StyledIcon).attrs(
  ({ buttonSize, iconSize, theme, iconColor, state, appearance }) => ({
    size: iconSize || (buttonSize === 'xl' ? '2xl' : 'xl'),
    color:
      state === State.DISABLED
        ? 'primary.100'
        : iconColor && iconColor !== ''
        ? iconColor
        : appearance === Appearance.FILLED
        ? 'primary.10'
        : 'primary.500',
  }),
)``;

const CustomPressable = ({
  children = '',
  size = 'md',
  iconSize,
  textSize,
  textColor = 'primary.400',
  leftStyle,
  iconColor,
  state,
  appearance = Appearance.FILLED,
  leftContent,
  rightContent,
  status = Status.PRIMARY,
  rounded = 'lg',
  shadow = 'md',
  subText = '',
  subTextColor = 'primary.400',
  subTextSize = 'xs',

  ...rest
}: CustomPressablePropTypes) => {
  return (
    <StyledPressable
      {...rest}
      rounded={rounded}
      shadow={shadow}
      size={size}
      textSize={textSize}
      appearance={appearance}
      state={state}
      disabled={state === State.DISABLED}
      status={status}
    >
      <View
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <View flexDirection="row" alignItems="center">
          {leftContent ? (
            <Image
              source={leftContent}
              fadeDuration={0}
              style={{
                width: leftStyle?.width,
                height: leftStyle?.height,
                marginRight: 12,
              }}
            />
          ) : (
            <></>
          )}
          <Text
            variants="text"
            textSize={textSize}
            appearance={appearance}
            selectable={false}
            ellipsizemode="tail"
            state={state}
            status={status}
            textColor={textColor}
          >
            {children}
          </Text>
          {subText ? (
            <View ml="lg" top={2}>
              <Text
                textColor={subTextColor}
                textSize={subTextSize}
                ellipsizemode="tail"
              >
                {subText}
              </Text>
            </View>
          ) : null}
        </View>
        {rightContent && typeof rightContent === 'string' ? (
          <Icon
            buttonSize={size}
            iconSize={iconSize}
            name={rightContent}
            iconColor={iconColor}
            iconAppearance={appearance}
            ml={size === 'sm' ? 0 : 'sm'}
            state={state}
            status={status}
          />
        ) : rightContent && typeof rightContent !== 'string' ? (
          // <Icon
          //   buttonSize={size}
          //   iconSize={iconSize}
          //   name={rightContent}
          //   iconColor={iconColor}
          //   iconAppearance={appearance}
          //   ml={size === 'sm' ? 0 : 'sm'}
          //   state={state}
          //   status={status}
          // />
          <>{rightContent}</>
        ) : (
          <></>
        )}
      </View>
    </StyledPressable>
  );
};

export default CustomPressable;
