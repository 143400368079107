/**
 * @generated SignedSource<<2162b9ce7fbf774f430be1279b11413a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumvaletWorkLogWorkLogHistoryType = "break_end" | "break_start" | "punch_in" | "punch_out" | "%future added value";
export type findValetWorkLogFilterInput = {
  date?: any | null;
  valetID: any;
};
export type valetWorkLogQuery$variables = {
  filter: findValetWorkLogFilterInput;
};
export type valetWorkLogQuery$data = {
  readonly findValetWorkLog: {
    readonly _id: any;
    readonly taskScore: number | null;
    readonly taskScoreTime: number | null;
    readonly workLogHistory: ReadonlyArray<{
      readonly time: any;
      readonly type: EnumvaletWorkLogWorkLogHistoryType | null;
    } | null> | null;
  } | null;
};
export type valetWorkLogQuery = {
  response: valetWorkLogQuery$data;
  variables: valetWorkLogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "valetWorkLog",
    "kind": "LinkedField",
    "name": "findValetWorkLog",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskScore",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskScoreTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "valetWorkLogWorkLogHistory",
        "kind": "LinkedField",
        "name": "workLogHistory",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "valetWorkLogQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "valetWorkLogQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0b903234ab3b4adee1e1765fa6da3120",
    "id": null,
    "metadata": {},
    "name": "valetWorkLogQuery",
    "operationKind": "query",
    "text": "query valetWorkLogQuery(\n  $filter: findValetWorkLogFilterInput!\n) {\n  findValetWorkLog(filter: $filter) {\n    _id\n    taskScore\n    taskScoreTime\n    workLogHistory {\n      time\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cc607870f3606cd309ee60adf6ca3fb";

export default node;
