/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native';
import {
  Layout,
  View,
  Text,
  IconButton,
  TopNavigation,
  Button,
  Icon,
} from '../../components/new';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useCommonStore from '../../stores/commonStore';
import useUserStore, { UserRole } from '../../stores/userStore';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import {
  PaymentGatewayType,
  maskedNumber,
  navigateBack,
  paymentOrderTypeEnum,
} from '../../utilities/helper';
import TaskCard from '../../components/new/primitive/TaskCard/TaskCard';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { partnerCashPaymentScreenQuery } from './API/partnerCashPaymentScreenQuery';
import useAuthStore from '../../stores/authStore';
import { createPopupOrderAPI } from '../API/createPopupOrder';
import { initiatePaymentAPI } from '../new/payment/api/paymentAmountScreenQuery';

interface PartnerCashPaymentScreenProps {
  queryOptions: any;
  variables: any;
  taskTypeFromParams: string;
  fetchKey: any;
  partnerPayment: number;
}

const PartnerCashPaymentScreen = ({
  queryOptions,
  variables,
  taskTypeFromParams,
  fetchKey,
  partnerPayment,
}: PartnerCashPaymentScreenProps) => {
  const navigation = useNavigation<any>();
  const { role, id: userId } = useUserStore((state) => state);
  const { campusId } = useCommonStore((state) => state);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { findPopUpStores, searchUsers } = useLazyLoadQuery(
    partnerCashPaymentScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const orderId = findPopUpStores?.edges[0]?.node?._id;

  const { contact, name, _id } = searchUsers[0] ?? {};

  const RenderUserDetails = ({
    title,
    text,
    isVerified,
    showNotVerifiedText,
    iconName,
  }) => {
    return (
      <View>
        <Text size="sm" color="primary.200">
          {title}
        </Text>
        <View
          mt="lg"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            size="lg"
            weight="medium"
            color={isVerified ? 'primary.500' : 'primary.200'}
          >
            {text}
          </Text>
          {isVerified && iconName?.length ? (
            <View>
              <Icon name={iconName} size="2xl" color="primary.500" />
            </View>
          ) : showNotVerifiedText ? (
            <Text size="xs" color="primary.100">
              Not Verified
            </Text>
          ) : (
            <></>
          )}
        </View>
      </View>
    );
  };

  const sendToAuditor = () => {
    firebaseEventLogger('sendToAuditor__sendToAuditor_Screen', {
      buttonName: 'sendToAuditor',
      screenName: 'sendToAuditor',
      userType: UserRole.PARTNER,
      interactionType: InteractionType.TAP,
      isLoggedIn,
      partnerId: userId,
      amount: Number(partnerPayment),
      orderType: paymentOrderTypeEnum.POPUPSTORE,
      orderId,
      useRewardPoints: false,
      userId: _id,
      paymentType: PaymentGatewayType.CASH,
    });
    const data = {
      record: {
        amount: Number(partnerPayment),
        userId: _id,
        referenceModelId: orderId,
        referenceModelName: paymentOrderTypeEnum.POPUP_ORDER,
        campus: {
          _id: campusId,
        },
        paymentType: PaymentGatewayType.CASH,
      },
    };
    createPopupOrderAPI(
      data,
      (res) => {
        if (res && res?.createPopupOrder && res?.createPopupOrder?.record) {
          initiatePaymentAPI(
            {
              amount: Number(partnerPayment),
              orderType: paymentOrderTypeEnum.POPUP_ORDER,
              orderId: res?.createPopupOrder?.record?._id,
              useRewardPoints: false,
              paymentType: PaymentGatewayType.CASH,
              userId: _id,
            },
            (resp) => {
              if (resp && resp?.initiatePayment) {
                navigation.replace('SentToAuditorScreen');
              }
            },
            (err) => {
              dispatchSnackbar({
                msg: 'Something Went Wrong!',
                status: SnackbarStatus.error,
                version: SnackbarVersion.v2,
                position: 'top',
                removeAfter: 4000,
              });
            },
          );
        }
      },
      (err) => {
        dispatchSnackbar({
          msg: 'Something Went Wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
      },
    );
  };

  const bottomLeftCTAComponent = () => {
    return (
      <Button
        size="lg"
        state="active"
        status="primary"
        appearance="filled"
        onPress={sendToAuditor}
      >
        Send to Auditor
      </Button>
    );
  };

  return (
    <View flex={1}>
      <ScrollView>
        <View px="2xl" mt="4xl" justifyContent="space-between">
          <View>
            <View
              mb="9xl"
              borderRadius="lg"
              borderColor="primary.50"
              borderWidth="xs"
              px="2xl"
              py="2xl"
            >
              <View>
                <RenderUserDetails
                  title="Mobile Number"
                  text={`${contact?.phone?.prefix} ${maskedNumber(
                    contact?.phone?.number,
                  )}`}
                  isVerified={contact?.phone?.verified}
                  showNotVerifiedText
                  iconName="check-filled-400"
                />
              </View>
              <View mt="4xl">
                <RenderUserDetails
                  title="Name"
                  text={`${name?.first || ''} ${name?.last || ''}`}
                  isVerified={contact?.phone?.verified}
                  iconName="check-filled-400"
                />
              </View>
              <View mt="4xl">
                <RenderUserDetails
                  title="Bill Amount"
                  text={partnerPayment}
                  isVerified={contact?.phone?.verified}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View position="absolute" bottom={0} width="100%" bg="primary.10">
        <TaskCard
          bottomLeftCTA={bottomLeftCTAComponent()}
          shadow="lg"
          alwaysOpened
        />
      </View>
    </View>
  );
};

const PartnerCashPaymentScreenWrapper = ({ navigation, route }) => {
  const { partnerPayment, contact } = route?.params ?? {};
  const campusID = useCommonStore((state) => state.campusId);
  const { role, id: userId } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  const filter = {
    ownerId: userId,
    campus: { _id: campusID },
  };

  const searchFilter = {
    contact: {
      phone: contact,
    },
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Cash Payment"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <PartnerCashPaymentScreen
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            partnerPayment={partnerPayment}
            variables={{ filter, searchFilter }}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default PartnerCashPaymentScreenWrapper;
