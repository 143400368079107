import { Platform } from 'react-native';

const FacebookPixelLogger = (name: string, value: string): void => {
  if (Platform.OS === 'web') {
    console.log('FacebookPixelLogger >>', value);
    // @ts-ignore
    if (window && window.fbq) {
      // @ts-ignore
      window.fbq(name, value);
    }
  }
};

export default FacebookPixelLogger;
