import { graphql, commitMutation } from 'react-relay';
import environment from './relayEnvironment';

export const rewardDataMaker = ({
  userId,
  parkingId,
  validForDays = 15,
  rewardType = 'FreeParking',
  rewardOrigin = '',
}) => {
  const expireAt = new Date();
  expireAt.setDate(expireAt.getDate() + validForDays);
  const recordData = {
    ownerId: userId,
    rewardType,
    isUsed: false,
    isExpired: false,
    expireAt,
    rewardOrigin,
  };

  if (parkingId) {
    recordData.parkingId = parkingId;
  }
  return {
    record: [recordData],
  };
};

// eslint-disable-next-line import/prefer-default-export
export const addReward = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation rewardApiMutation($records: [addRewardsRecordInput!]!) {
      addReward(records: $records) {
        recordIds
        error {
          message
        }
      }
    }
  `;
  const variables = {
    records: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};

export const claimRewardByStaff = (data, callback, errCallback) => {
  const mutation = graphql`
    mutation rewardApiClaimRewardMutation(
      $filter: claimrewardByStaffRecordInput!
    ) {
      claimRewardByStaff(filter: $filter) {
        _id
        isUsed
        isExpired
        rewardUniqueId
        rewardType
        expireAt
        created_at
        user {
          name {
            first
            last
          }
          _id
        }
      }
    }
  `;
  const variables = {
    filter: {
      rewardUniqueId: data.rewardUniqueId,
    },
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      // console.error(err);
      errCallback(err);
    },
  });
};
