/* eslint-disable import/prefer-default-export */
import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

const defaultQuery = graphql`
  query findVehicleQuery($filter: findVehiclesFilterInput) {
    findVehicles(filter: $filter) {
      brand {
        _id
        name
        brandLogo
      }
      _id
    }
  }
`;
export const findVehicleQuery = (variables, query = defaultQuery) => {
  return fetchQuery(environment, query, variables).toPromise();
};
