import React from 'react';
import { StyleSheet } from 'react-native';
import {
  Svg,
  Defs,
  Rect,
  LinearGradient,
  Stop,
  Circle,
} from 'react-native-svg';
import View from '../basic/View/View';

const GradientBackground = (props) => {
  const {
    id, // it's mandatory
    backgroundOpacity,
    children,
    gradientColors,
    showBorder,
    showMaskCircle,
    circleRadius = '20',
    circlePosition = '59%',
    strokeBorderRadius,
    strokeBorderColor,
    strokeBorderWidth,
    width: SVGWidth,
    maskCircleColor,
    borderRadius = 0,
    borderTopLeftRadius = 0,
    borderTopRightRadius = 0,
    borderBottomLeftRadius = 0,
    borderBottomRightRadius = 0,
    x1 = '0',
    y1 = '0',
    x2 = '0',
    y2 = '1',
  } = props ?? {};
  const RectangleBorder = showBorder
    ? {
        rx: strokeBorderRadius,
        ry: strokeBorderRadius,
        stroke: strokeBorderColor,
        strokeWidth: strokeBorderWidth,
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
      }
    : {};
  return (
    <View opacity={backgroundOpacity || 1} {...props}>
      <Svg
        height="100%"
        width={SVGWidth || '100%'}
        style={[
          StyleSheet.absoluteFillObject,
          {
            borderRadius,
            borderTopLeftRadius,
            borderTopRightRadius,
            borderBottomLeftRadius,
            borderBottomRightRadius,
          },
        ]}
      >
        <Defs>
          <LinearGradient id={`grad${id}`} x1={x1} y1={y1} x2={x2} y2={y2}>
            {gradientColors?.length &&
              gradientColors.map(({ offset, color, opacity }) => (
                <Stop
                  key={`gradient-${offset}`}
                  offset={offset}
                  stopColor={color}
                  stopOpacity={opacity}
                />
              ))}
          </LinearGradient>
        </Defs>
        <Rect
          width="100%"
          height="100%"
          fill={`url(#grad${id})`}
          {...RectangleBorder}
        />
        {showMaskCircle ? (
          <>
            <Circle
              id="circle1"
              key={5}
              r={circleRadius}
              cy={circlePosition}
              fill={maskCircleColor}
              strokeWidth={strokeBorderWidth}
              stroke={strokeBorderColor}
            />
            <Circle
              id="circle2"
              key={6}
              r={circleRadius}
              cx="100%"
              cy={circlePosition}
              fill={maskCircleColor}
              fillRule="evenodd"
              strokeWidth={strokeBorderWidth}
              stroke={strokeBorderColor}
            />
          </>
        ) : (
          <></>
        )}
      </Svg>
      {children}
    </View>
  );
};

export default GradientBackground;
