import { useState, useCallback } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import {
  billUploadViewerOverviewQuery,
  findBillsFragment,
} from './billUploadViewerApi';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import {
  toISOStringWithTimezone,
  UPLOAD_BILL_STATUS,
} from '../../../utilities/helper';
import { pagination } from '../../../utilities/Constant';
import { useNodeFromPagination } from '../../../hooks/useNodeFromPagination';

interface BillUploadViewerOverviewScreenHookTypes {
  hasNoApprovedBills: boolean;
  hasNoRejectedBills: boolean;
  approvedBillsTab: number;
  approvedBillsListItems: [];
  rejectedBillsListItems: [];
  handleEarningTabSwitch: (idx: number) => void;
  hasNext?: boolean;
  loadNext?: any;
  refetch?: any;
  verifiedCount?: number;
  verificationFailedCount?: number;
}

const todaysDate = toISOStringWithTimezone(
  new Date(new Date().setDate(new Date().getDate() - 30)),
);

/* eslint-disable-next-line import/prefer-default-export */
export const useBillUploadViewerOverviewScreen = (
  queryOptions: NewErrorBoundaryParentState,
): BillUploadViewerOverviewScreenHookTypes => {
  const [approvedBillsTab, setApprovedBillsTab] = useState(0);
  const [today, setToday] = useState(todaysDate);

  const filter = {
    status: [
      UPLOAD_BILL_STATUS.VERIFIED,
      UPLOAD_BILL_STATUS.VERIFICATION_FAILED,
    ],
    created_at: today,
  };

  const countFilter = {
    created_at: today,
  };

  const variables = {
    filter,
    countFilter,
    sort: '_ID_DESC',
    limit: 10,
    count: pagination.defaultPageSize,
  };

  const { getBillUploadStatusCount, ...findBillsRef } = useLazyLoadQuery(
    billUploadViewerOverviewQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const {
    data: findbillsPaginated,
    hasNext,
    loadNext,
    refetch: refetchHistory,
  } = usePaginationFragment(findBillsFragment, findBillsRef);

  const findBills = useNodeFromPagination(findbillsPaginated?.findBills);

  const handleEarningTabSwitch = useCallback(
    (idx: number) => {
      setApprovedBillsTab(idx);
      refetchHistory(
        {},
        {
          fetchPolicy: 'store-and-network',
        },
      );
    },
    [setApprovedBillsTab],
  );

  const approvedBillsListItems = findBills
    ?.filter((item) => item?.status === UPLOAD_BILL_STATUS.VERIFIED)
    .map((item) => {
      return {
        _id: item?._id,
        status: item?.status,
        isVerified: true,
        amount: item?.userInputs?.priceDetails?.netAmount,
        name: item?.restaurant?.displayName,
        bucket: item?.userInputs?.billImage?.bucket,
        key: item?.userInputs?.billImage?.key,
      };
    });
  const rejectedBillsListItems = findBills
    ?.filter((item) => item?.status === UPLOAD_BILL_STATUS.VERIFICATION_FAILED)
    .map((item) => {
      return {
        _id: item?._id,
        status: item?.status,
        isVerified: false,
        amount: item?.userInputs?.priceDetails?.netAmount,
        name: item?.restaurant?.displayName,
        bucket: item?.userInputs?.billImage?.bucket,
        key: item?.userInputs?.billImage?.key,
      };
    });

  return {
    hasNoApprovedBills: !(approvedBillsListItems.length > 0),
    hasNoRejectedBills: !(rejectedBillsListItems.length > 0),
    approvedBillsTab,
    approvedBillsListItems,
    rejectedBillsListItems,
    handleEarningTabSwitch,
    hasNext,
    loadNext,
    refetch: refetchHistory,
    verifiedCount: getBillUploadStatusCount?.VERIFIED,
    verificationFailedCount: getBillUploadStatusCount?.VERIFICATION_FAILED,
  };
};
