/**
 * @generated SignedSource<<688de57a6b32a170c23eecadbd8c876d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumcouponCouponBenefitType = "FLAT_CASHBACK" | "FLAT_DISCOUNT" | "PERCENTAGE_CASHBACK" | "PERCENTAGE_OFF" | "%future added value";
export type EnumcouponIssuanceStatus = "ACTIVE" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumcouponRedeemableAtRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type FilterFindManycouponIssuanceInput = {
  AND?: ReadonlyArray<FilterFindManycouponIssuanceInput> | null;
  OR?: ReadonlyArray<FilterFindManycouponIssuanceInput> | null;
  _id?: any | null;
  _operators?: FilterFindManycouponIssuanceOperatorsInput | null;
  briefText?: string | null;
  couponBenefit?: FilterFindManycouponCouponBenefitInput | null;
  couponCode?: string | null;
  couponExpiryDate?: any | null;
  couponId?: any | null;
  created_at?: any | null;
  finePrint?: FilterFindManyTicketModelTCwoUserFinePrintInput | null;
  maxDiscountAmount?: number | null;
  minBillAmount?: number | null;
  redeemableAt?: ReadonlyArray<FilterFindManycouponRedeemableAtInput | null> | null;
  status?: EnumcouponIssuanceStatus | null;
  updated_at?: any | null;
};
export type FilterFindManycouponCouponBenefitInput = {
  _id?: any | null;
  type?: EnumcouponCouponBenefitType | null;
  value?: number | null;
};
export type FilterFindManyTicketModelTCwoUserFinePrintInput = {
  _id?: any | null;
  details?: ReadonlyArray<string | null> | null;
  summary?: string | null;
};
export type FilterFindManycouponRedeemableAtInput = {
  _id?: any | null;
  refId?: any | null;
  refType?: EnumcouponRedeemableAtRefType | null;
};
export type FilterFindManycouponIssuanceOperatorsInput = {
  _id?: FilterFindManycouponIssuance_idOperatorsInput | null;
};
export type FilterFindManycouponIssuance_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type CouponsComponentFindIssuedCouponsFragmentQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  filter?: FilterFindManycouponIssuanceInput | null;
};
export type CouponsComponentFindIssuedCouponsFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CouponsComponentQueryFindIssuedCouponsFragment">;
};
export type CouponsComponentFindIssuedCouponsFragmentQuery = {
  response: CouponsComponentFindIssuedCouponsFragmentQuery$data;
  variables: CouponsComponentFindIssuedCouponsFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CouponsComponentFindIssuedCouponsFragmentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CouponsComponentQueryFindIssuedCouponsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CouponsComponentFindIssuedCouponsFragmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "couponIssuanceConnection",
        "kind": "LinkedField",
        "name": "findIssuedCoupons",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "couponIssuanceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "couponIssuance",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "couponCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "briefText",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserFinePrint",
                    "kind": "LinkedField",
                    "name": "finePrint",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "details",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "summary",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "couponCouponBenefit",
                    "kind": "LinkedField",
                    "name": "couponBenefit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "couponExpiryDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxDiscountAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "CouponsComponentFindCouponsFragment__findIssuedCoupons",
        "kind": "LinkedHandle",
        "name": "findIssuedCoupons"
      }
    ]
  },
  "params": {
    "cacheID": "e1183d93cd00499a8f4ff5011302ae07",
    "id": null,
    "metadata": {},
    "name": "CouponsComponentFindIssuedCouponsFragmentQuery",
    "operationKind": "query",
    "text": "query CouponsComponentFindIssuedCouponsFragmentQuery(\n  $count: Int\n  $cursor: String\n  $filter: FilterFindManycouponIssuanceInput\n) {\n  ...CouponsComponentQueryFindIssuedCouponsFragment_3KQYpM\n}\n\nfragment CouponsComponentQueryFindIssuedCouponsFragment_3KQYpM on Query {\n  findIssuedCoupons(filter: $filter, first: $count, after: $cursor) {\n    edges {\n      node {\n        couponCode\n        briefText\n        finePrint {\n          details\n          summary\n        }\n        couponBenefit {\n          type\n          value\n        }\n        couponExpiryDate\n        created_at\n        maxDiscountAmount\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f463a2557ddd415020f0ae9a07d4652c";

export default node;
