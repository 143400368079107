import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

import createCartSlice from './cart/cartSlice';
import videoSlice from './video/videoSlice';
import createVehicleSlice from './vehicle/vehicleSlice';
import slotMachineSlice from './games/slotMachine/slotMachineSlice';
import finishTaskSlice from './valet/finishTaskSlice';

const useBoundStore = create(
  persist(
    (...a) => ({
      ...createCartSlice(...a),
      ...videoSlice(...a),
      ...createVehicleSlice(...a),
      ...finishTaskSlice(...a),
    }),
    {
      name: 'cart-storage',
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
);

export default useBoundStore;
