import React, { useState } from 'react';
import { Icon, Pressable, Text, View } from '../../../../../components/new';
import CachedImage from '../../../../../components/new/custom/CachedImage';

const BM_LOGO = require('../../../../../../assets/images/black_membership/black-member-logo.webp');

const LOGO_WIDTH_RATIO = 0.16;
const LOGO_ASPECT_RATIO = 0.7;

const Header = (props: { onViewBenefits: () => void }) => {
  const { onViewBenefits } = props;
  const [width, setWidth] = useState(0);

  return (
    <View>
      <View
        p="2xl"
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        onLayout={(e) => {
          setWidth(e?.nativeEvent?.layout?.width);
        }}
      >
        <CachedImage
          source={BM_LOGO}
          style={{
            width: LOGO_WIDTH_RATIO * width,
            height: LOGO_WIDTH_RATIO * width * LOGO_ASPECT_RATIO,
          }}
        />
        <Pressable onPress={onViewBenefits}>
          <View flexDirection="row" alignItems="center">
            <Text mr="sm" size="xs" color="gold.50">
              Membership benefits
            </Text>
            <Icon name="keyboard-arrow-right-300" color="gold.50" size="xl" />
          </View>
        </Pressable>
      </View>
    </View>
  );
};

export default Header;
