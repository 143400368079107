import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Carousel from 'react-native-reanimated-carousel';
import { Dimensions, Linking, Platform } from 'react-native';
import View from '../../basic/View/View';
import CarouselProgressTiles from './CarouselProgressTiles';
import Pressable from '../../basic/Pressable/Pressable';
import { getBaseUrlForDeepLink } from '../../../../utilities/Utility';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import CachedImage from '../CachedImage';

const DEEPLINK_BASE_URL = getBaseUrlForDeepLink();

const CarouselData: { bannerImage: any; deeplink: string }[] = [
  {
    bannerImage: require('../../../../../assets/images/HomePageBanner-01.webp'),
    deeplink: `${DEEPLINK_BASE_URL}RestaurantScreen?restaurantId=65cc56039130c115f991361e`,
  },
  {
    bannerImage: require('../../../../../assets/images/HomePageBanner-02.webp'),
    deeplink: `${DEEPLINK_BASE_URL}eventsHome?id=6616450046b513db8b5c5bad`,
  },
  {
    bannerImage: require('../../../../../assets/images/HomePageBanner-03.webp'),
    deeplink: `${DEEPLINK_BASE_URL}RestaurantScreen?restaurantId=65a64e8638251b9bc1eff6d0`,
  },
];

const CarousalComponent = ({ data = [], bg = '' }) => {
  const { width } = Dimensions.get('window');
  const aspectRatio = 642 / 1492;
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const resData = data?.length > 0 ? data : CarouselData;

  if (Platform.OS === 'web') {
    return (
      <View borderRadius="xl" overflow="hidden" bg={bg}>
        <Carousel
          loop
          width={width - 32}
          height={(width - 32) * aspectRatio}
          data={resData}
          scrollAnimationDuration={100}
          autoPlayInterval={5000}
          onSnapToItem={(index) => {
            setActiveIndex(index);
          }}
          renderItem={({ index, item }) => (
            <Pressable
              style={{
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onPress={async () => {
                try {
                  firebaseEventLogger('home_page_banner_clicked', {
                    deeplink: resData?.[activeIndex]?.deeplink,
                  });
                  const link = resData?.[activeIndex]?.deeplink;
                  await Linking.openURL(link);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              <CachedImage
                source={item?.bannerImage}
                style={{
                  flex: 1,
                  width: width - 32,
                  height: (width - 32) * aspectRatio,
                  resizeMode: 'contain',
                }}
              />
            </Pressable>
          )}
          autoPlay
        />
        <View
          style={{
            position: 'absolute',
            marginHorizontal: 16,
            bottom: 16,
            width: width - 64,
          }}
        >
          <CarouselProgressTiles
            time={5000}
            numberOfBars={resData?.length}
            activeIndex={activeIndex}
          />
        </View>
      </View>
    );
  }
  return (
    <View borderRadius="xl" overflow="hidden" bg={bg}>
      <Carousel
        loop
        width={width - 32}
        height={(width - 32) * aspectRatio}
        data={resData}
        scrollAnimationDuration={100}
        autoPlayInterval={5000}
        onSnapToItem={(index) => {
          setActiveIndex(index);
        }}
        renderItem={({ index, item }) => (
          <Pressable
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onPress={async () => {
              try {
                firebaseEventLogger('home_page_banner_clicked', {
                  deeplink: resData?.[activeIndex].deeplink,
                });
                const link = resData?.[activeIndex].deeplink;
                await Linking.openURL(link);
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <CachedImage
              source={item?.bannerImage}
              style={{
                flex: 1,
                width: width - 32,
                height: (width - 32) * aspectRatio,
                resizeMode: 'contain',
              }}
            />
          </Pressable>
        )}
        autoPlay
        panGestureHandlerProps={{
          activeOffsetX: [-10, 10],
        }}
      />
      <View
        style={{
          position: 'absolute',
          marginHorizontal: 16,
          bottom: 16,
          width: width - 64,
        }}
      >
        <CarouselProgressTiles
          time={5000}
          numberOfBars={resData?.length}
          activeIndex={activeIndex}
        />
      </View>
    </View>
  );
};

export default CarousalComponent;
