import React, { useRef } from 'react';
import { Icon, Pressable, View } from '../new';
import { reactions } from '../../utilities/ratingOptions';
import { fontSizes, space } from '../../themes/new/theme';
import CustomLottieView from '../new/custom/CustomLottieView';

const IconComponent = ({
  onPress,
  selected,
  isTYCScreen = false,
  hasFeedBack = false,
}) => {
  const animation = useRef(null);

  const renderIcon = (item) => (
    <Pressable
      onPress={() => onPress(item)}
      disabled={hasFeedBack}
      key={`emoji_selected${item?.key}`}
      style={{
        height: isTYCScreen ? space['9xl'] : 56,
        width: isTYCScreen ? space['9xl'] : 56,
        marginRight: item?.key !== 4 ? 16 : 0,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {selected === item?.key ? (
        <CustomLottieView
          autoPlay
          loop
          source={item?.path}
          style={{
            width: isTYCScreen ? fontSizes['6xl'] : 56,
            height: isTYCScreen ? fontSizes['6xl'] : 56,
          }}
          containerWidth={isTYCScreen ? fontSizes['6xl'] : 56}
        />
      ) : (
        <Icon
          name={item?.icon}
          size={isTYCScreen ? '6xl' : '8xl'}
          color={item?.iconColor}
        />
      )}
    </Pressable>
  );

  return (
    <View flexDirection="row">
      {reactions?.length ? reactions.map((item) => renderIcon(item)) : null}
    </View>
  );
};
export default IconComponent;
