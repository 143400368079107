import React, { useState } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Svg, Defs, Rect, LinearGradient, Stop } from 'react-native-svg';
import View from '../../../basic/View/View';
import { getTheme, themeModes } from '../../../../../themes/new/theme';

const theme = getTheme(themeModes.light);

const CLR_1 = theme.colors.success[10];
const CLR_2 = theme.colors.warning[10];
const strokeBorderRadius = theme.radii.xl;
const strokeBorderWidth = theme.borderWidths.sm;
const strokeBorderColor = theme.colors.primary[50];

const Gradient = ({ children }) => {
  const [dimentions, setDimentions] = useState({ w: 0, h: 0 });

  return (
    <View
      onLayout={(e) => {
        setDimentions({
          w: e.nativeEvent.layout.width,
          h: e.nativeEvent.layout.height,
        });
      }}
      width="100%"
      height="100%"
      opacity={1}
    >
      <Svg
        height={dimentions.h}
        width={dimentions.w}
        style={[StyleSheet.absoluteFillObject, { borderRadius: 0 }]}
      >
        <Defs>
          <LinearGradient id="ticket-gradient" x1={0} y1={0} x2={0} y2={1}>
            <Stop offset={0} stopColor={CLR_1} stopOpacity={1} />
            <Stop offset={1} stopColor={CLR_2} stopOpacity={1} />
          </LinearGradient>
        </Defs>
        <Rect
          width="100%"
          height="100%"
          fill="url(#ticket-gradient)"
          rx={strokeBorderRadius}
          ry={strokeBorderRadius}
          stroke={strokeBorderColor}
          strokeWidth={strokeBorderWidth}
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      </Svg>
      {children}
    </View>
  );
};

export default Gradient;
