import React from 'react';
import { Image } from 'react-native';
import { IconButton, Modal, Text, View } from '../new';
import Button from '../new/primitive/Button/Button';
import Pressable from '../new/basic/Pressable/Pressable';
import { useDownloadAppStore } from '../../stores/downloadApp/downloadAppStore';
import CustomLottieView from '../new/custom/CustomLottieView';

const DownloadApp = () => {
  const {
    visible,
    title,
    dsc,
    btnText,
    imgSrc,
    lottieImgSrc,
    onBtnPress,
    hide,
  } = useDownloadAppStore((state) => state);
  // const commonStore = useCommonStore((state) => state);
  const downloadApp = () => {
    hide();
    onBtnPress();
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      onBackdropPress={() => {
        hide();
      }}
    >
      <View
        width="90%"
        maxWidth={358}
        // maxHeight={402}
        bg="background.primary.base"
        borderRadius={10}
        py="4xl"
        px="2xl"
      >
        <Text size="2xl" weight="medium" color="primary.500" textAlign="center">
          {title}
        </Text>
        <View mt="lg" alignSelf="center">
          {!!imgSrc && (
            <Image
              source={imgSrc}
              style={{ width: 196, height: 164 }}
              resizeMode="contain"
            />
          )}
          {!!lottieImgSrc && (
            <CustomLottieView
              autoPlay
              loop
              source={
                lottieImgSrc ||
                require('../../../assets/lottie/photo-video.json')
              }
              style={{ width: 196, height: 164 }}
              containerWidth={196}
            />
          )}
        </View>
        <Text size="md" color="primary.300" textAlign="center">
          {dsc}
        </Text>
        <Button mt="4xl" size="lg" onPress={downloadApp}>
          {btnText}
        </Button>
      </View>
      <View mt="2xl" width="90%" alignItems="center">
        <Pressable onPress={() => {}}>
          <IconButton
            name="close-outline-300"
            size="xl"
            bg="primary.500"
            iconColor="primary.50"
            onPress={() => {
              hide();
            }}
          />
        </Pressable>
      </View>
    </Modal>
  );
};

export default DownloadApp;
