import { useCallback, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import {
  dateUtils,
  toISOStringWithTimezone,
} from '../../../../utilities/helper';
import { TransactionsEarningsReport } from '../../types';
import earningsScreenQuery from './earningsScreenQuery';
import useRestaurantPartnerStore from '../../../../stores/restaurantpartner/restaurantPartnerStore';
import useTransactionsEarningReport from '../../API/fragments/transactionsEarningReportFragment/useTransactionsEarningReportFragment';
import { earningsScreenQuery$variables } from './__generated__/earningsScreenQuery.graphql';

const today = new Date();

const useEarningsScreen = (
  queryOptions: NewErrorBoundaryParentState,
  refresh: () => void,
) => {
  const navigation = useNavigation();
  const restaurantId = useRestaurantPartnerStore((state) => state.restaurantId);
  const [refreshing, setRefreshing] = useState(false);
  const [todaysDate, setTodaysDate] = useState(today);
  const variables = buildQueryVariables(todaysDate, restaurantId);
  const fragmentRef = useLazyLoadQuery(
    earningsScreenQuery,
    variables,
    queryOptions,
  );
  const { earningReports, refetchEarningReports } =
    useTransactionsEarningReport(fragmentRef);

  useFocusEffect(
    useCallback(() => {
      setTodaysDate(new Date());
    }, []),
  );

  const onPullToRefresh = useCallback(() => {
    setRefreshing(true);
    const updatedVariables = buildQueryVariables(new Date(), restaurantId);
    refetchEarningReports(() => {
      setRefreshing(false);
    }, updatedVariables);
  }, [restaurantId]);

  const navigateToEarningsOverviewScreen = (
    earningsReport: TransactionsEarningsReport,
  ) => {
    if (earningsReport.revenueAmount !== 0) {
      navigation.navigate('payoutOverview', {
        date: toISOStringWithTimezone(new Date(earningsReport.date)),
        restaurantId,
      });
    }
  };

  return {
    earningReports,
    pullToRefreshConf: {
      refreshing,
      onRefresh: onPullToRefresh,
    },
    navigateToEarningsOverviewScreen,
  };
};

export default useEarningsScreen;

const buildQueryVariables = (
  date: Date,
  restaurantId: string,
): earningsScreenQuery$variables => {
  let startDate = new Date(date);
  startDate.setMonth(startDate.getMonth() - 12);
  startDate = dateUtils.toStartOfMonth(startDate);

  return {
    filter: {
      date: {
        start: toISOStringWithTimezone(startDate),
        end: toISOStringWithTimezone(date),
      },
      groupBy: 'MONTH',
      orderId: restaurantId,
    },
  };
};
