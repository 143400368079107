import * as React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  View,
  Text,
  Divider,
  Button,
  RadioGroup,
  Input,
} from '../../../../components/new';
import AutoBottomSheet from '../../../../components/new/custom/AutoBottomSheet';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import useValetTaskStore from '../../../../stores/valet/taskStore';
import useUserStore from '../../../../stores/userStore';
import { updateTaskForSupervisor } from '../../../../relay/taskApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';

const tempData = [
  { name: 'Taxi', checked: true },
  { name: 'Driver Driven', checked: false },
  {
    name: 'Owner Parking',
    checked: false,
  },
  { name: 'Others', checked: false },
];

const CancelTask = ({
  bottomSheetModalRef,
  handleOnDismiss,
  task,
  refresh,
}) => {
  const [cancelTaskReason, setCancelTaskReason] = React.useState(tempData);
  const navigation = useNavigation();
  const [inputValue, setInputValue] = React.useState('');
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const userId = useUserStore((state) => state.id);
  const { tasks, setTasks } = useValetTaskStore((state) => state);

  const removeItem = (id) => {
    const arr = tasks.filter((item) => item._id !== id);
    setTasks({ data: arr, userId });
  };

  const selectedtaskReasonIndex = cancelTaskReason?.findIndex(
    (item) => item.checked,
  );

  const handleTextChange = (i) => {
    setInputValue(i);
  };

  const handleCancelTask = () => {
    if (task) {
      let cancelledReason =
        cancelTaskReason &&
        cancelTaskReason?.filter((item) => item?.checked)[0]?.name;
      if (selectedtaskReasonIndex === 3) {
        cancelledReason = `${cancelledReason}:: ${inputValue}`;
      }
      const data = {
        _id: task?._id,
        record: {
          history: { action: 'cancelled' },
          cancelledReason,
        },
      };
      updateTaskForSupervisor(
        data,
        (response, error) => {
          // refresh();
          if (response) {
            dispatchSnackbar({
              msg: `Task Cancelled`,
              status: SnackbarStatus.success,
              version: SnackbarVersion.v2,
            });
            removeItem(task?._id);
            refresh();
            navigation.reset({
              index: 0,
              routes: [{ name: 'valetSupervisor' }],
            });
          }
        },
        (error) => {
          // console.log(error);
        },
      );
    }
  };
  return (
    <View>
      <AutoBottomSheet
        bottomSheetRef={bottomSheetModalRef}
        handleClose={handleOnDismiss}
      >
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Select reason for cancellation
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            <RadioGroup
              radio={tempData}
              setRadio={(arr) => setCancelTaskReason(arr)}
              title=""
              isRequired
              size="lg"
              icon="right"
              collapsedCount={5}
            />
          </View>
          {selectedtaskReasonIndex === 3 && (
            <View px="2xl" mb="2xl">
              <Input
                value={inputValue}
                onChangeText={handleTextChange}
                placeholder="Enter Reason..."
              />
            </View>
          )}
          <View px="2xl" mt="xl" mb="xl">
            <Button size="lg" appearance="filled" onPress={handleCancelTask}>
              Cancel Task
            </Button>
          </View>
        </View>
      </AutoBottomSheet>
    </View>
  );
};

export default CancelTask;
