import React, { useCallback } from 'react';
import { FlatList } from 'react-native';
import { View, Text } from '../../../components/new';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { Capitalise } from '../../../utilities/helper';

const RestaurantsTimingBottomsheet = ({
  bottomSheetModalRef,
  timings,
  currentDay,
  openStatus,
  type,
}) => {
  const handleOnDismiss = useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  return (
    <BottomSheetV2
      ref={bottomSheetModalRef}
      onClose={handleOnDismiss}
      insets={false}
    >
      <View>
        <View mt="xl" px="2xl">
          <Text mt="2xl" mb="lg" color="primary.500" size="xl">
            {Capitalise(type.toLowerCase())} Timings
          </Text>
          <FlatList
            data={timings}
            renderItem={({ item, index }) => (
              <View py="2xl" borderTopWidth="xs" borderTopColor="primary.50">
                <Text color="primary.400" size="md" weight="medium">
                  {item?.day}
                </Text>
                {currentDay === item?.day ? (
                  <Text
                    size="xs"
                    color={
                      openStatus?.toLowerCase() === 'closed'
                        ? 'error.500'
                        : 'success.500'
                    }
                    mt="lg"
                  >
                    {openStatus?.toLowerCase() === 'closed'
                      ? 'Closed'
                      : 'Open Now'}
                  </Text>
                ) : (
                  <Text color="primary.200" size="xs" mt="lg">
                    {`${item?.opensAt.toUpperCase()} - ${item?.closesAt.toUpperCase()}`}
                  </Text>
                )}
              </View>
            )}
          />
        </View>
      </View>
    </BottomSheetV2>
  );
};

export default RestaurantsTimingBottomsheet;
