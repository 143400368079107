import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { taskHistoryActionTypes, taskTypes } from '../../utilities/helper';

export interface CashierTaskStoreInterface {
  tasks?: [];
}

interface useCashierTaskStorePropTypes {
  (state?: object);
}
const useCashierStore = <useCashierTaskStorePropTypes>create()(
  devtools(
    persist(
      (set) => ({
        openedCard: '',
        setOpenedCard: ({ id }) => {
          set(() => {
            return { openedCard: id };
          });
        },
        tasks: [],
        newTasks: [],
        setTasks: ({ data }) => {
          set(() => {
            return {
              tasks: data,
            };
          });
        },
        setNewTasks: ({ data }) => {
          set(() => {
            return { newTasks: data };
          });
        },
      }),
      {
        name: 'CashierTaskStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'CashierTaskStore',
    },
  ),
);

export default useCashierStore;
