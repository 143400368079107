import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { StyleSheet, Platform, useWindowDimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { View, Text, Modal } from '../../../components/new';
import CircularTimer from '../../../components/new/custom/CircularTimer';

const RenderTimer = ({ handleReparkTask, setShow, timeOfArrival }) => {
  const getTimeDiff = () => {
    const freezeFrom = timeOfArrival;
    const currentTime = new Date().valueOf();
    const timeDiff = Math.floor(
      (currentTime - new Date(freezeFrom).valueOf()) / 1000,
    );
    return 5 * 60 - timeDiff;
  };

  const isPlaying = true;
  const initialRemainingTime = getTimeDiff();
  const totalDuration = 5 * 60;
  const [timerText, setTimerText] = useState('Left');
  const theme = useTheme();
  if (true) {
    return (
      <View pt="9xl" px="2xl">
        <CircularTimer
          isPlaying={isPlaying}
          duration={totalDuration}
          updateInterval={0}
          colors={[theme.colors.singletone.black]}
          colorsTime={[theme.colors.singletone.black]}
          size={100}
          trailStrokeWidth={5}
          // trailColor={
          //   trailColor === '' ? theme.colors.primary[50] : trailColor
          // }
          strokeWidth={5}
          innerCircleBg={theme.colors.singletone.black}
          innerCircleMargin={10}
          textColor="primary.10"
          timerText={timerText}
          onComplete={() => {
            setTimerText(`Time's Up`);
            // handleReparkTask();
            // console.log('finisehd?');
            setShow(false);
            // setTrailColor(theme.colors.error[600]);
          }}
          initialRemainingTime={initialRemainingTime}
        />
      </View>
    );
  }
  return <></>;
};

const CountDownModal = ({ show, setShow, handleReparkTask, timeOfArrival }) => {
  const { height, width } = useWindowDimensions();

  const closeModal = () => {
    setShow(false);
  };

  const theme = useTheme();

  return (
    <View level="1">
      <Modal
        visible={show}
        style={[styles.modalContainer, { width }]}
        backdropStyle={{
          backgroundColor: theme.colors.gradient[90],
        }}
        onBackdropPress={() => {
          closeModal();
        }}
        // hitSlop={{ top: -1000 }}
      >
        <View top={0} bottom={0}>
          <View bg="primary.10" marginTop="4xl" borderRadius="md" mx="2xl">
            <View>
              <View justifyContent="center" alignItems="center" pt="2xl">
                <RenderTimer
                  handleReparkTask={handleReparkTask}
                  setShow={setShow}
                  timeOfArrival={timeOfArrival}
                />
              </View>

              <View py="2xl" borderBottomColor="primary.50" px="2xl">
                <Text mb="lg" size="sm" weight="medium" textAlign="center">
                  Waiting for customer
                </Text>
                <Text size="sm" color="primary.200" textAlign="center">
                  Please wait for atleast 5 min before parking the car again
                </Text>
              </View>
            </View>
          </View>
        </View>
        {Platform.OS !== 'web' && (
          <SafeAreaView
            edges={['bottom']}
            style={{
              backgroundColor: theme.colors.primary[10],
            }}
          />
        )}
      </Modal>
    </View>
  );
};
export default CountDownModal;

const styles = StyleSheet.create({
  modalContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    position: 'absolute',
    justifyContent: 'center',
  },
});
