import { graphql } from 'react-relay';

const transactionsFragment = graphql`
  fragment transactionsFragment on Query
  @refetchable(queryName: "TransactionsFragmentQuery")
  @argumentDefinitions(
    filter: { type: "findInvoiceInput!" }
    sort: { type: "SortConnectioninvoiceEnum" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    transactions: findInvoiceV2(
      filter: $filter
      sort: $sort
      first: $count
      after: $cursor
    ) @connection(key: "transactionsFragment_transactions") {
      edges {
        node {
          _id
          uId
          created_at
          status
          priceDetails {
            discountGiven
            grossAmount
            netAmount
            rewardsUsed
            tipAmount
            csrAmount
          }
          user {
            contact {
              phone {
                prefix
                number
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export default transactionsFragment;
