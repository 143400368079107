/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useRef, useState } from 'react';
import {
  FlatList,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useLazyLoadQuery } from 'react-relay';
import {
  Button,
  IconButton,
  Input,
  Layout,
  Pressable,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  hasWhiteSpace,
  navigateBack,
  taskHistoryActionTypes,
  taskTypes,
} from '../../utilities/helper';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import useUserStore, { UserRole } from '../../stores/userStore';
import useCommonStore from '../../stores/commonStore';
import { addTask } from '../../relay/taskApi';
import useCampusLocation from '../../stores/campusLocation';
import Loading from '../../components/loading.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { addVehicleScreenQuery } from './API/addVehicleScreenQuery';
import { getTheme } from '../../themes/new/theme';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { Appearance, State, Status } from '../../themes/new/helper';

const AddVehicleScreen = (props) => {
  const {
    variables,
    queryOptions,
    vehicleNumberFromParams,
    vehicleBrandFromParams,
  } = props;
  const isBrandFromParams =
    typeof vehicleBrandFromParams === 'string' &&
    vehicleBrandFromParams.trim().length > 0;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { findVehicleBrand } = useLazyLoadQuery(
    addVehicleScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );
  const { campusId } = useCommonStore((state) => state);
  const { campusLocations } = useCampusLocation((state) => state);
  const userId = useUserStore((state) => state.id);
  const navigation = useNavigation<any>();
  const [vehicleNumber, setVehicleeNumber] = useState(
    (vehicleNumberFromParams && vehicleNumberFromParams.toUpperCase()) || '',
  );
  const [selectedCarBrand, setSelectedCarBrand] = useState(
    isBrandFromParams ? { brand: { name: vehicleBrandFromParams } } : {},
  );
  const [screenDisabled, setScreenDisabled] = useState(false);
  const [errorHelperText, setErrorHelperText] = useState('');
  const [addAssignButtonDisabled, setAddAssignButtonDisabled] = useState(false);
  const [addCarDisabled, setAddCarDisabled] = useState(false);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const scrollViewRef = useRef(null);
  const { role } = useUserStore((state) => state);

  // ------ handle text change for input ------
  const handleOnTextChange = (num) => {
    setVehicleeNumber(num.toUpperCase());
  };

  const handleOnFocusOutVehicleNumber = () => {
    if (
      vehicleNumberFromParams &&
      vehicleNumber !== vehicleNumberFromParams.toUpperCase()
    ) {
      firebaseEventLogger('addVehicle__changeVrn_Data', {
        buttonName: 'changeVrn',
        screenName: 'addVehicle',
        userType: 'Valet, Controller, ValetSupervisor',
        interactionType: 'Data',
      });
    }
    if (hasWhiteSpace(vehicleNumber) || vehicleNumber?.length < 4) {
      setErrorHelperText('Please enter valid VRN');
    }
  };

  const handleOnFocusVehicleNumber = () => {
    setErrorHelperText('');
  };

  const handleSaveAndAcceptTask = ({
    onlyAddCar = false,
    saveAndAssign = false,
  }) => {
    setScreenDisabled(true);
    if (saveAndAssign) {
      setAddAssignButtonDisabled(true);
    }
    if (onlyAddCar || role === UserRole.VALET) {
      setAddCarDisabled(true);
    }
    Keyboard.dismiss();
    const data = {};
    (data as any).record = {};
    (data as any).record.taskType = taskTypes.park;
    (data as any).record.vehicle = {};
    (data as any).record.vehicle.registration = {};
    (data as any).record.vehicle.registration.plate =
      vehicleNumber?.toUpperCase();

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (selectedCarBrand?.brand) {
      (data as any).record.vehicle.brand = {};
      (data as any).record.vehicle.brand.name =
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        selectedCarBrand?.brand?.name;

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (selectedCarBrand?.brand?.brandLogo) {
        (data as any).record.vehicle.brand.brandLogo =
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          selectedCarBrand?.brand?.brandLogo;
      }
    }

    (data as any).record.campus = {};
    (data as any).record.campus._id = campusId;
    // ===================
    const location = campusLocations.filter(
      (parkingLocation) => parkingLocation.parkingSpaces.floor.length === 0,
    );
    (data as any).record.taskStartLocation = {};
    (data as any).record.taskStartLocation.locationId = location[0]?._id;
    (data as any).record.history = [];
    if (onlyAddCar || saveAndAssign) {
      (data as any).record.history = [
        { action: taskHistoryActionTypes.created },
      ];
    } else {
      (data as any).record.history = [
        { action: taskHistoryActionTypes.created },
        { action: taskHistoryActionTypes.assigned },
        { action: taskHistoryActionTypes.accepted },
      ];
    }
    if (role === UserRole.VALET) {
      (data as any).record.assignedTo = userId;
    }
    addTask(
      data,
      (res, err) => {
        if (res) {
          dispatchSnackbar({
            msg: 'Task Created Succesfully!',
            status: SnackbarStatus.success,
            version: SnackbarVersion.v2,
          });
          setScreenDisabled(false);
          if (saveAndAssign) {
            setAddAssignButtonDisabled(false);
            if (res && res?.addTask) {
              navigation.navigate('SearchValetScreen', {
                taskId: res?.addTask?.record?._id,
              });
            }
          } else if (role === UserRole.VALET) {
            navigation.navigate('home');
          } else {
            setAddCarDisabled(false);
            navigation.navigate('park');
          }
        }
      },
      (err) => {
        dispatchSnackbar({
          msg: err?.res?.errors[0]?.message || 'Something went wrong',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
        setScreenDisabled(false);
        setAddAssignButtonDisabled(false);
        setAddCarDisabled(false);
        navigation.navigate(role);
      },
    );
  };

  const validateVehicleDetails = () => {
    if (
      vehicleNumber?.length >= 4 &&
      errorHelperText?.length <= 0 &&
      selectedCarBrand &&
      Object.keys(selectedCarBrand).length > 0
    ) {
      return true;
    }
    return false;
  };

  const RenderBottomCTA = () => {
    return role !== UserRole.VALET ? (
      <>
        <View flexDirection="row" alignItems="center" m="2xl" bottom="sm">
          <View flex={1}>
            <Button
              size="lg"
              appearance={
                validateVehicleDetails() && !addAssignButtonDisabled
                  ? Appearance.OUTLINE
                  : Appearance.FILLED
              }
              status={Status.PRIMARY}
              state={
                validateVehicleDetails() && !addAssignButtonDisabled
                  ? State.ACTIVE
                  : State.DISABLED
              }
              onPress={() => handleSaveAndAcceptTask({ saveAndAssign: true })}
              loading={addAssignButtonDisabled}
            >
              Add & Assign
            </Button>
          </View>
          <View flex={1} ml="lg">
            <Button
              size="lg"
              appearance={Appearance.FILLED}
              status={Status.PRIMARY}
              state={
                validateVehicleDetails() && !addCarDisabled
                  ? State.ACTIVE
                  : State.DISABLED
              }
              onPress={() => handleSaveAndAcceptTask({ onlyAddCar: true })}
              loading={addCarDisabled}
            >
              Add Car
            </Button>
          </View>
        </View>
      </>
    ) : (
      <>
        <View m="2xl" bottom="sm">
          <Button
            size="lg"
            appearance={Appearance.FILLED}
            status={Status.PRIMARY}
            state={
              validateVehicleDetails() && !addCarDisabled
                ? State.ACTIVE
                : State.DISABLED
            }
            onPress={handleSaveAndAcceptTask}
            loading={addCarDisabled}
          >
            Add & Accept Task
          </Button>
        </View>
      </>
    );
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={
        Platform.OS === 'android'
          ? null
          : Platform.OS === 'ios'
          ? 'padding'
          : null
      }
    >
      {screenDisabled ? (
        <TouchableWithoutFeedback
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999,
          }}
        >
          <Loading />
        </TouchableWithoutFeedback>
      ) : (
        <></>
      )}
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 30,
        }}
        ref={scrollViewRef}
        keyboardShouldPersistTaps="handled"
      >
        <TouchableWithoutFeedback
          onPress={() => {
            Keyboard.dismiss();
            scrollViewRef.current?.scrollTo({
              x: 10,
              y: 120,
              animated: true,
            });
          }}
        >
          <>
            <View px="2xl">
              <View>
                <View mt="4xl">
                  <Input
                    inputMode="words"
                    label="Car Number (VRN)"
                    autoCapitalize="characters"
                    returnKeyType="next"
                    placeholder="HR26X6339"
                    value={vehicleNumber}
                    onChangeText={handleOnTextChange}
                    errorHelperText={errorHelperText}
                    maxLength={10}
                    autoFocus={!vehicleNumber.length}
                    onFocus={handleOnFocusVehicleNumber}
                    onBlur={handleOnFocusOutVehicleNumber}
                  />
                </View>
              </View>
            </View>
            {!isBrandFromParams && (
              <View mt="2xl">
                <Text ml="2xl" size="md" color="primary.300">
                  Select Brand
                </Text>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  directionalLockEnabled
                  alwaysBounceVertical={false}
                  keyboardShouldPersistTaps="handled"
                >
                  <FlatList
                    data={findVehicleBrand}
                    numColumns={Math.ceil(findVehicleBrand?.length / 2)}
                    showsHorizontalScrollIndicator={false}
                    keyboardShouldPersistTaps="handled"
                    renderItem={({ item, index }) => (
                      <Pressable
                        style={{
                          borderRadius: getTheme().radii.md,
                          borderWidth: getTheme().borderWidths.sm,
                          borderColor:
                            selectedCarBrand?._id === item?._id
                              ? getTheme().colors.primary[500]
                              : getTheme().colors.primary[50],
                          marginLeft: getTheme().space['2xl'],
                          marginRight:
                            index === findVehicleBrand?.length - 1
                              ? getTheme().space['2xl']
                              : 0,
                          marginTop: getTheme().space.lg,
                        }}
                        onPress={() => {
                          setSelectedCarBrand(item);
                        }}
                      >
                        <Image
                          source={{ uri: item?.brand?.brandLogo }}
                          resizeMode="contain"
                          style={{
                            width: getTheme().sizes['3xl'],
                            height: getTheme().sizes['3xl'],
                          }}
                        />
                        {/* {selectedCarBrand &&
                      Object.keys(selectedCarBrand).length &&
                      selectedCarBrand?._id === item?._id ? (
                        <View
                          position="absolute"
                          width="100%"
                          height="100%"
                          justifyContent="center"
                          backgroundColor="overlay.500"
                          // borderWidth={1}
                          borderRadius="md"
                        >
                          <View alignItems="center" justifyContent="center">
                            <Icon
                              name="check-filled-400"
                              size="2xl"
                              color="primary.10"
                            />
                          </View>
                        </View>
                      ) : (
                        <></>
                      )} */}
                      </Pressable>
                    )}
                  />
                </ScrollView>
              </View>
            )}
          </>
        </TouchableWithoutFeedback>
      </ScrollView>
      <RenderBottomCTA />
    </KeyboardAvoidingView>
  );
};

const AddVehicleScreenWrapper = ({ navigation, route }) => {
  const { vehicleNumber, vehicleBrand } = route?.params ?? {};
  const { role } = useUserStore((state) => state);
  const [netStatus, setNetStatus] = useState(true);

  const onPressLeftIcon = () => {
    navigateBack(navigation, role);
  };
  const filter = {};

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        title="Add New Car"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            onPress={onPressLeftIcon}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <AddVehicleScreen
            variables={{ filter }}
            queryOptions={refreshedQueryOptions}
            fetchKey={refreshedQueryOptions?.fetchKey}
            vehicleNumberFromParams={vehicleNumber}
            vehicleBrandFromParams={vehicleBrand}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default AddVehicleScreenWrapper;
