import React, { useCallback, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import VideoPlayer from '../../../../components/new/custom/VideoPlayer';

const InvestListVideoComp = ({ investmentVideoHeader }) => {
  const { width } = useWindowDimensions();
  const [play, setPlay] = useState(false);

  useFocusEffect(
    useCallback(() => {
      setPlay(true);
      return () => {
        setPlay(false);
      };
    }, []),
  );

  return (
    <DarkThemeWrapper>
      <VideoPlayer
        id={`Video_id${investmentVideoHeader?._id}`}
        url={investmentVideoHeader?.cover?.mediaURL}
        aspectRatio={1.57}
        play={play}
        setPlay={setPlay}
        showPlayPause
        isLooping
        hideControls
        footerTitle={investmentVideoHeader?.heading}
        footerSubTitle={investmentVideoHeader?.subHeading}
        //   videoBg={colors.backdrop['500']}
      />
    </DarkThemeWrapper>
  );
};

export default InvestListVideoComp;
