import React, { Suspense, useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import moment from 'moment';
import { useFocusEffect } from '@react-navigation/native';
import { isEmpty } from 'lodash';
import {
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import EventDetailCard from './EventDetailCard';
import PayWithPointsComp from '../payment/PayWithPointsComp';
import AmountSavedComp from '../payment/AmountSavedComp';
import TicketBillingCard from './TicketBillingCard';
import ReservationConfirmationFooter from './ReservationConfirmationFooter';
import { navigateBack } from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import { reservationConfirmationScreenQuery } from './API/reservationConfirmationScreen';
import useCommonStore from '../../../stores/commonStore';
import useAuthStore from '../../../stores/authStore';
import Loading from '../../../components/loading.component';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import NotLoggedInPlaceholderV2 from '../../../components/new/custom/NotLoggedInPlaceholderV2';

const ReservationConfirmationScreen = ({
  variables,
  queryOptions,
  route,
  payWithPointsState,
  setPayWithPointsState,
  setNetAmountState,
  ticketsInfo,
  totalPrice,
}) => {
  const { slotId } = route.params || {};

  const { getNetPrice: data, findEvents } = useLazyLoadQuery(
    reservationConfirmationScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );
  const rewardsAmountCanUsed = data?.priceDetails?.rewardsUsed;
  const rewardsAmountUsed = payWithPointsState
    ? data?.priceDetails?.rewardsUsed
    : 0;
  let netAmount = payWithPointsState
    ? data?.priceDetails?.netAmount
    : data?.priceDetails?.grossAmount;
  const discountAmount = data?.priceDetails?.discountGiven;

  if (netAmount === undefined) {
    netAmount = totalPrice;
  }

  useEffect(() => {
    setNetAmountState(netAmount);
  }, [netAmount]);

  const slotGoingToBook = findEvents?.[0]?.eventTiming?.find(
    (item) => item?._id === slotId,
  );

  const slotDate = moment(slotGoingToBook?.slot?.startTime).format(
    'Do MMMM, hh:mm A',
  );
  return (
    <View m="2xl">
      <View>
        <EventDetailCard venue={findEvents?.[0]?.venue} date={slotDate} />
        {!!(rewardsAmountCanUsed > 0) && (
          <View mt="4xl">
            <PayWithPointsComp
              rewardsAmountCanUsed={rewardsAmountCanUsed}
              payWithPointsState={payWithPointsState}
              setPayWithPointsState={setPayWithPointsState}
            />
          </View>
        )}
        <View mt="4xl">
          <Text size="md" color="primary.200" weight="medium">
            Billing Summary
          </Text>
        </View>
        {!!payWithPointsState && rewardsAmountCanUsed > 0 && (
          <View mt="2xl" mb="2xl">
            <AmountSavedComp
              text={`You’re saving ₹${rewardsAmountUsed} on this bill`}
            />
          </View>
        )}
        <View mt="2xl">
          <TicketBillingCard
            ticketsInfo={ticketsInfo}
            netAmount={netAmount}
            discountAmount={discountAmount}
            rewardsAmountUsed={rewardsAmountUsed}
          />
        </View>
      </View>
    </View>
  );
};

const ReservationConfirmationScreenWrapper = ({ navigation, route }) => {
  const { slotId, eventId, tickets, ticketsInfo, totalPrice } =
    route.params || {};

  const [netAmountState, setNetAmountState] = useState(0);
  const isLoggedIn = useAuthStore((state) => state?.isLoggedIn);
  const params = JSON.parse(JSON.stringify(route?.params || {}));

  const campusID = useCommonStore((state) => state.campusId);

  const [payWithPointsState, setPayWithPointsState] = useState(true);

  const { userRole } = useUserStore((state) => ({
    userRole: state.role,
  }));
  const grossAmount = ticketsInfo?.reduce((acc, curr) => {
    if (curr?.count > 0) {
      return acc + curr?.basePrice * curr?.count;
    }
    return acc;
  }, 0);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const variables = {
    useRewardPoints: true,
    orderType: 'EVENT',
    orderId: eventId,
    // amount: slots * pricePerPerson,
    includeGetNetPrice: isLoggedIn,
    filter: {
      campus: { _id: campusID },
      _id: eventId,
      isActive: true,
      // eventTiming: { slot: { startTime: new Date() } },
    },
    workshopDetails: {
      slotId,
      tickets: !isEmpty(tickets) ? Object.values(tickets) : [],
    },
  };

  useFocusEffect(
    React.useCallback(() => {
      firebaseEventLogger('event_confirmation_landing', {
        ...params,
        screenName: 'eventConfirmation',
        userType: 'user',
        interactionType: 'landing',
        eventId,
      });
    }, []),
  );

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="Event Registration" />;
  }

  return (
    <Layout level={2}>
      <View>
        <TopNavigation
          title="Event Registration"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => {
                navigateBack(navigation, userRole);
              }}
            />
          }
        />
      </View>

      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense
          fallback={
            <Loading />
            // <ShimmerPlaceholder height={height - (tabBarHeight + 70)} />
          }
        >
          <View flex={1}>
            <View flex={1}>
              <ScrollView style={{ flex: 1 }}>
                <ReservationConfirmationScreen
                  variables={variables}
                  queryOptions={refreshedQueryOptions}
                  route={route}
                  setNetAmountState={setNetAmountState}
                  payWithPointsState={payWithPointsState}
                  setPayWithPointsState={setPayWithPointsState}
                  ticketsInfo={ticketsInfo}
                  totalPrice={totalPrice}
                />
              </ScrollView>
            </View>
            <View>
              <ReservationConfirmationFooter
                grossAmount={grossAmount}
                netAmount={netAmountState}
                payWithPoints={payWithPointsState}
                route={route}
              />
            </View>
          </View>
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default ReservationConfirmationScreenWrapper;
