/* eslint-disable react/no-unescaped-entities */
import { useWindowDimensions } from 'react-native';
import { Button, Text, View } from '../../../components/new';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';

const CouponApplied = ({ appliedCoupon, closeModal }) => {
  const { width } = useWindowDimensions();
  return (
    <View
      width={width - 32}
      mx="2xl"
      px="2xl"
      bg="background.secondary.elevation"
      borderRadius="md"
    >
      <View alignItems="center" mt="4xl">
        <CustomLottieView
          autoPlay
          loop
          source={require('../../../../assets/lottie/investment_success.json')}
          style={{ width: 196, height: 196 }}
          containerWidth={196}
        />
        <Text size="md" color="primary.200" pb="lg">
          '{appliedCoupon?.couponCode}' Applied
        </Text>
        <Text size="xl" color="primary.500" weight="medium" textAlign="center">
          {appliedCoupon?.savedAmount > 0
            ? `You have saved ₹${appliedCoupon?.savedAmount} with this coupon`
            : appliedCoupon?.cashbackAmount > 0
            ? `You will get a cashback of ₹${appliedCoupon?.cashbackAmount} with this coupon`
            : ''}
        </Text>
      </View>
      <Button
        appearance="filled"
        status="primary"
        size="xl"
        my="4xl"
        width={width - 64}
        onPress={closeModal}
      >
        Awesome
      </Button>
    </View>
  );
};

export default CouponApplied;
