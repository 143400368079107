import React, { Suspense, useCallback, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useTheme } from 'styled-components/native';
import { useFocusEffect } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import useUserStore from '../../../../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import {
  Button,
  Icon,
  IconButton,
  Layout,
  Pressable,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import NewErrorView from '../../../../utilities/NewErrorView';
import Loading from '../../../../components/loading.component';
import { transactionsScreenQuery } from '../api/transactionsScreenQuery';
import TransactionCard from './TransactionCard';
import PaymentTag from '../PaymentTag';
import {
  DateFormatsEnum,
  getFormatedDate,
  transactionTypeEnum,
  walletTransactionEnum,
} from '../../../../utilities/helper';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import useAuthStore from '../../../../stores/authStore';
import NotLoggedInPlaceholderV2 from '../../../../components/new/custom/NotLoggedInPlaceholderV2';
import { getTheme } from '../../../../themes/new/theme';
import { FontWeights } from '../../../../themes/new/helper';
import CachedImage from '../../../../components/new/custom/CachedImage';
import CachedImageBackground from '../../../../components/new/custom/CachedImageBackground';
import ParallaxAnimationTransaction from './ParallaxAnimationTransactions';

const TransactionsScreen = ({
  variables,
  queryOptions,
  navigation,
  backToHome,
}) => {
  const theme = useTheme();
  const { getPaymentHistory: data, findUserById } = useLazyLoadQuery(
    transactionsScreenQuery,
    variables,
    queryOptions,
  );

  const { blackMembership, role } = useUserStore((state) => state);
  const { active } = blackMembership ?? {};
  const { earnedAmount, moneySaved } = findUserById?.wallet ?? {};
  const { top } = useSafeAreaInsets();

  const backActionHandler = () => {
    if (backToHome) {
      if (Platform.OS === 'web') {
        navigation.navigate(role);
      } else {
        navigation.popToTop();
      }
    } else {
      navigation.navigate('account');
    }
  };

  const RenderBlackMemberShipCard = () => (
    <Pressable
      onPress={() => {
        navigation.navigate('blackMembershipScreen');
      }}
    >
      <CachedImageBackground
        source={require('../../../../../assets/images/black_membership/mesh-bg-h.png')}
        style={{
          overflow: 'hidden',
          borderBottomWidth: getTheme().borderWidths.xs,
          borderBottomColor: getTheme(theme?.currentThemeMode).colors.gold[500],
        }}
      >
        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          py="lg"
        >
          <Text size="sm" color="static.white" mr="sm">
            {active
              ? 'Save more with your 32nd Plus membership'
              : 'Save upto ₹500 on every visit to 32nd'}
          </Text>
          <Icon
            name="keyboard-arrow-right-300"
            size="md"
            color="static.white"
          />
        </View>
      </CachedImageBackground>
    </Pressable>
  );

  const RenderAnimationContent = () => (
    <>
      <View my="4xl" mt={top + 60} justifyContent="center">
        <View mb="lg">
          <Text
            size="3xl"
            color="success.500"
            textAlign="center"
            weight={FontWeights.MEDIUM}
          >
            You Saved ₹{parseInt(moneySaved, 10)}
          </Text>
        </View>
        <Pressable
          style={{ alignSelf: 'center' }}
          onPress={() => {
            navigation.navigate('pointsScreen');
          }}
        >
          <View
            bg="opacity.30"
            borderWidth="xs"
            borderRadius="sm"
            borderColor="primary.50"
            flexDirection="row"
            alignItems="center"
            px="lg"
            py="sm"
          >
            <Text size="sm" color="gold.500" textAlign="center" mr="sm">
              Earned {parseInt(earnedAmount, 10)}
            </Text>
            <CachedImage
              style={{
                height: 24,
                width: 24,
                marginRight: getTheme().space.lg,
              }}
              source={require('../../../../../assets/images/coins.webp')}
            />
            <Icon name="keyboard-arrow-right-300" size="md" color="gold.500" />
          </View>
        </Pressable>
      </View>

      {RenderBlackMemberShipCard()}
    </>
  );

  return (
    <>
      {data.length > 0 ? (
        <ParallaxAnimationTransaction
          minHeight={0}
          onPressIcon={backActionHandler}
          leftIconName="back-outline-300"
          AnimationContent={RenderAnimationContent()}
          bottomContent={RenderBlackMemberShipCard()}
        >
          <View bg="background.primary.base">
            <FlashList
              // ref={ref}
              data={data}
              contentContainerStyle={{
                paddingBottom: getTheme().space['4xl'],
              }}
              scrollIndicatorInsets={{ right: 1 }}
              scrollEventThrottle={1}
              renderItem={(props) => {
                const item = props?.item as any;
                let displayName = '';
                if (item?.orderType === 'STORE') {
                  displayName = item?.sourceDetails?.store?.displayName;
                } else {
                  displayName = item?.sourceDetails?.restaurant?.displayName;
                }
                const grossAmount = item?.priceDetails?.grossAmount;
                if (!displayName) {
                  displayName = item?.displayName;
                }
                const netAmount = item?.priceDetails?.netAmount;
                const discountGiven = item?.priceDetails?.discountGiven ?? 0;
                const rewardsUsed = item?.priceDetails?.rewardsUsed ?? 0;
                const tipAmount = item?.priceDetails?.tipAmount ?? 0;
                const couponAmount = item?.priceDetails?.couponAmount ?? 0;
                const couponCashbackAmount =
                  item?.priceDetails?.couponCashbackAmount ?? 0;
                const charityAmount = item?.priceDetails?.csrAmount ?? 0;
                const invoiceId = item?._id;
                let earnedPoints = 0;
                item?.rewardPoints?.forEach((j) => {
                  if (
                    (j?.source?.type === walletTransactionEnum.firstTxn ||
                      j?.source?.type ===
                        walletTransactionEnum.subsequentTxn) &&
                    j?.type === transactionTypeEnum.credit
                  ) {
                    earnedPoints += j?.amount;
                  }
                });
                const date = `${getFormatedDate(
                  item?.created_at,
                  DateFormatsEnum.DateMonthYear,
                )} | ${getFormatedDate(
                  item?.created_at,
                  DateFormatsEnum.TIME,
                )}`;
                return (
                  <View mb="2xl" px="2xl">
                    <TransactionCard
                      {...props}
                      border
                      leftText={displayName}
                      leftDsc={date}
                      rightText={`₹${parseInt(grossAmount, 10)}`}
                      onPress={() => {
                        firebaseEventLogger('TRANSACTION_CARD_CLICKED', {
                          invoiceId,
                          restaurantName: displayName,
                          interactionType: 'tap',
                          isLoggedIn: true,
                        });
                        const qparams: Record<string, any> = { invoiceId };
                        if (tipAmount > 0) qparams.tipAmount = tipAmount;
                        if (charityAmount > 0)
                          qparams.charityAmount = charityAmount;
                        navigation.navigate('transactionScreen', qparams);
                      }}
                      logoVisible={false}
                      earnedPoints={earnedPoints + couponCashbackAmount}
                      savedPoints={discountGiven + rewardsUsed + couponAmount}
                      isBottomVisible={
                        item.status !== 'FAILED' && item.status !== 'REFUNDED'
                      }
                      rightBottomComp={<PaymentTag status={item?.status} />}
                    />
                  </View>
                );
              }}
            />
          </View>
        </ParallaxAnimationTransaction>
      ) : (
        <>
          <TopNavigation
            title=""
            appearance="ghost"
            level="none"
            IconLeft={
              <IconButton
                name="back-outline-300"
                size="sm"
                appearance="ghost"
                iconColor="primary.500"
                onPress={backActionHandler}
              />
            }
          />
          <View
            style={{
              height: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              paddingHorizontal: 16,
            }}
          >
            <CachedImage
              style={{ width: 240, height: 240, marginTop: 108 }}
              source={require('../../../../../assets/images/no-transaction.webp')}
            />
            <Text color="primary.500" size="2xl" weight="med">
              No Transactions Yet!
            </Text>
            <Text
              color="primary.200"
              size="md"
              mt={8}
              style={{ textAlign: 'center' }}
            >
              Pay a bill at any restaurant and get upto 20% discount
            </Text>
            <Button
              mt="4xl"
              width="100%"
              status="primary"
              size="lg"
              state="active"
              appearance="filled"
              onPress={() => {
                firebaseEventLogger('NO_TRANSACTION_PAY_BILL_BUTTON', {
                  userType: 'user',
                  interactionType: 'tap',
                  isLoggedIn: true,
                });
                navigation.navigate('dineIn');
              }}
            >
              Pay Bill (upto 20% off)
            </Button>
          </View>
        </>
      )}
    </>
  );
};

const TransactionsScreenWrapper = ({ navigation, route }) => {
  const { backToHome } = route?.params ?? {};
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { role: userRole, id } = useUserStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
      // networkCacheConfig: { force: true },
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  const variables = {
    amount: 100,
    useRewardPoints: false,
    sort: ['DESC'],
    userId: id,
  };
  // ===================

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="32nd Transactions" />;
  }

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <TransactionsScreen
            queryOptions={refreshedQueryOptions}
            variables={variables}
            navigation={navigation}
            backToHome={backToHome}
          />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};
export default TransactionsScreenWrapper;
