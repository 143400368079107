import React, { useState, useEffect, useContext } from 'react';
import { Linking, Platform, AppState } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ThemeContext } from 'styled-components/native';
import { get } from 'lodash';
import { Camera } from 'expo-camera';
import {
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import { navigateBack } from '../../utilities/helper';
import Barcode from '../../components/new/custom/Barcode';
import DarkThemeWrapper from '../new/wrapperComp/DarkThemeWrapper';
import { claimTicket } from './API/claimApi';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import useBoundStore from '../../stores';
import { findRestaurants2 } from '../../relay/restaurants2Api';
import useCommonStore from '../../stores/commonStore';
import useUserStore from '../../stores/userStore';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

const QR = 'qr';

const StaffBarCodeScannerScreen = ({ navigation, route }) => {
  const { screenName, restaurant, restaurantName, hideManual } =
    route.params ?? '';
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [scanned, setScanned] = useState(false);
  const [scannedError, setScannedError] = useState(false);
  const [tourchMode, setTourchMode] = useState(false);
  const [borderColor, setBorderColor] = useState('info.500');
  const { top } = useSafeAreaInsets();

  const userRole = useUserStore((state) => state.role);
  const theme = useContext(ThemeContext);
  const setOrderDetails = useBoundStore((state) => state.setOrderDetails);
  const campusID = useCommonStore((state) => state.campusId);

  const [appState, setAppState] = useState(AppState.currentState);
  const [
    hasReturnedFromPermissionSettings,
    setHasReturnedFromPermissionSettings,
  ] = useState(false);
  const [recheckPermission, setRecheckPermission] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'web') return;
    const handleAppStateChange = (nextAppState) => {
      if (appState.match(/inactive|background/) && nextAppState === 'active') {
        setHasReturnedFromPermissionSettings(true);
      }
      setAppState(nextAppState);
    };
    const subscription = AppState.addEventListener(
      'change',
      handleAppStateChange,
    );
    return () => {
      subscription.remove();
    };
  }, [appState]);

  const requestPermissionAgain = () => {
    Linking.openSettings();
  };

  const requestCameraPermission = () => {
    requestPermission()
      .then((res) => {
        if (res?.canAskAgain === false && res?.status !== 'granted') {
          dispatchSnackbar({
            msg: 'Please grant permission',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
          setTimeout(() => {
            requestPermissionAgain();
          }, 1000);
        } else if (res?.status !== 'granted') {
          dispatchSnackbar({
            msg: 'Permission denied!',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (permission && permission?.status !== 'denied' && !permission?.granted) {
      requestCameraPermission();
    }
  }, [permission]);

  useEffect(() => {
    if (hasReturnedFromPermissionSettings && recheckPermission) {
      setHasReturnedFromPermissionSettings(false);
      setRecheckPermission(false);
      requestCameraPermission();
    }
  }, [permission, hasReturnedFromPermissionSettings, recheckPermission]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setScanned(true);
      setBorderColor('error.500');
      setScannedError(true);
    }, 7000);
    return () => {
      clearInterval(timeoutID);
    };
  }, [scanned]);

  const handleBarCodeScanned = ({ type, data }) => {
    const isQr = type === 256 || type === QR || type === 'org.iso.QRCode';
    setScanned(true);
    if (screenName === 'pay' && isQr) {
      if (data) {
        if (data.includes('redirectToPlatformStore')) {
          const urlData = new URL(data);
          const baseUrl = data.split('redirectToPlatformStore')[0];
          let deeplink = urlData.searchParams.get('deeplink');

          deeplink = deeplink.replace(':::', '?');
          deeplink = deeplink.replace(/::/g, '=');
          deeplink = deeplink.replace(/:/g, '&');

          data = baseUrl + deeplink;
        }

        const urlData = new URL(data);
        const orderId = urlData.searchParams.get('orderId');
        const orderType = urlData.searchParams.get('orderType');
        const amount = urlData.searchParams.get('amount');
        if (!orderId || !orderType) {
          firebaseEventLogger('QRCodeScanner__invalidQR_Data', {
            buttonName: 'invalidQR',
            screenName: 'QRCodeScanner',
            userType: 'user',
            interactionType: 'data',
            orderId,
            orderType,
          });
          setBorderColor('error.500');
          setTourchMode(false);
          dispatchSnackbar({
            msg: 'Invalid Code, please scan the correct QR code to continue',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
          setScanned(false);
          return;
        }
        setBorderColor('info.500');
        setTourchMode(false);
        setScanned(false);
        setScannedError(false);
        firebaseEventLogger('QRCodeScanner__invalidQR_Data', {
          buttonName: 'QRSuccess',
          screenName: 'QRCodeScanner',
          userType: 'user',
          interactionType: 'data',
          orderId,
          orderType,
          pageTag: 'QRScan',
        });
        if (amount && Number(amount) !== 0) {
          navigation.navigate('paymentAmountScreen', {
            orderId,
            orderType,
            amount,
          });
        } else {
          navigation.navigate('paymentScreen', {
            orderId,
            orderType,
            amount,
          });
        }
      }
      return;
    }
    if (screenName === 'order' && isQr) {
      if (data) {
        if (data.includes('redirectToPlatformStore')) {
          const urlData = new URL(data);
          const baseUrl = data.split('redirectToPlatformStore')[0];
          let deeplink = urlData.searchParams.get('deeplink');

          deeplink = deeplink.replace(':::', '?');
          deeplink = deeplink.replace(/::/g, '=');
          deeplink = deeplink.replace(/:/g, '&');

          data = baseUrl + deeplink;
        }
        const urlData = new URL(data);
        const restaurantId = urlData.searchParams.get('restaurantId');
        const tableNumber = urlData.searchParams.get('tableNumber');

        // const restaurantName = urlData.searchParams.get('restaurantName');
        if (restaurantId !== restaurant) {
          setBorderColor('error.500');
          setTourchMode(false);
          dispatchSnackbar({
            msg: 'Invalid Code, please scan the correct QR code to continue',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
          navigation.navigate('order', {
            restaurant,
            restaurantName,
          });
          return;
        }
        if (restaurantId) {
          const restaurantData = {
            _id: restaurantId,
            campus: { _id: campusID },
          };
          findRestaurants2(restaurantData)
            .then((res) => {
              if (res.findRestaurants2.length) {
                setBorderColor('success.500');
                setTourchMode(false);
                const resData = res.findRestaurants2[0];
                // console.log(resData);
                if (tableNumber) {
                  setOrderDetails({ restaurantId, tableNumber });
                  navigation.navigate('order', {
                    restaurant: restaurantId,
                    restaurantName: resData.displayName,
                    // restaurantData: resData,
                  });
                }
              }
            })
            .catch((err) => console.log(err));
        }
      }
      return;
    }
    if (data && typeof parseInt(data, 10) === 'number') {
      const variables = {
        uId: parseInt(data, 10),
      };
      claimTicket(
        { filter: variables },
        (res, err) => {
          if (res && res?.claimTicket && res?.claimTicket?._id) {
            setBorderColor('success.500');
            setTourchMode(false);
            setTimeout(() => {
              navigation.navigate('TicketScreen', {
                tickedId: res?.claimTicket?._id,
                ticketTitle: res?.claimTicket?.title,
                data: {
                  ...res?.claimTicket,
                  state: 'REDEEMED',
                },
              });
            }, 200);
          }
        },
        (err) => {
          if (err?.res?.errors[0]?.extensions?.code === '101') {
            setBorderColor('error.500');
            setTourchMode(false);
            dispatchSnackbar({
              msg: `${err?.res?.errors[0]?.message || 'Something Went Wrong!'}`,
              status: SnackbarStatus?.error,
              version: SnackbarVersion?.v2,
            });
          } else if (
            (err?.res?.errors[0]?.extensions?.code === '103' ||
              err?.res?.errors[0]?.extensions?.code === '102') &&
            err?.res?.errors[0]?.extensions?.code !== '101'
          ) {
            setBorderColor('success.500');
            setTourchMode(false);
            setTimeout(() => {
              navigation.navigate('TicketScreen', {
                tickedId: err?.res?.errors[0]?.extensions?.data?.ticket?._id,
                ticketTitle:
                  err?.res?.errors[0]?.extensions?.data?.ticket?.title,
                data: {
                  ...err?.res?.errors[0]?.extensions?.data?.ticket,
                  state:
                    err?.res?.errors[0]?.extensions?.code === '103'
                      ? 'ALREADY_REDEEMED'
                      : 'EXPIRED',
                },
              });
            }, 200);
          } else {
            dispatchSnackbar({
              msg: `${err?.res?.errors[0]?.message || 'Something Went Wrong!'}`,
              status: SnackbarStatus?.error,
              version: SnackbarVersion?.v2,
            });
            setScannedError(true);
            setBorderColor('error.500');
          }
        },
      );
    } else {
      dispatchSnackbar({
        msg: 'Invalid Barcode',
        status: SnackbarStatus?.warning,
        version: SnackbarVersion?.v2,
      });
      setScannedError(true);
      setBorderColor('error.500');
    }
  };

  if (!permission?.granted) {
    return (
      <View flex={1}>
        <TopNavigation
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigateBack(navigation, userRole)}
            />
          }
          appearance="ghost"
          level="1"
        />
        <View flex={1} justifyContent="center" alignItems="center" px="2xl">
          <CustomLottieView
            style={{ width: 200, height: 200 }}
            autoPlay
            loop
            source={require('../../../assets/lottie/no-camera.json')}
            containerWidth={200}
          />
          <Text size="2xl" weight="medium" color="primary.400" mt="4xl">
            Permission denied!
          </Text>
          <Text textAlign="center" size="md" color="primary.200" mt="lg">
            {Platform.OS === 'web' ? (
              <>Enable permission for camera in browser settings</>
            ) : (
              <>Enable permission for camera in settings</>
            )}
          </Text>
          {Platform.OS !== 'web' && (
            <View width="100%">
              <Button
                appearance="filled"
                status="primary"
                size="lg"
                mt="4xl"
                onPress={() => {
                  Linking.openSettings();
                  setRecheckPermission(true);
                }}
              >
                Go To Settings
              </Button>
            </View>
          )}
        </View>
      </View>
    );
  }

  const onPressScanAgain = () => {
    setScanned(false);
    setScannedError(false);
    setBorderColor('info.500');
  };

  const onPressAddManually = () => {
    // navigation.replace('StaffAddManuallyBarcodeScreen');
  };

  return (
    <Layout level={2} edges={['top']}>
      {permission?.granted ? (
        <Barcode
          scanned={scanned}
          scannedError={scannedError}
          handleBarCodeScanned={handleBarCodeScanned}
          tourchMode={tourchMode}
          borderColor={get(theme?.colors, borderColor)}
          onPressScanAgain={onPressScanAgain}
          onPressAddManually={hideManual ? undefined : onPressAddManually}
          TopNavigationComponent={
            <View
              position="absolute"
              top={top + 8}
              backgroundColor="transparent"
              zIndex={99}
              width="100%"
            >
              <DarkThemeWrapper>
                <TopNavigation
                  appearance="ghost"
                  level="none"
                  IconLeft={
                    <IconButton
                      name="back-outline-300"
                      size="md"
                      appearance="ghost"
                      onPress={() => {
                        navigateBack(navigation, userRole);
                      }}
                    />
                  }
                  IconRight={
                    <IconButton
                      name={tourchMode ? 'flash-on-300' : 'flash-off-300'}
                      size="md"
                      appearance="ghost"
                      onPress={() => {
                        setTourchMode(!tourchMode);
                      }}
                    />
                  }
                />
              </DarkThemeWrapper>
            </View>
          }
          scanQr
        />
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default StaffBarCodeScannerScreen;
