import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Linking, Platform, useWindowDimensions } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useUserStore from '../../../../stores/userStore';

import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import {
  DateFormatsEnum,
  getFormatedDate,
  formatPlateNo,
  navigateBack,
  paymentOrderTypeEnum,
  getFullName,
  PaymentStatusEnum,
  PaymentGatewayType,
} from '../../../../utilities/helper';
import {
  Button,
  IconButton,
  Layout,
  Pressable,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import NewErrorView from '../../../../utilities/NewErrorView';
import Loading from '../../../../components/loading.component';
import { transactionScreenQuery } from '../api/transactionScreenQuery';
import BillingCard from '../BillingCard';
import TransactionCard from '../transactionsScreen/TransactionCard';
import useFeedbackModalStore from '../../../../stores/FeedbackModalStore';
import { feedbacksTopics } from '../../../../utilities/ratingOptions';
import usePaymentModalStore from '../../../../stores/PaymentModalStore';
import ParkingDetails from '../ParkingDetails';
import useHardBackButton from '../../../../hooks/useHardBackButton';
import {
  Appearance,
  FontWeights,
  State,
  Status,
} from '../../../../themes/new/helper';
import AppConstants from '../../../../utilities/AppConstants';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';
import TransactionBg from '../TransactionBg';
import PaymentTag from '../PaymentTag';
import { getTheme } from '../../../../themes/new/theme';
import CustomScrollView from '../../../../components/new/custom/CustomScrollView';
import { BS_MODES } from '../PaymentAmountScreen';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';
import PaymentBreakUp from '../PaymentBreakUp';
import CachedImage from '../../../../components/new/custom/CachedImage';
import CachedImageBackground from '../../../../components/new/custom/CachedImageBackground';
import TotalSaved from './TotalSaved';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';

const TransactionScreen = ({
  orderType,
  variables,
  queryOptions,
  paymentSuccessPage,
  isTrackYourCar = false,
  parkingID,
  charityAmount,
  backActionHandler,
  invoiceId,
}) => {
  const { REST_URL } = AppConstants;
  const navigation = useNavigation();
  const { bottom } = useSafeAreaInsets();
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const FeedbackModalStore = useFeedbackModalStore((state) => state);
  const componentState = usePaymentModalStore((state) => state);
  const { blackMembership } = useUserStore((state) => state);
  const { active } = blackMembership ?? {};
  const { width } = useWindowDimensions();
  const [bsMode, setBsMode] = useState<BS_MODES>(BS_MODES.NONE);
  const { findInvoice, findUserById } = useLazyLoadQuery(
    transactionScreenQuery,
    variables,
    queryOptions,
  );

  const data = findInvoice[0] ?? {};

  let displayName = '';
  let logo = '';
  let parkingTag = '';
  const valetTip = 0;
  let pointsData = [];
  let rewardPoints = [];
  let isParking = false;
  let appliedCoupon = null;
  const AppLogo = 'https://ik.imagekit.io/32ndhub/assets/32nd_logo_black.png';

  if (data?.orderType === paymentOrderTypeEnum.store) {
    displayName = data?.sourceDetails?.store?.displayName;
    logo = data?.sourceDetails?.store?.logo;
  } else if (data?.orderType === paymentOrderTypeEnum.restaurant) {
    displayName = data?.sourceDetails?.restaurant?.displayName;
    logo = data?.sourceDetails?.restaurant?.logo;
  } else if (data?.orderType === paymentOrderTypeEnum.popupStore) {
    displayName = data?.sourceDetails?.popUpStore?.displayName;
    logo = data?.sourceDetails?.popUpStore?.logo;
  } else if (data?.orderType === paymentOrderTypeEnum.ADDONS) {
    displayName = data?.displayName;
    logo = AppLogo;
  } else if (data?.orderType === paymentOrderTypeEnum.EVENT) {
    displayName = data?.displayName;
    logo = AppLogo;
  } else if (data?.orderType === paymentOrderTypeEnum.PARKING) {
    isParking = true;
    displayName = data?.sourceDetails?.parking?.vehicle?.registration?.plate
      ? formatPlateNo(
          data?.sourceDetails?.parking?.vehicle?.registration?.plate,
        )
      : data?.displayName;
    parkingTag = `#${data?.sourceDetails?.parking?.parkingTag || ''}`;
    pointsData = data?.sourceDetails?.parking?.pointsData ?? [];
    rewardPoints = data?.rewardPoints ?? [];
    logo = AppLogo;
  } else if (data?.orderType === paymentOrderTypeEnum.INVESTMENT_LEAD) {
    displayName =
      data?.sourceDetails?.investmentLead?.investmentDetails?.propertyName;
    logo = AppLogo;
  }

  const grossAmount = data?.priceDetails?.grossAmount;
  const netAmount = data?.priceDetails?.netAmount;
  const discountAmount = data?.priceDetails?.discountGiven ?? 0;
  let discountPercentage = data?.priceDetails?.discountPercentage;
  discountPercentage = Math.round(discountPercentage);
  const tipAmount = data?.priceDetails?.tipAmount;

  const rewardsAmountUsed = data?.priceDetails?.rewardsUsed ?? 0;
  const addonAmount = data?.priceDetails?.addonAmount ?? 0;
  const couponAmount = data?.priceDetails?.couponAmount ?? 0;

  const totalSaved = discountAmount + rewardsAmountUsed + couponAmount;
  const cashback =
    data?.priceDetails?.cashbackAmount +
    data?.priceDetails?.couponCashbackAmount;

  if (couponAmount > 0) {
    appliedCoupon = {
      couponCode: data?.couponCode,
      savedAmount: data?.priceDetails?.couponAmount,
    };
  }

  useEffect(() => {
    if (
      data?.orderType === paymentOrderTypeEnum.event ||
      data?.sourceDetails?.restaurant?.type === paymentOrderTypeEnum.store ||
      data?.orderType === paymentOrderTypeEnum.POPUPSTORE ||
      data?.orderType === paymentOrderTypeEnum.ADDONS
    ) {
      FeedbackModalStore.setIsOpen(false);
      return;
    }

    if (paymentSuccessPage) {
      if (isParking) {
        FeedbackModalStore.setParkingId(
          parkingID ?? data?.sourceDetails?.parking?._id,
        );
        FeedbackModalStore.setFeedbackType(feedbacksTopics.VALET);
      } else if (data?.orderType === paymentOrderTypeEnum.restaurant) {
        FeedbackModalStore.setRestaurantId(
          data?.sourceDetails?.restaurant?._id,
        );
        FeedbackModalStore.setInvoiceId(data?._id);
        FeedbackModalStore.setFeedbackType(feedbacksTopics.DINE_OUT);
      }
      FeedbackModalStore.setIsOpen(true);
    }
  }, [data]);

  const date = `${getFormatedDate(
    data?.created_at,
    DateFormatsEnum.DateMonthYear,
  )} | ${getFormatedDate(data?.created_at, DateFormatsEnum.TIME)}`;

  const pdfLink = data?.sourceDetails?.parking?.parkingInvoice?.invoicePdfPath
    ? `${REST_URL}/parkingInvoice/view/${data?.sourceDetails?.parking?._id}`
    : '';

  const openBreakUpPanel = () => {
    setBsMode(BS_MODES.BREAK_UP);
    bottomSheetModalRef?.current?.show();
  };

  const canViewBreakUp = (): boolean => {
    if (data?.orderType === paymentOrderTypeEnum.PARKING) return true;
    if (data?.orderType === paymentOrderTypeEnum.EVENT) {
      const evSlotId = data?.sourceDetails?.event?.eventTiming[0]?._id;
      const evTicketId =
        data?.sourceDetails?.event?.eventTiming[0]?.slot?.availableTickets[0]
          ?._id;
      if (!evSlotId || !evTicketId) return false;
      return true;
    }
    return false;
  };

  const generateBreakUpData = () => {
    const breakUpData: Record<string, any> = {
      ...data,
      billAmount: grossAmount,
    };
    if (data?.orderType === paymentOrderTypeEnum.EVENT) {
      breakUpData.slotId = data?.sourceDetails?.event?.eventTiming[0]?._id;
      breakUpData.tickets = [
        {
          ticketId:
            data?.sourceDetails?.event?.eventTiming[0]?.slot
              ?.availableTickets[0]?._id,
          spaces: data?.workshopDetails?.totalSpaces,
        },
      ];
    }

    return breakUpData;
  };

  const onBsDismiss = () => {
    setBsMode(BS_MODES.NONE);
  };

  return !findInvoice?.length ? (
    <View flex={1}>
      <TopNavigation
        title=""
        appearance="ghost"
        level="none"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="sm"
            appearance="ghost"
            iconColor="primary.500"
            onPress={backActionHandler}
          />
        }
      />
      <View alignItems="center" justifyContent="center">
        <CachedImage
          style={{ width: 240, height: 240, marginTop: 108 }}
          source={require('../../../../../assets/images/no-transaction.webp')}
        />
        <Text color="primary.500" size="2xl" weight="med">
          No Data Found!
        </Text>
      </View>
    </View>
  ) : (
    <View flex={1}>
      <TransactionBg showGreenBg>
        <View>
          <TopNavigation
            title=""
            appearance="ghost"
            level="none"
            IconLeft={
              <IconButton
                name="back-outline-300"
                size="sm"
                appearance="ghost"
                iconColor="primary.500"
                onPress={backActionHandler}
              />
            }
          />
          <View height={190} justifyContent="center">
            <CustomLottieView
              resizeMode="contain"
              autoPlay
              loop
              source={require('../../../../../assets/lottie/confetti_explode.json')}
              style={{ position: 'absolute', width, height: 200 }}
              containerWidth={width}
            />
            <View flex={1} justifyContent="center" alignItems="center">
              <Text
                size="3xl"
                color="primary.500"
                mb="lg"
                textAlign="center"
                weight={FontWeights.MEDIUM}
              >
                ₹{parseInt(grossAmount, 10)}
              </Text>
              <PaymentTag status={data?.status} />
            </View>
          </View>
          <View>
            {(data?.status === PaymentStatusEnum.SUCCESSFUL ||
              data?.paymentType === PaymentGatewayType.BILL_UPLOAD) &&
            (totalSaved > 0 || cashback > 0) ? (
              <TotalSaved
                totalSaved={totalSaved}
                cashback={cashback}
                isPointsAvailable={data?.rewardPoints[0]?.isAvailable}
                orderType={data?.orderType}
              />
            ) : null}
          </View>
        </View>
      </TransactionBg>

      <CustomScrollView contentContainerStyle={{ flex: 1 }}>
        <View mx="2xl">
          <View my="4xl">
            <TransactionCard
              logo={logo}
              border={false}
              leftText={displayName}
              leftDsc={date}
              rightText={
                isParking
                  ? parkingTag
                  : data?.orderType === paymentOrderTypeEnum.restaurant
                  ? data?.uId ?? 'NA'
                  : data?._id?.slice(-4).toUpperCase()
              }
              rightDsc={isParking ? 'Tag ID' : 'Code'}
              isBottomVisible={false}
            />
          </View>
          <View>
            <BillingCard
              onViewBreakUp={canViewBreakUp() ? openBreakUpPanel : null}
              orderType={data?.orderType}
              grossAmount={grossAmount}
              netAmount={netAmount}
              discountAmount={discountAmount}
              valetTip={valetTip}
              rewardsAmountUsed={rewardsAmountUsed}
              discountPercentage={discountPercentage}
              isParking={isParking}
              tipEnabled={tipAmount > 0}
              tipAmount={tipAmount}
              charityEnabled={charityAmount > 0}
              charityAmount={charityAmount}
              paid
              appliedCoupon={appliedCoupon}
              addonAmount={addonAmount}
            />
          </View>
          {active ? (
            <Pressable
              onPress={() => {
                navigation.navigate('blackMembershipScreen');
              }}
            >
              <CachedImageBackground
                source={require('../../../../../assets/images/black_membership/mesh-bg-h.png')}
                imageStyle={{
                  borderRadius: getTheme().radii.lg,
                  borderColor: getTheme().colors.static.gold,
                  borderWidth: getTheme().borderWidths.xs,
                  resizeMode: 'cover',
                }}
                style={{ marginTop: getTheme().space['4xl'] }}
              >
                <View flexDirection="row" alignItems="center" p="2xl">
                  <CachedImage
                    source={require('../../../../../assets/images/black_membership/black-member-logo.webp')}
                    style={{ width: 60, height: 40, resizeMode: 'contain' }}
                  />
                  {findUserById?.wallet?.earnedAmount ? (
                    <View ml="2xl" flex={1} flexWrap="wrap">
                      <Text size="xs" color="static.white">
                        Congratulations{' '}
                        {getFullName(
                          findUserById?.name?.first,
                          findUserById?.name?.last,
                        )}
                        !
                      </Text>
                      <Text color="primary.100" size="xs" mt="sm">
                        You’ve saved ₹
                        {parseInt(findUserById?.wallet?.moneySaved, 10)} with
                        32nd Plus so far
                      </Text>
                    </View>
                  ) : (
                    <View ml="2xl" flex={1} flexWrap="wrap">
                      <Text size="xs" color="static.white">
                        Save upto ₹500 on every visit to 32nd
                      </Text>
                    </View>
                  )}
                </View>
              </CachedImageBackground>
            </Pressable>
          ) : (
            <Pressable
              onPress={() => {
                navigation.navigate('blackMembershipScreen');
              }}
            >
              <CachedImageBackground
                source={require('../../../../../assets/images/black_membership/mesh-bg-h.png')}
                imageStyle={{
                  borderRadius: getTheme().radii.lg,
                  borderColor: getTheme().colors.static.gold,
                  borderWidth: getTheme().borderWidths.xs,
                  resizeMode: 'cover',
                }}
                style={{ marginTop: getTheme().space['4xl'] }}
              >
                <View
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  p="2xl"
                >
                  <Text size="xs" color="static.white">
                    Save upto ₹500 on every visit to 32nd
                  </Text>
                  <IconButton
                    name="keyboard-arrow-right-300"
                    size="sm"
                    iconSize="md"
                    iconColor="static.white"
                    appearance={Appearance.GHOST}
                  />
                </View>
              </CachedImageBackground>
            </Pressable>
          )}
          {isParking && isTrackYourCar ? (
            <ParkingDetails
              entryTime={componentState?.entryTime}
              recallTime={componentState?.recallTime}
            />
          ) : null}
        </View>
        <View height={50} />
      </CustomScrollView>
      {((isParking && netAmount !== 0) ||
        data?.orderType === paymentOrderTypeEnum.EVENT) &&
      data?.status === PaymentStatusEnum.SUCCESSFUL ? (
        <View width="100%" px="2xl" py="2xl" mb={bottom}>
          <Button
            size="lg"
            state={State.ACTIVE}
            status={Status.PRIMARY}
            appearance={Appearance.FILLED}
            onPress={() => {
              if (isParking) {
                if (Platform.OS === 'web' && pdfLink) {
                  Linking.openURL(pdfLink);
                } else {
                  navigation.navigate('CustomWebview', {
                    title: 'Payment Receipt',
                    url: pdfLink,
                    showLeft: true,
                  });
                }
              }
              if (data?.orderType === paymentOrderTypeEnum.EVENT) {
                navigation.navigate('eventTicket', { invoiceId });
              }
            }}
          >
            {data?.orderType === paymentOrderTypeEnum.EVENT
              ? 'View Booking'
              : 'View Receipt'}
          </Button>
        </View>
      ) : (
        <></>
      )}
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={onBsDismiss}>
        {bsMode === BS_MODES.BREAK_UP && (
          <View mt="xl" px="2xl">
            <PaymentBreakUp
              data={generateBreakUpData()}
              orderType={data?.orderType}
            />
          </View>
        )}
      </BottomSheetV2>
    </View>
  );
};

const TransactionScreenWrapper = ({ navigation, route }) => {
  const { role: userRole, id } = useUserStore((state) => state);
  const {
    invoiceId,
    parkingID,
    rewardTxnId,
    paymentTxnId,
    backToHome,
    isTrackYourCar,
    charityAmount,
    orderType,
    paymentSuccessPage,
    orderId,
    status,
  } = route?.params ?? {};
  useHardBackButton(() => {
    if (backToHome) {
      if (Platform.OS === 'web') {
        navigation.navigate(userRole);
      } else {
        navigation.popToTop();
      }
    } else {
      navigateBack(navigation, userRole);
    }
  });
  // ===================
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useEffect(() => {
    firebaseEventLogger('transaction_landing', {
      invoiceId,
    });
  }, []);

  const filter = {
    _id: invoiceId,
    paymentTxnId,
    rewardTxnId,
    orderId,
    status,
  };
  // ===================

  const backActionHandler = () => {
    if (backToHome) {
      navigation.popToTop();
    } else {
      navigateBack(navigation, userRole);
    }
  };

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <TransactionScreen
            orderType={orderType}
            queryOptions={refreshedQueryOptions}
            variables={{ filter, userId: id }}
            parkingID={parkingID}
            navigation={navigation}
            isTrackYourCar={isTrackYourCar}
            paymentSuccessPage={paymentSuccessPage}
            route={route}
            retry={refresh}
            charityAmount={charityAmount ?? 0}
            backActionHandler={backActionHandler}
            invoiceId={invoiceId}
          />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};
export default TransactionScreenWrapper;
