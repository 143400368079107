import React from 'react';
import { View } from '../../../components/new';
import BreakupPanel from '../../../components/new/custom/transaction/BreakupPanel';
import { TransactionsEarningsReport } from '../types';

const PointsCalculationPanel = ({
  earningsReport,
}: {
  earningsReport: TransactionsEarningsReport;
}) => {
  const {
    redeemedLoyaltyPoints,
    compensatedLoyaltyPoints,
    grantedLoyaltyPoints,
  } = earningsReport.formated;

  return (
    <BreakupPanel.Wrapper>
      <View mb="2xl">
        <BreakupPanel.ContentRow
          content={{
            leftTxt: 'Loyalty Points Redeemed (R)',
            rightTxt: redeemedLoyaltyPoints,
          }}
        />
      </View>
      <View mb="2xl">
        <BreakupPanel.ContentRow
          content={{
            leftTxt: 'Loyalty Points Given (G)',
            rightTxt: grantedLoyaltyPoints,
          }}
        />
      </View>
      <BreakupPanel.Divider />
      <View mb="2xl">
        <BreakupPanel.ContentRow
          content={{
            leftTxt:
              grantedLoyaltyPoints > redeemedLoyaltyPoints
                ? 'Points Compensation (R<G)'
                : 'Points Compensation (R-G)',
            rightTxt: compensatedLoyaltyPoints,
            color: 'success.500',
          }}
        />
      </View>
    </BreakupPanel.Wrapper>
  );
};

export default PointsCalculationPanel;
