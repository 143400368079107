import React, { useEffect, useState } from 'react';
import { Text } from '../../../components/new';
import { secondsToHms } from '../../../utilities/helper';

const CountDown = ({ onComplete, countDown = 3, v2 = false }) => {
  const [time, setTime] = useState<number>(countDown);

  useEffect(() => {
    let interval = setInterval(() => {
      setTime((val) => {
        if (val === 1) {
          clearInterval(interval);
          interval = null;
        }
        return val - 1;
      });
    }, 1000 * 1);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    if (time === 0) {
      if (onComplete && typeof onComplete === 'function') {
        onComplete();
      }
    }
  }, [time]);

  if (v2) {
    return (
      <Text textAlign="center" size="md" color="primary.100">
        {secondsToHms(time)}
      </Text>
    );
  }

  return (
    <Text textAlign="center" size="sm" color="primary.100">
      Redirecting in {time}s
    </Text>
  );
};

export default CountDown;
