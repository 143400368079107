import React from 'react';
import { Icon, Pressable, Text, View } from '../../../components/new';
import useCommonStore, {
  UpdateModelStatesEnum,
} from '../../../stores/commonStore';

const UpdateAppCard = () => {
  const { setShowUpdateModal, setDeniedTime } = useCommonStore(
    (state) => state,
  );
  const onPress = () => {
    setShowUpdateModal({ state: true, status: UpdateModelStatesEnum.PROMPT });
    setDeniedTime(null);
  };
  return (
    <Pressable onPress={onPress}>
      <View
        bg="background.primary.elevation"
        borderWidth="xs"
        borderColor="primary.20"
        borderRadius="lg"
        px="2xl"
        py="lg"
      >
        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <View flexDirection="row" alignItems="center">
            <Icon size="6xl" color="primary.400" name="clock-outline" />
            <Text size="sm" color="primary.500" ml="2xl">
              App Update Available
            </Text>
          </View>
          <Icon
            size="2xl"
            color="primary.500"
            name="keyboard-arrow-right-300"
          />
        </View>
      </View>
    </Pressable>
  );
};

export default UpdateAppCard;
