/* eslint-disable no-nested-ternary */
import React from 'react';
import { Image, useWindowDimensions } from 'react-native';
import Barcode from 'react-native-barcode-svg';
import { ThemeConsumer } from 'styled-components/native';
import { ThemeProvider } from '@react-navigation/native';
import { Text, View, Icon, Divider } from '../index';
import { themeModes, getTheme } from '../../../themes/new/theme';
import GradientBackground from './GradientBackground';
import {
  DateFormatsEnum,
  getFormatedDate,
  rewardOriginEnum,
} from '../../../utilities/helper';
import { rewardTypeEnums } from '../../../screens/new/games/slotMachine/helpers/slotMachineHelpers';
import imageConfig from '../../../../imagekit.config';
import useCommonStore from '../../../stores/commonStore';

const ParkingCard = (props) => {
  const {
    rewardId,
    owner,
    rewardType,
    expireAt,
    createdAt,
    reward,
    rewardOrigin = '',
    showReedemedStatus,
    showAlreadyRedeemedStatus,
    showExpiredStatus,
    gradientColors = [
      {
        offset: '0',
        color: getTheme(themeModes.light).colors.success[10],
        opacity: 1,
      },
      {
        offset: '1',
        color: getTheme(themeModes.light).colors.warning[10],
        opacity: 1,
      },
    ],
  } = props ?? {};
  const { first, last } = owner ?? {};
  const { width } = useWindowDimensions();
  const screenOrientationValue = useCommonStore((state) => state.orientation);

  const imageKitURL = imageConfig.url({
    src:
      `https://ik.imagekit.io/32ndhub/assets/rewards/${rewardType}.png` ||
      `https://ik.imagekit.io/32ndhub/assets/rewards/FreeParking.png`,
    transformation: [
      {
        height: '80',
        aspectRatio: '1-1',
        format: 'webp',
      },
    ],
  });

  const onText = getFormatedDate(createdAt, DateFormatsEnum.DateMonthYear);

  const getFooterText = () => {
    if (
      rewardType === rewardTypeEnums.freeParking &&
      (rewardOrigin === rewardOriginEnum.trackScreen ||
        rewardOrigin === rewardOriginEnum.trackCard)
    ) {
      return `Awarded as a token of apology, due to delay in delivery on ${onText}`;
    }
    if (
      rewardType === rewardTypeEnums.freeParking &&
      rewardOrigin === rewardOriginEnum.firstLogin
    ) {
      return `Voucher given as a gesture of gratitude for logging in on the 32nd Club app on ${onText}`;
    }
    if (
      rewardType === rewardTypeEnums.freeParking &&
      rewardOrigin === rewardOriginEnum.slotMachine
    ) {
      return `Reward added as a prize you earned from the spin & win game on ${onText}`;
    }
    if (
      rewardType === rewardTypeEnums.freeParking &&
      rewardOrigin === rewardOriginEnum.trackingSurveyReward
    ) {
      return `Reward added as a prize you earned from submitting the 32nd survey on  ${onText}`;
    }
    if (rewardType === rewardTypeEnums.freeParking) {
      return 'Display this ticket at the exit gate to redeem your free parking';
    }
    if (rewardType === rewardTypeEnums.freeBubbleTea) {
      return 'Display this ticket at the Got Tea Cafe to claim your free bubble tea';
    }
    if (rewardType === rewardTypeEnums.freeFridgeMagnet) {
      return 'Display this ticket at the waiting lounge counter to claim your free fridge magnet';
    }
    if (rewardType === rewardTypeEnums.freeKeyChain) {
      return 'Display this ticket at the waiting lounge counter to claim your free key chain';
    }
    if (rewardType === rewardTypeEnums.freePostCard) {
      return 'Display this ticket at the waiting lounge counter to claim your free post card';
    }
    if (rewardType === rewardTypeEnums.freeSticker) {
      return 'Display this ticket at the waiting lounge counter to claim your free sticker';
    }
    if (rewardType === rewardTypeEnums.event) {
      return '';
    }
    return 'Display this ticket at the exit gate to redeem your free parking';
  };

  const getFullName = (firstname, lastname) =>
    `${firstname}${lastname !== null ? ` ${lastname}` : ''}`;

  const RenderContent = ({
    title1 = '',
    subtitle1 = '',
    title2 = '',
    subtitle2 = '',
  }) => (
    <>
      <View>
        <Text size="sm" color={getTheme(themeModes.light).colors.primary[200]}>
          {title1}
        </Text>
        <Text
          size="lg"
          color={getTheme(themeModes.light).colors.primary[400]}
          mt="sm"
        >
          {subtitle1}
        </Text>
      </View>
      <View>
        <View alignItems="flex-end">
          <Text
            size="sm"
            color={getTheme(themeModes.light).colors.primary[200]}
          >
            {title2}
          </Text>
        </View>
        <Text
          size="lg"
          color={getTheme(themeModes.light).colors.primary[400]}
          mt="sm"
        >
          {subtitle2}
        </Text>
      </View>
    </>
  );
  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider theme={getTheme(themeModes.light)}>
          <GradientBackground
            showBorder
            showMaskCircle
            backgroundOpacity={1}
            gradientColors={gradientColors}
            maskCircleColor={theme.colors.background.primary.base}
            strokeBorderRadius={theme.radii.xl}
            strokeBorderWidth={theme.borderWidths.sm}
            strokeBorderColor={theme.colors.primary[50]}
            px="2xl"
            //   py="4xl"
            width={
              width > 1200 && screenOrientationValue < 3
                ? 375 - 2 * theme.space['2xl']
                : width - 2 * theme.space['2xl']
            }
            //   shadow="2xl"
            id={rewardId}
            circlePosition={
              rewardType === rewardTypeEnums.event ? '65%' : '59%'
            }
          >
            <View
              flexDirection="row"
              mt="4xl"
              alignItems="center"
              // justifyContent="center"
            >
              {rewardType !== rewardTypeEnums.event ? (
                <Image
                  source={{
                    uri: imageKitURL,
                  }}
                  style={{ height: 40, width: 40 }}
                />
              ) : (
                <Image
                  source={{
                    uri: 'https://ik.imagekit.io/32ndhub/assets/32nd-round-black.png',
                  }}
                  style={{ height: 40, width: 40 }}
                />
              )}
              {/* <View ml="xl" flex={1}> */}
              <Text
                color={getTheme(themeModes.light).colors.primary[400]}
                size="2xl"
                ml="2xl"
                weight="medium"
                numberOfLines={1}
              >
                {reward}
              </Text>
              {/* <Text color="primary.400" size="sm" numberOfLines={2}>
            Display this ticket at the exit gate
          </Text> */}
              {/* </View> */}
            </View>
            <View mt="4xl">
              <Divider level={1} />
            </View>
            <View mt="4xl">
              <View flexDirection="row" justifyContent="space-between">
                {rewardType !== rewardTypeEnums.event ? (
                  <RenderContent
                    title1="Customer Name"
                    subtitle1={getFullName(first, last).slice(0, 15)}
                    title2="Reward ID"
                    subtitle2={rewardId}
                  />
                ) : (
                  <RenderContent
                    title1="Event Date"
                    subtitle1={getFormatedDate(
                      expireAt,
                      DateFormatsEnum.DateMonthYear,
                    )}
                    title2="Event Time"
                    subtitle2="6:00 PM"
                  />
                )}
              </View>
              <View flexDirection="row" justifyContent="space-between" mt="9xl">
                {rewardType === rewardTypeEnums.freeParking && (
                  <RenderContent
                    title1="Issue Date"
                    subtitle1={getFormatedDate(
                      createdAt,
                      DateFormatsEnum.DateMonthYear,
                    )}
                    title2="Expiry Date"
                    subtitle2={getFormatedDate(
                      expireAt,
                      DateFormatsEnum.DateMonthYear,
                    )}
                  />
                )}
                {rewardType !== rewardTypeEnums.freeParking &&
                  rewardType !== rewardTypeEnums.event && (
                    <RenderContent
                      title1="Valid Till"
                      subtitle1={getFormatedDate(
                        createdAt,
                        DateFormatsEnum.DateMonthYear,
                      )}
                    />
                  )}
                {rewardType === rewardTypeEnums.event && (
                  <RenderContent
                    title1="Event Avenue"
                    subtitle1="32nd Lawns"
                    title2="Valid For"
                    subtitle2="1 Person"
                  />
                )}
              </View>
              {rewardType === rewardTypeEnums.event && (
                <View
                  flexDirection="row"
                  justifyContent="space-between"
                  mt="9xl"
                >
                  <RenderContent
                    title1="Customer Name"
                    subtitle1={getFullName(first, last).slice(0, 15)}
                    title2="Ticket ID"
                    subtitle2={rewardId}
                  />
                </View>
              )}
            </View>
            <View mt="9xl+4xl">
              <View height={2} px="sm" overflow="hidden">
                <View
                  borderStyle="dashed"
                  borderWidth="sm"
                  borderColor={getTheme(themeModes.light).colors.primary[50]}
                />
              </View>
            </View>
            {showReedemedStatus ? (
              <View mt="4xl" mb="9xl+9xl">
                <View mt="7xl" justifyContent="center" alignItems="center">
                  {/* <View> */}
                  <Icon
                    name="check-filled-400"
                    size="3xl+3xl"
                    color="success.500"
                  />
                  <Text size="2xl" color="success.500" weight="medium">
                    Offer Redeemed
                  </Text>
                  {/* </View> */}
                </View>
              </View>
            ) : showAlreadyRedeemedStatus ? (
              <View mt="4xl" mb="9xl+9xl">
                <View mt="7xl" justifyContent="center" alignItems="center">
                  {/* <View> */}
                  <Icon
                    name="warning-outline-400"
                    size="3xl+3xl"
                    color="error.500"
                  />
                  <Text size="2xl" color="error.500" weight="medium">
                    Already Redeemed
                  </Text>
                  {/* </View> */}
                </View>
              </View>
            ) : showExpiredStatus ? (
              <View mt="4xl" mb="9xl+9xl">
                <View mt="7xl" justifyContent="center" alignItems="center">
                  {/* <View> */}
                  <Icon
                    name="warning-outline-400"
                    size="3xl+3xl"
                    color="error.500"
                  />
                  <Text size="2xl" color="error.500" weight="medium">
                    Offer Expired
                  </Text>
                  {/* </View> */}
                </View>
              </View>
            ) : (
              <>
                <View mt="4xl">
                  <View mt="7xl" alignItems="center">
                    <Barcode
                      format="CODE39"
                      value={rewardId}
                      singleBarWidth={3}
                      maxWidth={
                        width > 1200 && screenOrientationValue < 3
                          ? 375 - 3 * theme.space['4xl']
                          : width - 3 * theme.space['4xl']
                      }
                      height={60}
                    />
                    <Text size="md" textAlign="center">
                      {rewardId}
                    </Text>
                  </View>
                </View>
                <View mt="9xl" mb="4xl">
                  <Text
                    size="md"
                    color={getTheme(themeModes.light).colors.primary[200]}
                    weight="medium"
                    textAlign="center"
                    numberOfLines={3}
                    ellipsizemode="tail"
                  >
                    {`${getFooterText()}`}
                  </Text>
                </View>
              </>
            )}
          </GradientBackground>
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

export default ParkingCard;
