/* eslint-disable @typescript-eslint/ban-types */

type initializeTeamScreenDataReturnType = {
  currentSwipedCardIndex: number;
};
const initializeTeamScreenData = (): initializeTeamScreenDataReturnType => {
  return { currentSwipedCardIndex: -1 };
};

export type teamScreenSliceReturnType = {
  currentSwipedCardIndex: number;
  updateCurrentSwipedCardIndex: Function;
  resetCurrentSwipedCardIndex: Function;
};

const teamScreenSlice = (
  set: (...a: any) => any,
): teamScreenSliceReturnType => {
  return {
    // data ====
    ...initializeTeamScreenData(),
    // data:end ===
    // methods ===
    updateCurrentSwipedCardIndex: ({
      currentSwipedCardIndex,
    }: {
      currentSwipedCardIndex: number;
    }) => {
      set(() => {
        return { currentSwipedCardIndex };
      });
    },
    resetCurrentSwipedCardIndex: () => {
      set(() => {
        return { currentSwipedCardIndex: -1 };
      });
    },
  };
};

export default teamScreenSlice;
