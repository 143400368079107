/* eslint-disable camelcase */
import React from 'react';
import { FlatList } from 'react-native';
import { useFragment } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { Pressable, Text, View } from '../../../../components/new';
import { getTheme } from '../../../../themes/new/theme';
import OfferInfoTile from '../../../../components/new/custom/OfferInfoTile';
import {
  toRupees,
  discountAndWalletTextMakerV2,
} from '../../../../utilities/helper';
import { homeScrenOffersRestaurantsFragment } from '../API/homeScreenQuery';
import { homeScreenQuery_findRestaurantsFragment$key } from '../API/__generated__/homeScreenQuery_findRestaurantsFragment.graphql';
import { buildRestaurantOfferViewData } from '../utils';
import { logFirebaseRestaurantSelectEvent } from '../analytics';
import useUserStore from '../../../../stores/userStore';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';

const theme = getTheme();

const buildOfferLabel = (restaurant, points) => {
  let label = '';
  const hasDiscount = restaurant?.discount > 0;
  const noDiscount = !hasDiscount;
  const hasPoints = points > 0;
  const noPoints = !hasPoints;

  if (hasDiscount && noPoints) {
    label = `FLAT ${restaurant?.discount}% off`;
  } else if (hasDiscount && hasPoints) {
    label = `FLAT ${restaurant?.discount}% + Extra ${toRupees(points)} off`;
  } else if (noDiscount && hasPoints) {
    label = `${toRupees(points)} off`;
  }

  return label;
};

const RestaurantOfferView = ({
  image,
  offerLabel,
  name,
  onPress,
}: {
  image: string;
  offerLabel: string;
  name: string;
  onPress: () => void;
}) => {
  return (
    <View py="2xl">
      <Pressable onPress={onPress}>
        <OfferInfoTile image={image} textPri={offerLabel} textSec={name} />
      </Pressable>
    </View>
  );
};

const RestaurantsWithOffers = ({
  fragmentRef,
}: {
  fragmentRef: homeScreenQuery_findRestaurantsFragment$key;
}) => {
  const navigation = useNavigation();
  const restaurants = useFragment(
    homeScrenOffersRestaurantsFragment,
    fragmentRef,
  );
  const restaurantsViewData = buildRestaurantOfferViewData(restaurants);
  const { currentBalance } = useUserStore((state) => state?.wallet);

  const hasRestaurants = Boolean(restaurantsViewData?.length);

  const navigateToRestaurantPage = (name: string, id: string) => {
    logFirebaseRestaurantSelectEvent(name, 'discount-&-offers');
    navigation.navigate('RestaurantScreen', { restaurantId: id });
  };

  return (
    <View mt={hasRestaurants ? '4xl' : 'none'}>
      {hasRestaurants && (
        <Text size="lg" weight="medium" color="primary.400">
          Cafes With Offers
        </Text>
      )}
      <DarkThemeWrapper>
        <FlatList
          horizontal
          directionalLockEnabled
          showsHorizontalScrollIndicator={false}
          data={restaurantsViewData}
          renderItem={({ item, index }) => {
            const isLastItem = index === restaurantsViewData.length - 1;
            return (
              <View pr={isLastItem ? 'none' : '2xl'}>
                <RestaurantOfferView
                  name={`@${item?.restaurantName}`}
                  image={item?.coverImage}
                  offerLabel={discountAndWalletTextMakerV2(
                    currentBalance,
                    item.discount,
                    item.cashback,
                  )}
                  onPress={() =>
                    navigateToRestaurantPage(item?.restaurantName, item?.id)
                  }
                />
              </View>
            );
          }}
        />
      </DarkThemeWrapper>
    </View>
  );
};

export default RestaurantsWithOffers;
