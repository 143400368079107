/* eslint-disable import/prefer-default-export */
import { graphql, commitMutation } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

export const updateTaskToArriveQuery = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation updateTaskToArriveQuery(
      $id: MongoID!
      $record: updateTaskRecordInput!
    ) {
      updateTask(record: $record, _id: $id) {
        record {
          _id
        }
      }
    }
  `;
  const variables = {
    id: data._id,
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};
