import React from 'react';
import { Icon, Pressable, Text, View } from '../..';

const RefreshBubble = ({ onPress, length }) => {
  if (!length) {
    return null;
  }
  return (
    <View
      bg="primary.500"
      position="absolute"
      zIndex={3}
      flexDirection="row"
      borderRadius="2xl"
    >
      <Pressable onPress={onPress}>
        <View py="lg" px="2xl">
          <View flexDirection="row" alignItems="center">
            <Text color="primary.10" size="sm" mr="lg">
              {length} New Tasks
            </Text>
            <Icon name="refresh-300" color="primary.10" size="md" />
          </View>
        </View>
      </Pressable>
    </View>
  );
};

export default RefreshBubble;
