import { graphql, requestSubscription } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

const partnerTransactionUpdatedSubscription = graphql`
  subscription partnerTransactionUpdatedSubscription(
    $invoiceId: ID!
    $orderId: ID
  ) {
    invoiceUpdated(invoiceId: $invoiceId, orderId: $orderId) {
      _id
      status
      priceDetails {
        grossAmount
      }
      user {
        _id
        contact {
          phone {
            prefix
            number
          }
        }
      }
      paymentType
      orderType
      orderId
      popUpStoreDetails {
        deliveryStatus
        displayName
      }
      created_at
      paymentDetails {
        razorpay {
          pgOrderId
        }
        paytmQr {
          transactionId
        }
      }
    }
  }
`;

export default (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: partnerTransactionUpdatedSubscription,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      console.log('error', error);
      // console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
