import React from 'react';
import { BlurView } from 'expo-blur';
import { StyleSheet } from 'react-native';
import { Button, Text, View } from '../../../../../components/new';
import { Appearance } from '../../../../../themes/new/helper';
import { daysToMonthYear, toRupees } from '../../../../../utilities/helper';
import { getTheme, themeModes } from '../../../../../themes/new/theme';
import GoldButton from '../GoldButton';

const lightTheme = getTheme(themeModes.light);
interface FooterProps {
  membershipAdded: boolean;
  duration: number;
  price: number;
  priceSlashed: number;
  onRemoveMembership: () => void;
  onAddMembership: () => void;
}

const Footer = (props: FooterProps) => {
  const {
    membershipAdded,
    duration,
    price,
    priceSlashed,
    onRemoveMembership,
    onAddMembership,
  } = props;

  return (
    <BlurView
      intensity={20}
      style={{ borderBottomEndRadius: 12, overflow: 'hidden' }}
    >
      <View
        px="2xl"
        py="lg"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {membershipAdded ? (
          <>
            <Text size="xs" color="primary.200">
              {daysToMonthYear(duration)}membership added
            </Text>
            <Button appearance={Appearance.GHOST} onPress={onRemoveMembership}>
              <Text size="md" color="error.500">
                Remove
              </Text>
            </Button>
          </>
        ) : (
          <>
            <View>
              <View flexDirection="row">
                {priceSlashed < price && (
                  <Text
                    mr="md"
                    size="xs"
                    color={lightTheme.colors.primary[200]}
                    style={styles.slashedText}
                  >
                    {toRupees(price)}
                  </Text>
                )}
                <Text size="xs" color="gold.50">
                  {priceSlashed}
                </Text>
              </View>
              <Text size="2xs" color={lightTheme.colors.primary[200]}>
                Limited Time Offer
              </Text>
            </View>
            <GoldButton onPress={onAddMembership} text=" Add To Cart" />
          </>
        )}
      </View>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  slashedText: {
    textDecorationLine: 'line-through',
  },
  bgImage: {
    flex: 1,
    justifyContent: 'center',
  },
});

export default Footer;
