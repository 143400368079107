import Wrapper from './Wrapper';
import ContentRow from './ContentRow';
import Divider from './Divider';
import { Data } from './types';
import View from '../../../basic/View/View';

interface BreakupPanelProps {
  withShadow?: boolean;
  bodyData: Data[];
  footerData: Data[];
}

const BreakupPanel = ({
  withShadow = false,
  bodyData,
  footerData,
}: BreakupPanelProps) => {
  return (
    <Wrapper withShadow={withShadow}>
      <View>
        {bodyData.map((data) => (
          <View key={data.leftTxt + data.rightTxt} mb="2xl">
            <ContentRow content={data} />
          </View>
        ))}
        <Divider />
        {footerData.map((data) => (
          <View key={data.leftTxt + data.rightTxt} mb="2xl">
            <ContentRow content={data} />
          </View>
        ))}
      </View>
    </Wrapper>
  );
};

BreakupPanel.Wrapper = Wrapper;
BreakupPanel.ContentRow = ContentRow;
BreakupPanel.Divider = Divider;

export default BreakupPanel;
