import React from 'react';
import useAuthStore from '../../stores/authStore';
// import UserAccountScreen from '../../screens/user/userAccountScreen';
import NotLoggedInAccountScreen from '../../screens/new/NotLoggedInAccountScreen';
import AccountScreenWrapper from '../../screens/new/account/AccountScreen';

const UserAccountScreenWrapper = (props) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  if (isLoggedIn) {
    // return <UserAccountScreen {...props} />;
    return <AccountScreenWrapper {...props} />;
  }
  return <NotLoggedInAccountScreen />;
};

export default UserAccountScreenWrapper;
