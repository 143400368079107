/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const valetOverviewQuery = graphql`
  query valetOverviewAPIQuery(
    $dailyBonusFilter: DailyValetTaskBonusFilter
    $monthlyBonusFilter: MonthlyValetTaskBonusFilter
    $yearlyBonusFilter: valetLast12MonthsBonusFilter
  ) {
    dailyValetTaskBonus(filter: $dailyBonusFilter) {
      date
      bonus {
        onTimeTaskCount
        delayedTaskCount
        bonusAmount
      }
    }
    monthlyValetTaskBonus(filter: $monthlyBonusFilter) {
      date
      bonusData {
        onTimeTaskCount
        delayedTaskCount
        bonusAmount
      }
    }
    valetLast12MonthsBonus(filter: $yearlyBonusFilter) {
      year
      month
      monthName
      onTimeTaskCount
      delayedTaskCount
      bonusAmount
    }
  }
`;
