import React, { useMemo, forwardRef } from 'react';
import View from '../../../basic/View/View';
import BottomSheetV2 from '../../../composites/BottomSheet/BottomSheetV2';

type BonusInfoBottomSheetProps = {
  onDismiss?: () => void;
  Content: React.ReactElement;
  insets: boolean;
};

const BonusInfoBottomSheet = forwardRef(
  ({ onDismiss, Content, insets }: BonusInfoBottomSheetProps, ref: any) => {
    return (
      <BottomSheetV2
        ref={ref}
        onClose={onDismiss || (() => ref?.current?.hide())}
        insets={insets}
      >
        <View>{Content}</View>
      </BottomSheetV2>
    );
  },
);

export default BonusInfoBottomSheet;
