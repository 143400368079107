import React from 'react';
import { View } from '../../../../components/new';
import { toRupees } from '../../../../utilities/helper';
import PriceRow from './PriceRow';

interface ParkingBillBreakUpProps {
  data: {
    billAmount: number;
    sourceDetails: {
      parking: {
        parkingInvoice: {
          charges: {
            chargesBreakup: { duration: number; payable: number }[];
          };
        };
      };
    };
  };
}

const getFormatedChargeInfo = (breakUp: {
  duration: number;
  payable: number;
}) => {
  const hours = Math.ceil(breakUp?.duration / 3600);
  const charge = breakUp?.payable;

  return {
    hours,
    charge,
  };
};

const ParkingBillBreakUp = ({ data }: ParkingBillBreakUpProps) => {
  const breakUpList = [
    ...data?.sourceDetails?.parking?.parkingInvoice?.charges?.chargesBreakup,
  ];
  const { billAmount } = data;
  if (breakUpList instanceof Array === false || !breakUpList?.length) {
    return null;
  }
  const { hours: baseHours, charge: baseCharge } = getFormatedChargeInfo(
    breakUpList[0],
  );

  const hasAdditionalHours = breakUpList?.length > 1;
  return (
    <View>
      <View mb="2xl">
        <PriceRow
          leftContent={`Parking Fee (${baseHours} ${
            baseHours > 1 ? 'hrs' : 'hr'
          })`}
          rightContent={toRupees(baseCharge)}
        />
      </View>
      {hasAdditionalHours && (
        <View mb="2xl">
          <PriceRow
            leftContent={`Additional Hour (${
              getFormatedChargeInfo(breakUpList[1])?.hours
            } ${
              getFormatedChargeInfo(breakUpList[1])?.hours > 1 ? 'hrs' : 'hr'
            })`}
            rightContent={toRupees(
              getFormatedChargeInfo(breakUpList[1])?.charge ?? 0,
            )}
          />
        </View>
      )}
      <PriceRow
        leftContent="Bill Amount"
        rightContent={toRupees(billAmount)}
        emphasized
      />
    </View>
  );
};

export default ParkingBillBreakUp;
