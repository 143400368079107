/* eslint-disable no-extra-boolean-cast */
import React, { useState, useCallback, useEffect, Suspense } from 'react';
import {
  BackHandler,
  Linking,
  ImageBackground,
  useWindowDimensions,
} from 'react-native';
import { Camera } from 'expo-camera';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import moment from 'moment';
import {
  BlurView,
  IconButton,
  Layout,
  TopNavigation,
  View,
} from '../components/new';
import { navigateBack } from '../utilities/helper';
import photoMoments from './Strings';
import HybridCamScanner from './videoMoments/HybridCamScanner';
import { useSnackbarStore } from '../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../components/new/primitive/snackbar/helpers/helpers';
import useLoginModalStore from '../stores/loginModalStore';
import useAuthStore from '../stores/authStore';
import VMLanding from './videoMoments/VMLanding';
import useUserStore from '../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../utilities/NewErrorBoundary';
import NewErrorView from '../utilities/NewErrorView';
import Confirmface from './videoMoments/ConfirmFace';
import ConfirmVideo from './videoMoments/ConfirmVideo';
import VideoCamera from './videoMoments/VideoCamera';
import { s3Client } from '../utilities/s3config';
import mapVideoToImageForVideoMomentsApi from '../relay/mapVideoToImageForVideoMomentsApi';
import useVideoMomentsStore from '../stores/VideoMomentsStore';
import { isValueNullOrEmpty } from '../utilities/Utility';
import { Loading } from '../components';
import { firebaseEventLogger } from '../utilities/firbaseAnalytics';
import CustomLottieView from '../components/new/custom/CustomLottieView';

const VideoMoments = ({ navigation }) => {
  const insets = useSafeAreaInsets();
  const userRole = useUserStore((state) => state.role);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [currentFlow, setCurrentFlow] = useState(0); // 0-intro || 1-camera || 2-confirm_face || 3-video_camera || 4-confirm_video
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [imageData, setImageData] = useState(null);
  const [hasMicPermission, setHasMicPermission] = useState(false);
  const [videoPath, setVideoPath] = useState(null);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const {
    videoData,
    setVideoData,
    uploaded,
    setUploaded,
    inProgress,
    setInProgress,
    motionSensor,
    setMotionSensor,
    lastUpdated,
    setLastUpdated,
  } = useVideoMomentsStore((state) => state);
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const onLoginButtonPress = useCallback(() => {
    setAuthModalOpen(true);
  }, []);
  const { height, width } = useWindowDimensions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backActionHandler = async () => {
    if (currentFlow === 1 || currentFlow === 4) {
      await setCurrentFlow(0);
      return true;
    }
    if (currentFlow === 2) {
      await setCurrentFlow(1);
      return true;
    }
    if (currentFlow === 3) {
      await setCurrentFlow(2);
      return true;
    }
    await navigateBack(navigation, userRole);
    return false;
  };

  useEffect(() => {
    const today = moment().format('DD/MM/YYYY');
    if (lastUpdated && lastUpdated === today) return;
    const now = new Date();
    let millisTill530 =
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 5, 30, 0, 0) -
      now;
    if (millisTill530 < 0) {
      setLastUpdated(today);
      millisTill530 += 86400000; // it's after 5:30am, try 5:30am tomorrow.
      if (!isValueNullOrEmpty(lastUpdated)) {
        firebaseEventLogger('videoMoments__videoSummary_data', {
          buttonName: 'makeVideo',
          screenName: 'videoMoments',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          uploaded,
          failed: !isValueNullOrEmpty(videoData)
            ? JSON.parse(videoData)?.length
            : 0,
        });
        setTimeout(() => {
          setUploaded(0);
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (currentFlow === 1) {
      setImageData(null);
    }
  }, [currentFlow]);

  useEffect(() => {
    if (isLoggedIn && global.checkPermission) {
      checkPermission();
      global.checkPermission = false;
    }
  }, [isLoggedIn]);

  useEffect(() => {
    return () => {
      global.checkPermission = false;
    };
  }, []);

  const requestPermissionAgain = () => {
    Linking.openSettings();
  };

  const checkMic = async () => {
    const micStatus = await Camera.requestMicrophonePermissionsAsync();
    if (micStatus.granted) {
      setHasMicPermission(true);
    } else {
      requestPermissionAgain();
    }
  };

  const checkPermission = async () => {
    firebaseEventLogger('videoMoments__makeVideo_Tap', {
      buttonName: 'makeVideo',
      screenName: 'videoMoments',
      userType: 'VideoSupervisor',
      interactionType: 'tap',
    });
    if (!isLoggedIn) {
      onLoginButtonPress();
      global.checkPermission = true;
      return;
    }
    if (permission?.granted && hasMicPermission) {
      setCurrentFlow(1);
    } else {
      requestPermission()
        .then((res) => {
          if (res?.status === 'granted') {
            setCurrentFlow(1);
            checkMic();
          } else {
            dispatchSnackbar({
              msg: photoMoments.permissionDenied,
              status: SnackbarStatus.error,
              version: SnackbarVersion.v1,
            });
            setTimeout(() => {
              requestPermissionAgain();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const processImage = (data) => {
    firebaseEventLogger('videoMoments__captureFace_Tap', {
      buttonName: 'captureFace',
      screenName: 'videoMoments',
      userType: 'VideoSupervisor',
      interactionType: 'tap',
    });
    setImageData(data);
    setCurrentFlow(2);
  };

  const takingBackFunction = () => {
    setCurrentFlow(0);
    dispatchSnackbar({
      msg: photoMoments.cameraTimeout,
      status: SnackbarStatus.error,
      version: SnackbarVersion.v1,
    });
  };

  const uploadfileToS3 = ({ bucketName, fileName, blob }) => {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: blob,
      contentDeposition: `inline;filename="${fileName}"`,
      ContentType: 'video/mp4',
    };

    return s3Client.upload(params).promise();
  };

  const uploadFunction = async (val) => {
    setTimeout(() => {
      setCurrentFlow(0);
      setImageData(null);
      setVideoPath(null);
    }, 2000);
    setInProgress(inProgress + 1);
    const result = await fetch(val?.uri);
    const blob = await result.blob();
    const params = {
      ...val,
      blob,
    };
    const newParams = {
      imageData,
      ...val,
    };
    uploadfileToS3(params)
      .then(() => {
        successFullyUploaded(newParams);
        firebaseEventLogger('videoMoments__awsSuccess_data', {
          buttonName: 'awsSuccess',
          screenName: 'videoMoments',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          videoId: val?.fileName,
        });
        // console.log('passed stage 1', val);
      })
      .catch((error) => {
        setToDefault({ storeVideo: true, awsfailedVal: newParams }); // saving video to local cache
        // console.log('failed stage 1', error);
        firebaseEventLogger('videoMoments__awsFailed_data', {
          buttonName: 'awsFailed',
          screenName: 'videoMoments',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          videoId: val?.fileName,
          error: JSON.stringify(error),
        });
        setInProgress(inProgress > 0 ? inProgress - 1 : 0);
      });
  };

  const mapVideosFunction = (params) => {
    const MapVideoToImageInput = {};
    (MapVideoToImageInput as any).imageBase64 = params?.imageBase64;
    (MapVideoToImageInput as any).videoId = params?.videoId;
    mapVideoToImageForVideoMomentsApi(MapVideoToImageInput)
      .then((response) => {
        // console.log('passed stage 2', response);
        if (response?.mapVideoToImageForVideoMoments?.success) {
          setToDefault({});
          setUploaded(uploaded + 1);
          setInProgress(inProgress > 0 ? inProgress - 1 : 0);
          dispatchSnackbar({
            msg: photoMoments.uploadedSuccessFully,
            status: SnackbarStatus.success,
            version: SnackbarVersion.v1,
          });
          firebaseEventLogger('videoMoments__apiSuccess_data', {
            buttonName: 'apiSuccess',
            screenName: 'videoMoments',
            userType: 'VideoSupervisor',
            interactionType: 'data',
            videoId: params?.videoId,
          });
        } else {
          setToDefault({ storeVideo: true, apiFailedVal: params }); // saving video to local cache
          setInProgress(inProgress > 0 ? inProgress - 1 : 0);
          // console.log('failed stage 2');
          firebaseEventLogger('videoMoments__apiFailed_data', {
            buttonName: 'apiFailed',
            screenName: 'videoMoments',
            userType: 'VideoSupervisor',
            interactionType: 'data',
            videoId: params?.videoId,
          });
        }
      })
      .catch((error) => {
        setToDefault({ storeVideo: true, apiFailedVal: params }); // saving video to local cache
        setInProgress(inProgress > 0 ? inProgress - 1 : 0);
        // console.log('failed stage 3', error);
        firebaseEventLogger('videoMoments__apiFailed_data', {
          buttonName: 'apiFailed',
          screenName: 'videoMoments',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          videoId: params?.videoId,
          error: JSON.stringify(error),
        });
      });
  };

  const setToDefault = ({
    storeVideo = false,
    awsfailedVal = null,
    apiFailedVal = null,
  }) => {
    // setCurrentFlow(0);
    if (storeVideo) {
      let params = {};
      if (!!awsfailedVal) {
        params = {
          uploadToS3: true,
          awsfailed: awsfailedVal,
          videoId: awsfailedVal?.fileName,
          imageBase64: awsfailedVal?.imageData?.base64,
          uri: awsfailedVal?.imageData?.uri,
          subTitle1: moment().format('Do MMMM, YYYY'),
          subTitle2: moment().format('hh:mm A'),
        };
      } else {
        params = {
          uploadToS3: false,
          apiFailedObj: apiFailedVal,
          videoId: apiFailedVal?.videoId,
          uri: apiFailedVal?.uri,
          subTitle1: moment().format('Do MMMM, YYYY'),
          subTitle2: moment().format('hh:mm A'),
        };
      }
      if (!isValueNullOrEmpty(videoData)) {
        const videoDataArr = JSON.parse(videoData);
        const exist = videoDataArr.find((item) => item?.uri === params.uri);
        if (isValueNullOrEmpty(exist)) {
          videoDataArr.push(params);
          setVideoData(JSON.stringify(videoDataArr));
        }
      } else {
        setVideoData(JSON.stringify([params]));
      }
      setTimeout(() => {
        setImageData(null);
        setVideoPath(null);
      }, 2000);
    } else {
      setImageData(null);
      setVideoPath(null);
    }
  };

  const successFullyUploaded = (val) => {
    const params = {
      videoId: val?.fileName,
      imageBase64: val?.imageData?.base64,
      uri: val?.imageData?.uri,
    };
    mapVideosFunction(params);
  };

  const requestMicrophonePermission = () => {
    dispatchSnackbar({
      msg: photoMoments.microPermission,
      status: SnackbarStatus.info,
      version: SnackbarVersion.v1,
    });
    setTimeout(() => {
      requestPermissionAgain();
    }, 1000);
  };

  const SelectFlow = () => {
    switch (currentFlow) {
      case 0:
        return (
          <VMLanding
            openLibrary={() => {
              navigation.navigate('VideoLibrary');
              firebaseEventLogger('videoMoments__videoLibrary_Tap', {
                buttonName: 'videoLibrary',
                screenName: 'videoMoments',
                userType: 'VideoSupervisor',
                interactionType: 'tap',
              });
            }}
            checkPermissionFunction={checkPermission}
          />
        );
      case 1:
        return (
          <HybridCamScanner
            imageUri={imageData?.uri ?? ''}
            setImageUriCallback={(data) => {
              processImage(data);
            }}
            takingBack={takingBackFunction}
          />
        );
      case 2:
        return (
          <Confirmface
            imagePath={imageData?.uri ?? ''}
            recordVideo={() =>
              hasMicPermission
                ? (setCurrentFlow(3),
                  firebaseEventLogger('videoMoments__confirmFace_Tap', {
                    buttonName: 'confirmFace',
                    screenName: 'videoMoments',
                    userType: 'VideoSupervisor',
                    interactionType: 'tap',
                  }))
                : requestMicrophonePermission()
            }
            captureAgain={() => {
              firebaseEventLogger('videoMoments__captureAgain_Tap', {
                buttonName: 'captureAgain',
                screenName: 'videoMoments',
                userType: 'VideoSupervisor',
                interactionType: 'tap',
              });
              setCurrentFlow(1);
              setImageData(null);
            }}
          />
        );
      case 3:
        return (
          <VideoCamera
            motionSensor={motionSensor}
            setMotionSensor={setMotionSensor}
            setVideoUriCallback={(data) => {
              setVideoPath(data);
              setCurrentFlow(4);
            }}
          />
        );
      case 4:
        return (
          <ConfirmVideo
            videoPath={videoPath}
            saveVideo={(val) => {
              // const params = {
              //   uploadToS3: true,
              //   awsfailed: val,
              //   videoId: val?.fileName,
              //   imageBase64: imageData?.base64,
              //   uri: imageData?.uri,
              //   subTitle1: moment().format('Do MMMM, YYYY'),
              //   subTitle2: moment().format('hh:mm A'),
              // };
              // if (!isValueNullOrEmpty(videoData)) {
              //   const videoDataArr = JSON.parse(videoData);
              //   const exist = videoDataArr.find(
              //     (item) => item?.uri === params.uri,
              //   );
              //   if (isValueNullOrEmpty(exist)) {
              //     videoDataArr.push(params);
              //     setVideoData(JSON.stringify(videoDataArr));
              //   }
              // } else {
              //   setVideoData(JSON.stringify([params]));
              // }
              // return;
              uploadFunction(val);
            }}
            discardRecord={() => {
              setCurrentFlow(3);
              firebaseEventLogger('videoMoments__recordAgain_Tap', {
                buttonName: 'recordAgain',
                screenName: 'videoMoments',
                userType: 'VideoSupervisor',
                interactionType: 'tap',
              });
            }}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    // Add event listener for hardware back button press on Android
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backActionHandler,
    );

    return () => {
      // clear/remove event listener
      backHandler.remove();
    };
  }, []);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const errorBoundary = () => (
    <NewErrorBoundary
      fetchKey={refreshedQueryOptions.fetchKey}
      fallback={
        <NewErrorView errorMsg="Sorry something went wrong" reload={refresh} />
      }
    >
      <Suspense
        fallback={
          <View mt="8xl">
            <Loading />
          </View>
        }
      >
        <View flex={1} pb={insets.bottom}>
          {SelectFlow()}
        </View>
        {inProgress > 0 ? (
          <CustomLottieView
            autoPlay
            loop
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width,
            }}
            source={require('../../assets/lottie/loading_line.json')}
            containerWidth={width}
          />
        ) : null}
      </Suspense>
    </NewErrorBoundary>
  );

  return (
    <Layout level={2}>
      {currentFlow === 1 || currentFlow === 2 || currentFlow === 4 ? (
        <>
          <View zIndex={1} position="absolute" top={0} width="100%">
            <TopNavigation
              appearance="ghost"
              level={currentFlow === 1 ? 'none' : 1}
              textColor="primary.500"
              textSize="md"
              IconLeft={
                <IconButton
                  name="back-outline-300"
                  size="sm"
                  appearance="ghost"
                  iconColor={currentFlow === 1 ? 'primary.20' : 'primary.500'}
                  onPress={backActionHandler}
                />
              }
            />
          </View>
          {errorBoundary()}
        </>
      ) : (
        <ImageBackground
          source={require('../../assets/images/campus.webp')}
          style={{ height: '100%', width: '100%' }}
        >
          <BlurView level="1">
            <View style={{ height, width }}>
              <View zIndex={1}>
                {currentFlow === 3 || currentFlow === 1 ? null : (
                  <TopNavigation
                    appearance="ghost"
                    level="none"
                    textColor="primary.500"
                    textSize="md"
                    IconLeft={
                      <IconButton
                        name="back-outline-300"
                        size="sm"
                        appearance="ghost"
                        iconColor="primary.20"
                        onPress={backActionHandler}
                      />
                    }
                  />
                )}
              </View>
              {errorBoundary()}
            </View>
          </BlurView>
        </ImageBackground>
      )}
    </Layout>
  );
};
export default VideoMoments;
