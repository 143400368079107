import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from '../../relay/relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const createPopupOrderAPI = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation createPopupOrderCreatePopupOrderMutation(
      $record: addPaymentOrderRecordInput!
    ) {
      createPopupOrder(record: $record) {
        record {
          _id
          status
          amount
          referenceModelName
          user {
            _id
            contact {
              phone {
                prefix
                number
              }
            }
          }
          paymentType
          popUpStoreDetails {
            deliveryStatus
            displayName
          }
        }
      }
    }
  `;
  const variables = {
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};
