import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const submitKeyScreenQuery = graphql`
  query submitKeyScreenQuery($filter: findTaskFilterInput!) {
    findTasks(filter: $filter) {
      _id
      vehicle {
        registration {
          plate
        }
      }
      history {
        _id
        action
        actor
        event_at
      }
      valet {
        _id
        name {
          first
          last
        }
        identityCode
      }
      taskType
      campus {
        _id
      }
      parkingETA {
        parkingTimePrediction {
          locationId
          name
          parkingTimeThreshold
          acceptedToCompleteTime
          startToCompleteTime
        }
      }
      taskEndLocation {
        locationId
      }
    }
  }
`;
