import { graphql, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

const query = graphql`
  query findPhotosV3ApiQuery($data: PhotoMomentsInputV3) {
    getMatchingPhotosForPhotoMomentsV3(data: $data) {
      assetType
      assetId
      assetLink
      clickedOn
      similarity
      thumbnail
      thumbnailId
    }
  }
`;

const findPhotosV3Api = (data) => {
  const variables = data;
  const request = fetchQuery(environment, query, { data: variables });
  return request.toPromise();
};
export default findPhotosV3Api;
