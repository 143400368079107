// it can be supervisor or controller
// supervisor - doing vehicle entry at the porch

import { taskHistoryActionTypes } from '../../../utilities/helper';

// controller - doing vehicle entry in parkings
export enum managerType {
  supervisor = 'supervisor',
  controller = 'controller',
}

export const doingEntry = (items) => {
  const output = items.find((item) => item.checked);
  if (output?.name.toLowerCase() === '32nd') {
    return true;
  }
  return false;
};

export const isAlreadyInList = (item, list) => {
  return Boolean(list.find((i) => i._id === item._id));
};

export const findAndReplaceTask = (item, list) => {
  const tempList = list.map((i) => {
    if (i._id === item._id) {
      return item;
    }
    return i;
  });

  return tempList;
};

export const findAndBlurTask = (item, list) => {
  let tempList = JSON.parse(JSON.stringify(list));
  tempList = tempList.map((i) => {
    if (i._id === item._id) {
      i.blur = true;
    }
    return i;
  });

  return tempList;
};

export const sortByAccepted = (list) => {
  if (list.length === 1) {
    return list;
  }
  const tempList = JSON.parse(JSON.stringify(list));
  const historyList = tempList.sort((itemOne, itemTwo) => {
    const acceptedTaskOne = itemOne?.history?.find(
      (i) => i?.action === taskHistoryActionTypes.accepted,
    );
    const acceptedTaskTwo = itemTwo?.history?.find(
      (i) => i?.action === taskHistoryActionTypes.accepted,
    );

    const pItemDate = new Date(acceptedTaskOne?.event_at);
    const cItemDate = new Date(acceptedTaskTwo?.event_at);
    return pItemDate.getTime() - cItemDate.getTime();
  });

  return historyList;
};

export const findAndRemoveTask = (item, list) => {
  const tempList = [...list];
  const matchingIndex = tempList.findIndex((i) => i._id === item._id);
  if (matchingIndex >= 0) {
    tempList.splice(matchingIndex, 1);
  }
  return tempList;
};
