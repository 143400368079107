import React, { useEffect, useState } from 'react';
import { graphql, commitMutation } from 'react-relay';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import NewOtpInput from '../../components/new/primitive/NewOtpInput/NewOtpInput';
import { EmailTypes, navigateBack } from '../../utilities/helper';
import { InputStates } from '../../components/new/primitive/OTPInput/helpers';
import environment from '../../relay/relayEnvironment';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import useUserStore from '../../stores/userStore';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

const verifyOtpRequest = graphql`
  mutation EmailOtpVerificationScreenMutation(
    $id: MongoID!
    $record: updateUserRecordInput!
  ) {
    updateUser(_id: $id, record: $record) {
      error {
        message
      }
    }
  }
`;

const EmailOtpVerificationScreen = ({ navigation, route }) => {
  const { bottom } = useSafeAreaInsets();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { params }: any = route;
  const { email, type } = params;
  const { userRole, userId } = useUserStore((state) => ({
    userRole: state.role,
    userId: state.id,
  }));
  const [otpCode, setOtpCode] = useState<string>('');
  const [otpError, setOtpError] = useState('');
  const [isPinReady, setIsPinReady] = useState<boolean>(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  /** handling submit button enable/disable state */
  useEffect(() => {
    if (isPinReady) setSubmitEnabled(true);
    else setSubmitEnabled(false);
  }, [isPinReady]);

  const handlePinReady = (ready: boolean) => {
    setIsPinReady(ready);
  };

  const handleOtpChange = (value: string) => {
    setOtpCode(value);
  };

  const showSuccessSnackbar = () => {
    dispatchSnackbar({
      msg: `${
        type === EmailTypes.PERSONAL ? 'Primary email' : 'Work email'
      } updated!`,
      status: SnackbarStatus.success,
      version: SnackbarVersion.v1,
    });
  };

  const handleOtpSubmit = () => {
    const variables = {
      id: userId,
      record: {
        pin: otpCode,
        contact: {
          email: {
            addr: email,
            type,
          },
        },
      },
    };

    setSubmitEnabled(false);
    commitMutation(environment, {
      mutation: verifyOtpRequest,
      variables,
      onCompleted: () => {
        showSuccessSnackbar();
        if (userRole === 'user') {
          firebaseEventLogger('editProfile__verifyEmailOtp_Tap', {
            buttonName: 'verifyEmailOtp',
            screenName: 'editProfile',
            userType: 'user',
            interactionType: 'tap',
            emailType: type,
          });
        }
        navigation.navigate('editProfile', {
          role: userRole,
          from: 'edit-email',
          emailType: type,
        });
      },
      onError: (err) => {
        setSubmitEnabled(true);
        const error = err?.res?.errors[0]?.message;
        setOtpError(error);
      },
    });
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        textSize="xl"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
            }}
          />
        }
      />
      <View flex={1} px="2xl">
        <View mt="4xl">
          <Text mb="sm" size="3xl" color="primary.200">
            Verify {type === EmailTypes.PERSONAL ? 'Primary' : 'Work'} Email
          </Text>
          <View flexDirection="row" flexWrap="wrap">
            <Text size="sm" color="primary.200">
              {`Enter the 4 digit OTP sent on `}
            </Text>
            <Text size="sm" color="primary.400">
              {email}
            </Text>
          </View>
        </View>
        <View mt="42">
          <NewOtpInput
            label="OTP"
            length={4}
            code={otpCode}
            setCode={handleOtpChange}
            setIsPinReady={handlePinReady}
            otpError={otpError}
            setOtpError={setOtpError}
          />
        </View>
        <View mb={bottom} flex={1} justifyContent="flex-end" bottom="2xl">
          <Button
            size="lg"
            appearance="filled"
            status="primary"
            state={submitEnabled ? InputStates.active : InputStates.disabled}
            onPress={handleOtpSubmit}
          >
            Verify
          </Button>
        </View>
      </View>
    </Layout>
  );
};

export default EmailOtpVerificationScreen;
