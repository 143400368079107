import React from 'react';
import { View } from 'react-native';
import { Button, Text } from '../components/new';
import { State, Status } from '../themes/new/helper';
// import AsyncStorage from '@react-native-async-storage/async-storage';

interface NewErrorViewProps {
  errorMsg: string;
  reload: () => void;
}

const NewErrorView = (props: NewErrorViewProps) => {
  const { errorMsg, reload } = props;

  const handelReload = () => {
    if (__DEV__) {
      // AsyncStorage.clear();
    }
    reload();
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignSelf: 'center' }}>
      {errorMsg && <Text textAlign="center">{errorMsg}</Text>}
      <Button
        size="md"
        status={Status.PRIMARY}
        state={State.ACTIVE}
        mt="lg"
        onPress={handelReload}
      >
        Retry
      </Button>
    </View>
  );
};

export default NewErrorView;
