import React from 'react';
import { StyleSheet, View } from 'react-native';
import { getTheme } from '../themes/new/theme';

type OwnProps = {
  totalDot: number;
  dotColor?: string;
  totalColorDot: number;
  paginationDotSize: any; // TODO: propTypes.instanceOf(Object)
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DotComponent.defaultProps;

const DotComponent = (props: Props) => {
  const { totalDot, dotColor, totalColorDot, paginationDotSize } = props;
  const dot = [];
  for (let i = 1; i <= totalDot; i += 1) {
    dot.push(i);
  }

  return (
    <>
      {dot.map((item) => (
        <View
          key={Math.random()}
          style={[
            paginationDotSize,
            styles.paginationDots,
            {
              borderRadius: paginationDotSize.width / 2,
              opacity: 1,
              backgroundColor:
                item <= totalColorDot
                  ? dotColor
                  : getTheme().colors.primary[50],
            },
          ]}
        />
      ))}
    </>
  );
};

export default DotComponent;

DotComponent.defaultProps = {
  dotColor: null,
};

const styles = StyleSheet.create({
  paginationDots: {
    marginRight: 3,
    marginVertical: 2,
  },
});
