/**
 * @generated SignedSource<<316d2cf079f0414eecaf7f04af5d1fba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Enumrestaurants2Amenities = "Bar_Area" | "Buffet" | "Catering" | "Charging_Point" | "Coat_Check" | "Dance_Floor" | "Delivery" | "Event_Space" | "Family_Play_Area" | "Fine_Dining" | "Karaoke" | "Kids_Menu" | "Late_Night_Dining" | "Live_Cooking_Stations" | "Live_Sports_Screening" | "Meeting_Rooms" | "On_Site_Parking" | "Outdoor_Barbecue" | "Outdoor_Coolers" | "Outdoor_Heaters" | "Outdoor_Seating" | "Patio" | "Photobooth" | "Private_Dining" | "Pure_Veg" | "Reservations" | "Rooftop_Dining" | "Serves_Alcohol" | "Smoking_Area" | "Takeout" | "Valet_Parking" | "Wheelchair_Accessible" | "Wifi" | "%future added value";
export type Enumrestaurants2DescriptionCusinesName = "american" | "asian" | "bakery" | "bar" | "bengali" | "beverages" | "bihari" | "bubble_tea" | "cafe" | "chinese" | "coastal_cuisine" | "coffee" | "continental" | "desserts" | "european" | "fastfood" | "french" | "goan" | "greek" | "healthy" | "himalayan" | "ice_cream" | "indian" | "italian" | "japanese" | "kashmiri" | "korean" | "lebanese" | "malaysian" | "mediterranean" | "mexican" | "momos" | "mughlai" | "north_eastern" | "north_indian" | "pizza" | "punjabi" | "rajasthani" | "rolls" | "russian" | "salad" | "seafood" | "shake" | "south_indian" | "sushi" | "thai" | "turkish" | "vietnamese" | "waffle" | "%future added value";
export type searchRestaurantsFilterInput = {
  _id?: any | null;
  campus: FilterFindManyrestaurants2CampusInput;
  search?: searchRestaurantsFilterInputSearch | null;
};
export type FilterFindManyrestaurants2CampusInput = {
  _id: any;
};
export type searchRestaurantsFilterInputSearch = {
  restaurantTitle?: string | null;
};
export type newSearchScreenQuery$variables = {
  filter: searchRestaurantsFilterInput;
};
export type newSearchScreenQuery$data = {
  readonly searchRestaurants2: ReadonlyArray<{
    readonly _id: any;
    readonly amenities: ReadonlyArray<Enumrestaurants2Amenities | null> | null;
    readonly description: {
      readonly cusines: ReadonlyArray<{
        readonly name: Enumrestaurants2DescriptionCusinesName | null;
        readonly primary: boolean | null;
      } | null> | null;
      readonly tags: ReadonlyArray<{
        readonly name: string | null;
      } | null> | null;
    };
    readonly displayName: string;
    readonly logo: string | null;
    readonly menu: {
      readonly restaurantId: any | null;
    } | null;
    readonly overAllRating: number | null;
    readonly trending: boolean | null;
  }>;
};
export type newSearchScreenQuery = {
  response: newSearchScreenQuery$data;
  variables: newSearchScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "restaurants2",
    "kind": "LinkedField",
    "name": "searchRestaurants2",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amenities",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "overAllRating",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trending",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurants2Description",
        "kind": "LinkedField",
        "name": "description",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2DescriptionTags",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2DescriptionCusines",
            "kind": "LinkedField",
            "name": "cusines",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "primary",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Menu",
        "kind": "LinkedField",
        "name": "menu",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "restaurantId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "newSearchScreenQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "newSearchScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cdc01a5158f3e6bb71628d277feaf088",
    "id": null,
    "metadata": {},
    "name": "newSearchScreenQuery",
    "operationKind": "query",
    "text": "query newSearchScreenQuery(\n  $filter: searchRestaurantsFilterInput!\n) {\n  searchRestaurants2(filter: $filter) {\n    _id\n    displayName\n    amenities\n    overAllRating\n    logo\n    trending\n    description {\n      tags {\n        name\n      }\n      cusines {\n        name\n        primary\n      }\n    }\n    menu {\n      restaurantId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "55ad70d86e180eded36fcbf22f960d52";

export default node;
