import React, { useEffect, useState } from 'react';
import { useFragment } from 'react-relay';
import { View } from '../../../../../../components/new';
import SectionDivider from '../../../components/SectionDivider';
import SavingsListItem from './SavingsListItem';
import { getUserPaymentStatsFragment } from '../API/blackMembershipProfileQuery';
import {
  blackMembershipProfileQueryPaymentStatsFragment$key as StatsRef,
  blackMembershipProfileQueryPaymentStatsFragment$data as StatsData,
} from '../API/__generated__/blackMembershipProfileQueryPaymentStatsFragment.graphql';
import { generateSavingsListData } from '../helpers';
import { toRupees } from '../../../../../../utilities/helper';
import {
  isProperNumber,
  normalizeToNumberOrZero,
} from '../../../../../../utilities/Utility';

interface BlackMembershipSavingsSectionProps {
  statsRef: StatsRef;
  eventDiscount: number;
}

const BlackMembershipSavingsSection = ({
  statsRef,
  eventDiscount,
}: BlackMembershipSavingsSectionProps) => {
  const [listData, setListData] = useState([]);
  const paymentStats: StatsData = useFragment(
    getUserPaymentStatsFragment,
    statsRef,
  );

  useEffect(() => {
    const list = generateSavingsListData(paymentStats, eventDiscount);
    setListData(list);
  }, [paymentStats, eventDiscount]);

  const paymentStatsFixed = normalizeToNumberOrZero(paymentStats?.saved);

  return (
    <View px="2xl">
      <SectionDivider label={`Saved ${toRupees(paymentStatsFixed)}`} />
      {listData.map(({ title, icon, info, subTitle }) => {
        return (
          <View key={title} mt="2xl">
            <SavingsListItem
              title={title}
              subTitle={subTitle}
              icon={icon}
              info={info}
            />
          </View>
        );
      })}
    </View>
  );
};

export default BlackMembershipSavingsSection;
