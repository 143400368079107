/**
 * @generated SignedSource<<54f3897b146536d59a50d5f3d57d1131>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cashierMetricsOverviewFilter = {
  endTime: string;
  startTime: string;
};
export type CashierOverviewScreenQuery$variables = {
  filter: cashierMetricsOverviewFilter;
};
export type CashierOverviewScreenQuery$data = {
  readonly getCashierTaskMetrics: {
    readonly " $fragmentSpreads": FragmentRefs<"CashierOverviewScreenTaskMetricsFragment">;
  } | null;
};
export type CashierOverviewScreenQuery = {
  response: CashierOverviewScreenQuery$data;
  variables: CashierOverviewScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CashierOverviewScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "cashierTaskMetricsTC",
        "kind": "LinkedField",
        "name": "getCashierTaskMetrics",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CashierOverviewScreenTaskMetricsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CashierOverviewScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "cashierTaskMetricsTC",
        "kind": "LinkedField",
        "name": "getCashierTaskMetrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ParkingCashierTC",
            "kind": "LinkedField",
            "name": "parkings",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingVehicle",
                "kind": "LinkedField",
                "name": "vehicle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingVehicleRegistration",
                    "kind": "LinkedField",
                    "name": "registration",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "plate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingInvoice",
                "kind": "LinkedField",
                "name": "parkingInvoice",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingParkingInvoicePaymentDetails",
                    "kind": "LinkedField",
                    "name": "paymentDetails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPaid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "focReason",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingParkingInvoiceCharges",
                    "kind": "LinkedField",
                    "name": "charges",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                        "kind": "LinkedField",
                        "name": "chargesBreakup",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payable",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discountAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalPayable",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "exitTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vehicleIsWhitelisted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingJourney",
                "kind": "LinkedField",
                "name": "journey",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taskID",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "cashierOverviewDetails",
            "kind": "LinkedField",
            "name": "overview",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cash",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "online",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bb8ecc9467f64e6059331dcd293a788",
    "id": null,
    "metadata": {},
    "name": "CashierOverviewScreenQuery",
    "operationKind": "query",
    "text": "query CashierOverviewScreenQuery(\n  $filter: cashierMetricsOverviewFilter!\n) {\n  getCashierTaskMetrics(filter: $filter) {\n    ...CashierOverviewScreenTaskMetricsFragment\n  }\n}\n\nfragment CashierOverviewScreenTaskMetricsFragment on cashierTaskMetricsTC {\n  parkings {\n    _id\n    vehicle {\n      registration {\n        plate\n      }\n    }\n    parkingInvoice {\n      paymentDetails {\n        isPaid\n        mode\n        focReason\n        amount\n      }\n      charges {\n        chargesBreakup {\n          _id\n          duration\n          payable\n        }\n        discountAmount\n        totalPayable\n      }\n    }\n    exitTime\n    vehicleIsWhitelisted\n    journey {\n      taskID\n    }\n  }\n  overview {\n    cash\n    online\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7d1997b54c5452fb628fdd4d61ca72a";

export default node;
