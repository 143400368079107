import React from 'react';
import { Image } from 'react-native';
import { Button, Text, View } from './new';

const LocatePhotographer = ({ onPress }) => {
  return (
    <View px="2xl">
      <View>
        <Image
          source={require('../../assets/images/32ndMoments-booth.webp')}
          resizeMode="contain"
          style={{ height: 200, width: 'auto' }}
        />
      </View>
      <View>
        <Text
          textAlign="center"
          mt="2xl"
          color="primary.400"
          size="xl"
          weight="medium"
        >
          Photographers available at the 32nd Moments booth
        </Text>
      </View>

      <Text mt="lg" color="primary.200" textAlign="center" size="sm">
        Visit our 32nd Moments booth opposite Starbucks, and simply ask our team
        for a photo
      </Text>

      <Button
        size="lg"
        status="primary"
        state="active"
        onPress={onPress}
        mt="7xl"
      >
        Got It
      </Button>
    </View>
  );
};

export default LocatePhotographer;
