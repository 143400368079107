import React, { useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { View, Text } from '../../../components/new';
import useUserStore from '../../../stores/userStore';
import { PaymentStatusEnum } from '../../../utilities/helper';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { themeModes } from '../../../themes/new/theme';
import CountDown from '../payment/CountDown';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const AfterSubmitScreen = ({ navigation, route }) => {
  const { infoText, subText, countDown, status, invoiceId } =
    route?.params ?? {};
  const { role } = useUserStore((state) => state);
  const theme = useTheme();

  const onComplete = () => {
    navigation.navigate('transactionScreen', {
      invoiceId,
      backToHome: true,
    });
  };

  useEffect(() => {
    firebaseEventLogger('afterSubmitScreen_landing', {
      invoiceId,
    });
  }, []);

  const LottieComponent = () => {
    return theme?.currentThemeMode === themeModes.dark ? (
      <CustomLottieView
        autoPlay
        loop
        style={{ height: 200, width: 200 }}
        source={
          status === PaymentStatusEnum.SUCCESSFUL
            ? require('../../../../assets/lottie/success-animation-dark.json')
            : require('../../../../assets/lottie/failed_animation.json')
        }
        containerWidth={200}
      />
    ) : (
      <CustomLottieView
        autoPlay
        loop
        style={{ height: 200, width: 200 }}
        source={
          status === PaymentStatusEnum.SUCCESSFUL
            ? require('../../../../assets/lottie/success_animation.json')
            : require('../../../../assets/lottie/failed_animation.json')
        }
        containerWidth={200}
      />
    );
  };

  return (
    <View flex={1} mx="2xl">
      <View flex={1} alignItems="center" justifyContent="center">
        <View style={{ height: 200, width: 200 }}>
          <LottieComponent />
        </View>
        <Text
          size="2xl"
          color="primary.400"
          weight="medium"
          mb="2xl"
          textAlign="center"
        >
          {infoText}
        </Text>
        <Text size="sm" color="primary.200" textAlign="center">
          {subText}
        </Text>
      </View>
      <View position="absolute" bottom="9xl" left={0} right={0}>
        <CountDown countDown={countDown} onComplete={onComplete} />
      </View>
    </View>
  );
};
export default AfterSubmitScreen;
