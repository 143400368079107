/**
 * @generated SignedSource<<7b546cefd241f3ac20e3ea5fe844c00f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumpopUpStoreDescriptionTagsIconColor = "error" | "info" | "success" | "%future added value";
export type findPopUpStoresFilterInput = {
  _id?: any | null;
  campus: FilterFindManypopUpStoreCampusInput;
  description?: FilterFindManypopUpStoreDescriptionInput | null;
  displayName?: string | null;
  ownerId?: any | null;
};
export type FilterFindManypopUpStoreDescriptionInput = {
  tags?: ReadonlyArray<FilterFindManypopUpStoreDescriptionTagsInput | null> | null;
};
export type FilterFindManypopUpStoreDescriptionTagsInput = {
  _id?: any | null;
  icon?: FilterFindManypopUpStoreDescriptionTagsIconInput | null;
  name?: string | null;
};
export type FilterFindManypopUpStoreDescriptionTagsIconInput = {
  _id?: any | null;
  color?: EnumpopUpStoreDescriptionTagsIconColor | null;
  iconName?: string | null;
};
export type FilterFindManypopUpStoreCampusInput = {
  _id?: any | null;
};
export type partnerPaymentScreenQuery$variables = {
  filter?: findPopUpStoresFilterInput | null;
};
export type partnerPaymentScreenQuery$data = {
  readonly findPopUpStores: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
      };
    }>;
  } | null;
};
export type partnerPaymentScreenQuery = {
  response: partnerPaymentScreenQuery$data;
  variables: partnerPaymentScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "popUpStoreConnection",
    "kind": "LinkedField",
    "name": "findPopUpStores",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "popUpStoreEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "popUpStore",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "partnerPaymentScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "partnerPaymentScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "09062fb3c83451770cf9b7e275d7a0d7",
    "id": null,
    "metadata": {},
    "name": "partnerPaymentScreenQuery",
    "operationKind": "query",
    "text": "query partnerPaymentScreenQuery(\n  $filter: findPopUpStoresFilterInput\n) {\n  findPopUpStores(filter: $filter) {\n    edges {\n      node {\n        _id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8f0b7cc5abffd324930c4e742af0ee2";

export default node;
