/**
 * @generated SignedSource<<5f8b3ccf957a4e2b6d08f0fd769d1830>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DailyValetTaskBonusFilter = {
  date: string;
};
export type valetDailyBonusQuery$variables = {
  filter: DailyValetTaskBonusFilter;
};
export type valetDailyBonusQuery$data = {
  readonly dailyValetTaskBonus: {
    readonly bonus: {
      readonly bonusAmount: number | null;
      readonly delayedTaskCount: number | null;
      readonly onTimeTaskCount: number | null;
    } | null;
    readonly date: string | null;
  } | null;
};
export type valetDailyBonusQuery = {
  response: valetDailyBonusQuery$data;
  variables: valetDailyBonusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "DailyValetBonusOutput",
    "kind": "LinkedField",
    "name": "dailyValetTaskBonus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValetBonus",
        "kind": "LinkedField",
        "name": "bonus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onTimeTaskCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "delayedTaskCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bonusAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "valetDailyBonusQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "valetDailyBonusQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ce43f5feb9dac8277b83ec377bdb4788",
    "id": null,
    "metadata": {},
    "name": "valetDailyBonusQuery",
    "operationKind": "query",
    "text": "query valetDailyBonusQuery(\n  $filter: DailyValetTaskBonusFilter!\n) {\n  dailyValetTaskBonus(filter: $filter) {\n    date\n    bonus {\n      onTimeTaskCount\n      delayedTaskCount\n      bonusAmount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8689be3d81f36e80922fa90bfd343029";

export default node;
