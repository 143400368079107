import React, { Suspense, useState, useEffect } from 'react';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import useUserStore from '../../../stores/userStore';
import {
  navigateBack,
  PaymentStatusEnum,
  paymentOrderTypeEnum,
  ReturnJuspayPaymentStatus,
} from '../../../utilities/helper';
import { Layout, Text, TopNavigation, View } from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import Loading from '../../../components/loading.component';
import CountDown from './CountDown';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import usePaymentModalStore from '../../../stores/PaymentModalStore';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import AppConstants from '../../../utilities/AppConstants';
import { themeModes } from '../../../themes/new/theme';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import useLoginModalStore from '../../../stores/loginModalStore';
import useNotificationModalStore from '../../../stores/notificationModalStore';
import { showNotificationPopupLogic } from '../../../utilities/Utility';
import FacebookPixelLogger from '../../../utilities/FacebookPixelLogger';

const AfterPaymentScreen = ({ route }) => {
  const navigation = useNavigation<any>();
  const theme = useTheme();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { setPaymentSuccess } = usePaymentModalStore((state) => state);
  const { appliedCoupon, setAppliedCoupon } = useLoginModalStore(
    (state) => state,
  );
  const setInvestmentPaymentReceived = useUserStore(
    (state) => state.setInvestmentPaymentReceived,
  );
  const userRole = useUserStore((state) => state.role);
  const setNotificationUiText = useNotificationModalStore(
    (state) => state.setUiText,
  );

  const result =
    route?.params?.paymentStatus ||
    ReturnJuspayPaymentStatus(route?.params?.status);
  const {
    invoiceId,
    isTrackYourCar,
    parkingID,
    tipAmount,
    charityAmount,
    orderType,
    eventId,
  } = route?.params ?? {};

  useEffect(() => {
    if (result === PaymentStatusEnum.SUCCESSFUL) {
      FacebookPixelLogger('track', 'Purchase');
    }
  }, [result]);

  let infoText = '';
  let subText = '';
  let countDown = 3;
  let LottieComp = <></>;

  if (result === PaymentStatusEnum.SUCCESSFUL) {
    countDown = 2;
    infoText = 'Payment Successful';
    subText =
      'Please wait a moment while we redirect you to the confirmation page';
    LottieComp =
      theme?.currentThemeMode === themeModes.dark ? (
        <CustomLottieView
          autoPlay
          loop
          style={{ height: 200, width: 200 }}
          source={require('../../../../assets/lottie/success-animation-dark.json')}
          containerWidth={200}
        />
      ) : (
        <CustomLottieView
          autoPlay
          loop
          style={{ height: 200, width: 200 }}
          source={require('../../../../assets/lottie/success_animation.json')}
          containerWidth={200}
        />
      );
  } else if (result === PaymentStatusEnum.FAILED) {
    countDown = 2;
    infoText = 'Payment Failed';
    subText =
      'Oops, something went wrong. If any amount was deducted from your account, it will be automatically refunded within 7 business days.';
    LottieComp = (
      <CustomLottieView
        autoPlay
        loop
        source={require('../../../../assets/lottie/failed_animation.json')}
        style={{ height: 200, width: 200 }}
        containerWidth={200}
      />
    );
  } else {
    countDown = 60;

    infoText = 'Payment Pending';
    subText =
      'Your payment is being processed. Please do not close or refresh the page.';
    LottieComp = (
      <CustomLottieView
        autoPlay
        loop
        source={require('../../../../assets/lottie/pending_animation.json')}
        style={{ height: 200, width: 200 }}
        containerWidth={200}
      />
    );
  }

  const onComplete = () => {
    if (result === PaymentStatusEnum.SUCCESSFUL) {
      if (appliedCoupon) {
        setAppliedCoupon(null);
      }
      if (orderType === paymentOrderTypeEnum.INVESTMENT_LEAD) {
        navigation.pop(3);
        setInvestmentPaymentReceived(true);
        return;
      }
      if (isTrackYourCar) {
        setPaymentSuccess(true);
        navigateBack(navigation, userRole);
        return;
      }
      if (eventId) {
        setNotificationUiText(
          'Never Miss Out',
          'Enable notifications for timely reminders and important updates about your booked workshops',
          'Ask me later',
          'Allow',
        );
        showNotificationPopupLogic();
        navigation.navigate('eventTicket', {
          invoiceId,
          backToHome: true,
        });
      } else if (Platform.OS === 'web') {
        navigation.navigate('transactionsScreen', { backToHome: true });
      } else {
        navigation.navigate('transactionScreen', {
          invoiceId,
          tipAmount,
          charityAmount,
          parkingID,
          isTrackYourCar,
          paymentSuccessPage: true,
          orderType,
          backToHome: true,
        });
      }
    } else if (result === PaymentStatusEnum.FAILED) {
      if (Platform.OS === 'web') {
        if (eventId) {
          window.location.replace(
            `${AppConstants.WEB_URL}/eventsReservations?eventId=${eventId}`,
          );
        } else {
          window.location.replace(`${AppConstants.WEB_URL}`);
        }
      } else {
        navigateBack(navigation, userRole);
        dispatchSnackbar({
          msg: 'Payment failed! Please try again',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v1,
        });
      }
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: 'user' }],
      });
    }
  };

  return (
    <View flex={1} mt="9xl+9xl" mx="2xl">
      <View alignItems="center">
        <View style={{ height: 200, width: 200 }}>{LottieComp}</View>
        <Text
          size="2xl"
          color="primary.400"
          weight="medium"
          mb="2xl"
          textAlign="center"
        >
          {infoText}
        </Text>
        <Text size="sm" color="primary.200" textAlign="center">
          {subText}
        </Text>
      </View>
      <View position="absolute" bottom="9xl" left={0} right={0}>
        <CountDown countDown={countDown} onComplete={onComplete} />
      </View>
    </View>
  );
};
const AfterPaymentScreenWrapper = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const params = JSON.parse(JSON.stringify(route?.params || {}));
  const isFocused = useIsFocused();

  // ===================
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useEffect(() => {
    if (isFocused) {
      firebaseEventLogger('after__payment__screen', {
        ...params,
      });
    }
  }, [isFocused]);

  const variables = {};
  // ===================

  const backActionHandler = () => {
    navigateBack(navigation, userRole);
  };
  return (
    <Layout level={2}>
      <View mb="4xl">
        <TopNavigation title="" />
      </View>
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense
            fallback={
              <View mt="8xl">
                <Loading />
              </View>
            }
          >
            <View flex={1}>
              <View flex={1}>
                <AfterPaymentScreen
                  queryOptions={refreshedQueryOptions}
                  variables={variables}
                  route={route}
                  retry={refresh}
                  backActionHandler={backActionHandler}
                />
              </View>
            </View>
          </Suspense>
        </NewErrorBoundary>
      </View>
    </Layout>
  );
};

export default AfterPaymentScreenWrapper;
