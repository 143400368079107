import { useCallback } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { discountScreenQuery } from './API/discountApi';
import { discountApiDiscountScreenQuery$data } from './API/__generated__/discountApiDiscountScreenQuery.graphql';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { MealTimeEnum } from './helper';

/* eslint-disable-next-line import/prefer-default-export */
export const useDiscount = (
  queryOptions: NewErrorBoundaryParentState,
  refresh: () => void,
) => {
  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  const { getRestaurantpartnerDiscount }: discountApiDiscountScreenQuery$data =
    useLazyLoadQuery(
      discountScreenQuery,
      {
        filter: {
          isActive: true,
        },
      },
      {
        ...queryOptions,
        networkCacheConfig: { force: true },
      },
    );

  const lunchData = getRestaurantpartnerDiscount?.filter(
    (item) => item?.mealTime === MealTimeEnum.LUNCH && item?.isActive,
  );

  const dinnerData = getRestaurantpartnerDiscount?.filter(
    (item) => item?.mealTime === MealTimeEnum.DINNER && item?.isActive,
  );

  return {
    lunchData,
    dinnerData,
  };
};
