import React from 'react';
import { Card, Divider, Text, View } from '../../../components/new';

const EventDetailCard = ({ venue = '', date = '' }) => {
  return (
    <View>
      <Card>
        <Text color="primary.400" size="lg" weight="medium">
          Event Details
        </Text>
        <View my="lg">
          <Divider />
        </View>
        <Text color="primary.300" weight="medium">
          📆 {date}
        </Text>
        <View mt="lg">
          <Text color="primary.300" weight="medium">
            📍 Venue - {venue}
          </Text>
        </View>
      </Card>
    </View>
  );
};

export default EventDetailCard;
