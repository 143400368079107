/**
 * @generated SignedSource<<feed6481a626c88c15e191ae1ef4910d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SlotMachineRewards = "FREE_COFFEE" | "FREE_DIMSUM" | "FREE_PRINT" | "FreeBubbleTea" | "FreeFridgeMagnet" | "FreeKeyChain" | "FreeParking" | "FreePoints" | "FreePostCard" | "FreeSticker" | "%future added value";
export type getSlotMachineRewardMutation$variables = {};
export type getSlotMachineRewardMutation$data = {
  readonly addSpinReward: {
    readonly freezedTill: number | null;
    readonly numberCount: number | null;
    readonly randomNumbers: ReadonlyArray<string | null> | null;
    readonly rewardPointsGiven: number | null;
    readonly rewardTypeGiven: SlotMachineRewards | null;
    readonly spinsLeft: number | null;
  } | null;
};
export type getSlotMachineRewardMutation = {
  response: getSlotMachineRewardMutation$data;
  variables: getSlotMachineRewardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "spinSlotTC",
    "kind": "LinkedField",
    "name": "addSpinReward",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "randomNumbers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "spinsLeft",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "freezedTill",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rewardTypeGiven",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rewardPointsGiven",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getSlotMachineRewardMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getSlotMachineRewardMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "35e379e5916b89fa32ede0ea22f3eadc",
    "id": null,
    "metadata": {},
    "name": "getSlotMachineRewardMutation",
    "operationKind": "mutation",
    "text": "mutation getSlotMachineRewardMutation {\n  addSpinReward {\n    randomNumbers\n    numberCount\n    spinsLeft\n    freezedTill\n    rewardTypeGiven\n    rewardPointsGiven\n  }\n}\n"
  }
};
})();

(node as any).hash = "1958aaa024fc38ebf6493d3293db5060";

export default node;
