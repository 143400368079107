/* eslint-disable no-nested-ternary */
import React, {
  Suspense,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Dimensions,
  Image,
  ImageBackground,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Button,
  View,
  Text,
  Layout,
  TopNavigation,
  IconButton,
} from '../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import useUserStore, { UserRole } from '../../stores/userStore';
import {
  getFormatedDate,
  formatPlateNo,
  getHoursFromSeconds,
  navigateBack,
  ParkingStatus,
  PaymentTypes,
  taskHistoryActionTypes,
  DateFormatsEnum,
  paymentOrderTypeEnum,
  PaymentGatewayType,
  PaymentStatusEnum,
  dynamicHeightMaker,
} from '../../utilities/helper';
import BillSummary from '../../components/new/custom/BillSummary';
import useCommonStore from '../../stores/commonStore';
import { cashierPaymentScreenQuery } from './API/cashierPaymentScreenQuery';
import { updateParking } from '../../relay/parkingApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import { getTheme } from '../../themes/new/theme';
import { updateTaskForSupervisor } from '../../relay/taskApi';
import CollectCashBottomSheet from './CollectCashBottomsheet';
import ParkingDetailsBottomSheet from './ParkingDetailsBottomSheet';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import { initiatePaymentAPI } from '../new/payment/api/paymentAmountScreenQuery';
import cashierTransactionUpdatedSubscription from './API/cashierTransactionUpdatedSubscription';
import cashierParkingUpdatedSubscription from './API/cashierParkingUpdatedSubscription';
import AppQrCode from '../../components/new/custom/AppQrCode';

const CashierPaymentScreen = forwardRef((props, ref) => {
  const { variables, queryOptions, refresh } = props ?? {};
  const navigation = useNavigation();
  const { id: userId } = useUserStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const cashCollectionBottomsheetRef = useRef(null);
  const viewBillBottomsheetRef = useRef(null);
  const [generatedQrImage, setGeneratedQrImage] = useState(null);
  const [generateQrDisabled, setGenerateQrDisabled] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [approveExitDisabled, setApproveExitDisabled] = useState(false);
  const [showAppQrCode, setShowAppQrCode] = useState(false);

  const handleCashCollectPress = useCallback(() => {
    cashCollectionBottomsheetRef?.current?.show();
  }, []);

  const handleOnDismissCashCollection = useCallback(() => {
    cashCollectionBottomsheetRef?.current?.hide();
  }, []);

  const handleViewBillPress = useCallback(() => {
    viewBillBottomsheetRef?.current?.show();
  }, []);

  const handleOnDismissViewBill = useCallback(() => {
    viewBillBottomsheetRef?.current?.hide();
  }, []);

  useFocusEffect(
    useCallback(() => {
      setGeneratedQrImage(null);
      setInvoiceId(null);
      setShowAppQrCode(false);
    }, []),
  );

  useImperativeHandle(ref, () => ({
    handleViewBillPress,
  }));

  const { width } = useWindowDimensions();

  const { findTasks } = useLazyLoadQuery(cashierPaymentScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });

  const { parking, _id } = findTasks[0] ?? {};

  const { parkingInvoice } = parking ?? {};

  const campusID = useCommonStore((state) => state.campusId);

  // ------ vehicle entry time ------
  const entryTime = new Date(parkingInvoice?.timings?.entryTime);

  // ------ vehicle exit time ------
  const exitTime = new Date(parkingInvoice?.timings?.exitTime);

  // ------ summary of entry and exit time  ------
  const summaryData = [
    {
      id: 0,
      leftText: 'Entry Time',
      rightText: getFormatedDate(entryTime, DateFormatsEnum.TIME),
    },
    {
      id: 1,
      leftText: 'Exit Time',
      rightText: getFormatedDate(exitTime, DateFormatsEnum.TIME),
    },
  ];

  // ------ charge break up for parking ------
  const parkingData =
    parkingInvoice &&
    parkingInvoice?.charges &&
    parkingInvoice?.charges?.chargesBreakup
      ? parkingInvoice?.charges?.chargesBreakup?.map((item, index) => {
          return {
            id: index,
            leftText:
              index === 0
                ? `Parking Charge (${getHoursFromSeconds(item?.duration)})`
                : `Additional Hours (${getHoursFromSeconds(item?.duration)})`,
            rightText: `₹${item?.payable}`,
          };
        })
      : [];

  /* ------ push discount to parking summary charges breakup array
      if there is some discount
      */
  // if (
  //   parkingInvoice &&
  //   parkingInvoice?.charges &&
  //   parkingInvoice?.charges?.discountAmount
  // ) {
  //   parkingData.push({
  //     id: parkingInvoice?.charges?.chargesBreakup?.length,
  //     leftText: '32nd Club Discount',
  //     leftTextColor: 'success.500',
  //     rightText: `-₹${parkingInvoice?.charges?.discountAmount}`,
  //     rightTextColor: 'success.500',
  //   });
  // }

  if (
    parkingInvoice &&
    parkingInvoice?.paymentDetails &&
    parkingInvoice?.paymentDetails?.amount &&
    parkingInvoice?.paymentDetails?.amount > 0
  ) {
    parkingData.push({
      id: parkingInvoice?.charges?.chargesBreakup?.length,
      leftText: '32nd Cashier Discount',
      leftTextColor: 'success.500',
      rightText: `-₹${
        parkingInvoice?.charges?.totalPayable -
        parkingInvoice?.paymentDetails?.amount
      }`,
      rightTextColor: 'success.500',
    });
  }

  // ------ API error handler for online payments ------
  const updateApiErrorHandlerForOnlinePayment = (error) => {
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v2,
    });
  };

  /* ------ handleUpdateParkingForOnlinePayment if response then navigate
  to task completion with lottie screen ------
  */
  const handelUpdateParkingForOnlinePayment = async (response) => {
    if (response?.updateParking !== null) {
      navigation.navigate('CashierPaymentCollectionScreen', {
        text1: 'Payment Received',
        showAnimation: true,
      });
    }
  };

  const handleUpdateTaskForOnlinePayment = () => {
    const data = {
      id: parking?._id,
      record: {
        status: ParkingStatus.OUT,
      },
    };
    updateParking(
      data,
      handelUpdateParkingForOnlinePayment,
      updateApiErrorHandlerForOnlinePayment,
    );
  };

  const handleTransactionUpdatedSubscription = (res) => {
    // ============
    const transactionUpdated = res?.invoiceUpdated;
    if (transactionUpdated) {
      if (transactionUpdated?.status === PaymentStatusEnum.SUCCESSFUL) {
        firebaseEventLogger('cashierPaymentScreen__paymentSuccess_Screen', {
          buttonName: 'paymentSuccess',
          screenName: 'cashierPaymentScreen',
          userType: UserRole.PARTNER,
          interactionType: InteractionType.TAP,
          partnerId: userId,
          generatedQrId: _id,
          paymentStatus: transactionUpdated?.status,
          paymentType: transactionUpdated?.paymentType,
          amount: res?.invoiceUpdated?.priceDetails?.grossAmount,
        });
        const data = {};
        (data as any)._id = _id;
        (data as any).record = {};
        (data as any).record.history = [];
        /*
    update history
    if last action is arrived then update status as completed & exited both
    else update status as exited
    */
        (data as any).record.history = [
          { action: taskHistoryActionTypes.EXITED },
        ];
        updateTaskForSupervisor(
          data,
          handleUpdateTaskForOnlinePayment,
          updateApiErrorHandlerForOnlinePayment,
        );
      }
      if (transactionUpdated?.status === PaymentStatusEnum.FAILED) {
        firebaseEventLogger('cashierPaymentScreen__paymentFailed_Screen', {
          buttonName: 'paymentFailed',
          screenName: 'cashierPaymentScreen',
          userType: UserRole.PARTNER,
          interactionType: InteractionType.TAP,
          partnerId: userId,
          generatedQrId: _id,
          paymentStatus: transactionUpdated?.status,
          paymentType: transactionUpdated?.paymentType,
          amount: res?.invoiceUpdated?.priceDetails?.grossAmount,
        });
        navigation.navigate('CashierPaymentCollectionScreen', {
          text1: 'Payment Failed',
          showAnimation: true,
          status: PaymentStatusEnum.FAILED,
        });
      }
    }
  };

  const handleParkingUpdatedSubscription = (res) => {
    // ============
    const parkingUpdated = res?.parkingUpdated;
    if (parkingUpdated && parkingUpdated?.parkingInvoice) {
      if (parkingUpdated?.parkingInvoice?.paymentDetails?.isPaid) {
        firebaseEventLogger('cashierPaymentScreen__paymentSuccess_Screen', {
          buttonName: 'paymentSuccess',
          screenName: 'cashierPaymentScreen',
          userType: UserRole.PARTNER,
          interactionType: InteractionType.TAP,
          partnerId: userId,
          generatedQrId: _id,
          paid: parkingUpdated?.parkingInvoice?.paymentDetails?.isPaid,
          amount: parkingUpdated?.parkingInvoice?.paymentDetails?.amount,
        });
        const data = {};
        (data as any)._id = _id;
        (data as any).record = {};
        (data as any).record.history = [];
        /*
    update history 
    if last action is arrived then update status as completed & exited both 
    else update status as exited
    */
        (data as any).record.history = [
          { action: taskHistoryActionTypes.EXITED },
        ];
        updateTaskForSupervisor(
          data,
          handleUpdateTaskForOnlinePayment,
          updateApiErrorHandlerForOnlinePayment,
        );
      }
    }
  };

  const handleTransactionUpdationSubRef = useRef(
    handleTransactionUpdatedSubscription,
  );

  const handleParkingUpdationSubRef = useRef(handleParkingUpdatedSubscription);

  useEffect(() => {
    let cancelSubscriptionUpdateData = {
      dispose: () => {},
    };
    const cancelUpdateTaskSubscription = (disposable) => {
      cancelSubscriptionUpdateData = disposable;
    };
    if (invoiceId) {
      const transactionUpdatedReq = {
        invoiceId,
      };
      cashierTransactionUpdatedSubscription(
        transactionUpdatedReq,
        (data) => handleTransactionUpdationSubRef.current(data),
        cancelUpdateTaskSubscription,
      );
    }

    return () => {
      // Useful for cleanup functions
      cancelSubscriptionUpdateData.dispose();
    };
  }, [invoiceId]);

  useEffect(() => {
    let cancelSubscriptionUpdateData = {
      dispose: () => {},
    };
    const cancelUpdateTaskSubscription = (disposable) => {
      cancelSubscriptionUpdateData = disposable;
    };
    if (!invoiceId && invoiceId === null && showAppQrCode) {
      const parkingUpdatedDataReq = {
        parkingId: parking?._id,
        userId,
      };
      cashierParkingUpdatedSubscription(
        parkingUpdatedDataReq,
        (data) => handleParkingUpdationSubRef.current(data),
        cancelUpdateTaskSubscription,
      );
    }

    return () => {
      // Useful for cleanup functions
      cancelSubscriptionUpdateData.dispose();
    };
  }, [showAppQrCode]);

  // ------ Rendering Bill Summary Parking Charges if parkingData updated ------
  const RenderSummaryData = useCallback(() => {
    return (
      <BillSummary
        label="Bill Summary"
        summary={parkingData}
        summaryResult={parkingResult}
      />
    );
  }, [parkingData]);

  // ------ total charge time in HH:MM format ------
  const totalChargeTime = getHoursFromSeconds(
    parkingInvoice?.timings?.chargeTime,
  );

  /* ------ if there is some amount from paymentDetails (this is editable amount)
   then show amount
   else show totalPayable from charges (this is our estimated/original amount)
  */
  const finalPayableAmount =
    parkingInvoice &&
    parkingInvoice?.charges &&
    parkingInvoice?.charges?.cashierDiscountAmount
      ? parkingInvoice?.charges?.totalPayable -
        parkingInvoice?.charges?.cashierDiscountAmount
      : parkingInvoice?.charges?.totalPayable;

  // ------ total charge time ------
  const summaryResult = {
    id: 0,
    leftText: 'Charged Time',
    rightText: `${totalChargeTime}`,
  };

  /* ------ total amount to be payable if there is edited amount by
  cashier then show that else show totalPayable ------ */
  const parkingResult = {
    id: 0,
    leftText: 'You Pay',
    rightText: `₹${
      parkingInvoice &&
      parkingInvoice?.charges &&
      parkingInvoice?.charges?.cashierDiscountAmount
        ? parkingInvoice?.charges?.totalPayable -
          parkingInvoice?.charges?.cashierDiscountAmount
        : parkingInvoice?.charges?.totalPayable
    }`,
  };

  /* ------ handleUpdateParking if response then navigate
  to task completion with lottie screen ------
  */
  const handelUpdateParking = async (response) => {
    if (response?.updateParking !== null) {
      setApproveExitDisabled(false);
      handleOnDismissCashCollection();
      navigation.navigate('CashierPaymentCollectionScreen', {
        text1: 'Cash Collected',
        showAnimation: true,
      });
    }
  };

  // ------ handle on pressing GenerateQR ------
  const handleGenerateQr = () => {
    setGenerateQrDisabled(true);
    firebaseEventLogger('cashierPaymentScreen__generateQr_Screen', {
      buttonName: 'generateQr',
      screenName: 'cashierPaymentScreen',
      userType: UserRole.CASHIER,
      interactionType: InteractionType.TAP,
      cashierId: userId,
      amount: Number(finalPayableAmount),
      orderType: paymentOrderTypeEnum.PARKING,
      paymentType: PaymentGatewayType.PAYTMQR,
    });
    initiatePaymentAPI(
      {
        amount: Number(finalPayableAmount),
        orderType: paymentOrderTypeEnum.PARKING,
        orderId: parking?._id,
        useRewardPoints: false,
        paymentType: PaymentGatewayType.PAYTMQR,
      },
      (res) => {
        setGenerateQrDisabled(false);
        if (
          res &&
          res?.initiatePayment &&
          res?.initiatePayment?.paymentDetails
        ) {
          setGeneratedQrImage(
            res?.initiatePayment?.paymentDetails?.paytmQr?.qrImage,
          );
          setInvoiceId(res?.initiatePayment?._id);
        }
      },
      (err) => {
        setGenerateQrDisabled(false);
        dispatchSnackbar({
          msg: 'Something Went Wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          position: 'top',
          removeAfter: 4000,
        });
      },
    );
  };

  // ------ API error handler ------
  const updateApiErrorHandler = (error) => {
    setApproveExitDisabled(false);
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v2,
    });
  };

  // ------ update parking on hanldeUpdateTask ------
  const handleUpdateTask = () => {
    const focReasonObj = parkingInvoice?.paymentDetails?.focReason
      ? {
          focReason: parkingInvoice?.paymentDetails?.focReason,
          message: parkingInvoice?.paymentDetails?.message,
        }
      : {};
    const data = {
      id: parking?._id,
      record: {
        parkingInvoice: {
          paymentDetails: {
            isPaid: true,
            mode: PaymentTypes.CASH,
            amount: finalPayableAmount,
            ...focReasonObj,
          },
        },
        status: ParkingStatus.OUT,
      },
    };
    updateParking(data, handelUpdateParking, updateApiErrorHandler);
  };

  // ------ handle on pressing approve exit update task history ------
  const handleApproveExit = () => {
    setApproveExitDisabled(true);
    const data = {};
    (data as any)._id = _id;
    (data as any).record = {};
    (data as any).record.history = [];
    /*
    update history
    if last action is arrived then update status as completed & exited both
    else update status as exited
    */
    (data as any).record.history = [{ action: taskHistoryActionTypes.EXITED }];
    updateTaskForSupervisor(data, handleUpdateTask, updateApiErrorHandler);
  };

  const RenderViewBillContent = () => (
    <ScrollView>
      <View px="2xl" mb="4xl">
        <View mt="4xl">
          <BillSummary
            label="Parking Details"
            summary={summaryData}
            summaryResult={summaryResult}
          />
        </View>
        <View mt="4xl">
          <RenderSummaryData />
        </View>
      </View>
    </ScrollView>
  );

  return (
    <>
      <ScrollView>
        <View px="2xl">
          <View
            mt="2xl"
            borderRadius="md"
            bg="primary.10"
            shadow="lg"
            alignItems="center"
            jutifyContent="center"
            p="2xl"
          >
            <Text size="md" color="primary.300">
              Parking Fee
            </Text>
            {parkingInvoice?.charges?.cashierDiscountAmount ? (
              <Text
                size="md"
                color="primary.200"
                mt="lg"
                style={{
                  textDecorationLine: 'line-through',
                  textDecorationStyle: 'solid',
                }}
              >
                {`₹${parkingInvoice?.charges?.totalPayable}`}
              </Text>
            ) : (
              <></>
            )}
            <Text size="3xl" weight="medium" mt="lg" color="primary.400">
              {`₹${finalPayableAmount}`}
            </Text>
            <Button
              mt="lg"
              appearance="ghost"
              status="info"
              size="md"
              onPress={() =>
                navigation.navigate('EditAmountScreen', { taskID: _id })
              }
            >
              Edit Amount
            </Button>
            <View mt="lg" flexDirection="row" alignItems="center">
              <View flex={1} height={1} backgroundColor="primary.50" />
            </View>
            {generatedQrImage ? (
              <Image
                source={{
                  uri: `data:image/png;base64,${generatedQrImage}`,
                }}
                style={{
                  marginTop: getTheme().space['sm+md'],
                  width: width - 2 * getTheme().space['7xl'],
                  height: dynamicHeightMaker(
                    width - 2 * getTheme().space['7xl'],
                    1,
                  ),
                }}
                resizeMode="contain"
              />
            ) : showAppQrCode ? (
              <View mt="7xl">
                <AppQrCode
                  orderId={parking?._id}
                  orderType={paymentOrderTypeEnum.PARKING}
                  amount={finalPayableAmount}
                />
              </View>
            ) : (
              <ImageBackground
                source={require('../../../assets/images/staticQR.png')}
                style={{
                  width: Dimensions.get('screen').width * 0.84,
                  height: Dimensions.get('screen').width * 0.84,
                  marginTop: getTheme().space['sm+md'],
                }}
                resizeMode="contain"
              >
                <View
                  bg="opacity.80"
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <View width="100%">
                    <Button
                      size="lg"
                      state={
                        finalPayableAmount > 0 && !generateQrDisabled
                          ? 'active'
                          : 'disabled'
                      }
                      loading={generateQrDisabled}
                      status="primary"
                      appearance="filled"
                      onPress={handleGenerateQr}
                    >
                      <View flexDirection="row" alignItems="center">
                        <Text size="md" color="primary.10" mr="lg">
                          Pay using
                        </Text>
                        <Image
                          source={require('../../../assets/images/upi.png')}
                          style={{
                            width: 50,
                            height: 22,
                            resizeMode: 'contain',
                          }}
                        />
                      </View>
                    </Button>
                    <Button
                      size="lg"
                      state={finalPayableAmount > 0 ? 'active' : 'disabled'}
                      mt="2xl"
                      status="primary"
                      appearance="outline"
                      onPress={() => setShowAppQrCode(!showAppQrCode)}
                    >
                      32ND Club app
                    </Button>
                  </View>
                </View>
              </ImageBackground>
            )}
          </View>
          <View mt="9xl" flexDirection="row" alignItems="center">
            <View flex={1} height={1} backgroundColor="primary.100" />
            <View mx="2xl">
              <Text size="md" color="primary.100" textAlign="center">
                OR
              </Text>
            </View>
            <View flex={1} height={1} backgroundColor="primary.100" />
          </View>
          <View mt="9xl" mb="4xl">
            <Button
              size="lg"
              status="primary"
              state="active"
              appearance="outline"
              onPress={handleCashCollectPress}
            >
              Collect Cash
            </Button>
          </View>
        </View>
      </ScrollView>
      <CollectCashBottomSheet
        bottomSheetModalRef={cashCollectionBottomsheetRef}
        handleOnDismiss={handleOnDismissCashCollection}
        handleApprove={handleApproveExit}
        amount={finalPayableAmount}
        approveExitDisabled={approveExitDisabled}
      />
      <ParkingDetailsBottomSheet
        bottomSheetModalRef={viewBillBottomsheetRef}
        renderContent={RenderViewBillContent()}
      />
    </>
  );
});

const CashierPaymentScreenWrapper = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const campusID = useCommonStore((state) => state.campusId);
  const { taskID, vehicleNumber } = route?.params ?? {};
  const viewBillFuncRef = useRef(null);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const taskFilter = {
    campus: { _id: campusID },
    _id: taskID,
  };

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  useFocusEffect(
    useCallback(() => {
      refresh();
      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
        title={formatPlateNo(vehicleNumber)}
        IconRight={
          <Button
            size="md"
            appearance="ghost"
            state="active"
            status="info"
            onPress={() => {
              viewBillFuncRef?.current?.handleViewBillPress();
            }}
          >
            View Bill
          </Button>
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <CashierPaymentScreen
            variables={{
              taskFilter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
            ref={viewBillFuncRef}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default CashierPaymentScreenWrapper;
