/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Button, Card, Icon, Text, View } from '../../../components/new';
import { space } from '../../../themes/new/theme';
import { getCouponImg } from '../../../utilities/helper';
import { Appearance, State, Status } from '../../../themes/new/helper';
import CachedImage from '../../../components/new/custom/CachedImage';

const CouponCard = ({ openDetails, data, handleCoupons }) => {
  const { width } = useWindowDimensions();
  const couponType = data?.couponBenefit?.type ?? '';

  return (
    <Card
      minHeight={data?.finePrint?.details?.[0] ? 128 : 76}
      style={{
        marginBottom: space['2xl'],
        marginLeft: space['2xl'],
        top: space.xs,
      }}
      width={width - 32}
    >
      <View flex={1}>
        <View
          flexDirection="row"
          alignItems="flex-start"
          borderBottomWidth={data?.finePrint?.details?.[0] ? 1 : 0}
          borderColor="grey.50"
          pb="2xl"
        >
          <CachedImage
            source={getCouponImg(couponType)}
            style={{ height: 24, width: 24, resizeMode: 'contain' }}
          />
          <View ml="lg" flex={0.75}>
            <Text
              size="sm"
              color="primary.400"
              weight="medium"
              pb="sm"
              numberOfLines={1}
            >
              {data?.finePrint?.summary}
            </Text>
            <Text size="xs" color="primary.100" numberOfLines={1}>
              {data?.briefText} "{data?.couponCode}"
            </Text>
          </View>
          <View alignItems="flex-end" flex={0.25}>
            <Button
              style={{
                width: 70,
              }}
              state="active"
              status="info"
              appearance="ghost"
              size="md"
              onPress={handleCoupons}
            >
              Apply
            </Button>
          </View>
        </View>
        {data?.finePrint?.details?.[0] && (
          <View width="100%">
            <Button
              state={State.ACTIVE}
              status={Status.PRIMARY}
              appearance={Appearance.GHOST}
              size="md"
              onPress={openDetails}
            >
              <View flexDirection="row" alignItems="center">
                <Text size="md" color="primary.300" mr="sm">
                  View Details
                </Text>
                <Icon
                  name="forward-outline-300"
                  size="xs"
                  color="primary.300"
                />
              </View>
            </Button>
          </View>
        )}
      </View>
    </Card>
  );
};

export default CouponCard;
