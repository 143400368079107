import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useEffect, useRef } from 'react';
import { ScrollView } from 'react-native';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import {
  Avatar,
  Pressable,
  TopNavigation,
  View,
} from '../../../../components/new';
import useUserStore from '../../../../stores/userStore';
import InvestProperties from './InvestProperties';
import EventBus, { EventBusEventsEnum } from '../../../../utilities/EventBus';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import useAuthStore from '../../../../stores/authStore';
import AppConstants from '../../../../utilities/AppConstants';
import { unwrapPagedData } from '../../../../utilities/paginationUtilities';
import {
  InvestmentPageQuery,
  InvestmentPageQueryInvestmentPropertiesFragment,
} from '../API/InvestmentPageQuery';
import EmptyListView from '../../../restaurantPartner/components/EmptyListView';

const PROPERTY_PAGE_SIZE = 2;

const InvestmentPage = ({ queryOptions }) => {
  const navigation = useNavigation();
  const {
    profileImage,
    firstName,
    lastName,
    checkConsentGiven,
    investmentVideoPlayed,
    blackMembership,
    setInvestmentVideoPlayed,
  } = useUserStore((state) => state);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { active: isBlackMember } = blackMembership;

  const { IMAGEKIT_PROD_URI } = AppConstants;
  const imageLink = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';

  const scrollViewRef = useRef(null);

  const variables = {
    count: PROPERTY_PAGE_SIZE,
    includeFindConsents: isLoggedIn,
  };

  const { ...investmentPageRef } = useLazyLoadQuery(
    InvestmentPageQuery,
    variables,
    queryOptions,
  );

  /* ========== INVESTMENT LISTING VIA PAGINATION ========== */
  const {
    data: InvestmentPropertiesNodes,
    loadNext: fetchMoreInvestmentProperties,
    hasNext: hasMoreInvestmentProperties,
  } = usePaginationFragment(
    InvestmentPageQueryInvestmentPropertiesFragment,
    investmentPageRef,
  );

  const investmentVideoHeader =
    investmentPageRef?.findInvestmentListingComponent;

  const consentGiven =
    investmentPageRef?.findInvestmentUserConsents?.status === 'GIVEN';

  // extracting node out of edges
  const investmentProperties = unwrapPagedData(
    InvestmentPropertiesNodes?.findInvestmentProperties?.edges,
  );

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        if (investmentVideoHeader?.cover?.mediaURL && !investmentVideoPlayed) {
          setInvestmentVideoPlayed(true);
        }
      };
    }, []),
  );

  useEffect(() => {
    EventBus.getInstance().on(EventBusEventsEnum.SCROLL_TO_TOP_TAB_3, () => {
      scrollViewRef?.current?.scrollTo({ x: 0, y: 0, animated: true });
    });
  }, []);

  return (
    <View flex={1}>
      <TopNavigation
        title="32nd Investments"
        appearance="ghost"
        level="1"
        IconRight={
          <View flexDirection="row" alignItems="center">
            <Pressable
              onPress={() => {
                navigation.navigate('account');
              }}
              hitSlop={{
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              }}
            >
              <View
                mr="lg"
                borderWidth="sm"
                borderColor={isBlackMember ? 'static.gold' : 'transparent'}
                borderRadius="full"
              >
                <Avatar
                  level={1}
                  source={imageLink}
                  resizeMode="cover"
                  name={`${firstName ?? ''} ${lastName ?? ''}`}
                />
              </View>
            </Pressable>
          </View>
        }
      />
      {investmentProperties?.length ? (
        <ScrollView showsVerticalScrollIndicator={false} ref={scrollViewRef}>
          <InvestProperties
            investmentVideoPlayed={investmentVideoPlayed}
            investmentVideoHeader={investmentVideoHeader}
            propertiesData={investmentProperties}
            PROPERTY_PAGE_SIZE={PROPERTY_PAGE_SIZE}
            fetchMoreInvestmentProperties={fetchMoreInvestmentProperties}
            hasMoreInvestmentProperties={hasMoreInvestmentProperties}
            isConsentGiven={consentGiven || checkConsentGiven}
            openPropertyDetails={(propertyId) => {
              navigation.navigate('PropertyDetails', {
                propertyId,
              });
              firebaseEventLogger('investmentPage__viewDetails_Tap', {
                buttonName: 'viewDetails',
                screenName: 'investmentPage',
                userType: 'user',
                interactionType: 'tap',
                isLoggedIn,
                propertyId,
              });
            }}
          />
        </ScrollView>
      ) : (
        <View flex={1} bg="static.black" justifyContent="center" pb="9xl">
          <EmptyListView
            label="No property found!"
            msg="Please recheck after some time"
          />
        </View>
      )}
    </View>
  );
};

export default InvestmentPage;
