/**
 * @generated SignedSource<<1f0fe57374be59b0eb22fd2f5e94d839>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumwallettransactionType = "CREDIT" | "DEBIT" | "%future added value";
export type TransactionSourceType = "ADDONS" | "CAMPUS_VISIT" | "COUPON_POINTS" | "EVENT" | "EVENT_PROMOTION" | "FEEDBACK_GIVEN" | "FIRST_LOGIN" | "FIRST_TXN" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "OTHER" | "PARKING" | "PARK_DELAY" | "POINTS_EXPIRY" | "POPUP_ORDER" | "POPUP_STORE" | "REFUND" | "RESTAURANT" | "SLOT_SPIN" | "STORE" | "SUBSEQUENT_TXN" | "%future added value";
export type TransactionType = "CREDIT" | "DEBIT" | "%future added value";
export type WalletHistorySortEnum = "ASC" | "DESC" | "%future added value";
export type rewardHistoryFilterTC = {
  type?: TransactionType | null;
};
export type RewardPointHistoryScreenQuery$variables = {
  filter?: rewardHistoryFilterTC | null;
  sort?: WalletHistorySortEnum | null;
  userId: any;
};
export type RewardPointHistoryScreenQuery$data = {
  readonly findUserById: {
    readonly _id: any;
    readonly wallet: {
      readonly currentBalance: number | null;
      readonly totalBalance: number | null;
    } | null;
  } | null;
  readonly getWalletHistory: ReadonlyArray<{
    readonly amount: number;
    readonly created_at: any | null;
    readonly source: {
      readonly name: string | null;
      readonly type: TransactionSourceType | null;
    } | null;
    readonly type: EnumwallettransactionType;
    readonly userId: any;
  } | null> | null;
};
export type RewardPointHistoryScreenQuery = {
  response: RewardPointHistoryScreenQuery$data;
  variables: RewardPointHistoryScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "findUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserWallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBalance",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "walletTransactionReservedTC",
    "kind": "LinkedField",
    "name": "getWalletHistory",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sourceDetailsTC",
        "kind": "LinkedField",
        "name": "source",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RewardPointHistoryScreenQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RewardPointHistoryScreenQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b1000337077f0943c9102319e3dd2803",
    "id": null,
    "metadata": {},
    "name": "RewardPointHistoryScreenQuery",
    "operationKind": "query",
    "text": "query RewardPointHistoryScreenQuery(\n  $userId: MongoID!\n  $filter: rewardHistoryFilterTC\n  $sort: WalletHistorySortEnum\n) {\n  findUserById(_id: $userId) {\n    _id\n    wallet {\n      totalBalance\n      currentBalance\n    }\n  }\n  getWalletHistory(filter: $filter, sort: $sort) {\n    userId\n    type\n    amount\n    created_at\n    source {\n      type\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd4c25e2239226882b62964b54b3d4de";

export default node;
