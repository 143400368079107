/**
 * @generated SignedSource<<e53105f10b79fa2264de2eacd6687248>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumParkingParkingInvoicePaymentDetailsFocReason = "CUSTOMER_ISSUE" | "EMPLOYEE_EXIT" | "FREE_PARKING_TICKET" | "MISSED_EXIT" | "OTHER" | "VALET_EXIT" | "VIP_EXIT" | "%future added value";
export type EnumParkingParkingInvoicePaymentDetailsMode = "APP" | "CASH" | "FASTAG" | "FOC" | "ONLINE" | "%future added value";
export type EnumParkingStatus = "FAULTY_PARK" | "FAULTY_RECALL" | "INCOMING" | "MISSED_EXIT" | "OUT" | "OUTGOING" | "PARKED" | "%future added value";
export type updateParkingRecordInput = {
  feedback?: UpdateByIdParkingFeedbackInput | null;
  parked_at?: any | null;
  parkingInvoice?: UpdateByIdParkingParkingInvoiceInput | null;
  parkingLocation?: UpdateByIdParkingParkingLocationInput | null;
  parkingTag?: string | null;
  status?: EnumParkingStatus | null;
  trakingParkingIds?: ReadonlyArray<string | null> | null;
};
export type UpdateByIdParkingFeedbackInput = {
  _id?: any | null;
  description?: string | null;
  feedbackFor?: ReadonlyArray<string | null> | null;
  rating: number;
};
export type UpdateByIdParkingParkingLocationInput = {
  _id?: any | null;
  locationId: any;
  parkingSpaces?: UpdateByIdParkingParkingLocationParkingSpacesInput | null;
};
export type UpdateByIdParkingParkingLocationParkingSpacesInput = {
  floor?: UpdateByIdParkingParkingLocationParkingSpacesFloorInput | null;
};
export type UpdateByIdParkingParkingLocationParkingSpacesFloorInput = {
  _id?: any | null;
  bay?: UpdateByIdParkingParkingLocationParkingSpacesFloorBayInput | null;
  floorId: any;
};
export type UpdateByIdParkingParkingLocationParkingSpacesFloorBayInput = {
  _id?: any | null;
  bayId: any;
};
export type UpdateByIdParkingParkingInvoiceInput = {
  paymentDetails?: UpdateByIdParkingParkingInvoicePaymentDetailsInput | null;
};
export type UpdateByIdParkingParkingInvoicePaymentDetailsInput = {
  _id?: any | null;
  amount?: number | null;
  focReason?: EnumParkingParkingInvoicePaymentDetailsFocReason | null;
  isPaid?: boolean | null;
  message?: string | null;
  mode?: EnumParkingParkingInvoicePaymentDetailsMode | null;
};
export type parkingApiUpdateMutation$variables = {
  id: any;
  record: updateParkingRecordInput;
};
export type parkingApiUpdateMutation$data = {
  readonly updateParking: {
    readonly record: {
      readonly journey: ReadonlyArray<{
        readonly taskID: any;
      } | null> | null;
    } | null;
    readonly recordId: any | null;
  } | null;
};
export type parkingApiUpdateMutation = {
  response: parkingApiUpdateMutation$data;
  variables: parkingApiUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdParkingPayload",
    "kind": "LinkedField",
    "name": "updateParking",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recordId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Parking",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ParkingJourney",
            "kind": "LinkedField",
            "name": "journey",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taskID",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "parkingApiUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "parkingApiUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2a24cf5f1c4048fbb075e4b92aba0c5f",
    "id": null,
    "metadata": {},
    "name": "parkingApiUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation parkingApiUpdateMutation(\n  $id: MongoID!\n  $record: updateParkingRecordInput!\n) {\n  updateParking(_id: $id, record: $record) {\n    recordId\n    record {\n      journey {\n        taskID\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa6eafe9093ee4e68961753806ad5356";

export default node;
