import React, { useEffect } from 'react';
import { Layout, Text, View } from '../../../../components/new';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';
import { FontWeights } from '../../../../themes/new/helper';

const CancellationStatusScreen = ({ navigation, route }) => {
  const { invoiceId, isRefundable } = route?.params ?? {};

  useEffect(() => {
    let timeout;
    if (invoiceId) {
      timeout = setTimeout(() => {
        navigation.navigate('eventTicket', {
          invoiceId,
          backToScreen: 'events',
        });
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [invoiceId]);

  return (
    <Layout>
      <View flex={1} alignItems="center" justifyContent="center">
        <View>
          <CustomLottieView
            source={require('../../../../../assets/lottie/ripple-tick-dark.json')}
            autoPlay
            loop
            style={{ width: 200, height: 200 }}
            containerWidth={200}
          />
        </View>
        <View width={327} alignItems="center" justifyContent="center">
          <Text
            mb="lg"
            weight={FontWeights.MEDIUM}
            size="2xl"
            color="primary.500"
            textAlign="center"
          >
            Booking Cancelled!
          </Text>
          {isRefundable && (
            <Text textAlign="center" size="md" color="primary.200">
              Your refund amount will be credited in your account within 3-7
              working days
            </Text>
          )}
        </View>
      </View>
    </Layout>
  );
};

export default CancellationStatusScreen;
