import { useLazyLoadQuery } from 'react-relay';
import searchParkingScreenQuery from './API/searchParkingScreenQuery';

const useSearchParkingScreenData = ({ refreshedQueryOptions, variables }) => {
  const apiData = useLazyLoadQuery(
    searchParkingScreenQuery,
    variables,
    refreshedQueryOptions,
  );
  return { apiData };
};

export default useSearchParkingScreenData;
