import * as React from 'react';
import {
  RadioGroup,
  View,
  Text,
  Icon,
  Pressable,
  Divider,
  Button,
} from '../../../../components/new';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';

const getTaskTypes = (checkedItem) => {
  const parking = {
    checked: false,
    id: 0,
    label: 'park',
    name: 'Parking',
  };
  const recall = {
    checked: false,
    id: 1,
    label: 'recall',
    name: 'Recall',
  };

  switch (checkedItem) {
    case 'park': {
      parking.checked = true;
      recall.checked = false;
      return [recall, parking];
    }
    case 'recall': {
      recall.checked = true;
      parking.checked = false;
      return [recall, parking];
    }
    default: {
      recall.checked = true;
      parking.checked = false;
      return [recall, parking];
    }
  }
};

const TaskTypeDropDown = ({ taskSelected, onSelect }) => {
  const bottomSheetModalRef = React.useRef(null);
  const [taskTypes, setTaskTypes] = React.useState(getTaskTypes(taskSelected));

  const handleTaskTypeSelection = React.useCallback(() => {
    const selected = taskTypes.filter((t) => t.checked)[0].label;
    onSelect(selected);
    bottomSheetModalRef.current?.hide();
  }, [taskTypes, taskSelected]);

  const handleOnDismiss = React.useCallback(() => {
    // reset radio data if apply button not clicked
    const updated = taskTypes.map((t) => ({
      ...t,
      checked: t.label === taskSelected,
    }));
    setTaskTypes(updated);
  }, [taskSelected, taskTypes]);

  const handlePresentModalPress = React.useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);

  return (
    <View>
      <Pressable
        onPress={handlePresentModalPress}
        hitSlop={{ top: 8, left: 8, right: 8, bottom: 8 }}
      >
        <View flexDirection="row" alignItems="center">
          <Text size="md" color="primary.400">
            {taskSelected === 'park' ? 'Parking' : 'Recall'}
          </Text>
          <View ml="lg">
            <Icon size="2xl" color="primary.400" name="expand-outline-300" />
          </View>
        </View>
      </Pressable>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Select Task Type
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            <RadioGroup
              radio={taskTypes}
              setRadio={(arr) => {
                setTaskTypes(arr);
              }}
              size="lg"
              icon="right"
              collapsedCount={5}
            />
          </View>
          <View px="2xl" mt="xl">
            <Button
              size="lg"
              appearance="filled"
              onPress={handleTaskTypeSelection}
            >
              Apply
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default TaskTypeDropDown;
