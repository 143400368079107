/**
 * @generated SignedSource<<1a82097ba051179533fc677607f998a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type updateTaskRecordInput = {
  assignedTo?: any | null;
  cancelledReason?: string | null;
  history?: ReadonlyArray<UpdateByIdtaskHistoryInput | null> | null;
  ownerId?: any | null;
  parkingId?: any | null;
  taskEndLocation?: UpdateByIdtaskTaskEndLocationInput | null;
  vehicleId?: any | null;
};
export type UpdateByIdtaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type UpdateByIdtaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
  parkingSpaces?: UpdateByIdtaskTaskEndLocationParkingSpacesInput | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesInput = {
  floor?: UpdateByIdtaskTaskEndLocationParkingSpacesFloorInput | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesFloorInput = {
  _id?: any | null;
  bay?: UpdateByIdtaskTaskEndLocationParkingSpacesFloorBayInput | null;
  floorId?: any | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesFloorBayInput = {
  _id?: any | null;
  bayId?: any | null;
};
export type updateTaskMutation$variables = {
  _id: any;
  record: updateTaskRecordInput;
};
export type updateTaskMutation$data = {
  readonly updateTask: {
    readonly record: {
      readonly _id: any;
    } | null;
  } | null;
};
export type updateTaskMutation = {
  response: updateTaskMutation$data;
  variables: updateTaskMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "_id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdtaskPayload",
    "kind": "LinkedField",
    "name": "updateTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTaskMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTaskMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "84db10434821d5ed2e7d268b09b0308c",
    "id": null,
    "metadata": {},
    "name": "updateTaskMutation",
    "operationKind": "mutation",
    "text": "mutation updateTaskMutation(\n  $_id: MongoID!\n  $record: updateTaskRecordInput!\n) {\n  updateTask(_id: $_id, record: $record) {\n    record {\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d925ca47a2d304e80851f39cf7c9aae7";

export default node;
