import React from 'react';
import PropTypes from 'prop-types';
import { Animated, Easing } from 'react-native';
import CircularProgress from './CircularProgress';

const AnimatedProgress = Animated.createAnimatedComponent(CircularProgress);

export default class AnimatedCircularProgress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fillAnimation: new Animated.Value(props.prefill),
    };
    if (props.onFillChange) {
      const { fillAnimation } = this.state;
      fillAnimation.addListener(({ value }) => props.onFillChange(value));
    }
  }

  componentDidMount() {
    this.animate();
  }

  componentDidUpdate(prevProps) {
    const { fill } = this.props;
    if (prevProps.fill !== fill) {
      this.animate();
    }
  }

  // reAnimate(prefill, toVal, dur, ease) {
  //   this.setState(
  //     {
  //       fillAnimation: new Animated.Value(prefill),
  //     },
  //     () => this.animate(toVal, dur, ease),
  //   );
  // }

  animate(toVal, dur, ease) {
    const {
      fill,
      duration,
      easing,
      onAnimationComplete,
      useNativeDriver,
      delay,
    } = this.props;
    const toValue = toVal >= 0 ? toVal : fill;
    const durationVal = dur || duration;
    const easingVal = ease || easing;

    const { fillAnimation } = this.state;

    const anim = Animated.timing(fillAnimation, {
      useNativeDriver,
      toValue,
      easing: easingVal,
      duration: durationVal,
      delay,
    });
    anim.start(onAnimationComplete);

    return anim;
  }

  animateColor() {
    const { tintColorSecondary, tintColor } = this.props;
    const { fillAnimation } = this.state;
    if (!tintColorSecondary) {
      return tintColor;
    }

    const tintAnimation = fillAnimation.interpolate({
      inputRange: [0, 100],
      outputRange: [tintColor, tintColorSecondary],
    });

    return tintAnimation;
  }

  render() {
    const { fill, prefill, ...other } = this.props;
    const { fillAnimation } = this.state;

    return (
      <AnimatedProgress
        {...other}
        fill={fillAnimation}
        tintColor={this.animateColor()}
      />
    );
  }
}

AnimatedCircularProgress.propTypes = {
  ...CircularProgress.propTypes,
  prefill: PropTypes.number,
  duration: PropTypes.number,
  easing: PropTypes.func,
  onAnimationComplete: PropTypes.func,
  useNativeDriver: PropTypes.bool,
  delay: PropTypes.number,
};

AnimatedCircularProgress.defaultProps = {
  duration: 500,
  easing: Easing.out(Easing.ease),
  prefill: 0,
  useNativeDriver: false,
  delay: 0,
};
