/* eslint-disable no-nested-ternary */
import React from 'react';
import { variant, color, flexbox, position, space } from 'styled-system';
import styled from 'styled-components/native';
import { get } from 'lodash';
import StyledIcon from '../Icon/Icon';
import Pressable from '../../basic/Pressable/Pressable';
import StyledText from '../Typography/Text/Text';
import { space as spaces } from '../../../../themes/new/theme';
import {
  Appearance,
  Shape,
  State,
  ThemeVariables,
} from '../../../../themes/new/helper';
import { TextCategories } from '../Typography/Text/helper';

const Icon = styled(StyledIcon).attrs(({ appearance, state, iconColor }) => ({
  color:
    state === State.DISABLED
      ? appearance === Appearance.FILLED
        ? 'primary.10'
        : 'primary.100'
      : iconColor && iconColor !== ''
      ? iconColor
      : appearance === Appearance.FILLED
      ? 'primary.10'
      : 'primary.500',
}))``;

const Text = styled(StyledText)`
  ${({ appearance, state, iconColor, theme }) => ({
    color:
      state === State.DISABLED
        ? appearance === Appearance.FILLED
          ? theme.colors.primary[10]
          : theme.colors.primary[100]
        : iconColor
        ? get(theme.colors, iconColor)
        : appearance === Appearance.FILLED
        ? theme.colors.primary[10]
        : theme.colors.primary[500],
    paddingTop: theme.space.xs,
  })}
`;

const sizeVariants = ({ theme, ...props }) =>
  variant({
    prop: ThemeVariables.SIZE,
    variants: {
      sm: {
        padding: () => {
          switch (props.iconSize) {
            case 'md':
              return theme.space.lg;
              break;
            case 'xl':
              return theme.space.md;
              break;
            case '2xl':
              return theme.space.sm;
              break;
            default:
              return theme.space.lg;
          }
        },
      },
      md: {
        padding: () => {
          switch (props.iconSize) {
            case 'md':
              return theme.space.xl;
              break;
            case 'xl':
              return theme.space['sm+md'];
              break;
            case '2xl':
              return theme.space.lg;
              break;
            default:
              return theme.space.lg;
          }
        },
      },
      lg: {
        padding: () => {
          switch (props.iconSize) {
            case 'md':
              return theme.space['1xl'];
              break;
            case 'xl':
              return theme.space.xl;
              break;
            case '2xl':
              return theme.space['sm+md'];
              break;
            default:
              return theme.space['sm+md'];
          }
        },
      },
      xl: {
        padding: () => {
          switch (props.iconSize) {
            case 'md':
              return theme.space.xl;
              break;
            case 'xl':
              return theme.space['1xl'];
              break;
            case '2xl':
              return theme.space['2xl'];
              break;
            default:
              return theme.space.xl;
          }
        },
      },
      '2xl': {
        padding: () => {
          switch (props.iconSize) {
            case 'md':
              return theme.space['4xl'];
              break;
            case 'xl':
              return theme.space['3xl'];
              break;
            case '2xl':
              return theme.space['1xl'];
              break;
            case '6xl':
              return theme.space['2xl'];
              break;
            default:
              return theme.space['1xl'];
          }
        },
      },
      '3xl': {
        width: theme.sizes['4xl+6xs'],
        height: theme.sizes['4xl+6xs'],
      },
    },
  });

const appearanceVariants = ({ theme, bg, withoutBorder, ...props }) =>
  variant({
    prop: ThemeVariables.APPEARANCE,
    variants: {
      filled: {
        bg: bg || theme.colors.primary[500],
        borderWidth: withoutBorder ? 0 : theme.borderWidths.xs,
        borderColor:
          props.shape === Shape.ROUNDED &&
          typeof props.text === 'string' &&
          props.text.length !== 0
            ? theme.colors.primary[500]
            : 'transparent',
      },
      outline: {
        bg: 'transparent',
        borderWidth: withoutBorder ? 0 : theme.borderWidths.xs,
        borderColor: bg || theme.colors.primary[500],
      },
      ghost: {
        bg: 'transparent',
        borderWidth: withoutBorder ? 0 : theme.borderWidths.xs,
        borderColor: 'transparent',
      },
    },
  });

const stateVariants = ({ theme, appearance, bg, ...rest }) => {
  return variant({
    prop: ThemeVariables.STATE,
    variants: {
      active: {},
      pressed: {
        backgroundColor:
          appearance === Appearance.FILLED
            ? bg
              ? get(theme.colors, bg)
              : theme.colors.primary[400]
            : 'transparent',
        borderColor:
          appearance === Appearance.FILLED
            ? bg
              ? get(theme.colors, bg)
              : theme.colors.primary[400]
            : appearance === Appearance.OUTLINE
            ? bg
              ? get(theme.colors, bg)
              : theme.colors.primary[400]
            : 'transparent',
        opacity:
          (appearance === Appearance.FILLED && bg) ||
          appearance !== Appearance.FILLED
            ? 0.9
            : 1,
      },
      disabled: {
        backgroundColor:
          appearance !== Appearance.GHOST
            ? theme.colors.primary['50']
            : 'transparent',
        borderColor:
          appearance === Appearance.GHOST
            ? 'transparent'
            : theme.colors.primary['50'],
      },
    },
  });
};

const shapeVariants = ({ theme, iconSize, ...rest }) => {
  return variant({
    prop: ThemeVariables.SHAPE,
    variants: {
      rounded: {
        borderRadius: theme.radii.full,
      },
      square: {
        borderRadius: 0,
      },
      rectangle: {
        borderRadius: 0,
        paddingHorizontal: 0,
        width: theme.fontSizes[iconSize],
      },
    },
  });
};

const StyledButton = styled(Pressable).attrs(
  ({ appearance, bg, state, shadow, theme }) => ({
    pressStyle: {
      backgroundColor:
        appearance === Appearance.FILLED
          ? bg
            ? get(theme.colors, bg)
            : theme.colors.primary[400]
          : 'transparent',
      borderColor:
        appearance === Appearance.FILLED
          ? bg
            ? get(theme.colors, bg)
            : theme.colors.primary[400]
          : appearance === Appearance.OUTLINE
          ? bg
            ? get(theme.colors, bg)
            : theme.colors.primary[400]
          : 'transparent',
      opacity:
        (appearance === Appearance.FILLED && bg) ||
        appearance !== Appearance.FILLED
          ? 0.9
          : 1,
    },
    shadow:
      appearance === Appearance.GHOST || state === State.DISABLED
        ? 'none'
        : shadow || 'md',
  }),
)`
  ${color}
  ${flexbox}
  ${(props) => sizeVariants(props)}
  ${(props) => appearanceVariants(props)}
  ${(props) => shapeVariants(props)}
  ${(props) => stateVariants(props)}
  ${({ shadow, theme }) =>
    shadow && shadow !== 'none' ? theme.boxShadows[shadow] : ''}
  ${({ shadow, theme }) => ({
    'shadow-color':
      shadow && shadow !== 'none'
        ? theme.colors.singletone.black
        : 'transparent',
  })}
  ${position}
  ${space}
`;

const IconButton = ({
  name,
  size,
  appearance,
  shape,
  iconColor,
  bg,
  state,
  iconSize,
  text,
  ...rest
}) => {
  return (
    <StyledButton
      size={size}
      appearance={appearance}
      justifyContent="center"
      alignItems="center"
      shape={shape}
      state={state}
      bg={bg}
      // bg={state === 'disabled' ? 'primary.50' : bg}
      disabled={state === State.DISABLED}
      text={text}
      iconSize={iconSize}
      {...rest}
    >
      <Icon
        name={name}
        size={
          shape === Shape.ROUNDED &&
          typeof text === 'string' &&
          text.length !== 0 &&
          size === '3xl'
            ? '2xl'
            : iconSize
        }
        state={state}
        iconColor={iconColor}
        appearance={appearance}
      />
      {shape === Shape.ROUNDED &&
        typeof text === 'string' &&
        text.length !== 0 &&
        size === '3xl' && (
          <Text
            size="xs"
            category={TextCategories.TAG}
            numberOfLines={1}
            ellipsizemode="tail"
            appearance={appearance}
            iconColor={iconColor}
            state={state}
          >
            {text}
          </Text>
        )}
    </StyledButton>
  );
};

IconButton.defaultProps = {
  size: 'md',
  appearance: Appearance.FILLED,
  shadow: 'md',
  shape: Shape.ROUNDED,
  state: State.ACTIVE,
  text: '',
  iconSize: '2xl',
};

export default IconButton;
