/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import {
  View,
  Text,
  Layout,
  TopNavigation,
  IconButton,
} from '../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import useUserStore from '../../stores/userStore';
import {
  DateFormatsEnum,
  getFormatedDate,
  getHoursFromSeconds,
  navigateBack,
} from '../../utilities/helper';
import BillSummary from '../../components/new/custom/BillSummary';
import useCommonStore from '../../stores/commonStore';
import { cashierPaymentScreenQuery } from './API/cashierPaymentScreenQuery';

const CashierReceiptScreen = (props) => {
  const { variables, queryOptions } = props ?? {};

  const { findTasks } = useLazyLoadQuery(cashierPaymentScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });

  const { parking } = findTasks[0] ?? {};
  const { parkingInvoice } = parking ?? {};

  // ------ vehicle entry time ------
  const entryTime = new Date(parkingInvoice?.timings?.entryTime);

  // ------ vehicle exit time ------
  const exitTime = new Date(parkingInvoice?.timings?.exitTime);

  // ------ summary of entry and exit time  ------
  const summaryData = [
    {
      id: 0,
      leftText: 'Entry Time',
      rightText: getFormatedDate(entryTime, DateFormatsEnum.TIME),
    },
    {
      id: 1,
      leftText: 'Exit Time',
      rightText: getFormatedDate(exitTime, DateFormatsEnum.TIME),
    },
  ];

  // ------ charge break up for parking ------
  const parkingData =
    parkingInvoice &&
    parkingInvoice?.charges &&
    parkingInvoice?.charges?.chargesBreakup
      ? parkingInvoice?.charges?.chargesBreakup?.map((item, index) => {
          return {
            id: index,
            leftText:
              index === 0
                ? `Parking Charge (${getHoursFromSeconds(item?.duration)})`
                : `Additional Hours (${getHoursFromSeconds(item?.duration)})`,
            rightText: `₹${item?.payable}`,
          };
        })
      : [];

  /* ------ push discount to parking summary charges breakup array
      if there is some discount
      */
  if (
    parkingInvoice &&
    parkingInvoice?.charges &&
    parkingInvoice?.charges?.discountAmount
  ) {
    parkingData.push({
      id: parkingInvoice?.charges?.chargesBreakup?.length,
      leftText: '32nd Club Discount',
      leftTextColor: 'success.500',
      rightText: `-₹${parkingInvoice?.charges?.discountAmount}`,
      rightTextColor: 'success.500',
    });
  }

  if (
    parkingInvoice &&
    parkingInvoice?.paymentDetails &&
    parkingInvoice?.paymentDetails?.amount
  ) {
    parkingData.push({
      id: parkingInvoice?.charges?.chargesBreakup?.length,
      leftText: '32nd Cashier Discount',
      leftTextColor: 'success.500',
      rightText: `-₹${
        parkingInvoice?.charges?.totalPayable -
        parkingInvoice?.paymentDetails?.amount
      }`,
      rightTextColor: 'success.500',
    });
  }
  if (parkingInvoice.paymentDetails?.mode === 'FOC') {
    let leftText = 'FOC Discount';
    if (findTasks[0].isVehicleWhitelisted) {
      leftText = 'Whitelist Discount';
    }
    parkingData.push({
      id: parkingInvoice?.charges?.chargesBreakup?.length,
      leftText,
      leftTextColor: 'primary.300',
      rightText: `-₹${parkingInvoice?.charges?.totalPayable}`,
      rightTextColor: 'primary.300',
    });
  }

  // ------ Rendering Bill Summary Parking Charges if parkingData updated ------
  const RenderSummaryData = useCallback(() => {
    return (
      <BillSummary
        label="Bill Summary"
        summary={parkingData}
        summaryResult={parkingResult}
      />
    );
  }, [parkingData]);

  // ------ total charge time in HH:MM format ------
  const totalChargeTime = getHoursFromSeconds(
    parkingInvoice?.timings?.chargeTime,
  );

  /* ------ if there is some amount from paymentDetails (this is editable amount)
   then show amount
   else show totalPayable from charges (this is our estimated/original amount)
  */
  const finalPayableAmount = parkingInvoice?.paymentDetails?.amount
    ? parkingInvoice?.paymentDetails?.amount
    : parkingInvoice?.charges?.totalPayable;

  // ------ total charge time ------
  const summaryResult = {
    id: 0,
    leftText: 'Charged Time',
    rightText: `${totalChargeTime}`,
  };

  /* ------ total amount to be payable if there is edited amount by
  cashier then show that else show totalPayable ------ */
  const parkingResult = {
    id: 0,
    leftText: 'Paid',
    rightText: `₹${
      parkingInvoice &&
      parkingInvoice?.paymentDetails &&
      parkingInvoice?.paymentDetails?.amount !== null
        ? parkingInvoice?.paymentDetails?.amount
        : parkingInvoice?.charges?.totalPayable
    }`,
  };

  return (
    <>
      <ScrollView>
        <View px="2xl">
          <View
            mt="2xl"
            borderRadius="md"
            bg="primary.10"
            shadow="lg"
            alignItems="center"
            jutifyContent="center"
            p="2xl"
          >
            <Text size="md" color="primary.300">
              Bill Amount
            </Text>
            {parkingInvoice?.charges?.discountAmount ? (
              <Text
                size="md"
                color="primary.200"
                mt="lg"
                style={{
                  textDecorationLine: 'line-through',
                  textDecorationStyle: 'solid',
                }}
              >
                {`₹${
                  parkingInvoice?.charges?.discountAmount +
                  parkingInvoice?.charges?.totalPayable
                }`}
              </Text>
            ) : (
              <></>
            )}
            <Text size="3xl" weight="medium" mt="xs" color="primary.400">
              {parkingInvoice &&
              parkingInvoice?.paymentDetails &&
              parkingInvoice?.paymentDetails?.amount !== null
                ? `₹${parkingInvoice?.paymentDetails?.amount}`
                : `₹${parkingInvoice?.charges?.totalPayable}`}
            </Text>
          </View>
          <View mt="4xl">
            <BillSummary
              label="Parking Details"
              summary={summaryData}
              summaryResult={summaryResult}
            />
          </View>
          <View mt="4xl">
            <RenderSummaryData />
          </View>
        </View>
        <View height={200} />
      </ScrollView>
    </>
  );
};

const CashierReceiptScreenWrapper = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const campusID = useCommonStore((state) => state.campusId);
  const { taskID } = route?.params ?? {};

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const taskFilter = {
    campus: { _id: campusID },
    _id: taskID,
  };

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'network-only',
    }));
  }, []);

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
        title="Receipt"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <CashierReceiptScreen
            variables={{
              taskFilter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default CashierReceiptScreenWrapper;
