import { graphql } from 'react-relay';

const AddParkingDetailsScreenQuery = graphql`
  query AddParkingDetailsScreenQuery(
    $parkingFilter: findParkingsFilterInput!
    $count: Int!
  ) {
    findParking: findParkingV2(filter: $parkingFilter, first: $count) {
      edges {
        node {
          _id
          parkingLocation {
            locationId
            parkingSpaces {
              floor {
                floorId
                bay {
                  bayId
                  _id
                }
                _id
              }
            }
            _id
          }
        }
      }
    }
  }
`;

export default AddParkingDetailsScreenQuery;
