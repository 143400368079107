import React from 'react';
import { Image } from 'react-native';
import { View } from '../..';

const NoContentView = ({ src }: { src?: string }) => {
  const noTasksImage =
    src || require('../../../../../assets/images/no-car-found.webp');

  return (
    <View mt="46" justifyContent="center" alignItems="center">
      <Image source={noTasksImage} style={{ width: 242, height: 300 }} />
    </View>
  );
};

export default NoContentView;
