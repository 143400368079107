import React, { useCallback, useEffect, useRef, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ScrollView } from 'react-native-actions-sheet';
import { Dimensions } from 'react-native';
import { Button, Divider, RadioGroup, Text, View } from '../../components/new';
import useUserStore, { UserRole } from '../../stores/userStore';
import useAuthStore from '../../stores/authStore';
import refreshTokenAPI from '../../utilities/refreshToken';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { camelCaseToSeparatedWords } from '../../utilities/helper';
import BottomSheetV2 from '../../components/new/composites/BottomSheet/BottomSheetV2';

const radioOptionsGenerator = (l, role) => {
  return l
    .map((item, index) => {
      return {
        name: camelCaseToSeparatedWords(item),
        checked: index === 0,
        key: item,
      };
    })
    .filter((item) => item?.key !== role);
};

const SwitchRoleBottomSheet = ({ isOpen = false, navigation }) => {
  const userRole = useUserStore((state) => state.role);
  const setRole = useUserStore((state) => state.setRole);
  const primaryRole = useUserStore((state) => state.primaryRole);
  const secondaryRoles = useUserStore((state) => state.secondaryRole);
  // const setPrimaryRole = useUserStore((state) => state.setPrimaryRole);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  // =======
  const radioOptionsTemp = radioOptionsGenerator(
    [primaryRole, ...(secondaryRoles || [])],
    userRole,
  );

  if (radioOptionsTemp.length) {
    radioOptionsTemp[0].checked = true;
  }

  // =======
  const [radioOptions, setRadioOptions] = useState(radioOptionsTemp);

  const bottomSheetModalRef = useRef(null);

  const handlePresentModalPress = useCallback(() => {
    if (bottomSheetModalRef.current) {
      bottomSheetModalRef.current?.show();
    }
  }, []);

  const handleOnDismiss = useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  const selected = radioOptions.find((item) => item.checked);
  // eslint-disable-next-line consistent-return
  const handleChangeRole = async () => {
    //= ===========
    if (
      selected &&
      (userRole === UserRole.CONTROLLER ||
        userRole === UserRole.CASHIER ||
        userRole === UserRole.VALET ||
        userRole === UserRole.VALET_SUPERVISOR) &&
      selected?.key === userRole
    ) {
      dispatchSnackbar({
        msg: 'You are already an Employee',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
      return handleOnDismiss();
    }
    if (selected && userRole === UserRole.USER && selected?.key === userRole) {
      dispatchSnackbar({
        msg: 'You are already a User',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
      return handleOnDismiss();
    }

    //= ===========
    try {
      const data = {
        _id: useUserStore.getState().id || null,
        token: useAuthStore.getState().refreshToken,
        activeRole: selected?.key,
      };

      const tokenDetails = await refreshTokenAPI(data);

      if (tokenDetails && tokenDetails?.getRefreshToken) {
        const { token, refreshToken } = tokenDetails?.getRefreshToken ?? {};
        // set new access token and refresh token in loacal storage
        await AsyncStorage.multiSet([
          ['userToken', token],
          ['refreshToken', refreshToken],
        ]);
        // await AsyncStorage.setItem('userToken', token);
        // await AsyncStorage.setItem('refreshToken', refreshToken);
        await useAuthStore
          .getState()
          .setLoginDetails(true, token, refreshToken);
        try {
          navigation?.popToTop();
          setRole(selected.key);
          // navigation?.popToTop();
        } catch (err) {
          console.log(err);
        }

        return token;
      }
    } catch (err) {
      dispatchSnackbar({
        msg: 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
      // console.log('error can not refresh token', err);
    }
  };

  useEffect(() => {
    if (isOpen) {
      handlePresentModalPress();
    } else {
      handleOnDismiss();
    }
  }, [isOpen]);

  const selectedIndex = radioOptions.findIndex((item) => item.checked);

  let isButtonDisabled = selectedIndex === -1;
  if (
    (userRole === UserRole.CONTROLLER ||
      userRole === UserRole.CASHIER ||
      userRole === UserRole.VALET ||
      userRole === UserRole.VALET_SUPERVISOR) &&
    selected?.key === userRole
  ) {
    isButtonDisabled = true;
  }
  if (userRole === UserRole.USER && selected?.key === userRole) {
    isButtonDisabled = true;
  }

  return (
    <BottomSheetV2
      ref={bottomSheetModalRef}
      onClose={handleOnDismiss}
      insets={false}
    >
      <View>
        <View px="2xl">
          <Text size="xl" color="primary.200">
            Role
          </Text>
          <View py="2xl">
            <Divider />
          </View>
          <ScrollView
            style={{
              maxHeight: Dimensions.get('screen').height * 0.65,
            }}
          >
            <RadioGroup
              radio={radioOptions}
              setRadio={setRadioOptions}
              size="md"
              icon="right"
            />
          </ScrollView>
          <View pt="2xl">
            <Button
              onPress={handleChangeRole}
              state={isButtonDisabled ? 'disabled' : ''}
            >
              Change Role
            </Button>
          </View>
        </View>
      </View>
    </BottomSheetV2>
  );
};

export default SwitchRoleBottomSheet;
