import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Icon, Text, View } from '../components/new';
import Pressable from '../components/new/basic/Pressable/Pressable';
import useUserStore, { UserRole } from '../stores/userStore';

interface BottomTabRoutes {
  title: string;
  activeIcon: string;
  inactiveIcon: string;
  navigateTo: string;
}

const RoutesArr: BottomTabRoutes[] = [
  {
    title: 'Home',
    activeIcon: 'home-filled-300',
    inactiveIcon: 'home-outline-300',
    navigateTo: 'home',
  },
  {
    title: 'Collections',
    activeIcon: 'grid-filled-300',
    inactiveIcon: 'grid-outline-300',
    navigateTo: 'collections',
  },
  {
    title: 'Earnings',
    activeIcon: 'transaction-filled',
    inactiveIcon: 'transaction-outline',
    navigateTo: 'earnings',
  },
  {
    title: '32nd Club',
    activeIcon: 'wallet-filled-300',
    inactiveIcon: 'wallet-outline-300',
    navigateTo: 'club',
  },
  {
    title: 'Account',
    activeIcon: 'account-filled-300',
    inactiveIcon: 'account-outline-300',
    navigateTo: 'account',
  },
];

const RoutesArrForStaff: BottomTabRoutes[] = [
  {
    title: 'Home',
    activeIcon: 'home-filled-300',
    inactiveIcon: 'home-outline-300',
    navigateTo: 'home',
  },
  {
    title: '32nd Club',
    activeIcon: 'wallet-filled-300',
    inactiveIcon: 'wallet-outline-300',
    navigateTo: 'club',
  },
  {
    title: 'Account',
    activeIcon: 'account-filled-300',
    inactiveIcon: 'account-outline-300',
    navigateTo: 'account',
  },
];

const RestaurantPartnerTabBar = ({ navigation, state }) => {
  const selectedState = state.index;

  const { role } = useUserStore((data) => data);

  const RenderTabBar = ({ route, index }) => (
    <Pressable
      style={{ flex: 1, alignItems: 'center' }}
      key={route.title}
      onPress={() => {
        navigation.navigate(route.navigateTo);
      }}
    >
      <View p="xs">
        <View alignItems="center">
          <Icon
            size="2xl"
            shape="square"
            color={index === selectedState ? 'primary.500' : 'primary.100'}
            name={
              index === selectedState ? route.activeIcon : route.inactiveIcon
            }
          />
        </View>
        <Text
          color={index === selectedState ? 'primary.500' : 'primary.100'}
          size="2xs"
        >
          {route.title}
        </Text>
      </View>
    </Pressable>
  );

  return (
    <View
      bg="background.primary.base"
      borderTopColor="primary.50"
      borderTopWidth="xs"
    >
      <SafeAreaView edges={['bottom']}>
        <View
          flexDirection="row"
          justifyContent="space-between"
          px="2xl"
          py="sm+md"
        >
          {role === UserRole.RESTAURANT_PARTNER_STAFF
            ? RoutesArrForStaff.map((route, index) => {
                return <RenderTabBar route={route} index={index} />;
              })
            : RoutesArr.map((route, index) => {
                return <RenderTabBar route={route} index={index} />;
              })}
        </View>
      </SafeAreaView>
    </View>
  );
};

export default RestaurantPartnerTabBar;
