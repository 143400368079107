/* eslint-disable no-nested-ternary */
import React from 'react';
import { ScrollView, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Button, Text, View } from '../../../components/new';
import { BonusOverviewBG } from '../../../components/new/custom/bonus';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import LoyalCustomerList from './LoyalCustomerList';
import TopContent from './TopContent';
import { useLoyalty } from './useLoyalty';
import { updateRestaurantConfig } from '../API/restaurantPartnerApi';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import useRestaurantPartnerStore from '../../../stores/restaurantpartner/restaurantPartnerStore';

const NotClubMemberContent = [
  {
    id: 0,
    title: '2x loyal customers',
    description:
      'by offering 10% cashback as loyalty points on every bill payment',
  },
  {
    id: 1,
    title: '30% more revenue',
    description:
      'with increase in customer repeats to redeem their loyalty points',
  },
  {
    id: 2,
    title: '100% transparency',
    description:
      'as you get compensation, if more loyalty points are redeemed than given',
  },
];

const ValetOverviewScreenInner = ({
  queryOptions,
  refresh,
}: {
  queryOptions: NewErrorBoundaryParentState;
  refresh: () => void;
}) => {
  const { findUserById, getRestaurantPartnerUserRef } = useLoyalty(
    queryOptions,
    refresh,
  );
  const { _id } = findUserById ?? {};
  const navigation = useNavigation();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const { isLoyaltyEnabled, setLoyalty, loyalCustomerLength } =
    useRestaurantPartnerStore((state) => state);

  const NotLoyalPartnerItem = ({ id, title, description }) => {
    return (
      <View
        key={id}
        bg="primary.10"
        shadow="sm"
        borderRadius="md"
        borderWidth="xs"
        borderColor="primary.50"
        p="2xl"
        mt={id !== 0 ? '2xl' : '4xl'}
      >
        <View flexDirection="row" alignItems="center">
          <Image
            source={require('../../../../assets/images/discount_trophy.webp')}
            style={{ width: 40, height: 40, resizeMode: 'contain' }}
          />
          <View flex={1} ml="1xl">
            <Text size="lg" color="signletone.black">
              {title}
            </Text>
            <Text mt="sm" size="xs" color="primary.200" numberOfLines={2}>
              {description}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View flex={1}>
      <BonusOverviewBG>
        <TopContent
          isLoyalityEnabled={isLoyaltyEnabled}
          loyalCustomerCount={loyalCustomerLength}
        />
      </BonusOverviewBG>
      {isLoyaltyEnabled ? (
        <LoyalCustomerList
          getRestaurantPartnerUserRef={getRestaurantPartnerUserRef}
          refresh={refresh}
        />
      ) : (
        <ScrollView>
          <View mt="7xl" px="2xl">
            <Text size="md" color="primary.400" textAlign="center">
              Drive repeat customers by offering loyalty points which can be
              redeemed within a year
            </Text>
            {NotClubMemberContent.map((item) =>
              NotLoyalPartnerItem({
                id: item.id,
                title: item.title,
                description: item.description,
              }),
            )}
          </View>
          <Button
            size="xl"
            status="primary"
            appearance="filled"
            state="active"
            mt="2xl"
            mx="2xl"
            mb="7xl"
            onPress={() => {
              const data = {
                restaurantId: _id,
                loyalityPoints: true,
              };
              updateRestaurantConfig(
                data,
                (res, err) => {
                  if (
                    res &&
                    res?.updateRestaurantConfig &&
                    res?.updateRestaurantConfig?.pointsEnabled &&
                    res?.updateRestaurantConfig?.pointsEnabled?.reward &&
                    res?.updateRestaurantConfig?.pointsEnabled?.redeem
                  ) {
                    setLoyalty(
                      res?.updateRestaurantConfig?.pointsEnabled?.reward &&
                        res?.updateRestaurantConfig?.pointsEnabled?.redeem,
                    );
                    navigation.navigate('congratulationScreen');
                  }
                },
                (err) => {
                  console.log('err', err);
                  dispatchSnackbar({
                    msg:
                      err?.res?.errors[0]?.message || `Something Went Wrong!`,
                    status: SnackbarStatus.error,
                    version: SnackbarVersion.v2,
                  });
                },
              );
            }}
          >
            Join the 32nd Club
          </Button>
        </ScrollView>
      )}
    </View>
  );
};

export default ValetOverviewScreenInner;
