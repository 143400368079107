import * as React from 'react';
import { Button, IconButton, Modal, Text, View } from '../../components/new';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { PaymentStatusEnum } from '../../utilities/helper';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useUserStore, { UserRole } from '../../stores/userStore';
import useAuthStore from '../../stores/authStore';
import CustomLottieView from '../../components/new/custom/CustomLottieView';
import { updatePopupOrderAPI } from '../API/updatePopupOrder';

const CancelTransactionModel = ({
  item,
  modalVisible,
  setModalVisible,
  refresh,
}) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { id: userId, role } = useUserStore((state) => state);

  const onPressYes = () => {
    firebaseEventLogger(
      `${
        role === UserRole.PARTNER
          ? 'partnerHomescreen__cancel_Screen'
          : 'auditorHomeScreen__cancel_Screen'
      } `,
      {
        buttonName: 'cancel',
        screenName: `${
          role === UserRole.PARTNER ? 'partnerHomescreen' : 'auditorHomeScreen'
        }`,
        userType: role,
        interactionType: InteractionType.TAP,
        isLoggedIn,
        partnerId: userId,
        paymentId: item?._id,
        paymentType: item?.paymentType,
        paymentStatus: item?.status,
        orderAmount: item?.amount,
        deliveryStatus: item?.popUpStoreDetails?.deliveryStatus,
      },
    );
    const data = {
      _id: item?._id,
      record: {
        status: PaymentStatusEnum.FAILED,
      },
    };
    const handleUpdatePopUpInvoice = (response, error) => {
      setModalVisible(false);
      if (response) {
        dispatchSnackbar({
          msg: 'Transaction is cancelled!',
          status: SnackbarStatus.warning,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
        refresh();
      }
      if (error && error.length) {
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
        refresh();
      }
    };

    const updateApiErrorHandler = (error) => {
      setModalVisible(false);
      dispatchSnackbar({
        msg: 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
        removeAfter: 4000,
      });
    };
    updatePopupOrderAPI(data, handleUpdatePopUpInvoice, updateApiErrorHandler);
  };

  return (
    <Modal
      bg="blur"
      visible={modalVisible}
      onBackdropPress={() => {
        setModalVisible(false);
      }}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View bg="background.primary.base" p="4xl" mx="2xl" borderRadius="lg">
        <Text size="2xl" weight="medium" textAlign="center" color="primary.500">
          Are your sure you want to cancel this transaction?
        </Text>
        <View mt="4xl" alignSelf="center">
          <CustomLottieView
            style={{ width: 150, height: 120 }}
            containerWidth={150}
            autoPlay
            loop
            source={require('../../../assets/lottie/delete.json')}
          />
        </View>
        <Text size="sm" mt="xl" textAlign="center" color="primary.500">
          Deleting this item will remove it from your system permanently.
        </Text>
        <View flexDirection="row" alignItems="center" mt="4xl">
          <View flex={1}>
            <Button
              size="lg"
              appearance="outline"
              state="primary"
              onPress={() => {
                setModalVisible(false);
              }}
            >
              No
            </Button>
          </View>
          <View flex={1} ml="sm+md">
            <Button
              size="lg"
              appearance="filled"
              state="primary"
              onPress={onPressYes}
            >
              Yes
            </Button>
          </View>
        </View>
      </View>
      <View mt="2xl" width="90%" alignItems="center">
        <IconButton
          name="close-outline-300"
          size="xl"
          bg="primary.500"
          iconColor="primary.50"
          onPress={() => {
            setModalVisible(false);
          }}
        />
      </View>
    </Modal>
  );
};

export default CancelTransactionModel;
