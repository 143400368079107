import React, { useState } from 'react';
import {
  View,
  Text,
  Divider,
  Button,
  RadioGroup,
  Input,
} from '../../../components/new';
import { updateTaskForSupervisor } from '../../../relay/taskApi';
import useValetTaskStore from '../../../stores/valet/taskStore';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import useUserStore from '../../../stores/userStore';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { Appearance, State, Status } from '../../../themes/new/helper';

const tempData = [
  { name: 'Taxi', checked: true },
  { name: 'Driver Driven', checked: false },
  {
    name: 'Owner Parking',
    checked: false,
  },
  { name: 'Others', checked: false },
];

const CancelTaskModel = ({
  bottomSheetModalRef,
  handleOnDismiss,
  task,
  refresh,
}) => {
  const [cancelTaskReason, setCancelTaskReason] = useState(tempData);
  const [inputValue, setInputValue] = useState('');
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const userId = useUserStore((state) => state.id);
  const [cancelTaskDisabled, setCancelTaskDisabled] = useState(false);
  const { tasks, setTasks } = useValetTaskStore((state) => state);

  const removeItem = (id) => {
    const arr = tasks.filter((item) => item._id !== id);
    setTasks({ data: arr, userId });
  };

  const selectedtaskReasonIndex = cancelTaskReason?.findIndex(
    (item) => item.checked,
  );

  const handleTextChange = (i) => {
    setInputValue(i);
  };

  const handleCancelTask = () => {
    setCancelTaskDisabled(true);
    if (task) {
      let cancelledReason =
        cancelTaskReason &&
        cancelTaskReason?.filter((item) => item?.checked)[0]?.name;
      if (selectedtaskReasonIndex === 3) {
        cancelledReason = `${cancelledReason}:: ${inputValue}`;
      }
      const data = {
        _id: task?._id,
        record: {
          history: { action: 'cancelled' },
          cancelledReason,
        },
      };
      updateTaskForSupervisor(
        data,
        (response, error) => {
          // refresh();
          if (response) {
            dispatchSnackbar({
              msg: `Task Cancelled`,
              status: SnackbarStatus.warning,
              version: SnackbarVersion.v2,
            });
            setCancelTaskDisabled(false);
            removeItem(task?._id);
            refresh();
          }
        },
        (error) => {
          // console.log(error);
          dispatchSnackbar({
            msg: error?.res?.errors[0]?.message || 'Something went wrong!',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
          });
          setCancelTaskDisabled(false);
        },
      );
    }
  };
  return (
    <View>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Select reason for cancellation
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            <RadioGroup
              radio={tempData}
              setRadio={(arr) => setCancelTaskReason(arr)}
              title=""
              isRequired
              size="lg"
              icon="right"
              collapsedCount={5}
            />
          </View>
          {selectedtaskReasonIndex === 3 && (
            <View px="2xl" mb="2xl">
              <Input
                value={inputValue}
                onChangeText={handleTextChange}
                placeholder="Enter Reason..."
              />
            </View>
          )}
          <View px="2xl" mt="xl">
            <Button
              size="lg"
              appearance={Appearance.FILLED}
              status={Status.PRIMARY}
              state={cancelTaskDisabled ? State.DISABLED : State.ACTIVE}
              onPress={handleCancelTask}
              loading={cancelTaskDisabled}
            >
              Cancel Task
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default CancelTaskModel;
