import React, { useEffect, useRef } from 'react';
import { FlatList } from 'react-native';
import { Chip, View } from '../../../components/new';
import { RestaurantFilter, RestaurantsFilterNames } from './resturantsData';
import { Appearance, State } from '../../../themes/new/helper';
import EventBus, { EventBusEventsEnum } from '../../../utilities/EventBus';

interface RestaurantChipFilterProps {
  data: RestaurantFilter[];
  onPress: (name: RestaurantsFilterNames, filterKey: string) => void;
}

const RenderChip = (props: {
  active: boolean;
  name: RestaurantsFilterNames;
  itemsSelected: number | null;
  index: number;
  onPress: (name: RestaurantsFilterNames, filterKey: string) => void;
  filterKey: string;
}) => {
  const isMultiFilter = !(
    props.itemsSelected === null || props.itemsSelected === undefined
  );
  return (
    <Chip
      state={props.active ? State.SELECTED : State.ACTIVE}
      onPress={() => props.onPress(props.name, props.filterKey)}
      size="xs"
      iconLeft=""
      iconRight={isMultiFilter ? 'down-outline-300' : ''}
      appearance={Appearance.FILLED}
      ml={props.index === 0 ? '2xl' : 'lg'}
    >
      {!isMultiFilter
        ? `${props.name}`
        : props.itemsSelected > 0
        ? `${props.name} (${props.itemsSelected})`
        : `${props.name}`}
    </Chip>
  );
};

const RestaurantChipFilter = (props: RestaurantChipFilterProps) => {
  const flatListRef = useRef(null);

  const onPress = (name: RestaurantsFilterNames, filterKey: string) => {
    props.onPress(name, filterKey);
  };

  useEffect(() => {
    EventBus.getInstance().on(
      EventBusEventsEnum.RESTAURANT_PAGE_FILTER_SCROLL,
      () => {
        flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
      },
    );
  }, []);

  return (
    <View mt="2xl" mb="lg">
      <FlatList
        ref={flatListRef}
        keyExtractor={(item, index) => item.name + index}
        data={props.data}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={(item) => {
          return (
            <RenderChip
              active={item.item.active}
              name={item.item.name}
              itemsSelected={item.item.itemsSelected}
              index={item.index}
              filterKey={item.item.filterKey}
              onPress={onPress}
            />
          );
        }}
      />
    </View>
  );
};

export default RestaurantChipFilter;
