import React from 'react';
import { FlatList, Image } from 'react-native';
import { View, Text } from '../../../components/new';
import AutoBottomSheet from '../../../components/new/custom/AutoBottomSheet';
import { getTheme } from '../../../themes/new/theme';

const CorporateBottomSheet = ({ bottomSheetModalRef, data }) => {
  return (
    <AutoBottomSheet bottomSheetRef={bottomSheetModalRef}>
      <View>
        <View mt="xl">
          <Text
            size="xl"
            weight="medium"
            color="primary.400"
            textAlign="center"
          >
            Our corporate partners
          </Text>
          <View px="2xl" mb="2xl">
            <FlatList
              data={data}
              style={{ alignSelf: 'center' }}
              renderItem={({ item, index }) => (
                <View
                  ml={index % 4 !== 0 ? '2xl' : 0}
                  mt="2xl"
                  borderRadius="full"
                  borderWidth="xs"
                  borderColor="primary.50"
                >
                  <Image
                    source={{ uri: item?.image }}
                    style={{
                      width: 60,
                      height: 60,
                      resizeMode: 'contain',
                      borderRadius: getTheme().radii.full,
                      borderWidth: getTheme().borderWidths.xs,
                      borderColor: getTheme().colors.primary[50],
                    }}
                  />
                </View>
              )}
              // horizontal
              numColumns={4}
            />
          </View>
        </View>
      </View>
    </AutoBottomSheet>
  );
};

export default CorporateBottomSheet;
