/**
 * @generated SignedSource<<5da1e0f54f40eafb976e2a3b5a346cb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumTicketModelTCwoUserCanBeClaimedByRefType = "CAMPUS_LOCATION" | "RESTAURANT" | "%future added value";
export type EnumTicketModelTCwoUserIssuedByRefType = "CAMPUS_LOCATION" | "EVENT" | "POPUP_STORE" | "RESTAURANT" | "%future added value";
export type EnumticketDisplayType = "COUPON" | "QR" | "%future added value";
export type EnumticketStatus = "ACTIVE" | "CANCELLED" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumticketTicketType = "EVENT_TICKET" | "PRODUCT_TICKET" | "REWARD_TICKET" | "%future added value";
export type FilterFindManyticketInput = {
  AND?: ReadonlyArray<FilterFindManyticketInput> | null;
  OR?: ReadonlyArray<FilterFindManyticketInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyticketOperatorsInput | null;
  additionalFields?: FilterFindManyTicketModelTCwoUserAdditionalFieldsInput | null;
  canBeClaimedBy?: ReadonlyArray<FilterFindManyTicketModelTCwoUserCanBeClaimedByInput | null> | null;
  couponId?: any | null;
  coverImg?: string | null;
  displayType?: EnumticketDisplayType | null;
  expires_on?: any | null;
  finePrint?: FilterFindManyTicketModelTCwoUserFinePrintInput | null;
  invoiceId?: any | null;
  isPrimary?: boolean | null;
  issuedBy?: FilterFindManyTicketModelTCwoUserIssuedByInput | null;
  popUpImg?: string | null;
  rewardId?: any | null;
  status?: EnumticketStatus | null;
  thumbnailImg?: string | null;
  ticketType?: EnumticketTicketType | null;
  title?: string | null;
  uId?: number | null;
  updated_at?: any | null;
};
export type FilterFindManyTicketModelTCwoUserIssuedByInput = {
  _id?: any | null;
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserIssuedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserCanBeClaimedByInput = {
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserCanBeClaimedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsInput = {
  _id?: any | null;
  event?: FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput = {
  _id?: any | null;
  endTime?: any | null;
  invoicePdfPath?: string | null;
  spaces?: number | null;
  startTime?: any | null;
};
export type FilterFindManyTicketModelTCwoUserFinePrintInput = {
  _id?: any | null;
  details?: ReadonlyArray<string | null> | null;
  summary?: string | null;
};
export type FilterFindManyticketOperatorsInput = {
  _id?: FilterFindManyticket_idOperatorsInput | null;
};
export type FilterFindManyticket_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type RewardsScreenQuery$variables = {
  count: number;
  filter: FilterFindManyticketInput;
  userId: any;
};
export type RewardsScreenQuery$data = {
  readonly findUserById: {
    readonly _id: any;
    readonly wallet: {
      readonly currentBalance: number | null;
      readonly totalBalance: number | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RewardListTicketQueryFragment">;
};
export type RewardsScreenQuery = {
  response: RewardsScreenQuery$data;
  variables: RewardsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_id",
      "variableName": "userId"
    }
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "findUserById",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserWallet",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalBalance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentBalance",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RewardsScreenQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "RewardListTicketQueryFragment"
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RewardsScreenQuery",
    "selections": [
      {
        "alias": "tickets",
        "args": (v6/*: any*/),
        "concreteType": "ticketConnection",
        "kind": "LinkedField",
        "name": "findTickets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ticketEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ticket",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ticketType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coverImg",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnailImg",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expires_on",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserIssuedBy",
                    "kind": "LinkedField",
                    "name": "issuedBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "refType",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserAdditionalFields",
                    "kind": "LinkedField",
                    "name": "additionalFields",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketModelTCwoUserAdditionalFieldsEvent",
                        "kind": "LinkedField",
                        "name": "event",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserCanBeClaimedBy",
                    "kind": "LinkedField",
                    "name": "canBeClaimedBy",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketModelTCwoUserCanBeClaimedByVenue",
                        "kind": "LinkedField",
                        "name": "venue",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TicketModelTCwoUserCanBeClaimedByVenueGeo",
                            "kind": "LinkedField",
                            "name": "geo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lat",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "long",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addressLine1",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rewardId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "tickets",
        "args": (v6/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "RewardListTicketQueryFragment__tickets",
        "kind": "LinkedHandle",
        "name": "findTickets"
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "bf629b3c24eaea9d67dc00220eaab004",
    "id": null,
    "metadata": {},
    "name": "RewardsScreenQuery",
    "operationKind": "query",
    "text": "query RewardsScreenQuery(\n  $filter: FilterFindManyticketInput!\n  $userId: MongoID!\n  $count: Int!\n) {\n  ...RewardListTicketQueryFragment_26eJ3g\n  findUserById(_id: $userId) {\n    _id\n    wallet {\n      totalBalance\n      currentBalance\n    }\n  }\n}\n\nfragment RewardListTicketQueryFragment_26eJ3g on Query {\n  tickets: findTickets(filter: $filter, first: $count) {\n    edges {\n      node {\n        _id\n        ticketType\n        coverImg\n        thumbnailImg\n        uId\n        expires_on\n        title\n        issuedBy {\n          refType\n          _id\n        }\n        status\n        additionalFields {\n          event {\n            startTime\n            endTime\n          }\n        }\n        canBeClaimedBy {\n          venue {\n            geo {\n              lat\n              long\n            }\n            addressLine1\n          }\n        }\n        rewardId\n        updated_at\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b1ba1528ea266b4ae0a73994629ae58";

export default node;
