/* eslint-disable @typescript-eslint/ban-types */
export const throttleTime = 60; // s // waiting time
export const triesLimit = 5;

type initializeSlotMachineDataReturnType = {
  points: number;
  otherRewardsCount?: number;
  lastFreezeStart?: object;
};
const initializeSlotMachineData = (): initializeSlotMachineDataReturnType => {
  return { points: 0, otherRewardsCount: 0, lastFreezeStart: {} };
};

export type slotMachineSliceReturnType = {
  points: number;
  otherRewardsCount?: number;
  lastFreezeStart?: object;
  updateLastFreezeStart: Function;
  updateOtherRewardsCount: Function;
  updatePoints: Function;
  resetSlotMachineState: Function;
};

const slotMachineSlice = (
  set: (...a: any) => any,
): slotMachineSliceReturnType => {
  return {
    // data ====
    ...initializeSlotMachineData(),
    // data:end ===
    // methods ===
    updatePoints: ({ count }: { count: number }) => {
      set(() => {
        return { points: count };
      });
    },
    updateLastFreezeStart: () => {
      set(({ lastFreezeStart }) => {
        const temp = lastFreezeStart;
        temp.timeObj = new Date().getTime();
        return {
          lastFreezeStart: temp,
        };
      });
    },
    updateOtherRewardsCount: ({ count }: { count: number }) => {
      set(() => {
        return {
          otherRewardsCount: count,
        };
      });
    },
    // updateOtherRewards: ({ otherRewards = [] }) => {
    //   set(() => {
    //     return {
    //       otherRewards,
    //     };
    //   });
    // },
    resetSlotMachineState: () => {
      set(() => {
        return {
          ...initializeSlotMachineData(),
        };
      });
    },
    // methods:end ===
  };
};

export default slotMachineSlice;
