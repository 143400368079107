/**
 * @generated SignedSource<<208cee83649a2f4e5c1bd517b877ea43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PlaceOrderInput = {
  additionalNotes?: string | null;
  campusId: string;
  menuItems?: ReadonlyArray<PlaceOrderMenuItem | null> | null;
  restaurantId: string;
  tableNo: number;
};
export type PlaceOrderMenuItem = {
  addOns?: ReadonlyArray<PlaceOrderMenuItemAddon | null> | null;
  itemId: string;
  quantity: number;
  variations?: ReadonlyArray<PlaceOrderMenuItemVariation | null> | null;
};
export type PlaceOrderMenuItemVariation = {
  category: string;
  name: string;
};
export type PlaceOrderMenuItemAddon = {
  category: string;
  name: string;
  quantity: number;
};
export type orderApiMutation$variables = {
  record?: PlaceOrderInput | null;
};
export type orderApiMutation$data = {
  readonly placeOrder: {
    readonly orderId: string | null;
  } | null;
};
export type orderApiMutation = {
  response: orderApiMutation$data;
  variables: orderApiMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "PlaceOrderOutput",
    "kind": "LinkedField",
    "name": "placeOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "orderApiMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "orderApiMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cc0442eba71d455ddf530c6329d48f68",
    "id": null,
    "metadata": {},
    "name": "orderApiMutation",
    "operationKind": "mutation",
    "text": "mutation orderApiMutation(\n  $record: PlaceOrderInput\n) {\n  placeOrder(record: $record) {\n    orderId\n  }\n}\n"
  }
};
})();

(node as any).hash = "a286964741daca458d9b7794e3eaf3cb";

export default node;
