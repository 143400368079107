/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useRef, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import Reanimated from 'react-native-reanimated';
import {
  Face,
  FaceDetectionOptions,
} from 'react-native-vision-camera-face-detector';
import { View, Icon, Pressable, Text } from '../../components/new';
import { space } from '../../themes/new/theme';
import photoMoments from '../Strings';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { blobToBase64 } from '../../utilities/helper';

const RNFaceDetector =
  Platform.OS === 'web'
    ? {}
    : require('react-native-vision-camera-face-detector');

const RNWorklets =
  Platform.OS === 'web' ? {} : require('react-native-worklets-core');

const { Worklets } = RNWorklets;

const camType =
  Platform.OS === 'web'
    ? require('expo-camera')
    : require('react-native-vision-camera');

const { Camera, useCameraDevice, useFrameProcessor, useCameraFormat } = camType;

const { useFaceDetector } = RNFaceDetector;

const ReanimatedCamera = Reanimated.createAnimatedComponent(Camera);

const HybridCamscanner = ({
  imageUri = '',
  setImageUriCallback,
  takingBack = () => {},
}) => {
  const camera = useRef<Camera>(null);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [count, setCount] = useState(0);
  const [remainingTime, setRemainingTime] = useState(10);
  const [isFront, setIsFront] = useState(false);
  const [facedetected, setFacedetected] = useState(false);
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    if (__DEV__) return;
    startResendTimer();
  }, []);

  const startResendTimer = () => {
    setRemainingTime(15);
    const interval = setInterval(() => {
      setRemainingTime((val) => {
        if (val === 1) {
          takingBack();
          clearInterval(interval);
        }
        return val - 1;
      });
    }, 1000);
  };

  const takePicture = async () => {
    if (!facedetected) {
      dispatchSnackbar({
        msg: photoMoments.noFaceDetected,
        status: SnackbarStatus.error,
        version: SnackbarVersion.v1,
      });
      return;
    }
    if (camera && imageUri?.length === 0) {
      setCount(count + 1);
      const data = await camera.current.takePhoto({
        flash: flash && !isFront ? 'on' : 'off',
      });
      const imageResponse = await fetch(`file://${data?.path}`);
      const blobdata = await imageResponse.blob();
      const base64Data = await blobToBase64(blobdata);
      const imageBase64 = base64Data?.split('base64,');
      const imageData = {
        base64: imageBase64[1],
        uri: `file://${data?.path}`,
      };
      setImageUriCallback(imageData);
    }
  };

  const onFacesDetected = (faces) => {
    if (faces.length !== 0) {
      if (!facedetected) {
        setFacedetected(true);
      }
    } else if (facedetected) {
      setFacedetected(false);
    }
  };

  const faceDetectionOptions = useRef<FaceDetectionOptions>({
    classificationMode: 'all',
    performanceMode: 'accurate',
  }).current;

  const { detectFaces } = useFaceDetector(faceDetectionOptions);

  const handleDetectedFaces = Worklets.createRunOnJS((faces: Face[]) => {
    onFacesDetected(faces);
  });

  const frameProcessor = useFrameProcessor(
    (frame) => {
      'worklet';

      const faces = detectFaces(frame);
      handleDetectedFaces(faces);
    },
    [handleDetectedFaces],
  );

  const deviceFront = useCameraDevice('front');
  const deviceBack = useCameraDevice('back', {
    physicalDevices: [
      'ultra-wide-angle-camera',
      'wide-angle-camera',
      'telephoto-camera',
    ],
  });

  const format = useCameraFormat(isFront ? deviceFront : deviceBack, [
    { photoResolution: { width: 540, height: 540 } },
  ]);

  return (
    <>
      <ReanimatedCamera
        ref={camera}
        style={styles.mainCamera}
        device={isFront ? deviceFront : deviceBack}
        format={format}
        photo
        video
        isActive
        frameProcessor={frameProcessor}
      />
      <Pressable
        onPress={() => takePicture()}
        style={{
          flex: 1,
          position: 'absolute',
          bottom: space['9xl'],
          alignSelf: 'center',
        }}
      >
        <View
          height={space['8xl+8xl']}
          width={space['8xl+8xl']}
          borderRadius={space['5xl+6xl']}
          borderWidth="md"
          borderColor="primary.10"
          justifyContent="center"
          alignItems="center"
        >
          <View
            height={space['5xl+6xl']}
            width={space['5xl+6xl']}
            borderRadius="3xl"
            bg="primary.10"
            borderWidth="sm"
            borderColor="transparent"
          />
        </View>
      </Pressable>
      <Pressable
        onPress={() => {
          setIsFront(!isFront);
          setFlash(!flash);
        }}
        style={{
          flex: 1,
          position: 'absolute',
          bottom: space['9xl'],
          alignSelf: 'center',
          right: space['3xl'],
        }}
      >
        <View
          height={space['5xl+6xl']}
          width={space['5xl+6xl']}
          borderRadius="3xl"
          bg="primary.10"
          justifyContent="center"
          alignItems="center"
        >
          <Icon size="2xl" color="primary.400" name="rotate-400" />
        </View>
      </Pressable>
      {isFront ? null : (
        <Pressable
          onPress={() => setFlash(!flash)}
          style={{
            flex: 1,
            position: 'absolute',
            bottom: space['9xl'],
            alignSelf: 'center',
            left: space.xl,
          }}
        >
          <View
            height={space['5xl+6xl']}
            width={space['5xl+6xl']}
            borderRadius="3xl"
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              size="9xl"
              color="primary.10"
              name={flash ? 'flash-on-300' : 'flash-off-300'}
            />
          </View>
        </Pressable>
      )}
      {facedetected ? (
        <></>
      ) : (
        <View
          bg="opacity.20"
          position="absolute"
          bottom={172}
          px="xl"
          py="sm"
          borderRadius="xs"
          alignSelf="center"
        >
          <Text color="primary.10" size="sm">
            {photoMoments.noFace}
          </Text>
        </View>
      )}
    </>
  );
};
export default HybridCamscanner;

const styles = StyleSheet.create({
  mainCamera: {
    height: '100%',
  },
  lottieView: {
    top: 4,
    left: 2,
    position: 'absolute',
  },
  instructions: {
    fontSize: 20,
    textAlign: 'center',
    top: 28,
    fontWeight: 'bold',
    color: '#fff',
    // position: 'absolute',
  },
  action: {
    fontSize: 24,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});
