/**
 * @generated SignedSource<<623117814b66ff2b0efac1b0062dd70a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeScreenQuery_findTypeFormResponsesFragment$data = ReadonlyArray<{
  readonly eventId: string | null;
  readonly formId: string | null;
  readonly hidden: any | null;
  readonly landedAt: any | null;
  readonly submittedAt: any | null;
  readonly " $fragmentType": "homeScreenQuery_findTypeFormResponsesFragment";
}>;
export type homeScreenQuery_findTypeFormResponsesFragment$key = ReadonlyArray<{
  readonly " $data"?: homeScreenQuery_findTypeFormResponsesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeScreenQuery_findTypeFormResponsesFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "homeScreenQuery_findTypeFormResponsesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submittedAt",
      "storageKey": null
    }
  ],
  "type": "typeformResponse",
  "abstractKey": null
};

(node as any).hash = "b1ffb831935b2bd2d8182f52a2f9f43a";

export default node;
