import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const selectParkingLocationScreenQuery = graphql`
  query selectParkingLocationScreenQuery($filter: findTaskFilterInput!) {
    findTasks(filter: $filter) {
      _id
      vehicle {
        registration {
          plate
        }
      }
      taskStartLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      history {
        _id
        action
        actor
        event_at
      }
      taskType
      campus {
        _id
      }
      parkingETA {
        parkingTimePrediction {
          locationId
          name
          parkingTimeThreshold
          acceptedToCompleteTime
          startToCompleteTime
        }
      }
    }
  }
`;
