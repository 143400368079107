/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const controllerOverviewQuery = graphql`
  query controllerOverviewQuery(
    $dailyBonusFilter: DailyControllerTaskBonusFilter
    $monthlyBonusFilter: MonthlyControllerTaskBonusFilter
    $yearlyBonusFilter: controllerLast12MonthsBonusFilter
  ) {
    dailyControllerTaskBonus(filter: $dailyBonusFilter) {
      date
      bonus {
        onTimeTaskCount
        delayedTaskCount
        bonusAmount
      }
    }
    monthlyControllerTaskBonus(filter: $monthlyBonusFilter) {
      date
      bonusData {
        onTimeTaskCount
        delayedTaskCount
        bonusAmount
      }
    }
    controllerLast12MonthsBonus(filter: $yearlyBonusFilter) {
      year
      month
      monthName
      onTimeTaskCount
      delayedTaskCount
      bonusAmount
    }
  }
`;
