import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { Layout } from '../../../components/new';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import Restaurant from './restaurantDetail';
import useUserStore from '../../../stores/userStore';
import useCommonStore from '../../../stores/commonStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import {
  CheckIfDateLiesInWeekend,
  checkIfCurrentTimeLiesBetweenTwoTimes,
} from '../../../utilities/helper';
import { DiscountDaysEnum } from '../../restaurantPartner/discounts/helper';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const RenderRestaurantScreen = (props) => {
  const {
    variables,
    restaurantScreenQuery,
    refreshedQueryOptions,
    navigation,
    route,
    refresh,
  } = props;
  const userRole = useUserStore((state) => state.role);
  const restaurantId = route?.params?.restaurantId;

  const {
    findRestaurants2,
    getNetPrice,
    getRestaurantpartnerDiscount,
    findConfig,
  } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLazyLoadQuery(restaurantScreenQuery, variables, {
      ...refreshedQueryOptions,
    });

  return (
    <Restaurant
      refresh={refresh}
      navigation={navigation}
      restaurantId={restaurantId}
      findRestaurants2={findRestaurants2}
      getRestaurantpartnerDiscount={getRestaurantpartnerDiscount}
      userRole={userRole}
      findConfig={findConfig}
    />
  );
};

const RestaurantPage = ({ navigation, route }) => {
  const [netStatus, setNetStatus] = useState(true);
  const restaurantId = route?.params?.restaurantId;
  const today = new Date();

  const campusID = useCommonStore((state) => state.campusId);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const restaurantScreenQuery = graphql`
    query restaurantScreen1Query(
      $filter: findRestaurantsFilterInput!
      $discountFilter: restaurantpartnerDiscountfilterInput
    ) {
      findRestaurants2(filter: $filter) {
        ...restaurantDetail_findRestaurants2
      }
      getRestaurantpartnerDiscount(filter: $discountFilter) {
        ...restaurantDetail_getRestaurantpartnerDiscount
      }
      findConfig {
        billUploadConfig {
          cashbackPercentage
        }
      }
    }
  `;

  useFocusEffect(
    useCallback(() => {
      firebaseEventLogger('RestaurantScreenLanding', {
        restaurantId,
      });
    }, []),
  );

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <RenderRestaurantScreen
            variables={{
              filter: { _id: restaurantId, campus: { _id: campusID } },
              discountFilter: {
                restaurantId,
                isActive: true,
                mealTime: checkIfCurrentTimeLiesBetweenTwoTimes(),
                days: CheckIfDateLiesInWeekend(today, 0)
                  ? DiscountDaysEnum.WEEKEND
                  : DiscountDaysEnum.WEEKDAY,
              },
            }}
            restaurantScreenQuery={restaurantScreenQuery}
            refreshedQueryOptions={refreshedQueryOptions}
            fetchKey={refreshedQueryOptions.fetchKey}
            navigation={navigation}
            route={route}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default RestaurantPage;
