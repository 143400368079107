/* eslint-disable react/require-default-props */
import * as React from 'react';
import View from '../../basic/View/View';
import Scoreview from './ScoreView';

interface ScorecardProps {
  scorecard1?: {
    title1: string;
    title2: string;
    title1Color: string;
    title2Color: string;
  };
  scorecard2?: {
    title1: string;
    title2: string;
    title1Color: string;
    title2Color: string;
  };
}

const BottomScoreCard = ({
  scorecard1 = {
    title1: '',
    title2: '',
    title1Color: '',
    title2Color: '',
  },
  scorecard2 = {
    title1: '',
    title2: '',
    title1Color: '',
    title2Color: '',
  },
}: ScorecardProps) => {
  return (
    <View
      bg="opacity.80"
      py="lg"
      px="2xl"
      flexDirection="row"
      alignItems="center"
      //   justifyContent="space-between"
    >
      <View flex={1}>
        <Scoreview
          title1={scorecard1?.title1}
          title1Color={scorecard1?.title1Color}
          title2={scorecard1?.title2}
          title2Color={scorecard1?.title2Color}
        />
      </View>
      <View flex={1} ml="2xl">
        <Scoreview
          title1={scorecard2?.title1}
          title1Color={scorecard2?.title1Color}
          title2={scorecard2?.title2}
          title2Color={scorecard2?.title2Color}
        />
      </View>
    </View>
  );
};

export default BottomScoreCard;
