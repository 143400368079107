import * as React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  View,
  Text,
  Divider,
  Button,
  RadioGroup,
  Input,
} from '../../../../components/new';
import AutoBottomSheet from '../../../../components/new/custom/AutoBottomSheet';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import useValetTaskStore from '../../../../stores/valet/taskStore';
import useUserStore from '../../../../stores/userStore';
import { updateTaskForSupervisor } from '../../../../relay/taskApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { taskHistoryActionTypes } from '../../../../utilities/helper';
import { optionMaker } from './helper/helper';
import { updateParking } from '../../../../relay/parkingApi';

const markExitRecalledTask = (task, callBack) => {
  const parkingData = { record: { status: 'OUT' }, id: task.parkingId };
  updateParking(
    parkingData,
    (response) => {
      callBack(task);
    },
    (err) => {
      // console.log(err);
    },
  );
};

const TaskOptions = ({
  bottomSheetModalRef,
  handleOnDismiss,
  task,
  refresh,
  showCancelTaskModal,
}) => {
  const [cancelTaskReason, setCancelTaskReason] = React.useState(
    optionMaker(undefined),
  );
  const navigation = useNavigation();
  const [inputValue, setInputValue] = React.useState('');
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const userId = useUserStore((state) => state.id);
  const { tasks, setTasks } = useValetTaskStore((state) => state);

  const removeItem = (id) => {
    const arr = tasks.filter((item) => item._id !== id);
    setTasks({ data: arr, userId });
  };

  const selectedtaskReasonIndex = cancelTaskReason?.findIndex(
    (item) => item.checked,
  );

  const buttonEnabled = cancelTaskReason.some((item) => item?.checked);

  const handleTextChange = (i) => {
    setInputValue(i);
  };

  React.useEffect(() => {
    setCancelTaskReason(optionMaker(task));
  }, [task]);

  const handleCancelTask = () => {
    if (selectedtaskReasonIndex === 0) {
      showCancelTaskModal();
    } else if (selectedtaskReasonIndex === 1) {
      markExitRecalledTask(task, () => {
        const data = {
          _id: task?._id,
          record: {
            history: { action: taskHistoryActionTypes.completed },
          },
        };
        updateTaskForSupervisor(
          data,
          (response, error) => {
            // refresh();
            if (response) {
              dispatchSnackbar({
                msg: `Car marked exited`,
                status: SnackbarStatus.success,
                version: SnackbarVersion.v2,
              });
              refresh();
              navigation.reset({
                index: 0,
                routes: [{ name: 'valetSupervisor' }],
              });
            }
          },
          (error) => {
            // console.log(error);
            handleOnDismiss();
          },
        );
      });
    }
  };
  return (
    <View>
      <AutoBottomSheet
        bottomSheetRef={bottomSheetModalRef}
        handleClose={handleOnDismiss}
      >
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Select One
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            <RadioGroup
              radio={cancelTaskReason}
              setRadio={(arr) => setCancelTaskReason(arr)}
              title=""
              isRequired
              size="lg"
              icon="right"
              collapsedCount={5}
            />
          </View>
          {selectedtaskReasonIndex === 3 && (
            <View px="2xl" mb="2xl">
              <Input
                value={inputValue}
                onChangeText={handleTextChange}
                placeholder="Enter Reason..."
              />
            </View>
          )}
          <View px="2xl" mt="xl">
            <Button
              size="lg"
              appearance="filled"
              state={buttonEnabled ? 'enabled' : 'disabled'}
              onPress={handleCancelTask}
            >
              Confirm
            </Button>
          </View>
        </View>
      </AutoBottomSheet>
    </View>
  );
};

export default TaskOptions;
