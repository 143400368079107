const createVehicleSlice = (set) => ({
  vehicleData: [
    // {
    //   // vehicleStatus: 'assigning',
    //   feedbackGiven: 'none',
    //   taskId: '',
    //   // statusTimestamp: 0,
    // },
  ],
  // setVehicleData: (action) =>
  //   set((state) => {
  //     return {
  //       vehicleData: {
  //         ...state.vehicleData,
  //         vehicleStatus: action.vehicleStatus,
  //         statusTimestamp: action.statusTimestamp,
  //       },
  //     };
  //   }),
  setFeedback: (action) =>
    set((state) => {
      const index = state.vehicleData.findIndex(
        (item) => item.taskId === action.taskId,
      );
      // console.log('action', { action, index });
      if (index === -1) {
        return {
          vehicleData: [
            ...state.vehicleData,
            { feedbackGiven: action.status, taskId: action.taskId },
          ],
        };
      }
      // console.log('index', index);
      // console.log('state.vehicleData', state.vehicleData[index]);
      const vehicleDataCopy = [...state.vehicleData];
      vehicleDataCopy[index].feedbackGiven = action.status;
      return {
        vehicleData: vehicleDataCopy,
      };
    }),
  clearVehicleData: () =>
    set(() => {
      return {
        vehicleData: {
          // vehicleStatus: 'assigning',
          feedbackGiven: 'none',
          // statusTimestamp: 0,
        },
      };
    }),
});

export default createVehicleSlice;
