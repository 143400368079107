import React from 'react';
import { Icon, Text, View } from '..';

interface ParkingETAProps {
  iconColor: string;
  time: string;
}

const ParkingETA = ({
  iconColor = 'success.500',
  time = '2 min',
}: ParkingETAProps) => {
  return (
    <View
      flexDirection="row"
      borderRadius="md"
      borderWidth="xs"
      borderColor="primary.50"
      bg="primary.10"
      p="sm+md"
      alignItems="center"
    >
      <Icon name="timer-outline-300" size="xl" color={iconColor} />
      <Text ml="lg" size="md" weight="medium" color="primary.300">
        {time}
      </Text>
    </View>
  );
};

export default ParkingETA;
