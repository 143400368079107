import React from 'react';
import { View, Text } from '../../../components/new';
import useUserStore from '../../../stores/userStore';
import CountDown from '../../new/payment/CountDown';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';

const CongratulationScreen = ({ navigation, route }) => {
  const { role } = useUserStore((state) => state);

  const navigateToHome = () => {
    navigation.navigate(role);
  };

  return (
    <View flex={1} bg="primary.10" alignItems="center" justifyContent="center">
      <CustomLottieView
        loop
        autoPlay
        source={require('../../../../assets/lottie/tick.json')}
        style={{
          width: 200,
          height: 200,
        }}
        containerWidth={200}
      />
      <View px="2xl">
        <Text size="2xl" weight="medium" color="primary.400" textAlign="center">
          Congratulations!
        </Text>
        <Text mt="2xl" size="md" color="primary.200" textAlign="center">
          You have successfully joined the 32nd Club
        </Text>
      </View>
      <View position="absolute" bottom="md">
        <CountDown countDown={5} onComplete={navigateToHome} />
      </View>
    </View>
  );
};
export default CongratulationScreen;
