import React from 'react';
import { Modal, View, Text, Button, IconButton } from '../new';
import useBoundStore from '../../stores';

const ClearCartModal = ({
  visible,
  setVisible,
  // restaurantName,
  // restaurantId,
  navigation,
  clear = false,
}) => {
  const cart = useBoundStore((state) => state.cart);
  const clearCart = useBoundStore((state) => state.clearCart);
  const clearOrderDetails = useBoundStore((state) => state.clearOrderDetails);
  const otherItem = useBoundStore((state) => state.otherItem);
  const addToCart = useBoundStore((state) => state.addToCart);
  const clearOtherItem = useBoundStore((state) => state.clearOtherItem);

  const confirmHandler = () => {
    clearOrderDetails();
    clearCart();
    setVisible(false);
    if (!clear) {
      addToCart(otherItem);
      clearOtherItem();
    }
  };

  const viewCartHandler = () => {
    // if (orderPlaced) {
    //   return;
    // }
    setVisible(false);
    navigation.navigate('order', {
      restaurant: cart[0].restaurant._id,
      restaurantName: cart[0].restaurant.displayName,
    });
  };

  return (
    <>
      {visible ? (
        <Modal
          visible={visible}
          onBackdropPress={() => {
            setVisible(false);
          }}
        >
          <View
            width="90%"
            maxWidth={358}
            maxHeight={500}
            bg="background.primary.base"
            borderRadius={10}
            py="4xl"
            px="2xl"
          >
            <Text
              size="2xl"
              weight="medium"
              color="primary.500"
              textAlign="center"
            >
              Your cart will be cleared
            </Text>
            <Text size="md" color="primary.300" textAlign="center" mt="xl">
              {clear
                ? `You have items in your cart from ${cart[0].restaurant.displayName} Cafe, are you sure you want to clear all items from your cart?`
                : `You have items in your cart from ${cart[0].restaurant.displayName} Cafe, are you sure you want to replace it with other items?`}
            </Text>
            <View flexDirection="row" mt="4xl">
              <View flex={1}>
                <Button
                  appearance="outline"
                  size="lg"
                  onPress={viewCartHandler}
                >
                  View Cart
                </Button>
              </View>
              <View flex={1} ml="2xl">
                <Button size="lg" onPress={confirmHandler}>
                  Confirm
                </Button>
              </View>
            </View>
          </View>
          <View mt="2xl" width="90%" alignItems="center">
            {/* <Pressable onPress={() => {}}> */}
            <IconButton
              name="close-outline-300"
              size="xl"
              bg="primary.500"
              iconColor="primary.50"
              onPress={() => {
                setVisible(false);
              }}
            />
            {/* </Pressable> */}
          </View>
        </Modal>
      ) : null}
    </>
  );
};

export default ClearCartModal;
