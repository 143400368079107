import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import CommonLayout from './components/CommonLayout';
import HiddenInput from './components/HiddenInput';
import { Text, View } from '../../../../components/new';
import BackButton from './components/BackButton';
import CachedImage from '../../../../components/new/custom/CachedImage';
import { FontWeights } from '../../../../themes/new/helper';
import { useKioskStore } from '../../../../stores/kiosk/kioskStore';
import useCountdown from '../../../../utilities/customHooks/useCountDown';
import { kiosk } from '../../../../utilities/Constant';
import { analytics } from './analytics';
import { addTask } from '../../../../relay/taskApi';
import { addTaskFun } from '../../../valetSupervisor/addTaskFun';
import WhatsappCount from './components/WhatsappCount';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';
import AnimatedButton from './components/AnimatedButton';

const { SESSION_TIMEOUT } = kiosk;

const RecallCarScreen = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const { parking, setActiveTask } = useKioskStore((state) => state);

  const { countdown, startCountdown } = useCountdown(SESSION_TIMEOUT, {
    onComplete: () => {
      navigation.replace('kioskRecall');
    },
  });

  useEffect(() => {
    startCountdown();
    analytics.recallCarScreen.landed(parking ?? ({} as any));
  }, []);

  const navigateToRecallStatusScreen = (res) => {
    if (res.addTask && res.addTask?.record?.taskType === 'recall') {
      setLoading(false);
      setActiveTask(res.addTask?.record);
      navigation.navigate('recallStatus', {
        isFreshRecall: true,
        tagId: parking?.parkingTag ?? '',
      });
    }
  };

  const handleRecall = () => {
    const lastTask = parking?.tasks?.slice(-1)[0];
    if (!loading && lastTask) {
      analytics.recallCarScreen.recallConfirmed(parking ?? ({} as any));
      setLoading(true);
      const task = {
        ...lastTask,
        campus: {
          _id: lastTask.campus._id,
          name: lastTask.campus.name,
        },
      };
      const data = addTaskFun(task);
      addTask(
        data,
        (res) => {
          analytics.recallCarScreen.recallSuccessful(parking ?? ({} as any));
          navigateToRecallStatusScreen(res);
        },
        (err) => {
          console.log(err);
          analytics.recallCarScreen.recallFailed(parking ?? ({} as any));
          setLoading(false);
        },
      );
    }
  };

  return (
    <CommonLayout>
      <HiddenInput />
      <View flex={1} p="9xl" width="100%" justifyContent="space-between">
        <View flex={1} width="100%">
          <View>
            <BackButton
              onBack={() => {
                analytics.recallCarScreen.backClicked(parking ?? ({} as any));
                navigation.goBack();
              }}
            />
          </View>
          <View flex={1} alignItems="center">
            <View width={640} height={610} mt="4xl" alignItems="center">
              <CachedImage
                source={require('../../../../../assets/images/kiosk/confirm-recall-mockup.webp')}
                style={{ width: 640, height: 610 }}
              />
              <View zIndex={2} position="absolute" top={365} left={120}>
                <CustomLottieView
                  autoPlay
                  loop
                  style={{
                    width: 320,
                    height: 50,
                  }}
                  containerWidth={320}
                  source={require('../../../../../assets/lottie/kiosk/highlighter.json')}
                />
              </View>
            </View>
            <View mt="9xl">
              <WhatsappCount count={1} />
            </View>
            <View mt="2xl" alignitems="center">
              <Text
                weight={FontWeights.MEDIUM}
                textAlign="center"
                size="5xl"
                color="primary.500"
              >
                Recall your car directly from WhatsApp next time you visit 32nd!
              </Text>
            </View>
          </View>
        </View>
        <View>
          {/* @ts-ignore */}
          <AnimatedButton loading={loading} onPress={handleRecall}>
            Get My Car Now
          </AnimatedButton>
        </View>
        <View mt="4xl">
          <Text
            textAlign="center"
            size="2xl"
            weight="regular"
            color="primary.300"
          >
            Session ending in {countdown}s
          </Text>
        </View>
      </View>
    </CommonLayout>
  );
};

export default RecallCarScreen;
