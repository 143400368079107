import React from 'react';
import { FlashList } from '@shopify/flash-list';
import { useNavigation } from '@react-navigation/native';
import { View, Text, Icon, Pressable } from '../../../components/new';
import BonusListItem from '../../../components/new/custom/bonus/BonusListItem';
import NoContentView from '../../../components/new/custom/bonus/NoContentView';
import BonusBadge from '../../../components/new/custom/bonus/BonusBadge';
import { toRupees } from '../../../utilities/helper';
import PaginationLoader from '../../../components/new/custom/PaginationLoader';
import { pagination } from '../../../utilities/Constant';

type ItemListProps = {
  items: Array<{
    _id: string;
    status: string;
    name: string;
    isVerified: boolean;
    amount: number;
    userInputs: any;
    bucket?: string;
    key?: string;
  }>;
};

type ListItemProps = {
  items: ItemListProps;
  hasNext: boolean;
  loadNext: any;
};

const ListItems = ({ items, hasNext, loadNext }: ListItemProps) => {
  const navigation = useNavigation();
  return (
    <FlashList
      data={items}
      scrollsToTop
      renderItem={({ item }) => {
        const onPress = () => {
          navigation.navigate('ViewBillScreenWrapper', {
            restaurantName: item?.name,
            itemId: item?._id,
            bucket: item?.bucket,
            key: item?.key,
            viewOnly: true,
          });
        };
        return (
          <Pressable onPress={onPress}>
            <View key={item?._id} mb="2xl" px="2xl">
              <BonusListItem
                contentPrimary={item?.name}
                contentSecondary="Paid"
                sizePri="sm"
                RightContent={
                  <View minWidth="54" flexDirection="row">
                    <BonusBadge variant={item?.isVerified ? 'gain' : 'loss'}>
                      <Text size="sm" color="primary.500">
                        {toRupees(Math.trunc(item?.amount))}
                      </Text>
                    </BonusBadge>
                    <View ml="lg">
                      <Icon
                        name="keyboard-arrow-right-300"
                        color="primary.500"
                        size="2xl"
                      />
                    </View>
                  </View>
                }
              />
            </View>
          </Pressable>
        );
      }}
      onEndReached={() => {
        if (hasNext) {
          loadNext(pagination.defaultPageSize);
        }
      }}
      ListFooterComponentStyle={{
        margin: 0,
        padding: 0,
      }}
      ListFooterComponent={() => {
        if (hasNext) return <PaginationLoader />;
        return null;
      }}
      ListEmptyComponent={
        <View>
          <NoContentView />
          <Text textAlign="center" color="primary.100">
            No Bills yet
          </Text>
        </View>
      }
    />
  );
};

export default ListItems;
