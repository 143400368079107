/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  Button,
  IconButton,
  Input,
  Layout,
  TopNavigation,
  View,
} from '../../components/new';
import { hasWhiteSpace, navigateBack } from '../../utilities/helper';
import useUserStore, { UserRole } from '../../stores/userStore';
import { InputStates } from '../../components/new/primitive/Input/helpers';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useControllerModalStore from '../../stores/controller/controllerModalStore';

const StaffAddManuallyBarcodeScreen = ({ navigation, route }) => {
  const scrollViewRef = useRef(null);
  const { parkingTag, taskId } = route?.params ?? {};
  const { role } = useUserStore((state) => state);
  const { data, setData } = useControllerModalStore(
    (controllerState) => controllerState,
  );
  const [value, setValue] = useState(parkingTag || data?.tag || '');
  const [errorHelperText, setErrorHelperText] = useState('');

  useEffect(() => {
    return () => {
      setValue('');
      Keyboard.dismiss();
    };
  }, []);

  const onPressLeftIcon = () => {
    Keyboard.dismiss();
    navigateBack(navigation, role);
  };

  // ------ handle text change for input ------
  const handleOnTextChange = (num) => {
    setValue(num);
    if (hasWhiteSpace(num)) {
      setErrorHelperText('Please enter valid VRN');
    } else {
      setErrorHelperText('');
    }
  };

  const onPressSaveChanges = () => {
    firebaseEventLogger('addParkingTag__editTag_Tap', {
      buttonName: 'editTag',
      screenName: 'addParkingTag',
      userType: UserRole.CONTROLLER,
      interactionType: InteractionType.TAP,
      taskId: `${taskId}`,
      tagNo: `${value}`,
    });
    setData({ tag: value });
    navigation.replace('reviewDetails');
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        title="Edit Tag ID"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            onPress={onPressLeftIcon}
          />
        }
      />
      <KeyboardAvoidingView
        // style={Platform.OS === 'web' ? {} : { flex: 1 }}
        style={{ flex: 1 }}
        behavior={
          Platform.OS === 'android'
            ? null
            : Platform.OS === 'ios'
            ? 'padding'
            : null
        }
        // {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
      >
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 30,
          }}
          ref={scrollViewRef}
          keyboardShouldPersistTaps="handled"
        >
          <TouchableWithoutFeedback
            onPress={() => {
              Keyboard.dismiss();
              scrollViewRef.current?.scrollTo({
                x: 10,
                y: 120,
                animated: true,
              });
            }}
          >
            <View px="2xl">
              <View>
                <View mt="7xl">
                  <Input
                    value={value}
                    state={InputStates.active}
                    textSize="md"
                    onChangeText={handleOnTextChange}
                    inputMode="numeric"
                    keyboardType="numeric"
                    returnKeyType="next"
                    placeholder="123456"
                    errorHelperText={errorHelperText}
                    maxLength={6}
                    autoFocus
                  />
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
        <View m="2xl" bottom="sm">
          <Button
            size="lg"
            appearance="filled"
            status="primary"
            state={value?.length >= 6 ? 'active' : 'disabled'}
            onPress={onPressSaveChanges}
          >
            Save Changes
          </Button>
        </View>
      </KeyboardAvoidingView>
    </Layout>
  );
};

export default StaffAddManuallyBarcodeScreen;
