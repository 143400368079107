import { graphql } from 'react-relay';

const taskJourneyScreenQuery = graphql`
  query taskJourneyScreenQuery($filter: findTaskFilterInput!) {
    findTasks(filter: $filter) {
      _id
      taskType
      # make new recallAPI and remove line below
      parkingId
      parkingTag
      parkingId
      vehicle {
        # make new recallAPI and remove line below
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        # make new recallAPI and remove line below
        color
      }
      # make new recallAPI and remove line below
      campus {
        _id
        name
      }
      history {
        action
        event_at
      }
      taskStartLocation {
        # make new recallAPI and remove line below
        locationId
        # make new recallAPI and remove line below
        parkingSpaces {
          floor {
            floorId
            floorName
            bay {
              bayId
              bayName
            }
          }
        }
        name
      }
      taskEndLocation {
        # make new recallAPI and remove line below
        locationId
        # make new recallAPI and remove line below
        parkingSpaces {
          floor {
            floorId
            floorName
            bay {
              bayId
              bayName
            }
          }
        }
        name
      }
      user {
        profileImage
        contact {
          phone {
            prefix
            number
          }
        }
      }
      parking {
        parkingTag
      }
      valet {
        profileImage
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
    }
  }
`;

export default taskJourneyScreenQuery;
