import { graphql, requestSubscription } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

const cashierTransactionUpdatedSubscription = graphql`
  subscription cashierTransactionUpdatedSubscription($invoiceId: ID!) {
    invoiceUpdated(invoiceId: $invoiceId) {
      _id
      status
    }
  }
`;

export default (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: cashierTransactionUpdatedSubscription,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      console.log('error', error);
      // console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
