import { graphql, commitMutation } from 'react-relay';
import environment from './relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const addParkingFeedback = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation parkingFeedbackApiMutation(
      $record: parkingFeedbackRecordInput!
      $id: MongoID!
    ) {
      addParkingFeedback(record: $record, _id: $id) {
        recordId
        record {
          _id
        }
        error {
          message
        }
      }
    }
  `;
  const variables = {
    record: data.record,
    id: data.id,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};
