import React, { useEffect } from 'react';
import useAuthStore from '../../stores/authStore';
import TrackMyVehicleScreen from '../../screens/new/trackMyVehicle/TrackMyVehicleScreen';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import NotLoggedInPlaceholderV2 from '../new/custom/NotLoggedInPlaceholderV2';

const TrackMyVehicleScreenWrapper = (props) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  useEffect(() => {
    firebaseEventLogger('TRACK_MY_VEHICLE_SCREEN_LANDING', {
      screenName: 'TrackMyVehicleScreen',
      userType: 'user',
      interactionType: 'landing',
    });
  }, []);

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="My Car" />;
  }
  return <TrackMyVehicleScreen />;
};

export default TrackMyVehicleScreenWrapper;
