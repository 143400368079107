import React from 'react';
import { View } from '../../../../../../components/new';
import SectionDivider from '../../../components/SectionDivider';
import BenefitsListItem from './BenefitsListItem';
import { generateBenefitsListData } from '../helpers';

interface BlackMembershipBenefitsSectionProps {
  isBlackMember: boolean;
  parkingDiscount: number;
  eventsDiscount: number;
}

const BlackMembershipBenefitsSection = ({
  isBlackMember,
  parkingDiscount,
  eventsDiscount,
}: BlackMembershipBenefitsSectionProps) => {
  const listData = generateBenefitsListData(
    isBlackMember,
    parkingDiscount,
    eventsDiscount,
  );
  return (
    <View px="2xl">
      <SectionDivider label="Member Benefits" />
      {listData.map(({ title, subTitle, icon, info }) => (
        <View key={title} mt="2xl">
          <BenefitsListItem
            icon={icon}
            title={title}
            subTitle={subTitle}
            info={info}
          />
        </View>
      ))}
    </View>
  );
};

export default BlackMembershipBenefitsSection;
