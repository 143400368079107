import styled from 'styled-components/native';
import imageConfig from '../../../../imagekit.config';
import { Text, View } from '../../../components/new';
import Pressable from '../../../components/new/basic/Pressable/Pressable';
import { StarRatingVariants } from '../../../components/new/composites/StarRating/helpers/helpers';
import StarRating from '../../../components/new/composites/StarRating/StarRating';
import { applyEllipsis } from '../../../utilities/helper';

const StyledImage = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: 999px;
`;
const SearchListItem = ({ data, onPress = () => {}, showRating = true }) => {
  const { logo, displayName, overAllRating, description } = data;
  let tagList = [];
  if (description) {
    tagList = description.tags;
  }

  const imageKitURL = imageConfig.url({
    src: logo,
    transformation: [
      {
        aspectRatio: '1-1',
        format: 'webp',
        width: '80',
      },
    ],
  });

  return (
    <Pressable onPress={() => onPress()}>
      <View flexDirection="row" alignItems="center">
        <View height={40} width={40} mr="2xl">
          <StyledImage source={{ uri: imageKitURL }} />
        </View>
        <View flexDirection="row" justifyContent="space-between" flex={1}>
          <View width="80%">
            <Text size="md" color="primary.400" mb="sm">
              {/* do not capitalize this, because the owner can choose how he wants to decorize it */}
              {displayName}
            </Text>
            <Text size="xs" color="primary.200">
              {tagList.map(({ name }, index) => {
                if (tagList.length === index + 1) {
                  return applyEllipsis(name, 15);
                }
                return `${applyEllipsis(name, 15)}, `;
              })}
            </Text>
          </View>
          <View alignItems="center" justifyContent="center">
            {showRating && overAllRating && (
              <StarRating
                ratingScore={overAllRating || 0}
                size={StarRatingVariants.sm}
              />
            )}
          </View>
        </View>
      </View>
    </Pressable>
  );
};

export default SearchListItem;
