import React from 'react';
import { Button, IconButton, Modal, Text, View } from '../../components/new';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

const LoyaltyModal = ({ modalVisible, setModalVisible }) => {
  const handleDismiss = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      bg="blur"
      visible={modalVisible}
      onBackdropPress={() => {
        setModalVisible(false);
      }}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View
        bg="background.primary.base"
        py="4xl"
        px="7xl"
        borderRadius="lg"
        mx="2xl"
      >
        <View alignItems="center" mb="4xl">
          <CustomLottieView
            autoPlay
            source={require('../../../assets/lottie/no_photos.json')}
            loop
            style={{ width: 200, height: 200 }}
            containerWidth={200}
          />
        </View>
        <View mb="4xl">
          <Text
            size="xl"
            color="primary.500"
            weight="medium"
            textAlign="center"
          >
            Please reach out to the 32nd team to deactivate your loyalty program
          </Text>
        </View>
        <Button onPress={handleDismiss} appearance="filled">
          Okay
        </Button>
      </View>
      <View mt="2xl" width="90%" alignItems="center">
        <IconButton
          name="close-outline-300"
          size="xl"
          bg="primary.500"
          iconColor="primary.50"
          onPress={handleDismiss}
        />
      </View>
    </Modal>
  );
};

export default LoyaltyModal;
