import React from 'react';
import moment from 'moment';
import { Card, Text, View } from '../../../components/new';

const ParkingDetails = ({ entryTime, recallTime }) => {
  return (
    <View mt="4xl">
      <Text size="md" color="primary.200" weight="medium" mb="2xl">
        Parking Details
      </Text>
      <Card>
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            Entry Time
          </Text>
          <Text size="sm" color="primary.400">
            {moment(entryTime).format('DD MMM,YYYY | hh:mm A')}
          </Text>
        </View>

        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            Recall Time
          </Text>
          <Text size="sm" color="primary.400">
            {moment(recallTime).format('DD MMM,YYYY | hh:mm A')}
          </Text>
        </View>
      </Card>
    </View>
  );
};

export default ParkingDetails;
