/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const auditorOverviewQuery = graphql`
  query auditorOverviewQuery($filter: getPopupOrderCollectionsFilter) {
    getPopupOrderCollections(filter: $filter) {
      date
      count
      cashTotal
    }
  }
`;
