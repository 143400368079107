import React, { useEffect, useState } from 'react';
import { Image, Platform, ScrollView, useWindowDimensions } from 'react-native';
import Slider from '@react-native-community/slider';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Button, IconButton, Text, View } from '../../../../components/new';
import { Appearance, Status } from '../../../../themes/new/helper';
import {
  getStyledPremium,
  isValueNullOrEmpty,
  toCurrency,
} from '../../../../utilities/Utility';
import { colors, radii } from '../../../../themes/new/theme';
import InvestmentBlurView from './InvestmentBlurView';

const InvestmentCalculator = ({
  data,
  consentGiven,
  openRoiDetails = (details: object) => {},
  onPressUnlock = () => {},
  bgColor = '',
  handleOnPress = () => {},
}) => {
  const { width, height } = useWindowDimensions();
  const insets = useSafeAreaInsets();
  const XIRR = data?.data?.investment?.roi?.xirr ?? [];
  const [currentAmount, setCurrentAmount] = useState(
    data?.data?.investmentAmount?.default,
  );
  const [currentInvestmentYear, setCurrentInvestmentYear] = useState(
    data?.data?.investment?.years?.default,
  );
  const [estimatedRent, setEstimatedRent] = useState(0);
  const [estimatedROI, setEstimatedROI] = useState(0);
  const [estimatedReturn, setEstimatedReturn] = useState(0);
  const [salePrice, setSalePrice] = useState(0);

  const checkNewData = () => {
    const currentROI = XIRR?.find((val) => val?.year === currentInvestmentYear);
    if (!isValueNullOrEmpty(currentROI)) {
      const { capitalAppriciation, rentalYieldPostOps } =
        data?.data?.investment?.roi;
      const assuredBuyBack =
        data?.data?.investment?.assuredBuyBack *
          data?.data?.investment?.units.perUnitAmount ?? 0;
      const minInvestmentAmount = data?.data?.investmentAmount?.min;
      const numOfUnits = currentAmount / minInvestmentAmount;
      const yearRentAmount =
        (currentAmount / 100) * rentalYieldPostOps * currentInvestmentYear;

      const CAPTA = capitalAppriciation / 100 + 1;
      // @ts-ignore
      const yearCapitalAppreciation =
        currentAmount * CAPTA ** currentInvestmentYear;

      const assuredSalePrice = assuredBuyBack * numOfUnits;

      const finalSalePrice =
        assuredBuyBack > 0 ? assuredSalePrice : yearCapitalAppreciation;

      setEstimatedROI(currentROI?.percentage);
      setSalePrice(finalSalePrice);
      setEstimatedRent(yearRentAmount / (12 * currentInvestmentYear));
      setEstimatedReturn(yearRentAmount + finalSalePrice);
    }
  };

  useEffect(() => {
    checkNewData();
  }, [currentInvestmentYear]);

  const onPressRoiCalculated = () => {
    const details = { ...data?.data };
    const rentalIncome = estimatedRent * 12 * currentInvestmentYear;

    const totalReturn = rentalIncome + salePrice;

    details.rentalIncome = rentalIncome;
    details.salePrice = salePrice;
    details.totalReturn = totalReturn;
    details.totalInvested = currentAmount;
    details.totalProfit = totalReturn - currentAmount;
    details.targetIRR = estimatedROI;
    details.InvMultiplier = (totalReturn / currentAmount).toFixed(1);
    openRoiDetails(details);
  };

  return (
    <View bg={bgColor} height={height} width={width}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View alignItems="center" py="9xl+9xl" px="2xl" mb="8xl+8xl">
          <Text size="xs" color="primary.300" pb="lg" textAlign="center">
            {data?.heading ?? 'ATTRACTIVE RETURNS'}
          </Text>
          <Text size="2xl" color="primary.500" textAlign="center">
            {data?.subHeading ?? 'Find your ideal opportunity'}
          </Text>
          <View
            mt="9xl"
            borderWidth={1}
            borderRadius="xl"
            borderColor="grey.50"
            width="100%"
            bg="primary.10"
          >
            <InvestmentBlurView
              blurHeight="100%"
              blurWidth="100%"
              showBlur={!consentGiven}
              BlurViewStyle={{ borderRadius: radii.xl }}
              iconSize="6xl"
              onPressUnlock={onPressUnlock}
            />
            <View px="2xl" py="9xl">
              <View mb="9xl">
                <View
                  flexDirection="row"
                  justifyContent="space-between"
                  mb="3xl+xs"
                >
                  <Text color="primary.300" size="lg">
                    {data?.data?.investment?.years?.min ===
                    data?.data?.investment?.years?.max
                      ? 'Investment Period'
                      : 'Select Years'}
                  </Text>
                  <Text color="primary.500" size="lg" weight="medium">
                    {currentInvestmentYear} yrs
                  </Text>
                </View>
                {data?.data?.investment?.years?.min ===
                data?.data?.investment?.years?.max ? null : (
                  <Slider
                    style={{
                      width: '100%',
                      height: 24,
                    }}
                    minimumValue={data?.data?.investment?.years?.min}
                    maximumValue={data?.data?.investment?.years?.max}
                    onValueChange={(val) => setCurrentInvestmentYear(val)}
                    tapToSeek
                    minimumTrackTintColor={colors.primary[50]}
                    maximumTrackTintColor={colors.primary[400]}
                    thumbTintColor={colors.primary[50]}
                    step={1}
                    value={currentInvestmentYear}
                    onSlidingComplete={() => {}}
                  />
                )}
              </View>
              <View mb="9xl">
                <View
                  flexDirection="row"
                  justifyContent="space-between"
                  mb="3xl+xs"
                >
                  <Text color="primary.300" size="lg">
                    Total Investment Amount
                  </Text>
                  <Text color="primary.500" size="lg" weight="medium">
                    {getStyledPremium(currentAmount)}
                  </Text>
                </View>
                <Slider
                  style={{
                    width: '100%',
                    height: 24,
                  }}
                  minimumValue={data?.data?.investmentAmount?.min}
                  maximumValue={data?.data?.investmentAmount?.max}
                  onValueChange={(val) => setCurrentAmount(val)}
                  tapToSeek
                  minimumTrackTintColor={colors.primary[50]}
                  maximumTrackTintColor={colors.primary[400]}
                  thumbTintColor={colors.primary[50]}
                  step={data?.data?.investmentAmount?.min}
                  value={currentAmount}
                  onSlidingComplete={() => {
                    checkNewData();
                  }}
                />
              </View>
              <View backgroundColor="grey.50" height={1} width="100%" />
              <View flexDirection="row" mt="4xl" alignItems="center">
                <Image
                  source={require('../../../../../assets/images/estimatedRent.webp')}
                  style={{ height: 24, width: 24 }}
                />
                <Text mx="2xl" color="primary.300" size="sm">
                  You will receive an estimated
                  <Text
                    color="success.500"
                    size="lg"
                    weight="medium"
                  >{` ₹${toCurrency(estimatedRent)} `}</Text>
                  rent per month*
                </Text>
              </View>
              <View flexDirection="row" mt="4xl" alignItems="center">
                <Image
                  source={require('../../../../../assets/images/estimatedROI.webp')}
                  style={{ height: 24, width: 24 }}
                />
                <Text mx="2xl" color="primary.300" size="sm">
                  Your estimated annual ROI will be
                  <Text
                    color="success.500"
                    size="lg"
                    weight="medium"
                  >{` ${estimatedROI}%`}</Text>
                  *
                </Text>
              </View>
              <View flexDirection="row" mt="4xl" alignItems="center">
                <Image
                  source={require('../../../../../assets/images/estimatedReturn.webp')}
                  style={{ height: 24, width: 24 }}
                />
                <Text mx="2xl" color="primary.300" size="sm">
                  Your {getStyledPremium(currentAmount)} will turn into an
                  estimated
                  <Text
                    color="success.500"
                    size="lg"
                    weight="medium"
                  >{` ₹${toCurrency(estimatedReturn)}`}</Text>
                  *
                </Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        position="absolute"
        top={32}
        right={16}
        alignItems="flex-end"
        borderRadius={radii.full}
        bg={bgColor}
      >
        <IconButton
          size="xl"
          name="close-outline2-400"
          appearance="ghost"
          onPress={handleOnPress}
        />
      </View>
      <View
        position="absolute"
        bottom={0}
        width="100%"
        px="2xl"
        pb={Platform.OS === 'web' ? 0 : insets.bottom}
        borderTopWidth={1}
        borderColor="grey.50"
        bg={bgColor}
      >
        <Button
          appearance={Appearance.OUTLINE}
          status={Status.PRIMARY}
          size="md"
          my="2xl"
          onPress={onPressRoiCalculated}
        >
          How is the ROI calculated?
        </Button>
      </View>
    </View>
  );
};

export default InvestmentCalculator;
