/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Image,
  ScrollView,
  Vibration,
  Platform,
  useWindowDimensions,
} from 'react-native';
import {
  useAnimatedStyle,
  Easing,
  useSharedValue,
  withTiming,
  interpolateColor,
} from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import {
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
// @ts-ignore
import SlotMachineImage from '../../../../../assets/images/slotMachine.webp';
import ResultComp from './ResultComp';
import { fetchRewards } from './API/slotMachineQuery';
import { navigateBack } from '../../../../utilities/helper';
import {
  rewardTypeEnums,
  slotMachinePrizeToKeyMapper,
} from './helpers/slotMachineHelpers';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import { triesLimit as slotMachineTriesLimit } from '../../../../stores/games/slotMachine/slotMachineSlice';
import SlotMachineComp from './SlotMachineComp';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import {
  getSlotMachineReward,
  getSlotMachineRewardDataMaker,
} from './API/getSlotMachineReward';
import MsgBox from './MsgBox';
import RewardTag from './RewardTag';
import useSound from '../../../../hooks/useSound';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { useSlotMachineStore } from '../../../../stores/games/slotMachine/slotMachineStore';
import useUserStore from '../../../../stores/userStore';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';
import useAuthStore from '../../../../stores/authStore';
import NotLoggedInPlaceholderV2 from '../../../../components/new/custom/NotLoggedInPlaceholderV2';
import { AppTheme } from '../../../../stores/commonStore';
import { useThemedSystemNav } from '../../../../hooks/useThemedSystemNav';

const SlotMachineScreen = ({ navigation, route }) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [value, setValue] = useState('abc');
  // const parkingId = route?.params?.parkingId;

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const userRole = useUserStore((state) => state.role);
  const userId = useUserStore((state) => state.id);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const [rewardsCount, setRewardsCount] = useState(0);
  const [points, setPoints] = useState(0);
  const [recentlyWonPoints, setRecentlyWonPoints] = useState(0);

  const {
    points: localStorePoints,
    otherRewardsCount: localStoreOtherRewardsCount,
    updatePoints,
    updateOtherRewardsCount,
    updateLastFreezeStart,
    // updatePoints,
    // updateOtherRewardsCount,
    // resetSlotMachineState,
  } = useSlotMachineStore((state) => {
    return state;
  });

  const theme = useTheme();

  const [spinsLeft, setSpinsLeft] = useState(0);

  const [wonRewardType, setwonRewardType] = useState('');

  const [searchInFirst, setSearchInFirst] = useState(false);

  const [showResultHeader, setShowResultHeader] = useState(true);

  const [showResult, setShowResult] = useState(false);

  const [startedPlayingAfterOnScreen, setStartedPlayingAfterOnScreen] =
    useState(false);

  const [pointVal, setPointVal] = useState(0);
  const [rewardVal, setRewardVal] = useState(0);
  const [active, setActive] = useState(0);

  // const [pointBtnAnimation] = useState(new Animated.Value(0));
  // const [rewardBtnAnimation] = useState(new Animated.Value(0));
  // const [headerBorderAnimation] = useState(new Animated.Value(0));

  const pointBtnAnimation = useSharedValue(0);
  const rewardBtnAnimation = useSharedValue(0);
  const headerBorderAnimation = useSharedValue(0);

  const { playSound: playSlotWheelSound } = useSound({
    soundOrigin: require('../../../../../assets/slot-wheel-sound_2.mp3'),
  });

  const { playSound: playConfettiSound } = useSound({
    soundOrigin: require('../../../../../assets/confetti-sound.wav'),
  });

  const { playSound: playCoinsSound } = useSound({
    soundOrigin: require('../../../../../assets/coins-sound.mp3'),
  });

  const slotMachineDuration = 2500;
  const leftCoinsRef = useRef(null);
  const rightCoinsRef = useRef(null);
  const confettiRef = useRef(null);

  const timerBin = [];

  const pointButtonStyles = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        pointBtnAnimation.value,
        [0, 1],
        ['#262626', theme.colors.static.gold],
      ),
      color: interpolateColor(
        pointBtnAnimation.value,
        [0, 1],
        [theme.colors.static.gold, 'black'],
      ),
    };
  });

  const rewardButtonStyles = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        rewardBtnAnimation.value,
        [0, 1],
        ['#262626', theme.colors.static.gold],
      ),
      color: interpolateColor(
        rewardBtnAnimation.value,
        [0, 1],
        [theme.colors.static.gold, 'black'],
      ),
    };
  });

  const headerBorderStyles = useAnimatedStyle(() => {
    return {
      borderColor: interpolateColor(
        headerBorderAnimation.value,
        [0, 1],
        ['transparent', theme.colors.static.gold],
      ),
    };
  });

  const startRewardBtnAnimation = () => {
    rewardBtnAnimation.value = withTiming(1, {
      duration: 500,
      easing: Easing.linear,
    });
  };

  const startPointBtnAnimation = () => {
    pointBtnAnimation.value = withTiming(1, {
      duration: 500,
      easing: Easing.linear,
    });
  };

  const startHeaderBorderAnimation = () => {
    headerBorderAnimation.value = withTiming(1, {
      duration: 500,
      easing: Easing.linear,
    });
  };

  const revertHeaderBorderAnimation = () => {
    // reverting header border with animation animation
    headerBorderAnimation.value = withTiming(0, {
      duration: 500,
      easing: Easing.linear,
    });
  };

  const revertRewardAnimation = () => {
    rewardBtnAnimation.value = withTiming(0, {
      duration: 500,
      easing: Easing.linear,
    });
    pointBtnAnimation.value = withTiming(0, {
      duration: 500,
      easing: Easing.linear,
    });
  };

  const handleSpinNow = () => {
    setButtonDisabled(true);

    // ===========
    firebaseEventLogger('slotMachineScreen__spinNow_tap', {
      buttonName: 'spinNow',
      state: buttonDisabled ? 'disabled' : 'enabled',
      screenName: 'slotMachineScreen',
      userType: 'user',
      interactionType: 'tap',
    });
    // ===========

    getSlotMachineReward(
      getSlotMachineRewardDataMaker(),
      (res) => {
        if (res?.addSpinReward) {
          playSlotWheelSound();
          setShowResultHeader(false);
          if (!startedPlayingAfterOnScreen) {
            setStartedPlayingAfterOnScreen(true);
          }

          setValue(
            res?.addSpinReward?.randomNumbers
              .map(
                (item) =>
                  slotMachinePrizeToKeyMapper[item][searchInFirst ? 0 : 1],
              )
              .join(''),
          );
          setRecentlyWonPoints(res?.addSpinReward?.rewardPointsGiven);
          setSearchInFirst((state) => !state);
          setSpinsLeft(res?.addSpinReward?.spinsLeft);
          if (res?.addSpinReward?.spinsLeft === 0) {
            updateLastFreezeStart();
          }
          setwonRewardType(res?.addSpinReward?.rewardTypeGiven);
          setTimeout(() => {
            const isNoReward =
              res?.addSpinReward?.rewardTypeGiven ===
                rewardTypeEnums.freePoints &&
              res?.addSpinReward?.rewardPointsGiven === 0;
            if (!isNoReward) {
              confettiRef?.current?.reset();
              confettiRef?.current?.play();
              playConfettiSound();
            }
            setShowResultHeader(true);
            startHeaderBorderAnimation();
            timerBin.push(
              setTimeout(() => {
                if (
                  res?.addSpinReward?.rewardTypeGiven ===
                    rewardTypeEnums.freePoints &&
                  res?.addSpinReward?.rewardPointsGiven === 0
                ) {
                  revertHeaderBorderAnimation();
                } else if (
                  res?.addSpinReward?.rewardTypeGiven ===
                  rewardTypeEnums.freePoints
                ) {
                  leftCoinsRef?.current?.reset();
                  leftCoinsRef?.current?.play();
                  startPointBtnAnimation();
                  setActive(0);
                  setPointVal(1);
                  revertHeaderBorderAnimation();
                  updatePoints({
                    count:
                      localStorePoints + res.addSpinReward.rewardPointsGiven,
                  });
                } else if (
                  res?.addSpinReward?.rewardTypeGiven ===
                    rewardTypeEnums.freeCoffee ||
                  res?.addSpinReward?.rewardTypeGiven ===
                    rewardTypeEnums.freeDimsum ||
                  res?.addSpinReward?.rewardTypeGiven ===
                    rewardTypeEnums.freePrint
                ) {
                  rightCoinsRef?.current?.reset();
                  rightCoinsRef?.current?.play();
                  startRewardBtnAnimation();
                  setActive(1);
                  setRewardVal(1);
                  revertHeaderBorderAnimation();
                  updateOtherRewardsCount({
                    count: localStoreOtherRewardsCount + 1,
                  });
                }
                let playSound = true;
                if (
                  res?.addSpinReward?.rewardTypeGiven ===
                    rewardTypeEnums.freePoints &&
                  res?.addSpinReward?.rewardPointsGiven === 0
                ) {
                  playSound = false;
                }
                if (playSound) {
                  playCoinsSound();
                }
                fetchAndUpdateReward({ onMount: false });
                timerBin.push(
                  setTimeout(() => {
                    revertRewardAnimation();
                    setPointVal(0);
                    setRewardVal(0);
                  }, 2600),
                );
              }, 2000),
            );
            Vibration.vibrate([100, 200]);
          }, slotMachineDuration);
          timerBin.push(
            setTimeout(() => {
              setButtonDisabled(false);
            }, slotMachineDuration + 5000),
          );
        }
      },
      (err) => {
        dispatchSnackbar({
          msg: 'Oops! Something went wrong!😓',
          status: SnackbarStatus.info,
          version: SnackbarVersion.v2,
        });
      },
    );
  };

  const fetchAndUpdateReward = ({ onMount = false }) => {
    fetchRewards({
      ticketType: 'REWARD_TICKET',
      userId,
    }).then((r) => {
      // setShowResultHeader(false);
      setwonRewardType('');

      let activeRewardsTemp = 0;
      let activePointsTemp = 0;
      let spinsLeftTemp = 0;
      // extracting reward
      if (r?.findTickets) {
        r?.findTickets?.edges?.forEach((item) => {
          if (item?.node?.status === 'ACTIVE') {
            activeRewardsTemp += 1;
          }
        });
      }

      // extracting points
      if (r?.findUserById) {
        activePointsTemp = r?.findUserById?.wallet?.currentBalance;
      }
      // extracting spins left
      if (r?.getSpinsCount) {
        spinsLeftTemp = r?.getSpinsCount?.spinsLeft;
        // show result screen if spin left === 0 now,
        if (r?.getSpinsCount?.spinsLeft === 0) {
          if (onMount) {
            navigateBack(navigation, userRole);
          } else {
            timerBin.push(
              setTimeout(() => {
                if (startedPlayingAfterOnScreen) {
                  if (
                    localStoreOtherRewardsCount === 0 &&
                    localStorePoints === 0
                  ) {
                    goBack();
                  } else {
                    setShowResult(true);
                  }
                }
              }, 4000),
            );
          }
        }

        // ----setting all values
        setRewardsCount(activeRewardsTemp);
        setPoints(activePointsTemp);
        setSpinsLeft(spinsLeftTemp);
      }
    });
  };

  useEffect(() => {
    setShowResult(false);
    fetchAndUpdateReward({ onMount: false });
    return () => {
      timerBin.forEach((item) => clearTimeout(item));
    };
  }, []);

  useThemedSystemNav(AppTheme.DARK);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      setStartedPlayingAfterOnScreen(false);
      // resetSlotMachineState();
      updateOtherRewardsCount({ count: 0 });
      updatePoints({ count: 0 });

      // ==========
    });

    return unsubscribe;
  }, [navigation]);

  const goBack = () => {
    navigateBack(navigation, userRole);
  };

  const backHandler = () => {
    if (buttonDisabled) {
      return;
    }
    if (!startedPlayingAfterOnScreen) {
      goBack();
    } else if (localStoreOtherRewardsCount === 0 && localStorePoints === 0) {
      goBack();
    } else if (spinsLeft < slotMachineTriesLimit) {
      if (!showResult) {
        setShowResult(true);
      }
    } else {
      goBack();
    }
  };

  // Slot Machine Dimentions
  /**
   *  `DEVICE_AR` comparison:
   *  Thin devices (fold) < normal devices (0.45 - 0.6) < Wide Devices (Ipads, Tablets)
   */
  const DEVICE_AR = windowWidth / windowHeight; // gets the device aspect ratio
  const { slotMachineWidth, slotMachineHeight } = useMemo(() => {
    const SM_AR_HEIGHT = DEVICE_AR < 0.45 ? 0.35 : 0.424; // aspect ratio to calculate slot machine height
    const SM_AR_WIDTH = 0.97; // w/h
    const smH = Math.round(
      Math.max(180, Math.min(500, windowHeight * SM_AR_HEIGHT)),
    );
    const smW = Math.round(SM_AR_WIDTH * smH);

    return {
      slotMachineWidth: smW,
      slotMachineHeight: smH,
    };
  }, [DEVICE_AR]);

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="Spin & Win" />;
  }

  return (
    <DarkThemeWrapper>
      <View flex={1}>
        {showResult && <ResultComp navigation={navigation} goBack={goBack} />}
        {!showResult && (
          <Layout level={2}>
            <TopNavigation
              appearance=""
              level=""
              IconLeft={
                <IconButton
                  onPress={backHandler}
                  appearance="ghost"
                  name="back-outline-300"
                />
              }
            />
            <View flex={1} justifyContent="space-between">
              <ScrollView style={{ flex: 1 }}>
                <View position="absolute">
                  <CustomLottieView
                    autoPlay={false}
                    loop={false}
                    style={{
                      height: 400,
                      width: 400,
                      alignSelf: 'center',
                    }}
                    containerWidth={400}
                    ref={confettiRef}
                    source={require('../../../../../assets/lottie/confetti_lottie.json')}
                  />
                </View>
                <View>
                  <View px="2xl" flex={1}>
                    <View>
                      <View alignItems="center" mb="7xl" zIndex={1}>
                        <View flexDirection="row">
                          <View mr="2xl">
                            <RewardTag
                              iconName="coin-400"
                              title={points > 1 ? 'Points' : 'Point'}
                              rewardsCount={points}
                              styles={pointButtonStyles}
                              pointVal={pointVal}
                              active={active}
                              // disabled={buttonDisabled}
                              onPress={() => {
                                // ===========
                                firebaseEventLogger(
                                  'slotMachineScreen__points_tap',
                                  {
                                    buttonName: 'points',
                                    state: buttonDisabled
                                      ? 'disabled'
                                      : 'enabled',
                                    screenName: 'slotMachineScreen',
                                    userType: 'user',
                                    interactionType: 'tap',
                                  },
                                );

                                // ===========
                                if (buttonDisabled) {
                                  return;
                                }
                                navigation.navigate('pointsScreen');
                              }}
                            />
                          </View>
                          <RewardTag
                            iconName="voucher-400"
                            title={rewardsCount > 1 ? 'Rewards' : 'Reward'}
                            rewardsCount={rewardsCount}
                            styles={rewardButtonStyles}
                            rewardVal={rewardVal}
                            active={active}
                            // disabled={buttonDisabled}
                            onPress={() => {
                              // ===========
                              firebaseEventLogger(
                                'slotMachineScreen__rewards_tap',
                                {
                                  buttonName: 'rewards',
                                  state: buttonDisabled
                                    ? 'disabled'
                                    : 'enabled',
                                  screenName: 'slotMachineScreen',
                                  userType: 'user',
                                  interactionType: 'tap',
                                },
                              );
                              // ===========
                              if (buttonDisabled) {
                                return;
                              }
                              navigation.navigate('RewardsScreen');
                            }}
                          />
                        </View>
                      </View>
                      <View>
                        <MsgBox
                          boxStyles={headerBorderStyles}
                          wonRewardType={wonRewardType}
                          showResultHeader={showResultHeader}
                          points={recentlyWonPoints}
                          startedPlayingAfterOnScreen={
                            startedPlayingAfterOnScreen
                          }
                        />
                      </View>
                      <View textAlign="center" alignItems="center" mt="4xl">
                        <View
                          position="absolute"
                          zIndex={1}
                          top={-170}
                          left={45}
                        >
                          <CustomLottieView
                            autoPlay={false}
                            loop={false}
                            style={{
                              transform: [
                                {
                                  rotate: '-25deg',
                                },
                              ],
                              width: 145,
                              height: 145,
                            }}
                            containerWidth={145}
                            ref={leftCoinsRef}
                            source={require('../../../../../assets/lottie/coins_lottie.json')}
                          />
                        </View>
                        <View
                          position="absolute"
                          zIndex={1}
                          top={-175}
                          left={65}
                          alignItems="center"
                        >
                          <CustomLottieView
                            autoPlay={false}
                            loop={false}
                            style={{
                              transform: [
                                {
                                  rotate:
                                    Platform.OS === 'web' ? '20deg' : '10deg',
                                },
                              ],
                              width: 105,
                            }}
                            containerWidth={105}
                            ref={rightCoinsRef}
                            source={require('../../../../../assets/lottie/coins_lottie.json')}
                          />
                        </View>
                        <View alignItems="center">
                          {/* slot machine comes here */}
                          <Image
                            source={SlotMachineImage}
                            style={{
                              width: slotMachineWidth,
                              height: slotMachineHeight,
                            }}
                          />
                          <View
                            position="absolute"
                            bottom={0.153 * slotMachineHeight}
                          >
                            <SlotMachineComp
                              smHeight={slotMachineHeight}
                              value={value}
                              slotMachineDuration={slotMachineDuration}
                            />
                          </View>
                        </View>
                        <Text size="xs" color="primary.200" mt="lg">
                          {spinsLeft <= 0 ? (
                            `No Spins left`
                          ) : spinsLeft === 1 ? (
                            <>
                              <Text size="sm" color="primary.200">
                                {spinsLeft}{' '}
                              </Text>
                              Spin left
                            </>
                          ) : (
                            <>
                              <Text size="sm" color="primary.200">
                                {spinsLeft}{' '}
                              </Text>
                              Spins left
                            </>
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </ScrollView>
              <View px="2xl" mb={DEVICE_AR > 0.5 ? '9xl' : '9xl+9xl'}>
                <Button
                  size="xl"
                  appearance="filled"
                  onPress={handleSpinNow}
                  state={
                    spinsLeft === 0 || buttonDisabled ? 'disabled' : 'enabled'
                  }
                >
                  Spin Now
                </Button>
              </View>
            </View>
          </Layout>
        )}
      </View>
    </DarkThemeWrapper>
  );
};

export default SlotMachineScreen;
