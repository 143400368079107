import React from 'react';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import BonusInfoCard from './BonusInfoCard';

type BonusTaskInfoTileProps = {
  taskCount: number;
  status: 'on-time' | 'delayed';
};

const resolveIndicatorClr = (status: string) => {
  switch (status) {
    case 'on-time':
      return 'success.500';
    case 'delayed':
      return 'error.500';
    default:
      return 'primary.10';
  }
};

const BonusTaskInfoTile = ({ taskCount, status }: BonusTaskInfoTileProps) => {
  const indicatorClr = resolveIndicatorClr(status);
  const statusText = status === 'on-time' ? 'On-Time' : 'Delayed';

  return (
    <BonusInfoCard>
      <Text mb="sm" size="xl" weight="medium" color="primary.10">
        {taskCount}
      </Text>
      <View flexDirection="row" alignItems="center">
        <View
          borderRadius="md"
          mr="sm"
          width="6"
          height="6"
          bg={indicatorClr}
        />
        <Text size="sm" weight="regular" color="primary.200">
          {statusText}
        </Text>
      </View>
    </BonusInfoCard>
  );
};

export default BonusTaskInfoTile;
