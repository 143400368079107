/**
 * @generated SignedSource<<ad9a64bc4ff7079cd74db3d8d2873111>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type surveySubscriptionSubscription$variables = {
  formId: string;
  userId: string;
};
export type surveySubscriptionSubscription$data = {
  readonly surveySubmission: {
    readonly form: {
      readonly eventId: string | null;
      readonly formId: string | null;
      readonly hidden: any | null;
      readonly landedAt: any | null;
      readonly submittedAt: any | null;
    } | null;
  } | null;
};
export type surveySubscriptionSubscription = {
  response: surveySubscriptionSubscription$data;
  variables: surveySubscriptionSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "formId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "formId",
        "variableName": "formId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "typeFromResponseOut",
    "kind": "LinkedField",
    "name": "surveySubmission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "typeformResponse",
        "kind": "LinkedField",
        "name": "form",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eventId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hidden",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "landedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submittedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "surveySubscriptionSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "surveySubscriptionSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "02421db5f041ba67cd9578d6c6276fec",
    "id": null,
    "metadata": {},
    "name": "surveySubscriptionSubscription",
    "operationKind": "subscription",
    "text": "subscription surveySubscriptionSubscription(\n  $userId: ID!\n  $formId: ID!\n) {\n  surveySubmission(userId: $userId, formId: $formId) {\n    form {\n      eventId\n      formId\n      hidden\n      landedAt\n      submittedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7e1174e30946fa148b5f8c2801cd6c1";

export default node;
