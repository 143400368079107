import React, { useEffect } from 'react';
import { Image, Pressable } from 'react-native';
import LottieView from 'lottie-react-native';
import { Text, View } from '../../../../components/new';
import useCountdown from '../../../../utilities/customHooks/useCountDown';
import CommonLayout from './components/CommonLayout';
import BackButton from './components/BackButton';
import HiddenInput from './components/HiddenInput';
import { EventDataOutput, analytics } from './analytics';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import MemberInfo from './components/MemberInfo';
import { FontWeights } from '../../../../themes/new/helper';

const BORDER_CLR = 'gold.500';
const BM_LOGO = require('../../../../../assets/images/black_membership/black-member-logo-2.webp');
const CUP_LOTTIE = require('../../../../../assets/images/black_membership/lottie/cup.json');
const PERCENT_LOTTIE = require('../../../../../assets/images/black_membership/lottie/percent-gold.json');
const PARKING_LOTTIE = require('../../../../../assets/images/black_membership/lottie/parking.json');
const FOOD_LOTTIE = require('../../../../../assets/images/black_membership/lottie/food.json');

type AddonConfigType = {
  additionalCashback: {
    refType: string;
    cashback: {
      type: string;
      value: number;
    };
  }[];
  additionalDiscount: {
    refType: string;
    discount: {
      type: string;
      value: number;
    };
  }[];
};

const generateMembershipBenefitsData = (addonConfig: AddonConfigType) => {
  const labels = [];
  let cashbackPerc = null;

  if (addonConfig?.additionalCashback?.length) {
    if (addonConfig?.additionalCashback[0]?.cashback?.type === 'PERCENTAGE') {
      cashbackPerc =
        addonConfig?.additionalCashback[0]?.cashback?.value ?? null;
    }
  }

  const event = addonConfig?.additionalDiscount?.find(
    (cb) => cb?.refType === 'EVENT',
  );

  const parking = addonConfig?.additionalDiscount?.find(
    (cb) => cb?.refType === 'PARKING',
  );

  if (event && event?.discount?.type === 'PERCENTAGE') {
    labels.push(`Flat ${event?.discount?.value}% off on event bookings`);
  } else {
    labels.push('');
  }

  if (parking && parking?.discount?.type === 'PERCENTAGE') {
    labels.push(`Flat ${parking?.discount?.value}% off on parking fee`);
  } else {
    labels.push('');
  }

  if (cashbackPerc) {
    labels.push(`Flat ${cashbackPerc}% cashback on F&B`);
  } else {
    labels.push('');
  }

  labels.push('1 complimentary coffee monthly');

  return labels;
};

const BenefitView = ({
  lottieImg,
  label,
  border = false,
  bottomSpace = 'none',
  iconSize,
}: {
  lottieImg: any;
  label: string;
  border?: boolean;
  bottomSpace?: string;
  iconSize: number;
}) => (
  <View
    flex={1}
    py="3xl"
    px="9xl"
    alignItems="center"
    justifyContent="center"
    borderRightWidth={border ? 'xs' : 0}
    borderColor={BORDER_CLR}
  >
    <View width={iconSize} height={iconSize}>
      <LottieView
        autoPlay
        loop
        style={{
          height: iconSize,
          width: iconSize,
        }}
        source={lottieImg}
      />
    </View>
    <Text
      mt="2xl"
      mb={bottomSpace}
      textAlign="center"
      size="2xl"
      weight={FontWeights.MEDIUM}
      color="gold.500"
    >
      {label}
    </Text>
  </View>
);

const RecallPerksScreen = ({ navigation, route }) => {
  const {
    eventData,
    addonConfig,
  }: { eventData: EventDataOutput; addonConfig: string } = route.params;
  const labels = addonConfig
    ? generateMembershipBenefitsData(JSON.parse(addonConfig))
    : [];
  const { countdown, startCountdown } = useCountdown(59, {
    onComplete: () => {
      navigateToHomeScreen();
    },
  });

  useEffect(() => {
    startCountdown();
    analytics.perksScreen.landed(eventData);
  }, []);

  const navigateToHomeScreen = () => {
    navigation.replace('kioskRecall');
  };

  return (
    <CommonLayout>
      <HiddenInput />
      <View
        overflow="hidden"
        p="9xl"
        flex={1}
        width="100%"
        justifyContent="space-between"
      >
        <View>
          <BackButton
            onBack={() => {
              analytics.perksScreen.backClicked(eventData);
              navigateToHomeScreen();
            }}
          />
          <View mt="9xl" alignItems="center">
            <DarkThemeWrapper>
              <View
                p="4xl"
                alignItems="center"
                borderWidth="xs"
                borderRadius="lg"
                borderColor="#151412"
                width="100%"
                overflow="hidden"
                bg="opacity.90"
              >
                <Image source={BM_LOGO} style={{ width: 384, height: 60 }} />
                <Text mt="lg" size="md" color="primary.300">
                  --- Membership Benefits ---
                </Text>
                <View width="100%" px="2xl" mt="9xl" mb="2xl">
                  <View
                    flexDirection="row"
                    borderBottomWidth="xs"
                    borderColor={BORDER_CLR}
                  >
                    <BenefitView
                      lottieImg={PERCENT_LOTTIE}
                      label={labels[0] ?? ''}
                      border
                      bottomSpace="3xl"
                      iconSize={90}
                    />
                    <BenefitView
                      lottieImg={PARKING_LOTTIE}
                      label={labels[1] ?? ''}
                      bottomSpace="3xl"
                      iconSize={90}
                    />
                  </View>
                  <View flexDirection="row">
                    <BenefitView
                      lottieImg={FOOD_LOTTIE}
                      label={labels[2] ?? ''}
                      border
                      iconSize={90}
                    />
                    <BenefitView
                      lottieImg={CUP_LOTTIE}
                      label={labels[3] ?? ''}
                      iconSize={90}
                    />
                  </View>
                </View>
              </View>
            </DarkThemeWrapper>
          </View>
          <View mt="9xl+5xl">
            <MemberInfo
              withStoreBtn
              label={
                <>
                  Become a 32nd Plus member & get{' '}
                  <Text color="gold.50">FLAT 50% Off!</Text>
                </>
              }
            />
          </View>
        </View>
        <View>
          <Pressable
            onPress={() => {
              analytics.perksScreen.sessionEnded(eventData);
              navigateToHomeScreen();
            }}
          >
            <View
              mt="2xl"
              alignItems="center"
              py="9xl"
              borderRadius="20"
              borderWidth="xs"
              borderColor="primary.100"
              bg="transparent"
            >
              <Text
                textAlign="center"
                size="3xl"
                weight="medium"
                color="primary.500"
              >
                End Session ({countdown}s)
              </Text>
            </View>
          </Pressable>
        </View>
      </View>
    </CommonLayout>
  );
};

export default RecallPerksScreen;
