import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum FeedbackScreenSteps {
  SELECT_RATING = 'select_rating',
  RATING_DETAILS = 'rating_details',
  RATING_SUCCESS = 'rating_success',
}

export interface FeedbackModalStoreInterface {
  isOpen: boolean;
  currentScreen: FeedbackScreenSteps;
  rating: number;
  feedbackType: string;
  parkingId: string;
  restaurantId: string;
  invoiceId: string;
  pointsGiven: number;
  onSuccess: boolean;
  setIsOpen: (open: boolean) => void;
  setRating: (rating: number) => void;
  setFeedbackType: (feedbackType: string) => void;
  setCurrentScreen: (currentScreen: FeedbackScreenSteps) => void;
  setParkingId: (parkingId: string) => void;
  setRestaurantId: (restaurantId: string) => void;
  setInvoiceId: (invoiceId: string) => void;
  setPointsGiven: (pointsGiven: number) => void;
  setOnSuccess: (onSuccess: boolean) => void;
  setToDefault: () => void;
}

const useFeedbackModalStore = create<FeedbackModalStoreInterface>()(
  devtools(
    (set, get) => ({
      isOpen: false,
      currentScreen: FeedbackScreenSteps.SELECT_RATING,
      rating: 0,
      feedbackType: '',
      parkingId: '',
      restaurantId: '',
      invoiceId: '',
      pointsGiven: 0,
      onSuccess: false,
      setIsOpen: (open: boolean) =>
        set((state) => {
          if (
            !open &&
            state.currentScreen === FeedbackScreenSteps.RATING_DETAILS
          ) {
            return {
              isOpen: false,
              currentScreen: FeedbackScreenSteps.SELECT_RATING,
            };
          }
          return {
            isOpen: open,
            currentScreen: FeedbackScreenSteps.SELECT_RATING,
          };
        }),
      setRating: (rating: number) =>
        set((state) => ({
          rating,
        })),
      setCurrentScreen: (currentScreen: FeedbackScreenSteps) =>
        set((state) => ({
          currentScreen,
        })),
      setFeedbackType: (feedbackType: string) =>
        set((state) => ({
          feedbackType,
        })),
      setParkingId: (parkingId: string) =>
        set((state) => ({
          parkingId,
        })),
      setRestaurantId: (restaurantId: string) =>
        set((state) => ({
          restaurantId,
        })),
      setInvoiceId: (invoiceId: string) =>
        set((state) => ({
          invoiceId,
        })),
      setPointsGiven: (pointsGiven: number) =>
        set((state) => ({
          pointsGiven,
        })),
      setOnSuccess: (onSuccess: boolean) =>
        set((state) => ({
          onSuccess,
        })),
      setToDefault: () =>
        set(() => ({
          isOpen: false,
          currentScreen: FeedbackScreenSteps.SELECT_RATING,
          feedbackType: '',
          parkingId: '',
          restaurantId: '',
          invoiceId: '',
          pointsGiven: 0,
          rating: 0,
        })),
    }),
    {
      name: 'FeedbackModalStore',
    },
  ),
);

export default useFeedbackModalStore;
