import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const billUploadReviewerHomeScreenQuery = graphql`
  query billUploadReviewerHomeScreenQuery(
    $filter: FilterFindManybillUploadFindBillTCInput
    $sort: SortConnectionbillUploadFindBillTCEnum
    $count: Int!
    $cursor: String
  ) {
    ...billUploadReviewerHomeScreenQuery__findBillsFragment
      @arguments(filter: $filter, sort: $sort, count: $count, cursor: $cursor)
  }
`;

export const findBillsFragment = graphql`
  fragment billUploadReviewerHomeScreenQuery__findBillsFragment on Query
  @refetchable(
    queryName: "billUploadReviewerHomeScreenQuery__findBillsFragmentQuery"
  )
  @argumentDefinitions(
    filter: { type: "FilterFindManybillUploadFindBillTCInput" }
    sort: { type: "SortConnectionbillUploadFindBillTCEnum" }
    count: { type: "Int!" }
    cursor: { type: "String" }
  ) {
    findBills(filter: $filter, sort: $sort, first: $count, after: $cursor)
      @connection(
        key: "billUploadReviewerHomeScreenQuery__findBillsFragmentQuery__findBills"
      ) {
      edges {
        node {
          userId
          _id
          created_at
          userInputs {
            orderType
            orderId
            priceDetails {
              netAmount
            }
            billImage {
              bucket
              key
            }
          }
          user {
            contact {
              phone {
                prefix
                number
              }
            }
          }
          status
          ocrResponseParsed {
            bill {
              date
              billId
            }
          }
          restaurant {
            displayName
          }
        }
      }
    }
  }
`;
