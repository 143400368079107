import React from 'react';
import { Image } from 'react-native';

const GainIcon = require('../../../../../assets/gain-icon.png');
const LossIcon = require('../../../../../assets/loss-icon.png');

type GainLossIconProps = {
  isGain: boolean;
};

const GainLossIcon = ({ isGain = true }: GainLossIconProps) => {
  const icon = isGain ? GainIcon : LossIcon;

  return <Image source={icon} style={{ width: 16, height: 16 }} />;
};

export default GainLossIcon;
