import { graphql, requestSubscription } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

const transactionUpdatedSubscription = graphql`
  subscription transactionUpdatedSubscription($invoiceId: ID!) {
    invoiceUpdated(invoiceId: $invoiceId) {
      _id
      status
      paymentType
      priceDetails {
        grossAmount
      }
    }
  }
`;

export default (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: transactionUpdatedSubscription,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      console.log('error', error);
      // console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
