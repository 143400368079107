import React from 'react';
import { Layout, Text, View } from '../../../components/new';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { FontWeights } from '../../../themes/new/helper';

const RecallSuccessScreen = ({ route, navigation }) => {
  const { orderType, orderId, amount, isVehicleWhitelisted } =
    route?.params ?? {};

  return (
    <Layout level={2}>
      <View flex={1}>
        <View height="100%" alignItems="center" justifyContent="center">
          <CustomLottieView
            autoPlay
            loop={false}
            onAnimationFinish={() => {
              if (isVehicleWhitelisted) {
                navigation.popToTop();
              } else if (orderType && orderId && amount) {
                navigation.replace('paymentAmountScreen', {
                  orderType,
                  orderId,
                  amount,
                });
              }
            }}
            style={{
              height: 200,
              width: 315,
            }}
            source={require('../../../../assets/lottie/car-recalled.json')}
            containerWidth={315}
          />
          <Text
            mt="2xl"
            size="2xl"
            weight={FontWeights.MEDIUM}
            color="primary.500"
            textAlign="center"
          >
            Your car has been recalled successfully!
          </Text>
        </View>
      </View>
    </Layout>
  );
};

export default RecallSuccessScreen;
