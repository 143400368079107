/* eslint-disable no-nested-ternary */
import React from 'react';
import IcoMoon from 'react-icomoon';
import { get } from 'lodash';
import { Platform } from 'react-native';
import { Path, Svg } from 'react-native-svg';
// import { color, space } from 'styled-system';
import { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import { getTheme, themeModes } from '../../../../themes/new/theme';

const iconSet = require('../../../../../assets/fonts/icomoon/selection.json');
// import { createIconSetFromIcoMoon } from '@expo/vector-icons';

// const Icon = createIconSetFromIcoMoon(
//   require('../../../../../assets/fonts/icomoon/selection.json'),
//   'IcoMoon',
//   'fonts/icomoon/fonts/icomoon.ttf',
// );

const CustomIcon = ({ color, ...props }) => {
  const nativeObj =
    Platform.OS !== 'web'
      ? { native: true, SvgComponent: Svg, PathComponent: Path }
      : {};
  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider
          theme={
            theme.currentThemeMode === themeModes.light
              ? getTheme(themeModes.light)
              : getTheme(themeModes.dark)
          }
        >
          <IcoMoon
            iconSet={iconSet}
            {...nativeObj}
            {...props}
            color={get(getTheme(theme.currentThemeMode).colors, color)}
          />
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

export default CustomIcon;
