/**
 * @generated SignedSource<<e24dceabf15147bbd8d657c9f00b8cfb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type searchParkingsFilterInput = {
  campus: FilterFindManyParkingCampusInputsearchInput;
  parkingTag?: string | null;
  search: searchParkingsFilterInputSearch;
};
export type FilterFindManyParkingCampusInputsearchInput = {
  _id: any;
};
export type searchParkingsFilterInputSearch = {
  vehicleOrPhoneOrTag?: string | null;
  vehicleRef?: string | null;
};
export type searchParkingScreenQuery$variables = {
  filter: searchParkingsFilterInput;
};
export type searchParkingScreenQuery$data = {
  readonly searchParking: ReadonlyArray<{
    readonly parkingTag: string | null;
    readonly tasks: ReadonlyArray<{
      readonly _id: any;
      readonly history: ReadonlyArray<{
        readonly action: EnumtaskHistoryAction;
      } | null>;
      readonly taskType: EnumtaskTaskType;
      readonly user: {
        readonly contact: {
          readonly phone: {
            readonly number: string;
            readonly prefix: string;
          };
        };
        readonly profileImage: string | null;
      } | null;
      readonly vehicle: {
        readonly registration: {
          readonly plate: string;
        } | null;
      } | null;
    } | null> | null;
  }>;
};
export type searchParkingScreenQuery = {
  response: searchParkingScreenQuery$data;
  variables: searchParkingScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "Parking",
    "kind": "LinkedField",
    "name": "searchParking",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingTag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskHistory",
            "kind": "LinkedField",
            "name": "history",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileImage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContact",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserContactPhone",
                    "kind": "LinkedField",
                    "name": "phone",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prefix",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "searchParkingScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "searchParkingScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82f3048fc467abf3dec008209ecf7615",
    "id": null,
    "metadata": {},
    "name": "searchParkingScreenQuery",
    "operationKind": "query",
    "text": "query searchParkingScreenQuery(\n  $filter: searchParkingsFilterInput!\n) {\n  searchParking(filter: $filter) {\n    parkingTag\n    tasks {\n      _id\n      taskType\n      vehicle {\n        registration {\n          plate\n        }\n      }\n      history {\n        action\n      }\n      user {\n        profileImage\n        contact {\n          phone {\n            prefix\n            number\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "561f568e992d2fbf8c084384c53ad1a7";

export default node;
