/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, View, Text, Icon } from '../../../../components/new';
import {
  formatPlateNo,
  formatTimeToHrsMinSec,
  taskHistoryActionTypes,
} from '../../../../utilities/helper';
import TaskCard from '../../../../components/new/primitive/TaskCard/TaskCard';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import CancelTaskConfirmation from './CancelTaskConfirmation';
import { handlePhone } from '../../../../utilities/handleLinking';
import useTaskStore from '../../../../stores/taskStore';
import useCampusLocation from '../../../../stores/campusLocation';
import RectangularTimer from '../../../../components/new/primitive/Timer/RectangularTimer';
import ParkingETA from '../../../../components/new/custom/ParkingETA';
import { Appearance, State, Status } from '../../../../themes/new/helper';

const RenderItem = ({ item, isSupervisor, selectedIndex }) => {
  const navigation = useNavigation<any>();
  const cancelTaskBottomSheetModalRef = useRef(null);
  const { parkingTaskOpened, setParkingTaskOpened } = useTaskStore(
    (state) => state,
  );

  const cancelTaskConfirmationHandleDismiss = React.useCallback(() => {
    cancelTaskBottomSheetModalRef.current?.hide();
  }, []);

  const handleCancelTaskModalPress = React.useCallback(() => {
    cancelTaskBottomSheetModalRef.current?.show();
  }, []);
  const getFullName = (firstname, lastname) =>
    `${firstname}${lastname !== null ? ` ${lastname}` : ''}`;
  const formattedVehicleNumber = formatPlateNo(
    item?.vehicle?.registration?.plate,
  );
  const valetName = getFullName(
    item?.valet?.name?.first,
    item?.valet?.name?.last,
  );
  const heading = isSupervisor ? formattedVehicleNumber : valetName;
  const subHeading = isSupervisor ? '' : formattedVehicleNumber;
  const acceptedStatus =
    item &&
    item?.history &&
    item?.history?.length &&
    item?.history?.filter(
      (ele) => ele?.action === taskHistoryActionTypes.accepted,
    );
  const arrivedStatus =
    item &&
    item?.history &&
    item?.history?.length &&
    item?.history?.filter(
      (ele) => ele?.action === taskHistoryActionTypes.arrived,
    );
  const lastStatus = item && item?.history && item?.history.slice(-1)[0];
  const taskCreatedTime = isSupervisor
    ? selectedIndex !== 1
      ? new Date(item?.history[0]?.event_at).valueOf()
      : acceptedStatus && acceptedStatus?.length
      ? new Date(acceptedStatus[0]?.event_at).valueOf()
      : new Date(item?.history[0]?.event_at).valueOf()
    : new Date(acceptedStatus[0]?.event_at).valueOf();
  const currentTime = new Date().valueOf();
  const difference = currentTime - taskCreatedTime;
  const timerObj = {
    title1: isSupervisor ? 'Wait Time' : 'Parking Time',
    title2: formatTimeToHrsMinSec(difference / 1000),
    title2Color: 'primary.500',
  };
  if (difference / 1000 > 120 && difference / 1000 < 300) {
    timerObj.title2Color = 'warning.500';
  }
  if (difference / 1000 > 300) {
    timerObj.title2Color = 'error.500';
  }

  const onPressCallValet = () => {
    if (item?.assignedTo !== null) {
      handlePhone(
        // eslint-disable-next-line no-unsafe-optional-chaining
        item?.valet?.contact?.phone?.prefix +
          // eslint-disable-next-line no-unsafe-optional-chaining
          item?.valet?.contact?.phone?.number,
      );
    }
  };
  const headerRightComponent = () =>
    isSupervisor &&
    isValueNullOrEmpty(item?.assignedTo) &&
    selectedIndex === 1 ? (
      <>
        <Icon size="xl" name="no-man-400" color="error.500" />
      </>
    ) : (
      <></>
    );

  const topRightCornerComponent = () =>
    !isValueNullOrEmpty(item?.assignedTo) ? (
      item?.valet?.identityCode ? (
        <View
          borderRadius="full"
          borderWidth="xs"
          borderColor="primary.100"
          py="sm"
          px="md"
          bg="primary.50"
        >
          <Text size="sm" color="primary.500">
            {item?.valet?.identityCode}
          </Text>
        </View>
      ) : (
        <></>
      )
    ) : (
      <View
        borderRadius="full"
        borderWidth="xs"
        borderColor="primary.50"
        p="sm"
      >
        <Icon size="xl" name="car-filled-400" color="primary.100" />
      </View>
    );

  const BottomLeftCornerComponent = () => {
    const { currentLocation } = useCampusLocation((state) => state);
    const ETA = item.parkingETA?.parkingTimePrediction?.filter(
      (ele) => ele?.locationId === currentLocation?._id,
    );

    // ------ current time ------
    const delayAt = ETA.length
      ? new Date(ETA[0]?.acceptedToCompleteTime).valueOf()
      : new Date().valueOf(); // delay time
    const isDelayed = currentTime > delayAt; // check if current time breached delay time

    // ------ difference between current time and delayAt -------
    const delayedDifferenceInMins = (delayAt - currentTime) / 60 / 1000;

    const [delayedTimer, setDelayedTimer] = useState(
      Math.floor(delayedDifferenceInMins),
    ); // 1 min timer for for waiting timer in task card

    // ------ updating timer after every 1 min for timer in task card ------
    useEffect(() => {
      if (currentLocation.name === '32nd') {
        return () => {};
      }
      const delayedInterval = 1000 * 60;
      const delayedTimerInterval = setInterval(() => {
        if (delayedTimer > 0) {
          setDelayedTimer(delayedTimer - 1);
        }
      }, delayedInterval);
      return () => {
        // Useful for cleanup functions
        clearInterval(delayedTimerInterval);
      };
    }, [delayedTimer]);

    if (currentLocation.name === '32nd') {
      return (
        <RectangularTimer
          title1={timerObj?.title1}
          title2={timerObj?.title2}
          title2Color={timerObj?.title2Color}
        />
      );
    }

    return (
      <ParkingETA
        iconColor={
          isDelayed || delayedTimer < 1 ? 'warning.500' : 'success.500'
        }
        time={delayedTimer < 1 || isDelayed ? 'Delayed' : `${delayedTimer} min`}
      />
    );
  };

  const bottomRightCornerComponent = () => <></>;

  const bottomLeftCTAComponentFor32nd = () => {
    const onPressAssignTask = () => {
      navigation.navigate('SearchValetScreen', {
        taskId: item?._id,
      });
    };
    return selectedIndex === 0 ? (
      <Button
        size="md"
        appearance={Appearance.OUTLINE}
        status={Status.PRIMARY}
        onPress={handleCancelTaskModalPress}
        shadow="sm"
      >
        Cancel Task
      </Button>
    ) : isValueNullOrEmpty(item?.assignedTo) ? (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        onPress={onPressAssignTask}
        shadow="sm"
      >
        Assign Valet
      </Button>
    ) : (
      <Button
        size="md"
        appearance={Appearance.OUTLINE}
        status={Status.PRIMARY}
        onPress={onPressAssignTask}
        shadow="sm"
        state={
          lastStatus?.action === taskHistoryActionTypes.started
            ? State.DISABLED
            : State.ACTIVE
        }
      >
        Re-Assign
      </Button>
    );
  };

  const bottomLeftCTAComponentForOtherParking = () => {
    return (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        onPress={onPressCallValet}
        shadow="sm"
      >
        Call Valet
      </Button>
    );
  };

  const bottomRightCTAComponentFor32nd = () => {
    const onPressOnboardNow = () => {
      navigation.navigate('SelectCustomerScreen', {
        vehicleNumber: item?.vehicle?.registration?.plate,
        // vehicleId: item?.vehicle?._id,
        taskId: item?._id,
      });
    };
    return selectedIndex === 0 ? (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        shadow="sm"
        onPress={onPressOnboardNow}
      >
        Onboard Now
      </Button>
    ) : isValueNullOrEmpty(item?.assignedTo) ? (
      <></>
    ) : (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        shadow="sm"
        onPress={onPressCallValet}
      >
        Call Valet
      </Button>
    );
  };
  const bottomRightCTAComponentForOtherParking = () => {
    const onPressCollectKey = () => {
      navigation.navigate('addParkingDetails', {
        parkingId: item?.parkingId,
        taskId: item?._id,
      });
    };
    return (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        shadow="sm"
        state={
          lastStatus?.action === taskHistoryActionTypes.arrived
            ? State.ACTIVE
            : State.DISABLED
        }
        onPress={onPressCollectKey}
      >
        Collect Key
      </Button>
    );
  };

  const isSwipeEnabled =
    selectedIndex === 1 && isSupervisor && arrivedStatus?.length <= 0;

  return (
    <>
      <View px="2xl" my="lg">
        <TaskCard
          blur={item?.blur}
          heading={heading}
          subHeading={subHeading}
          headingRight={headerRightComponent()}
          topRightCorner={topRightCornerComponent()}
          shadow="md"
          bottomLeftCorner={<BottomLeftCornerComponent />}
          bottomRightCorner={bottomRightCornerComponent()}
          bottomLeftCTA={
            isSupervisor
              ? bottomLeftCTAComponentFor32nd()
              : bottomLeftCTAComponentForOtherParking()
          }
          bottomRightCTA={
            isSupervisor ? bottomRightCTAComponentFor32nd() : null
          }
          swipeEnabled={isSwipeEnabled}
          onPressSwipeRight={handleCancelTaskModalPress}
          swipeRightText="Cancel Task"
          preExpanded={parkingTaskOpened === item?._id}
          onCardPressCB={() => {
            if (parkingTaskOpened !== item?._id) {
              setParkingTaskOpened({ id: item?._id });
            } else {
              setParkingTaskOpened({ id: '' });
            }
          }}
        />
      </View>
      <CancelTaskConfirmation
        bottomSheetModalRef={cancelTaskBottomSheetModalRef}
        handleOnDismiss={cancelTaskConfirmationHandleDismiss}
        task={item}
        selectedIndex={selectedIndex}
      />
    </>
  );
};

export default RenderItem;
