import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View } from '../../../../../components/new';

const InvestmentDetailsPlaceholder = () => {
  const { width } = useWindowDimensions();
  const { top } = useSafeAreaInsets();

  return (
    <Placeholder Animation={Fade}>
      <View pt={top}>
        <View>
          <PlaceholderLine height={400} style={{ width, borderRadius: 0 }} />
        </View>
        <View top={-300} px="2xl" alignItems="center">
          <PlaceholderLine
            height={20}
            style={{ width: 200, borderRadius: 16 }}
          />
          <PlaceholderLine
            height={40}
            style={{ width: width - 32, borderRadius: 16 }}
          />
        </View>
        <View top={-250} px="2xl" alignItems="center">
          <PlaceholderLine
            height={50}
            style={{ width: 50, borderRadius: 16 }}
          />
          <PlaceholderLine
            height={40}
            style={{ width: width - 32, borderRadius: 16 }}
          />
          <PlaceholderLine
            height={20}
            style={{ width: 200, borderRadius: 16 }}
          />
        </View>
      </View>
    </Placeholder>
  );
};

export default InvestmentDetailsPlaceholder;

const styles = StyleSheet.create({
  avatar: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 100,
    top: -50,
    left: '50%',
    transform: [{ translateX: -50 }],
  },
});
