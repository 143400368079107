import React from 'react';
import { Text, View } from '..';

interface IdentityCodeProps {
  code: string;
}

const IdentityCode = ({ code = '' }: IdentityCodeProps) => {
  return (
    <View
      borderRadius="full"
      borderWidth="xs"
      borderColor="primary.100"
      py="sm"
      px="md"
      bg="primary.50"
    >
      <Text size="sm" color="primary.500">
        {code}
      </Text>
    </View>
  );
};

export default IdentityCode;
