import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Platform, ScrollView } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import _ from 'lodash';
import { checkVersion } from 'react-native-check-version';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';

import {
  Layout,
  View,
  Text,
  Card,
  Divider,
  TopNavigation,
  IconButton,
} from '../../../components/new';
import NewErrorView from '../../../utilities/NewErrorView';
import Loading from '../../../components/loading.component';
import ProfileCard from './ProfileCard';
import CustomPressable from '../../../components/new/primitive/CustomPressable/CustomPressable';
import useUserStore, { UserRole } from '../../../stores/userStore';
import useAccountScreenData from './hooks/useAccountScreenData';
import useAuthStore from '../../../stores/authStore';
import { MembershipTypes, navigateBack } from '../../../utilities/helper';
import UserProfileCard from './UserProfileCard';
import UpdateAppCard from './UpdateAppCard';
import useCommonStore from '../../../stores/commonStore';
import { appBundleId, installedVersion } from '../../../hooks/useUpdateApp';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import { isValueNullOrEmpty } from '../../../utilities/Utility';

const AccountScreen = ({ refreshAPI, refreshedQueryOptions, variables }) => {
  const navigation = useNavigation();

  useEffect(() => {
    firebaseEventLogger('account__land_Landing', {
      buttonName: 'land',
      screenName: 'account',
      userType: 'user',
      interactionType: InteractionType.LANDING,
    });
  }, []);

  const { apiData, appVersionText, options, isUser, shouldUpdate } =
    useAccountScreenData({
      refreshedQueryOptions,
      variables,
    });

  return (
    <View flex={1}>
      <ScrollView scrollIndicatorInsets={{ right: 1 }}>
        {isUser ? (
          <View mx="2xl" mt="4xl">
            <UserProfileCard refreshAPI={refreshAPI} apiData={apiData} />
          </View>
        ) : (
          <View mx="2xl" mt="4xl">
            <Card>
              <ProfileCard refreshAPI={refreshAPI} apiData={apiData} />
            </Card>
          </View>
        )}
        {shouldUpdate ? (
          <View px="2xl" pt="4xl">
            <UpdateAppCard />
          </View>
        ) : (
          <></>
        )}
        <View pt="2xl" pb="5xl" flex={1}>
          {options?.map((item) => {
            return Object.keys(item).length ? (
              <View mx="2xl" key={item?.label}>
                <CustomPressable
                  appearance="ghost"
                  textSize="md"
                  rightContent="keyboard-arrow-right-300"
                  textColor="primary.300"
                  iconColor="primary.300"
                  onPress={() => {
                    if (!isValueNullOrEmpty(item?.navigation?.screenName)) {
                      navigation.navigate(item?.navigation?.screenName, {
                        ...(item?.navigation?.params || {}),
                      });
                    }
                    item?.onPress();
                  }}
                >
                  {item?.label}
                </CustomPressable>
                <Divider />
              </View>
            ) : (
              <></>
            );
          })}
          <View mt="5xl" alignItems="center">
            <Text size="sm" color="grey.300">
              Version {appVersionText}
            </Text>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const AccountScreenWrapper = ({ navigation }) => {
  const { id: userId } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
      networkCacheConfig: { force: true },
    });

  const refreshAPI = () => {
    setRefreshedQueryOptions((prev) => {
      return {
        ...prev,
        fetchKey: (prev?.fetchKey || 0) + 1,
      };
    });
  };

  const { setShouldUpdate } = useCommonStore((state) => state);
  const checkUpdates = async () => {
    try {
      const updates = await checkVersion({
        bundleId: appBundleId,
        country: 'in',
      });
      if (updates.needsUpdate && installedVersion < updates.version) {
        setShouldUpdate(updates.needsUpdate);
      } else {
        setShouldUpdate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userRole = useUserStore((state) => state.role);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const variables = {
    filter: { valetID: userId, date: new Date() },
    addonsFilter: { type: MembershipTypes.PLUS_MEMBERSHIP },
    userId,
    isNotUser: Boolean(!(userRole === UserRole.USER || !userRole)),
    isLoggedIn,
  };

  useFocusEffect(
    useCallback(() => {
      refreshAPI();
    }, []),
  );

  useEffect(() => {
    if (Platform.OS !== 'web') {
      checkUpdates();
    }
  }, []);

  const showTopNavigator = navigation.getState().type !== 'tab';

  const handleBackPress = useCallback(
    _.throttle(
      () => {
        navigateBack(navigation, userRole);
      },
      1000,
      { trailing: false },
    ),
    [],
  );

  return (
    <Layout level={2} edges={showTopNavigator ? [] : ['top']}>
      {!!showTopNavigator && (
        <TopNavigation
          appearance="ghost"
          level="1"
          textColor="primary.500"
          textSize="md"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={handleBackPress}
            />
          }
        />
      )}
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions?.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refreshAPI}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <View flex={1}>
            <AccountScreen
              refreshAPI={refreshAPI}
              refreshedQueryOptions={refreshedQueryOptions}
              variables={variables}
            />
          </View>
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default AccountScreenWrapper;
