import React from 'react';
import { Image } from 'react-native';
import View from '../../../basic/View/View';
import Text from '../../../primitive/Typography/Text/Text';
import { getTheme } from '../../../../../themes/new/theme';
import { imageTransformation } from '../../../../../utilities/helper';

const theme = getTheme();
const defaultLogo = 'https://ik.imagekit.io/32ndhub/assets/nouser.png';

interface HeaderProps {
  image: string;
  title: string;
}

const Header = ({ image, title }: HeaderProps) => {
  const logoUri = imageTransformation(image || defaultLogo, 42);

  return (
    <View
      borderBottomWidth="xs"
      borderColor="primary.50"
      pt="3xl+xs"
      pb="2xl"
      flexDirection="row"
      alignItems="center"
    >
      <Image
        source={{ uri: logoUri }}
        style={{
          width: 42,
          height: 42,
          borderRadius: theme.radii.full,
          resizeMode: 'cover',
        }}
      />
      <Text numberOfLines={1} pl="lg" size="xl" color="primary.400">
        {title}
      </Text>
    </View>
  );
};

export default Header;
