import React, { useState } from 'react';
import { SafeAreaView } from 'react-native';
import { get } from 'lodash';
import styled from 'styled-components/native';
import View from '../../basic/View/View';
import Text from '../Typography/Text/Text';
import {
  InputLabelTextColorResolver,
  InputStates,
  InputHelperTextColorResolver,
} from './helpers';
// import InputWithIcon from './InputWithIcon';
import OTPInputWithLabel from './OTPInputWithLabel';
import { TextCategories } from '../Typography/Text/helper';

const InputLabelText = styled(Text)`
  color: ${({ variant, theme }) =>
    get(theme.colors, InputLabelTextColorResolver(variant))};
`;

const InputHelpetText = styled(Text)`
  color: ${({ variant, theme }) =>
    get(theme.colors, InputHelperTextColorResolver(variant))};
`;

function OTPInput({
  state = InputStates.default,
  label = '',
  helperText = '',
  onFocus = () => {},
  onBlur = () => {},
  ...props
}) {
  const [localState, setLocalInputState] = useState('');
  return (
    <View flexDirection="column">
      {!!label && (
        <View>
          <InputLabelText
            category={TextCategories.INPUTLABEL}
            variant={state}
            color="primary.200"
            numberOfLines={1}
            size="sm"
            ellipsizemode="tail"
          >
            {label}
          </InputLabelText>
        </View>
      )}

      <View>
        <OTPInputWithLabel
          state={localState || state}
          onFocus={() => {
            if (state === InputStates.default)
              setLocalInputState(InputStates.active);
            onFocus();
          }}
          onBlur={() => {
            setLocalInputState('');
            onBlur();
          }}
          {...props}
        />
      </View>
      {!!helperText && (
        <View mt="sm">
          <InputHelpetText
            variant={state}
            category={TextCategories.HELPERTEXT}
            numberOfLines={2}
            ellipsizemode="tail"
          >
            {helperText}
          </InputHelpetText>
        </View>
      )}
    </View>
  );
}

export default OTPInput;
