import React, { useRef, useState } from 'react';
import { Platform } from 'react-native';
import { View } from '../../../components/new';
import AddButton from './AddButton';
import MenuListSection from './MenuScreenListSection';
import MenuScreenFooter from './MenuFooterSection';
import VariationsBottomSheet from './variationsBottomSheet.component';
import ClearCartModal from '../../../components/modals/ClearCartModal';

const MenuListAndFooter = ({
  // menuData,
  newMenuData,
  headersData,
  selectedIndex,
  setSelectedIndex,
  flatlistRef,
  navigation,
  restaurantId,
  restaurantName,
  // restaurantposId,
  restaurant,
}) => {
  const [removeItem, setRemoveItem] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [repeatLast, setRepeatLast] = useState(false);
  const bottomSheetModalRef = useRef(null);

  return (
    <>
      <MenuListSection
        headersData={headersData}
        newMenuData={newMenuData}
        setSelectedIndex={setSelectedIndex}
        setRemoveItem={setRemoveItem}
        bottomSheetModalRef={bottomSheetModalRef}
        setSelectedMenuItem={setSelectedMenuItem}
        flatlistRef={flatlistRef}
        setRepeatLast={setRepeatLast}
        restaurantName={restaurantName}
        restaurantId={restaurantId}
        setVisible={setVisible}
        // restaurantposId={restaurantposId}
        restaurant={restaurant}
      />
      <View
        position={Platform.OS === 'web' ? 'sticky' : 'absolute'}
        bottom={0}
        left={0}
        right={0}
      >
        <MenuScreenFooter
          navigation={navigation}
          selectedIndex={selectedIndex}
          restaurantId={restaurantId}
          headersData={headersData}
          setSelectedIndex={setSelectedIndex}
          ref={flatlistRef}
          menuData={newMenuData}
          // menuData={menuData.map((item) => {
          //   return {
          //     _id: item._id,
          //     name: item.sectionTitle,
          //     // menuItemsLength: item.menuItems.length,
          //     disabled: false,
          //   };
          // })}
          restaurantName={restaurantName}
          // restaurantposId={restaurantposId}
          restaurant={restaurant}
        />
      </View>
      <VariationsBottomSheet
        ref={bottomSheetModalRef}
        ctaText="Add Item"
        removeItem={removeItem}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
        AddButton={AddButton}
        setRemoveItem={setRemoveItem}
        repeatLast={repeatLast}
        setRepeatLast={setRepeatLast}
        restaurantId={restaurantId}
        restaurantName={restaurantName}
        setVisible={setVisible}
        restaurant={restaurant}
      />
      <ClearCartModal
        visible={visible}
        setVisible={setVisible}
        restaurantName={restaurantName}
        restaurantId={restaurantId}
        navigation={navigation}
      />
    </>
  );
};

export default MenuListAndFooter;
