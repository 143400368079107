import findCampusLocation from '../relay/campusLocationApi';
import VideoMomentsStore from '../stores/VideoMomentsStore';
import useUserStore from '../stores/userStore';
import useAuthStore from '../stores/authStore';
import useCommonStore from '../stores/commonStore';
import { checkUser, LoginUserPayload, loginUserV2 } from '../relay/authApi';
import useLoginModalStore, {
  LoginModalScreenSteps,
  SignUpPayload,
} from '../stores/loginModalStore';
import { convertPhoneNumber } from './Utility';

export const handleLoginUser = (response) => {
  const { setScannedS3Imagekey } = VideoMomentsStore.getState();
  const { setUserDetails } = useUserStore.getState();
  const { setLoginDetails } = useAuthStore.getState();
  const { setCampusId } = useCommonStore.getState();

  if (response?.loginUser?.record?.photoMomentsSearchImage) {
    setScannedS3Imagekey(response.loginUser.record.photoMomentsSearchImage);
  }
  setUserDetails(
    response?.loginUser?.record?.name?.first,
    response?.loginUser?.record?.name?.last,
    response?.loginUser?.record?.role?.name,
    response?.loginUser?.record?.role?.permission,
    response?.loginUser?.record?._id,
    response?.loginUser?.record?.contact,
    response?.loginUser?.record?.role?.name,
    response?.loginUser?.record?.role?.secondaryRole,
    response?.loginUser?.record?.profileImage,
  );
  setLoginDetails(
    true,
    response.loginUser.record.token,
    response.loginUser.record.refreshToken,
  );
  const { name, permission } = response.loginUser.record.role;

  const campusID = response.loginUser.record.role.meta?.campus?._id;

  if (name !== 'user') {
    if (campusID) {
      setCampusId(campusID);
    }
  }
  if (name === 'user') {
    findCampusLocation({})
      .then((res) => {
        setCampusId(res?.findCampusLocation[0]?._id);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const handleWhatsappLogin = (waId: string) => {
  const {
    setIsLoginWhatsappActive,
    setSecondarySignup,
    setMobileNumber,
    setSignupPayload,
    setCurrentScreen,
    setWaId,
    setIsOpen,
    setCountryCallingCode,
  } = useLoginModalStore.getState();
  setIsLoginWhatsappActive(true);
  checkUser({ waId })
    .then((response) => {
      if (
        response?.checkUser === null ||
        response?.checkUser?.firstLogin === false ||
        response?.checkUser?.firstLogin === null
      ) {
        if (response?.checkUser?.firstLogin === false) {
          // set secondary signup
          setSecondarySignup(true);
        }
        // save response signup user
        setMobileNumber(response?.checkUser?.contact?.phone?.number || '');
        setCountryCallingCode(
          convertPhoneNumber(response?.checkUser?.contact?.phone?.prefix || ''),
        );
        const signupData: SignUpPayload = {
          name: {
            first: response?.checkUser?.name?.first || '',
            last: response?.checkUser?.name?.last || '',
          },
        };
        setSignupPayload(signupData);
        setWaId(waId);
        setCurrentScreen(LoginModalScreenSteps.SIGN_UP);
        setIsLoginWhatsappActive(false);
        return null;
      }
      const data: LoginUserPayload = {
        waId,
        contact: {
          phone: {
            number: response.checkUser.contact.phone.number,
            prefix: response.checkUser.contact.phone.prefix,
          },
        },
        pin: '7869',
      };
      return loginUserV2(data);
    })
    .then((result) => {
      if (result) {
        handleLoginUser(result);
        setIsLoginWhatsappActive(false);
        setIsOpen(false);
        setCurrentScreen(LoginModalScreenSteps.ENTER_PHONE_NUMBER);
        setMobileNumber('');
        setSignupPayload({ name: { first: '', last: '' } });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
