import styled from 'styled-components/native';
import { Text, View } from '../../../components/new';
import imageBg from '../../../../assets/images/noSearchImage.webp';

const StyledImage = styled.Image`
  width: 225px;
  height: 150px;
`;

const NoMatchSearchSection = ({ searchInputValue }) => {
  return (
    <View mb="4xl">
      <View justifyContent="center" alignItems="center">
        <StyledImage source={imageBg} />
      </View>
      <View justifyContent="center" alignItems="center" mt="lg">
        <View maxWidth={204}>
          <Text size="md" color="primary.200" textAlign="center">
            Sorry we couldn’t find any matches for {searchInputValue}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default NoMatchSearchSection;
