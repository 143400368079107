import React, { Suspense, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Layout } from '../../components/new';
import Loading from '../../components/loading.component';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import Store from './storeDetail';
import useUserStore from '../../stores/userStore';
import useCommonStore from '../../stores/commonStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';

const StorePage = ({ navigation, route }) => {
  const storeId = route?.params?.storeId;
  const [netStatus, setNetStatus] = useState(true);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const userRole = useUserStore((state) => state.role);
  const campusID = useCommonStore((state) => state.campusId);

  const storeScreenQuery = graphql`
    query storeScreenQuery($filter: findGalleryFilterInput!) {
      findStore(filter: $filter) {
        ...storeDetail_findStore
      }
    }
  `;

  const RenderStoreScreen = (props) => {
    const { variables } = props;
    const { findStore } =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useLazyLoadQuery(storeScreenQuery, variables, {
        ...refreshedQueryOptions,
      });

    return (
      <Store
        navigation={navigation}
        storeId={storeId}
        findStore={findStore}
        userRole={userRole}
      />
    );
  };

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          {/* <RenderStoreScreen */}
          {/*   variables={{ */}
          {/*     filter: { _id: storeId, campus: { _id: campusID } }, */}
          {/*   }} */}
          {/*   fetchKey={refreshedQueryOptions.fetchKey} */}
          {/* /> */}
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default StorePage;
