/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-extend-native */
/* eslint-disable no-continue */
import React, { useState, useRef, useEffect } from 'react';
import { ScrollView } from 'react-native';
import View from '../basic/View/View';
import Chip from '../primitive/Chip/Chip';
import { Appearance } from '../../../themes/new/helper';

const ChipFilter = ({
  data,
  //   selectedIndex,
  //   setSelectedIndex,
  callbackFn,
  ...rest
}) => {
  const [offset, setOffset] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [chipData, setChipData] = useState(data);
  const scrollRef = useRef(null);

  //   const setData = () => {
  //     const filterData =
  //       data?.length &&
  //       data?.map((item, index) => {
  //         return item?.id === 0
  //           ? { ...item, state: !item?.disabled ? 'selected' : 'disabled' }
  //           : { ...item, state: !item?.disabled ? 'active' : 'disabled' };
  //       });
  //     setChipData(filterData);
  //   };

  //   useEffect(() => {
  //     setData();
  //   }, []);

  //   const getState = (item, index) => {
  //     if (item.disabled) {
  //       setChipState('disabled');
  //     }
  //   };

  //   console.log('offset.....', offset);

  //   const scrollTo = () => {
  //     const findIndex =
  //       chipData?.length &&
  //       chipData?.findLastIndex((item) => item?.state === 'selected');
  //     console.log('findindex,,,,,', findIndex);
  //     if (scrollRef?.current) {
  //       scrollRef.current.scrollTo({
  //         x: offset[findIndex] - 22,
  //         y: 0,
  //         animated: true,
  //       });
  //     }
  //   };

  //   useEffect(() => {
  //     // if (scrollRef?.current) {
  //     //   scrollRef.current.scrollTo({
  //     //     x: offset[selectedIndex] - 22,
  //     //     y: 0,
  //     //     animated: true,
  //     //   });
  //     // }
  //     scrollTo();
  //   }, [chipData]);

  const sortByAppliedFilter = (a, b) => {
    if (a.state === 'selected' && b.state === 'active') {
      return -1;
    }
    if (a.state === 'selected' && b.state === 'disabled') {
      return -1;
    }
    if (a.state === 'active' && b.state === 'disabled') {
      return -1;
    }
    if (a.state === 'active' && b.state === 'selected') {
      return 1;
    }
    if (a.state === 'disabled' && b.state === 'active') {
      return 1;
    }
    if (a.state === 'disabled' && b.state === 'selected') {
      return 1;
    }
    return 0;
  };

  const sortByAllAppliedFilter = (a) => {
    // all should always be at index 0
    if (a.name.toLowerCase() === 'all') {
      return -1;
    }
    return 0;
  };

  const pressHandler = (element, activeIndex) => {
    if (!element.disabled) {
      setSelectedIndex(activeIndex);
      //   if (element?.id === 0) {
      //     setChipData([]);
      //     // setData();
      //   } else {
      //     const filterData =
      //       chipData?.length &&
      //       chipData.map((item, index) => {
      //         return item?.id === element?.id
      //           ? {
      //               ...item,
      //               state: item?.state === 'selected' ? 'active' : 'selected',
      //             }
      //           : { ...item, state: item?.id === 0 ? 'active' : item?.state };
      //       });
      //     // setChipData([]);
      //     setChipData(filterData);
      //   }
    }
    // scrollTo(element);
    // if (element?.state !== 'selected') {
    callbackFn(element, activeIndex);
    // }
  };

  // Array.prototype.swap = function (x, y) {
  //   const temp = this[x];
  //   this[x] = this[y];
  //   this[y] = temp;
  //   return this;
  // };

  // const sortSelectedActive = (sortData) => {
  //   if (sortData?.length) {
  //     let low = 0;
  //     let high = sortData.length - 1;
  //     let mid = 0;

  //     while (mid <= high) {
  //       if (sortData[mid]?.state === 'selected') {
  //         sortData.swap(low, mid);
  //         low += 1;
  //         mid += 1;
  //         continue;
  //       }
  //       if (sortData[mid]?.state === 'active') {
  //         mid += 1;
  //         continue;
  //       }
  //       if (sortData[mid]?.state === 'disabled') {
  //         sortData.swap(mid, high);
  //         high -= 1;
  //         continue;
  //       }
  //     }
  //   }
  //   return sortData;
  // };

  // console.log('sortttttt', sortSelectedActive(chipData));

  return (
    <ScrollView
      ref={scrollRef}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      {chipData
        .sort(sortByAppliedFilter)
        .sort(sortByAllAppliedFilter)
        .map((item, index) => (
          <View
            key={item?.id}
            pl={index === 0 ? '2xl' : 0}
            pr={index === data.length - 1 ? '2xl' : 0}
            mr="lg"
            my="sm"
            onLayout={(event) => {
              const { x } = event.nativeEvent.layout;
              setOffset((items) => [...items, x]);
            }}
          >
            <Chip
              state={item?.state}
              onPress={() => pressHandler(item, index)}
              size="xs"
              iconLeft=""
              iconRight={item.filter?.length ? 'down-outline-300' : ''}
              appearance={Appearance.FILLED}
            >
              {item.name}
            </Chip>
          </View>
        ))}
    </ScrollView>
  );
};

export default ChipFilter;
