/**
 * @generated SignedSource<<a0d029e7e84af8404ab1c5beb7aa0772>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Enumrestaurants2Amenities = "Bar_Area" | "Buffet" | "Catering" | "Charging_Point" | "Coat_Check" | "Dance_Floor" | "Delivery" | "Event_Space" | "Family_Play_Area" | "Fine_Dining" | "Karaoke" | "Kids_Menu" | "Late_Night_Dining" | "Live_Cooking_Stations" | "Live_Sports_Screening" | "Meeting_Rooms" | "On_Site_Parking" | "Outdoor_Barbecue" | "Outdoor_Coolers" | "Outdoor_Heaters" | "Outdoor_Seating" | "Patio" | "Photobooth" | "Private_Dining" | "Pure_Veg" | "Reservations" | "Rooftop_Dining" | "Serves_Alcohol" | "Smoking_Area" | "Takeout" | "Valet_Parking" | "Wheelchair_Accessible" | "Wifi" | "%future added value";
export type Enumrestaurants2MediaGalleryMediaMediaType = "image" | "video" | "%future added value";
export type Enumrestaurants2MediaGallerySectionTitle = "Ambience" | "Awards___Recognitions" | "Food" | "Founder_Story" | "%future added value";
export type Enumrestaurants2Occasion = "celebration" | "corporate" | "date_night" | "reunion" | "%future added value";
export type Enumrestaurants2Type = "RESTAURANT" | "STORE" | "%future added value";
export type findRestaurantsFilterInput = {
  _id?: any | null;
  amenities?: ReadonlyArray<Enumrestaurants2Amenities | null> | null;
  campus: FilterFindManyrestaurants2CampusInput;
  cusines?: ReadonlyArray<string | null> | null;
  imageSection?: string | null;
  occasion?: ReadonlyArray<Enumrestaurants2Occasion | null> | null;
  openHours?: string | null;
  outdoorAndEventFlag?: boolean | null;
  outdoorSeating?: boolean | null;
  overAllRating?: number | null;
  ownerId?: any | null;
  pureVeg?: boolean | null;
  search?: findRestaurantsFilterInputSearch | null;
  serveAlcohol?: boolean | null;
  type?: Enumrestaurants2Type | null;
  withOffer?: boolean | null;
};
export type FilterFindManyrestaurants2CampusInput = {
  _id: any;
};
export type findRestaurantsFilterInputSearch = {
  restaurantTitle?: string | null;
};
export type RestaurantScreenGalleryQuery$variables = {
  filter: findRestaurantsFilterInput;
};
export type RestaurantScreenGalleryQuery$data = {
  readonly findRestaurants2: ReadonlyArray<{
    readonly imageSection: ReadonlyArray<{
      readonly description: string | null;
      readonly displayOrder: number | null;
      readonly media: ReadonlyArray<{
        readonly coverImage: boolean | null;
        readonly description: string | null;
        readonly displayOrder: number | null;
        readonly mediaType: string | null;
        readonly mediaURL: string | null;
        readonly primeImage: string | null;
        readonly sectionCover: boolean | null;
        readonly title: string | null;
      } | null> | null;
      readonly sectionTitle: string | null;
    } | null> | null;
    readonly mediaGallery: ReadonlyArray<{
      readonly _id: any | null;
      readonly description: string | null;
      readonly displayOrder: number;
      readonly media: ReadonlyArray<{
        readonly _id: any | null;
        readonly description: string | null;
        readonly displayOrder: number;
        readonly mediaType: Enumrestaurants2MediaGalleryMediaMediaType;
        readonly mediaURL: string;
        readonly sectionCover: boolean | null;
        readonly title: string | null;
      } | null> | null;
      readonly sectionTitle: Enumrestaurants2MediaGallerySectionTitle;
    } | null> | null;
  }>;
};
export type RestaurantScreenGalleryQuery = {
  response: RestaurantScreenGalleryQuery$data;
  variables: RestaurantScreenGalleryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionTitle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaURL",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionCover",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "restaurants2",
    "kind": "LinkedField",
    "name": "findRestaurants2",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "imageSectionOutput",
        "kind": "LinkedField",
        "name": "imageSection",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MediaTypeOutput",
            "kind": "LinkedField",
            "name": "media",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coverImage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "primeImage",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurants2MediaGallery",
        "kind": "LinkedField",
        "name": "mediaGallery",
        "plural": true,
        "selections": [
          (v8/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2MediaGalleryMedia",
            "kind": "LinkedField",
            "name": "media",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v4/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RestaurantScreenGalleryQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RestaurantScreenGalleryQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "ce801564a78da68dd30c9f9beaf3735b",
    "id": null,
    "metadata": {},
    "name": "RestaurantScreenGalleryQuery",
    "operationKind": "query",
    "text": "query RestaurantScreenGalleryQuery(\n  $filter: findRestaurantsFilterInput!\n) {\n  findRestaurants2(filter: $filter) {\n    imageSection {\n      sectionTitle\n      displayOrder\n      description\n      media {\n        title\n        description\n        coverImage\n        primeImage\n        mediaURL\n        mediaType\n        sectionCover\n        displayOrder\n      }\n    }\n    mediaGallery {\n      _id\n      sectionTitle\n      description\n      displayOrder\n      media {\n        _id\n        title\n        description\n        mediaURL\n        mediaType\n        sectionCover\n        displayOrder\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd821b513cccc016ea6096e6819d75e1";

export default node;
