import React, { useState } from 'react';
import { Svg, Circle, G, Rect } from 'react-native-svg';
import { StyleSheet } from 'react-native';
import View from '../../../basic/View/View';
import { getTheme, themeModes } from '../../../../../themes/new/theme';

const theme = getTheme(themeModes.light);

const CIRCLE_DIA = 38;
const BORDER_WIDTH = theme.borderWidths.xs;
const CIRCLE_CLR = theme.colors.background.secondary.base;
const BORDER_CLR = theme.colors.primary[50];
const DASH_LINE_SPACING = 16;

const Divider = ({ circleBg = CIRCLE_CLR }: { circleBg?: string }) => {
  const [width, setWidth] = useState(0);
  const dashes = new Array(Math.floor(width / DASH_LINE_SPACING)).fill(null);

  return (
    <View
      onLayout={(event) => {
        setWidth(event.nativeEvent.layout.width);
      }}
      width="100%"
      height={CIRCLE_DIA + BORDER_WIDTH}
    >
      <Svg width="100%" height="100%" style={[StyleSheet.absoluteFillObject]}>
        <Circle
          cx={-3}
          cy="50%"
          r={CIRCLE_DIA / 2}
          fill={circleBg}
          strokeWidth={BORDER_WIDTH}
          stroke={BORDER_CLR}
        />
        <G>
          {dashes.map((_, index) => (
            <Rect
              key={index}
              x={19}
              y="48%"
              width={10}
              height={2}
              fill={BORDER_CLR}
              translateX={DASH_LINE_SPACING * index}
            />
          ))}
        </G>
        <Circle
          cx={width + 3}
          cy="50%"
          r={CIRCLE_DIA / 2}
          fill={circleBg}
          strokeWidth={BORDER_WIDTH}
          stroke={BORDER_CLR}
        />
      </Svg>
    </View>
  );
};

export default Divider;
