/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const transactionsRevenueReportFragment = graphql`
  fragment transactionsRevenueReportFragment on Query
  @refetchable(queryName: "transactionsRevenueReportFragmentQuery")
  @argumentDefinitions(filter: { type: "PaymentReportFilter!" }) {
    reports: getPaymentReports(filter: $filter) {
      date
      period
      transactionsCount
      grossAmount
      netAmount
      discountGiven
      rewardPointsUsed
    }
  }
`;
