import React, { useEffect } from 'react';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import { useWindowDimensions, StyleSheet, Platform } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import View from '../basic/View/View';
import Button from '../primitive/Button/Button';
import DarkThemeWrapper from '../../../screens/new/wrapperComp/DarkThemeWrapper';

const Barcode = ({
  scanned,
  scannedError,
  handleBarCodeScanned,
  tourchMode,
  borderColor,
  onPressScanAgain,
  onPressAddManually,
  TopNavigationComponent,
  scanQr,
}) => {
  const { width, height } = useWindowDimensions();

  const maskRowHeight = Math.round(height / (scanQr ? 40 : 10));
  const maskColWidth = (width - 300) / 2;

  const focused = useIsFocused();

  return (
    <>
      {TopNavigationComponent}
      <View flex={1} flexDirection="column" justifyContent="center">
        {focused ? (
          <Camera
            flashMode={
              tourchMode
                ? (Camera.Constants.FlashMode as any).torch
                : (Camera.Constants.FlashMode as any).off
            }
            style={[styles.cameraView]}
            onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
            barCodeScannerSettings={{
              barCodeTypes: [
                BarCodeScanner.Constants.BarCodeType.code39,
                BarCodeScanner.Constants.BarCodeType.qr,
              ],
            }}
            ratio="16:9"
            focusDepth={1}
            autoFocus={(Camera.Constants.AutoFocus as any).on}
          >
            <View
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="space-around"
            >
              <View
                flex={maskRowHeight}
                backgroundColor="rgba(0,0,0,0.6)"
                width="100%"
              />
              <View flex={30} flexDirection="row">
                <View width={maskColWidth} backgroundColor="rgba(0,0,0,0.6)" />
                <View
                  backgroundColor="transparent"
                  width={width - 2 * 16}
                  // height={width - 2 * 16}
                  borderColor={borderColor}
                  borderWidth={2}
                />
                <View width={maskColWidth} backgroundColor="rgba(0,0,0,0.6)" />
              </View>
              <View
                flex={maskRowHeight}
                backgroundColor="rgba(0,0,0,0.6)"
                width="100%"
              />
            </View>
          </Camera>
        ) : null}
        {scanned && scannedError && (
          <DarkThemeWrapper>
            <View
              position="absolute"
              bottom={50}
              left={0}
              right={0}
              marginHorizontal={25}
            >
              <Button
                appearance="filled"
                status="primary"
                size="xl"
                onPress={onPressScanAgain}
              >
                Scan Again
              </Button>
              {!scanQr ||
              (onPressAddManually !== undefined &&
                typeof onPressAddManually === 'function') ? (
                <Button
                  mt="xl"
                  size="xl"
                  appearance="ghost"
                  onPress={onPressAddManually}
                >
                  Add Manually
                </Button>
              ) : null}
            </View>
          </DarkThemeWrapper>
        )}
      </View>
    </>
  );
};

export default Barcode;

const styles = StyleSheet.create({
  cameraView: {
    flex: 1,
    justifyContent: 'flex-start',
  },
});
