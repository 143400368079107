/**
 * @generated SignedSource<<973db120c582bd57b524bd6304ec04fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UploadImageToS3Input = {
  base64Image: string;
  bucketName: string;
  contentType: string;
  key: string;
};
export type microServicesApisUploadImageToS3Query$variables = {
  data?: UploadImageToS3Input | null;
};
export type microServicesApisUploadImageToS3Query$data = {
  readonly uploadImageToS3: {
    readonly bucketName: string;
    readonly key: string;
  } | null;
};
export type microServicesApisUploadImageToS3Query = {
  response: microServicesApisUploadImageToS3Query$data;
  variables: microServicesApisUploadImageToS3Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "UploadImageToS3",
    "kind": "LinkedField",
    "name": "uploadImageToS3",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bucketName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "microServicesApisUploadImageToS3Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "microServicesApisUploadImageToS3Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "41df6abbb1f972f9b3c2ec17f8e841f1",
    "id": null,
    "metadata": {},
    "name": "microServicesApisUploadImageToS3Query",
    "operationKind": "query",
    "text": "query microServicesApisUploadImageToS3Query(\n  $data: UploadImageToS3Input\n) {\n  uploadImageToS3(data: $data) {\n    bucketName\n    key\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d807d3bb9e624e544786f854b015363";

export default node;
