import { variant as variantStyle, border, space, color } from 'styled-system';
import styled from 'styled-components/native';
import View from '../../basic/View/View';
import Icon from '../../primitive/Icon/Icon';
import Text from '../../primitive/Typography/Text/Text';
import { resolveTextSize, StarRatingVariants } from './helpers/helpers';
import { getTheme, themeModes } from '../../../../themes/new/theme';

const OuterStyledViewVariant = ({ theme }) => {
  return {
    [StarRatingVariants.md]: {
      px: theme.space.lg,
      py: theme.space.md,
      bg: theme.colors.background.secondary.elevation,
      borderColor:
        theme.currentThemeMode === themeModes.light
          ? getTheme(themeModes.light).colors.grey[50]
          : getTheme(themeModes.dark).colors.grey[50],
    },
    [StarRatingVariants.sm]: {
      px: theme.space.md,
      py: theme.space.sm,
      bg: theme.colors.background.secondary.elevation,
      borderColor:
        theme.currentThemeMode === themeModes.light
          ? getTheme(themeModes.light).colors.grey[50]
          : getTheme(themeModes.dark).colors.grey[50],
    },
  };
};

const OuterStyledView = styled.View`
  ${border}
  ${color}
  ${(props) =>
    variantStyle({ prop: 'size', variants: OuterStyledViewVariant(props) })}
`;

const StarRating = ({ ratingScore = 0, size = StarRatingVariants.sm }) => {
  return (
    <View>
      <OuterStyledView size={size} borderRadius="md" borderWidth="xs">
        <View flexDirection="row" justifyContent="center" alignItems="center">
          <Icon name="star-filled-300" color="static.gold" size="md" />
          <Text
            size={resolveTextSize(size)}
            color="primary.400"
            ml="xs"
            weight="medium"
          >
            {ratingScore && ratingScore.toFixed(1)}
          </Text>
        </View>
      </OuterStyledView>
    </View>
  );
};

export default StarRating;
