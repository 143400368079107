import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const startRideScreenQuery = graphql`
  query startRideScreenQuery(
    $filter: findTaskFilterInput! # $searchFilter: searchUsersFilterInput!
  ) {
    findTasks(filter: $filter) {
      _id
      vehicle {
        registration {
          plate
        }
      }
      taskStartLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      history {
        _id
        action
        actor
        event_at
      }
      valet {
        _id
        identityCode
      }
      user {
        _id
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
            verified
          }
        }
        parkingVisitCount
      }
      taskType
      campus {
        _id
      }
    }
    # searchUsers(filter: $searchFilter) {
    #   _id
    #   name {
    #     first
    #     last
    #   }
    #   contact {
    #     phone {
    #       prefix
    #       number
    #     }
    #   }
    #   profileImage
    #   parkingVisitCount
    # }
  }
`;
