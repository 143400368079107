const finishTaskSliceDataMaker = ({ timeOfArrival, taskId }) => {
  return { finishTaskSliceData: { taskId, timeOfArrival } };
};

const finishTaskSliceInitialData = { timeOfArrival: null, taskId: '' };

const finishTaskSlice = (set, get) => {
  return {
    finishTaskSliceData: finishTaskSliceInitialData,
    updatefinishTaskSliceData: ({
      timeOfArrival = undefined,
      taskId = undefined,
    }) => {
      set((state) => {
        // set old values for both if both undefined
        if (timeOfArrival === undefined && taskId === undefined) {
          return finishTaskSliceDataMaker({
            timeOfArrival: state.finishTaskSliceData.timeOfArrival,
            taskId: state.finishTaskSliceData.taskId,
          });
        }
        // set old value for which is undefined
        if (timeOfArrival === undefined) {
          return finishTaskSliceDataMaker({
            timeOfArrival: state.finishTaskSliceData.timeOfArrival,
            taskId,
          });
        }
        // set old value for which is undefined
        if (taskId === undefined) {
          return finishTaskSliceDataMaker({
            timeOfArrival,
            taskId: state.finishTaskSliceData.timeOfArrival,
          });
        }
        // both are not undefined then udpate both
        return finishTaskSliceDataMaker({ timeOfArrival, taskId });
      });
    },
    clearFinishTaskSliceData: () => {
      set((state) => {
        return finishTaskSliceDataMaker(finishTaskSliceInitialData);
      });
    },
  };
};

export default finishTaskSlice;
