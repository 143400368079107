/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';
import { commitMutationPromise } from '../../../../utilities/helper';

const defaultMutation = graphql`
  mutation updateTaskMutation($_id: MongoID!, $record: updateTaskRecordInput!) {
    updateTask(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;
export const updateTaskMutation = (variables, mutation = defaultMutation) => {
  return commitMutationPromise(mutation, variables);
};
