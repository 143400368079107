import React, { useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Button, IconButton, Layout, Text, View } from '../new';
import { checkUser, CheckUserData, generateOTP } from '../../relay/authApi';
import useLoginFlowStore, {
  LoginFlowScreenSteps,
} from '../../stores/loginFlowStore';
import useCommonStore from '../../stores/commonStore';
import InternationalPhoneInput from '../new/custom/InternationalPhoneInput';

const EnterPhoneNumber = () => {
  const componentState = useLoginFlowStore((state) => state);
  const commonStore = useCommonStore((state) => state);
  const navigation = useNavigation<any>();

  const [phoneNumber, setPhoneNumber] = useState<string>(
    componentState.mobileNumber,
  );

  const [callingCode, setCallingCode] = useState<string>(
    componentState.countryCallingCode,
  );

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(
    componentState.mobileNumber.length === 0,
  );

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
    if (value.length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const handleCountryChange = (countryCode: string) => {
    setCallingCode(countryCode);
  };

  const onContinue = () => {
    componentState.setMobileNumber(phoneNumber);

    const payload: CheckUserData = {
      phone: { number: phoneNumber, prefix: `+${callingCode}` },
    };

    checkUser(payload)
      .then((data) => {
        if (data?.checkUser === null || !data?.checkUser?.firstLogin) {
          if (data?.checkUser?.firstLogin === false) {
            // set secondary signup
            componentState.setSecondarySignup(true);
          }
          // navigate to signup
          commonStore.setWasSignupFlowActive(true);
          componentState.setSignupPayload({
            name: {
              first: data?.checkUser?.name?.first,
              last: data?.checkUser?.name?.last,
            },
          });
          componentState.setCountryCallingCode(callingCode);
          componentState.setCurrentScreen(LoginFlowScreenSteps.SIGN_UP);
          navigation.navigate('signUp');
        } else {
          componentState.setCountryCallingCode(callingCode);
          commonStore.setWasSignupFlowActive(false);
          // send otp
          generateOTP(payload, (error) => {
            console.log(error);
          });
          componentState.setCurrentScreen(LoginFlowScreenSteps.ENTER_OTP);
          navigation.navigate('enterOtp');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnBackPress = () => {
    navigation.navigate('login');
  };

  return (
    <Layout level={2} style={styles.mainWrapper}>
      <SafeAreaView style={{ flex: 1 }} edges={['top', 'bottom']}>
        <View
          pb="2xl"
          pt="2xl"
          style={{
            paddingHorizontal: 16,
            borderBottomWidth: 1,
            borderBottomColor: '#d2d2d2',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <TouchableOpacity
              // @ts-ignore
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                transform: [{ translateY: -9 }],
              }}
              onPress={() => {}}
            >
              <IconButton
                name="back-outline-300"
                size="md"
                appearance="ghost"
                iconColor="primary.500"
                onPress={handleOnBackPress}
                shape={undefined}
                bg={undefined}
                state={undefined}
                iconSize={undefined}
                text={undefined}
              />
            </TouchableOpacity>
            <Text size="md" color="primary.400">
              32nd
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <View>
            <Text size="3xl" color="primary.200" mb="32" mt="24">
              Login or Sign Up
            </Text>
            <View>
              <InternationalPhoneInput
                label="Mobile Number"
                autofocus={Platform.OS !== 'web'}
                defaultValue={phoneNumber}
                defaultCallingCode={callingCode}
                onChangeNumber={handlePhoneNumberChange}
                onChangeCountry={handleCountryChange}
              />
            </View>
            <View bottom="xl" style={{ marginTop: 24 }}>
              <Button
                size="lg"
                appearance="filled"
                status="primary"
                state={buttonDisabled === true ? 'disabled' : 'active'}
                onPress={onContinue}
              >
                Continue
              </Button>
            </View>
          </View>
        </View>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    flex: 1,
  },
  container: {
    paddingHorizontal: 16,
    flex: 1,
  },
});

export default EnterPhoneNumber;
