/* eslint-disable no-nested-ternary */
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ScrollView, useWindowDimensions } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  View,
  Layout,
  TopNavigation,
  IconButton,
  Text,
  Button,
} from '../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { Loading } from '../../components';
import useUserStore, { UserRole } from '../../stores/userStore';
import {
  DeliveryStatus,
  PaymentGatewayType,
  PaymentTypes,
  dynamicHeightMaker,
  navigateBack,
  numberWithCommas,
  paymentOrderTypeEnum,
  PaymentStatusEnum,
} from '../../utilities/helper';
import { getTheme } from '../../themes/new/theme';
import partnerTransactionUpdatedSubscription from './API/partnerTransactionUpdatedSubscription';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { updatePopUpStoreInvoice } from './API/partnerApi';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useAuthStore from '../../stores/authStore';
import { initiatePaymentAPI } from '../new/payment/api/paymentAmountScreenQuery';
import AppQrCode from '../../components/new/custom/AppQrCode';
import CustomLottieView from '../../components/new/custom/CustomLottieView';
import CachedImage from '../../components/new/custom/CachedImage';
import CachedImageBackground from '../../components/new/custom/CachedImageBackground';
import {
  Appearance,
  Directions,
  FontWeights,
  State,
  Status,
} from '../../themes/new/helper';
import CollectCashBottomSheet from '../cashier/CollectCashBottomsheet';
import PreviewImageBottomSheet from './PreviewImageBottomSheet';
import { createPopupOrderAPI } from '../API/createPopupOrder';
import { partnerPaymentScreenQuery } from './API/partnerPaymentScreenQuery';
import useCommonStore from '../../stores/commonStore';
import { updatePopupOrderAPI } from '../API/updatePopupOrder';

const PartnerPaymentScreen = (props) => {
  const { amount, orderId, variables, queryOptions } = props ?? {};
  const navigation = useNavigation<any>();
  const [orderIdState, setOrderIdState] = useState(orderId || '');
  const { id: userId, role: userRole } = useUserStore((state) => state);
  const { campusId } = useCommonStore((state) => state);
  const cashCollectionBottomsheetRef = useRef(null);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [data, setData] = useState(null);
  const [showAppQrCode, setShowAppQrCode] = useState({ show: false, id: null });
  const [generateQrDisabled, setGenerateQrDisabled] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { width } = useWindowDimensions();
  const { bottom } = useSafeAreaInsets();

  const { priceDetails, _id, status } = data ?? {};

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { findPopUpStores } = useLazyLoadQuery(
    partnerPaymentScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const referenceModelId = findPopUpStores?.edges[0]?.node?._id;

  // const base64Image = `data:image/png;base64,${paymentDetails?.paytmQr?.qrImage}`;

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailure, setPaymentFailure] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [timerValue, setTimerValue] = useState(5); // 5 seconds timer
  const [collectCashDisabled, setCollectCashDisabled] = useState(false);

  useFocusEffect(
    useCallback(() => {
      setData(null);
      setInvoiceId(null);
    }, []),
  );

  const handleCashCollectPress = useCallback(() => {
    cashCollectionBottomsheetRef?.current?.show();
  }, []);

  const handleOnDismissCashCollection = useCallback(() => {
    cashCollectionBottomsheetRef?.current?.hide();
  }, []);

  // ------ updating timer after every 1 sec for ------
  useEffect(() => {
    const interval = 1000;
    const timer = setInterval(() => {
      if (paymentFailure || delivered) {
        if (timerValue > 0) {
          setTimerValue(timerValue - 1);
        }
        if (timerValue === 0) {
          if (paymentFailure) {
            navigateBack(navigation, userRole);
          } else {
            navigation.popToTop();
          }
        }
      }
    }, interval);
    return () => {
      // Useful for cleanup functions
      clearInterval(timer);
    };
  }, [timerValue, paymentFailure, paymentSuccess, delivered]);

  const handleTransactionUpdatedSubscription = (res) => {
    // ============
    const transactionUpdated = res?.invoiceUpdated;
    if (transactionUpdated) {
      setData(transactionUpdated);
      if (transactionUpdated?.status === PaymentStatusEnum.SUCCESSFUL) {
        firebaseEventLogger('partnerQrScreen__paymentSuccess_Screen', {
          buttonName: 'paymentSuccess',
          screenName: 'partnerQrScreen',
          userType: UserRole.PARTNER,
          interactionType: InteractionType.TAP,
          isLoggedIn,
          partnerId: userId,
          generatedQrId: _id,
          paymentStatus: transactionUpdated?.status,
          paymentType: transactionUpdated?.paymentType,
          amount: transactionUpdated?.priceDetails?.grossAmount,
        });
        setPaymentSuccess(true);
      }
      if (transactionUpdated?.status === PaymentStatusEnum.FAILED) {
        firebaseEventLogger('partnerQrScreen__paymentFailed_Screen', {
          buttonName: 'paymentFailed',
          screenName: 'partnerQrScreen',
          userType: UserRole.PARTNER,
          interactionType: InteractionType.TAP,
          isLoggedIn,
          partnerId: userId,
          generatedQrId: _id,
          paymentStatus: transactionUpdated?.status,
          paymentType: transactionUpdated?.paymentType,
          amount: transactionUpdated?.priceDetails?.grossAmount,
        });
        setPaymentFailure(true);
      }
    }
  };

  const handleTransactionUpdationSubRef = useRef(
    handleTransactionUpdatedSubscription,
  );

  useFocusEffect(
    useCallback(() => {
      let cancelSubscriptionUpdateData = {
        dispose: () => {},
      };
      const cancelUpdateTaskSubscription = (disposable) => {
        cancelSubscriptionUpdateData = disposable;
      };
      const transactionUpdatedReq = {
        invoiceId: invoiceId || orderIdState,
        orderId: orderIdState,
      };

      partnerTransactionUpdatedSubscription(
        transactionUpdatedReq,
        (newData) => handleTransactionUpdationSubRef.current(newData),
        cancelUpdateTaskSubscription,
      );

      return () => {
        // Useful for cleanup functions
        cancelSubscriptionUpdateData.dispose();
      };
    }, [invoiceId, orderIdState, showAppQrCode]),
  );

  const onPressDelivered = () => {
    firebaseEventLogger('partnerQrScreen__markDelivered_Screen', {
      buttonName: 'markDelivered',
      screenName: 'partnerQrScreen',
      userType: UserRole.PARTNER,
      interactionType: InteractionType.TAP,
      isLoggedIn,
      partnerId: userId,
      generatedQrId: _id,
      paymentStatus: status,
      amount,
    });
    const deliveredData = {
      _id: orderIdState,
      record: {
        popUpStoreDetails: {
          deliveryStatus: DeliveryStatus.DELIVERED,
        },
      },
    };
    const handleUpdatePopUpInvoice = (response, error) => {
      if (response) {
        dispatchSnackbar({
          msg: 'Mark Delivered Successfully!',
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
        setDelivered(true);
      }
      if (error && error.length) {
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
      }
    };

    const updateApiErrorHandler = (error) => {
      dispatchSnackbar({
        msg: 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
        removeAfter: 4000,
      });
    };
    updatePopupOrderAPI(
      deliveredData,
      handleUpdatePopUpInvoice,
      updateApiErrorHandler,
    );
  };

  const bottomLeftCTAComponent = () => {
    return (
      <Button
        size="lg"
        appearance="filled"
        status="primary"
        state="active"
        onPress={onPressDelivered}
        shadow="sm"
      >
        Mark Delivered
      </Button>
    );
  };

  const handleCollectViaUPI = ({ orderIdProp }) => {
    setGenerateQrDisabled(true);
    firebaseEventLogger('partnerQrScreen__collectViaUpi_Screen', {
      buttonName: 'collectViaUpi',
      screenName: 'partnerQrScreen',
      userType: UserRole.PARTNER,
      interactionType: InteractionType.TAP,
      isLoggedIn,
      partnerId: userId,
      generatedQrId: _id,
      paymentType: PaymentGatewayType.PAYTMQR,
      amount,
    });
    initiatePaymentAPI(
      {
        amount: Number(amount),
        orderType: paymentOrderTypeEnum.POPUP_ORDER,
        orderId: orderIdProp,
        useRewardPoints: false,
        paymentType: PaymentGatewayType.PAYTMQR,
      },
      (res) => {
        setGenerateQrDisabled(false);
        if (
          res &&
          res?.initiatePayment &&
          res?.initiatePayment?.paymentDetails &&
          res?.initiatePayment?.paymentDetails?.paytmQr
        ) {
          setData(res?.initiatePayment);
          setInvoiceId(res?.initiatePayment?._id);
        }
      },
      (err) => {
        setGenerateQrDisabled(false);
        dispatchSnackbar({
          msg: 'Something Went Wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          position: 'top',
          removeAfter: 4000,
        });
      },
    );
  };

  const PayViaApp = ({ orderIdProp }) => {
    setShowAppQrCode({ show: !showAppQrCode.show, id: orderIdProp });
  };

  const handleCreateOrder = ({ payViaApp }) => {
    if (orderIdState) {
      if (payViaApp) {
        PayViaApp({ orderIdProp: orderIdState });
      } else {
        handleCollectViaUPI({ orderIdProp: orderIdState });
      }
    } else {
      const newData = {
        record: {
          amount: Number(amount),
          referenceModelId,
          referenceModelName: paymentOrderTypeEnum.POPUPSTORE,
          paymentType: payViaApp
            ? PaymentGatewayType.JUSPAY
            : PaymentGatewayType.PAYTMQR,
          campus: {
            _id: campusId,
          },
        },
      };
      createPopupOrderAPI(
        newData,
        (res) => {
          if (res && res?.createPopupOrder && res?.createPopupOrder?.record) {
            setOrderIdState(res?.createPopupOrder?.record?._id);
            if (payViaApp) {
              PayViaApp({ orderIdProp: res?.createPopupOrder?.record?._id });
            } else {
              handleCollectViaUPI({
                orderIdProp: res?.createPopupOrder?.record?._id,
              });
            }
          }
        },
        (err) => {
          dispatchSnackbar({
            msg: err?.error?.message || 'Something Went Wrong!',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
            position: Directions.TOP,
            removeAfter: 4000,
          });
        },
      );
    }
  };

  const RenderContent = () => {
    if (!paymentFailure && !paymentSuccess && !delivered) {
      return (
        <>
          <View
            mt="2xl"
            borderRadius="md"
            bg="primary.10"
            shadow="lg"
            alignItems="center"
            jutifyContent="center"
            p="2xl"
          >
            <Text
              size="3xl"
              weight={FontWeights.MEDIUM}
              color="primary.500"
              textAlign="center"
              mb="lg"
            >
              ₹{numberWithCommas(amount)}
            </Text>
            {userRole !== UserRole.MOMENT_STAFF_PARTNER ? (
              <Button
                appearance={Appearance.GHOST}
                status={Status.INFO}
                size="md"
                onPress={() =>
                  navigation.replace('AddAmountScreen', { amount })
                }
              >
                Edit
              </Button>
            ) : (
              <></>
            )}
            <View mt="lg" flexDirection="row" alignItems="center">
              <View flex={1} height={1} backgroundColor="primary.50" />
            </View>
            {data && Object.keys(data).length ? (
              <CachedImage
                source={{
                  uri: `data:image/png;base64,${data?.paymentDetails?.paytmQr?.qrImage}`,
                }}
                style={{
                  marginTop: getTheme().space['sm+md'],
                  width: width - 4 * getTheme().space['2xl'],
                  height: dynamicHeightMaker(
                    width - 4 * getTheme().space['2xl'],
                    1,
                  ),
                }}
              />
            ) : showAppQrCode.show ? (
              <View mt="7xl">
                <AppQrCode
                  orderId={showAppQrCode.id}
                  orderType={paymentOrderTypeEnum.POPUP_ORDER}
                  amount={amount}
                />
              </View>
            ) : (
              <CachedImageBackground
                source={require('../../../assets/images/staticQR.png')}
                style={{
                  width: width - 4 * getTheme().space['2xl'],
                  height: dynamicHeightMaker(
                    width - 4 * getTheme().space['2xl'],
                    1,
                  ),
                  marginTop: getTheme().space['sm+md'],
                }}
                imageStyle={{
                  resizeMode: 'contain',
                }}
              >
                <View
                  bg="opacity.80"
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <View width="100%">
                    <Button
                      size="lg"
                      state={amount > 0 ? 'active' : 'disabled'}
                      status={Status.PRIMARY}
                      appearance={Appearance.FILLED}
                      onPress={() => handleCreateOrder({ payViaApp: true })}
                    >
                      Pay Via 32nd Club App
                    </Button>
                    <Button
                      size="lg"
                      state={
                        amount > 0 && !generateQrDisabled
                          ? State.ACTIVE
                          : State.DISABLED
                      }
                      mt="2xl"
                      loading={generateQrDisabled}
                      status={Status.PRIMARY}
                      appearance={Appearance.OUTLINE}
                      onPress={() => handleCreateOrder({ payViaApp: false })}
                    >
                      Pay Via UPI
                    </Button>
                  </View>
                </View>
              </CachedImageBackground>
            )}
          </View>
        </>
      );
    }
    if (paymentSuccess && !delivered) {
      return (
        <View flex={1}>
          <View alignItems="center" justifyContent="center">
            <CustomLottieView
              style={{
                width: 200,
                height: 200,
              }}
              autoPlay
              loop
              source={require('../../../assets/lottie/tick.json')}
              containerWidth={200}
            />
            <Text
              size="2xl"
              weight={FontWeights.MEDIUM}
              color="primary.500"
              textAlign="center"
            >
              Payment Received!
            </Text>
          </View>
        </View>
      );
    }
    if (paymentFailure && !delivered) {
      return (
        <View flex={1}>
          <View alignItems="center" justifyContent="center">
            <CustomLottieView
              style={{
                width: 200,
                height: 200,
              }}
              autoPlay
              loop
              source={require('../../../assets/lottie/tick.json')}
              containerWidth={200}
            />
            <Text
              size="2xl"
              weight={FontWeights.MEDIUM}
              color="primary.500"
              textAlign="center"
            >
              Uh-oh! Payment Failed!
            </Text>
          </View>
        </View>
      );
    }
    if (delivered) {
      return (
        <View flex={1}>
          <View alignItems="center" justifyContent="center">
            <CustomLottieView
              style={{
                width: 200,
                height: 200,
              }}
              autoPlay
              loop
              source={require('../../../assets/lottie/deliver.json')}
              containerWidth={200}
            />
            <Text
              size="2xl"
              weight={FontWeights.MEDIUM}
              color="primary.500"
              textAlign="center"
            >
              Marked as Delivered!
            </Text>
          </View>
        </View>
      );
    }
    return <></>;
  };

  const RedirectComponent = () => {
    return (
      <Text size="sm" color="primary.100" my="9xl" textAlign="center">
        Redirecting in {timerValue}s
      </Text>
    );
  };

  const handleApproveCollectCash = () => {
    firebaseEventLogger('partnerQrScreen__collectCash_Screen', {
      buttonName: 'collectCash',
      screenName: 'partnerQrScreen',
      userType: UserRole.MOMENT_STAFF_PARTNER,
      interactionType: InteractionType.TAP,
      isLoggedIn,
      partnerId: userId,
      generatedQrId: _id,
      paymentStatus: status,
      amount,
    });
    setCollectCashDisabled(true);
    const deliveredData = {
      _id: orderIdState,
      record: {
        status: PaymentStatusEnum.SUCCESSFUL,
        paymentType: PaymentTypes.CASH,
      },
    };
    const handleUpdatePopUpInvoice = (response, error) => {
      if (response) {
        dispatchSnackbar({
          msg: 'Cash Collected!',
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
        setCollectCashDisabled(false);
        handleOnDismissCashCollection();
        setPaymentSuccess(true);
      }
      if (error && error.length) {
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          removeAfter: 4000,
        });
        setCollectCashDisabled(false);
        handleOnDismissCashCollection();
      }
    };

    const updateApiErrorHandler = (error) => {
      setGenerateQrDisabled(false);
      setCollectCashDisabled(false);
      dispatchSnackbar({
        msg: 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
        removeAfter: 4000,
      });
    };

    initiatePaymentAPI(
      {
        amount: Number(amount),
        orderType: paymentOrderTypeEnum.POPUP_ORDER,
        orderId,
        useRewardPoints: false,
        paymentType: PaymentGatewayType.CASH,
        userId: _id,
      },
      (res) => {
        setGenerateQrDisabled(false);
        if (res && res?.initiatePayment) {
          updatePopupOrderAPI(
            deliveredData,
            handleUpdatePopUpInvoice,
            updateApiErrorHandler,
          );
        }
      },
      (err) => {
        setGenerateQrDisabled(false);
        setCollectCashDisabled(false);
        dispatchSnackbar({
          msg: 'Something Went Wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          position: 'top',
          removeAfter: 4000,
        });
      },
    );
  };

  return (
    <View flex={1}>
      <ScrollView>
        <View px="2xl" mt="4xl">
          <RenderContent />
          {!paymentFailure && !paymentSuccess ? (
            <>
              <View mt="9xl" flexDirection="row" alignItems="center">
                <View flex={1} height={1} backgroundColor="primary.100" />
                <View mx="2xl">
                  <Text size="md" color="primary.100" textAlign="center">
                    OR
                  </Text>
                </View>
                <View flex={1} height={1} backgroundColor="primary.100" />
              </View>
              <View mt="9xl" mb="4xl">
                <Button
                  size="lg"
                  status="primary"
                  state="active"
                  appearance="outline"
                  onPress={() => {
                    firebaseEventLogger('partnerQrScreen__payViaCash_Screen', {
                      buttonName: 'payViaCash',
                      screenName: 'partnerQrScreen',
                      userType: UserRole.PARTNER,
                      interactionType: InteractionType.TAP,
                      isLoggedIn,
                      partnerId: userId,
                      generatedQrId: _id,
                      paymentStatus: status,
                      amount,
                    });
                    if (userRole === UserRole.MOMENT_STAFF_PARTNER) {
                      handleCashCollectPress();
                    } else {
                      navigation.replace('SearchUserScreen', {
                        partnerPayment: amount,
                      });
                    }
                  }}
                >
                  Collect Cash
                </Button>
              </View>
            </>
          ) : (
            <></>
          )}
          {delivered || paymentFailure ? <RedirectComponent /> : <></>}
        </View>
      </ScrollView>
      <CollectCashBottomSheet
        bottomSheetModalRef={cashCollectionBottomsheetRef}
        handleOnDismiss={handleOnDismissCashCollection}
        handleApprove={handleApproveCollectCash}
        amount={numberWithCommas(amount)}
        approveExitDisabled={collectCashDisabled}
      />
      {paymentSuccess && !delivered ? (
        <View
          position="absolute"
          bottom={bottom}
          my="2xl"
          px="2xl"
          width="100%"
        >
          {bottomLeftCTAComponent()}
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const PartnerPaymentScreenWrapper = ({ navigation, route }) => {
  const { amount, orderId, assetId } = route?.params ?? {};
  const { role: userRole, id: userId } = useUserStore((state) => state);
  const { campusId } = useCommonStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);
  const bottomSheetModalRef = useRef(null);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  const handlePress = useCallback(() => {
    bottomSheetModalRef?.current?.show();
  }, []);

  return (
    <Layout level={1}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        IconRight={
          userRole === UserRole.MOMENT_STAFF_PARTNER ? (
            <Button
              size="md"
              appearance={Appearance.GHOST}
              status={Status.INFO}
              state={State.ACTIVE}
              onPress={handlePress}
            >
              View
            </Button>
          ) : (
            <View />
          )
        }
        appearance="ghost"
        level="1"
        title="Payment"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <PartnerPaymentScreen
            amount={amount}
            orderId={orderId}
            variables={{
              filter: { ownerId: userId, campus: { _id: campusId } },
            }}
          />
          <PreviewImageBottomSheet
            bottomSheetModalRef={bottomSheetModalRef}
            assetId={assetId}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default PartnerPaymentScreenWrapper;
