import React from 'react';
import { Icon, Pressable, Text, View } from '../../../components/new';

const RenderCheckbox = (props: {
  name: string;
  value: string;
  isChecked: boolean;
  onPress: (value: string) => void;
}) => {
  return (
    <Pressable
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onPress={() => {
        props.onPress(props.value);
      }}
    >
      <Text size="lg" color={props.isChecked ? 'primary.500' : 'primary.200'}>
        {props.name}
      </Text>
      <View px="lg" py="lg">
        <Icon
          name={
            props.isChecked
              ? 'checkbox-outline-300'
              : 'checkbox-empty-outline-300'
          }
          size="2xl"
          color={props.isChecked ? 'primary.500' : 'primary.200'}
        />
      </View>
    </Pressable>
  );
};

export default RenderCheckbox;
