const videoSlice = (set, get) => {
  return {
    isMuted: true,
    toggleMuted: () =>
      set((state) => {
        return { ...state, isMuted: !state?.isMuted };
      }),
  };
};

export default videoSlice;
