import { graphql, fetchQuery } from 'react-relay';
import refreshTokenEnvironment from '../relay/relayRefreshTokenEnvironment';
import { RefreshTokenPayload } from '../relay/relayEnvironment';

const refreshTokenAPI = (data: RefreshTokenPayload) => {
  const query = graphql`
    query refreshTokenApiQuery($filter: findRefreshAuthtoken) {
      getRefreshToken(filter: $filter) {
        _id
        token
        refreshToken
      }
    }
  `;
  const variables = {
    filter: data,
  };

  return fetchQuery(refreshTokenEnvironment, query, variables, {
    force: true,
  }).toPromise();
};
export default refreshTokenAPI;
