import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { RefreshControl } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Text, View } from '../../../../components/new';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import SearchBar from '../../components/SearchBar';
import TransactionList from '../../components/TransactionList';
import EmptyListView from '../../components/EmptyListView';
import { useTransactionsScreen } from './useTransactionsScreen';
import TransactionDetailsBottomSheet from '../../components/TransactionDetailsBottomSheet';
import { getTheme } from '../../../../themes/new/theme';

interface TransactionsScreenInnerProps {
  queryOptions: NewErrorBoundaryParentState;
  refresh: () => void;
}

const TransactionsScreenInner = ({
  queryOptions,
  refresh,
}: TransactionsScreenInnerProps) => {
  const navigation = useNavigation();
  const {
    bsRef,
    transactions,
    selectedTransaction,
    pullToRefresh,
    fetchMoreTransactions,
    showTransactionDetails,
    hideTransactionDetails,
  } = useTransactionsScreen(queryOptions, refresh);
  const { top } = useSafeAreaInsets();

  return (
    <View flex={1}>
      <SearchBar onClick={() => navigation.navigate('transactionSearch')} />
      <View px="2xl" pt={getTheme().space['2xl']}>
        {transactions.length && (
          <Text size="2xl" color="primary.300">
            Today’s Transactions
          </Text>
        )}
      </View>
      <View flex={1}>
        <TransactionList
          data={transactions.map((t) => {
            return {
              amount: t.formated.billAmount,
              date: t.formated.time,
              orderId: t.orderId,
              paymentStatus: t.paymentStatus,
            };
          })}
          RefreshControl={
            <RefreshControl
              refreshing={pullToRefresh.refreshing}
              onRefresh={pullToRefresh.onRefresh}
            />
          }
          onScrollEnd={fetchMoreTransactions}
          NoContentView={NoContentView}
          onItemPress={(txn) => showTransactionDetails(txn.orderId)}
        />
      </View>
      <TransactionDetailsBottomSheet
        onDismiss={hideTransactionDetails}
        ref={bsRef}
        transaction={selectedTransaction}
      />
    </View>
  );
};

export default TransactionsScreenInner;

const NoContentView = () => (
  <View mt="122">
    <EmptyListView
      label="No Transactions Yet!"
      msg="There are no transactions at the moment, check back again"
    />
  </View>
);
