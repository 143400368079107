/**
 * @generated SignedSource<<84f631a34dc5ddab4679c4fbb214d8a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeScreenQuery_instagramFragment$data = {
  readonly data: {
    readonly followers_count: number | null;
    readonly media: {
      readonly data: ReadonlyArray<{
        readonly media_type: string | null;
        readonly media_url: string | null;
      } | null> | null;
    } | null;
    readonly profile_picture_url: string | null;
    readonly username: string | null;
  } | null;
  readonly platform: string;
  readonly " $fragmentType": "homeScreenQuery_instagramFragment";
};
export type homeScreenQuery_instagramFragment$key = {
  readonly " $data"?: homeScreenQuery_instagramFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeScreenQuery_instagramFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeScreenQuery_instagramFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InstagramBasicData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profile_picture_url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followers_count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MediaData",
          "kind": "LinkedField",
          "name": "media",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramMedia",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "media_url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "media_type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SocialMedia",
  "abstractKey": null
};

(node as any).hash = "af95b082b848a1ec920b35eef9f065a4";

export default node;
