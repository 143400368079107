import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const partnerHomeScreenQuery = graphql`
  query partnerHomeScreenQuery(
    $filter: findpaymentOrderfilterInput
    $sort: [PaymentOrderHistorySortEnum]
    $count: Int!
    $cursor: String
  ) {
    ...partnerHomeScreenQuery__paymentHistoryFragmentV2
      @arguments(filter: $filter, sort: $sort, count: $count, cursor: $cursor)
  }
`;

export const paymentHistoryFragmentV2 = graphql`
  fragment partnerHomeScreenQuery__paymentHistoryFragmentV2 on Query
  @refetchable(
    queryName: "partnerHomeScreenQuery__paymentHistoryFragmentV2Query"
  )
  @argumentDefinitions(
    filter: { type: "findpaymentOrderfilterInput" }
    sort: { type: "[PaymentOrderHistorySortEnum]" }
    count: { type: "Int!" }
    cursor: { type: "String" }
  ) {
    findPopupOrders(
      filter: $filter
      sort: $sort
      first: $count
      after: $cursor
    )
      @connection(
        key: "partnerHomeScreenQuery__paymentHistoryFragmentV2Query__findPopupOrders"
      ) {
      edges {
        node {
          _id
          status
          created_at
          amount
          itemDetails
          referenceModelName
          user {
            _id
            contact {
              phone {
                prefix
                number
              }
            }
          }
          invoice {
            _id
            orderType
            orderId
            created_at
            paymentDetails {
              razorpay {
                pgOrderId
              }
              paytmQr {
                transactionId
              }
            }
          }
          paymentType
          popUpStoreDetails {
            deliveryStatus
            displayName
          }
        }
      }
    }
  }
`;

export const paymentHistoryFragment = graphql`
  fragment partnerHomeScreenQuery__paymentHistoryFragment on invoice
  @relay(plural: true) {
    _id
    status
    priceDetails {
      grossAmount
    }
    user {
      _id
      contact {
        phone {
          prefix
          number
        }
      }
    }
    paymentType
    orderType
    orderId
    popUpStoreDetails {
      deliveryStatus
      displayName
    }
    created_at
    paymentDetails {
      razorpay {
        pgOrderId
      }
      paytmQr {
        transactionId
      }
    }
  }
`;
