/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Image } from 'react-native';
import { Button, Text, View } from '../../../../../../components/new';
import { FontWeights } from '../../../../../../themes/new/helper';
import { iconNameEnum } from '../helpers';
import analytics from '../analytics';

type CTA = { label: string; navLink: string; navParams?: Record<string, any> };
type BenefitsListItemProps = {
  icon: string;
  title: string;
  subTitle: string;
  info: string | CTA;
};

const iconMapping = {
  [iconNameEnum.CAR]: require('../../../../../../../assets/images/icons/Car.webp'),
  [iconNameEnum.MOBILE]: require('../../../../../../../assets/images/icons/mobile-icon.webp'),
  [iconNameEnum.CALENDAR]: require('../../../../../../../assets/images/icons/calendar-icon.webp'),
  [iconNameEnum.CUP]: require('../../../../../../../assets/images/icons/cup-icon.webp'),
};

const BenefitsListItem = ({
  icon,
  title,
  subTitle,
  info,
}: BenefitsListItemProps) => {
  const navigation = useNavigation();
  const CTAinfo = info as CTA;

  return (
    <View p="2xl" borderRadius="lg" bg="background.primary.elevation">
      <View pb="md" borderBottomWidth={1} borderColor="primary.50">
        <Text
          textAlign="center"
          weight={FontWeights.MEDIUM}
          size="sm"
          color="primary.300"
        >
          {title}
        </Text>
      </View>
      <View mt="lg" flexDirection="row" alignItems="center">
        <Image source={iconMapping[icon]} style={{ width: 40, height: 40 }} />
        <View flex={1} ml="2xl" alignItems="flex-start">
          <Text
            weight={FontWeights.MEDIUM}
            mb="lg"
            size="md"
            color="primary.500"
          >
            {subTitle}
          </Text>
          <View justifyContent="center">
            {typeof info === 'string' && (
              <Text weight={FontWeights.MEDIUM} size="xs" color="primary.300">
                {info}
              </Text>
            )}
            {typeof CTAinfo?.navLink === 'string' && (
              <Button
                state="active"
                status="info"
                appearance="ghost"
                size="sm"
                iconRight="keyboard-arrow-right-300"
                onPress={() => {
                  analytics.memberBenefitsCTAClicked(info);
                  navigation.navigate(
                    CTAinfo?.navLink,
                    CTAinfo?.navParams ?? {},
                  );
                }}
              >
                {CTAinfo?.label}
              </Button>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default BenefitsListItem;
