/**
 * @generated SignedSource<<459c83766b010f65af7626d7db046e61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type findParkingLocationFilterInput = {
  campus: FilterFindManyparkingLocationCampusInput;
};
export type FilterFindManyparkingLocationCampusInput = {
  _id: any;
};
export type parkingLocationQuery$variables = {
  filter: findParkingLocationFilterInput;
};
export type parkingLocationQuery$data = {
  readonly findParkingLocation: ReadonlyArray<{
    readonly _id: any;
    readonly campus: {
      readonly _id: any | null;
    } | null;
    readonly name: string;
    readonly parkingSpaces: {
      readonly floor: ReadonlyArray<{
        readonly _id: any | null;
        readonly bay: ReadonlyArray<{
          readonly _id: any | null;
          readonly bayName: string;
          readonly parkingOccupied: number | null;
          readonly parkingSpots: number | null;
          readonly parkingVacant: number | null;
        } | null>;
        readonly floorName: string;
      } | null> | null;
    } | null;
  }>;
};
export type parkingLocationQuery = {
  response: parkingLocationQuery$data;
  variables: parkingLocationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "parkingLocation",
    "kind": "LinkedField",
    "name": "findParkingLocation",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "parkingLocationCampus",
        "kind": "LinkedField",
        "name": "campus",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "parkingLocationParkingSpaces",
        "kind": "LinkedField",
        "name": "parkingSpaces",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "parkingLocationParkingSpacesFloor",
            "kind": "LinkedField",
            "name": "floor",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "floorName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "parkingLocationParkingSpacesFloorBay",
                "kind": "LinkedField",
                "name": "bay",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parkingSpots",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parkingOccupied",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parkingVacant",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "parkingLocationQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "parkingLocationQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b1cb810cc7037f8e2b41afc68c60089b",
    "id": null,
    "metadata": {},
    "name": "parkingLocationQuery",
    "operationKind": "query",
    "text": "query parkingLocationQuery(\n  $filter: findParkingLocationFilterInput!\n) {\n  findParkingLocation(filter: $filter) {\n    _id\n    name\n    campus {\n      _id\n    }\n    parkingSpaces {\n      floor {\n        _id\n        floorName\n        bay {\n          _id\n          bayName\n          parkingSpots\n          parkingOccupied\n          parkingVacant\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "561f23152b412230b8e5c1afbf09a2d5";

export default node;
