/* eslint-disable no-unsafe-optional-chaining */
import React, {
  Suspense,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useWindowDimensions } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import moment from 'moment';
import { ActionSheetRef } from 'react-native-actions-sheet';
import {
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
  Button,
  Divider,
} from '../../components/new';
import { Loading } from '../../components';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { navigateBack } from '../../utilities/helper';
import useUserStore from '../../stores/userStore';
import { space } from '../../themes/new/theme';
import VideoCard from './VideoCard';
import photoMoments from '../../screens/Strings';
import { s3Client } from '../../utilities/s3config';
import mapVideoToImageForVideoMomentsApi from '../../relay/mapVideoToImageForVideoMomentsApi';
import useVideoMomentsStore from '../../stores/VideoMomentsStore';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { isValueNullOrEmpty } from '../../utilities/Utility';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import BottomSheetV2 from '../../components/new/composites/BottomSheet/BottomSheetV2';

let inprogressVal = 0;
let multiSelected = [];
let uploadedIds = [];

const RenderVideoLibrary = ({
  videoDataArr,
  forceUpdate,
  selectAll = false,
}) => {
  const { width } = useWindowDimensions();
  const { uploaded, setVideoData, setUploaded, inProgress } =
    useVideoMomentsStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const bottomSheetRescanRef = useRef<ActionSheetRef>(null);
  const [loader, setLoader] = useState(false);
  const [awsfailed, setAwsFailed] = useState(null);
  const [apiFailedObj, setApiFaledObj] = useState(null);

  const topStatusArr = [
    { title: 'Failed', count: videoDataArr?.length, color: 'error.500' },
    {
      title:
        inprogressVal === 0 && inProgress === 0 ? 'Pending' : 'In progress',
      count: inprogressVal || inProgress,
      color: 'warning.500',
    },
    { title: 'Uploaded', count: uploaded, color: 'success.500' },
  ];

  useEffect(() => {
    return () => {
      multiSelected = [];
      inprogressVal = 0;
    };
  }, []);

  const uploadfileToS3 = ({ bucketName, fileName, blob }) => {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: blob,
      contentDeposition: `inline;filename="${fileName}"`,
      ContentType: 'video/mp4',
    };

    return s3Client.upload(params).promise();
  };

  const removeUploaded = (response) => {
    if (multiSelected?.length) {
      setVideoData(JSON.stringify(response));
      uploadSelectedVideos();
    } else {
      const videoDataRes = [...videoDataArr];
      const res = videoDataRes.filter(
        (item) => !uploadedIds.find((ids) => item?.videoId === ids),
      );
      setVideoData(JSON.stringify(res));
      setUploaded(uploaded + uploadedIds?.length);
      uploadedIds = [];
    }
    forceUpdate();
  };

  const setToDefault = (params) => {
    // console.log(params?.videoId, 'testtttttt', uploaded, videoDataArr);
    if (params) {
      const videoDataRes = [...videoDataArr];
      const videoId = params?.videoId;
      const response = videoDataRes.filter((item) => item?.videoId !== videoId);
      // console.log('fffffff', multiSelected?.length);
      if (multiSelected?.length) {
        uploadedIds.push(videoId);
        inprogressVal =
          multiSelected?.length > 0 ? multiSelected?.length - 1 : 0;
        multiSelected.shift();
        if (multiSelected?.length === 0) {
          setLoader(false);
        }
        removeUploaded(response);
      } else if (response?.length) {
        setUploaded(uploaded + 1);
        setVideoData(JSON.stringify(response));
        inprogressVal = 0;
        multiSelected = [];
        setLoader(false);
      } else {
        setUploaded(uploaded + 1);
        inprogressVal = 0;
        multiSelected = [];
        setVideoData('');
        setLoader(false);
      }
      forceUpdate();
    }
    setAwsFailed(null);
    setApiFaledObj(null);
    bottomSheetRescanRef.current?.hide();
  };

  const apiUploadPending = (val) => {
    const videoDataRes = [...videoDataArr];
    const currentObj = videoDataRes.find(
      (item) => item?.videoId === val?.videoId,
    );
    currentObj.uploadToS3 = false;
    currentObj.apiFailedObj = val;
    currentObj.subTitle1 = moment().format('Do MMMM, YYYY');
    currentObj.subTitle2 = moment().format('hh:mm A');
    const arr2 = [currentObj];
    videoDataRes.map(
      (obj) => arr2.find((o) => o.videoId === obj.videoId) || obj,
    );
    setVideoData(JSON.stringify(videoDataRes));
  };

  const mapVideoToImageForVideoMoments = (params) => {
    setLoader(true);
    setApiFaledObj(null);
    const MapVideoToImageInput = {};
    (MapVideoToImageInput as any).imageBase64 = params?.imageBase64;
    (MapVideoToImageInput as any).videoId = params?.videoId;
    mapVideoToImageForVideoMomentsApi(MapVideoToImageInput)
      .then((response) => {
        if (!multiSelected?.length) {
          setLoader(false);
        }
        // console.log('passed stage library 2');
        if (response?.mapVideoToImageForVideoMoments?.success) {
          setToDefault(params);
          dispatchSnackbar({
            msg: photoMoments.uploadedSuccessFully,
            status: SnackbarStatus.success,
            version: SnackbarVersion.v1,
          });
          firebaseEventLogger('videoLibrary__apiSuccess_data', {
            buttonName: 'apiSuccess',
            screenName: 'videoLibrary',
            userType: 'VideoSupervisor',
            interactionType: 'data',
            videoId: params?.videoId,
          });
        } else {
          inprogressVal = 0;
          if (params?.uploadToS3) {
            apiUploadPending(params);
          }
          setApiFaledObj(params); // triggered when api failed
          bottomSheetRescanRef.current?.show();
          // console.log('failed stage library 2');
          firebaseEventLogger('videoLibrary__apiFailed_data', {
            buttonName: 'apiFailed',
            screenName: 'videoLibrary',
            userType: 'VideoSupervisor',
            interactionType: 'data',
            videoId: params?.videoId,
          });
        }
      })
      .catch((error) => {
        inprogressVal = 0;
        setLoader(false);
        if (params?.uploadToS3) {
          apiUploadPending(params);
        }
        setApiFaledObj(params); // triggered when api failed
        bottomSheetRescanRef.current?.show();
        // console.log('failed stage library 3', error);
        firebaseEventLogger('videoMoments__apiFailed_data', {
          buttonName: 'apiFailed',
          screenName: 'videoMoments',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          videoId: params?.videoId,
          error: JSON.stringify(error),
        });
      });
  };

  const successFullyUploaded = (val) => {
    const params = {
      videoId: val?.fileName,
      imageBase64: val?.imageBase64,
      uploadToS3: val?.uploadToS3,
    };
    mapVideoToImageForVideoMoments(params);
  };

  const uploadFunction = async (val) => {
    setAwsFailed(null);
    setLoader(true);
    const result = await fetch(val?.uri);
    const blob = await result.blob();
    const params = {
      ...val,
      blob,
    };
    uploadfileToS3(params)
      .then(() => {
        successFullyUploaded(val);
        // console.log('passed stage library 1');
        firebaseEventLogger('videoLibrary__awsSuccess_data', {
          buttonName: 'awsSuccess',
          screenName: 'videoLibrary',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          videoId: val?.fileName,
        });
      })
      .catch((error) => {
        inprogressVal = 0;
        setAwsFailed(val); // triggered when aws upload failed
        bottomSheetRescanRef.current?.show();
        // console.log('failed stage library 1', error);
        setLoader(false);
        firebaseEventLogger('videoLibrary__awsFailed_data', {
          buttonName: 'awsFailed',
          screenName: 'videoLibrary',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          videoId: val?.fileName,
          error: JSON.stringify(error),
        });
      });
  };

  const retrySpecificFile = ({ item, isMultiple = false, lastItem = true }) => {
    if (multiSelected?.length > 0 && !isMultiple) {
      processSelected(item);
    } else {
      // eslint-disable-next-line no-nested-ternary
      inprogressVal = multiSelected?.length
        ? multiSelected?.length
        : lastItem
        ? 1
        : 1;
      if (item?.uploadToS3) {
        const params = {
          ...item?.awsfailed,
          imageBase64: item?.imageBase64,
          uploadToS3: true,
        };
        uploadFunction(params);
      } else {
        mapVideoToImageForVideoMoments(item?.apiFailedObj);
      }
    }
  };

  const uploadSelectedVideos = () => {
    if (multiSelected?.length) {
      retrySpecificFile({
        item: multiSelected[0],
        isMultiple: true,
        lastItem: multiSelected?.length === 1,
      });
    }
  };

  const retryFunction = () => {
    firebaseEventLogger('videoLibrary__retryUpload_Tap', {
      buttonName: 'retryUpload',
      screenName: 'videoLibrary',
      userType: 'VideoSupervisor',
      interactionType: 'data',
      videoId: awsfailed?.videoId ?? apiFailedObj?.videoId,
    });
    bottomSheetRescanRef.current?.hide();
    inprogressVal = 1;
    if (awsfailed) {
      uploadFunction(awsfailed);
    } else {
      mapVideoToImageForVideoMoments(apiFailedObj);
    }
  };

  const checkExist = (val) => {
    const res = multiSelected.find((item) => item?.videoId === val?.videoId);
    return !!res;
  };

  const processSelected = (videoObj) => {
    const eachVideo = { ...videoObj };
    if (checkExist(eachVideo)) {
      const res = multiSelected.filter(
        (item) => item?.videoId !== eachVideo?.videoId,
      );
      multiSelected = res;
    } else {
      multiSelected.push(eachVideo);
    }
    forceUpdate();
  };

  const checkSelected = (item) => {
    const selected = multiSelected.find(
      (listItem) => listItem?.videoId === item?.videoId,
    );
    return !!selected;
  };

  const checkListItem = (val) => {
    const collectionlist = val ?? [];
    const newList = [];
    collectionlist.forEach((item) => {
      const eachItem = { ...item };
      if (multiSelected?.length) {
        if (checkSelected(eachItem)) {
          eachItem.isSelected = true;
        } else {
          eachItem.isSelected = false;
        }
      } else {
        eachItem.isSelected = false;
      }
      newList.push(eachItem);
    });
    return newList;
  };

  const NoVideosFound = () => (
    <View flex={1} justifyContent="center" alignItems="center">
      <Text size="2xl" weight="medium" color="primary.500">
        {photoMoments.noVideos}
      </Text>
      <View height={space['9xl+9xl']} />
    </View>
  );

  const topView = () =>
    topStatusArr.map((item, index) => (
      <View
        bg="background.secondary.base"
        ml={index !== 0 ? 'lg' : 0}
        width={(width - 48) / 3}
        height={space['8xl+8xl']}
        key={`catogry_header${item?.title}`}
        justifyContent="center"
        alignItems="center"
        borderRadius="md"
      >
        <Text size="md" color="primary.500">
          {item?.title}
        </Text>
        <Text size="md" color={item?.color}>
          {item?.count}
        </Text>
      </View>
    ));

  const videoView = () => {
    const modifyListItem = checkListItem(videoDataArr);
    return (
      <FlashList
        data={modifyListItem}
        renderItem={({ item, index }) => {
          return (
            <VideoCard
              item={item}
              processLongPress={(picture) => processSelected(picture)}
              showMargin={index % 2 !== 0}
              showTopMargin={index === 0 || index === 1}
              retryUpload={() => retrySpecificFile({ item, isMultiple: false })}
            />
          );
        }}
        estimatedItemSize={76}
        numColumns={2}
        drawDistance={500}
      />
    );
  };

  return (
    <View flex={1}>
      <View mt="4xl" flexDirection="row" mx="2xl">
        {topView()}
      </View>
      {videoDataArr?.length ? videoView() : <NoVideosFound />}
      {videoDataArr?.length && multiSelected?.length ? (
        <Button
          size="lg"
          appearance="filled"
          status="primary"
          state="active"
          mt="2xl"
          mx="2xl"
          mb="8xl"
          onPress={uploadSelectedVideos}
        >
          {photoMoments.uploadVideos}
        </Button>
      ) : null}
      <BottomSheetV2 ref={bottomSheetRescanRef} onClose={setToDefault}>
        <View justifyContent="center" p="2xl">
          <Text size="xl" color="primary.200">
            {photoMoments.retry}
          </Text>
          <View mt="xl">
            <Divider level={1} />
          </View>
          <Text size="s" color="primary.200" mt="3xl">
            {photoMoments.retryStatement}
          </Text>
          <View px="2xl" my="4xl">
            <Divider level={1} />
          </View>
          <View flexDirection="row" alignItems="center">
            <Button
              style={{
                width: (width - 3 * 16) / 2,
                marginRight: 16,
              }}
              state="active"
              status="primary"
              appearance="outline"
              size="lg"
              onPress={() => {
                setToDefault(null);
                firebaseEventLogger('videoLibrary__cancelUpload_Tap', {
                  buttonName: 'cancelUpload',
                  screenName: 'videoLibrary',
                  userType: 'VideoSupervisor',
                  interactionType: 'data',
                  videoId: awsfailed?.videoId ?? apiFailedObj?.videoId,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ width: (width - 3 * 16) / 2 }}
              state="active"
              status="primary"
              appearance="filled"
              size="lg"
              onPress={retryFunction}
            >
              {photoMoments.retry}
            </Button>
          </View>
        </View>
      </BottomSheetV2>
      {loader ? <Loading /> : null}
    </View>
  );
};

const VideoLibrary = ({ navigation }) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [netStatus, setNetStatus] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const userRole = useUserStore((state) => state.role);
  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };
  const { videoData } = useVideoMomentsStore((state) => state);
  const videoDataArr = !isValueNullOrEmpty(videoData)
    ? JSON.parse(videoData)
    : [];

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const renderSelectRight = () => {
    forceUpdate();
    if (
      videoDataArr?.length &&
      videoDataArr?.length === multiSelected?.length
    ) {
      multiSelected = [];
      setSelectAll(false);
    } else {
      setSelectAll(true);
      multiSelected = videoDataArr;
    }
  };

  const rightText = () => {
    let res = '';
    if (
      videoDataArr?.length &&
      videoDataArr?.length === multiSelected?.length
    ) {
      res = 'Cancel';
    } else if (videoDataArr?.length) {
      res = 'Select all';
    }
    return res;
  };

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        rightText={rightText()}
        rightTextColor="info.500"
        rightTextSize="sm"
        onPressRightText={renderSelectRight}
        appearance="ghost"
        level="1"
        title="Video Library"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <RenderVideoLibrary
            videoDataArr={videoDataArr}
            forceUpdate={forceUpdate}
            selectAll={selectAll}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};
export default VideoLibrary;
