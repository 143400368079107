/**
 * @generated SignedSource<<52abf37b3b77c85c8e65007ba877e912>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type supervisorOverviewApiAllMetricQuery$variables = {
  endTimeStamp: number;
  startTimeStamp: number;
};
export type supervisorOverviewApiAllMetricQuery$data = {
  readonly getTaskMetricsDateWise: {
    readonly metrics: ReadonlyArray<{
      readonly assignmentScore: number | null;
      readonly assignmentTime: number | null;
      readonly date: string | null;
      readonly deliveryScore: number | null;
      readonly deliveryTime: number | null;
      readonly onboardingScore: number | null;
      readonly onboardingTime: number | null;
      readonly parkingScore: number | null;
      readonly parkingTime: number | null;
    } | null> | null;
  } | null;
};
export type supervisorOverviewApiAllMetricQuery = {
  response: supervisorOverviewApiAllMetricQuery$data;
  variables: supervisorOverviewApiAllMetricQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endTimeStamp"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startTimeStamp"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endTimeStamp",
        "variableName": "endTimeStamp"
      },
      {
        "kind": "Variable",
        "name": "startTimeStamp",
        "variableName": "startTimeStamp"
      }
    ],
    "concreteType": "taskMetricsDayWiseTC",
    "kind": "LinkedField",
    "name": "getTaskMetricsDateWise",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "taskMetricsDateTC",
        "kind": "LinkedField",
        "name": "metrics",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignmentTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignmentScore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliveryTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliveryScore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingScore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onboardingTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onboardingScore",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "supervisorOverviewApiAllMetricQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "supervisorOverviewApiAllMetricQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6e6fc648a2d703cd553eb435024d39fc",
    "id": null,
    "metadata": {},
    "name": "supervisorOverviewApiAllMetricQuery",
    "operationKind": "query",
    "text": "query supervisorOverviewApiAllMetricQuery(\n  $startTimeStamp: Int!\n  $endTimeStamp: Int!\n) {\n  getTaskMetricsDateWise(startTimeStamp: $startTimeStamp, endTimeStamp: $endTimeStamp) {\n    metrics {\n      date\n      assignmentTime\n      assignmentScore\n      deliveryTime\n      deliveryScore\n      parkingTime\n      parkingScore\n      onboardingTime\n      onboardingScore\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd0c31f94bf7c789af93b63e379fef02";

export default node;
