import React, { useEffect } from 'react';
import { Image } from 'react-native';
import { Text, View } from '../../../../../components/new';
import useCountdown from '../../../../../utilities/customHooks/useCountDown';
import BackButton from '../components/BackButton';
import { kiosk } from '../../../../../utilities/Constant';
import { analytics } from '../analytics';
import CustomLottieView from '../../../../../components/new/custom/CustomLottieView';

const { SESSION_TIMEOUT } = kiosk;
const KioskImage = require('../../../../../../assets/images/kiosk.webp');

const ScanCardScreen = ({ onBack }) => {
  useEffect(() => {
    analytics.scanTagScreen.landed();
  }, []);

  const handleBack = () => {
    analytics.scanTagScreen.backClicked();
    onBack();
  };
  const { countdown, startCountdown } = useCountdown(SESSION_TIMEOUT, {
    onComplete: () => {
      onBack();
    },
  });

  useEffect(() => {
    startCountdown();
  }, []);

  return (
    <View flex={1} width="100%" justifyContent="space-between">
      <View>
        <BackButton onBack={handleBack} />
        <View p="lg" mt="9xl">
          <Text
            textAlign="center"
            size="7xl"
            lineHeight="9xl"
            weight="medium"
            color="primary.500"
          >
            Scan Valet Tag To Recall Your Car
          </Text>
        </View>
      </View>
      <View>
        <View alignItems="center">
          <View
            zIndex={10}
            position="absolute"
            width={100}
            height={100}
            top={401}
            left={265}
          >
            <CustomLottieView
              autoPlay
              ref={(ref) => ref}
              source={require('../../../../../../assets/lottie/scan-rect.json')}
              style={{ height: 60, width: 56 }}
              containerWidth={56}
            />
          </View>
          <Image source={KioskImage} style={{ height: 700, width: 640 }} />
        </View>
        <View mt="4xl">
          <Text
            textAlign="center"
            size="2xl"
            weight="regular"
            color="primary.300"
          >
            Session ending in {countdown}s
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ScanCardScreen;
