import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Text, View } from '../../../../components/new';
import GoldButton from './GoldButton';
import { Appearance } from '../../../../themes/new/helper';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import CachedImageBackground from '../../../../components/new/custom/CachedImageBackground';
import CachedImage from '../../../../components/new/custom/CachedImage';

const LOGO = require('../../../../../assets/images/black_membership/black-member-logo-3.webp');
const MESH_BG = require('../../../../../assets/images/black_membership/mesh-bg.png');

interface BlackMembershipGenericPopUpProps {
  text: string;
  btnOneText: string;
  btnTwoText: string;
  onPressOne: (...args: any) => void;
  onPressTwo: (...args: any) => void;
}

const BlackMembershipGenericPopUp = (
  props: BlackMembershipGenericPopUpProps,
) => {
  const { text, btnOneText, btnTwoText, onPressOne, onPressTwo } = props;

  return (
    <DarkThemeWrapper>
      <View height={400}>
        <CachedImageBackground
          source={MESH_BG}
          style={styles.bgImage}
          imageStyle={{
            resizeMode: 'cover',
          }}
        >
          <View px="2xl">
            <View mb="6xl" alignItems="center">
              <CachedImage source={LOGO} style={{ width: 153, height: 83 }} />
            </View>
            <View pb="lg" borderBottomWidth="xs" borderColor="grey.50">
              <Text textAlign="center" size="xl" color="primary.500">
                {text}
              </Text>
            </View>
            <View mt="4xl">
              {/* logo */}
              <GoldButton onPress={onPressOne} text={btnOneText} />
              <Button
                onPress={onPressTwo}
                mt="2xl"
                appearance={Appearance.OUTLINE}
              >
                <Text textAlign="center" size="md" color="primary.500">
                  {btnTwoText}
                </Text>
              </Button>
            </View>
          </View>
        </CachedImageBackground>
      </View>
    </DarkThemeWrapper>
  );
};

const styles = StyleSheet.create({
  bgImage: {
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
});

export default BlackMembershipGenericPopUp;
