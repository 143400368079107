import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Layout, Text, View } from '../../components/new';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useUserStore from '../../stores/userStore';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

interface RenderWidgetProps {
  onPress: () => void;
  lottie: any;
  text: string;
  style?: StyleProp<ViewStyle>;
}
const RenderWidget = (props: RenderWidgetProps) => {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      activeOpacity={0.8}
      style={props?.style ? props.style : {}}
    >
      <View
        style={{
          padding: 16,
          flexDirection: 'column',
          alignItems: 'center',
        }}
        bg="white"
        borderRadius="md"
        shadow="lg"
        weight="medium"
      >
        <CustomLottieView
          style={{ width: 100, height: 100 }}
          autoPlay
          loop
          source={props.lottie}
          containerWidth={100}
        />
        <Text size="md" color="primary.300" mt="2xl" weight="medium">
          {props.text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const CampusStaffHomeScreen = () => {
  const navigation = useNavigation();
  const { role } = useUserStore((state) => state);

  return (
    <Layout level={2} edges={['top']}>
      <View px="2xl" mt="4xl">
        <RenderWidget
          onPress={() => {
            firebaseEventLogger('account__scan&Redeem&Ticket_Tap', {
              buttonName: 'scan&Redeem&Ticket',
              screenName: 'home',
              userType: role,
              interactionType: InteractionType.TAP,
              isLoggedIn: true,
            });
            navigation.navigate('StaffBarcodeScannerScreen', {
              hideManual: true,
            });
          }}
          lottie={require('../../../assets/lottie/scanning.json')}
          text="Scan & Redeem Ticket"
        />
      </View>
    </Layout>
  );
};

export default CampusStaffHomeScreen;
