/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useRef, useState } from 'react';
import { ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import {
  Button,
  View,
  Text,
  Layout,
  TopNavigation,
  IconButton,
  Input,
  ChipGroup,
} from '../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import useUserStore from '../../stores/userStore';
import { FocReason, navigateBack } from '../../utilities/helper';
import { InputStates } from '../../components/new/primitive/Input/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { updateParking } from '../../relay/parkingApi';
import { editAmountScreenQuery } from './API/editAmountScreenQuery';
import useCommonStore from '../../stores/commonStore';

// ------ foc Reasons for free of charge exit ------
const focReasonData = [
  { id: 0, name: 'Valet', key: FocReason.VALET_EXIT },
  { id: 1, name: 'Employee', key: FocReason.EMPLOYEE_EXIT },
  { id: 2, name: 'VIP', key: FocReason.VIP_EXIT },
  { id: 3, name: 'Missed', key: FocReason.MISSED_EXIT },
  { id: 4, name: 'Customer Issue', key: FocReason.CUSTOMER_ISSUE },
  { id: 5, name: 'Other', key: FocReason.OTHER },
];

const EditAmountScreen = (props) => {
  const { navigation, queryOptions, variables } = props ?? {};
  const scrollViewRef = useRef(null);
  const reasonInputRef = useRef(null);
  const userRole = useUserStore((state) => state.role);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [errorHelperText, setErrorHelperText] = useState('');
  const [inputState, setInputState] = useState(InputStates.default);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const { findTasks } = useLazyLoadQuery(editAmountScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });
  const [inputValue, setInputValue] = useState('');

  const { parking, _id } = findTasks[0] ?? {};
  /* ------ if there is some amount from paymentDetails (this is editable amount)
   then show amount
   else show totalPayable from charges (this is our estimated/original amount)
  */
  const finalPayableAmount =
    parking?.parkingInvoice?.paymentDetails?.amount !== null
      ? parking?.parkingInvoice?.paymentDetails?.amount
      : parking?.parkingInvoice?.charges?.totalPayable;
  const [value, setValue] = useState(finalPayableAmount.toString());

  // ------ handleUpdateParking if response then navigateBack ------
  const handelUpdateParking = async (response) => {
    if (response?.updateParking !== null) {
      navigateBack(navigation, userRole);
    }
  };

  const findSelectedReason = focReasonData[selectedIndex];

  // ------ API error handler ------
  const updateApiErrorHandler = (error) => {
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v2,
    });
  };

  // ------ handle on pressing Continue update amount in paymentDetails ------
  const handleContinue = () => {
    const data = {
      id: parking?._id,
      record: {
        parkingInvoice: {
          paymentDetails: {
            amount: parseInt(value, 10),
            focReason: findSelectedReason.key,
            message: inputValue?.length ? inputValue : null,
          },
        },
      },
    };
    updateParking(data, handelUpdateParking, updateApiErrorHandler);
  };

  const handleTextChange = (i) => {
    setInputValue(i);
  };

  const handleOnFocusOutReasonInput = () => {
    if (inputValue?.length < 2) {
      setErrorHelperText('Input minimum 10 characters');
      setInputState(InputStates.error);
    }
  };

  const handleOnFocusReasonInput = () => {
    setErrorHelperText('');
  };

  const checkIfButtonDisabled = () => {
    if (
      Number.isNaN(parseInt(value, 10)) ||
      value === finalPayableAmount ||
      selectedIndex === null
    ) {
      return true;
    }
    if (
      (selectedIndex === 4 || selectedIndex === 5) &&
      inputValue?.length <= 10
    ) {
      return true;
    }
    return false;
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
    >
      <ScrollView
        ref={scrollViewRef}
        // contentContainerStyle={{ flexGrow: 1 }}
        // style={{ flex: 1 }}
        keyboardShouldPersistTaps="handled"
        automaticallyAdjustKeyboardInsets
      >
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
        <View>
          <View px="2xl">
            <View
              my="4xl"
              borderRadius="md"
              bg="primary.10"
              shadow="lg"
              //   alignItems="center"
              //   jutifyContent="center"
              p="2xl"
            >
              <Text size="lg" color="primary.300" textAlign="center">
                Enter Bill Amount (₹)
              </Text>
              <View mt="2xl">
                <Input
                  placeholder="₹0"
                  value={`${value}`}
                  state={InputStates.unstyled}
                  textSize="5xl"
                  onChangeText={(i) => {
                    const modifiedInput = i.replace(/[^0-9]/g, '');
                    setValue(modifiedInput);
                  }}
                  multiline
                  inputMode="numeric"
                  keyboardType="numeric"
                  textAlign="center"
                  style={{
                    textAlign: 'center',
                  }}
                  maxLength={7}
                  autoFocus
                />
              </View>
            </View>
          </View>
          {value !== null && value !== finalPayableAmount.toString() ? (
            <View mt="2xl">
              <Text size="lg" weight="medium" px="2xl">
                Select reason for editing fee
              </Text>
              <ChipGroup
                data={focReasonData}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                callbackFn={(item, index) => {
                  if (index === 4 || index === 5) {
                    try {
                      setTimeout(() => {
                        reasonInputRef?.current?.focus();
                        setTimeout(() => {
                          scrollViewRef?.current?.scrollToEnd();
                        }, 200);
                      }, 200);
                    } catch (error) {
                      console.log('errro-------', error);
                    }
                  }
                }}
                multiLine
              />
              {selectedIndex === 4 || selectedIndex === 5 ? (
                <View px="2xl" mt="2xl">
                  <Input
                    inputRef={reasonInputRef}
                    value={inputValue}
                    onChangeText={handleTextChange}
                    errorHelperText={errorHelperText}
                    placeholder="Enter Reason..."
                    maxLength={256}
                    minLength={10}
                    state={inputState}
                    onFocus={handleOnFocusReasonInput}
                    onBlur={handleOnFocusOutReasonInput}
                  />
                </View>
              ) : (
                <></>
              )}
            </View>
          ) : (
            <></>
          )}
          {/* <View height={200} /> */}
        </View>
        {/* </TouchableWithoutFeedback> */}
        <View height={Platform.OS !== 'ios' ? 100 : 0} />
      </ScrollView>

      <View px="2xl" bg="background.primary.base">
        <Button
          appearance="filled"
          state={checkIfButtonDisabled() ? 'disabled' : 'active'}
          size="lg"
          my="2xl"
          onPress={handleContinue}
        >
          Continue
        </Button>
      </View>
    </KeyboardAvoidingView>
  );
};

const EditAmountScreenWrapper = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const campusID = useCommonStore((state) => state.campusId);
  const { taskID } = route?.params ?? {};
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const taskFilter = {
    campus: { _id: campusID },
    _id: taskID,
  };

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
        title="Payment"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <EditAmountScreen
            variables={{
              taskFilter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default EditAmountScreenWrapper;
