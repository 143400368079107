import React, { useMemo, useRef, useEffect } from 'react';
import { StyleSheet, Pressable, ActivityIndicator } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { getTheme, themeModes } from '../../../../../themes/new/theme';
import { useKioskStore } from '../../../../../stores/kiosk/kioskStore';

interface AnimatedButtonProps {
  children: string;
  onPress: () => void;
  loading: boolean;
}

const AnimatedButton = ({
  children,
  loading = false,
  onPress,
}: AnimatedButtonProps) => {
  const timeoutRef = useRef(null);
  const themeMode: string = useKioskStore((state) => state.theme);
  const theme = getTheme(themeMode as themeModes);
  const { filledVariantClr, outlineVariantClr } = useMemo(
    () => generateColors(themeMode as themeModes),
    [themeMode],
  );

  const sv = useSharedValue(outlineVariantClr);

  const btnAnimatedStyle = useAnimatedStyle(() => ({
    backgroundColor: withTiming(sv.value.bg, {
      duration: 300,
    }),
    borderColor: withTiming(sv.value.border),
  }));

  const textAnimatedStyle = useAnimatedStyle(() => ({
    color: withTiming(sv.value.text, {
      duration: 300,
    }),
  }));

  useEffect(() => {
    const timeout = setTimeout(() => {
      sv.value = filledVariantClr;
    }, 5000);
    timeoutRef.current = timeout;
  }, [filledVariantClr]);

  return (
    <Pressable
      onPress={() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        if (loading) return;
        onPress();
      }}
    >
      <Animated.View style={[styles.button, btnAnimatedStyle]}>
        {loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <Animated.Text style={[styles.label, textAnimatedStyle]}>
            {children}
          </Animated.Text>
        )}
      </Animated.View>
    </Pressable>
  );
};

const generateColors = (themeMode: themeModes) => {
  const theme = getTheme(themeMode);
  return {
    filledVariantClr: {
      border: theme.colors.primary[500],
      bg: theme.colors.primary[500],
      text: theme.colors.primary[10],
    },
    outlineVariantClr: {
      border: theme.colors.primary[500],
      bg: 'transparent',
      text: theme.colors.primary[500],
    },
  };
};

const styles = StyleSheet.create({
  button: {
    width: '100%',
    borderRadius: 20,
    borderWidth: getTheme().borderWidths.xs,
    alignItems: 'center',
    paddingVertical: getTheme().space['9xl'],
  },
  label: {
    fontSize: getTheme().fontSizes['3xl'],
    fontFamily: getTheme().fonts.bodyMedium,
  },
});

export default AnimatedButton;
