/* eslint-disable import/prefer-default-export */

import moment from 'moment';

export interface EarningListItemType {
  date: string;
  formatedDate: string;
  onTimeCount: number;
  delayedCount: number;
  bonus: number;
}

export interface PayoutListItemType {
  month: string;
  year: string;
  payout: number;
  isCurrentMonth: boolean;
}

export const toRupees = (amount: number | string) => `₹${amount}`;

export const getBonusTextColor = (
  onTimeTasks: number,
  delayedTasks: number,
): string => {
  if (onTimeTasks > 0) return 'success.500';
  if (delayedTasks > 0) return 'error.500';
  return 'success.500';
};

export const buildEarningListData = <T extends Readonly<Record<string, any>[]>>(
  monthlyBonusData: T,
  today: string,
): EarningListItemType[] => {
  const filtertedToday = monthlyBonusData.filter((md) => {
    return !moment(md.date).isSame(today, 'day');
  });

  return filtertedToday.map((mb: T[number]) => {
    const { onTimeTaskCount, delayedTaskCount, bonusAmount } = mb.bonusData;

    return {
      date: mb.date,
      formatedDate: moment(mb.date).format('ddd, D MMM'),
      onTimeCount: onTimeTaskCount,
      delayedCount: delayedTaskCount,
      bonus: bonusAmount,
    };
  });
};

export const buildPayoutListData = (
  bonuses: Readonly<
    {
      year: string;
      month: string;
      monthName: string;
      bonusAmount: number;
    }[]
  >,
): PayoutListItemType[] => {
  const nonZeroBonuses = bonuses.filter((bonus) => bonus.bonusAmount > 0);
  return nonZeroBonuses.map(({ monthName, bonusAmount, month, year }) => {
    const date = moment();
    date.month(Number(month) - 1);
    date.year(Number(year));
    const isCurrentMonth = date.isSame(moment(), 'month');
    return {
      year,
      month: monthName,
      payout: bonusAmount,
      isCurrentMonth,
    };
  });
};
