import React from 'react';
import { Image, Pressable } from 'react-native';
import View from '../../../basic/View/View';
import Text from '../../../primitive/Typography/Text/Text';
import { handleDirections } from '../../../../../utilities/handleLinking';
import Icon from '../../../primitive/Icon/Icon';
import { getTheme } from '../../../../../themes/new/theme';
import { FontWeights } from '../../../../../themes/new/helper';

const theme = getTheme();

const PartnerListItem = ({ data }) => (
  <View px="2xl" pb="2xl">
    <View
      shadow="sm"
      bg="background.primary.elevated"
      p="2xl"
      flexDirection=" row"
      alignItems="center"
      borderWidth="xs"
      borderRadius="lg"
      borderColor="primary.50"
    >
      {data?.logo ? (
        <Image
          source={{ uri: data?.logo }}
          style={{
            width: 42,
            height: 42,
            borderRadius: theme.radii.full,
            resizeMode: 'cover',
          }}
        />
      ) : (
        <Icon name="account-filled-300" size="9xl" color="primary.500" />
      )}
      <View ml="xl">
        <Text size="md" color="primary.400" weight={FontWeights.MEDIUM}>
          {data?.location}
        </Text>
        <Pressable onPress={() => handleDirections(data?.lat, data?.long)}>
          <Text size="sm" color="info.500">
            Open In Maps
          </Text>
        </Pressable>
      </View>
    </View>
  </View>
);

export default PartnerListItem;
