/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const controllerDailyBonusQuery = graphql`
  query controllerDailyBonusQuery($filter: DailyControllerTaskBonusFilter!) {
    dailyControllerTaskBonus(filter: $filter) {
      date
      bonus {
        onTimeTaskCount
        delayedTaskCount
        bonusAmount
      }
    }
  }
`;
