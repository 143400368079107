/**
 * @generated SignedSource<<d9c37328d887472dd5b1f37a79aac2d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type userApiQuery$variables = {
  id: any;
};
export type userApiQuery$data = {
  readonly findUserById: {
    readonly _id: any;
    readonly contact: {
      readonly phone: {
        readonly number: string;
        readonly prefix: string;
      };
    };
    readonly name: {
      readonly first: string;
      readonly last: string | null;
    };
  } | null;
};
export type userApiQuery = {
  response: userApiQuery$data;
  variables: userApiQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "findUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserName",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContactPhone",
            "kind": "LinkedField",
            "name": "phone",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userApiQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userApiQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c60a32b4a0f3aa9a7f3d67ceab0747b1",
    "id": null,
    "metadata": {},
    "name": "userApiQuery",
    "operationKind": "query",
    "text": "query userApiQuery(\n  $id: MongoID!\n) {\n  findUserById(_id: $id) {\n    _id\n    name {\n      first\n      last\n    }\n    contact {\n      phone {\n        number\n        prefix\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fe7f2ee4fd1a371b61a6bc953aef637";

export default node;
