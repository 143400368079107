import EventEmitter from 'events';

export enum EventBusEventsEnum {
  SCROLL_TO_TOP_TAB_1 = 'SCROLL_TO_TOP_TAB_1',
  SCROLL_TO_TOP_TAB_2 = 'SCROLL_TO_TOP_TAB_2',
  SCROLL_TO_TOP_TAB_3 = 'SCROLL_TO_TOP_TAB_3',
  SCROLL_TO_TOP_TAB_4 = 'SCROLL_TO_TOP_TAB_4',
  SCROLL_TO_TOP_TAB_5 = 'SCROLL_TO_TOP_TAB_5',
  RESTAURANT_PAGE_FILTER_SCROLL = 'RESTAURANT_PAGE_FILTER_SCROLL',
}

export default class EventBus {
  private static emitter: EventEmitter;

  public static emitEvent(eventName: EventBusEventsEnum, payload: any) {
    if (!this.emitter) {
      this.emitter = new EventEmitter();
    }
    this.emitter.emit(eventName, payload);
  }

  public static getInstance() {
    if (!this.emitter) {
      this.emitter = new EventEmitter();
    }
    return this.emitter;
  }
}
