import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from '../../relay/relayEnvironment';

/* eslint-disable import/prefer-default-export */
const query = graphql`
  query getPaymentInfoQuery(
    $amount: Float
    $useRewardPoints: Boolean!
    $orderId: ID!
    $orderType: Referer!
  ) {
    getNetPrice(
      amount: $amount
      useRewardPoints: $useRewardPoints
      orderId: $orderId
      orderType: $orderType
    ) {
      priceDetails {
        grossAmount
        discountGiven
        rewardsUsed
        netAmount
      }
      discountPercentage
      cashbackPercentage
      cashbackAmount
    }
  }
`;

export const getPaymentInfo = ({
  amount,
  useRewardPoints,
  orderId,
  orderType,
}) => {
  const variables = { amount, useRewardPoints, orderId, orderType };
  return fetchQuery(environment, query, variables).toPromise();
};
