import React from 'react';
import { Dimensions, Image } from 'react-native';
import {
  dynamicHeightMaker,
  imageTransformation,
} from '../../../utilities/helper';
import { Text, View, Pressable, Icon } from '../index';
import useCommonStore from '../../../stores/commonStore';
// import theme from '../../../themes/new/theme';

const AwardsAndRecognitionCard = (props) => {
  const { url, showLastItem, onPress, theme } = props;
  const { width, height } = Dimensions.get('screen');
  const screenOrientationValue = useCommonStore((state) => state.orientation);
  const widthProp =
    width > 1200 && screenOrientationValue < 3 ? 375 * 0.567 : width * 0.567;

  const RenderCard = () => (
    <Pressable
      onPress={onPress}
      style={{ borderRadius: theme.radii.lg, ...theme.boxShadows.sm }}
    >
      <Image
        source={{
          uri: imageTransformation(
            url,
            widthProp,
            `${widthProp}-${widthProp * 1.497}`,
          ),
        }}
        style={{
          width:
            width > 1200 && screenOrientationValue < 3
              ? 375 * 0.567
              : width * 0.567,
          height: dynamicHeightMaker(widthProp, 1.497),
          borderRadius: theme.radii.lg,
        }}
        resizeMode="cover"
      />
    </Pressable>
  );
  return (
    <>
      {!showLastItem ? (
        <RenderCard />
      ) : (
        <>
          <RenderCard />
          <Pressable
            style={{
              position: 'absolute',
              width:
                width > 1200 && screenOrientationValue < 3
                  ? 375 * 0.57
                  : width * 0.57,
              height: '100%',
              justifyContent: 'center',
              backgroundColor: theme.colors.gradient[70],
              borderRadius: theme.radii.lg,
            }}
            onPress={onPress}
          >
            <View
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Text size="md" weight="medium" color="background.primary.base">
                All Awards
              </Text>
              <View>
                <Icon
                  name="keyboard-arrow-right-300"
                  color="primary.10"
                  size="xl"
                />
              </View>
            </View>
          </Pressable>
        </>
      )}
    </>
  );
};

export default AwardsAndRecognitionCard;
