import React from 'react';
import {
  View,
  Avatar,
  Text,
  Pressable,
  Icon,
  Card,
} from '../../../components/new';
import { getTheme } from '../../../themes/new/theme';

interface UserCardProps {
  imageLink: string;
  fullName: string;
  number: string;
  onPress: () => void;
  marginHorizontal: string;
  parkingCount: string;
  isVerified: boolean;
  disabled?: boolean;
}

const UserCard = ({
  imageLink = 'https://ik.imagekit.io/32ndhub/assets/nouser.png',
  fullName = '',
  number = '',
  onPress = () => {},
  marginHorizontal = '2xl',
  parkingCount = '00',
  isVerified = false,
  disabled = false,
}: UserCardProps) => {
  return (
    <Card
      category="none"
      mx={marginHorizontal}
      borderColor="primary.50"
      borderWidth="xs"
    >
      <Pressable onPress={onPress} disabled={disabled}>
        <View px="2xl" py="lg">
          <View
            flexDirection="row"
            alignItems="center"
            //   mb="lg"
            justifyContent="space-between"
          >
            <View flexDirection="row" alignItems="center">
              <Avatar level={1} source={imageLink} resizeMode="cover" />
              <View ml="2xl">
                <View flexDirection="row" alignItems="center">
                  <Text size="sm" color="primary.500">
                    {number}
                  </Text>
                  {isVerified ? (
                    <View ml="lg">
                      <Icon
                        name="check-filled-400"
                        size="md"
                        color="primary.500"
                      />
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <Text size="xs" color="primary.100" mt="sm">
                  {fullName}
                </Text>
              </View>
            </View>
            <View flexDirection="row" alignItems="center">
              <Text size="sm" color="primary.200" weight="medium" ml="sm">
                {parkingCount} visits
              </Text>
            </View>
          </View>
        </View>
      </Pressable>
    </Card>
    // </View>
  );
};

export default UserCard;
