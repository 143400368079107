/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const transactionsEarningReportFragment = graphql`
  fragment transactionsEarningReportFragment on Query
  @refetchable(queryName: "transactionsEarningReportFragmentQuery")
  @argumentDefinitions(filter: { type: "PaymentReportFilter!" }) {
    earingReports: getPaymentReports(filter: $filter) {
      date
      period
      transactionsCount
      grossAmount
      netAmount
      payoutAmount
      discountGiven
      rewardPointsUsed
      rewardPointsGranted
      rewardPointsCompensated
    }
  }
`;
