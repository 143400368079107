import React from 'react';
import CachedImageBackground from '../../../components/new/custom/CachedImageBackground';
import PaymentsImage from '../../../../assets/images/payments.webp';
import { getTheme, themeModes } from '../../../themes/new/theme';
import { Text, View } from '../../../components/new';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { FontWeights } from '../../../themes/new/helper';
import { toRupees } from '../../../utilities/helper';
import CachedImage from '../../../components/new/custom/CachedImage';

const GOLD_METAL_BG = require('../../../../assets/images/black_membership/golden-metal-texture.png');
const PercentLottie = require('../../../../assets/images/black_membership/lottie/percent-dark.json');

const lightTheme = getTheme(themeModes.light);

const MembershipBenefitStrip = ({
  cashback,
  fallbackContent,
}: {
  cashback: number;
  fallbackContent: string;
}) => {
  return Boolean(cashback) || Boolean(fallbackContent) ? (
    <View height={44}>
      <CachedImageBackground
        source={GOLD_METAL_BG}
        style={{ justifyContent: 'center', width: '100%', height: '100%' }}
        imageStyle={{
          resizeMode: 'cover',
        }}
      >
        <View py="sm+md" px="2xl" flexDirection="row" alignItems="center">
          <View mr="lg">
            <CustomLottieView
              autoPlay
              loop
              style={{
                height: 24,
                width: 24,
              }}
              containerWidth={24}
              source={PercentLottie}
            />
          </View>
          {cashback ? (
            <View flexDirection="row">
              <View>
                <Text
                  flex={1}
                  size="xs"
                  color={lightTheme.colors.primary[500]}
                  weight={FontWeights.MEDIUM}
                >
                  {`${toRupees(
                    cashback,
                  )} cashback will be credited in your 32nd `}
                </Text>
              </View>
              <View>
                <CachedImage
                  source={PaymentsImage}
                  style={{ height: 16, width: 16 }}
                />
              </View>
              <View>
                <Text
                  size="xs"
                  color={lightTheme.colors.primary[500]}
                  weight={FontWeights.MEDIUM}
                >
                  {' '}
                  balance!
                </Text>
              </View>
            </View>
          ) : (
            Boolean(fallbackContent) && (
              <Text
                flex={1}
                size="xs"
                color={lightTheme.colors.primary[500]}
                weight={FontWeights.MEDIUM}
              >
                {fallbackContent}
              </Text>
            )
          )}
        </View>
      </CachedImageBackground>
    </View>
  ) : null;
};

export default MembershipBenefitStrip;
