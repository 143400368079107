/**
 * @generated SignedSource<<3c23e5b2ae3a4d8943ecaf9702d4da18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type requestEventTicketCancellationInput = {
  invoiceId: any;
  reason: string;
};
export type cancelTicketMutation$variables = {
  record: requestEventTicketCancellationInput;
};
export type cancelTicketMutation$data = {
  readonly requestEventTicketCancellation: {
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type cancelTicketMutation = {
  response: cancelTicketMutation$data;
  variables: cancelTicketMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "record",
    "variableName": "record"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cancelTicketMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOneeventticketCancellationPayload",
        "kind": "LinkedField",
        "name": "requestEventTicketCancellation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cancelTicketMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOneeventticketCancellationPayload",
        "kind": "LinkedField",
        "name": "requestEventTicketCancellation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c9eec665c61accfadd884d435a1812d",
    "id": null,
    "metadata": {},
    "name": "cancelTicketMutation",
    "operationKind": "mutation",
    "text": "mutation cancelTicketMutation(\n  $record: requestEventTicketCancellationInput!\n) {\n  requestEventTicketCancellation(record: $record) {\n    error {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eee1e80eb37a0a8cca73bcdad7939cb2";

export default node;
