/* eslint-disable jsx-a11y/iframe-has-title */
import * as React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { WebView } from 'react-native-webview';
import { View, TopNavigation, IconButton } from '../../components/new';

const CustomWebView = ({ navigation, route }) => {
  const { url, title, showLeft } = route?.params ?? {};

  const { width, height } = useWindowDimensions();

  return (
    <View flex={1}>
      <TopNavigation
        IconRight={
          showLeft ? (
            <View />
          ) : (
            <IconButton
              name="close-outline-300"
              size="md"
              appearance="ghost"
              iconSize="2xl"
              iconColor="primary.500"
              onPress={() => {
                navigation.goBack();
              }}
            />
          )
        }
        IconLeft={
          showLeft ? (
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconSize="2xl"
              iconColor="primary.500"
              onPress={() => {
                navigation.goBack();
              }}
            />
          ) : (
            <View />
          )
        }
        title={title || ''}
      />
      {Platform.OS !== 'web' ? (
        <WebView
          style={{ width, height }}
          source={{ uri: url }}
          automaticallyAdjustContentInsets
          allowsBackForwardNavigationGestures
          autoManageStatusBarEnabled
          androidHardwareAccelerationDisabled
        />
      ) : (
        <></>
      )}
    </View>
  );
};

export default CustomWebView;
