/* eslint-disable import/prefer-default-export */
export enum VehicleStatusTagEnum {
  recalled = 'Recalled',
  parked = 'Parked',
  parking = 'Parking',
  out = 'Out',
  OUT = 'OUT',
  OUTGOING = 'OUTGOING',
  reParking = 'Re-Parking',
  reParked = 'Re-Parked',
  created = 'created',
  accepted = 'accepted',
  started = 'started',
}
