/* eslint-disable no-extra-boolean-cast */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  Suspense,
} from 'react';
import * as Device from 'expo-device';
import {
  Platform,
  useWindowDimensions,
  BackHandler,
  Linking,
  TouchableOpacity,
} from 'react-native';
import { Camera } from 'expo-camera';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useFocusEffect } from '@react-navigation/native';
import { keyBy } from 'lodash';
import uuid from 'react-native-uuid';
import { ActionSheetRef } from 'react-native-actions-sheet';
import {
  Icon,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../components/new';
import {
  handleStorePress,
  mediaTypes,
  navigateBack,
} from '../utilities/helper';
import photoMoments from '../screens/Strings';
import Camscanner from './photomoments/Camscanner';
import PhotoLibrary from './photomoments/PhotoLibrary';
import { useSnackbarStore } from '../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../components/new/primitive/snackbar/helpers/helpers';
import useLoginModalStore from '../stores/loginModalStore';
import useAuthStore from '../stores/authStore';
import {
  InteractionType,
  firebaseEventLogger,
} from '../utilities/firbaseAnalytics';
import PMLanding from './photomoments/PMLanding';
import PMLoader from './photomoments/PMLoader';
import PMNophotos from './photomoments/PMNophotos';
import {
  isValueNullOrEmpty,
  showNotificationPopupLogic,
} from '../utilities/Utility';
import useUserStore, { UserRole } from '../stores/userStore';
import { useDownloadAppStore } from '../stores/downloadApp/downloadAppStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../utilities/NewErrorBoundary';
import Loading from '../components/loading.component';
import NewErrorView from '../utilities/NewErrorView';
import getPhotoMomentsUserPhotosMappingApi from '../relay/getPhotoMomentsUserMappingApi';
import findPhotosV3Api from '../relay/findPhotosV3Api';
import useCommonStore, { faqDataPM } from '../stores/commonStore';
import useVideoMomentsStore from '../stores/VideoMomentsStore';
import { bottomNudgeType } from '../stores/nudges/bottomNudges/bottomNudgesSlice';
import { useBottomNudges } from '../stores/nudges/bottomNudges/bottomNudgesStore';
import { uploadImageToS3Query } from '../relay/microServicesApis';
import useNotificationModalStore from '../stores/notificationModalStore';
import { getTheme } from '../themes/new/theme';
import { updateUserV2 } from '../relay/userApi';
import BottomSheetV2 from '../components/new/composites/BottomSheet/BottomSheetV2';

const PhotoMoments = ({ navigation }) => {
  const userRole = useUserStore((state) => state.role);
  const userId = useUserStore((state) => state.id);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { dispatchDownloadApp } = useDownloadAppStore((state) => state);
  const bottomSheetRescanRef = useRef<ActionSheetRef>(null);
  const insets = useSafeAreaInsets();
  const [currentFlow, setCurrentFlow] = useState(-1); // 0-intro || 1-camera || 2-loader || 3-library || 4-No Photos
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [imageUri, setImageUri] = useState('');
  const [loader, setLoader] = useState(false);
  const [photoGallery, setPhotoGallery] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [favoriteList, setFavoriteList] = useState([]);
  const [deletedMoments, setDeletedMoments] = useState([]);
  const { width } = useWindowDimensions();
  const [isPhotoMomentLoading, setIsPhotoMomentLoading] = useState(true);
  const setFaqData = useCommonStore((state) => state.setFaqScreenData);
  const {
    momentsData,
    setMomentsData,
    setPhotoMomentsForWidget,
    setScannedS3Imagekey,
    scannedS3Imagekey,
  } = useVideoMomentsStore((state) => state);

  const setNotificationUiText = useNotificationModalStore(
    (state) => state.setUiText,
  );

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const loginModalStore = useLoginModalStore((state) => state);
  const { openBottomNudge, closeBottomNudge } = useBottomNudges();
  const onLoginButtonPress = useCallback(() => {
    loginModalStore.setIsOpen(true);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backActionHandler = async () => {
    if (userRole === UserRole.PHOTO_MOMENTS_PARTNER) {
      navigation.replace('user');
      return;
    }
    if (photoGallery?.length && currentFlow === 1) {
      await setCurrentFlow(3);
      return true;
    }
    if (
      (currentFlow === 1 || currentFlow === 4) &&
      isValueNullOrEmpty(scannedS3Imagekey)
    ) {
      await setCurrentFlow(0);
      return true;
    }
    await navigateBack(navigation, userRole);
    return false;
  };

  const showDownloadAppPopover = () => {
    dispatchDownloadApp({
      visible: true,
      title: photoMoments.oneStep,
      dsc: photoMoments.downloadApp,
      btnText: photoMoments.downloadNow,
      lottieImgSrc: require('../../assets/lottie/photo-video.json'),
      onBtnPress: () => {
        firebaseEventLogger('photoMoments__downloadApp_Tap', {
          buttonName: 'downloadApp',
          screenName: 'photoMoments',
          userType: 'user',
          interactionType: 'tap',
        });
        const webOsName = Device.osName;
        handleStorePress(webOsName);
      },
    });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      setCurrentFlow(0);
      return;
    }
    if (isLoggedIn && Platform.OS === 'web') {
      setCurrentFlow(3);
      return;
    }
    if (isLoggedIn && global.checkPermission) {
      checkPermission(true);
      global.checkPermission = false;
    }
  }, [isLoggedIn]);

  useEffect(() => {
    init();
    if (isLoggedIn) {
      getUserMapping();
    }
  }, []);

  // for filtering out deleted and favorites images from photo viewer
  useFocusEffect(
    React.useCallback(() => {
      if (global.changesNeeded) {
        getUserMapping();
        global.changesNeeded = false;
      }
      return () => {
        closeBottomNudge();
      };
    }, []),
  );
  // for filtering out deleted and favorites images from photo viewer
  const init = async () => {
    if (isLoggedIn) {
      if (
        !isValueNullOrEmpty(momentsData) &&
        !isValueNullOrEmpty(scannedS3Imagekey)
      ) {
        checkPhotos(JSON.parse(momentsData));
      } else {
        setCurrentFlow(2);
      }
      checkRefresh();
    }
    return () => {
      global.checkPermission = false;
    };
  };

  const getUserMapping = () => {
    getPhotoMomentsUserPhotosMappingApi()
      .then((response) => {
        const {
          userId: usrId,
          likedImages,
          deletedImages,
        } = response?.getPhotoMomentsUserMapping;
        if (!isValueNullOrEmpty(usrId)) {
          setFavoriteList(likedImages);
          setDeletedMoments(deletedImages);
          setIsPhotoMomentLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkPhotos = async (photos) => {
    if (!!photos) {
      const parsedPhotos = photos;
      const parsedDeleted = deletedMoments;
      let finalArray = parsedPhotos;
      if (!!deletedMoments && parsedPhotos?.length) {
        const photoMomentsMap = keyBy(parsedPhotos, 'assetId');

        parsedDeleted?.forEach((assetId) => {
          if (photoMomentsMap?.[assetId]) {
            delete photoMomentsMap[assetId];
          }
        });
        finalArray = Object.values(photoMomentsMap);
      }
      if (finalArray?.length) {
        setPhotoGallery(finalArray);
        setIsPhotoMomentLoading(false);
        setCurrentFlow(3);
        return;
      }
      renderNoResultFound(false);
      setPhotoMomentsForWidget([], '', 0);
      return;
    }
    setCurrentFlow(0);
  };

  const requestPermissionAgain = () => {
    Linking.openSettings();
  };

  const checkPermission = (direct) => {
    if (!direct) {
      firebaseEventLogger('photoMoments__scanAndRelive_Tap', {
        buttonName: 'scanAndRelive',
        screenName: 'photoMoments',
        userType: 'user',
        interactionType: 'tap',
        isLoggedIn,
      });
    }
    if (Platform.OS === 'web') {
      openBottomNudge({
        bottomNudgeName: bottomNudgeType.photoMoments,
      });
      return;
    }
    if (!isLoggedIn) {
      onLoginButtonPress();
      global.checkPermission = true;
      return;
    }
    if (permission?.granted) {
      setCurrentFlow(1);
    } else {
      requestPermission()
        .then((res) => {
          firebaseEventLogger('photoMoments__cameraPermission_misc', {
            buttonName: 'cameraPermission',
            screenName: 'photoMoments',
            userType: 'user',
            interactionType: 'misc',
            permission: res?.status,
          });
          if (res?.status === 'granted') {
            setCurrentFlow(1);
          } else {
            dispatchSnackbar({
              msg: photoMoments.permissionDenied,
              status: SnackbarStatus.error,
              version: SnackbarVersion.v1,
            });
            setTimeout(() => {
              requestPermissionAgain();
            }, 1000);
          }
        })
        .catch((err) => {
          firebaseEventLogger('photoMoments__cameraPermission_misc', {
            buttonName: 'cameraPermission',
            screenName: 'photoMoments',
            userType: 'user',
            interactionType: 'misc',
            permission: 'error',
          });
          console.log(err);
        });
    }
  };

  const renderNoResultFound = (isDeleted) => {
    setCurrentFlow(4);
    if (!isDeleted) {
      dispatchSnackbar({
        msg: photoMoments.noPhotosFound,
        status: SnackbarStatus.error,
        version: SnackbarVersion.v1,
      });
    }
  };

  const findPhotosV3 = async (data) => {
    setScannedS3Imagekey(data.scannedS3Imagekey);
    let savedResponse = [];
    if (!isValueNullOrEmpty(momentsData)) {
      savedResponse = JSON.parse(momentsData);
    }
    setLoader(true);
    const PhotoMomentsInputV2 = {};
    (PhotoMomentsInputV2 as any).assetId = data.scannedS3Imagekey;
    (PhotoMomentsInputV2 as any).rescanning = data?.rescanning ?? false;
    const startTime = new Date().valueOf();
    findPhotosV3Api(PhotoMomentsInputV2)
      .then((response) => {
        setRefreshing(false);
        setLoader(false);
        setImageUri('');
        const { getMatchingPhotosForPhotoMomentsV3 = [] } = response;
        setMomentsData(JSON.stringify(getMatchingPhotosForPhotoMomentsV3));
        if (getMatchingPhotosForPhotoMomentsV3?.length) {
          if (
            savedResponse?.length !==
              getMatchingPhotosForPhotoMomentsV3?.length ||
            !data?.rescanning
          ) {
            checkPhotos(getMatchingPhotosForPhotoMomentsV3);
          }
          if (!data?.rescanning) {
            global.notifyMe = false;
            firebaseEventLogger('photoMoments__scanFace_Data', {
              buttonName: 'scanFace',
              screenName: 'photoMoments',
              userType: 'user',
              interactionType: 'tap',
            });
          }
          const endTime = new Date().valueOf();
          const timeDiff = (endTime - startTime) / 1000;
          firebaseEventLogger('photoMoments__listingFound_Data', {
            buttonName: 'listingFound',
            screenName: 'photoMoments',
            userType: 'user',
            interactionType: 'data',
            count: String(getMatchingPhotosForPhotoMomentsV3?.length),
            apiResTime: String(timeDiff),
            photosCount: getMatchingPhotosForPhotoMomentsV3?.filter(
              (i) => i?.assetType === mediaTypes.IMAGE,
            )?.length,
            videosCount: getMatchingPhotosForPhotoMomentsV3?.filter(
              (i) => i?.assetType === mediaTypes.VIDEO,
            )?.length,
          });
        } else {
          setNotificationUiText(
            'Get notified when your 32nd Moments are found?',
            'Enable alerts to stay informed when your photos and videos are uploaded here',
            'No',
            'Yes, Allow',
          );
          showNotificationPopupLogic();
          if (!data?.rescanning) {
            global.notifyMe = false;
            firebaseEventLogger('photoMoments__scanNotFound_Data', {
              buttonName: 'scanNotFound',
              screenName: 'photoMoments',
              userType: 'user',
              interactionType: 'data',
            });
          }
          renderNoResultFound(false);
          setPhotoMomentsForWidget([], '', 0);
          setPhotoGallery([]);
        }
      })
      .catch((error) => {
        setLoader(false);
        setImageUri('');
        renderNoResultFound(false);
        setPhotoMomentsForWidget([], '', 0);
        setRefreshing(false);
      });
  };

  const processImage = async (data) => {
    if (!loader) {
      setLoader(true);
      setCurrentFlow(2);
      const payload = {
        base64Image: data,
        bucketName: 'photo-moments-user-face',
        contentType: 'image/jpeg',
        key: String(uuid.v4()),
      };
      const res = await uploadImageToS3Query(payload);
      updateUserV2(userId, {
        photoMomentsSearchImage: res?.uploadImageToS3?.key,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
      findPhotosV3({
        scannedS3Imagekey: res?.uploadImageToS3?.key,
      });
    }
  };

  const takingBackFunction = () => {
    dispatchSnackbar({
      msg: photoMoments.noFaceDetected,
      status: SnackbarStatus.error,
      version: SnackbarVersion.v1,
    });
    setTimeout(() => {
      init();
    }, 1000);
  };

  const checkRefresh = async () => {
    if (isValueNullOrEmpty(scannedS3Imagekey) || !isLoggedIn) {
      setCurrentFlow(0);
      setRefreshing(false);
    } else {
      const data = {
        scannedS3Imagekey,
        rescanning: true,
      };
      findPhotosV3(data);
    }
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await checkRefresh();
  };

  const SelectFlow = () => {
    switch (currentFlow) {
      case 0:
        return <PMLanding checkPermissionFunction={checkPermission} />;
      case 1:
        return (
          <Camscanner
            imageUri={imageUri}
            setImageUriCallback={processImage}
            takingBack={takingBackFunction}
          />
        );
      case 2:
        return <PMLoader />;
      case 3:
        if (isPhotoMomentLoading) {
          return <PMLoader />;
        }
        return (
          <PhotoLibrary
            photoGallery={photoGallery}
            favoriteList={favoriteList}
            deletedMoments={deletedMoments}
            checkDelete={getUserMapping}
            onRefresh={onRefresh}
            refreshing={refreshing}
            noPhotosFound={renderNoResultFound}
            navigateImageViewer={(val) => {
              navigation.navigate('PhotoViewer', {
                data: val?.data,
                photoGallery: val?.photoGallery,
              });
              firebaseEventLogger('photoMoments__openPicture_Tap', {
                buttonName: 'openPicture',
                screenName: 'photoMoments',
                userType: 'user',
                interactionType: 'tap',
                imageId: val?.data?.assetId,
                isVideo: val?.data?.assetType === 'VIDEO',
              });
            }}
          />
        );
      case 4:
        return (
          <PMNophotos
            onRescan={() => {
              if (userRole === UserRole.PHOTO_MOMENTS_PARTNER) {
                navigation.replace('user');
                return;
              }
              firebaseEventLogger('photoMoments__recscan_Tap', {
                buttonName: 'recscan',
                screenName: 'photoMoments',
                userType: 'user',
                interactionType: 'tap',
              });
              setCurrentFlow(1);
            }}
          />
        );
      default:
        return null;
    }
  };

  const rescanFunction = () => {
    handleOnDismiss();
    if (userRole === UserRole.PHOTO_MOMENTS_PARTNER) {
      navigation.replace('user');
      return;
    }
    setTimeout(() => {
      checkPermission(true);
    }, 200);
  };

  const handleOnDismiss = useCallback(() => {
    bottomSheetRescanRef.current?.hide();
  }, []);

  useEffect(() => {
    firebaseEventLogger('photoMoments__land_Landing', {
      buttonName: 'land',
      screenName: 'photoMoments',
      userType: 'user',
      interactionType: InteractionType.LANDING,
    });
    // Add event listener for hardware back button press on Android
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backActionHandler,
    );

    return () => {
      // clear/remove event listener
      backHandler.remove();
    };
  }, []);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const { top } = useSafeAreaInsets();

  const RenderTopNavigation = () => {
    if (currentFlow === 1 || currentFlow === 0) {
      return (
        <View
          style={{
            position: 'absolute',
            backgroundColor: 'primary.500',
            zIndex: 9,
            width: '100%',
          }}
        >
          <TopNavigation
            title={photoMoments.moments}
            appearance="ghost"
            level=""
            textColor="static.white"
            textSize="md"
            IconLeft={
              <IconButton
                name="back-outline-300"
                size="md"
                appearance="ghost"
                iconColor="static.white"
                onPress={backActionHandler}
              />
            }
          />
        </View>
      );
    }
    return (
      <View zIndex={1}>
        <TopNavigation
          title={photoMoments.moments}
          appearance="ghost"
          level="1"
          textColor="primary.500"
          textSize="md"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={backActionHandler}
            />
          }
          IconRight={
            currentFlow === 0 || currentFlow === 3 ? (
              <IconButton
                name="menu-dots-400"
                size="md"
                appearance="ghost"
                iconColor="primary.500"
                onPress={() => {
                  if (currentFlow === 0) {
                    setFaqData(faqDataPM);
                    navigation.navigate('faq');
                  } else {
                    bottomSheetRescanRef.current?.show();
                  }
                }}
              />
            ) : (
              <View width={26} />
            )
          }
        />
      </View>
    );
  };

  const onPressFAQ = () => {
    handleOnDismiss();
    setFaqData(faqDataPM);
    setTimeout(() => {
      navigation.navigate('faq');
    });
  };

  return (
    <Layout level={2}>
      {RenderTopNavigation()}
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense
          fallback={
            <View mt="8xl">
              <Loading />
            </View>
          }
        >
          <View flex={1}>
            {SelectFlow()}
            <BottomSheetV2
              ref={bottomSheetRescanRef}
              onClose={handleOnDismiss}
              insets={false}
            >
              <View mx="2xl">
                <TouchableOpacity
                  style={{ marginTop: getTheme().space['2xl'] }}
                  onPress={rescanFunction}
                >
                  <View
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt="lg"
                  >
                    <Text size="xl" color="primary.500">
                      Re-Scan
                    </Text>
                    <Icon name="keyboard-arrow-right-300" color="primary.500" />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    marginTop: getTheme().space.lg,
                  }}
                  onPress={onPressFAQ}
                >
                  <View
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt="2xl"
                    mb="lg"
                  >
                    <Text size="xl" color="primary.500">
                      FAQ
                    </Text>
                    <Icon name="keyboard-arrow-right-300" color="primary.500" />
                  </View>
                </TouchableOpacity>
              </View>
            </BottomSheetV2>
          </View>
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};
export default PhotoMoments;
