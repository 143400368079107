import React, { useRef } from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import useUserStore, { UserRole } from '../../../stores/userStore';
import { Icon, Pressable, Text, View } from '../../../components/new';
import {
  applyEllipsis,
  transactionTypeEnum,
  walletTransactionEnum,
} from '../../../utilities/helper';
import PointsInfo from '../../../components/new/custom/PointsInfo';
import PointsEarnedBottomsheet from './PointsEarnedBottomSheet';

interface PointListItemProps {
  item: object | null;
  title: string;
  subTitle?: string;
  isAvailable?: boolean;
}

const PointsListItem = ({
  item = {},
  title = '',
  subTitle = '',
  isAvailable = true,
}: PointListItemProps) => {
  const navigation = useNavigation();
  const bottomSheetRef = useRef(null);
  const { role } = useUserStore((state) => state);
  const { amount } = item?.parkingInvoice?.paymentDetails ?? { amount: 0 };
  const { totalPayable } = item?.parkingInvoice?.charges ?? { totalPayable: 0 };
  const { width } = useWindowDimensions();
  // eslint-disable-next-line no-nested-ternary
  const bgColor = item?.vehicleIsWhitelisted
    ? 'primary.50'
    : amount < totalPayable
    ? 'error.10'
    : 'success.10';

  const onPressListItem = () => {
    if (
      item?.type === transactionTypeEnum.debit &&
      item?._id &&
      item?.source?.type !== walletTransactionEnum.pointsExpiry
    ) {
      navigation.navigate('transactionScreen', {
        rewardTxnId: item?._id,
      });
    } else if (
      item?.type === transactionTypeEnum.credit &&
      (item?.source?.type === walletTransactionEnum.firstTxn ||
        (item?.source?.type === walletTransactionEnum.subsequentTxn &&
          item?.source?.type !== walletTransactionEnum.firstTxn) ||
        item?.source?.type === walletTransactionEnum.COUPON_POINTS)
    ) {
      navigation.navigate('transactionScreen', {
        paymentTxnId: item?.source?.refId,
      });
    } else if (
      item?.type === transactionTypeEnum.credit &&
      item?.source?.type === walletTransactionEnum?.refund &&
      item?._id
    ) {
      navigation.navigate('transactionScreen', {
        rewardTxnId: item?._id,
      });
    } else {
      bottomSheetRef?.current?.show();
    }
  };

  return (
    <>
      <Pressable onPress={onPressListItem} disabled={role !== UserRole.USER}>
        <View px="2xl" mb="2xl">
          <View
            borderWidth="xs"
            px="2xl"
            py="lg"
            borderColor="primary.50"
            borderRadius="md"
            bg="primary.10"
            shadow="sm"
          >
            <View
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <View>
                <Text size="sm" color="primary.400">
                  {applyEllipsis(title, 20)}
                </Text>
                <Text size="xs" color="primary.100" mt="xs">
                  {subTitle}
                </Text>
              </View>
              {role === UserRole.CASHIER ? (
                <View
                  p="md"
                  bg={bgColor}
                  flexDirection="row"
                  alignItems="center"
                  borderRadius="md"
                  justifyContent="center"
                  minWidth={85}
                >
                  <Text size="sm" color="primary.500" mx="sm">
                    ₹{applyEllipsis(amount.toString(), 5)}
                  </Text>
                </View>
              ) : (
                <View flexDirection="row" alignItems="center">
                  <PointsInfo
                    type={item?.type}
                    amount={`₹${item?.amount}`}
                    isAvailable={isAvailable}
                  />
                  <View ml="md">
                    <Icon
                      name="keyboard-arrow-right-300"
                      size="xl"
                      color="primary.300"
                    />
                  </View>
                </View>
              )}
            </View>
            {!isAvailable ? (
              <View
                py="xs"
                width={width - 64}
                bg="primary.50"
                mt="2xl"
                borderRadius="xs"
                alignItems="center"
              >
                <Text size="2xs" color="primary.300">
                  Cashback points will be credited within 24 hours
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </Pressable>
      <PointsEarnedBottomsheet
        bottomSheetModalRef={bottomSheetRef}
        totalPointsEarned={item?.amount}
        rewardName={item?.source?.name}
      />
    </>
  );
};

export default PointsListItem;
