/**
 * @generated SignedSource<<007930fceaafc64de99596fbcaecc129>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumInvestmentPropertyLeadsGenerateModelStatus = "APPLICATION_RECEIVED" | "CREATED" | "REFUND_INITIATED" | "REFUND_PROCESSED" | "%future added value";
export type GenerateInvestmentLeadRecordInput = {
  investmentDetails?: GenerateInvestmentLeadInvestmentDetailsInput | null;
  investmentPropertyId: any;
  leadUTM?: InvestmentPropertyLeadsGenerateModelLeadUTMInput | null;
  trackingCode?: string | null;
  updated_at?: any | null;
};
export type InvestmentPropertyLeadsGenerateModelLeadUTMInput = {
  medium?: string | null;
  source?: string | null;
  subSource?: string | null;
};
export type GenerateInvestmentLeadInvestmentDetailsInput = {
  region: string;
};
export type DetailsPageQueryGenerateInvestmentLeadMutation$variables = {
  record: GenerateInvestmentLeadRecordInput;
};
export type DetailsPageQueryGenerateInvestmentLeadMutation$data = {
  readonly generateInvestmentLead: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly record: {
      readonly _id: any;
      readonly investmentDetails: {
        readonly investmentDocs: {
          readonly agreementDoc: string | null;
          readonly offerLetter: string | null;
          readonly termsDoc: string | null;
        } | null;
        readonly investmentManagerDetails: {
          readonly contact: {
            readonly phone: string | null;
            readonly whatsapp: string | null;
          } | null;
          readonly intro: string | null;
          readonly name: string | null;
          readonly profileImage: string | null;
        } | null;
        readonly region: string | null;
      } | null;
      readonly investmentPropertyId: any;
      readonly sfdcLeadId: string;
      readonly status: EnumInvestmentPropertyLeadsGenerateModelStatus | null;
    } | null;
  } | null;
};
export type DetailsPageQueryGenerateInvestmentLeadMutation = {
  response: DetailsPageQueryGenerateInvestmentLeadMutation$data;
  variables: DetailsPageQueryGenerateInvestmentLeadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "record",
    "variableName": "record"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "InvestmentPropertyLeadsGenerateModel",
  "kind": "LinkedField",
  "name": "record",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sfdcLeadId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "investmentPropertyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvestmentPropertyLeadsGenerateModelInvestmentDetails",
      "kind": "LinkedField",
      "name": "investmentDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvestmentPropertyLeadsGenerateModelInvestmentDetailsInvestmentDocs",
          "kind": "LinkedField",
          "name": "investmentDocs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "offerLetter",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "termsDoc",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "agreementDoc",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "region",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InvestmentPropertyLeadsGenerateModelInvestmentDetailsInvestmentManagerDetails",
          "kind": "LinkedField",
          "name": "investmentManagerDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profileImage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "intro",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InvestmentPropertyLeadsGenerateModelInvestmentDetailsInvestmentManagerDetailsContact",
              "kind": "LinkedField",
              "name": "contact",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whatsapp",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailsPageQueryGenerateInvestmentLeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOneInvestmentPropertyLeadsGenerateModelPayload",
        "kind": "LinkedField",
        "name": "generateInvestmentLead",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DetailsPageQueryGenerateInvestmentLeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOneInvestmentPropertyLeadsGenerateModelPayload",
        "kind": "LinkedField",
        "name": "generateInvestmentLead",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c13ed1f675a7e2b55ef1a6ebd90032c3",
    "id": null,
    "metadata": {},
    "name": "DetailsPageQueryGenerateInvestmentLeadMutation",
    "operationKind": "mutation",
    "text": "mutation DetailsPageQueryGenerateInvestmentLeadMutation(\n  $record: GenerateInvestmentLeadRecordInput!\n) {\n  generateInvestmentLead(record: $record) {\n    record {\n      _id\n      sfdcLeadId\n      investmentPropertyId\n      status\n      investmentDetails {\n        investmentDocs {\n          offerLetter\n          termsDoc\n          agreementDoc\n        }\n        region\n        investmentManagerDetails {\n          name\n          profileImage\n          intro\n          contact {\n            phone\n            whatsapp\n          }\n        }\n      }\n    }\n    error {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "793847752f46565689cad41f6313a178";

export default node;
