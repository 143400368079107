import React, { useMemo } from 'react';
import BreakupPanel from '../../../components/new/custom/transaction/BreakupPanel';

type Mode = 'REVENUE' | 'EARNINGS';
interface TransactionsReportPanelProps {
  mode: Mode;
  report: {
    revenueAmount: string;
    points: string;
    discountAmount: string;
    collectedAmount: string;
  };
}

const TransactionsReportPanel = ({
  mode,
  report,
}: TransactionsReportPanelProps) => {
  const { bodyData, footerData } = useMemo(
    () => buildPanelData(report, mode),
    [report],
  );
  return <BreakupPanel bodyData={bodyData} footerData={footerData} />;
};

export default TransactionsReportPanel;

const buildPanelData = (
  report: TransactionsReportPanelProps['report'],
  mode: Mode,
) => ({
  bodyData: [
    {
      leftTxt: 'Total Revenue',
      rightTxt: report?.revenueAmount,
      [mode === 'REVENUE' ? 'color' : '']: 'success.500',
    },
    {
      leftTxt: 'Loyalty Points Redeemed',
      rightTxt: `-${report?.points}`,
    },
    {
      leftTxt: 'Discount',
      rightTxt: `-${report?.discountAmount}`,
    },
  ],
  footerData: [
    {
      leftTxt: mode === 'REVENUE' ? 'Collected Amount' : 'My Earnings',
      rightTxt: report?.collectedAmount,
      [mode === 'EARNINGS' ? 'color' : '']: 'success.500',
    },
  ],
});
