/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback } from 'react';
import { Text, View } from '../../../components/new';
import Loading from '../../../components/loading.component';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import {
  BonusOverviewBanner,
  BonusInfoBottomSheet,
  BonusIncentiveInfoBox,
  BonusConversionInfo,
  BonusEarningList,
  BonusPayoutList,
  NoContentView,
} from '../../../components/new/custom/bonus';
import TabSwitcher from '../../valetSupervisor/new/TabSwitcher';
import useBonusInfoBottomSheet, {
  ContentVariant,
} from '../../../components/new/custom/bonus/BonusInfoBottomSheet/useBonusInfoBottomSheet';
import { toRupees } from '../../../utilities/bonus';
import { useControllerOverview } from './useControllerOverview';
import { controllerBonusAmount } from '../../../utilities/Constant';

const ControllerOverviewScreenInner = ({
  queryOptions,
}: {
  queryOptions: NewErrorBoundaryParentState;
}) => {
  const {
    ref: bonusInfoSheetRef,
    currentState,
    taskCount,
    dismiss,
    showBonusInfo,
    showOnTimeTaskInfo,
    showDelayedTaskInfo,
  } = useBonusInfoBottomSheet();

  const {
    today,
    bannerData,
    earningsTab,
    hasNoEarningItems,
    hasNoPayoutItems,
    earningListItems,
    payoutListItems,
    handleTasksScreenNavigation,
    handleEarningTabSwitch,
  } = useControllerOverview(queryOptions);

  const { bonusAmount, delayedTaskCount, onTimeTaskCount } = bannerData;
  const hasNoEarningAndPayoutItems = hasNoEarningItems && hasNoPayoutItems;
  const { onTime: onTimeAmount, delayed: delayedAmount } =
    controllerBonusAmount;

  // eslint-disable-next-line consistent-return
  const getBottomSheetContent = useCallback(
    (contentStateVariant: ContentVariant) => {
      // eslint-disable-next-line default-case
      switch (contentStateVariant) {
        case ContentVariant.BonusIncentiveInfo:
          return (
            <BonusIncentiveInfoBox
              onTimeBonus={toRupees(onTimeAmount)}
              delayPenalty={toRupees(delayedAmount)}
            />
          );
        case ContentVariant.OnTimeBonusInfo:
        case ContentVariant.DelayedBonusInfo:
          return (
            <BonusConversionInfo
              tasks={
                contentStateVariant === ContentVariant.OnTimeBonusInfo
                  ? onTimeTaskCount
                  : delayedTaskCount
              }
              onTime={contentStateVariant === ContentVariant.OnTimeBonusInfo}
              onTimeBonus={onTimeAmount}
              delayPenalty={delayedAmount}
            />
          );

        default:
          return <></>;
      }
    },
    [onTimeAmount, delayedAmount, onTimeTaskCount, delayedTaskCount],
  );

  return (
    <Suspense fallback={<Loading />}>
      <BonusOverviewBanner
        mode={0}
        bonusAmount={toRupees(bonusAmount ?? 0)}
        onTimeTasks={onTimeTaskCount ?? 0}
        delayedTasks={delayedTaskCount ?? 0}
        navigateToTasks={() => handleTasksScreenNavigation(today, true)}
        showDelayedDetails={() => showDelayedTaskInfo(delayedTaskCount ?? 0)}
        showInfo={showBonusInfo}
        showOnTimeDetails={() => showOnTimeTaskInfo(onTimeTaskCount ?? 0)}
      />
      {hasNoEarningAndPayoutItems ? (
        <View>
          <NoContentView />
          <Text textAlign="center" color="primary.100">
            No Earnings and Payouts yet
          </Text>
        </View>
      ) : (
        <>
          <View mt="4xl">
            <TabSwitcher
              tabOneText="Earnings"
              tabTwoText="Payouts"
              handleTabSwitch={handleEarningTabSwitch}
              currentTab={earningsTab}
              mx="2xl"
            />
          </View>
          {earningsTab === 0 ? (
            <BonusEarningList
              items={earningListItems}
              onClick={handleTasksScreenNavigation}
            />
          ) : (
            <BonusPayoutList items={payoutListItems} />
          )}
        </>
      )}

      <BonusInfoBottomSheet
        ref={bonusInfoSheetRef}
        onDismiss={dismiss}
        Content={getBottomSheetContent(currentState)}
        insets
      />
    </Suspense>
  );
};

export default ControllerOverviewScreenInner;
