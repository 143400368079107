import React from 'react';
import { Pressable } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import moment from 'moment';
import { View, Text } from '../..';
import BonusListItem from './BonusListItem';
import BonusBadge from './BonusBadge';
import NoContentView from './NoContentView';
import GainLossIcon from './GainLossIcon';
import { toRupees } from '../../../../utilities/bonus';

type BonusEarningListProps = {
  onClick: (date: string) => void;
  items: Array<{
    date: string;
    formatedDate: string;
    onTimeCount: number;
    delayedCount: number;
    bonus: number;
  }>;
};

export const formatBonusCardInfo = (
  onTimeCount: string,
  delayedCount: string,
): string => {
  let taskCountInfo = '';
  if (Number(onTimeCount) === 0 && Number(delayedCount) > 0) {
    taskCountInfo = `${delayedCount} Delayed`;
  } else {
    taskCountInfo = `${onTimeCount} On-Time | ${delayedCount} Delayed`;
  }
  return taskCountInfo;
};

const BonusEarningList = ({ items, onClick }: BonusEarningListProps) => {
  items = items.filter((i) => Number(i.delayedCount) || Number(i.onTimeCount));

  return (
    <FlashList
      scrollsToTop
      data={items}
      renderItem={({ item }) => (
        <BonusEarningListItem item={item} onClick={onClick} />
      )}
      ListEmptyComponent={
        <View>
          <NoContentView />
          <Text textAlign="center" color="primary.100">
            No Earnings yet
          </Text>
        </View>
      }
    />
  );
};

const BonusEarningListItem = ({ item, onClick }) => {
  const { bonus, formatedDate, onTimeCount, delayedCount } = item;
  const isGain = bonus > 0;
  const taskCountInfo = formatBonusCardInfo(onTimeCount, delayedCount);
  const ISODate = moment.utc(item.date, 'YYYY-MM-DD').toISOString();

  return (
    <View key={item.date} mb="2xl" px="2xl">
      <Pressable onPress={() => onClick(ISODate)}>
        <BonusListItem
          contentPrimary={formatedDate}
          contentSecondary={taskCountInfo}
          sizePri="sm"
          RightContent={
            <BonusBadge variant={isGain ? 'gain' : 'loss'}>
              <GainLossIcon isGain={isGain} />
              <Text color="primary.500" size="sm" weight="regular">
                {toRupees(Math.trunc(bonus))}
              </Text>
            </BonusBadge>
          }
        />
      </Pressable>
    </View>
  );
};

export default BonusEarningList;
