import { createContext, useEffect, useMemo, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { uniqBy } from 'lodash';

export const RecentSearchContext = createContext([]);

const RecentSearchContextProvide = ({ children }) => {
  const [recentSearch, setRecentSearch] = useState([]);

  // =======================================

  const updateRecentSearches = async (data) => {
    try {
      await AsyncStorage.setItem('recentSearches', JSON.stringify(data));
      setRecentSearch([...data]);
    } catch (e) {
      // TODO:
    }
  };

  const addRecentSearches = async (data) => {
    const prevData = recentSearch;
    // removing the old same values
    let newData = uniqBy([data, ...prevData], '_id');
    newData = [...newData].slice(0, 4);
    // ===========================
    try {
      await AsyncStorage.setItem('recentSearches', JSON.stringify(newData));
      setRecentSearch([...newData]);
    } catch (e) {
      // TODO:
    }
  };

  // ========================================

  useEffect(() => {}, [recentSearch]);

  useEffect(() => {
    const asyncData = async () => {
      const data = await AsyncStorage.getItem('recentSearches');
      if (data) {
        const jsonValue = JSON.parse(data);
        setRecentSearch(jsonValue);
      }
    };
    asyncData();
  }, []);

  // =======================================

  const value = useMemo(
    () => ({
      data: recentSearch,
      updateData: updateRecentSearches,
      addData: addRecentSearches,
    }),
    [recentSearch],
  );

  // =======================================

  return (
    <RecentSearchContext.Provider value={value}>
      {children}
    </RecentSearchContext.Provider>
  );
};

export default RecentSearchContextProvide;
