import React from 'react';
import { RefreshControl } from 'react-native';
import { Text, View } from '../../../../components/new';
import useEarningsScreen from './useEarningsScreen';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import EarningsList from './EarningsList';

interface EarningsScreenInnerProps {
  queryOptions: NewErrorBoundaryParentState;
  refresh: () => void;
}

const EarningsScreenInner = ({
  queryOptions,
  refresh,
}: EarningsScreenInnerProps) => {
  const {
    earningReports,
    pullToRefreshConf,
    navigateToEarningsOverviewScreen,
  } = useEarningsScreen(queryOptions, refresh);

  return (
    <View flex={1} pt="sm+md">
      {earningReports.length && (
        <Text px="2xl" mb="2xl" size="2xl" weight="regular" color="primary.300">
          Earnings
        </Text>
      )}
      <EarningsList
        RefreshControl={
          <RefreshControl
            refreshing={pullToRefreshConf.refreshing}
            onRefresh={pullToRefreshConf.onRefresh}
          />
        }
        earningReports={earningReports}
        onItemPress={navigateToEarningsOverviewScreen}
      />
    </View>
  );
};

export default EarningsScreenInner;
