import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Dimensions, Platform } from 'react-native';
import { ScrollView } from 'react-native-actions-sheet';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as StoreReview from 'expo-store-review';
import { Text, View } from '../new';
import useFeedbackModalStore, {
  FeedbackScreenSteps,
} from '../../stores/FeedbackModalStore';
import SelectRating from '../RatingModalComponents/SelectRating';
import RatingDetails from '../RatingModalComponents/RatingDetails';
import RatingSuccess from '../RatingModalComponents/RatingSuccess';
import AutoBottomSheet from '../new/custom/AutoBottomSheet';

const FeedbackBottomSheetModal = () => {
  const componentState = useFeedbackModalStore((state) => state);

  const bottomSheetModalRef = useRef(null);

  const appRating = async () => {
    if (await StoreReview.hasAction()) {
      StoreReview.requestReview();
    }
  };

  const handleOnDismiss = useCallback(() => {
    if (componentState.rating >= 3 && Platform.OS !== 'web') {
      appRating();
    }
    componentState.setToDefault();
  }, []);

  useEffect(() => {
    if (componentState.isOpen) {
      bottomSheetModalRef?.current?.show();
    } else {
      bottomSheetModalRef?.current?.hide();
    }
  }, [componentState.isOpen]);

  const renderContent = () => (
    <>
      {componentState.currentScreen === FeedbackScreenSteps.SELECT_RATING && (
        <SelectRating />
      )}
      {componentState.currentScreen === FeedbackScreenSteps.RATING_DETAILS && (
        <RatingDetails />
      )}
      {componentState.currentScreen === FeedbackScreenSteps.RATING_SUCCESS && (
        <RatingSuccess />
      )}
    </>
  );

  return (
    <AutoBottomSheet
      bottomSheetRef={bottomSheetModalRef}
      handleClose={handleOnDismiss}
      insets={false}
    >
      <View>
        <ScrollView
          style={{
            maxHeight: Dimensions.get('screen').height * 0.85,
          }}
          showsVerticalScrollIndicator={false}
          scrollEnabled={
            componentState.currentScreen === FeedbackScreenSteps.RATING_DETAILS
          }
        >
          {renderContent()}
        </ScrollView>
      </View>
    </AutoBottomSheet>
  );
};

export default FeedbackBottomSheetModal;
