import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const cashierCarSearchScreenQuery = graphql`
  query cashierCarSearchScreenQuery($taskFilter: findTaskFilterInput!) {
    findTasks(filter: $taskFilter) {
      ...cashierCarSearchScreenTasksFragment
    }
  }
`;
