import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const taskScreenQuery = graphql`
  query taskScreenQuery(
    $taskFilter: findTaskFilterInput!
    $sort: SortFindManytaskInput
    $logFilter: findValetWorkLogFilterInput!
  ) {
    ...taskScreenQueryFindTaskFragment
      @arguments(filter: $taskFilter, sort: $sort)
    findValetWorkLog(filter: $logFilter) {
      _id
      date
      workLogHistory {
        time
        type
      }
      workSummary {
        tasksDone
      }
    }
  }
`;

export const taskScreenFindTaskFragment = graphql`
  fragment taskScreenQueryFindTaskFragment on Query
  @refetchable(queryName: "taskScreenQueryFindTaskQuery")
  @argumentDefinitions(
    filter: { type: "findTaskFilterInput!" }
    sort: { type: "SortFindManytaskInput" }
  ) {
    findTasks(filter: $filter, sort: $sort) {
      _id
      vehicle {
        _id
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
      }
      taskType
      taskStartLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      campus {
        _id
        name
      }
      history {
        _id
        action
        actor
        event_at
      }
      assignedTo
      valet {
        _id
        name {
          first
          last
        }
        identityCode
        contact {
          phone {
            prefix
            number
          }
        }
      }
      user {
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      ETA {
        _id
        startAt
        delayAt
        originalPredictionTime
        pendingRecall
        available
        busy
      }
      vehicleId
      ownerId
      parkingId
      parkingTag
      createdBy {
        status
      }
    }
  }
`;
