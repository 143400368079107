import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, Layout, Text, View } from '../../components/new';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import VideoMomentsStore from '../../stores/VideoMomentsStore';

const PhotoMomentsPartnerHome = () => {
  const navigation = useNavigation<any>();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const scannedS3Imagekey = VideoMomentsStore(
    (state) => state.scannedS3Imagekey,
  );

  const handleOnViewMomentsPress = () => {
    if (!scannedS3Imagekey) {
      dispatchSnackbar({
        msg: 'Register face to view moments!',
        status: SnackbarStatus.info,
        version: SnackbarVersion.v2,
      });
      return;
    }
    navigation.navigate('PhotoMoments');
  };
  const handleOnScanFacePress = () => {
    navigation.navigate('scanFace');
  };

  return (
    <Layout level={1} edges={['top']}>
      <View px="2xl" mt="4xl">
        <Text textAlign="center" size="2xl">
          Welcome to PhotoMoments
        </Text>
        <Button mt="xl" onPress={handleOnScanFacePress}>
          Register Face
        </Button>
        <Button mt="xl" onPress={handleOnViewMomentsPress}>
          View 32nd Moments
        </Button>
      </View>
    </Layout>
  );
};

export default PhotoMomentsPartnerHome;
