/**
 * @generated SignedSource<<e5057e14b3b7ace9e3f1771df95fa740>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumRestaurantDiscountGetTCDays = "Weekday" | "Weekend" | "%future added value";
export type EnumRestaurantDiscountGetTCMealTime = "dinner" | "lunch" | "%future added value";
export type restaurantpartnerDiscountfilterInput = {
  AND?: ReadonlyArray<FilterFindManyRestaurantDiscountGetTCInput> | null;
  OR?: ReadonlyArray<FilterFindManyRestaurantDiscountGetTCInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyRestaurantDiscountGetTCOperatorsInput | null;
  days?: EnumRestaurantDiscountGetTCDays | null;
  discounts?: FilterFindManyrestaurantDiscountDiscountsInput | null;
  isActive?: boolean | null;
  mealTime?: EnumRestaurantDiscountGetTCMealTime | null;
  restaurantId?: any | null;
};
export type FilterFindManyrestaurantDiscountDiscountsInput = {
  _id?: any | null;
  allUser?: number | null;
  corporateUser?: number | null;
  newUser?: number | null;
};
export type FilterFindManyRestaurantDiscountGetTCOperatorsInput = {
  _id?: FilterFindManyRestaurantDiscountGetTC_idOperatorsInput | null;
};
export type FilterFindManyRestaurantDiscountGetTC_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FilterFindManyRestaurantDiscountGetTCInput = {
  AND?: ReadonlyArray<FilterFindManyRestaurantDiscountGetTCInput> | null;
  OR?: ReadonlyArray<FilterFindManyRestaurantDiscountGetTCInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyRestaurantDiscountGetTCOperatorsInput | null;
  created_at?: any | null;
  days?: EnumRestaurantDiscountGetTCDays | null;
  discounts?: FilterFindManyrestaurantDiscountDiscountsInput | null;
  isActive?: boolean | null;
  mealTime?: EnumRestaurantDiscountGetTCMealTime | null;
  restaurantId?: any | null;
  updated_at?: any | null;
};
export type discountsAndOfferScreenApiDiscountScreenQuery$variables = {
  filter?: restaurantpartnerDiscountfilterInput | null;
};
export type discountsAndOfferScreenApiDiscountScreenQuery$data = {
  readonly getRestaurantpartnerDiscount: ReadonlyArray<{
    readonly _id: any;
    readonly days: EnumRestaurantDiscountGetTCDays;
    readonly discounts: {
      readonly allUser: number;
      readonly corporateUser: number;
      readonly newUser: number;
    };
    readonly isActive: boolean | null;
    readonly mealTime: EnumRestaurantDiscountGetTCMealTime;
    readonly restaurantId: any;
    readonly user: {
      readonly isEmployee: boolean | null;
      readonly isNewUser: boolean | null;
    } | null;
  }>;
};
export type discountsAndOfferScreenApiDiscountScreenQuery = {
  response: discountsAndOfferScreenApiDiscountScreenQuery$data;
  variables: discountsAndOfferScreenApiDiscountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "RestaurantDiscountGetTC",
    "kind": "LinkedField",
    "name": "getRestaurantpartnerDiscount",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "restaurantId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurantDiscountDiscounts",
        "kind": "LinkedField",
        "name": "discounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "corporateUser",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "days",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mealTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GetRestaurantDiscountUserTc",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEmployee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isNewUser",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "discountsAndOfferScreenApiDiscountScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "discountsAndOfferScreenApiDiscountScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4eedc13272488ec7db3df308520af6a2",
    "id": null,
    "metadata": {},
    "name": "discountsAndOfferScreenApiDiscountScreenQuery",
    "operationKind": "query",
    "text": "query discountsAndOfferScreenApiDiscountScreenQuery(\n  $filter: restaurantpartnerDiscountfilterInput\n) {\n  getRestaurantpartnerDiscount(filter: $filter) {\n    _id\n    restaurantId\n    discounts {\n      allUser\n      newUser\n      corporateUser\n    }\n    days\n    mealTime\n    isActive\n    user {\n      isEmployee\n      isNewUser\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6023815e2c4e92e313bdbf9632941e73";

export default node;
