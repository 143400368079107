import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View } from '../../../../components/new';
import { navigateBack } from '../../../../utilities/helper';
import useUserStore from '../../../../stores/userStore';
import { useTransactionSearchScreen } from './useTransactionSearchScreen';
import { Loading } from '../../../../components';
import NoResultsView from '../../components/NoResultsView';
import TransactionListItem from '../../../../components/new/custom/transaction/TransactionListItem';
import TransactionDetailsBottomSheet from '../../components/TransactionDetailsBottomSheet';
import SearchInput from '../../../../components/new/composites/SearchInput/SearchInput';

const TransactionSearchScreenInner = () => {
  const navigation = useNavigation();
  const userRole = useUserStore((state) => state.role);
  const {
    bsRef,
    orderId,
    transaction,
    showTransactions,
    showNoContentView,
    isLoading,
    handleOrderIdTyping,
    clearSearch,
    openTransactionDetailsPanel,
  } = useTransactionSearchScreen();

  const { top } = useSafeAreaInsets();

  return (
    <View flex={1}>
      <View pt={top} shadow="md" mb="2xl">
        <View p="2xl" mt="sm+md">
          <SearchInput
            rightIconName={
              orderId.length ? 'close-outline-300' : 'search-outline-400'
            }
            placeholder="Search order id"
            onRightIconClick={clearSearch}
            value={orderId}
            onChangeText={handleOrderIdTyping}
            onLeftIconClick={() => navigateBack(navigation, userRole)}
            leftIconColor="primary.100"
            autocapitalize="characters"
            autoFocus
            maxLength={6}
          />
        </View>
      </View>
      <View pt="2xl" flex={1}>
        {isLoading && (
          <View mt="114" height="100">
            <Loading />
          </View>
        )}
        {showTransactions && (
          <View pb="2xl" px="2xl">
            <TransactionListItem
              orderId={transaction.orderId}
              paymentStatus={transaction.paymentStatus}
              amount={transaction.formated.billAmount}
              time={transaction.formated.time}
              onPress={openTransactionDetailsPanel}
            />
          </View>
        )}
        {showNoContentView && (
          <View mt="114">
            <NoResultsView text={`No Results for "${orderId}"`} />
          </View>
        )}
      </View>
      <TransactionDetailsBottomSheet
        ref={bsRef}
        transaction={transaction}
        insets={false}
      />
    </View>
  );
};

export default TransactionSearchScreenInner;
