import React, { memo } from 'react';
import Header from './Header';

const areEqual = (prevProps, nextProps) => {
  if (
    prevProps.chipData !== nextProps.chipData ||
    prevProps.hideChips !== nextProps.hideChips
  )
    return false;
  return true;
};

const RenderHeader = ({
  chipData,
  setChipData,
  navigation,
  actionBarStyle,
  setSelectedFilter,
  handlePresentModalPress,
  setRadioBtn,
  setCheckboxes,
  setRestaurantFilter,
  setData,
  restaurantFilter,
  refresh,
  filter,
  searchText,
  setOpenSearchScreen,
  hideChips,
  placeholderText,
}) => {
  return (
    <Header
      chipData={chipData}
      setChipData={setChipData}
      navigation={navigation}
      animatedStyle={actionBarStyle}
      placeholderText={placeholderText}
      searchText={searchText}
      setOpenSearchScreen={setOpenSearchScreen}
      hideChips={hideChips}
      onPress={(item, index) => {
        setSelectedFilter(item);
        if (item?.filter?.length > 0) {
          handlePresentModalPress();
          if (item?.maxSelected === 1 && item?.minSelected === 1) {
            setRadioBtn(item?.filter);
          } else if (!('maxSelected' in item) && !('minSelected' in item)) {
            setCheckboxes(item?.filter);
          }
        }
        if (item?.id === 0 && item?.state !== 'selected') {
          setData();
          setRestaurantFilter(filter);
          refresh();
        }
        if ((item?.id === 4 || item?.id === 9) && !item?.filter?.length) {
          if (
            Object.prototype.hasOwnProperty.call(restaurantFilter, item?.key)
          ) {
            const filterValue = {
              ...restaurantFilter,
            };
            delete filterValue[item?.key];
            setRestaurantFilter(filterValue);
          } else {
            const filterValue = {
              ...restaurantFilter,
              [item?.key]: item?.value,
            };
            setRestaurantFilter(filterValue);
          }
        }
        if (item?.id === 1 || item?.id === 2 || item?.id === 3) {
          if (
            Object.prototype.hasOwnProperty.call(restaurantFilter, item?.key)
          ) {
            const filterValue = {
              ...restaurantFilter,
            };
            delete filterValue[item?.key];
            setRestaurantFilter(filterValue);
          } else {
            const filterValue = {
              ...restaurantFilter,
              ...item?.value,
            };
            setRestaurantFilter(filterValue);
          }
        }
        if (
          item?.id !== 0 &&
          item?.id !== 4 &&
          item?.id !== 9 &&
          item?.id !== 2 &&
          item?.id !== 3 &&
          item?.id !== 1 &&
          !item?.filter?.length
        ) {
          if (
            Object.prototype.hasOwnProperty.call(restaurantFilter, item?.key)
          ) {
            const filterValue = {
              ...restaurantFilter,
              [item?.key]: !restaurantFilter[item?.key],
            };
            setRestaurantFilter(filterValue);
          } else {
            const filterValue = {
              ...restaurantFilter,
              [item?.key]: !item?.value,
            };
            setRestaurantFilter(filterValue);
          }
        }
      }}
    />
  );
};

export default memo(RenderHeader, areEqual);
