/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, useRef, useEffect } from 'react';
// import { Layout, View, Text, Icon, TopNavigation, IconButton, MobileInput, Button } from '';

import { StyleSheet, TextInput } from 'react-native';
import Clipboard from '@react-native-community/clipboard';
import View from '../../basic/View/View';
// import { ThemeContext } from '../themes';
// import { TextInput } from 'react-native-gesture-handler';

import OtpInputSingle from './OTPSingleInput';

// type OwnOtpInputProps = {
//     label: string;
//     setOtpArrayVal?: (...args: any[]) => any;
//     digits: number;
//     autoFocus: boolean;
//     [x: string]: any
// };

// // @ts-expect-error TS(2456): Type alias 'PinInputProps' circularly references i... Remove this comment to see the full error message
// type OtpInputProps = OwnOtpInputProps & typeof OtpInput.defaultProps;

const OtpInputWithLabel = (props) => {
  const { label, digits, setOtpArrayVal, setAutoSubmit, autoFocus, onFocus } =
    props;

  const [otpArray, setOtpArray] = useState([...Array(digits)].map(() => ''));
  //   eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = [...Array(digits)].map(() => useRef(null));
  const [otpMaxLen, setOtpMaxLen] = useState(1);

  const refCallback = (textInputRef) => (node) => {
    textInputRef.current = node;
  };

  useEffect(() => {
    if (inputRef[0].current) {
      inputRef[0].current.focus();
    }
  }, []);

  const handleInputSave = (index, value) => {
    const otpArrayCopy = otpArray.concat();
    otpArrayCopy[index] = value;
    setOtpArray(otpArrayCopy);
    setOtpArrayVal(otpArrayCopy);
  };

  // const handleOnPaste = (content) => {
  //   console.log('paste detected! content: '.concat(content));
  // };

  // const handleOnChangeText = async (content) => {
  //   if (content === '') return;
  //   const copiedContent = await Clipboard.getString();
  //   // console.log('content', copiedContent)
  //   if (copiedContent === '') return;
  //   const isPasted = content.includes(copiedContent);
  //   setOtpMaxLen(4);
  //   if (isPasted) handleOnPaste(content);
  // };

  const onOtpChange = (index) => (value) => {
    // console.log('onOtpChange');
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(Number(value))) {
      return;
    }
    // console.log('index', index)
    // console.log('value', value)
    if (index === 0) {
      // const copiedContent =  Clipboard.getString();
      // // if (copiedContent === '') return;
      // const isPasted = value.includes(copiedContent);
      // setOtpMaxLen(4)
      // if (isPasted) handleOnPaste(value);
      // handleOnChangeText(value);
    }
    if (value.length === 4) {
      // console.log('here...');
      const otp = value.split('');
      setOtpArray(otp);
      setOtpArrayVal(otp);
      //   if(inputRef[value.length - 1].current){
      //     inputRef[value.length - 1].current.focus();
      //   }
      inputRef[value.length - 1].current.focus();
      setAutoSubmit(true);
      //  handleOnChangeText(otp)
    } else {
      handleInputSave(index, value);

      if (value !== '') {
        if (index < digits - 1) {
          if (inputRef[index + 1].current) {
            inputRef[index + 1].current.focus();
          }
        } else if (index === digits - 1) {
          //   inputRef[index].current.blur();
        }
      }
    }
  };

  const onOtpKeyPress =
    (index) =>
    ({ nativeEvent: { key: value } }) => {
      if (value === 'Backspace' && otpArray[index] === '') {
        if (index !== 0) {
          handleInputSave(index - 1, '');
          if (inputRef[index - 1].current) {
            inputRef[index - 1].current.focus();
          }
        }
      }
    };

  return (
    <View flexDirection="row" alignItems="center">
      {inputRef.map((textInputRef, index) => (
        <OtpInputSingle
          value={otpArray[index]}
          onKeyPress={onOtpKeyPress(index)}
          onChangeText={onOtpChange(index)}
          //   onChangeText={(index)=>(value)=>handleOnChangeText(value)}
          maxLength={index === 0 ? 4 : 1}
          // maxLength={otpMaxLen}
          //   label={index === 0 ? label : ' '}
          //   onChange={onOtpChange(index)}
          autoFocus={autoFocus === true && index === 0 ? true : undefined}
          onFocus={onFocus}
          refCallback={refCallback(textInputRef)}
          key={index}
        />
      ))}
    </View>
  );
};

export default OtpInputWithLabel;

OtpInputWithLabel.defaultProps = {
  setOtpArrayVal: function name() {},
};
