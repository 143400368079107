import React from 'react';
import { useWindowDimensions } from 'react-native';
import { usePayoutOverviewScreen } from './usePayoutOverviewScreen';
import { Text, View } from '../../../../components/new';
import TransactionSummaryBanner from '../../../../components/new/custom/transaction/TransactionSummaryBanner';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import OverviewTile from '../../../../components/new/custom/transaction/OverviewTile';
import EarningReportList from './EarningReportList';
import PayoutDetailsBottomSheet from './PayoutDetailsBottomSheet';
import { dateUtils } from '../../../../utilities/helper';
import { FontWeights } from '../../../../themes/new/helper';

interface PayoutOverviewScreenInnerProps {
  queryOptions: NewErrorBoundaryParentState;
}

const PayoutOverviewScreenInner = ({
  queryOptions,
}: PayoutOverviewScreenInnerProps) => {
  const {
    bsRef,
    bsMode,
    earningReportOfMonth,
    earningReportsOfMonthDays,
    handleBack,
    showEarningsPanelBS,
    navigateToEarningsOverviewScreen,
    handleBSDismiss,
  } = usePayoutOverviewScreen(queryOptions);

  const { width } = useWindowDimensions();

  const BannerBottomContent = (
    <View mt="2xl" width={width} flexDirection="row" justifyContent="center">
      <View width={160} mr="xl">
        <OverviewTile
          txtPrimary={earningReportOfMonth?.formated?.collectedAmount}
          txtSecondsry="My Earnings"
        />
      </View>
      <View width={160} ml="xl">
        <OverviewTile
          txtPrimary={earningReportOfMonth?.formated?.compensatedLoyaltyPoints}
          txtSecondsry="Points Compensated"
        />
      </View>
    </View>
  );

  return (
    <View flex={1}>
      <TransactionSummaryBanner
        onBack={handleBack}
        title={`${earningReportOfMonth?.formated?.date} Earnings`}
        amount={earningReportOfMonth?.formated?.payoutAmount}
        status={
          dateUtils.isCurrentMonth(earningReportOfMonth?.date)
            ? 'PENDING'
            : 'SUCCESSFUL'
        }
        onInfo={showEarningsPanelBS}
        BottomContent={BannerBottomContent}
      />
      <View flex={1}>
        {earningReportsOfMonthDays.length && (
          <Text
            px="2xl"
            my="2xl"
            size="md"
            weight={FontWeights.MEDIUM}
            color="primary.400"
          >
            My Earnings ({earningReportOfMonth?.formated?.date})
          </Text>
        )}
        <EarningReportList
          earningsReports={earningReportsOfMonthDays}
          onItemPress={navigateToEarningsOverviewScreen}
        />
      </View>
      <PayoutDetailsBottomSheet
        ref={bsRef}
        bsMode={bsMode}
        onDismiss={handleBSDismiss}
        earningsReport={earningReportOfMonth}
      />
    </View>
  );
};

export default PayoutOverviewScreenInner;
