/**
 * @generated SignedSource<<230ad4f5c843cc70a4a1b5503a3460c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type updateTaskRecordInput = {
  assignedTo?: any | null;
  cancelledReason?: string | null;
  history?: ReadonlyArray<UpdateByIdtaskHistoryInput | null> | null;
  ownerId?: any | null;
  parkingId?: any | null;
  taskEndLocation?: UpdateByIdtaskTaskEndLocationInput | null;
  vehicleId?: any | null;
};
export type UpdateByIdtaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type UpdateByIdtaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
  parkingSpaces?: UpdateByIdtaskTaskEndLocationParkingSpacesInput | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesInput = {
  floor?: UpdateByIdtaskTaskEndLocationParkingSpacesFloorInput | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesFloorInput = {
  _id?: any | null;
  bay?: UpdateByIdtaskTaskEndLocationParkingSpacesFloorBayInput | null;
  floorId?: any | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesFloorBayInput = {
  _id?: any | null;
  bayId?: any | null;
};
export type taskApiUpdateMutation$variables = {
  id: any;
  record: updateTaskRecordInput;
};
export type taskApiUpdateMutation$data = {
  readonly updateTask: {
    readonly record: {
      readonly ETA: {
        readonly _id: any | null;
        readonly available: number | null;
        readonly busy: number | null;
        readonly delayAt: any | null;
        readonly originalPredictionTime: number | null;
        readonly pendingRecall: number | null;
        readonly startAt: any | null;
      } | null;
      readonly _id: any;
      readonly assignedTo: any | null;
      readonly campus: {
        readonly _id: any;
        readonly name: string;
      } | null;
      readonly createdBy: {
        readonly status: string | null;
      } | null;
      readonly history: ReadonlyArray<{
        readonly _id: any | null;
        readonly action: EnumtaskHistoryAction;
        readonly actor: any | null;
        readonly event_at: any | null;
      } | null>;
      readonly ownerId: any | null;
      readonly parkingId: any | null;
      readonly parkingTag: string | null;
      readonly taskEndLocation: {
        readonly locationId: any;
        readonly name: string | null;
        readonly parkingSpaces: {
          readonly floor: {
            readonly bay: {
              readonly bayId: any;
              readonly bayName: string | null;
            } | null;
            readonly floorId: any;
            readonly floorName: string | null;
          } | null;
        } | null;
      } | null;
      readonly taskStartLocation: {
        readonly locationId: any;
        readonly name: string | null;
        readonly parkingSpaces: {
          readonly floor: {
            readonly bay: {
              readonly bayId: any;
              readonly bayName: string | null;
            } | null;
            readonly floorId: any;
            readonly floorName: string | null;
          } | null;
        } | null;
      } | null;
      readonly taskType: EnumtaskTaskType;
      readonly user: {
        readonly contact: {
          readonly phone: {
            readonly number: string;
            readonly prefix: string;
          };
        };
        readonly name: {
          readonly first: string;
          readonly last: string | null;
        };
      } | null;
      readonly valet: {
        readonly _id: any;
        readonly contact: {
          readonly phone: {
            readonly number: string;
            readonly prefix: string;
          };
        };
        readonly identityCode: string | null;
        readonly name: {
          readonly first: string;
          readonly last: string | null;
        };
      } | null;
      readonly vehicle: {
        readonly brand: {
          readonly brandLogo: string | null;
          readonly name: string | null;
        } | null;
        readonly color: string | null;
        readonly registration: {
          readonly plate: string;
        } | null;
      } | null;
      readonly vehicleId: any | null;
    } | null;
  } | null;
};
export type taskApiUpdateMutation = {
  response: taskApiUpdateMutation$data;
  variables: taskApiUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserContactPhone",
      "kind": "LinkedField",
      "name": "phone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdtaskPayload",
    "kind": "LinkedField",
    "name": "updateTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleBrand",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandLogo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskStartLocation",
            "kind": "LinkedField",
            "name": "taskStartLocation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskStartLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                        "kind": "LinkedField",
                        "name": "bay",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskEndLocation",
            "kind": "LinkedField",
            "name": "taskEndLocation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskEndLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                        "kind": "LinkedField",
                        "name": "bay",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "campusLocation",
            "kind": "LinkedField",
            "name": "campus",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskHistory",
            "kind": "LinkedField",
            "name": "history",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "event_at",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignedTo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "valet",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identityCode",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskETA",
            "kind": "LinkedField",
            "name": "ETA",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "delayAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "originalPredictionTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pendingRecall",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "available",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "busy",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vehicleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingTag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "createdBy",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taskApiUpdateMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taskApiUpdateMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "2d99dd6a289071f7cddb680fc16f80e3",
    "id": null,
    "metadata": {},
    "name": "taskApiUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation taskApiUpdateMutation(\n  $id: MongoID!\n  $record: updateTaskRecordInput!\n) {\n  updateTask(record: $record, _id: $id) {\n    record {\n      _id\n      vehicle {\n        brand {\n          name\n          brandLogo\n        }\n        registration {\n          plate\n        }\n        color\n      }\n      taskType\n      taskStartLocation {\n        locationId\n        name\n        parkingSpaces {\n          floor {\n            floorName\n            floorId\n            bay {\n              bayId\n              bayName\n            }\n          }\n        }\n      }\n      taskEndLocation {\n        locationId\n        name\n        parkingSpaces {\n          floor {\n            floorName\n            floorId\n            bay {\n              bayId\n              bayName\n            }\n          }\n        }\n      }\n      campus {\n        _id\n        name\n      }\n      history {\n        _id\n        action\n        actor\n        event_at\n      }\n      assignedTo\n      valet {\n        _id\n        name {\n          first\n          last\n        }\n        identityCode\n        contact {\n          phone {\n            prefix\n            number\n          }\n        }\n      }\n      user {\n        name {\n          first\n          last\n        }\n        contact {\n          phone {\n            prefix\n            number\n          }\n        }\n      }\n      ETA {\n        _id\n        startAt\n        delayAt\n        originalPredictionTime\n        pendingRecall\n        available\n        busy\n      }\n      vehicleId\n      ownerId\n      parkingId\n      parkingTag\n      createdBy {\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2266f453535cb8baca697a83591bf10";

export default node;
