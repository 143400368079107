import React from 'react';
import { Icon, Text, View } from '..';
import StarRating from '../composites/StarRating/StarRating';
import { StarRatingVariants } from '../composites/StarRating/helpers/helpers';
import { getTagLine, imageTransformation } from '../../../utilities/helper';
import CachedImage from './CachedImage';

interface ShortDetailsCardProps {
  shadow?: 'sm' | 'md' | 'lg';
  title: string;
  rating: number;
  image: string;
  tags: { name: string }[];
  showOverlay?: boolean;
  overlayText?: string;
}

const ShortDetailsCard = ({
  shadow = 'sm',
  title,
  rating,
  image,
  tags,
  showOverlay = false,
  overlayText = 'Overlay Text',
}: ShortDetailsCardProps) => {
  const cardWidth = 252;
  const previewImage = imageTransformation(image, cardWidth, `252-270`);

  return (
    <View>
      <View
        position="relative"
        width={cardWidth}
        bg="background.primary.elevation"
        shadow={shadow}
        overflow="hidden"
        borderRadius="lg"
        borderColor="grey.50"
        borderWidth="xs"
      >
        <View width={cardWidth} height={270}>
          <CachedImage
            style={{
              width: cardWidth,
              height: 270,
              resizeMode: 'cover',
            }}
            source={{ uri: previewImage }}
          />
        </View>
        <View minHeight={98} p="2xl">
          <View mb="2xl" flexDirection="row" justifyContent="space-between">
            <View width={170}>
              <Text numberOfLines={1} size="lg" color="primary.400">
                {title}
              </Text>
            </View>
            <StarRating
              ratingScore={rating ?? 0}
              size={StarRatingVariants.sm}
            />
          </View>
          <Text
            numberOfLines={1}
            size="sm"
            weight="regular"
            color="primary.200"
          >
            {getTagLine(tags)}
          </Text>
        </View>
        {/* overlay area */}
        {showOverlay && (
          <View
            position="absolute"
            width="100%"
            height="100%"
            bg="rgba(18, 18, 18, 0.9)"
            justifyContent="center"
            alignItems="center"
          >
            <View flexDirection="row">
              <Text size="sm" category="button" color="singletone.white">
                {overlayText}
              </Text>
              <Icon
                name="keyboard-arrow-right-300"
                color="primary.10"
                size="xl"
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default ShortDetailsCard;
