import React from 'react';
import { useWindowDimensions, ImageBackground, FlatList } from 'react-native';
import * as Progress from 'react-native-progress';
import { Button, Pressable, Text, View } from '../../../../components/new';
import { colors, radii, space } from '../../../../themes/new/theme';
import {
  INVESTMENTKEYS,
  imageTransformation,
} from '../../../../utilities/helper';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import useAuthStore from '../../../../stores/authStore';
import { getStyledPremium } from '../../../../utilities/Utility';
import InvestmentBlurView from './InvestmentBlurView';
import GradientBackground from '../../../../components/new/custom/GradientBackground';
import InvestListVideoComp from './InvestListVideoComp';

const InvestProperties = ({
  propertiesData,
  fetchMoreInvestmentProperties,
  PROPERTY_PAGE_SIZE,
  hasMoreInvestmentProperties,
  investmentVideoHeader,
  investmentVideoPlayed = false,
  openPropertyDetails = (propertyId: string) => {},
}) => {
  const { width } = useWindowDimensions();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const CardInfo = ({ cardData, isFractional = false }) => {
    return (
      <View>
        <View
          flexDirection="row"
          justifyContent="space-between"
          pt="lg"
          px="2xl"
        >
          <View alignItems="flex-start">
            <Text color="primary.400" size="xs">
              {isFractional ? 'Min. Investment' : 'Managed by'}
            </Text>
            <Text color="primary.500" size="md" pt="xs">
              {isFractional
                ? getStyledPremium(
                    cardData?.investment?.data?.investmentAmount?.min,
                  )
                : cardData?.project?.managedBy?.name ?? 'N/A'}
            </Text>
          </View>
          <View alignItems="flex-start">
            <Text color="primary.400" size="xs">
              {isFractional ? 'Rental Yield' : 'Designed by'}
            </Text>
            <Text color="primary.500" size="md" pt="xs">
              {isFractional
                ? `${cardData?.investment?.data?.investment?.roi?.rentalYieldPreOps}%`
                : cardData?.project?.designer ?? 'N/A'}
            </Text>
          </View>
          <View alignItems="flex-start">
            <Text color="primary.400" size="xs">
              {isFractional ? 'Target IRR' : 'Est. ROI'}
            </Text>
            <Text color="primary.500" size="md" pt="xs">
              {cardData?.investment?.data?.investment?.roi?.xirr?.[0]
                ?.percentage
                ? `${cardData?.investment?.data?.investment?.roi?.xirr?.[0]?.percentage}%`
                : 'N/A'}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const ownershipForBlur = (investmentType) => {
    let res = false;
    if (investmentType === INVESTMENTKEYS.FRACTIONAL_OWNERSHIP && !isLoggedIn) {
      res = true;
    }
    return res;
  };

  const returnBarWidth = () => {
    const res = width - space['9xl+4xl'];
    return res;
  };

  const RenderFractionalShares = ({ cardData }) => {
    const { totalUnits = 0 } = cardData?.investment?.data?.investment?.units;
    const applied = cardData?.investment?.data?.application?.applied ?? 0;
    return (
      <View px="2xl" pt="3xl">
        {applied > 0 ? (
          <View
            flexDirection="row"
            mb="lg"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text size="md" color="success.500" weight="medium">
              {applied}{' '}
              <Text size="md" color="primary.300" weight="medium">
                {applied > 1 ? 'applications' : 'application'} received for{' '}
                {totalUnits} shares
              </Text>
            </Text>
          </View>
        ) : null}
        <Progress.Bar
          progress={applied / totalUnits}
          width={returnBarWidth()}
          unfilledColor={colors.primary[300]}
          color={colors.success[500]}
          borderWidth={0}
          useNativeDriver
          animationType="timing"
          animationConfig={{ duration: 5000, bounciness: 0.5 }}
        />
      </View>
    );
  };

  const PropertyCard = ({ item, index }) => {
    const mediaType = item?.intro?.media?.mediaType;
    const imgSource =
      mediaType === 'VIDEO'
        ? item?.intro?.media?.thumbnailURL
        : item?.intro?.media?.mediaURL;
    const bg = {
      uri: imageTransformation(imgSource, width - 32, `${width - 32}-400`),
    };

    const showBlur = ownershipForBlur(item?.investmentType);

    const gradientColors = [
      { offset: '0', color: colors.gradient[0], opacity: 0 },
      { offset: '1', color: colors.singletone.black, opacity: 1 },
    ];

    return (
      <DarkThemeWrapper>
        <View px="2xl">
          <Pressable
            onPress={() => {
              openPropertyDetails(item?._id);
            }}
          >
            <ImageBackground
              source={bg}
              style={{
                overflow: 'hidden',
                width: width - 32,
                height: 476,
                borderRadius: radii.xl,
                marginTop:
                  index === 0 && !investmentVideoPlayed
                    ? space['9xl']
                    : space['2xl'],
              }}
            >
              <GradientBackground
                backgroundOpacity={1}
                gradientColors={gradientColors}
                id={item?._id}
                style={{
                  width: width - 32,
                  height: 476,
                  position: 'absolute',
                }}
              />
              <View flex={1} justifyContent="flex-end">
                <View pt="2xl">
                  {item?.location?.name ? (
                    <Text
                      color="primary.500"
                      size="sm"
                      weight="medium"
                      px="2xl"
                    >
                      {item?.location?.name}
                    </Text>
                  ) : null}
                  {item?.about?.name ? (
                    <Text
                      color="primary.500"
                      size="2xl"
                      weight="medium"
                      px="2xl"
                    >
                      {item?.about?.name}
                    </Text>
                  ) : null}
                </View>
                <View>
                  <InvestmentBlurView
                    blurHeight="100%"
                    blurWidth={width - 32}
                    showBlur={showBlur}
                    BlurViewStyle={{
                      paddingTop: space.xl,
                      justifyContent: 'flex-start',
                    }}
                  />
                  <CardInfo
                    cardData={item}
                    isFractional={
                      item?.investmentType ===
                      INVESTMENTKEYS.FRACTIONAL_OWNERSHIP
                    }
                  />
                  {item?.investmentType ===
                  INVESTMENTKEYS.FRACTIONAL_OWNERSHIP ? (
                    <RenderFractionalShares cardData={item} />
                  ) : null}
                  <View zIndex={99}>
                    <Button
                      size="lg"
                      appearance="filled"
                      status="primary"
                      onPress={() => {
                        openPropertyDetails(item?._id);
                      }}
                      mt={
                        item?.investmentType ===
                        INVESTMENTKEYS.FRACTIONAL_OWNERSHIP
                          ? '2xl'
                          : '7xl'
                      }
                      mx="2xl"
                      mb="2xl"
                    >
                      View Details
                    </Button>
                  </View>
                </View>
              </View>
            </ImageBackground>
          </Pressable>
        </View>
      </DarkThemeWrapper>
    );
  };

  return (
    <View bg="background.primary.base">
      <FlatList
        data={propertiesData}
        scrollEnabled
        contentContainerStyle={{
          paddingBottom: space['9xl'],
        }}
        initialNumToRender={5}
        windowSize={5}
        maxToRenderPerBatch={5}
        ListHeaderComponent={
          !investmentVideoPlayed &&
          investmentVideoHeader?.cover?.mediaURL && (
            <InvestListVideoComp
              investmentVideoHeader={investmentVideoHeader}
            />
          )
        }
        ListFooterComponent={
          investmentVideoPlayed &&
          investmentVideoHeader?.cover?.mediaURL && (
            <View mt="9xl">
              <InvestListVideoComp
                investmentVideoHeader={investmentVideoHeader}
              />
            </View>
          )
        }
        renderItem={({ item, index }) => (
          <PropertyCard item={item} index={index} />
        )}
        onEndReached={() => {
          if (hasMoreInvestmentProperties) {
            fetchMoreInvestmentProperties(PROPERTY_PAGE_SIZE);
          }
        }}
      />
      {/* <InvestPartners /> */}
    </View>
  );
};

export default InvestProperties;
