/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useRef, useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';

import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import validateWrapper from '../../utilities/validationWrapper';
import AddFeedback from '../../relay/feedbackApi';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import {
  Button,
  IconButton,
  Input,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import { navigateBack } from '../../utilities/helper';
import { InputStates } from '../../components/new/primitive/Input/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import CustomSelectSheet, {
  CustomSelectSheetType,
} from '../../components/picker/CustomSelectSheet';
import { feedbackType, feedbacksTopics } from '../../utilities/ratingOptions';
import useUserStore from '../../stores/userStore';
import NotLoggedInPlaceholderV2 from '../../components/new/custom/NotLoggedInPlaceholderV2';
import useAuthStore from '../../stores/authStore';

const services = [
  { label: 'Valet Service', value: feedbacksTopics.VALET_SERVICE },
  { label: 'Valet Driver', value: feedbacksTopics.VALET_DRIVER },
  { label: 'Other', value: feedbacksTopics.OTHER },
  // { label: 'Restaurant',value:'6' },
  // { label: 'Complex Amenities',value:'7' },
];

const filter = (item, query) =>
  item.title.toLowerCase().includes(query.toLowerCase());

const UserFeedbackScreen = ({ navigation }) => {
  const { height, width } = useWindowDimensions();
  const [userID, setUserId] = useState('');
  // let userId = useRef(userID);
  const inputRef = useRef(null);
  const [topicValue, setTopicValue] = useState('');

  const [topicValueError, setTopicValueError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [listData, setListData] = useState(services);
  const [feedbackButtonDisable, setFeedbackButtonDisable] = useState(true);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const scrollViewRef = useRef(null);
  const userRole = useUserStore((state) => state.role);

  const validateForm = (fieldName) => {
    const fieldValue = fieldName === 'topicValue' ? topicValue : description;
    const error = validateWrapper(fieldName, fieldValue);
    if (error) {
      // eslint-disable-next-line no-unused-expressions
      fieldName === 'topicValue'
        ? setTopicValueError(error)
        : setDescriptionError(error);
    } else {
      // eslint-disable-next-line no-unused-expressions
      fieldName === 'topicValue'
        ? setTopicValueError('')
        : setDescriptionError('');
    }
  };

  const validationClear = (fieldName) => {
    if (fieldName === 'topicValue') {
      setTopicValueError('');
    } else {
      setDescriptionError('');
    }
  };

  const focusHandler = () => {
    validationClear('description');
    if (scrollViewRef.current) {
      // setTimeout(() => {
      //   scrollViewRef.current.scrollToEnd();
      // }, 400);
    }
  };

  const selectionHandler = (name) => {
    if (description) {
      setFeedbackButtonDisable(false);
    }
    setTopicValue(name);
  };

  const feedBackHandler = (val) => {
    // if ((val.length < 10 || val.length > 1000) && !!topicValue) {
    //   setFeedbackButtonDisable(false);
    // }
    setDescription(val);
  };

  const onChangeText = (query) => {
    setTopicValue(query);
    setListData(services.filter((item) => filter(item, query)));
  };

  const refCallback = (InputRef) => (node) => {
    InputRef.current = node;
  };

  useEffect(() => {
    if (topicValue !== '') {
      setTopicValueError('');
    }
    if (
      topicValue !== '' &&
      description !== '' &&
      description.length >= 10 &&
      topicValueError === '' &&
      descriptionError === ''
    ) {
      setFeedbackButtonDisable(false);
    } else {
      setFeedbackButtonDisable(true);
    }
  }, [topicValue, description, topicValueError, descriptionError]);

  const handleAddFeedback = (response) => {
    setFeedbackButtonDisable(false);
    if (response.addVehicle !== null) {
      dispatchSnackbar({
        msg: `thank you for your feedback`,
        status: SnackbarStatus.success,
        version: SnackbarVersion.v1,
      });
      navigateBack(navigation, userRole);
    } else {
      // console.log('Please enter correct! Vehicle Detail.');
    }
  };

  const onError = () => {
    setFeedbackButtonDisable(false);
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v1,
    });
  };

  const submitFeedBack = () => {
    // console.log('====== yes =======');
    const feedbackData = {};
    (feedbackData as any).type = feedbackType.GENERAL;
    (feedbackData as any).feedbacks = [];
    // (feedbackData as any).description.summary = description;
    // (feedbackData as any).topic = topicValue;
    (feedbackData as any).feedbacks.push({
      topic: topicValue,
      summary: description,
    });
    AddFeedback(feedbackData, handleAddFeedback, onError);
    firebaseEventLogger('feedback__submitFeedback_Tap', {
      buttonName: 'submitFeedback',
      screenName: 'feedback',
      userType: 'User',
      interactionType: 'Tap',
      description,
      topicValue,
    });
  };

  useEffect(() => {
    const userDetail = async () => {
      const userId = await AsyncStorage.getItem('userId');
      setUserId(userId);
    };
    userDetail();
  }, []);

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="Feedback" />;
  }

  return (
    <Layout level={2}>
      <TopNavigation
        title="Feedback"
        appearance="ghost"
        level="1"
        textColor="primary.500"
        textSize="md"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="sm"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
            }}
          />
        }
      />
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : null}
      >
        <View flex={1}>
          <ScrollView
            contentContainerStyle={{
              flex: 1,
            }}
            ref={scrollViewRef}
            keyboardShouldPersistTaps="handled"
          >
            <View px="2xl" flex={1}>
              <View mt="4xl">
                <View pb="2xl">
                  <Text size="md" mb="lg" color="primary.300">
                    Area of concern
                  </Text>
                  <CustomSelectSheet
                    data={services.map((ele) => {
                      return {
                        name: ele.label,
                        value: ele.value,
                      };
                    })}
                    onInputClick={() => {
                      Keyboard.dismiss();
                    }}
                    disabled={false}
                    placeholder="Area of concern"
                    title="Area of concern"
                    actionButtonText="Select"
                    onSelect={(res) => {
                      selectionHandler(res.value);
                    }}
                    type={CustomSelectSheetType.DROP_DOWN}
                  />
                </View>
                <View mt="4xl">
                  <Input
                    multiline
                    numberOfLines={3}
                    state={
                      descriptionError === ''
                        ? InputStates.default
                        : InputStates.error
                    }
                    inputMode="text"
                    onFocus={focusHandler}
                    inputRef={inputRef}
                    label="Describe your issue"
                    placeholder="Type something..."
                    value={description}
                    helperText={descriptionError}
                    onBlur={() => validateForm('description')}
                    onChangeText={(val) => {
                      feedBackHandler(val);
                    }}
                    style={{
                      height: 87,
                      textAlignVertical: 'top',
                    }}
                  />
                </View>
              </View>
              <View position="absolute" bottom={0} left={0} right={0} px="2xl">
                <View bottom="2xl">
                  <Button
                    size="lg"
                    appearance="filled"
                    status="primary"
                    state={feedbackButtonDisable ? 'disabled' : 'active'}
                    onPress={submitFeedBack}
                  >
                    Submit Feedback
                  </Button>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </KeyboardAvoidingView>

      <SafeAreaView edges={['bottom']} />
      <DisconnectedDropover
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};
export default UserFeedbackScreen;
