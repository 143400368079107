import React from 'react';
import { Pressable } from 'react-native';
import { Icon, Text, View } from '../../../components/new';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { FontWeights } from '../../../themes/new/helper';

interface CancellationInfoBanner {
  onPress?: (...args: any) => void;
  title: string;
  message: string;
}

const CancellationInfoBanner = ({
  title,
  message,
  onPress,
}: CancellationInfoBanner) => (
  <Pressable
    onPress={() => {
      if (typeof onPress === 'function') onPress();
    }}
  >
    <View
      px="2xl"
      py="lg"
      borderRadius="lg"
      borderColor="success.500"
      borderWidth="xs"
      bg="success.10"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <View flexDirection="row" alignItems="center">
        <CustomLottieView
          autoPlay
          loop
          style={{
            height: 40,
            width: 40,
          }}
          containerWidth={40}
          source={require('../../../../assets/lottie/secure-shield.json')}
        />
        <View pl="2xl" width={230}>
          <Text size="sm" color="primary.500" weight={FontWeights.MEDIUM}>
            {title}
          </Text>
          <Text size="xs" color="primary.200">
            {message}
          </Text>
        </View>
      </View>
      <View>
        <Icon name="keyboard-arrow-right-300" size="2xl" color="primary.400" />
      </View>
    </View>
  </Pressable>
);

export default CancellationInfoBanner;
