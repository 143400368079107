/**
 * @generated SignedSource<<83ed243ea85dc222f40274a2d35f5576>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CollectionsOrderPaymentType = "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type getPopupOrderCollectionsFilter = {
  date: string;
  paymentType?: ReadonlyArray<CollectionsOrderPaymentType | null> | null;
};
export type partnerOverviewQuery$variables = {
  filter?: getPopupOrderCollectionsFilter | null;
};
export type partnerOverviewQuery$data = {
  readonly getPopupOrderCollections: ReadonlyArray<{
    readonly cashTotal: number | null;
    readonly count: number | null;
    readonly date: string | null;
    readonly onlineTotal: number | null;
    readonly total: number | null;
  } | null> | null;
};
export type partnerOverviewQuery = {
  response: partnerOverviewQuery$data;
  variables: partnerOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "popupOrderCollectionsOutput",
    "kind": "LinkedField",
    "name": "getPopupOrderCollections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cashTotal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onlineTotal",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "partnerOverviewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "partnerOverviewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7751d877029dc560488c121909f91c58",
    "id": null,
    "metadata": {},
    "name": "partnerOverviewQuery",
    "operationKind": "query",
    "text": "query partnerOverviewQuery(\n  $filter: getPopupOrderCollectionsFilter\n) {\n  getPopupOrderCollections(filter: $filter) {\n    total\n    date\n    count\n    cashTotal\n    onlineTotal\n  }\n}\n"
  }
};
})();

(node as any).hash = "38f7772c11780986df284fc090aaa86e";

export default node;
