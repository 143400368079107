import React from 'react';
import { Text, View } from '../../../../components/new';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';

const ConsentSuccess = () => {
  return (
    <View
      px="2xl"
      alignItems="center"
      flex={1}
      justifyContent="center"
      pb="9xl+9xl"
      bg="background.primary.elevation"
    >
      <View>
        <CustomLottieView
          style={{ width: 150, height: 150 }}
          autoPlay
          loop
          source={require('../../../../../assets/lottie/consent_success.json')}
        />
      </View>
      <Text size="xl" color="primary.400" weight="medium" textAlign="center">
        Exclusive Property Details{'\n'}UNLOCKED!
      </Text>
    </View>
  );
};

export default ConsentSuccess;
