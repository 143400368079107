/**
 * @generated SignedSource<<038d61801caf4e902561b4585de152f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DateGroupBy = "DAY" | "MONTH" | "%future added value";
export type PaymentReportFilter = {
  date: DateRange;
  groupBy: DateGroupBy;
  orderId: string;
};
export type DateRange = {
  end: string;
  start: string;
};
export type payoutOverviewScreenQuery$variables = {
  earningsReportfilter: PaymentReportFilter;
  revenueReportFilter: PaymentReportFilter;
};
export type payoutOverviewScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"transactionsEarningReportFragment" | "transactionsRevenueReportFragment">;
};
export type payoutOverviewScreenQuery = {
  response: payoutOverviewScreenQuery$data;
  variables: payoutOverviewScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "earningsReportfilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "revenueReportFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "earningsReportfilter"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "revenueReportFilter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "period",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionsCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossAmount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netAmount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountGiven",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rewardPointsUsed",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "payoutOverviewScreenQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "transactionsEarningReportFragment"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "transactionsRevenueReportFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "payoutOverviewScreenQuery",
    "selections": [
      {
        "alias": "earingReports",
        "args": (v1/*: any*/),
        "concreteType": "PaymentReport",
        "kind": "LinkedField",
        "name": "getPaymentReports",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payoutAmount",
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardPointsGranted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardPointsCompensated",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "reports",
        "args": (v2/*: any*/),
        "concreteType": "PaymentReport",
        "kind": "LinkedField",
        "name": "getPaymentReports",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b00b459b70d8a5d30bab926ffbcbba44",
    "id": null,
    "metadata": {},
    "name": "payoutOverviewScreenQuery",
    "operationKind": "query",
    "text": "query payoutOverviewScreenQuery(\n  $earningsReportfilter: PaymentReportFilter!\n  $revenueReportFilter: PaymentReportFilter!\n) {\n  ...transactionsEarningReportFragment_2UKAZW\n  ...transactionsRevenueReportFragment_3eySGF\n}\n\nfragment transactionsEarningReportFragment_2UKAZW on Query {\n  earingReports: getPaymentReports(filter: $earningsReportfilter) {\n    date\n    period\n    transactionsCount\n    grossAmount\n    netAmount\n    payoutAmount\n    discountGiven\n    rewardPointsUsed\n    rewardPointsGranted\n    rewardPointsCompensated\n  }\n}\n\nfragment transactionsRevenueReportFragment_3eySGF on Query {\n  reports: getPaymentReports(filter: $revenueReportFilter) {\n    date\n    period\n    transactionsCount\n    grossAmount\n    netAmount\n    discountGiven\n    rewardPointsUsed\n  }\n}\n"
  }
};
})();

(node as any).hash = "8599430d077ba15d65c0eb9b6ead2599";

export default node;
