import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Text, View } from '../../../../components/new';
import VideoPlayer from '../../../../components/new/custom/VideoPlayer';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import LightThemeWrapper from '../../wrapperComp/LightThemeWrapper';
import VideoWithThumbnail from '../../../../components/new/custom/VideoWithThumbnail';
import { imageTransformation } from '../../../../utilities/helper';

const VideoFrame = ({
  data,
  index,
  selectedIndex,
  showPadding = false,
  bgColor = '',
}) => {
  const { width } = useWindowDimensions();
  const [play, setPlay] = useState(false);

  useEffect(() => {
    if (selectedIndex === index) {
      setPlay(true);
    } else {
      setPlay(false);
    }
  }, [selectedIndex]);

  return (
    <View
      py={data?.data?.autoPlayable && !showPadding ? 0 : '9xl+9xl'}
      bg={bgColor}
      px={data?.data?.autoPlayable && !showPadding ? 0 : '2xl'}
      alignItems="center"
    >
      {data?.heading ? (
        <Text size="xs" color="primary.300" pb="lg" textAlign="center">
          {data?.heading}
        </Text>
      ) : null}
      {data?.subHeading ? (
        <Text size="2xl" color="primary.500" textAlign="center">
          {data?.subHeading}
        </Text>
      ) : null}
      {!isValueNullOrEmpty(data?.data?.mediaURL) ? (
        <View mt={data?.data?.autoPlayable && !showPadding ? 0 : '9xl'}>
          {data?.data?.autoPlayable ? (
            <View>
              <VideoPlayer
                id={`Video_id${data?.data?._id}`}
                url={data?.data?.mediaURL}
                aspectRatio={data?.aspectRatio ?? 1.34}
                play={play}
                setPlay={setPlay}
                hideControls
                showPlayPause
                isLooping
                width={data?.width ?? width - 32}
                // videoBg={colors.backdrop['500']}
                footerTitle={data?.footerTitle}
                footerSubTitle={data?.footerSubTitle}
              />
            </View>
          ) : (
            <LightThemeWrapper>
              <VideoWithThumbnail
                aspectRatio={data?.aspectRatio ?? 0.56}
                thumbnailWidth={width - 32}
                thumbnailSource={imageTransformation(
                  data?.data?.thumbnailURL,
                  width - 32,
                  `${width - 32}-${(width - 32) * 0.56}`,
                )}
                videoSource={data?.data?.mediaURL}
                onPress={() => null}
                videoTitle=""
                videoSubTitle=""
              />
            </LightThemeWrapper>
          )}
        </View>
      ) : null}
    </View>
  );
};

export default VideoFrame;
