import React, { useState, useRef, useEffect } from 'react';
import { FlatList, ScrollView } from 'react-native';
import View from '../../basic/View/View';
import Chip from '../../primitive/Chip/Chip';
import { applyEllipsis } from '../../../../utilities/helper';
import { Appearance } from '../../../../themes/new/helper';

const ChipGroup = ({
  data,
  selectedIndex,
  setSelectedIndex,
  maxLength = 15,
  callbackFn = () => {},
  multiLine = false,
  ...rest
}) => {
  const [offset, setOffset] = useState([]);
  const scrollRef = useRef(null);

  // console.log('ChipGroup data', data);

  const getState = (item, index) => {
    if (item.disabled) {
      return 'disabled';
    }
    if (index === selectedIndex) {
      return 'selected';
    }
    return 'active';
  };

  useEffect(() => {
    if (scrollRef?.current && offset[selectedIndex]) {
      scrollRef.current.scrollTo({
        x: offset[selectedIndex].x - 22,
        y: 0,
        animated: true,
      });
    }
  }, [selectedIndex]);

  const pressHandler = (item, index) => {
    if (!item.disabled) {
      setSelectedIndex(index);
    }
    callbackFn(item, index);
  };

  return multiLine ? (
    <ScrollView
      ref={scrollRef}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <View
            key={item.sectionTitle ?? item.name}
            // pl={index === 0 ? '2xl' : 0}
            // pr={index === data.length - 1 ? '2xl' : 0}
            // mr="lg"
            ml={index === 0 || index === Math.ceil(data?.length / 2) ? 'xl' : 0}
            // eslint-disable-next-line no-unsafe-optional-chaining
            mr={index === data?.length - 1 ? 'xl' : 0}
            px="sm"
            my="sm"
            onLayout={(event) => {
              const { x } = event.nativeEvent.layout;
              setOffset((items) => {
                const exist = items.findIndex(
                  (offsetItem) => offsetItem.index === index,
                );
                if (exist === -1) {
                  return [...items, { x, index }].sort(
                    (a, b) => a.index - b.index,
                  );
                }
                return items;
              });
            }}
          >
            <Chip
              helperText={item?.helperText}
              helperTextColor={item?.helperTextColor}
              textColor={item?.textColor}
              state={getState(item, index)}
              onPress={() => pressHandler(item, index)}
              size="xs"
              iconLeft=""
              iconRight={item.filter?.length ? 'down-outline-300' : ''}
              appearance={item?.appearance || Appearance.FILLED}
              {...rest}
            >
              {applyEllipsis(item.sectionTitle ?? item.name, maxLength)}
            </Chip>
          </View>
        )}
        numColumns={Math.ceil(data?.length / 2)}
        scrollEnabled={false}
      />
    </ScrollView>
  ) : (
    <ScrollView
      ref={scrollRef}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      {data?.map((item, index) => (
        <View
          key={item.sectionTitle ?? item.name}
          // pl={index === 0 ? '2xl' : 0}
          // pr={index === data.length - 1 ? '2xl' : 0}
          // mr="lg"
          ml={index === 0 ? 'xl' : 0}
          // eslint-disable-next-line no-unsafe-optional-chaining
          mr={index === data?.length - 1 ? 'xl' : 0}
          px="sm"
          my="sm"
          onLayout={(event) => {
            const { x } = event.nativeEvent.layout;
            setOffset((items) => {
              const exist = items.findIndex(
                (offsetItem) => offsetItem.index === index,
              );
              if (exist === -1) {
                return [...items, { x, index }].sort(
                  (a, b) => a.index - b.index,
                );
              }
              return items;
            });
          }}
        >
          <Chip
            helperText={item?.helperText}
            helperTextColor={item?.helperTextColor}
            textColor={item?.textColor}
            state={getState(item, index)}
            onPress={() => pressHandler(item, index)}
            size="xs"
            iconLeft=""
            iconRight={item.filter?.length ? 'down-outline-300' : ''}
            appearance={item?.appearance || Appearance.FILLED}
            {...rest}
          >
            {applyEllipsis(item.sectionTitle ?? item.name, maxLength)}
          </Chip>
        </View>
      ))}
    </ScrollView>
  );
};

export default ChipGroup;
