import React, { forwardRef, useMemo } from 'react';
import PayoutCalculationPanel from '../../components/PayoutCalculationPanel';
import PointsCalculationPanel from '../../components/PointsCalculationPanel';
import { TransactionsEarningsReport } from '../../types';
import { Text, View } from '../../../../components/new';
import TransactionsReportPanel from '../../components/TransactionsReportPanel';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';

export enum BSMode {
  DISMISSED = 'DISMISSED',
  EARNINGS_REPORT = 'EARNINGS_REPORT',
}

interface PayoutDetailsBottomSheetProps {
  onDismiss: () => void;
  bsMode: BSMode;
  earningsReport: TransactionsEarningsReport;
}

const PayoutDetailsBottomSheet = forwardRef(
  (props: PayoutDetailsBottomSheetProps, ref) => {
    const { earningsReport, bsMode, onDismiss } = props;

    const {
      revenueAmount,
      collectedAmount,
      redeemedLoyaltyPoints,
      discountAmount,
    } = earningsReport.formated;

    return (
      <BottomSheetV2 ref={ref} onClose={onDismiss} insets={false}>
        <View>
          {bsMode === BSMode.EARNINGS_REPORT && (
            <View px="2xl" pt="2xl">
              {/* Payout */}
              <PayoutCalculationPanel earningsReport={earningsReport} />
              {/* Earnings */}
              <View mt="4xl">
                <Text
                  mb="lg"
                  size="md"
                  fomtFamily="bodyMedium"
                  color="primary.200"
                >
                  My Earnings
                </Text>
                <TransactionsReportPanel
                  mode="EARNINGS"
                  report={{
                    revenueAmount,
                    collectedAmount,
                    discountAmount,
                    points: redeemedLoyaltyPoints,
                  }}
                />
              </View>
              {/* Points Compensation */}
              <View mt="4xl">
                <Text
                  mb="lg"
                  size="md"
                  fomtFamily="bodyMedium"
                  color="primary.200"
                >
                  Points Compensation
                </Text>
                <PointsCalculationPanel earningsReport={earningsReport} />
                {earningsReport?.grantedLoyaltyPoints >
                  earningsReport?.redeemedLoyaltyPoints && (
                  <View mt="2xl">
                    <Text size="sm" color="primary.200">
                      Point Compensation is ₹0 when Loyalty points redeemed is
                      less than Loyalty Points Given
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}
        </View>
      </BottomSheetV2>
    );
  },
);

export default PayoutDetailsBottomSheet;
