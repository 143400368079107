import { useLazyLoadQuery } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { valetDailyBonusQuery } from '../API/valetDailyBonusQuery';
import valetDailyBonusTasksQuery from '../API/valetDailyBonusTasksQuery';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { valetBonusAmount } from '../../../utilities/Constant';

/* eslint-disable-next-line import/prefer-default-export */
export const useValetBonusTaskBreakDownLogic = (
  date: string,
  queryOptions: NewErrorBoundaryParentState,
) => {
  const navigation = useNavigation();

  const { dailyValetTaskBonus } = useLazyLoadQuery(
    valetDailyBonusQuery,
    {
      filter: { date },
    },
    {
      ...queryOptions,
      neworkCacheConfig: { force: true },
    },
  );

  const { findDailyValetRewards } = useLazyLoadQuery(
    valetDailyBonusTasksQuery,
    {
      filter: { date },
    },
    {
      ...queryOptions,
      neworkCacheConfig: { force: true },
    },
  );

  const { bonus } = dailyValetTaskBonus ?? {};

  const navigateBack = () => {
    navigation.navigate('valet');
  };

  const dailyRewards = findDailyValetRewards.map((reward) => {
    const taskTypeLabel = (() => {
      switch (reward?.task?.taskType) {
        case 'park':
          return `Parking Task | ${reward?.valetCode}`;
        case 'repark':
          return `Reparking Task | ${reward?.valetCode}`;
        case 'recall':
          return `Recall Task | ${reward?.valetCode}`;
        default:
          return '';
      }
    })();

    return {
      ...reward,
      label: taskTypeLabel,
    };
  });

  return {
    dailyRewards,
    onTimeBonus: valetBonusAmount.onTime, // Rupees
    delayPenalty: valetBonusAmount.delayed, // Rupees
    noDailyRewards: findDailyValetRewards?.length === 0,
    onTimeTaskCount: bonus?.onTimeTaskCount ?? 0,
    delayedTaskCount: bonus?.delayedTaskCount ?? 0,
    bonusAmountTotal: bonus?.bonusAmount ?? 0,
    navigateBack,
  };
};
