import { useContext } from 'react';
import { Button, Text, View } from '../../../components/new';
import Chip from '../../../components/new/primitive/Chip/Chip';
import { RecentSearchContext } from './RecentSearchContextProvider';

const RecentSearchSection = ({ navigation }) => {
  const { data: recentSearchData, updateData: updateRecentSearch } =
    useContext(RecentSearchContext);

  return (
    <>
      {recentSearchData.length ? (
        <View mb="lg">
          <View flexDirection="row" justifyContent="space-between" mb="xl">
            <View justifyContent="center">
              <Text color="primary.300" size="sm">
                Recent Searches
              </Text>
            </View>
            <View>
              <Button
                appearance="ghost"
                status="info"
                size="sm"
                onPress={() => updateRecentSearch([])}
              >
                Clear All
              </Button>
            </View>
          </View>
          <View flexDirection="row" flexWrap="wrap">
            {recentSearchData.map(({ displayName, _id: id }) => (
              <View p="sm" key={displayName} maxWidth={144}>
                <Chip
                  state="active"
                  size="xs"
                  onPress={() =>
                    // navigation.navigate('menu', {
                    //   restaurantId: id,
                    // })
                    navigation.navigate('RestaurantScreen', {
                      restaurantId: id,
                    })
                  }
                >
                  {displayName}
                </Chip>
              </View>
            ))}
          </View>
        </View>
      ) : null}
    </>
  );
};

export default RecentSearchSection;
