import React from 'react';
import IcoMoon, { IconProps } from 'react-icomoon';
import { Animated, Platform } from 'react-native';
import { Path, Svg } from 'react-native-svg';

const iconSet = require('../../../../../assets/fonts/icomoon/selection.json');

const StarIcon = Animated.createAnimatedComponent(
  class IcoMoonClass extends React.PureComponent<IconProps> {
    private nativeObj: any;

    constructor(props) {
      super(props);
      this.nativeObj =
        Platform.OS !== 'web'
          ? { native: true, SvgComponent: Svg, PathComponent: Path }
          : {};
    }

    render() {
      return <IcoMoon {...this.nativeObj} iconSet={iconSet} {...this.props} />;
    }
  },
);

export default StarIcon;
