import React from 'react';
import { TicketVariant } from './enums';
import { TicketProps } from './types';
import EventTicket, { EventTicketProps } from './variants/EventTicket';
import RewardTicket, { RewardTicketProps } from './variants/RewardTicket';

const Ticket = ({ variant, ...props }: TicketProps) => {
  switch (variant) {
    case TicketVariant.EVENT:
      return <EventTicket {...(props as EventTicketProps)} />;
    case TicketVariant.REWARD:
      return <RewardTicket {...(props as RewardTicketProps)} />;
    default:
      return <></>;
  }
};

export default Ticket;
export * from './enums';
