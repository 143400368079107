/* eslint-disable arrow-body-style */
import { Environment, RecordSource, Store } from 'relay-runtime';
import {
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware,
  cacheMiddleware,
} from 'react-relay-network-modern';
import useAuthStore from '../stores/authStore';
import AppConstants from '../utilities/AppConstants';

const { API_URL } = AppConstants;

const middlewares = [
  urlMiddleware({
    url: () => API_URL,
  }),
  cacheMiddleware({
    size: 250, // max 100 requests
    ttl: 300000, // 5 minutes
    allowMutations: false,
    clearOnMutation: true,
  }),
  authMiddleware({
    /* eslint-disable no-return-await */
    token: async () => useAuthStore.getState().authToken,
    allowEmptyToken: true,
  }),
];

const network = new RelayNetworkLayer(middlewares);

const refreshTokenEnvironment = new Environment({
  network,
  store: new Store(new RecordSource()),
});

export default refreshTokenEnvironment;
