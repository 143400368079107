import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Text, View } from '../../../../../components/new';
import CustomIcon from '../../../../../components/new/primitive/Icon/customIcon';

type Status = 'success' | 'error' | 'warning';

interface RecallStatusInfo {
  status: Status;
  icon?: string;
  textPri: string;
  textSec?: string;
  showBorder?: boolean;
}

const getStatusColor = (status: RecallStatusInfo['status']) => {
  switch (status) {
    case 'success':
      return 'success.500';
    case 'warning':
      return 'warning.500';
    case 'error':
      return 'error.500';
    default:
      return 'primary.500';
  }
};

const RecallStatusInfo = ({
  status,
  icon = 'check-filled-400',
  textPri,
  textSec,
  showBorder = false,
}: RecallStatusInfo) => {
  const statusColor = getStatusColor(status);
  const { width } = useWindowDimensions();

  return (
    <View alignItems="center">
      {/* Divider */}
      {/* @ts-ignore */}
      <View
        bottom={0}
        position="absolute"
        width={width}
        borderBottomWidth={showBorder ? 'xs' : 0}
        borderColor={showBorder ? 'primary.50' : 'transparent'}
      />
      <View mb="4xl" width={80} height={80} overflow="hidden">
        <View alignItems="center" justifyContent="center">
          <CustomIcon color={statusColor} icon={icon} size={80} />
        </View>
      </View>
      <Text mb="lg" p="lg" size="5xl" weight="medium" color={statusColor}>
        {textPri}
      </Text>
      {textSec && (
        <Text mb="9xl" size="lg" weight="medium" color="primary.400">
          {textSec}
        </Text>
      )}
    </View>
  );
};

export default RecallStatusInfo;
