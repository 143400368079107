// =================================
// const freeParkingProb = 0.001;
// const freeBubbleTeaProb = 0.0;
// const freePostCardProb = 0.004;
// const freeFridgeMagnetProb = 0.0;
// const freeKeyChainProb = 0.0;
// const freeStickerProb = 0.002;
// =================================

export enum rewardTypeEnums {
  freeCoffee = 'FREE_COFFEE',
  freeDimsum = 'FREE_DIMSUM',
  freePrint = 'FREE_PRINT',
  'freeParking' = 'FreeParking',
  'freeBubbleTea' = 'FreeBubbleTea',
  'freePostCard' = 'FreePostCard',
  'freeFridgeMagnet' = 'FreeFridgeMagnet',
  'freeKeyChain' = 'FreeKeyChain',
  'freeSticker' = 'FreeSticker',
  'freePoints' = 'FreePoints',
  'event' = 'Event',
}

export const freeItemsInfo = [
  {
    // winningProb: freeParkingProb,
    adjustedWinningProb: 0,
    winningKeyFirstRoll: 'h',
    winningKeySecondRoll: '8',
    prizeName: rewardTypeEnums.freeParking,
  },
  {
    // winningProb: freeBubbleTeaProb,
    adjustedWinningProb: 0,
    winningKeyFirstRoll: 'a',
    winningKeySecondRoll: '1',
    prizeName: rewardTypeEnums.freeBubbleTea,
  },
  {
    // winningProb: freePostCardProb,
    adjustedWinningProb: 0,
    winningKeyFirstRoll: 'b',
    winningKeySecondRoll: '2',
    prizeName: rewardTypeEnums.freePostCard,
  },
  {
    // winningProb: freeFridgeMagnetProb,
    adjustedWinningProb: 0,
    winningKeyFirstRoll: 'c',
    winningKeySecondRoll: '3',
    prizeName: rewardTypeEnums.freeFridgeMagnet,
  },
  {
    // winningProb: freeKeyChainProb,
    adjustedWinningProb: 0,
    winningKeyFirstRoll: 'd',
    winningKeySecondRoll: '4',
    prizeName: rewardTypeEnums.freeKeyChain,
  },
  {
    // winningProb: freeStickerProb,
    adjustedWinningProb: 0,
    winningKeyFirstRoll: 'e',
    winningKeySecondRoll: '5',
    prizeName: rewardTypeEnums.freeSticker,
  },
];

export const slotMachineRangeMapper = {
  a: 0,
  b: 1,
  c: 2,
  d: 3,
  e: 4,
  f: 5,
  g: 6,
  h: 7,
  i: 8,
  // ======
  j: 0, // bogus value
  k: 1, // bogus value
  l: 2, // bogus value
  m: 3, // bogus value
  n: 4, // bogus value
  o: 5, // bogus value
  p: 6, // bogus value
  q: 7, // bogus value
  r: 8, // bogus value
  // =======
  s: 0, // bogus value
  t: 1, // bogus value
  u: 2, // bogus value
  v: 3, // bogus value
  w: 4, // bogus value
  x: 5, // bogus value
  y: 6, // bogus value
  z: 7, // bogus value
  0: 8, // bogus value
  // ========
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
  7: 6,
  8: 7,
  9: 8,
  // ========
};

export const slotMachinePrizeToKeyMapper = {
  0: ['a', '1'],
  1: ['b', '2'],
  2: ['c', '3'],
  3: ['d', '4'],
  4: ['e', '5'],
  5: ['f', '6'],
  6: ['g', '7'],
  7: ['h', '8'],
  8: ['i', '9'],
};
export const winningNumbers = [];

let winningProbabilityInTotalTemp = 0;

freeItemsInfo.forEach((item, index) => {
  let tempProb = 0;
  for (let i = 0; i <= index; i += 1) {
    tempProb += freeItemsInfo[i].winningProb;
  }
  item.adjustedWinningProb = tempProb;
  // =======
  winningProbabilityInTotalTemp += item.winningProb;
  // =======

  const winningKey = slotMachineRangeMapper[item.winningKeyFirstRoll];

  winningNumbers.push(`${winningKey}`);
});

export const winningProbabilityInTotal = winningProbabilityInTotalTemp;

export const slotMachineRange = 'abcdefghijklmnopqrstuvwxyz0123456789';

const getRandomNumberBetween = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const areAllItemIdenticalInArr = (arr) => {
  for (let i = 0; i < arr.length - 1; i += 1) {
    if (arr[i] !== arr[i + 1]) {
      return false;
    }
  }
  return true;
};

export const getRandomKeysFromObj = ({
  noOfKeys = 3,
  searchInFirst = false,
  nonWinningValCanMatch = true,
}) => {
  const randomNumber = Math.random(); // [0,1]
  let returnVal = [];
  let range;
  if (searchInFirst) {
    range = [0, 8];
  } else {
    range = [25, 32];
  }
  // if not winningslotMachineRange:: means not winning
  if (randomNumber > winningProbabilityInTotal) {
    //  - make sure a is not from winning numbers array

    if (nonWinningValCanMatch) {
      for (let i = 0; i < noOfKeys; i += 1) {
        returnVal[i] = getRandomNumberBetween(range[0], range[1]);
      }
    } else {
      do {
        for (let i = 0; i < noOfKeys; i += 1) {
          returnVal[i] = getRandomNumberBetween(range[0], range[1]);
        }
      } while (areAllItemIdenticalInArr(returnVal));
    }

    const slotMachineRangeMapperArr = slotMachineRange;

    returnVal = returnVal.map((item) => {
      return slotMachineRangeMapperArr[item];
    });
    return returnVal;
  }

  // for winning:::

  for (let index = 0; index < freeItemsInfo.length; index += 1) {
    if (randomNumber <= freeItemsInfo[index].adjustedWinningProb) {
      if (searchInFirst) {
        for (let i = 0; i < noOfKeys; i += 1) {
          returnVal[i] = freeItemsInfo[index].winningKeyFirstRoll;
        }
      } else {
        for (let i = 0; i < noOfKeys; i += 1) {
          returnVal[i] = freeItemsInfo[index].winningKeySecondRoll;
        }
      }
      break;
    }
  }
  return returnVal;
};
