import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const qrCodeScreenQuery = graphql`
  query qrCodeScreenQuery($userId: MongoID!) {
    findUserById(_id: $userId) {
      _id
      name {
        first
        last
      }
      identityCode
      profileImage
    }
  }
`;
