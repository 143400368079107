import React from 'react';
import { Text, View } from '../../../../../components/new';
import CachedImage from '../../../../../components/new/custom/CachedImage';

const WhatsappCount = ({ count }: { count: number }) => {
  return (
    <View width={80}>
      <CachedImage
        source={require('../../../../../../assets/images/whatsapp-logo.webp')}
        style={{ width: 80, height: 80 }}
      />
      <View
        width={24}
        height={24}
        borderRadius="full"
        bg="error.500"
        position="absolute"
        top={10}
        right={5}
        alignItems="center"
        justifyContent="center"
      >
        <Text size="xs" color="static.white">
          {count}
        </Text>
      </View>
    </View>
  );
};

export default WhatsappCount;
