import { graphql } from 'react-relay';

// eslint-disable-next-line import/prefer-default-export
export const pointsScreenQuery = graphql`
  query pointsScreenQuery(
    $filter: rewardHistoryFilterTC
    $sort: WalletHistorySortEnum
    $id: MongoID!
    $groupSTW: Boolean
  ) {
    getWalletHistory(filter: $filter, sort: $sort, groupSTW: $groupSTW) {
      userId
      _id
      type
      amount
      isAvailable
      created_at
      source {
        type
        name
        refId
      }
    }
    findUserById(_id: $id) {
      wallet {
        currentBalance
        earnedAmount
      }
    }
  }
`;
