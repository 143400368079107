/**
 * @generated SignedSource<<ebf500b5f64f266c4e28a53dbbac9d01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type Enumrestaurants2DescriptionCusinesName = "american" | "asian" | "bakery" | "bar" | "bengali" | "beverages" | "bihari" | "bubble_tea" | "cafe" | "chinese" | "coastal_cuisine" | "coffee" | "continental" | "desserts" | "european" | "fastfood" | "french" | "goan" | "greek" | "healthy" | "himalayan" | "ice_cream" | "indian" | "italian" | "japanese" | "kashmiri" | "korean" | "lebanese" | "malaysian" | "mediterranean" | "mexican" | "momos" | "mughlai" | "north_eastern" | "north_indian" | "pizza" | "punjabi" | "rajasthani" | "rolls" | "russian" | "salad" | "seafood" | "shake" | "south_indian" | "sushi" | "thai" | "turkish" | "vietnamese" | "waffle" | "%future added value";
export type Enumrestaurants2DescriptionTagsIconColor = "error" | "info" | "success" | "%future added value";
export type Enumrestaurants2MediaGalleryMediaMediaType = "image" | "video" | "%future added value";
export type Enumrestaurants2MediaGallerySectionTitle = "Ambience" | "Awards___Recognitions" | "Food" | "Founder_Story" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type restaurantListingQueryFragment$data = {
  readonly findRestaurants2V2: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly description: {
          readonly cost2pax: number | null;
          readonly cusines: ReadonlyArray<{
            readonly _id: any | null;
            readonly name: Enumrestaurants2DescriptionCusinesName | null;
            readonly primary: boolean | null;
          } | null> | null;
          readonly summary: string | null;
          readonly tagLine: ReadonlyArray<string | null> | null;
          readonly tags: ReadonlyArray<{
            readonly _id: any | null;
            readonly icon: {
              readonly color: Enumrestaurants2DescriptionTagsIconColor | null;
              readonly iconName: string | null;
            } | null;
            readonly name: string | null;
          } | null> | null;
        };
        readonly displayName: string;
        readonly logo: string | null;
        readonly mediaGallery: ReadonlyArray<{
          readonly _id: any | null;
          readonly description: string | null;
          readonly displayOrder: number;
          readonly media: ReadonlyArray<{
            readonly cover: boolean | null;
            readonly coverVideo: boolean | null;
            readonly displayOrder: number;
            readonly mediaType: Enumrestaurants2MediaGalleryMediaMediaType;
            readonly mediaURL: string;
            readonly prime: boolean | null;
            readonly sectionCover: boolean | null;
          } | null> | null;
          readonly sectionTitle: Enumrestaurants2MediaGallerySectionTitle;
        } | null> | null;
        readonly menuId: any | null;
        readonly overAllRating: number | null;
        readonly payBill: {
          readonly payEnabled: boolean | null;
          readonly pointsEnabled: {
            readonly redeem: boolean | null;
            readonly reward: boolean | null;
          } | null;
        } | null;
        readonly payBillCashback: number | null;
        readonly payBillPercentage: number | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "restaurantListingQueryFragment";
};
export type restaurantListingQueryFragment$key = {
  readonly " $data"?: restaurantListingQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"restaurantListingQueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "findRestaurants2V2"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./restaurantListingFragmentQuery.graphql')
    }
  },
  "name": "restaurantListingQueryFragment",
  "selections": [
    {
      "alias": "findRestaurants2V2",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "restaurants2Connection",
      "kind": "LinkedField",
      "name": "__restaurantListingQueryFragment_findRestaurants2V2_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2Edge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "restaurants2",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "logo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "menuId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "restaurants2Description",
                  "kind": "LinkedField",
                  "name": "description",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "summary",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tagLine",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "restaurants2DescriptionCusines",
                      "kind": "LinkedField",
                      "name": "cusines",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "primary",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "restaurants2DescriptionTags",
                      "kind": "LinkedField",
                      "name": "tags",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "restaurants2DescriptionTagsIcon",
                          "kind": "LinkedField",
                          "name": "icon",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "iconName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "color",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cost2pax",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "overAllRating",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "restaurants2MediaGallery",
                  "kind": "LinkedField",
                  "name": "mediaGallery",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sectionTitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "restaurants2MediaGalleryMedia",
                      "kind": "LinkedField",
                      "name": "media",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cover",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "prime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "mediaURL",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "mediaType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sectionCover",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "coverVideo",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payBillPercentage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payBillCashback",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "restaurants2PayBill",
                  "kind": "LinkedField",
                  "name": "payBill",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "payEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "restaurants2PayBillPointsEnabled",
                      "kind": "LinkedField",
                      "name": "pointsEnabled",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "redeem",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "reward",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f9404a21cbb6145c1dcc9880030b81ae";

export default node;
