import { debounce } from 'lodash';
import React, { memo, useState } from 'react';
import { Image } from 'react-native';
import styled, { ThemeContext, ThemeProvider } from 'styled-components/native';
import theme, { getTheme } from '../../../themes/new/theme';
import View from '../basic/View/View';
import WebCarouselDots from './WebCarouselDots';
import GradientBackground from './GradientBackground';
import { imageTransformation } from '../../../utilities/helper';
import VideoPlayer from './VideoPlayer';
import Button from '../primitive/Button/Button';
import useCommonStore from '../../../stores/commonStore';
import Text from '../primitive/Typography/Text/Text';

const StyledView = styled.View`
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
  }
`;

const gradientColors = [
  { offset: '0', color: theme.colors.primary[500], opacity: 0 },
  { offset: '1', color: theme.colors.primary[500], opacity: 0.8 },
];

const areEqual = (prevProps, nextProps) => {
  if (prevProps?.data?.length !== nextProps?.data?.length) return false;
  return true;
};

const WebCarousel = ({
  data,
  dotsFromBottom,
  aspectRatio,
  seeAllPhotoHandle,
  // muted,
  // setMuted,
  windowWidth,
  showNavigationCta,
  showTitleAndDescription,
}) => {
  //   const boxHeight = 200;
  const boxWidth = 200;

  // const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const [widthSize, setWidthSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const screenOrientationValue = useCommonStore((state) => state.orientation);

  // setting only first two images
  const [tempData, setTempData] = useState(
    data.map((item, index) => {
      if (index === 0 || index === 1) {
        return item;
      }
      return { _id: item._id };
    }),
  );

  const debounced = debounce((a) => {
    const newData = [...tempData];
    const page = a === 0 ? 0 : Math.round(a / widthSize);
    setCurrentPage(page);
    const nextPage = page + 1;
    const previousPage = page - 1;

    let dataModified = false;
    // for current
    if (page < data.length && !newData[page]?.mediaURL) {
      newData[page] = data[page];
      dataModified = true;
    }
    // for next
    if (nextPage < data.length && !newData[nextPage]?.mediaURL) {
      newData[nextPage] = data[nextPage];
      dataModified = true;
    }
    // for previous
    if (previousPage > 0 && !newData[previousPage]?.mediaURL) {
      newData[previousPage] = data[previousPage];
      dataModified = true;
    }
    if (dataModified) {
      setTempData(newData);
    }
  }, 0);

  const handleOnScroll = (e) => {
    debounced(e.target.scrollLeft);
  };

  return (
    <View
      style={{ height: '100%' }}
      onLayout={(e) => {
        if (e.nativeEvent.layout.width !== 0) {
          setWidthSize(e.nativeEvent.layout.width);
        }
      }}
    >
      <StyledView
        style={{
          background: 'background.secondary.base',
          overflow: 'scroll',
          scrollbarWidth: 'none',
          flexDirection: 'row',
          scrollSnapType: 'both mandatory',
          height: '100%',
        }}
        onScroll={handleOnScroll}
      >
        {!tempData?.length && (
          // <Pressable onPress={onPress}>
          <View style={{ height: '100%', width: '100%' }}>
            <Image
              style={{ height: '100%', width: '100%' }}
              source={require('../../../../assets/images/Noimage.png')}
              resizeMode="cover"
            />
          </View>
          // </Pressable>
        )}
        {tempData.map((item, index) => (
          <View
            key={index}
            style={{
              // height: '100%',
              width: '100%',
              scrollSnapAlign: 'start',
              scrollSnapStop: 'always',
            }}
          >
            <View
              position="absolute"
              bottom={0}
              zIndex={2}
              height={
                dotsFromBottom && dotsFromBottom !== 0
                  ? dotsFromBottom + 80
                  : 80
              }
              width="100%"
            >
              <GradientBackground
                backgroundOpacity={0.6}
                gradientColors={gradientColors}
                flex={1}
                id={item?._id}
              />
            </View>
            {item?.mediaType !== 'video' ? (
              <Image
                style={{ height: '100%', width: '100%' }}
                source={{
                  uri: item?.mediaURL
                    ? imageTransformation(
                        item.mediaURL,
                        windowWidth > 1200 && screenOrientationValue < 3
                          ? 375 - 2 * theme.space['2xl']
                          : windowWidth - 2 * theme.space['2xl'],
                      )
                    : '',
                }}
              />
            ) : (
              <View zIndex={3}>
                <VideoPlayer
                  key={currentPage}
                  // muted={muted}
                  // setMuted={setMuted}
                  id={item?._id}
                  url={item?.mediaURL}
                  aspectRatio={aspectRatio}
                  play={index === currentPage}
                  showPlayPause
                  isLooping
                  bottom={dotsFromBottom}
                />
              </View>
            )}
            {tempData?.length > 1 &&
              index === tempData.length - 1 &&
              showNavigationCta && (
                // put an button overlay on lasy image/ video
                <ThemeProvider
                  theme={
                    ThemeContext.currentThemeMode === 'light'
                      ? getTheme('dark')
                      : getTheme('light')
                  }
                >
                  <View
                    position="absolute"
                    height="100%"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="opacity.80"
                  >
                    <Button
                      size="md"
                      appearance="filled"
                      iconLeft="photolibrary-outline-400"
                      status="primary"
                      onPress={seeAllPhotoHandle}
                    >
                      See All Photos
                    </Button>
                  </View>
                </ThemeProvider>
              )}
            {showTitleAndDescription ? (
              <View
                position="absolute"
                height="100%"
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  size="2xl"
                  weight="medium"
                  color="primary.10"
                  textAlign="center"
                  numberOfLines={1}
                >
                  {item?.title}
                </Text>
                <Text
                  mt="xs"
                  size="md"
                  color="grey.50"
                  textAlign="center"
                  numberOfLines={1}
                >
                  {item?.description}
                </Text>
              </View>
            ) : (
              <></>
            )}
          </View>
        ))}
      </StyledView>
      {!!(data && data?.length && data.length > 1) && (
        <View
          position="absolute"
          // bottom={0}
          bottom={
            dotsFromBottom && dotsFromBottom !== 0
              ? dotsFromBottom + theme.space['2xl']
              : theme.space['2xl']
          }
          zIndex={2}
          alignItems="center"
          style={{ transform: 'translate(-50%,0)', marginLeft: '50%' }}
          // left={0}
          // right={0}
        >
          <WebCarouselDots dots={data || []} currentIndex={currentPage} />
        </View>
      )}
    </View>
  );
};

export default memo(WebCarousel, areEqual);
