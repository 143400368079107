import React from 'react';
import { Image, Pressable } from 'react-native';
import { Avatar, View } from '../../../../components/new';
import { getTheme } from '../../../../themes/new/theme';

const theme = getTheme();

interface EditableAvatarProps {
  isDarkTheme: boolean;
  profileImage: string;
  fullName: string;
  onProfileEdit: () => void;
}

const EditableAvatar = ({
  isDarkTheme,
  profileImage,
  fullName,
  onProfileEdit,
}: EditableAvatarProps) => {
  return (
    <View
      alignItems="center"
      justifyContent="center"
      borderWidth="sm"
      borderColor={
        isDarkTheme ? theme.colors.gold[500] : theme.colors.primary[50]
      }
      borderRadius="full"
    >
      <Avatar
        level={2}
        source={profileImage || ''}
        name={fullName}
        resizeMode="cover"
      />
      <Pressable
        style={{ position: 'absolute', bottom: -4, right: -4 }}
        onPress={onProfileEdit}
        hitSlop={{ top: 10, bottom: 0, left: 0, right: 0 }}
      >
        <View borderRadius="full" borderWidth="sm" borderColor="primary.50">
          <Image
            style={{ width: 24, height: 24 }}
            source={require('../../../../../assets/edit-pencil.png')}
          />
        </View>
      </Pressable>
    </View>
  );
};

export default EditableAvatar;
