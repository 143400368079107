/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react';
import {
  Linking,
  useWindowDimensions,
  StyleSheet,
  Pressable,
  Platform,
} from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import * as MediaLibrary from 'expo-media-library';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { IconButton, Text, View } from '../../components/new';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import Loading from '../../components/loading.component';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import AppConstants from '../../utilities/AppConstants';
import { FontWeights } from '../../themes/new/helper';

const FooterBar = ({
  data = null,
  multiSelected = [],
  isLibrary = false,
  favorite = false,
  fromViewer = false,
  isVideo = false,
  onDelete = () => {},
  onFavorite = () => {},
  downloadSuccess = () => {},
  generateQR = () => {},
  instagramShare = false,
}) => {
  const { height, width } = useWindowDimensions();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [permissionResponse, requestPermission] = MediaLibrary.usePermissions();
  const { bottom } = useSafeAreaInsets();
  const [loading, setLoading] = useState(false);
  const actions = [
    { id: 0, name: 'delete-400', isActive: true, label: 'Delete' },
    {
      id: 1,
      name: favorite ? 'favorite-filled-400' : 'favourite-outline-400',
      isActive: !isLibrary,
      label: 'Favourite',
    },
    {
      id: 2,
      name: 'share-outline-400',
      isActive: !isLibrary && Platform.OS !== 'web',
      label: 'Share',
    }, // share
    {
      id: 4,
      name: 'print-outline-300',
      isActive: !isLibrary && !isVideo,
      label: 'Print',
    }, // print
    {
      id: 3,
      name: 'download-outline-400',
      isActive: Platform.OS !== 'web',
      label: 'Download',
    }, // download
  ];

  const sharingStatement = `Hey, I just discovered my amazing 32nd Moments from my recent visit to 32nd Avenue, Gurugram! ${String.fromCodePoint(
    128248,
  )} ${String.fromCodePoint(
    10024,
  )} Don't miss out on finding your own special 32nd Moments. Download the 32nd Club app now and and embark on a journey down the memory lane!. ${String.fromCodePoint(
    128171,
  )} Get the app here ${AppConstants.WEB_URL}/PhotoMoments`;

  const onShare = async () => {
    const imageData = multiSelected?.length ? multiSelected?.[0] : data;
    // if (multiSelected?.length) {
    //   const newArr = [];
    //   multiSelected.map((item) => newArr.push(item?.assetLink));
    //   try {
    //     const result = await Share.share({
    //       message: sharingStatement.concat('\n\n').concat(newArr.join('\n\n')),
    //     });
    //     if (result.action === Share.sharedAction) {
    //       if (result.activityType) {
    //         // shared with activity type of result.activityType
    //       } else {
    //         // shared
    //       }
    //     } else if (result.action === Share.dismissedAction) {
    //       // dismissed
    //     }
    //   } catch (error: any) {
    //     Alert.alert(error.message);
    //   }
    //   return;
    // }
    setLoading(true);
    const fileDetails = {
      extension: isVideo ? '.mp4' : '.jpg',
      shareOptions: {
        mimeType: isVideo ? 'video/mp4' : 'image/jpeg',
        dialogTitle: sharingStatement,
        UTI: isVideo ? 'video/mp4' : 'image/jpeg',
      },
    };
    const downloadPath = `${FileSystem.cacheDirectory}${imageData.assetId}${fileDetails.extension}`;
    const { uri: localUrl } = await FileSystem.downloadAsync(
      imageData.assetLink,
      downloadPath,
    );
    if (!(await Sharing.isAvailableAsync())) {
      dispatchSnackbar({
        msg: 'Sharing is not available',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v1,
      });
      setLoading(false);
      return;
    }
    setLoading(false);
    firebaseEventLogger('photoViewer__sharePicture_Tap', {
      buttonName: 'sharePicture',
      screenName: 'photoViewer',
      userType: 'user',
      interactionType: 'tap',
      imageId: imageData.assetId,
      isVideo,
    });
    if (fromViewer) {
      global.changesNeeded = true; // for refreshing list from picture viewer
    }
    await Sharing.shareAsync(localUrl, fileDetails.shareOptions);
  };

  const handleDownload = async ({ val, lastItem }) => {
    setLoading(true);
    const assetLink = val?.assetLink;
    let fileUri = `${FileSystem.documentDirectory}/${val?.assetId}`;
    fileUri = fileUri.replace(' ', '');
    try {
      const res = await FileSystem.downloadAsync(assetLink, fileUri);
      await saveFile({ fileUri: res.uri, lastItem });
    } catch (err) {
      console.log('FS Err: ', err);
      setLoading(false);
    }
  };

  const saveFile = async ({ fileUri, lastItem }) => {
    const { status } = await requestPermission();
    if (status === 'granted') {
      try {
        const asset = await MediaLibrary.createAssetAsync(fileUri);
        const album = await MediaLibrary.getAlbumAsync('32nd');
        if (album == null) {
          await MediaLibrary.createAlbumAsync('32nd', asset, true);
        } else {
          MediaLibrary.addAssetsToAlbumAsync([asset], album.id, true);
        }
        if (lastItem) {
          setLoading(false);
          await FileSystem.deleteAsync(fileUri)
            .then((res) => console.log(res, 'res'))
            .catch((err) => console.log(err, 'errr'));
          downloadSuccess();
          dispatchSnackbar({
            msg: `${
              multiSelected?.length > 1 ? 'Media Files' : 'Media'
            } saved in gallery!`,
            status: SnackbarStatus.success,
            version: SnackbarVersion.v2,
          });
          if (fromViewer) {
            global.changesNeeded = true; // for refreshing list from picture viewer
          }
        }
      } catch (err) {
        console.log(err, 'err');
        setLoading(false);
        dispatchSnackbar({
          msg: `Oops, Something went wrong!`,
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
      }
    } else if (status === 'denied') {
      setLoading(false);
      Linking.openSettings();
      dispatchSnackbar({
        msg: `Oops, seems like we do not have access to storage on your phone. Please udpate the phone settings!`,
        status: SnackbarStatus.info,
        version: SnackbarVersion.v2,
      });
    }
  };

  const onDownload = async () => {
    if (isLibrary) {
      await multiSelected.map((item, index) =>
        handleDownload({
          val: item,
          lastItem: index + 1 === multiSelected?.length,
        }),
      );
      firebaseEventLogger('photoMoments__downloadMultiple_Tap', {
        buttonName: 'downloadMultiple',
        screenName: 'photoMoments',
        userType: 'user',
        interactionType: 'tap',
        multiple: true,
      });
    } else {
      await handleDownload({ val: data, lastItem: true });
      firebaseEventLogger('photoViewer__downloadPicture_Tap', {
        buttonName: 'downloadPicture',
        screenName: 'photoViewer',
        userType: 'user',
        interactionType: 'tap',
        imageId: data?.assetId,
        isVideo,
      });
    }
  };

  const handledAction = (val) => {
    switch (val) {
      case 0:
        // call delete api
        return onDelete();
      case 1:
        // call favorite api
        return onFavorite();
      case 2:
        // share image
        return onShare();
      case 3:
        // download image
        return onDownload();
      case 4:
        // download image
        return generateQR();
      default:
        return null;
    }
  };

  const itemCount = isLibrary ? 2 : 4;

  const navigation = useNavigation();

  return (
    <>
      <View
        px="2xl"
        flexDirection="row"
        justifyContent="space-between"
        bg="primary.500"
        height={bottom + 56}
        top={isLibrary ? 1 : 0}
      >
        {actions?.map((item) =>
          item?.isActive ? (
            <View flex={1} key={`footer_item${item?.name}`} mb={bottom}>
              <IconButton
                name={item?.name}
                size="md"
                iconSize="xl"
                appearance="outline"
                shape="square"
                iconColor="primary.10"
                onPress={() => {
                  handledAction(item?.id);
                }}
                shadow="none"
              />
              <Text textAlign="center" color="primary.10" fontSize="2xs">
                {item?.label}
              </Text>
              {item?.id === 4 && (
                <Pressable
                  style={{
                    ...StyleSheet.absoluteFillObject,
                    alignItems: 'flex-end',
                    margin: 2,
                  }}
                  onPress={() => handledAction(item?.id)}
                >
                  <View
                    backgroundColor="error.500"
                    px="sm"
                    borderRadius="2xl"
                    width={(width / itemCount - 32) / 2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      lineHeight="2xs"
                      color="primary.10"
                      fontSize="2xs"
                      weight={FontWeights.MEDIUM}
                    >
                      New
                    </Text>
                  </View>
                </Pressable>
              )}
            </View>
          ) : null,
        )}
      </View>
      {loading && (
        <View
          justifyContent="center"
          alignItems="center"
          height={height}
          width={width}
          position="absolute"
          bottom={0}
        >
          <Loading />
        </View>
      )}
    </>
  );
};
export default FooterBar;
