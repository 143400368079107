import React from 'react';
import View from '../../../basic/View/View';
import Label from '../components/Label';
import TicketQrCode from '../components/TicketQrCode';
import BaseTicket, { BaseTicketProps } from '../BaseTicket';
import TicketStates, { State } from '../components/TicketStates';

export interface EventTicketProps
  extends Omit<BaseTicketProps, 'MoreLabels' | 'BottomContent'> {
  eventDate: string;
  eventStartTime: string;
  state?: State;
  qrAspectRatio?: number;
}

const EventTicket = ({
  state,
  eventDate,
  eventStartTime,
  qrAspectRatio,
  ...baseTicketProps
}: EventTicketProps) => {
  return (
    <BaseTicket
      {...baseTicketProps}
      MoreLabels={
        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Label title="Event Date" text={eventDate} />
          <Label
            position="RIGHT"
            title="Event Start Time"
            text={eventStartTime}
          />
        </View>
      }
      BottomContent={
        state ? (
          <TicketStates state={state} />
        ) : (
          <View pb="3xl" flex={1} alignItems="center" justifyContent="center">
            <TicketQrCode
              data={baseTicketProps.ticketId}
              aspectRatio={qrAspectRatio}
            />
          </View>
        )
      }
    />
  );
};

export default EventTicket;
