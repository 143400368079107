import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

// this is not getting used don't know why himanshu created this (To be discussed)
// export interface CampusLocationInterface {
//   campusId: [];
//   showFirstLoginParkingRewardModal: boolean;
//   setCampusId: (campusId: string) => void;
//   setShowFirstLoginParkingRewardModal: (open: boolean) => void;
// }

interface newDataPropTypes {
  currentLocation?: object;
  campusLocations?: object[];
}

interface useCampusLocationPropTypes {
  (state?: object);
}

const useCampusLocation = <useCampusLocationPropTypes>create()(
  devtools(
    persist(
      (set) => ({
        currentLocation: null,
        campusLocations: [],
        setCampusLocations: ({ data, initial = false }) => {
          set((state) => {
            const currentLocation = data.find((item) => item.checked);

            const newData = <newDataPropTypes>{};
            // update selected data as well
            // ==========
            if (state.campusLocations.length === 0 && initial) {
              newData.currentLocation = currentLocation;
              newData.campusLocations = data;
            }
            if (state.campusLocations.length !== 0 && !initial) {
              newData.currentLocation = currentLocation;
              newData.campusLocations = data;
            }
            return newData;
            //
          });
        },
      }),
      {
        name: 'CampusLocationStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'CampusLocationStore',
    },
  ),
);

export default useCampusLocation;
