/* eslint-disable no-nested-ternary */
import { useWindowDimensions, TextInput } from 'react-native';
import React, { useState, useRef, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { View, Text, Button } from '../../../../components/new';
import VrnView from '../../../../components/new/primitive/VrnView/VrnView';
import useCountdown, {
  CountdownStatus,
} from '../../../../utilities/customHooks/useCountDown';
import BackButton from './components/BackButton';
import CommonLayout from './components/CommonLayout';
import { kiosk } from '../../../../utilities/Constant';
import HiddenInput from './components/HiddenInput';
import { KioskTheme, useKioskStore } from '../../../../stores/kiosk/kioskStore';
import { analytics } from './analytics';

const { SESSION_TIMEOUT } = kiosk;

const ReviewCarDetailsScreen = ({ navigation }) => {
  const parking = useKioskStore((state) => state.parking);
  const theme = useKioskStore((state) => state.theme);
  const [hiddenInput, sethiddenInput] = useState('');
  const isFocused = useIsFocused();
  const ref = useRef(null);
  const {
    countdown,
    countdownStatus,
    startCountdown,
    restartCountdown,
    stopCountdown,
  } = useCountdown(SESSION_TIMEOUT, {
    onComplete: () => {
      analytics.reviewCarScreen.sessionEnded();
      navigation.replace('kioskRecall');
    },
  });

  useEffect(() => {
    analytics.reviewCarScreen.landed(parking ?? ({} as any));
  }, []);

  useEffect(() => {
    if (isFocused) {
      if (countdownStatus === CountdownStatus.NOT_STARTED) {
        startCountdown();
      } else {
        restartCountdown();
      }
    }
  }, [isFocused]);

  let { height } = useWindowDimensions();
  height = height >= 768 ? 768 : height;

  const goBack = () => {
    analytics.reviewCarScreen.backClicked(parking ?? ({} as any));
    navigation.replace('kioskRecall');
  };

  const handleProceed = () => {
    stopCountdown();
    analytics.reviewCarScreen.proceedClicked(parking as any);
    navigation.navigate('recallCar');
  };

  return (
    <CommonLayout>
      <HiddenInput />
      <View flex={1} p="9xl" width="100%">
        <View justifyContent="center">
          {/* Added hidden input for navigating back fix on scanning valet card */}
          <View position="absolute" top={-999}>
            {/* <View alignItems="center"> */}
            <TextInput
              ref={ref}
              style={{
                width: 40,
                height: 40,
                margin: 12,
                borderWidth: 1,
                padding: 10,
                backgroundColor: '#fff',
              }}
              showSoftInputOnFocus={false}
              autoFocus
              onChangeText={sethiddenInput}
              value={hiddenInput}
            />
          </View>
        </View>
        <View flex={1} width="100%" justifyContent="space-between">
          <View>
            <BackButton onBack={goBack} />
            <View p="lg" mt="9xl" mb="102" alignItems="center">
              <Text
                size="7xl"
                lineHeight="9xl"
                color="primary.500"
                weight="medium"
              >
                Review Your Car Details
              </Text>
            </View>
            <View>
              <Text
                textAlign="center"
                color="primary.400"
                mb="2xl"
                size="4xl"
                lineHeight="6xl"
                weight="regular"
              >
                Car Number
              </Text>
              <VrnView plateNumber={parking?.vehicle?.registration?.plate} />
            </View>
          </View>
          <View>
            {/* @ts-ignore */}
            <Button
              onPress={handleProceed}
              rounded="md+lg"
              shadow={theme === KioskTheme.LIGHT ? 'md' : 'none'}
              size="10xl"
            >
              <Text size="4xl" color="primary.10">
                Proceed
              </Text>
            </Button>
            <View mt="4xl">
              <Text
                textAlign="center"
                size="2xl"
                weight="regular"
                color="primary.300"
              >
                Session ending in {countdown}s
              </Text>
            </View>
          </View>
        </View>
      </View>
    </CommonLayout>
  );
};

export default ReviewCarDetailsScreen;
