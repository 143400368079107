import React, { memo } from 'react';
import { Icon, Text, View } from '../../../../components/new';

const areEqual = () => {
  return true;
};

const InvestmentListDataComp = ({ data, horizontal = false, bgColor = '' }) => {
  return (
    <View py="9xl+9xl" bg={bgColor}>
      <Text size="xs" color="primary.300" pb="lg" textAlign="center">
        {data?.heading}
      </Text>
      <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
        {data?.subHeading}
      </Text>
      {data?.data?.length && (
        <View mt="9xl" px="2xl">
          {data?.data.map((item, index) => (
            <View
              flexDirection={horizontal ? 'row' : 'column'}
              key={`inv_list_data_comp_${horizontal}_${item?._id}`}
              mt={index === 0 ? 0 : '4xl'}
              alignItems={horizontal ? 'flex-start' : 'center'}
            >
              <View>
                <Icon
                  name={item?.icon ?? item?.media?.mediaURL}
                  color="primary.400"
                  size="8xl"
                />
              </View>
              <View
                alignItems={horizontal ? 'flex-start' : 'center'}
                mt={horizontal ? 0 : 'lg'}
                ml={horizontal ? '2xl' : 0}
                style={{ flex: 1 }}
              >
                <Text size="md" weight="medium" color="primary.400">
                  {item?.heading}
                </Text>
                <Text
                  size="xs"
                  color="primary.300"
                  mt="sm"
                  flex={1}
                  numberOfLines={2}
                >
                  {item?.subHeading}
                </Text>
              </View>
            </View>
          ))}
        </View>
      )}
    </View>
  );
};

export default memo(InvestmentListDataComp, areEqual);
