import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import { Text, View } from '../../../../components/new';
import VideoPlayer from '../../../../components/new/custom/VideoPlayer';
import LightThemeWrapper from '../../wrapperComp/LightThemeWrapper';

const VideoSlider = ({ data, selIndex, selectedIndex, bgColor = '' }) => {
  const { width } = useWindowDimensions();
  const [play, setPlay] = useState(false);
  const [activeDot, setActiveDot] = useState(0);

  useEffect(() => {
    if (selectedIndex === selIndex) {
      setPlay(true);
    } else {
      setPlay(false);
    }
  }, [selectedIndex]);

  const RenderItem = ({ item, index }) => {
    return (
      <View style={{ flex: 1 }} ml="2xl" bg="opacity.50">
        <LightThemeWrapper>
          <VideoPlayer
            id={`Video_id${item?._id}`}
            url={item?.mediaURL}
            aspectRatio={1}
            play={play && (activeDot === index || activeDot + 1 === index)}
            setPlay={setPlay}
            hideControls
            showPlayPause
            isLooping
            // videoBg={colors.backdrop['500']}
            width={width - 72}
            height={width - 72}
          />
        </LightThemeWrapper>
        <Text
          size="md"
          color="primary.500"
          weight="medium"
          mr="3xl+9xl"
          numberOfLines={2}
          style={{ position: 'absolute', left: 16, bottom: 16 }}
        >
          {item?.title}
        </Text>
      </View>
    );
  };

  const baseOptions = {
    vertical: false,
    width: width - 56,
    height: width - 72,
  } as const;

  return (
    <View bg={bgColor}>
      <View alignItems="center" py="9xl+9xl" px="2xl">
        {data?.heading ? (
          <Text size="xs" color="primary.300" pb="lg" textAlign="center">
            {data?.heading}
          </Text>
        ) : null}
        {data?.subHeading ? (
          <Text size="2xl" color="primary.500" textAlign="center">
            {data?.subHeading}
          </Text>
        ) : null}
        {data?.data?.length ? (
          <View mt="7xl">
            <Carousel
              {...baseOptions}
              loop={false}
              style={{ width }}
              data={data?.data}
              renderItem={RenderItem}
              autoPlay={false}
              onSnapToItem={(index) => {
                setActiveDot(index);
              }}
            />
          </View>
        ) : null}
      </View>
    </View>
  );
};

export default VideoSlider;
