/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useRef, useState } from 'react';
import {
  StyleSheet,
  useWindowDimensions,
  Switch,
  Platform,
} from 'react-native';

import { Gyroscope } from 'expo-sensors';
import Reanimated, {
  useAnimatedProps,
  useSharedValue,
} from 'react-native-reanimated';
import { View, Icon, Pressable, Text } from '../../components/new';
import { colors, space } from '../../themes/new/theme';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { convertToTime } from '../../utilities/Utility';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

const camType =
  Platform.OS === 'web'
    ? require('expo-camera')
    : require('react-native-vision-camera');

const { Camera, useCameraDevice, CameraProps } = camType;

const ReanimatedCamera = Reanimated.createAnimatedComponent(Camera);

const VideoCamera = ({
  setVideoUriCallback,
  motionSensor,
  setMotionSensor,
}) => {
  const camera = useRef<Camera>(null);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [started, setIsStarted] = useState(false);
  const [recording, setRecording] = useState(false);
  const [controlDotPos, setControlDotPos] = useState({ x: 0 });
  const { width, height } = useWindowDimensions();
  const [isEnabled, setIsEnabled] = useState(motionSensor);
  const [flash, setFlash] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [countDownVal, setCountDownVal] = useState(0);
  const [timerVal, setTimerVal] = useState(0);
  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  const device = useCameraDevice('back', {
    physicalDevices: [
      'ultra-wide-angle-camera',
      'wide-angle-camera',
      'telephoto-camera',
    ],
  });
  const zoom = useSharedValue(0);

  const animatedProps = useAnimatedProps<Partial<CameraProps>>(
    () => ({ zoom: zoom.value }),
    [zoom],
  );

  const recordVideo = async () => {
    setIsStarted(!started);
    if (!recording) {
      setRecording(true);
      camera.current.startRecording({
        flash: flash ? 'on' : 'off',
        videoCodec: 'h265',
        onRecordingFinished: (video) => (
          // eslint-disable-next-line no-sequences
          console.log(video), setVideoUriCallback(video)
        ),
        onRecordingError: (error) => console.error(error),
      });
    } else {
      setRecording(false);
      await camera.current.stopRecording();
    }
  };

  const startCountDown = () => {
    firebaseEventLogger('videoMoments__captureVideo_Tap', {
      buttonName: 'captureVideo',
      screenName: 'videoMoments',
      userType: 'VideoSupervisor',
      interactionType: 'tap',
    });
    if (recording) {
      recordVideo();
    } else {
      setCountDownVal(2);
      const interval = setInterval(() => {
        setCountDownVal((val) => {
          if (val === 1) {
            clearInterval(interval);
            recordVideo();
          }
          return val - 1;
        });
      }, 1000);
    }
  };

  useEffect(() => {
    startTimer();
  }, [recording]);

  useEffect(() => {
    if (timerVal === 15) {
      camera.current.stopRecording();
      setRecording(false);
    }
  }, [timerVal]);

  const startTimer = () => {
    let interval = null;
    if (timerVal === 15 || !recording) {
      clearInterval(interval);
      setTimerVal(0);
    } else {
      setTimerVal(0);
      interval = setInterval(() => {
        setTimerVal((val) => {
          return val + 1;
        });
      }, 1000);
    }
  };

  const setToDefault = () => {
    _unsubscribe();
    setControlDotPos({ x: 0 });
    global.prevVal = 0;
  };

  useEffect(() => {
    global.prevVal = 0;
    firebaseEventLogger('videoMoments__switchMotionSensor_Tap', {
      buttonName: 'switchMotionSensor',
      screenName: 'videoMoments',
      userType: 'VideoSupervisor',
      interactionType: 'misc',
      status: isEnabled,
    });
    if (isEnabled) {
      Gyroscope.setUpdateInterval(1000);
      _subscribe();
      setMotionSensor(true);
      dispatchSnackbar({
        msg: 'Motion sensor activated!',
        status: SnackbarStatus.success,
        version: SnackbarVersion.v1,
      });
    } else {
      setMotionSensor(false);
      setToDefault();
    }
    return () => setToDefault();
  }, [isEnabled]);

  useEffect(() => {
    setTimeout(() => {
      global.prevVal = controlDotPos.x;
    }, 1000);
    const prevValue = global.prevVal.toFixed(0);
    const currentVal = controlDotPos.x.toFixed(0);
    const diff =
      prevValue - currentVal < 0
        ? prevValue - currentVal * -1
        : prevValue - currentVal;
    if (
      prevValue !== currentVal &&
      diff > 2 &&
      !recording &&
      countDownVal === 0
    ) {
      startCountDown(); // started
    } else if (prevValue === currentVal && recording) {
      setRecording(false);
      camera.current.stopRecording(); // stop
    } else {
      // console.log('no change state----->>>');
    }
  }, [controlDotPos]);

  const _subscribe = () => {
    setSubscription(
      Gyroscope.addListener((gyroscopeData) => {
        setControlDotPos((prevPosition) => ({
          x: prevPosition.x - gyroscopeData.y * 4,
        }));
      }),
    );
  };

  const _unsubscribe = () => {
    if (subscription) {
      subscription.remove();
    }
    setSubscription(null);
  };

  return (
    <View flex={1} top={Platform.OS === 'ios' ? 0 : space['5xl']}>
      <ReanimatedCamera
        ref={camera}
        style={[StyleSheet.absoluteFill, { position: 'absolute', flex: 1 }]}
        device={device}
        video
        audio
        isActive
        animatedProps={animatedProps}
        fps={30}
      />
      <Pressable
        onPress={() =>
          motionSensor && !recording
            ? dispatchSnackbar({
                msg: 'Please disable motion sensor to activate manual support!',
                status: SnackbarStatus.info,
                version: SnackbarVersion.v1,
              })
            : startCountDown()
        }
        disabled={countDownVal > 0}
        style={{
          flex: 1,
          position: 'absolute',
          bottom: space['9xl'],
          alignSelf: 'center',
        }}
      >
        <View
          height={space['8xl+8xl']}
          width={space['8xl+8xl']}
          borderRadius="3xl"
          borderWidth="md"
          borderColor={started ? 'primary.100' : 'primary.10'}
          justifyContent="center"
          alignItems="center"
        >
          <View
            height={started ? space['6xl'] : space['5xl+6xl']}
            width={started ? space['6xl'] : space['5xl+6xl']}
            borderRadius={started ? 0 : '3xl'}
            bg={
              started || countDownVal > 0 || (motionSensor && !recording)
                ? 'primary.100'
                : 'error.500'
            }
            borderWidth="sm"
            borderColor="transparent"
          >
            <></>
          </View>
        </View>
      </Pressable>
      {recording ? null : (
        <View
          flex={1}
          position="absolute"
          bottom={space['5xl+6xl']}
          alignSelf="center"
          right={space['3xl']}
        >
          <Switch
            trackColor={{
              false: colors.primary['20'],
              true: colors.primary['10'],
            }}
            thumbColor={
              isEnabled ? colors.primary['200'] : colors.primary['100']
            }
            ios_backgroundColor={colors.primary['600']}
            onValueChange={toggleSwitch}
            value={isEnabled}
          />
        </View>
      )}
      {recording ? null : (
        <Pressable
          onPress={() => setFlash(!flash)}
          style={{
            flex: 1,
            position: 'absolute',
            bottom: space['9xl'],
            alignSelf: 'center',
            left: space.xl,
          }}
        >
          <View
            height={space['5xl+6xl']}
            width={space['5xl+6xl']}
            borderRadius="3xl"
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              size="9xl"
              color="primary.10"
              name={flash ? 'flash-on-300' : 'flash-off-300'}
            />
          </View>
        </Pressable>
      )}
      {countDownVal > 0 ? (
        <Text
          flex={1}
          mt={height / 2 - 100}
          alignSelf="center"
          style={{ fontSize: 100 }}
          color="primary.10"
        >
          {countDownVal}
        </Text>
      ) : null}
      <View
        position="absolute"
        flex={1}
        top={space['lg+9xl']}
        alignSelf="center"
      >
        <Text size="2xl" color="primary.50">
          {convertToTime(timerVal)}
        </Text>
      </View>
    </View>
  );
};
export default VideoCamera;
