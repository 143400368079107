import { Text, View } from '../../../components/new';
import { PaymentStatusEnum } from '../../../utilities/helper';

const PaymentTag = ({ status }) => {
  let bg = '';
  let textColor = '';
  let name = '';
  let borderColor = '';
  if (status === PaymentStatusEnum.SUCCESSFUL) {
    bg = 'success.10';
    textColor = 'success.500';
    borderColor = 'success.500';
    name = 'Paid';
  } else if (status === PaymentStatusEnum.FAILED) {
    bg = 'error.10';
    textColor = 'error.500';
    borderColor = 'error.500';
    name = 'Failed';
  } else if (status === PaymentStatusEnum.PENDING) {
    bg = 'warning.10';
    textColor = 'warning.500';
    borderColor = 'warning.500';
    name = 'Pending';
  } else if (status === PaymentStatusEnum.REFUNDED) {
    bg = 'primary.50';
    textColor = 'primary.200';
    borderColor = 'primary.200';
    name = 'Refunded';
  }

  return (
    <View flexDirection="row">
      <View
        bg="transparent"
        px="lg"
        py="xs"
        borderRadius="xl"
        borderWidth="xs"
        borderColor={borderColor}
      >
        <Text size="2xs" color={textColor}>
          {name}
        </Text>
      </View>
    </View>
  );
};

export default PaymentTag;
