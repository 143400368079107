/* eslint-disable import/prefer-default-export */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';

import { create } from 'zustand';
import slotMachineSlice, {
  slotMachineSliceReturnType,
} from './slotMachineSlice';

export const useSlotMachineStore = create<slotMachineSliceReturnType>()(
  devtools(
    persist(
      (...a) => ({
        ...slotMachineSlice(...a),
      }),
      {
        name: 'games-slot-machine-storage',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
  ),
);
