import { graphql } from 'react-relay';

export const eventConfirmCancellationScreenQuery = graphql`
  query eventConfirmCancellationScreenQuery(
    $invoiceFilter: findInvoiceInput!
    $ticketFilter: FilterFindManyticketInput!
  ) {
    ...eventConfirmCancellationScreenQueryInvoiceFragment
      @arguments(filter: $invoiceFilter)
    ...eventConfirmCancellationScreenQueryTicketFragment
      @arguments(filter: $ticketFilter, count: 1)
  }
`;

export const eventConfirmCancellationInvoiceFragment = graphql`
  fragment eventConfirmCancellationScreenQueryInvoiceFragment on Query
  @refetchable(queryName: "EventConfirmCancellationScreenInvoiceQuery")
  @argumentDefinitions(
    filter: { type: "findInvoiceInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findInvoiceV2(filter: $filter, first: $count, after: $cursor)
      @connection(
        key: "EventConfirmCancellationScreenInvoiceQueryFragment__findInvoiceV2"
      ) {
      edges {
        node {
          _id
          orderId
          priceDetails {
            grossAmount
            rewardsUsed
            netAmount
          }
        }
      }
    }
  }
`;

export const eventConfirmCancellationTicketQueryFragment = graphql`
  fragment eventConfirmCancellationScreenQueryTicketFragment on Query
  @refetchable(queryName: "EventConfirmCancellationScreenTicketQuery")
  @argumentDefinitions(
    filter: { type: "FilterFindManyticketInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    tickets: findTickets(filter: $filter, first: $count, after: $cursor)
      @connection(
        key: "EventConfirmCancellationScreenTicketQueryFragment__tickets"
      ) {
      edges {
        node {
          _id
          additionalFields {
            event {
              startTime
            }
          }
        }
      }
    }
  }
`;
