import React, { useState } from 'react';
import Slider from '@react-native-community/slider';
import { Platform } from 'react-native';
import { View, Text, Icon, Pressable } from '../../../components/new';
import { arrayRange } from '../../../utilities/helper';
import { getTheme } from '../../../themes/new/theme';
import { FontWeights } from '../../../themes/new/helper';

interface SliderSectionProps {
  title: string;
  sliderMinValue: number;
  sliderMaxValue: number;
  sliderStep: number;
  showError: boolean;
  errorMsg: string;
  defaultMinValue?: number;
  showInfo?: boolean;
  onPressInfo?: () => void;
  sliderStepToShow: number;
  onSlidingComplete: (val: number) => void;
}

const SliderSection = ({
  title = '',
  sliderMinValue = 0,
  sliderMaxValue = 10,
  sliderStep = 1,
  showError = false,
  errorMsg = '',
  defaultMinValue = 0,
  showInfo = false,
  onPressInfo = () => {},
  sliderStepToShow = 10,
  onSlidingComplete = (val) => {},
}: SliderSectionProps) => {
  const [value, setValue] = useState(defaultMinValue || sliderMinValue);

  const sliderData = arrayRange(
    sliderMinValue,
    sliderMaxValue,
    sliderStepToShow,
  );

  const sliderProps =
    Platform.OS === 'web'
      ? { thumbTintColor: getTheme().colors.primary[10] }
      : {
          thumbImage: require('../../../../assets/images/ellipse.png'),
        };

  return (
    <View>
      <View flexDirection="row" alignItems="center">
        <Text size="md" color="primary.300">
          {title}
        </Text>
        {showInfo ? (
          <Pressable
            style={{ marginLeft: getTheme().space.lg }}
            onPress={onPressInfo}
          >
            <Icon name="info-outline-300" size="2xl" color="primary.500" />
          </Pressable>
        ) : (
          <></>
        )}
      </View>
      <View mt="2xl">
        <View mb="lg">
          <Text
            size="sm"
            color={value > 0 ? 'success.500' : 'grey.300'}
            textAlign="center"
            weight={FontWeights.MEDIUM}
          >
            {value}%
          </Text>
        </View>
        <Slider
          style={{
            width: '100%',
            height: 24,
          }}
          minimumValue={sliderMinValue}
          maximumValue={sliderMaxValue}
          onValueChange={(val) => setValue(val)}
          tapToSeek
          minimumTrackTintColor={getTheme().colors.success[500]}
          maximumTrackTintColor={getTheme().colors.primary[50]}
          // minimumTrackImage={require('../../../../assets/images/sliderSelected.png')}
          // maximumTrackImage={require('../../../../assets/images/sliderDefault.png')}
          {...sliderProps}
          // thumbTintColor={getTheme().colors.primary[10]}
          // thumbStyle={{width:24, height:24}}
          step={sliderStep}
          value={value}
          onSlidingComplete={(finalValue) => {
            onSlidingComplete(finalValue);
          }}
        />
        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          px="2xl"
          mt="sm"
        >
          {sliderData.map((item, index) => (
            <Text
              size="sm"
              color={item === value ? 'success.500' : 'grey.300'}
              ml={index !== 0 ? 'sm' : 0}
              weight={item === value ? FontWeights.MEDIUM : FontWeights.REGULAR}
            >
              {item}%
            </Text>
          ))}
        </View>
        {showError ? (
          <Text mt="2xl" size="xs" color="error.500">
            {errorMsg}
          </Text>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

export default SliderSection;
