import * as React from 'react';
import { Image } from 'react-native';
import { Button, Modal, Text, View } from '../../components/new';

const AssignValetModal = ({
  title,
  subTitle,
  modalVisible,
  setModalVisible,
  firstCTAText,
  secondCTAText,
  firstCTATextHandler,
  secondCTATextHandler,
  img,
}) => {
  return (
    <Modal
      bg="blur"
      visible={modalVisible}
      onBackdropPress={() => {
        setModalVisible({ state: false, status: '', primaryCB: () => {} });
      }}
      onRequestClose={() => {
        setModalVisible({ state: false, status: '', primaryCB: () => {} });
      }}
    >
      <View width="100%">
        <View
          bg="background.primary.base"
          py="4xl"
          px="7xl"
          borderRadius="lg"
          mx="2xl"
        >
          <View alignItems="center" mb="4xl">
            {!!img && <Image style={{ width: 60, height: 60 }} source={img} />}
          </View>
          <Text size="xl" weight="medium" textAlign="center">
            {title}
          </Text>
          {subTitle ? (
            <Text
              mt={title && title?.length ? 'lg' : 0}
              size={title && title?.length ? 'sm' : 'xl'}
              textAlign="center"
              color="grey.400"
            >
              {subTitle}
            </Text>
          ) : null}
          <Button
            appearance="filled"
            status="primary"
            onPress={firstCTATextHandler}
            size="xl"
            mt="4xl"
          >
            {firstCTAText}
          </Button>
          {secondCTAText ? (
            <Button
              appearance="ghost"
              status="info"
              onPress={secondCTATextHandler}
              size="xl"
              mt="xl"
            >
              {secondCTAText}
            </Button>
          ) : null}
        </View>
      </View>
    </Modal>
  );
};

export default AssignValetModal;
