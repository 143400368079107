/**
 * @generated SignedSource<<bf2b5ef3dfca96b38fbf6501af129750>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnumcouponCouponBenefitType = "FLAT_CASHBACK" | "FLAT_DISCOUNT" | "PERCENTAGE_CASHBACK" | "PERCENTAGE_OFF" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CouponsComponentQueryFindIssuedCouponsFragment$data = {
  readonly findIssuedCoupons: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly briefText: string | null;
        readonly couponBenefit: {
          readonly type: EnumcouponCouponBenefitType;
          readonly value: number;
        };
        readonly couponCode: string;
        readonly couponExpiryDate: any;
        readonly created_at: any | null;
        readonly finePrint: {
          readonly details: ReadonlyArray<string | null> | null;
          readonly summary: string | null;
        } | null;
        readonly maxDiscountAmount: number | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "CouponsComponentQueryFindIssuedCouponsFragment";
};
export type CouponsComponentQueryFindIssuedCouponsFragment$key = {
  readonly " $data"?: CouponsComponentQueryFindIssuedCouponsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CouponsComponentQueryFindIssuedCouponsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "findIssuedCoupons"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CouponsComponentFindIssuedCouponsFragmentQuery.graphql')
    }
  },
  "name": "CouponsComponentQueryFindIssuedCouponsFragment",
  "selections": [
    {
      "alias": "findIssuedCoupons",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "couponIssuanceConnection",
      "kind": "LinkedField",
      "name": "__CouponsComponentFindCouponsFragment__findIssuedCoupons_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "couponIssuanceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "couponIssuance",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "couponCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "briefText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TicketModelTCwoUserFinePrint",
                  "kind": "LinkedField",
                  "name": "finePrint",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "details",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "summary",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "couponCouponBenefit",
                  "kind": "LinkedField",
                  "name": "couponBenefit",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "couponExpiryDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxDiscountAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f463a2557ddd415020f0ae9a07d4652c";

export default node;
