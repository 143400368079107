import { graphql, commitMutation } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const updateBill = (data, callback, handleErrrCB) => {
  const mutation = graphql`
    mutation billUploadReviewerApiUpdateBillMutation(
      $id: MongoID!
      $record: updateBillInput!
    ) {
      updateBill(_id: $id, record: $record) {
        record {
          userId
        }
      }
    }
  `;
  const variables = {
    id: data._id,
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      handleErrrCB(err);
      // console.log('error', err.res.errors[0].message);
    },
  });
};
