/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FlatList } from 'react-native';
import { ChipGroup, Text, View } from '../../../components/new';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { getTheme } from '../../../themes/new/theme';
import TrackDiscountCard from './TrackDiscountCard';
import { useTrackDiscount } from './useTrackDiscount';

const DATA = [
  { id: 0, name: 'Active', disabled: false, active: true },
  {
    id: 1,
    name: 'Inactive',
    disabled: false,
    active: false,
  },
];

const TrackScreenInner = ({
  queryOptions,
  refresh,
}: {
  queryOptions: NewErrorBoundaryParentState;
  refresh: () => void;
}) => {
  const { getRestaurantPartnerDiscountTrack } = useTrackDiscount(
    queryOptions,
    refresh,
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [discountsData, setDiscountsData] = useState(
    getRestaurantPartnerDiscountTrack.filter(
      (item) => item?.discount?.isActive === true,
    ),
  );

  const handleFilter = (item) => {
    const newData = getRestaurantPartnerDiscountTrack?.filter(
      (ele) => ele?.discount?.isActive === item?.active,
    );
    setDiscountsData(newData);
  };

  return (
    <View flex={1}>
      <View mt="3xl">
        <ChipGroup
          data={DATA}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          callbackFn={(item, index) => {
            // console.log('ChipGroup', item, index)
            handleFilter(item);
          }}
        />
      </View>
      <FlatList
        contentContainerStyle={{
          paddingHorizontal: getTheme().space['2xl'],
          paddingBottom: getTheme().space['4xl'],
        }}
        data={discountsData}
        renderItem={({ item, index }) =>
          item?._id && (
            <View mt="2xl">
              <TrackDiscountCard
                active={item?.discount?.isActive}
                discount={item?.discount?.discounts?.allUser}
                discountForCorporate={item?.discount?.discounts?.corporateUser}
                discountForNewUsers={item?.discount?.discounts?.newUser}
                discountPercent={item?.totalDiscountPercentage}
                discountsGiven={item?.totalDiscount}
                grossSales={item?.totalNetAmount}
                totalOrders={item?.totalSales}
              />
            </View>
          )
        }
        ListEmptyComponent={() => (
          <View mt="9xl" flex={1} justifyContent="center" alignItems="center">
            <Text size="md" color="primary.500">
              No Data Found!
            </Text>
          </View>
        )}
      />
    </View>
  );
};

export default TrackScreenInner;
