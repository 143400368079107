import { graphql, fetchQuery } from 'react-relay';
import { paymentOrderTypeEnum } from '../../../../../utilities/helper';
import environment from '../../../../../relay/relayEnvironment';

export const findAddonsQuery = graphql`
  query findAddonsQuery($filter: FilterFindManyaddonInput) {
    findAddons(filter: $filter) {
      ...findAddonsQueryfragment
    }
  }
`;

export const addonFragment = graphql`
  fragment findAddonsQueryfragment on addon @relay(plural: true) {
    _id
    type
    additionalDiscount {
      refType
      discount {
        type
        value
      }
    }
    additionalCashback {
      refType
      cashback {
        type
        value
      }
    }
  }
`;

export const fetchAddons = (variables) => {
  return fetchQuery(environment, findAddonsQuery, variables, {
    fetchPolicy: 'store-or-network',
  }).toPromise();
};

export const getDiscountAndCashbackPercentage = (findAddon) => {
  let discountPerc = 0;
  let cashbackPerc = 0;
  if (findAddon?.length) {
    const parkingDiscount = findAddon[0]?.additionalDiscount?.find(
      (d) => d?.refType === paymentOrderTypeEnum.PARKING,
    );
    const parkingCashback = findAddon[0]?.additionalCashback?.find(
      (d) => d?.refType === paymentOrderTypeEnum.PARKING,
    );
    if (parkingDiscount && parkingDiscount?.discount?.type === 'PERCENTAGE') {
      discountPerc = parkingDiscount?.discount?.value;
    }
    if (parkingCashback && parkingCashback?.cashback?.type === 'PERCENTAGE') {
      cashbackPerc = parkingCashback?.cashback?.value;
    }
  }

  return { discountPerc, cashbackPerc };
};
