import React, { useEffect, useRef, useState } from 'react';
import {
  Keyboard,
  Platform,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
// import {
//   checkIfHasSMSPermission,
//   requestReadSMSPermission,
//   startReadSMS,
// } from '@maniac-tech/react-native-expo-read-sms';
import { Text, View } from '../../index';
import { colors } from '../../../../themes/new/theme';

function extractOTP(input: string): string | null {
  const otpRegex = /:\d{4}\b/;
  const match = input.match(otpRegex);
  return match ? match[0].slice(1) : null; // Removing the colon from the matched string
}

export interface NewOtpInputProps {
  label: string;
  length: number;
  code: string;
  setCode: (otp) => void;
  setIsPinReady: (ready: boolean) => void;
  otpError: string;
  setOtpError: (error: string) => void;
}

const NewOtpInput = (props: NewOtpInputProps) => {
  const { label, length, code, setCode, setIsPinReady, otpError, setOtpError } =
    props;

  const inputRef = useRef(null);

  const boxArray = new Array(length).fill(0);
  const [isInputBoxFocused, setIsInputBoxFocused] = useState(true);

  const { height, width } = useWindowDimensions();

  const handleOnPress = () => {
    setIsInputBoxFocused(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Platform.OS === 'web') {
      inputRef?.current?.focus();
    } else {
      setTimeout(() => inputRef?.current?.focus(), 200);
    }
  };

  const handleOnBlur = () => {
    setIsInputBoxFocused(false);
  };

  useEffect(() => {
    // update pin ready status
    if (code.length === length) {
      setIsPinReady(true);
      Keyboard.dismiss();
    } else {
      setIsPinReady(false);
    }
    // clean up function
    return () => {
      setIsPinReady(false);
    };
  }, [code]);

  const handleSetCode = (e) => {
    setCode(e);
    setOtpError('');
  };

  // useEffect(() => {
  // if (hasSmsPermission) {
  //   startReadSMS((res, message, err) => {
  //     const otp = extractOTP(message);
  //     if (otp) {
  //       handleSetCode(otp);
  //     }
  //   })
  //     .then((res) => {})
  //     .catch((err) => {});
  // } else if (otpAutoDetect && Platform.OS === 'android') {
  //   const smsReadFlow = async () => {
  //     const permission = await checkIfHasSMSPermission();
  //     if (permission !== true) {
  //       const result = await requestReadSMSPermission();
  //       setHasSmsPermission(true);
  //     } else {
  //       setHasSmsPermission(true);
  //     }
  //   };
  //   smsReadFlow();
  // }
  // }, [hasSmsPermission]);

  const boxDigit = (_, index) => {
    const emptyInput = '';
    const digit = code[index] || emptyInput;

    const isCurrentValue = index === code.length;
    const isLastValue = index === length - 1;
    const isCodeComplete = code.length === length;

    const isValueFocused =
      isInputBoxFocused && (isCurrentValue || (isLastValue && isCodeComplete));

    if (digit) {
      return (
        <TouchableOpacity
          activeOpacity={1}
          key={index}
          onPress={handleOnPress}
          style={[
            !isValueFocused ? styles.otpBox : styles.otpBoxFocused,
            !isValueFocused ? { borderColor: colors.primary[200] } : {},
          ]}
        >
          <Text size="2xl" color="primary.500" style={styles.otpText}>
            {digit}
          </Text>
        </TouchableOpacity>
      );
    }
    return (
      <TouchableOpacity
        activeOpacity={1}
        key={index}
        onPress={handleOnPress}
        style={[
          !isValueFocused ? styles.otpBox : styles.otpBoxFocused,
          { borderColor: colors.primary[200] },
        ]}
      >
        {isValueFocused ? (
          <View
            borderColor="primary.200"
            bg="primary.10"
            style={{
              borderWidth: 1,
              height: 24,
              marginBottom: 8,
            }}
          />
        ) : null}
      </TouchableOpacity>
    );
  };

  return (
    <View>
      <Text size="sm" color="primary.200">
        {label}
      </Text>
      <TextInput
        autoFocus={Platform.OS !== 'web'}
        value={code}
        onChangeText={handleSetCode}
        maxLength={length}
        ref={inputRef}
        keyboardType="number-pad"
        onFocus={() => {
          setIsInputBoxFocused(true);
        }}
        onBlur={handleOnBlur}
        style={
          Platform.OS !== 'web'
            ? {
                position: 'absolute',
                right: 0,
                opacity: 0,
                backgroundColor: colors.primary[10],
                height: 80,
                width: width - 32,
                zIndex: 2,
              }
            : {
                position: 'absolute',
                top: 40,
                left: 20,
                opacity: 0.1,
                backgroundColor: colors.primary[10],
                height: 1,
                width: 1,
              }
        }
      />
      <View style={styles.otpBoxWrapper}>{boxArray.map(boxDigit)}</View>
      {!!otpError && (
        <Text size="xs" mt="2" color="error.500">
          {otpError}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  otpBoxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  otpBox: {
    height: 48,
    width: 48,
    borderBottomWidth: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  otpBoxFocused: {
    height: 48,
    width: 48,
    borderWidth: 1,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  otpText: {
    marginBottom: 4,
  },
});

export default NewOtpInput;
