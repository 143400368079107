/**
 * @generated SignedSource<<3896d30782a8a9d505aac9205f32ec40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumUserContactEmailType = "PERSONAL" | "WORK" | "%future added value";
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumUserRolePermission = "staff" | "%future added value";
export type EnumUserRoleSecondaryRole = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type addValetRecordInput = {
  contact: UserContactInput;
  identityCode: string;
  name: UserNameInput;
  role: UserRoleInput;
};
export type UserNameInput = {
  _id?: any | null;
  first: string;
  last?: string | null;
};
export type UserContactInput = {
  _id?: any | null;
  email?: ReadonlyArray<UserContactEmailInput | null> | null;
  phone: UserContactPhoneInput;
};
export type UserContactPhoneInput = {
  _id?: any | null;
  number: string;
  prefix: string;
};
export type UserContactEmailInput = {
  _id?: any | null;
  addr?: string | null;
  type?: EnumUserContactEmailType | null;
};
export type UserRoleInput = {
  _id?: any | null;
  meta: UserRoleMetaInput;
  name: EnumUserRoleName;
  permission?: EnumUserRolePermission | null;
  secondaryRole?: ReadonlyArray<EnumUserRoleSecondaryRole | null> | null;
};
export type UserRoleMetaInput = {
  _id?: any | null;
  campus: UserRoleMetaCampusInput;
};
export type UserRoleMetaCampusInput = {
  _id: any;
  name?: string | null;
};
export type userApiAddValetMutation$variables = {
  record: addValetRecordInput;
};
export type userApiAddValetMutation$data = {
  readonly addValet: {
    readonly record: {
      readonly _id: any;
      readonly role: {
        readonly name: EnumUserRoleName | null;
      } | null;
    } | null;
  } | null;
};
export type userApiAddValetMutation = {
  response: userApiAddValetMutation$data;
  variables: userApiAddValetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOneValetPayload",
    "kind": "LinkedField",
    "name": "addValet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Valet",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userApiAddValetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userApiAddValetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bede396119cd15cbcd214293c12f93f2",
    "id": null,
    "metadata": {},
    "name": "userApiAddValetMutation",
    "operationKind": "mutation",
    "text": "mutation userApiAddValetMutation(\n  $record: addValetRecordInput!\n) {\n  addValet(record: $record) {\n    record {\n      _id\n      role {\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c9ebb24865b1ec96a1f6361f841c8a1";

export default node;
