/* eslint-disable import/prefer-default-export */
import { taskHistoryActionTypes } from '../../../../../utilities/helper';

const contains = (data, c) => {
  const output = data.find((item) => item.action === c);
  return Boolean(output);
};

const sortByRank = (tasks) => {
  tasks.sort((prev, curr) => {
    return prev.rank - curr.rank;
  });
  return tasks;
};

const giveRanks = (task) => {
  const tempTask = JSON.parse(JSON.stringify(task));
  // =======
  const onboardedNotAccepted = [];
  const acceptedNotOnboarded = [];
  const acceptedAndOnboarded = [];
  // =======
  tempTask.forEach((item) => {
    const { history } = item;
    const containsOnboarded = contains(
      history,
      taskHistoryActionTypes.onboarded,
    );
    const containsAccepted = contains(history, taskHistoryActionTypes.accepted);

    if (containsOnboarded && !containsAccepted) {
      item.rank = 0;
      onboardedNotAccepted.push(item);
    }
    if (!containsOnboarded && containsAccepted) {
      item.rank = 1;
      acceptedNotOnboarded.push(item);
    }
    if (containsOnboarded && containsAccepted) {
      item.rank = 2;
      acceptedAndOnboarded.push(item);
    }

    // ========

    onboardedNotAccepted.sort((itemOne, itemTwo) => {
      const acceptedTaskOne = itemOne?.history?.find(
        (i) => i?.action === taskHistoryActionTypes.created,
      );
      const acceptedTaskTwo = itemTwo?.history?.find(
        (i) => i?.action === taskHistoryActionTypes.created,
      );

      const pItemDate = new Date(acceptedTaskOne?.event_at);
      const cItemDate = new Date(acceptedTaskTwo?.event_at);
      return pItemDate.getTime() - cItemDate.getTime();
    });
  });

  acceptedNotOnboarded.sort((itemOne, itemTwo) => {
    const acceptedTaskOne = itemOne?.history?.find(
      (i) => i?.action === taskHistoryActionTypes.accepted,
    );
    const acceptedTaskTwo = itemTwo?.history?.find(
      (i) => i?.action === taskHistoryActionTypes.accepted,
    );

    const pItemDate = new Date(acceptedTaskOne?.event_at);
    const cItemDate = new Date(acceptedTaskTwo?.event_at);
    return pItemDate.getTime() - cItemDate.getTime();
  });

  acceptedAndOnboarded.sort((itemOne, itemTwo) => {
    const acceptedTaskOne = itemOne?.history?.find(
      (i) => i?.action === taskHistoryActionTypes.created,
    );
    const acceptedTaskTwo = itemTwo?.history?.find(
      (i) => i?.action === taskHistoryActionTypes.created,
    );

    const pItemDate = new Date(acceptedTaskOne?.event_at);
    const cItemDate = new Date(acceptedTaskTwo?.event_at);
    return pItemDate.getTime() - cItemDate.getTime();
  });

  return [
    ...onboardedNotAccepted,
    ...acceptedNotOnboarded,
    ...acceptedAndOnboarded,
  ];
};

export const giveRankAndSort = (tasks) => {
  const rankedTasks = giveRanks(tasks);
  return sortByRank(rankedTasks);
};
