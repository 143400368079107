/**
 * @generated SignedSource<<23602668b7f4aa3eed34b75448ce7ef6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type EnumParkingParkingInvoicePaymentDetailsFocReason = "CUSTOMER_ISSUE" | "EMPLOYEE_EXIT" | "FREE_PARKING_TICKET" | "MISSED_EXIT" | "OTHER" | "VALET_EXIT" | "VIP_EXIT" | "%future added value";
export type EnumParkingParkingInvoicePaymentDetailsMode = "APP" | "CASH" | "FASTAG" | "FOC" | "ONLINE" | "%future added value";
export type EnumParkingStatus = "FAULTY_PARK" | "FAULTY_RECALL" | "INCOMING" | "MISSED_EXIT" | "OUT" | "OUTGOING" | "PARKED" | "%future added value";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type cashierTaskUpdatedSubscriptionApiSubscription$variables = {
  campusId: string;
  event_at?: any | null;
  history?: ReadonlyArray<EnumtaskHistoryAction | null> | null;
  taskType?: ReadonlyArray<EnumtaskTaskType | null> | null;
  userId: string;
};
export type cashierTaskUpdatedSubscriptionApiSubscription$data = {
  readonly taskUpdated: {
    readonly ETA: {
      readonly _id: any | null;
      readonly available: number | null;
      readonly busy: number | null;
      readonly delayAt: any | null;
      readonly originalPredictionTime: number | null;
      readonly pendingRecall: number | null;
      readonly startAt: any | null;
    } | null;
    readonly _id: any;
    readonly campus: {
      readonly _id: any;
    } | null;
    readonly history: ReadonlyArray<{
      readonly _id: any | null;
      readonly action: EnumtaskHistoryAction;
      readonly actor: any | null;
      readonly event_at: any | null;
    } | null>;
    readonly isVehicleWhitelisted: boolean | null;
    readonly parking: {
      readonly _id: any;
      readonly parkingInvoice: {
        readonly charges: {
          readonly chargesBreakup: ReadonlyArray<{
            readonly duration: number | null;
            readonly payable: number | null;
          } | null> | null;
          readonly discountAmount: number | null;
          readonly totalPayable: number | null;
        } | null;
        readonly paymentDetails: {
          readonly amount: number | null;
          readonly focReason: EnumParkingParkingInvoicePaymentDetailsFocReason | null;
          readonly isPaid: boolean;
          readonly mode: EnumParkingParkingInvoicePaymentDetailsMode | null;
        } | null;
        readonly timings: {
          readonly chargeTime: number | null;
          readonly entryTime: any;
          readonly exitTime: any | null;
        } | null;
      } | null;
      readonly parkingTag: string | null;
      readonly status: EnumParkingStatus;
    } | null;
    readonly parkingId: any | null;
    readonly taskType: EnumtaskTaskType;
    readonly vehicle: {
      readonly registration: {
        readonly plate: string;
      } | null;
    } | null;
  } | null;
};
export type cashierTaskUpdatedSubscriptionApiSubscription = {
  response: cashierTaskUpdatedSubscriptionApiSubscription$data;
  variables: cashierTaskUpdatedSubscriptionApiSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campusId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "event_at"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "history"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "campusId",
        "variableName": "campusId"
      },
      {
        "kind": "Variable",
        "name": "event_at",
        "variableName": "event_at"
      },
      {
        "kind": "Variable",
        "name": "history",
        "variableName": "history"
      },
      {
        "kind": "Variable",
        "name": "taskType",
        "variableName": "taskType"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "taskUpdated",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "taskVehicle",
        "kind": "LinkedField",
        "name": "vehicle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleRegistration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVehicleWhitelisted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "campusLocation",
        "kind": "LinkedField",
        "name": "campus",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskHistory",
        "kind": "LinkedField",
        "name": "history",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "event_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Parking",
        "kind": "LinkedField",
        "name": "parking",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingTag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ParkingParkingInvoice",
            "kind": "LinkedField",
            "name": "parkingInvoice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingInvoiceTimings",
                "kind": "LinkedField",
                "name": "timings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "entryTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "exitTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "chargeTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingInvoicePaymentDetails",
                "kind": "LinkedField",
                "name": "paymentDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPaid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "focReason",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingInvoiceCharges",
                "kind": "LinkedField",
                "name": "charges",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                    "kind": "LinkedField",
                    "name": "chargesBreakup",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "payable",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discountAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalPayable",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskETA",
        "kind": "LinkedField",
        "name": "ETA",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "delayAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "originalPredictionTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingRecall",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "available",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "busy",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "cashierTaskUpdatedSubscriptionApiSubscription",
    "selections": (v6/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "cashierTaskUpdatedSubscriptionApiSubscription",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "af6a5175230e4a63347de85870e76233",
    "id": null,
    "metadata": {},
    "name": "cashierTaskUpdatedSubscriptionApiSubscription",
    "operationKind": "subscription",
    "text": "subscription cashierTaskUpdatedSubscriptionApiSubscription(\n  $campusId: ID!\n  $userId: ID!\n  $history: [EnumtaskHistoryAction]\n  $event_at: Date\n  $taskType: [EnumtaskTaskType]\n) {\n  taskUpdated(campusId: $campusId, userId: $userId, history: $history, event_at: $event_at, taskType: $taskType) {\n    _id\n    vehicle {\n      registration {\n        plate\n      }\n    }\n    isVehicleWhitelisted\n    taskType\n    campus {\n      _id\n    }\n    history {\n      _id\n      action\n      actor\n      event_at\n    }\n    parking {\n      _id\n      status\n      parkingTag\n      parkingInvoice {\n        timings {\n          entryTime\n          exitTime\n          chargeTime\n        }\n        paymentDetails {\n          isPaid\n          mode\n          focReason\n          amount\n        }\n        charges {\n          chargesBreakup {\n            duration\n            payable\n          }\n          discountAmount\n          totalPayable\n        }\n      }\n    }\n    ETA {\n      _id\n      startAt\n      delayAt\n      originalPredictionTime\n      pendingRecall\n      available\n      busy\n    }\n    parkingId\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc017a6d6e927db4b7b4970039065f1c";

export default node;
