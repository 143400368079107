/**
 * @generated SignedSource<<c0b375fa238268251fdb1e2b60877fd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumwallettransactionType = "CREDIT" | "DEBIT" | "%future added value";
export type TransactionSourceType = "ADDONS" | "CAMPUS_VISIT" | "COUPON_POINTS" | "EVENT" | "EVENT_PROMOTION" | "FEEDBACK_GIVEN" | "FIRST_LOGIN" | "FIRST_TXN" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "OTHER" | "PARKING" | "PARK_DELAY" | "POINTS_EXPIRY" | "POPUP_ORDER" | "POPUP_STORE" | "REFUND" | "RESTAURANT" | "SLOT_SPIN" | "STORE" | "SUBSEQUENT_TXN" | "%future added value";
export type TransactionType = "CREDIT" | "DEBIT" | "%future added value";
export type WalletHistorySortEnum = "ASC" | "DESC" | "%future added value";
export type rewardHistoryFilterTC = {
  type?: TransactionType | null;
};
export type pointsScreenQuery$variables = {
  filter?: rewardHistoryFilterTC | null;
  groupSTW?: boolean | null;
  id: any;
  sort?: WalletHistorySortEnum | null;
};
export type pointsScreenQuery$data = {
  readonly findUserById: {
    readonly wallet: {
      readonly currentBalance: number | null;
      readonly earnedAmount: number | null;
    } | null;
  } | null;
  readonly getWalletHistory: ReadonlyArray<{
    readonly _id: any;
    readonly amount: number;
    readonly created_at: any | null;
    readonly isAvailable: boolean;
    readonly source: {
      readonly name: string | null;
      readonly refId: string | null;
      readonly type: TransactionSourceType | null;
    } | null;
    readonly type: EnumwallettransactionType;
    readonly userId: any;
  } | null> | null;
};
export type pointsScreenQuery = {
  response: pointsScreenQuery$data;
  variables: pointsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupSTW"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "groupSTW",
        "variableName": "groupSTW"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "walletTransactionReservedTC",
    "kind": "LinkedField",
    "name": "getWalletHistory",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAvailable",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sourceDetailsTC",
        "kind": "LinkedField",
        "name": "source",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "findUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserWallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "earnedAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "pointsScreenQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "pointsScreenQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "3b8631128d740bbc1da71191a05c5a04",
    "id": null,
    "metadata": {},
    "name": "pointsScreenQuery",
    "operationKind": "query",
    "text": "query pointsScreenQuery(\n  $filter: rewardHistoryFilterTC\n  $sort: WalletHistorySortEnum\n  $id: MongoID!\n  $groupSTW: Boolean\n) {\n  getWalletHistory(filter: $filter, sort: $sort, groupSTW: $groupSTW) {\n    userId\n    _id\n    type\n    amount\n    isAvailable\n    created_at\n    source {\n      type\n      name\n      refId\n    }\n  }\n  findUserById(_id: $id) {\n    wallet {\n      currentBalance\n      earnedAmount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4775ab8d46738750232a016b2b2fe23";

export default node;
