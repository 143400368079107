import React from 'react';
import { useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-actions-sheet';
import { Text, View } from '../../../../components/new';
import InvestmentFileComponent from './InvestmentFileComponent';

const OfferDetails = ({ data }) => {
  const { height } = useWindowDimensions();

  return (
    <View height={height * 0.8}>
      <ScrollView>
        <View my="2xl" px="2xl">
          {data?.subscriptionDetails?.values.map((item) => (
            <View mt="2xl" key={`Offer_details_val${item?.header}`}>
              <Text size="md" color="primary.300">
                {item?.header}
              </Text>
              <View
                mt="2xl"
                py="2xl"
                px="2xl"
                borderWidth={1}
                borderRadius="lg"
                borderColor="grey.50"
                width="100%"
                bg="background.primary.elevation"
              >
                {item?.details?.map((detailItem, detailIndex) => (
                  <View
                    key={`Offer_details_sub${detailItem?.key}`}
                    flexDirection="row"
                    justifyContent="space-between"
                    pb={
                      detailIndex + 1 === item?.details?.length &&
                      item?.details?.length > 1
                        ? 0
                        : '2xl'
                    }
                  >
                    <Text size="sm" color="primary.400">
                      {detailItem?.key}
                    </Text>
                    <Text size="sm" color="primary.400">
                      {detailItem?.value}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
        {data?.subscriptionDetails?.documents?.length ? (
          <View pb="9xl">
            <Text size="md" color="primary.300" px="2xl">
              Investment Details
            </Text>
            <InvestmentFileComponent
              data={{
                data: data?.subscriptionDetails?.documents,
              }}
              hidePadding
            />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
};

export default OfferDetails;
