/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';
import { commitMutationPromise } from '../../../../utilities/helper';

export const reservationConfirmationScreenQuery = graphql`
  query reservationConfirmationScreenQuery(
    $useRewardPoints: Boolean!
    $orderId: ID!
    $orderType: Referer!
    # $amount: Float
    $filter: findEventInput!
    $includeGetNetPrice: Boolean!
    $workshopDetails: invoiceWorkshopDetailsInput
  ) {
    getNetPrice(
      useRewardPoints: $useRewardPoints
      orderId: $orderId
      orderType: $orderType
      # amount: $amount
      workshopDetails: $workshopDetails
    ) @include(if: $includeGetNetPrice) {
      priceDetails {
        grossAmount
        discountGiven
        rewardsUsed
        netAmount
      }
      discountPercentage
      cashbackPercentage
      cashbackAmount
    }
    findEvents(filter: $filter) {
      _id
      campus {
        _id
      }
      title
      description
      venue
      media {
        cover {
          mediaURL
          mediaType
          cover
          displayOrder
        }
        past {
          mediaURL
          mediaType
          cover
          displayOrder
        }
        testimonials {
          mediaURL
          mediaType
          cover
          displayOrder
        }
      }
      mediaCoverages {
        imageUrl
        hyperLink
      }
      faqs {
        question
        answer
      }
      host {
        name
        description
        media {
          mediaURL
          mediaType
          cover
          displayOrder
        }
      }
      motivation {
        title
        logo
      }
      timeline {
        title
        logo
        duration
        media {
          mediaURL
          mediaType
          cover
          displayOrder
        }
      }
      eventTiming {
        _id
        date
        slot {
          startTime
          endTime
          slotSpace
          slotsLeft
          slotTag
          _id
        }
      }
    }
  }
`;

export const reservationConfirmationScreenInitiatePaymentMutation = (data) => {
  const mutation = graphql`
    mutation reservationConfirmationScreenInitiatePaymentMutation(
      $orderType: Referer!
      $orderId: ID!
      $useRewardPoints: Boolean!
      $workshopDetails: invoiceWorkshopDetailsInput
    ) {
      initiatePayment(
        orderType: $orderType
        orderId: $orderId
        useRewardPoints: $useRewardPoints
        workshopDetails: $workshopDetails
      ) {
        userId
        displayName
        priceDetails {
          grossAmount
          discountGiven
          rewardsUsed
          netAmount
          _id
        }
        status
        _id
        created_at
        orderId
      }
    }
  `;

  const variables = data;

  return commitMutationPromise(mutation, variables);
};
