/* eslint-disable import/prefer-default-export */
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

export const logFirebaseRestaurantSelectEvent = (
  restaurantName = 'N/A',
  section: string,
): void => {
  firebaseEventLogger('homeScreen__selectRestaurant_Tap', {
    buttonName: 'selectRestaurant',
    screenName: 'homeScreen',
    userType: 'user',
    interactionType: 'tap',
    section,
    restaurantName,
  });
};

export const logFirebaseRestaurantExploreEvent = (section: string): void => {
  firebaseEventLogger('homeScreen__exploreRestaurantsButton_Tap', {
    buttonName: 'exploreRestaurantsButton',
    screenName: 'homeScreen',
    userType: 'user',
    section,
    interactionType: 'tap',
  });
};

export const logFirebaseRestaurantVideoEvent = (
  restaurantName = 'N/A',
  section: string,
): void => {
  firebaseEventLogger('homeScreen__restaurantVideo_Tap', {
    buttonName: 'restaurantVideo',
    screenName: 'homeScreen',
    userType: 'user',
    section,
    restaurantName,
    interactionType: 'tap',
  });
};
