import React, { Suspense, useState } from 'react';
import { IconButton, Layout, TopNavigation } from '../../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import { Loading } from '../../../../components';
import { navigateBack } from '../../../../utilities/helper';
import useUserStore from '../../../../stores/userStore';
import EventConfirmCancellationScreenInner from './EventConfirmCancellationScreenInner';
import { eventAnalytics } from '../analytics/eventAnalytics';

const EventConfirmCancellationScreen = ({ navigation, route }) => {
  const role = useUserStore((state) => state.role);
  const { invoiceId } = route?.params ?? {};
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });
  const [queryVariables] = useState({
    invoiceFilter: {
      _id: invoiceId,
    },
    ticketFilter: {
      invoiceId,
    },
  });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2}>
      <TopNavigation
        title="Confirm Cancellation"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              eventAnalytics.confirmCancellationScreen.backClicked(invoiceId);
              navigateBack(navigation, role);
            }}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions?.fetchKey}
        fallback={
          <NewErrorView
            reload={refresh}
            errorMsg="Sorry something went wrong"
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <EventConfirmCancellationScreenInner
            queryVariables={queryVariables}
            queryOptions={refreshedQueryOptions}
          />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default EventConfirmCancellationScreen;
