/**
 * @generated SignedSource<<f08e1cb141b55cfa640317a1c2f27b75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DateGroupBy = "DAY" | "MONTH" | "%future added value";
export type PaymentReportFilter = {
  date: DateRange;
  groupBy: DateGroupBy;
  orderId: string;
};
export type DateRange = {
  end: string;
  start: string;
};
export type transactionsEarningReportFragmentQuery$variables = {
  filter: PaymentReportFilter;
};
export type transactionsEarningReportFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"transactionsEarningReportFragment">;
};
export type transactionsEarningReportFragmentQuery = {
  response: transactionsEarningReportFragmentQuery$data;
  variables: transactionsEarningReportFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transactionsEarningReportFragmentQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "transactionsEarningReportFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transactionsEarningReportFragmentQuery",
    "selections": [
      {
        "alias": "earingReports",
        "args": (v1/*: any*/),
        "concreteType": "PaymentReport",
        "kind": "LinkedField",
        "name": "getPaymentReports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "period",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transactionsCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payoutAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountGiven",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardPointsUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardPointsGranted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardPointsCompensated",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d87c1358ee5661b836be81271596f5a3",
    "id": null,
    "metadata": {},
    "name": "transactionsEarningReportFragmentQuery",
    "operationKind": "query",
    "text": "query transactionsEarningReportFragmentQuery(\n  $filter: PaymentReportFilter!\n) {\n  ...transactionsEarningReportFragment_Vt7Yj\n}\n\nfragment transactionsEarningReportFragment_Vt7Yj on Query {\n  earingReports: getPaymentReports(filter: $filter) {\n    date\n    period\n    transactionsCount\n    grossAmount\n    netAmount\n    payoutAmount\n    discountGiven\n    rewardPointsUsed\n    rewardPointsGranted\n    rewardPointsCompensated\n  }\n}\n"
  }
};
})();

(node as any).hash = "137fca85f1f214d63c050efb2e9dfe60";

export default node;
