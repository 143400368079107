import React, { useState, Suspense, useCallback, useEffect } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Image, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import {
  Layout,
  Text,
  View,
  TopNavigation,
  IconButton,
  Card,
  Divider,
  Button,
} from '../../../components/new';
import {
  getFormatedDate,
  navigateBack,
  DateFormatsEnum,
} from '../../../utilities/helper';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import useUserStore from '../../../stores/userStore';
import useAuthStore from '../../../stores/authStore';
import { space } from '../../../themes/new/theme';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import NotLoggedInPlaceholderV2 from '../../../components/new/custom/NotLoggedInPlaceholderV2';

const pastOrdersQuery = graphql`
  query pastOrdersQuery($filter: findOrdersFilterInput) {
    findOrders(filter: $filter) {
      _id
      orderDate
      menuItems {
        itemId
        quantity
        itemName
      }
      priceDetails {
        _id
        total
      }
      restaurant {
        displayName
        logo
      }
    }
  }
`;

const StyledImage = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 999px;
`;

const RenderPastOrdersScreen = (props) => {
  const { variables, navigation } = props;

  const { findOrders } = useLazyLoadQuery(pastOrdersQuery, variables, {
    networkCacheConfig: { force: true },
  });

  useEffect(() => {
    firebaseEventLogger('pastOrders__land_Landing', {
      buttonName: 'land',
      screenName: 'pastOrders',
      userType: 'user',
      interactionType: InteractionType.LANDING,
    });
  }, []);

  return (
    <View flex={1}>
      <TopNavigation
        title="Your Orders"
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, 'user')}
          />
        }
      />
      {findOrders.length ? (
        <ScrollView>
          <View px="2xl" mt="xl" flex={1}>
            {findOrders.map((item, index) => {
              return (
                <Card
                  my="xl"
                  category="none"
                  key={item._id}
                  mb={findOrders.length - 1 === index ? '5xl' : 'xl'}
                >
                  <View flexDirection="row" alignItems="center" p="2xl">
                    <StyledImage
                      source={{ uri: item.restaurant?.logo ?? '' }}
                    />
                    <Text
                      ml="2xl"
                      size="xl"
                      color="primary.300"
                      weight="medium"
                    >
                      {item.restaurant.displayName}
                    </Text>
                  </View>
                  <View>
                    <Divider />
                  </View>
                  {item.menuItems?.length ? (
                    <View px="2xl" py="md">
                      {item.menuItems.map((menuItem) => {
                        return (
                          <View key={menuItem.itemId} my="sm+md">
                            <Text
                              size="sm"
                              color="primary.400"
                            >{`${menuItem.quantity} x ${menuItem.itemName}`}</Text>
                          </View>
                        );
                      })}
                    </View>
                  ) : null}
                  <View>
                    <Divider />
                  </View>
                  <View
                    flexDirection="row"
                    justifyContent="space-between"
                    p="2xl"
                  >
                    <Text size="sm" color="primary.100">
                      {`${getFormatedDate(
                        new Date(item.orderDate),
                        DateFormatsEnum.DateMonthYear,
                      )} at ${getFormatedDate(
                        new Date(item.orderDate),
                        DateFormatsEnum.TIME,
                      )}`}
                    </Text>
                  </View>
                </Card>
              );
            })}
          </View>
        </ScrollView>
      ) : (
        <View flex={1} justifyContent="center" alignItems="center" px="2xl">
          <View
            bottom={space['9xl'] + space['3xl']}
            alignItems="center"
            width="100%"
          >
            <Image
              source={{
                uri: 'https://ik.imagekit.io/32ndhub/assets/no-order-found.png',
              }}
              style={{
                width: 280,
                height: 280,
                top: 20,
                resizeMode: 'contain',
              }}
            />
            <Text size="2xl" color="primary.400" weight="medium" mt="6xl">
              No Orders Yet!
            </Text>
            <Text size="md" color="primary.200" mt="lg" textAlign="center">
              There are no orders at the moment, browse our menus to place your
              first order
            </Text>
            <View width="100%" mt="6xl">
              <Button
                size="lg"
                onPress={() => {
                  firebaseEventLogger('pastOrders__goToHome_Tap', {
                    buttonName: 'goToHome',
                    screenName: 'pastOrders',
                    userType: 'user',
                    interactionType: 'tap',
                  });
                  navigation.navigate('home');
                }}
              >
                Go to Home
              </Button>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const PastOrders = ({ navigation, route }) => {
  const [netStatus, setNetStatus] = useState(true);
  const userId = useUserStore((state) => state.id);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [isButtonPressed, setIsButtonPressed] = useState(false);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'network-only',
    }));
  }, []);

  useEffect(() => {
    if (isButtonPressed && isLoggedIn) {
      navigation.navigate('home');
      setIsButtonPressed(false);
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="Your Orders" />;
  }

  return (
    <View flex={1}>
      <Layout level={2}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <RenderPastOrdersScreen
              variables={{ filter: { userId } }}
              fetchKey={refreshedQueryOptions?.fetchKey}
              navigation={navigation}
            />
          </Suspense>
        </NewErrorBoundary>
        <DisconnectedDropover
          setNetStatus={setNetStatus}
          text="No Internet Connection"
          icon="wifi-off-outline"
        />
      </Layout>
    </View>
  );
};

export default PastOrders;
