import { graphql, requestSubscription } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

const supervisorTaskCreatedSubscription = graphql`
  subscription supervisorTaskCreatedSubscription(
    $campusId: ID!
    $parkingLocationId: ID
    $userId: ID # $event_at: Date
    $taskType: [EnumtaskTaskType]
  ) {
    taskCreated(
      campusId: $campusId
      parkingLocationId: $parkingLocationId
      userId: $userId # event_at: $event_at
      taskType: $taskType
    ) {
      _id
      vehicle {
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
      }
      taskType
      taskStartLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      campus {
        _id
        name
      }
      history {
        _id
        action
        actor
        event_at
      }
      assignedTo
      valet {
        name {
          first
          last
        }
        identityCode
        contact {
          phone {
            prefix
            number
          }
        }
      }
      user {
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      vehicleId
      ownerId
      parkingId
      parkingTag
      createdBy {
        status
      }
    }
  }
`;

export default (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: supervisorTaskCreatedSubscription,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      console.log('error', error);
      // console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
