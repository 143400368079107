import React from 'react';
import { View } from 'react-native';
import { graphql, useFragment } from 'react-relay';
import ParkingLocationInfo from '../../components/parkingLocationInfo.component';

type Props = {
  findParkingLocation: any[]; // TODO: propTypes.instanceOf(Object)
};

const SearchParkingList = (props: Props) => {
  const { findParkingLocation } = props;

  const findParkingLocationList = useFragment(
    graphql`
      fragment searchParkingList_findParkingLocation on parkingLocation
      @relay(plural: true) {
        _id
        name
        address {
          addressLine1
        }
        parkingSpaces {
          floor {
            _id
            floorName
            bay {
              _id
              bayName
              parkingSpots
              parkingOccupied
              parkingVacant
            }
          }
        }
      }
    `,
    findParkingLocation,
  );

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        paddingBottom: 32,
      }}
    >
      {findParkingLocationList.map((item, index) => (
        <ParkingLocationInfo
          key={Math.random()}
          findParkingLocation={item}
          showVerticalLine={index !== findParkingLocation.length - 1}
        />
      ))}
    </View>
  );
};
export default SearchParkingList;
