import 'expo-dev-client';
/* eslint-disable no-extra-boolean-cast */
import React, { Suspense, useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Constants from 'expo-constants';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as ScreenOrientation from 'expo-screen-orientation';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react-native';
import { ThemeProvider } from 'styled-components/native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { Dimensions, LogBox, Platform } from 'react-native';
import { RelayEnvironmentProvider } from 'react-relay';
import * as Updates from 'expo-updates';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LogLevel, OneSignal } from 'react-native-onesignal';
import OTAConfig from './react-native.config';
import AppNavigator from './src/navigation/appNavigator';
import environment from './src/relay/relayEnvironment';
import { getTheme } from './src/themes/new/theme';
import { SnackBar } from './src/components/new';
import { setPerformanceEnable } from './src/utilities/firbaseAnalytics';
import DownloadApp from './src/components/modals/DownloadApp';
import useCommonStore from './src/stores/commonStore';
import Loading from './src/components/loading.component';
import '@expo/metro-runtime';
import AppConstants from './src/utilities/AppConstants';
import { isValueNullOrEmpty } from './src/utilities/Utility';

LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
LogBox.ignoreAllLogs(); // Ignore all log notifications
SplashScreen.preventAutoHideAsync();

if (Platform.OS !== 'web') {
  OneSignal.Debug.setLogLevel(LogLevel.Verbose);
  OneSignal.initialize(Constants.expoConfig.extra.oneSignalAppId);
}

if (!__DEV__) {
  const { version } = Constants.expoConfig;
  const modifiedAppVersion =
    !isValueNullOrEmpty(OTAConfig.ota) && Platform.OS !== 'web'
      ? `${version}-${OTAConfig.ota}`
      : version;
  Sentry.init({
    dsn: 'https://44049234ccce4c7da2f97dc494a59d00@o489978.ingest.us.sentry.io/5553134',
    enableTracing: true,
    enableAppHangTracking: true,
    enableAutoPerformanceTracing: true,
    enableNative: true,
    enableCaptureFailedRequests: true,
    enabled: true,
    debug: !__DEV__,
    environment: AppConstants.APP_ENV,
    tracesSampleRate: 1,
    attachScreenshot: true,
    release: modifiedAppVersion,
  });
}

function App() {
  const theme = useCommonStore((state) => state.theme);
  const styledTheme = getTheme(theme);
  const screenOrientation = useCommonStore((state) => state.orientation);
  const setScreenOrientation = useCommonStore((state) => state.setOrientation);

  const [loaded] = useFonts({
    'NewYork-Regular': require('./assets/fonts/NewYork-Regular.ttf'),
    'MessinaSans-Bold': require('./assets/fonts/MessinaSans-Bold.otf'),
    'MessinaSans-Regular': require('./assets/fonts/MessinaSans-Regular.otf'),
    'CircularStd-Regular': require('./assets/fonts/CircularStd-Book.otf'),
    'CircularStd-Medium': require('./assets/fonts/CircularStd-Medium.otf'),
    'MixtaDidoneAlt-Medium': require('./assets/fonts/MixtaDidoneAlt-Medium.otf'),
    'Gotham-Regular': require('./assets/fonts/Gotham-Book.otf'),
    'Gotham-Medium': require('./assets/fonts/Gotham-Medium.otf'),
    'DomaineText-RegularItalic': require('./assets/fonts/DomaineText-RegularItalic.otf'),
    'UniversNextPro-Regular': require('./assets/fonts/UniversNextPro-Regular.otf'),
    IcoMoon: require('./assets/fonts/icomoon/fonts/icomoon.ttf'),
  });

  const windowWidth = Dimensions.get('window').width;

  useEffect(() => {
    if (Platform.OS === 'web' && AppConstants.APP_ENV === 'production') {
      const script = document.createElement('script');
      script.innerHTML = `
      !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '2116460215362701');fbq('track', 'PageView');
    `;
      document.head.appendChild(script);
    }
  }, []);

  const hideSplashScreen = async () => {
    await SplashScreen.hideAsync();
  };

  useEffect(() => {
    setPerformanceEnable(true);
    if (Platform.OS !== 'web') {
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
    }
    ScreenOrientation.addOrientationChangeListener((event) => {
      setScreenOrientation(event?.orientationInfo?.orientation);
    });
    ScreenOrientation.getOrientationAsync()
      .then((value) => {
        setScreenOrientation(value);
      })
      .catch((err) => {
        setScreenOrientation(0);
        throw err;
      });
    return () => {
      ScreenOrientation.removeOrientationChangeListeners();
      setPerformanceEnable(false);
    };
  }, []);

  async function onFetchUpdateAsync() {
    if (Platform.OS !== 'android' || __DEV__) {
      return;
    }
    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        Updates.fetchUpdateAsync();
      }
    } catch (error) {
      console.log('error-->', error);
    }
  }

  useEffect(() => {
    // Once our data is ready, hide the Splash Screen
    const checkUpdate = async () => {
      await onFetchUpdateAsync();
      await hideSplashScreen();
    };
    if (loaded) {
      checkUpdate();
    }
  }, [loaded]);

  if (!loaded) {
    return null;
  }

  return (
    <ThemeProvider theme={styledTheme}>
      <ActionSheetProvider>
        <RelayEnvironmentProvider environment={environment}>
          <SafeAreaProvider
            style={
              windowWidth > 1200 && screenOrientation < 3
                ? { width: 375, alignSelf: 'center' }
                : {}
            }
          >
            <Suspense fallback={<Loading />}>
              <AppNavigator />
            </Suspense>
            <SnackBar />
            <DownloadApp />
          </SafeAreaProvider>
        </RelayEnvironmentProvider>
      </ActionSheetProvider>
    </ThemeProvider>
  );
}

export default Sentry.wrap(App);
