import React, { useEffect, useRef, useState } from 'react';
import { AutoFocus, Camera, CameraType } from 'expo-camera';
import { StyleSheet } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import uuid from 'react-native-uuid';
import {
  Button,
  IconButton,
  Layout,
  TopNavigation,
  View,
} from '../../components/new';
import ThemeWrapper, {
  ThemeWrapperType,
} from '../new/wrapperComp/ThemeWrapper';
import { uploadImageToS3Query } from '../../relay/microServicesApis';
import { updateUserV2 } from '../../relay/userApi';
import useUserStore from '../../stores/userStore';
import VideoMomentsStore from '../../stores/VideoMomentsStore';

const PhotoMomentsScanFaceWeb = () => {
  const navigation = useNavigation<any>();
  const isFocused = useIsFocused();
  const cameraRef = useRef<Camera>();
  const [showCamera, setShowCamera] = useState<boolean>(true);
  const userId = useUserStore((state) => state.id);
  const setScannedS3Imagekey = VideoMomentsStore(
    (state) => state.setScannedS3Imagekey,
  );
  const setMomentsData = VideoMomentsStore((state) => state.setMomentsData);

  useEffect(() => {
    if (isFocused) {
      setShowCamera(true);
    } else {
      setShowCamera(false);
    }
  }, [isFocused]);

  const handleClickPicture = async () => {
    const data = await cameraRef.current.takePictureAsync({
      base64: true,
      quality: 0.2,
    });

    const base64 = data.base64.split(',')[1];

    if (base64) {
      const payload = {
        base64Image: base64,
        bucketName: 'photo-moments-user-face',
        contentType: 'image/jpeg',
        key: String(uuid.v4()),
      };
      const res = await uploadImageToS3Query(payload);
      const resUser = await updateUserV2(userId, {
        photoMomentsSearchImage: res?.uploadImageToS3?.key,
      });
      setMomentsData('');
      setScannedS3Imagekey(res?.uploadImageToS3?.key);
      navigation.navigate('PhotoMoments');
    }
  };

  return (
    <Layout level={1}>
      <ThemeWrapper type={ThemeWrapperType.DARK}>
        <TopNavigation
          appearance="ghost"
          level="1"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="sm"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigation.popToTop()}
            />
          }
          title="Scan Face"
        />
      </ThemeWrapper>
      <View flex={1} bg="primary.500" alignItems="center">
        <View style={styles.cameraWrapper}>
          {showCamera && (
            <Camera
              ref={cameraRef}
              style={styles.mainCamera}
              type={CameraType.front}
              ratio="1:1"
              autoFocus={AutoFocus.on}
            />
          )}
        </View>
        <View style={styles.clickButtonWrapper}>
          <ThemeWrapper type={ThemeWrapperType.DARK}>
            <Button size="md" onPress={handleClickPicture}>
              Click Picture
            </Button>
          </ThemeWrapper>
        </View>
      </View>
    </Layout>
  );
};

export default PhotoMomentsScanFaceWeb;

const styles = StyleSheet.create({
  cameraWrapper: {
    height: 300,
    width: 300,
    overflow: 'hidden',
    borderRadius: 150,
    marginTop: 150,
  },
  mainCamera: {
    flex: 1,
    justifyContent: 'center',
  },
  clickButtonWrapper: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 32,
    justifyContent: 'flex-end',
    marginBottom: 16,
  },
});
