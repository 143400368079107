/* eslint-disable no-nested-ternary */
import React from 'react';
import { FlatList } from 'react-native';
import { Pressable, View } from '../../../../components/new';
import TransactionSummaryListItem from '../../../../components/new/custom/transaction/TransactionSummaryListItem';
import { TransactionsEarningsReport } from '../../types';
import { getTheme } from '../../../../themes/new/theme';
import { dateUtils } from '../../../../utilities/helper';
import EmptyListView from '../../components/EmptyListView';

const theme = getTheme();
interface EarningsListProps {
  earningReports: TransactionsEarningsReport[];
  RefreshControl: JSX.Element;
  onItemPress: (earningsReport: TransactionsEarningsReport) => void;
}

const EarningsList = ({
  earningReports,
  onItemPress,
  RefreshControl,
}: EarningsListProps) => {
  return (
    <View flex={1}>
      <FlatList
        contentContainerStyle={{
          paddingHorizontal: theme.space['2xl'],
        }}
        data={earningReports}
        keyExtractor={(item) => item?.formated.date}
        refreshControl={RefreshControl}
        renderItem={({ item }) => {
          const isCurrentMonth = dateUtils.isCurrentMonth(item.date);
          return (
            <View mb="2xl">
              <Pressable onPress={() => onItemPress(item)}>
                <TransactionSummaryListItem
                  amount={item.formated.payoutAmount}
                  date={item.formated.date}
                  label={isCurrentMonth ? 'Pending (MTD)' : 'Paid'}
                  paymentStatus={isCurrentMonth ? 'PENDING' : 'SUCCESSFUL'}
                />
              </Pressable>
            </View>
          );
        }}
        ListEmptyComponent={NoEarningsView}
      />
    </View>
  );
};

export default EarningsList;

const NoEarningsView = () => (
  <View mt="122">
    <EmptyListView
      label="No Earnings Yet!"
      msg="There are no earnings at the moment, check back again"
    />
  </View>
);
