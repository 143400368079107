/* eslint-disable import/prefer-default-export */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import bottomNudgesSlice, {
  bottomNudgesSliceReturnType,
} from './bottomNudgesSlice';

export const useBottomNudges = create<bottomNudgesSliceReturnType>()(
  devtools((...a) => ({
    ...bottomNudgesSlice(...a),
  })),
);
