/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const transactionScreenQuery = graphql`
  query transactionScreenQuery($filter: findInvoiceInput!, $userId: MongoID!) {
    findUserById(_id: $userId) {
      _id
      name {
        first
        last
      }
      wallet {
        totalBalance
        currentBalance
        moneySaved
        earnedAmount
      }
    }
    findInvoice(filter: $filter) {
      userId
      displayName
      priceDetails {
        grossAmount
        discountGiven
        rewardsUsed
        netAmount
        discountPercentage
        couponAmount
        couponCashbackAmount
        cashbackAmount
        tipAmount
        addonAmount
        cashbackPercentage
      }
      paymentType
      couponCode
      status
      orderType
      _id
      uId
      created_at
      orderId
      rewardPoints {
        type
        amount
        isAvailable
        created_at
        source {
          type
          refId
        }
      }
      addons {
        orderId
        _id
      }
      workshopDetails {
        totalSpaces
      }
      sourceDetails {
        restaurant {
          displayName
          type
          logo
          _id
        }
        store {
          displayName
          logo
        }
        popUpStore {
          displayName
          logo
        }

        event {
          title
          _id
          eventTiming {
            _id
            slot {
              _id
              availableTickets {
                _id
                price
                basePrice
              }
            }
          }
        }
        parking {
          _id
          vehicle {
            registration {
              plate
            }
          }
          parkingInvoice {
            charges {
              chargesBreakup {
                duration
                payable
              }
              discountAmount
              valetTip
            }
            invoicePdfPath
          }
          parkingTag
          pointsData {
            amount
            source {
              type
            }
          }
        }
        investmentLead {
          investmentDetails {
            propertyName
          }
        }
      }
    }
  }
`;
