import React, { memo } from 'react';
import { Text, View } from '../../../../components/new';
import baseTheme from '../../../../themes/new/theme';
import Photogrid from '../../../../components/new/custom/PhotoGrid';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import useAuthStore from '../../../../stores/authStore';
import useCommonStore from '../../../../stores/commonStore';

const areEqual = () => {
  return true;
};

const InvestmentPhotoGrid = ({
  data,
  bgColor = '',
  propertyId,
  navigateToGallery = (res: any) => {},
}) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const commonStore = useCommonStore((state) => state);

  const navigateToImageGallery = (index) => {
    const res = { data: data?.data, curIndex: index };
    navigateToGallery(res);
    firebaseEventLogger('PropertyDetails__imageView_Tap', {
      buttonName: 'imageView',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'tap',
      imageId: data?.data?.[index]?._id,
      isLoggedIn,
      propertyId,
    });
  };

  const renderContent = () => (
    <View py="9xl+9xl" px="2xl" alignItems="center">
      <Text size="xs" color="primary.300" pb="lg" textAlign="center">
        {data?.heading}
      </Text>
      <Text size="2xl" color="primary.500" textAlign="center">
        {data?.subHeading}
      </Text>
      {data?.data?.length ? (
        <View mt="5xl">
          <Photogrid
            images={data?.data}
            theme={baseTheme}
            onPress={(index) => navigateToImageGallery(index)}
          />
        </View>
      ) : null}
    </View>
  );

  return <View bg={bgColor}>{renderContent()}</View>;
};

export default memo(InvestmentPhotoGrid, areEqual);
