import React, { useEffect } from 'react';
import {
  Image,
  Keyboard,
  Pressable,
  TouchableWithoutFeedback,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Button,
  Input,
  RadioGroup,
  Text,
  View,
} from '../../../../components/new';
import useEventConfirmCancellationScreen, {
  BS_MODES,
  ReasonLabels,
} from './useEventConfirmCancellationScreen';
import { Appearance, FontWeights, State } from '../../../../themes/new/helper';
import { toRupees } from '../../../../utilities/helper';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';
import PaymentsImage from '../../../../../assets/images/payments.webp';
import { InputStates } from '../../../../components/new/primitive/Input/helpers';
import { eventAnalytics } from '../analytics/eventAnalytics';

const EventConfirmCancellationScreenInner = ({
  queryVariables,
  queryOptions,
}) => {
  const { bottom } = useSafeAreaInsets();
  const {
    bsRef,
    bsMode,
    eventId,
    ticketId,
    radioConfig,
    selectedReason,
    amountSummary,
    amountBreakUp,
    proceedCTAState,
    otherCancellationReason,
    reasonValidationError,
    onBsDismiss,
    showBreakUpBs,
    handleOtherReasonChange,
    handleOtherReasonValidation,
    clearOtherReasonValidation,
    handleCancellation,
    showCancellationReasonsBs,
    handleCancellationRadioSelection,
    handleCancellationReasonApply,
  } = useEventConfirmCancellationScreen(queryVariables, queryOptions);

  useEffect(() => {
    eventAnalytics.confirmCancellationScreen.landed(eventId, ticketId);
  }, [eventId, ticketId]);

  return (
    <TouchableWithoutFeedback style={{ flex: 1 }} onPress={Keyboard.dismiss}>
      <View flex={1} justifyContent="space-between">
        <View px="2xl" mt="4xl">
          {/* Input */}
          <View mb="9xl">
            <Text mb="lg" size="md" color="primary.300">
              Select reason for cancellation*
            </Text>
            {/* Input for Showing Bottom Sheet with cancellation reasons */}
            <Input
              rightIconName="expand-outline-300"
              placeholder="Select Something..."
              onPressMiddleArea={showCancellationReasonsBs}
              value={selectedReason}
            />
            {/* Other custom cancellation reason field, enabled once "Other" reason is selected from Bottom Sheet */}
            {selectedReason === ReasonLabels.OTHER && (
              <View mt="4xl">
                <Input
                  multiline
                  numberOfLines={3}
                  state={
                    reasonValidationError
                      ? InputStates.error
                      : InputStates.default
                  }
                  inputMode="text"
                  label="Describe your reason*"
                  labelFontSize="md"
                  labelFontFamily="body"
                  placeholder="Type something..."
                  value={otherCancellationReason}
                  helperText={reasonValidationError}
                  onBlur={handleOtherReasonValidation}
                  onFocus={clearOtherReasonValidation}
                  onChangeText={(val) => {
                    handleOtherReasonChange(val);
                  }}
                  style={{
                    height: 87,
                    textAlignVertical: 'top',
                  }}
                />
              </View>
            )}
          </View>
          <SummaryPanel showBreakUpBs={showBreakUpBs} data={amountSummary} />
        </View>
        <View>
          <FooterControls
            btnState={proceedCTAState}
            amount={amountSummary?.refundAmount}
            onProceed={handleCancellation}
            bottomPadding={bottom}
          />
        </View>

        <BottomSheetV2 onClose={onBsDismiss} ref={bsRef} insets={false}>
          {bsMode === BS_MODES.BREAK_UP && (
            <BottomSheetAmountBreakUp amountBreakUp={amountBreakUp} />
          )}
          {bsMode === BS_MODES.CANCELLATION_REASONS && (
            <BottomSheetCancellationReason
              radioConfig={radioConfig}
              onRadioSelect={handleCancellationRadioSelection}
              onApply={handleCancellationReasonApply}
            />
          )}
        </BottomSheetV2>
      </View>
    </TouchableWithoutFeedback>
  );
};

const SummaryPanel = ({ data, showBreakUpBs }) => {
  const { paidAmount, cancellationCharge, refundAmount } = data;
  return (
    <View>
      <Text mb="2xl" size="md" color="primary.400" weight={FontWeights.MEDIUM}>
        Refund Summary
      </Text>
      <View p="2xl" borderWidth="xs" borderRadius="lg" borderColor="grey.50">
        <View
          mb="2xl"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <View flexDirection="row" alignItems="center">
            <Text pr="lg" size="sm" color="primary.400">
              Amount paid
            </Text>
            <Pressable onPress={showBreakUpBs}>
              <Text size="xs" color="info.500">
                View breakup
              </Text>
            </Pressable>
          </View>
          <Text flex={1} textAlign="right" size="sm" color="primary.400">
            {toRupees(paidAmount)}
          </Text>
        </View>
        <View
          mb="2xl"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text pr="lg" size="sm" color="success.500">
            Cancellation charges
          </Text>
          <Text flex={1} textAlign="right" size="sm" color="success.500">
            -{toRupees(cancellationCharge)}
          </Text>
        </View>
        <View
          pt="lg"
          borderTopWidth="xs"
          borderColor="grey.50"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            weight={FontWeights.MEDIUM}
            pr="lg"
            size="sm"
            color="primary.500"
          >
            Total Refund amount
          </Text>
          <Text
            flex={1}
            weight={FontWeights.MEDIUM}
            textAlign="right"
            size="sm"
            color="primary.500"
          >
            {toRupees(refundAmount)}
          </Text>
        </View>
      </View>
    </View>
  );
};

const FooterControls = ({
  btnState,
  amount,
  onProceed,
  bottomPadding,
}: {
  btnState: State;
  amount: number;
  onProceed: () => void;
  bottomPadding: number;
}) => {
  return (
    <View
      bg="background.primary.elevation"
      borderTopWidth="xs"
      borderColor="grey.50"
      pb={bottomPadding}
    >
      <View
        p="2xl"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <View flex={1}>
          <Text size="xl" color="primary.500" weight={FontWeights.MEDIUM}>
            {toRupees(amount)}
          </Text>
          <Text size="xs" color="primary.200">
            Refund Amount
          </Text>
        </View>
        <View flex={1}>
          <Button
            state={btnState}
            onPress={onProceed}
            size="lg"
            appearance={Appearance.FILLED}
          >
            Proceed
          </Button>
        </View>
      </View>
    </View>
  );
};

const BottomSheetAmountBreakUp = ({ amountBreakUp }) => (
  <View>
    <View p="2xl">
      <View mb="2xl" flexDirection="row" justifyContent="space-between">
        <Text size="sm" color="primary.400">
          Paid via UPI
        </Text>
        <Text size="sm" color="primary.400">
          {toRupees(amountBreakUp?.upiAmount)}
        </Text>
      </View>
      <View mb="2xl" flexDirection="row" justifyContent="space-between">
        <Text size="sm" color="primary.400">
          Paid via 32nd points
        </Text>
        <View flexDirection="row" alignItems="center">
          <Text mr="sm" size="sm" color="primary.400">
            {toRupees(amountBreakUp?.pointsAmount)}
          </Text>
          <Image source={PaymentsImage} style={{ height: 16, width: 16 }} />
        </View>
      </View>
      <View
        py="lg"
        borderTopWidth="xs"
        borderBottomWidth="xs"
        borderColor="grey.50"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Text size="sm" color="primary.400" weight={FontWeights.MEDIUM}>
          Bill Amount
        </Text>
        <Text size="sm" color="primary.400" weight={FontWeights.MEDIUM}>
          {toRupees(amountBreakUp?.billAmount)}
        </Text>
      </View>
    </View>
  </View>
);

const BottomSheetCancellationReason = ({
  radioConfig,
  onRadioSelect,
  onApply,
}) => {
  return (
    <View>
      <View p="2xl">
        <View pb="lg" borderBottomWidth="xs" borderColor="grey.50">
          <Text size="xl" color="primary.300">
            Select reason for cancellation
          </Text>
        </View>
        <View my="2xl">
          <RadioGroup
            radio={radioConfig}
            setRadio={onRadioSelect}
            size="lg"
            icon="right"
            collapsedCount={5}
          />
        </View>
        <View>
          <Button onPress={onApply} appearance={Appearance.FILLED} size="lg">
            Apply
          </Button>
        </View>
      </View>
    </View>
  );
};

export default EventConfirmCancellationScreenInner;
