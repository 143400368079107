/* eslint-disable import/prefer-default-export */
import { taskTypes } from '../../../../../utilities/helper';

const tempData = [
  { name: 'Cancel Task', checked: true, state: 'active' },
  { name: 'Mark Exited', checked: false, state: 'active' },
];
const doesHistoryContains = (history = [], action = '') => {
  if (!history?.length && !action) {
    return false;
  }
  const result = history.some((item) => item?.action === action);
  return result;
};

export const optionMaker = (task) => {
  if (!task) {
    return tempData;
  }

  const historyContainsArrived = doesHistoryContains(task?.history, 'arrived');

  const newTempData = JSON.parse(JSON.stringify(tempData));

  if (task?.withTerminalStatusFe) {
    // newTempData[0].state = 'disabled';
    newTempData[1].state = 'disabled';
  }
  if (task?.taskType === taskTypes.recall) {
    newTempData[0].state = 'disabled';
  }
  if (!(task?.taskType === taskTypes.recall) || !historyContainsArrived) {
    // newTempData[0].state = 'disabled';
    newTempData[1].state = 'disabled';
  }
  if (historyContainsArrived) {
    newTempData[0].state = 'disabled';
    // newTempData[1].state = 'disabled';
  }

  if (
    newTempData[0].state === 'disabled' &&
    newTempData[1].state === 'disabled'
  ) {
    newTempData[0].checked = false;
    newTempData[1].checked = false;
  } else if (
    newTempData[0].state === 'disabled' &&
    newTempData[1].state !== 'disabled'
  ) {
    newTempData[0].checked = false;
    newTempData[1].checked = true;
  } else if (
    newTempData[0].state !== 'disabled' &&
    newTempData[1].state === 'disabled'
  ) {
    newTempData[0].checked = false;
    newTempData[1].checked = true;
  } else if (
    newTempData[0].state !== 'disabled' &&
    newTempData[1].state !== 'disabled'
  ) {
    newTempData[0].checked = false;
    newTempData[1].checked = false;
  }

  return newTempData;
};
