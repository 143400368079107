import React from 'react';
import { Fade, Placeholder, PlaceholderLine } from 'rn-placeholder';
import { View } from '../../../../components/new';

const Tag = () => (
  <PlaceholderLine height={26} width={20} style={{ borderRadius: 8 }} />
);

const PointItemPlaceholder = () => {
  return (
    <View
      bg="primary.20"
      shadow="sm"
      borderRadius="md"
      px="2xl"
      pt="2xl"
      mb="2xl"
      alignItems="center"
    >
      <Placeholder Right={Tag} Animation={Fade}>
        <PlaceholderLine height={14} width={50} />
        <PlaceholderLine height={10} width={30} />
      </Placeholder>
    </View>
  );
};

export default PointItemPlaceholder;
