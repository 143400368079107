import React from 'react';
import useAuthStore from '../../stores/authStore';
import PointsScreen from '../../screens/new/PointsScreen/PointsScreen';
import RewardPointHistoryScreen from '../../screens/new/RewardPointHistoryScreen';

const PointsScreenWrapper = (props) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  if (isLoggedIn) {
    return <PointsScreen {...props} />;
  }
  return <RewardPointHistoryScreen {...props} />;
};

export default PointsScreenWrapper;
