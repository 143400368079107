/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const transactionsSearchScreenQuery = graphql`
  query transactionsSearchScreenQuery(
    $filter: findInvoiceInput!
    $sort: SortConnectioninvoiceEnum
    $pageSize: Int
  ) {
    ...transactionsFragmentInline
      @arguments(filter: $filter, sort: $sort, count: $pageSize)
  }
`;
