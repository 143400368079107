import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { Divider, Pressable, Text, View } from '../../../../components/new';
import imageConfig from '../../../../../imagekit.config';
import { applyEllipsis } from '../../../../utilities/helper';
import { getTheme } from '../../../../themes/new/theme';
import CachedImage from '../../../../components/new/custom/CachedImage';

const StyledImage = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: 999px;
`;

const TransactionCard = ({
  border = true,
  leftText = '',
  rightText = '',
  leftDsc = '',
  rightDsc = '',
  logo = '',
  bottomInfo = [],
  rightBottomComp = <></>,
  onPress = () => {},
  disablePress,
  earnedPoints,
  savedPoints,
  isBottomVisible,
  logoVisible = true,
}) => {
  const theme = useTheme();
  const imageKitURL = imageConfig.url({
    src: logo,
    transformation: [
      {
        aspectRatio: '1-1',
        format: 'webp',
        width: '80',
      },
    ],
  });

  const StyleObj = border
    ? {
        borderWidth: getTheme(theme.currentThemeMode).borderWidths.xs,
        borderColor: getTheme(theme.currentThemeMode).colors.primary[50],
        borderRadius: getTheme(theme.currentThemeMode).radii.md,
        // ...getTheme().boxShadows.sm,
        backgroundColor: getTheme(theme.currentThemeMode).colors.background
          .primary.base,
      }
    : {
        borderWidth: 0,
        borderRadius: getTheme(theme.currentThemeMode).radii.md,
        backgroundColor: getTheme(theme.currentThemeMode).colors.background
          .primary.base,
      };

  return (
    <Pressable onPress={onPress} disabled={disablePress} style={StyleObj}>
      <View p={border ? '2xl' : 0}>
        <View>
          <View flexDirection="row">
            <View flex={3}>
              <View flexDirection="row">
                {logoVisible && (
                  <View
                    height={40}
                    width={40}
                    mr="2xl"
                    bg="primary.50"
                    borderRadius={9999}
                  >
                    <StyledImage source={{ uri: imageKitURL }} />
                  </View>
                )}
                <View flex={1}>
                  <View>
                    <Text
                      size="md"
                      color="primary.400"
                      numberOfLines={1}
                      ellipsizemode="tail"
                    >
                      {leftText}
                    </Text>
                  </View>
                  <View mt="sm">
                    <Text
                      size="xs"
                      color="primary.200"
                      numberOfLines={1}
                      ellipsizemode="tail"
                    >
                      {leftDsc}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View flex={2} alignItems="flex-end" justifyContent="center">
              <View flexDirection="row">
                <View flex={1}>
                  <Text
                    size="md"
                    color="primary.500"
                    textAlign="right"
                    numberOfLines={1}
                    ellipsizemode="tail"
                    alignItems="flex-end"
                  >
                    {applyEllipsis(rightText, 8)}
                  </Text>
                  {!!rightDsc && (
                    <Text
                      size="xs"
                      color="primary.100"
                      textAlign="right"
                      numberOfLines={1}
                      ellipsizemode="tail"
                      mt="sm"
                    >
                      {rightDsc}
                    </Text>
                  )}
                  <View alignItems="flex-end" mt="sm">
                    {rightBottomComp}
                  </View>
                </View>
              </View>
            </View>
          </View>
          {!!isBottomVisible && (
            <View>
              {(!!earnedPoints || !!savedPoints) && (
                <View mt="2xl">
                  <Divider />
                </View>
              )}
              {(!!earnedPoints || !!savedPoints) && (
                <View flexDirection="row" mt="2xl">
                  {!!earnedPoints && (
                    <View
                      bg="success.10"
                      borderRadius="md"
                      py="sm"
                      px="lg"
                      ml={0}
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Text size="xs" color="primary.400">
                        Earned {parseInt(earnedPoints, 10)}
                      </Text>
                      <CachedImage
                        style={{ height: 16, width: 16 }}
                        source={require('../../../../../assets/images/coins.webp')}
                      />
                    </View>
                  )}
                  {!!savedPoints && (
                    <View
                      bg="success.10"
                      borderRadius="md"
                      py="sm"
                      px="lg"
                      ml="lg"
                    >
                      <Text color="primary.400" size="xs">
                        {`₹${parseInt(savedPoints, 10)} Saved`}
                      </Text>
                    </View>
                  )}
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </Pressable>
  );
};
export default TransactionCard;
