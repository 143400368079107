import React, { useMemo, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Keyboard, TouchableWithoutFeedback } from 'react-native';
import { useMutation } from 'react-relay';
import AnimatedLottieView from 'lottie-react-native';
import {
  Button,
  IconButton,
  Input,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import { navigateBack } from '../../../../utilities/helper';
import useUserStore from '../../../../stores/userStore';
import { InputStates } from '../../../../components/new/primitive/Input/helpers';
import sendRequestChangeMailMutation from './API/requestChangeMailMutation';
import { requestChangeMailMutation } from './API/__generated__/requestChangeMailMutation.graphql';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';
import { FontWeights } from '../../../../themes/new/helper';

const GirlWithLaptop = require('../../../../../assets/lottie/girl-with-laptop.json');

const RequestChangesFormScreenInner = () => {
  const bsRef = useRef();
  const { bottom } = useSafeAreaInsets();
  const userRole = useUserStore((state) => state.role);
  const navigation = useNavigation();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const [message, setMessage] = useState<string>('');
  const [commitMutation, isMutationInFlight] =
    useMutation<requestChangeMailMutation>(sendRequestChangeMailMutation);

  const handleTextChange = (value: string) => {
    setMessage(value);
  };

  const handleFormSubmit = () => {
    if (isMutationInFlight) return;
    commitMutation({
      variables: {
        record: {
          to: 'PRODUCT',
          subjectType: 'RESTAURANT_PARTNER_REQUEST_CHANGES',
          body: {
            message,
          },
        },
      },
      onCompleted: (response, errors) => {
        setMessage('');
        if (response?.sendMail?.status === 200) {
          bsRef?.current?.show();
        } else {
          dispatchSnackbar({
            msg: `Oops, Something went wrong!`,
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
          });
        }
      },
    });
  };

  return (
    <View flex={1}>
      <TopNavigation
        appearance="ghost"
        title="Request Changes"
        level="1"
        textSize="md"
        textColor="primary.500"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
            }}
          />
        }
      />
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        <View flex={1} justifyContent="space-between">
          <View px="2xl" pt="2xl">
            <Text
              mb="2xl"
              pb="sm"
              size="lg"
              color="primary.400"
              weight={FontWeights.MEDIUM}
            >
              Request Changes
            </Text>
            <Input
              state={InputStates.active}
              multiline
              numberOfLines={6}
              inputMode="text"
              label="Enter your request"
              labelFontFamily="body"
              labelFontSize="sm"
              placeholder="Enter the changes required"
              value={message}
              onChangeText={handleTextChange}
              borderColor="primary.50"
              style={{ borderColor: 'white', textAlignVertical: 'top' }}
              autoFocus
              returnKeyType="done"
            />
          </View>
          <View p="2xl" mb={bottom}>
            <Button
              size="lg"
              status="primary"
              state={
                message.trim().length
                  ? InputStates.active
                  : InputStates.disabled
              }
              onPress={handleFormSubmit}
              loading={isMutationInFlight}
            >
              Submit Request
            </Button>
          </View>
        </View>
      </TouchableWithoutFeedback>
      <BottomSheetV2 ref={bsRef} insets={false}>
        <View>
          <View
            px="5xl"
            mt="2xl"
            bg="white"
            height={300}
            borderRadius="xl"
            alignItems="center"
          >
            <AnimatedLottieView
              style={{ width: 200 }}
              autoPlay
              loop
              source={GirlWithLaptop}
            />
            <Text mt="2xl" textAlign="center" size="2xl" color="primary.500">
              The 32nd team will get back to you on your request!
            </Text>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default RequestChangesFormScreenInner;
