/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, Platform, useWindowDimensions } from 'react-native';
import { graphql, useFragment } from 'react-relay';
import { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import * as Linking from 'expo-linking';
import { useRoute } from '@react-navigation/native';
import { StarRatingVariants } from '../../../components/new/composites/StarRating/helpers/helpers';
import StarRating from '../../../components/new/composites/StarRating/StarRating';
import { getTheme, themeModes } from '../../../themes/new/theme';
import {
  Text,
  View,
  Icon,
  Button,
  IconButton,
  Pressable,
  Divider,
} from '../../../components/new';
import {
  handlePhone,
  handleDirections,
} from '../../../utilities/handleLinking';
import {
  applyEllipsis,
  Capitalise,
  formatNumber,
  navigateBack,
  roundOffToMultiple,
  convertTimeStringToDate,
  getMinutesBetweenDates,
  paymentOrderTypeEnum,
  SplitAndCapitalise,
  RestaurantType,
  PaymentGatewayType,
} from '../../../utilities/helper';
import NotableMentionCard from '../../../components/new/custom/NotableMentionCard';
import AwardsAndRecognitionCard from '../../../components/new/custom/AwardsAndRecognitionCard';
import VideoPlayer from '../../../components/new/custom/VideoPlayer';
import ParallaxHeader from '../../../components/new/custom/ParallaxHeader';
import useBoundStore from '../../../stores';
import PaybillFooter from '../payment/PaybillFooter';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import useUserStore from '../../../stores/userStore';
import { getPaymentInfo } from '../../API/getPaymentInfo';
import RestaurantsTimingBottomsheet from './RestaurantsTimingBottomsheet';
import WalkInOfferCard from '../WalkInOfferCard';
import {
  CustomerDiscountTimingEnum,
  MealTimeEnum,
} from '../../restaurantPartner/discounts/helper';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import CachedImage from '../../../components/new/custom/CachedImage';

const isMenuEnabled = (restaurant) => {
  if (
    restaurant?.type === 'RESTAURANT' &&
    restaurant?.displayName === 'The Monk'
  )
    return true;
  return false;
};

const Restaurant = (props) => {
  const {
    findRestaurants2,
    navigation,
    userRole,
    restaurantId,
    getRestaurantpartnerDiscount,
    findConfig,
  } = props;
  const route = useRoute();
  const tableNumber = parseInt(route?.params?.tableNumber, 10);
  const setOrderDetails = useBoundStore((state) => state?.setOrderDetails);
  const [numberOfLines, setNumberOfLines] = useState(0);
  const [textShown, setTextShown] = useState(false);
  const restaurantsTimingBottomSheetRef = useRef(null);
  const { wallet } = useUserStore((state) => state);
  const [authorVideoSectionOffset, setAuthorVideoSectionOffset] = useState(0);
  const [restaurantOpenHours, setRestaurantOpenHours] = useState(new Map());
  const [play, setPlay] = useState(false);
  const bottomSheetModalRef = useRef(null);

  const handlePresentModalPress = useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);

  const handleOnDismiss = useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  useEffect(() => {
    // this is done when customer has scanned table QR standy via default camera app
    // and is redirected to the restaurant page with table number.
    if (Number.isNaN(tableNumber) === false) {
      setOrderDetails({
        tableNumber,
        restaurantId,
      });
    } else {
      setOrderDetails({
        tableNumber: null,
      });
    }
  }, [tableNumber]);

  const restaurantData = useFragment(
    graphql`
      fragment restaurantDetail_findRestaurants2 on restaurants2
      @relay(plural: true) {
        _id
        type
        displayName
        logo
        type
        menuId
        description {
          summary
          tagLine
          cusines {
            _id
            name
            primary
          }
          tags {
            _id
            name
            icon {
              iconName
              color
            }
          }
          cost2pax
        }
        amenities
        overAllRating
        openHours {
          day
          opensAt
          closesAt
        }
        ratings {
          _id
          platform
          displayIcon
          ratingScore
          ratingCount
        }
        ratingTagLine
        address {
          _id
          addressLine1
          geo {
            lat
            long
          }
        }
        campus {
          _id
        }
        mediaGallery {
          _id
          sectionTitle
          description
          media {
            _id
            title
            description
            mediaURL
            mediaType
            sectionCover
            cover
            prime
            coverVideo
            displayOrder
          }
          displayOrder
        }
        externalLinks {
          _id
          title
          readingTime
          posterURL
          webURL
          publisher
        }
        contact {
          phone {
            prefix
            number
          }
        }
        openStatus {
          status
        }
        menu {
          restaurantId
          menu {
            sectionTitle
            _id
          }
        }
        popularMenuItems {
          id
          title
          images {
            type
            mediaUrl
            _id
          }
        }
        payBill {
          payEnabled
          pointsEnabled {
            redeem
            reward
          }
        }
        payBillCashback
        speciality {
          name
          icon {
            iconName
            color
          }
        }
      }
    `,
    findRestaurants2,
  );

  const discountsData = useFragment(
    graphql`
      fragment restaurantDetail_getRestaurantpartnerDiscount on RestaurantDiscountGetTC
      @relay(plural: true) {
        _id
        restaurantId
        discounts {
          allUser
          newUser
          corporateUser
        }
        days
        mealTime
        isActive
        user {
          isEmployee
          isNewUser
        }
      }
    `,
    getRestaurantpartnerDiscount,
  );

  const config = findConfig;

  const {
    _id,
    logo,
    displayName,
    overAllRating,
    description,
    openHours,
    ratings: ratingsTemp,
    address,
    mediaGallery,
    externalLinks,
    contact,
    openStatus,
    menu,
    popularMenuItems,
    ratingTagLine,
    temporaryClose,
    payBill,
    amenities,
    speciality,
    type,
    payBillCashback,
  } = restaurantData[0] ?? {};

  const isBillPayEnabled =
    payBill?.payEnabled &&
    payBill?.pointsEnabled?.redeem &&
    payBill?.pointsEnabled?.reward;

  // === change rating order in descending
  let ratings =
    ratingsTemp &&
    ratingsTemp?.length &&
    JSON.parse(JSON.stringify(ratingsTemp));
  ratings =
    ratings &&
    ratings?.length &&
    ratings.sort((item, nextItem) => {
      return nextItem.ratingScore - item.ratingScore;
    });

  const { width, height } = useWindowDimensions();
  const sortFn = (a, b) => {
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    return 0;
  };

  // const onTextLayout = useCallback((e) => {
  //   setLongText(e.nativeEvent.lines.length >= 3); // to check the text is more than 3 lines or not
  // }, []);

  const onLayout = useCallback((e) => {
    if (Math.floor(e.nativeEvent.layout.height / 22) > 3) {
      setNumberOfLines(Math.floor(e.nativeEvent.layout.height / 22));
    }
  }, []);

  const onPressMenu = (itemId = '') => {
    firebaseEventLogger('restaurantScreen__menu_Tap', {
      buttonName: 'menu',
      screenName: 'restaurantScreen',
      userType: 'User',
      interactionType: 'Tap',
      restaurantName: displayName,
    });
    navigation.navigate('menu', {
      restaurantId: _id,
      itemId,
    });
  };

  const [discountPercentage, setDiscountPercentage] = useState(0);
  useEffect(() => {
    getPaymentInfo({
      amount: 100,
      useRewardPoints: true,
      orderId: _id,
      orderType: 'RESTAURANT',
    })
      .then((res) => {
        const discountPercentageRes = res?.getNetPrice?.discountPercentage;
        if (discountPercentageRes) {
          setDiscountPercentage(discountPercentageRes);
        }
      })
      .catch((e) => {
        setDiscountPercentage(0);
      });
  }, []);

  const handlePayBill = () => {
    const restaurantName = restaurantData?.[0]?.displayName;
    firebaseEventLogger('pay_bill', {
      buttonName: 'pay_bill',
      screenName: 'restaurant_screen',
      userType: 'user',
      interactionType: 'Tap',
      restaurantId: _id,
      restaurantName,
    });
    if (!isBillPayEnabled) {
      navigation.navigate('paymentScreen', {
        orderId: _id,
        orderType: paymentOrderTypeEnum.restaurant,
        paymentMethod: PaymentGatewayType.BILL_UPLOAD,
      });
    } else {
      navigation.navigate('paymentScreen', {
        orderId: _id,
        orderType: paymentOrderTypeEnum.restaurant,
      });
    }
  };

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole || null);
  };

  const scrollListener = (event) => {
    if (authorVideoSectionOffset !== 0) {
      if (
        event.nativeEvent.contentOffset.y <= authorVideoSectionOffset &&
        play
      ) {
        setPlay(false);
      }
    }
  };

  const navigateToImageGallery = () => {
    firebaseEventLogger('RestaurantScreenGalleryPhotoClicked');
    navigation.navigate('RestaurantScreenGallery', {
      restaurantId,
    });
  };

  const navigateToDiscounts = () => {
    firebaseEventLogger('RestaurantScreenViewAllOffersClicked');
    navigation.navigate('DicountAndOffer', {
      restaurantId,
      payBillCashback,
    });
  };

  const navigateToImageGalleryAwardsSection = () => {
    navigation.navigate('RestaurantScreenGallery', {
      restaurantId,
      imageSection: 'Awards & Recognitions',
    });
  };

  const MapRestaurantOpenHours = (dates) => {
    const data = new Map();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dates.length; i++) {
      const concatvalue = (dates[i].opensAt + dates[i].closesAt).replace(
        /\s/g,
        '',
      );
      // console.log(key)
      if (data.has(concatvalue)) {
        const tempData = data.get(concatvalue);
        data.set(concatvalue, [...tempData, dates[i]]);
      } else {
        data.set(concatvalue, [dates[i]]);
      }
    }
    return data;
  };

  useEffect(() => {
    if (openHours && openHours?.length) {
      setRestaurantOpenHours(MapRestaurantOpenHours(openHours));
    }
  }, []);

  const awards =
    mediaGallery &&
    mediaGallery?.length &&
    mediaGallery
      ?.filter((item) => item?.sectionTitle === 'Awards___Recognitions')
      .map((ele) => ele?.media)
      .flat()
      .slice(0, 5);

  const founderStory =
    mediaGallery &&
    mediaGallery?.length &&
    mediaGallery?.filter((item) => item?.sectionTitle === 'Founder_Story');

  const sortByMultipleProperties = (a, b) => {
    if (a.cover === b.cover) {
      if (a.cover && a.mediaType === 'video') {
        return -1;
      }
      if (b.cover && b.mediaType === 'video') {
        return 1;
      }
    }
    return b.cover - a.cover;
  };

  const coverImages =
    mediaGallery && mediaGallery?.length
      ? JSON.parse(JSON.stringify(mediaGallery))
          .filter((ele) => ele?.sectionTitle !== 'Founder_Story')
          .sort(sortFn)
          .map((ele) => ele?.media && ele?.media.sort(sortFn))
          .flat()
          .sort(sortByMultipleProperties)
      : [];

  const weekDays: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const currentDay: string = weekDays[new Date().getDay()];
  const nextDay: string = weekDays[(new Date().getDay() + 1) % 7];
  const isStoreOpen: boolean = openStatus?.status.toLowerCase() === 'open';

  const currentDayTimings =
    openHours && openHours?.find((item) => item?.day === currentDay);

  const nextDayTimings =
    openHours && openHours?.find((item) => item?.day === nextDay);

  const isStoreClosedAllDay: boolean =
    currentDayTimings?.opensAt === '00:00 AM' &&
    currentDayTimings?.closesAt === '00:00 AM';

  const isStoreClosedNextDay: boolean =
    nextDayTimings?.opensAt === '00:00 AM' &&
    nextDayTimings?.closesAt === '00:00 AM';

  const isStoreOpenFullDayToday: boolean =
    currentDayTimings?.opensAt === '00:00 AM' &&
    currentDayTimings?.closesAt === '11:59 AM';

  const isStoreOpenFullDayNextDay: boolean =
    nextDayTimings?.opensAt === '00:00 AM' &&
    nextDayTimings?.closesAt === '11:59 AM';

  let storeOpenCloseText = '';
  const isTempClosed = !!temporaryClose;

  if (openHours && openHours.length === 7) {
    if (isStoreOpen) {
      const currentTime: Date = new Date();
      if (isStoreOpenFullDayToday && isStoreOpenFullDayNextDay) {
        storeOpenCloseText = '';
      } else {
        const closingTime: Date = convertTimeStringToDate(
          currentDayTimings.closesAt,
        );
        if (currentTime > closingTime) {
          closingTime.setDate(closingTime.getDate() + 1);
        }
        const diffInMinutes = getMinutesBetweenDates(closingTime, currentTime);
        if (diffInMinutes < 60) {
          storeOpenCloseText = `Closes in ${diffInMinutes} min`;
        }
      }
    } else {
      const currentTime: Date = new Date();
      const currentDayOpeningTime = convertTimeStringToDate(
        currentDayTimings?.opensAt,
      );

      if (currentDayOpeningTime > currentTime) {
        const diffInMinutes = getMinutesBetweenDates(
          currentDayOpeningTime,
          currentTime,
        );
        if (diffInMinutes < 60) {
          storeOpenCloseText = `Opens in ${diffInMinutes} min`;
        } else {
          storeOpenCloseText = `Opens at ${currentDayTimings?.opensAt}`;
        }
      } else if (isStoreClosedNextDay) {
        storeOpenCloseText = '';
      } else {
        storeOpenCloseText = 'Opens Tomorrow';
      }
    }
  }

  const handleTimingPress = useCallback(() => {
    firebaseEventLogger('RestaurantScreenTimingText');
    restaurantsTimingBottomSheetRef?.current?.show();
  }, []);
  const renderItem = ({ item, index }) => {
    if (item?.user?.isNewUser && item?.discounts?.newUser > 0) {
      return (
        <View ml="2xl" my="2xl" mr={index === discountsData.length ? '2xl' : 0}>
          <WalkInOfferCard
            title={`Flat ${item?.discounts?.newUser}% Off`}
            subTitle={
              item?.mealTime === MealTimeEnum.LUNCH
                ? CustomerDiscountTimingEnum.LUNCHTIMING
                : CustomerDiscountTimingEnum.DINNERTIMING
            }
            bottomText="For members only"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../../assets/images/black_membership/black-member-logo-3.webp')}
            forBlackMember
          />
        </View>
      );
    }
    if (item?.user?.isEmployee && item?.discounts?.corporateUser > 0) {
      return (
        <View ml="2xl" my="2xl" mr={index === discountsData.length ? '2xl' : 0}>
          <WalkInOfferCard
            title={`Flat ${item?.discounts?.corporateUser}% Off`}
            subTitle={
              item?.mealTime === MealTimeEnum.LUNCH
                ? CustomerDiscountTimingEnum.LUNCHTIMING
                : CustomerDiscountTimingEnum.DINNERTIMING
            }
            bottomText="For members only"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../../assets/images/black_membership/black-member-logo-3.webp')}
            forBlackMember
          />
        </View>
      );
    }

    if (item?.payBillCashback > 0) {
      return (
        <View ml="2xl" my="2xl" mr={index === discountsData.length ? '2xl' : 0}>
          <WalkInOfferCard
            title={`${item?.payBillCashback}% Cashback`}
            subTitle="Exclusively for you"
            bottomText="For members only"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../../assets/images/black_membership/black-member-logo-3.webp')}
            forBlackMember
          />
        </View>
      );
    }

    if (item && item?.currentBalance > 0) {
      return (
        <View my="2xl" mr="2xl">
          <WalkInOfferCard
            title={`Extra ₹${item?.currentBalance} Off`}
            subTitle="32nd Points"
            bottomText="Exclusively for you"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../../assets/images/coins.webp')}
          />
        </View>
      );
    }

    if (
      !item?.user?.isEmployee &&
      !item?.user?.isNewUser &&
      item?.discounts?.allUser > 0
    ) {
      return (
        <View ml="2xl" my="2xl" mr={index === discountsData.length ? '2xl' : 0}>
          <WalkInOfferCard
            title={`Flat ${item?.discounts?.allUser}% Off`}
            subTitle={
              item?.mealTime === MealTimeEnum.LUNCH
                ? CustomerDiscountTimingEnum.LUNCHTIMING
                : CustomerDiscountTimingEnum.DINNERTIMING
            }
            bottomText="For members only"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../../assets/images/black_membership/black-member-logo-3.webp')}
            forBlackMember
          />
        </View>
      );
    }

    return <></>;
  };

  const getPayBillFooterDiscount = () => {
    if (
      discountsData[0]?.user?.isNewUser &&
      discountsData[0]?.discounts?.newUser > 0
    ) {
      return discountsData[0]?.discounts?.newUser;
    }
    if (
      discountsData[0]?.user?.isEmployee &&
      discountsData[0]?.discounts?.corporateUser > 0
    ) {
      return discountsData[0]?.discounts?.corporateUser;
    }
    if (
      !discountsData[0]?.user?.isEmployee &&
      !discountsData[0]?.user?.isNewUser &&
      discountsData[0]?.discounts?.corporateUser > 0
    ) {
      return discountsData[0]?.discounts?.allUser;
    }
    return '';
  };

  const checkArray = discountsData?.every(
    (item) =>
      item?.discounts?.allUser > 0 ||
      item?.discounts?.newUser > 0 ||
      item?.discounts?.corporateUser > 0,
  );

  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider
          theme={
            theme.currentThemeMode === themeModes.light
              ? getTheme(themeModes.light)
              : getTheme(themeModes.dark)
          }
        >
          <>
            <ParallaxHeader
              minHeight={0}
              images={
                coverImages && coverImages?.length
                  ? coverImages.slice(0, 6)
                  : []
              }
              navTitle={displayName}
              onPressIcon={onPressLeftIcon}
              leftIconName="back-outline-300"
              scrollListener={scrollListener}
              onPress={navigateToImageGallery}
            >
              <View mb="7xl" backgroundColor="transparent">
                <View mt="7xl">
                  <View px="2xl" flexDirection="row" alignItems="center">
                    <CachedImage
                      source={{ uri: logo }}
                      style={{
                        width: theme.sizes.xl,
                        height: theme.sizes.xl,
                        borderRadius: theme.radii.full,
                        resizeMode: 'cover',
                      }}
                    />
                    <View ml="2xl" flex={1}>
                      <View
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <View width="75%">
                          <Text
                            size="2xl"
                            color="primary.400"
                            numberOfLines={3}
                          >
                            {displayName}
                          </Text>
                        </View>
                        {overAllRating > 0 ? (
                          <StarRating
                            ratingScore={overAllRating}
                            size={StarRatingVariants.md}
                          />
                        ) : (
                          <></>
                        )}
                      </View>
                      <Pressable onPress={handleTimingPress}>
                        <View
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="flex-start"
                          alignSelf="flex-start"
                          py="sm"
                          px="xl"
                          borderRadius="md"
                          borderWidth="xs"
                          borderColor="primary.50"
                        >
                          {!isTempClosed ? (
                            <View flexDirection="row">
                              <Text
                                size="xs"
                                color={
                                  openStatus?.status.toLowerCase() === 'closed'
                                    ? 'error.500'
                                    : 'success.500'
                                }
                              >
                                {openStatus?.status.toLowerCase() === 'closed'
                                  ? 'Closed'
                                  : 'Open Now'}
                              </Text>
                              <Text size="xs" color="primary.50">
                                {storeOpenCloseText ? ' | ' : ''}
                              </Text>
                              <Text size="xs" color="primary.100">
                                {storeOpenCloseText}
                              </Text>
                            </View>
                          ) : (
                            <Text size="xs" color="error.500">
                              {Capitalise('Temporarily Closed')}
                            </Text>
                          )}
                        </View>
                      </Pressable>
                    </View>
                  </View>
                  {discountsData &&
                  discountsData?.length &&
                  payBill?.pointsEnabled?.redeem &&
                  payBill?.pointsEnabled?.reward ? (
                    <View mt="4xl">
                      <View
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        mt="2xl"
                        px="2xl"
                      >
                        <Text size="xl" color="primary.500" weight="medium">
                          Walk-in offers
                        </Text>
                        <Button
                          size="md"
                          iconRight="keyboard-arrow-right-300"
                          appearance="ghost"
                          iconSize="xl"
                          onPress={navigateToDiscounts}
                        >
                          View All
                        </Button>
                      </View>
                      <FlatList
                        data={[
                          ...discountsData,
                          { payBillCashback },
                          wallet?.currentBalance ? wallet : {},
                        ]}
                        renderItem={renderItem}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                      />
                    </View>
                  ) : (
                    <></>
                  )}
                  {speciality && speciality.length > 0 ? (
                    <View px="2xl" mt="2xl">
                      <Text size="xl" weight="medium" color="primary.500">
                        Our Specialities
                      </Text>
                      <View
                        mt="lg"
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        {speciality?.map((item, index) =>
                          item?.name && item?.name?.length ? (
                            <View
                              flexDirection="row"
                              justifyContent="center"
                              alignItems="flex-end"
                              // mt="sm"
                              mr="4xl"
                              py="md"
                              key={item?._id}
                            >
                              {item?.icon !== null &&
                              Object.keys(item?.icon).length > 0 ? (
                                <Icon
                                  name={item?.icon?.iconName}
                                  size="xl"
                                  // color={`${item?.icon?.color}.500`}
                                  color="primary.200"
                                />
                              ) : null}
                              <Text
                                size="xs"
                                color="primary.300"
                                ml="xs"
                                // category="tag"
                                weight="medium"
                              >
                                {item?.name && item?.name?.length < 15
                                  ? SplitAndCapitalise(item?.name)
                                  : applyEllipsis(
                                      SplitAndCapitalise(item?.name),
                                      15,
                                    )}
                                {/* : `${item?.name?.slice(0, 15)}...`} */}
                              </Text>
                            </View>
                          ) : (
                            <></>
                          ),
                        )}
                      </View>
                    </View>
                  ) : null}
                  {amenities && amenities?.length ? (
                    <View mt="2xl" px="2xl">
                      {/* <ReadMore
                        numberOfLines={3}
                        renderTruncatedFooter={renderTruncatedFooter}
                        renderRevealedFooter={renderRevealedFooter}
                        onReady={handleTextReady}
                      >
                        <Text size="sm" color="primary.200">
                          {description && description?.summary}
                        </Text>
                      </ReadMore> */}
                      <Text size="xl" weight="medium" color="primary.500">
                        Our Facilities
                      </Text>
                      <FlatList
                        data={amenities}
                        renderItem={({ item, index }) => (
                          <View width="50%" alignSelf="flex-start" mt="lg">
                            <View flexDirection="row" alignItems="center">
                              <Icon
                                name="check-outline-400-1"
                                color="primary.300"
                                size="lg"
                              />
                              <Text size="sm" color="primary.300" ml="xs">
                                {SplitAndCapitalise(item)}
                              </Text>
                            </View>
                          </View>
                        )}
                        numColumns={2}
                        columnWrapperStyle={{ justifyContent: 'space-between' }}
                      />
                    </View>
                  ) : (
                    <></>
                  )}
                  {description &&
                  description?.summary &&
                  description?.summary?.length ? (
                    <View mt="2xl" px="2xl">
                      {/* <ReadMore
                        numberOfLines={3}
                        renderTruncatedFooter={renderTruncatedFooter}
                        renderRevealedFooter={renderRevealedFooter}
                        onReady={handleTextReady}
                      >
                        <Text size="sm" color="primary.200">
                          {description && description?.summary}
                        </Text>
                      </ReadMore> */}
                      <Text size="xl" weight="medium" color="primary.500">
                        About{' '}
                        {type === RestaurantType.RESTAURANT
                          ? 'Restaurant'
                          : 'Store'}
                      </Text>
                      <Text
                        size="sm"
                        color="primary.300"
                        mt="lg"
                        // onTextLayout={onTextLayout}
                        onLayout={onLayout}
                        numberOfLines={
                          numberOfLines > 0
                            ? numberOfLines > 3 && !textShown
                              ? 3
                              : numberOfLines
                            : undefined
                        }
                      >
                        {description && description?.summary}
                      </Text>

                      {numberOfLines >= 3 && !textShown ? (
                        <Text
                          size="sm"
                          color="primary.300"
                          weight="medium"
                          // onPress={toggleNumberOfLines}
                          onPress={() => {
                            setNumberOfLines(6);
                            setTextShown(true);
                          }}
                        >
                          read more
                        </Text>
                      ) : (
                        <></>
                      )}
                    </View>
                  ) : (
                    <></>
                  )}
                  {description &&
                  description?.cusines &&
                  description?.cusines?.length > 0 ? (
                    <View mt="2xl" px="2xl">
                      <Text size="md" color="primary.500">
                        Cuisines
                      </Text>
                      <View
                        mt="sm"
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        {description?.cusines?.map((item, index) =>
                          item?.name && item?.name?.length ? (
                            <View
                              flexDirection="row"
                              justifyContent="center"
                              alignItems="flex-end"
                              // mt="sm"
                              key={item?._id}
                            >
                              <Text size="sm" color="primary.300">
                                {SplitAndCapitalise(item?.name)}
                                {index < description?.cusines?.length - 1
                                  ? ', '
                                  : ''}
                                {/* : `${item?.name?.slice(0, 15)}...`} */}
                              </Text>
                            </View>
                          ) : (
                            <></>
                          ),
                        )}
                      </View>
                    </View>
                  ) : null}
                </View>
                {description && description?.cost2pax ? (
                  <View mt="4xl" px="2xl">
                    <View>
                      <Text size="md" color="primary.500">
                        Cost
                      </Text>
                      <Text size="sm" mt="sm" color="primary.300">
                        Cost for two - &#8377;{' '}
                        {description && description?.cost2pax} (approx.)
                      </Text>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
                {type === RestaurantType.RESTAURANT ? (
                  <View mt="4xl" px="2xl">
                    <View
                      flexDirection="row"
                      // justifyContent="space-between"
                      alignItems="center"
                    >
                      <View flex={1} pr="4xl" justifyContent="flex-start">
                        <Text size="md" color="primary.500">
                          Reserve Table
                        </Text>
                        <Text size="sm" mt="sm" color="primary.300">
                          Call the reservation desk to reserve your table
                          immediately
                        </Text>
                      </View>
                      <View justifyContent="flex-end">
                        <IconButton
                          size="md"
                          iconSize="xl"
                          iconColor="primary.500"
                          appearance="outline"
                          name="call-filled-300"
                          shadow="none"
                          onPress={() => {
                            firebaseEventLogger(
                              'RestaurantListingReserveTableClicked',
                            );
                            const { prefix, number } = contact?.phone[0] ?? {};
                            handlePhone(prefix + number);
                          }}
                        />
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
                <View mt="4xl" px="2xl">
                  <View flexDirection="row" alignItems="center">
                    <View flex={1} pr="4xl" justifyContent="flex-start">
                      <Text size="md" color="primary.500">
                        Location
                      </Text>
                      <Text
                        size="sm"
                        mt="sm"
                        color="primary.300"
                        numberOfLines={2}
                      >
                        {address?.addressLine1}
                      </Text>
                    </View>
                    <View justifyContent="flex-end">
                      <IconButton
                        size="md"
                        iconSize="xl"
                        iconColor="primary.500"
                        appearance="outline"
                        shadow="none"
                        name="navigate-filled-300"
                        onPress={() => {
                          firebaseEventLogger(
                            'RestaurantListingLocationIconClicked',
                          );
                          const { lat, long } = address?.geo ?? {};
                          handleDirections(lat, long);
                        }}
                      />
                    </View>
                  </View>
                </View>
                {overAllRating ? (
                  <View mt="7xl" px="2xl">
                    <View py="2xl">
                      <Text size="xl" color="primary.500" weight="medium">
                        32nd Rating & Review
                      </Text>
                    </View>
                    <View
                      mt="lg"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <View
                        justifyContent="flex-start"
                        width={ratings && ratings?.length ? '68%' : '100%'}
                        borderRightWidth={ratings && ratings?.length ? 1 : 0}
                        borderRightColor="primary.50"
                      >
                        <Text
                          size="1xl"
                          lineHeight={Platform.OS === 'android' ? 'xl' : '2xl'}
                          color="primary.500"
                        >
                          {overAllRating?.toFixed(1)}
                          <Text size="md">/5</Text>
                        </Text>
                        <View mt="lg" flexDirection="row" alignItems="center">
                          <Text size="sm" color="primary.400">
                            Overall Rating
                          </Text>
                          <Pressable
                            style={{ marginLeft: theme.space.sm }}
                            onPress={() => {
                              handlePresentModalPress();
                            }}
                          >
                            <Icon
                              name="info-outline-300"
                              color="info.500"
                              size="md"
                            />
                          </Pressable>
                        </View>
                        {/* <View mt="4xl" mr="3xl" flexWrap="wrap"> */}
                        <Text
                          mt="4xl"
                          mr="3xl"
                          size="sm"
                          color="primary.300"
                          numberOfLines={4}
                        >
                          {ratingTagLine}
                        </Text>
                        {/* </View> */}
                      </View>
                      <View ml="3xl">
                        {ratings && ratings?.length ? (
                          ratings.slice(0, 2)?.map((item, index) => (
                            <View
                              alignItems="center"
                              mt={index !== 0 ? '6xl' : 0}
                              key={item?._id}
                            >
                              {item?.ratingScore && item?.ratingScore > 0 ? (
                                <>
                                  <Text size="md" color="primary.300">
                                    {item?.ratingScore}
                                    <Text size="xs">/5</Text>
                                  </Text>
                                </>
                              ) : (
                                <></>
                              )}
                              {item?.ratingCount && item?.ratingCount > 0 ? (
                                <Text mt="xs" size="xs" color="primary.200">
                                  {item?.ratingCount < 1000
                                    ? item?.ratingCount > 100
                                      ? roundOffToMultiple(
                                          item?.ratingCount,
                                          100,
                                        )
                                      : roundOffToMultiple(
                                          item?.ratingCount,
                                          10,
                                        )
                                    : formatNumber(item?.ratingCount, 1)}
                                  + Ratings
                                </Text>
                              ) : (
                                <></>
                              )}
                              <View mt="lg">
                                <CachedImage
                                  source={{ uri: item?.displayIcon }}
                                  style={{
                                    width: theme.space['3xl'],
                                    height: theme.space['3xl'],
                                    resizeMode: 'cover',
                                  }}
                                />
                              </View>
                            </View>
                          ))
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
                {externalLinks && externalLinks?.length ? (
                  <View mt="7xl" px="2xl">
                    <View py="2xl">
                      <Text size="xl" weight="medium" color="primary.500">
                        Notable Mentions
                      </Text>
                    </View>
                    {externalLinks &&
                      externalLinks?.length &&
                      externalLinks?.slice(0, 3).map((item, index) => (
                        <View mt={index === 0 ? 'lg' : '2xl'} key={item?._id}>
                          <NotableMentionCard
                            posterURL={item?.posterURL}
                            webURL={item?.webURL}
                            title={item?.title}
                            readingTime={item?.readingTime}
                            publisher={item?.publisher}
                            navigation={navigation}
                            theme={
                              theme.currentThemeMode === themeModes.light
                                ? getTheme(themeModes.light)
                                : getTheme(themeModes.dark)
                            }
                            onPress={() => {
                              if (Platform.OS === 'web') {
                                Linking.canOpenURL(item?.webURL)
                                  .then(() => {
                                    window.open(item?.webURL, '_blank');
                                  })
                                  .catch((err) => {
                                    // console.log(err)
                                  });
                              } else {
                                navigation.navigate('CustomWebview', {
                                  url: item?.webURL,
                                  title: 'Notable Mentions',
                                });
                              }
                            }}
                          />
                        </View>
                      ))}
                  </View>
                ) : (
                  <></>
                )}
                {awards && awards?.length ? (
                  <View mt="7xl">
                    <View
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px="2xl"
                      pt="2xl"
                    >
                      <Text size="xl" color="primary.500" weight="medium">
                        Awards & Recognitions
                      </Text>
                      <Button
                        size="md"
                        iconRight="keyboard-arrow-right-300"
                        appearance="ghost"
                        iconSize="xl"
                        onPress={navigateToImageGalleryAwardsSection}
                      >
                        See All
                      </Button>
                    </View>
                    <FlatList
                      data={awards}
                      horizontal
                      keyExtractor={(item) => item?._id}
                      contentContainerStyle={{
                        paddingVertical: theme.space['4xl'],
                      }}
                      renderItem={({ item, index }) => (
                        <View
                          ml="2xl"
                          mr={index === awards?.length - 1 ? '2xl' : 0}
                          key={item?._id}
                        >
                          <AwardsAndRecognitionCard
                            url={item?.mediaURL}
                            // showLastItem={index === awards?.length - 1}
                            onPress={navigateToImageGalleryAwardsSection}
                            theme={
                              theme.currentThemeMode === themeModes.light
                                ? getTheme(themeModes.light)
                                : getTheme(themeModes.dark)
                            }
                          />
                        </View>
                      )}
                      showsHorizontalScrollIndicator={false}
                      directionalLockEnabled
                      // initialNumToRender={2}
                      // windowSize={3}
                      // maxToRenderPerBatch={2}
                      scrollEventThrottle={16}
                    />
                  </View>
                ) : (
                  <></>
                )}
                {founderStory && founderStory?.length ? (
                  <>
                    <View mt="7xl" px="2xl">
                      <View py="2xl">
                        <Text size="xl" weight="medium" color="primary.500">
                          Brand Story
                        </Text>
                      </View>
                    </View>
                    {founderStory[0]?.media &&
                    founderStory[0]?.media?.length ? (
                      <View
                        mt="lg"
                        onLayout={({ nativeEvent }) => {
                          if (authorVideoSectionOffset === 0) {
                            setAuthorVideoSectionOffset(nativeEvent?.layout.y);
                          }
                        }}
                      >
                        <VideoPlayer
                          id={founderStory[0]?.media[0]?._id}
                          play={play}
                          setPlay={setPlay}
                          // muted={muted}
                          // setMuted={setMuted}
                          isLooping
                          url={founderStory[0]?.media[0]?.mediaURL}
                          aspectRatio={1}
                          showFullScreen
                          onTogglePlay={() => {
                            firebaseEventLogger(
                              'RestaurantScreenBrandStoryToggle',
                            );
                          }}
                        />
                      </View>
                    ) : (
                      <></>
                    )}
                    <View px="2xl" mt="xl" mb="2xl">
                      {founderStory[0]?.media &&
                      founderStory[0]?.media?.length ? (
                        <>
                          <Text size="md" weight="medium" color="primary.500">
                            {founderStory[0]?.media[0]?.title}
                          </Text>
                          <Text size="xs" color="primary.100">
                            {founderStory[0]?.media[0]?.description}
                          </Text>
                        </>
                      ) : (
                        <></>
                      )}
                      <Text size="sm" color="primary.200" mt="sm">
                        {founderStory[0]?.description}
                      </Text>
                    </View>
                  </>
                ) : (
                  <></>
                )}
              </View>
            </ParallaxHeader>
            {/* <SelectedCartFooter
              restaurantId={restaurantId}
              restaurantName={displayName}
            /> */}
            <PaybillFooter
              disableMenu={!isMenuEnabled(restaurantData[0])}
              isPayBillDisabled={!isBillPayEnabled}
              onPressSecondary={onPressMenu}
              onPressPrimary={handlePayBill}
              discountPercentage={
                Array.isArray(discountsData) && discountsData?.length
                  ? getPayBillFooterDiscount()
                  : 0
              }
              cashbackPercentage={config?.billUploadConfig?.cashbackPercentage}
            />
            <RestaurantsTimingBottomsheet
              bottomSheetModalRef={restaurantsTimingBottomSheetRef}
              timings={openHours}
              currentDay={currentDay}
              openStatus={openStatus?.status}
              type={type}
            />
            <BottomSheetV2
              ref={bottomSheetModalRef}
              onClose={handleOnDismiss}
              insets={false}
            >
              <View>
                <View
                  flexDirection="row"
                  alignItems="center"
                  // justifyContent="space-between"
                  px="2xl"
                >
                  <Icon name="info-outline-300" color="info.500" size="2xl" />
                  <Text size="xl" ml="sm" color="primary.200">
                    Overall Rating
                  </Text>
                </View>
                <View px="2xl" mt="lg">
                  <Divider level={1} />
                </View>
                <Text size="sm" color="primary.500" px="2xl" mt="2xl">
                  The overall rating is calculated by taking the average of all
                  popular rating platforms.
                </Text>
              </View>
            </BottomSheetV2>
          </>
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

export default Restaurant;
