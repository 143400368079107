/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const partnerDayWiseCollectionQuery = graphql`
  query partnerDayWiseCollectionQuery(
    $collectionFilter: getPopupOrderCollectionsFilter
    $ordersFilter: findpaymentOrderfilterInput
    $sort: [PaymentOrderHistorySortEnum]
    $count: Int!
    $cursor: String
  ) {
    getPopupOrderCollections(filter: $collectionFilter, limit: 1) {
      total
      date
      count
      cashTotal
      onlineTotal
    }
    ...partnerHomeScreenQuery__paymentHistoryFragmentV2
      @arguments(
        filter: $ordersFilter
        sort: $sort
        count: $count
        cursor: $cursor
      )
  }
`;
