/* eslint-disable no-extra-boolean-cast */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, Dimensions, Image, Platform, Alert } from 'react-native';
import { useTheme } from 'styled-components/native';
import _ from 'lodash';
import Reanimated from 'react-native-reanimated';
import {
  Face,
  FaceDetectionOptions,
} from 'react-native-vision-camera-face-detector';
import AnimatedCircularProgress from './CircularProgress/AnimatedCircularProgress';
import { View, Text } from '../../components/new';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { getTheme } from '../../themes/new/theme';
import {
  ACTIONS,
  STATUS,
  STEPS,
  contains,
  detections,
  instructionsText,
} from './contains';
import { blobToBase64 } from '../../utilities/helper';

const RNWorklets =
  Platform.OS === 'web' ? {} : require('react-native-worklets-core');

const RNFaceDetector =
  Platform.OS === 'web'
    ? {}
    : require('react-native-vision-camera-face-detector');

const camType =
  Platform.OS === 'web'
    ? require('expo-camera')
    : require('react-native-vision-camera');

const { Camera, useCameraDevice, useFrameProcessor, useCameraFormat } = camType;
const { useFaceDetector } = RNFaceDetector;
const { Worklets } = RNWorklets;

const ReanimatedCamera = Reanimated.createAnimatedComponent(Camera);

interface FaceDetection {
  rollAngle: number;
  yawAngle: number;
  smilingProbability: number;
  leftEyeOpenProbability: number;
  rightEyeOpenProbability: number;
  bounds: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

const { width: windowWidth } = Dimensions.get('window');

const PREVIEW_SIZE = windowWidth - 2 * getTheme().space['2xl'];

const PREVIEW_RECT = {
  minX: (windowWidth - PREVIEW_SIZE) / 2,
  minY: 50,
  width: PREVIEW_SIZE,
  height: PREVIEW_SIZE,
};

const initialState = {
  instructionText: instructionsText.initialPrompt,
  photoBorderColor: getTheme().colors.static.white,
  currentStep: 0,
  currentStatus: {
    // Each step status ('Pending, Processing, Completed')
    [STEPS.BLINK]: STATUS.PENDING,
    [STEPS.SMILE]: STATUS.PENDING,
  },
  isFaceInsideArea: false,
  isShowCompleted: false,
  isAnyErrorExist: false,
};

const detectionReducer = (
  state: typeof initialState,
  action,
): typeof initialState => {
  switch (action.type) {
    case ACTIONS.NO_FACE_FOUND:
      return {
        ...state,
        isFaceInsideArea: false,
        photoBorderColor: action.payload.photoBorderColor,
        instructionText: instructionsText.initialPrompt,
      };
    case ACTIONS.ON_ERROR:
      return {
        ...state,
        photoBorderColor: getTheme().colors.error[500],
        instructionText: action.payload.errorMessage,
      };

    case ACTIONS.SET_FACE_INSIDE_AREA:
      return { ...state, isFaceInsideArea: action.payload };

    case ACTIONS.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
        instructionText: action.payload.instructionText,
        photoBorderColor: action.payload.photoBorderColor,
      };

    case ACTIONS.SET_CURRENT_STEP_STATUS:
      return {
        ...state,
        currentStatus: action.payload.currentStatus,
        currentStep: action.payload.currentStep,
        instructionText: action.payload.instructionText,
      };

    case ACTIONS.SET_IS_SHOW_COMPLETED:
      return {
        ...state,
        photoBorderColor: action.payload.photoBorderColor,
        isShowCompleted: action.payload.isShowCompleted,
      };

    case ACTIONS.SET_INSTRUCTION_TEXT:
      return { ...state, instructionText: action.payload };

    case ACTIONS.SET_ERROR:
      return { ...state, isAnyErrorExist: action.payload };

    case ACTIONS.RESET:
      return { ...initialState };

    default:
      return state;
  }
};

let blinkTimerId = null;
let smileTimerId = null;

const Camscanner = ({ setImageUriCallback, takingBack = () => {} }) => {
  const theme = useTheme();
  const camera = useRef<Camera>(null);
  const [state, dispatch] = React.useReducer(detectionReducer, initialState);
  const [blinkTimer, setBlinkTimer] = useState(0);
  const [smileTimer, setSmileTimer] = useState(0);
  const [cameraPreview, setCameraPreview] = useState(true);
  const isResetDone = useRef(false);

  const pauseBlinkTimer = () => {
    clearInterval(blinkTimerId);
    blinkTimerId = null;
  };

  const pauseSmileTimer = () => {
    clearInterval(smileTimerId);
    smileTimerId = null;
  };

  useEffect(() => {
    return () => {
      unSubscribeBlinkTimer();
      unSubscribeSmileTimer();
    };
  }, []);

  const unSubscribeBlinkTimer = () => {
    clearInterval(blinkTimerId);
    blinkTimerId = null;
  };

  const unSubscribeSmileTimer = () => {
    clearInterval(smileTimerId);
    smileTimerId = null;
  };

  const handleBlinkAnimationLoad = () => {
    setTimeout(() => {
      const currentStatus = {
        [STEPS.BLINK]: STATUS.PROCESSING,
        [STEPS.SMILE]: STATUS.PENDING,
      };
      dispatch({
        type: ACTIONS.SET_CURRENT_STEP_STATUS,
        payload: {
          currentStatus,
          currentStep: STEPS.BLINK,
          instructionText: detections.BLINK.instruction,
        },
      });
    }, 100);
  };

  const handleSmileAnimationLoad = () => {
    setTimeout(() => {
      const currentStatus = {
        [STEPS.BLINK]: STATUS.COMPLETED,
        [STEPS.SMILE]: STATUS.PROCESSING,
      };
      dispatch({
        type: ACTIONS.SET_CURRENT_STEP_STATUS,
        payload: {
          currentStatus,
          currentStep: STEPS.SMILE,
          instructionText: detections.SMILE.instruction,
        },
      });
    }, 700);
  };

  let clicked = false;

  const pictureTake = async () => {
    const data = await camera.current.takePhoto();
    const imageResponse = await fetch(`file://${data?.path}`);
    const blobdata = await imageResponse.blob();
    const base64Data = await blobToBase64(blobdata);
    const imageBase64 = base64Data?.split('base64,');
    setImageUriCallback(imageBase64[1]);
  };

  const takePicture = _.throttle(function () {
    clicked = true;
    pictureTake();
  }, 1000);

  const onBlinkDetected = () => {
    unSubscribeBlinkTimer();
    dispatch({
      type: ACTIONS.SET_INSTRUCTION_TEXT,
      payload: instructionsText.Completed,
    });
    // camera?.pausePreview();
    setCameraPreview(false);
    dispatch({
      type: ACTIONS.SET_IS_SHOW_COMPLETED,
      payload: {
        photoBorderColor: getTheme(theme?.currentThemeMode).colors.success[500],
        isShowCompleted: true,
      },
    });
    setTimeout(() => {
      // camera?.resumePreview();
      setCameraPreview(true);
      if (!isResetDone?.current) {
        dispatch({
          type: ACTIONS.SET_IS_SHOW_COMPLETED,
          payload: {
            photoBorderColor: getTheme(theme?.currentThemeMode).colors
              .info[500],
            isShowCompleted: false,
          },
        });
        const status = {
          [STEPS.BLINK]: STATUS.COMPLETED,
          [STEPS.SMILE]: STATUS.PENDING,
        };
        dispatch({
          type: ACTIONS.SET_CURRENT_STEP_STATUS,
          payload: {
            currentStatus: status,
            currentStep: STEPS.SMILE,
            instructionText: detections.SMILE.instruction,
          },
        });
      } else {
        dispatch({
          type: ACTIONS.SET_IS_SHOW_COMPLETED,
          payload: {
            photoBorderColor: getTheme(theme?.currentThemeMode).colors
              .info[500],
            isShowCompleted: false,
          },
        });
        isResetDone.current = false;
      }
    }, 1500);

    firebaseEventLogger('photoMoments__eyesblink_misc', {
      buttonName: 'eyesblink',
      screenName: 'photoMoments',
      userType: 'user',
      interactionType: 'misc',
    });
  };

  const onSmileDetected = useCallback(() => {
    if (clicked) return;
    unSubscribeSmileTimer();
    firebaseEventLogger('photoMoments__smile_misc', {
      buttonName: 'smile',
      screenName: 'photoMoments',
      userType: 'user',
      interactionType: 'misc',
    });
    dispatch({
      type: ACTIONS.SET_IS_SHOW_COMPLETED,
      payload: {
        photoBorderColor: getTheme(theme?.currentThemeMode).colors.success[500],
        isShowCompleted: true,
      },
    });
    const status = {
      [STEPS.BLINK]: STATUS.COMPLETED,
      [STEPS.SMILE]: STATUS.COMPLETED,
    };
    dispatch({
      type: ACTIONS.SET_CURRENT_STEP_STATUS,
      payload: {
        currentStatus: status,
        currentStep: STEPS.SMILE,
        instructionText: instructionsText.Completed,
      },
    });
    takePicture();
  }, [clicked]);

  const onFacesDetected = (faces) => {
    // No faces found
    if (
      (faces?.length < 1 &&
        ((state.currentStatus[STEPS.BLINK] === STATUS.COMPLETED &&
          state.currentStatus[STEPS.SMILE] !== STATUS.COMPLETED) ||
          state.isShowCompleted)) ||
      (faces?.length === 1 && faces?.[0]?.bounds?.width < 50)
    ) {
      isResetDone.current = true;
      dispatch({ type: ACTIONS.RESET });
      return;
    }

    if (
      faces?.length < 1 &&
      state.currentStep &&
      state.currentStatus[state.currentStep] === STATUS.PROCESSING
    ) {
      // No Faces found but after trying atleast once
      dispatch({ type: ACTIONS.SET_ERROR, payload: true });
      dispatch({
        type: ACTIONS.NO_FACE_FOUND,
        payload: {
          photoBorderColor: getTheme(theme?.currentThemeMode).colors.error[500],
        },
      });
      pauseBlinkTimer();
      pauseSmileTimer();
      return;
    }

    if (faces?.length < 1) {
      dispatch({ type: ACTIONS.SET_ERROR, payload: true });
      dispatch({
        type: ACTIONS.NO_FACE_FOUND,
        payload: {
          photoBorderColor: getTheme(theme?.currentThemeMode).colors.static
            .white,
        },
      });
      pauseBlinkTimer();
      pauseSmileTimer();
      return;
    } // No faces found at first time

    // Multiple faces detected
    if (faces?.length > 1) {
      dispatch({ type: ACTIONS.SET_ERROR, payload: true });
      pauseBlinkTimer();
      pauseSmileTimer();
      dispatch({
        type: ACTIONS.ON_ERROR,
        payload: { errorMessage: instructionsText.multipleFaces },
      });
      return;
    }

    const face: FaceDetection = faces[0];
    const faceRect = {
      minX: face.bounds.x,
      minY: face.bounds.y,
      width: face.bounds.width,
      height: face.bounds.height,
    };

    const edgeOffset = 50;
    const faceRectSmaller = {
      width: faceRect.width - edgeOffset,
      height: faceRect.height - edgeOffset,
      minY: faceRect.minY + edgeOffset / 2,
      minX: faceRect.minX + edgeOffset / 2,
    };

    const previewContainsFace = contains({
      outside: PREVIEW_RECT,
      inside: faceRectSmaller,
    });

    if (!previewContainsFace) {
      dispatch({ type: ACTIONS.SET_FACE_INSIDE_AREA, payload: false });
      dispatch({
        type: ACTIONS.NO_FACE_FOUND,
        payload: {
          photoBorderColor:
            state.currentStep &&
            state.currentStatus[state.currentStep] === STATUS.PROCESSING
              ? getTheme(theme?.currentThemeMode).colors.error[500]
              : getTheme(theme?.currentThemeMode).colors.static.white,
        },
      });
      return;
    }

    const { currentStep, currentStatus } = state;

    if (!blinkTimerId && currentStatus[STEPS.BLINK] === STATUS.PROCESSING) {
      blinkTimerId = setInterval(() => {
        setBlinkTimer((t) => t + 1);
      }, 1000);
    }

    if (!smileTimerId && currentStatus[STEPS.SMILE] === STATUS.PROCESSING) {
      smileTimerId = setInterval(() => {
        setSmileTimer((t) => t + 1);
      }, 1000);
    }

    // set current step
    if (!currentStep || currentStatus[STEPS.BLINK] !== STATUS.COMPLETED) {
      dispatch({
        type: ACTIONS.SET_CURRENT_STEP,
        payload: {
          currentStep: STEPS.BLINK,
          instructionText: detections.BLINK.instruction,
          photoBorderColor: getTheme(theme?.currentThemeMode).colors.info[500],
        },
      });
    } else if (
      currentStatus[STEPS.BLINK] === STATUS.COMPLETED &&
      currentStatus[STEPS.SMILE] !== STATUS.COMPLETED
    ) {
      dispatch({
        type: ACTIONS.SET_CURRENT_STEP,
        payload: {
          currentStep: STEPS.SMILE,
          instructionText: detections.SMILE.instruction,
          photoBorderColor: getTheme(theme?.currentThemeMode).colors.info[500],
        },
      });
    }

    dispatch({ type: ACTIONS.SET_ERROR, payload: false });

    if (currentStatus[STEPS.BLINK] === STATUS.PROCESSING) {
      const leftEyeClosed =
        face.leftEyeOpenProbability <= detections.BLINK.minProbability;
      const rightEyeClosed =
        face.rightEyeOpenProbability <= detections.BLINK.minProbability;
      if (leftEyeClosed && rightEyeClosed) {
        onBlinkDetected();
      }
    } else if (
      currentStatus[STEPS.SMILE] === STATUS.PROCESSING &&
      currentStatus[STEPS.BLINK] === STATUS.COMPLETED
    ) {
      if (face.smilingProbability >= detections.SMILE.minProbability) {
        onSmileDetected();
      }
    }
  };

  const isShowBlinkAnimation = Boolean(
    state.currentStatus[STEPS.BLINK] === STATUS.PENDING ||
      (state.currentStatus[STEPS.BLINK] === STATUS.PROCESSING &&
        (blinkTimer % 5 === 0 || blinkTimer % 5 === 1)),
  );
  const isShowSmileAnimation = Boolean(
    (state.currentStatus[STEPS.SMILE] === STATUS.PENDING &&
      state.currentStatus[STEPS.BLINK] === STATUS.COMPLETED) ||
      (state.currentStatus[STEPS.BLINK] === STATUS.COMPLETED &&
        state.currentStatus[STEPS.SMILE] === STATUS.PROCESSING &&
        (smileTimer % 5 === 0 || smileTimer % 5 === 1)),
  );

  const tintColor = state.isShowCompleted
    ? getTheme(theme?.currentThemeMode).colors.success[500]
    : isShowBlinkAnimation || isShowSmileAnimation
    ? getTheme(theme?.currentThemeMode).colors.static.white
    : state.photoBorderColor;

  const device = useCameraDevice('front');

  const faceDetectionOptions = useRef<FaceDetectionOptions>({
    classificationMode: 'all',
    performanceMode: 'fast',
  }).current;

  const { detectFaces } = useFaceDetector(faceDetectionOptions);

  const handleDetectedFaces = Worklets.createRunOnJS((faces: Face[]) => {
    onFacesDetected(faces);
  });

  const frameProcessor = useFrameProcessor(
    (frame) => {
      'worklet';

      const faces = detectFaces(frame);
      if (cameraPreview) {
        handleDetectedFaces(faces);
      }
    },
    [handleDetectedFaces],
  );

  const format = useCameraFormat(device, [
    { photoResolution: { width: 540, height: 540 } },
  ]);

  return (
    <View bg="primary.500" flex={1} justifyContent="center" alignItems="center">
      <View style={styles.cameraContainer}>
        <ReanimatedCamera
          ref={camera}
          style={[StyleSheet.absoluteFill, { position: 'absolute', flex: 1 }]}
          device={device}
          format={format}
          photo
          video
          isActive
          frameProcessor={frameProcessor}
        />
        <AnimatedCircularProgress
          style={styles.circularProgress}
          size={PREVIEW_SIZE}
          width={5}
          backgroundWidth={7}
          fill={100}
          tintColor={tintColor}
          backgroundColor={tintColor}
        />

        {(isShowBlinkAnimation || isShowSmileAnimation) &&
          !state.isAnyErrorExist && <View style={styles.blur} />}

        {state.isShowCompleted && (
          <View style={styles.greenTick}>
            <Image
              source={require('../../../assets/images/green_tick.webp')}
              style={{ height: 60, width: 60 }}
            />
          </View>
        )}

        {isShowBlinkAnimation &&
          !state.isShowCompleted &&
          !state.isAnyErrorExist && (
            <View style={styles.lottie}>
              <Image
                style={{ height: '80%', width: '100%' }}
                resizeMode="contain"
                source={require('../../../assets/blink_eye.gif')}
                onLoadEnd={handleBlinkAnimationLoad}
              />
            </View>
          )}

        {isShowSmileAnimation &&
          !state.isShowCompleted &&
          !state.isAnyErrorExist && (
            <View style={styles.lottie}>
              <Image
                style={{ height: '100%', width: '100%' }}
                resizeMode="contain"
                source={require('../../../assets/smile.gif')}
                onLoadEnd={handleSmileAnimationLoad}
              />
            </View>
          )}
      </View>
      <Text size="2xl" color="primary.10" mt="4xl" weight="medium">
        {state.instructionText}
      </Text>
      {state.currentStep ? (
        <Text
          color="primary.200"
          size="sm"
          mt="2xl"
        >{`Step ${state.currentStep}`}</Text>
      ) : null}
    </View>
  );
};

export default memo(Camscanner);

const styles = StyleSheet.create({
  greenTick: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  blur: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
  lottie: {
    ...StyleSheet.absoluteFillObject,
  },
  cameraContainer: {
    width: PREVIEW_SIZE,
    height: PREVIEW_SIZE,
    borderRadius: 300, // half of the width and height
    overflow: 'hidden',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  mainCamera: {
    flex: 1,
    justifyContent: 'center',
  },
  lottieView: {
    top: 4,
    left: 2,
    position: 'absolute',
  },
  circularProgress: {
    width: PREVIEW_SIZE,
  },
  instructions: {
    fontSize: 20,
    textAlign: 'center',
    top: 28,
    fontWeight: 'bold',
    color: getTheme().colors.static.white,
  },
  action: {
    fontSize: 24,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});
