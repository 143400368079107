/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const earningsOverviewScreenQuery = graphql`
  query earningsOverviewScreenQuery(
    $transactionsFilter: findInvoiceInput!
    $earningReportFilter: PaymentReportFilter!
    $sort: SortConnectioninvoiceEnum
    $count: Int!
  ) {
    ...transactionsEarningReportFragment
      @arguments(filter: $earningReportFilter)
    ...transactionsFragment
      @arguments(filter: $transactionsFilter, sort: $sort, count: $count)
  }
`;
