import React from 'react';
import View from '../../../basic/View/View';
import Text from '../../../primitive/Typography/Text/Text';
import Icon from '../../../primitive/Icon/Icon';
import Button from '../../../primitive/Button/Button';

interface CouponCodeProps {
  buttonText: string;
  onPress: () => void;
  disabled: boolean;
  label?: string;
  icon?: string;
}

const CouponCode = ({
  onPress,
  disabled = false,
  buttonText,
  icon = 'warning-outline-400',
  label = 'Coupon Code',
}: CouponCodeProps) => {
  return (
    <View alignSelf="center" width={184} mt={98}>
      <Text textAlign="center" mb="lg" size="sm" color="primary.100">
        {label}
      </Text>
      <Button
        state={disabled ? 'disabled' : ''}
        onPress={onPress}
        name="content-copy-300"
        appearance="filled"
        status="primary"
        size="lg"
      >
        <View flexDirection="row" alignItems="center">
          <Text mr="lg" size="md" color="primary.10">
            {buttonText}
          </Text>
          <Icon name={icon} color="white" size="lg" />
        </View>
      </Button>
    </View>
  );
};

export default CouponCode;
