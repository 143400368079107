export enum menuAddButtonAppearanceEnum {
  solid = 'solid',
  outline = 'outline',
}
export enum menuAddButtonStateEnum {
  active = 'active',
  disabled = 'disabled',
}

export enum menuAddButtonSizeEnum {
  md = 'md',
  lg = 'lg',
}
