import React from 'react';
import View from '../../../basic/View/View';
import Text from '../../../primitive/Typography/Text/Text';

interface LabelProps {
  title: string;
  text: string;
  position?: 'LEFT' | 'RIGHT';
}

const Label = ({ title, text, position = 'LEFT' }: LabelProps) => {
  return (
    <View>
      <Text
        textAlign={position === 'RIGHT' ? 'right' : 'left'}
        mb="sm"
        size="sm"
        color="primary.200"
      >
        {title}
      </Text>
      <Text
        textAlign={position === 'RIGHT' ? 'right' : 'left'}
        size="lg"
        color="primary.400"
      >
        {text}
      </Text>
    </View>
  );
};

export default Label;
