import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Platform, ScrollView, Share, useWindowDimensions } from 'react-native';
import * as Sharing from 'expo-sharing';
import { useActionSheet } from '@expo/react-native-action-sheet';
import Constants from 'expo-constants';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { navigateBack, paymentOrderTypeEnum } from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import GradientBackground from '../../../components/new/custom/GradientBackground';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import InvestmentPromise from './components/InvestmentPromise';
import InvestBanners from './components/InvestBanners';
import InvestNextSteps from './components/InvestNextSteps';
import { State } from '../../../themes/new/helper';
import { toCurrency } from '../../../utilities/Utility';
import InvestmentSubscriptionDetails from './components/InvestmentSubscriptionDetails';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import InvestFAQs from './components/InvestFAQs';

const actionSheetOptions = ['Copy To Clipboard', 'Cancel'];

const InvestApplyComponent = ({ route }) => {
  const insets = useSafeAreaInsets();
  const params = route?.params;
  const { width } = useWindowDimensions();
  const userRole = useUserStore((state) => state.role);
  const navigation = useNavigation();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { showActionSheetWithOptions } = useActionSheet();
  const [navBg, showNavBg] = useState(false);
  const isOverApplied = params?.isOverApplied;
  const propertyData = params?.data;
  const promiseData = {
    heading: propertyData?.project?.managedBy?.promise?.heading,
    subHeading: propertyData?.project?.managedBy?.promise?.subHeading,
    data: propertyData?.project?.managedBy?.promise?.values,
  };
  const carousalData = {
    heading: 'WEALTH CREATION',
    subHeading: `You're One Step Closer To`,
    data: [
      {
        bannerImage: require('../../../../assets/images/Invest_Banner_1.webp'),
      },
      {
        bannerImage: require('../../../../assets/images/Invest_Banner_2.webp'),
      },
    ],
  };

  const nextStepsData = {
    heading: 'NEXT STEPS',
    subHeading: `A Personalised Journey`,
    data: [
      {
        img: require('../../../../assets/images/FoodAndWine.webp'),
        description:
          'An in-depth investment walk through with an exclusive wine & cheese session',
      },
      {
        img: require('../../../../assets/images/assitant.webp'),
        description:
          'Get a full time dedicated investment expert, to help you at every step',
      },
    ],
  };
  const subscriptionData = {
    heading: 'Accepting Applications',
    subHeading: '',
    data: propertyData?.investment?.data?.investment?.units,
    applicationData: propertyData?.investment?.data?.application,
  };

  const gradientColors = [
    { offset: '0', color: '#121212', opacity: 0 },
    { offset: '1', color: '#0A461D', opacity: 1 },
  ];

  useEffect(() => {
    firebaseEventLogger('InvestmentApply__investmentApply_Landing', {
      buttonName: 'investmentApply',
      screenName: 'InvestmentApply',
      userType: 'user',
      interactionType: 'landing',
      propertyId: propertyData?._id,
      leadId: params?.leadResponse?._id,
    });
  }, []);

  const sharePlan = async () => {
    firebaseEventLogger('PropertyDetails__shareLink_Tap', {
      buttonName: 'shareLink',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'tap',
    });
    const baseURL =
      Constants?.expoConfig?.extra?.APP_ENV === 'production'
        ? 'https://club.32nd.com/'
        : 'https://staging.club.32nd.com/';
    const suffixUrl = `PropertyDetails?propertyId=${propertyData?._id}&utm_source=share&utm_medium=link&utm_campaign=conversion`;
    const url = baseURL + suffixUrl;
    try {
      const options =
        Platform.OS === 'android'
          ? {
              url,
              message: `${url}`,
            }
          : { url };
      await Share.share(options);
    } catch (error) {
      if (Platform.OS === 'web' && !(await Sharing.isAvailableAsync())) {
        showActionSheetWithOptions(
          {
            options: actionSheetOptions,
            cancelButtonIndex: 1,
            // destructiveButtonIndex:3,
          },
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (selectedIndex: number) => {
            switch (selectedIndex) {
              case 0:
                // Save
                // eslint-disable-next-line no-case-declarations
                const shareText = `${url}`;
                // eslint-disable-next-line no-case-declarations
                const dummyInput = document.createElement('input');
                document.body.appendChild(dummyInput);
                dummyInput.value = shareText;
                dummyInput.select();
                document.execCommand('copy');
                document.body.removeChild(dummyInput);
                dispatchSnackbar({
                  msg: 'Link copied to the clipboard',
                  status: SnackbarStatus.info,
                  version: SnackbarVersion.v2,
                });
                break;
              default:
                break;
              // something
            }
          },
        );
      }
    }
  };

  const handleOnScroll = (event) => {
    if (event?.nativeEvent?.contentOffset?.y > 60 && !navBg) {
      showNavBg(true);
    } else if (event?.nativeEvent?.contentOffset?.y < 60 && navBg) {
      showNavBg(false);
    }
  };

  return (
    <Layout level={2}>
      <DarkThemeWrapper>
        <View
          position="absolute"
          top={0}
          width="100%"
          zIndex={2}
          bg={navBg ? 'background.primary.base' : ''}
        >
          <TopNavigation
            title={navBg ? subscriptionData?.heading : ''}
            appearance="ghost"
            level="none"
            IconLeft={
              <IconButton
                name="back-outline-300"
                size="sm"
                appearance="ghost"
                iconColor="primary.500"
                onPress={() => navigateBack(navigation, userRole)}
              />
            }
            IconRight={
              <IconButton
                name="share-outline-400"
                size="sm"
                appearance="ghost"
                iconColor="primary.500"
                onPress={sharePlan}
              />
            }
          />
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          bounces={false}
          scrollEventThrottle={24}
          onScroll={handleOnScroll}
        >
          <View bg="background.primary.base">
            <GradientBackground
              backgroundOpacity={1}
              gradientColors={gradientColors}
              id="inv_apply_component"
              style={{
                width,
                height: Platform.OS !== 'web' ? 380 : 320,
                position: 'absolute',
              }}
              x1="1"
              y1="0"
              x2="0"
              y2="1"
            />
            <InvestmentSubscriptionDetails
              data={subscriptionData}
              isOverApplied={isOverApplied}
              applied={
                propertyData?.investment?.data?.application?.applied ?? 0
              }
              propertyViewCount={
                propertyData?.findInvestmentProperty?.propertyViewCount ?? 0
              }
              onPressUnlock={() => null}
              consentGiven
              bgColor="transparent"
              verticlePadding="9xl"
              headingSize="lg"
              headingColor="primary.400"
              headingWeight="medium"
              headingToContentMargin="2xl"
              headingTopMargin={Platform.OS !== 'web' ? '9xl+9xl+lg' : '9xl+lg'}
              iconName="non-veg-filled-300"
            />
            <View
              mt="9xl"
              mx="2xl"
              borderWidth={1}
              borderRadius="xl"
              borderColor="grey.10"
              bg="primary.10"
              overflow="hidden"
            >
              <View
                height={34}
                bg="success.200"
                justifyContent="center"
                alignItems="center"
              >
                <Text color="primary.500" size="xs" weight="medium">
                  Application Fee: ₹
                  {toCurrency(
                    propertyData?.investment?.data?.application?.amount,
                  )}
                </Text>
              </View>
              <View flexDirection="row" width="100%">
                <View flex={0.2} justifyContent="center" alignItems="center">
                  <CustomLottieView
                    autoPlay
                    loop
                    style={{
                      height: 48,
                      width: 48,
                    }}
                    containerWidth={40}
                    source={require('../../../../assets/lottie/secure-shield.json')}
                  />
                </View>
                <View py="2xl" pr="2xl" flex={0.8}>
                  <Text color="primary.400" size="sm">
                    100% Refundable
                  </Text>
                  <Text color="primary.200" size="xs" pt="sm">
                    You may withdraw your application fee of ₹
                    {toCurrency(
                      propertyData?.investment.data.application.amount,
                    )}{' '}
                    whenever you want!
                  </Text>
                </View>
              </View>
            </View>
            <InvestmentPromise
              verticleMargin="9xl"
              data={promiseData}
              bgColor="background.primary.base"
            />
            <InvestBanners
              bgColor="background.primary.base"
              data={carousalData}
            />
            <InvestNextSteps
              bgColor="background.primary.base"
              data={nextStepsData}
            />
            <InvestFAQs />
            {/* <InvestPartners bgColor="background.primary.base" /> */}
            <View height={100} />
          </View>
        </ScrollView>
        <View
          bg="background.primary.base"
          width="100%"
          position="absolute"
          bottom={0}
        >
          <View px="2xl" py="lg">
            <Text color="primary.300" size="xs">
              ⭐️ This fee will be adjusted against your total investment
            </Text>
          </View>
          <View
            flexDirection="row"
            jystifyContent="space-between"
            bg="background.primary.elevation"
            width="100%"
            p="2xl"
            pb={
              Platform.OS === 'web' || Platform.OS === 'android'
                ? '2xl'
                : insets.bottom
            }
          >
            <View flex={0.5}>
              <Text size="xl" color="primary.500" weight="medium">
                ₹{toCurrency(propertyData?.investment.data.application.amount)}
              </Text>
              <Text size="xs" color="primary.200" pt="sm">
                100% Refundable
              </Text>
            </View>
            <View flex={0.5}>
              <Button
                state={State.ACTIVE}
                status="primary"
                size="md"
                onPress={() => {
                  firebaseEventLogger('InvestmentApply__proceedToPay_Tap', {
                    buttonName: 'proceedToPay',
                    screenName: 'InvestmentApply',
                    userType: 'user',
                    interactionType: 'tap',
                    propertyId: propertyData?._id,
                    leadId: params?.leadResponse?._id,
                    amount: String(
                      propertyData?.investment.data.application.amount,
                    ),
                  });
                  navigation.navigate('paymentAmountScreen', {
                    orderId: params?.leadResponse?._id,
                    orderType: paymentOrderTypeEnum.INVESTMENT_LEAD,
                    amount: propertyData?.investment.data.application.amount,
                  });
                }}
              >
                Proceed To Apply
              </Button>
            </View>
          </View>
        </View>
      </DarkThemeWrapper>
    </Layout>
  );
};

export default InvestApplyComponent;
