import Input from '../../primitive/Input/Input';

const defaultLeftIconName = 'back-outline-300';
const rightIconName = 'search-outline-400';

// not allowing state prop to pass down intentionally
const SearchInput = ({
  value = '',
  onChangeText = () => {},
  onLeftIconClick = () => {},
  onRightIconClick = () => {},
  placeholder = 'placeholder',
  inputRef,
  leftIconName = defaultLeftIconName,
  ...rest
}) => {
  return (
    <Input
      inputRef={inputRef}
      value={value}
      placeholder={placeholder}
      onChangeText={onChangeText}
      leftIconName={leftIconName}
      rightIconName={value ? 'close-outline-300' : rightIconName}
      onLeftIconClick={onLeftIconClick}
      onRightIconClick={onRightIconClick}
      {...rest}
    />
  );
};

export default SearchInput;
