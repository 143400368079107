import React from 'react';
import styled from 'styled-components/native';
import { space, color, layout, flexbox, border, position } from 'styled-system';

const StyledView = styled.View`
  ${layout}
  ${space}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  ${({ shadow, theme }) =>
    shadow && shadow !== 'none' ? theme.boxShadows[shadow] : ''}
${({ shadow, theme }) => ({
    'shadow-color':
      shadow && shadow !== 'none' ? theme.colors.primary['500'] : 'transparent',
  })}
`;

const View = ({ children, ...rest }) => {
  return <StyledView {...rest}>{children || null}</StyledView>;
};

export default View;
