/**
 * @generated SignedSource<<9507d3afcabaee03dfa8824561ad5229>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type restaurantPartnerApiMutation$variables = {
  loyalityPoints?: boolean | null;
  restaurantId?: string | null;
};
export type restaurantPartnerApiMutation$data = {
  readonly updateRestaurantConfig: {
    readonly _id: any;
    readonly pointsEnabled: {
      readonly redeem: boolean | null;
      readonly reward: boolean | null;
    } | null;
    readonly restaurantId: any;
  } | null;
};
export type restaurantPartnerApiMutation = {
  response: restaurantPartnerApiMutation$data;
  variables: restaurantPartnerApiMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loyalityPoints"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "restaurantId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "loyalityPoints",
        "variableName": "loyalityPoints"
      },
      {
        "kind": "Variable",
        "name": "restaurantId",
        "variableName": "restaurantId"
      }
    ],
    "concreteType": "restaurantConfig",
    "kind": "LinkedField",
    "name": "updateRestaurantConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "restaurantId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurants2PayBillPointsEnabled",
        "kind": "LinkedField",
        "name": "pointsEnabled",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "redeem",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reward",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "restaurantPartnerApiMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "restaurantPartnerApiMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "02d8b8a5b130e81db217229ad9c18938",
    "id": null,
    "metadata": {},
    "name": "restaurantPartnerApiMutation",
    "operationKind": "mutation",
    "text": "mutation restaurantPartnerApiMutation(\n  $restaurantId: String\n  $loyalityPoints: Boolean\n) {\n  updateRestaurantConfig(restaurantId: $restaurantId, loyalityPoints: $loyalityPoints) {\n    _id\n    restaurantId\n    pointsEnabled {\n      redeem\n      reward\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0b6fa13dec408953df510af214804b2";

export default node;
