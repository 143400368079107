export const enum InputStates {
  default = 'default',
  active = 'active',
  success = 'success',
  error = 'error',
  disabled = 'disabled',
  unstyled = 'unstyled',
}

export const InputHelperTextColorResolver = (variant) => {
  if (variant === InputStates.success) {
    return 'success.500';
  }
  if (variant === InputStates.error) {
    return 'error.500';
  }
  return 'primary.100';
};

export const InputLabelTextColorResolver = (variant) => {
  if (variant === InputStates.disabled) {
    return 'primary.100';
  }
  return 'primary.300';
};
