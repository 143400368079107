/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Linking, Platform, useWindowDimensions } from 'react-native';
import { Camera } from 'expo-camera';
import { ScrollView } from 'react-native-gesture-handler';
import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import {
  View,
  Text,
  TopNavigation,
  IconButton,
  Button,
  Layout,
  Icon,
} from '../../../../components/new';
import {
  delay,
  formatPlateNo,
  navigateBack,
} from '../../../../utilities/helper';
import { findValet } from '../../../../relay/userApi';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import TaskCard from '../../../../components/new/primitive/TaskCard/TaskCard';
import useControllerModalStore, {
  ScanSteps,
} from '../../../../stores/controller/controllerModalStore';
import validateWrapper from '../../../../utilities/validationWrapper';
import { updateParking } from '../../../../relay/parkingApi';
import Loading from '../../../../components/loading.component';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../utilities/firbaseAnalytics';
import useUserStore, { UserRole } from '../../../../stores/userStore';
import IdentityCode from '../../../../components/new/custom/IdentityCode';
import ParkingETA from '../../../../components/new/custom/ParkingETA';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';
import { Appearance, State, Status } from '../../../../themes/new/helper';

const TopRightCornerComponent = () => {
  const { data } = useControllerModalStore((state) => state);
  const { ongoingTask } = data.valet;
  const ETA = ongoingTask?.parkingETA.parkingTimePrediction.filter(
    (item) => item?.locationId === ongoingTask.taskEndLocation.locationId,
  );

  const delayAt = ETA?.length
    ? new Date(ETA[0].startToCompleteTime).valueOf()
    : new Date().valueOf();

  const currentTime = new Date().valueOf();

  const isDelayed = currentTime > delayAt;
  const delayedDifferenceInMins = (delayAt - currentTime) / 60 / 1000;

  let delayedTimer = Math.floor(delayedDifferenceInMins);
  const [timer, setTimer] = useState(Math.floor(delayedDifferenceInMins));
  useEffect(() => {
    const delayedInterval = 1000 * 60;
    const delayedTimerInterval = setInterval(() => {
      if (delayedTimer > 0) {
        delayedTimer -= 1;
        setTimer(delayedTimer - 1);
      }
    }, delayedInterval);
    return () => {
      clearInterval(delayedTimerInterval);
    };
  }, [delayedTimer]);

  return ETA ? (
    <ParkingETA
      iconColor={
        ETA
          ? isDelayed || delayedTimer < 1
            ? 'warning.500'
            : 'success.500'
          : 'success.500'
      }
      time={
        ETA && (delayedTimer < 1 || isDelayed)
          ? 'Delayed'
          : `${delayedTimer} min`
      }
    />
  ) : (
    <></>
  );
};
interface Props {
  borderColor?: string;
  showEdit?: boolean;
}
type Ref = ScrollView;

export const ControllerScreen = React.forwardRef<Ref, Props>(
  ({ borderColor = 'primary.50', showEdit = false }, ref) => {
    const navigation = useNavigation<any>();
    const { state, setState, data, setData } = useControllerModalStore(
      (controllerState) => controllerState,
    );
    const { dispatchSnackbar } = useSnackbarStore(
      (snackbarState) => snackbarState,
    );
    const { bottom } = useSafeAreaInsets();
    const [reviewParkingDisabled, setReviewParkingDisabled] = useState(false);

    const stepsArr = [
      { id: 0, title: 'Scan Valet' },
      { id: 1, title: 'Scan Key' },
      { id: 2, title: 'Review' },
    ];

    const BottomLeftCTAComponent = () => {
      const handleUpdateParkingTag = (response) => {
        setReviewParkingDisabled(false);
        navigation.navigate('addParkingDetails', {
          parkingId: data.valet.ongoingTask?.parkingId,
          taskId: data.valet.ongoingTask?._id,
        });
      };

      const selectParkingHandler = () => {
        firebaseEventLogger('reviewDetails__reviewParking_Tap', {
          buttonName: 'reviewParking',
          screenName: 'reviewDetails',
          userType: UserRole.CONTROLLER,
          interactionType: InteractionType.TAP,
          taskId: `${data?.valet?.ongoingTask?._id}`,
        });
        setReviewParkingDisabled(true);
        const error = validateWrapper('parkingTag', data.tag);
        if (error) {
          dispatchSnackbar({
            msg: error,
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
          setReviewParkingDisabled(false);
        } else {
          const parkingData = {
            id: data.valet.ongoingTask?.parkingId,
            record: {
              parkingTag: data.tag,
              // status: ParkingStatus.PARKED,
              // parkingLocation: data.valet.ongoingTask.taskEndLocation,
            },
          };
          updateParking(parkingData, handleUpdateParkingTag, (err) => {
            console.log('updateParking error', err);
            setReviewParkingDisabled(false);
          });
        }
      };

      return (
        <View pb={bottom}>
          <Button
            size="lg"
            appearance={Appearance.FILLED}
            status={Status.PRIMARY}
            state={
              state === ScanSteps.REVIEW && !reviewParkingDisabled
                ? State.ACTIVE
                : State.DISABLED
            }
            onPress={selectParkingHandler}
            shadow="sm"
            loading={reviewParkingDisabled}
          >
            Review Parking Location
          </Button>
        </View>
      );
    };

    return (
      <View flex={1}>
        <View mt="3xl" flex={1}>
          <View
            flexDirection="row"
            justifyContent="space-between"
            px="2xl"
            mb="xl"
          >
            {stepsArr.map((item, index) => {
              return (
                <View key={item.id} flexDirection="row" alignItems="center">
                  <Icon
                    name={
                      index < state
                        ? 'check-filled-400'
                        : `counter${item.id + 1}-filled-300`
                    }
                    size="xl"
                    color={
                      index <= state
                        ? index === state
                          ? 'primary.500'
                          : 'success.500'
                        : 'primary.100'
                    }
                  />
                  <Text
                    size="sm"
                    ml="xs"
                    color={
                      index <= state
                        ? index === state
                          ? 'primary.500'
                          : 'success.500'
                        : 'primary.100'
                    }
                  >
                    {item.title}
                  </Text>
                </View>
              );
            })}
          </View>
          <ScrollView ref={ref} style={{ paddingHorizontal: 16 }}>
            <View
              mt="7xl"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text size="lg" color="primary.200">
                Valet Details
              </Text>
              {!showEdit ? (
                <Button
                  appearance={Appearance.GHOST}
                  status={Status.INFO}
                  size="md"
                  state={
                    state === ScanSteps.VALET ? State.DISABLED : State.ACTIVE
                  }
                  onPress={() => {
                    setState(ScanSteps.VALET);
                    setData({ valet: {}, tag: null });
                  }}
                >
                  Edit
                </Button>
              ) : null}
            </View>
            <View
              p="2xl"
              mt="2xl"
              mb="9xl"
              borderWidth="xs"
              borderColor={
                state === ScanSteps.VALET ? borderColor : 'primary.50'
              }
              borderRadius="md"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text size="md" color="primary.100">
                {state === ScanSteps.VALET
                  ? 'Scanning...'
                  : `${data.valet.name?.first ?? ''} ${
                      data.valet.name?.last ?? ''
                    }`}
              </Text>
              {data.valet.identityCode && state !== ScanSteps.VALET ? (
                <IdentityCode code={data.valet.identityCode} />
              ) : null}
            </View>
            {state !== ScanSteps.VALET ? (
              <View>
                <View
                  // mt="9xl"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text size="lg" color="primary.200">
                    Tag ID
                  </Text>
                  <Button
                    appearance={Appearance.GHOST}
                    status={Status.PRIMARY}
                    size="md"
                    state={
                      state === ScanSteps.KEY ? State.DISABLED : State.ACTIVE
                    }
                    onPress={() => {
                      firebaseEventLogger('reviewDetails__edit_Tap', {
                        buttonName: 'edit',
                        screenName: 'reviewDetails',
                        userType: UserRole.CONTROLLER,
                        interactionType: InteractionType.TAP,
                        taskId: `${data?.valet?.ongoingTask?._id}`,
                      });
                      if (showEdit) {
                        navigation.navigate('addParkingTag', {
                          taskId: data?.valet?.ongoingTask?._id,
                        });
                      } else {
                        setState(ScanSteps.KEY);
                      }
                    }}
                  >
                    Edit
                  </Button>
                </View>
                <View
                  px="2xl"
                  py="sm+md"
                  mt="2xl"
                  mb="7xl"
                  borderWidth="xs"
                  borderColor={borderColor}
                  borderRadius="md"
                >
                  <Text size="md" color="primary.100">
                    {state === ScanSteps.KEY ? 'Scanning...' : `#${data.tag}`}
                  </Text>
                </View>
              </View>
            ) : null}
          </ScrollView>
        </View>
        <TaskCard
          heading={
            state === ScanSteps.VALET
              ? ''
              : formatPlateNo(
                  data.valet.ongoingTask?.vehicle.registration.plate,
                ) ?? ''
          }
          topRightCorner={<TopRightCornerComponent />}
          shadow="lg"
          bottomLeftCTA={BottomLeftCTAComponent()}
          alwaysOpened
        />
      </View>
    );
  },
);

const ControllerScannerScreen = ({ navigation }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [tourchMode, setTourchMode] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [borderColor, setBorderColor] = useState('info.500');
  const userRole = useUserStore((state) => state.role);

  const {
    state,
    setState,
    setData,
    data: valet,
  } = useControllerModalStore((controllerState) => controllerState);
  const { dispatchSnackbar } = useSnackbarStore(
    (snackbarState) => snackbarState,
  );

  const scrollRef = useRef<ScrollView>(null);
  const { bottom } = useSafeAreaInsets();
  const { width, height } = useWindowDimensions();
  const focused = useIsFocused();

  const maskRowHeight = Math.round(height / 55);
  const maskColWidth = (width * 0.45) / 2;

  const RequestPermission = () => {
    BarCodeScanner.requestPermissionsAsync()
      .then(({ status }) => {
        setHasPermission(status === 'granted');
      })
      .catch((err) => {
        setHasPermission(false);
      });
  };

  useFocusEffect(
    React.useCallback(() => {
      RequestPermission();
    }, []),
  );

  if (hasPermission === false) {
    return (
      <View flex={1}>
        <TopNavigation
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigateBack(navigation, userRole)}
            />
          }
          appearance="ghost"
          level="1"
        />
        <View flex={1} justifyContent="center" alignItems="center" px="2xl">
          <CustomLottieView
            style={{ width: 200, height: 200 }}
            autoPlay
            loop
            source={require('../../../../../assets/lottie/no-camera.json')}
            containerWidth={200}
          />
          <Text size="2xl" weight="medium" color="primary.400" mt="4xl">
            Permission denied!
          </Text>
          <Text size="md" color="primary.200" mt="lg">
            Enable permission for camera in settings
          </Text>
          <View width="100%">
            <Button
              appearance={Appearance.FILLED}
              status={Status.PRIMARY}
              size="lg"
              mt="4xl"
              onPress={() => Linking.openSettings()}
            >
              Go To Settings
            </Button>
          </View>
        </View>
      </View>
    );
  }

  if (hasPermission === null) {
    return <Loading />;
  }

  const handleValetQrScan = (data: string) => {
    findValet({ _id: data })
      .then((res) => {
        if (res.findValet.length) {
          if (!res.findValet[0].ongoingTask) {
            dispatchSnackbar({
              msg: 'No ongoing tasks available',
              status: SnackbarStatus.error,
              version: SnackbarVersion.v1,
            });
            setScanned(false);
            return;
          }
          firebaseEventLogger('controllerScan__valetFound_Data', {
            buttonName: 'valetFound',
            screenName: 'controllerScan',
            userType: UserRole.CONTROLLER,
            interactionType: InteractionType.DATA,
            taskId: `${res?.findValet[0]?.ongoingTask?._id}`,
            valetId: `${data}`,
          });
          setBorderColor('success.500');
          setData({ valet: res.findValet[0] });
          dispatchSnackbar({
            msg: 'Valet id scanned',
            status: SnackbarStatus.success,
            version: SnackbarVersion.v1,
          });
          setTimeout(() => {
            setState(ScanSteps.KEY);
            setTimeout(() => {
              scrollRef.current?.scrollToEnd();
            }, 500);
            setScanned(false);
            setBorderColor('info.500');
          }, 500);
        } else {
          firebaseEventLogger('controllerScan__valetNotFound_Data', {
            buttonName: 'valetNotFound',
            screenName: 'controllerScan',
            userType: UserRole.CONTROLLER,
            interactionType: InteractionType.DATA,
            taskId: `${res?.findValet[0]?.ongoingTask?._id}`,
            valetId: `${data}`,
          });
          setBorderColor('error.500');
          setTimeout(() => {
            setScanned(false);
            setBorderColor('info.500');
          }, 500);
        }
      })
      .catch((err) => {
        setBorderColor('error.500');
        setTimeout(() => {
          setScanned(false);
          setBorderColor('info.500');
        }, 500);
      });
  };

  const handleTagQrScan = (data: string) => {
    firebaseEventLogger('controllerScan__tagFound_Data', {
      buttonName: 'tagFound',
      screenName: 'controllerScan',
      userType: UserRole.CONTROLLER,
      interactionType: InteractionType.DATA,
      taskId: `${valet?.ongoingTask?._id}`,
      tagNo: `${data}`,
    });
    setBorderColor('success.500');
    setData({ tag: data });
    setTimeout(() => {
      setScanned(false);
      setState(ScanSteps.REVIEW);
      setBorderColor('info.500');
      navigation.navigate('reviewDetails');
    }, 100);
  };

  const handleBarCodeScanned = ({ type, data }) => {
    if (type === 256 || type === 'org.iso.QRCode') {
      if (typeof data !== 'string') return;
      setScanned(true);
      if (state === ScanSteps.VALET && data.length === 24) {
        handleValetQrScan(data);
      } else if (state === ScanSteps.KEY) {
        try {
          const url = new URL(data);
          const qParams = new URLSearchParams(url.search);
          const tagId = qParams.get('valetTag');
          if (tagId.length === 6) {
            handleTagQrScan(tagId);
          }
        } catch (e) {
          setBorderColor('error.500');
          delay(500);
          setTimeout(() => {
            setScanned(false);
            setBorderColor('info.500');
          }, 800);
        }
      } else {
        firebaseEventLogger('controllerScan__tagNoFound_Data', {
          buttonName: 'tagNoFound',
          screenName: 'controllerScan',
          userType: UserRole.CONTROLLER,
          interactionType: InteractionType.DATA,
          taskId: `${valet?.ongoingTask?._id}`,
          tagNo: `${data}`,
        });
        setBorderColor('error.500');
        delay(500);
        setTimeout(() => {
          setScanned(false);
          setBorderColor('info.500');
        }, 800);
      }
    }
  };

  return (
    <Layout level={2}>
      <View flex={1}>
        <View
          position="absolute"
          top={0}
          left={0}
          right={0}
          zIndex={1}
          alignItems="center"
          justifyContent="center"
          height="50%"
        >
          <View
            flex={maskRowHeight}
            backgroundColor="rgba(0,0,0,0.6)"
            width="100%"
          />
          <View flex={30} flexDirection="row">
            <View width={maskColWidth} backgroundColor="rgba(0,0,0,0.6)" />
            <View
              flex={1}
              backgroundColor="transparent"
              width={width * 0.45}
              borderColor={borderColor}
              borderWidth={5}
            />
            <View width={maskColWidth} backgroundColor="rgba(0,0,0,0.6)" />
          </View>
          <View
            flex={maskRowHeight}
            backgroundColor="rgba(0,0,0,0.6)"
            width="100%"
          />
        </View>
        <View position="absolute" top={0} left={0} right={0} zIndex={1}>
          <TopNavigation
            appearance="ghost"
            level="none"
            IconLeft={
              <IconButton
                name="back-outline-300"
                size="md"
                appearance="filled"
                iconColor="primary.10"
                onPress={() => {
                  setState(ScanSteps.VALET);
                  setData({ valet: {}, tag: null });
                  navigation.navigate('valetSupervisor');
                }}
              />
            }
            IconRight={
              <IconButton
                name={tourchMode ? 'flash-on-300' : 'flash-off-300'}
                size="md"
                appearance="filled"
                iconColor="primary.10"
                onPress={() => {
                  setTourchMode(!tourchMode);
                }}
              />
            }
          />
        </View>

        {focused ? (
          <Camera
            flashMode={
              tourchMode
                ? (Camera.Constants.FlashMode as any).torch
                : (Camera.Constants.FlashMode as any).off
            }
            style={{ height: Platform.OS === 'android' ? '70%' : '50%' }}
            onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
            barCodeScannerSettings={{
              barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
            }}
            ratio="4:3"
            autoFocus={(Camera.Constants.AutoFocus as any).on}
          />
        ) : null}
      </View>
      <View
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        bg="background.primary.base"
        height="50%"
        borderTopLeftRadius={18}
        borderTopRightRadius={18}
      >
        <View pb={bottom} flex={1}>
          <View alignItems="center">
            <View
              width={40}
              height={4}
              bg="background.secondary.base"
              borderRadius="xs"
              mt="2xl"
            />
          </View>
          <ControllerScreen borderColor={borderColor} ref={scrollRef} />
        </View>
      </View>
    </Layout>
  );
};

export default ControllerScannerScreen;
