import React, { useRef } from 'react';
import { FlatList, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { View, Text, Button } from '../../components/new';
import theme, { space } from '../../themes/new/theme';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useCommonStore from '../../stores/commonStore';
import { UserRole } from '../../stores/userStore';
import { DateFormatsEnum, getFormatedDate } from '../../utilities/helper';
import BookingCard from '../../components/new/custom/BookingCard';
import CachedImage from '../../components/new/custom/CachedImage';

const BookingList = ({ bookings, loadMoreBookings }) => {
  const { width } = useWindowDimensions();
  const screenOrientationValue = useCommonStore((state) => state.orientation);
  const navigation = useNavigation<any>();
  const filteredBookings = bookings.filter((b) => {
    if (
      b?.status === 'ACTIVE' ||
      b?.status === 'CLAIMED' ||
      b?.status === 'EXPIRED' ||
      b?.status === 'CANCELLED'
    )
      return true;

    return false;
  });

  const checkAction = (item) => {
    navigation.navigate('eventTicket', {
      invoiceId: item?.invoiceId,
    });
  };

  const renderItem = ({ item, index }) => {
    const expiryDate = getFormatedDate(
      new Date(item?.expires_on),
      DateFormatsEnum.DateMonthYear,
    ) as string;

    return (
      <View
        mt="2xl"
        width={
          width > 1200 && screenOrientationValue < 3
            ? (375 - 3 * 16) / 2
            : (width - 3 * 16) / 2
        }
        minHeight={202}
        ml={index % 2 !== 0 ? '2xl' : 0}
      >
        <BookingCard
          imageURL={item?.coverImg}
          isExpired={item?.status === 'EXPIRED'}
          isCancelled={item?.status === 'CANCELLED'}
          reward={item.title}
          expireAt={expiryDate}
          onPress={() => {
            firebaseEventLogger('myBookings_event_card_tap', {
              buttonName: 'card',
              screenName: 'myBookings',
              userType: 'user',
              interactionType: 'tap',
              eventId: item._id,
            });
            checkAction(item);
          }}
        />
      </View>
    );
  };

  const flatListRef = useRef(null);

  return (
    <>
      {filteredBookings.length ? (
        <FlatList
          ref={flatListRef}
          onEndReached={loadMoreBookings}
          contentContainerStyle={{
            paddingHorizontal: theme.space['2xl'],
            marginTop: theme.space.lg,
            marginBottom: theme.space['4xl'],
          }}
          data={filteredBookings}
          renderItem={renderItem}
          keyExtractor={(item) => item._id}
          numColumns={2}
          ListFooterComponent={() => <View height="2xl" />}
          scrollIndicatorInsets={{ right: 0 }}
        />
      ) : (
        <View flex={1} justifyContent="center" alignItems="center" px="2xl">
          <View
            bottom={space['9xl'] + space['3xl']}
            alignItems="center"
            width="100%"
          >
            <CachedImage
              source={{
                uri: 'https://ik.imagekit.io/32ndhub/assets/no-order-found.png',
              }}
              style={{
                width: 280,
                height: 280,
                top: 20,
                resizeMode: 'contain',
              }}
            />
            <Text size="2xl" color="primary.400" weight="medium" mt="6xl">
              No Bookings Found!
            </Text>
            <Text size="md" color="primary.200" mt="lg" textAlign="center">
              There are no bookings at the moment, browse our events to book
            </Text>
            <View width="100%" mt="6xl">
              <Button
                size="lg"
                // state="disabled" // temp
                onPress={() => {
                  firebaseEventLogger('pastOrders__goToHome_Tap', {
                    buttonName: 'goToHome',
                    screenName: 'pastOrders',
                    userType: UserRole.USER,
                    interactionType: InteractionType.TAP,
                  });
                  navigation.navigate('events');
                }}
              >
                Explore Events
              </Button>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

export default BookingList;
