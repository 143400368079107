// Load the required clients and packages
import { updateUser } from '../relay/userApi';
import { uploadImageToS3Query } from '../relay/microServicesApis';
import useUserStore from '../stores/userStore';
import AppConstants from './AppConstants';

let uploadedPictureResult = {};

// eslint-disable-next-line consistent-return
const S3Upload = async (file, { userId, userRole }, refresh) => {
  file = file.assets[0];
  // Set the AWS Region
  const Region = 'ap-south-1'; // REGION
  const albumBucketName = '32nd-hub-app'; // BUCKET_NAME

  const { API_URL } = AppConstants;
  let env;
  if (API_URL === 'http://localhost:8000/graphql') {
    env = 'prod';
  } else {
    env = 'other';
  }
  const albumName = `profile-images/${env}/${userRole}/${userId}/`;

  if (file !== undefined) {
    const imageExt = 'jpg';
    const base64Image = file.base64;

    const handleUpdateUser = (response, err) => {
      if (response.updateUser !== null) {
        useUserStore
          .getState()
          .setProfileImage(response?.updateUser?.record?.profileImage);
        refresh();
      } else {
        // console.log(err);
      }
    };

    try {
      const fileName = `${new Date().getTime()}-image.${imageExt}`;
      const photoKey = albumName + fileName;
      const uploadParams = {
        bucketName: albumBucketName,
        key: photoKey,
        base64Image,
        contentType: 'image/jpeg',
      };

      try {
        await uploadImageToS3Query(uploadParams);
        const data = {};
        (data as any).id = userId;
        (data as any).record = {};
        (
          data as any
        ).record.profileImage = `profile-images/${env}/${userRole}/${userId}/${fileName}`;
        updateUser(data, handleUpdateUser, (err) => {});
        uploadedPictureResult = JSON.parse(JSON.stringify(data));
      } catch (err) {
        console.log('There was an error uploading your photo: ', err.message);
      }
      return uploadedPictureResult;
    } catch (err) {
      console.log(err);
      if (!file) {
        alert('Choose  a file to upload first.');
      }
    }
  }
};

export default S3Upload;
