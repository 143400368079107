/* eslint-disable import/prefer-default-export */
import { commitMutation, graphql } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

export const addValet = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation commonApiAddValetMutation($record: addValetRecordInput!) {
      addValet(record: $record) {
        record {
          _id
          role {
            name
          }
        }
      }
    }
  `;

  const variables = {
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      console.error(err);
      errorCallback(err);
    },
  });
};
