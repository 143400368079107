import React, { memo, useCallback, useRef, useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import useFeedbackModalStore, {
  FeedbackScreenSteps,
} from '../../stores/FeedbackModalStore';
import { Button, Chip, Input, Text, View } from '../new';
import IconComponent from './IconComponent';
import StarComponent from './StarComponent';
import { feedbacksTopics, getFeedbacks } from '../../utilities/ratingOptions';
import AddFeedback from '../../relay/feedbackApi';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../new/primitive/snackbar/helpers/helpers';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { Appearance, State } from '../../themes/new/helper';

const areEqual = () => {
  return true;
};

const RatingDetails = () => {
  const textInputRef = useRef(null);
  const scrollViewRef = useRef(null);
  const componentState = useFeedbackModalStore((state) => state);
  const [selected, setSelected] = useState(componentState.rating);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [summary, setSummary] = useState('');
  const [feedbackArray, setFeedbackArray] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const feedbackOptions = getFeedbacks({
    rating: selected,
    option: componentState.feedbackType,
  });

  const handleOnPress = useCallback((val) => {
    setSelected(val?.key);
    componentState.setRating(val?.key);
  }, []);

  const handleOnSubmit = useCallback(() => {
    firebaseEventLogger('RatingDetails__submitFeedback_Tap', {
      buttonName: 'submitFeedback',
      screenName: 'RatingDetails',
      userType: 'user',
      interactionType: 'tap',
      ratingType: componentState.feedbackType,
    });
    setShowLoading(true);
    const finalObj = {
      type: componentState.feedbackType,
      feedbacks: [
        {
          topic: 'OVERALL',
          ratingType: 'EMOJI',
          summary,
          rating: selected,
        },
        ...feedbackArray,
      ],
    };
    if (componentState.feedbackType === feedbacksTopics.VALET) {
      (finalObj as any).parkingId = componentState?.parkingId;
    } else if (componentState.feedbackType === feedbacksTopics.DINE_OUT) {
      (finalObj as any).restaurantId = componentState?.restaurantId;
      (finalObj as any).invoiceId = componentState?.invoiceId;
    }
    const feedbackData = finalObj;
    AddFeedback(feedbackData, handleAddFeedback, onError);
  }, [selected, summary, feedbackArray]);

  const handleAddFeedback = (response) => {
    setShowLoading(false);
    componentState.setOnSuccess(true);
    componentState.setPointsGiven(response?.addFeedback?.pointsRewarded ?? 0);
    componentState.setCurrentScreen(FeedbackScreenSteps.RATING_SUCCESS);
  };

  const onError = () => {
    setShowLoading(false);
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v1,
    });
  };

  const countSettingFunction = ({ val, item, isTags = false }) => {
    const feedbackDataArr = [...feedbackArray];
    const sel = feedbackDataArr.find((obj) => obj.topic === item?.titleEnum);
    const active = { ...sel };
    if (isTags) {
      const removeTag = active.tags.findIndex((obj) => obj === val);
      if (removeTag > -1) {
        active.tags.splice(removeTag, 1);
      } else {
        active.tags.push(val);
      }
    }
    const x = feedbackDataArr.findIndex((obj) => obj.topic === item?.titleEnum);
    if (x > -1) {
      feedbackDataArr.splice(x, 1);
    }
    if (isTags) {
      feedbackDataArr.push(active);
    } else {
      feedbackDataArr.push({
        topic: item?.titleEnum,
        ratingType: 'STARS',
        tags: [],
        rating: val,
      });
    }

    setFeedbackArray(feedbackDataArr);
  };

  const checkSelected = (item) => {
    let val = null;
    const selectedItem = feedbackArray.find((i) => i?.topic === item);
    if (selectedItem) {
      val = selectedItem;
    }
    return val;
  };

  const renderAdditionalReason = ({ val, selItem, item }) => {
    const tag = val?.enum;
    const isExist = selItem?.tags?.includes(tag);
    return (
      <Chip
        key={`ADDITIONAL_REAS0N_${val?.title}`}
        state={isExist ? State.SELECTED : State.ACTIVE}
        onPress={() => countSettingFunction({ val: tag, item, isTags: true })}
        size="xs"
        mr="sm+md"
        mb="xl"
        iconLeft=""
        iconRight=""
        isUpperCase={false}
        appearance={Appearance.FILLED}
      >
        {val?.title}
      </Chip>
    );
  };

  const renderOptions = ({ item, index }) => {
    const selItem = checkSelected(item?.titleEnum);
    return (
      <View
        borderTopWidth="xs"
        pt="3xl+xs"
        pb={selItem?.rating > 0 && selItem?.rating <= 3 ? 'lg' : '3xl+xs'}
        borderColor="primary.50"
        borderBottomWidth={
          index === feedbackOptions?.optionsArray?.length - 1 ? 'xs' : 0
        }
        key={`OPTION_STAR_${item?.title}`}
      >
        <View
          flexDirection="row"
          key={`feedback_options${item?.title}`}
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <View flex={0.38}>
            <Text color="primary.300" size="lg" pt="xs">
              {item.title}
            </Text>
          </View>
          <View flex={0.62}>
            <StarComponent
              onPress={(val) => countSettingFunction({ val, item })}
              count={selItem?.rating ?? 0}
            />
          </View>
        </View>
        {selItem?.rating > 0 && selItem?.rating <= 3 ? (
          <View mt="3xl" flexDirection="row" style={{ flexWrap: 'wrap' }}>
            {item?.options?.map((val) =>
              renderAdditionalReason({
                val,
                selItem,
                item,
              }),
            )}
          </View>
        ) : null}
      </View>
    );
  };

  const handleSummary = (val) => {
    setSummary(val);
  };

  const checkBtnState = () => {
    return feedbackOptions?.optionsArray?.length === feedbackArray?.length
      ? 'filled'
      : 'outline';
  };

  if (Platform.OS === 'web') {
    return (
      <View px="2xl" pb="2xl">
        <Text mt="3xl" size="xl" color="primary.200">
          {feedbackOptions.heading}
        </Text>
        <View mt="2xl" flexDirection="row">
          <IconComponent
            onPress={(val) => handleOnPress(val)}
            selected={selected}
          />
        </View>
        <View mt="3xl">
          {feedbackOptions?.optionsArray?.map((item, index) =>
            renderOptions({ item, index }),
          )}
        </View>
        <View mt="2xl">
          <Text size="md" color="primary.400" mb="2xl">
            Tell us more about your experience?
            <Text size="md" color="primary.100">
              {` ${`(optional)`}`}
            </Text>
          </Text>
          <Input
            multiline
            numberOfLines={3}
            inputMode="text"
            inputRef={textInputRef}
            label=""
            returnKeyType="next"
            placeholder="Write to us..."
            value={summary}
            maxLength={120}
            onChangeText={handleSummary}
            borderColor="primary.50"
            style={{ borderColor: 'white', height: 56 }}
          />
        </View>
        <Button
          mt="2xl"
          size="lg"
          appearance={checkBtnState()}
          status="primary"
          state={showLoading ? 'disabled' : 'active'}
          onPress={handleOnSubmit}
          loading={showLoading}
        >
          Submit Feedback
        </Button>
      </View>
    );
  }
  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'position'}
    >
      <View flex={1}>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
          }}
          ref={scrollViewRef}
          keyboardShouldPersistTaps="handled"
        >
          <View px="2xl" pb="2xl">
            <Text mt="3xl" size="xl" color="primary.200">
              {feedbackOptions.heading}
            </Text>
            <View mt="2xl" flexDirection="row">
              <IconComponent
                onPress={(val) => handleOnPress(val)}
                selected={selected}
              />
            </View>
            <View mt="3xl">
              {feedbackOptions?.optionsArray?.map((item, index) =>
                renderOptions({ item, index }),
              )}
            </View>
            <View mt="2xl">
              <Text size="md" color="primary.400" mb="2xl">
                Tell us more about your experience?
                <Text size="md" color="primary.100">
                  {` ${`(optional)`}`}
                </Text>
              </Text>
              <Input
                multiline
                numberOfLines={3}
                inputMode="text"
                inputRef={textInputRef}
                label=""
                returnKeyType="next"
                placeholder="Write to us..."
                value={summary}
                maxLength={120}
                onChangeText={handleSummary}
                borderColor="primary.50"
                style={{
                  borderColor: 'white',
                  height: 56,
                  textAlignVertical: 'top',
                }}
              />
            </View>
            <Button
              mt="2xl"
              size="lg"
              appearance={checkBtnState()}
              status="primary"
              state={showLoading ? 'disabled' : 'active'}
              onPress={handleOnSubmit}
              loading={showLoading}
            >
              Submit Feedback
            </Button>
          </View>
        </ScrollView>
      </View>
    </KeyboardAvoidingView>
  );
};

export default memo(RatingDetails, areEqual);
