/**
 * @generated SignedSource<<58deee34d86a0b36d5e959bf08bc1783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ShareOnInstagramBannerQuery$variables = {};
export type ShareOnInstagramBannerQuery$data = {
  readonly getInstagramBasicData: {
    readonly data: {
      readonly followers_count: number | null;
      readonly momentsMedia: ReadonlyArray<{
        readonly deepLink: string | null;
        readonly displayOrder: number | null;
        readonly mediaType: string | null;
        readonly mediaUrl: string | null;
      } | null> | null;
    } | null;
    readonly platform: string;
  } | null;
};
export type ShareOnInstagramBannerQuery = {
  response: ShareOnInstagramBannerQuery$data;
  variables: ShareOnInstagramBannerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SocialMedia",
    "kind": "LinkedField",
    "name": "getInstagramBasicData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "platform",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InstagramBasicData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "followers_count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MomentsData",
            "kind": "LinkedField",
            "name": "momentsMedia",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mediaUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deepLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mediaType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayOrder",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareOnInstagramBannerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ShareOnInstagramBannerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2bc8c5fd05362c3bfbac5fcbdeaea224",
    "id": null,
    "metadata": {},
    "name": "ShareOnInstagramBannerQuery",
    "operationKind": "query",
    "text": "query ShareOnInstagramBannerQuery {\n  getInstagramBasicData {\n    platform\n    data {\n      followers_count\n      momentsMedia {\n        mediaUrl\n        deepLink\n        mediaType\n        displayOrder\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ceb686e95af59d3802cc62645eb548c";

export default node;
