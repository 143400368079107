/* eslint-disable import/prefer-default-export */
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useRef, useState } from 'react';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import { pagination } from '../../../../utilities/Constant';
import useRestaurantPartnerStore from '../../../../stores/restaurantpartner/restaurantPartnerStore';
import { toISOStringWithTimezone } from '../../../../utilities/helper';
import { transactionsScreenMainQuery$variables } from './__generated__/transactionsScreenMainQuery.graphql';
import { ITransaction } from '../../types';
import { useTransactions } from '../../API/fragments/transactionsFragment/useTransactions';
import { transactionsScreenMainQuery } from './transactionsScreenMainQuery';

const todaysDate = toISOStringWithTimezone(new Date());

export const useTransactionsScreen = (
  queryOptions: NewErrorBoundaryParentState,
  refresh: () => void,
) => {
  const bsRef = useRef();
  const restaurantId = useRestaurantPartnerStore((state) => state.restaurantId);
  const [today, setToday] = useState(todaysDate);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<ITransaction | null>(null);

  const variables: transactionsScreenMainQuery$variables = {
    sort: 'CREATED_AT_DESC',
    filter: {
      orderId: restaurantId,
      datePeriod: {
        from: today,
        period: 'DAILY',
      },
    },
    pageSize: pagination.defaultPageSize,
  };

  const onPullToRefresh = useCallback(() => {
    setRefreshing(true);
    refetchTransactions(() => setRefreshing(false));
  }, []);

  useFocusEffect(
    useCallback(() => {
      setToday(toISOStringWithTimezone(new Date()));
    }, []),
  );

  const transactionRef = useLazyLoadQuery(
    transactionsScreenMainQuery,
    variables,
    queryOptions,
  );
  const {
    transactions,
    fetchMoreTransactions,
    refetchTransactions,
    hasMoreTransactions,
  } = useTransactions(transactionRef);

  const showTransactionDetails = (orderId: ITransaction['orderId']) => {
    const txn = transactions.filter((tx) => tx.orderId === orderId)[0];
    setSelectedTransaction(txn);
    bsRef.current?.show();
  };

  const hideTransactionDetails = () => {
    setSelectedTransaction(null);
  };

  return {
    bsRef,
    transactions,
    pullToRefresh: {
      refreshing,
      onRefresh: onPullToRefresh,
    },
    selectedTransaction,
    hasMoreTransactions,
    showTransactionDetails,
    hideTransactionDetails,
    fetchMoreTransactions: () => {
      if (hasMoreTransactions)
        fetchMoreTransactions(pagination.defaultPageSize);
    },
  };
};
