/**
 * @generated SignedSource<<f49769da833c1b7d803d49e0584c951c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type searchParkingList_findParkingLocation$data = ReadonlyArray<{
  readonly _id: any;
  readonly address: {
    readonly addressLine1: string;
  };
  readonly name: string;
  readonly parkingSpaces: {
    readonly floor: ReadonlyArray<{
      readonly _id: any | null;
      readonly bay: ReadonlyArray<{
        readonly _id: any | null;
        readonly bayName: string;
        readonly parkingOccupied: number | null;
        readonly parkingSpots: number | null;
        readonly parkingVacant: number | null;
      } | null>;
      readonly floorName: string;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "searchParkingList_findParkingLocation";
}>;
export type searchParkingList_findParkingLocation$key = ReadonlyArray<{
  readonly " $data"?: searchParkingList_findParkingLocation$data;
  readonly " $fragmentSpreads": FragmentRefs<"searchParkingList_findParkingLocation">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "searchParkingList_findParkingLocation",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "parkingLocationAddress",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine1",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "parkingLocationParkingSpaces",
      "kind": "LinkedField",
      "name": "parkingSpaces",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "parkingLocationParkingSpacesFloor",
          "kind": "LinkedField",
          "name": "floor",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "floorName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "parkingLocationParkingSpacesFloorBay",
              "kind": "LinkedField",
              "name": "bay",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parkingSpots",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parkingOccupied",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parkingVacant",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "parkingLocation",
  "abstractKey": null
};
})();

(node as any).hash = "139da7127909d637bed1cba4bf241fdb";

export default node;
