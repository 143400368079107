import React from 'react';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import Text from '../primitive/Typography/Text/Text';
import View from '../basic/View/View';
import { formatTimeToHrsMinSec } from '../../../utilities/helper';

const CircularTimer = ({
  isPlaying,
  duration,
  colors,
  colorsTime,
  size,
  trailStrokeWidth,
  trailColor,
  innerCircleBg,
  updateInterval,
  strokeWidth,
  innerCircleMargin,
  textColor,
  onComplete,
  timerText,
  initialRemainingTime,
}) => {
  return (
    <CountdownCircleTimer
      isPlaying={isPlaying}
      duration={duration}
      colors={colors}
      colorsTime={colorsTime}
      onComplete={onComplete}
      updateInterval={updateInterval}
      isGrowing
      strokeLinecap="round"
      rotation="counterclockwise"
      isSmoothColorTransition
      size={size}
      trailStrokeWidth={trailStrokeWidth}
      trailColor={trailColor}
      strokeWidth={strokeWidth}
      initialRemainingTime={initialRemainingTime}
    >
      {({ remainingTime, color }) => (
        <View
          width={size - 2 * trailStrokeWidth - innerCircleMargin}
          height={size - 2 * trailStrokeWidth - innerCircleMargin}
          borderRadius={size / 2}
          bg={innerCircleBg || color}
          alignItems="center"
          justifyContent="center"
        >
          <Text
            size="xl"
            color={textColor}
            weight="medium"
            accessibilityRole="timer"
            accessibilityLiveRegion="assertive"
            importantForAccessibility="yes"
          >
            {formatTimeToHrsMinSec(remainingTime)}
          </Text>
          <Text size="xs" color={textColor}>
            {timerText}
          </Text>
        </View>
      )}
    </CountdownCircleTimer>
  );
};

export default CircularTimer;

// import React, { useCallback } from 'react';
// import {
//   StyleSheet,
//   Text,
//   View,
//   Dimensions,
//   TouchableOpacity,
//   useWindowDimensions,
// } from 'react-native';
// import Animated, {
//   useSharedValue,
//   withTiming,
//   useAnimatedProps,
//   useDerivedValue,
// } from 'react-native-reanimated';

// import Svg, { Circle } from 'react-native-svg';
// import AnimatedText from './AnimatedText';

// const CircularTimer = () => {
//   const BACKGROUND_COLOR = '#444B6F';
//   const BACKGROUND_STROKE_COLOR = '#303858';
//   const STROKE_COLOR = '#A6E1FA';

//   const { width, height } = useWindowDimensions();

//   const CIRCLE_LENGTH = 1000; // 2PI*R
//   const R = CIRCLE_LENGTH / (2 * Math.PI);

//   const AnimatedCircle = Animated.createAnimatedComponent(Circle);
//   const progress = useSharedValue(0);

//   const animatedProps = useAnimatedProps(() => ({
//     strokeDashoffset: CIRCLE_LENGTH * (1 - progress.value),
//   }));

//   const progressText = useDerivedValue(() => {
//     return `${Math.floor(progress.value * 100)}`;
//   });

//   const onPress = useCallback(() => {
//     progress.value = withTiming(progress.value > 0 ? 0 : 1, { duration: 2000 });
//   }, []);

//   return (
//     <View style={[styles.container, { backgroundColor: BACKGROUND_COLOR }]}>
//       <AnimatedText style={styles.progressText} text={progressText.value} />
//       <Svg style={{ position: 'absolute' }}>
//         <Circle
//           cx={width / 2}
//           cy={height / 2}
//           r={R}
//           stroke={BACKGROUND_STROKE_COLOR}
//           strokeWidth={30}
//         />
//         <AnimatedCircle
//           cx={width / 2}
//           cy={height / 2}
//           r={R}
//           stroke={STROKE_COLOR}
//           strokeWidth={15}
//           strokeDasharray={CIRCLE_LENGTH}
//           animatedProps={animatedProps}
//           strokeLinecap="round"
//         />
//       </Svg>
//       <TouchableOpacity
//         onPress={onPress}
//         style={[
//           styles.button,
//           { width: width * 0.7, backgroundColor: BACKGROUND_COLOR },
//         ]}
//       >
//         <Text style={styles.buttonText}>Run</Text>
//       </TouchableOpacity>
//     </View>
//   );
// };

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   progressText: {
//     fontSize: 80,
//     color: 'rgba(256,256,256,0.7)',
//     width: 200,
//     textAlign: 'center',
//   },
//   button: {
//     position: 'absolute',
//     bottom: 80,
//     // width: width * 0.7,
//     height: 60,
//     // backgroundColor: BACKGROUND_STROKE_COLOR,
//     borderRadius: 25,
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   buttonText: {
//     fontSize: 25,
//     color: 'white',
//     letterSpacing: 2.0,
//   },
// });

// export default CircularTimer;
