/**
 * @generated SignedSource<<30fed239ebee8f61cdf9ac7494488091>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumUserContactEmailType = "PERSONAL" | "WORK" | "%future added value";
export type EnumUserPartnerRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumUserRolePermission = "staff" | "%future added value";
export type EnumUserRoleSecondaryRole = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type addUserRecordInput = {
  contact: UserContactInput;
  createdBy?: any | null;
  deleted?: boolean | null;
  deletedAt?: any | null;
  devices?: ReadonlyArray<UserDevicesInput | null> | null;
  firstLogin?: boolean | null;
  firstLoginAt?: any | null;
  isEmployee?: boolean | null;
  lastLogin?: any | null;
  name: UserNameInput;
  parkedCount?: number | null;
  partnerRefId?: any | null;
  partnerRefType?: EnumUserPartnerRefType | null;
  photoMomentsSearchImage?: string | null;
  pin?: string | null;
  profileImage?: string | null;
  role?: UserRoleInput | null;
  typeForm?: UserTypeFormInput | null;
  uniBoxCredentials?: UserUniBoxCredentialsInput | null;
  visitCount?: number | null;
  waId?: string | null;
  wallet?: UserWalletInput | null;
};
export type UserNameInput = {
  _id?: any | null;
  first: string;
  last?: string | null;
};
export type UserContactInput = {
  _id?: any | null;
  email?: ReadonlyArray<UserContactEmailInput | null> | null;
  phone: UserContactPhoneInput;
};
export type UserContactPhoneInput = {
  _id?: any | null;
  number: string;
  prefix: string;
};
export type UserContactEmailInput = {
  _id?: any | null;
  addr?: string | null;
  type?: EnumUserContactEmailType | null;
};
export type UserDevicesInput = {
  OS?: string | null;
  _id?: any | null;
  deviceToken?: string | null;
  enabled?: boolean | null;
  expoToken?: string | null;
  modelName?: string | null;
  platform?: string | null;
};
export type UserTypeFormInput = {
  _id?: any | null;
  landedAt: any;
  submittedAt: any;
};
export type UserRoleInput = {
  _id?: any | null;
  meta: UserRoleMetaInput;
  name: EnumUserRoleName;
  permission?: EnumUserRolePermission | null;
  secondaryRole?: ReadonlyArray<EnumUserRoleSecondaryRole | null> | null;
};
export type UserRoleMetaInput = {
  _id?: any | null;
  campus: UserRoleMetaCampusInput;
};
export type UserRoleMetaCampusInput = {
  _id: any;
  name?: string | null;
};
export type UserUniBoxCredentialsInput = {
  _id?: any | null;
  password: string;
  username: string;
};
export type UserWalletInput = {
  _id?: any | null;
  currentBalance?: number | null;
  earnedAmount?: number | null;
  moneySaved?: number | null;
  totalBalance?: number | null;
};
export type authApiAddUserV2Mutation$variables = {
  record: addUserRecordInput;
};
export type authApiAddUserV2Mutation$data = {
  readonly addUser: {
    readonly record: {
      readonly _id: any;
      readonly role: {
        readonly name: EnumUserRoleName | null;
      } | null;
      readonly token: string | null;
    } | null;
  } | null;
};
export type authApiAddUserV2Mutation = {
  response: authApiAddUserV2Mutation$data;
  variables: authApiAddUserV2Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOneUserPayload",
    "kind": "LinkedField",
    "name": "addUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authApiAddUserV2Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authApiAddUserV2Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0813b9084ddbb7dfb70f5c7c11223ed3",
    "id": null,
    "metadata": {},
    "name": "authApiAddUserV2Mutation",
    "operationKind": "mutation",
    "text": "mutation authApiAddUserV2Mutation(\n  $record: addUserRecordInput!\n) {\n  addUser(record: $record) {\n    record {\n      _id\n      token\n      role {\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b36e86bb27e095b3d5c76cfa60ae12d";

export default node;
