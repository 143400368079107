import AsyncStorage from '@react-native-async-storage/async-storage';
import AppConstants from '../utilities/AppConstants';

interface Currency {
  code: string;
  name: string;
  symbol: string;
}

interface TimeZone {
  name: string;
  offset: number;
  offset_with_dst: number;
  current_time: string;
  current_time_unix: number;
  is_dst: boolean;
  dst_savings: number;
  dst_exists: boolean;
  dst_start: string;
  dst_end: string;
}

interface IPGeolocation {
  ip: string;
  continent_code: string;
  continent_name: string;
  country_code2: string;
  country_code3: string;
  country_name: string;
  country_name_official: string;
  country_capital: string;
  state_prov: string;
  state_code: string;
  district: string;
  city: string;
  zipcode: string;
  latitude: string;
  longitude: string;
  is_eu: boolean;
  calling_code: string;
  country_tld: string;
  languages: string;
  country_flag: string;
  geoname_id: string;
  isp: string;
  connection_type: string;
  organization: string;
  country_emoji: string;
  currency: Currency;
  time_zone: TimeZone;
}

export default class IpGeoLocationService {
  private readonly apiKey: string;

  private readonly cacheKey: string = 'IPGeolocationCache';

  constructor() {
    this.apiKey = AppConstants.IP_GEO_LOCATION_KEY;
  }

  private async getLocationDetailsFromIp(): Promise<IPGeolocation> {
    const response = await fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${this.apiKey}`,
    );
    const apiResponse = await response.json();
    return apiResponse;
  }

  private async getCachedLocationDetails(): Promise<IPGeolocation | null> {
    const cachedData = await AsyncStorage.getItem(this.cacheKey);
    if (!cachedData) {
      return null;
    }

    const { data, timestamp } = JSON.parse(cachedData);

    const now = new Date().getTime();
    if (now - timestamp > 10 * 60 * 1000) {
      return null; // Cache is older than 10 minutes
    }

    return data;
  }

  private async setCachedLocationDetails(data: IPGeolocation): Promise<void> {
    const cacheData = {
      data,
      timestamp: new Date().getTime(),
    };
    await AsyncStorage.setItem(this.cacheKey, JSON.stringify(cacheData));
  }

  public async getLocationDetails(): Promise<IPGeolocation> {
    const cachedData = await this.getCachedLocationDetails();
    if (cachedData) {
      return cachedData;
    }
    const locationDetails = await this.getLocationDetailsFromIp();
    await this.setCachedLocationDetails(locationDetails);
    return locationDetails;
  }
}
