/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { checkVersion } from 'react-native-check-version';
import { Linking, Platform } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { View, Text, Button } from '../..';
import useCommonStore, {
  UpdateModelStatesEnum,
} from '../../../../stores/commonStore';
import useUserStore, { UserRole } from '../../../../stores/userStore';
import BottomSheetV2 from '../../composites/BottomSheet/BottomSheetV2';
import CustomLottieView from '../CustomLottieView';
import {
  appBundleId,
  installedVersion,
  onPressUpdateNow,
  UpdateText,
} from '../../../../hooks/useUpdateApp';
import { Appearance, State, Status } from '../../../../themes/new/helper';
import AppConstants from '../../../../utilities/AppConstants';

const UpdateModel = () => {
  const bottomSheetModalRef = useRef(null);
  const isFocused = useIsFocused();
  const { deniedTime, setDeniedTime, showUpdateModal, setShowUpdateModal } =
    useCommonStore((state) => state);
  const handleUpdateModalPress = useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);

  const handleOnDismiss = useCallback(() => {
    setDeniedTime(new Date());
    bottomSheetModalRef.current?.hide();
    setShowUpdateModal({ state: false, status: '' });
  }, []);
  const { role } = useUserStore((state) => state);

  const checkRoleUser = () => {
    if (role === UserRole.USER || role === '') {
      return true;
    }
    return false;
  };

  const onPress = () => {
    if (Platform.OS === 'ios') {
      Linking.openURL(
        `https://apps.apple.com/in/app/32nd-hub/${AppConstants.APP_STORE_ID}`,
      );
    } else {
      onPressUpdateNow({ status: showUpdateModal?.status });
      setDeniedTime(null);
    }
  };

  const checkUpdates = async () => {
    if (deniedTime) {
      // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      const time = Math.abs(new Date() - new Date(deniedTime));
      if (time && Math.ceil(time / (1000 * 60 * 60 * 24)) <= 1) return;
    }
    const [installedMajor, installedMinor, installedMicro] =
      installedVersion?.split('.');
    try {
      const updates = await checkVersion({
        bundleId: appBundleId,
        country: 'in',
      });
      if (updates.needsUpdate && installedVersion < updates.version) {
        const [major, minor, micro] = updates.version.split('.');
        const isMajorGTInstalled =
          parseInt(major, 10) > parseInt(installedMajor, 10);
        const isMajorLTInstalled =
          parseInt(major, 10) < parseInt(installedMajor, 10);
        const isMinorGTInstalled =
          parseInt(minor, 10) > parseInt(installedMinor, 10);
        const isMinorGTEInstalled =
          parseInt(minor, 10) >= parseInt(installedMinor, 10);
        const diffInstalledMinor =
          parseInt(minor, 10) - parseInt(installedMinor, 10);
        const isMicroGTInstalled =
          parseInt(micro, 10) > parseInt(installedMicro, 10);
        const diffInstalledMicro =
          parseInt(micro, 10) - parseInt(installedMicro, 10);
        if (isMajorLTInstalled) {
          return;
        }
        if (isMajorGTInstalled) {
          setShowUpdateModal({
            state: true,
            status: UpdateModelStatesEnum.FORCE,
          });
          return;
        }
        if (isMinorGTInstalled && diffInstalledMinor >= 1 && checkRoleUser()) {
          if (diffInstalledMinor >= 3) {
            setShowUpdateModal({
              state: true,
              status: UpdateModelStatesEnum.FORCE,
            });
          } else {
            setShowUpdateModal({
              state: true,
              status: UpdateModelStatesEnum.PROMPT,
            });
          }
        }
        if (isMinorGTInstalled && diffInstalledMinor >= 1 && !checkRoleUser()) {
          setShowUpdateModal({
            state: true,
            status: UpdateModelStatesEnum.FORCE,
          });
        }
        if (
          isMinorGTEInstalled &&
          isMicroGTInstalled &&
          diffInstalledMicro >= 1
        ) {
          if (
            parseInt(micro, 10) - parseInt(installedMicro, 10) >= 3 &&
            !checkRoleUser()
          ) {
            setShowUpdateModal({
              state: true,
              status: UpdateModelStatesEnum.FORCE,
            });
          } else {
            setShowUpdateModal({
              state: true,
              status: UpdateModelStatesEnum.PROMPT,
            });
          }
        }
        // console.log(showModal);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      setShowUpdateModal({ state: false, status: '' });
      checkUpdates();
    }
  }, [isFocused]);

  useEffect(() => {
    if (showUpdateModal?.state && showUpdateModal?.status !== '') {
      handleUpdateModalPress();
    }
  }, [showUpdateModal]);

  return (
    <View>
      <BottomSheetV2
        ref={bottomSheetModalRef}
        onClose={handleOnDismiss}
        closable={showUpdateModal?.status !== UpdateModelStatesEnum.FORCE}
        insets={false}
      >
        <View>
          <View alignSelf="center">
            <CustomLottieView
              style={{ width: 200, height: 200 }}
              autoPlay
              loop
              source={require('../../../../../assets/lottie/update-rocket.json')}
              containerWidth={200}
            />
          </View>
          <View px="2xl" mt="2xl">
            <Text size="xl" color="primary.400" textAlign="center">
              {UpdateText.title}
            </Text>
            <Text size="sm" color="primary.200" mt="lg" textAlign="center">
              {UpdateText.message}
            </Text>
          </View>
          <View
            px="2xl"
            mt="4xl"
            mb="2xl"
            flexDirection="row"
            alignItems="center"
          >
            {showUpdateModal?.status !== UpdateModelStatesEnum.FORCE ? (
              <View flex={1}>
                <Button
                  size="lg"
                  appearance={Appearance.OUTLINE}
                  status={Status.PRIMARY}
                  state={
                    showUpdateModal?.status === UpdateModelStatesEnum.FORCE
                      ? State.DISABLED
                      : State.ACTIVE
                  }
                  onPress={handleOnDismiss}
                >
                  {UpdateText.askMeLaterBtnText}
                </Button>
              </View>
            ) : (
              <></>
            )}
            <View
              flex={1}
              ml={
                showUpdateModal?.status !== UpdateModelStatesEnum.FORCE
                  ? '2xl'
                  : 0
              }
            >
              <Button
                size="lg"
                appearance={Appearance.FILLED}
                status={Status.PRIMARY}
                state={State.ACTIVE}
                onPress={onPress}
              >
                {UpdateText.upadteNowBtnText}
              </Button>
            </View>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default UpdateModel;
