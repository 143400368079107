import React from 'react';
import { PlaceholderLine } from 'rn-placeholder';
import { View } from '../../../../components/new';

const PointsCardPlacholder = () => {
  return (
    <View
      shadow="lg"
      bg="primary.10"
      borderRadius="xl"
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingTop: 24,
        height: 186,
      }}
    >
      <PlaceholderLine height={44} width={70} />
      <PlaceholderLine height={18} width={55} />
      <PlaceholderLine height={24} />
    </View>
  );
};

export default PointsCardPlacholder;
