/**
 * @generated SignedSource<<434413895563655bfca642fc9d87ed9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumaddonAdditionalCashbackRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonAdditionalDiscountRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonPriceDiscountBaseDiscountType = "PERCENTAGE" | "%future added value";
export type EnumaddonType = "a_32ND_BLACK_MEMBERSHIP" | "%future added value";
export type findValetWorkLogFilterInput = {
  date?: any | null;
  valetID: any;
};
export type FilterFindManyaddonInput = {
  AND?: ReadonlyArray<FilterFindManyaddonInput> | null;
  OR?: ReadonlyArray<FilterFindManyaddonInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyaddonOperatorsInput | null;
  additionalCashback?: ReadonlyArray<FilterFindManyaddonAdditionalCashbackInput | null> | null;
  additionalDiscount?: ReadonlyArray<FilterFindManyaddonAdditionalDiscountInput | null> | null;
  expiryDuration?: FilterFindManyaddonExpiryDurationInput | null;
  isMembershipType?: boolean | null;
  name?: string | null;
  price?: number | null;
  priceDiscount?: ReadonlyArray<FilterFindManyaddonPriceDiscountInput | null> | null;
  tier?: number | null;
  type?: EnumaddonType | null;
};
export type FilterFindManyaddonPriceDiscountInput = {
  _id?: any | null;
  baseDiscount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
};
export type FilterFindManyaddonPriceDiscountBaseDiscountInput = {
  _id?: any | null;
  type?: EnumaddonPriceDiscountBaseDiscountType | null;
  value?: number | null;
};
export type FilterFindManyaddonExpiryDurationInput = {
  _id?: any | null;
  days?: number | null;
};
export type FilterFindManyaddonAdditionalCashbackInput = {
  _id?: any | null;
  cashback?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refId?: any | null;
  refType?: EnumaddonAdditionalCashbackRefType | null;
};
export type FilterFindManyaddonAdditionalDiscountInput = {
  _id?: any | null;
  discount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refType?: EnumaddonAdditionalDiscountRefType | null;
};
export type FilterFindManyaddonOperatorsInput = {
  _id?: FilterFindManyaddon_idOperatorsInput | null;
  tier?: FilterFindManyaddonTierOperatorsInput | null;
  type?: FilterFindManyaddonTypeOperatorsInput | null;
};
export type FilterFindManyaddonTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnumaddonType | null;
  gte?: EnumaddonType | null;
  in?: ReadonlyArray<EnumaddonType | null> | null;
  lt?: EnumaddonType | null;
  lte?: EnumaddonType | null;
  ne?: EnumaddonType | null;
  nin?: ReadonlyArray<EnumaddonType | null> | null;
};
export type FilterFindManyaddonTierOperatorsInput = {
  exists?: boolean | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number | null> | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
  nin?: ReadonlyArray<number | null> | null;
};
export type FilterFindManyaddon_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type accountScreenQuery$variables = {
  addonsFilter?: FilterFindManyaddonInput | null;
  filter: findValetWorkLogFilterInput;
  isLoggedIn: boolean;
  isNotUser: boolean;
  userId: any;
};
export type accountScreenQuery$data = {
  readonly findAddons?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"profileCardQueryAddonFragment_findAddonQuery">;
  }>;
  readonly findUserById?: {
    readonly " $fragmentSpreads": FragmentRefs<"profileCardQueryFragment_findUserById_Query">;
  } | null;
  readonly findValetWorkLog?: {
    readonly " $fragmentSpreads": FragmentRefs<"profileCardQueryFragment_findWorkLogFragment_Query">;
  } | null;
};
export type accountScreenQuery = {
  response: accountScreenQuery$data;
  variables: accountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addonsFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLoggedIn"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNotUser"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "_id",
    "variableName": "userId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "addonsFilter"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountScreenQuery",
    "selections": [
      {
        "condition": "isNotUser",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "valetWorkLog",
            "kind": "LinkedField",
            "name": "findValetWorkLog",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "profileCardQueryFragment_findWorkLogFragment_Query"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isLoggedIn",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "findUserById",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "profileCardQueryFragment_findUserById_Query"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isNotUser",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "addon",
            "kind": "LinkedField",
            "name": "findAddons",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "profileCardQueryAddonFragment_findAddonQuery"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "accountScreenQuery",
    "selections": [
      {
        "condition": "isNotUser",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "valetWorkLog",
            "kind": "LinkedField",
            "name": "findValetWorkLog",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "valetWorkLogWorkLogHistory",
                "kind": "LinkedField",
                "name": "workLogHistory",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "time",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "valetWorkLogWorkSummary",
                "kind": "LinkedField",
                "name": "workSummary",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tasksDone",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isLoggedIn",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "findUserById",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserName",
                "kind": "LinkedField",
                "name": "name",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identityCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserRole",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userSince",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parkedCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visitCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileImage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMemberships",
                "kind": "LinkedField",
                "name": "memberships",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "membershipAddonId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expiryDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserWallet",
                "kind": "LinkedField",
                "name": "wallet",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moneySaved",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "earnedAmount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isNotUser",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "addon",
            "kind": "LinkedField",
            "name": "findAddons",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "22138919394ce7488cb6ba68dfb876c0",
    "id": null,
    "metadata": {},
    "name": "accountScreenQuery",
    "operationKind": "query",
    "text": "query accountScreenQuery(\n  $filter: findValetWorkLogFilterInput!\n  $addonsFilter: FilterFindManyaddonInput\n  $userId: MongoID!\n  $isNotUser: Boolean!\n  $isLoggedIn: Boolean!\n) {\n  findValetWorkLog(filter: $filter) @include(if: $isNotUser) {\n    ...profileCardQueryFragment_findWorkLogFragment_Query\n  }\n  findUserById(_id: $userId) @include(if: $isLoggedIn) {\n    ...profileCardQueryFragment_findUserById_Query\n  }\n  findAddons(filter: $addonsFilter) @skip(if: $isNotUser) {\n    ...profileCardQueryAddonFragment_findAddonQuery\n  }\n}\n\nfragment profileCardQueryAddonFragment_findAddonQuery on addon {\n  _id\n  type\n}\n\nfragment profileCardQueryFragment_findUserById_Query on User {\n  _id\n  name {\n    first\n    last\n  }\n  identityCode\n  role {\n    name\n  }\n  userSince\n  parkedCount\n  visitCount\n  profileImage\n  memberships {\n    membershipAddonId\n    status\n    expiryDate\n  }\n  wallet {\n    moneySaved\n    earnedAmount\n  }\n}\n\nfragment profileCardQueryFragment_findWorkLogFragment_Query on valetWorkLog {\n  workLogHistory {\n    time\n    type\n  }\n  workSummary {\n    tasksDone\n  }\n}\n"
  }
};
})();

(node as any).hash = "50f9ed6d5034ee390fc6d06765ecdcd1";

export default node;
