/**
 * @generated SignedSource<<dc0160825d8d988e802bf4add7bc234a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type updateVehicleRecordInput = {
  brand?: UpdateByIdVehicleBrandInput | null;
  color?: string | null;
  isWhitelisted?: boolean | null;
  ownerID?: any | null;
  registration?: UpdateByIdVehicleRegistrationInput | null;
};
export type UpdateByIdVehicleBrandInput = {
  _id?: any | null;
  brandLogo?: string | null;
  name?: string | null;
};
export type UpdateByIdVehicleRegistrationInput = {
  _id?: any | null;
  city?: string | null;
  plate?: string | null;
  state?: string | null;
};
export type vehicleApiUpdateMutation$variables = {
  id: any;
  record: updateVehicleRecordInput;
};
export type vehicleApiUpdateMutation$data = {
  readonly updateVehicle: {
    readonly record: {
      readonly _id: any;
      readonly brand: {
        readonly _id: any | null;
        readonly name: string | null;
      } | null;
      readonly color: string | null;
      readonly registration: {
        readonly plate: string;
      };
    } | null;
  } | null;
};
export type vehicleApiUpdateMutation = {
  response: vehicleApiUpdateMutation$data;
  variables: vehicleApiUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdVehiclePayload",
    "kind": "LinkedField",
    "name": "updateVehicle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Vehicle",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "VehicleBrand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VehicleRegistration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "vehicleApiUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "vehicleApiUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "91f09da92ebd513487c234972476640b",
    "id": null,
    "metadata": {},
    "name": "vehicleApiUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation vehicleApiUpdateMutation(\n  $id: MongoID!\n  $record: updateVehicleRecordInput!\n) {\n  updateVehicle(_id: $id, record: $record) {\n    record {\n      _id\n      brand {\n        _id\n        name\n      }\n      color\n      registration {\n        plate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7cd85865f6b5a7aa208e9466d096732b";

export default node;
