/**
 * @generated SignedSource<<ae4816b49a041be1618d9b0d9a20ba31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumParkingParkingInvoicePaymentDetailsFocReason = "CUSTOMER_ISSUE" | "EMPLOYEE_EXIT" | "FREE_PARKING_TICKET" | "MISSED_EXIT" | "OTHER" | "VALET_EXIT" | "VIP_EXIT" | "%future added value";
export type EnumParkingParkingInvoicePaymentDetailsMode = "APP" | "CASH" | "FASTAG" | "FOC" | "ONLINE" | "%future added value";
export type EnumParkingStatus = "FAULTY_PARK" | "FAULTY_RECALL" | "INCOMING" | "MISSED_EXIT" | "OUT" | "OUTGOING" | "PARKED" | "%future added value";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type cashierPaymentScreenQuery$variables = {
  taskFilter: findTaskFilterInput;
};
export type cashierPaymentScreenQuery$data = {
  readonly findTasks: ReadonlyArray<{
    readonly ETA: {
      readonly _id: any | null;
      readonly available: number | null;
      readonly busy: number | null;
      readonly delayAt: any | null;
      readonly originalPredictionTime: number | null;
      readonly pendingRecall: number | null;
      readonly startAt: any | null;
    } | null;
    readonly _id: any;
    readonly history: ReadonlyArray<{
      readonly _id: any | null;
      readonly action: EnumtaskHistoryAction;
      readonly event_at: any | null;
    } | null>;
    readonly isVehicleWhitelisted: boolean | null;
    readonly parking: {
      readonly _id: any;
      readonly parkingInvoice: {
        readonly charges: {
          readonly cashierDiscountAmount: number | null;
          readonly chargesBreakup: ReadonlyArray<{
            readonly duration: number | null;
            readonly payable: number | null;
          } | null> | null;
          readonly discountAmount: number | null;
          readonly totalPayable: number | null;
        } | null;
        readonly paymentDetails: {
          readonly amount: number | null;
          readonly focReason: EnumParkingParkingInvoicePaymentDetailsFocReason | null;
          readonly isPaid: boolean;
          readonly message: string | null;
          readonly mode: EnumParkingParkingInvoicePaymentDetailsMode | null;
        } | null;
        readonly timings: {
          readonly chargeTime: number | null;
          readonly entryTime: any;
          readonly exitTime: any | null;
        } | null;
      } | null;
      readonly parkingTag: string | null;
      readonly status: EnumParkingStatus;
    } | null;
    readonly vehicle: {
      readonly brand: {
        readonly brandLogo: string | null;
        readonly name: string | null;
      } | null;
      readonly color: string | null;
      readonly registration: {
        readonly plate: string;
      } | null;
    } | null;
  }>;
};
export type cashierPaymentScreenQuery = {
  response: cashierPaymentScreenQuery$data;
  variables: cashierPaymentScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskFilter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "taskFilter"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "findTasks",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "taskHistory",
        "kind": "LinkedField",
        "name": "history",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "event_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVehicleWhitelisted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskVehicle",
        "kind": "LinkedField",
        "name": "vehicle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleBrand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandLogo",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleRegistration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskETA",
        "kind": "LinkedField",
        "name": "ETA",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "delayAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "originalPredictionTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingRecall",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "available",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "busy",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Parking",
        "kind": "LinkedField",
        "name": "parking",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingTag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ParkingParkingInvoice",
            "kind": "LinkedField",
            "name": "parkingInvoice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingInvoiceTimings",
                "kind": "LinkedField",
                "name": "timings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "entryTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "exitTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "chargeTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingInvoicePaymentDetails",
                "kind": "LinkedField",
                "name": "paymentDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPaid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "focReason",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingInvoiceCharges",
                "kind": "LinkedField",
                "name": "charges",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                    "kind": "LinkedField",
                    "name": "chargesBreakup",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "payable",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discountAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cashierDiscountAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalPayable",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cashierPaymentScreenQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cashierPaymentScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "31b43e33e84f7fb031dae8b1a87e1127",
    "id": null,
    "metadata": {},
    "name": "cashierPaymentScreenQuery",
    "operationKind": "query",
    "text": "query cashierPaymentScreenQuery(\n  $taskFilter: findTaskFilterInput!\n) {\n  findTasks(filter: $taskFilter) {\n    _id\n    history {\n      _id\n      action\n      event_at\n    }\n    isVehicleWhitelisted\n    vehicle {\n      brand {\n        name\n        brandLogo\n      }\n      registration {\n        plate\n      }\n      color\n    }\n    ETA {\n      _id\n      startAt\n      delayAt\n      originalPredictionTime\n      pendingRecall\n      available\n      busy\n    }\n    parking {\n      _id\n      status\n      parkingTag\n      parkingInvoice {\n        timings {\n          entryTime\n          exitTime\n          chargeTime\n        }\n        paymentDetails {\n          isPaid\n          mode\n          focReason\n          message\n          amount\n        }\n        charges {\n          chargesBreakup {\n            duration\n            payable\n          }\n          discountAmount\n          cashierDiscountAmount\n          totalPayable\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2164a2c9377d35a7caba6b4d4e3a3d9";

export default node;
