import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import RecallScreen from '../screens/valetSupervisor/new/recall/RecallScreen';
import ParkingScreen from '../screens/valetSupervisor/new/parking/ParkingScreen';
import AddParkingDetailsScreen from '../screens/valetSupervisor/new/parking/AddParkingDetailsScreen';
import ParkingTaskEndScreen from '../screens/valetSupervisor/new/parking/ParkingTaskEndScreen';
import useUserStore, { ControllerRole } from '../stores/userStore';
import ValetSupervisorTabBarNew from './ValetSupervisorTabBarNew';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import AccountScreenWrapper from '../screens/new/account/AccountScreen';
import TeamScreenWrapper from '../screens/Team/TeamScreen';
import SearchParkingScreen from '../screens/valetSupervisor/new/searchParkingScreen/SearchParkingScreen';
import TaskJourneyScreenWrapper from '../screens/valetSupervisor/new/taskJourney/TaskJourney';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import ControllerHomeScreen from '../screens/controller/ControllerHomeScreen';
import ControllerOverviewScreen from '../screens/controller/ControllerOverviewScreen';
import ParkingOverview from '../screens/valetSupervisor/ParkingOverview/ParkingOverview';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import AssignTaskWrapper from '../screens/valetSupervisor/AssignTask';
import OTPInputScreen from '../screens/auth/OTPInputScreen';
import BarCodeScannerScreen from '../screens/valetSupervisor/barcodeScannerScreen';
import EnterTagIdScreen from '../screens/valetSupervisor/new/parking/EnterTagIdScreen';
import ScanTagIdScreen from '../screens/valetSupervisor/new/parking/ScanTagIdScreen';
import ScanQrCodeScreen from '../screens/valetSupervisor/new/recall/ScanQrCodeScreen';
import ControllerBonusTaskBreakdownScreen from '../screens/controller/ControllerBonusTasksBreakdownScreen';
import StartRideScreen from '../screens/valet/new/StartRideScreen';
import SelectCustomerScreen from '../screens/valet/new/SelectCustomerScreen';
import SearchUserScreen from '../screens/valet/new/SearchUserScreen';
import SearchValetScreen from '../screens/valetSupervisor/SearchValetScreen';
import AddCustomerScreenWrapper from '../screens/valet/new/AddCustomerScreen';
import ControllerScannerScreen from '../screens/valetSupervisor/new/parking/ControllerScannerScreen';
import ReviewDetailScreen from '../screens/valetSupervisor/new/parking/ReviewDetailScreen';
import AddVehicleScreen from '../screens/common/AddVehicleScreen';
import AddTeamMemberScreen from '../screens/common/AddTeamMemberScreen';
import AddParkingTagManuallyScreen from '../screens/common/AddParkingTagManuallyScreen';
import AnprDetectionScreen from '../screens/new/AnprDetectionScren';

const ValetSupervisorHomeTabBar = () => {
  const { Navigator, Screen } = createBottomTabNavigator();

  const { role, controllerRole } = useUserStore((state) => state);

  return (
    <Navigator
      // @ts-expect-error TS(2769): No overload matches this call.
      keyboardHidesTabBar="true"
      screenOptions={{ headerShown: false }}
      tabBar={(props) => {
        return <ValetSupervisorTabBarNew {...props} />;
      }}
    >
      {role === 'controller' && !controllerRole && (
        <Screen name="controllerHome" component={ControllerHomeScreen} />
      )}
      {role === 'controller' && controllerRole === ControllerRole.PARK && (
        <Screen name="park" component={ParkingScreen} />
      )}
      {role === 'controller' && controllerRole === ControllerRole.RECALL && (
        <Screen name="recall" component={RecallScreen} />
      )}
      {role === 'controller' && controllerRole && (
        <Screen name="overview" component={ControllerOverviewScreen} />
      )}
      {role === 'valetSupervisor' && (
        <>
          <Screen name="recall" component={RecallScreen} />
          <Screen name="park" component={ParkingScreen} />
        </>
      )}
      {role === 'valetSupervisor' && (
        <Screen name="overview" component={ParkingOverview} />
      )}
      <Screen name="valetAccount" component={AccountScreenWrapper} />
    </Navigator>
  );
};

const ValetSupervisorHomeTabs = () => {
  const Stack = createStackNavigator();

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="valetSupervisor"
        component={ValetSupervisorHomeTabBar}
      />
      <Stack.Screen
        name="addParkingDetails"
        component={AddParkingDetailsScreen}
      />
      <Stack.Screen
        name="parkingTaskEndScreen"
        component={ParkingTaskEndScreen}
      />
      <Stack.Screen name="team" component={TeamScreenWrapper} />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="editProfile" component={EditProfileScreen} />
      <Stack.Screen name="assignTask" component={AssignTaskWrapper} />
      <Stack.Screen name="taskJourney" component={TaskJourneyScreenWrapper} />
      <Stack.Screen name="phoneVerify" component={OTPInputScreen} />
      <Stack.Screen
        name="BarCodeScannerScreen"
        component={BarCodeScannerScreen}
      />
      <Stack.Screen name="EnterTagIdScreen" component={EnterTagIdScreen} />
      <Stack.Screen name="ScanTagIdScreen" component={ScanTagIdScreen} />
      <Stack.Screen
        name="SearchParkingScreen"
        component={SearchParkingScreen}
      />
      <Stack.Screen name="ScanQrCodeScreen" component={ScanQrCodeScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
      <Stack.Screen
        name="bonusTasks"
        component={ControllerBonusTaskBreakdownScreen}
      />
      <Stack.Screen name="StartRideScreen" component={StartRideScreen} />
      <Stack.Screen
        name="SelectCustomerScreen"
        component={SelectCustomerScreen}
      />
      <Stack.Screen name="SearchUserScreen" component={SearchUserScreen} />
      <Stack.Screen name="SearchValetScreen" component={SearchValetScreen} />
      <Stack.Screen
        name="AddNewCustomerScreen"
        component={AddCustomerScreenWrapper}
      />

      <Stack.Screen name="controllerScan" component={ControllerScannerScreen} />
      <Stack.Screen name="reviewDetails" component={ReviewDetailScreen} />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen name="AddVehicleScreen" component={AddVehicleScreen} />
      <Stack.Screen
        name="AddTeamMemberScreen"
        component={AddTeamMemberScreen}
      />
      <Stack.Screen
        name="addParkingTag"
        component={AddParkingTagManuallyScreen}
      />
      <Stack.Screen
        name="anprDetectionScreen"
        component={AnprDetectionScreen}
      />
    </Stack.Navigator>
  );
};

export default ValetSupervisorHomeTabs;
