/* eslint-disable import/prefer-default-export */
import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

export const findInvoice = async (data) => {
  const query = graphql`
    query trackMyVehicleApiFindInvoiceQuery($filter: findInvoiceInput!) {
      findInvoice(filter: $filter) {
        _id
      }
    }
  `;
  const variables = {
    filter: data,
  };
  return fetchQuery(environment, query, variables).toPromise();
};
