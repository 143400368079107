import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { FlatList } from 'react-native-gesture-handler';
import {
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import {
  trackMyVehicleScreenQuery,
  trackMyVehicleScreenParkingFragment,
} from './API/trackMyVehicleScreenQuery';
import { VehicleStatusTagEnum } from '../../../components/new/ui/VehicleStatusTags/helpers/helpers';
import NoVehicleFoundScreen from './NoVehicleFoundScreen';
import { sortTrackingCards } from './helper/helper';
import useUserStore from '../../../stores/userStore';
import VehicleHistoryCard from './VehicleHistoryCard';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import ShimmerPlaceholder from './shimmerPlaceholders/screens/TrackMyVehicleScreenPlaceholder';
import TrackingWidgetComponent from '../trackYourCar/TrackingWidgetComponent';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';
import PaginationLoader from '../../../components/new/custom/PaginationLoader';
import { navigateBack } from '../../../utilities/helper';

const HISTORY_PAGE_SIZE = 8;

const HistoryHeadingListComp = ({ item }) => {
  if (!item?.historyLength) {
    return <></>;
  }
  return (
    <View mt="4xl">
      <Text size="md" color="primary.400" pb="2xl">
        Parking History
      </Text>
    </View>
  );
};

const HistoryItemListComp = ({ itemData, retry }) => {
  const lastTask = itemData.tasks.slice(-1)[0];
  if (isValueNullOrEmpty(lastTask)) return <></>;
  const historyList = lastTask?.history;
  const lastHistory = historyList.slice(-1)[0];
  if (lastHistory?.action !== 'completed' && lastHistory?.action !== 'exited')
    return <></>;

  return (
    <View mb="3xl" key={itemData?._id}>
      <VehicleHistoryCard
        data={itemData}
        lastHistory={lastHistory}
        retry={retry}
      />
    </View>
  );
};

const TrackMyVehicleScreen = ({ retry, queryOptions, variables }) => {
  const navigation = useNavigation<any>();
  const listRef = useRef(null);
  const [taskNotAvailable, setTaskNotAvailable] = useState(false);
  const [pastTaskData, setPastTaskData] = useState([]);

  const { ...parkingHistoryRef } = useLazyLoadQuery(
    trackMyVehicleScreenQuery,
    variables,
    queryOptions,
  );

  /* ========== PARKING HISTORY VIA PAGINATION ========== */
  const {
    data: parkingHistoryNodes,
    loadNext: fetchMoreParkingHistory,
    hasNext: hasMoreParkings,
  } = usePaginationFragment(
    trackMyVehicleScreenParkingFragment,
    parkingHistoryRef,
  );

  const [listData, setListData] = useState([]);

  useEffect(() => {
    // extracting node out of edges
    const parkingHistory = unwrapPagedData(
      parkingHistoryNodes?.findParkingV2?.edges,
    );
    // sorting and adding tags (this `sortTrackingCards` logic should be done on backend)
    let history = sortTrackingCards(parkingHistory);

    history = history.filter((item) => {
      const { taskTagFE } = item;
      // return true:::  only apply filter when status is out
      return taskTagFE === VehicleStatusTagEnum.out;
    });

    if (history?.length) {
      history.sort(
        (a, b) =>
          moment(b?.taskHistoryCreateAtFE).unix() -
          moment(a?.taskHistoryCreateAtFE).unix(),
      );
    }

    setPastTaskData(history);

    const flashListData = [
      // -------
      { type: 'mainCard' },
      // -------
      // { type: 'notYourVehicleCard', ongoingCardLength: modifiedData?.length },
      // -------
      { type: 'freeParkingAvailableCard' },
      // -------
      { type: 'historyCardHeader', historyLength: history?.length },
      // -------
      ...history.map((item) => {
        if (item) {
          item.type = 'historyCard';
          return item;
        }
      }),
    ];
    setListData(flashListData);
  }, [parkingHistoryNodes]);

  if (pastTaskData?.length === 0 && taskNotAvailable) {
    return (
      <View mb="9xl+9xl" flex={1}>
        <NoVehicleFoundScreen retry={retry} navigation={navigation} />
      </View>
    );
  }

  return (
    <View flex={1}>
      <FlatList
        ref={listRef}
        data={listData}
        onEndReachedThreshold={0.8}
        onEndReached={() => {
          if (hasMoreParkings) {
            fetchMoreParkingHistory(HISTORY_PAGE_SIZE);
          }
        }}
        keyExtractor={(item, index) => `${item?.type + index}`}
        renderItem={({ item }) => {
          if (item?.type === 'mainCard') {
            return (
              <TrackingWidgetComponent
                parentFetchKey={queryOptions.fetchKey}
                taskNotAvailable={() => setTaskNotAvailable(true)}
                reloadComp={() => {
                  retry();
                }}
              />
            );
          }
          if (item?.type === 'historyCardHeader') {
            return (
              <View px="2xl">
                <HistoryHeadingListComp item={item} />
              </View>
            );
          }
          if (item?.type === 'historyCard') {
            return (
              <View px="2xl">
                <HistoryItemListComp itemData={item} retry={retry} />
              </View>
            );
          }

          return <></>;
        }}
        ListFooterComponent={() => {
          if (hasMoreParkings) return <PaginationLoader />;
          return null;
        }}
      />
    </View>
  );
};

const TrackMyVehicleScreenWithErrorBoundary = () => {
  const navigation = useNavigation<any>();
  const userId = useUserStore((state) => state.id);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const variables = {
    historyFilter: {
      ownerId: userId,
      parkingHistory: true,
    },
    count: HISTORY_PAGE_SIZE,
  };

  return (
    <Layout level={2}>
      <View>
        <TopNavigation
          title="My Car"
          appearance="none"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="sm"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigateBack(navigation, 'user')}
            />
          }
        />
      </View>

      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<ShimmerPlaceholder />}>
          <View flex={1}>
            <TrackMyVehicleScreen
              queryOptions={refreshedQueryOptions}
              retry={refresh}
              variables={variables}
            />
          </View>
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default TrackMyVehicleScreenWithErrorBoundary;
