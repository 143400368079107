/* eslint-disable */
import React from "react";
import { Card, View, Text } from "../../../../components/new";
import { resolveColor } from "../utils";
import {taskStatus} from "../constants"

type Props = {
  title: string;
  status: string;
  score: string;
  time: string;
};

const MetricsCard = ({ title, status = taskStatus.AVERAGE, score, time }: Props) => {
  const metricColor =resolveColor(status)

  return (
    <View flex={1} mx="lg">
      <Text mb="sm+md" size="md" weight="medium" textAlign="center">
        {title}
      </Text>
      <Card flexDirection="column">
        <View
          py="sm"
          mb="2xl"
          borderRadius="sm"
          borderWidth="1"
          borderColor="primary.50"
          alignItems="center"
          justifyContent="center"
        >
          <Text mb="xs" color={metricColor} size="md" weight="medium">
            {score}
          </Text>
          <Text color="primary.100" size="xs" weight="regular">
            Score
          </Text>
        </View>
        <View
          py="sm"
          flexDirection="column"
          borderRadius="sm"
          borderWidth="1"
          borderColor="primary.50"
          alignItems="center"
          justifyContent="center"
        >
          <Text mb="xs" color={metricColor} size="md" weight="medium">
            {time}
          </Text>
          <Text color="primary.100" size="xs" weight="regular">
            Time
          </Text>
        </View>
      </Card>
    </View>
  );
};

export default MetricsCard;
