/**
 * @generated SignedSource<<51417940eb2c895e1f757ae9cae2e662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumTicketModelTCwoUserCanBeClaimedByRefType = "CAMPUS_LOCATION" | "RESTAURANT" | "%future added value";
export type EnumTicketModelTCwoUserIssuedByRefType = "CAMPUS_LOCATION" | "EVENT" | "POPUP_STORE" | "RESTAURANT" | "%future added value";
export type EnumticketDisplayType = "COUPON" | "QR" | "%future added value";
export type EnumticketStatus = "ACTIVE" | "CANCELLED" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumticketTicketType = "EVENT_TICKET" | "PRODUCT_TICKET" | "REWARD_TICKET" | "%future added value";
export type FilterFindManyticketInput = {
  AND?: ReadonlyArray<FilterFindManyticketInput> | null;
  OR?: ReadonlyArray<FilterFindManyticketInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyticketOperatorsInput | null;
  additionalFields?: FilterFindManyTicketModelTCwoUserAdditionalFieldsInput | null;
  canBeClaimedBy?: ReadonlyArray<FilterFindManyTicketModelTCwoUserCanBeClaimedByInput | null> | null;
  couponId?: any | null;
  coverImg?: string | null;
  displayType?: EnumticketDisplayType | null;
  expires_on?: any | null;
  finePrint?: FilterFindManyTicketModelTCwoUserFinePrintInput | null;
  invoiceId?: any | null;
  isPrimary?: boolean | null;
  issuedBy?: FilterFindManyTicketModelTCwoUserIssuedByInput | null;
  popUpImg?: string | null;
  rewardId?: any | null;
  status?: EnumticketStatus | null;
  thumbnailImg?: string | null;
  ticketType?: EnumticketTicketType | null;
  title?: string | null;
  uId?: number | null;
  updated_at?: any | null;
};
export type FilterFindManyTicketModelTCwoUserIssuedByInput = {
  _id?: any | null;
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserIssuedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserCanBeClaimedByInput = {
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserCanBeClaimedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsInput = {
  _id?: any | null;
  event?: FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput = {
  _id?: any | null;
  endTime?: any | null;
  invoicePdfPath?: string | null;
  spaces?: number | null;
  startTime?: any | null;
};
export type FilterFindManyTicketModelTCwoUserFinePrintInput = {
  _id?: any | null;
  details?: ReadonlyArray<string | null> | null;
  summary?: string | null;
};
export type FilterFindManyticketOperatorsInput = {
  _id?: FilterFindManyticket_idOperatorsInput | null;
};
export type FilterFindManyticket_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type homeScreenFindTicketQueryRefechable$variables = {
  cursor?: string | null;
  filter: FilterFindManyticketInput;
  first?: number | null;
};
export type homeScreenFindTicketQueryRefechable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"homeScreenQueryFindTicketsFragment">;
};
export type homeScreenFindTicketQueryRefechable = {
  response: homeScreenFindTicketQueryRefechable$data;
  variables: homeScreenFindTicketQueryRefechable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "homeScreenFindTicketQueryRefechable",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "homeScreenQueryFindTicketsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "homeScreenFindTicketQueryRefechable",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ticketConnection",
        "kind": "LinkedField",
        "name": "findTickets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ticketEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ticket",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "homeScreenFindTicketsFragment_findTickets",
        "kind": "LinkedHandle",
        "name": "findTickets"
      }
    ]
  },
  "params": {
    "cacheID": "5e7212dc268171b090e68fa8310b5728",
    "id": null,
    "metadata": {},
    "name": "homeScreenFindTicketQueryRefechable",
    "operationKind": "query",
    "text": "query homeScreenFindTicketQueryRefechable(\n  $cursor: String\n  $filter: FilterFindManyticketInput!\n  $first: Int\n) {\n  ...homeScreenQueryFindTicketsFragment_ui5P\n}\n\nfragment homeScreenQueryFindTicketsFragment_ui5P on Query {\n  findTickets(filter: $filter, first: $first, after: $cursor) {\n    edges {\n      node {\n        _id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "be633b289198c318143159b54ba7f607";

export default node;
