/**
 * @generated SignedSource<<e4c12b2505e9c0f79abf2bc21345e52f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumUserRolePermission = "staff" | "%future added value";
export type findrewardFilterInput = {
  _id?: any | null;
  isExpired?: boolean | null;
  isUsed?: boolean | null;
  ownerId: any;
  parkingId?: any | null;
  rewardOrigin?: string | null;
};
export type userAccountScreenQuery$variables = {
  filter?: findrewardFilterInput | null;
  userId: any;
};
export type userAccountScreenQuery$data = {
  readonly findReward: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"userAccountScreen_findReward">;
  }>;
  readonly findUserById: {
    readonly _id: any;
    readonly name: {
      readonly first: string;
      readonly last: string | null;
    };
    readonly parkedCount: number | null;
    readonly profileImage: string | null;
    readonly role: {
      readonly name: EnumUserRoleName | null;
      readonly permission: EnumUserRolePermission | null;
    } | null;
    readonly userSince: string | null;
    readonly visitCount: number | null;
  } | null;
};
export type userAccountScreenQuery = {
  response: userAccountScreenQuery$data;
  variables: userAccountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_id",
      "variableName": "userId"
    }
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "findUserById",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserName",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permission",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parkedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileImage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "userAccountScreenQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Reward",
        "kind": "LinkedField",
        "name": "findReward",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "userAccountScreen_findReward"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "userAccountScreenQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Reward",
        "kind": "LinkedField",
        "name": "findReward",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isExpired",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expireAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardUniqueId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardOrigin",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69694f7ba05751d73e3b0a8e65089edf",
    "id": null,
    "metadata": {},
    "name": "userAccountScreenQuery",
    "operationKind": "query",
    "text": "query userAccountScreenQuery(\n  $userId: MongoID!\n  $filter: findrewardFilterInput\n) {\n  findUserById(_id: $userId) {\n    _id\n    name {\n      first\n      last\n    }\n    role {\n      name\n      permission\n    }\n    userSince\n    visitCount\n    parkedCount\n    profileImage\n  }\n  findReward(filter: $filter) {\n    ...userAccountScreen_findReward\n  }\n}\n\nfragment userAccountScreen_findReward on Reward {\n  _id\n  ownerId\n  parkingId\n  rewardType\n  isUsed\n  isExpired\n  expireAt\n  created_at\n  updated_at\n  rewardUniqueId\n  rewardOrigin\n}\n"
  }
};
})();

(node as any).hash = "ffddcb39be62ceb4df1c8e8b9853b36c";

export default node;
