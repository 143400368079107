import { graphql } from 'react-relay';

const valetDailyBonusTasksQuery = graphql`
  query valetDailyBonusTasksQuery($filter: findDailyValetRewardsFilter) {
    findDailyValetRewards(filter: $filter) {
      task {
        taskDate
        taskType
        taskStatus
        delayedBy
      }
      value
      valetCode
    }
  }
`;

export default valetDailyBonusTasksQuery;
