import { graphql, commitMutation } from 'react-relay';
import environment from './relayEnvironment';

const AddWorkLog = (data) =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation valetMyTasksApiAddMutation($record: valetWorkLogRecordInput!) {
        createValetWorkLog(record: $record) {
          record {
            workLogHistory {
              time
              type
            }
          }
        }
      }
    `;

    const variables = { record: data };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (res, err) => {
        if (err) return reject(err);
        return resolve(res);
      },
      onError: (err) => reject(err),
    });
  });

export default AddWorkLog;
