import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Loading from '../../components/loading.component';
import useAuthStore from '../../stores/authStore';

const LoginTrackComponent = () => {
  const authStore = useAuthStore((state) => state);

  const navigation = useNavigation();

  useEffect(() => {
    try {
      if (authStore?.isLoggedIn) {
        AsyncStorage.removeItem('loginMode').finally(() => {
          navigation.navigate('user', { screen: 'track' });
        });
      } else {
        AsyncStorage.setItem('loginMode', 'qrMode').finally(() => {
          Linking.openURL(
            'https://32nd.authlink.me/?redirectUri=https://club.32nd.com//login?qrMode=true&deeplink=true',
          );
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  return <Loading />;
};

export default LoginTrackComponent;
