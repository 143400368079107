/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { setBackgroundColorAsync } from 'expo-navigation-bar';
import { Platform } from 'react-native';
import useCommonStore, { AppTheme } from '../stores/commonStore';
import { getTheme } from '../themes/new/theme';

/**
 * This hook sets the System Nav bg color.
 * Use it when you need to match the nav bar with the current screen theme (light or dark).
 *
 * @param theme - The theme to which you want to set the System Nav bg (AppTheme.DARK or AppTheme.LIGHT)
 * @param activeColor - to override the default color to set.
 *
 * Ex:
 * If current screen is to be 'Dark' on Light App theme.
 *
 * `
 * useThemedSystemNav(AppTheme.DARK)
 * `
 *
 *  * If current screen is to be 'Light' on Dark App theme.
 *
 * `
 * useThemedSystemNav(AppTheme.Light)
 * `
 */

export const useThemedSystemNav = (theme: AppTheme, activeColor?: string) => {
  const activeTheme = useCommonStore((state) => state.theme);

  useEffect(() => {
    const isAndroid = Platform.OS === 'android';
    if (isAndroid) {
      setBackgroundColorAsync(
        activeColor ?? getTheme(theme).colors.background.primary.base,
      );
    }

    return () => {
      // revert to normal
      if (isAndroid) {
        setBackgroundColorAsync(
          getTheme(activeTheme).colors.background.primary.base,
        );
      }
    };
  }, [activeTheme, activeColor]);
};
