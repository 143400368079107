/* eslint-disable no-nested-ternary */
import React from 'react';
import { Image, Platform } from 'react-native';
import {
  DateFormatsEnum,
  dateDiffInDays,
  getFormatedDate,
} from '../../../utilities/helper';
import { Text, View, Button, Pressable } from '../index';

const RewardCard = (props) => {
  const { imageURL, reward, isUsed, isExpired, expireAt, onPress, updatedAt } =
    props ?? {};
  // const months = [
  //   'Jan',
  //   'Feb',
  //   'Mar',
  //   'Apr',
  //   'May',
  //   'Jun',
  //   'Jul',
  //   'Aug',
  //   'Sep',
  //   'Oct',
  //   'Nov',
  //   'Dec',
  // ];
  const date = new Date(expireAt);
  // const day = date.getDate();
  // const month =
  //   Platform.OS !== 'android'
  //     ? date.toLocaleString('en-US', { month: 'short' })
  //     : months[date.getMonth()];
  // const year = date.getFullYear();
  const today = new Date(Date.now());
  const difference = dateDiffInDays(today, date);
  const shadowProps =
    isExpired || isUsed
      ? { borderColor: 'primary.50' }
      : { borderColor: 'transparent', shadow: 'sm' };
  return (
    <View
      borderRadius="md"
      borderWidth="xs"
      bg="background.primary.elevation"
      {...shadowProps}
    >
      <Pressable
        onPress={() => {
          if (!isExpired && !isUsed) {
            onPress();
          }
        }}
      >
        <View p="2xl" justifyContent="center" alignItems="center">
          {isExpired || isUsed ? (
            <View
              position="absolute"
              zIndex={1}
              width="100%"
              height="100%"
              backgroundColor="primary.10"
              opacity={0.7}
            />
          ) : (
            <></>
          )}
          <View width="100%" pl="3xl" pr="sm+md">
            <Image
              source={{
                uri: imageURL,
              }}
              style={{
                width: '100%',
                // height: '100%',
                aspectRatio: 1.09,
                resizeMode: 'cover',
              }}
            />
          </View>
          <Text
            mt="2xl"
            size="md"
            color="primary.500"
            weight="medium"
            numberOfLines={1}
            ellipsizemode="tail"
          >
            {reward}
          </Text>
          {difference <= 5 && difference >= 0 && !isExpired && !isUsed ? (
            <Text
              mt="lg"
              // mb="xl"
              size="xs"
              color="warning.500"
              numberOfLines={1}
            >
              Expires
              {difference > 1
                ? ` in ${difference} days`
                : difference === 0
                ? ` today`
                : ` in ${difference} day`}
            </Text>
          ) : (
            <Text
              mt="lg"
              // mb="xl"
              size="xs"
              color="primary.100"
              numberOfLines={1}
            >
              {isExpired || isUsed
                ? isExpired && !isUsed
                  ? `Expired on ${getFormatedDate(
                      expireAt,
                      DateFormatsEnum.DateMonthYear,
                    )}`
                  : `Reedemed on ${getFormatedDate(
                      updatedAt,
                      DateFormatsEnum.DateMonthYear,
                    )}`
                : `Valid till ${getFormatedDate(
                    expireAt,
                    DateFormatsEnum.DateMonthYear,
                  )}`}
            </Text>
          )}
        </View>
        {isUsed || isExpired ? (
          <View
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            alignItems="center"
            justifyContent="center"
            // borderRadius="md"
            // borderWidth="xs"
            // borderColor="success.10"
            // shadow="md"
          >
            <View
              width="100%"
              alignItems="center"
              justifyContent="center"
              backgroundColor={isUsed ? 'success.50' : 'primary.50'}
              py="lg"
            >
              <Text color={isUsed ? 'success.500' : 'primary.100'} size="md">
                {isUsed ? 'Redeemed' : 'Expired'}
              </Text>
            </View>
          </View>
        ) : (
          // <Button
          //   size="lg"
          //   status="primary"
          //   state={isExpired ? 'disabled' : 'active'}
          //   appearance="filled"
          //   shadow="md"
          // >
          //   {isExpired ? 'Expired' : 'Reedem Now'}
          // </Button>
          <></>
        )}
      </Pressable>
    </View>
  );
};

export default RewardCard;
