/* eslint-disable no-unsafe-optional-chaining */
import React, { Suspense, useState } from 'react';
import { StatusBar } from 'react-native';
import { View, Layout } from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import Loading from '../../../components/loading.component';
import BonusTaskBreakdownScreen from '../../shared/BonusTaskBreakdownScreen';
import { useValetBonusTaskBreakDownLogic } from './useValetBonusTaskBreakDownLogic';
import { BonusOverviewBannerTitleModes } from '../../../components/new/custom/bonus/BonusOverviewBanner';

const ValetBonusTaskBreakDownScreen = ({ route }) => {
  const { date, mode } = route.params;
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2}>
      <StatusBar barStyle="light-content" />
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <BonusTaskBreakdownScreen
              date={date}
              mode={
                mode === 'today'
                  ? BonusOverviewBannerTitleModes.SUB_TITLE
                  : BonusOverviewBannerTitleModes.DATE_TITLE
              }
              queryOptions={refreshedQueryOptions}
              useBusinessLogic={useValetBonusTaskBreakDownLogic}
            />
          </Suspense>
        </NewErrorBoundary>
      </View>
    </Layout>
  );
};

export default ValetBonusTaskBreakDownScreen;
