import { variant as variantStyle, border, space, color } from 'styled-system';
import styled from 'styled-components/native';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import { VehicleStatusTagEnum } from './helpers/helpers';

const OuterStyledViewVariant = ({ theme }) => {
  return {
    [VehicleStatusTagEnum.parked]: {
      bg: theme.colors.success[500],
    },
    [VehicleStatusTagEnum.parking]: {
      bg: theme.colors.info[500],
    },
    [VehicleStatusTagEnum.recalled]: {
      bg: theme.colors.warning[500],
    },
    [VehicleStatusTagEnum.reParking]: {
      bg: theme.colors.info[500],
    },
    [VehicleStatusTagEnum.reParked]: {
      bg: theme.colors.success[500],
    },
    [VehicleStatusTagEnum.out]: {
      bg: theme.colors.primary[300],
    },
  };
};

const StyledTextVariant = ({ theme }) => {
  return {
    [VehicleStatusTagEnum.parked]: {
      color: theme.colors.primary[10],
    },
    [VehicleStatusTagEnum.parking]: {
      color: theme.colors.primary[10],
    },
    [VehicleStatusTagEnum.recalled]: {
      color: theme.colors.primary[10],
    },
    [VehicleStatusTagEnum.reParking]: {
      color: theme.colors.primary[10],
    },
    [VehicleStatusTagEnum.reParked]: {
      color: theme.colors.primary[10],
    },
    [VehicleStatusTagEnum.out]: {
      color: theme.colors.primary[10],
    },
  };
};

const OuterStyledView = styled.View`
  ${border}
  ${space}
  ${color}
  ${(props) =>
    variantStyle({ prop: 'status', variants: OuterStyledViewVariant(props) })}
`;

const StyledText = styled(Text)`
  ${(props) =>
    variantStyle({ prop: 'status', variants: StyledTextVariant(props) })}
`;

const VehicleStatusTag = ({ status = VehicleStatusTagEnum.recalled }) => {
  return (
    <View>
      <OuterStyledView status={status} borderRadius="xs" px="sm+md" py="sm">
        <StyledText size="xs" status={status}>
          {status}
        </StyledText>
      </OuterStyledView>
    </View>
  );
};

export default VehicleStatusTag;
