/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { Platform, Image, useWindowDimensions } from 'react-native';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import {
  Button,
  IconButton,
  Layout,
  TopNavigation,
  View,
} from '../../components/new';
import {
  dynamicHeightMaker,
  imageTransformation,
  navigateBack,
} from '../../utilities/helper';
import { useDownloadAppStore } from '../../stores/downloadApp/downloadAppStore';
import useUserStore from '../../stores/userStore';

const StaffScanReedemScreen = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const { dispatchDownloadApp } = useDownloadAppStore((state) => state);
  const { width, height } = useWindowDimensions();

  const onPressScanNow = () => {
    if (Platform.OS === 'web') {
      dispatchDownloadApp({
        visible: true,
        title: 'Recall & Win Free Parking',
        dsc: 'Get an assured free parking and lots of exciting offers on downloading the 32nd Club app',
        btnText: 'Download Now',
        imgSrc: {
          uri: imageTransformation(
            'https://ik.imagekit.io/32ndhub/assets/GiftBoxAnimation.gif',
            196,
          ),
        },
        onBtnPress: () => {},
      });
    } else {
      navigation.navigate('StaffBarcodeScannerScreen');
    }
  };

  return (
    <Layout level={2}>
      <TopNavigation
        title="Scan & Reedem Offer"
        appearance="ghost"
        level="1"
        textColor="primary.500"
        textSize="md"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="sm"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
            }}
          />
        }
      />
      <View px="2xl">
        <View my="4xl" alignSelf="center">
          <Image
            source={require('../../../assets/images/scanandredeem.png')}
            style={{
              width: width > 1200 ? 375 - 32 : width - 32,
              height: dynamicHeightMaker(
                width > 1200 ? 375 - 32 : width - 32,
                1.207,
              ),
            }}
            resizeMode="contain"
          />
        </View>
        <Button
          appearance="filled"
          size="lg"
          status="primary"
          state="active"
          onPress={onPressScanNow}
        >
          Scan Now
        </Button>
      </View>
      <DisconnectedDropover
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};
export default StaffScanReedemScreen;
