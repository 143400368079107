/* eslint-disable import/prefer-default-export */
export const addTaskFun = (taskDetail) => {
  // setDisabled(true);
  const data = {};
  (data as any).record = {};
  (data as any).record.taskType = 'recall';
  (data as any).record.parkingId = taskDetail.parkingId;
  (data as any).record.vehicle = {};
  if (taskDetail.vehicle.color) {
    (data as any).record.vehicle.color = taskDetail.vehicle.color;
  }
  if (taskDetail.vehicle.brand && taskDetail.vehicle.brand.name) {
    (data as any).record.vehicle.brand = {};
    (data as any).record.vehicle.brand.name = taskDetail.vehicle.brand.name;
    if (taskDetail.vehicle.brand.brandLogo) {
      (data as any).record.vehicle.brand.brandLogo =
        taskDetail.vehicle.brand.brandLogo;
    }
  }
  (data as any).record.vehicle.registration = {};
  (data as any).record.vehicle.registration.plate =
    taskDetail.vehicle.registration.plate;
  if (taskDetail.parkingActivity) {
    (data as any).record.parkingActivity = {};
    (data as any).record.parkingActivity._id = taskDetail.parkingActivity._id;
  }

  (data as any).record.campus = {};
  (data as any).record.campus = taskDetail.campus;

  (data as any).record.taskStartLocation = {};
  (data as any).record.taskEndLocation = {};
  (data as any).record.taskStartLocation.parkingSpaces = {};
  (data as any).record.taskEndLocation.parkingSpaces = {};
  if (
    taskDetail.history?.filter(
      (status) =>
        status.action === 'cancelled' && status.action === 'incomplete',
    ).length > 0
  ) {
    (data as any).record.taskStartLocation.locationId =
      taskDetail.taskStartLocation?.locationId;
    (data as any).record.taskEndLocation.locationId =
      taskDetail.taskEndLocation?.locationId;
  } else {
    (data as any).record.taskStartLocation.locationId =
      taskDetail?.taskEndLocation?.locationId;
    if (
      taskDetail?.taskEndLocation?.parkingSpaces &&
      taskDetail?.taskEndLocation?.parkingSpaces?.floor &&
      taskDetail?.taskEndLocation?.parkingSpaces?.floor?.floorId
    ) {
      (data as any).record.taskStartLocation.parkingSpaces.floor = {};
      (data as any).record.taskStartLocation.parkingSpaces.floor.floorId =
        taskDetail?.taskEndLocation?.parkingSpaces?.floor?.floorId;
      if (
        taskDetail?.taskEndLocation?.parkingSpaces?.floor?.bay &&
        taskDetail?.taskEndLocation?.parkingSpaces?.floor?.bay?.bayId
      ) {
        (data as any).record.taskStartLocation.parkingSpaces.floor.bay = {};
        (data as any).record.taskStartLocation.parkingSpaces.floor.bay.bayId =
          taskDetail?.taskEndLocation?.parkingSpaces?.floor?.bay?.bayId;
      }
    }
    (data as any).record.taskEndLocation.locationId =
      taskDetail.taskStartLocation?.locationId;
    if (
      taskDetail?.taskStartLocation?.parkingSpaces &&
      taskDetail?.taskStartLocation?.parkingSpaces?.floor &&
      taskDetail?.taskStartLocation?.parkingSpaces?.floor?.floorId
    ) {
      (data as any).record.taskEndLocation.parkingSpaces.floor = {};
      (data as any).record.taskEndLocation.parkingSpaces.floor.floorId =
        taskDetail?.taskStartLocation?.parkingSpaces?.floor?.floorId;
      if (
        taskDetail?.taskStartLocation?.parkingSpaces?.floor?.bay &&
        taskDetail?.taskStartLocation?.parkingSpaces?.floor?.bay?.bayId
      ) {
        (data as any).record.taskEndLocation.parkingSpaces.floor.bay = {};
        (data as any).record.taskEndLocation.parkingSpaces.floor.bay.bayId =
          taskDetail?.taskStartLocation?.parkingSpaces?.floor?.bay?.bayId;
      }
    }
  }
  (data as any).record.history = [];
  (data as any).record.history = [{ action: 'created' }];
  return data;
};
