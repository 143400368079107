import { findRestaurantsFilterInput } from './__generated__/restaurantListingQuery.graphql';

export enum RestaurantsFilterNames {
  ALL = 'ALL',
  CLUB_OFFERS = '32nd Plus Offers',
  FINE_DINING = 'Fine Dining',
  CAFES = 'Cafes',
  RATING_4_PLUS = 'Rating 4.0+',
  CUISINE = 'Cuisine',
  OCCASION = 'Occasion',
  SERVES_ALCOHOL = 'Serves alcohol',
  PURE_VEG = 'Pure veg',
  OPEN_NOW = 'Open now',
  OUTDOOR_SEATING = 'Outdoor seating',
}

export interface RestaurantFilter {
  name: RestaurantsFilterNames;
  active: boolean;
  filterKey?: string;
  itemsSelected?: number;
}

export interface BottomSheetFilterDataType {
  sheetTitle: string;
  referenceFilterName: string;
  dataArr: { name: string; value: string; isChecked: boolean }[];
}

export const FilterData: RestaurantFilter[] = [
  { name: RestaurantsFilterNames.ALL, active: true },
  {
    name: RestaurantsFilterNames.CLUB_OFFERS,
    active: false,
    filterKey: 'withOffer',
  },
  {
    name: RestaurantsFilterNames.FINE_DINING,
    active: false,
    filterKey: 'amenities',
  },
  {
    name: RestaurantsFilterNames.CAFES,
    active: false,
    filterKey: '',
  },
  {
    name: RestaurantsFilterNames.RATING_4_PLUS,
    active: false,
    filterKey: 'overAllRating',
  },
  {
    name: RestaurantsFilterNames.CUISINE,
    active: false,
    filterKey: 'cusines',
    itemsSelected: 0,
  },
  {
    name: RestaurantsFilterNames.OCCASION,
    active: false,
    filterKey: 'occasion',
    itemsSelected: 0,
  },
  {
    name: RestaurantsFilterNames.SERVES_ALCOHOL,
    active: false,
    filterKey: 'serveAlcohol',
  },
  {
    name: RestaurantsFilterNames.PURE_VEG,
    active: false,
    filterKey: 'pureVeg',
  },
  {
    name: RestaurantsFilterNames.OPEN_NOW,
    active: false,
    filterKey: 'openHours',
  },
  {
    name: RestaurantsFilterNames.OUTDOOR_SEATING,
    active: false,
    filterKey: 'outdoorSeating',
  },
];

export const BottomSheetFilterData: Record<string, BottomSheetFilterDataType> =
  {
    cusines: {
      sheetTitle: 'Select Cuisine',
      referenceFilterName: 'cusines',
      dataArr: [
        { isChecked: false, name: 'American', value: 'american' },
        { isChecked: false, name: 'Asian', value: 'asian' },
        { isChecked: false, name: 'Bakery', value: 'bakery' },
        { isChecked: false, name: 'Bar', value: 'bar' },
        { isChecked: false, name: 'Bengali', value: 'bengali' },
        { isChecked: false, name: 'Beverages', value: 'beverages' },
        { isChecked: false, name: 'Bubble Tea', value: 'bubble_tea' },
        { isChecked: false, name: 'Bihari', value: 'bihari' },
        { isChecked: false, name: 'Chinese', value: 'chinese' },
        { isChecked: false, name: 'Cafe', value: 'cafe' },
        { isChecked: false, name: 'Coffee', value: 'coffee' },
        { isChecked: false, name: 'Coastal Cuisine', value: 'coastal_cuisine' },
        { isChecked: false, name: 'Continental', value: 'continental' },
        { isChecked: false, name: 'Desserts', value: 'desserts' },
        { isChecked: false, name: 'European', value: 'european' },
        { isChecked: false, name: 'Fast Food', value: 'fastfood' },
        { isChecked: false, name: 'French', value: 'french' },
        { isChecked: false, name: 'Greek', value: 'greek' },
        { isChecked: false, name: 'Goan', value: 'goan' },
        { isChecked: false, name: 'Healthy', value: 'healthy' },
        { isChecked: false, name: 'Himalayan', value: 'himalayan' },
        { isChecked: false, name: 'Indian', value: 'indian' },
        { isChecked: false, name: 'Italian', value: 'italian' },
        { isChecked: false, name: 'Ice Cream', value: 'ice_cream' },
        { isChecked: false, name: 'Japanese', value: 'japanese' },
        { isChecked: false, name: 'Korean', value: 'korean' },
        { isChecked: false, name: 'Kashmiri', value: 'kashmiri' },
        { isChecked: false, name: 'Lebanese', value: 'lebanese' },
        { isChecked: false, name: 'Mexican', value: 'mexican' },
        { isChecked: false, name: 'Mughlai', value: 'mughlai' },
        { isChecked: false, name: 'Momos', value: 'momos' },
        { isChecked: false, name: 'Mediterranean', value: 'mediterranean' },
        { isChecked: false, name: 'Malaysian', value: 'malaysian' },
        { isChecked: false, name: 'North Indian', value: 'north_indian' },
        { isChecked: false, name: 'North Eastern', value: 'north_eastern' },
        { isChecked: false, name: 'Punjabi', value: 'punjabi' },
        { isChecked: false, name: 'Pizza', value: 'pizza' },
        { isChecked: false, name: 'Russian', value: 'russian' },
        { isChecked: false, name: 'Rajasthani', value: 'rajasthani' },
        { isChecked: false, name: 'Seafood', value: 'sea_food' },
        { isChecked: false, name: 'South Indian', value: 'south_indian' },
        { isChecked: false, name: 'Sushi', value: 'sushi' },
        { isChecked: false, name: 'Shake', value: 'shake' },
        { isChecked: false, name: 'Salad', value: 'salad' },
        { isChecked: false, name: 'Thai', value: 'thai' },
        { isChecked: false, name: 'Turkish', value: 'turkish' },
        { isChecked: false, name: 'Vegan', value: 'vegan' },
        { isChecked: false, name: 'Vietnamese', value: 'vietnamese' },
        { isChecked: false, name: 'Waffle', value: 'waffle' },
      ],
    },
    occasion: {
      sheetTitle: 'Select Occasion',
      referenceFilterName: 'occasion',
      dataArr: [{ isChecked: false, name: 'Date Night', value: 'date_night' }],
    },
  };

export const getQueryFiltersForData = (
  campusId: string,
  data: RestaurantFilter[],
  extraData: Record<string, string[]>,
): findRestaurantsFilterInput => {
  const filter: findRestaurantsFilterInput = { campus: { _id: campusId } };
  data.forEach((item) => {
    if (item.active) {
      switch (item.name) {
        case RestaurantsFilterNames.ALL:
          break;
        case RestaurantsFilterNames.CLUB_OFFERS:
          filter.withOffer = true;
          break;
        case RestaurantsFilterNames.FINE_DINING:
          if (filter?.amenities && filter?.amenities?.length > 0) {
            filter.amenities = [...filter.amenities, 'Fine_Dining'];
          } else {
            filter.amenities = ['Fine_Dining'];
          }
          break;
        case RestaurantsFilterNames.CAFES:
          if (filter?.cusines && filter?.cusines?.length > 0) {
            filter.cusines = [...filter.cusines, 'cafe'];
          } else {
            filter.cusines = ['cafe'];
          }
          break;
        case RestaurantsFilterNames.RATING_4_PLUS:
          filter.overAllRating = 4;
          break;
        case RestaurantsFilterNames.CUISINE:
          if (filter?.cusines && filter?.cusines?.length > 0) {
            filter.cusines = [...filter.cusines, ...extraData.cusines];
          } else if (extraData?.cusines && extraData?.cusines?.length > 0) {
            filter.cusines = [...extraData?.cusines];
          }
          break;
        case RestaurantsFilterNames.OCCASION:
          if (filter?.occasion && filter?.occasion?.length > 0) {
            // @ts-ignore
            filter.occasion = [...filter.occasion, ...extraData.occasion];
          } else if (extraData?.occasion && extraData?.occasion?.length > 0) {
            // @ts-ignore
            filter.occasion = [...extraData?.occasion];
          }
          break;
        case RestaurantsFilterNames.SERVES_ALCOHOL:
          filter.serveAlcohol = true;
          break;
        case RestaurantsFilterNames.PURE_VEG:
          filter.pureVeg = true;
          break;
        case RestaurantsFilterNames.OPEN_NOW:
          filter.openHours = Date.now().toString();
          break;
        case RestaurantsFilterNames.OUTDOOR_SEATING:
          filter.outdoorSeating = true;
          break;
        default:
          break;
      }
    }
  });
  return filter;
};

export const constructExtraDataFromSheetFilters = (
  data: BottomSheetFilterDataType,
): Record<string, string[]> => {
  const filtersArr = data.dataArr.reduce((accumulator, current) => {
    if (current.isChecked) {
      return [...accumulator, current.value];
    }
    return accumulator;
  }, []);

  const filterData = {};
  filterData[data.referenceFilterName] = filtersArr;

  return filterData;
};

export const getExtraFilterState = (
  dataArr: { name: string; value: string; isChecked: boolean }[],
): string => {
  const filtersArr = dataArr.reduce((accumulator, current) => {
    if (current.isChecked) {
      return [...accumulator, current.value];
    }
    return accumulator;
  }, []);
  const state = filtersArr.sort().join('-');
  return state;
};

export const getFiltersArrayInitialState = (
  activeFilter: RestaurantsFilterNames,
): RestaurantFilter[] => {
  if (!activeFilter) {
    return FilterData;
  }
  let newFilterArr: RestaurantFilter[] = [];
  const activeFilterArr: RestaurantFilter[] = [];
  FilterData.forEach((filter) => {
    if (filter.name === activeFilter) {
      activeFilterArr.push({
        ...filter,
        active: true,
      });
    } else {
      newFilterArr.push({
        ...filter,
        active: false,
      });
    }
  });
  if (activeFilterArr.length === 0) {
    newFilterArr[0].active = true;
  } else {
    const firstElement = newFilterArr.shift();
    newFilterArr = [...activeFilterArr, ...newFilterArr];
    newFilterArr.unshift(firstElement);
  }
  return newFilterArr;
};
