/**
 * @generated SignedSource<<e64c8ca4ac8d9edf042897c795e2369f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type transactionsFragmentInline$data = {
  readonly transactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly created_at: any | null;
        readonly priceDetails: {
          readonly csrAmount: number;
          readonly discountGiven: number;
          readonly grossAmount: number;
          readonly netAmount: number;
          readonly rewardsUsed: number;
          readonly tipAmount: number | null;
        };
        readonly status: EnuminvoiceStatus | null;
        readonly uId: string | null;
        readonly user: {
          readonly contact: {
            readonly phone: {
              readonly number: string;
              readonly prefix: string;
            };
          };
        } | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "transactionsFragmentInline";
};
export type transactionsFragmentInline$key = {
  readonly " $data"?: transactionsFragmentInline$data;
  readonly " $fragmentSpreads": FragmentRefs<"transactionsFragmentInline">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "transactionsFragmentInline"
};

(node as any).hash = "5b0a21623476a688b680e5f5a3db0490";

export default node;
