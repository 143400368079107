/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Card, Icon, Pressable, Text, View } from '../../../components/new';
import CachedImage from '../../../components/new/custom/CachedImage';

const CouponBox = ({
  appliedCoupon = null,
  removeCoupon,
  handleCoupons,
  couponResData = null,
}) => {
  const msg = couponResData?.message;
  const splittedMsg = msg?.length ? msg.split(': ') : [''];
  if (appliedCoupon) {
    return (
      <Card>
        <View flexDirection="row" alignItems="center">
          <View flexDirection="row" alignItems="flex-start" flex={0.75}>
            <Icon
              name={
                couponResData?.isApplied ? 'check-filled-400' : 'cancel_filled'
              }
              color={couponResData?.isApplied ? 'success.500' : 'error.500'}
              size="2xl"
            />
            <View ml="lg" flex={1}>
              <Text size="sm" color="primary.400" weight="medium" pb="sm">
                '{appliedCoupon?.couponCode}' Applied
              </Text>
              {couponResData?.isApplied ? (
                <Text size="xs" color="success.500" flex={1} numberOfLines={2}>
                  {appliedCoupon?.savedAmount > 0
                    ? `₹${appliedCoupon?.savedAmount} saved with this coupon`
                    : appliedCoupon?.cashbackAmount > 0
                    ? `₹${appliedCoupon?.cashbackAmount} cashback with this coupon`
                    : ''}
                </Text>
              ) : (
                <Text size="xs" color="error.500" flex={1} numberOfLines={3}>
                  {splittedMsg?.length > 1
                    ? splittedMsg[1]
                    : 'This coupon is not applicable'}
                </Text>
              )}
            </View>
          </View>
          <View alignItems="flex-end" flex={0.25}>
            {couponResData?.isApplied ? (
              <Pressable onPress={removeCoupon}>
                <Text size="sm" color="error.500">
                  Remove
                </Text>
              </Pressable>
            ) : (
              <Pressable
                onPress={() => {
                  handleCoupons();
                  removeCoupon();
                }}
              >
                <Text size="sm" color="info.500">
                  View All
                </Text>
              </Pressable>
            )}
          </View>
        </View>
      </Card>
    );
  }
  return (
    <Card>
      <Pressable onPress={handleCoupons}>
        <View flexDirection="row" alignItems="center">
          <View>
            <CachedImage
              source={require('../../../../assets/images/offers.png')}
              style={{ height: 24, width: 24 }}
            />
          </View>
          <View ml="lg">
            <Text size="sm" color="primary.400" weight="medium">
              View all coupons
            </Text>
          </View>
          <View alignItems="flex-end" flex={1}>
            <Icon
              name="keyboard-arrow-right-300"
              color="primary.400"
              size="2xl"
            />
          </View>
        </View>
      </Pressable>
    </Card>
  );
};

export default CouponBox;
