// import { graphql, useLazyLoadQuery } from 'react-relay';
import { useContext } from 'react';
import { Divider, View } from '../../../components/new';
import Pressable from '../../../components/new/basic/Pressable/Pressable';
import DinerItem from './DinerItem';
import NoMatchSearchSection from './NoMatchSearchSection';
import { RecentSearchContext } from './RecentSearchContextProvider';
import SearchListItem from './SearchListItem';

const dinerItems = [
  // {
  //   img: 'https://images.pexels.com/photos/1099680/pexels-photo-1099680.jpeg?cs=srgb&dl=pexels-jane-doan-1099680.jpg&fm=jpg',
  //   heading: 'Pizza',
  //   tags: ['Dish'],
  // },
  // {
  //   img: 'https://images.pexels.com/photos/1099680/pexels-photo-1099680.jpeg?cs=srgb&dl=pexels-jane-doan-1099680.jpg&fm=jpg',
  //   heading: 'Pizza',
  //   tags: ['Dish'],
  // },
];

const SearchResultSection = ({
  searchInputValue,
  navigation,
  searchResultList,
}) => {
  const { addData: addRecentSearchItem } = useContext(RecentSearchContext);

  // const SearchResultSectionFragment = graphql`
  //   fragment SearchResultSectionFragment on restaurants2 {
  //     _id
  //     displayName
  //     amenities
  //     overAllRating
  //     logo
  //     trending
  //     description {
  //       tags {
  //         name
  //       }
  //       cusines {
  //         name
  //         primary
  //       }
  //     }
  //     menu {
  //       restaurantId
  //     }
  //   }
  // `;

  return (
    <>
      <View>
        {!!searchResultList.length &&
          dinerItems.map((item) => {
            return <DinerItem data={item} />;
          })}
      </View>
      <View mb="4xl">
        {searchResultList.map((item) => (
          <Pressable>
            <View mb="2xl" key={item.heading}>
              <SearchListItem
                data={item}
                onPress={() => {
                  addRecentSearchItem({
                    displayName: item.displayName,
                    _id: item._id,
                  });
                  // This restaurandId is for staging
                  // TODO: remove this static navigation
                  // if (process.env.APP_ENV === 'staging') {
                  navigation.navigate('RestaurantScreen', {
                    restaurantId: item._id,
                  });
                  // }
                }}
              />
              <Divider mt="2xl" />
            </View>
          </Pressable>
        ))}
      </View>

      {searchResultList.length === 0 && (
        <>
          <View mb="xs+2xl" />
          <NoMatchSearchSection searchInputValue={searchInputValue} />
        </>
      )}
    </>
  );
};

export default SearchResultSection;
