import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

const taskMetricsQuery = graphql`
  query taskMetricsQuery($startTimeStamp: Int!, $endTimeStamp: Int!) {
    getTaskMetrics(
      startTimeStamp: $startTimeStamp
      endTimeStamp: $endTimeStamp
    ) {
      assignmentScore
      assignmentTime
      deliveryScore
      deliveryTime
      parkingScore
      parkingTime
      onboardingScore
      onboardingTime
    }
  }
`;

const fetchTaskMetricsQuery = (data) => {
  const variables = {
    startTimeStamp: data?.startTimeStamp,
    endTimeStamp: data?.endTimeStamp,
  };
  const request = fetchQuery(environment, taskMetricsQuery, variables);
  return request.toPromise();
};

export default fetchTaskMetricsQuery;
