import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Device from 'expo-device';
import { Image, Platform, useWindowDimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import AutoBottomSheet from './AutoBottomSheet';
import { useBottomNudges } from '../../../stores/nudges/bottomNudges/bottomNudgesStore';
import View from '../basic/View/View';
import Text from '../primitive/Typography/Text/Text';
import Button from '../primitive/Button/Button';
import { handleStorePress, mediaTypes } from '../../../utilities/helper';
import CarouselDots from './Carousel/CarouselDots';
import CustomLottieView from './CustomLottieView';

const S = ({ data }) => {
  const [activeDot, setActiveDot] = useState(0);
  const { width } = useWindowDimensions();

  return (
    <View style={{ alignItems: 'center' }}>
      <Carousel
        width={width - 64}
        height={320}
        scrollAnimationDuration={100}
        autoPlayInterval={2500}
        data={data}
        onSnapToItem={(index) => {
          setActiveDot(index);
        }}
        renderItem={({ index, item }) => {
          return (
            <View flex={1} alignItems="center" justifyContent="center">
              <Text size="2xl" weight="medium" textAlign="center" mb="7xl">
                {item?.title}
              </Text>
              {!!(item?.media?.mediaType === mediaTypes.LOTTIE) && (
                <CustomLottieView
                  style={{ width: 150, height: 150 }}
                  autoPlay
                  loop
                  source={item?.media?.src}
                  containerWidth={150}
                />
              )}
              {!!(item?.media?.mediaType === mediaTypes.IMAGE) && (
                <View width={150} height={150} alignItems="center">
                  <Image
                    style={{ width: 150, height: 150 }}
                    source={item?.media?.src}
                    resizeMode="center"
                  />
                </View>
              )}
            </View>
          );
        }}
        autoPlay
      />
      <View my="2xl">
        <CarouselDots numberOfDots={data.length} activeIndex={activeDot} />
      </View>
    </View>
  );
};

const BottomNudges = () => {
  const bottomSheetRef = useRef(null);
  const { currentCarousalData, closeBottomNudge } = useBottomNudges();

  const showModal = useCallback(() => {
    bottomSheetRef.current?.show();
  }, []);

  const handleCloseBottomSheet = useCallback(() => {
    closeBottomNudge();
    bottomSheetRef.current?.hide();
  }, []);

  useEffect(() => {
    if (currentCarousalData && Platform.OS === 'web') {
      showModal();
    }
  }, [currentCarousalData]);

  const handlePress = () => {
    handleStorePress(Device.osName);
  };

  const title =
    currentCarousalData?.carousalData?.[currentCarousalData?.activeIndex]
      ?.title;

  const lottieFile =
    currentCarousalData?.carousalData?.[currentCarousalData?.activeIndex]
      ?.lottie;

  const data = currentCarousalData?.carousalData;

  return (
    <AutoBottomSheet
      bottomSheetRef={bottomSheetRef}
      handleOpen={showModal}
      handleClose={handleCloseBottomSheet}
      insets={false}
    >
      <View mt="8xl" mx="2xl" mb="2xl">
        <S data={data} />
        <Button size="lg" mt="lg" onPress={handlePress}>
          {' '}
          Join The 32nd Club
        </Button>
      </View>
    </AutoBottomSheet>
  );
};

export default BottomNudges;
