import React from 'react';
import { useTheme } from 'styled-components/native';
import { imageTransformation } from '../../../utilities/helper';
import View from '../basic/View/View';
import GradientBackground from './GradientBackground';
import Text from '../primitive/Typography/Text/Text';
import { FontWeights } from '../../../themes/new/helper';
import CachedImage from './CachedImage';

interface OfferInfoTileProps {
  id: string;
  image: string;
  textPri: string;
  textSec: string;
}

const h = 150;
const w = 200;

const OfferInfoTile = ({ id, image, textPri, textSec }: OfferInfoTileProps) => {
  const theme = useTheme();
  const gradientColors = [
    { offset: '0', color: theme.colors.singletone.black, opacity: 0 },
    { offset: '1', color: theme.colors.singletone.black, opacity: 0.9 },
  ];
  return (
    <View height={h} width={w} borderRadius="lg" overflow="hidden">
      <View position="absolute" top={0} zIndex={20} overflow="hidden">
        <View
          bg="opacity.90"
          borderTopLeftRadius="lg"
          borderBottomRightRadius="lg"
          px="lg"
          py="sm"
        >
          <CachedImage
            source={require('../../../../assets/images/black_membership/black-member-logo-2.webp')}
            style={{ width: w / 2.5, height: 16, resizeMode: 'contain' }}
          />
        </View>
      </View>
      <View
        p="lg"
        zIndex={10}
        height="100%"
        justifyContent="flex-end"
        overflow="hidden"
      >
        <Text
          size="sm"
          weight={FontWeights.MEDIUM}
          numberOfLines={1}
          ellipsizemode="tail"
          color={theme.colors.singletone.white}
        >
          {textPri}
        </Text>
        <Text
          size="sm"
          color="primary.300"
          numberOfLines={1}
          ellipsizemode="tail"
        >
          {textSec}
        </Text>
      </View>
      <CachedImage
        style={{
          width: w,
          height: h,
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: theme.radii.lg,
          resizeMode: 'cover',
        }}
        source={{ uri: imageTransformation(image, w, `${w}-${h}`) }}
      />
      <View position="absolute" bottom={0} zIndex={2} height={130} width={w}>
        <GradientBackground
          backgroundOpacity={1}
          gradientColors={gradientColors}
          flex={1}
          id={id}
        />
      </View>
    </View>
  );
};

export default OfferInfoTile;
