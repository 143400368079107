import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import { Easing, Platform } from 'react-native';
import AuditorTabBar from './auditorBottomTabBar';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import AuditorHomeScreen from '../screens/auditor/AuditorHomeScreen';
import AuditorOverviewScreen from '../screens/auditor/AuditorOverview/AuditorOverviewScreen';
import AuditorAccountScreen from '../screens/auditor/AuditorAccountScreen';
import OTPInputScreen from '../screens/auth/OTPInputScreen';
import About from '../screens/new/About';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import AuditorSearchCodeScreen from '../screens/auditor/AuditorSearchCodeScreen';
import DayWiseCollectionScreenWrapper from '../screens/auditor/AuditorOverview/DayWiseCollectionScreen';
import CustomWebview from '../screens/new/CustomWebview';

const { Navigator, Screen } = createBottomTabNavigator();
const Stack = createStackNavigator();

const AuditorHomeTabBar = () => (
  <Navigator
    // @ts-expect-error TS(2769): No overload matches this call.
    keyboardHidesTabBar="true"
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <AuditorTabBar {...props} />}
  >
    <Screen name="home" component={AuditorHomeScreen} />
    <Screen name="overview" component={AuditorOverviewScreen} />
    <Screen name="valetAccount" component={AuditorAccountScreen} />
  </Navigator>
);

const AuditorTabs = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="auditor" component={AuditorHomeTabBar} />
      <Stack.Screen name="phoneVerify" component={OTPInputScreen} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen
        name="editProfile"
        options={{ gestureEnabled: false }}
        component={EditProfileScreen}
      />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen
        name="AuditorSearchCodeScreen"
        component={AuditorSearchCodeScreen}
      />
      <Stack.Screen
        name="AuditorDayWiseCollectionScreen"
        component={DayWiseCollectionScreenWrapper}
      />
      <Stack.Screen
        name="CustomWebview"
        component={CustomWebview}
        options={{
          animationTypeForReplace: 'push',
          animationEnabled: true,
          transitionSpec: {
            open: {
              animation: 'timing',
              config: {
                duration: 300,
                easing: Easing.inOut(Easing.ease),
              },
            },
            close: {
              animation: 'timing',
              config: {
                duration: 100,
                easing: Easing.inOut(Easing.ease),
              },
            },
          },
          cardStyleInterpolator:
            Platform.OS === 'android'
              ? CardStyleInterpolators.forRevealFromBottomAndroid
              : CardStyleInterpolators.forVerticalIOS,
        }}
      />
    </Stack.Navigator>
  );
};

export default AuditorTabs;
