import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useWindowDimensions, ImageBackground, AppState } from 'react-native';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { debounce } from 'lodash';
import NetInfo from '@react-native-community/netinfo';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as Device from 'expo-device';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { Button, View } from '../../../components/new';
import { getTheme } from '../../../themes/new/theme';
import TrackingCTA from './trackingComps/TrackingCTA';
import TrackingDetails from './trackingComps/TrackingDetails';
import { handlePhone } from '../../../utilities/handleLinking';
import {
  handleStorePress,
  taskHistoryActionTypes,
  pointsTypes,
  taskTypes,
  secondsDifferenceInDate,
  checkDateBreach,
  paymentOrderTypeEnum,
  toRupees,
} from '../../../utilities/helper';
import useCommonStore from '../../../stores/commonStore';
import useUserStore from '../../../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import taskUpdatedsubscriptionApi from '../../../relay/taskUpdatedsubscriptionApi';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import MoreDetails from './MoreDetails';
import ConfirmRecallBox from './ConfirmRecallBox';
import { recallQuery } from '../trackMyVehicle/API/recallQuery';
import usePaymentModalStore from '../../../stores/PaymentModalStore';
import useFeedbackModalStore from '../../../stores/FeedbackModalStore';
import {
  isValueNullOrEmpty,
  showNotificationPopupLogic,
} from '../../../utilities/Utility';
import { TrackingWidgetPlaceHolder } from '../BannerHomeScreen/shimmerPlaceholders';
import JoinClub from '../../../components/new/custom/JoinClub';
import {
  firebaseEventLogger,
  InteractionType,
} from '../../../utilities/firbaseAnalytics';
import ClaimWelcomeReward from './ClaimWelcomeReward';
import useNotificationModalStore from '../../../stores/notificationModalStore';
import checkClaimWelcomeRewardMutation from './API/checkClaimWelcomeReward';
import { makeTrackMyVehicleScreenQueryVars } from '../trackMyVehicle/API/trackMyVehicleScreenQuery';
import { sortTrackingCards } from '../trackMyVehicle/helper/helper';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import { useBottomNudges } from '../../../stores/nudges/bottomNudges/bottomNudgesStore';

const TrackingWidgetComponentQuery = graphql`
  query TrackingWidgetComponentQuery(
    $myCarFilter: findParkingsFilterInput!
    $limit: Int
  ) {
    findParkingCharges {
      basePrice
      baseDuration
      additionalPrice
      additionalDuration
      whichDay
    }
    findParking: findParkingV2(filter: $myCarFilter, first: $limit) {
      edges {
        node {
          _id
          vehicle {
            brand {
              name
              brandLogo
            }
            color
            registration {
              plate
            }
          }
          parkingRewardsGiven
          campus {
            _id
            contact {
              phone {
                prefix
                number
              }
            }
          }
          feedback {
            _id
            type
            feedbacks {
              topic
              summary
              ratingType
              rating
              tags
            }
          }
          parkingInvoice {
            paymentDetails {
              isPaid
              amount
            }
            charges {
              chargesBreakup {
                duration
                payable
              }
              discountAmount
              totalPayable
              valetTip
            }
            invoicePdfPath
          }
          pointsData {
            source {
              type
            }
            amount
          }
          parked_at
          tasks {
            _id
            taskType
            isVehicleWhitelisted
            user {
              name {
                first
                last
              }
              contact {
                phone {
                  prefix
                  number
                }
              }
            }
            taskStartLocation {
              locationId
              name
              parkingSpaces {
                floor {
                  floorId
                  floorName
                  bay {
                    bayId
                    bayName
                  }
                }
              }
            }
            taskEndLocation {
              locationId
              name
              parkingSpaces {
                floor {
                  floorId
                  floorName
                  bay {
                    bayId
                    bayName
                  }
                }
              }
            }
            ETA {
              delayAt
              createToArrivedTime
              createToAcceptedTime
              acceptedToArrivedTime
              acceptedToArrivedCustomerTime
            }
            valet {
              contact {
                phone {
                  prefix
                  number
                }
              }
              name {
                first
                last
              }
              profileImage
            }
            history {
              action
              event_at
            }
            vehicle {
              brand {
                name
                brandLogo
              }
              color
              registration {
                plate
              }
            }
            parkingTag
            parkingId
            ownerId
            createdByUser {
              name {
                first
                _id
              }
              role {
                name
              }
            }
            points {
              _id
              amount
              type
              source {
                type
              }
            }
          }
          invoicesData {
            priceDetails {
              discountGiven
              rewardsUsed
            }
          }
          status
          parkingTag
          rewardData {
            parkingId
            rewardOrigin
            uniqueHash
            rewardType
            rewardUniqueId
            isUsed
            isExpired
            expireAt
            _id
            updated_at
            created_at
            deleted
            deletedAt
          }
        }
      }
    }
  }
`;

const arr = [
  'assigned',
  'accepted',
  'started',
  'arrived',
  'completed',
  'abort',
  'cancelled',
  'incomplete',
  'exited',
];

let cancelSubscriptionUpdateData = null;

function isWithinVaultOperatingHours(): boolean {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const day = now.getDay(); // getDay returns 0 for Sunday, 1 for Monday, and so on

  if (day === 1 || day === 2) {
    // Monday or Tuesday
    return (hours > 11 || (hours === 11 && minutes >= 30)) && hours < 20;
  } // Other days
  return (
    (hours > 11 || (hours === 11 && minutes >= 30)) &&
    (hours < 21 || (hours === 21 && minutes < 30))
  );
}

const statusInitializer = (historyLast) => {
  if (historyLast?.action) {
    return historyLast.action;
  }
  return 'created';
};

const TrackingWidget = (props) => {
  const { queryOptions, refresh, reloadComp, taskNotAvailable } = props;

  const navigation = useNavigation<any>();
  const userId = useUserStore((state) => state.id);
  const { width } = useWindowDimensions();
  const bottomSheetMoreDetailsRef = useRef<ActionSheetRef>(null);

  const queryVars = makeTrackMyVehicleScreenQueryVars({
    userId,
    status: ['INCOMING', 'PARKED', 'OUTGOING', 'OUT'],
  });

  const variables = {
    myCarFilter: queryVars?.filter,
    limit: 1,
  };

  const { findParking: findParkingEdges, findParkingCharges } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLazyLoadQuery(TrackingWidgetComponentQuery, variables, {
      ...queryOptions,
    });

  const findParking = useMemo(
    () => unwrapPagedData(findParkingEdges?.edges),
    [findParkingEdges],
  );

  const componentState = usePaymentModalStore((state) => state);
  const FeedbackModalStore = useFeedbackModalStore((state) => state);
  const [taskData, setTaskData] = useState(null);
  const [isDelayed, setIsDelayed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recalledByKiosk, setRecalledByKiosk] = useState(false);
  const [historyLast, setHistoryLast] = useState(
    taskData?.history?.slice(-1)[0],
  );
  const [status, setStatus] = useState(() => statusInitializer(historyLast));
  const [isMoreDetails, setIsMoreDetails] = useState(true);
  const [coffeeRewardRecall, setCoffeeRewardRecall] = useState(false);
  const [welcomeReward, setWelcomeReward] = useState<any>(null);

  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { openBottomNudge } = useBottomNudges();

  const [flashTitle, setFlashTitle] = useState('');

  const campusID = useCommonStore((state) => state.campusId);
  const feedback = taskData?.parking?.feedback;
  const parkingInvoice = taskData?.parking?.parkingInvoice;
  const parked_at = taskData?.parking?.parked_at;
  const parkindId = findParking[0]?._id;

  const isPaid = parkingInvoice?.paymentDetails?.isPaid;

  const bottomSheetModalRef = useRef<ActionSheetRef>(null);

  const setNotificationUiText = useNotificationModalStore(
    (state) => state.setUiText,
  );

  const cancelUpdateTaskSubscription = (disposable) => {
    cancelSubscriptionUpdateData = disposable;
  };

  const subscriptionUpdateCallBack = (subsData) => {
    const data = subsData.taskUpdated;
    const status2 = data?.history?.slice(-1)?.[0]?.action;
    if (status2 === taskHistoryActionTypes.incomplete) {
      setTimeout(() => {
        setIsDelayed(false);
        refresh();
      }, 2000);
    }
    if (status2 !== taskHistoryActionTypes.incomplete) {
      setTaskData(data);
      setHistoryLast(data?.history?.slice(-1)[0]);
    }
    if (
      (status2 === taskHistoryActionTypes.completed &&
        (data?.taskType === taskTypes.park ||
          data?.taskType === taskTypes.repark)) ||
      (data?.taskType === taskTypes.recall &&
        status2 === taskHistoryActionTypes.EXITED)
    ) {
      cancelSubscriptionUpdateData.dispose();
      setIsDelayed(false);
      refresh();
      if (data?.taskType === taskTypes.recall) {
        firebaseEventLogger('recallWidget__taskCompletion_Data', {
          buttonName: 'taskCompletion',
          screenName: 'recallWidget',
          userType: 'user',
          interactionType: 'Data',
        });
      }
    }
    if (
      status2 === taskHistoryActionTypes.started ||
      status2 === taskHistoryActionTypes.created
    ) {
      if (data?.taskType === taskTypes.repark) {
        setIsDelayed(false);
        refresh();
      }
    }
    if (data) {
      const lastStatus = { action: '', event_at: '' };
      lastStatus.action = data?.history.slice(-1)[0].action;
      lastStatus.event_at = data?.history.slice(-1)[0].event_at;
      if (!arr.includes(lastStatus.action)) {
        return;
      }
      if (
        lastStatus.action === taskHistoryActionTypes.abort ||
        lastStatus.action === taskHistoryActionTypes.cancelled
      ) {
        refresh();
      }
      if (status2 !== taskHistoryActionTypes.incomplete) {
        setStatus(lastStatus.action);
      }
    }
  };

  useEffect(() => {
    const curStatus = taskData?.history?.slice(-1)?.[0]?.action;
    if (curStatus !== taskHistoryActionTypes.incomplete) {
      // setHistoryLast(widgetData?.history?.slice(-1)[0]);
      const diff = secondsDifferenceInDate(taskData?.ETA?.createToArrivedTime);
      if (taskData?.taskType === taskTypes.recall && diff <= 0) {
        setIsDelayed(true);
      } else {
        setIsDelayed(false);
      }
    }
  }, [taskData]);

  useEffect(() => {
    if (taskData?.taskType !== taskTypes.recall) {
      setIsDelayed(false);
    }
    const vehicleArrivedObj = taskData?.history?.find(
      (item) => item?.action === taskHistoryActionTypes.arrived,
    );
    if (
      !!vehicleArrivedObj &&
      taskData?.ETA?.createToArrivedTime < vehicleArrivedObj?.event_at
    ) {
      setIsDelayed(true);
    }
    setStatus(statusInitializer(historyLast));
  }, [historyLast?.action]);

  const hasFeedBack = (feedbackRes) => {
    if (
      feedbackRes &&
      Object.keys(feedbackRes)?.length &&
      feedbackRes?.feedbacks &&
      feedbackRes?.feedbacks?.length &&
      feedbackRes?.feedbacks[0]?.rating
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const finalTasks = [];
    if (findParking?.length) {
      let modifiedData = sortTrackingCards(findParking);
      if (modifiedData?.length) {
        modifiedData = [
          modifiedData.find((item) => {
            const { tasks } = item;
            if (tasks?.length === 0) {
              return false;
            }
            if (tasks?.length) {
              const lastTask = item?.tasks?.slice(-1)[0];
              const lastHistory = lastTask?.history?.slice(-1)[0];
              const isDateBreach = checkDateBreach(lastHistory?.event_at, 2);
              if (isDateBreach) {
                return false;
              }
              if (
                lastTask?.taskType === taskTypes.recall &&
                lastHistory?.action === taskHistoryActionTypes.EXITED &&
                hasFeedBack(item?.feedback)
              ) {
                return false;
              }
              const widgetData = { ...lastTask };
              widgetData.parking = {
                parked_at: item?.parked_at,
                parkingTag: item?.parkingTag,
                feedback: item?.feedback,
                parkingInvoice: item?.parkingInvoice,
                pointsData: item?.pointsData,
                invoicesData: item?.invoicesData,
              };
              widgetData.campus = item?.campus?.[0];
              finalTasks.push({
                type: 'parking',
                ...widgetData,
              });
            }

            return true;
          }),
        ];
        if (finalTasks?.[0]?.history?.length) {
          firebaseEventLogger('home__homeScreenRevamp_Landing', {
            buttonName: 'homeScreenRevamp',
            screenName: 'home',
            userType: 'user',
            interactionType: 'Landing',
            RecallWidgetFound: String(!!finalTasks?.[0]),
          });
          const curStatus = finalTasks?.[0]?.history?.slice(-1)?.[0]?.action;
          if (curStatus !== taskHistoryActionTypes.incomplete) {
            setTaskData(finalTasks?.[0] ?? {});
            setHistoryLast(finalTasks?.[0]?.history?.slice(-1)[0]);
          }
          if (
            finalTasks?.[0]?.taskType === taskTypes.recall &&
            curStatus !== taskHistoryActionTypes.EXITED
          ) {
            initSubscription();
          }
          if (finalTasks?.[0]?.taskType === taskTypes.recall) {
            componentState.setTaskTypeRecall(true);
          } else {
            componentState.setTaskTypeRecall(false);
          }
        } else {
          setTaskData(null);
          taskNotAvailable();
          componentState.setTaskTypeRecall(false);
        }
        // check recall source
        if (finalTasks?.[0]?.taskType !== taskTypes.park) {
          const task = finalTasks?.[0];
          setRecalledByKiosk(task?.createdByUser?.role?.name !== 'user');
        }
      }
    } else {
      setTaskData(null);
      taskNotAvailable();
      componentState.setTaskTypeRecall(false);
    }
    if (!isValueNullOrEmpty(findParkingCharges)) {
      componentState.setParkingCharges(JSON.stringify(findParkingCharges));
    }
  }, [findParking]);

  const initSubscription = () => {
    const data = {
      campusId: campusID,
      userId,
      history: arr,
      event_at: new Date().toISOString(),
      taskType: taskTypes.recall,
    };
    taskUpdatedsubscriptionApi(
      data,
      subscriptionUpdateCallBack,
      cancelUpdateTaskSubscription,
    );
  };

  useFocusEffect(
    useCallback(() => {
      if (taskData === null) return;
      const curStatus = taskData?.history?.slice(-1)?.[0]?.action;
      firebaseEventLogger('recallWidget__widgetFocused_Data', {
        currentStatus: curStatus,
        taskId: taskData?._id,
        taskType: taskData?.taskType,
      });
      if (
        curStatus === taskHistoryActionTypes?.EXITED ||
        ((taskData?.taskType === taskTypes.park ||
          taskData?.taskType === taskTypes.repark) &&
          curStatus === taskHistoryActionTypes?.completed)
      )
        return;
      const data = {
        campusId: campusID,
        userId,
        history: arr,
        event_at: new Date().toISOString(),
      };
      if (status !== taskHistoryActionTypes.completed) {
        data.taskType = taskData?.taskType;
      }
      let isFirstEvent = true;

      const debouncedSubscriptionFunction = debounce((state) => {
        if (state.isConnected && !isFirstEvent) {
          refresh();
        }
        isFirstEvent = false;
        if (cancelSubscriptionUpdateData) {
          cancelSubscriptionUpdateData.dispose();
        }
        taskUpdatedsubscriptionApi(
          data,
          subscriptionUpdateCallBack,
          cancelUpdateTaskSubscription,
        );
      });

      const unsubscribe = NetInfo.addEventListener((state) => {
        debouncedSubscriptionFunction(state);
      });
      return () => {
        if (cancelSubscriptionUpdateData) {
          cancelSubscriptionUpdateData.dispose();
        }
        unsubscribe();
      };
    }, [taskData]),
  );

  const checkStatus = () => {
    let res = status;
    if (
      taskData?.taskType === taskTypes.park ||
      taskData?.taskType === taskTypes.repark
    ) {
      if (status === taskHistoryActionTypes.completed) {
        res = 'parked';
      } else {
        res = 'parking';
      }
    }
    return res;
  };

  const valetNotAssigned =
    checkStatus() === taskHistoryActionTypes.created ||
    checkStatus() === taskHistoryActionTypes.parked;

  const delayPointsAvailable = taskData?.points?.find(
    (item) => item?.source?.type === pointsTypes.parkDelay,
  );

  // useEffect(() => {
  //   if (delayPointsAvailable?.amount && componentState.claimDelayCompensation) {
  //     setTimeout(() => {
  //       setFlashTitle(`You Earned ${delayPointsAvailable?.amount} Points`);
  //       componentState.setClaimDelayCompensation(false);
  //       reloadComp();
  //     }, 200);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [delayPointsAvailable?.amount, componentState.claimDelayCompensation]);

  const handleCall = () => {
    firebaseEventLogger('recallWidget__callPressed_Data', {
      buttonName: 'callPressed',
      screenName: 'recallWidget',
      interactionType: InteractionType.DATA,
      valetAssigned: !valetNotAssigned,
      prefix: taskData?.campus?.contact?.phone?.prefix,
      number: taskData?.campus?.contact?.phone?.number,
    });
    if (valetNotAssigned) {
      const { prefix, number } = taskData?.campus?.contact?.phone ?? {};
      handlePhone(prefix + number);
    } else {
      const { prefix, number } = taskData?.valet?.contact?.phone ?? {};
      handlePhone(prefix + number);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      if (
        FeedbackModalStore?.onSuccess &&
        isValueNullOrEmpty(FeedbackModalStore?.parkingId) &&
        (checkStatus() === taskHistoryActionTypes.arrived ||
          checkStatus() === taskHistoryActionTypes.completed ||
          checkStatus() === taskHistoryActionTypes.EXITED)
      ) {
        reloadComp();
        FeedbackModalStore.setOnSuccess(false);
      }
    }, [FeedbackModalStore]),
  );

  const pointsData = taskData?.parking?.pointsData ?? [];
  const campusVisitArr = pointsData?.filter(
    (item) => item?.source?.type === pointsTypes.campusVisit,
  );
  let campusReward = 0; // campus visit reward
  // eslint-disable-next-line array-callback-return
  campusVisitArr?.map((item) => {
    campusReward += item?.amount;
  });
  let delayReward = 0; // delay reward
  // eslint-disable-next-line array-callback-return
  taskData?.points?.map((item) => {
    delayReward += item?.amount;
  });

  let rewardsUsed = 0; // reward used while making payment
  let discountAmount = 0; // discount amount available while making payment

  const invoicesData = taskData?.parking?.invoicesData;

  if (invoicesData?.length) {
    invoicesData.forEach((item) => {
      rewardsUsed += item?.priceDetails?.rewardsUsed;
      discountAmount += item?.priceDetails?.discountGiven;
    });
  }

  const totalSaved = discountAmount + rewardsUsed;
  const totalPointsEarned = campusReward + delayReward;
  const payButtonDiscountAmount =
    findParking[0]?.parkingInvoice?.charges?.discountAmount ?? 0;

  useEffect(() => {
    // flash msg after payment success
    if (
      (componentState.paymentSuccess &&
        !recalledByKiosk &&
        invoicesData?.length) ||
      (componentState.paymentSuccess && recalledByKiosk)
    ) {
      setTimeout(() => {
        setFlashTitle(
          totalSaved > 0 && totalPointsEarned > 0
            ? `You Saved ₹${totalSaved} & Earned ${totalPointsEarned} Points`
            : totalSaved > 0
            ? `You Saved ₹${totalSaved}`
            : totalPointsEarned > 0
            ? `You Earned ${totalPointsEarned} Points`
            : `Better luck next time!`,
        );
      }, 200);
      setTimeout(() => {
        componentState.setToDefault();
      }, 5000);
    }
  }, [componentState.paymentSuccess, invoicesData?.length]);

  const redirectOnPaybillAfterRecall = useCallback(
    (recallTask) => {
      if (recallTask?.isVehicleWhitelisted) {
        return dispatchSnackbar({
          msg: 'You are not allowed to pay since this vehicle is whitelisted!',
          status: SnackbarStatus.info,
          version: SnackbarVersion.v1,
        });
      }
      const additionalHourObj = {
        totalSeconds: 0,
        totalAmount: 0,
      };
      // eslint-disable-next-line array-callback-return
      recallTask?.parking?.parkingInvoice?.charges?.chargesBreakup?.map(
        (item, index) => {
          if (index > 0) {
            additionalHourObj.totalSeconds += item?.duration;
            additionalHourObj.totalAmount += item?.payable;
          }
        },
      );
      const parkingFee =
        recallTask?.parking?.parkingInvoice?.charges?.chargesBreakup?.[0]
          ?.payable;
      const additionalHourFee = additionalHourObj?.totalAmount ?? 0;
      const totalAmount = parkingFee + Number(additionalHourFee);

      navigation.navigate('RecallSuccessScreen', {
        orderId: parkindId,
        orderType: paymentOrderTypeEnum.PARKING,
        amount: totalAmount,
      });
    },
    [parkindId],
  );
  // eslint-disable-next-line consistent-return
  const onPaymentButtonPress = useCallback(() => {
    if (recalledByKiosk) {
      firebaseEventLogger('recallWidget__claimMembershipDis_Tap', {
        buttonName: 'claimMembershipDis',
        screenName: 'recallWidget',
        userType: 'user',
        interactionType: 'Data',
      });
    } else {
      firebaseEventLogger('recallWidget__payNow_Tap', {
        buttonName: 'payNow',
        screenName: 'recallWidget',
        userType: 'user',
        interactionType: 'Tap',
      });
    }
    if (taskData?.isVehicleWhitelisted) {
      return dispatchSnackbar({
        msg: 'You are not allowed to pay since this vehicle is whitelisted!',
        status: SnackbarStatus.info,
        version: SnackbarVersion.v1,
      });
    }
    componentState.setRecallTime('');
    componentState.setEntryTime('');
    const additionalHourObj = {
      totalSeconds: 0,
      totalAmount: 0,
    };
    // eslint-disable-next-line array-callback-return
    parkingInvoice?.charges?.chargesBreakup?.map((item, index) => {
      if (index > 0) {
        additionalHourObj.totalSeconds += item?.duration;
        additionalHourObj.totalAmount += item?.payable;
      }
    });
    componentState.setAdditionalData(JSON.stringify(additionalHourObj));
    componentState.setParkingID(taskData.parkingId);
    componentState.setChargesData(JSON.stringify(parkingInvoice?.charges));
    componentState.setEntryTime(parked_at);
    componentState.setRecallTime(createdTimeObj?.event_at);

    const parkingFee = parkingInvoice?.charges?.chargesBreakup?.[0]?.payable;
    const additionalHourFee = additionalHourObj?.totalAmount ?? 0;
    const totalAmount = parkingFee + Number(additionalHourFee);

    navigation.navigate('paymentAmountScreen', {
      orderId: parkindId,
      orderType: paymentOrderTypeEnum.PARKING,
      amount: totalAmount,
    });
  }, [taskData]);

  const checkDelayAndPaid = () => {
    // if (isDelayed && !delayPointsAvailable?.amount)
    //   return 'Claim Delay Compensation';
    if (isPaid) return 'View Your Savings';
    if (recalledByKiosk) return 'Claim Membership Discount';
    return payButtonDiscountAmount
      ? `Pay Now and get ${toRupees(payButtonDiscountAmount)} off`
      : 'Pay Now';
  };

  const renderBtnTitle = () => {
    switch (checkStatus()) {
      case taskHistoryActionTypes.parked:
      case taskHistoryActionTypes.parking:
        return 'Recall Now';
      case taskHistoryActionTypes.created:
      case taskHistoryActionTypes.started:
      case taskHistoryActionTypes.assigned:
      case taskHistoryActionTypes.accepted:
        return checkDelayAndPaid();
      case taskHistoryActionTypes.arrived:
      case taskHistoryActionTypes.completed:
        if (isDelayed && !delayPointsAvailable?.amount)
          return checkDelayAndPaid();
        if (isPaid) return 'View Your Savings';
        if (recalledByKiosk) return 'Claim Membership Discount';
        return payButtonDiscountAmount
          ? `Pay Now and get ${toRupees(payButtonDiscountAmount)} off`
          : 'Pay Now';
      default:
        return '';
    }
  };

  const onError = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v1,
    });
  };

  // const onSuccess = (response) => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  //   if (response?.logEvent?.success) {
  //     componentState.setClaimDelayCompensation(true);
  //     refresh();
  //   } else {
  //     onError();
  //   }
  // };

  // const claimDelayCompensation = () => {
  //   firebaseEventLogger('recallWidget__claimDelay_Tap', {
  //     buttonName: 'claimDelay',
  //     screenName: 'recallWidget',
  //     userType: 'user',
  //     interactionType: 'Tap',
  //   });
  //   const delayParams = {
  //     eventType: 'TASK_DELAYED_CLAIM',
  //     refId: taskData?._id,
  //     refType: 'TASK',
  //   };
  //   setLoading(true);
  //   claimDelayMutation(delayParams, onSuccess, onError);
  // };

  const openBottomSheet = () => {
    bottomSheetModalRef?.current?.show();
  };

  const onPressButton = async () => {
    switch (checkStatus()) {
      case taskHistoryActionTypes.parking:
        return dispatchSnackbar({
          msg: `You can recall your car once it’s parked!`,
          status: SnackbarStatus.info,
          version: SnackbarVersion.v2,
        });
      case taskHistoryActionTypes.parked:
        firebaseEventLogger('recallWidget__recallNow_Tap', {
          buttonName: 'recallNow',
          screenName: 'recallWidget',
          userType: 'user',
          interactionType: 'Tap',
        });
        // if (Platform.OS === 'web') {
        //   return openBottomSheet();
        // }
        try {
          if (isWithinVaultOperatingHours()) {
            const data = await checkClaimWelcomeRewardMutation({
              eventType: 'SIGNUP_REWARD_CHECK',
            });
            if (!data.logEvent.success) {
              throw data.logEvent.error;
            }
            if (!data.logEvent.data.rewardTicket) {
              throw 'No reward Ticket';
            }
            setCoffeeRewardRecall(true);
            setWelcomeReward(data.logEvent.data.rewardTicket);
          } else {
            setCoffeeRewardRecall(false);
            setWelcomeReward(null);
          }
          return (
            setIsMoreDetails(false), bottomSheetMoreDetailsRef?.current?.show()
          );
        } catch (e) {
          setCoffeeRewardRecall(false);
          setWelcomeReward(null);
          return (
            setIsMoreDetails(false), bottomSheetMoreDetailsRef?.current?.show()
          );
        }
      case taskHistoryActionTypes.created:
      case taskHistoryActionTypes.started:
      case taskHistoryActionTypes.assigned:
      case taskHistoryActionTypes.accepted:
        // if (isDelayed && !delayPointsAvailable?.amount) {
        //   return claimDelayCompensation();
        // }
        if (isPaid) {
          return (
            navigation.navigate('pointsScreen', { showBackIcon: true }),
            firebaseEventLogger('recallWidget__viewSavings_Tap', {
              buttonName: 'viewSavings',
              screenName: 'recallWidget',
              userType: 'user',
              interactionType: 'Tap',
            })
          );
        }
        //  if (Platform.OS === 'web') {
        //     return openBottomNudge({
        //       bottomNudgeName: bottomNudgeType.restaurantPay,
        //     });
        //   }
        return onPaymentButtonPress();
      case taskHistoryActionTypes.arrived:
      case taskHistoryActionTypes.completed:
        // if (isDelayed && !delayPointsAvailable?.amount) {
        //   return claimDelayCompensation();
        // }
        if (isPaid) {
          return (
            navigation.navigate('pointsScreen', { showBackIcon: true }),
            firebaseEventLogger('recallWidget__viewSavings_Tap', {
              buttonName: 'viewSavings',
              screenName: 'recallWidget',
              userType: 'user',
              interactionType: 'Tap',
            })
          );
        }
        // if (Platform.OS === 'web') {
        //           return openBottomNudge({
        //             bottomNudgeName: bottomNudgeType.restaurantPay,
        //           });
        //         }
        return onPaymentButtonPress();
      default:
        return null;
    }
  };

  const checkClaimParking = () => {
    if (taskData?.taskType === taskTypes.recall) {
      const vehicleArrived = taskData?.history?.find(
        (item) => item?.action === taskHistoryActionTypes.arrived,
      );
      const createToArrivedTime = taskData?.ETA?.createToArrivedTime;
      if (isDelayed !== vehicleArrived?.event_at > createToArrivedTime) {
        setIsDelayed(vehicleArrived?.event_at > createToArrivedTime);
      }
      if (!vehicleArrived) {
        const diff = secondsDifferenceInDate(
          taskData?.ETA?.createToArrivedTime,
        );
        if (diff <= 0) {
          setIsDelayed(true);
        }
      }
    }
  };

  const handleOnDismiss = useCallback(() => {
    setIsMoreDetails(true);
    if (!loading) {
      bottomSheetMoreDetailsRef.current?.hide();
    }
  }, [loading]);

  // const isLight = themeContext.currentThemeMode === themeModes.light;

  const createdTimeObj = taskData?.history?.find(
    (item) => item?.action === taskHistoryActionTypes.created,
  );

  const taskCreatedTimeStamp = createdTimeObj?.event_at
    ? new Date(createdTimeObj?.event_at).getTime()
    : 0;

  const arrivedTimeObj = taskData?.history?.find(
    (item) => item?.action === taskHistoryActionTypes.arrived,
  );

  const recallHandle = () => {
    setLoading(true);
    firebaseEventLogger('recallWidget__confirmRecall_Tap', {
      buttonName: 'confirmRecall',
      screenName: 'recallWidget',
      userType: 'user',
      interactionType: 'Tap',
    });
    const recallParams = {
      taskId: taskData?._id,
      taskEndLocationId: taskData?.taskEndLocation?.locationId,
    };
    recallQuery(recallParams)
      .then((res) => {
        setLoading(false);
        handleOnDismiss();
        setNotificationUiText(
          'Get notified when your car arrives?',
          'Stay updated on your car status! Enable alerts to stay informed',
          'No',
          'Yes, Allow',
        );
        showNotificationPopupLogic();
        setIsDelayed(false);
        // if (Platform.OS !== 'web') {
        if (res?.addRecallTask?.isVehicleWhitelisted) {
          navigation.navigate('RecallSuccessScreen', {
            orderId: parkindId,
            orderType: paymentOrderTypeEnum.PARKING,
            isVehicleWhitelisted: res?.addRecallTask?.isVehicleWhitelisted,
          });
        } else {
          redirectOnPaybillAfterRecall(res?.addRecallTask);
        }
        // } else {
        //   refresh();
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log('==', err);
        refresh();
        dispatchSnackbar({
          msg: 'something went wrong! refreshing the data!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v1,
        });
      });
  };

  const showButton = () => {
    let res = false;
    if (
      checkStatus() === taskHistoryActionTypes.parking ||
      checkStatus() === taskHistoryActionTypes.parked
    ) {
      return true;
    }
    if (
      (checkStatus() === taskHistoryActionTypes.created ||
        checkStatus() === taskHistoryActionTypes.accepted ||
        checkStatus() === taskHistoryActionTypes.started ||
        checkStatus() === taskHistoryActionTypes.arrived ||
        checkStatus() === taskHistoryActionTypes.completed) &&
      !taskData?.isVehicleWhitelisted
    ) {
      res = true;
    } else if (
      (checkStatus() === taskHistoryActionTypes.arrived ||
        checkStatus() === taskHistoryActionTypes.completed) &&
      taskData?.isVehicleWhitelisted
    ) {
      res = true;
    } else if (checkStatus() === taskHistoryActionTypes.EXITED) {
      res = true;
    }
    return res;
  };

  const timeDiff = secondsDifferenceInDate(arrivedTimeObj?.event_at) * -1;

  const imageSource =
    taskData?.taskType === taskTypes.recall &&
    checkStatus() === taskHistoryActionTypes.arrived &&
    timeDiff > 60
      ? require('../../../../assets/images/delay.webp')
      : require('../../../../assets/images/ontime.webp');

  const checkBgColor = () => {
    refresh();
  };

  let rewardsText = '';
  if (isPaid) {
    if (totalSaved > 0 && totalPointsEarned > 0) {
      rewardsText = `You Saved ₹${totalSaved} & Earned ${totalPointsEarned} Points`;
    } else if (totalPointsEarned > 0) {
      rewardsText = `You Earned ${totalPointsEarned} Points`;
    } else if (totalSaved > 0) {
      rewardsText = `You Saved ₹${totalSaved}`;
    }
  } else if (totalPointsEarned > 0) {
    rewardsText = `You Earned ${totalPointsEarned} Points`;
  }

  if (taskData === null) return null;

  return (
    <View mt="3xl">
      <DarkThemeWrapper>
        <View alignSelf="center">
          <ImageBackground
            source={imageSource}
            style={{
              width: width - 32,
              overflow: 'hidden',
              borderRadius: getTheme().radii.xl,
            }}
            resizeMode="cover"
          >
            <TrackingDetails
              isPaid={isPaid}
              taskData={taskData}
              isDelayed={isDelayed}
              taskCreatedTimeStamp={taskCreatedTimeStamp}
              arrivedTime={arrivedTimeObj?.event_at}
              status={checkStatus()}
              checkClaimParking={checkClaimParking}
              checkBgColor={checkBgColor}
              onSelectInfo={() => {
                bottomSheetMoreDetailsRef?.current?.show();
              }}
              handleCall={handleCall}
              rewardsText={rewardsText}
            />
            {showButton() ? (
              <TrackingCTA
                btnText={renderBtnTitle()}
                onPressButton={onPressButton}
                status={checkStatus()}
                parkingId={taskData.parkingId}
                delayedAndNotRedeemed={
                  isDelayed && !delayPointsAvailable?.amount
                }
                flashEffect={flashTitle}
                feedback={feedback}
                loading={loading}
                isPaid={isPaid}
                isVehicleWhitelisted={taskData?.isVehicleWhitelisted}
                refreshFlash={() => {
                  setFlashTitle('');
                }}
              />
            ) : null}
          </ImageBackground>
        </View>
      </DarkThemeWrapper>
      <BottomSheetV2 ref={bottomSheetMoreDetailsRef} onClose={handleOnDismiss}>
        {isMoreDetails ? (
          <MoreDetails
            tag={taskData?.parking?.parkingTag}
            taskCreatedTimeStamp={taskCreatedTimeStamp}
            parked_at={parked_at}
            historyLast={
              historyLast?.action === taskHistoryActionTypes.completed
                ? historyLast
                : null
            }
            isParked={
              taskData?.taskType === taskTypes.park ||
              taskData?.taskType === taskTypes.repark
            }
            parkingCharges={
              !isValueNullOrEmpty(componentState.parkingCharges)
                ? JSON.parse(componentState.parkingCharges)
                : null
            }
          />
        ) : coffeeRewardRecall ? (
          <ClaimWelcomeReward
            recalling={loading}
            onClaim={() => {
              handleOnDismiss();
              navigation.navigate('FreeParkingScreen', {
                rewardId: welcomeReward?.uId,
              });
            }}
            onRecall={recallHandle}
            data={{
              image: require('../../../../assets/images/coffee_recall.webp'),
              recallButtonText: 'Skip Coffee & Recall My Car',
              claimRewardText: 'Click To Claim Free Coffee',
              titleText:
                'Before you leave, grab a FREE cup of coffee at ‘The Vault’ by 32nd!',
            }}
          />
        ) : (
          <ConfirmRecallBox
            recalling={loading}
            onSubmit={recallHandle}
            onCancel={handleOnDismiss}
          />
        )}
      </BottomSheetV2>
      <BottomSheetV2 ref={bottomSheetModalRef}>
        <View px="2xl" mt="3xl">
          <JoinClub />
          <View mt="9xl">
            <Button onPress={() => handleStorePress(Device.osName)}>
              Join the 32nd club
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

const TrackingWidgetComponent = ({
  reloadComp = () => {},
  taskNotAvailable = () => {},
  parentFetchKey,
}) => {
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      appState.current = nextAppState;
      setAppStateVisible(appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    React.useCallback(() => {
      if (appStateVisible === 'active') {
        refresh();
      }
      return () => {};
    }, [appStateVisible]),
  );

  useEffect(() => {
    refresh();
  }, [parentFetchKey]);

  return (
    <View>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<TrackingWidgetPlaceHolder />}>
          <TrackingWidget
            refresh={refresh}
            reloadComp={reloadComp}
            taskNotAvailable={taskNotAvailable}
            queryOptions={refreshedQueryOptions ?? {}}
            fetchKey={refreshedQueryOptions.fetchKey}
          />
        </Suspense>
      </NewErrorBoundary>
    </View>
  );
};

export default TrackingWidgetComponent;
