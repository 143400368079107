/**
 * @generated SignedSource<<cb43596d94dde54663294491573b5fd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumRewardRewardType = "Event" | "FreeBubbleTea" | "FreeFridgeMagnet" | "FreeKeyChain" | "FreeParking" | "FreePostCard" | "FreeSticker" | "%future added value";
export type claimrewardByStaffRecordInput = {
  rewardUniqueId: string;
};
export type rewardApiClaimRewardMutation$variables = {
  filter: claimrewardByStaffRecordInput;
};
export type rewardApiClaimRewardMutation$data = {
  readonly claimRewardByStaff: {
    readonly _id: any;
    readonly created_at: any | null;
    readonly expireAt: any;
    readonly isExpired: boolean | null;
    readonly isUsed: boolean | null;
    readonly rewardType: EnumRewardRewardType;
    readonly rewardUniqueId: string | null;
    readonly user: {
      readonly _id: any;
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
  } | null;
};
export type rewardApiClaimRewardMutation = {
  response: rewardApiClaimRewardMutation$data;
  variables: rewardApiClaimRewardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "Reward",
    "kind": "LinkedField",
    "name": "claimRewardByStaff",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isUsed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isExpired",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rewardUniqueId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rewardType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expireAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserName",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "rewardApiClaimRewardMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "rewardApiClaimRewardMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bcf3a7523d8f57f4e41717eb25befa4a",
    "id": null,
    "metadata": {},
    "name": "rewardApiClaimRewardMutation",
    "operationKind": "mutation",
    "text": "mutation rewardApiClaimRewardMutation(\n  $filter: claimrewardByStaffRecordInput!\n) {\n  claimRewardByStaff(filter: $filter) {\n    _id\n    isUsed\n    isExpired\n    rewardUniqueId\n    rewardType\n    expireAt\n    created_at\n    user {\n      name {\n        first\n        last\n      }\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "972febd288f5a1fceb7fd7f695bb9230";

export default node;
