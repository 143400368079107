import React, { memo } from 'react';
import { Image, Linking, Platform, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Pressable, Text, View } from '../../../../components/new';
import { space } from '../../../../themes/new/theme';
import { imageTransformation } from '../../../../utilities/helper';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import useAuthStore from '../../../../stores/authStore';

const areEqual = () => {
  return true;
};

const InvestArticle = ({ data, bgColor = '', propertyId }) => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const redirectWebPage = (item) => {
    firebaseEventLogger('PropertyDetails__articleTap_Tap', {
      buttonName: 'articleTap',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'tap',
      articleId: item?.title,
      isLoggedIn,
      propertyId,
    });
    if (Platform.OS === 'web') {
      Linking.openURL(item?.url);
    } else {
      navigation?.navigate('CustomWebview', {
        title: item?.title,
        url: item?.url,
      });
    }
  };

  return (
    <View bg={bgColor}>
      <View
        alignItems="center"
        py={isValueNullOrEmpty(data?.img) ? '9xl+lg' : '9xl+9xl'}
        px="2xl"
      >
        {data?.heading ? (
          <Text size="xs" color="primary.300" pb="lg" textAlign="center">
            {data?.heading}
          </Text>
        ) : null}
        {data?.subHeading ? (
          <Text size="2xl" color="primary.500" textAlign="center">
            {data?.subHeading}
          </Text>
        ) : null}
        {data?.data?.length ? (
          <View mt="9xl">
            {data?.data.map((item, index) => (
              <Pressable
                key={`articles_invest_${item?.title}_${index}`}
                style={{ width: width - 32 }}
                onPress={() => redirectWebPage(item)}
              >
                <View
                  flexDirection="row"
                  bg={
                    bgColor === 'background.primary.base'
                      ? 'background.secondary.base'
                      : 'background.primary.base'
                  }
                  mb="4xl"
                  p="lg"
                >
                  <View bg="background.primary.elevation">
                    <Image
                      source={{
                        uri: imageTransformation(
                          item?.image?.mediaType === 'VIDEO'
                            ? item?.image?.thumbnailURL
                            : item?.image?.mediaURL,
                          100,
                          '1-1',
                        ),
                      }}
                      style={{
                        height: space['9xl+9xl+3xl'],
                        width: space['9xl+9xl+3xl'],
                      }}
                    />
                  </View>
                  <View flex={1} ml="2xl">
                    <Text
                      size="md"
                      weight="medium"
                      color="primary.500"
                      mb="sm"
                      numberOfLines={2}
                    >
                      {item?.title}
                    </Text>
                    <Text size="sm" color="primary.300" numberOfLines={2}>
                      {item?.description}
                    </Text>
                    <Text
                      style={{ position: 'absolute', right: 0, bottom: 0 }}
                      size="sm"
                      color="primary.300"
                    >
                      {item?.readTime} min read
                    </Text>
                  </View>
                </View>
              </Pressable>
            ))}
          </View>
        ) : null}
      </View>
    </View>
  );
};

export default memo(InvestArticle, areEqual);
