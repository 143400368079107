import React from 'react';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import BonusListItemCard from './BonusListItemCard';

type BonusListItemProps = {
  contentPrimary: string | React.ReactElement;
  contentSecondary: string | React.ReactElement;
  sizePri?: string;
  sizeSec?: string;
  RightContent?: React.ReactElement;
  LeftContent?: React.ReactElement;
};

const BonusListItem = ({
  contentPrimary,
  contentSecondary,
  LeftContent,
  RightContent,
  sizePri = 'xs',
  sizeSec = 'xs',
}: BonusListItemProps) => {
  if (typeof contentPrimary === 'string') {
    contentPrimary = (
      <Text mb="3" size={sizePri} color="primary.500">
        {contentPrimary}
      </Text>
    );
  }
  if (typeof contentSecondary === 'string') {
    contentSecondary = (
      <Text size={sizeSec} color="primary.100">
        {contentSecondary}
      </Text>
    );
  }

  return (
    <BonusListItemCard>
      <View
        px="2xl"
        py="lg"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {LeftContent && <View>{LeftContent}</View>}
        <View>
          {contentPrimary}
          {contentSecondary}
        </View>
        {RightContent && <View>{RightContent}</View>}
      </View>
    </BonusListItemCard>
  );
};

export default BonusListItem;
