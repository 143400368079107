/**
 * @generated SignedSource<<ecfed81429b0a4935f550d7f3fea7a73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnuminvoiceOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnuminvoicePaymentType = "BILL_UPLOAD" | "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnuminvoicePopUpStoreDetailsDeliveryStatus = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type Periodicity = "DAILY" | "MONTHLY" | "%future added value";
export type SortConnectioninvoiceEnum = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "SLOT_CLOSE_TIME_ASC" | "SLOT_CLOSE_TIME_DESC" | "SLOT_START_TIME_ASC" | "SLOT_START_TIME_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type findInvoiceInput = {
  AND?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  OR?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyinvoiceOperatorsInput | null;
  addons?: FilterFindManyinvoiceAddonsInput | null;
  couponCode?: string | null;
  created_at?: any | null;
  csrContributionId?: any | null;
  datePeriod?: DatePeriod | null;
  displayName?: string | null;
  orderId?: any | null;
  orderType?: EnuminvoiceOrderType | null;
  paymentTxnId?: any | null;
  paymentType?: EnuminvoicePaymentType | null;
  popUpStoreDetails?: FilterFindManyinvoicePopUpStoreDetailsInput | null;
  priceDetails?: FilterFindManyinvoicePriceDetailsInput | null;
  rewardTxnId?: any | null;
  search?: findInvoiceInputSearch | null;
  status?: EnuminvoiceStatus | null;
  uId?: string | null;
  userId?: any | null;
  workshopDetails?: FilterFindManyinvoiceWorkshopDetailsInput | null;
};
export type FilterFindManyinvoicePriceDetailsInput = {
  _id?: any | null;
  addonAmount?: number | null;
  cashbackAmount?: number | null;
  cashbackPercentage?: number | null;
  couponAmount?: number | null;
  couponCashbackAmount?: number | null;
  couponId?: any | null;
  csrAmount?: number | null;
  discountGiven?: number | null;
  discountId?: any | null;
  discountPercentage?: number | null;
  grossAmount?: number | null;
  netAmount?: number | null;
  rewardsUsed?: number | null;
  tipAmount?: number | null;
};
export type FilterFindManyinvoiceWorkshopDetailsInput = {
  slotId?: any | null;
  tickets?: ReadonlyArray<FilterFindManyinvoiceWorkshopDetailsTicketsInput | null> | null;
};
export type FilterFindManyinvoiceWorkshopDetailsTicketsInput = {
  spaces?: number | null;
  ticketId?: any | null;
};
export type FilterFindManyinvoicePopUpStoreDetailsInput = {
  _id?: any | null;
  deliveryStatus?: EnuminvoicePopUpStoreDetailsDeliveryStatus | null;
  displayName?: string | null;
};
export type FilterFindManyinvoiceAddonsInput = {
  orderId?: any | null;
};
export type FilterFindManyinvoiceOperatorsInput = {
  _id?: FilterFindManyinvoice_idOperatorsInput | null;
  orderType?: FilterFindManyinvoiceOrderTypeOperatorsInput | null;
  status?: FilterFindManyinvoiceStatusOperatorsInput | null;
};
export type FilterFindManyinvoiceStatusOperatorsInput = {
  exists?: boolean | null;
  gt?: EnuminvoiceStatus | null;
  gte?: EnuminvoiceStatus | null;
  in?: ReadonlyArray<EnuminvoiceStatus | null> | null;
  lt?: EnuminvoiceStatus | null;
  lte?: EnuminvoiceStatus | null;
  ne?: EnuminvoiceStatus | null;
  nin?: ReadonlyArray<EnuminvoiceStatus | null> | null;
};
export type FilterFindManyinvoiceOrderTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnuminvoiceOrderType | null;
  gte?: EnuminvoiceOrderType | null;
  in?: ReadonlyArray<EnuminvoiceOrderType | null> | null;
  lt?: EnuminvoiceOrderType | null;
  lte?: EnuminvoiceOrderType | null;
  ne?: EnuminvoiceOrderType | null;
  nin?: ReadonlyArray<EnuminvoiceOrderType | null> | null;
};
export type FilterFindManyinvoice_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FilterFindManyinvoiceInput = {
  AND?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  OR?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyinvoiceOperatorsInput | null;
  addons?: FilterFindManyinvoiceAddonsInput | null;
  couponCode?: string | null;
  created_at?: any | null;
  csrContributionId?: any | null;
  displayName?: string | null;
  orderId?: any | null;
  orderType?: EnuminvoiceOrderType | null;
  paymentTxnId?: any | null;
  paymentType?: EnuminvoicePaymentType | null;
  popUpStoreDetails?: FilterFindManyinvoicePopUpStoreDetailsInput | null;
  priceDetails?: FilterFindManyinvoicePriceDetailsInput | null;
  rewardTxnId?: any | null;
  status?: EnuminvoiceStatus | null;
  uId?: string | null;
  userId?: any | null;
  workshopDetails?: FilterFindManyinvoiceWorkshopDetailsInput | null;
};
export type DatePeriod = {
  from: string;
  period: Periodicity;
};
export type findInvoiceInputSearch = {
  uId?: string | null;
};
export type TransactionsFragmentQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  filter: findInvoiceInput;
  sort?: SortConnectioninvoiceEnum | null;
};
export type TransactionsFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"transactionsFragment">;
};
export type TransactionsFragmentQuery = {
  response: TransactionsFragmentQuery$data;
  variables: TransactionsFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v2 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionsFragmentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "transactionsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionsFragmentQuery",
    "selections": [
      {
        "alias": "transactions",
        "args": (v3/*: any*/),
        "concreteType": "invoiceConnection",
        "kind": "LinkedField",
        "name": "findInvoiceV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "invoiceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "invoice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "invoicePriceDetails",
                    "kind": "LinkedField",
                    "name": "priceDetails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discountGiven",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grossAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "netAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rewardsUsed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tipAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "csrAmount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserContact",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserContactPhone",
                            "kind": "LinkedField",
                            "name": "phone",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "prefix",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "number",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "transactions",
        "args": (v3/*: any*/),
        "filters": [
          "filter",
          "sort"
        ],
        "handle": "connection",
        "key": "transactionsFragment_transactions",
        "kind": "LinkedHandle",
        "name": "findInvoiceV2"
      }
    ]
  },
  "params": {
    "cacheID": "7479a184a744330e0a76e2a49daa4f0b",
    "id": null,
    "metadata": {},
    "name": "TransactionsFragmentQuery",
    "operationKind": "query",
    "text": "query TransactionsFragmentQuery(\n  $count: Int\n  $cursor: String\n  $filter: findInvoiceInput!\n  $sort: SortConnectioninvoiceEnum\n) {\n  ...transactionsFragment_1GWUVg\n}\n\nfragment transactionsFragment_1GWUVg on Query {\n  transactions: findInvoiceV2(filter: $filter, sort: $sort, first: $count, after: $cursor) {\n    edges {\n      node {\n        _id\n        uId\n        created_at\n        status\n        priceDetails {\n          discountGiven\n          grossAmount\n          netAmount\n          rewardsUsed\n          tipAmount\n          csrAmount\n        }\n        user {\n          contact {\n            phone {\n              prefix\n              number\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c4f094178cda2f631a615357a7d2d1ae";

export default node;
