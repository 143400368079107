import React from 'react';
import { Image, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Text, View } from '../../../../components/new';
import { space } from '../../../../themes/new/theme';

const InterestRegistered = () => {
  const insets = useSafeAreaInsets();
  return (
    <View
      bg="grey.10"
      flexDirection="row"
      p="2xl"
      alignItems="center"
      pb={Platform.OS === 'web' ? '2xl' : insets.bottom}
    >
      <Image
        source={require('../../../../../assets/images/green_tick.webp')}
        style={{
          height: space['9xl'],
          width: space['9xl'],
          resizeMode: 'contain',
        }}
      />
      <View flex={1} ml="xl">
        <Text
          size="md"
          weight="medium"
          color="primary.500"
          mb="sm"
          numberOfLines={2}
        >
          Thank you for your interest!
        </Text>
        <Text size="xs" color="primary.300" numberOfLines={2}>
          Expect us to reach out to you soon to discuss exclusive details- Stay
          tuned!
        </Text>
      </View>
    </View>
  );
};

export default InterestRegistered;
