import React from 'react';
import { View, Avatar, Text, Pressable, Card } from '..';

interface StatusTypes {
  text: string;
  color: string;
}

interface OverviewSearchValetCardProps {
  imageLink: string;
  fullName: string;
  onPress: () => void;
  marginHorizontal: string;
  status: StatusTypes;
}

const OverviewSearchValetCard = ({
  imageLink = '',
  fullName = '',
  onPress = () => {},
  marginHorizontal = '2xl',
  status = {
    text: 'Unavailable',
    color: 'grey.500',
  },
}: OverviewSearchValetCardProps) => {
  return (
    <Card
      category="none"
      mx={marginHorizontal}
      borderColor="primary.50"
      borderWidth="xs"
    >
      <Pressable onPress={onPress}>
        <View px="2xl" py="lg">
          <View
            flexDirection="row"
            alignItems="center"
            //   mb="lg"
            justifyContent="space-between"
          >
            <View flexDirection="row" alignItems="center">
              <Avatar level={1} source={imageLink} name={fullName} />
              <View ml="2xl">
                <View flexDirection="row" alignItems="center">
                  <Text size="sm" color="primary.500">
                    {fullName}
                  </Text>
                </View>
                <View flexDirection="row" alignItems="center">
                  <View
                    width="3xs"
                    height="3xs"
                    bg={status?.color}
                    borderRadius="full"
                  />
                  <Text size="xs" color="primary.100" ml="sm">
                    {status?.text}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Pressable>
    </Card>
  );
};

export default OverviewSearchValetCard;
