import { useCallback, useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { loyaltyQuery } from './loyaltyApi';
import { loyaltyApiQuery$data } from './__generated__/loyaltyApiQuery.graphql';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import useCommonStore from '../../../stores/commonStore';
import useUserStore from '../../../stores/userStore';
import useRestaurantPartnerStore from '../../../stores/restaurantpartner/restaurantPartnerStore';
import { pagination } from '../../../utilities/Constant';

/* eslint-disable-next-line import/prefer-default-export */
export const useLoyalty = (
  queryOptions: NewErrorBoundaryParentState,
  refresh: () => void,
) => {
  const navigation = useNavigation();
  const { campusId } = useCommonStore((state) => state);
  const { id: userId } = useUserStore((state) => state);
  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  const { findUserById, ...getRestaurantPartnerUserRef }: loyaltyApiQuery$data =
    useLazyLoadQuery(
      loyaltyQuery,
      {
        id: userId,
        count: pagination.defaultPageSize,
        filter: {
          campus: {
            _id: campusId,
          },
        },
      },
      {
        ...queryOptions,
        networkCacheConfig: { force: true },
      },
    );

  const { setLoyalty, setRestaurantName } = useRestaurantPartnerStore(
    (state) => state,
  );

  useEffect(() => {
    setLoyalty(
      findUserById?.restaurants?.payBill?.pointsEnabled?.redeem &&
        findUserById?.restaurants?.payBill?.pointsEnabled?.reward,
    );
    setRestaurantName(findUserById?.restaurants?.displayName);
  }, []);

  return {
    findUserById,
    getRestaurantPartnerUserRef,
  };
};
