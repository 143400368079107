import React, { useState } from 'react';
import { Image, SafeAreaView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import uuid from 'react-native-uuid';
import {
  Button,
  IconButton,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import ShareOnInstagramBanner from './ShareOnInstagramBanner';
import useUserStore from '../../stores/userStore';
import { navigateBack } from '../../utilities/helper';
import { InputStates } from '../../components/new/primitive/OTPInput/helpers';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import Loading from '../../components/loading.component';

const RenderFeatureWidget = ({ image, text }) => {
  return (
    <View
      style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 14 }}
    >
      <Image
        style={{ height: 40, width: 40, marginRight: 16 }}
        source={image}
      />
      <Text size="md" color="primary.300">
        {text}
      </Text>
    </View>
  );
};

const InstagramSharePage = ({ route }) => {
  const navigation = useNavigation<any>();
  const assetType = route?.params?.assetType;
  const assetUri = route?.params?.assetUri;

  const { role } = useUserStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const [loading, setLoading] = useState<boolean>(false);

  const onShare = async () => {
    setLoading(true);
    const fileDetails = {
      extension: assetType !== 'IMAGE' ? '.mp4' : '.jpg',
      shareOptions: {
        mimeType: assetType !== 'IMAGE' ? 'video/mp4' : 'image/jpeg',
        dialogTitle: '',
        UTI: assetType !== 'IMAGE' ? 'video/mp4' : 'image/jpeg',
      },
    };
    const downloadPath = `${FileSystem.cacheDirectory}${uuid.v4()}${
      fileDetails.extension
    }`;
    const { uri: localUrl } = await FileSystem.downloadAsync(
      assetUri,
      downloadPath,
    );
    if (!(await Sharing.isAvailableAsync())) {
      dispatchSnackbar({
        msg: 'Sharing is not available',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v1,
      });
      setLoading(false);
      return;
    }
    setLoading(false);
    await Sharing.shareAsync(localUrl, fileDetails.shareOptions);
  };

  return (
    <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
      {loading && (
        <View
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: 'black',
            zIndex: 1,
            opacity: 0.5,
          }}
        >
          <Loading />
        </View>
      )}
      {/* @ts-ignore */}
      <TopNavigation
        title="Get Featured"
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={() => navigateBack(navigation, role)}
          />
        }
      />
      <SafeAreaView style={{ flex: 1 }}>
        <View style={{ flex: 1 }} px="2xl">
          <View mb="7xl">
            <ShareOnInstagramBanner onClick={() => {}} showStories />
          </View>
          <Text size="xl" color="primary.500" mb="2xl">
            How do I get featured?
          </Text>
          {!assetUri && (
            <RenderFeatureWidget
              image={require('../../../assets/images/photoShare.png')}
              text={`View your 32nd Moments and \nshare on Instagram`}
            />
          )}
          <RenderFeatureWidget
            image={require('../../../assets/images/tintedHash.webp')}
            text={`Post your moment on Instagram \nwith #32NDMOMENT`}
          />
          <RenderFeatureWidget
            image={require('../../../assets/images/addFriends.webp')}
            text="Follow us @32ndofficial"
          />
        </View>
        <View px="2xl" pb="2xl">
          <Button
            size="lg"
            status="primary"
            state={InputStates.active}
            onPress={() => {
              if (assetUri) {
                firebaseEventLogger('photo_moments_continue_to_post_clicked', {
                  buttonName: 'Continue to Post',
                  screenName: 'instagram_share',
                  interactionType: 'click',
                });
                onShare().catch((e) => console.log(e));
              } else {
                firebaseEventLogger('photo_moments_view_my_moments_clicked', {
                  buttonName: 'View My Moments',
                  screenName: 'instagram_share',
                  interactionType: 'click',
                });
                navigation.goBack();
              }
            }}
          >
            {assetUri ? 'Continue to Post' : 'View My Moments'}
          </Button>
        </View>
      </SafeAreaView>
    </View>
  );
};

export default InstagramSharePage;
