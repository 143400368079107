/* eslint-disable import/prefer-default-export */
import { useCallback, useMemo, useRef, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {
  dateUtils,
  navigateBack,
  toISOStringWithTimezone,
} from '../../../../utilities/helper';
import { TransactionsRevenueReport } from '../../types';
import { BSMode } from './PayoutDetailsBottomSheet';
import useUserStore from '../../../../stores/userStore';
import payoutOverviewScreenQuery from './payoutOverviewScreenQuery';
import { payoutOverviewScreenQuery$variables } from './__generated__/payoutOverviewScreenQuery.graphql';
import { useTransactionsRevenueReport } from '../../API/fragments/transactionsRevenueReportFragment';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import useTransactionsEarningReport from '../../API/fragments/transactionsEarningReportFragment/useTransactionsEarningReportFragment';

export const usePayoutOverviewScreen = (
  queryOptions: NewErrorBoundaryParentState,
) => {
  const bsRef = useRef();
  const navigation = useNavigation();
  const route = useRoute();

  /**
   * =======================================================================
   *                      Dates and states and manipulation
   * =======================================================================
   */
  const userRole = useUserStore((state) => state.role);
  const [bsMode, setBSMode] = useState<BSMode>(BSMode.DISMISSED);
  const {
    date, // this date could be of prev months as well as of current month
    restaurantId,
  } = route.params;
  const [todaysDate, setTodaysDate] = useState(
    dateUtils.isCurrentMonth(new Date(date)) ? new Date() : new Date(date),
  );
  const queryDates = useMemo(
    () => calculateQueryDates(todaysDate),
    [todaysDate],
  );

  /**
   * =======================================================================
   *                      Query related stuff
   * =======================================================================
   */
  const variables = buildQueryVariables(queryDates, restaurantId);
  const fragmentRef = useLazyLoadQuery(
    payoutOverviewScreenQuery,
    variables,
    queryOptions,
  );
  const { earningReports } = useTransactionsEarningReport(fragmentRef);
  const { revenueReports } = useTransactionsRevenueReport(fragmentRef);

  /**
   * =======================================================================
   *                      Hooks
   * =======================================================================
   */
  useFocusEffect(
    useCallback(() => {
      if (dateUtils.isCurrentMonth(new Date(date))) {
        // keep on updating the endTime to the latest
        setTodaysDate(new Date());
      }
    }, [date]),
  );

  /**
   * =======================================================================
   *                        Callbacks and Handlers
   * =======================================================================
   */
  const showEarningsPanelBS = () => {
    setBSMode(BSMode.EARNINGS_REPORT);
    bsRef.current?.show();
  };

  const handleBSDismiss = () => {
    setBSMode(BSMode.DISMISSED);
  };

  const navigateToEarningsOverviewScreen = (
    revenueReport: TransactionsRevenueReport,
  ) => {
    if (revenueReport.revenueAmount !== 0) {
      navigation.navigate('earningsOverview', {
        date: toISOStringWithTimezone(new Date(revenueReport.date)),
        restaurantId,
      });
    }
  };

  return {
    bsRef,
    bsMode,
    earningReportOfMonth: earningReports[0],
    earningReportsOfMonthDays: revenueReports,
    handleBSDismiss,
    showEarningsPanelBS,
    navigateToEarningsOverviewScreen,
    handleBack: () => navigateBack(navigation, userRole),
  };
};

/**
 * =======================================================================
 *                        Utilities
 * =======================================================================
 */
const buildQueryVariables = (
  queryDates: { startDate: string; endDate: string },
  restaurantId: string,
): payoutOverviewScreenQuery$variables => {
  const { startDate, endDate } = queryDates;
  return {
    revenueReportFilter: {
      date: {
        start: startDate,
        end: endDate,
      },
      groupBy: 'DAY',
      orderId: restaurantId,
    },
    earningsReportfilter: {
      orderId: restaurantId,
      groupBy: 'MONTH',
      date: {
        start: startDate,
        end: endDate,
      },
    },
  };
};

const calculateQueryDates = (
  date: Date,
): { startDate: string; endDate: string } => {
  const startDate = dateUtils.toStartOfMonth(date);
  const endDate = dateUtils.isCurrentMonth(date)
    ? date
    : dateUtils.toEndOfMonth(date);

  return {
    startDate: toISOStringWithTimezone(startDate),
    endDate: toISOStringWithTimezone(endDate),
  };
};
