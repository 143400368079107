import React from 'react';
import View from '../../../basic/View/View';
import { getTheme, themeModes } from '../../../../../themes/new/theme';

const darkTheme = getTheme(themeModes.dark);

const Divider = () => (
  <View
    mb="lg"
    borderBottomWidth="xs"
    borderColor={darkTheme.colors.opacity['10']}
  />
);

export default Divider;
