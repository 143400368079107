/* eslint-disable import/prefer-default-export */
import { UserRole } from '../../../../../stores/userStore';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../../utilities/firbaseAnalytics';
import { BenefitsListItemData } from './helpers';

const analytics = {
  landed: () => {
    firebaseEventLogger('blackMembershipScreen__land_Landing', {
      buttonName: 'land',
      userType: UserRole.USER,
      interactionType: InteractionType.LANDING,
    });
  },
  buy32ndBlackClicked: () => {
    firebaseEventLogger('blackMembershipScreen__buy32ndBlack_Tap', {
      buttonName: 'buy32ndBlack',
      userType: UserRole.USER,
      interactionType: InteractionType.TAP,
    });
  },
  memberBenefitsCTAClicked: (info: BenefitsListItemData['info']) => {
    if (typeof info !== 'string') {
      const { name, ...restOptions } = info?.eventInfo ?? {};
      firebaseEventLogger(name, restOptions);
    }
  },
  checkoutOffersClicked: () => {
    firebaseEventLogger('blackMembershipScreen__checkOffers_Tap', {
      buttonName: 'checkOffers',
      userType: UserRole.USER,
      interactionType: InteractionType.TAP,
    });
  },
};

export default analytics;
