import React from 'react';
import View from '../basic/View/View';
import Text from '../primitive/Typography/Text/Text';
import Button from '../primitive/Button/Button';

const ConfirmationCard = ({
  heading = '',
  subHeading = '',
  buttonOneText = '',
  buttonTwoText = '',
  img = null,
  onButtonOnePress = () => {},
  onButtonTwoPress = () => {},
}) => {
  return (
    <View
      width="90%"
      maxWidth={358}
      maxHeight={500}
      bg="background.primary.base"
      borderRadius={10}
      px="4xl"
      py="xl"
    >
      {!!img && (
        <View alignItems="center" mb="2xl">
          {img}
        </View>
      )}
      <Text textAlign="center" size="2xl" color="primary.500">
        {heading}
      </Text>
      <Text textAlign="center" size="sm" mt="xl" mb="4xl" color="primary.300">
        {subHeading}
      </Text>
      <View flexDirection="row" justifyContent="space-between">
        <View flex={1} mr="sm">
          <Button appearance="outline" onPress={onButtonOnePress}>
            {buttonOneText}
          </Button>
        </View>
        <View flex={1} ml="sm">
          <Button onPress={onButtonTwoPress}>{buttonTwoText}</Button>
        </View>
      </View>
    </View>
  );
};

export default ConfirmationCard;
