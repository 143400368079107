import React from 'react';
import { useWindowDimensions } from 'react-native';
import {
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import useLoginModalStore from '../../stores/loginModalStore';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import useAuthStore from '../../stores/authStore';
import { navigateBack } from '../../utilities/helper';
import useCommonStore from '../../stores/commonStore';
import RewardsScreen from './RewardsScreen';

const NotLoggedInRewardsScreen = (props) => {
  const { width, height } = useWindowDimensions();
  const screenOrientationValue = useCommonStore((state) => state.orientation);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const handleButtonAction = () => {
    firebaseEventLogger('rewards__freeParking_Tap', {
      buttonName: 'freeParking',
      screenName: 'rewards',
      userType: 'user',
      interactionType: 'tap',
    });
    setAuthModalOpen(true);
  };

  if (isLoggedIn) {
    return <RewardsScreen {...props} />;
  }

  return (
    <Layout level={2}>
      <TopNavigation
        title="32nd Rewards"
        appearance="ghost"
        level="1"
        textSize="xl"
        textColor="primary.400"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="sm"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(props.navigation, 'user')}
          />
        }
      />
      <View justifyContent="center" alignItems="center" flex={1}>
        <Text size="lg" color="primary.500">
          Rewards coming soon!
        </Text>
      </View>
    </Layout>
  );
};

export default NotLoggedInRewardsScreen;
