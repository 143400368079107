import React, { forwardRef } from 'react';
import View from '../../../basic/View/View';
import Text from '../../../primitive/Typography/Text/Text';
import PartnerListItem from './PartnerListItem';
import BottomSheetV2 from '../../BottomSheet/BottomSheetV2';
import { FontWeights } from '../../../../../themes/new/helper';

type PartnerDetail = { location: string; lat: string; long: string };
interface PartnersBottomSheetProps {
  partnerDetails: PartnerDetail[];
}

const PartnersBottomSheet = forwardRef(
  (props: PartnersBottomSheetProps, ref) => {
    const { partnerDetails } = props;

    return (
      <BottomSheetV2 ref={ref} insets={false}>
        <View>
          <View mt="2xl">
            <Text
              mt="2xl"
              mb="4xl"
              textAlign="center"
              size="xl"
              color="primary.400"
              weight={FontWeights.MEDIUM}
            >
              Our Partners
            </Text>
            {partnerDetails.length &&
              partnerDetails.map((partner) => (
                <PartnerListItem data={partner} />
              ))}
          </View>
        </View>
      </BottomSheetV2>
    );
  },
);

export default PartnersBottomSheet;
