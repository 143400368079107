import React, { memo, useRef } from 'react';
import { Platform } from 'react-native';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as StoreReview from 'expo-store-review';
import useFeedbackModalStore from '../../stores/FeedbackModalStore';
import { Button, Text, View } from '../new';
import CustomLottieView from '../new/custom/CustomLottieView';

const areEqual = () => {
  return true;
};

const RatingSuccess = () => {
  const componentState = useFeedbackModalStore((state) => state);
  const animation = useRef(null);

  const handleOnSubmit = async () => {
    if (
      componentState.rating >= 3 &&
      (await StoreReview.hasAction()) &&
      Platform.OS !== 'web'
    ) {
      StoreReview.requestReview();
    }
    componentState.setToDefault();
  };

  return (
    <View justifyContent="center" p="2xl" alignItems="center">
      <Text mt="2xl" size="xl" color="primary.400" weight="medium">
        Thank you for your feedback!
      </Text>
      <CustomLottieView
        autoPlay
        loop
        source={require('../../../assets/lottie/gift-box-open.json')}
        style={{ width: 180, height: 140 }}
        containerWidth={180}
      />
      <View flexDirection="row" mt="lg" justifyContent="center">
        <Text size="md" color="primary.300" weight="medium" textAlign="center">
          Your feedback is valuable to us!
        </Text>
      </View>
      <View width="100%">
        <Button
          mt="3xl"
          mb="sm"
          size="lg"
          appearance="filled"
          status="primary"
          state="active"
          onPress={handleOnSubmit}
        >
          Ok, Got it!
        </Button>
      </View>
    </View>
  );
};

export default memo(RatingSuccess, areEqual);
