import React, { useState, useCallback, Suspense } from 'react';
import { FlatList, useWindowDimensions } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import {
  Layout,
  View,
  IconButton,
  TopNavigation,
  ChipGroup,
} from '../../components/new';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useUserStore from '../../stores/userStore';
import {
  CheckIfDateLiesInWeekend,
  getDayFromDate,
  navigateBack,
} from '../../utilities/helper';
import WalkInOfferCard from './WalkInOfferCard';
import {
  DiscountDaysEnum,
  CustomerDiscountTimingEnum,
  MealTimeEnum,
} from '../../screens/restaurantPartner/discounts/helper';
import { discountsAndOfferScreenQuery } from './API/discountsAndOfferScreenApi';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

const DiscountsAndOfferScreen = (props) => {
  const { queryOptions, variables, refresh, navigation, payBillCashback } =
    props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getRestaurantpartnerDiscount } = useLazyLoadQuery(
    discountsAndOfferScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const { width } = useWindowDimensions();

  const { wallet } = useUserStore((state) => state);

  const renderItem = ({ item, index }) => {
    if (item?.user?.isNewUser && item?.discounts?.newUser > 0) {
      return (
        <View
          ml="2xl"
          mt="2xl"
          mr={index === getRestaurantpartnerDiscount.length - 1 ? '2xl' : 0}
        >
          <WalkInOfferCard
            title={`Flat ${item?.discounts?.newUser}% Off`}
            subTitle={
              item?.mealTime === MealTimeEnum.LUNCH
                ? CustomerDiscountTimingEnum.LUNCHTIMING
                : CustomerDiscountTimingEnum.DINNERTIMING
            }
            bottomText="For members only"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../assets/images/black_membership/black-member-logo-3.webp')}
            forBlackMember
          />
        </View>
      );
    }
    if (item?.user?.isEmployee && item?.discounts?.corporateUser > 0) {
      return (
        <View
          ml="2xl"
          mt="2xl"
          mr={index === getRestaurantpartnerDiscount.length - 1 ? '2xl' : 0}
        >
          <WalkInOfferCard
            title={`Flat ${item?.discounts?.corporateUser}% Off`}
            subTitle={
              item?.mealTime === MealTimeEnum.LUNCH
                ? CustomerDiscountTimingEnum.LUNCHTIMING
                : CustomerDiscountTimingEnum.DINNERTIMING
            }
            bottomText="For members only"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../assets/images/black_membership/black-member-logo-3.webp')}
            forBlackMember
          />
        </View>
      );
    }

    if (item?.payBillCashback > 0) {
      return (
        <View ml="2xl" mt="2xl" mr="2xl">
          <WalkInOfferCard
            title={`${item?.payBillCashback}% Cashback`}
            subTitle="Exclusively for you"
            bottomText="For members only"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../assets/images/black_membership/black-member-logo-3.webp')}
            forBlackMember
          />
        </View>
      );
    }

    if (item && item?.currentBalance > 0) {
      return (
        <View
          ml={index % 2 === 0 ? '2xl' : 0}
          mt="2xl"
          mr={index % 2 === 0 ? 0 : '2xl'}
        >
          <WalkInOfferCard
            title={`Extra ₹${item?.currentBalance} Off`}
            subTitle="32nd Points"
            bottomText="Exclusively for you"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../assets/images/coins.webp')}
          />
        </View>
      );
    }

    if (
      !item?.user?.isEmployee &&
      !item?.user?.isNewUser &&
      item?.discounts?.allUser > 0
    ) {
      return (
        <View
          ml="2xl"
          mt="2xl"
          mr={index === getRestaurantpartnerDiscount.length - 1 ? '2xl' : 0}
        >
          <WalkInOfferCard
            title={`Flat ${item?.discounts?.corporateUser}% Off`}
            subTitle={
              item?.mealTime === MealTimeEnum.LUNCH
                ? CustomerDiscountTimingEnum.LUNCHTIMING
                : CustomerDiscountTimingEnum.DINNERTIMING
            }
            bottomText="For members only"
            widthProp={width * 0.44}
            aspectRatio={1.064}
            imageSrc={require('../../../assets/images/black_membership/black-member-logo-3.webp')}
            forBlackMember
          />
        </View>
      );
    }
    return null;
  };

  const discountsData = [...getRestaurantpartnerDiscount].filter(
    (item) =>
      item?.discounts?.allUser > 0 &&
      item?.discounts?.newUser > 0 &&
      item?.discounts?.corporateUser > 0,
  );

  const walletData =
    wallet?.currentBalance || wallet?.moneySaved ? [wallet] : [];

  return (
    <View flex={1}>
      <FlatList
        data={[...discountsData, { payBillCashback }, ...walletData]}
        renderItem={renderItem}
        numColumns={2}
      />
    </View>
  );
};

const DiscountsAndOfferScreenWrapper = ({ navigation, route }) => {
  const { role } = useUserStore((state) => state);
  const { restaurantId, payBillCashback } = route?.params ?? {};

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const today = new Date();

  const [chipData, setChipData] = useState([
    {
      id: 0,
      name: 'Today',
      disabled: false,
      value: CheckIfDateLiesInWeekend(today, 0)
        ? DiscountDaysEnum.WEEKEND
        : DiscountDaysEnum.WEEKDAY,
    },
    {
      id: 1,
      name: 'Tomorrow',
      disabled: false,
      value: CheckIfDateLiesInWeekend(today, 1)
        ? DiscountDaysEnum.WEEKEND
        : DiscountDaysEnum.WEEKDAY,
    },
    {
      id: 2,
      name: getDayFromDate(today, 2),
      disabled: false,
      value: CheckIfDateLiesInWeekend(today, 2)
        ? DiscountDaysEnum.WEEKEND
        : DiscountDaysEnum.WEEKDAY,
    },
    {
      id: 3,
      name: getDayFromDate(today, 3),
      disabled: false,
      value: CheckIfDateLiesInWeekend(today, 3)
        ? DiscountDaysEnum.WEEKEND
        : DiscountDaysEnum.WEEKDAY,
    },
    {
      id: 4,
      name: getDayFromDate(today, 4),
      disabled: false,
      value: CheckIfDateLiesInWeekend(today, 4)
        ? DiscountDaysEnum.WEEKEND
        : DiscountDaysEnum.WEEKDAY,
    },
    {
      id: 5,
      name: getDayFromDate(today, 5),
      disabled: false,
      value: CheckIfDateLiesInWeekend(today, 5)
        ? DiscountDaysEnum.WEEKEND
        : DiscountDaysEnum.WEEKDAY,
    },
    {
      id: 6,
      name: getDayFromDate(today, 6),
      disabled: false,
      value: CheckIfDateLiesInWeekend(today, 6)
        ? DiscountDaysEnum.WEEKEND
        : DiscountDaysEnum.WEEKDAY,
    },
  ]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const [filter, setFilter] = useState({
    restaurantId,
    days: chipData[0].value,
    isActive: true,
  });

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        title="Discounts & Rewards"
        ChildrenElement={
          <View pb="xl">
            <ChipGroup
              data={chipData}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              callbackFn={(item, index) => {
                firebaseEventLogger('DicountAndOfferFilterClicked', {
                  name: item?.name,
                });
                setFilter({ restaurantId, isActive: true, days: item?.value });
              }}
            />
          </View>
        }
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <DiscountsAndOfferScreen
            variables={{ filter }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
            payBillCashback={payBillCashback}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default DiscountsAndOfferScreenWrapper;
