/**
 * @generated SignedSource<<7d1e58faa4858430cfba0fdffd0dbed7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type findVehiclesFilterInput = {
  _id?: any | null;
  ownerID: any;
  registration?: FilterFindManyVehicleRegistrationInput | null;
};
export type FilterFindManyVehicleRegistrationInput = {
  _id?: any | null;
  city?: string | null;
  plate?: string | null;
  state?: string | null;
};
export type vehicleApiQuery$variables = {
  filter: findVehiclesFilterInput;
};
export type vehicleApiQuery$data = {
  readonly findVehicles: ReadonlyArray<{
    readonly _id: any;
    readonly brand: {
      readonly brandLogo: string | null;
      readonly name: string | null;
    } | null;
    readonly color: string | null;
    readonly ownerID: any;
    readonly registration: {
      readonly plate: string;
    };
  }>;
};
export type vehicleApiQuery = {
  response: vehicleApiQuery$data;
  variables: vehicleApiQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "Vehicle",
    "kind": "LinkedField",
    "name": "findVehicles",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VehicleBrand",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brandLogo",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VehicleRegistration",
        "kind": "LinkedField",
        "name": "registration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "plate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ownerID",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "vehicleApiQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "vehicleApiQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ea849d64c743a09397ca7a16379942f8",
    "id": null,
    "metadata": {},
    "name": "vehicleApiQuery",
    "operationKind": "query",
    "text": "query vehicleApiQuery(\n  $filter: findVehiclesFilterInput!\n) {\n  findVehicles(filter: $filter) {\n    _id\n    brand {\n      name\n      brandLogo\n    }\n    registration {\n      plate\n    }\n    color\n    ownerID\n  }\n}\n"
  }
};
})();

(node as any).hash = "537b01d133d253481a9c0859a769280d";

export default node;
