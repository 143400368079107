const snackbarSlice = (set, get) => {
  return {
    snackbarData: null,
    timer: null,
    dispatchSnackbar: ({
      msg,
      version,
      status,
      position,
      styleProps,
      removeAfter = 3000,
    }) => {
      set((state) => {
        // first clear old timer
        if (state.timer) {
          clearTimeout(state.timer);
        }
        // start new timer
        const localTimer = setTimeout(() => {
          set(() => ({ snackbarData: null }));
        }, removeAfter);

        // ==================
        return {
          snackbarData: { msg, version, status, position, styleProps },
          timer: localTimer,
        };
      });
    },
    clearCurrentSnackbar: () => {
      set((state) => {
        if (state.timer) {
          return { snackbarData: null, timer: null };
        }
        return {};
      });
    },
    getSnackbarState: () => get().snackbarData,
  };
};

export default snackbarSlice;
