/* eslint-disable no-nested-ternary */
import React from 'react';
import CustomIcon from './customIcon';
import { getTheme } from '../../../../themes/new/theme';

const Icon = ({ size, name, color, ...props }) => {
  // console.log('propos....', props);
  const getSize = (iconSize) => {
    if (iconSize === 'xs') {
      return getTheme().fontSizes.xs;
    }
    if (iconSize === 'md') {
      return getTheme().fontSizes.md;
    }
    if (iconSize === 'lg') {
      return getTheme().fontSizes.lg;
    }
    if (iconSize === 'xl') {
      return getTheme().fontSizes.xl;
    }
    if (iconSize === '9xl') {
      return getTheme().fontSizes['9xl'];
    }
    if (iconSize === '6xl') {
      return getTheme().fontSizes['6xl'];
    }
    if (iconSize === '3xl+3xl') {
      return 2 * getTheme().fontSizes['3xl'];
    }
    if (iconSize === '8xl') {
      return getTheme().fontSizes['8xl'];
    }
    if (iconSize === 'sm+9xl') {
      return getTheme().fontSizes.sm + getTheme().fontSizes['9xl'];
    }
    if (iconSize === '3xl') {
      return getTheme().fontSizes['3xl'];
    }
    return getTheme().fontSizes['2xl'];
  };
  return <CustomIcon color={color} icon={name} size={getSize(size)} />;
};

export default Icon;
