import React, { useEffect, useRef, useState } from 'react';
import { Video, ResizeMode } from 'expo-av';
import { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import { ActivityIndicator, useWindowDimensions } from 'react-native';
import { View, Pressable, IconButton, Icon, Text } from '../index';
import { colors, getTheme, space, themeModes } from '../../../themes/new/theme';
import GradientBackground from './GradientBackground';
import useBoundStore from '../../../stores';
import Loading from '../../loading.component';
import {
  dynamicHeightMaker,
  videoTransformation,
} from '../../../utilities/helper';

const VideoPlayer = (props) => {
  const {
    id,
    play,
    setPlay,
    isLooping,
    url,
    aspectRatio,
    showPlayPause,
    height: heightProp,
    showFullScreen,
    bottom,
    width: widthProp,
    isVideoMoments = false,
    bg,
    styleObjProp,
    onTogglePlay,
    ...rest
  } = props ?? {};

  const resizeMode = ResizeMode.COVER;
  const { width } = useWindowDimensions();
  const styleObj = {
    width: widthProp || width,
    height: heightProp || dynamicHeightMaker(width, aspectRatio),
    position: 'relative',
    backgroundColor: rest?.videoBg ?? 'transparent',
  };

  const videoRef = useRef(null);
  const [showControls, setShowControls] = useState(true);
  const [buffering, setBuffering] = useState(false);
  const muted = useBoundStore((state) => state.isMuted);
  const setMuted = useBoundStore((state) => state.toggleMuted);
  const [videoURL, setVideoURL] = useState(
    videoTransformation(url, styleObj?.width, aspectRatio),
  );
  const [isPreloading, setIsPreloading] = useState(false);

  useEffect(() => {
    if (isVideoMoments) {
      setTimeout(() => {
        ToggleFullScreen();
      }, 10);
    }
  }, []);

  const getStatusAsync = async () => {
    return videoRef?.current?.getStatusAsync().then((res) => {
      return res;
    });
  };

  const TogglePlay = async () => {
    if (onTogglePlay) {
      onTogglePlay();
    }
    const status = await getStatusAsync();
    setPlay(!status?.isPlaying);
    // setVideoStatus({ ...status, shoulPlay: !play });
    videoRef.current.setStatusAsync({
      //   ...status,
      shouldPlay: !status?.isPlaying,
    });
    setTimeout(() => {
      setShowControls(!showControls);
    }, 2000);
  };

  const ToggleMuteUnmute = async () => {
    videoRef.current.setStatusAsync({
      isMuted: !muted,
    });
    setMuted();
  };

  const ToggleFullScreen = async () => {
    videoRef?.current.presentFullscreenPlayer();
    const status = await getStatusAsync();
    setPlay(status?.isPlaying ? status?.isPlaying : !status?.isPlaying);
    videoRef.current.setStatusAsync({
      shouldPlay: status?.isPlaying ? status?.isPlaying : !status?.isPlaying,
    });
  };

  useEffect(() => {
    if (!play) {
      setShowControls(true);
    }
    if (rest?.hideControls) {
      checkPlay();
    }
  }, [play]);

  const checkPlay = async () => {
    videoRef?.current?.setStatusAsync({
      shouldPlay: play,
      isMuted: true,
    });
  };

  const gradientColors = [
    { offset: '0', color: colors.gradient[0], opacity: 0 },
    { offset: '1', color: colors.singletone.black, opacity: 1 },
  ];

  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider
          theme={
            theme.currentThemeMode === themeModes.light
              ? getTheme(themeModes.light)
              : getTheme(themeModes.dark)
          }
        >
          <View>
            {isPreloading && (
              <ActivityIndicator
                animating
                color={colors.grey[100]}
                size="large"
                style={{
                  flex: 1,
                  position: 'absolute',
                  top: '50%',
                  left: '45%',
                }}
              />
            )}
            <Pressable
              onPress={() => {
                setShowControls(!showControls);
              }}
            >
              <Video
                ref={videoRef}
                source={{
                  uri: videoURL,
                }}
                rate={1}
                isMuted={muted}
                isLooping={isLooping}
                useNativeControls={false}
                resizeMode={resizeMode}
                onError={() => setVideoURL(url)}
                onLoadStart={() => setIsPreloading(true)}
                onReadyForDisplay={() => setIsPreloading(false)}
                onFullscreenUpdate={(val) => {
                  if (isVideoMoments && val?.fullscreenUpdate === 3) {
                    setPlay(false);
                  }
                }}
                onPlaybackStatusUpdate={(playbackStatus) => {
                  setBuffering(playbackStatus?.isBuffering);
                  if (playbackStatus?.didJustFinish && !isLooping) {
                    setPlay(false);
                    videoRef?.current?.setPositionAsync(0);
                    if (isVideoMoments) {
                      videoRef?.current?.dismissFullscreenPlayer();
                    }
                  }
                }}
                shouldPlay={play}
                videoStyle={styleObj}
                style={styleObj}
              />
              {showControls && !rest?.hideControls ? (
                <View
                  position="absolute"
                  width="100%"
                  height="100%"
                  backgroundColor={
                    bg ||
                    (theme.currentThemeMode === themeModes.light
                      ? getTheme(themeModes.dark).colors.opacity[40]
                      : getTheme(themeModes.light).colors.opacity[40])
                  }
                >
                  {!showPlayPause ? (
                    <Pressable
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onPress={TogglePlay}
                    >
                      <Icon
                        name={play ? 'pause-filled-400' : 'play-outline-300'}
                        size="9xl"
                        color="static.white"
                      />
                    </Pressable>
                  ) : (
                    <></>
                  )}
                  <View
                    position="absolute"
                    bottom={0}
                    height={space['9xl+9xl']}
                    width={width}
                  >
                    <GradientBackground
                      backgroundOpacity={0.6}
                      gradientColors={[
                        {
                          offset: '0',
                          color: theme.colors.primary[500],
                          opacity: 0,
                        },
                        {
                          offset: '1',
                          color: theme.colors.primary[500],
                          opacity: 0.8,
                        },
                      ]}
                      id={id}
                    />
                  </View>
                  {showFullScreen ? (
                    <View
                      position="absolute"
                      bottom={
                        bottom && bottom !== 0
                          ? bottom + getTheme(themeModes.light).space.sm
                          : getTheme(themeModes.light).space.sm
                      }
                      left="2xl"
                    >
                      <IconButton
                        name="fullscreen-400"
                        size="md"
                        iconSize="xl"
                        iconColor="static.white"
                        shape="square"
                        appearance="ghost"
                        onPress={ToggleFullScreen}
                      />
                    </View>
                  ) : (
                    <></>
                  )}
                  <View
                    position="absolute"
                    bottom={
                      bottom && bottom !== 0
                        ? bottom + getTheme(themeModes.light).space.sm
                        : getTheme(themeModes.light).space.sm
                    }
                    right="2xl"
                  >
                    <IconButton
                      name={muted ? 'mute-outline-400' : 'un-mute-outline-400'}
                      size="md"
                      iconSize="xl"
                      iconColor="static.white"
                      shape="square"
                      appearance="ghost"
                      onPress={ToggleMuteUnmute}
                    />
                  </View>
                </View>
              ) : (
                <></>
              )}
            </Pressable>
            {buffering && isVideoMoments ? <Loading /> : null}
            {rest?.footerTitle || rest?.footerSubTitle ? (
              <View position="absolute" width="100%" bottom={0}>
                <View position="absolute" height={300} width="100%" bottom={-1}>
                  <GradientBackground
                    backgroundOpacity={1}
                    gradientColors={gradientColors}
                    flex={1}
                    id={`heading_subheading_${id}`}
                  />
                </View>
                <View bottom={60}>
                  {rest?.footerTitle ? (
                    <Text
                      size="2xl"
                      weight="medium"
                      color="primary.500"
                      textAlign="center"
                      px="2xl"
                      mb="lg"
                      numberOfLines={1}
                      {...rest?.footerTitleStyle}
                    >
                      {rest?.footerTitle}
                    </Text>
                  ) : null}
                  {rest?.footerSubTitle ? (
                    <Text
                      size="md"
                      textAlign="center"
                      px="2xl"
                      color="primary.500"
                      numberOfLines={2}
                      {...rest?.footerSubTitleStyle}
                    >
                      {rest?.footerSubTitle}
                    </Text>
                  ) : null}
                </View>
              </View>
            ) : null}
          </View>
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

export default VideoPlayer;
