/* eslint-disable indent */
import { graphql, fetchQuery, commitMutation } from 'react-relay';
import _ from 'lodash';
import environment from './relayEnvironment';

export interface CheckUserData {
  phone?: {
    prefix: string;
    number: string;
  };
  waId?: string;
}

export const checkUser = (data: CheckUserData) => {
  const query = graphql`
    query authApiQuery($filter: checkUserSafeFilterInput!) {
      checkUser(filter: $filter) {
        _id
        name {
          first
          last
        }
        contact {
          phone {
            number
            prefix
          }
        }
        firstLogin
      }
    }
  `;

  const filteredQuery = (queryData) => {
    if (queryData?.waId && queryData.waId !== '') {
      return {
        waId: queryData?.waId,
      };
    }
    return {
      contact: {
        phone: queryData.phone,
      },
    };
  };
  // const variables = {
  //   filter: {
  //     contact: {
  //       phone: data.phone,
  //     },
  //   },
  // };
  const variables = {
    filter: filteredQuery(data),
  };
  return fetchQuery(environment, query, variables).toPromise();
};

export const generateOTP = (data, callback, optionalData = {}) => {
  const mutation = graphql`
    mutation authApiMutation($record: OtpRecordInput!) {
      generateOTP(record: $record) {
        recordId
      }
    }
  `;

  const variables = {
    record: {
      contact: {
        phone: data.phone,
      },
      resendOTPCount: data?.resendOTPCount || 0,
    },
  };
  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response, data, optionalData);
    },
    onError: (err) => {
      callback(err);
      console.error(err);
    },
  });
};
export const loginUser = (data, callback, errCallback) => {
  const mutation = graphql`
    mutation authApiLoginMutation(
      $filter: loginFilterInput!
      $record: loginRecordInput!
    ) {
      loginUser(filter: $filter, record: $record) {
        record {
          _id
          token
          refreshToken
          typeForm {
            landedAt
            submittedAt
          }
          contact {
            phone {
              prefix
              number
            }
            email {
              addr
            }
          }
          role {
            name
            permission
            secondaryRole
            meta {
              campus {
                _id
              }
            }
          }
          name {
            first
            last
          }
          firstLoginParkingReward
          profileImage
          photoMomentsSearchImage
        }
      }
    }
  `;
  const variables = {
    record: {
      name: data.name || {},
    },
    filter: {
      contact: {
        phone: data.phone,
      },
      pin: data.pin,
      waId: data.waId ? data.waId : '',
    },
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      console.error(err);
      errCallback(err);
    },
  });
};

export const addUser = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation authApiAddUserMutation($record: addUserRecordInput!) {
      addUser(record: $record) {
        record {
          _id
          token
          role {
            name
          }
        }
      }
    }
  `;

  const variables = {
    record: {
      name: data.name,
      contact: {
        phone: data.phone,
      },
      pin: data.pin,
      waId: data.waId ? data.waId : '',
    },
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      console.error(err);
      errorCallback(err);
    },
  });
};

export interface LoginUserPayload {
  name?: { first: string; last?: string };
  contact: {
    phone: { number: string; prefix: string };
  };
  pin: string;
  waId?: string;
}

export const loginUserV2 = async (data: LoginUserPayload) => {
  const name = { name: _.cloneDeep(data?.name) };
  delete data?.name;

  const mutation = graphql`
    mutation authApiLoginV2Mutation(
      $filter: loginFilterInput!
      $record: loginRecordInput!
    ) {
      loginUser(filter: $filter, record: $record) {
        record {
          _id
          token
          refreshToken
          typeForm {
            landedAt
            submittedAt
          }
          contact {
            phone {
              prefix
              number
            }
            email {
              addr
            }
          }
          role {
            name
            permission
            secondaryRole
            meta {
              campus {
                _id
              }
            }
          }
          name {
            first
            last
          }
          firstLoginParkingReward
          profileImage
          photoMomentsSearchImage
        }
      }
    }
  `;

  return new Promise((resolve, reject) => {
    const variables = {
      record: name || {},
      filter: data,
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, error) => {
        resolve(response);
      },
      onError: (err) => {
        reject(err);
      },
    });
  });
};

export interface AddUserPayload {
  name: { first: string; last: string };
  contact: {
    phone: { number: string; prefix: string };
  };
  pin: string;
  waId?: string;
}

export const addUserV2 = (data: AddUserPayload) => {
  const mutation = graphql`
    mutation authApiAddUserV2Mutation($record: addUserRecordInput!) {
      addUser(record: $record) {
        record {
          _id
          token
          role {
            name
          }
        }
      }
    }
  `;

  return new Promise((resolve, reject) => {
    const variables = {
      record: data,
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, error) => {
        if (error) {
          reject(error);
        }
        resolve(response);
      },
      onError: (err) => {
        reject(err);
      },
    });
  });
};
