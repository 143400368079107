import React, { PropsWithChildren } from 'react';
import View from '../../basic/View/View';

type BonusBadgeProps = PropsWithChildren<{
  variant: 'gain' | 'loss';
}>;

const BonusBadge = ({ variant, children }: BonusBadgeProps) => {
  const bg = variant === 'gain' ? 'success.10' : 'error.10';

  return (
    <View
      px="lg"
      py="sm"
      bg={bg}
      borderRadius="md"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </View>
  );
};

export default BonusBadge;
