import React from 'react';
import moment from 'moment';
import { Pressable, Text, View } from '../../../components/new';
import { space } from '../../../themes/new/theme';

const DateTimeSlots = ({
  data,
  selectedDateIndex = 0,
  setSelectedDateIndex,
  curIndex,
}) => {
  const isSelected = curIndex === selectedDateIndex;

  return (
    <Pressable onPress={setSelectedDateIndex}>
      <View
        borderWidth={1}
        borderColor={isSelected ? 'primary.200' : 'primary.20'}
        borderRadius="md"
        py="lg"
        justifyContent="center"
        alignItems="center"
        width={space['9xl+4xl']}
        mr="2xl"
        bg={isSelected ? 'primary.500' : 'background.primary.elevation'}
      >
        <Text
          size="sm"
          color={isSelected ? 'primary.10' : 'primary.300'}
          mb="sm"
        >
          {moment(data[0]?.date).format('ddd')}
        </Text>
        <Text
          size="xl"
          color={isSelected ? 'primary.10' : 'primary.300'}
          mb="sm"
        >
          {moment(data[0]?.date).format('DD')}
        </Text>
        <Text size="2xs" color="primary.200">
          {moment(data[0]?.date).format('MMM')}
        </Text>
      </View>
    </Pressable>
  );
};

export default DateTimeSlots;
