// Load the required clients and packages
import { updateUser } from '../relay/userApi';
import useUserStore from '../stores/userStore';
import AppConstants from './AppConstants';

const S3Delete = async (key, userId, refresh) => {
  const { API_URL } = AppConstants;
  let env;
  if (API_URL === 'http://localhost:8000/graphql') {
    env = 'prod';
  } else {
    env = 'other';
  }

  if (key !== undefined) {
    const handleUpdateUser = (response, err) => {
      if (response.updateUser !== null) {
        useUserStore
          .getState()
          .setProfileImage(response?.updateUser?.record?.profileImage);
        refresh();
      } else {
        // console.log(err);
      }
    };

    try {
      // const data = await s3.send(new DeleteObjectCommand(bucketParams));
      const userData = {};
      (userData as any).id = userId;
      (userData as any).record = {};
      (userData as any).record.profileImage = '';
      updateUser(userData, handleUpdateUser, (err) => {});
    } catch (err) {
      // console.log('There was an error deleting your photo: ', err.message);
    }
  }
};

export default S3Delete;
