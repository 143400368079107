import React, { useCallback, useEffect, useState } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Pressable, View, Text, Divider, Icon } from '../../../components/new';

const config = {
  duration: 200,
  easing: Easing.bezier(0.17, 0.67, 0.83, 0.67),
  useNativeDriver: true,
};

interface TrackDiscountCardProps {
  discount: number;
  active: boolean;
  subTitle?: string;
  discountForNewUsers?: number;
  discountForCorporate?: number;
  grossSales?: number;
  totalOrders?: number;
  discountsGiven?: number;
  discountPercent?: number;
  onPress: () => void;
}

const TrackDiscountCard = ({
  discount = 0,
  active = false,
  subTitle = '',
  discountForNewUsers = 0,
  discountForCorporate = 0,
  grossSales = 0,
  totalOrders = 0,
  discountsGiven = 0,
  discountPercent = 0,
  onPress = () => {},
}: TrackDiscountCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [cardHeightExpanded, setCardHeightExpanded] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);
  const [expandedContentHeight, setExpandedContentHeight] = useState(0);

  useEffect(() => {
    if (
      cardHeightExpanded !== 0 &&
      expandedContentHeight !== 0 &&
      !isExpanded
    ) {
      setCardHeight(cardHeightExpanded - (expandedContentHeight + 16));
    }
  }, [cardHeightExpanded, expandedContentHeight]);

  const heightValue = useSharedValue(0);

  const style = useAnimatedStyle(
    () => ({
      maxHeight: withTiming(heightValue.value, config),
    }),
    [cardHeightExpanded, expandedContentHeight, cardHeight],
  );

  useEffect(() => {
    if (isExpanded) {
      heightValue.value = cardHeightExpanded + 100;
    } else {
      heightValue.value = 0;
    }
  }, [isExpanded]);

  const onLayout = useCallback((event) => {
    setCardHeightExpanded(event?.nativeEvent?.layout?.height);
  }, []);

  return (
    <Pressable
      onPress={() => {
        setIsExpanded(!isExpanded);
        onPress();
      }}
    >
      <View
        bg="background.primary.elevation"
        p="2xl"
        borderRadius="md"
        borderWidth="xs"
        borderColor="primary.50"
        shadow="md"
        onLayout={onLayout}
      >
        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <View flexDirection="row" alignItems="center">
            <Text size="lg" weight="medium" color="primary.400">
              {`${discount}% Off`}
            </Text>
            <View flexDirection="row" alignItems="center" ml="lg">
              <View
                width="3xs"
                height="3xs"
                bg={active ? 'success.500' : 'error.500'}
                borderRadius="full"
              />
              <Text
                size="xs"
                color={active ? 'success.500' : 'error.500'}
                ml="sm"
              >
                {active ? 'Active' : 'Inactive'}
              </Text>
            </View>
          </View>
          <Icon
            name={isExpanded ? 'expand-less-outline-300' : 'expand-outline-300'}
            size="2xl"
            color="primary.500"
          />
        </View>
        <Text size="sm" color="primary.200" mt="sm">
          {subTitle}
        </Text>
        <View mt="sm" flexDirection="row" alignItems="center">
          <View
            borderWidth="xs"
            borderRadius="md"
            borderColor="primary.50"
            px="lg"
            py="sm"
          >
            <Text size="xs" color={active ? 'success.500' : 'primary.200'}>
              {`+ ${discountForNewUsers}% `}
              <Text color="primary.200">for new members</Text>
            </Text>
          </View>
          <View
            borderWidth="xs"
            borderRadius="md"
            borderColor="primary.50"
            ml="lg"
            px="lg"
            py="sm"
          >
            <Text size="xs" color={active ? 'success.500' : 'primary.200'}>
              {`+ ${discountForCorporate}% `}
              <Text color="primary.200">for corporates</Text>
            </Text>
          </View>
        </View>
        <Animated.View
          style={[
            style,
            {
              // borderRadius: getTheme().radii.md,
              // borderWidth: getTheme().borderWidths.xs,
              // borderColor: 'transparent',
              overflow: 'hidden',
            },
          ]}
        >
          <View
            onLayout={(event) => {
              setExpandedContentHeight(event?.nativeEvent?.layout?.height);
            }}
          >
            <View mt="2xl">
              <Divider level={1} />
            </View>
            <View
              mt="2xl"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <View>
                <Text
                  size="xl"
                  weight="medium"
                  color="primary.400"
                >{`₹ ${grossSales}`}</Text>
                <Text mt="xs" size="xs" color="primary.100">
                  Gross Sales
                </Text>
              </View>
              <View alignItems="flex-end">
                <Text
                  size="xl"
                  weight="medium"
                  color="primary.400"
                >{`₹ ${discountsGiven}`}</Text>
                <Text mt="xs" size="xs" color="primary.100">
                  Discount Given
                </Text>
              </View>
            </View>
            <View
              mt="2xl"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <View>
                <Text
                  size="xl"
                  weight="medium"
                  color="primary.400"
                >{`${totalOrders}`}</Text>
                <Text mt="xs" size="xs" color="primary.100">
                  Total Orders
                </Text>
              </View>
              <View alignItems="flex-end">
                <Text
                  size="xl"
                  weight="medium"
                  color="primary.400"
                >{`${discountPercent}%`}</Text>
                <Text mt="xs" size="xs" color="primary.100">
                  Discount Percent
                </Text>
              </View>
            </View>
          </View>
        </Animated.View>
      </View>
    </Pressable>
  );
};

export default TrackDiscountCard;
