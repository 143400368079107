/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState } from 'react';
import { ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Button, CheckBox, Text, View } from '../../../components/new';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { getTheme } from '../../../themes/new/theme';
import SliderSection from './SliderSection';
import {
  Appearance,
  Directions,
  State,
  Status,
} from '../../../themes/new/helper';
import CorporateBottomSheet from './CorporateBottomSheet';
import { createDiscount } from './API/discountApi';
import { navigateBack } from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { useCreateDiscount } from './useCreateDiscount';
import useRestaurantPartnerStore from '../../../stores/restaurantpartner/restaurantPartnerStore';
import { isValueNullOrEmpty } from '../../../utilities/Utility';

const CreateDiscountScreenInner = ({
  queryOptions,
  refresh,
  discountId,
  data,
}: {
  queryOptions: NewErrorBoundaryParentState;
  refresh: () => void;
  discountId: string;
  data?: object;
}) => {
  const navigation = useNavigation();
  const { role } = useUserStore((state) => state);
  const [checkedTermsCondition, setCheckedTermsCondition] = useState(false);
  const coporateListBottomSheetRef = useRef(null);
  const handleOnDismiss = useCallback(() => {
    coporateListBottomSheetRef?.current?.hide();
  }, []);
  const handleInfoPress = useCallback(() => {
    coporateListBottomSheetRef?.current?.show();
  }, []);
  const { restaurantName } = useRestaurantPartnerStore((state) => state);
  const { getRestaurantpartnerDiscount, findConfig } = useCreateDiscount(
    queryOptions,
    refresh,
    discountId || data?.discountId,
  );

  const { days, mealTime } = getRestaurantpartnerDiscount[0] ?? {};
  const { corporateConfig } = findConfig ?? {};
  const {
    allUser: allUserDiscount,
    newUser: newUserDiscount,
    corporateUser: corporateDiscount,
  } = getRestaurantpartnerDiscount[0]?.discounts ?? {};
  const { days: daysFromProps, mealTime: mealTimeFromProps } = data ?? {};
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [defaultAllUserDiscount, setDefaultAllUserDiscount] = useState(
    allUserDiscount >= 0 ? allUserDiscount : null,
  );
  const [defaultNewUserDiscount, setDefaultNewUserDiscount] = useState(
    newUserDiscount >= 0 ? newUserDiscount : null,
  );
  const [defaultCorporateDiscount, setDefaultCorporateDiscount] = useState(
    corporateDiscount >= 0 ? corporateDiscount : null,
  );

  const [allUserDiscountError, setAllUserDiscountError] = useState('');
  const [newUserDiscountError, setNewUserDiscountError] = useState('');
  const [corporateUserDiscountError, setCorporateUserDiscountError] =
    useState('');

  const handleConfirmAndActivate = () => {
    setButtonDisabled(true);
    const createDiscountData = {};
    (createDiscountData as any).record = {};
    (createDiscountData as any).record.days = days || daysFromProps;
    (createDiscountData as any).record.mealTime = mealTime || mealTimeFromProps;
    (createDiscountData as any).record.discounts = {
      allUser: defaultAllUserDiscount,
      newUser: defaultNewUserDiscount,
      corporateUser: defaultCorporateDiscount,
    };
    createDiscount(
      createDiscountData,
      (res, err) => {
        setButtonDisabled(false);
        if (
          res &&
          res?.addRestaurantpartnerDiscount &&
          res?.addRestaurantpartnerDiscount?.record &&
          res?.addRestaurantpartnerDiscount?.record?._id
        ) {
          dispatchSnackbar({
            msg: 'Discount is created successfully!',
            status: SnackbarStatus.success,
            version: SnackbarVersion.v2,
          });
          navigateBack(navigation, role);
        }
      },
      (err) => {
        setButtonDisabled(false);
        if (err?.res?.errors[0]?.extensions?.code === '101') {
          dispatchSnackbar({
            msg: err?.res?.errors[0]?.message,
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
          });
        } else if (err?.res?.errors[0]?.extensions?.code === '102') {
          setNewUserDiscountError(err?.res?.errors[0]?.message);
        } else if (err?.res?.errors[0]?.extensions?.code === '103') {
          setCorporateUserDiscountError(err?.res?.errors[0]?.message);
        } else {
          dispatchSnackbar({
            msg: err?.res?.errors[0]?.message || 'Something Went Wrong!',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
          });
        }
      },
    );
  };

  const checkIfButtonDisabled = () => {
    if (checkedTermsCondition) {
      if (
        defaultAllUserDiscount === allUserDiscount &&
        defaultNewUserDiscount === newUserDiscount &&
        defaultCorporateDiscount === corporateDiscount
      ) {
        return true;
      }
      if (
        isValueNullOrEmpty(defaultAllUserDiscount) &&
        isValueNullOrEmpty(defaultNewUserDiscount) &&
        isValueNullOrEmpty(defaultCorporateDiscount)
      ) {
        return true;
      }
      if (buttonDisabled) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <View flex={1}>
      <ScrollView
        style={{
          paddingHorizontal: getTheme().space['2xl'],
          marginTop: getTheme().space['2xl'],
        }}
        scrollIndicatorInsets={{ right: 16 }}
      >
        <View>
          <SliderSection
            title="Discount for all members"
            sliderMinValue={0}
            sliderMaxValue={50}
            sliderStep={5}
            sliderStepToShow={10}
            defaultMinValue={defaultAllUserDiscount}
            showError={allUserDiscountError?.length}
            onSlidingComplete={(val) => {
              setDefaultAllUserDiscount(val);
              setAllUserDiscountError('');
              setNewUserDiscountError('');
              setCorporateUserDiscountError('');
            }}
            errorMsg={allUserDiscountError}
          />
        </View>
        <View mt="2xl">
          <SliderSection
            title="Discount for new members"
            sliderMinValue={0}
            sliderMaxValue={50}
            sliderStep={5}
            sliderStepToShow={10}
            defaultMinValue={defaultNewUserDiscount}
            onSlidingComplete={(val) => {
              setDefaultNewUserDiscount(val);
              setAllUserDiscountError('');
              setNewUserDiscountError('');
              setCorporateUserDiscountError('');
            }}
            showError={newUserDiscountError?.length}
            errorMsg={newUserDiscountError}
          />
        </View>
        <View mt="2xl">
          <SliderSection
            title="Discount for corporate employees"
            sliderMinValue={0}
            sliderMaxValue={50}
            sliderStep={5}
            sliderStepToShow={10}
            defaultMinValue={defaultCorporateDiscount}
            showInfo
            onSlidingComplete={(val) => {
              setDefaultCorporateDiscount(val);
              setAllUserDiscountError('');
              setNewUserDiscountError('');
              setCorporateUserDiscountError('');
            }}
            onPressInfo={handleInfoPress}
            showError={corporateUserDiscountError?.length}
            errorMsg={corporateUserDiscountError}
          />
        </View>
        <View mt="4xl">
          <Text size="md" color="primary.300">
            Offer Details
          </Text>
          <View flexDirection="row" alignItems="flex-start">
            <Text size="sm" color="primary.100" mt="lg">
              1.
            </Text>
            <Text size="sm" color="primary.100" mt="lg" ml="sm">
              Discounts are applicable between 6:00 AM to 6:00 PM from Mon- Thu
              on all menu items
            </Text>
          </View>
          <View flexDirection="row" alignItems="flex-start">
            <Text size="sm" color="primary.100">
              2.
            </Text>
            <Text size="sm" color="primary.100" ml="sm">
              Valid at : {restaurantName}, 32nd Avenue, Gurugram
            </Text>
          </View>
        </View>
        <View height={200} />
      </ScrollView>
      <View
        position="absolute"
        bottom={0}
        shadow="sm"
        bg="background.primary.elevation"
        p="2xl"
        width="100%"
        borderTopLeftRadius="md"
        borderTopRightRadius="md"
      >
        <CheckBox
          size="sm"
          icon={Directions.LEFT}
          checked={checkedTermsCondition}
          onPress={() => setCheckedTermsCondition(!checkedTermsCondition)}
          state={State.ACTIVE}
        >
          I have read and accept the terms and conditions
        </CheckBox>
        <Button
          size="lg"
          status={Status.PRIMARY}
          appearance={Appearance.FILLED}
          state={checkIfButtonDisabled() ? State.DISABLED : State.ACTIVE}
          my="2xl"
          onPress={handleConfirmAndActivate}
          loading={buttonDisabled}
        >
          Confirm & Activate
        </Button>
      </View>
      <CorporateBottomSheet
        bottomSheetModalRef={coporateListBottomSheetRef}
        data={corporateConfig}
      />
    </View>
  );
};

export default CreateDiscountScreenInner;
