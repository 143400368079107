import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  Dimensions,
  Platform,
  Pressable,
  useWindowDimensions,
} from 'react-native';
import {
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from 'react-relay';
import Animated, {
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import _ from 'lodash';
import { ActionSheetRef, FlatList } from 'react-native-actions-sheet';
import {
  Avatar,
  Button,
  Input,
  Layout,
  Text,
  View,
} from '../../../components/new';
import useUserStore from '../../../stores/userStore';
import {
  BottomSheetFilterData,
  BottomSheetFilterDataType,
  constructExtraDataFromSheetFilters,
  getExtraFilterState,
  getFiltersArrayInitialState,
  getQueryFiltersForData,
  RestaurantFilter,
  RestaurantsFilterNames,
} from './resturantsData';
import RestaurantChipFilter from './restaurantChipFilter';
import NewErrorView from '../../../utilities/NewErrorView';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import { RestaurantScreenPlaceholder } from '../shimmerPlaceholders';
import {
  restaurantListingFindUserFragment,
  restaurantListingFragment,
  restaurantListingQuery,
  restaurantListingFindCashbackFragment,
} from './restaurantListingQuery';
import useCommonStore from '../../../stores/commonStore';
import useAuthStore from '../../../stores/authStore';
import EventBus, { EventBusEventsEnum } from '../../../utilities/EventBus';
import RestaurantListEmptyPlaceholder from './restaurantListEmptyPlaceholder';
import Divider from '../../../components/new/primitive/Divider/Divider';
import theme, { getTheme } from '../../../themes/new/theme';
import RenderCheckbox from './renderCheckbox';
import AppConstants from '../../../utilities/AppConstants';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { Loading } from '../../../components';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import PaginationLoader from '../../../components/new/custom/PaginationLoader';
import { useNodeFromPagination } from '../../../hooks/useNodeFromPagination';
import RenderItem from './renderItem';
import { dynamicHeightMaker } from '../../../utilities/helper';

const RESTAURANT_PAGE_SIZE = 5;

const Listing = ({
  refreshedQueryOptions,
  queryFilters,
  refresh,
  onScroll,
}) => {
  const navigation = useNavigation<any>();
  const ref = useRef<any>();
  const { width } = useWindowDimensions();
  const [viewableItem, setViewableItem] = useState('');
  const viewabilityConfigRef = useRef({
    itemVisiblePercentThreshold: 100,
    minimumViewTime: 50,
  });

  const onViewableItemsChangedCallback = useCallback(({ viewableItems }) => {
    if (viewableItems[0]?.isViewable) {
      setViewableItem(viewableItems[0]?.key);
    }
  }, []);

  const { ...findRestaurantRef } = useLazyLoadQuery(
    restaurantListingQuery,
    queryFilters,
    {
      fetchKey: refreshedQueryOptions.fetchKey,
      fetchPolicy: refreshedQueryOptions.fetchPolicy,
    },
  );

  /* ========== PARKING HISTORY VIA PAGINATION ========== */
  const {
    data: restaurantListingNodes,
    loadNext: fetchMoreParkingHistory,
    hasNext: hasMoreRestaurants,
  } = usePaginationFragment(restaurantListingFragment, findRestaurantRef);

  // extracting node out of edges
  const collection = useNodeFromPagination(
    restaurantListingNodes?.findRestaurants2V2,
  );

  const userWallet = useFragment(
    restaurantListingFindUserFragment,
    findRestaurantRef?.findUserById,
  );

  const config = useFragment(
    restaurantListingFindCashbackFragment,
    findRestaurantRef?.findConfig,
  );

  useEffect(() => {
    EventBus.getInstance().on(EventBusEventsEnum.SCROLL_TO_TOP_TAB_2, () => {
      ref?.current?.scrollToOffset({ animated: true, offset: 0 });
    });
  }, []);

  const ITEM_HEIGHT = dynamicHeightMaker(width - getTheme().space['7xl'], 0.8);

  return (
    <View px="2xl" flex={1}>
      <Animated.FlatList
        ref={ref}
        data={collection}
        onScroll={onScroll}
        bounces={false}
        scrollEventThrottle={Platform.OS === 'web' ? 500 : 24}
        contentContainerStyle={{
          paddingBottom: getTheme().space['4xl'],
        }}
        // @ts-ignore
        keyExtractor={(item) => item?._id}
        renderItem={(item, index) =>
          RenderItem({ item, userWallet, navigation, viewableItem, config })
        }
        horizontal={false}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={<RestaurantListEmptyPlaceholder />}
        initialNumToRender={5}
        windowSize={10}
        onEndReachedThreshold={0.8}
        getItemLayout={(data, index) => ({
          length: ITEM_HEIGHT,
          offset: ITEM_HEIGHT * index,
          index,
        })}
        directionalLockEnabled
        onEndReached={() => {
          if (hasMoreRestaurants) {
            fetchMoreParkingHistory(RESTAURANT_PAGE_SIZE);
          }
        }}
        viewabilityConfig={viewabilityConfigRef.current}
        onViewableItemsChanged={onViewableItemsChangedCallback}
        ListFooterComponent={() => {
          if (hasMoreRestaurants)
            return (
              <View pt="9xl">
                <PaginationLoader />
              </View>
            );
          return null;
        }}
      />
    </View>
  );
};

const RestaurantListing = ({ route }) => {
  const isMounted = useRef(false);
  const navigation = useNavigation<any>();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const campusID = useCommonStore((state) => state.campusId);
  const { id, profileImage, firstName, lastName, blackMembership } =
    useUserStore((state) => state);
  const { active: isBlackMember } = blackMembership;
  const [queryFiltersInitialState] = useState<any>({
    filter: { campus: { _id: campusID } },
    includeFindUser: isLoggedIn,
    sort: 'DISCOUNT_DESC',
    id,
    count: RESTAURANT_PAGE_SIZE,
  });
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const filterFromNavigation = route?.params?.filter;

  const screenHeight = Dimensions.get('window').height;
  const maxHeightPercentage = 0.9;

  useEffect(() => {
    if (filterFromNavigation) {
      const filters = getFiltersArrayInitialState(filterFromNavigation);
      setFilters(filters);
      setQueryFilters({
        ...queryFiltersInitialState,
        filter: getQueryFiltersForData(campusID, filters, extraFilterData),
      });
    }
    if (isMounted.current) {
      setTimeout(() => {
        refresh();
      }, 200);
    }
  }, [filterFromNavigation]);

  useEffect(() => {
    // ComponentDidMount
    isMounted.current = true;
  }, []);

  const calculateMaxHeight = () => {
    return Math.round(screenHeight * maxHeightPercentage);
  };

  const [filters, setFilters] = useState<RestaurantFilter[]>(
    getFiltersArrayInitialState(filterFromNavigation),
  );

  const [extraFilterData, setExtraFilterData] = useState<
    Record<string, string[]>
  >({});

  const [queryFilters, setQueryFilters] = useState<any>(
    filterFromNavigation
      ? {
          ...queryFiltersInitialState,
          filter: getQueryFiltersForData(campusID, filters, extraFilterData),
        }
      : { ...queryFiltersInitialState },
  );

  const [bottomSheetFilterData, setBottomSheetFilterData] = useState<
    Record<string, BottomSheetFilterDataType>
  >(_.cloneDeep(BottomSheetFilterData));

  const [activeBottomSheetFilterData, setActiveBottomSheetFilterData] =
    useState<BottomSheetFilterDataType>({
      dataArr: [],
      referenceFilterName: '',
      sheetTitle: '',
    });

  const [extraFilterCurrentState, setExtraFilterCurrentState] =
    useState<string>('');
  const [extraFilterPrevState, setExtraFilterPrevState] = useState<string>('');

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: Platform.OS === 'web' ? 'network-only' : 'store-and-network',
    });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  }, []);

  const { IMAGEKIT_PROD_URI } = AppConstants;

  const imageLink = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';

  const navigateToSearchScreen = useCallback(() => {
    firebaseEventLogger('SearchBarClicked');
    navigation.navigate('newSearchScreen');
  }, []);

  const handleFilterOnPress = (
    name: RestaurantsFilterNames,
    filterKey: string,
  ) => {
    navigation.setParams({ filter: undefined });
    firebaseEventLogger('restaurantsListingFilterPress', {
      filterName: name,
    });
    let openBottomSheet = false;
    filters.forEach((filter) => {
      if (filter.name === name && !isNaN(filter.itemsSelected)) {
        openBottomSheet = true;
      }
    });
    if (openBottomSheet) {
      const extraFilterState = getExtraFilterState(
        bottomSheetFilterData[filterKey].dataArr,
      );
      setExtraFilterPrevState(extraFilterState);
      setExtraFilterCurrentState(extraFilterState);
      setActiveBottomSheetFilterData(bottomSheetFilterData[filterKey]);
      bottomSheetModalRef?.current?.show();
      return;
    }
    if (name === RestaurantsFilterNames.ALL) {
      setExtraFilterData({});
      setQueryFilters(queryFiltersInitialState);
      const newFilters = filters.map((filter) => {
        if (filter.name === RestaurantsFilterNames.ALL) {
          return {
            ...filter,
            active: true,
          };
        }
        return {
          ...filter,
          active: false,
          itemsSelected: isNaN(filter.itemsSelected) ? undefined : 0,
        };
      });
      setBottomSheetFilterData(_.cloneDeep(BottomSheetFilterData));
      setFilters(newFilters);
      refresh();
      return;
    }
    const newFilters = filters.map((filter) => {
      if (filter.name === RestaurantsFilterNames.ALL) {
        return {
          ...filter,
          active: false,
        };
      }
      if (filter.name === name) {
        return {
          ...filter,
          active: !filter.active,
        };
      }
      return filter;
    });
    const firstEle = newFilters.shift();
    newFilters.sort((a, b) => {
      if (a.active && b.active) return 0;
      if (a.active && !b.active) return -1;
      if (!a.active && b.active) return 1;
    });
    newFilters.unshift(firstEle);
    const activeCount = newFilters.reduce((accumulator, currentValue) => {
      if (currentValue.active) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    if (activeCount === 0) {
      newFilters[0].active = true;
    }
    setFilters(newFilters);

    const queryFiltersNew = getQueryFiltersForData(
      campusID,
      newFilters,
      extraFilterData,
    );
    setQueryFilters({
      ...queryFiltersInitialState,
      filter: queryFiltersNew,
    });
    refresh();
    EventBus.emitEvent(EventBusEventsEnum.RESTAURANT_PAGE_FILTER_SCROLL, null);
  };

  const handleOnDismiss = () => {
    setExtraFilterCurrentState('');
    setExtraFilterPrevState('');
  };

  const handleExtraFilterClicked = (value: string) => {
    const newBottomSheetFilterDataArr = activeBottomSheetFilterData.dataArr.map(
      (filter) => {
        if (value === filter.value) {
          return {
            ...filter,
            isChecked: !filter.isChecked,
          };
        }
        return filter;
      },
    );
    const extraFilterState = getExtraFilterState(newBottomSheetFilterDataArr);
    setExtraFilterCurrentState(extraFilterState);
    setActiveBottomSheetFilterData((prevState) => ({
      ...prevState,
      dataArr: newBottomSheetFilterDataArr,
    }));
  };

  const handleClearExtraFilterClicked = () => {
    const newBottomSheetFilterDataArr = activeBottomSheetFilterData.dataArr.map(
      (filter) => {
        return { ...filter, isChecked: false };
      },
    );
    const extraFilterState = getExtraFilterState(newBottomSheetFilterDataArr);
    setExtraFilterCurrentState(extraFilterState);
    setActiveBottomSheetFilterData((prevState) => ({
      ...prevState,
      dataArr: newBottomSheetFilterDataArr,
    }));
  };

  useEffect(() => {
    firebaseEventLogger('restaurantsListingPageLanding');
  }, []);

  const handleApplyExtraFilters = () => {
    const data = constructExtraDataFromSheetFilters(
      activeBottomSheetFilterData,
    );
    const filterName = activeBottomSheetFilterData.referenceFilterName;
    const extraFiltersData = {
      ...extraFilterData,
      ...data,
    };

    setExtraFilterData(extraFiltersData);
    const updatedBottomSheetData = {
      ...bottomSheetFilterData,
    };
    updatedBottomSheetData[filterName] = {
      ...activeBottomSheetFilterData,
    };
    setBottomSheetFilterData(updatedBottomSheetData);

    const newFilters = filters.map((filter) => {
      if (filter.filterKey === filterName) {
        return {
          ...filter,
          active: data[filterName].length > 0,
          itemsSelected: data[filterName].length,
        };
      }
      return filter;
    });
    const firstEle = newFilters.shift();
    newFilters.sort((a, b) => {
      if (a.active && b.active) return 0;
      if (a.active && !b.active) return -1;
      if (!a.active && b.active) return 1;
    });
    newFilters.unshift(firstEle);
    const activeCount = newFilters.reduce((accumulator, currentValue) => {
      if (currentValue.active) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    if (activeCount === 0) {
      newFilters[0].active = true;
    } else {
      newFilters[0].active = false;
    }
    setFilters(newFilters);

    const queryFiltersNew = getQueryFiltersForData(
      campusID,
      newFilters,
      extraFiltersData,
    );
    setQueryFilters({
      ...queryFiltersInitialState,
      filter: queryFiltersNew,
    });
    refresh();
    bottomSheetModalRef?.current?.hide();
    EventBus.emitEvent(EventBusEventsEnum.RESTAURANT_PAGE_FILTER_SCROLL, null);
  };

  const opacity = useSharedValue(1);
  const height = useSharedValue(70);

  const scrollThreshold = 120;
  const lastScrollPosition = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      try {
        if (event.contentOffset.y <= 0) {
          height.value = withTiming(70);
          opacity.value = withTiming(1);
          return;
        }

        const deltaY = event?.contentOffset?.y - lastScrollPosition?.value;

        if (Math.abs(deltaY) > scrollThreshold) {
          if (deltaY < 0) {
            height.value = withTiming(70);
            opacity.value = withTiming(1);
          } else {
            height.value = withTiming(8);
            opacity.value = withTiming(0.01);
          }
          lastScrollPosition.value = event?.contentOffset?.y;
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const showHideStyle = useAnimatedStyle(() => {
    return {
      height: height.value,
      opacity: opacity.value,
    };
  });

  const insets = useSafeAreaInsets();

  return (
    <Layout level={2}>
      <View
        zIndex={2}
        mt={insets.top + getTheme().space.lg}
        borderBottomWidth="xs"
        borderColor="grey.50"
      >
        <View px="2xl" flexDirection="row">
          <View flex={1}>
            <Input
              rightIconName="search-outline-400"
              placeholder="Search restaurant..."
              onFocus={navigateToSearchScreen}
              onRightIconClick={navigateToSearchScreen}
              onPressMiddleArea={navigateToSearchScreen}
            />
          </View>
          <Pressable
            onPress={() => {
              firebaseEventLogger('account_avatar_clicked');
              navigation.navigate('account');
            }}
            hitSlop={{
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            }}
          >
            <View
              ml="lg"
              borderWidth="sm"
              borderColor={isBlackMember ? 'static.gold' : 'transparent'}
              borderRadius="full"
            >
              <Avatar
                level={1}
                source={imageLink}
                name={`${firstName ?? ''} ${lastName ?? ''}`}
                resizeMode="cover"
              />
            </View>
          </Pressable>
        </View>
        <Animated.View style={showHideStyle}>
          <RestaurantChipFilter data={filters} onPress={handleFilterOnPress} />
        </Animated.View>
      </View>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense
          fallback={
            Platform.OS === 'web' ? (
              <Loading />
            ) : (
              <RestaurantScreenPlaceholder />
            )
          }
        >
          <Listing
            refreshedQueryOptions={refreshedQueryOptions}
            queryFilters={queryFilters}
            refresh={refresh}
            onScroll={scrollHandler}
          />
        </Suspense>
      </NewErrorBoundary>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View style={{ maxHeight: calculateMaxHeight() }}>
          <View flexDirection="column">
            <View
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              px="2xl"
            >
              <Text size="xl" color="primary.200">
                {activeBottomSheetFilterData?.sheetTitle}
              </Text>
              <Button
                appearance="ghost"
                status="info"
                size="md"
                onPress={handleClearExtraFilterClicked}
              >
                Clear All
              </Button>
            </View>
            <View px="2xl" my="lg">
              <Divider level={1} />
            </View>
            <FlatList
              style={{
                maxHeight: Dimensions.get('screen').height * 0.65,
              }}
              contentContainerStyle={{
                paddingHorizontal: theme.space['2xl'],
                paddingTop: theme.space['2xl'],
              }}
              data={activeBottomSheetFilterData.dataArr}
              renderItem={(item) => {
                return (
                  <RenderCheckbox
                    name={item.item.name}
                    value={item.item.value}
                    isChecked={item.item.isChecked}
                    onPress={handleExtraFilterClicked}
                  />
                );
              }}
            />
            <View px="2xl" mt="xl">
              <Button
                size="lg"
                appearance="filled"
                state={
                  extraFilterCurrentState === extraFilterPrevState
                    ? 'disabled'
                    : 'active'
                }
                onPress={handleApplyExtraFilters}
              >
                Apply
              </Button>
            </View>
          </View>
        </View>
      </BottomSheetV2>
    </Layout>
  );
};

export default RestaurantListing;
