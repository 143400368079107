import React from 'react';
import styled from 'styled-components/native';
import {
  // ScrollView,
  TouchableWithoutFeedback,
  // TouchableOpacity,
  Pressable,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import View from '../../basic/View/View';
import BlurView from '../../basic/BlurView/BlurView';
import { colors } from '../../../../themes/new/theme';
import IconButton from '../../primitive/IconButton/IconButton';

const StyledModal = styled.Modal``;

const Modal = ({ children, ...rest }) => {
  const {
    onBackdropPress,
    bg,
    placement,
    handleClose = () => {},
    showBottomCloseButton = false,
  } = rest;
  const insets = useSafeAreaInsets();

  // const backdropHandler = (e) => {
  //   if (e.target === e.currentTarget) {
  //     onBackdropPress();
  //   }
  // };

  const setPosition = () => {
    switch (placement) {
      case 'top':
        return {
          alignItems: 'center',
        };
      case 'bottom':
        return {
          justifyContent: 'flex-end',
          alignItems: 'center',
        };
      case 'left':
        return {
          justifyContent: 'center',
          alignItems: 'flex-start',
        };
      case 'right':
        return {
          justifyContent: 'center',
          alignItems: 'flex-end',
        };
      default:
        return {
          justifyContent: 'center',
          alignItems: 'center',
        };
    }
  };

  return (
    <StyledModal {...rest} animationType="fade" transparent>
      <TouchableWithoutFeedback onPressOut={onBackdropPress}>
        {bg === 'blur' ? (
          <BlurView
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            level="backdrop"
          />
        ) : (
          <View
            bg={bg || colors.modes.dark.opacity[80]}
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
          />
        )}
      </TouchableWithoutFeedback>
      <View
        flex={1}
        pt={insets.top}
        pb={insets.bottom}
        pl={insets.left}
        pr={insets.right}
        // {...setPosition()}
      >
        {/* TODO: remove style */}
        <Pressable
          style={{
            flex: 1,
            width: '100%',
            justifyContent: 'center',
          }}
          onPress={onBackdropPress}
        >
          <View {...setPosition()}>
            {children}
            <>
              {showBottomCloseButton && (
                <View mt="2xl" width="90%" alignItems="center">
                  <Pressable onPress={() => handleClose()}>
                    <IconButton
                      name="close-outline-300"
                      size="xl"
                      bg="primary.500"
                      iconColor="primary.50"
                      onPress={() => handleClose()}
                    />
                  </Pressable>
                </View>
              )}
            </>
          </View>
        </Pressable>
      </View>
    </StyledModal>
  );
};

export default Modal;
