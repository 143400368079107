import React, { useCallback, useEffect, useRef } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { IconButton, Text, View } from '../new';
import useLoginModalStore, {
  LoginModalScreenSteps,
} from '../../stores/loginModalStore';
import EnterOtpScreen from '../AuthModalComponents/EnterOtpScreen';
import EnterPhoneNumber from '../AuthModalComponents/EnterPhoneNumber';
import SignupScreen from '../AuthModalComponents/SignupScreen';
import useAuthStore from '../../stores/authStore';
import useLoginFlowStore, {
  LoginFlowScreenSteps,
} from '../../stores/loginFlowStore';
import useCommonStore from '../../stores/commonStore';
import { themeModes } from '../../themes/new/theme';
import BottomSheetV2 from '../new/composites/BottomSheet/BottomSheetV2';
import ThemeWrapper, {
  ThemeWrapperType,
} from '../../screens/new/wrapperComp/ThemeWrapper';
import { calculateMaxHeight } from '../../utilities/helper';
import CustomLottieView from '../new/custom/CustomLottieView';

const AuthBottomSheetModal = () => {
  const componentState = useLoginModalStore((state) => state);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const redirectUri = useAuthStore((state) => state.redirectUri);
  const setRedirectUri = useAuthStore((state) => state.setRedirectUri);
  const authFlowState = useLoginFlowStore((state) => state);
  const commonStore = useCommonStore((state) => state);

  const bottomSheetModalRef = useRef(null);

  const handleOnDismiss = useCallback(() => {
    if (redirectUri.screenName) {
      setRedirectUri({ screenName: '', params: {} });
    }
    componentState.setIsOpen(false);
    componentState.setMobileNumber('');
    commonStore.setWasSignupFlowActive(false);
    componentState.setSignupPayload({
      name: { first: '', last: '' },
    });
    componentState.setWaId('');
    componentState.setSecondarySignup(false);
  }, []);

  const { height: screenHeight } = useWindowDimensions();
  const maxHeightPercentage = 0.85;

  useEffect(() => {
    if (componentState.isOpen) {
      bottomSheetModalRef.current?.show();
    } else {
      componentState.setLoginScreenTitle('Login or Sign Up');
      componentState.setWhatsappLoginEnabled(true);
      componentState.setSignUpScreenTitle('Sign Up');
      componentState.setAuthModalTheme('');
      componentState.setIsLoginWhatsappActive(false);
      bottomSheetModalRef?.current?.hide();
    }
  }, [componentState.isOpen]);

  useEffect(() => {
    if (isLoggedIn) {
      authFlowState.setMobileNumber('');
      authFlowState.setCurrentScreen(LoginFlowScreenSteps.SELECT_LOGIN_METHOD);
      authFlowState.setWaId('');
      authFlowState.setSignupPayload(null);
      componentState.setIsOpen(false);
      commonStore.setHasLoginSkipped(true);
    } else {
      authFlowState.setMobileNumber('');
      authFlowState.setCurrentScreen(LoginFlowScreenSteps.SELECT_LOGIN_METHOD);
      authFlowState.setWaId('');
      authFlowState.setSignupPayload(null);
    }
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return null;
  }

  return (
    <ThemeWrapper
      type={
        componentState.authModalTheme === themeModes.dark
          ? ThemeWrapperType.DARK
          : ThemeWrapperType.AUTO
      }
    >
      <BottomSheetV2
        ref={bottomSheetModalRef}
        onClose={handleOnDismiss}
        insets={false}
      >
        <View
          style={{
            height:
              Platform.OS !== 'web'
                ? calculateMaxHeight(screenHeight, maxHeightPercentage)
                : '',
          }}
        >
          {componentState.isLoginWhatsappActive ? (
            <View alignItems="center" pt="9xl">
              <CustomLottieView
                autoPlay
                loop
                source={require('../../../assets/lottie/whatsappLoginAnimation.json')}
                style={{ width: 196, height: 164 }}
                containerWidth={196}
              />
              <Text
                color="success.500"
                style={{ fontSize: 20, textAlign: 'center' }}
              >
                Securely Signing in...
              </Text>
            </View>
          ) : (
            <>
              <HandleBackPress />
              {componentState.currentScreen ===
                LoginModalScreenSteps.ENTER_PHONE_NUMBER && (
                <EnterPhoneNumber />
              )}
              {componentState.currentScreen ===
                LoginModalScreenSteps.ENTER_OTP && <EnterOtpScreen />}
              {componentState.currentScreen ===
                LoginModalScreenSteps.SIGN_UP && <SignupScreen />}
            </>
          )}
        </View>
      </BottomSheetV2>
    </ThemeWrapper>
  );
};

export default AuthBottomSheetModal;

const HandleBackPress = () => {
  const componentState = useLoginModalStore((state) => state);
  const commonStore = useCommonStore((state) => state);

  const handleOnBackPress = () => {
    switch (componentState.currentScreen) {
      case LoginModalScreenSteps.ENTER_PHONE_NUMBER:
        break;
      case LoginModalScreenSteps.ENTER_OTP:
        if (commonStore.wasSignupFlowActive) {
          componentState.setCurrentScreen(LoginModalScreenSteps.SIGN_UP);
        } else {
          componentState.setCurrentScreen(
            LoginModalScreenSteps.ENTER_PHONE_NUMBER,
          );
        }
        break;
      case LoginModalScreenSteps.SIGN_UP:
        if (commonStore.wasSignupFlowActive) {
          componentState.setSignupPayload({
            name: { first: '', last: '' },
          });
        }
        componentState.setCurrentScreen(
          LoginModalScreenSteps.ENTER_PHONE_NUMBER,
        );
        break;
      default:
        break;
    }
  };

  if (
    componentState.currentScreen === LoginModalScreenSteps.ENTER_PHONE_NUMBER ||
    !componentState.showBackIcon
  ) {
    return null;
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingHorizontal: 8,
      }}
    >
      <IconButton
        name="back-outline-300"
        size="md"
        appearance="ghost"
        iconColor="primary.500"
        onPress={handleOnBackPress}
        shape={undefined}
        bg={undefined}
        state={undefined}
        iconSize={undefined}
        text={undefined}
      />
    </View>
  );
};
