import React, { Suspense, useCallback, useState } from 'react';
import { Layout } from '../../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import { Loading } from '../../../../components';
import DisconnectedDropover from '../../../../components/disconnectedpopover.component';
import TransactionSearchScreenInner from './TransactionSearchScreenInner';

const TransactionSearchScreen = () => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'network-only',
    }));
  }, []);

  return (
    <Layout level="1">
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <TransactionSearchScreenInner />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default TransactionSearchScreen;
