/* eslint-disable no-nested-ternary */
import React, { memo, useContext } from 'react';
import { useWindowDimensions } from 'react-native';
import { ThemeContext, ThemeProvider } from 'styled-components/native';
import { getTheme } from '../../../themes/new/theme';
import { Text, View, Pressable } from '../index';
import useCommonStore from '../../../stores/commonStore';
import { imageTransformation } from '../../../utilities/helper';
import CachedImage from './CachedImage';
import CachedImageBackground from './CachedImageBackground';
// import theme from '../../../themes/new/theme';

const areEqual = () => {
  return true;
};

const Photogrid = (props) => {
  const { images, onPress = () => {}, theme } = props ?? {};
  const { width, height } = useWindowDimensions();

  const Row = ({ children }) => <View flexDirection="row">{children}</View>;

  const Col = ({ numRows, children, style }) => (
    <View
      flex={numRows}
      //   borderColor="error.500"
      {...style}
    >
      {children}
    </View>
  );

  const themeContext = useContext(ThemeContext);
  const screenOrientationValue = useCommonStore((state) => state.orientation);

  return (
    <View
      width={
        width > 1200 && screenOrientationValue < 3
          ? 375 - 2 * theme.space['2xl']
          : width - 2 * theme.space['2xl']
      }
      //   mt="2xl"
    >
      <Row>
        <Col
          numRows={3}
          style={{
            marginRight: theme.space.lg,
            height: 186,
          }}
          key={images[0]?._id}
        >
          <Pressable style={{ flex: 1 }} onPress={() => onPress(0)}>
            <CachedImage
              source={{
                uri: imageTransformation(
                  images[0]?.mediaURL,
                  (width - 48) * 0.75,
                ),
              }}
              style={{
                flex: 1,
                // borderWidth: 1,
                borderRadius: theme.radii.md,
                // borderColor: 'transparent',
                resizeMode: 'cover',
              }}
              // defaultSource={require('../../../../assets/images/Noimage.png')}
            />
          </Pressable>
        </Col>
        <Col numRows={1} style={{ height: 186 }}>
          <Col numRows={2} style={{}} key={images[1]?._id}>
            <Pressable style={{ flex: 1 }} onPress={() => onPress(1)}>
              <CachedImage
                source={{
                  uri: imageTransformation(
                    images[1]?.mediaURL,
                    (width - 48) * 0.25,
                  ),
                }}
                style={{
                  flex: 1,
                  //   borderWidth: 1,
                  borderRadius: theme.radii.md,
                  resizeMode: 'cover',
                  //   borderColor: 'transparent',
                }}
                // defaultSource={require('../../../../assets/images/Noimage.png')}
              />
            </Pressable>
          </Col>
          <Col numRows={2} style={{}} key={images[2]?._id}>
            <Pressable
              style={{ flex: 1, marginTop: theme.space.lg }}
              onPress={() => onPress(2)}
            >
              <CachedImage
                source={{
                  uri: imageTransformation(
                    images[2]?.mediaURL,
                    (width - 48) * 0.25,
                  ),
                }}
                style={{
                  flex: 1,
                  //   borderWidth: 1,
                  borderRadius: theme.radii.md,
                  resizeMode: 'cover',
                  //   borderColor: 'transparent',
                }}
                // defaultSource={require('../../../../assets/images/Noimage.png')}
              />
            </Pressable>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col numRows={3} style={{}} key={images[3]?._id}>
          <Pressable
            style={{ marginTop: theme.space.lg, height: 135 }}
            onPress={() => onPress(3)}
          >
            <CachedImage
              source={{
                uri: imageTransformation(
                  images[3]?.mediaURL,
                  (width - 48) * 0.6,
                ),
              }}
              style={{
                flex: 1,
                // borderWidth: 1,
                borderRadius: theme.radii.md,
                resizeMode: 'cover',
                // borderColor: 'transparent',
              }}
              // defaultSource={require('../../../../assets/images/Noimage.png')}
            />
          </Pressable>
        </Col>
        <Col numRows={2} style={{}} key={images[4]?._id}>
          <Pressable
            style={{
              marginLeft: theme.space.lg,
              marginTop: theme.space.lg,
              height: 135,
            }}
            onPress={() => onPress(4)}
          >
            <CachedImageBackground
              source={{
                uri: imageTransformation(
                  images[4]?.mediaURL,
                  (width - 48) * 0.4,
                ),
              }}
              style={{
                flex: 1,
              }}
              imageStyle={{
                // borderWidth: 1,
                borderRadius: theme.radii.md,
                // borderColor: 'transparent',
                resizeMode: 'cover',
              }}
              // defaultSource={require('../../../../assets/images/Noimage.png')}
            >
              {images && images?.length > 5 && (
                <ThemeProvider
                  theme={
                    themeContext.currentThemeMode === 'light'
                      ? getTheme('dark')
                      : getTheme('dark')
                  }
                >
                  <View
                    position="absolute"
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    backgroundColor="opacity.80"
                    // borderWidth={1}
                    borderRadius="md"
                    // borderColor="transparent"
                  >
                    <View alignItems="center" justifyContent="center">
                      <Text size="md" weight="medium" color="singletone.white">
                        {images && images?.length > 5
                          ? `+${images.length - 5}`
                          : `${images.length}`}
                      </Text>
                      {/* <Text size="md" weight="medium" color="singletone.fg">
                        Photos
                      </Text> */}
                    </View>
                  </View>
                </ThemeProvider>
              )}
            </CachedImageBackground>
          </Pressable>
        </Col>
      </Row>
    </View>
  );
};

export default memo(Photogrid, areEqual);
