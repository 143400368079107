import React from 'react';
import { Text, View } from '../../../../components/new';
import BlackMembershipCard from '../../../../components/new/custom/BlackMembershipCard';
import { toRupees } from '../../../../utilities/helper';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import CachedImage from '../../../../components/new/custom/CachedImage';

const BM_LOGO = require('../../../../../assets/images/black_membership/black-member-logo.webp');

interface BlackMembershipActiveCardProps {
  data:
    | {
        discount: number;
        cashback: number;
      }
    | string;
}

const BlackMembershipActiveCard = (props: BlackMembershipActiveCardProps) => {
  const { data } = props;

  return (
    <DarkThemeWrapper>
      <BlackMembershipCard>
        <View alignItems="center">
          <View
            p="2xl"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <View pt="sm">
              <CachedImage source={BM_LOGO} style={{ width: 45, height: 32 }} />
            </View>
            <Text flex={1} ml="2xl" size="xs" color="primary.500">
              {generateText(data)}
            </Text>
          </View>
        </View>
      </BlackMembershipCard>
    </DarkThemeWrapper>
  );
};

const generateText = (data: BlackMembershipActiveCardProps['data']) => {
  if (typeof data === 'string') return data;
  if (typeof data?.cashback === 'number' && typeof data?.cashback === 'number')
    return `As a 32nd Plus member, you get FLAT ${toRupees(
      data?.discount,
    )} off ${
      data?.cashback > 0 ? `+ ${toRupees(data?.cashback)} cashback!` : ''
    }`;
  return '';
};

export default BlackMembershipActiveCard;
