/* eslint-disable import/prefer-default-export */
import { useCallback, useRef, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  dateUtils,
  navigateBack,
  toISOStringWithTimezone,
} from '../../../../utilities/helper';
import { ITransaction } from '../../types';
import { BSMode } from '../ReportOverviewScreen/ReportDetailsBottomSheet';
import useUserStore from '../../../../stores/userStore';
import { earningsOverviewScreenQuery } from './earningsOverviewScreenQuery';
import { earningsOverviewScreenQuery$variables } from './__generated__/earningsOverviewScreenQuery.graphql';
import { useTransactions } from '../../API/fragments/transactionsFragment/useTransactions';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import { pagination } from '../../../../utilities/Constant';
import useTransactionsEarningReport from '../../API/fragments/transactionsEarningReportFragment/useTransactionsEarningReportFragment';

export const useEarningsOverviewScreen = (
  queryOptions: NewErrorBoundaryParentState,
) => {
  const bsRef = useRef();
  const navigation = useNavigation();
  const route = useRoute();
  const { date, restaurantId } = route.params;
  const userRole = useUserStore((state) => state.role);

  const variables = buildQueryVariables(date, restaurantId);
  const fragmentRef = useLazyLoadQuery(
    earningsOverviewScreenQuery,
    variables,
    queryOptions,
  );

  const {
    transactions,
    fetchMoreTransactions: fetchMore,
    hasMoreTransactions,
  } = useTransactions(fragmentRef);

  const [clickedTransaction, setClickedTransaction] =
    useState<ITransaction | null>(null);
  const [bsMode, setBSMode] = useState<BSMode>(BSMode.DISMISSED);
  const { earningReports } = useTransactionsEarningReport(fragmentRef);
  const report = earningReports[0];

  const showReportDetailsBS = () => {
    setBSMode(BSMode.REPORT);
    bsRef.current?.show();
  };

  const showTransactionDetailsBS = (orderId: ITransaction['orderId']) => {
    const txn = transactions.filter((t) => t.orderId === orderId)[0];
    setClickedTransaction(txn);
    setBSMode(BSMode.TRANSACTION);
    bsRef.current?.show();
  };

  const fetchMoreTransactions = () => {
    if (hasMoreTransactions) fetchMore(10);
  };

  const handleBSDismiss = () => {
    setBSMode(BSMode.DISMISSED);
  };

  const getBannerSubtitle = useCallback(() => {
    if (report) {
      if (dateUtils.isToday(report.date)) {
        return 'My Earnings (Today)';
      }
      return `My Earnings (${report.formated.date})`;
    }
    return '';
  }, [report.date]);

  return {
    bsRef,
    bsMode,
    transactions,
    clickedTransaction,
    report: earningReports[0],
    bannerSubTitle: getBannerSubtitle(),
    listTitle: getBannerSubtitle(),
    fetchMoreTransactions,
    handleBSDismiss,
    showReportDetailsBS,
    showTransactionDetailsBS,
    handleBack: () => navigateBack(navigation, userRole),
  };
};

const buildQueryVariables = (
  date: string,
  restaurantId: string,
): earningsOverviewScreenQuery$variables => {
  const startDate = dateUtils.toStartOfDay(new Date(date));
  const endDate = dateUtils.toEndOfDay(new Date(date));

  return {
    earningReportFilter: {
      date: {
        start: toISOStringWithTimezone(startDate),
        end: toISOStringWithTimezone(endDate),
      },
      groupBy: 'DAY',
      orderId: restaurantId,
    },
    sort: 'CREATED_AT_DESC',
    transactionsFilter: {
      orderId: restaurantId,
      status: 'SUCCESSFUL',
      datePeriod: {
        from: date,
        period: 'DAILY',
      },
    },
    count: pagination.defaultPageSize,
  };
};
