import { SCAN_STATUS } from '../../../utilities/helper';

export const checkIfClickPictureDisabled = (
  scanStatus: SCAN_STATUS,
): boolean => {
  if (
    scanStatus === SCAN_STATUS.IDLE ||
    scanStatus === SCAN_STATUS.PROCESSING
  ) {
    return true;
  }
  return false;
};

export const checkStatusNotCapturing = (scanStatus: SCAN_STATUS): boolean => {
  if (
    scanStatus === SCAN_STATUS.IDLE ||
    scanStatus === SCAN_STATUS.PROCESSING ||
    scanStatus === SCAN_STATUS.STARTED ||
    scanStatus === SCAN_STATUS.FAILURE ||
    scanStatus === SCAN_STATUS.SUCCESS
  ) {
    return true;
  }
  return false;
};

export const checkCameraVisible = (scanStatus: SCAN_STATUS): boolean => {
  if (scanStatus === SCAN_STATUS.IDLE || scanStatus === SCAN_STATUS.REQUESTED) {
    return true;
  }
  return false;
};

export const checkImageVisible = (
  scanStatus: SCAN_STATUS,
  detectedBillImage: string,
): boolean => {
  if (
    (scanStatus === SCAN_STATUS.PROCESSING ||
      scanStatus === SCAN_STATUS.STARTED ||
      scanStatus === SCAN_STATUS.FAILURE ||
      scanStatus === SCAN_STATUS.SUCCESS) &&
    detectedBillImage
  ) {
    return true;
  }
  return false;
};
