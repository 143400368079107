import { graphql } from 'react-relay';

const cashierCarSearchScreenTasksFragment = graphql`
  fragment cashierCarSearchScreenTasksFragment on task @relay(plural: true) {
    _id
    vehicle {
      registration {
        plate
      }
    }
    taskType
    campus {
      _id
    }
    history {
      _id
      action
      actor
      event_at
    }
    isVehicleWhitelisted
    ETA {
      _id
      startAt
      delayAt
      originalPredictionTime
      pendingRecall
      available
      busy
    }
    parking {
      status
      parkingInvoice {
        timings {
          entryTime
          exitTime
          chargeTime
        }
        paymentDetails {
          isPaid
          mode
          focReason
          amount
        }
        charges {
          chargesBreakup {
            duration
            payable
          }
          discountAmount
          totalPayable
        }
      }
    }
    parkingId
  }
`;

export default cashierCarSearchScreenTasksFragment;
