/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { FlatList } from 'react-native';
import moment from 'moment';
import { Pressable, Text, View } from '../../../components/new';
import {
  NoContentView,
  BonusBadge,
  BonusListItem,
} from '../../../components/new/custom/bonus';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import {
  DateFormatsEnum,
  getFormatedDate,
  numberWithCommas,
  toISOStringWithTimezone,
} from '../../../utilities/helper';
import { getTheme } from '../../../themes/new/theme';

const OverviewCollectionList = ({
  collectionList,
}: {
  collectionList: NewErrorBoundaryParentState;
}) => {
  const navigation = useNavigation<any>();

  return (
    <FlatList
      data={collectionList}
      renderItem={({ item, index }) => (
        <View mt={index !== 0 ? '2xl' : 0}>
          <Pressable
            onPress={() => {
              navigation.navigate('dayWiseCollectionScreen', {
                date: toISOStringWithTimezone(
                  moment(item.date, 'YYYY-MM-DD').toDate(),
                ),
              });
            }}
          >
            <BonusListItem
              contentPrimary={getFormatedDate(
                new Date(item.date),
                DateFormatsEnum.DateMonthYear,
              )}
              contentSecondary={`Cash - ₹${numberWithCommas(
                item.cashTotal,
              )} |  Other - ₹${numberWithCommas(item.onlineTotal)}`}
              RightContent={
                <BonusBadge variant="gain">
                  <Text size="sm" color="primary.500">
                    ₹{numberWithCommas(item.total)}
                  </Text>
                </BonusBadge>
              }
            />
          </Pressable>
        </View>
      )}
      keyExtractor={(item) => item?._id}
      style={{
        paddingHorizontal: getTheme().space['2xl'],
        paddingVertical: getTheme().space['4xl'],
      }}
      ListEmptyComponent={
        <View>
          <NoContentView />
          <Text textAlign="center" color="primary.100">
            No Collections yet
          </Text>
        </View>
      }
    />
  );
};

export default OverviewCollectionList;
