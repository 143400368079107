import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { taskHistoryActionTypes, taskTypes } from '../../utilities/helper';

export interface ValetTaskStoreInterface {
  tasks?: [];
  valetWorkLog: object;
}

interface useValetTaskStorePropTypes {
  (state?: object);
}
const useValetTaskStore = <useValetTaskStorePropTypes>create()(
  devtools(
    persist(
      (set) => ({
        openedCard: '',
        valetWorkLog: {},
        setOpenedCard: ({ id }) => {
          set(() => {
            return { openedCard: id };
          });
        },
        tasks: [],
        newTasks: [],
        setTasks: ({ data, userId }) => {
          set(() => {
            const allBlur = (list) => {
              return list && list?.length && list?.every((item) => item?.blur);
            };
            // eslint-disable-next-line array-callback-return, consistent-return
            const filterTasks = data.filter((item) => {
              if (
                item?.taskType === taskTypes.park ||
                item?.taskType === taskTypes?.repark
              ) {
                if (
                  item?.history.slice(-1)[0]?.action ===
                    taskHistoryActionTypes.created ||
                  item?.history.slice(-1)[0]?.action ===
                    taskHistoryActionTypes.assigned ||
                  item?.history.slice(-1)[0]?.action ===
                    taskHistoryActionTypes.accepted ||
                  item?.history.slice(-1)[0]?.action ===
                    taskHistoryActionTypes.started ||
                  item?.history.slice(-1)[0]?.action ===
                    taskHistoryActionTypes.onboarded ||
                  item?.history.slice(-1)[0]?.action ===
                    taskHistoryActionTypes.arrived
                ) {
                  return { ...item };
                }
              } else if (item?.taskType === taskTypes.recall) {
                return { ...item };
              }
            });
            const assignedData =
              filterTasks &&
              filterTasks?.length &&
              filterTasks?.filter((item) => item?.assignedTo === userId);
            const allAssignedDataBlurred = allBlur(assignedData);
            const unassignedData =
              assignedData && assignedData?.length
                ? filterTasks
                    ?.filter((item) => item?.assignedTo !== userId)
                    .map((item) => {
                      return {
                        ...item,
                        blur: !(assignedData.length && allAssignedDataBlurred),
                      };
                    })
                : [...filterTasks];
            return {
              tasks:
                assignedData && assignedData?.length
                  ? [...assignedData, ...unassignedData]
                  : [...unassignedData],
            };
          });
        },
        setNewTasks: ({ data }) => {
          set(() => {
            return { newTasks: data };
          });
        },
        setValetWorkLog: ({ data }) => {
          set(() => {
            return { valetWorkLog: data };
          });
        },
      }),
      {
        name: 'ValetTaskStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'ValetTaskStore',
    },
  ),
);

export default useValetTaskStore;
