/**
 * @generated SignedSource<<960c3a06ecf917ff27fce181f6caec7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type DatePeriodType = "DAY" | "DAY_MONTH" | "MONTH" | "WEEK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type transactionsEarningReportFragment$data = {
  readonly earingReports: ReadonlyArray<{
    readonly date: string | null;
    readonly discountGiven: number | null;
    readonly grossAmount: number | null;
    readonly netAmount: number | null;
    readonly payoutAmount: number | null;
    readonly period: DatePeriodType | null;
    readonly rewardPointsCompensated: number | null;
    readonly rewardPointsGranted: number | null;
    readonly rewardPointsUsed: number | null;
    readonly transactionsCount: number | null;
  } | null> | null;
  readonly " $fragmentType": "transactionsEarningReportFragment";
};
export type transactionsEarningReportFragment$key = {
  readonly " $data"?: transactionsEarningReportFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"transactionsEarningReportFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./transactionsEarningReportFragmentQuery.graphql')
    }
  },
  "name": "transactionsEarningReportFragment",
  "selections": [
    {
      "alias": "earingReports",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "PaymentReport",
      "kind": "LinkedField",
      "name": "getPaymentReports",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "period",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionsCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payoutAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discountGiven",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardPointsUsed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardPointsGranted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardPointsCompensated",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "137fca85f1f214d63c050efb2e9dfe60";

export default node;
