/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React, { memo, useEffect } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { Icon, Pressable, Text, View } from '../../../components/new';
import {
  DateFormatsEnum,
  PaymentStatusEnum,
  PaymentTypes,
  formatPlateNo,
  getFormatedDate,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../utilities/helper';
import { getTheme } from '../../../themes/new/theme';
import { findInvoice } from './API/trackMyVehicleApi';
import {
  feedbacksTopics,
  ratingType,
  reactions,
} from '../../../utilities/ratingOptions';
import useFeedbackModalStore from '../../../stores/FeedbackModalStore';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import { rewardTypeEnums } from '../games/slotMachine/helpers/slotMachineHelpers';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import CachedImage from '../../../components/new/custom/CachedImage';

const VehicleHistoryCard = ({
  data,
  lastHistory,
  retry,
  setFeedbackType,
  setParkingId,
  setIsOpen,
  setOnSuccess,
}) => {
  const navigation = useNavigation();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const plateNumber = data?.vehicle?.registration?.plate;
  let rewardPoints =
    data?.parkingRewardsGiven && !isValueNullOrEmpty(data?.parkingRewardsGiven)
      ? data?.parkingRewardsGiven
      : 0;
  let rewardsUsed = 0;
  let discountGiven = 0;
  let netAmount = 0;
  const { feedback } = data ?? {};
  const { parkingInvoice, invoicesData = [] } = data ?? {};

  const handleOpenFeedbackModal = () => {
    firebaseEventLogger('trackMyVehicle__rateUs_Tap', {
      buttonName: 'rateUs',
      screenName: 'trackMyVehicle',
      userType: 'user',
      interactionType: 'tap',
    });
    setParkingId(data?._id);
    setFeedbackType(feedbacksTopics.VALET);
    setIsOpen(true);
    setOnSuccess(false);
  };

  if (invoicesData?.length) {
    invoicesData.forEach((item) => {
      rewardsUsed += item?.priceDetails?.rewardsUsed;
      discountGiven += item?.priceDetails?.discountGiven;
      netAmount += item?.priceDetails?.netAmount;
    });
  }

  data?.tasks?.forEach((item) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    rewardPoints += item?.rewardsGiven;
  });

  const shadowProps =
    Platform.OS === 'web' || Platform.OS === 'ios'
      ? { borderColor: 'primary.50', shadow: 'sm' }
      : { borderColor: 'primary.50' };

  // ------ finding recall task from data ------
  const findLastRecallTask =
    (data &&
      data?.tasks &&
      data?.tasks?.length &&
      data?.tasks[data?.tasks?.length - 1]?.taskType === taskTypes.recall &&
      data?.tasks[data?.tasks?.length - 1]) ??
    {};

  const { isVehicleWhitelisted } = findLastRecallTask;

  // ------ finding arrived status from history of the recall task found above ------
  const arrivedStatus =
    (Object.keys(findLastRecallTask)?.length &&
      findLastRecallTask?.history?.filter(
        (item) => item?.action === taskHistoryActionTypes.arrived,
      )[0]) ??
    {};

  // ------ finding and storing delayAt from recall task found above ------
  const delayAt = new Date(findLastRecallTask?.ETA?.delayAt).valueOf();

  // ------ finding and storing arrived status time from arrived status ------
  const arrivedStatusTime = new Date(arrivedStatus?.event_at).valueOf();

  // ------ difference between delayAt and arrived status time ------
  const differenceBtwEtaArrivedStatus = Object.keys(arrivedStatus)?.length
    ? delayAt - arrivedStatusTime
    : 0;

  // ------ converting difference found above to mins ------
  const convertToMins = Math.round(differenceBtwEtaArrivedStatus / 60000);

  /* ------ function to check if parking has feedback,
  rating given and rating type of Emoji */
  const hasFeedBack = () => {
    if (
      feedback &&
      Object.keys(feedback)?.length &&
      feedback?.feedbacks &&
      feedback?.feedbacks?.length &&
      feedback?.feedbacks[0]?.rating &&
      feedback?.feedbacks[0]?.ratingType === ratingType.EMOJI
    ) {
      return true;
    }
    return false;
  };

  const taskPlus90Days =
    moment(lastHistory?.event_at).add(90, 'days').unix() * 1000;
  const showRating = new Date().valueOf() < taskPlus90Days;

  const totalDiscount = discountGiven + rewardsUsed;

  const handlePress = () => {
    const filter = {
      orderId: data?._id,
      status: PaymentStatusEnum.SUCCESSFUL,
    };
    findInvoice(filter)
      .then((res) => {
        if (res && res?.findInvoice) {
          navigation.navigate('transactionScreen', {
            invoiceId: res?.findInvoice[0]?._id,
          });
        }
      })
      .catch((err) => {
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
      });
  };

  return (
    <>
      <View
        borderRadius="md"
        borderWidth="xs"
        backgroundColor="background.primary.elevation"
        borderColor="grey.50"
      >
        <View p="2xl">
          <Pressable
            onPress={handlePress}
            disabled={
              data?.parkingInvoice?.paymentDetails?.mode ===
                PaymentTypes.CASH ||
              data?.parkingInvoice?.paymentDetails?.mode === null
            }
          >
            <View
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <View>
                <Text size="md" color="primary.400">
                  {formatPlateNo(plateNumber)}
                </Text>
                <Text size="xs" color="primary.100" pt="sm">
                  {getFormatedDate(
                    lastHistory?.event_at,
                    DateFormatsEnum.DateMonthYear,
                  )}{' '}
                  |{' '}
                  {getFormatedDate(
                    new Date(lastHistory?.event_at),
                    DateFormatsEnum.TIME,
                  )}
                </Text>
              </View>
              {!data?.tasks?.[0]?.isVehicleWhitelisted &&
              (netAmount > 0 || totalDiscount > 0) ? (
                <View>
                  <View alignItems="flex-end">
                    <Text size="md" color="primary.400" weight="medium">
                      ₹{netAmount ?? 0}
                    </Text>
                  </View>
                  {!isValueNullOrEmpty(totalDiscount) &&
                  parkingInvoice?.paymentDetails?.isPaid ? (
                    <View style={{ marginTop: 4, alignSelf: 'center' }}>
                      <Text size="xs" color="primary.100">
                        Saved ₹{totalDiscount}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              ) : (
                <></>
              )}
            </View>
            <View flexDirection="row" alignItems="center" mt="2xl">
              {rewardPoints > 0 ? (
                <View
                  borderRadius="md"
                  py="sm"
                  px="lg"
                  bg="success.10"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Text size="xs" color="primary.300">
                    Earned {rewardPoints}
                  </Text>
                  <CachedImage
                    source={require('../../../../assets/images/coins.webp')}
                    style={{ width: 16, height: 16, resizeMode: 'contain' }}
                  />
                </View>
              ) : (
                <></>
              )}
              <View
                borderRadius="md"
                py="sm"
                px="lg"
                bg={
                  convertToMins >= 0
                    ? 'success.10'
                    : isVehicleWhitelisted
                    ? 'warning.10'
                    : 'success.10'
                }
                flexDirection="row"
                alignItems="center"
                ml={rewardPoints > 0 ? 'lg' : 0}
              >
                {convertToMins >= 0 ? (
                  convertToMins >= 1 ? (
                    <>
                      <Text size="xs" color="primary.300">
                        Delivered{' '}
                      </Text>
                      <Text size="xs" color="success.500">
                        {convertToMins > 1
                          ? `${convertToMins} mins `
                          : `${convertToMins} min `}
                      </Text>
                      <Text size="xs" color="primary.300">
                        early!
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text size="xs" color="primary.300">
                        Delivered{' '}
                      </Text>
                      <Text size="xs" color="success.500">
                        on time
                      </Text>
                    </>
                  )
                ) : isVehicleWhitelisted ? (
                  <>
                    <Text size="xs" color="primary.300">
                      Delayed by{' '}
                    </Text>
                    <Text size="xs" color="warning.500">
                      {Math.abs(convertToMins) > 1
                        ? `${Math.abs(convertToMins)} mins `
                        : `${Math.abs(convertToMins)} min `}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text size="xs" color="primary.300">
                      Rewarded{' '}
                    </Text>
                    <Text size="xs" color="success.500">
                      {data.rewardData[0]?.rewardType ===
                      rewardTypeEnums.freeParking
                        ? 'Free Parking '
                        : '70 Credit Points '}
                    </Text>
                    <Text size="xs" color="primary.300">
                      for delay
                    </Text>
                  </>
                )}
              </View>
            </View>
          </Pressable>
          <View my="2xl">
            <View height={1} px="sm" overflow="hidden">
              <View
                // borderStyle="dashed"
                borderWidth="sm"
                borderColor="primary.50"
              />
            </View>
          </View>
          <View>
            {showRating || hasFeedBack() ? (
              <Pressable
                onPress={handleOpenFeedbackModal}
                disabled={hasFeedBack()}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View flexDirection="row" alignItems="center">
                  {reactions?.map((item, index) => (
                    <View ml={index !== 0 ? 'sm' : 0} key={`${item?.key}`}>
                      {hasFeedBack() &&
                      feedback?.feedbacks[0].rating === item?.key ? (
                        <CachedImage
                          source={item.selectedIcon}
                          style={{
                            width: getTheme().fontSizes.xl,
                            height: getTheme().fontSizes.xl,
                            resizeMode: 'contain',
                          }}
                        />
                      ) : (
                        <View style={{ alignSelf: 'center' }}>
                          <Icon
                            name={item.icon}
                            color={item.iconColor}
                            size={item.size}
                          />
                        </View>
                      )}
                    </View>
                  ))}
                </View>
                {!hasFeedBack() ? (
                  <View style={{ marginTop: 4, alignSelf: 'center' }}>
                    <Text size="xs" color="info.500">
                      Rate Us
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </Pressable>
            ) : (
              <></>
            )}
          </View>
        </View>
      </View>
    </>
  );
};

const arePropsEqual = (prev, current) => {
  return true;
};

const MemoizedVehicleHistoryCard = memo(VehicleHistoryCard, arePropsEqual);
const VehicleHistoryCardWrapper = (props) => {
  const {
    setParkingId,
    parkingId,
    setFeedbackType,
    setIsOpen,
    onSuccess,
    feedbackType,
    setOnSuccess,
  } = useFeedbackModalStore((state) => state);

  useEffect(() => {
    if (feedbackType === feedbacksTopics.VALET && onSuccess) {
      props?.retry();
      setOnSuccess(false);
    }
  }, [onSuccess]);

  return (
    <MemoizedVehicleHistoryCard
      {...props}
      parkingId={parkingId}
      setParkingId={setParkingId}
      setIsOpen={setIsOpen}
      setFeedbackType={setFeedbackType}
      setOnSuccess={setOnSuccess}
    />
  );
};

export default VehicleHistoryCardWrapper;
