import React from 'react';
import { View } from '../../../components/new';
import { mediaTypes, SplitAndCapitalise } from '../../../utilities/helper';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import DetailCard from '../../../components/new/custom/DetailCard';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const RenderItem = ({ item, userWallet, navigation, viewableItem, config }) => {
  const sortByPrimary = (a, b) => {
    return b.primary - a.primary;
  };

  const sortFn = (a, b) => {
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    return 0;
  };
  const itemData = item.item;

  const tagLine =
    itemData?.description &&
    itemData?.description?.cusines?.length &&
    JSON.parse(JSON.stringify(itemData?.description?.cusines))
      .sort(sortByPrimary)
      .map((ele) => {
        return ele?.name === 'fastfood'
          ? 'Fast Food'
          : SplitAndCapitalise(ele.name);
      });

  const canUsePoints = itemData?.payBill?.pointsEnabled?.redeem;

  const coverImages =
    itemData?.mediaGallery &&
    itemData?.mediaGallery?.length &&
    JSON.parse(JSON.stringify(itemData?.mediaGallery))
      .filter(
        (ele) =>
          ele?.sectionTitle !== 'Awards___Recognitions' &&
          ele?.sectionTitle !== 'Founder_Story',
      )
      .sort(sortFn)
      .map((ele) => ele?.media && ele?.media.sort(sortFn))
      .flat()
      .filter((ele) => ele?.mediaType === mediaTypes.image)
      .sort((a, b) => b.cover - a.cover);

  const coverVideo =
    itemData?.mediaGallery &&
    itemData?.mediaGallery?.length &&
    JSON.parse(JSON.stringify(itemData?.mediaGallery))
      .filter(
        (ele) =>
          ele?.sectionTitle !== 'Awards___Recognitions' &&
          ele?.sectionTitle !== 'Founder_Story',
      )
      .sort(sortFn)
      .map((ele) => ele?.media && ele?.media.sort(sortFn))
      .flat()
      .filter((ele) => ele?.mediaType === mediaTypes.video)
      .sort((a, b) => b.coverVideo - a.coverVideo)[0];

  return (
    <DarkThemeWrapper>
      <View mt={item.index === 0 ? '2xl' : 'xl'}>
        <DetailCard
          discountPercentage={itemData?.payBillPercentage}
          payBillCashback={itemData?.payBillCashback}
          billUploadCashback={config?.billUploadConfig?.cashbackPercentage}
          currentBalance={canUsePoints ? userWallet?.wallet?.currentBalance : 0}
          id={itemData?._id}
          key={itemData?._id}
          displayName={itemData?.displayName}
          pointsEnabled={itemData?.payBill?.pointsEnabled}
          tagLine={tagLine}
          coverImages={
            coverImages && coverImages?.length ? coverImages.slice(0, 10) : []
          }
          ratingScore={
            itemData?.overAllRating > 0 ? itemData?.overAllRating : 0
          }
          onPress={() => {
            firebaseEventLogger('RestaurantCardClicked', {
              id: itemData._id,
            });
            navigation.navigate('RestaurantScreen', {
              restaurantId: itemData._id,
            });
          }}
          viewableItem={viewableItem}
          coverVideo={coverVideo}
        />
      </View>
    </DarkThemeWrapper>
  );
};

export default RenderItem;
