import React, { useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { View, Text, Icon } from '../components/new';
import useUserStore from '../stores/userStore';
import { dynamicHeightMaker, PaymentStatusEnum } from '../utilities/helper';
import CustomLottieView from '../components/new/custom/CustomLottieView';

const TaskCompletionScreen = ({ navigation, route }) => {
  const { text1, text2, text3, showAnimation, status } = route?.params ?? {};
  const { role } = useUserStore((state) => state);
  const { width } = useWindowDimensions();

  const navigateToHome = () => {
    navigation.navigate(role);
  };

  useEffect(() => {
    setTimeout(() => {
      navigateToHome();
    }, 2200);
  }, []);

  return (
    <View flex={1} bg="primary.10">
      {showAnimation ? (
        <View position="absolute">
          <CustomLottieView
            loop
            autoPlay
            source={
              status && status === PaymentStatusEnum.FAILED
                ? require('../../assets/lottie/failed_animation.json')
                : require('../../assets/lottie/celebration.json')
            }
            style={{
              width,
              height: dynamicHeightMaker(width, 1),
            }}
            containerWidth={width}
          />
        </View>
      ) : (
        <></>
      )}
      <View flex={1} justifyContent="center" alignItems="center">
        <Icon name="check-filled-400" color="success.500" size="3xl+3xl" />
        <Text mt="4xl" size="2xl" weight="medium" color="primary.300">
          {text1}
        </Text>
        <Text mt="2xl" size="3xl" weight="medium" color="primary.500">
          {text2}
        </Text>
        <Text mt="sm" size="sm" color="primary.100">
          {text3}
        </Text>
      </View>
    </View>
  );
};
export default TaskCompletionScreen;
