import React, { useState, useEffect, useContext } from 'react';
import { useWindowDimensions, Linking } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { ThemeContext } from 'styled-components/native';
import { get } from 'lodash';
import {
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import {
  navigateBack,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../../utilities/helper';
import Barcode from '../../../../components/new/custom/Barcode';
import DarkThemeWrapper from '../../../new/wrapperComp/DarkThemeWrapper';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import useCommonStore from '../../../../stores/commonStore';
import { updateTaskForSupervisor } from '../../../../relay/taskApi';
import useUserStore from '../../../../stores/userStore';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';

const ScanQrCodeScreen = ({
  navigation,
  route,
  printPhoto = false,
  backtoStaffMoments = () => {},
  scannedAssetId,
}) => {
  const { task = {} } = route?.params ?? '';
  const { role } = useUserStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [scannedError, setScannedError] = useState(false);
  const [tourchMode, setTourchMode] = useState(false);
  const [borderColor, setBorderColor] = useState('info.500');
  const { width, height } = useWindowDimensions();
  const { top } = useSafeAreaInsets();
  const userRole = useUserStore((state) => state.role);
  const theme = useContext(ThemeContext);
  const campusID = useCommonStore((state) => state.campusId);

  const RequestPermission = async () => {
    // const { status } = await BarCodeScanner.requestPermissionsAsync();
    // setHasPermission(status === 'granted');
    BarCodeScanner.requestPermissionsAsync()
      .then(({ status }) => {
        setHasPermission(status === 'granted');
      })
      .catch((err) => {
        setHasPermission(false);
      });
  };

  useEffect(() => {
    RequestPermission();
  }, []);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setScanned(true);
      setBorderColor('error.500');
      setScannedError(true);
    }, 7000);
    return () => {
      clearInterval(timeoutID);
    };
  }, [scanned]);

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    if (printPhoto) {
      scannedAssetId(data);
      return;
    }
    // setScannedError(false);
    if (data && data?.length === 24) {
      const taskData = {
        _id: task?._id,
        record: {
          history:
            task?.taskType === taskTypes.recall
              ? [
                  { action: taskHistoryActionTypes.assigned },
                  { action: taskHistoryActionTypes.accepted },
                  { action: taskHistoryActionTypes.started },
                ]
              : [
                  { action: taskHistoryActionTypes.assigned },
                  { action: taskHistoryActionTypes.accepted },
                ],
          assignedTo: data,
        },
      };
      const handleUpdateTask = (res) => {
        if (res) {
          setBorderColor('success.500');
          setTourchMode(false);
          dispatchSnackbar({
            msg: `Task Assigned Successfully`,
            status: SnackbarStatus.success,
            version: SnackbarVersion.v2,
            position: 'bottom',
          });
          setTimeout(() => {
            navigation.popToTop();
          }, 200);
        }
      };
      const handleError = (error) => {
        dispatchSnackbar({
          msg: `${error?.res?.errors[0]?.message}`,
          status:
            error &&
            error?.res &&
            error?.res?.errors[0] &&
            error?.res?.errors[0]?.extensions &&
            error?.res?.errors[0]?.extensions?.code === 100
              ? SnackbarStatus?.warning
              : SnackbarStatus?.error,
          version: SnackbarVersion?.v2,
        });
        setScannedError(true);
        setBorderColor('error.500');
      };
      updateTaskForSupervisor(taskData, handleUpdateTask, handleError);
    } else {
      dispatchSnackbar({
        msg: 'Invalid QrCode',
        status: SnackbarStatus?.warning,
        version: SnackbarVersion?.v2,
      });
      setScannedError(true);
      setBorderColor('error.500');
    }
  };

  if (hasPermission === false) {
    return (
      <View flex={1}>
        <TopNavigation
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigateBack(navigation, userRole)}
            />
          }
          appearance="ghost"
          level="1"
        />
        <View flex={1} justifyContent="center" alignItems="center" px="2xl">
          <CustomLottieView
            style={{ width: 200, height: 200 }}
            autoPlay
            loop
            source={require('../../../../../assets/lottie/no-camera.json')}
            containerWidth={200}
          />
          <Text size="2xl" weight="medium" color="primary.400" mt="4xl">
            Permission denied!
          </Text>
          <Text size="md" color="primary.200" mt="lg">
            Enable permission for camera in settings
          </Text>
          <View width="100%">
            <Button
              appearance="filled"
              status="primary"
              size="lg"
              mt="4xl"
              onPress={() => Linking.openSettings()}
            >
              Go To Settings
            </Button>
          </View>
        </View>
      </View>
    );
  }

  const onPressScanAgain = () => {
    setScanned(false);
    setScannedError(false);
    setBorderColor('info.500');
  };

  const handleAddManually = () => {
    navigation.navigate('SearchValetScreen', {
      // ...response.addTask.record,
      ...task,
    });
  };

  return (
    <Layout level={2} edges={['top']}>
      {hasPermission ? (
        <Barcode
          scanned={scanned}
          scannedError={scannedError}
          handleBarCodeScanned={handleBarCodeScanned}
          tourchMode={tourchMode}
          borderColor={get(theme?.colors, borderColor)}
          onPressScanAgain={onPressScanAgain}
          TopNavigationComponent={
            <View
              position="absolute"
              top={top + 8}
              backgroundColor="transparent"
              zIndex={99}
              width="100%"
            >
              <DarkThemeWrapper>
                <TopNavigation
                  appearance="ghost"
                  level="none"
                  IconLeft={
                    <IconButton
                      name="back-outline-300"
                      size="md"
                      appearance="ghost"
                      onPress={() => {
                        navigateBack(navigation, userRole);
                      }}
                    />
                  }
                  IconRight={
                    <IconButton
                      name={tourchMode ? 'flash-on-300' : 'flash-off-300'}
                      size="md"
                      appearance="ghost"
                      onPress={() => {
                        setTourchMode(!tourchMode);
                      }}
                    />
                  }
                />
              </DarkThemeWrapper>
            </View>
          }
          scanQr
          onPressAddManually={handleAddManually && !printPhoto}
        />
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default ScanQrCodeScreen;
