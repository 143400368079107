import React, { Suspense, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { FlatList, Keyboard, Platform, RefreshControl } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ActionSheetRef } from 'react-native-actions-sheet';
import LottieView from 'lottie-react-native';
import {
  IconButton,
  Input,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import Layout from '../../../components/new/primitive/Layout/Layout';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import { CouponsPlaceholder } from './shimmerPlaceholders';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import { navigateBack } from '../../../utilities/helper';
import useAuthStore from '../../../stores/authStore';
import { InputStates } from '../../../components/new/primitive/Input/helpers';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';
import CouponCard from './CouponCard';
import {
  CouponsComponentFindCouponsFragment,
  CouponsComponentFindIssuedCouponsFragment,
  CouponsComponentQuery,
  CouponsComponentQueryNonLoggedInQuery,
} from './api/CouponsComponentQuery';
import { space } from '../../../themes/new/theme';
import EmptyListView from '../../../screens/restaurantPartner/components/EmptyListView';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import { getNetPriceApi } from '../../../relay/parkingApi';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import useLoginModalStore from '../../../stores/loginModalStore';

const COUPONS_PAGE_SIZE = 10;

const CouponsPage = ({
  queryOptions,
  refresh,
  refreshing,
  variables,
  netPriceVariables,
  orderType,
}) => {
  const navigation = useNavigation();
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [detailsArray, setDetailsArray] = useState([]);
  const listRef = useRef(null);
  const { bottom } = useSafeAreaInsets();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const componentState = useLoginModalStore((state) => state);

  const { ...findCouponsRef } = useLazyLoadQuery(
    isLoggedIn ? CouponsComponentQuery : CouponsComponentQueryNonLoggedInQuery,
    variables,
    queryOptions,
  );

  const {
    data: findCouponNodes,
    loadNext: fetchMoreCoupons,
    hasNext: hasMoreCoupons,
  } = usePaginationFragment(
    CouponsComponentFindCouponsFragment,
    findCouponsRef,
  );

  // extracting node out of edges
  const couponsArray = unwrapPagedData(findCouponNodes?.findCoupons?.edges);

  let coupons: any[] = [];

  if (isLoggedIn) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: findIssuedCouponNodes } = usePaginationFragment(
      CouponsComponentFindIssuedCouponsFragment,
      findCouponsRef,
    );

    // extracting node out of edges
    const issuedCouponsArray = unwrapPagedData(
      findIssuedCouponNodes?.findIssuedCoupons?.edges,
    );
    const couponsRes = issuedCouponsArray.concat(couponsArray);
    coupons = couponsRes;
  } else {
    coupons = couponsArray;
  }

  const renderError = (message, code) => {
    const msg = message;
    const splittedMsg = msg.split(': ');
    dispatchSnackbar({
      msg: splittedMsg?.[1] ?? 'This coupon code does not exist',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v3,
    });
    firebaseEventLogger('couponScreen__couponError_Data', {
      buttonName: 'couponError',
      screenName: 'couponScreen',
      userType: 'user',
      interactionType: 'Tap',
      platform: Platform.OS,
      orderType,
      couponCode: code,
      error: splittedMsg?.[1] ?? 'This coupon code does not exist',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleCouponSelection = async (couponCodeVal) => {
    const params = { ...netPriceVariables };
    params.couponCode = couponCodeVal;
    await getNetPriceApi(params)
      .then((response) => {
        if (response?.getNetPrice?.extras?.coupons?.isApplied) {
          const appliedCoupon = {
            couponCode: couponCodeVal,
            savedAmount: response?.getNetPrice?.priceDetails?.couponAmount ?? 0,
            cashbackAmount:
              response?.getNetPrice?.priceDetails?.couponCashbackAmount ?? 0,
          };
          componentState?.setAppliedCoupon(appliedCoupon);
          navigateBack(navigation, 'user');
        } else {
          const message = response?.getNetPrice?.extras?.coupons?.message;
          renderError(message, couponCodeVal);
        }
      })
      .catch((err) => {
        const message = err?.res?.errors?.[0]?.message;
        renderError(message, couponCodeVal);
      });
  };

  const handleOnDismiss = () => {
    setDetailsArray([]);
  };

  const showBottomSheet = () => {
    bottomSheetModalRef?.current?.show();
  };

  const renderBottomSheet = () => {
    return (
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View my="2xl" px="2xl">
          <View pb="lg" borderBottomWidth={1} borderColor="grey.100">
            <Text size="md" color="primary.300" weight="medium">
              Coupon Details
            </Text>
          </View>
          {detailsArray.map((item, index) => {
            return (
              <View flexDirection="row" key={`${item}_index`} mt="2xl">
                <Text size="sm" color="primary.300" mr="md">
                  •{' '}
                </Text>
                <Text size="sm" color="primary.300">
                  {item}
                </Text>
              </View>
            );
          })}
        </View>
      </BottomSheetV2>
    );
  };

  return (
    <View flex={1}>
      <View flex={1}>
        <FlatList
          ref={listRef}
          data={coupons}
          onEndReached={() => {
            if (hasMoreCoupons) {
              fetchMoreCoupons(COUPONS_PAGE_SIZE);
            }
          }}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ marginTop: space['4xl'] }}
          keyExtractor={(item, index) => `${item?.couponCode}`}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                refresh();
              }}
            />
          }
          renderItem={({ item }) => (
            <CouponCard
              data={item}
              openDetails={() => {
                if (isValueNullOrEmpty(item?.finePrint?.details?.[0])) {
                  dispatchSnackbar({
                    msg: 'Details not available for this ticket.',
                    status: SnackbarStatus.error,
                    version: SnackbarVersion.v1,
                  });
                  return;
                }
                setDetailsArray(item?.finePrint?.details);
                showBottomSheet();
                firebaseEventLogger('couponScreen__viewDetails_Tap', {
                  buttonName: 'viewDetails',
                  screenName: 'couponScreen',
                  userType: 'user',
                  interactionType: 'Tap',
                  platform: Platform.OS,
                  orderType,
                });
              }}
              handleCoupons={() => {
                handleCouponSelection(item?.couponCode);
                firebaseEventLogger('couponScreen__applyTap_Tap', {
                  buttonName: 'applyTap',
                  screenName: 'couponScreen',
                  userType: 'user',
                  interactionType: 'Tap',
                  platform: Platform.OS,
                  orderType,
                  couponCode: item?.couponCode,
                });
              }}
            />
          )}
          ListFooterComponent={() => {
            if (coupons?.length && hasMoreCoupons)
              return (
                <View px="2xl" height={140} alignItems="center">
                  <View width={177} height={100}>
                    <LottieView
                      autoPlay
                      loop
                      source={require('../../../../assets/lottie/pagination-loader.json')}
                      style={{ width: 177, height: 100 }}
                    />
                  </View>
                </View>
              );
            return null;
          }}
          ListEmptyComponent={
            <View mt="122" px="2xl">
              <EmptyListView
                label="No Coupons Available!"
                msg="Unfortunately, no coupons are available now. If you have a code, please enter it above"
              />
            </View>
          }
        />
      </View>
      {renderBottomSheet()}
    </View>
  );
};

const CouponsComponent = ({ route }) => {
  const { refId, netPriceVariables, orderType } = route.params ?? {};
  const navigation = useNavigation();
  const [couponCode, setCouponCode] = useState('');
  const componentState = useLoginModalStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [refreshing, setRefreshing] = useState(false);

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const variables = {
    filter: {
      redeemableAt: {
        refId,
        refType: orderType,
      },
    },
    issuedFilter: {
      redeemableAt: {
        refId,
        refType: orderType,
      },
      status: 'ACTIVE',
    },
    issuedCount: 20,
    count: COUPONS_PAGE_SIZE,
  };

  const renderError = (message, code) => {
    const msg = message;
    const splittedMsg = msg.split(': ');
    dispatchSnackbar({
      msg: splittedMsg?.[1] ?? 'This coupon code does not exist',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v3,
    });
    firebaseEventLogger('couponScreen__couponError_Data', {
      buttonName: 'couponError',
      screenName: 'couponScreen',
      userType: 'user',
      interactionType: 'Tap',
      platform: Platform.OS,
      orderType,
      couponCode: code,
      error: splittedMsg?.[1] ?? 'This coupon code does not exist',
    });
  };

  const handleCouponSelection = async (couponCodeVal) => {
    const params = { ...netPriceVariables };
    params.couponCode = couponCodeVal;
    await getNetPriceApi(params)
      .then((response) => {
        if (response?.getNetPrice?.extras?.coupons?.isApplied) {
          const appliedCoupon = {
            couponCode: couponCodeVal,
            savedAmount: response?.getNetPrice?.priceDetails?.couponAmount ?? 0,
            cashbackAmount:
              response?.getNetPrice?.priceDetails?.couponCashbackAmount ?? 0,
          };
          componentState?.setAppliedCoupon(appliedCoupon);
          navigateBack(navigation, 'user');
        } else {
          const message = response?.getNetPrice?.extras?.coupons?.message;
          renderError(message, couponCodeVal);
        }
      })
      .catch((err) => {
        const message = err?.res?.errors?.[0]?.message;
        renderError(message, couponCodeVal);
      });
  };

  return (
    <Layout level={2}>
      <TopNavigation
        title="All Coupons"
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="sm"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, 'user')}
          />
        }
        ChildrenElement={
          <View pt={0} p="2xl">
            <Input
              value={`${couponCode}`}
              placeholder="Enter coupon code..."
              state={InputStates.default}
              onChangeText={(i) => {
                setCouponCode(i);
              }}
              autoCapitalize="characters"
              rightTextName="Apply"
              rightIconColor={
                couponCode?.length > 3 ? 'info.500' : 'primary.100'
              }
              onRightIconClick={() =>
                couponCode?.length > 3
                  ? (Keyboard.dismiss(),
                    handleCouponSelection(couponCode),
                    firebaseEventLogger('couponScreen__applySelfTap_Tap', {
                      buttonName: 'applySelfTap',
                      screenName: 'couponScreen',
                      userType: 'user',
                      interactionType: 'Tap',
                      platform: Platform.OS,
                      orderType,
                      couponCode,
                    }))
                  : null
              }
            />
          </View>
        }
      />
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<CouponsPlaceholder />}>
            <CouponsPage
              queryOptions={refreshedQueryOptions}
              refresh={refresh}
              refreshing={refreshing}
              variables={variables}
              netPriceVariables={netPriceVariables}
              orderType={orderType}
            />
          </Suspense>
        </NewErrorBoundary>
      </View>
      <DisconnectedDropover
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default CouponsComponent;
