import React, { useRef, useState } from 'react';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { Chip, Icon, Pressable, Text, View } from '../../../../components/new';
import { Appearance, FontWeights, State } from '../../../../themes/new/helper';
import InvestmentBlurView from './InvestmentBlurView';
import {
  comparisonArray,
  realEstate32ND,
} from '../../../../utilities/InvestmentConstants';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';

const InvestmentComparisonComp = ({
  bgColor = '',
  data,
  consentGiven,
  onPressUnlock = () => {},
}) => {
  const bottomSheetModalComparison = useRef<ActionSheetRef>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleOnDismiss = () => {};

  return (
    <View bg={bgColor}>
      <InvestmentBlurView
        blurHeight="100%"
        blurWidth="100%"
        showBlur={!consentGiven}
        iconSize="6xl"
        onPressUnlock={onPressUnlock}
      />
      <View py="9xl" px="2xl">
        {data?.heading && (
          <Text size="xs" color="primary.300" pb="lg" textAlign="center">
            {data?.heading}
          </Text>
        )}
        {data?.subHeading && (
          <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
            {data?.subHeading}
          </Text>
        )}
        <View flexDirection="row" my="5xl">
          <View
            flex={1}
            alignItems="center"
            borderRightWidth={1}
            borderColor="grey.50"
          >
            <Chip
              key="inv_comparison_32nd_real_estate"
              state={State.ACTIVE}
              onPress={() => null}
              size="md"
              isUpperCase={false}
              appearance={Appearance.FILLED}
            >
              32nd Real Estate
            </Chip>
            {realEstate32ND.map((item) => (
              <View
                key={`realEstate32ND${item?.key}`}
                alignItems="center"
                mt="4xl"
              >
                <Text color="primary.300" size="xs">
                  {item?.key}
                </Text>
                <Text color="success.500" size="md">
                  {item?.value}
                </Text>
              </View>
            ))}
          </View>
          <View flex={1} alignItems="center">
            <Chip
              key="inv_comparison_other"
              state={State.SELECTED}
              onPress={() => bottomSheetModalComparison?.current?.show()}
              size="md"
              iconRight="down-outline-300"
              isUpperCase={false}
              appearance={Appearance.FILLED}
            >
              {comparisonArray?.[selectedIndex]?.shortName}
            </Chip>
            {comparisonArray?.[selectedIndex]?.data.map((item) => (
              <View
                key={`realEstate32ND${item?.key}`}
                alignItems="center"
                mt="4xl"
              >
                <Text color="primary.300" size="xs">
                  {item?.key}
                </Text>
                <Text color="warning.500" size="md">
                  {item?.value}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </View>
      <BottomSheetV2
        ref={bottomSheetModalComparison}
        onClose={handleOnDismiss}
        insets
      >
        <View px="2xl">
          <View borderBottomWidth={1} borderColor="grey.100" mt="3xl">
            <Text color="primary.300" size="xl" pb="lg">
              Compare Investments with 32nd
            </Text>
          </View>
          {comparisonArray?.map((item, index) => {
            return (
              <Pressable
                onPress={() => {
                  setSelectedIndex(index);
                  bottomSheetModalComparison?.current?.hide();
                }}
                disabled={index === selectedIndex}
                key={`bottomSheetModalComparison_${item?.id}`}
              >
                <View
                  mt="5xl"
                  mb={index === comparisonArray?.length - 1 ? '7xl' : 0}
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text
                    color={
                      selectedIndex === index ? 'primary.500' : 'primary.300'
                    }
                    size="sm"
                    flex={1}
                    weight={
                      selectedIndex === index
                        ? FontWeights.MEDIUM
                        : FontWeights.REGULAR
                    }
                  >
                    {item?.title}
                  </Text>
                  <Icon
                    name={
                      selectedIndex === index
                        ? 'radiobutton-checked-outline-300'
                        : 'radiobutton-unchecked-outline-300'
                    }
                    size="2xl"
                    color={
                      selectedIndex === index ? 'primary.500' : 'primary.300'
                    }
                  />
                </View>
              </Pressable>
            );
          })}
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default InvestmentComparisonComp;
