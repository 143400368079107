import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { Button, IconButton, Input, Layout, Text, View } from '../new';
import { InputStates } from '../new/primitive/Input/helpers';
import validateWrapper from '../../utilities/validationWrapper';
import { MobilePayload } from '../AuthModalComponents/SignupScreen';
import {
  AddUserPayload,
  addUserV2,
  generateOTP,
  LoginUserPayload,
  loginUserV2,
} from '../../relay/authApi';
import useLoginFlowStore, {
  LoginFlowScreenSteps,
} from '../../stores/loginFlowStore';
import findCampusLocation from '../../relay/campusLocationApi';
import useAuthStore from '../../stores/authStore';
import useUserStore from '../../stores/userStore';
import useCommonStore from '../../stores/commonStore';
import useLoginModalStore from '../../stores/loginModalStore';
import VideoMomentsStore from '../../stores/VideoMomentsStore';

const Signup = () => {
  const componentState = useLoginFlowStore((state) => state);

  const getSavedUserName = () => {
    const firstName = componentState?.signupData?.name?.first?.trim() || '';
    const lastName = componentState?.signupData?.name?.last?.trim() || '';
    const fullName = firstName + (lastName ? ` ${lastName}` : '');

    return fullName;
  };
  const savedUserName = getSavedUserName();

  const navigation = useNavigation<any>();

  const [userErrorText, setUserErrorText] = useState('');
  const [userName, setUserName] = useState(savedUserName);

  const [buttonDisabled, setButtonDisabled] = useState(!savedUserName?.length);

  const handleUserName = (fullName) => {
    const error = validateWrapper('fullName', fullName);
    setUserName(fullName);
    if (error) {
      setUserErrorText(error);
      setButtonDisabled(true);
    } else {
      setUserErrorText('');
      setButtonDisabled(false);
    }
  };
  const handleGenerateOtp = (response, data) => {
    setButtonDisabled(false);
    if (response.generateOTP !== null) {
      componentState.setCurrentScreen(LoginFlowScreenSteps.ENTER_OTP);
      navigation.navigate('enterOtp');
    } else {
      setUserErrorText('Please enter a valid user name.');
      setButtonDisabled(true);
    }
  };

  const validationClear = () => {
    setUserErrorText('');
  };

  useEffect(() => {
    return () => {
      componentState.setSignupPayload({
        name: { first: '', last: '' },
      });
    };
  }, []);

  const redirectUri = useAuthStore((state) => state.redirectUri);
  const setRedirectUri = useAuthStore((state) => state.setRedirectUri);

  const setUserDetails = useUserStore((state) => state.setUserDetails);
  const setLoginDetails = useAuthStore((state) => state.setLoginDetails);
  const setCampusId = useCommonStore((state) => state.setCampusId);
  const commonStore = useCommonStore((state) => state);
  const loginModalStore = useLoginModalStore((state) => state);
  const setScannedS3Imagekey = VideoMomentsStore(
    (state) => state.setScannedS3Imagekey,
  );
  const handleLoginUser = (response) => {
    if (response?.loginUser?.record?.photoMomentsSearchImage) {
      setScannedS3Imagekey(response.loginUser.record.photoMomentsSearchImage);
    }
    setUserDetails(
      response?.loginUser?.record?.name?.first,
      response?.loginUser?.record?.name?.last,
      response?.loginUser?.record?.role?.name,
      response?.loginUser?.record?.role?.permission,
      response?.loginUser?.record?._id,
      response?.loginUser?.record?.contact,
      response?.loginUser?.record?.role?.name,
      response?.loginUser?.record?.role?.secondaryRole,
      response?.loginUser?.record?.profileImage,
    );
    setLoginDetails(
      true,
      response.loginUser.record.token,
      response.loginUser.record.refreshToken,
    );
    const { name, permission } = response.loginUser.record.role;

    const campusID = response.loginUser.record.role.meta?.campus?._id;

    if (name !== 'user') {
      if (campusID) {
        setCampusId(campusID);
      }
    }
    if (name === 'user') {
      if (Platform.OS !== 'web') {
        loginModalStore.setShowWelcomeModal(true);
        commonStore.setWasSignupFlowActive(false);
      }
      // if (response?.loginUser?.record?.firstLoginParkingReward === true) {
      // setTimeout(() => {
      //   setShowFirstLoginParkingRewardModal(true);
      // }, 500);
      // }
      findCampusLocation({})
        .then((res) => {
          setCampusId(res?.findCampusLocation[0]?._id);
          if (redirectUri.screenName === 'order') {
            setRedirectUri({ screenName: '', params: {} });
            navigation.navigate('order', redirectUri.params);
          } else {
            navigation.navigate('user');
          }
        })
        .catch((err) => {});
    }
  };

  const onContinue = () => {
    const error = validateWrapper('fullName', userName);
    const mobileData: MobilePayload = {
      phone: {
        number: componentState.mobileNumber,
        prefix: `+${componentState.countryCallingCode}`,
      },
    };
    const data = {
      ...mobileData,
      name: {
        first: '',
        last: '',
      },
    };
    const nameArr = userName.trim().split(' ');
    if (nameArr.length > 1) {
      data.name.first = nameArr[0];
      data.name.last = nameArr.slice(1).join(' ').replace(/\s\s+/g, ' ').trim();
    } else {
      data.name.first = userName;
    }
    if (!error) {
      componentState.setSignupPayload({
        name: { first: data.name.first, last: data.name.last },
      });

      if (componentState.waId) {
        // signup whatsapp
        if (componentState.secondarySignup) {
          const loginPayload: LoginUserPayload = {
            name: {
              first: data.name.first,
              last: data.name.last,
            },
            contact: {
              phone: {
                number: componentState.mobileNumber,
                prefix: `+${componentState.countryCallingCode}`,
              },
            },
            pin: '6789',
            waId: componentState.waId,
          };
          loginUserV2(loginPayload)
            .then((res) => {
              handleLoginUser(res);
            })
            .catch((err) => {});
        } else {
          const addUserPayload: AddUserPayload = {
            contact: {
              phone: {
                number: componentState.mobileNumber,
                prefix: `+${componentState.countryCallingCode}`,
              },
            },
            name: { first: data.name.first, last: data.name.last },
            pin: '6789',
            waId: componentState.waId,
          };
          addUserV2(addUserPayload)
            .then((res) => {
              const loginPayload: LoginUserPayload = {
                contact: {
                  phone: {
                    number: componentState.mobileNumber,
                    prefix: `+${componentState.countryCallingCode}`,
                  },
                },
                pin: '6789',
                waId: componentState.waId,
              };
              return loginUserV2(loginPayload);
            })
            .then((res) => {
              handleLoginUser(res);
            })
            .catch((err) => {});
        }
      } else {
        setButtonDisabled(true);
        setUserErrorText('');
        generateOTP(data, handleGenerateOtp);
      }
    }
  };

  const handleOnBackPress = () => {
    if (componentState.waId) {
      navigation.replace('login');
    } else {
      navigation.navigate('enterPhone');
    }
  };

  return (
    <Layout level={2} style={styles.mainWrapper}>
      <SafeAreaView style={{ flex: 1 }} edges={['top', 'bottom']}>
        <View
          pb="2xl"
          pt="2xl"
          style={{
            paddingHorizontal: 16,
            borderBottomWidth: 1,
            borderBottomColor: '#d2d2d2',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <TouchableOpacity
              // @ts-ignore
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                transform: [{ translateY: -9 }],
              }}
              onPress={() => {}}
            >
              <IconButton
                name="back-outline-300"
                size="md"
                appearance="ghost"
                iconColor="primary.500"
                onPress={handleOnBackPress}
                shape={undefined}
                bg={undefined}
                state={undefined}
                iconSize={undefined}
                text={undefined}
              />
            </TouchableOpacity>
            <Text size="md" color="primary.400">
              32nd
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <View>
            <View>
              <Text size="3xl" color="primary.200" mb="16" mt="24">
                Sign Up
              </Text>
              <Input
                state={
                  userErrorText === '' ? InputStates.default : InputStates.error
                }
                inputMode="text"
                label="Full Name"
                placeholder="Your full name"
                helperText={userErrorText}
                value={userName}
                onChangeText={handleUserName}
                borderColor="primary.50"
                style={{ borderColor: 'white' }}
                onBlur={() => {
                  setUserErrorText('');
                }}
                autoFocus
                autoCapitalize="words"
                returnKeyType="done"
                onFocus={validationClear}
              />
            </View>
            <View bottom="xl" style={{ paddingVertical: 60 }}>
              <Button
                size="lg"
                appearance="filled"
                status="primary"
                state={
                  buttonDisabled ? InputStates.disabled : InputStates.active
                }
                onPress={onContinue}
              >
                Continue
              </Button>
            </View>
          </View>
        </View>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    flex: 1,
  },
  container: {
    paddingHorizontal: 16,
    flex: 1,
  },
});

export default Signup;
