/**
 * @generated SignedSource<<b1cba83bc59717fecd02b85251beaddb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumaddonAdditionalCashbackRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonAdditionalDiscountRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonPriceDiscountBaseDiscountType = "PERCENTAGE" | "%future added value";
export type EnumaddonType = "a_32ND_BLACK_MEMBERSHIP" | "%future added value";
export type FilterFindManyaddonInput = {
  AND?: ReadonlyArray<FilterFindManyaddonInput> | null;
  OR?: ReadonlyArray<FilterFindManyaddonInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyaddonOperatorsInput | null;
  additionalCashback?: ReadonlyArray<FilterFindManyaddonAdditionalCashbackInput | null> | null;
  additionalDiscount?: ReadonlyArray<FilterFindManyaddonAdditionalDiscountInput | null> | null;
  expiryDuration?: FilterFindManyaddonExpiryDurationInput | null;
  isMembershipType?: boolean | null;
  name?: string | null;
  price?: number | null;
  priceDiscount?: ReadonlyArray<FilterFindManyaddonPriceDiscountInput | null> | null;
  tier?: number | null;
  type?: EnumaddonType | null;
};
export type FilterFindManyaddonPriceDiscountInput = {
  _id?: any | null;
  baseDiscount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
};
export type FilterFindManyaddonPriceDiscountBaseDiscountInput = {
  _id?: any | null;
  type?: EnumaddonPriceDiscountBaseDiscountType | null;
  value?: number | null;
};
export type FilterFindManyaddonExpiryDurationInput = {
  _id?: any | null;
  days?: number | null;
};
export type FilterFindManyaddonAdditionalCashbackInput = {
  _id?: any | null;
  cashback?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refId?: any | null;
  refType?: EnumaddonAdditionalCashbackRefType | null;
};
export type FilterFindManyaddonAdditionalDiscountInput = {
  _id?: any | null;
  discount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refType?: EnumaddonAdditionalDiscountRefType | null;
};
export type FilterFindManyaddonOperatorsInput = {
  _id?: FilterFindManyaddon_idOperatorsInput | null;
  tier?: FilterFindManyaddonTierOperatorsInput | null;
  type?: FilterFindManyaddonTypeOperatorsInput | null;
};
export type FilterFindManyaddonTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnumaddonType | null;
  gte?: EnumaddonType | null;
  in?: ReadonlyArray<EnumaddonType | null> | null;
  lt?: EnumaddonType | null;
  lte?: EnumaddonType | null;
  ne?: EnumaddonType | null;
  nin?: ReadonlyArray<EnumaddonType | null> | null;
};
export type FilterFindManyaddonTierOperatorsInput = {
  exists?: boolean | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number | null> | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
  nin?: ReadonlyArray<number | null> | null;
};
export type FilterFindManyaddon_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type blackMembershipProfileQuery$variables = {
  addonFilter?: FilterFindManyaddonInput | null;
  userId: any;
};
export type blackMembershipProfileQuery$data = {
  readonly findAddons: ReadonlyArray<{
    readonly _id: any;
    readonly additionalDiscount: ReadonlyArray<{
      readonly discount: {
        readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
        readonly value: number | null;
      };
      readonly refType: EnumaddonAdditionalDiscountRefType;
    } | null> | null;
    readonly expiryDuration: {
      readonly days: number;
    } | null;
    readonly price: number;
    readonly type: EnumaddonType;
  }>;
  readonly findUserById: {
    readonly " $fragmentSpreads": FragmentRefs<"blackMembershipProfileQueryUserFragment">;
  } | null;
  readonly getUserPaymentStats: {
    readonly " $fragmentSpreads": FragmentRefs<"blackMembershipProfileQueryPaymentStatsFragment">;
  } | null;
};
export type blackMembershipProfileQuery = {
  response: blackMembershipProfileQuery$data;
  variables: blackMembershipProfileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addonFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_id",
    "variableName": "userId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filter",
      "variableName": "addonFilter"
    }
  ],
  "concreteType": "addon",
  "kind": "LinkedField",
  "name": "findAddons",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "addonExpiryDuration",
      "kind": "LinkedField",
      "name": "expiryDuration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "days",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "addonAdditionalDiscount",
      "kind": "LinkedField",
      "name": "additionalDiscount",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "addonPriceDiscountBaseDiscount",
          "kind": "LinkedField",
          "name": "discount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saved",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "blackMembershipProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "findUserById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "blackMembershipProfileQueryUserFragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPaymentStats",
        "kind": "LinkedField",
        "name": "getUserPaymentStats",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "blackMembershipProfileQueryPaymentStatsFragment"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "blackMembershipProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "findUserById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserWallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "moneySaved",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "earnedAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMemberships",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "membershipAddonId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiryDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPaymentStats",
        "kind": "LinkedField",
        "name": "getUserPaymentStats",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPaymentStatsBreakup",
            "kind": "LinkedField",
            "name": "breakup",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orderType",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "4e875738e0ef91d911300f65ad08ee68",
    "id": null,
    "metadata": {},
    "name": "blackMembershipProfileQuery",
    "operationKind": "query",
    "text": "query blackMembershipProfileQuery(\n  $userId: MongoID!\n  $addonFilter: FilterFindManyaddonInput\n) {\n  findUserById(_id: $userId) {\n    ...blackMembershipProfileQueryUserFragment\n  }\n  getUserPaymentStats {\n    ...blackMembershipProfileQueryPaymentStatsFragment\n  }\n  findAddons(filter: $addonFilter) {\n    _id\n    type\n    price\n    expiryDuration {\n      days\n    }\n    additionalDiscount {\n      refType\n      discount {\n        value\n        type\n      }\n    }\n  }\n}\n\nfragment blackMembershipProfileQueryPaymentStatsFragment on UserPaymentStats {\n  invoiceCount\n  saved\n  breakup {\n    orderType\n    invoiceCount\n    saved\n  }\n}\n\nfragment blackMembershipProfileQueryUserFragment on User {\n  wallet {\n    totalBalance\n    currentBalance\n    moneySaved\n    earnedAmount\n  }\n  memberships {\n    membershipAddonId\n    status\n    expiryDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "bac77cac20be9da4d610b4cee8a4463f";

export default node;
