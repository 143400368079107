import React from 'react';
import { useWindowDimensions } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import { View, Card, Avatar, Text } from '../../../components/new';
import { getTheme } from '../../../themes/new/theme';

interface ValetQrCodeCardProps {
  imageLink: string;
  fullName: string;
  _id: string;
  identityCode: string;
  qrCodeAspectRatio: number;
}

const ValetQrCodeCard = ({
  imageLink = 'https://ik.imagekit.io/32ndhub/assets/nouser.png',
  fullName = '',
  _id = '',
  identityCode = '',
  qrCodeAspectRatio = 0.73,
}: ValetQrCodeCardProps) => {
  const { width } = useWindowDimensions();
  return (
    <Card category="none" appearance="outline" mt="9xl+9xl+3xl">
      <View flex={1} alignItems="center" justifyContent="center">
        <View position="absolute" top={-50}>
          <Avatar level={3} source={imageLink} />
        </View>
      </View>
      <View mt={getTheme().space['6xl+3xl'] + getTheme().space.lg}>
        <Text size="xl" weight="medium" color="primary.300" textAlign="center">
          {identityCode}
        </Text>
        <Text size="md" color="primary.200" mt="sm" textAlign="center">
          {fullName}
        </Text>
      </View>
      <View width="100%" justifyContent="center" alignItems="center" my="9xl">
        <QRCode value={_id} size={width * qrCodeAspectRatio} />
      </View>
    </Card>
  );
};

export default ValetQrCodeCard;
