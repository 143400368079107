import React, { useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import BottomScoreCard from '../../../components/new/primitive/ScoreCard/BottomScoreCard';
import { View } from '../../../components/new';
import fetchValetDailyBonus from '../API/valetDailyBonusQuery';

const BottomBonusBoard = ({ fetchKey }) => {
  const [bonus, setBonus] = useState({
    bonusAmount: '0',
    onTimeTaskCount: '0',
  });

  const getColor = (tasks) => {
    if (tasks > 0) {
      return 'success.500';
    }
    return 'primary.500';
  };

  useFocusEffect(
    React.useCallback(() => {
      const today = moment().toISOString();
      fetchValetDailyBonus({
        date: today,
      }).then(({ dailyValetTaskBonus }) => {
        if (dailyValetTaskBonus) {
          setBonus({
            bonusAmount: dailyValetTaskBonus?.bonus?.bonusAmount ?? '0',
            onTimeTaskCount: dailyValetTaskBonus?.bonus?.onTimeTaskCount ?? '0',
          });
        }
      });
    }, [setBonus, fetchKey]),
  );

  const { bonusAmount, onTimeTaskCount } = bonus;

  return (
    <View justifyContent="flex-end">
      <BottomScoreCard
        scorecard1={{
          title1: "Today's Bonus",
          title2: `₹${bonusAmount}`,
          title2Color: getColor(onTimeTaskCount),
        }}
        scorecard2={{
          title1: 'On-Time Tasks',
          title2: onTimeTaskCount,
          title2Color: getColor(onTimeTaskCount),
        }}
      />
    </View>
  );
};

export default BottomBonusBoard;
