import React from 'react';
import { Avatar, Divider, Text, View } from '../../../../components/new';

const upComp = <View mb="9xl" />;
const downComp = <View mb="9xl" />;

const TaskJourneyStep = ({
  step = '',
  up = false,
  down = false,
  timeStr = '',
  title = '',
  subText = '',
}) => {
  return (
    <View>
      {!!timeStr && (
        <View flexDirection="row">
          <View
            borderRadius={999}
            borderWidth="xs"
            borderColor="primary.50"
            px="xl"
          >
            <Text size="2xs" textAlign="center">
              {timeStr}
            </Text>
          </View>
        </View>
      )}
      <View flexDirection="row">
        <View>
          <View flexDirection="row">
            <View flex={1}>
              <Divider horizontal />
            </View>
            {!!up && upComp}
          </View>

          <View flexDirection="row">
            <View>
              <Avatar name={step} />
            </View>
          </View>

          <View flexDirection="row">
            <View flex={1}>
              <Divider horizontal />
            </View>
            {!!down && downComp}
          </View>
        </View>

        <View ml="3xl">
          {!!up && upComp}
          <Text size="sm" color="primary.300">
            {title}
          </Text>
          <Text size="xs" color="primary.100">
            {subText}
          </Text>
          {!!down && downComp}
        </View>
      </View>
    </View>
  );
};

export default TaskJourneyStep;
