/**
 * @generated SignedSource<<0cfa6f424fb1cf853d164edb8e3a5855>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillUploadStatusEnum = "CREATED" | "MATCHED" | "PROCESSED" | "PROCESSING" | "PROCESSING_FAILED" | "UNMATCHED" | "UNVERIFIED" | "VERIFICATION_FAILED" | "VERIFIED" | "%future added value";
export type EnumbillUploadFindBillTCUserInputsOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumbillUploadVerifiedDetailsOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type SortConnectionbillUploadFindBillTCEnum = "_ID_ASC" | "_ID_DESC" | "%future added value";
export type FilterFindManybillUploadFindBillTCInput = {
  AND?: ReadonlyArray<FilterFindManybillUploadFindBillTCInput> | null;
  OR?: ReadonlyArray<FilterFindManybillUploadFindBillTCInput> | null;
  _id?: any | null;
  _operators?: FilterFindManybillUploadFindBillTCOperatorsInput | null;
  approvalNotes?: FilterFindManybillUploadApprovalNotesInput | null;
  created_at?: any | null;
  invoiceId?: any | null;
  isUserSubmit?: boolean | null;
  ocrResponseParsed?: FilterFindManybillUploadFindBillTCOcrResponseParsedInput | null;
  posVerificationRetries?: FilterFindManybillUploadFindBillTCPosVerificationRetriesInput | null;
  processing?: FilterFindManybillUploadProcessingInput | null;
  status?: ReadonlyArray<BillUploadStatusEnum | null> | null;
  updated_at?: any | null;
  userId?: any | null;
  userInputs?: FilterFindManybillUploadFindBillTCUserInputsInput | null;
  verifiedDetails?: FilterFindManybillUploadVerifiedDetailsInput | null;
};
export type FilterFindManybillUploadFindBillTCUserInputsInput = {
  billImage?: FilterFindManybillUploadFindBillTCUserInputsBillImageInput | null;
  orderId?: any | null;
  orderType?: EnumbillUploadFindBillTCUserInputsOrderType | null;
  priceDetails?: FilterFindManybillUploadFindBillTCUserInputsPriceDetailsInput | null;
};
export type FilterFindManybillUploadFindBillTCUserInputsPriceDetailsInput = {
  netAmount?: number | null;
};
export type FilterFindManybillUploadFindBillTCUserInputsBillImageInput = {
  bucket?: string | null;
  key?: string | null;
};
export type FilterFindManybillUploadFindBillTCOcrResponseParsedInput = {
  address?: FilterFindManybillUploadFindBillTCOcrResponseParsedAddressInput | null;
  bill?: FilterFindManybillUploadFindBillTCOcrResponseParsedBillInput | null;
  vendor?: FilterFindManybillUploadFindBillTCOcrResponseParsedVendorInput | null;
};
export type FilterFindManybillUploadFindBillTCOcrResponseParsedAddressInput = {
  addressBlock?: string | null;
  city?: string | null;
  full?: string | null;
  street?: string | null;
  zipCode?: string | null;
};
export type FilterFindManybillUploadFindBillTCOcrResponseParsedVendorInput = {
  gstNumber?: string | null;
  name?: string | null;
};
export type FilterFindManybillUploadFindBillTCOcrResponseParsedBillInput = {
  billId?: string | null;
  date?: any | null;
  finalAmount?: number | null;
  subTotal?: number | null;
};
export type FilterFindManybillUploadProcessingInput = {
  _id?: any | null;
  msg?: string | null;
  success?: boolean | null;
};
export type FilterFindManybillUploadVerifiedDetailsInput = {
  _id?: any | null;
  orderId?: any | null;
  orderType?: EnumbillUploadVerifiedDetailsOrderType | null;
  posData?: FilterFindManybillUploadVerifiedDetailsPosDataInput | null;
  priceDetails?: FilterFindManybillUploadVerifiedDetailsPriceDetailsInput | null;
};
export type FilterFindManybillUploadVerifiedDetailsPriceDetailsInput = {
  netAmount?: number | null;
};
export type FilterFindManybillUploadVerifiedDetailsPosDataInput = {
  _id?: any | null;
  billId?: string | null;
  posType?: string | null;
  refId?: any | null;
};
export type FilterFindManybillUploadFindBillTCPosVerificationRetriesInput = {
  retryCount?: number | null;
  totalCount?: number | null;
};
export type FilterFindManybillUploadApprovalNotesInput = {
  _id?: any | null;
  approvedBy?: any | null;
  data?: FilterFindManybillUploadApprovalNotesDataInput | null;
  isApproved?: boolean | null;
  message?: string | null;
};
export type FilterFindManybillUploadApprovalNotesDataInput = {
  _id?: any | null;
  bill?: FilterFindManybillUploadApprovalNotesDataBillInput | null;
};
export type FilterFindManybillUploadApprovalNotesDataBillInput = {
  billId?: string | null;
};
export type FilterFindManybillUploadFindBillTCOperatorsInput = {
  _id?: FilterFindManybillUploadFindBillTC_idOperatorsInput | null;
};
export type FilterFindManybillUploadFindBillTC_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type getBillUploadStatusCountFilter = {
  created_at: any;
};
export type billUploadViewerApiQuery$variables = {
  count: number;
  countFilter?: getBillUploadStatusCountFilter | null;
  cursor?: string | null;
  filter?: FilterFindManybillUploadFindBillTCInput | null;
  sort?: SortConnectionbillUploadFindBillTCEnum | null;
};
export type billUploadViewerApiQuery$data = {
  readonly getBillUploadStatusCount: {
    readonly VERIFICATION_FAILED: number;
    readonly VERIFIED: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"billUploadViewerApiQuery__findBillsFragment">;
};
export type billUploadViewerApiQuery = {
  response: billUploadViewerApiQuery$data;
  variables: billUploadViewerApiQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v6 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filter",
      "variableName": "countFilter"
    }
  ],
  "concreteType": "billUploadStatusCountTC",
  "kind": "LinkedField",
  "name": "getBillUploadStatusCount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "VERIFIED",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "VERIFICATION_FAILED",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "billUploadViewerApiQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "billUploadViewerApiQuery__findBillsFragment"
      },
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "billUploadViewerApiQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "billUploadFindBillTCConnection",
        "kind": "LinkedField",
        "name": "findBills",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "billUploadFindBillTCEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "billUploadFindBillTC",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "billUploadFindBillTCUserInputs",
                    "kind": "LinkedField",
                    "name": "userInputs",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "orderType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "orderId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "billUploadFindBillTCUserInputsPriceDetails",
                        "kind": "LinkedField",
                        "name": "priceDetails",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "netAmount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "billUploadFindBillTCUserInputsBillImage",
                        "kind": "LinkedField",
                        "name": "billImage",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bucket",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "billUploadFindBillTCOcrResponseParsed",
                    "kind": "LinkedField",
                    "name": "ocrResponseParsed",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "billUploadFindBillTCOcrResponseParsedBill",
                        "kind": "LinkedField",
                        "name": "bill",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "restaurants2",
                    "kind": "LinkedField",
                    "name": "restaurant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [
          "filter",
          "sort"
        ],
        "handle": "connection",
        "key": "billUploadViewerApiQuery__findBillsFragmentQuery__findBills",
        "kind": "LinkedHandle",
        "name": "findBills"
      },
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "7256ead903a7681cc0d5b4dca5007216",
    "id": null,
    "metadata": {},
    "name": "billUploadViewerApiQuery",
    "operationKind": "query",
    "text": "query billUploadViewerApiQuery(\n  $filter: FilterFindManybillUploadFindBillTCInput\n  $sort: SortConnectionbillUploadFindBillTCEnum\n  $count: Int!\n  $cursor: String\n  $countFilter: getBillUploadStatusCountFilter\n) {\n  ...billUploadViewerApiQuery__findBillsFragment_1GWUVg\n  getBillUploadStatusCount(filter: $countFilter) {\n    VERIFIED\n    VERIFICATION_FAILED\n  }\n}\n\nfragment billUploadViewerApiQuery__findBillsFragment_1GWUVg on Query {\n  findBills(filter: $filter, sort: $sort, first: $count, after: $cursor) {\n    edges {\n      node {\n        _id\n        userInputs {\n          orderType\n          orderId\n          priceDetails {\n            netAmount\n          }\n          billImage {\n            bucket\n            key\n          }\n        }\n        status\n        ocrResponseParsed {\n          bill {\n            date\n            billId\n          }\n        }\n        restaurant {\n          displayName\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9ec6a7bb7066c69ee3d0ad5a1902829";

export default node;
