import { useState, useEffect } from 'react';
import { Audio } from 'expo-av';

const useSound = ({ soundOrigin }) => {
  const [soundState, setSoundState] = useState(new Audio.Sound());

  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(soundOrigin);
    setSoundState(sound);

    await sound.playAsync();
  }

  useEffect(() => {
    return soundState
      ? () => {
          soundState.unloadAsync();
        }
      : undefined;
  }, [soundState]);

  return { playSound };
};

export default useSound;
