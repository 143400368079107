import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  TextInput,
  Keyboard,
  useWindowDimensions,
  Pressable,
} from 'react-native';
import * as ScreenOrientation from 'expo-screen-orientation';
import Constants from 'expo-constants';
import { useNavigation } from '@react-navigation/native';
import { View, Text } from '../../../../../components/new';
import { searchParking } from '../../../../../relay/parkingApi';
import { useSnackbarStore } from '../../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../../components/new/primitive/snackbar/helpers/helpers';
import {
  MembershipTypes,
  maskedNumber,
  minutesDifferenceInDate,
  taskTypes,
} from '../../../../../utilities/helper';
import useCommonStore from '../../../../../stores/commonStore';
import DisconnectedDropover from '../../../../../components/disconnectedpopover.component';
import { isValueNullOrEmpty } from '../../../../../utilities/Utility';
/** @ts-ignore */
import OTAConfig from '../../../../../../react-native.config';
import RecallWelcomeScreen from './RecallWelcomeScreen';
import ScanCardScreen from './ScanCardScreen';
import FetchingCarDetailsScreen from './FetchingCarDetailsScreen';
import CommonLayout from '../components/CommonLayout';
import {
  KioskTheme,
  useKioskStore,
} from '../../../../../stores/kiosk/kioskStore';
import { kiosk } from '../../../../../utilities/Constant';
import { analytics, extractEventData } from '../analytics';
import { fetchAddons } from './findAddonsQuery';

const getMins = (task) => {
  if (task) {
    const mins = minutesDifferenceInDate(task.ETA?.delayAt ?? '');
    return Math.abs(Math.max(-16, Math.min(-1, mins)));
  }
  return 1;
};

const { SCANNED_STRING_LENGTH } = kiosk;

const RecallInitiateScreen = () => {
  const navigation = useNavigation<any>();
  const darkModeEnabled = useKioskStore((state) => state.darkModeEnabled);
  const currentTheme = useKioskStore((state) => state.theme);
  const setDarkTheme = useKioskStore((state) => state.setDarkTheme);
  const setLightTheme = useKioskStore((state) => state.setLightTheme);
  const { version } = Constants.expoConfig;
  const [hiddenInput, sethiddenInput] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showScanCardScreen, setShowScanCardScreen] = useState(false);
  const [netStatus, setNetStatus] = useState(true);
  const campusID = useCommonStore((state) => state.campusId);
  const inputRef = useRef(null);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [addonsRef, setAddonsRef] = useState(null);
  const [showFetchingCarDetailsScreen, setShowFetchingCarDetailsScreen] =
    useState(false);
  const { resetRecallData, setParking, setActiveTask } = useKioskStore(
    (state) => state,
  );

  const showWelcomeScreen =
    !disabled && !showScanCardScreen && !showFetchingCarDetailsScreen;

  let { width, height } = useWindowDimensions();
  width = width >= 1024 ? 1024 : width;
  height = height >= 768 ? 768 : height;

  const lockScreenOrientation = async () => {
    await ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock.PORTRAIT_UP,
    );
  };

  useEffect(() => {
    if (showWelcomeScreen) {
      if (darkModeEnabled) {
        if (currentTheme !== KioskTheme.DARK) setDarkTheme();
      } else if (currentTheme !== KioskTheme.LIGHT) setLightTheme();
    }
  }, [showWelcomeScreen, darkModeEnabled, currentTheme]);

  useEffect(() => {
    resetRecallData();
    fetchAddons({
      type: MembershipTypes.PLUS_MEMBERSHIP,
    })
      .then((res) => {
        if (Array.isArray(res?.findAddons) && res?.findAddons?.length) {
          setAddonsRef(res?.findAddons);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    focusHiddenInput();
    lockScreenOrientation();
  }, []);

  const focusHiddenInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const navigateAfterSearch = (parking, tagId) => {
    const lastTask = parking.tasks?.slice(-1)[0];
    const { taskType } = lastTask;
    setParking(parking);
    setActiveTask(lastTask);
    const routeParams: Record<string, any> = {
      tagId,
      isFreshRecall: false,
      eventData: extractEventData(parking ?? {}),
    };

    const noCarFoundScreenRouteParams = {
      ...routeParams,
      parking: {
        ownerContact:
          `+91${maskedNumber(parking?.owner?.contact?.phone?.number)}` ?? '',
        isClubMember: parking?.owner?.contact?.phone?.verified,
        arrivalTime: getMins(lastTask),
        totalAmount: parking?.parkingInvoice?.charges?.totalPayable ?? 100,
        discountAmount: parking?.parkingInvoice?.charges?.discountAmount ?? 0,
      },
    };

    switch (taskType) {
      case taskTypes.park:
      case taskTypes.repark: {
        navigation.replace('reviewCarDetails');
        break;
      }
      case taskTypes.recall:
        if (
          lastTask.history.slice(-1)[0].action === 'completed' ||
          lastTask.history.slice(-1)[0].action === 'exited'
        ) {
          navigation.replace('noCarFound', noCarFoundScreenRouteParams);
        } else {
          delete routeParams?.eventData;
          navigation.replace('recallStatus', routeParams);
        }
        break;
      default: {
        navigation.replace('noCarFound', noCarFoundScreenRouteParams);
      }
    }
  };

  const handleSearchResult = (res, screen: 'welcome' | 'scanTag', tagId) => {
    if (res.searchParking.length) {
      if (screen === 'welcome')
        analytics.welcomeScreen.scanSuccessful(res.searchParking[0]);
      if (screen === 'scanTag')
        analytics.scanTagScreen.scanSuccessful(res.searchParking[0]);
      setDisabled(false);
      navigateAfterSearch(res.searchParking[0], tagId);
    } else {
      sethiddenInput('');
      // setShowGif(false);
      setDisabled(false);
      focusHiddenInput();
      if (screen === 'welcome') analytics.welcomeScreen.invalidBarcodeScanned();
      if (screen === 'scanTag') analytics.scanTagScreen.invalidBarcodeScanned();
      dispatchSnackbar({
        msg: 'Invalid Barcode, please try again',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v1,
      });
    }
  };

  const makeApiRequest = (tagId: string) => {
    let screen: 'welcome' | 'scanTag' = 'welcome';
    if (showWelcomeScreen) screen = 'welcome';
    else if (!disabled && showScanCardScreen) {
      screen = 'scanTag';
    }
    if (!disabled) {
      if (screen === 'welcome') analytics.welcomeScreen.scanInitiated();
      if (screen === 'scanTag') analytics.scanTagScreen.scanInitiated();

      setDisabled(true);
      Keyboard.dismiss();
      const data = {
        campus: { _id: campusID },
        search: { vehicleOrPhoneOrTag: tagId },
      };

      searchParking(data)
        .then((res) => {
          setShowFetchingCarDetailsScreen(false);
          handleSearchResult(res, screen, tagId);
        })
        .catch((err) => {
          if (screen === 'welcome') analytics.welcomeScreen.scanFailed();
          if (screen === 'scanTag') analytics.scanTagScreen.scanFailed();

          dispatchSnackbar({
            msg: 'Something went wrong, please try again',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
          focusHiddenInput();
          setShowFetchingCarDetailsScreen(false);
          setDisabled(false);
        });
    }
  };

  const handleBack = () => {
    focusHiddenInput();
    setDisabled(false);
    setShowScanCardScreen(false);
  };

  const handleBeginAction = () => {
    analytics.welcomeScreen.scanBegan();
    setShowScanCardScreen(true);
    focusHiddenInput();
  };

  if (hiddenInput.length > 1 && !showFetchingCarDetailsScreen) {
    // workaround for showing fetch screen early tather than waiting for all 61 chars to get typed
    setShowFetchingCarDetailsScreen(true);
  }

  if (hiddenInput.length > SCANNED_STRING_LENGTH) {
    sethiddenInput('');
    setShowFetchingCarDetailsScreen(false);
    setTimeout(() => focusHiddenInput(), 100);
  } else if (hiddenInput.length === SCANNED_STRING_LENGTH) {
    const inputValue = hiddenInput;
    sethiddenInput('');
    try {
      const url = new URL(inputValue);
      const qParams = new URLSearchParams(url.search);
      const tagId = qParams.get('valetTag');
      if (tagId && tagId.length === 6) {
        makeApiRequest(tagId);
      }
    } catch (e) {
      sethiddenInput('');
      setShowFetchingCarDetailsScreen(false);
      setTimeout(() => focusHiddenInput(), 100);
    }
  }

  return (
    <Pressable
      android_ripple={{ radius: 0 }}
      style={{ flex: 1 }}
      onPress={focusHiddenInput}
    >
      <CommonLayout>
        <View flex={1} width="100%" p="9xl">
          {showWelcomeScreen && (
            <RecallWelcomeScreen onBeginAction={handleBeginAction} />
          )}
          {!disabled && showScanCardScreen && !showFetchingCarDetailsScreen && (
            <ScanCardScreen onBack={handleBack} />
          )}
          {showFetchingCarDetailsScreen && addonsRef && (
            <FetchingCarDetailsScreen
              onBack={handleBack}
              addonRef={addonsRef}
            />
          )}
          <View position="absolute" top={-999}>
            <TextInput
              ref={inputRef}
              style={{
                width: 40,
                height: 40,
                margin: 12,
                borderWidth: 1,
                padding: 10,
                backgroundColor: '#fff',
              }}
              autoFocus
              showSoftInputOnFocus={false}
              onChangeText={sethiddenInput}
              value={hiddenInput}
            />
          </View>
        </View>
        <View position="absolute" bottom={10} right={15}>
          <Text m="md" color="primary.50">
            {!isValueNullOrEmpty(OTAConfig.ota)
              ? `${version}-${OTAConfig.ota}`
              : version}
          </Text>
        </View>
      </CommonLayout>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Pressable>
  );
};

export default RecallInitiateScreen;
