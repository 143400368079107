/**
 * @generated SignedSource<<d91653ae9e962c15fa4ba1d4699ea89a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumUserContactEmailType = "PERSONAL" | "WORK" | "%future added value";
export type EnumpopUpStoreDescriptionTagsIconColor = "error" | "info" | "success" | "%future added value";
export type findPopUpStoresFilterInput = {
  _id?: any | null;
  campus: FilterFindManypopUpStoreCampusInput;
  description?: FilterFindManypopUpStoreDescriptionInput | null;
  displayName?: string | null;
  ownerId?: any | null;
};
export type FilterFindManypopUpStoreDescriptionInput = {
  tags?: ReadonlyArray<FilterFindManypopUpStoreDescriptionTagsInput | null> | null;
};
export type FilterFindManypopUpStoreDescriptionTagsInput = {
  _id?: any | null;
  icon?: FilterFindManypopUpStoreDescriptionTagsIconInput | null;
  name?: string | null;
};
export type FilterFindManypopUpStoreDescriptionTagsIconInput = {
  _id?: any | null;
  color?: EnumpopUpStoreDescriptionTagsIconColor | null;
  iconName?: string | null;
};
export type FilterFindManypopUpStoreCampusInput = {
  _id?: any | null;
};
export type searchUsersFilterInput = {
  _id?: any | null;
  contact?: FilterFindManyUserContactInput | null;
  registration?: searchUsersFilterInputRegistration | null;
};
export type FilterFindManyUserContactInput = {
  _id?: any | null;
  email?: ReadonlyArray<FilterFindManyUserContactEmailInput | null> | null;
  phone?: FilterFindManyUserContactPhoneInput | null;
};
export type FilterFindManyUserContactPhoneInput = {
  _id?: any | null;
  number?: string | null;
  prefix?: string | null;
};
export type FilterFindManyUserContactEmailInput = {
  _id?: any | null;
  addr?: string | null;
  type?: EnumUserContactEmailType | null;
};
export type searchUsersFilterInputRegistration = {
  plate?: string | null;
};
export type partnerCashPaymentScreenQuery$variables = {
  filter?: findPopUpStoresFilterInput | null;
  searchFilter: searchUsersFilterInput;
};
export type partnerCashPaymentScreenQuery$data = {
  readonly findPopUpStores: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
      };
    }>;
  } | null;
  readonly searchUsers: ReadonlyArray<{
    readonly _id: any;
    readonly contact: {
      readonly phone: {
        readonly number: string;
        readonly prefix: string;
        readonly verified: boolean | null;
      };
    };
    readonly name: {
      readonly first: string;
      readonly last: string | null;
    };
    readonly parkingVisitCount: number | null;
    readonly profileImage: string | null;
  }>;
};
export type partnerCashPaymentScreenQuery = {
  response: partnerCashPaymentScreenQuery$data;
  variables: partnerCashPaymentScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchFilter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "popUpStoreConnection",
    "kind": "LinkedField",
    "name": "findPopUpStores",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "popUpStoreEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "popUpStore",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "searchFilter"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "searchUsers",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserName",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContactPhone",
            "kind": "LinkedField",
            "name": "phone",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verified",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingVisitCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "partnerCashPaymentScreenQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "partnerCashPaymentScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "272670352e681c47621078d7810c554e",
    "id": null,
    "metadata": {},
    "name": "partnerCashPaymentScreenQuery",
    "operationKind": "query",
    "text": "query partnerCashPaymentScreenQuery(\n  $filter: findPopUpStoresFilterInput\n  $searchFilter: searchUsersFilterInput!\n) {\n  findPopUpStores(filter: $filter) {\n    edges {\n      node {\n        _id\n      }\n    }\n  }\n  searchUsers(filter: $searchFilter) {\n    _id\n    name {\n      first\n      last\n    }\n    contact {\n      phone {\n        prefix\n        number\n        verified\n      }\n    }\n    profileImage\n    parkingVisitCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "cdf694c6568a6c7c42200bafa1d54bce";

export default node;
