/* eslint-disable import/prefer-default-export */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';

import { create } from 'zustand';
import teamScreenSlice, { teamScreenSliceReturnType } from './teamScreenSlice';

export const useTeamScreenStore = create<teamScreenSliceReturnType>()(
  devtools(
    persist(
      (...a) => ({
        ...teamScreenSlice(...a),
      }),
      {
        name: 'team-screen-storage',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
  ),
);
