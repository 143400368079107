import { graphql, requestSubscription } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

const cashierTaskUpdatedSubscription = graphql`
  subscription cashierTaskUpdatedSubscriptionApiSubscription(
    $campusId: ID!
    $userId: ID!
    $history: [EnumtaskHistoryAction]
    $event_at: Date
    $taskType: [EnumtaskTaskType]
  ) {
    taskUpdated(
      campusId: $campusId
      userId: $userId
      history: $history
      event_at: $event_at
      taskType: $taskType
    ) {
      _id
      vehicle {
        registration {
          plate
        }
      }
      isVehicleWhitelisted
      taskType
      campus {
        _id
      }
      history {
        _id
        action
        actor
        event_at
      }
      parking {
        _id
        status
        parkingTag
        parkingInvoice {
          timings {
            entryTime
            exitTime
            chargeTime
          }
          paymentDetails {
            isPaid
            mode
            focReason
            amount
          }
          charges {
            chargesBreakup {
              duration
              payable
            }
            discountAmount
            totalPayable
          }
        }
      }
      ETA {
        _id
        startAt
        delayAt
        originalPredictionTime
        pendingRecall
        available
        busy
      }
      parkingId
    }
  }
`;

export default (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: cashierTaskUpdatedSubscription,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      console.log('error 2', error);
      // console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
