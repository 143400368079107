import React, { memo } from 'react';
import SlotMachine from 'react-native-slot-machine';
import SlotContent from './SlotContent';
import {
  slotMachineRange,
  slotMachineRangeMapper,
} from './helpers/slotMachineHelpers';
import BarIcon from '../../../../../assets/images/slotMachineIcons/bar.webp';
import CarIcon from '../../../../../assets/images/slotMachineIcons/car.webp';
import CoinsIcon from '../../../../../assets/images/slotMachineIcons/coins.webp';
import fAndBIcon from '../../../../../assets/images/slotMachineIcons/fb.webp';
import giftIcon from '../../../../../assets/images/slotMachineIcons/gift.webp';
import retailIcon from '../../../../../assets/images/slotMachineIcons/retail.webp';
import shakeIcon from '../../../../../assets/images/slotMachineIcons/shake.webp';
import starIcon from '../../../../../assets/images/slotMachineIcons/star.webp';
import ticketsIcon from '../../../../../assets/images/slotMachineIcons/tickets.webp';

export const slotIcons = [
  { id: 0, img: BarIcon },
  { id: 1, img: CarIcon },
  { id: 2, img: CoinsIcon },
  { id: 3, img: fAndBIcon },
  { id: 4, img: giftIcon },
  { id: 5, img: retailIcon },
  { id: 6, img: shakeIcon },
  { id: 7, img: starIcon },
  { id: 8, img: ticketsIcon },
];

const AR_H = 0.293; // Slot height aspect ration wrt slot machine height
const AR_SLOT = 0.761; // aspect ration of single slot wrt slot Height (w/h)

const SlotMachineComp = ({ slotMachineDuration, value, smHeight }) => {
  const slotHeight = AR_H * smHeight;
  const slotWidth = AR_SLOT * slotHeight;

  return (
    <SlotMachine
      text={`${value}`}
      initialAnimation={false}
      range={slotMachineRange}
      renderContent={(props) => {
        return (
          <SlotContent
            {...props}
            imgInfo={slotIcons[slotMachineRangeMapper[props]]}
          />
        );
      }}
      duration={slotMachineDuration}
      height={slotHeight}
      width={slotWidth}
      padding={0}
      styles={{
        container: {
          backgroundColor: 'black',
          margin: 0,
        },
        slotWrapper: {
          margin: 0,
          overlay: 'none',
          padding: 0,
          marginRight: 0,
          marginLeft: 0,
        },
        slotInner: {
          padding: 0,
          margin: 0,
          overlay: 'none',
          backgroundColor: '#ffffff',
        },
        innerBorder: {
          borderWidth: 0,
        },
        outerBorder: {
          borderWidth: 1,
          borderColor: 'black',
        },
        overlay: {
          display: 'none',
          padding: 0,
          margin: 0,
        },
      }}
    />
  );
};

const arePropsEqual = (prev, current) => {
  if (prev.smHeight !== current.smHeight) return false;
  if (prev.value !== current.value) return false;
  return true;
};

export default memo(SlotMachineComp, arePropsEqual);
