/**
 * @generated SignedSource<<e1079f0479991ad55313f8d549c0c0ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type cashierCarSearchScreenQuery$variables = {
  taskFilter: findTaskFilterInput;
};
export type cashierCarSearchScreenQuery$data = {
  readonly findTasks: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"cashierCarSearchScreenTasksFragment">;
  }>;
};
export type cashierCarSearchScreenQuery = {
  response: cashierCarSearchScreenQuery$data;
  variables: cashierCarSearchScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "taskFilter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cashierCarSearchScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "findTasks",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "cashierCarSearchScreenTasksFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cashierCarSearchScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "findTasks",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "campusLocation",
            "kind": "LinkedField",
            "name": "campus",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskHistory",
            "kind": "LinkedField",
            "name": "history",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "event_at",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isVehicleWhitelisted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskETA",
            "kind": "LinkedField",
            "name": "ETA",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "delayAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "originalPredictionTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pendingRecall",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "available",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "busy",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Parking",
            "kind": "LinkedField",
            "name": "parking",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingInvoice",
                "kind": "LinkedField",
                "name": "parkingInvoice",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingParkingInvoiceTimings",
                    "kind": "LinkedField",
                    "name": "timings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entryTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exitTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "chargeTime",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingParkingInvoicePaymentDetails",
                    "kind": "LinkedField",
                    "name": "paymentDetails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPaid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "focReason",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingParkingInvoiceCharges",
                    "kind": "LinkedField",
                    "name": "charges",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                        "kind": "LinkedField",
                        "name": "chargesBreakup",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payable",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discountAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalPayable",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec7e8b23320f6e28f6990a2a9a33cddd",
    "id": null,
    "metadata": {},
    "name": "cashierCarSearchScreenQuery",
    "operationKind": "query",
    "text": "query cashierCarSearchScreenQuery(\n  $taskFilter: findTaskFilterInput!\n) {\n  findTasks(filter: $taskFilter) {\n    ...cashierCarSearchScreenTasksFragment\n  }\n}\n\nfragment cashierCarSearchScreenTasksFragment on task {\n  _id\n  vehicle {\n    registration {\n      plate\n    }\n  }\n  taskType\n  campus {\n    _id\n  }\n  history {\n    _id\n    action\n    actor\n    event_at\n  }\n  isVehicleWhitelisted\n  ETA {\n    _id\n    startAt\n    delayAt\n    originalPredictionTime\n    pendingRecall\n    available\n    busy\n  }\n  parking {\n    status\n    parkingInvoice {\n      timings {\n        entryTime\n        exitTime\n        chargeTime\n      }\n      paymentDetails {\n        isPaid\n        mode\n        focReason\n        amount\n      }\n      charges {\n        chargesBreakup {\n          duration\n          payable\n        }\n        discountAmount\n        totalPayable\n      }\n    }\n  }\n  parkingId\n}\n"
  }
};
})();

(node as any).hash = "b55d673f1cb38637fe03854347cc3216";

export default node;
