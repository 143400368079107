import React, { Suspense, useState } from 'react';
import { IconButton, Layout, TopNavigation } from '../../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import { Loading } from '../../../../components';
import { navigateBack } from '../../../../utilities/helper';
import EventCancellationScreenInner from './EventCancellationScreenInner';
import useUserStore from '../../../../stores/userStore';
import { eventAnalytics } from '../analytics/eventAnalytics';

const EventCancellationScreen = ({ navigation, route }) => {
  const role = useUserStore((state) => state.role);
  const { invoiceId } = route?.params ?? {};
  const [{ eventId, ticketId }, setAnalyticsData] = useState({
    eventId: '',
    ticketId: '',
  }); // for logging in analytics event
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });

  const [queryVariables] = useState({
    ticketFilter: {
      invoiceId,
    },
    invoiceFilter: {
      _id: invoiceId,
    },
  });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2}>
      <TopNavigation
        title="Cancel Booking"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              eventAnalytics.cancelBookingScreen.backClicked(eventId, ticketId);
              navigateBack(navigation, role);
            }}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions?.fetchKey}
        fallback={
          <NewErrorView
            reload={refresh}
            errorMsg="Sorry something went wrong"
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <EventCancellationScreenInner
            setAnalyticsData={(evId: string, tcId: string) =>
              setAnalyticsData({ eventId: evId, ticketId: tcId })
            }
            invoiceId={invoiceId}
            queryVariables={queryVariables}
            queryOptions={refreshedQueryOptions}
          />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default EventCancellationScreen;
