/**
 * @generated SignedSource<<501630ba235f6f4209eb317f4d9edb76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Referer = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type blackMembershipProfileQueryPaymentStatsFragment$data = {
  readonly breakup: ReadonlyArray<{
    readonly invoiceCount: number | null;
    readonly orderType: Referer;
    readonly saved: number | null;
  } | null> | null;
  readonly invoiceCount: number | null;
  readonly saved: number | null;
  readonly " $fragmentType": "blackMembershipProfileQueryPaymentStatsFragment";
};
export type blackMembershipProfileQueryPaymentStatsFragment$key = {
  readonly " $data"?: blackMembershipProfileQueryPaymentStatsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"blackMembershipProfileQueryPaymentStatsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saved",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "blackMembershipProfileQueryPaymentStatsFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPaymentStatsBreakup",
      "kind": "LinkedField",
      "name": "breakup",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderType",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UserPaymentStats",
  "abstractKey": null
};
})();

(node as any).hash = "6a0f1888497ca5f256cf9fd9ceef4f3e";

export default node;
