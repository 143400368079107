import React, { useState, useEffect } from 'react';
import { Linking } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { navigateBack } from '../../utilities/helper';
import Barcode from '../../components/new/custom/Barcode';
import useUserStore from '../../stores/userStore';
import { getTheme } from '../../themes/new/theme';
import {
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import DarkThemeWrapper from '../new/wrapperComp/DarkThemeWrapper';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

const BarCodeScannerScreen = ({ navigation, route }) => {
  const routeData = route.params;
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [scannedError, setScannedError] = useState(false);
  const [tourchMode, setTourchMode] = useState(false);
  const [borderColor, setBorderColor] = useState(getTheme().colors.info[500]);
  const { top } = useSafeAreaInsets();
  const userRole = useUserStore((state) => state.role);

  const RequestPermission = async () => {
    BarCodeScanner.requestPermissionsAsync()
      .then(({ status }) => {
        setHasPermission(status === 'granted');
      })
      .catch((err) => {
        setHasPermission(false);
      });
  };

  useEffect(() => {
    RequestPermission();
  }, []);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setScanned(true);
      setBorderColor(getTheme().colors.error[500]);
      setScannedError(true);
    }, 7000);
    return () => {
      clearInterval(timeoutID);
    };
  }, [scanned]);

  const handleBarCodeScanned = ({ type, data }) => {
    if (type === 256 || type === 'org.iso.QRCode') {
      if (typeof data !== 'string') return;
      setScanned(true);
      try {
        const url = new URL(data);
        const qParams = new URLSearchParams(url.search);
        const tagId = qParams.get('valetTag');
        if (
          tagId.length === 6 &&
          typeof Number(tagId) === 'number' &&
          !Number.isNaN(Number(tagId))
        ) {
          setBorderColor(getTheme().colors.success[500]);
          setTimeout(() => {
            navigation.replace('SearchParkingScreen', {
              searchText: tagId,
            });
          }, 200);
        } else {
          setScannedError(true);
          setBorderColor(getTheme().colors.error[500]);
        }
      } catch (e) {
        setScannedError(true);
        setBorderColor(getTheme().colors.error[500]);
      }
    }
  };

  if (hasPermission === false) {
    return (
      <View flex={1}>
        <TopNavigation
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigateBack(navigation, userRole)}
            />
          }
          appearance="ghost"
          level="1"
        />
        <View flex={1} justifyContent="center" alignItems="center" px="2xl">
          <CustomLottieView
            style={{ width: 200, height: 200 }}
            autoPlay
            loop
            source={require('../../../assets/lottie/no-camera.json')}
            containerWidth={200}
          />
          <Text size="2xl" weight="medium" color="primary.400" mt="4xl">
            Permission denied!
          </Text>
          <Text size="md" color="primary.200" mt="lg">
            Enable permission for camera in settings
          </Text>
          <View width="100%">
            <Button
              appearance="filled"
              status="primary"
              size="lg"
              mt="4xl"
              onPress={() => Linking.openSettings()}
            >
              Go To Settings
            </Button>
          </View>
        </View>
      </View>
    );
  }

  const onPressScanAgain = () => {
    setScanned(false);
    setScannedError(false);
    setBorderColor(getTheme().colors.info[500]);
  };

  const onPressAddManually = () => {
    navigation.replace('SearchParkingScreen', {
      autoFocus: 'true',
    });
  };

  return (
    <Layout level={2} edges={['top']}>
      {hasPermission ? (
        <Barcode
          scanQr
          scanned={scanned}
          scannedError={scannedError}
          handleBarCodeScanned={handleBarCodeScanned}
          tourchMode={tourchMode}
          borderColor={borderColor}
          onPressScanAgain={onPressScanAgain}
          onPressAddManually={onPressAddManually}
          TopNavigationComponent={
            <View
              style={{
                position: 'absolute',
                top: top + 32,
                backgroundColor: 'transparent',
                zIndex: 99,
                width: '100%',
              }}
            >
              <DarkThemeWrapper>
                <TopNavigation
                  appearance="ghost"
                  level=""
                  IconLeft={
                    <IconButton
                      name="back-outline-300"
                      size="md"
                      // appearance="ghost"
                      // iconColor="primary.500"
                      onPress={() => navigateBack(navigation, userRole)}
                    />
                  }
                  IconRight={
                    <IconButton
                      name={tourchMode ? 'flash-on-300' : 'flash-off-300'}
                      size="md"
                      // appearance="ghost"
                      // iconColor="primary.500"
                      onPress={() => setTourchMode(!tourchMode)}
                    />
                  }
                />
              </DarkThemeWrapper>
            </View>
          }
        />
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default BarCodeScannerScreen;
