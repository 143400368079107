import { graphql } from 'react-relay';

export const eventCancellationScreenQuery = graphql`
  query eventCancellationScreenQuery(
    $ticketFilter: FilterFindManyticketInput!
    $invoiceFilter: findInvoiceInput!
  ) {
    ...eventCancellationScreenQueryTicketFragment
      @arguments(filter: $ticketFilter)
    ...eventCancellationScreenQueryInvoiceFragment
      @arguments(filter: $invoiceFilter)
  }
`;

export const eventCancellationTicketQueryFragment = graphql`
  fragment eventCancellationScreenQueryTicketFragment on Query
  @refetchable(queryName: "EventCancellationScreenTicketQuery")
  @argumentDefinitions(
    filter: { type: "FilterFindManyticketInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    tickets: findTickets(filter: $filter, first: $count, after: $cursor)
      @connection(key: "EventCancellationScreenTicketQueryFragment__tickets") {
      edges {
        node {
          _id
          ticketType
          coverImg
          thumbnailImg
          uId
          expires_on
          title
          issuedBy {
            refType
            _id
          }
          user {
            name {
              first
              last
            }
          }
          finePrint {
            summary
            details
          }
          status
          additionalFields {
            event {
              startTime
              endTime
              spaces
            }
          }
          canBeClaimedBy {
            venue {
              geo {
                lat
                long
              }
              addressLine1
            }
            displayName
          }
          rewardId
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const eventCancellationInvoiceFragment = graphql`
  fragment eventCancellationScreenQueryInvoiceFragment on Query
  @refetchable(queryName: "EventCancellationScreenInvoiceQuery")
  @argumentDefinitions(
    filter: { type: "findInvoiceInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findInvoiceV2(filter: $filter, first: $count, after: $cursor)
      @connection(
        key: "EventCancellationScreenInvoiceQueryFragment__findInvoiceV2"
      ) {
      edges {
        node {
          orderId
          priceDetails {
            grossAmount
            netAmount
            rewardsUsed
          }
        }
      }
    }
  }
`;
