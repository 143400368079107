import React from 'react';
import { Text, View } from '../../../../components/new';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';

interface SectionDividerProps {
  label: string;
}

const SectionDivider = ({ label }: SectionDividerProps) => {
  return (
    <DarkThemeWrapper>
      <View flexDirection="row" alignItems="center">
        <View flex={1} height={1} bg="gold.500" />
        <View px="1xl">
          <Text textAlign="center" size="md" color="gold.500">
            {label}
          </Text>
        </View>
        <View flex={1} height={1} bg="gold.500" />
      </View>
    </DarkThemeWrapper>
  );
};

export default SectionDivider;
