/**
 * @generated SignedSource<<3e1b17b22142713c8002af2ae0e28d6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Referer = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type paymentScreenQuery$variables = {
  amount?: number | null;
  orderId: string;
  orderType: Referer;
  useRewardPoints: boolean;
};
export type paymentScreenQuery$data = {
  readonly findConfig: {
    readonly billUploadConfig: {
      readonly cashbackPercentage: number;
    } | null;
  } | null;
  readonly getNetPrice: {
    readonly cashbackAmount: number | null;
    readonly cashbackPercentage: number | null;
    readonly discountPercentage: number | null;
    readonly priceDetails: {
      readonly discountGiven: number;
      readonly discountPercentage: number;
      readonly grossAmount: number;
      readonly netAmount: number;
      readonly rewardsUsed: number;
    };
    readonly sourceDetails: {
      readonly event: {
        readonly title: string;
      } | null;
      readonly popUpStore: {
        readonly displayName: string;
      } | null;
      readonly restaurant: {
        readonly displayName: string;
        readonly payBill: {
          readonly payEnabled: boolean | null;
        } | null;
      } | null;
      readonly store: {
        readonly displayName: string;
      } | null;
    } | null;
  } | null;
};
export type paymentScreenQuery = {
  response: paymentScreenQuery$data;
  variables: paymentScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useRewardPoints"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPercentage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cashbackPercentage",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      },
      {
        "kind": "Variable",
        "name": "orderType",
        "variableName": "orderType"
      },
      {
        "kind": "Variable",
        "name": "useRewardPoints",
        "variableName": "useRewardPoints"
      }
    ],
    "concreteType": "invoiceModelPriceExtendedTC",
    "kind": "LinkedField",
    "name": "getNetPrice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "invoicePriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountGiven",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardsUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netAmount",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceSourceDetailsTC",
        "kind": "LinkedField",
        "name": "sourceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2",
            "kind": "LinkedField",
            "name": "restaurant",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2PayBill",
                "kind": "LinkedField",
                "name": "payBill",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payEnabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "stores",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "popUpStore",
            "kind": "LinkedField",
            "name": "popUpStore",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cashbackAmount",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "config",
    "kind": "LinkedField",
    "name": "findConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "configBillUploadConfig",
        "kind": "LinkedField",
        "name": "billUploadConfig",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentScreenQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "paymentScreenQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "a230aa9cf8d976c6e3c7d235f9376b05",
    "id": null,
    "metadata": {},
    "name": "paymentScreenQuery",
    "operationKind": "query",
    "text": "query paymentScreenQuery(\n  $amount: Float\n  $useRewardPoints: Boolean!\n  $orderId: ID!\n  $orderType: Referer!\n) {\n  getNetPrice(amount: $amount, useRewardPoints: $useRewardPoints, orderId: $orderId, orderType: $orderType) {\n    priceDetails {\n      grossAmount\n      discountGiven\n      rewardsUsed\n      netAmount\n      discountPercentage\n    }\n    sourceDetails {\n      restaurant {\n        displayName\n        payBill {\n          payEnabled\n        }\n      }\n      event {\n        title\n      }\n      store {\n        displayName\n      }\n      popUpStore {\n        displayName\n      }\n    }\n    discountPercentage\n    cashbackPercentage\n    cashbackAmount\n  }\n  findConfig {\n    billUploadConfig {\n      cashbackPercentage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "033574eaa6fecdff80da701c76f24dae";

export default node;
