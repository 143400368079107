/**
 * @generated SignedSource<<217bc40b69957293c7f0f333c9fd9595>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type taskJourneyScreenQuery$variables = {
  filter: findTaskFilterInput;
};
export type taskJourneyScreenQuery$data = {
  readonly findTasks: ReadonlyArray<{
    readonly _id: any;
    readonly campus: {
      readonly _id: any;
      readonly name: string;
    } | null;
    readonly history: ReadonlyArray<{
      readonly action: EnumtaskHistoryAction;
      readonly event_at: any | null;
    } | null>;
    readonly parking: {
      readonly parkingTag: string | null;
    } | null;
    readonly parkingId: any | null;
    readonly parkingTag: string | null;
    readonly taskEndLocation: {
      readonly locationId: any;
      readonly name: string | null;
      readonly parkingSpaces: {
        readonly floor: {
          readonly bay: {
            readonly bayId: any;
            readonly bayName: string | null;
          } | null;
          readonly floorId: any;
          readonly floorName: string | null;
        } | null;
      } | null;
    } | null;
    readonly taskStartLocation: {
      readonly locationId: any;
      readonly name: string | null;
      readonly parkingSpaces: {
        readonly floor: {
          readonly bay: {
            readonly bayId: any;
            readonly bayName: string | null;
          } | null;
          readonly floorId: any;
          readonly floorName: string | null;
        } | null;
      } | null;
    } | null;
    readonly taskType: EnumtaskTaskType;
    readonly user: {
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly profileImage: string | null;
    } | null;
    readonly valet: {
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
      readonly profileImage: string | null;
    } | null;
    readonly vehicle: {
      readonly brand: {
        readonly brandLogo: string | null;
        readonly name: string | null;
      } | null;
      readonly color: string | null;
      readonly registration: {
        readonly plate: string;
      } | null;
    } | null;
  }>;
};
export type taskJourneyScreenQuery = {
  response: taskJourneyScreenQuery$data;
  variables: taskJourneyScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parkingTag",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "UserContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserContactPhone",
      "kind": "LinkedField",
      "name": "phone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "findTasks",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingId",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "taskVehicle",
        "kind": "LinkedField",
        "name": "vehicle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleBrand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandLogo",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleRegistration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "campusLocation",
        "kind": "LinkedField",
        "name": "campus",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskHistory",
        "kind": "LinkedField",
        "name": "history",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "event_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskTaskStartLocation",
        "kind": "LinkedField",
        "name": "taskStartLocation",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskStartLocationParkingSpaces",
            "kind": "LinkedField",
            "name": "parkingSpaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                "kind": "LinkedField",
                "name": "floor",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                    "kind": "LinkedField",
                    "name": "bay",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskTaskEndLocation",
        "kind": "LinkedField",
        "name": "taskEndLocation",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskEndLocationParkingSpaces",
            "kind": "LinkedField",
            "name": "parkingSpaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                "kind": "LinkedField",
                "name": "floor",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                    "kind": "LinkedField",
                    "name": "bay",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Parking",
        "kind": "LinkedField",
        "name": "parking",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "valet",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserName",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taskJourneyScreenQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taskJourneyScreenQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "efcfdbd300a34709ac5931da7d588dcd",
    "id": null,
    "metadata": {},
    "name": "taskJourneyScreenQuery",
    "operationKind": "query",
    "text": "query taskJourneyScreenQuery(\n  $filter: findTaskFilterInput!\n) {\n  findTasks(filter: $filter) {\n    _id\n    taskType\n    parkingId\n    parkingTag\n    vehicle {\n      brand {\n        name\n        brandLogo\n      }\n      registration {\n        plate\n      }\n      color\n    }\n    campus {\n      _id\n      name\n    }\n    history {\n      action\n      event_at\n    }\n    taskStartLocation {\n      locationId\n      parkingSpaces {\n        floor {\n          floorId\n          floorName\n          bay {\n            bayId\n            bayName\n          }\n        }\n      }\n      name\n    }\n    taskEndLocation {\n      locationId\n      parkingSpaces {\n        floor {\n          floorId\n          floorName\n          bay {\n            bayId\n            bayName\n          }\n        }\n      }\n      name\n    }\n    user {\n      profileImage\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n    parking {\n      parkingTag\n    }\n    valet {\n      profileImage\n      name {\n        first\n        last\n      }\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1845d30e2f9c0ac4d75f9800eb9f092f";

export default node;
