import React, { memo } from 'react';
import { useWindowDimensions } from 'react-native';
import { Text, View } from '../../../../components/new';
import { radii, space } from '../../../../themes/new/theme';
import InvestmentBlurView from './InvestmentBlurView';

const areEqual = () => {
  return true;
};

const Tracker = ({
  data,
  consentGiven,
  isFractionalOwnership,
  bgColor = '',
  onPressUnlock = () => {},
}) => {
  const { width } = useWindowDimensions();

  return (
    <View py="9xl+9xl" bg={bgColor} alignItems="center">
      <Text size="xs" color="primary.300" pb="lg" textAlign="center">
        {data?.heading}
      </Text>
      <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
        {data?.subHeading}
      </Text>
      {data?.data?.length ? (
        <View
          borderWidth={1}
          borderRadius={radii.xl}
          borderColor="primary.100"
          mt="9xl"
          width={width - 32}
        >
          <InvestmentBlurView
            blurHeight="100%"
            blurWidth="100%"
            showBlur={!consentGiven && isFractionalOwnership}
            BlurViewStyle={{ borderRadius: radii.xl }}
            iconSize="6xl"
            onPressUnlock={onPressUnlock}
          />
          <View px="2xl" py="4xl">
            {data?.data.map((item, index) => (
              <View flexDirection="row" key={`tracker_items_${item?._id}`}>
                <View
                  style={{ width: 1 }}
                  bg="primary.100"
                  left={15}
                  top={30}
                  mb={index === data?.data?.length - 1 ? '8xl' : '6xl'}
                />
                <View
                  justifyContent="center"
                  alignItems="center"
                  borderWidth={1}
                  height={space['6xl']}
                  width={space['6xl']}
                  borderRadius={radii.xl}
                  borderColor="primary.100"
                >
                  <Text size="xs" weight="medium" color="primary.100">
                    {index + 1}
                  </Text>
                </View>
                <View
                  flex={1}
                  ml="4xl"
                  mb={index === data?.data?.length - 1 ? 0 : '6xl+3xl'}
                >
                  <Text size="md" weight="medium" color="primary.400" mb="sm">
                    {item?.heading}
                  </Text>
                  <Text size="sm" color="primary.300">
                    {item?.subHeading}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default memo(Tracker, areEqual);
