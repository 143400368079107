import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import useUserStore, { UserRole } from '../../../stores/userStore';
import {
  searchTeamQuery,
  searchTeamQueryFragment,
} from '../API/searchTeamQuery';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';

const sortByOnlineStatus = (data) => {
  const tempSearchValet = JSON.parse(JSON.stringify(data));
  return tempSearchValet?.sort((item) => {
    if (item?.workLogStatus?.status === 'available') {
      return -1;
    }
    if (item?.workLogStatus?.status === 'busy') {
      return 0;
    }
    return 1;
  });
};

const useTeamScreenData = ({ refreshedQueryOptions, variables }) => {
  const { role } = useUserStore((state) => state);

  const apiDataRef = useLazyLoadQuery(
    searchTeamQuery,
    variables,
    refreshedQueryOptions,
  );

  const {
    data: paginatedData,
    loadNext: fetchMoreUserList,
    hasNext: hasMoreUsers,
  } = usePaginationFragment(searchTeamQueryFragment, apiDataRef);
  const apiData = unwrapPagedData(paginatedData?.searchValetV2?.edges);

  const sortedData = sortByOnlineStatus(apiData);

  return {
    apiData: sortedData,
    allowDeletion: role === UserRole.VALET_SUPERVISOR,
    hasMoreUsers,
    fetchMoreUserList,
  };
};

export default useTeamScreenData;
