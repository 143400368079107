import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SCAN_STATUS } from '../../../utilities/helper';
import { checkIfClickPictureDisabled } from './validation';
import { getTheme, themeModes } from '../../../themes/new/theme';
import { Appearance, State, Status } from '../../../themes/new/helper';
import { Button, Pressable, View } from '../../../components/new';

const BottomCTA = ({
  scanStatus,
  overlayColor,
  captureImage,
  onPressContinue,
  onPressRetake,
  extractingDetails,
  onPressStartAgain,
  onPressSubmitBill,
  scanAttemptCount,
  MAX_SCAN_ATTEMT_COUNT,
  submitBillDetails,
}) => {
  const { bottom } = useSafeAreaInsets();
  if (scanStatus === SCAN_STATUS.IDLE || scanStatus === SCAN_STATUS.REQUESTED) {
    return (
      <View py="7xl" bg={overlayColor} alignItems="center" mb={bottom}>
        <Pressable
          onPress={captureImage}
          disabled={checkIfClickPictureDisabled(scanStatus)}
        >
          <View
            p="sm"
            borderWidth="md"
            borderColor={
              !checkIfClickPictureDisabled(scanStatus)
                ? getTheme(themeModes.dark).colors.primary['500']
                : getTheme(themeModes.dark).colors.grey[300]
            }
            borderRadius="full"
          >
            <View
              width={48}
              height={48}
              borderRadius="full"
              bg={
                !checkIfClickPictureDisabled(scanStatus)
                  ? getTheme(themeModes.dark).colors.primary['500']
                  : getTheme(themeModes.dark).colors.grey[300]
              }
            />
          </View>
        </Pressable>
      </View>
    );
  }
  if (
    scanStatus === SCAN_STATUS.PROCESSING ||
    scanStatus === SCAN_STATUS.STARTED
  ) {
    return (
      <View mb={bottom} pb="7xl" bg={overlayColor} width="100%">
        <Button
          size="md"
          status={Status.PRIMARY}
          state={extractingDetails ? State.DISABLED : State.ACTIVE}
          onPress={onPressContinue}
        >
          Confirm
        </Button>
        <Button
          size="md"
          status={Status.PRIMARY}
          state={extractingDetails ? State.DISABLED : State.ACTIVE}
          appearance={Appearance.OUTLINE}
          mt="2xl"
          onPress={onPressRetake}
        >
          Re-Take
        </Button>
      </View>
    );
  }
  if (scanStatus === SCAN_STATUS.FAILURE) {
    return (
      <View
        mb={bottom}
        pb="7xl"
        bg={overlayColor}
        width="100%"
        flexDirection="row"
      >
        <View flex={1}>
          <Button
            size="md"
            status={Status.PRIMARY}
            state={submitBillDetails ? State.DISABLED : State.ACTIVE}
            appearance={
              scanAttemptCount >= MAX_SCAN_ATTEMT_COUNT
                ? Appearance.OUTLINE
                : Appearance.FILLED
            }
            onPress={onPressStartAgain}
          >
            Start Again
          </Button>
        </View>
        {scanAttemptCount >= MAX_SCAN_ATTEMT_COUNT ? (
          <View flex={1}>
            <Button
              size="md"
              status={Status.PRIMARY}
              state={
                extractingDetails || submitBillDetails
                  ? State.DISABLED
                  : State.ACTIVE
              }
              appearance={Appearance.FILLED}
              ml="2xl"
              onPress={onPressSubmitBill}
            >
              Submit Bill
            </Button>
          </View>
        ) : (
          <></>
        )}
      </View>
    );
  }
  return <></>;
};

export default BottomCTA;
