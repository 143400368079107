import React from 'react';
import { BlurView } from 'expo-blur';
import { Platform } from 'react-native';
import { Icon, Pressable, View } from '../../../../components/new';

const InvestmentBlurView = ({
  blurHeight,
  blurWidth,
  showBlur = false,
  BlurViewStyle = {},
  iconSize = 'xl',
  iconName = 'lock-filled',
  iconColor = 'primary.500',
  onPressUnlock = () => {},
}) => {
  if (!showBlur) return;
  return (
    <View
      height={blurHeight}
      width={blurWidth}
      style={{ zIndex: 1, borderRadius: 16, flex: 1 }}
      position="absolute"
    >
      <BlurView
        intensity={showBlur ? (Platform.OS === 'android' ? 15 : 20) : 0}
        experimentalBlurMethod="dimezisBlurView"
        style={[
          {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          },
          BlurViewStyle,
        ]}
      >
        <Pressable
          onPress={onPressUnlock}
          style={{
            height: 100,
            width: 100,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon name={iconName} size={iconSize} color={iconColor} />
        </Pressable>
      </BlurView>
    </View>
  );
};

export default InvestmentBlurView;
