import {
  menuAddButtonAppearanceEnum,
  menuAddButtonSizeEnum,
  menuAddButtonStateEnum,
} from './varAndEnum';

export const iconBgResolver = (appearance, state) => {
  if (state === menuAddButtonStateEnum.disabled) {
    return 'primary.50';
  }
  if (appearance === menuAddButtonAppearanceEnum.solid) {
    return 'primary.500';
  }
  return 'primary.10';
};
export const iconColorResolver = (appearance, state) => {
  if (state === menuAddButtonStateEnum.disabled) {
    return 'primary.10';
  }
  if (appearance === menuAddButtonAppearanceEnum.solid) {
    return 'primary.10';
  }
  return 'primary.300';
};
export const textColorResolver = (appearance, state) => {
  if (state === menuAddButtonStateEnum.disabled) {
    return 'primary.100';
  }
  if (appearance === menuAddButtonAppearanceEnum.solid) {
    return 'primary.10';
  }
  return 'primary.300';
};

export const leftIconPaddingResolver = (size, count) => {
  if (size === menuAddButtonSizeEnum.md && count <= 0) {
    return {
      paddingLeft: 'sm+md',
      paddingRight: 'sm+md',
      paddingTop: 'sm+md',
      paddingBottom: 'sm+md',
    };
  }
  if (size === menuAddButtonSizeEnum.md && count > 0) {
    return {
      paddingLeft: 'sm+md',
      paddingRight: 'sm+md',
      paddingTop: 'sm+md',
      paddingBottom: 'sm+md',
    };
  }
  if (size === menuAddButtonSizeEnum.lg && count <= 0) {
    return {
      paddingLeft: 'xl',
      paddingRight: 'xl',
      paddingTop: 'xl',
      paddingBottom: 'xl',
    };
  }
  if (size === menuAddButtonSizeEnum.lg && count > 0) {
    return {
      paddingLeft: 'xl',
      paddingRight: 'xl',
      paddingTop: 'xl',
      paddingBottom: 'xl',
    };
  }

  // default

  return {
    paddingLeft: 'md',
    paddingRight: 'md',
    paddingTop: 'md',
    paddingBottom: 'md',
  };
};

export const rightIconPaddingResolver = (size, count) => {
  if (size === menuAddButtonSizeEnum.md && count <= 0) {
    return {
      paddingLeft: 'md',
      paddingRight: 'md',
      paddingTop: 'md',
      paddingBottom: 'md',
    };
  }
  if (size === menuAddButtonSizeEnum.md && count > 0) {
    return {
      paddingLeft: 'sm+md',
      paddingRight: 'sm+md',
      paddingTop: 'sm+md',
      paddingBottom: 'sm+md',
    };
  }
  if (size === menuAddButtonSizeEnum.lg && count <= 0) {
    return {
      paddingLeft: 'lg',
      paddingRight: 'lg',
      paddingTop: 'lg',
      paddingBottom: 'lg',
    };
  }
  if (size === menuAddButtonSizeEnum.lg && count > 0) {
    return {
      paddingLeft: 'xl',
      paddingRight: 'xl',
      paddingTop: 'xl',
      paddingBottom: 'xl',
    };
  }

  // default
  return {
    paddingLeft: 'md',
    paddingRight: 'md',
    paddingTop: 'md',
    paddingBottom: 'md',
  };
};
