import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import { BlurView, Button, Text, View } from '../../../components/new';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import { InputStates } from '../../../components/new/primitive/Input/helpers';
import FacebookPixelLogger from '../../../utilities/FacebookPixelLogger';

const EventsHomeFooter = ({
  id,
  title,
  price,
  StripContent,
  isPastOrSoldoutEvent = false,
  onNotify,
}) => {
  const buttonDisabled = false;
  const navigation = useNavigation<any>();
  const insets = useSafeAreaInsets();

  const handlePress = () => {
    firebaseEventLogger('EventsHome_Reserve_slot', {
      id,
      name: title,
    });
    FacebookPixelLogger('track', 'AddToCart');
    navigation.navigate('eventsReservations', { eventId: id });
  };

  return (
    <View>
      {StripContent}
      <BlurView level="4">
        <View pb={insets.bottom} bg="opacity.90">
          <View>
            <View
              flexDirection="row"
              p="2xl"
              pb={Platform.OS === 'ios' ? 0 : '2xl'}
              justifyContent="space-between"
              alignItems="space-between"
              borderTopWidth="xs"
              borderColor="primary.50"
            >
              <View flex={1} alignItems="center" flexDirection="row">
                {Boolean(price) && (
                  <>
                    <Text size="xl" weight="medium" color="primary.500">
                      {`₹${Math.ceil(price)} `}
                    </Text>
                    <Text weight="regular" color="primary.300" size="xs" mt={4}>
                      onwards
                    </Text>
                  </>
                )}
              </View>

              {isPastOrSoldoutEvent ? (
                <View flex={1}>
                  <Button
                    state={
                      buttonDisabled ? InputStates.disabled : InputStates.active
                    }
                    size="lg"
                    onPress={onNotify}
                  >
                    Notify Me
                  </Button>
                </View>
              ) : (
                <View flex={1}>
                  <Button
                    state={
                      buttonDisabled ? InputStates.disabled : InputStates.active
                    }
                    size="lg"
                    onPress={handlePress}
                  >
                    Reserve Slots
                  </Button>
                </View>
              )}
            </View>
          </View>
        </View>
      </BlurView>
    </View>
  );
};

export default EventsHomeFooter;
