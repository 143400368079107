import React, { Suspense, useCallback, useRef, useState } from 'react';
import { FlashList } from '@shopify/flash-list';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { debounce } from 'lodash';
import { Dimensions } from 'react-native';
import {
  IconButton,
  TopNavigation,
  View,
  Text,
  Layout,
  Button,
  ChipGroup,
} from '../../../components/new';
import VideoPlayerWrapper from '../../../components/new/custom/VideoPlayerWrapper';
import baseTheme from '../../../themes/new/theme';
import { navigateBack } from '../../../utilities/helper';
import imageConfig from '../../../../imagekit.config';
import Loading from '../../../components/loading.component';
import useCommonStore from '../../../stores/commonStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useUserStore from '../../../stores/userStore';
import CachedImage from '../../../components/new/custom/CachedImage';

const imageKitURL = ({ logo, width }) =>
  imageConfig.url({
    src: logo,
    transformation: [
      {
        width: `${width * 2}`,
        aspectRatio: '1-1',
        format: 'webp',
      },
    ],
  });

const restaurantScreenQuery = graphql`
  query RestaurantScreenGalleryQuery($filter: findRestaurantsFilterInput!) {
    findRestaurants2(filter: $filter) {
      imageSection {
        sectionTitle
        displayOrder
        description
        media {
          title
          description
          coverImage
          primeImage
          mediaURL
          mediaType
          sectionCover
          displayOrder
        }
      }
      mediaGallery {
        _id
        sectionTitle
        description
        displayOrder
        media {
          _id
          title
          description
          mediaURL
          mediaType
          sectionCover
          displayOrder
        }
      }
    }
  }
`;

const RenderItem = ({ item, width, restaurantId, navigation }) => {
  return (
    <View>
      <View mt="4xl" mb="4xl" px="2xl">
        <View mb="sm">
          <Text
            size="3xl"
            fontFamily={baseTheme.fonts.headingMedium}
            letterSpacing={0.5}
            color="primary.400"
          >
            {item?.sectionTitle
              .replace(/___|_/g, (m) => {
                if (m === '_') {
                  return ' ';
                }
                return ' & ';
              })
              .replace('Founder', 'Brand')}
          </Text>
        </View>
        <Text color="primary.200">{item?.description}</Text>
      </View>
      {item?.media?.map((mediaItem) => (
        <View mb="sm">
          <View>
            {mediaItem?.mediaType === 'video' && (
              <VideoPlayerWrapper
                id={mediaItem?._id}
                isMuted
                isLooping
                url={mediaItem?.mediaURL}
                // height={507}
                aspectRatio={0.76}
                showFullScreen
              />
            )}
            {mediaItem?.mediaType === 'image' && (
              <CachedImage
                source={{
                  uri: imageKitURL({
                    logo: mediaItem?.mediaURL,
                    width,
                  }),
                }}
                style={{ height: 507, width }}
              />
            )}
          </View>
          <View px="2xl">
            {!!mediaItem?.title && (
              <View mt="xl" pb="2xl">
                <Text size="md" color="primary.500">
                  {mediaItem?.title}
                </Text>
              </View>
            )}
            {!!mediaItem?.description && (
              <View pt="sm" pb="md">
                <Text color="primary.200">{mediaItem?.description}</Text>
              </View>
            )}
            {!!mediaItem?.title && item?.sectionTitle === 'Food' && (
              <View flexDirection="row" mb="2xl">
                {/* <Text color="primary.200">{mediaItem.description}</Text> */}
                <Button
                  iconRight="forward-outline-300"
                  appearance="ghost"
                  iconSize="md"
                  onPress={() => navigation.navigate('menu', { restaurantId })}
                >
                  Explore Menu
                </Button>
              </View>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};

const Header = ({
  navigation,
  chipData,
  setSelectedIndex,
  selectedIndex,
  flatRef: ref,
  offset,
  imageSectionNewProp,
}) => {
  const userRole = useUserStore((state) => state.role);

  return (
    <>
      <TopNavigation
        appearance="ghost"
        level="1"
        title={
          imageSectionNewProp && imageSectionNewProp?.length > 0
            ? ''
            : 'All Photos and Videos'
        }
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() =>
              // navigation.goBack()
              navigateBack(navigation, userRole)
            }
          />
        }
        ChildrenElement={
          imageSectionNewProp && imageSectionNewProp?.length > 0 ? (
            <></>
          ) : (
            <View pb="xl">
              <ChipGroup
                data={chipData}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                callbackFn={(e, i) => {
                  ref?.current?.scrollToIndex({
                    animated: true,
                    index: i,
                    // viewOffset: offset,
                    // animated?: boolean | null | undefined;
                    // index: number;
                    // viewOffset?: number | undefined;
                    // viewPosition?: number | undefined;
                  });
                }}
              />
            </View>
          )
        }
      />
    </>
  );
};

const RestaurantScreenGallery = ({
  navigation,
  route,
  variables,
  restaurantId,
  imageSectionNewProp,
}) => {
  const { width, height } = Dimensions.get('screen');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [layout, setLayout] = useState(null);
  // const chipData = tempData.map((item) => ({ name: item.sectionTitle }));
  const ref = useRef();

  const updateSelectedDebounce = debounce((i) => {
    setSelectedIndex(i);
  }, 700);

  const handleViewableItemsChanged = useCallback(
    ({ viewableItems, changed, ...other }) => {
      const index = viewableItems[1]?.index || viewableItems[0]?.index;
      if (index !== undefined) {
        updateSelectedDebounce(index);
      }
    },
    [],
  );

  // const initialItem = route?.params?.initialItem;

  const { findRestaurants2 } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLazyLoadQuery(restaurantScreenQuery, variables);
  const flatData = [];
  const chipData = [];
  const { imageSection, mediaGallery } = findRestaurants2[0] ?? {};
  const imageSectionNew = JSON.parse(JSON.stringify(imageSection));
  // temporarily hiding "Awards___Recognitions" section due to buggy chip highlight on scroll
  const mediaGalleryNew = JSON.parse(
    JSON.stringify(
      mediaGallery.filter((d) => d.sectionTitle !== 'Awards___Recognitions'),
    ),
  );
  // arrange by display order

  imageSectionNew.sort((item, nextItem) => {
    return item.displayOrder - nextItem.displayOrder;
  });
  mediaGalleryNew.sort((item, nextItem) => {
    return item.displayOrder - nextItem.displayOrder;
  });

  if (imageSectionNew && imageSectionNew?.length) {
    imageSectionNew.forEach((innerItem) => {
      // arrange by display order
      innerItem?.media?.sort((item, nextItem) => {
        return item.displayOrder - nextItem.displayOrder;
      });
      // =====
      flatData.push(innerItem);
      chipData.push({
        name: innerItem.sectionTitle
          .replace('___', ' & ')
          .replace('Founder', 'Brand'),
      });
    });
  } else {
    // eslint-disable-next-line no-unused-expressions
    mediaGalleryNew &&
      mediaGalleryNew?.length &&
      mediaGalleryNew.forEach((innerItem) => {
        // arrange by display order
        innerItem.media.sort((item, nextItem) => {
          return item.displayOrder - nextItem.displayOrder;
        });
        // =====
        flatData.push(innerItem);
        chipData.push({
          name: innerItem.sectionTitle
            .replace('___', ' & ')
            .replace('_', ' ')
            .replace('Founder', 'Brand'),
        });
      });
  }

  return (
    <>
      <Layout level={2}>
        <View height={layout ? layout?.height : 107} />
        {/* with flashlist */}
        {/* ================== */}
        <FlashList
          ref={ref}
          data={flatData}
          scrollIndicatorInsets={{ right: 1 }}
          renderItem={(props) => (
            <RenderItem
              {...props}
              restaurantId={restaurantId}
              navigation={navigation}
              width={width}
            />
          )}
          estimatedItemSize={1257}
          drawDistance={500}
          estimatedFirstItemOffset={1000}
          onViewableItemsChanged={handleViewableItemsChanged}
        />
        {!flatData.length && (
          <Layout level={2}>
            <View justifyContent="center" alignItems="center" mt="4xl">
              <Text>No Data Available</Text>
            </View>
          </Layout>
        )}
      </Layout>
      <View
        style={{ position: 'absolute', zIndex: 1, top: 0, width: '100%' }}
        onLayout={(e) => {
          setLayout(e.nativeEvent.layout);
        }}
      >
        <Header
          navigation={navigation}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          chipData={chipData}
          flatRef={ref}
          offset={layout?.height}
          imageSectionNewProp={imageSectionNewProp}
        />
      </View>
    </>
  );
};

const RestaurantScreenGalleryErrorBoundary = ({ navigation, route }) => {
  const [netStatus, setNetStatus] = useState(true);
  // const { campusID } = useContext(UserContext);
  const campusID = useCommonStore((state) => state.campusId);

  // TODO , make campus ID dynamic
  // const campusID = campusID;
  const restaurantId = route?.params?.restaurantId;
  const imageSectionNew = route?.params?.imageSection;

  const variables = {
    filter: {
      _id: restaurantId,
      campus: { _id: campusID },
      imageSection: imageSectionNew,
    },
  };

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <RestaurantScreenGallery
            variables={variables}
            navigation={navigation}
            restaurantId={restaurantId}
            route={route}
            imageSectionNewProp={imageSectionNew}
          />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default RestaurantScreenGalleryErrorBoundary;
