import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, Image } from 'react-native';
import { graphql, usePaginationFragment } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { Button, Icon, Pressable, Text, View } from '../../../components/new';
import ValetCard from '../../../components/new/custom/ValetCard';
import {
  NOTABLETOSENTMESSAGEOPTIONSENUM,
  dynamicHeightMaker,
  dateDiffInDays,
} from '../../../utilities/helper';
import { sentMessageQuery } from '../API/sentMessageQuery';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { getTheme, themeModes } from '../../../themes/new/theme';
import useUserStore, { UserRole } from '../../../stores/userStore';
import AppConstants from '../../../utilities/AppConstants';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';
import { pagination } from '../../../utilities/Constant';
import useRestaurantPartnerStore from '../../../stores/restaurantpartner/restaurantPartnerStore';
import { Appearance, State, Status } from '../../../themes/new/helper';
import SendMessageBottomSheet from './SendMessageBottomSheet';
import PaginationLoader from '../../../components/new/custom/PaginationLoader';
import DarkThemeWrapper from '../../new/wrapperComp/DarkThemeWrapper';

const LoyalCustomerList = ({ getRestaurantPartnerUserRef, refresh }) => {
  const {
    data: users,
    loadNext: fetchMoreUserList,
    hasNext: hasMoreUsers,
  } = usePaginationFragment(
    graphql`
      fragment LoyalCustomerList_getRestaurantPartnerUser on Query
      @refetchable(queryName: "loyaltyCustomerListFragmentQuery")
      @argumentDefinitions(
        filter: { type: "getMypaidUserFilter!" }
        count: { type: "Int" }
        cursor: { type: "String" }
      ) {
        getRestaurantPartnerUserV2(
          filter: $filter
          first: $count
          after: $cursor
        ) @connection(key: "LoyalCustomerList_getRestaurantPartnerUserV2") {
          count
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              userId
              _id
              partnerVisits
              lastVisit
              lastComunication
              msgText {
                text
              }
              user {
                _id
                name {
                  first
                  last
                }
                contact {
                  phone {
                    prefix
                    number
                  }
                }
                visitCount
                profileImage
                wallet {
                  currentBalance
                }
              }
            }
          }
        }
      }
    `,
    getRestaurantPartnerUserRef,
  );

  const { setLoyalCustomerLength } = useRestaurantPartnerStore(
    (state) => state,
  );

  useEffect(() => {
    setLoyalCustomerLength(users?.getRestaurantPartnerUserV2?.count);
  }, []);
  const data = unwrapPagedData(users?.getRestaurantPartnerUserV2?.edges);

  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [option, setOption] = useState(
    NOTABLETOSENTMESSAGEOPTIONSENUM.NOT_VISITED_CAMPUS,
  );
  const navigation = useNavigation();
  const { role } = useUserStore((state) => state);
  const [showTick, setShowTick] = useState({ id: '', show: false });
  const [sendMessageDisabled, setSendMessageDisabled] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showError, setShowError] = useState(false);
  const sendMessageModalRef = useRef(null);

  const handleOnDismissSendMessage = useCallback(() => {
    setSelectedUser(null);
    setShowError(false);
    sendMessageModalRef.current?.hide();
  }, []);

  const handleSendMessageModalPress = useCallback(() => {
    sendMessageModalRef.current?.show();
  }, []);

  const { IMAGEKIT_PROD_URI } = AppConstants;

  const handleSendMessage = async (userId) => {
    setSendMessageDisabled(userId);
    try {
      const sentMessageRes = await sentMessageQuery({ id: userId });
      if (
        sentMessageRes &&
        sentMessageRes?.sentloyaltyMsg &&
        sentMessageRes?.sentloyaltyMsg?._id
      ) {
        handleOnDismissSendMessage();
        setSendMessageDisabled('');
        setShowTick({ id: userId, show: true });
        dispatchSnackbar({
          msg: `Invite sent to ${
            sentMessageRes?.sentloyaltyMsg?.user?.name?.first
          } ${
            sentMessageRes?.sentloyaltyMsg?.user?.name?.last !== null
              ? sentMessageRes?.sentloyaltyMsg?.user?.name?.last
              : ''
          }`,
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
        });
        setTimeout(() => {
          setShowTick({ id: '', show: false });
          refresh();
        }, 3000);
      }
    } catch (error) {
      setSendMessageDisabled('');
      setShowError(true);
      if (error?.res?.errors[0]?.extensions?.code === 101) {
        setOption(NOTABLETOSENTMESSAGEOPTIONSENUM.INVITE_ALREADY_SENT);
      } else if (error?.res?.errors[0]?.extensions?.code === 102) {
        setOption(NOTABLETOSENTMESSAGEOPTIONSENUM.NOT_VISITED_CAMPUS);
      } else if (error?.res?.errors[0]?.extensions?.code === 103) {
        setOption(NOTABLETOSENTMESSAGEOPTIONSENUM.NOT_ENOUGH_LOYALTY_POINTS);
      }
      setTimeout(() => {
        handleSendMessageModalPress();
      }, 200);
    }
  };

  const renderItem = ({ item, index }) => {
    const fullName = `${item?.user?.name?.first} ${
      item?.user?.name?.last !== null ? item?.user?.name?.last : ''
    }`;
    const imageLink = item?.user?.profileImage
      ? IMAGEKIT_PROD_URI + item?.user?.profileImage
      : 'https://ik.imagekit.io/32ndhub/assets/nouser.png';

    const today = new Date();
    const lastVisitDate = new Date(item?.lastVisit);
    const lastComunicationDate = new Date(item?.lastComunication);

    const difference = dateDiffInDays(lastVisitDate, today);
    const lastComunicationDifference = dateDiffInDays(
      lastComunicationDate,
      today,
    );

    const onPress = async () => {
      setSelectedUser(item);
      if (difference <= 7) {
        setShowError(true);
        setOption(NOTABLETOSENTMESSAGEOPTIONSENUM.NOT_VISITED_CAMPUS);
        setTimeout(() => {
          handleSendMessageModalPress();
        }, 200);
      }
      if (difference > 7 && lastComunicationDifference <= 30) {
        setShowError(true);
        setOption(NOTABLETOSENTMESSAGEOPTIONSENUM.INVITE_ALREADY_SENT);
        setTimeout(() => {
          handleSendMessageModalPress();
        }, 200);
      } else {
        handleSendMessageModalPress();
      }
    };

    if (!item?.user) {
      return null;
    }

    return (
      <View
        px="2xl"
        mt={index === 0 ? 'lg' : '2xl'}
        mb={index === data?.length - 1 ? '4xl' : 0}
      >
        <ValetCard
          id={`${item?._id}`}
          imageLink={imageLink}
          heading={fullName}
          subText={`${item?.user?.wallet?.currentBalance} Loyalty Points | ${
            item?.partnerVisits
          } ${item?.partnerVisits > 1 ? 'visits' : 'visit'}`}
          contentRight={
            role === UserRole.RESTAURANT_PARTNER ? (
              <View>
                {(showTick &&
                  showTick?.id &&
                  showTick?.show &&
                  showTick?.id === item?._id) ||
                (lastComunicationDifference <= 30 && difference > 7) ? (
                  <>
                    <Text size="sm" color="success.500">
                      Invite Sent
                    </Text>
                  </>
                ) : (
                  <>
                    <Button
                      size="md"
                      state={
                        item?._id === sendMessageDisabled || difference <= 7
                          ? State.DISABLED
                          : State.ACTIVE
                      }
                      appearance={Appearance.GHOST}
                      status={Status.INFO}
                      iconRight="keyboard-arrow-right-300"
                      onPress={() => {
                        setSelectedUser(item);
                        handleSendMessageModalPress();
                      }}
                    >
                      Invite
                    </Button>
                  </>
                )}
              </View>
            ) : (
              <></>
            )
          }
          onPress={onPress}
        />
      </View>
    );
  };

  const ListEmptyComponent = () => (
    <View alignItems="center" justifyContent="center">
      <Image
        source={require('../../../../assets/images/discount_trophy.webp')}
        style={{ width: 200, height: 200, resizeMode: 'contain' }}
      />
      <View px="2xl" mt="2xl">
        <Text size="2xl" weight="medium" color="primary.400" textAlign="center">
          Loyalty program activated!
        </Text>
        <Text size="md" color="primary.200" mt="lg" textAlign="center">
          Start encouraging your customers to pay via 32nd Club App and build a
          community of loyal customers
        </Text>
      </View>
    </View>
  );

  return (
    <View flex={1}>
      <DarkThemeWrapper>
        <Pressable
          style={{
            padding: getTheme().space['2xl'],
            borderRadius: getTheme().radii.md,
            borderWidth: getTheme().borderWidths.xs,
            borderColor: getTheme(themeModes.dark).colors.grey[50],
            marginHorizontal: getTheme().space['2xl'],
            marginVertical: getTheme().space['2xl'],
            backgroundColor: getTheme(themeModes.dark).colors.background.primary
              .elevation,
            ...getTheme(themeModes.dark).boxShadows.sm,
          }}
          onPress={() => {
            navigation.navigate('Discounts');
          }}
        >
          <View
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Image
              source={require('../../../../assets/images/black_membership/black-member-logo-3.webp')}
              style={{
                width: 65,
                height: dynamicHeightMaker(65, 0.616),
                resizeMode: 'contain',
              }}
            />
            <View ml="sm">
              <Text size="md" weight="medium" color="primary.500">
                Manage Discounts
              </Text>
              <Text size="sm" color="primary.300">
                For 32nd Plus Members
              </Text>
            </View>
            <View ml="lg">
              <Icon size="2xl" color="primary.500" name="forward-outline-300" />
            </View>
          </View>
        </Pressable>
      </DarkThemeWrapper>
      <FlatList
        data={data}
        contentContainerStyle={{ marginBottom: getTheme().space['4xl'] }}
        renderItem={renderItem}
        ListEmptyComponent={ListEmptyComponent}
        ListHeaderComponent={() =>
          data?.length ? (
            <Text
              size="md"
              weight="medium"
              color="primary.400"
              px="2xl"
              mb="lg"
            >
              My Loyal Customers
            </Text>
          ) : (
            <></>
          )
        }
        onEndReachedThreshold={0.3}
        onEndReached={() => {
          if (hasMoreUsers) {
            fetchMoreUserList(pagination.defaultPageSize);
          }
        }}
        ListFooterComponent={() => {
          if (hasMoreUsers) return <PaginationLoader />;
          return null;
        }}
      />
      <SendMessageBottomSheet
        bottomSheetModalRef={sendMessageModalRef}
        handleOnDismiss={handleOnDismissSendMessage}
        handleSendMessage={() => handleSendMessage(selectedUser?._id)}
        sendMessageDisabled={selectedUser?._id === sendMessageDisabled}
        selectedUser={selectedUser}
        showOption={option}
        showError={showError}
      />
    </View>
  );
};

export default LoyalCustomerList;
