import React from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { radii } from '../../../themes/new/theme';
import { View } from '../../../components/new';
import LightThemeWrapper from '../../new/wrapperComp/LightThemeWrapper';
import InfoTile from './InfoTile';
import CachedImageBackground from '../../../components/new/custom/CachedImageBackground';

const bannerBgSrc = 'https://ik.imagekit.io/32ndhub/assets/bg_ontime_blur.png';

type OverviewBannerProps = {
  approvedBills: number;
  rejectedBills: number;
};

const OverviewBanner = ({
  approvedBills,
  rejectedBills,
}: OverviewBannerProps) => {
  const { top } = useSafeAreaInsets();
  return (
    <LightThemeWrapper>
      <View
        bg="primary.500"
        borderBottomLeftRadius={radii.lg + radii.sm}
        borderBottomRightRadius={radii.lg + radii.sm}
      >
        <CachedImageBackground
          source={{ uri: bannerBgSrc }}
          style={styles.bannerImg}
        >
          <View zIndex={2} mt={top} pt="4xl">
            <View
              mt="2xl"
              mb="4xl"
              px="2xl"
              flexDirection="row"
              justifyContent="center"
            >
              <View flex={1} ml="2xl" mr="lg">
                <InfoTile
                  taskCount={approvedBills}
                  status="success"
                  statusText="Approved Bills"
                />
              </View>
              <View flex={1} ml="lg" mr="2xl">
                <View>
                  <InfoTile
                    taskCount={rejectedBills}
                    status="failed"
                    statusText="Rejected Bills"
                  />
                </View>
              </View>
            </View>
          </View>
        </CachedImageBackground>
      </View>
    </LightThemeWrapper>
  );
};

const styles = StyleSheet.create({
  bannerImg: {
    overflow: 'hidden',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});

export default OverviewBanner;
