/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const loyaltyQuery = graphql`
  query loyaltyApiQuery(
    $id: MongoID!
    $filter: getMypaidUserFilter!
    $count: Int!
    $cursor: String
  ) {
    findUserById(_id: $id) {
      _id
      restaurants {
        _id
        displayName
        payBill {
          pointsEnabled {
            redeem
            reward
          }
        }
      }
    }
    ...LoyalCustomerList_getRestaurantPartnerUser
      @arguments(filter: $filter, count: $count, cursor: $cursor)
  }
`;
