import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BlurView, Button, Text, View } from '../../../components/new';
import { Appearance, State } from '../../../themes/new/helper';
import useAuthStore from '../../../stores/authStore';

const PaybillFooter = ({
  isPayBillDisabled = false,
  discountPercentage = 0,
  onPressPrimary = () => {},
  onPressSecondary = () => {},
  disableMenu = true,
  cashbackPercentage = 0,
}) => {
  const insets = useSafeAreaInsets();
  const { isLoggedIn } = useAuthStore((state) => state);

  return !isPayBillDisabled ? (
    <View borderTopWidth="xs" borderColor="primary.50">
      <BlurView level="4">
        <View pb={insets.bottom} bg="opacity.90">
          <View>
            <View flexDirection="row" p="2xl">
              {!disableMenu && (
                <View flex={1} ml="sm" mr="lg">
                  <Button
                    size="lg"
                    onPress={onPressSecondary}
                    appearance={Appearance.OUTLINE}
                  >
                    View Menu
                  </Button>
                </View>
              )}
              <View flex={1}>
                <Button
                  size="lg"
                  appearance={Appearance.FILLED}
                  onPress={onPressPrimary}
                  state={isPayBillDisabled ? State.DISABLED : State.ACTIVE}
                >
                  <Text color="primary.10" size="md">
                    Pay Bill{' '}
                    {!!discountPercentage && (
                      <Text size="md" color="primary.10">
                        {discountPercentage
                          ? `(${discountPercentage}% Off)`
                          : ''}
                      </Text>
                    )}
                  </Text>
                </Button>
              </View>
            </View>
          </View>
        </View>
      </BlurView>
    </View>
  ) : cashbackPercentage ? (
    <View borderTopWidth="xs" borderColor="primary.50">
      <BlurView level="4">
        <View pb={insets.bottom} bg="opacity.90">
          <View>
            <View flexDirection="row" p="2xl">
              <View flex={1}>
                <Button size="lg" appearance="filled" onPress={onPressPrimary}>
                  <Text color="primary.10" size="md">
                    Upload Bill & Get{' '}
                    <Text size="md" color="primary.10">
                      {cashbackPercentage}% Cashback
                    </Text>
                  </Text>
                </Button>
              </View>
            </View>
          </View>
        </View>
      </BlurView>
    </View>
  ) : (
    <></>
  );
};

export default PaybillFooter;
