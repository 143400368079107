import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { Layout, View } from '../../../../components/new';
import PayoutOverviewScreenInner from './PayoutOverviewScreenInner';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import { Loading } from '../../../../components';
import DisconnectedDropover from '../../../../components/disconnectedpopover.component';

const PayoutOverviewScreen = () => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'network-only',
    }));
  }, []);

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  return (
    <Layout level="1">
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <PayoutOverviewScreenInner queryOptions={refreshedQueryOptions} />
          </Suspense>
        </NewErrorBoundary>
        <DisconnectedDropover
          setNetStatus={setNetStatus}
          text="No Internet Connection"
          icon="wifi-off-outline"
        />
      </View>
    </Layout>
  );
};

export default PayoutOverviewScreen;
