/* eslint-disable import/prefer-default-export */
import { graphql, commitMutation } from 'react-relay';
import environment from '../../../../../relay/relayEnvironment';

export const getSlotMachineRewardDataMaker = () => {
  return null;
};

export const getSlotMachineReward = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation getSlotMachineRewardMutation {
      addSpinReward {
        randomNumbers
        numberCount
        spinsLeft
        freezedTill
        rewardTypeGiven
        rewardPointsGiven
      }
    }
  `;

  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};
