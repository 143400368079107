import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface photoMomentsWidget {
  title?: string;
  photo?: string;
  assetType?: string;
}

export interface VideoMomentsStoreInterface {
  videoData: string;
  uploaded: number;
  inProgress: number;
  motionSensor: boolean;
  lastUpdated: string;
  momentsData: string;
  scannedS3Imagekey: string;
  photoMomentsForWidget: photoMomentsWidget[];
  photoMomentsWidgetHeader: string;
  totalPhotoMoments: number;
  lastPhotoDownloadPopUpDate: string;
  setVideoData: (videoData: string) => void;
  setUploaded: (uploaded: number) => void;
  setInProgress: (inProgress: number) => void;
  setMotionSensor: (motionSensor: boolean) => void;
  setLastUpdated: (lastUpdated: string) => void;
  setMomentsData: (momentsData: string) => void;
  setScannedS3Imagekey: (scannedS3Imagekey: string) => void;
  emptyMomentsStore: () => void;
  setPhotoMomentsForWidget: (
    photoMoments: [],
    photoMomentsWidgetHeader: string,
    totalPhotoMoments: number,
  ) => void;
  setLastPhotoDownloadPopUpDate: (date: string) => void;
}

const VideoMomentsStore = create<VideoMomentsStoreInterface>()(
  devtools(
    persist(
      (set) => ({
        videoData: '',
        uploaded: 0,
        inProgress: 0,
        motionSensor: false,
        lastUpdated: '',
        momentsData: '',
        scannedS3Imagekey: '',
        photoMomentsForWidget: [],
        photoMomentsWidgetHeader: '',
        totalPhotoMoments: 0,
        lastPhotoDownloadPopUpDate: '',
        setVideoData: (videoData: string) =>
          set((state) => ({
            videoData,
          })),
        setScannedS3Imagekey: (scannedS3Imagekey: string) =>
          set((state) => ({
            scannedS3Imagekey,
          })),
        setUploaded: (uploaded: number) =>
          set((state) => ({
            uploaded,
          })),
        setInProgress: (inProgress: number) =>
          set((state) => ({
            inProgress,
          })),
        setMotionSensor: (motionSensor: boolean) =>
          set((state) => ({
            motionSensor,
          })),
        setLastUpdated: (lastUpdated: string) =>
          set((state) => ({
            lastUpdated,
          })),
        setMomentsData: (momentsData: string) =>
          set((state) => ({
            momentsData,
          })),
        emptyMomentsStore: () =>
          set(() => ({
            momentsData: '',
            scannedS3Imagekey: '',
          })),
        setPhotoMomentsForWidget: (
          photoMoments: [],
          photoMomentsWidgetHeader: string,
          totalPhotoMoments: number,
        ) =>
          set(() => ({
            photoMomentsForWidget: photoMoments,
            photoMomentsWidgetHeader,
            totalPhotoMoments,
          })),
        setLastPhotoDownloadPopUpDate: (date: string) => {
          set(() => {
            return { lastPhotoDownloadPopUpDate: date };
          });
        },
      }),
      {
        name: 'VideoMomentsStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
  ),
);

export default VideoMomentsStore;
