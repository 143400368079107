import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useWindowDimensions } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { View, Text, Icon, Layout } from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import { endTaskScreenQuery } from './API/endTaskScreenQuery';
import { TaskStatus, dynamicHeightMaker } from '../../../utilities/helper';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';

const EndTaskScreen = (props) => {
  const { queryOptions, variables, refresh } = props;
  const navigation = useNavigation<any>();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { findStaffRewards } = useLazyLoadQuery(endTaskScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
    fetchPolicy: 'store-and-network',
  });
  const { _id, value, task } = findStaffRewards[0] ?? {};
  const animation = useRef(null);
  const { width } = useWindowDimensions();

  const navigateToHome = () => {
    navigation.popToTop();
  };

  useEffect(() => {
    setTimeout(() => {
      navigateToHome();
    }, 2200);
  }, []);

  return (
    <View flex={1} bg="primary.10">
      <View position="absolute">
        <CustomLottieView
          loop
          autoPlay
          ref={animation}
          source={require('../../../../assets/lottie/celebration.json')}
          style={{
            width,
            height: dynamicHeightMaker(width, 1),
          }}
          containerWidth={width}
        />
      </View>
      <View flex={1} justifyContent="center" alignItems="center">
        <Icon name="check-filled-400" color="success.500" size="3xl+3xl" />
        <Text mt="4xl" size="2xl" weight="medium" color="primary.300">
          {task?.taskStatus === TaskStatus.ON_TIME &&
          !isValueNullOrEmpty(value) &&
          value !== 0
            ? 'Task Completed On-time'
            : 'Task Completed'}
        </Text>
        {value && value > 0 ? (
          <>
            <Text mt="2xl" size="3xl" weight="medium" color="primary.5000">
              ₹{value}
            </Text>
            <Text mt="sm" size="sm" color="primary.100">
              Bonus Earned
            </Text>
          </>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

const EndTaskScreenWrapper = ({ navigation, route }) => {
  const { taskId } = route?.params ?? {};
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const filter = {
    task: { taskId },
  };

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <EndTaskScreen
            variables={{
              filter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};
export default EndTaskScreenWrapper;
