import React from 'react';
import { Image, ImageProps } from 'expo-image';

const CachedImage = (props: ImageProps) => {
  const propsDefault: ImageProps = {
    cachePolicy: 'disk',
    ...props,
  };
  return <Image {...propsDefault} />;
};

export default CachedImage;
