import React, { useState } from 'react';
import { Text, View } from '../../../components/new';
import PaymentStatusTag from '../../../components/new/custom/transaction/BreakupPanel/PaymentStatusTag';
import { PaymentStatusEnum } from '../../../utilities/helper';
import BreakupPanel from '../../../components/new/custom/transaction/BreakupPanel';
import { ITransaction } from '../types';

type PaymentStatusType = keyof typeof PaymentStatusEnum;
type Mode = 'REVENUE' | 'EARNINGS';
export interface TransactionDetailsPanelProps {
  transaction: ITransaction;
  mode: Mode;
}

const resolveColor = (status: PaymentStatusType) => {
  switch (status) {
    case PaymentStatusEnum.SUCCESSFUL:
      return 'success.500';
    case PaymentStatusEnum.FAILED:
      return 'error.500';
    case PaymentStatusEnum.PENDING:
      return 'warning.500';
    case PaymentStatusEnum.REFUNDED:
      return 'grey.500';
    default:
      return 'grey.400';
  }
};

const TransactionDetailsPanel = ({
  mode,
  transaction,
}: TransactionDetailsPanelProps) => {
  const [{ bodyData, footerData }] = useState(
    buildBreakupPanelData(mode, transaction),
  );

  return (
    <View>
      <View
        mb="2xl"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <View>
          <Text mb="sm" size="md" weight="regular" color="singletone.black">
            Order Id - #{transaction.orderId}
          </Text>
          <Text size="xs" color="primary.100">
            {transaction.formated.customerPhone}
          </Text>
        </View>
        <View>
          <PaymentStatusTag status={transaction.paymentStatus} />
        </View>
      </View>
      <BreakupPanel bodyData={bodyData} footerData={footerData} />
    </View>
  );
};

export default TransactionDetailsPanel;

const buildBreakupPanelData = (mode: Mode, transaction: ITransaction) => ({
  bodyData: [
    {
      leftTxt: 'Bill Amount',
      rightTxt: transaction.formated.billAmount,
      [mode === 'REVENUE' ? 'color' : '']: resolveColor(
        transaction.paymentStatus,
      ),
      weight: 'regular',
    },
    {
      leftTxt: 'Loyalty Points Redeemed',
      rightTxt: `-${transaction.formated.loyaltyPoints}`,
      weight: 'regular',
    },
    {
      leftTxt: 'Tip Amount',
      rightTxt: transaction.formated.tipAmount,
      weight: 'regular',
    },
    {
      leftTxt: 'Discount',
      rightTxt: `-${transaction.formated.discount}`,
      weight: 'regular',
    },
  ],
  footerData: [
    {
      leftTxt: mode === 'REVENUE' ? 'Collected Amount' : 'My Earnings',
      rightTxt: transaction.formated.netAmountWithoutDonation,
      [mode === 'EARNINGS' ? 'color' : '']: 'success.500',
    },
  ],
});
