import { graphql } from 'react-relay';

const controllerDailyBonusTasksQuery = graphql`
  query controllerDailyBonusTasksQuery(
    $filter: findDailyControllerRewardsFilter
  ) {
    findDailyControllerRewards(filter: $filter) {
      task {
        taskDate
        taskType
        taskStatus
        delayedBy
      }
      category
      value
      valetCode
    }
  }
`;

export default controllerDailyBonusTasksQuery;
