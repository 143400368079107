/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useNetInfo } from '@react-native-community/netinfo';
import { Button, View, Text, Icon } from '../../../components/new';
import {
  ParkingStatus,
  formatPlateNo,
  formatTimeToHrsMinSec,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../utilities/helper';
import TaskCard from '../../../components/new/primitive/TaskCard/TaskCard';
import RectangularTimer from '../../../components/new/primitive/Timer/RectangularTimer';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import DarkThemeWrapper from '../../new/wrapperComp/DarkThemeWrapper';
import PunchInOutModal from './PunchInOutModal';
import coffeeImg from '../../../../assets/images/coffee-break.png';
import watchImg from '../../../../assets/images/watch.png';
import AddWorkLog from '../../../relay/valetMyTasksApi';
import useUserStore, { UserRole } from '../../../stores/userStore';
import { updateTaskForSupervisor } from '../../../relay/taskApi';
import CancelTaskModel from './CancelTaskModel';
import useValetTaskStore from '../../../stores/valet/taskStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { updateParking } from '../../../relay/parkingApi';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';

const RenderItem = ({
  item,
  dispatchSnackbar,
  // valetWorkLog,
  refresh,
}) => {
  const navigation = useNavigation<any>();
  const [modalVisible, setModalVisible] = useState({
    state: false,
    status: '',
    primaryCB: () => {},
  });
  const [leftButtonDisabled, setLeftButtonDisabled] = useState(false);
  const [rightButtonDisabled, setRightButtonDisabled] = useState(false);
  const { openedCard, setOpenedCard, valetWorkLog, setValetWorkLog } =
    useValetTaskStore((state) => state);
  const netinfo = useNetInfo(null);

  const cancelTaskBottomSheetModalRef = useRef(null);

  const handleCancelTaskModalPress = React.useCallback(() => {
    cancelTaskBottomSheetModalRef.current?.show();
  }, []);

  const cancelTaskConfirmationHandleDismiss = React.useCallback(() => {
    cancelTaskBottomSheetModalRef.current?.hide();
  }, []);

  const userId = useUserStore((state) => state.id);
  const formattedVehicleNumber = formatPlateNo(
    item?.vehicle?.registration?.plate,
  );
  const heading = formattedVehicleNumber;
  const acceptedStatus =
    item &&
    item?.history &&
    item?.history?.length &&
    item?.history?.filter(
      (ele) => ele?.action === taskHistoryActionTypes.accepted,
    );
  const startedStatus =
    item &&
    item?.history &&
    item?.history?.length &&
    item?.history?.filter(
      (ele) => ele?.action === taskHistoryActionTypes.started,
    );
  const onboardedStatus =
    item &&
    item?.history &&
    item?.history?.length &&
    item?.history?.filter(
      (ele) => ele?.action === taskHistoryActionTypes.onboarded,
    );
  const lastStatus = item && item?.history && item?.history?.slice(-1)[0];
  const taskCreatedTime =
    acceptedStatus && acceptedStatus?.length
      ? new Date(acceptedStatus[0]?.event_at).valueOf()
      : onboardedStatus && onboardedStatus?.length
      ? new Date(onboardedStatus[0]?.event_at).valueOf()
      : new Date(item?.history[0]?.event_at).valueOf();
  const currentTime = new Date().valueOf();
  const difference = currentTime - taskCreatedTime;
  const timerObj = {
    title1: item?.taskType !== 'recall' ? 'Wait Time' : 'Recall Time',
    title2: formatTimeToHrsMinSec(difference / 1000),
    title2Color: 'primary.500',
  };
  if (difference / 1000 > 120 && difference / 1000 < 300) {
    timerObj.title2Color = 'warning.500';
  }
  if (difference / 1000 > 300) {
    timerObj.title2Color = 'error.500';
  }

  const headerRightComponent = () =>
    onboardedStatus && onboardedStatus?.length ? (
      <Icon size="xl" name="verified-400" color="info.500" />
    ) : (
      <></>
    );

  const topRightCornerComponent = () =>
    !isValueNullOrEmpty(item?.assignedTo) ? (
      item?.valet?.identityCode ? (
        <View
          borderRadius="full"
          borderWidth="xs"
          borderColor="primary.100"
          py="sm"
          px="md"
          bg="primary.50"
        >
          <Text size="sm" color="primary.500">
            {item?.valet?.identityCode}
          </Text>
        </View>
      ) : (
        <></>
      )
    ) : (
      <View
        borderRadius="full"
        borderWidth="xs"
        borderColor="primary.50"
        p="sm"
      >
        <Icon size="xl" name="car-filled-400" color="primary.100" />
      </View>
    );

  const bottomLeftCornerComponent = () => (
    <RectangularTimer
      title1={timerObj?.title1}
      title2={timerObj?.title2}
      title2Color={timerObj?.title2Color}
    />
  );

  const checkWorkLogAndShowModal = (primaryCB = () => {}) => {
    const { type } =
      valetWorkLog && Object.keys(valetWorkLog)?.length
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          valetWorkLog?.workLogHistory?.slice(-1)[0]
        : '';
    // =============
    if (!valetWorkLog?.workLogHistory?.length || type === 'punch_out') {
      setModalVisible({
        state: true,
        status: 'punch_out',
        primaryCB,
      });
    } else if (type === 'break_start') {
      setModalVisible({
        state: true,
        status: type,
        primaryCB,
      });
    } else {
      primaryCB();
    }
  };

  const handleUpdateTaskForParking = (response, error, action) => {
    if (error && error.length) {
      dispatchSnackbar({
        msg: 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
      // setRefetch(Math.random());
      refresh();
    }
    if (response) {
      if (
        action === taskHistoryActionTypes.onboarded &&
        acceptedStatus?.length <= 0
      ) {
        navigation.navigate('StartRideScreen', { taskId: item?._id });
      }
      if (action === taskHistoryActionTypes.created) {
        navigation.navigate('SelectCustomerScreen', {
          vehicleNumber: item?.vehicle?.registration?.plate,
          // vehicleId: item?.vehicleId,
          taskId: item?._id,
        });
      }
    }
  };

  const onPressBottomRightCTAForParking = ({ action }) => {
    firebaseEventLogger(`valetHome__continueTask_Tap`, {
      buttonName: 'continueTask',
      screenName: 'valetHome',
      userType: UserRole.VALET,
      interactionType: InteractionType.TAP,
      taskId: `${item?._id}`,
      lastStatus: `${action}`,
      valetId: `${userId}`,
      vehicleNo: `${item?.vehicle?.registration?.plate}`,
      taskType: `${item?.taskType}`,
    });
    if (!netinfo.isConnected) {
      return;
    }
    if (
      (action === taskHistoryActionTypes.accepted ||
        action === taskHistoryActionTypes.assigned) &&
      onboardedStatus?.length <= 0
    ) {
      checkWorkLogAndShowModal(() => {
        navigation.navigate('SelectCustomerScreen', {
          vehicleNumber: item?.vehicle?.registration?.plate,
          taskId: item?._id,
        });
      });
    }
    if (
      (action === taskHistoryActionTypes.accepted ||
        action === taskHistoryActionTypes.assigned) &&
      onboardedStatus &&
      onboardedStatus?.length
    ) {
      checkWorkLogAndShowModal(() => {
        navigation.navigate('StartRideScreen', {
          taskId: item?._id,
        });
      });
    }
    if (action === taskHistoryActionTypes.started) {
      checkWorkLogAndShowModal(() => {
        navigation.navigate('SelectParkingLocationScreen', {
          taskId: item?._id,
        });
      });
    }
    if (action === taskHistoryActionTypes.arrived) {
      checkWorkLogAndShowModal(() => {
        navigation.navigate('SubmitKeyScreen', {
          taskId: item?._id,
        });
      });
    }
    if (
      action === taskHistoryActionTypes.onboarded &&
      acceptedStatus?.length <= 0
    ) {
      checkWorkLogAndShowModal(() => {
        const data = {
          _id: item?._id,
          record: {
            history: { action: taskHistoryActionTypes.accepted },
            assignedTo: userId,
          },
        };
        updateTaskForSupervisor(
          data,
          (res, err) => handleUpdateTaskForParking(res, err, action),
          () => {},
        );
      });
    }
    if (
      action === taskHistoryActionTypes.onboarded &&
      acceptedStatus?.length > 0
    ) {
      checkWorkLogAndShowModal(() => {
        navigation.navigate('StartRideScreen', { taskId: item?._id });
      });
    }
    if (action === taskHistoryActionTypes.created) {
      checkWorkLogAndShowModal(() => {
        const data = {
          _id: item?._id,
          record: {
            history: { action: 'accepted' },
            assignedTo: userId,
          },
        };
        updateTaskForSupervisor(
          data,
          (res, err) => handleUpdateTaskForParking(res, err, action),
          () => {},
        );
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const getBottomCTAStatusForParking = ({ action }) => {
    if (
      action === taskHistoryActionTypes.accepted ||
      action === taskHistoryActionTypes.started ||
      action === taskHistoryActionTypes.assigned ||
      action === taskHistoryActionTypes.arrived ||
      (action === taskHistoryActionTypes.onboarded &&
        acceptedStatus &&
        acceptedStatus?.length)
    ) {
      return 'Continue Task';
    }
    if (
      action === taskHistoryActionTypes.onboarded &&
      acceptedStatus?.length <= 0
    ) {
      return 'Accept & Start Ride';
    }
    if (action === taskHistoryActionTypes.created) {
      return 'Add Customer';
    }
  };

  const getBottomCTAStatusForRecall = ({ action }) => {
    if (
      action === taskHistoryActionTypes.accepted ||
      action === taskHistoryActionTypes.assigned
    ) {
      return 'Start Ride';
    }
    return 'Continue Task';
  };

  const updateApiErrorHandler = (error) => {
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v2,
    });
  };

  const handleUpdateTask = () => {
    const data = {
      id: item?.parkingId,
      record: {
        status: ParkingStatus.OUTGOING,
      },
    };
    updateParking(
      data,
      (response) => {
        navigation.navigate('ReachedDropLocationScreen', {
          taskId: item?._id,
        });
      },
      updateApiErrorHandler,
    );
  };

  const onPressBottomRightCTAForRecall = ({ action }) => {
    firebaseEventLogger(`valetHome__continueTask_Tap`, {
      buttonName: 'continueTask',
      screenName: 'valetHome',
      userType: UserRole.VALET,
      interactionType: InteractionType.TAP,
      taskId: `${item?._id}`,
      lastStatus: `${action}`,
      valetId: `${userId}`,
      vehicleNo: `${item?.vehicle?.registration?.plate}`,
      taskType: `${item?.taskType}`,
    });
    checkWorkLogAndShowModal(() => {
      if (
        action === taskHistoryActionTypes.accepted ||
        action === taskHistoryActionTypes.assigned
      ) {
        const data = {
          _id: item?._id,
          record: {
            history: { action: taskHistoryActionTypes.started },
            assignedTo: userId,
          },
        };
        updateTaskForSupervisor(data, handleUpdateTask, updateApiErrorHandler);
      } else if (action === taskHistoryActionTypes.started) {
        navigation.navigate('ReachedDropLocationScreen', {
          taskId: item?._id,
        });
      } else if (action === taskHistoryActionTypes.arrived) {
        navigation.navigate('FinishTaskScreen', {
          taskId: item?._id,
        });
      }
    });
  };

  const bottomLeftCTAComponent = () => {
    if (
      item?.taskType === taskTypes.park ||
      item?.taskType === taskTypes.repark
    ) {
      if (
        lastStatus?.action === taskHistoryActionTypes.accepted ||
        lastStatus?.action === taskHistoryActionTypes.assigned
      ) {
        return (
          <Button
            size="md"
            appearance="outline"
            status="primary"
            onPress={() =>
              checkWorkLogAndShowModal(() => {
                handleCancelTaskModalPress();
              })
            }
            shadow="sm"
            state={
              acceptedStatus && acceptedStatus?.length ? 'active' : 'disabled'
            }
          >
            Cancel Task
          </Button>
        );
      }
      return (
        <Button
          size="md"
          appearance="filled"
          status="primary"
          onPress={() =>
            onPressBottomRightCTAForParking({ action: lastStatus?.action })
          }
          shadow="sm"
        >
          {getBottomCTAStatusForParking({ action: lastStatus?.action })}
        </Button>
      );
    }
    return (
      <Button
        size="md"
        appearance="filled"
        status="primary"
        onPress={() =>
          onPressBottomRightCTAForRecall({ action: lastStatus?.action })
        }
        shadow="sm"
      >
        {getBottomCTAStatusForRecall({ action: lastStatus?.action })}
      </Button>
    );
  };

  const bottomRightCTAComponent = () => {
    if (
      item?.taskType === taskTypes.park ||
      item?.taskType === taskTypes.repark
    ) {
      if (
        lastStatus?.action === taskHistoryActionTypes.accepted ||
        lastStatus?.action === taskHistoryActionTypes.assigned
      ) {
        return (
          <Button
            size="md"
            appearance="filled"
            status="primary"
            onPress={() =>
              onPressBottomRightCTAForParking({ action: lastStatus?.action })
            }
            shadow="sm"
          >
            {getBottomCTAStatusForParking({ action: lastStatus?.action })}
          </Button>
        );
      }
      return <></>;
    }
    return <></>;
  };

  const cancelHandler = () => {
    setModalVisible({
      state: false,
      status: '',
      primaryCB: () => {},
    });
  };

  const modalCallBack = () => {
    const type =
      modalVisible.status === 'break_start' ? 'break_end' : 'punch_in';
    return AddWorkLog({
      date: new Date().toISOString(),
      valetID: userId,
      workLogHistory: [
        {
          time: new Date().toISOString(),
          type,
        },
      ],
    })
      .then((res) => {
        // console.log('res......', res, modalVisible);
        if ((res as any).createValetWorkLog) {
          // setNewTaskArray([]);
          // setRefetch(Math.random());
          setValetWorkLog({
            data: {
              ...valetWorkLog,
              workLogHistory: (res as any).createValetWorkLog?.workLogHistory,
            },
          });
          cancelHandler();
          refresh();
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <>
      <View px="2xl" my="lg">
        {item?.assignedTo === userId || item?.taskType === 'recall' ? (
          <DarkThemeWrapper>
            <TaskCard
              heading={heading}
              headingRight={headerRightComponent()}
              topRightCorner={topRightCornerComponent()}
              bottomLeftCorner={bottomLeftCornerComponent()}
              bottomLeftCTA={bottomLeftCTAComponent()}
              bottomRightCTA={bottomRightCTAComponent()}
              swipeEnabled={false}
              shadow="md"
              blur={item?.blur}
              preExpanded={openedCard === item?._id}
              onCardPressCB={() => {
                if (openedCard !== item?._id) {
                  setOpenedCard({ id: item?._id });
                } else {
                  setOpenedCard({ id: '' });
                }
              }}
            />
          </DarkThemeWrapper>
        ) : (
          <TaskCard
            heading={heading}
            headingRight={headerRightComponent()}
            topRightCorner={topRightCornerComponent()}
            bottomLeftCorner={bottomLeftCornerComponent()}
            bottomLeftCTA={bottomLeftCTAComponent()}
            bottomRightCTA={bottomRightCTAComponent()}
            swipeEnabled={false}
            shadow="md"
            blur={item?.blur}
            preExpanded={openedCard === item?._id}
            onCardPressCB={() => {
              if (openedCard !== item?._id) {
                setOpenedCard({ id: item?._id });
              } else {
                setOpenedCard({ id: '' });
              }
            }}
          />
        )}
      </View>
      <PunchInOutModal
        modalVisible={modalVisible?.state}
        modalPrimaryCB={modalVisible?.primaryCB}
        setModalVisible={setModalVisible}
        img={
          // eslint-disable-next-line no-nested-ternary
          modalVisible?.status === 'break_start' ? coffeeImg : watchImg
        }
        title={
          modalVisible?.status === 'break_start'
            ? 'You are on break, End break to access your tasks.'
            : 'Kindly Punch-in to access all tasks!'
        }
        firstCTAText={
          modalVisible.status === 'break_start' ? 'End Break' : 'Punch - In'
        }
        secondCTAText="Cancel"
        firstCTATextHandler={modalCallBack}
        secondCTATextHandler={cancelHandler}
      />
      <CancelTaskModel
        bottomSheetModalRef={cancelTaskBottomSheetModalRef}
        handleOnDismiss={cancelTaskConfirmationHandleDismiss}
        task={item}
        refresh={refresh}
      />
    </>
  );
};

export default RenderItem;
