/* eslint-disable import/prefer-default-export */
import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

const defaultQuery = graphql`
  query sentMessageQuery($id: MongoID!) {
    sentloyaltyMsg(_id: $id) {
      _id
      user {
        name {
          first
          last
        }
      }
    }
  }
`;

export const sentMessageQuery = async (variables, query = defaultQuery) => {
  return fetchQuery(environment, query, variables).toPromise();
};
