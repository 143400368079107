/**
 * @generated SignedSource<<21af2285b590df6defd7da377ff9795d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumUserRolePermission = "staff" | "%future added value";
export type EnumUserRoleSecondaryRole = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type loginFilterInput = {
  contact: FilterUpdateOneUserContactInput;
  pin: string;
  waId?: string | null;
};
export type FilterUpdateOneUserContactInput = {
  phone: FilterUpdateOneUserContactPhoneInput;
};
export type FilterUpdateOneUserContactPhoneInput = {
  number: string;
  prefix: string;
};
export type loginRecordInput = {
  name?: UpdateOneUserNameInput | null;
};
export type UpdateOneUserNameInput = {
  _id?: any | null;
  first?: string | null;
  last?: string | null;
};
export type authApiLoginMutation$variables = {
  filter: loginFilterInput;
  record: loginRecordInput;
};
export type authApiLoginMutation$data = {
  readonly loginUser: {
    readonly record: {
      readonly _id: any;
      readonly contact: {
        readonly email: ReadonlyArray<{
          readonly addr: string | null;
        } | null> | null;
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly firstLoginParkingReward: boolean | null;
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
      readonly photoMomentsSearchImage: string | null;
      readonly profileImage: string | null;
      readonly refreshToken: string | null;
      readonly role: {
        readonly meta: {
          readonly campus: {
            readonly _id: any | null;
          } | null;
        } | null;
        readonly name: EnumUserRoleName | null;
        readonly permission: EnumUserRolePermission | null;
        readonly secondaryRole: ReadonlyArray<EnumUserRoleSecondaryRole | null> | null;
      } | null;
      readonly token: string | null;
      readonly typeForm: {
        readonly landedAt: any;
        readonly submittedAt: any;
      } | null;
    } | null;
  } | null;
};
export type authApiLoginMutation = {
  response: authApiLoginMutation$data;
  variables: authApiLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateOneUserPayload",
    "kind": "LinkedField",
    "name": "loginUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refreshToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserTypeForm",
            "kind": "LinkedField",
            "name": "typeForm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "landedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submittedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContactPhone",
                "kind": "LinkedField",
                "name": "phone",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prefix",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContactEmail",
                "kind": "LinkedField",
                "name": "email",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addr",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permission",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondaryRole",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserRoleMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserRoleMetaCampus",
                    "kind": "LinkedField",
                    "name": "campus",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserName",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstLoginParkingReward",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileImage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photoMomentsSearchImage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authApiLoginMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authApiLoginMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b3be2f6bbbf7c1f7040d9507229819a7",
    "id": null,
    "metadata": {},
    "name": "authApiLoginMutation",
    "operationKind": "mutation",
    "text": "mutation authApiLoginMutation(\n  $filter: loginFilterInput!\n  $record: loginRecordInput!\n) {\n  loginUser(filter: $filter, record: $record) {\n    record {\n      _id\n      token\n      refreshToken\n      typeForm {\n        landedAt\n        submittedAt\n      }\n      contact {\n        phone {\n          prefix\n          number\n        }\n        email {\n          addr\n        }\n      }\n      role {\n        name\n        permission\n        secondaryRole\n        meta {\n          campus {\n            _id\n          }\n        }\n      }\n      name {\n        first\n        last\n      }\n      firstLoginParkingReward\n      profileImage\n      photoMomentsSearchImage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "97d8f4454e8aefce8dab749fdf163e77";

export default node;
