/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  View,
  IconButton,
  TopNavigation,
  Button,
  Input,
  MobileInput,
} from '../../../components/new';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import useCommonStore from '../../../stores/commonStore';
import useUserStore, { UserRole } from '../../../stores/userStore';
import { InputStates } from '../../../components/new/primitive/Input/helpers';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import { addUserMutation } from './API/addUserMutation';
import { addVehicleMutation } from './API/addVehicleMutation';
import { findTaskQuery } from './API/findTaskQuery';
import { updateTaskMutation } from './API/updateTaskMutation';
import {
  defaultPhoneNumberPrefix,
  navigateBack,
} from '../../../utilities/helper';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import { Appearance, State, Status } from '../../../themes/new/helper';
import InternationalPhoneInput from '../../../components/new/custom/InternationalPhoneInput';

const AddCustomerScreen = (props) => {
  const { taskId, contact, partnerPayment } = props;
  const navigation = useNavigation<any>();
  const scrollviewRef = useRef(null);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { role } = useUserStore((state) => state);
  const campusID = useCommonStore((state) => state.campusId);
  const insets = useSafeAreaInsets();
  const [nameInput, setNameInput] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [callingCode, setCallingCode] = useState<string>('');

  // ------ handle text change for input ------
  const handleOnTextChange = (value) => {
    setNameInput(value);
    if (value?.length >= 3) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  useEffect(() => {
    return () => {
      setNameInput('');
      setButtonDisabled(true);
    };
  }, []);

  const handleOnSaveDetails = async () => {
    try {
      if (role === UserRole.PARTNER) {
        // ------ step-2 -> add user ------
        const user = await addUserMutation({
          record: {
            name: { first: nameInput?.trim() },
            contact: {
              phone: {
                prefix: `+${callingCode}`,
                number: contact?.phone?.number,
              },
            },
          },
        })
          .then((res) => {
            if (res) {
              navigation.navigate('PartnerCashPaymentScreen', {
                partnerPayment,
                contact: {
                  prefix: `+${callingCode}`,
                  number: contact?.phone?.number,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // ------ step-1 -> finding task on basis of taskId and campusId ------
        let task = await findTaskQuery({
          filter: { _id: taskId, campus: { _id: campusID } },
        });
        task = task?.findTasks[0];

        // ------ step-2 -> add user ------
        let user = await addUserMutation({
          record: {
            name: { first: nameInput?.trim() },
            contact: {
              phone: {
                prefix: `+${callingCode}`,
                number: contact?.phone?.number,
              },
            },
          },
        });
        user = user?.addUser?.record;

        // ------ step-3 -> add vehicle with ownerId ------
        let vehicle = await addVehicleMutation({
          record: {
            brand: task?.vehicle?.brand,
            registration: task?.vehicle?.registration,
            color: task?.vehicle?.color,
            ownerID: user?._id,
          },
        });
        vehicle = vehicle?.addVehicle?.record;

        // ------ step-4 -> update task for vehicleId and ownerId ------
        await updateTaskMutation({
          _id: taskId,
          record: { vehicleId: vehicle?._id, ownerId: user?._id },
        });

        firebaseEventLogger('addNewCustomer__addNewCustomer_Tap', {
          buttonName: 'addNewCustomer',
          screenName: 'addNewCustomer',
          userType: `${role}`,
          interactionType: InteractionType.TAP,
          taskId: `${taskId}`,
          customerName: `${user[0]?.name?.first} ${
            user[0]?.name?.last !== null ? user[0]?.name?.last : ''
          }`,
          phoneNo: `${contact?.phone?.prefix} ${contact?.phone?.number}`,
        });
        // ------ step-5 -> navigate to start ride screen ------
        navigation.replace('StartRideScreen', {
          taskId,
        });
      }
    } catch (err) {
      dispatchSnackbar({
        msg: 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
    }
  };

  const handleCountryChange = (countryCode: string) => {
    setCallingCode(countryCode);
  };

  return (
    <KeyboardAvoidingView
      behavior={
        Platform.OS === 'android'
          ? null
          : Platform.OS === 'ios'
          ? 'padding'
          : null
      }
      style={{ flex: 1 }}
    >
      <ScrollView ref={scrollviewRef} keyboardShouldPersistTaps="handled">
        <View px="2xl" mt="4xl">
          {/* <View flexDirection="row" alignItems="center"> */}
          {/* <MobileInput
              state={InputStates.disabled}
              value={contact?.phone?.number}
              label="Mobile Number"
              countryCode={contact?.phone?.prefix || defaultPhoneNumberPrefix}
            /> */}
          <InternationalPhoneInput
            label="Mobile Number"
            autofocus={Platform.OS !== 'web'}
            defaultValue={contact?.phone?.number?.toString()}
            defaultCallingCode={callingCode}
            inputViewOnly
            onChangeNumber={() => {}}
            onChangeCountry={handleCountryChange}
          />
          {/* </View> */}
          <View mt="4xl">
            <Input
              value={nameInput}
              placeholder="Karan Sharma"
              label="Customer Name"
              autoFocus
              onChangeText={handleOnTextChange}
            />
          </View>
        </View>
      </ScrollView>
      <View px="2xl" mb="xl" pb={insets.bottom}>
        <Button
          size="lg"
          appearance={Appearance.FILLED}
          status={Status.PRIMARY}
          state={buttonDisabled === true ? State.DISABLED : State.ACTIVE}
          onPress={handleOnSaveDetails}
        >
          Save Details
        </Button>
      </View>
    </KeyboardAvoidingView>
  );
};

const AddCustomerScreenWrapper = ({ navigation, route }) => {
  const { taskId, contact, partnerPayment } = route?.params ?? {};
  const { role } = useUserStore((state) => state);
  const [netStatus, setNetStatus] = useState(true);

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Add New Customer"
      />
      <AddCustomerScreen
        taskId={taskId}
        contact={contact}
        partnerPayment={partnerPayment}
      />
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default AddCustomerScreenWrapper;
