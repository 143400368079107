import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Layout, Text, View } from '../new';
import ChooseLoginMethod from './ChooseLoginMethod';
import useLoginFlowStore, {
  LoginFlowScreenSteps,
} from '../../stores/loginFlowStore';
import useAuthStore from '../../stores/authStore';
import useCommonStore from '../../stores/commonStore';
import {
  checkUser,
  CheckUserData,
  generateOTP,
  LoginUserPayload,
  loginUserV2,
} from '../../relay/authApi';
import useUserStore from '../../stores/userStore';
import findCampusLocation from '../../relay/campusLocationApi';
import useLoginModalStore, {
  SignUpPayload,
} from '../../stores/loginModalStore';
import VideoMomentsStore from '../../stores/VideoMomentsStore';
import CustomLottieView from '../new/custom/CustomLottieView';
import { convertPhoneNumber } from '../../utilities/Utility';

export const RenderLoginTopBar = () => {
  const commonStore = useCommonStore((state) => state);
  const navigation = useNavigation();

  return (
    <View
      pb="2xl"
      pt="2xl"
      style={{
        paddingHorizontal: 16,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Text size="md" color="primary.400">
          Join the 32nd Club
        </Text>
        <TouchableOpacity
          // @ts-ignore
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
          }}
          onPress={() => {
            commonStore.setHasLoginSkipped(true);
            navigation.reset({
              index: 0,
              routes: [{ name: 'user' }],
            });
          }}
        >
          <Text color="info.500" size="sm">
            Skip
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const Login = ({ route }) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const navigation = useNavigation<any>();
  const currentState = useLoginFlowStore((state) => state);
  const commonStore = useCommonStore((state) => state);

  const sid = route?.params?.sid;
  const waId: string = route?.params?.waId;
  const phoneNumber: string = route?.params?.mobile;

  const [isLoginWhatsappActive, setIsLoginWhatsappActive] =
    useState<boolean>(false);

  const [isWifiLoginActive, setIsWifiLoginActive] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn) {
      if (currentState.isWifiFlowEnabled && currentState.setWifiFlowSid) {
        // do whatever is required for login Wi-Fi
      }
      navigation.reset({
        index: 0,
        routes: [{ name: 'user' }],
      });
    }
  }, [isLoggedIn]);

  // useEffect(() => {
  //   const sid = route?.params?.sid;
  //   if (sid) {
  //     currentState.setIsWifiFlowEnabled(true);
  //     currentState.setWifiFlowSid(sid);
  //   }
  //   if (isLoggedIn) {
  //     // do whatever is required for login Wi-Fi
  //   }
  // }, []);

  const redirectUri = useAuthStore((state) => state.redirectUri);
  const setRedirectUri = useAuthStore((state) => state.setRedirectUri);

  const setUserDetails = useUserStore((state) => state.setUserDetails);
  const setLoginDetails = useAuthStore((state) => state.setLoginDetails);
  const setCampusId = useCommonStore((state) => state.setCampusId);
  const loginModalStore = useLoginModalStore((state) => state);
  const setScannedS3Imagekey = VideoMomentsStore(
    (state) => state.setScannedS3Imagekey,
  );

  const handleLoginUser = (response) => {
    if (response?.loginUser?.record?.photoMomentsSearchImage) {
      setScannedS3Imagekey(response.loginUser.record.photoMomentsSearchImage);
    }
    setUserDetails(
      response?.loginUser?.record?.name?.first,
      response?.loginUser?.record?.name?.last,
      response?.loginUser?.record?.role?.name,
      response?.loginUser?.record?.role?.permission,
      response?.loginUser?.record?._id,
      response?.loginUser?.record?.contact,
      response?.loginUser?.record?.role?.name,
      response?.loginUser?.record?.role?.secondaryRole,
      response?.loginUser?.record?.profileImage,
    );
    setLoginDetails(
      true,
      response.loginUser.record.token,
      response.loginUser.record.refreshToken,
    );
    const { name, permission } = response.loginUser.record.role;

    const campusID = response.loginUser.record.role.meta?.campus?._id;

    if (name !== 'user') {
      if (campusID) {
        setCampusId(campusID);
      }
    }
    if (name === 'user') {
      // if (response?.loginUser?.record?.firstLoginParkingReward === true) {
      //   setTimeout(() => {
      //     setShowFirstLoginParkingRewardModal(true);
      //   }, 500);
      // }
      findCampusLocation({})
        .then((res) => {
          setCampusId(res?.findCampusLocation[0]?._id);
          if (redirectUri.screenName === 'order') {
            setRedirectUri({ screenName: '', params: {} });
            navigation.navigate('order', redirectUri.params);
          } else {
            navigation.navigate('user');
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (sid) {
        // wifi login flow
      }
    } else if (waId) {
      // whatsapp login
      setIsLoginWhatsappActive(true);
      checkUser({ waId })
        .then((response) => {
          if (
            response?.checkUser === null ||
            response?.checkUser?.firstLogin === false ||
            response?.checkUser?.firstLogin === null
          ) {
            if (response?.checkUser?.firstLogin === false) {
              // set secondary signup
              currentState.setSecondarySignup(true);
            }
            // save response signup user
            currentState.setMobileNumber(
              response?.checkUser?.contact?.phone?.number || '',
            );
            currentState.setCountryCallingCode(
              convertPhoneNumber(
                response?.checkUser?.contact?.phone?.prefix || '',
              ),
            );
            const signupData: SignUpPayload = {
              name: {
                first: response?.checkUser?.name?.first || '',
                last: response?.checkUser?.name?.last || '',
              },
            };
            setIsLoginWhatsappActive(false);
            currentState.setSignupPayload(signupData);
            currentState.setWaId(waId);
            currentState.setCurrentScreen(LoginFlowScreenSteps.SIGN_UP);
            navigation.navigate('signUp');

            return null;
          }
          const data: LoginUserPayload = {
            waId,
            contact: {
              phone: {
                number: response.checkUser.contact.phone.number,
                prefix: response.checkUser.contact.phone.prefix,
              },
            },
            pin: '7869',
          };
          return loginUserV2(data);
        })
        .then((result) => {
          if (result) {
            handleLoginUser(result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (phoneNumber) {
      const valid = /^\d{10}$/.test(phoneNumber);
      if (valid) {
        const payload: CheckUserData = {
          phone: { number: phoneNumber, prefix: '+91' },
        };

        checkUser(payload)
          .then((data) => {
            if (data?.checkUser === null || !data?.checkUser?.firstLogin) {
              // navigate to signup
              if (data?.checkUser?.firstLogin === false) {
                // set secondary signup
                currentState.setSecondarySignup(true);
              }
              commonStore.setWasSignupFlowActive(true);
              currentState.setSignupPayload({
                name: {
                  first: data?.checkUser?.name?.first,
                  last: data?.checkUser?.name?.last,
                },
              });

              currentState.setMobileNumber(phoneNumber);
              currentState.setCurrentScreen(LoginFlowScreenSteps.SIGN_UP);
              navigation.navigate('signUp');
            } else {
              // send otp
              generateOTP(payload, () => {});
              currentState.setMobileNumber(phoneNumber);
              currentState.setCurrentScreen(LoginFlowScreenSteps.ENTER_OTP);
              navigation.navigate('enterOtp');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [waId, sid, phoneNumber]);

  if (isLoginWhatsappActive) {
    return (
      <Layout level={2} style={styles.loadingWrapper}>
        <View style={{ transform: [{ translateY: -40 }] }}>
          <CustomLottieView
            autoPlay
            loop
            source={require('../../../assets/lottie/whatsappLoginAnimation.json')}
            style={{ width: 196, height: 164 }}
            containerWidth={196}
          />
          <Text
            color="success.500"
            style={{ fontSize: 20, textAlign: 'center' }}
          >
            Securely Signing in...
          </Text>
        </View>
      </Layout>
    );
  }

  if (isWifiLoginActive) {
    return (
      <Layout level={2} style={styles.loadingWrapper}>
        <View style={{ transform: [{ translateY: -40 }] }}>
          <CustomLottieView
            autoPlay
            loop
            source={require('../../../assets/lottie/wifiLoginAnimation.json')}
            style={{ width: 196, height: 164 }}
            containerWidth={196}
          />
          <Text
            color="primary.400"
            style={{ fontSize: 20, textAlign: 'center' }}
          >
            Connecting WiFi...
          </Text>
        </View>
      </Layout>
    );
  }

  return (
    <Layout level={2} style={styles.mainWrapper}>
      <SafeAreaView style={{ flex: 1 }} edges={['top', 'bottom']}>
        <RenderLoginTopBar />
        <View style={styles.container}>
          <ChooseLoginMethod />
        </View>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    flex: 1,
  },
  container: {
    paddingHorizontal: 16,
    flex: 1,
  },
  loadingWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Login;
