import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { FlatList, Platform } from 'react-native';
import moment from 'moment/moment';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import Animated, {
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useAuthStore from '../../../stores/authStore';
import useCommonStore from '../../../stores/commonStore';
import useUserStore from '../../../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import { Avatar, Layout, Pressable, View, Text } from '../../../components/new';
import AppConstants from '../../../utilities/AppConstants';
import ListingFilters, {
  EventListingDataInfoFull,
  ListingFilterDataType,
} from '../../../components/new/custom/ListingFilters';
import NewErrorView from '../../../utilities/NewErrorView';
import { Loading } from '../../../components';
import EventsScreenPlacehodler from '../shimmerPlaceholders/screens/EventsScreenPlaceholder';
import EventBus, { EventBusEventsEnum } from '../../../utilities/EventBus';
import {
  eventsListingScreenQuery,
  eventsListingQueryfindEventsV2Fragment,
} from './eventsListingQuery';
import { getTheme } from '../../../themes/new/theme';
import EventsListingEmptyPlaceholder from './EventsListingEmptyPlaceholder';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';
import PaginationLoader from '../../../components/new/custom/PaginationLoader';
import DetailCard from '../../../components/new/custom/DetailCard';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import {
  getEventCashbackPercentage,
  getEventDiscountPercentage,
} from '../plusMembership/helpers';
import {
  isValueNullOrEmpty,
  normalizeToNumberOrZero,
} from '../../../utilities/Utility';
import {
  getDateText,
  getEventPrice,
  isPassedEvent,
  isSoldOutEvent,
} from './helpers/helpers';
import { MembershipTypes, mediaTypes } from '../../../utilities/helper';

//  make specific properties optional in a typescript `type`
type MakeOptional<T extends R, R> = Omit<T, keyof R> & Partial<R>;
type StatusType = 'upcoming';

type ListingFilterDataTypeWithOptionalData = MakeOptional<
  ListingFilterDataType,
  Pick<ListingFilterDataType, 'data'>
>;

const EVENTS_PAGE_SIZE = 5;

const AccountScreenIcon = ({
  imageLink,
  firstName,
  lastName,
  isBlackMember,
}) => {
  const navigation = useNavigation<any>();
  return (
    <View flexDirection="row" alignItems="center">
      <Pressable
        onPress={() => {
          navigation.navigate('account');
        }}
        hitSlop={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        }}
      >
        <View
          ml="lg"
          borderWidth="sm"
          borderColor={isBlackMember ? 'static.gold' : 'transparent'}
          borderRadius="full"
        >
          <Avatar
            level={1}
            source={imageLink}
            resizeMode="cover"
            name={`${firstName ?? ''} ${lastName ?? ''}`}
          />
        </View>
      </Pressable>
    </View>
  );
};

const Listing = ({
  refreshedQueryOptions,
  queryFilters,
  onScroll,
  setIsListEmpty,
  eventId,
}) => {
  const ref = useRef<FlatList>();
  const navigation = useNavigation<any>();
  const [viewableItem, setViewableItem] = useState('');
  const viewabilityConfigRef = useRef({
    itemVisiblePercentThreshold: 100,
    minimumViewTime: 50,
  });

  const onViewableItemsChangedCallback = useCallback(({ viewableItems }) => {
    if (viewableItems[0]?.isViewable) {
      setViewableItem(viewableItems[0]?.key);
    }
  }, []);

  const walletBalance = useUserStore((state) => state.wallet.currentBalance);

  const { findAddons, ...eventsRef } = useLazyLoadQuery(
    eventsListingScreenQuery,
    queryFilters,
    refreshedQueryOptions,
  );
  //  from plus membership config
  const eventDiscountPercentage = getEventDiscountPercentage(
    findAddons[0] as any,
  );
  const eventCashbackPercentage = getEventCashbackPercentage(
    findAddons[0] as any,
  );

  /* ========== EVENT LIST VIA PAGINATION ========== */
  const {
    data: eventsNodes,
    loadNext: fetchMoreEvents,
    hasNext: hasMoreEvents,
  } = usePaginationFragment(eventsListingQueryfindEventsV2Fragment, eventsRef);

  // extracting node out of edges
  const events = unwrapPagedData(eventsNodes?.findEventsV2?.edges);

  useEffect(() => {
    if (Array.isArray(events) && events?.length === 0) {
      setIsListEmpty(true);
    } else {
      setIsListEmpty(false);
    }
  }, [eventsNodes]);

  useEffect(() => {
    EventBus.getInstance().on(EventBusEventsEnum.SCROLL_TO_TOP_TAB_4, () => {
      ref?.current?.scrollToOffset({ animated: true, offset: 0 });
    });
  }, []);

  const handleCardPress = (id: string, eventName: string) => {
    firebaseEventLogger('events__eventCard__Tap', {
      buttonName: 'eventCard',
      screenName: 'events',
      userType: 'user',
      interactionType: 'tap',
      id,
      name: eventName,
    });
    navigation.navigate('eventsHome', { id });
  };

  return (
    <View px="2xl" flex={1}>
      <Animated.FlatList
        ref={ref}
        data={events}
        onScroll={onScroll}
        bounces={false}
        scrollEventThrottle={Platform.OS === 'web' ? 500 : 24}
        contentContainerStyle={{
          paddingBottom: getTheme().space['4xl'],
        }}
        // @ts-ignore
        keyExtractor={(item) => item?._id}
        renderItem={({ item, index }) => {
          const isPastEvent = isPassedEvent(item);
          let eventPrice;
          if (isPastEvent) {
            eventPrice = item?.basePrice;
          } else {
            eventPrice = getEventPrice(item);
          }
          const isPastOrSoldOutevent =
            isSoldOutEvent(item) || isPassedEvent(item);
          return (
            <DarkThemeWrapper>
              <View mt={item.index === 0 ? '2xl' : 'xl'}>
                <DetailCard
                  id={item?._id}
                  displayName={item?.title}
                  discountPercentage={normalizeToNumberOrZero(
                    eventDiscountPercentage,
                  )}
                  currentBalance={walletBalance ?? 0}
                  tagLine={
                    <View flexDirection="row" justifyContent="space-between">
                      {isPastOrSoldOutevent ? (
                        <Text size="sm" color="primary.400">
                          Coming soon!
                        </Text>
                      ) : (
                        <Text size="sm" color="primary.400">
                          {
                            getDateText({
                              data: item?.eventTiming ?? [],
                            }) as string
                          }
                        </Text>
                      )}
                      {Boolean(eventPrice) && (
                        <Text size="sm" color="primary.400">{`₹${Math.ceil(
                          eventPrice,
                        )} onwards`}</Text>
                      )}
                    </View>
                  }
                  statusTag={
                    isPastOrSoldOutevent ? (
                      <EventStatusTag
                        statusTag={{ status: 'upcoming', label: 'Upcoming' }}
                      />
                    ) : null
                  }
                  onPress={() => handleCardPress(item?._id, item?.title)}
                  coverImages={item?.media?.cover?.filter(
                    (img) => img?.mediaType === 'image',
                  )}
                  coverVideo={
                    item?.media?.cover?.filter(
                      (ele) =>
                        ele?.mediaType === mediaTypes.video && ele?.cover,
                    )[0]
                  }
                  payBillCashback={normalizeToNumberOrZero(
                    eventCashbackPercentage,
                  )}
                  pointsEnabled={{ redeem: true, reward: true }}
                  viewableItem={viewableItem}
                />
              </View>
            </DarkThemeWrapper>
          );
        }}
        viewabilityConfig={viewabilityConfigRef.current}
        onViewableItemsChanged={onViewableItemsChangedCallback}
        onEndReached={() => {
          if (hasMoreEvents) {
            fetchMoreEvents(EVENTS_PAGE_SIZE);
          }
        }}
        initialNumToRender={5}
        windowSize={10}
        onEndReachedThreshold={0.8}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={
          events?.length ? null : <EventsListingEmptyPlaceholder />
        }
        ListFooterComponent={
          hasMoreEvents ? (
            <View px="9xl" height={200} alignItems="center">
              <PaginationLoader />
            </View>
          ) : null
        }
      />
    </View>
  );
};

const EventsListing = ({ route }) => {
  const eventId = route?.params?.eventId;
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const campusID = useCommonStore((state) => state.campusId);
  const { id, profileImage, firstName, lastName, blackMembership } =
    useUserStore((state) => state);
  const { active: isBlackMember } = blackMembership;
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: Platform.OS === 'web' ? 'network-only' : 'store-and-network',
    });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  }, []);

  const { IMAGEKIT_PROD_URI } = AppConstants;

  const imageLink = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';

  const [filters, setFilters] =
    useState<ListingFilterDataTypeWithOptionalData[]>(EventListingFilters);
  const [activeFilter, setActiveFilter] =
    useState<null | EventListingDataInfoFull>({
      endDate: null,
      startDate: null,
      name: 'All',
    });
  const [queryFilters, setQueryFilters] = useState<any>({
    filter: {
      campus: { _id: campusID },
      isActive: true,
      priorityEventId: eventId ?? null,
      eventTiming:
        activeFilter?.startDate && activeFilter?.endDate
          ? {
              slot: {
                startTime: activeFilter.startDate.toString(),
                endTime: activeFilter.endDate.toString(),
                isActive: true,
              },
            }
          : undefined,
    },
    sort: 'SLOT_START_TIME_ASC',
    includeFindUser: isLoggedIn,
    id,
    count: EVENTS_PAGE_SIZE,
    addonsFilter: {
      type: MembershipTypes.PLUS_MEMBERSHIP,
    },
  });
  const [isListEmpty, setIsListEmpty] = useState(false);

  useFocusEffect(() => {
    firebaseEventLogger('eventsListingPageLanding');
  });

  const onFilterPress = (data: ListingFilterDataTypeWithOptionalData) => {
    if (activeFilter?.name === 'All' && data?.name === 'All') return;
    firebaseEventLogger('eventsListingFilterPress', {
      filterName: data.name,
    });
    let activeFilterObj: EventListingDataInfoFull = {
      startDate: null,
      endDate: null,
      name: 'All',
    };
    let selectedFilterName = '';
    const filtersData =
      data?.name === 'All'
        ? EventListingFilters
        : filters.map((filter) => {
            if (filter.name === data.name && !filter.selected) {
              selectedFilterName = filter.name;
              activeFilterObj = { ...filter?.data, name: filter.name };
              return {
                ...filter,
                selected: true,
              };
            }
            return {
              ...filter,
              selected: false,
            };
          });
    setActiveFilter(activeFilterObj);
    const selected = filtersData?.find((val) => val?.selected);
    setFilters(
      isValueNullOrEmpty(selected) ? EventListingFilters : filtersData,
    );

    const updatedQueryFilter = {
      ...queryFilters,
      filter: {
        campus: { _id: campusID },
        isActive: true,
        priorityEventId: eventId ?? null,
        eventTiming:
          activeFilterObj?.startDate && activeFilterObj?.endDate
            ? {
                slot: {
                  startTime: activeFilterObj.startDate.toString(),
                  endTime: activeFilterObj.endDate.toString(),
                  isActive: true,
                },
              }
            : undefined,
      },
      sort: 'SLOT_START_TIME_ASC',
      includeFindUser: isLoggedIn,
      id,
      count: EVENTS_PAGE_SIZE,
    };

    if (selectedFilterName === 'Upcoming') {
      updatedQueryFilter.filter.upcomingEvent = true;
    } else if (activeFilterObj?.startDate && activeFilterObj?.endDate) {
      updatedQueryFilter.filter.eventTiming = {
        slot: {
          startTime: activeFilterObj.startDate.toString(),
          endTime: activeFilterObj.endDate.toString(),
          isActive: true,
        },
      };
    }
    setQueryFilters(updatedQueryFilter);
    refresh();
  };

  const opacity = useSharedValue(1);
  const height = useSharedValue(40);

  const scrollThreshold = 120;
  const lastScrollPosition = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      try {
        if (event.contentOffset.y <= 0) {
          height.value = withTiming(40);
          opacity.value = withTiming(1);
          return;
        }

        const deltaY = event?.contentOffset?.y - lastScrollPosition?.value;

        if (Math.abs(deltaY) > scrollThreshold) {
          if (deltaY < 0) {
            height.value = withTiming(40);
            opacity.value = withTiming(1);
          } else {
            height.value = withTiming(8);
            opacity.value = withTiming(0.01);
          }
          lastScrollPosition.value = event?.contentOffset?.y;
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const showHideStyle = useAnimatedStyle(() => {
    return {
      height: height.value,
      opacity: opacity.value,
      marginBottom: (12 * height.value) / 40,
    };
  });

  const insets = useSafeAreaInsets();

  return (
    <Layout level={2}>
      <View
        borderBottomWidth="xs"
        borderColor="grey.50"
        mt={insets.top + getTheme().space.lg}
      >
        <View flexDirection="row" px="2xl" pb="lg">
          <View style={{ height: 38, width: 38 }} />
          <View
            flexDirection="row"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              size="md"
              color="primary.400"
              numberOfLines={1}
              ellipsizemode="tail"
            >
              Events
            </Text>
          </View>
          <AccountScreenIcon
            imageLink={imageLink}
            firstName={firstName}
            lastName={lastName}
            isBlackMember={isBlackMember}
          />
        </View>
        {!(isListEmpty && activeFilter?.name === 'All') && (
          <Animated.View style={showHideStyle}>
            <ListingFilters
              isVisible={!(isListEmpty && activeFilter?.name === 'All')}
              data={filters}
              onPress={onFilterPress}
            />
          </Animated.View>
        )}
      </View>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense
          fallback={
            Platform.OS === 'web' ? <Loading /> : <EventsScreenPlacehodler />
          }
        >
          <Listing
            refreshedQueryOptions={refreshedQueryOptions}
            queryFilters={queryFilters}
            onScroll={scrollHandler}
            setIsListEmpty={(isEmpty: boolean) => {
              setIsListEmpty(isEmpty);
            }}
            eventId={eventId}
          />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default EventsListing;

const EventStatusTag = ({ statusTag }) => {
  return (
    <View position="absolute" top={0} zIndex={20} overflow="hidden">
      <View
        bg="primary.10"
        borderBottomWidth="xs"
        borderRightWidth="xs"
        borderColor="warning.100"
        borderBottomRightRadius="xl"
        px="lg"
        py="sm"
      >
        <View
          px="2xl"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <View
            width={8}
            height={8}
            borderRadius="full"
            bg={getStatusColor(statusTag?.status)}
          />
          <Text ml="sm" size="xs" color={getStatusColor(statusTag?.status)}>
            {statusTag?.label ?? ''}
          </Text>
        </View>
      </View>
    </View>
  );
};

const getStatusColor = (status: StatusType) => {
  switch (status) {
    case 'upcoming':
      return 'warning.500';
    default:
      return 'info.500';
  }
};

function getDateRange(input: filterRangeInput): {
  startDate: Date | null;
  endDate: Date | null;
} {
  const today = moment().startOf('day').add(330, 'm').utc();
  const endOfDay = moment().add(1, 'day').startOf('day').add(330, 'm').utc();

  switch (input.toLowerCase()) {
    case 'All':
      return { startDate: null, endDate: null };
    case 'today':
      return { startDate: today.toDate(), endDate: endOfDay.toDate() };
    case 'tomorrow':
      const tomorrow = today.clone().add(1, 'day');
      const endOfTomorrow = endOfDay.clone().add(1, 'day');
      return {
        startDate: tomorrow.toDate(),
        endDate: endOfTomorrow.toDate(),
      };
    case 'this weekend':
      const saturday = moment()
        .isoWeekday(5)
        .startOf('day')
        .add(330, 'm')
        .utc();
      const sunday = moment()
        .isoWeekday(7)
        .add(1, 'day')
        .startOf('day')
        .add(330, 'm')
        .utc();
      return { startDate: saturday.toDate(), endDate: sunday.toDate() };
    default:
      return { startDate: null, endDate: null };
  }
}

type filterRangeInput = 'All' | 'today' | 'tomorrow' | 'this weekend';

const EventListingFilters: ListingFilterDataTypeWithOptionalData[] = [
  {
    name: 'All',
    selected: true,
    data: getDateRange('All'),
  },
  {
    name: 'Today',
    selected: false,
    data: getDateRange('today'),
  },
  {
    name: 'Tomorrow',
    selected: false,
    data: getDateRange('tomorrow'),
  },
  {
    name: 'This Weekend',
    selected: false,
    data: getDateRange('this weekend'),
  },
  {
    name: 'Upcoming',
    selected: false,
  },
];
