/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const CouponsComponentQuery = graphql`
  query CouponsComponentQuery(
    $filter: FilterFindManycouponInput
    $issuedFilter: FilterFindManycouponIssuanceInput
    $issuedCount: Int!
    $count: Int!
    $cursor: String
  ) {
    ...CouponsComponentQueryFindCouponsFragment
      @arguments(filter: $filter, count: $count, cursor: $cursor)
    ...CouponsComponentQueryFindIssuedCouponsFragment
    @arguments(filter: $issuedFilter, count: $issuedCount, cursor: $cursor)
  }
`;

export const CouponsComponentQueryNonLoggedInQuery = graphql`
  query CouponsComponentQueryNonLoggedInQuery(
    $filter: FilterFindManycouponInput
    $count: Int!
    $cursor: String
  ) {
    ...CouponsComponentQueryFindCouponsFragment
      @arguments(filter: $filter, count: $count, cursor: $cursor)
  }
`;

export const CouponsComponentFindCouponsFragment = graphql`
  fragment CouponsComponentQueryFindCouponsFragment on Query
  @refetchable(queryName: "CouponsComponentFindCouponsFragmentQuery")
  @argumentDefinitions(
    filter: { type: "FilterFindManycouponInput" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findCoupons(filter: $filter, first: $count, after: $cursor)
      @connection(key: "CouponsComponentFindCouponsFragment__findCoupons") {
      edges {
        node {
            couponCode
            briefText
            finePrint {
                details
                summary
            }
            couponBenefit{
                type
                value
            }
            couponExpiry {
                durationInDays
            }
            created_at
            maxDiscountAmount
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const CouponsComponentFindIssuedCouponsFragment = graphql`
  fragment CouponsComponentQueryFindIssuedCouponsFragment on Query
  @refetchable(queryName: "CouponsComponentFindIssuedCouponsFragmentQuery")
  @argumentDefinitions(
    filter: { type: "FilterFindManycouponIssuanceInput" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findIssuedCoupons(filter: $filter, first: $count, after: $cursor)
      @connection(key: "CouponsComponentFindCouponsFragment__findIssuedCoupons") {
      edges {
        node {
            couponCode
            briefText
            finePrint {
                details
                summary
            }
            couponBenefit{
                type
                value
            }
            couponExpiryDate
            created_at
            maxDiscountAmount
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
