import React, { forwardRef } from 'react';
import styled from 'styled-components/native';
import {
  color,
  border,
  space,
  shadow,
  position,
  variant as variantStyle,
} from 'styled-system';
import { Platform, useWindowDimensions } from 'react-native';
import baseTheme from '../../../../themes/new/theme';
import Pressable from '../../basic/Pressable/Pressable';

import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import { InputStates } from '../Input/helpers';
import InputBase from '../Input/InputBase';
import { Appearance, Shape, State } from '../../../../themes/new/helper';

const variants = ({ theme }) => ({
  [InputStates.default]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.success]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'success.500',
    borderRadius: 'md',
  },
  [InputStates.error]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    // borderColor: 'error.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.active]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
    shadowColor: theme.colors.singletone.black,
    ...theme.boxShadows.md,
  },
  [InputStates.disabled]: {
    bg: 'grey.50',
    borderWidth: 'xs',
    color: 'primary.100',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.unstyled]: {
    bg: 'primary.10',
    borderWidth: '0px',
    borderColor: 'primary.50',
  },
});

const StyledInputView = styled(View)(
  {
    justifyContent: 'center',
  },
  color,
  border,
  space,
  shadow,
  (props) => variantStyle({ variants: variants(props) }),
);

const StyledPressabled = styled(Pressable)(
  {
    position: 'absolute',
    paddingTop: ({ theme }) => theme.space.lg,
    paddingBottom: ({ theme }) => theme.space.lg,
    paddingLeft: ({ theme }) => theme.space.lg,
    paddingRight: ({ theme }) => theme.space.lg,
    zIndex: 1,
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  position,
);

function InputWithMobileLabel({
  rightIconName = null,
  rightIconColor,
  state = InputStates.default,
  onRightIconClick = () => {},
  inputRef,
  countryCode,
  ...props
}) {
  return (
    <View flexDirection="row">
      <StyledInputView
        variant={InputStates.disabled}
        width={52}
        borderWidth={1}
        pl="xl"
        pb="xs"
        justifyContent="center"
        alignItems="center"
      >
        <InputBase
          style={
            Platform.OS === 'ios' ? { lineHeight: 0, paddingBottom: 2 } : {}
          }
          textSize="md"
          state={State.DISABLED}
          {...props}
          placeholder=""
          borderWidth={1}
          value={countryCode}
          onChangeText={null}
          autoFocus={false}
        />
      </StyledInputView>

      <StyledInputView variant={state} ml="sm" pb="xs" borderWidth={1} flex={1}>
        <View
          paddingLeft="xl"
          paddingTop={baseTheme.space.lg}
          paddingBottom={baseTheme.space.lg}
        >
          <View py="xs">
            <InputBase
              ref={inputRef}
              state={
                state === InputStates.disabled
                  ? InputStates.disabled
                  : InputStates.unstyled
              }
              {...props}
            />
          </View>
        </View>
        {!!rightIconName && (
          <View position="absolute" zIndex={1} right={0}>
            <IconButton
              name={rightIconName}
              size="md"
              iconColor={rightIconColor}
              appearance={Appearance.GHOST}
              shape={Shape.SQUARE}
              onPress={onRightIconClick}
              withoutBorder
              state={State.ACTIVE}
            />
          </View>
        )}
      </StyledInputView>
    </View>
  );
}

export default InputWithMobileLabel;
