import { graphql, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const findRestaurants2 = (data) => {
  const query = graphql`
    query restaurants2ApiQuery($filter: findRestaurantsFilterInput!) {
      findRestaurants2(filter: $filter) {
        _id
        posId
        displayName
        address {
          addressLine1
        }
        ownerId
        contact {
          phone {
            _id
            prefix
            number
          }
        }
        owner {
          restaurants {
            payBill {
              pointsEnabled {
                redeem
                reward
              }
            }
          }
        }
      }
    }
  `;
  const variables = {
    filter: data,
  };
  return fetchQuery(environment, query, variables).toPromise();
};
