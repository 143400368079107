import React, { useState, useEffect, useContext } from 'react';
import { useWindowDimensions, Linking } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { ThemeContext } from 'styled-components/native';
import { get } from 'lodash';
import {
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import { navigateBack } from '../../../../utilities/helper';
import Barcode from '../../../../components/new/custom/Barcode';
import DarkThemeWrapper from '../../../new/wrapperComp/DarkThemeWrapper';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import useUserStore from '../../../../stores/userStore';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';

const ScanTagIdScreen = ({ navigation, route }) => {
  const { parkingId } = route.params ?? '';
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [scannedError, setScannedError] = useState(false);
  const [tourchMode, setTourchMode] = useState(false);
  const [borderColor, setBorderColor] = useState('info.500');
  const { width, height } = useWindowDimensions();
  const { top } = useSafeAreaInsets();
  const userRole = useUserStore((state) => state.role);
  const theme = useContext(ThemeContext);

  const RequestPermission = async () => {
    // const { status } = await BarCodeScanner.requestPermissionsAsync();
    // setHasPermission(status === 'granted');
    BarCodeScanner.requestPermissionsAsync()
      .then(({ status }) => {
        setHasPermission(status === 'granted');
      })
      .catch((err) => {
        setHasPermission(false);
      });
  };

  useEffect(() => {
    RequestPermission();
  }, []);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setScanned(true);
      setBorderColor('error.500');
      setScannedError(true);
    }, 7000);
    return () => {
      clearInterval(timeoutID);
    };
  }, [scanned]);

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    if (
      data &&
      (data?.length === 6 || data?.length === 5) &&
      typeof Number(data) === 'number' &&
      !Number.isNaN(Number(data))
    ) {
      setBorderColor('success.500');
      setTimeout(() => {
        navigation.navigate('EnterTagIdScreen', {
          searchText: data?.length === 6 ? `${data}` : `0${data}`,
          parkingId,
        });
      }, 200);
    } else {
      setScannedError(true);
      setBorderColor('error.500');
    }
  };

  if (hasPermission === false) {
    return (
      <View flex={1}>
        <TopNavigation
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigateBack(navigation, userRole)}
            />
          }
          appearance="ghost"
          level="1"
        />
        <View flex={1} justifyContent="center" alignItems="center" px="2xl">
          <CustomLottieView
            style={{ width: 200, height: 200 }}
            autoPlay
            loop
            source={require('../../../../../assets/lottie/no-camera.json')}
            containerWidth={200}
          />
          <Text size="2xl" weight="medium" color="primary.400" mt="4xl">
            Permission denied!
          </Text>
          <Text size="md" color="primary.200" mt="lg">
            Enable permission for camera in settings
          </Text>
          <View width="100%">
            <Button
              appearance="filled"
              status="primary"
              size="lg"
              mt="4xl"
              onPress={() => Linking.openSettings()}
            >
              Go To Settings
            </Button>
          </View>
        </View>
      </View>
    );
  }

  const onPressScanAgain = () => {
    setScanned(false);
    setScannedError(false);
    setBorderColor('info.500');
  };

  const onPressAddManually = () => {
    navigation.replace('EnterTagIdScreen', { parkingId });
  };

  return (
    <Layout level={2} edges={['top']}>
      {hasPermission ? (
        <Barcode
          scanned={scanned}
          scannedError={scannedError}
          handleBarCodeScanned={handleBarCodeScanned}
          tourchMode={tourchMode}
          borderColor={get(theme?.colors, borderColor)}
          onPressScanAgain={onPressScanAgain}
          onPressAddManually={onPressAddManually}
          TopNavigationComponent={
            <View
              position="absolute"
              top={top + 8}
              backgroundColor="transparent"
              zIndex={99}
              width="100%"
            >
              <DarkThemeWrapper>
                <TopNavigation
                  appearance="ghost"
                  level="none"
                  IconLeft={
                    <IconButton
                      name="back-outline-300"
                      size="md"
                      appearance="ghost"
                      onPress={() => {
                        navigateBack(navigation, userRole);
                      }}
                    />
                  }
                  IconRight={
                    <IconButton
                      name={tourchMode ? 'flash-on-300' : 'flash-off-300'}
                      size="md"
                      appearance="ghost"
                      onPress={() => {
                        setTourchMode(!tourchMode);
                      }}
                    />
                  }
                />
              </DarkThemeWrapper>
            </View>
          }
        />
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default ScanTagIdScreen;
