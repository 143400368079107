import { graphql, commitMutation } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const updateRestaurantConfig = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation restaurantPartnerApiMutation(
      $restaurantId: String
      $loyalityPoints: Boolean
    ) {
      updateRestaurantConfig(
        restaurantId: $restaurantId
        loyalityPoints: $loyalityPoints
      ) {
        _id
        restaurantId
        pointsEnabled {
          redeem
          reward
        }
      }
    }
  `;
  const variables = {
    restaurantId: data?.restaurantId,
    loyalityPoints: data?.loyalityPoints,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};
