import { commitMutation, graphql } from 'react-relay';
import { Platform } from 'react-native';
import environment from './relayEnvironment';

export const mapTokenToUser = (token: string) => {
  const mutation = graphql`
    mutation notificationTokenMappingApisMapTokenMutation(
      $data: NotificationMappingInput!
    ) {
      mapTokenToUser(data: $data) {
        success
      }
    }
  `;
  const platform =
    // eslint-disable-next-line no-nested-ternary
    Platform.OS === 'android'
      ? 'ANDROID'
      : Platform.OS === 'ios'
      ? 'IOS'
      : 'WEB';
  const variables = {
    data: {
      token,
      device: platform,
    },
  };
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, error) => {
        resolve(response);
      },
      onError: (err) => {
        reject(err);
      },
    });
  });
};

export const removeTokenMapping = (token: string) => {
  const mutation = graphql`
    mutation notificationTokenMappingApisRemoveTokenMutation(
      $data: RemoveNotificationMappingInput!
    ) {
      removeTokenMapping(data: $data) {
        success
      }
    }
  `;

  const variables = {
    data: {
      token,
    },
  };
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, error) => {
        resolve(response);
      },
      onError: (err) => {
        reject(err);
      },
    });
  });
};
