import React from 'react';
import { StyleSheet, ImageBackground, Pressable } from 'react-native';
import { radii } from '../../../../themes/new/theme';
import { IconButton, Button, TopNavigation, View, Text, Icon } from '../..';
import LightThemeWrapper from '../../../../screens/new/wrapperComp/LightThemeWrapper';
import BonusTaskInfoTile from './BonusTaskInfoTile';
import { getBonusTextColor } from '../../../../utilities/bonus';

const bannerBgSrc = 'https://ik.imagekit.io/32ndhub/assets/bg_ontime_blur.png';

export enum BonusOverviewBannerTitleModes {
  NO_TITLE,
  SUB_TITLE,
  DATE_TITLE,
}

type BonusOverviewBannerProps = {
  mode: BonusOverviewBannerTitleModes;
  date?: string;
  bonusAmount: string;
  onTimeTasks: number;
  delayedTasks: number;
  navigateBack?: () => void;
  navigateToTasks?: () => void;
  showInfo: () => void;
  showOnTimeDetails: () => void;
  showDelayedDetails: () => void;
};

const BonusOverviewBanner = ({
  mode,
  date,
  bonusAmount,
  onTimeTasks,
  delayedTasks,
  navigateBack,
  navigateToTasks,
  showInfo,
  showOnTimeDetails,
  showDelayedDetails,
}: BonusOverviewBannerProps) => {
  const bonusTxtClr = getBonusTextColor(onTimeTasks, delayedTasks);

  let mainTitle = '';
  if (mode === BonusOverviewBannerTitleModes.NO_TITLE) mainTitle = '';
  if (mode === BonusOverviewBannerTitleModes.SUB_TITLE)
    mainTitle = "Today's Earnings";
  if (mode === BonusOverviewBannerTitleModes.DATE_TITLE) mainTitle = date;

  return (
    <LightThemeWrapper>
      <View
        bg="primary.500"
        borderBottomLeftRadius={radii.lg + radii.sm}
        borderBottomRightRadius={radii.lg + radii.sm}
      >
        <ImageBackground source={{ uri: bannerBgSrc }} style={styles.bannerImg}>
          <View zIndex={2}>
            <TopNavigation
              title={mainTitle}
              textSize="md"
              appearance="ghost"
              level="none"
              textColor="primary.10"
              IconLeft={
                mode !== 0 && (
                  <IconButton
                    name="back-outline-300"
                    size="md"
                    appearance="ghost"
                    iconColor="primary.10"
                    onPress={() => navigateBack()}
                  />
                )
              }
              IconRight={
                <IconButton
                  name="info-outline-300"
                  size="md"
                  appearance="ghost"
                  iconColor="primary.10"
                  onPress={() => showInfo()}
                />
              }
            />
            <View alignItems="center" justifyContent="center">
              <Text size="3xl" weight="medium" color={bonusTxtClr}>
                {bonusAmount}
              </Text>
              {mode === 0 && (
                <Button
                  mt="1xl"
                  mb="md+sm"
                  appearance="ghost"
                  size="sm"
                  onPress={navigateToTasks}
                >
                  <View
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text size="sm" mr="sm" weight="regular" color="primary.10">
                      Today&#39;s Earnings
                    </Text>
                    <Icon
                      size="md"
                      name="keyboard-arrow-right-300"
                      color="primary.10"
                    />
                  </View>
                </Button>
              )}
            </View>

            <View mt="2xl" mb="4xl" flexDirection="row" justifyContent="center">
              <View width="171" ml="2xl" mr="lg">
                <Pressable onPress={showOnTimeDetails}>
                  <BonusTaskInfoTile taskCount={onTimeTasks} status="on-time" />
                </Pressable>
              </View>
              <View width="171" ml="lg" mr="2xl">
                <View>
                  <Pressable onPress={showDelayedDetails}>
                    <BonusTaskInfoTile
                      taskCount={delayedTasks}
                      status="delayed"
                    />
                  </Pressable>
                </View>
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>
    </LightThemeWrapper>
  );
};

const styles = StyleSheet.create({
  bannerImg: {
    overflow: 'hidden',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});

export default BonusOverviewBanner;
