import * as React from 'react';
import { Image } from 'react-native';
import { Button, Modal, Text, View } from '../../../components/new';

const PunchInOutModal = ({
  title,
  subTitle,
  modalVisible,
  setModalVisible,
  firstCTAText,
  secondCTAText,
  firstCTATextHandler,
  secondCTATextHandler,
  modalPrimaryCB = () => {},
  img,
}) => {
  return (
    <Modal
      bg="blur"
      visible={modalVisible}
      onBackdropPress={() => {
        setModalVisible({ state: false, status: '', primaryCB: () => {} });
      }}
      onRequestClose={() => {
        setModalVisible({ state: false, status: '', primaryCB: () => {} });
      }}
    >
      <View
        bg="background.primary.base"
        py="4xl"
        px="7xl"
        borderRadius="lg"
        mx="2xl"
      >
        <View alignItems="center" mb="4xl">
          {!!img && <Image style={{ width: 60, height: 60 }} source={img} />}
        </View>
        <Text size="2xl" weight="medium" textAlign="center">
          {title}
        </Text>
        {subTitle ? <Text textAlign="center">{subTitle}</Text> : null}
        <Button
          appearance="filled"
          status={firstCTAText === 'Punch - In' ? 'success' : 'primary'}
          onPress={() =>
            firstCTATextHandler().then(() => {
              modalPrimaryCB();
            })
          }
          size="xl"
          mt="4xl"
        >
          {firstCTAText}
        </Button>
        {secondCTAText ? (
          <Button
            appearance="ghost"
            status="info"
            onPress={secondCTATextHandler}
            size="xl"
            mt="xl"
          >
            {secondCTAText}
          </Button>
        ) : null}
      </View>
    </Modal>
  );
};

export default PunchInOutModal;
