import * as React from 'react';
import { forwardRef, useRef, useState } from 'react';
import { ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  // BlurView,
  Button,
  IconButton,
  Modal,
  Text,
  View,
} from '../../../components/new';
import Pressable from '../../../components/new/basic/Pressable/Pressable';
import useBoundStore from '../../../stores';
import { getTheme, themeModes } from '../../../themes/new/theme';
import SelectedCartFooter from '../../../components/new/custom/SelectedCartFooter';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const MenuScreenFooter = (
  {
    selectedIndex,
    menuData,
    restaurantId,
    headersData,
    setSelectedIndex,
    restaurantName,
    // navigation,
    // restaurantposId,
    // restaurant,
  },
  flatlistRef,
) => {
  const cart = useBoundStore((state) => state.cart);
  const insets = useSafeAreaInsets();

  const [menuModalVisible, setMenuModalVisible] = useState(false);

  const menuModalscrollViewRef = useRef(null);

  const scrollMenuModalItemIntoView = (menuModalItemsLayout) => {
    if (typeof selectedIndex === 'number') {
      const topOffset =
        menuModalItemsLayout.height * selectedIndex -
        menuModalItemsLayout.height;
      if (menuModalscrollViewRef) {
        menuModalscrollViewRef.current.scrollTo({
          y: topOffset,
          animated: true,
        });
      }
    }
  };

  return (
    <>
      <View
        mb={
          // eslint-disable-next-line no-nested-ternary
          cart?.length > 0 && restaurantId === cart[0].restaurantId
            ? 0
            : insets.bottom === 0
            ? '2xl'
            : insets.bottom
        }
        // alignItems="center"
        position="absolute"
        left="50%"
        bottom={
          cart?.length > 0 && restaurantId === cart[0].restaurant?._id
            ? 76 + insets.bottom
            : 0
        }
        style={{ transform: [{ translateX: -50 }] }}
      >
        <Button
          iconLeft="menu2-filled-300"
          iconRight=""
          state=""
          appearance="filled"
          size="xl"
          rounded="4xl"
          iconSize="md"
          mb={cart?.length > 0 ? '2xl' : 0}
          onPress={() => {
            firebaseEventLogger('MenuFooterSectionModalOpenPress');
            setMenuModalVisible(!menuModalVisible);
          }}
        >
          Menu
        </Button>
      </View>
      <SelectedCartFooter
        restaurantId={restaurantId}
        restaurantName={restaurantName}
      />
      {/* ==================== */}
      {/* MENU BUTTON MODAL  */}
      {/* ==================== */}
      <Modal
        visible={menuModalVisible}
        onBackdropPress={() => {
          setMenuModalVisible(!menuModalVisible);
        }}
        onRequestClose={() => {
          setMenuModalVisible(!menuModalVisible);
        }}
        bg="blur"
      >
        <View
          width="92%"
          maxWidth={358}
          maxHeight={500}
          bg="background.primary.base"
          borderRadius={10}
          // px="4xl"
          py="sm"
          alignSelf="center"
        >
          <ScrollView
            // showsVerticalScrollIndicator={false}
            onStartShouldSetResponder={() => true}
            ref={menuModalscrollViewRef}
            contentContainerStyle={{
              paddingVertical: getTheme(themeModes.light).space.lg,
            }}
          >
            {headersData.map((item, index) => {
              const menuItemsLength =
                menuData.filter((ele) => item.categoryId === ele.categoryId)
                  .length - 1;

              return (
                <View px="4xl" key={item._id}>
                  <Pressable
                    onPress={() => {
                      firebaseEventLogger('MenuModalSectionCLicked', {
                        item: item.sectionTitle,
                      });
                      setSelectedIndex(index);
                      setMenuModalVisible(!menuModalVisible);
                      setTimeout(() => {
                        const listIndex = menuData.findIndex(
                          (ele) => ele._id === item._id,
                        );
                        // eslint-disable-next-line react/destructuring-assignment
                        flatlistRef?.current?.scrollToIndex({
                          animated: true,
                          index: listIndex,
                        });
                      }, 100);
                      // eslint-disable-next-line react/destructuring-assignment
                    }}
                    onLayout={(e) => {
                      // its important to copy e.native into a variable because for performance reason react makes it null after some time
                      if (index === 0 && e.nativeEvent) {
                        const copyEvent = e.nativeEvent;
                        setTimeout(
                          () => scrollMenuModalItemIntoView(copyEvent.layout),
                          100,
                        );
                      }
                    }}
                  >
                    <View
                      flexDirection="row"
                      // justifyContent="space-between"
                      my="xl"
                    >
                      <View flex={1}>
                        <Text
                          size="md"
                          weight={
                            index === selectedIndex ? 'medium' : 'regular'
                          }
                          color={
                            index === selectedIndex
                              ? 'primary.500'
                              : 'primary.300'
                          }
                          ellipsizeMode="tail"
                          numberOfLines={1}
                          pr="2xl"
                          style={{
                            textDecorationLine:
                              index === selectedIndex ? 'underline' : 'none',
                          }}
                        >
                          {item.sectionTitle}
                        </Text>
                      </View>
                      <Text
                        size="md"
                        weight={index === selectedIndex ? 'medium' : 'regular'}
                        color={
                          index === selectedIndex
                            ? 'primary.500'
                            : 'primary.300'
                        }
                      >
                        {menuItemsLength ?? ''}
                      </Text>
                    </View>
                  </Pressable>
                </View>
              );
            })}
          </ScrollView>
        </View>
        <View mt="2xl" width="90%" alignItems="center">
          <Pressable onPress={() => setMenuModalVisible(!menuModalVisible)}>
            <IconButton
              name="close-outline-300"
              size="xl"
              bg="background.primary.base"
              iconColor="primary.500"
              onPress={() => {
                firebaseEventLogger('MenuFooterSectionModalClosePress');
                setMenuModalVisible(!menuModalVisible);
              }}
            />
          </Pressable>
        </View>
      </Modal>
      {/* // ================ */}
    </>
  );
};

export default forwardRef(MenuScreenFooter);
