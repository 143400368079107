import { useState, useCallback, useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import {
  PaymentGatewayType,
  isToday,
  toISOStringWithTimezone,
} from '../../../utilities/helper';
import { auditorOverviewQuery } from '../API/auditorOverview/auditorOverviewQuery';
import { OverviewCollectionListItem } from './types';

interface AuditorOverviewScreenHookTypes {
  bannerData: {
    amount: number;
  };
  today: string;
  collectionList: OverviewCollectionListItem[];
}

const todaysDate = toISOStringWithTimezone(new Date());

/* eslint-disable-next-line import/prefer-default-export */
export const useAuditorOverviewScreen = (
  queryOptions: NewErrorBoundaryParentState,
): AuditorOverviewScreenHookTypes => {
  const [today, setToday] = useState(todaysDate);

  const { getPopupOrderCollections } = useLazyLoadQuery(auditorOverviewQuery, {
    filter: {
      date: today,
      paymentType: PaymentGatewayType.CASH,
    },
    queryOptions,
  });

  useFocusEffect(
    useCallback(() => {
      setToday(toISOStringWithTimezone(new Date()));
    }, []),
  );

  /**
   * `getPopupOrderCollections` has sorted data by date, with first item of most recent date.
   * here we check if first item has today's data, if yes then that becomes `bannerData` else goes
   * in the collections list.
   */
  const { bannerData, dailyCollections } = useMemo(() => {
    const bnrData = {
      amount: 0,
    };
    const dlyCollections = [];
    getPopupOrderCollections.forEach((collection) => {
      if (isToday(collection?.date)) {
        bnrData.amount = collection?.cashTotal ?? 0;
      } else {
        dlyCollections.push(collection);
      }
    });

    return {
      bannerData: bnrData,
      dailyCollections: dlyCollections,
    };
  }, [getPopupOrderCollections]);

  return {
    bannerData,
    today,
    collectionList: dailyCollections,
  };
};
