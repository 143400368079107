import React from 'react';
import { Pressable } from 'react-native';
import moment from 'moment';
import { View, Text } from '../../../../components/new';
import { DayPickerDataType } from '../types';
import { dateFormat } from '../utils';

export type Props = {
  onSelect: (date: string) => void;
  days: Array<DayPickerDataType>;
  selectedDate: string;
};

const DayPicker = (props: Props) => {
  const { days, onSelect, selectedDate } = props;

  return (
    <View
      borderBottomWidth="xs"
      borderColor="primary.50"
      flexDirection="row"
      justifyContent="center"
      bg="primary.10"
      py="lg"
    >
      {days.map(
        ({ dateString, day, date, highlightColor = 'primary.400' }, idx) => {
          const active = moment(selectedDate, dateFormat).date() === date;
          const isLast = idx === days.length - 1;
          const bg = active ? 'primary.300' : 'primary.10';
          const dayTextColor = active ? 'primary.10' : 'primary.500';
          const dateTextColor = active ? 'primary.10' : highlightColor;

          return (
            <Pressable key={date} onPress={() => onSelect(dateString)}>
              <View
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                bg={bg}
                borderRadius="8"
                height="59"
                minWidth="37"
                py="sm"
                px="lg"
                mr={!isLast && 'lg'}
              >
                <Text color={dayTextColor} size="2xs">
                  {day}
                </Text>
                <Text color={dateTextColor} size="xl">
                  {date}
                </Text>
              </View>
            </Pressable>
          );
        },
      )}
    </View>
  );
};

export default DayPicker;
