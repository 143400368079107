/* eslint-disable prettier/prettier */
import * as React from 'react';
import Animated, {
    withSpring,
    useAnimatedStyle,
    // useSharedValue,
} from 'react-native-reanimated';
import View from '../basic/View/View';
import theme from '../../../themes/new/theme';

const WebCarouselDots = ({ currentIndex, dots, activeColor = theme.colors.singletone.white, inactiveColor = theme.colors.opacity[50] }) => {
    // ==================
    // bot box width shuuld be bigger then dot width and height
    const DotBoxWidth = 20;
    const DotsToShow = dots.length < 5 ? dots.length : 5;
    // =================
    const getHeight = (index) => {
        if (currentIndex === index) {
            return theme.sizes['2xs'];
        }
        if (currentIndex - 1 === index) {
            return theme.sizes['3xs'];
        }
        if (currentIndex + 1 === index) {
            return theme.sizes['3xs'];
        }
        return theme.sizes['5xs'];
    };

    const getWidth = (index) => {
        if (currentIndex === index) {
            return theme.sizes['2xs'];
        }
        if (currentIndex - 1 === index) {
            return theme.sizes['3xs'];
        }
        if (currentIndex + 1 === index) {
            return theme.sizes['3xs'];
        }
        return theme.sizes['5xs'];
    };

    const getTotalWidth = () => {
        return dots.length * DotBoxWidth;
    };

    const getTransformWidth = () => {
        const leftThreshold = Math.floor(DotsToShow / 2);
        const rightThreshold = Math.floor(dots.length - DotsToShow / 2);

        // in middle
        if (currentIndex > leftThreshold && currentIndex < rightThreshold) {
            return (currentIndex - leftThreshold) * DotBoxWidth;
        }

        // from right
        if (currentIndex >= rightThreshold) {
            return (dots.length - DotsToShow) * DotBoxWidth;
        }
        // from left
        // if (currentIndex <= leftThreshold) {
        return 0;
        // }
    };

    const w = getTransformWidth();

    const customSpringStyles = useAnimatedStyle(() => {

        return {
            transform: [
                {
                    translateX: withSpring(0 - w,
                        {
                            damping: 20,
                            stiffness: 90,
                        }
                    ),

                },
            ],
        };
    });

    return (
        <View
            style={{
                width:
                    DotsToShow < dots.length
                        ? DotsToShow * DotBoxWidth
                        : dots.length * DotBoxWidth,
                overflow: 'hidden',
            }}
        >
            <Animated.View
                style={[{
                    display: 'flex',
                    flexDirection: 'row',
                    // transform: `translate(-${getTransformWidth()}px)`,
                    // transition: '.5s all',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    // width: getTotalWidth(),
                },
                    customSpringStyles
                ]}
            >
                {dots.map((item, index) => {
                    return (
                        <View
                            key={JSON.stringify(item)}
                            style={{
                                width: DotBoxWidth,
                                height: DotBoxWidth,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View
                                key={item}
                                style={{
                                    height: getHeight(index),
                                    width: getWidth(index),
                                    transition: '.5s all',
                                    backgroundColor:
                                        currentIndex === index
                                            ? activeColor
                                            : inactiveColor,
                                    borderRadius: 999,
                                }}
                            />
                        </View>
                    );
                })}
            </Animated.View>
        </View>
    );
};

export default WebCarouselDots;
