import React from 'react';
import { Image, Pressable } from 'react-native';
import { Button, Icon, Text, View } from '../../../../components/new';
import BlackMembershipCard from '../../../../components/new/custom/BlackMembershipCard';
import { getTheme, themeModes } from '../../../../themes/new/theme';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import LightThemeWrapper from '../../wrapperComp/LightThemeWrapper';
import EditableAvatar from './EditableAvatar';
import { capitalizeAfterSpace, toRupees } from '../../../../utilities/helper';
import useUserProfileCard from './useUserProfileCard';
import PaymentsImage from '../../../../../assets/images/payments.webp';

const theme = getTheme(themeModes.light);

const UserProfileCard = ({ refreshAPI, apiData: parantApiData }) => {
  const {
    isBlackMember,
    isDarkTheme,
    fullName,
    profileImage,
    savedAmount,
    earnedAmount,
    hasEarnedAmount,
    hasSavedMoney,
    handleCTAPress,
    handleProfilePicEdit,
  } = useUserProfileCard(refreshAPI, parantApiData);

  const amountClr = isDarkTheme ? 'gold.500' : 'primary.500';
  const capitalisedFullname = capitalizeAfterSpace(fullName);
  const ThemeWrapper = isDarkTheme ? DarkThemeWrapper : LightThemeWrapper;

  return (
    <BlackMembershipCard
      shadow="md"
      bgVariant={isDarkTheme ? 'mesh_dark' : 'mesh_light'}
      borderRadius={theme.radii.xl}
      borderColor={
        isDarkTheme ? theme.colors.gold[500] : theme.colors.primary[50]
      }
    >
      <ThemeWrapper>
        <View p="2xl">
          <View
            pb="2xl"
            flexDirection="row"
            alignItems="center"
            borderBottomWidth="xs"
            borderColor="primary.50"
          >
            <EditableAvatar
              isDarkTheme={isDarkTheme}
              fullName={fullName}
              profileImage={profileImage}
              onProfileEdit={handleProfilePicEdit}
            />
            <View ml="2xl">
              <Text mb="sm" size="1xl" color="primary.500">
                {capitalisedFullname}
              </Text>
              {isBlackMember && (
                <Text size="sm" color="gold.500">
                  32nd Plus Member
                </Text>
              )}
            </View>
          </View>
          <Pressable onPress={handleCTAPress}>
            <View
              mt="2xl"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {isBlackMember
                ? renderBlackMemberInfo({
                    hasSavedMoney,
                    hasEarnedAmount,
                    amountClr,
                    savedAmount,
                    earnedAmount,
                  })
                : renderNonBlackMemberInfo({
                    amountClr,
                    isDarkTheme,
                  })}
              {isBlackMember && !hasSavedMoney && (
                <Button
                  state="active"
                  status="info"
                  appearance="ghost"
                  size="md"
                  iconRight="keyboard-arrow-right-300"
                  onPress={handleCTAPress}
                >
                  Redeem Now
                </Button>
              )}
            </View>
          </Pressable>
        </View>
      </ThemeWrapper>
    </BlackMembershipCard>
  );
};

const renderBlackMemberInfo = (props) => {
  const {
    hasSavedMoney,
    hasEarnedAmount,
    amountClr,
    savedAmount,
    earnedAmount,
  } = props;
  return (
    <View
      flexBasis="auto"
      flexGrow={1}
      flexDirection="row"
      justifyContent="space-between"
    >
      <View flexDirection="row" alignItems="center">
        {hasSavedMoney && (
          <Text size="md" color="primary.300">
            Saved <Text color={amountClr}>{toRupees(savedAmount)}</Text>
          </Text>
        )}
        {hasEarnedAmount && (
          <>
            <Text size="md" color="primary.300">
              {hasSavedMoney && ' & '}Earned{' '}
              <Text color={amountClr}>{toRupees(earnedAmount)}</Text>
            </Text>
            <Image source={PaymentsImage} style={{ height: 20, width: 20 }} />
          </>
        )}
      </View>
      {hasSavedMoney && (
        <View>
          <Icon name="keyboard-arrow-right-300" color={amountClr} />
        </View>
      )}
    </View>
  );
};

const renderNonBlackMemberInfo = (props) => {
  const { amountClr, isDarkTheme } = props;

  return (
    <View
      flexBasis="auto"
      flexGrow={1}
      flexDirection="row"
      justifyContent="space-between"
    >
      <View flexDirection="row" alignItems="center">
        <Text size="md" color={isDarkTheme ? 'primary.300' : 'primary.500'}>
          Save upto ₹500 on every visit
        </Text>
      </View>
      <View>
        <Icon name="keyboard-arrow-right-300" color={amountClr} />
      </View>
    </View>
  );
};

export default UserProfileCard;
