import React, {
  Suspense,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  FlatList,
  Linking,
  Platform,
  Share,
  useWindowDimensions,
} from 'react-native';
import { useActionSheet } from '@expo/react-native-action-sheet';
import * as Sharing from 'expo-sharing';
import { useLazyLoadQuery } from 'react-relay';
import {
  useFocusEffect,
  useLinkBuilder,
  useNavigation,
} from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import { isEmpty } from 'lodash';
import {
  Button,
  Divider,
  IconButton,
  Layout,
  Pressable,
  Text,
  View,
} from '../../../components/new';
import ParallaxHeader from '../../../components/new/custom/ParallaxHeader';
import EventsHomePlaceholder from '../shimmerPlaceholders/screens/EventsHomePlaceholder';
import baseTheme, { colors, space } from '../../../themes/new/theme';
import {
  OpenMap,
  daysMaker,
  durationMaker,
  modifyEventsPrice,
  navigateBack,
  openWhatsApp,
} from '../../../utilities/helper';
import useUserStore, { UserRole } from '../../../stores/userStore';
import useCommonStore from '../../../stores/commonStore';
import FaqItem from '../faq/FaqItem';
import Photogrid from '../../../components/new/custom/PhotoGrid';
import EventsHomeFooter from './EventsHomeFooter';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import WorkStep from './WorkStep';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import { eventsHomeQuery } from './API/eventsHomeQuery';
import OurParticipantSection from './OurParticipantSection';
import VideoOrImageComp from './VideoOrImageComp';
import Loading from '../../../components/loading.component';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import useAuthStore from '../../../stores/authStore';
import AppConstants from '../../../utilities/AppConstants';
import EventCardSmall from './EventCardSmall';
import EventsInfoTile from './EventsInfoTile';
import CachedImage from '../../../components/new/custom/CachedImage';
import { isPassedEvent, isSoldOutEvent } from './helpers/helpers';
import { notifyEventMutation } from './API/eventNotifyMutation';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import useLoginModalStore from '../../../stores/loginModalStore';
import { getEventDiscountPercentage } from '../plusMembership/helpers';
import CachedImageBackground from '../../../components/new/custom/CachedImageBackground';
import {
  Appearance,
  State,
  Status,
  FontWeights,
} from '../../../themes/new/helper';
import CustomPressable from '../../../components/new/primitive/CustomPressable/CustomPressable';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import FacebookPixelLogger from '../../../utilities/FacebookPixelLogger';

const actionSheetOptions = ['Copy To Clipboard', 'Cancel'];
const GOLD_METAL_BG = require('../../../../assets/images/black_membership/golden-metal-texture.png');
const PercentLottie = require('../../../../assets/images/black_membership/lottie/percent-dark.json');

enum BS_MODE {
  NONE,
  NOTIFY_SUCCESS,
  NOTIFY_PERMISSION,
}

let numOfLines = 0;

const EventsHome = ({ variables, queryOptions, route, eventId }) => {
  const navigation = useNavigation<any>();
  const userRole = useUserStore((state) => state.role);
  const userId = useUserStore((state) => state.id);
  const campusId = useCommonStore((state) => state.campusId);
  const blackMembership = useUserStore((state) => state.blackMembership);
  const { active: isBlackMember } = blackMembership;
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { id } = route.params;
  const params = JSON.parse(JSON.stringify(route?.params || {}));
  const [textShown, setTextShown] = useState(false);
  const bsRef = useRef();
  const [bsMode, setBsMode] = useState<BS_MODE>(BS_MODE.NONE);
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);

  const linkBuilder = useLinkBuilder();

  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { showActionSheetWithOptions } = useActionSheet();

  const { width: screenWidth } = useWindowDimensions();

  const [openIndex, setOpenIndex] = useState(0);
  const [allEventsData, setAllEventsData] = useState([]);

  const handleItemPress = (index: number) => {
    // Toggle the selected item and close all others
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const { findEventsCurrent, findEventsAll, findAddons } = useLazyLoadQuery(
    eventsHomeQuery,
    variables,
    queryOptions,
  );
  const eventBlackMemberDiscountPercentage = getEventDiscountPercentage(
    findAddons?.length ? findAddons[0] : {},
  );

  useEffect(() => {
    //  if no event found with the given event id then navigate to PLP
    if (
      !findEventsCurrent ||
      (Array.isArray(findEventsCurrent) && findEventsCurrent?.length === 0)
    )
      navigation.replace('user', {
        screen: 'events',
      });
  }, [findEventsCurrent]);

  const isPastOrSoldOutevent =
    isSoldOutEvent(findEventsCurrent[0]) || isPassedEvent(findEventsCurrent[0]);

  const eventData = findEventsCurrent?.[0];
  let price = !isEmpty(eventData?.eventTiming[0]?.slot?.availableTickets)
    ? eventData?.eventTiming[0]?.slot?.availableTickets?.reduce((acc, curr) => {
        if (curr?.isActive) {
          const newPrice = curr?.price / curr?.personAllowed;
          if (newPrice < acc) {
            return newPrice;
          }
        }
        return acc;
      }, Number.MAX_SAFE_INTEGER)
    : 0;
  price = price === Number.MAX_SAFE_INTEGER ? 0 : price;

  useEffect(() => {
    if (findEventsAll?.length > 1 && eventData?._id) {
      const newData = [...findEventsAll];
      newData?.filter((item) => item?._id !== eventData?._id);
      setAllEventsData(newData);
    }
  }, [eventData, findEventsAll]);

  const handleSharePress = async () => {
    firebaseEventLogger('event__share__Tap', {
      id,
      name: eventData?.title,
    });

    const hostName = AppConstants.WEB_URL;
    const extra = linkBuilder(route.name, route.params);
    const url = `${hostName}${extra}`;

    try {
      const options =
        Platform.OS === 'android'
          ? {
              url,
              message: `${url}`,
            }
          : { url };
      await Share.share(options);
    } catch (error) {
      if (Platform.OS === 'web' && !(await Sharing.isAvailableAsync())) {
        showActionSheetWithOptions(
          {
            options: actionSheetOptions,
            cancelButtonIndex: 1,
            // destructiveButtonIndex:3,
          },
          (selectedIndex: number) => {
            switch (selectedIndex) {
              case 0:
                // Save
                // eslint-disable-next-line no-case-declarations
                const shareText = `${url}`;
                // eslint-disable-next-line no-case-declarations
                const dummyInput = document.createElement('input');
                document.body.appendChild(dummyInput);
                dummyInput.value = shareText;
                dummyInput.select();
                document.execCommand('copy');
                document.body.removeChild(dummyInput);
                // alert('Link copied to the clipboard');
                dispatchSnackbar({
                  msg: 'Link copied to the clipboard',
                  status: SnackbarStatus.info,
                  version: SnackbarVersion.v2,
                });
                firebaseEventLogger('copy__To__Clipboard__Tap', {
                  ...params,
                  buttonName: 'copyToClipboard',
                  screenName: 'eventsHome',
                  userType: 'user',
                  interactionType: 'tap',
                });
                break;

              default:
                break;
              // something
            }
          },
        );
      }
    }
  };

  const onLayout = useCallback((e) => {
    if (Math.floor(e.nativeEvent.layout.height / 22) > 3) {
      numOfLines = Math.floor(e.nativeEvent.layout.height / 22);
    }
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      firebaseEventLogger('event__landing', {
        id: eventId,
        name: eventData?.title,
      });
    }, []),
  );

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  let modifiedCoverImage = JSON.parse(
    JSON.stringify(eventData?.media?.cover || []),
  );

  modifiedCoverImage = modifiedCoverImage.sort(
    (a, b) => a?.displayOrder - b?.displayOrder,
  );

  const navigateToImageGallery = ({ index, val }) => {
    const ele = { data: val, curIndex: index };
    navigation.navigate('Gallery', {
      ...ele,
      title:
        eventData?.title?.length > 24
          ? `${eventData?.title?.substring(0, 24)}...`
          : eventData?.title,
    });
  };

  const openHostInstaProfile = () => {
    if (eventData?.host?.socialMediaURL) {
      Linking.openURL(eventData?.host?.socialMediaURL);
    }
  };

  const BlackMemberStrip = (
    <View>
      <CachedImageBackground
        source={GOLD_METAL_BG}
        resizeMode="cover"
        style={{
          justifyContent: 'center',
          width: '100%',
          height: 40,
        }}
      >
        <View py="sm+md" px="2xl" flexDirection="row" alignItems="center">
          <CustomLottieView
            autoPlay
            loop
            source={PercentLottie}
            style={{ width: 24, height: 24 }}
            containerWidth={24}
          />
          <Text
            ml="lg"
            size="xs"
            color="static.black"
            weight={FontWeights.MEDIUM}
          >
            {isBlackMember ? (
              <>
                As a 32nd Plus member, get {eventBlackMemberDiscountPercentage}%
                off now!
              </>
            ) : (
              <>
                Become a 32nd Plus member to get{' '}
                {eventBlackMemberDiscountPercentage}% off now!
              </>
            )}
          </Text>
        </View>
      </CachedImageBackground>
    </View>
  );

  const subscribeForEventNotify = async () => {
    if (!isLoggedIn) {
      firebaseEventLogger('eventsHome__proceedToLogin_Tap', {
        screenName: 'eventsHome',
        userType: 'user',
        buttonName: 'proceedToLogin',
        interactionType: 'Tap',
        eventId,
      });
      setAuthModalOpen(true);
    } else {
      if (Platform.OS !== 'web') {
        try {
          const permission = await Notifications.getPermissionsAsync();
          if (
            permission?.status === 'denied' ||
            permission?.status === 'undetermined'
          ) {
            setBsMode(BS_MODE.NOTIFY_PERMISSION);
            bsRef.current?.show();
            return;
          }
        } catch (e) {
          console.log(e);
          return;
        }
      }
      firebaseEventLogger('eventsHome__NotifyMe_Tap', {
        id: eventId,
        name: eventData?.title,
      });
      notifyEventMutation(
        {
          record: {
            userId,
            campus: {
              _id: campusId,
            },
            topicId: eventId,
          },
        },
        () => {
          // success callback
          setBsMode(BS_MODE.NOTIFY_SUCCESS);
          bsRef.current?.show();
        },
        (e) => {
          // error callback
          let snackBarMsg = 'Failed to subscribe for notification';
          let status = SnackbarStatus.error;
          if (Array.isArray(e?.res?.errors) && e?.res?.errors?.length === 1) {
            const errorMsg = e?.res?.errors[0]?.message;
            if (
              typeof errorMsg === 'string' &&
              errorMsg.includes('already subscribed')
            ) {
              snackBarMsg = 'You have already subscribed to this event';
              status = SnackbarStatus.warning;
            }
          }
          dispatchSnackbar({
            msg: snackBarMsg,
            status,
            version: SnackbarVersion.v3,
          });
        },
      );
    }
  };

  const onBottomSheetDismiss = () => {
    setBsMode(BS_MODE.NONE);
  };

  const needHelpPress = () => {
    firebaseEventLogger('eventsHome__chatWithUs_Tap', {
      id: eventId,
      name: eventData?.title,
    });
    openWhatsApp(`Hi, I would like to know about ${eventData?.title}`);
  };

  if (!eventData) {
    return (
      <View flex={1}>
        <View flex={1} justifyContent="center" alignItems="center">
          <Text>no data found</Text>
        </View>
      </View>
    );
  }

  return (
    <View flex={1}>
      <ParallaxHeader
        minHeight={0}
        images={modifiedCoverImage}
        imageSectionAspectRatio={1}
        showNavigationCta={false}
        onPress={() =>
          navigateToImageGallery({ index: 0, val: eventData?.media?.cover })
        }
        iconRight={
          <IconButton
            name="share-outline-400"
            iconSize="xl"
            iconColor="static.black"
            bg="static.white"
            onPress={handleSharePress}
          />
        }
        navTitle={
          eventData?.title?.length > 24
            ? `${eventData?.title?.substring(0, 24)}...`
            : eventData?.title
        }
        onPressIcon={onPressLeftIcon}
        leftIconName="back-outline-300"
      >
        <View>
          <View backgroundColor="transparent">
            <View mt="4xl" mx="2xl">
              <Text size="xl" color="primary.500" weight="medium" mb="lg">
                {eventData?.title}
              </Text>
              <View>
                <Text
                  size="sm"
                  color="primary.200"
                  mt="lg"
                  onLayout={onLayout}
                  numberOfLines={
                    numOfLines > 0
                      ? numOfLines > 3 && !textShown
                        ? 3
                        : numOfLines
                      : undefined
                  }
                >
                  {eventData && eventData?.description}
                </Text>

                {numOfLines >= 3 && !textShown ? (
                  <Text
                    size="sm"
                    color="primary.200"
                    weight="medium"
                    onPress={() => {
                      numOfLines = 6;
                      setTextShown(true);
                    }}
                  >
                    read more
                  </Text>
                ) : (
                  <></>
                )}
              </View>
            </View>
            {/* // ======== */}
            <View mx="2xl" mt="4xl">
              {!isPastOrSoldOutevent && (
                <EventsInfoTile
                  text={daysMaker(eventData?.eventTiming)}
                  icon="calendar-outline-300"
                />
              )}
              <EventsInfoTile
                text={durationMaker(eventData?.eventTiming)}
                icon="clock-outline"
              />
              <EventsInfoTile
                text={
                  eventData?.venueDetails?.venue ??
                  eventData?.venue ??
                  '32ND Milestone'
                }
                isRightBtn={eventData?.venueDetails?.geo?.lat ? 'Map' : ''}
                rightBtnIcon="navigate-filled-300"
                icon="locationon_outline"
                rightHandler={() =>
                  OpenMap(
                    eventData?.venueDetails?.geo?.lat,
                    eventData?.venueDetails?.geo?.long,
                  )
                }
              />
              {!isValueNullOrEmpty(eventData?.ageLimit) ? (
                <EventsInfoTile text={eventData?.ageLimit} icon="people" />
              ) : null}
            </View>
            {/* // --------- */}
            {!!eventData?.timeline?.length && (
              <View mt="4xl">
                <Text size="lg" color="primary.500" weight="medium" pl="2xl">
                  Why to attend
                </Text>
                <FlatList
                  contentContainerStyle={{
                    marginTop: space['4xl'],
                    paddingLeft: space['2xl'],
                  }}
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  keyExtractor={(item) => item?.title}
                  ListFooterComponent={<View width={space['2xl']} />}
                  renderItem={({ item, index }) => {
                    return (
                      <WorkStep
                        index={index}
                        key={item?.title}
                        {...item}
                        showLeftDivider={
                          index < eventData?.timeline?.length - 1
                        }
                        eventId={id}
                      />
                    );
                  }}
                  data={eventData?.timeline}
                />
              </View>
            )}
            {/* // --------- */}
            {!!eventData?.media?.past?.length && (
              <View mx="2xl" mt="4xl">
                <Text size="lg" color="primary.500" weight="medium">
                  Highlights from the Previous Events
                </Text>
                <View mt="4xl">
                  <Photogrid
                    images={eventData?.media?.past}
                    theme={baseTheme}
                    onPress={(index) => {
                      navigateToImageGallery({
                        index,
                        val: eventData?.media?.past,
                      });
                      firebaseEventLogger('eventsHome__photogridTap_Tap', {
                        screenName: 'eventsHome',
                        userType: 'user',
                        buttonName: 'photogridTap',
                        interactionType: 'Tap',
                        eventId,
                        assetId: eventData?.media?.past?.[index]?._id,
                      });
                    }}
                  />
                </View>
              </View>
            )}
            {!!eventData?.inclusion && (
              <View
                mx="2xl"
                mt="4xl"
                borderTopWidth={1}
                borderBottomWidth={1}
                borderColor="grey.100"
                py="4xl"
              >
                <Text size="lg" color="primary.500" weight="medium">
                  Event inclusions
                </Text>
                <View mt="lg">
                  {eventData?.inclusion?.itemsToProvide?.length > 0 && (
                    <View>
                      {eventData?.inclusion?.itemsToProvide.map((item) => (
                        <View
                          flexDirection="row"
                          key={`itemsToProvide_${item}`}
                        >
                          <View pr="lg">
                            <Text color="primary.300" size="sm">
                              •
                            </Text>
                          </View>
                          <View flex={1}>
                            <Text color="primary.300" size="sm">
                              {item}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  )}
                </View>

                {eventData?.inclusion?.itemsToBring?.length > 0 && (
                  <View mt="4xl">
                    <Text size="lg" color="primary.500" weight="medium">
                      What you need to bring
                    </Text>
                    <View mt="lg">
                      {eventData?.inclusion?.itemsToBring.map((item) => (
                        <View flexDirection="row" key={`itemsToBring_${item}`}>
                          <View pr="lg">
                            <Text color="primary.300" size="sm">
                              •
                            </Text>
                          </View>
                          <View flex={1}>
                            <Text color="primary.300" size="sm">
                              {item}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  </View>
                )}
              </View>
            )}
            {/* // --------- */}
            {!!eventData?.host?.name && (
              <View mt="4xl">
                <View mx="2xl">
                  <Text size="lg" color="primary.500" weight="medium">
                    Know your host
                  </Text>
                  <Pressable
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: space['4xl'],
                    }}
                    onPress={openHostInstaProfile}
                    disabled={!eventData?.host?.socialMediaURL}
                  >
                    <View flexDirection="row" alignItems="center">
                      <CachedImage
                        source={{
                          uri:
                            eventData?.host?.media?.[0]?.mediaType === 'video'
                              ? eventData?.host?.media?.[0]?.thumbnailURL
                              : eventData?.host?.media?.[0]?.mediaURL,
                        }}
                        style={{
                          height: space['9xl'],
                          width: space['9xl'],
                          borderRadius: space['3xl'],
                        }}
                      />
                      <Text
                        size="md"
                        color="primary.400"
                        weight="medium"
                        ml="xl"
                      >
                        {eventData?.host?.name}
                      </Text>
                    </View>
                    {eventData?.host?.socialMediaURL ? (
                      <CachedImage
                        source={require('../../../../assets/images/Instagram_logo_full.webp')}
                        style={{ height: space['3xl'], width: space['3xl'] }}
                      />
                    ) : null}
                  </Pressable>
                  <Text color="primary.200" mb="md" mt="2xl" size="sm">
                    {eventData?.host?.description}
                  </Text>
                  <View mt="2xl">
                    <VideoOrImageComp
                      media={{
                        mediaType: eventData?.host?.media?.[0]?.mediaType,
                        mediaURL: eventData?.host?.media?.[0]?.mediaURL,
                        thumbnailURL: eventData?.host?.media?.[0]?.thumbnailURL,
                      }}
                      aspectRatio={0.66}
                      widthProp={screenWidth - 32}
                      videoRadius="xl"
                      onPress={() => {
                        firebaseEventLogger('eventsHome__hostedBy__Tap', {
                          buttonName: 'hostedBy',
                          screenName: 'eventsHome',
                          userType: UserRole.USER,
                          interactionType: InteractionType.TAP,
                          eventId: id,
                          userId,
                          isLoggedIn,
                          sectionName: 'Hosted By',
                          mediaId: eventData?.host?.media?.[0]?._id,
                        });
                      }}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>
          {!!eventData?.OurParticipant?.length && (
            <View>
              <OurParticipantSection
                data={eventData?.OurParticipant}
                eventId={id}
              />
            </View>
          )}
          {!!allEventsData?.length && (
            <View>
              <View mx="2xl">
                <View mt="9xl">
                  <Text size="lg" color="primary.500" weight="medium">
                    You may also like this
                  </Text>
                </View>
              </View>
              <FlatList
                contentContainerStyle={{
                  paddingTop: space['4xl'],
                  paddingHorizontal: 16,
                }}
                horizontal
                showsHorizontalScrollIndicator={false}
                keyExtractor={(item) => item?._id}
                renderItem={({ item, index }) => {
                  return (
                    <EventCardSmall
                      item={item}
                      index={index}
                      handleCardPress={() => {
                        firebaseEventLogger('eventsHome__youMayLike_Tap', {
                          id: item?._id,
                          name: item?.title,
                        });
                        navigation.push('eventsHome', { id: item?._id });
                      }}
                    />
                  );
                }}
                data={allEventsData}
              />
            </View>
          )}
          <View
            mx="2xl"
            mt="2xl"
            borderColor="grey.50"
            borderWidth={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            p="2xl"
            borderRadius="lg"
          >
            <Text color="primary.400" size="sm">
              Need help?
            </Text>
            <CustomPressable
              appearance={Appearance.FILLED}
              size="lg"
              textSize="sm"
              iconSize="sm"
              leftStyle={{ width: 20, height: 20 }}
              state={State.ACTIVE}
              status={Status.PRIMARY}
              leftContent={require('../../../../assets/whatsapp.png')}
              onPress={needHelpPress}
              textColor="primary.500"
              style={{
                borderColor: colors.primary[300],
              }}
            >
              Chat with us
            </CustomPressable>
          </View>
          {!!eventData?.termsAndConditions?.length && (
            <View>
              <View mx="2xl">
                <View mt="9xl">
                  <Text size="lg" color="primary.500" weight="medium">
                    Terms and Conditions
                  </Text>
                </View>
              </View>
              <FlatList
                contentContainerStyle={{
                  paddingTop: space['4xl'],
                  paddingHorizontal: 16,
                }}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      flexDirection="row"
                      key={`termsAndConditions_${item}`}
                      mt={index === 0 ? 0 : '2xl'}
                    >
                      <View px="lg">
                        <Text color="primary.300" size="md">
                          •
                        </Text>
                      </View>
                      <View flex={1}>
                        <Text color="primary.300" size="md">
                          {item}
                        </Text>
                      </View>
                    </View>
                  );
                }}
                data={eventData?.termsAndConditions}
              />
            </View>
          )}
          {!!eventData?.faqs?.length && (
            <View>
              <View mx="2xl">
                <View mt="7xl">
                  <Text size="lg" color="primary.500" weight="medium">
                    Frequently Asked Questions
                  </Text>
                </View>
                <View mt="lg">
                  <Divider />
                </View>
              </View>
              <FlatList
                style={{ flex: 1 }}
                contentContainerStyle={{
                  paddingTop: 24,
                  paddingHorizontal: 16,
                }}
                renderItem={({ item, index }) => {
                  return (
                    <FaqItem
                      title={item.title}
                      about={item.about}
                      index={index}
                      isOpen={index === openIndex}
                      onPress={handleItemPress}
                    />
                  );
                }}
                data={
                  eventData?.faqs?.map((item, index) => ({
                    index,
                    title: item?.question,
                    about: item?.answer,
                  })) || []
                }
              />
            </View>
          )}
          <View mt="5xl" />
        </View>
      </ParallaxHeader>
      <View>
        <EventsHomeFooter
          id={id}
          title={eventData?.title}
          price={modifyEventsPrice(eventData?.eventTiming)}
          StripContent={BlackMemberStrip}
          isPastOrSoldoutEvent={isPastOrSoldOutevent}
          onNotify={subscribeForEventNotify}
        />
      </View>
      <BottomSheetV2 onClose={onBottomSheetDismiss} ref={bsRef} insets={false}>
        {bsMode === BS_MODE.NOTIFY_SUCCESS && (
          <View px="2xl" alignItems="center">
            <View mb="2xl">
              <CustomLottieView
                autoPlay
                loop
                source={require('../../../../assets/lottie/green-bell.json')}
                style={{ width: 200, height: 200 }}
                containerWidth={200}
              />
            </View>
            <View mb="lg" alignItems="center">
              <Text textAlign="center" size="xl" color="primary.400">
                We will notify you once this event is available for booking,
                thank you for your interest!
              </Text>
            </View>
            <View
              pt="4xl"
              width="100%"
              borderTopWidth="xs"
              borderColor="grey.50"
            >
              <Button
                onPress={() => bsRef.current?.hide()}
                appearance={Appearance.FILLED}
                size="lg"
              >
                Awesome
              </Button>
            </View>
          </View>
        )}
        {bsMode === BS_MODE.NOTIFY_PERMISSION && (
          <View p="2xl" alignItems="center">
            <View mb="4xl" px="4xl">
              <Text textAlign="center" size="md">
                Please grant notification permission in device settings.
              </Text>
            </View>
            <View flexDirection="row">
              <View flex={1} pr="md">
                <Button
                  onPress={() => bsRef.current?.hide()}
                  appearance={Appearance.OUTLINE}
                >
                  Cancel
                </Button>
              </View>
              <View flex={1} pl="md">
                <Button
                  onPress={() => {
                    bsRef.current?.hide();
                    Linking.openSettings();
                  }}
                  appearance={Appearance.FILLED}
                >
                  Open Settings
                </Button>
              </View>
            </View>
          </View>
        )}
      </BottomSheetV2>
    </View>
  );
};

const EventsHomeWrapper = ({ navigation, route }) => {
  const { id } = route.params;
  const params = JSON.parse(JSON.stringify(route?.params || {}));

  const campusID = useCommonStore((state) => state.campusId);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    React.useCallback(() => {
      firebaseEventLogger('event__landing', {
        ...params,
        screenName: 'eventsHome',
        userType: 'user',
        interactionType: 'landing',
      });
    }, []),
  );

  useEffect(() => {
    FacebookPixelLogger('track', 'ViewContent');
  }, []);

  const variables = {
    filter: {
      campus: { _id: campusID },
      _id: id,
      isActive: true,
    },
    filterAllEvents: {
      campus: { _id: campusID },
      isActive: true,
    },
    sort: 'SLOT_START_TIME_ASC',
    limit: 5,
  };

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={({ hasError }) => {
          if (hasError) {
            navigation.replace('user', { screen: 'events' });
            return null;
          }

          return (
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          );
        }}
      >
        <Suspense
          fallback={
            Platform.OS === 'web' ? <Loading /> : <EventsHomePlaceholder />
          }
        >
          <View flex={1}>
            <EventsHome
              queryOptions={refreshedQueryOptions}
              variables={variables}
              route={route}
              eventId={id}
            />
          </View>
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default EventsHomeWrapper;
