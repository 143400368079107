/**
 * @generated SignedSource<<140c6b6e6b855f43cc2e732f54eb29f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type trackDiscountApiDiscountScreenQuery$variables = {};
export type trackDiscountApiDiscountScreenQuery$data = {
  readonly getRestaurantPartnerDiscountTrack: ReadonlyArray<{
    readonly _id: any | null;
    readonly discount: {
      readonly discounts: {
        readonly allUser: number;
        readonly corporateUser: number;
        readonly newUser: number;
      };
      readonly isActive: boolean | null;
    } | null;
    readonly discountId: any | null;
    readonly totalDiscount: number | null;
    readonly totalDiscountPercentage: number | null;
    readonly totalNetAmount: number | null;
    readonly totalRewardsUsed: number | null;
    readonly totalSales: number | null;
  } | null> | null;
};
export type trackDiscountApiDiscountScreenQuery = {
  response: trackDiscountApiDiscountScreenQuery$data;
  variables: trackDiscountApiDiscountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DiscountTrackOTC",
    "kind": "LinkedField",
    "name": "getRestaurantPartnerDiscountTrack",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalSales",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalDiscount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalNetAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalRewardsUsed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalDiscountPercentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurantDiscount",
        "kind": "LinkedField",
        "name": "discount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurantDiscountDiscounts",
            "kind": "LinkedField",
            "name": "discounts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "corporateUser",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "trackDiscountApiDiscountScreenQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "trackDiscountApiDiscountScreenQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "48d4bbf609bed3916bb0d2f0e133e6f9",
    "id": null,
    "metadata": {},
    "name": "trackDiscountApiDiscountScreenQuery",
    "operationKind": "query",
    "text": "query trackDiscountApiDiscountScreenQuery {\n  getRestaurantPartnerDiscountTrack {\n    _id\n    totalSales\n    totalDiscount\n    totalNetAmount\n    totalRewardsUsed\n    totalDiscountPercentage\n    discountId\n    discount {\n      discounts {\n        allUser\n        newUser\n        corporateUser\n      }\n      isActive\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e805ae0dba342d97df03da10ee0a0ba6";

export default node;
