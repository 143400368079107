/* eslint-disable no-extra-boolean-cast */
import React, { memo, useCallback, useRef, useState } from 'react';
import { Dimensions, Image, Platform, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { Button, Text, View } from '../../components/new';
import LocatePhotographer from '../../components/LocatePhotographer';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import BottomSheetV2 from '../../components/new/composites/BottomSheet/BottomSheetV2';
import CustomLottieView from '../../components/new/custom/CustomLottieView';
import { useBottomNudges } from '../../stores/nudges/bottomNudges/bottomNudgesStore';
import { bottomNudgeType } from '../../stores/nudges/bottomNudges/bottomNudgesSlice';

const screenHeight = Dimensions.get('window').height;

const bottomSheetStateMap = {
  GET_CLICKED_NOW: 'GET_CLICKED_NOW',
  FIND_YOUR_PHOTOS: 'PHOTOSHOOT_DONE',
};

const PMNophotos = ({ onRescan }) => {
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const [bottomSheetState, setBottomSheetState] = useState(null);
  const [snapPoints, setSnapPoints] = useState(['55%']);
  const { openBottomNudge, closeBottomNudge } = useBottomNudges();

  const _onPressGetClicked = () => {
    setBottomSheetState(bottomSheetStateMap.GET_CLICKED_NOW);
    bottomSheetModalRef?.current?.show();
  };

  const handleOnDismiss = () => {};

  const _onCheckLater = () => {
    bottomSheetModalRef?.current?.hide();
  };

  const onContentLayout = useCallback(
    ({
      nativeEvent: {
        layout: { height },
      },
    }) => setSnapPoints([height + 50]),
    [],
  );

  const calculateMaxHeight = useCallback(() => {
    return Math.round(screenHeight * 0.7);
  }, []);

  const _onPressReScan = useCallback(() => {
    firebaseEventLogger('photoMoments_ReScan_Tap', {
      screenName: 'PhotoMoments',
      buttonName: 'ReScan',
      userType: 'user',
      interactionType: 'Tap',
    });
    bottomSheetModalRef?.current?.hide();
    if (Platform.OS === 'web') {
      setTimeout(() => {
        openBottomNudge({
          bottomNudgeName: bottomNudgeType.photoMoments,
        });
      }, 200);
    } else {
      onRescan();
    }
  }, []);

  const { bottom } = useSafeAreaInsets();

  const renderBottomSheet = () => {
    return (
      <BottomSheetV2
        ref={bottomSheetModalRef}
        onClose={handleOnDismiss}
        insets={false}
      >
        <View>
          {bottomSheetState === bottomSheetStateMap.GET_CLICKED_NOW && (
            <View>
              <LocatePhotographer
                onPress={() => bottomSheetModalRef?.current?.hide()}
              />
            </View>
          )}

          {bottomSheetState === bottomSheetStateMap.FIND_YOUR_PHOTOS && (
            <View mx="2xl">
              <View alignItems="center">
                <Image
                  source={require('../../../assets/images/photo_upload.webp')}
                  style={{ width: 200, height: 200 }}
                />
              </View>
              <Text
                textAlign="center"
                color="primary.400"
                size="xl"
                weight="medium"
                mt="2xl"
              >
                Uploading photos may take sometime
              </Text>
              <Text textAlign="center" mt="lg" color="primary.200" size="sm">
                Please re-check in sometime. If not found, try rescanning your
                face
              </Text>

              <View
                flexDirection="row"
                mb="2xl"
                justifyContent="center"
                alignItems="center"
              >
                <View mt="4xl" flex={1}>
                  <Button
                    size="lg"
                    appearance="outline"
                    onPress={_onPressReScan}
                  >
                    Re-Scan
                  </Button>
                </View>

                <View mt="4xl" ml="2xl" flex={1}>
                  <Button size="lg" appearance="filled" onPress={_onCheckLater}>
                    Check Later
                  </Button>
                </View>
              </View>
            </View>
          )}
        </View>
      </BottomSheetV2>
    );
  };

  return (
    <View flex={1} mx={15} justifyContent="center">
      <Text textAlign="center" size="2xl" weight="medium">
        No photos found, get your photoshoot done now!
      </Text>
      <View alignItems="center" mt="lg">
        <CustomLottieView
          autoPlay
          loop
          style={{
            height: 170,
            width: 240,
          }}
          source={require('../../../assets/lottie/camera.json')}
          containerWidth={240}
        />
      </View>
      <Button
        size="lg"
        status="primary"
        state="active"
        onPress={_onPressGetClicked}
        mt="9xl"
      >
        Get Clicked Now
      </Button>
      <TouchableOpacity
        onPress={() => {
          setBottomSheetState(bottomSheetStateMap.FIND_YOUR_PHOTOS);
          bottomSheetModalRef?.current?.show();
        }}
      >
        <Text
          size="md"
          textAlign="center"
          mt="2xl"
          color="info.500"
        >{`Can't find your clicked photos?`}</Text>
      </TouchableOpacity>
      {renderBottomSheet()}
    </View>
  );
};

export default memo(PMNophotos);
