/**
 * @generated SignedSource<<95a2c300563ddd575e7629e46f4d6322>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DailyControllerTaskBonusFilter = {
  date: string;
};
export type controllerDailyBonusQuery$variables = {
  filter: DailyControllerTaskBonusFilter;
};
export type controllerDailyBonusQuery$data = {
  readonly dailyControllerTaskBonus: {
    readonly bonus: {
      readonly bonusAmount: number | null;
      readonly delayedTaskCount: number | null;
      readonly onTimeTaskCount: number | null;
    } | null;
    readonly date: string | null;
  } | null;
};
export type controllerDailyBonusQuery = {
  response: controllerDailyBonusQuery$data;
  variables: controllerDailyBonusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "DailyControllerBonusOutput",
    "kind": "LinkedField",
    "name": "dailyControllerTaskBonus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ControllerBonus",
        "kind": "LinkedField",
        "name": "bonus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onTimeTaskCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "delayedTaskCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bonusAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "controllerDailyBonusQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "controllerDailyBonusQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c4cde83fe3366444c3655a225f504c59",
    "id": null,
    "metadata": {},
    "name": "controllerDailyBonusQuery",
    "operationKind": "query",
    "text": "query controllerDailyBonusQuery(\n  $filter: DailyControllerTaskBonusFilter!\n) {\n  dailyControllerTaskBonus(filter: $filter) {\n    date\n    bonus {\n      onTimeTaskCount\n      delayedTaskCount\n      bonusAmount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7cf57bc045e1288ac0b8a08242c329c9";

export default node;
