import React from 'react';
import { Image } from 'react-native';
import { Icon, Text, View } from '../../../../components/new';

const InvestmentPromise = ({
  data,
  bgColor = '',
  verticleMargin = '9xl+9xl',
}) => {
  return (
    <View py={verticleMargin} bg={bgColor}>
      <Text size="xs" color="primary.300" pb="lg" textAlign="center">
        {data?.heading}
      </Text>
      <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
        {data?.subHeading}
      </Text>
      {data?.data?.length && data?.data?.length > 3 ? (
        <View
          mt="9xl"
          mx="2xl"
          bg="primary.10"
          px="2xl"
          py="xl"
          borderWidth={1}
          borderColor="grey.10"
          borderRadius="lg"
        >
          <View
            flexDirection="row"
            justifyContent="space-between"
            borderBottomWidth={1}
            borderColor="grey.50"
          >
            <View alignItems="center" flex={1} py="4xl" px="xl">
              <Image
                source={{
                  uri: data?.data?.[0]?.icon,
                }}
                style={{
                  height: 40,
                  width: 40,
                  resizeMode: 'contain',
                }}
              />
              <Text
                pt="2xl"
                px="2xl"
                color="primary.400"
                size="sm"
                numberOfLines={2}
                textAlign="center"
              >
                {data?.data?.[0]?.heading}
              </Text>
            </View>
            <View
              alignItems="center"
              flex={1}
              borderLeftWidth={1}
              borderColor="grey.50"
              py="4xl"
              px="xl"
            >
              <Image
                source={{
                  uri: data?.data?.[1]?.icon,
                }}
                style={{
                  height: 40,
                  width: 40,
                  resizeMode: 'contain',
                }}
              />
              <Text
                pt="2xl"
                px="2xl"
                color="primary.400"
                size="sm"
                numberOfLines={2}
                textAlign="center"
              >
                {data?.data?.[1]?.heading}
              </Text>
            </View>
          </View>
          <View flexDirection="row" justifyContent="space-between">
            <View alignItems="center" flex={1} py="4xl" px="xl">
              <Image
                source={{
                  uri: data?.data?.[2]?.icon,
                }}
                style={{
                  height: 40,
                  width: 40,
                  resizeMode: 'contain',
                }}
              />
              <Text
                pt="2xl"
                px="2xl"
                color="primary.400"
                size="sm"
                numberOfLines={2}
                textAlign="center"
              >
                {data?.data?.[2]?.heading}
              </Text>
            </View>
            <View
              alignItems="center"
              flex={1}
              borderLeftWidth={1}
              borderColor="grey.50"
              py="4xl"
              px="xl"
            >
              <Image
                source={{
                  uri: data?.data?.[3]?.icon,
                }}
                style={{
                  height: 40,
                  width: 40,
                  resizeMode: 'contain',
                }}
              />
              <Text
                pt="2xl"
                px="2xl"
                color="primary.400"
                size="sm"
                numberOfLines={2}
                textAlign="center"
              >
                {data?.data?.[3]?.heading}
              </Text>
            </View>
          </View>
        </View>
      ) : (
        <View mt="9xl" px="2xl">
          {data?.data.map((item, index) => (
            <View
              flexDirection="row"
              key={`inv_list_data_comp_hori_${item?._id}`}
              mt={index === 0 ? 0 : '4xl'}
              alignItems="flex-start"
            >
              <View>
                <Icon name={item?.icon} color="primary.400" size="8xl" />
              </View>
              <View alignItems="flex-start" ml="2xl" style={{ flex: 1 }}>
                <Text size="md" weight="medium" color="primary.400">
                  {item?.heading}
                </Text>
                <Text
                  size="xs"
                  color="primary.300"
                  mt="sm"
                  flex={1}
                  numberOfLines={2}
                >
                  {item?.subHeading}
                </Text>
              </View>
            </View>
          ))}
        </View>
      )}
    </View>
  );
};

export default InvestmentPromise;
