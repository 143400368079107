import React, { Suspense, useCallback, useState } from 'react';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { View } from '../../../components/new';
import Layout from '../../../components/new/primitive/Layout/Layout';
import HomePageSections from './components/HomePageSections';
import HomePageNonLoggedIn from './components/HomePageNonLoggedIn';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import PayButton from './components/PayButton.component';
import { HomePagePlaceholder } from './shimmerPlaceholders';
import useUserStore from '../../../stores/userStore';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import useAuthStore from '../../../stores/authStore';
import { Loading } from '../../../components';

const BannerHomeScreen = () => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const userId = useUserStore((state) => state.id);

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2}>
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense
            fallback={
              Platform.OS === 'web' ? <Loading /> : <HomePagePlaceholder />
            }
          >
            {isLoggedIn ? (
              <HomePageSections
                queryOptions={refreshedQueryOptions}
                refresh={refresh}
              />
            ) : (
              <HomePageNonLoggedIn queryOptions={refreshedQueryOptions} />
            )}
          </Suspense>
          {Platform.OS !== 'web' ? (
            <View position="absolute" right="2xl" bottom="3xl+xs">
              <PayButton />
            </View>
          ) : null}
        </NewErrorBoundary>
      </View>
      <DisconnectedDropover
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default BannerHomeScreen;
