import React, { useEffect, useState } from 'react';
import { FlatList, Image, TouchableOpacity } from 'react-native';
import * as Linking from 'expo-linking';
import { fetchQuery, graphql } from 'react-relay';
import { Icon, Text, View } from '../../components/new';
import environment from '../../relay/relayEnvironment';
import { shortenNumber } from '../../utilities/Utility';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

interface ShareOnInstagramBannerProps {
  onClick: () => void;
  showStories: boolean;
}

export const query = graphql`
  query ShareOnInstagramBannerQuery {
    getInstagramBasicData {
      platform
      data {
        followers_count
        momentsMedia {
          mediaUrl
          deepLink
          mediaType
          displayOrder
        }
      }
    }
  }
`;

const RenderStories = (props: {
  image: string;
  deeplink: string;
  index: number;
}) => {
  return (
    <TouchableOpacity
      style={{
        position: 'relative',
        height: 72,
        width: 72,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12,
        marginLeft: props.index === 0 ? 16 : 0,
      }}
      onPress={async () => {
        firebaseEventLogger('photo_moments_stories_clicked', {
          buttonName: 'Stories',
          screenName: 'instagram_share_banner',
          interactionType: 'click',
        });
        if (await Linking.canOpenURL(props.deeplink)) {
          await Linking.openURL(props.deeplink);
        } else {
          console.log('Cannot open URL');
        }
      }}
    >
      <Image
        source={require('../../../assets/images/instaStoryRing.webp')}
        style={{ height: 72, width: 72, position: 'absolute', zIndex: 0 }}
      />
      <Image
        style={{ height: 60, width: 60, zIndex: 1, borderRadius: 30 }}
        source={{ uri: props.image }}
      />
    </TouchableOpacity>
  );
};

const ShareOnInstagramBanner = (props: ShareOnInstagramBannerProps) => {
  const [stories, setStories] = useState<
    { deepLink: string; mediaUrl: string }[]
  >([]);
  const [followerCount, setFollowerCount] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const response = await fetchQuery(environment, query, null, {
        fetchPolicy: 'store-or-network',
        networkCacheConfig: { force: false },
      }).toPromise();

      const { data } = response.getInstagramBasicData;
      const followers = shortenNumber(data.followers_count || 0);
      setIsLoading(false);
      setFollowerCount(followers);
      setStories(data.momentsMedia);
    })();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <TouchableOpacity activeOpacity={1} onPress={props.onClick}>
      <View
        mt="xl"
        shadow="lg"
        borderRadius="md"
        borderWidth="xs"
        borderColor="primary.50"
        py="2xl"
        bg="primary.10"
      >
        <View
          style={{
            flexDirection: 'row',
          }}
          px="2xl"
        >
          <View
            style={{
              height: 46,
              width: 46,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
            mr="lg"
          >
            <View
              style={{
                height: 38,
                width: 38,
                overflow: 'hidden',
                borderRadius: 19,
              }}
            >
              <Image
                style={{
                  height: 38,
                  width: 38,
                  transform: [{ scale: 1 }],
                }}
                resizeMode="contain"
                source={require('../../../assets/images/32nd-logo-dark.webp')}
              />
            </View>
            <View
              style={{
                position: 'absolute',
                backgroundColor: '#ffffff',
                borderRadius: 8,
                height: 16,
                width: 16,
                right: 1,
                bottom: 1,
              }}
            >
              <Icon
                style={{ backgroundColor: '#ffffff' }}
                name="addcircle-400"
                size="md"
                color="info.500"
              />
            </View>
          </View>
          <View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text size="lg" color="primary.400">
                Get featured
              </Text>
              <Image
                style={{ height: 28, width: 114, marginLeft: 4 }}
                source={require('../../../assets/images/32ndOfficial.webp')}
              />
            </View>
            <Text size="xs" color="primary.200">
              {`${followerCount}`} followers
            </Text>
          </View>
        </View>
        {props.showStories && stories.length > 0 && (
          <FlatList
            style={{ marginTop: 12 }}
            data={stories}
            renderItem={(item) => {
              return (
                <RenderStories
                  index={item.index}
                  image={item.item.mediaUrl}
                  deeplink={item.item.deepLink}
                />
              );
            }}
            keyExtractor={(item) => item.deepLink}
            horizontal
            showsHorizontalScrollIndicator={false}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default ShareOnInstagramBanner;
