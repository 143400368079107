import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Icon, Text, View } from '../components/new';
import Pressable from '../components/new/basic/Pressable/Pressable';
import useAuthStore from '../stores/authStore';
import useUserStore, { ControllerRole } from '../stores/userStore';

interface BottomTabRoutes {
  title: string;
  activeIcon: string;
  inactiveIcon: string;
  navigateTo: string;
}

const SupervisorTabs: BottomTabRoutes[] = [
  {
    title: 'Recall',
    activeIcon: 'recall-car-filled-400',
    inactiveIcon: 'recall-car-outline-400',
    navigateTo: 'recall',
  },
  {
    title: 'Park',
    activeIcon: 'park-car-filled-400',
    inactiveIcon: 'park-car-outline-400',
    navigateTo: 'park',
  },
  {
    title: 'Overview',
    activeIcon: 'grid-filled-300',
    inactiveIcon: 'grid-outline-300',
    navigateTo: 'overview',
  },
  {
    title: 'Account',
    activeIcon: 'account-filled-300',
    inactiveIcon: 'account-outline-300',
    navigateTo: 'valetAccount',
  },
];

const ValetSupervisorTabBarNew = ({ navigation, state }) => {
  const selectedState = state.index;
  const isLoggedIn = useAuthStore((authState) => authState.isLoggedIn);
  const { role, controllerRole } = useUserStore((userStore) => userStore);
  let navigateTo = '';

  switch (controllerRole) {
    case ControllerRole.PARK:
      navigateTo = 'park';
      break;
    case ControllerRole.RECALL:
      navigateTo = 'recall';
      break;
    default:
      if (role === 'controller' && !controllerRole) {
        navigateTo = 'controllerHome';
      } else {
        navigateTo = 'recall';
      }
  }

  const ControllerTabs: BottomTabRoutes[] = [
    {
      title: 'Home',
      activeIcon: 'home-filled-300',
      inactiveIcon: 'home-outline-300',
      navigateTo,
    },
    {
      title: 'Overview',
      activeIcon: 'grid-filled-300',
      inactiveIcon: 'grid-outline-300',
      navigateTo: 'overview',
    },
    {
      title: 'Account',
      activeIcon: 'account-filled-300',
      inactiveIcon: 'account-outline-300',
      navigateTo: 'valetAccount',
    },
  ];

  const tabsArr = role === 'controller' ? ControllerTabs : SupervisorTabs;

  return (
    <View
      bg="background.primary.base"
      borderTopColor="primary.50"
      borderTopWidth="xs"
    >
      <SafeAreaView edges={['bottom']}>
        <View
          style={{ flexDirection: 'row', justifyContent: 'space-around' }}
          px="2xl"
          py="sm+md"
        >
          {tabsArr.map((route, index) => {
            return (
              <Pressable
                style={{ flex: 1, alignItems: 'center' }}
                key={route.title}
                onPress={() => {
                  navigation.navigate(route.navigateTo);
                }}
              >
                <View p="xs">
                  <View alignItems="center">
                    <Icon
                      size="2xl"
                      shape="square"
                      color={
                        index === selectedState ? 'primary.500' : 'primary.100'
                      }
                      name={
                        index === selectedState
                          ? route.activeIcon
                          : route.inactiveIcon
                      }
                    />
                  </View>
                  <Text
                    style={{ textAlign: 'center' }}
                    color={
                      index === selectedState ? 'primary.500' : 'primary.100'
                    }
                    size="2xs"
                  >
                    {route.title}
                  </Text>
                </View>
              </Pressable>
            );
          })}
        </View>
      </SafeAreaView>
    </View>
  );
};

export default ValetSupervisorTabBarNew;
