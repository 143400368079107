import { graphql, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

const findBookedEvents = (data) => {
  const query = graphql`
    query eventsApiFindInvoiceQuery(
      $filter: findInvoiceInput!
      $sort: SortFindManyinvoiceInput
    ) {
      findInvoice(filter: $filter, sort: $sort) {
        _id
        displayName
        workshopDetails {
          startTime
          endTime
        }
        sourceDetails {
          event {
            host {
              media {
                mediaURL
              }
            }
          }
        }
      }
    }
  `;
  const variables = { filter: data, sort: 'SLOT_CLOSE_TIME_ASC' };
  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};

export default findBookedEvents;
