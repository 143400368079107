const isSubSet = (newSet, existingSet) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const id of newSet) {
    if (!existingSet.has(id)) {
      return false;
    }
  }
  return true;
};

const createCartSlice = (set) => ({
  cart: [],
  orderDetails: {},
  otherItem: {},
  addToCart: (action) =>
    set((state) => {
      const occuerenceIndexes = [];
      for (let index = 0; index < state.cart.length; index += 1) {
        if (state.cart[index].id === action.id) {
          occuerenceIndexes.push(index);
        }
      }
      if (occuerenceIndexes.length) {
        for (let index = 0; index < occuerenceIndexes.length; index += 1) {
          const itemIndex = occuerenceIndexes[index];
          const existingItem = state.cart[itemIndex];
          if (!existingItem.options.length && !action.options.length) {
            existingItem.quantity += 1;
            existingItem.timestamp = action.timestamp;
            return { cart: [...state.cart] };
          }
          if (existingItem.options.length === action.options.length) {
            const existingSet = new Set();
            const newSet = new Set();
            for (let i = 0; i < existingItem.options.length; i += 1) {
              existingSet.add(existingItem.options[i].id);
            }
            for (let k = 0; k < action.options.length; k += 1) {
              newSet.add(action.options[k].id);
            }
            if (isSubSet(newSet, existingSet)) {
              existingItem.quantity += 1;
              existingItem.timestamp = action.timestamp;
              return { cart: [...state.cart] };
            }
          }
        }
      }
      return { cart: [...state.cart, { ...action, quantity: 1 }] };
    }),
  removeFromCart: (action) =>
    set((state) => {
      const occuerenceIndexes = [];
      const stateArr = state.cart.slice();
      for (let index = 0; index < state.cart.length; index += 1) {
        if (state.cart[index].id === action.id) {
          occuerenceIndexes.push(index);
        }
      }
      if (occuerenceIndexes.length) {
        for (let i = 0; i < occuerenceIndexes.length; i += 1) {
          const itemIndex = occuerenceIndexes[i];
          const existingItem = state.cart[itemIndex];
          if (existingItem.options.length === action.options.length) {
            const existingSet = new Set();
            const newSet = new Set();
            for (let j = 0; j < existingItem.options.length; j += 1) {
              existingSet.add(existingItem.options[j].id);
            }
            for (let k = 0; k < action.options.length; k += 1) {
              newSet.add(action.options[k].id);
            }
            if (isSubSet(newSet, existingSet)) {
              if (existingItem.quantity > 1) {
                existingItem.quantity -= 1;
              } else {
                stateArr.splice(itemIndex, 1);
                if (action.ref) {
                  const index = stateArr.findIndex((item) => {
                    return item.id === action.id;
                  });
                  if (index === -1) {
                    action.ref.current?.hide();
                  }
                }
              }
            }
          }
        }
      }
      return { cart: [...stateArr] };
    }),
  // set((state) => {
  //   const tempCartArr = state.cart.slice();
  //   tempCartArr.splice(tempCartArr.index, 1, { ...action, quantity: 1 });
  //   return { cart: [...tempCartArr] };
  // }),
  updateCart: (action) =>
    set((state) => {
      // checking if same item already exist in the cart (same item can exist in the cart with diffrent options)
      const occuerenceIndexes = [];
      const stateArr = state.cart.slice();
      for (let index = 0; index < state.cart.length; index += 1) {
        if (state.cart[index].id === action.id) {
          occuerenceIndexes.push(index);
        }
      }

      // if there already exist some item with same id then check if they have same options or not
      if (occuerenceIndexes.length) {
        for (let i = 0; i < occuerenceIndexes.length; i += 1) {
          const itemIndex = occuerenceIndexes[i];
          const existingItem = state.cart[itemIndex];
          if (existingItem.options.length === action.options.length) {
            const existingSet = new Set();
            const newSet = new Set();
            for (let j = 0; j < existingItem.options.length; j += 1) {
              existingSet.add(existingItem.options[j].id);
            }
            for (let k = 0; k < action.options.length; k += 1) {
              newSet.add(action.options[k].id);
            }
            // here they have same options
            if (isSubSet(newSet, existingSet)) {
              existingItem.quantity += 1;
              existingItem.timestamp = action.timestamp;

              // ================
              const selectedIndex = action.index;
              const selectedCartItemQuantity = stateArr[selectedIndex].quantity;
              const currentItemAddition =
                action.index === state.cart.indexOf(existingItem);
              if (currentItemAddition) {
                stateArr[selectedIndex].quantity -= 1;
                return { cart: [...stateArr] };
              }
              if (selectedCartItemQuantity > 1) {
                stateArr[selectedIndex].quantity -= 1;
              } else {
                stateArr.splice(selectedIndex, 1);
              }
              return { cart: [...stateArr] };
            }
          }
        }
      }

      // else
      const selectedIndex = action.index;
      const selectedCartItemQuantity = stateArr[selectedIndex].quantity;
      if (selectedCartItemQuantity > 1) {
        stateArr[selectedIndex].quantity -= 1;
        return { cart: [{ ...action, quantity: 1 }, ...stateArr] };
      }
      stateArr.splice(selectedIndex, 1, { ...action, quantity: 1 });
      return { cart: [...stateArr] };
    }),
  clearCart: (action) =>
    set((state) => {
      return { cart: [] };
    }),
  setOrderDetails: (action) =>
    set((state) => {
      // const index = state.orderDetails.findIndex(
      //   (order) => order.restaurantId === action.restaurantId && !order.orderId,
      // );
      // if (index > -1) {
      //   const returnedTarget = Object.assign(state.orderDetails[index], action);
      //   return { orderDetails: [...state.orderDetails] };
      // }
      // if (index === -1 && action.tableNumber) {
      //   // cannot add snackbar because "useSnackbarStore" hook cannot be called outside of a component
      //   alert('Wrong Restaurant');
      //   return { orderDetails: state.orderDetails };
      // }
      // return { orderDetails: [...state.orderDetails, { ...action }] };
      return { orderDetails: action };
    }),
  clearOrderDetails: (action) =>
    set((state) => {
      // const orderDetails = state.orderDetails.filter(
      //   (o) => o.restaurantId !== action.restaurantId && o.orderPlaced,
      // );
      // if (!Object.keys(action).length) {
      //   return { orderDetails: [] };
      // }
      // const index = state.orderDetails.findIndex(
      //   (order) => order.restaurantId === action.restaurantId && !order.orderId,
      // );
      // if (index > -1) {
      //   // console.log(index);
      //   const orderDetailsCopy = [...state.orderDetails];
      //   orderDetailsCopy.splice(index, 1);
      //   // const returnedTarget = Object.assign(state.orderDetails[index], action);
      //   return { orderDetails: orderDetailsCopy };
      // }
      // // return { orderDetails: [...state.orderDetails, { ...action }] };
      return { orderDetails: {} };
    }),
  setOtherItem: (action) =>
    set((state) => {
      return { otherItem: action };
    }),
  clearOtherItem: (action) =>
    set((state) => {
      return { otherItem: {} };
    }),
});

export default createCartSlice;
