import React from 'react';
import View from '../basic/View/View';
import Text from '../primitive/Typography/Text/Text';

const JoinClub = () => {
  return (
    <View>
      <View mb="9xl">
        <Text size="2xl" color="primary.500" textAlign="center">
          Join the 32nd Club and enjoy
        </Text>
        <Text size="2xl" color="primary.500" textAlign="center">
          exclusive member perks!
        </Text>
      </View>
      <View>
        <View flexDirection="row" justifyContent="space-between" mb="7xl">
          <View flex={1}>
            <Text size="5xl" textAlign="center">
              %
            </Text>

            <Text textAlign="center" color="primary.300" size="md">
              Flat 30% off every{' '}
            </Text>
            <Text textAlign="center" color="primary.300" size="md">
              time on parking
            </Text>
          </View>
          <View flex={1}>
            <Text size="5xl" textAlign="center">
              📲
            </Text>

            <Text textAlign="center" color="primary.300" size="md">
              No waiting, recall
            </Text>
            <Text textAlign="center" color="primary.300" size="md">
              from anywhere
            </Text>
          </View>
        </View>
        <View flexDirection="row" justifyContent="space-between">
          <View flex={1}>
            <Text size="5xl" textAlign="center">
              ⏳
            </Text>

            <Text textAlign="center" color="primary.300" size="md">
              Compensation for{' '}
            </Text>
            <Text textAlign="center" color="primary.300" size="md">
              late delivery
            </Text>
          </View>
          <View flex={1}>
            <Text size="5xl" textAlign="center">
              🍽️
            </Text>

            <Text textAlign="center" color="primary.300" size="md">
              Exclusive Discounts
            </Text>
            <Text textAlign="center" color="primary.300" size="md">
              and Cashbacks
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default JoinClub;
