import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const auditorHomeScreenQuery = graphql`
  query auditorHomeScreenQuery(
    $filter: findpaymentOrderfilterInput
    $sort: [PaymentOrderHistorySortEnum]
    $count: Int!
    $cursor: String
  ) {
    ...auditorHomeScreenQuery__paymentHistoryFragmentV2
      @arguments(filter: $filter, sort: $sort, count: $count, cursor: $cursor)
  }
`;

export const paymentHistoryFragment = graphql`
  fragment auditorHomeScreenQuery__paymentHistoryFragmentV2 on Query
  @refetchable(
    queryName: "auditorHomeScreenQuery__paymentHistoryFragmentV2Query"
  )
  @argumentDefinitions(
    filter: { type: "findpaymentOrderfilterInput" }
    sort: { type: "[PaymentOrderHistorySortEnum]" }
    count: { type: "Int!" }
    cursor: { type: "String" }
  ) {
    findPopupOrders(
      filter: $filter
      sort: $sort
      first: $count
      after: $cursor
    )
      @connection(
        key: "auditorHomeScreenQuery__paymentHistoryFragmentV2Query__findPopupOrders"
      ) {
      edges {
        node {
          _id
          status
          created_at
          amount
          referenceModelName
          user {
            _id
            contact {
              phone {
                prefix
                number
              }
            }
          }
          invoice {
            _id
            orderType
            orderId
            created_at
            paymentDetails {
              razorpay {
                pgOrderId
              }
              paytmQr {
                transactionId
              }
            }
          }
          paymentType
          popUpStoreDetails {
            deliveryStatus
            displayName
          }
        }
      }
    }
  }
`;
