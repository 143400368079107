import * as React from 'react';
import {
  RadioGroup,
  View,
  Text,
  Icon,
  Pressable,
  Divider,
  Button,
} from '../../../components/new';
import useCampusLocation from '../../../stores/campusLocation';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';

const LocationDropDown = ({ data }) => {
  const { currentLocation, campusLocations, setCampusLocations } =
    useCampusLocation((state) => state);
  const bottomSheetModalRef = React.useRef(null);
  const [campusLocationsData, setCampusLocationsData] =
    React.useState(campusLocations);

  const handleOnDismiss = React.useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  const handleSetCampusLocations = () => {
    setCampusLocations({ data: campusLocationsData });
    handleOnDismiss();
  };

  const handlePresentModalPress = React.useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);

  return (
    <View>
      <Pressable
        onPress={handlePresentModalPress}
        hitSlop={{ top: 8, left: 8, right: 8, bottom: 8 }}
      >
        <View flexDirection="row" alignItems="center">
          <Text size="md" color="primary.400">
            {currentLocation?.name}
          </Text>
          <View ml="lg">
            <Icon
              size="2xl"
              color="primary.400"
              name="expand-outline-center-300"
            />
          </View>
        </View>
      </Pressable>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Select Location
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            <RadioGroup
              radio={campusLocationsData}
              setRadio={(arr) => setCampusLocationsData(arr)}
              title=""
              isRequired
              size="lg"
              icon="right"
              collapsedCount={5}
            />
          </View>
          <View px="2xl" mt="xl">
            <Button
              size="lg"
              appearance="filled"
              onPress={handleSetCampusLocations}
            >
              Apply
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default LocationDropDown;
