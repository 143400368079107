/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, Icon, Text, View } from '../../../components/new';
import { BonusInfoCard } from '../../../components/new/custom/bonus';
import { usePartnerOverviewScreen } from './usePartnerOverview';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import OverviewBanner from '../../../components/new/custom/OverviewBanner';
import { numberWithCommas } from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import OverviewCollectionList from './OverviewCollectionList';

const PartnerOverviewScreenInner = ({
  queryOptions,
}: {
  queryOptions: NewErrorBoundaryParentState;
}) => {
  const { today, bannerData, collectionList } =
    usePartnerOverviewScreen(queryOptions);
  const navigation = useNavigation<any>();
  const { role } = useUserStore((state) => state);
  const { cashTotal, onlineTotal, amount } = bannerData;

  return (
    <View flex={1}>
      <OverviewBanner>
        <View zIndex={2} mt="4xl">
          <View alignItems="center" justifyContent="center">
            <Text mt="4xl" size="3xl" weight="medium" color="success.500">
              ₹{numberWithCommas(amount)}
            </Text>

            <Button
              mt="1xl"
              mb="md+sm"
              appearance="ghost"
              size="sm"
              onPress={() => {
                navigation.navigate('dayWiseCollectionScreen', { date: today });
              }}
            >
              <View
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Text size="sm" mr="sm" weight="regular" color="primary.10">
                  Today&#39;s Collections
                </Text>
                <Icon
                  size="md"
                  name="keyboard-arrow-right-300"
                  color="primary.10"
                />
              </View>
            </Button>
          </View>

          <View mt="2xl" mb="4xl" flexDirection="row" justifyContent="center">
            <View width="171" ml="2xl" mr="lg">
              <BonusInfoCard>
                <Text mb="sm" size="xl" weight="medium" color="primary.10">
                  ₹{numberWithCommas(cashTotal)}
                </Text>
                <Text size="sm" weight="regular" color="primary.200">
                  Cash Collection
                </Text>
              </BonusInfoCard>
            </View>
            <View width="171" ml="lg" mr="2xl">
              <View>
                <BonusInfoCard>
                  <Text mb="sm" size="xl" weight="medium" color="primary.10">
                    ₹{numberWithCommas(onlineTotal)}
                  </Text>
                  <Text size="sm" weight="regular" color="primary.200">
                    Other Collections
                  </Text>
                </BonusInfoCard>
              </View>
            </View>
          </View>
        </View>
      </OverviewBanner>

      <>
        <OverviewCollectionList collectionList={collectionList} />
      </>
    </View>
  );
};

export default PartnerOverviewScreenInner;
