/**
 * @generated SignedSource<<78e31ee43c1be5608bb83bca343a6d27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumaddonAdditionalCashbackRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonAdditionalDiscountRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonPriceDiscountBaseDiscountType = "PERCENTAGE" | "%future added value";
export type EnumaddonType = "a_32ND_BLACK_MEMBERSHIP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type findAddonsQueryfragment$data = ReadonlyArray<{
  readonly _id: any;
  readonly additionalCashback: ReadonlyArray<{
    readonly cashback: {
      readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
      readonly value: number | null;
    };
    readonly refType: EnumaddonAdditionalCashbackRefType;
  } | null> | null;
  readonly additionalDiscount: ReadonlyArray<{
    readonly discount: {
      readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
      readonly value: number | null;
    };
    readonly refType: EnumaddonAdditionalDiscountRefType;
  } | null> | null;
  readonly type: EnumaddonType;
  readonly " $fragmentType": "findAddonsQueryfragment";
}>;
export type findAddonsQueryfragment$key = ReadonlyArray<{
  readonly " $data"?: findAddonsQueryfragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"findAddonsQueryfragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refType",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "findAddonsQueryfragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "addonAdditionalDiscount",
      "kind": "LinkedField",
      "name": "additionalDiscount",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "addonPriceDiscountBaseDiscount",
          "kind": "LinkedField",
          "name": "discount",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "addonAdditionalCashback",
      "kind": "LinkedField",
      "name": "additionalCashback",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "addonPriceDiscountBaseDiscount",
          "kind": "LinkedField",
          "name": "cashback",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "addon",
  "abstractKey": null
};
})();

(node as any).hash = "9b1a8ef81febdd7366775f2197abc412";

export default node;
