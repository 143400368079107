import React, { useState } from 'react';
import Carousel from 'react-native-reanimated-carousel';
import { Dimensions } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Text, View } from '../../index';
import { shuffle } from '../../../../utilities/helper';
import CarouselProgressTiles from './CarouselProgressTiles';

const CarouselItem = ({ text }) => {
  return (
    <View style={{ alignItems: 'center', width: '100%', height: '100%' }}>
      <Text
        size="xl"
        color="primary.400"
        textAlign="center"
        style={{ marginTop: 'auto', marginBottom: 'auto' }}
      >
        {text}
      </Text>
    </View>
  );
};

const DidYouKnowCarouselNew = () => {
  const theme = useTheme();
  const [data, setData] = useState<{ id: number; text: string }[]>(
    shuffle(didYouKnowData).slice(0, 10),
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { width } = Dimensions.get('window');

  return (
    <View
      py="2xl"
      px="lg"
      borderRadius={theme.radii.lg}
      backgroundColor="background.primary.elevation"
      borderColor="grey.50"
      borderWidth={1}
      shadow="sm"
      style={{
        height: 200,
      }}
    >
      <Text size="md" mb="lg" color="primary.100" textAlign="center">
        Did you know?
      </Text>
      <View style={{ marginBottom: 16 }}>
        <Carousel
          loop
          width={width - 64}
          height={100}
          data={data}
          scrollAnimationDuration={100}
          autoPlayInterval={5000}
          onSnapToItem={(index) => {
            setActiveIndex(index);
          }}
          renderItem={({ index, item }) => <CarouselItem text={item.text} />}
          autoPlay
        />
      </View>
      <View
        style={{
          marginHorizontal: 16,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CarouselProgressTiles
          time={5000}
          numberOfBars={data.length}
          activeIndex={activeIndex}
          availableWidth={width}
          inactiveBarColor={theme.colors.primary[100]}
          activeBarColor={theme.colors.primary[500]}
          borderRadius={40}
          height={3}
        />
      </View>
    </View>
  );
};

export default DidYouKnowCarouselNew;

export const didYouKnowData: {
  id: number;
  text: string;
  textLength: number;
}[] = [
  {
    id: 1,
    text: 'Boju means Grandma in Nepalese',
    textLength: 30,
  },
  {
    id: 2,
    text: 'The 32nd campus has nearly 10 Bonsai trees',
    textLength: 43,
  },
  {
    id: 3,
    text: 'The 10 Bonsais at 32nd were sourced from the banks of the Godavari river',
    textLength: 72,
  },
  {
    id: 4,
    text: 'Mala Akbari is a wordplay on Malabar & Akbar dedicated to the historical cuisine',
    textLength: 80,
  },
  {
    id: 5,
    text: 'The ‘Samosa’ – arguably India’s most popular preparation, originated in Iran',
    textLength: 76,
  },
  {
    id: 6,
    text: 'The Dal Makhani shares the same sauce base as the famous Butter Chicken',
    textLength: 71,
  },
  {
    id: 7,
    text: 'Seattle, the coffee capital of the world, is also home to Third Cuture Coffee',
    textLength: 77,
  },
  {
    id: 8,
    text: 'Third Culture Coffee was founded in Seattle — also the birthplace of Starbucks',
    textLength: 79,
  },
  {
    id: 9,
    text: 'Third Culture Coffee source farm to table, unprocessed, organic ingredients only',
    textLength: 80,
  },
  {
    id: 10,
    text: "Fifth Avenue's classic NY cheescake is a secret recipe from Cheesecake Factory",
    textLength: 79,
  },
  {
    id: 11,
    text: 'The Malleshwaram 18th Cross Dosa is named after a nostalgic street in Bengaluru',
    textLength: 79,
  },
  {
    id: 12,
    text: 'The Potbelly Divine is the first contemporary Bihari restaurant to open in India',
    textLength: 80,
  },
  {
    id: 13,
    text: 'The Potbelly Divine has won The Times Best Regional Bihari Cuisine, twice',
    textLength: 73,
  },
  {
    id: 14,
    text: 'Sattu — a powder made from Bengal gram is a key ingredient in Bihari cuisine',
    textLength: 76,
  },
  {
    id: 15,
    text: 'Haji Banda Hasan, founder of Saleem Javed, introduced Roomali Rolls in 1971',
    textLength: 75,
  },
  {
    id: 16,
    text: 'The founders of Saleem Javed are famously known as the ‘Khan Market Brothers’',
    textLength: 77,
  },
  {
    id: 17,
    text: 'The CAD in CAD tech bar & kitchen stands for Ctrl + Alt + Del',
    textLength: 61,
  },
  {
    id: 18,
    text: 'Pōṣā, the verb to Pōṣān (Poshan) in Sanskrit, translates to nutrition',
    textLength: 69,
  },
  {
    id: 19,
    text: 'Burosu translates to ‘Broth’ in Japanese',
    textLength: 40,
  },
  {
    id: 20,
    text: 'The Library, is a homage to Shakespeare & company in Rue de la Bûcherie, Paris',
    textLength: 78,
  },
  {
    id: 21,
    text: 'To become a pro at ordering ramen, master your choice of Tare(base)',
    textLength: 67,
  },
  {
    id: 22,
    text: 'There are 3 types of bases you can choose from for your ramen at Long Finish',
    textLength: 76,
  },
  {
    id: 23,
    text: 'Shio, meaning salt in Japanese, is a type of base available at Long Finish',
    textLength: 74,
  },
  {
    id: 24,
    text: 'Shoyu, meaning soy in Japanese, is a type of base available at Long Finish',
    textLength: 74,
  },
  {
    id: 25,
    text: 'Tare means a base for your ramen, in Japanese',
    textLength: 45,
  },
  {
    id: 26,
    text: 'Chef Suvir Saran is the founder of the House of Celeste',
    textLength: 55,
  },
  {
    id: 27,
    text: 'First-ever chef to win a Michelin Star for Indian Cuisine is Chef Suvir Saran',
    textLength: 77,
  },
  {
    id: 28,
    text: '32nd Avenue is exactly 32kms from the centre of Delhi — hence the name',
    textLength: 70,
  },
  {
    id: 29,
    text: "32nd launched Howzzat, India's first microbrewery based on a cricket theme",
    textLength: 74,
  },
  {
    id: 30,
    text: '32nd holds a Guinness World Record for hosting the longest dance party in Asia',
    textLength: 78,
  },
  {
    id: 31,
    text: '32nd Milestone was known in the ‘90s and ‘00s primarily for its Go-Karting track',
    textLength: 80,
  },
  {
    id: 32,
    text: 'The Carrot Cranberry tea cake from Fifth Avenue Bakery has no butter in it',
    textLength: 74,
  },
  {
    id: 33,
    text: 'Did you know Michael Jackson visited the 32nd campus back in the day?',
    textLength: 69,
  },
  {
    id: 34,
    text: 'The famous Neapolitan pizza crust originated in the Italian city of Naples',
    textLength: 74,
  },
  {
    id: 35,
    text: "Como Pizzeria's menu has a unique savoury souffle made of feta cheese",
    textLength: 70,
  },
  {
    id: 36,
    text: 'Did you know some eateries on campus were cloud kitchens initially?',
    textLength: 68,
  },
  {
    id: 37,
    text: 'The word "dim sum" means "touch the heart" in Chinese',
    textLength: 53,
  },
  {
    id: 38,
    text: "Boju's Kitchen was started by a mother-daughter duo",
    textLength: 51,
  },
  {
    id: 39,
    text: 'Timpano is the only eatery on campus that serves handmade pasta',
    textLength: 63,
  },
  {
    id: 40,
    text: 'Melt House is the only restaurant on campus that serves breakfast all day',
    textLength: 73,
  },
  {
    id: 41,
    text: 'The Betel Leaf Chicken served at Mala Akbari is from the Ahom kingdom in Assam',
    textLength: 78,
  },
  {
    id: 42,
    text: 'Mala Akbari\'s cocktail "Chandraras" was to celebrate Chandrayaan-3\'s landing',
    textLength: 76,
  },
  {
    id: 43,
    text: "Bhawan's cocktail menu is inspired by authentic Indian ingredients",
    textLength: 66,
  },
  {
    id: 44,
    text: '32nd Avenue was previously called 32nd Milestone',
    textLength: 48,
  },
  {
    id: 45,
    text: 'The Aamras at Bhawan is made of 100% Ratnagiri Alphonso mangoes',
    textLength: 63,
  },
  {
    id: 46,
    text: 'Many Indian eateries on campus serve specific gluten-free dishes during Navratri',
    textLength: 80,
  },
  {
    id: 47,
    text: 'The word "Bhawan" means mansion or house',
    textLength: 40,
  },
  {
    id: 48,
    text: '32nd is the largest pet-friendly F&B & retail destination in NCR',
    textLength: 64,
  },
  {
    id: 49,
    text: 'Fifth Avenue offers some pet-friendly dishes in their menu',
    textLength: 58,
  },
  {
    id: 50,
    text: 'The word "tikka" comes from the Turkic word tikku which means "piece',
    textLength: 69,
  },
  {
    id: 51,
    text: 'The correct way to eat ramen is by starting with the soup or broth',
    textLength: 66,
  },
  {
    id: 52,
    text: "32nd's building was created taking inspiration from New York's flatiron building",
    textLength: 80,
  },
  {
    id: 53,
    text: 'Did you know Frozen Fun serves more than 90 unique flavours of gelato?',
    textLength: 71,
  },
  {
    id: 54,
    text: 'Como Pizzeria started off as a mass delivery kitchen called Baking Bad',
    textLength: 70,
  },
  {
    id: 55,
    text: 'Posa is known to serve authentic Pindi Chole without any tomatoes',
    textLength: 65,
  },
  {
    id: 56,
    text: "Some of Potbelly's chutneys, pastes, and spices are made on a silbatta",
    textLength: 70,
  },
  {
    id: 57,
    text: 'Silbatta is an ancient Indian stone tool used to crush & grind food ingredients',
    textLength: 79,
  },
  {
    id: 58,
    text: 'Unlocked Bar & Kitchen also has a fun escape room experience',
    textLength: 60,
  },
  {
    id: 59,
    text: "Nun-Kun's vegan Pesto Brioche uses coconut butter in its recipe",
    textLength: 63,
  },
  {
    id: 60,
    text: "Nun' and 'Kun' mean salt and sweet, respectively",
    textLength: 48,
  },
  {
    id: 61,
    text: 'Got Tea was founded by three friends who went to school in Mussoorie',
    textLength: 68,
  },
  {
    id: 62,
    text: 'Bubble tea is also popularly known as Boba tea',
    textLength: 46,
  },
  {
    id: 63,
    text: 'Bubble tea originated in Taiwan and reached the US through Taiwanese immigrants',
    textLength: 79,
  },
  {
    id: 64,
    text: 'The Indian kitchen on campus, Posa, offers some amazing homemade pickles',
    textLength: 72,
  },
  {
    id: 65,
    text: "Did you know Bhawan's Appletini cocktail has Himachali apple chutney in it?",
    textLength: 75,
  },
  {
    id: 66,
    text: "Greenr's Build Your Own Pizza offering gives you over 26 toppings to choose from",
    textLength: 80,
  },
  {
    id: 67,
    text: 'Greenr serves a vegan protein variant called Sea Chickpea Tuna',
    textLength: 62,
  },
  {
    id: 68,
    text: "Did you know that Bonsai is an art form, and not just a plant's name?",
    textLength: 69,
  },
  {
    id: 69,
    text: 'Bonsai is the art of growing artifical trees in a pot',
    textLength: 53,
  },
  {
    id: 70,
    text: 'Greenr Cafe was first launched in Shahpur Jat, New Delhi',
    textLength: 56,
  },
  {
    id: 71,
    text: 'Third Wave Coffee has over 90 cafes in India',
    textLength: 44,
  },
  {
    id: 72,
    text: 'Did you know that adding cream to coffee helps keep it warm for longer?',
    textLength: 71,
  },
  {
    id: 73,
    text: 'Kopi Luwak" is the most expensive coffee and it comes from cat poop',
    textLength: 68,
  },
  {
    id: 74,
    text: 'The founder of The Piano Man is an electronic engineer and music scholar',
    textLength: 72,
  },
  {
    id: 75,
    text: 'Musician Arjun Sagar Gupta founded The Piano Man in 2015',
    textLength: 56,
  },
  {
    id: 76,
    text: 'The Piano Man was the first jazz club to open in NCR',
    textLength: 52,
  },
  {
    id: 77,
    text: 'Did you know that Frozen Fun was previously called Cream Choc?',
    textLength: 62,
  },
  {
    id: 78,
    text: "Gelato translates to 'Frozen' in Italian",
    textLength: 40,
  },
  {
    id: 79,
    text: "Did you know that Carnatic Cafe's ice-cream division is called Jaatre?",
    textLength: 70,
  },
  {
    id: 80,
    text: "Jaatre Ice Cream's offerings have no artificial ingredients and are 100% veg",
    textLength: 76,
  },
  {
    id: 81,
    text: "The most popular dish at The Potbelly Divine is 'Madhubani Thali'",
    textLength: 65,
  },
  {
    id: 82,
    text: 'Long Finish started as a delivery kitchen that was called "Ramen Donn',
    textLength: 70,
  },
  {
    id: 83,
    text: 'Haji Banda Hasan, founder of Saleem Javed, is lovingly called "Uncle',
    textLength: 69,
  },
  {
    id: 84,
    text: "Roomali roti gets its name from the word 'roomal' which means handkerchief",
    textLength: 74,
  },
  {
    id: 85,
    text: 'The Mughals used roomali roti as a handkerchief to wipe off excess oil from food',
    textLength: 80,
  },
  {
    id: 86,
    text: 'Como Pizzeria gets its name from the city of Como in Italy',
    textLength: 58,
  },
  {
    id: 87,
    text: 'Como Pizzeria has an outlet in Goa called "Como Agua',
    textLength: 53,
  },
  {
    id: 88,
    text: 'The founders of Bhawan also run a Parsi homestyle restaurant called "Rustom\'s',
    textLength: 78,
  },
  {
    id: 89,
    text: "Bhawan is ranked #19 on Condé Nast Traveller India's Top 50 Restaurants 2023",
    textLength: 76,
  },
  {
    id: 90,
    text: 'The Galleries at 32nd is home to 10+ unique homegrown Indian fashion brands',
    textLength: 75,
  },
  {
    id: 91,
    text: 'The fashion brand Almost Gods, opened their very first store at 32nd Avenue',
    textLength: 75,
  },
  {
    id: 92,
    text: "32nd's founders also established GuestHouser, India's then top vacation platform",
    textLength: 80,
  },
  {
    id: 93,
    text: "The Galleries at 32nd is referred to as India's answer to Dover Street Market",
    textLength: 78,
  },
  {
    id: 94,
    text: 'The 10 ft tall bonsai found at The Galleries at 32nd is called Ficus Microcarpa',
    textLength: 79,
  },
  {
    id: 95,
    text: 'There are more than 25+ F&B brands currently established at 32nd',
    textLength: 65,
  },
  {
    id: 96,
    text: 'The Galleries at 32nd sprawls across 40,000 sq ft in space',
    textLength: 58,
  },
  {
    id: 97,
    text: 'Nadi Nadi & Hannan located at The Galleries at 32nd are sibling brands',
    textLength: 70,
  },
  {
    id: 98,
    text: "The fashion brand 'Ituvana' loosely translates to 'The - itu' & 'vana - Forest'",
    textLength: 80,
  },
  {
    id: 99,
    text: "The brand name Khara Kapas means 'Pure Cotton'",
    textLength: 47,
  },
  {
    id: 100,
    text: "The brand name 'Ka-Sha' is named after it's founder Karishma Shahani",
    textLength: 68,
  },
  {
    id: 101,
    text: "The Highline' at 32nd incubates 5 unique cloud kitchen turned restaurants",
    textLength: 74,
  },
  {
    id: 102,
    text: 'The Highline is one of the only places with super specialised cuisines in Ggn',
    textLength: 77,
  },
  {
    id: 103,
    text: 'Approximately 10K customers visit 32nd on a daily basis',
    textLength: 55,
  },
  {
    id: 104,
    text: "The Monk' – that had set up shop at The Galaxy hotel is now open at 32nd",
    textLength: 73,
  },
  {
    id: 105,
    text: "'The Monk' is launching soon at 32nd!",
    textLength: 37,
  },
  {
    id: 106,
    text: 'Third Culture Coffee conducts coffee brewing workshops every other week at 32nd',
    textLength: 79,
  },
  {
    id: 107,
    text: "Third Culture Coffee's Indian flagship store was first launched at 32nd",
    textLength: 71,
  },
  {
    id: 108,
    text: 'Third Culture Coffee uses 100% natural, farm to table ingredients in their menu',
    textLength: 79,
  },
  {
    id: 109,
    text: 'Third Culture Coffee sells curated & all naturally processed coffee blends',
    textLength: 74,
  },
  {
    id: 110,
    text: 'Third Culture Coffee also sells hand crafted artisanal tea blends',
    textLength: 65,
  },
  {
    id: 111,
    text: "Pollo in 'Sandhouse Pollo Burger' means 'Chicken' in Italian, Mexican & Spanish",
    textLength: 79,
  },
  {
    id: 112,
    text: "Carnatic Cafe's most popular dish is the 'Malleshwaram 18th Cross Dosa'",
    textLength: 71,
  },
  {
    id: 113,
    text: "The 'Benne' in the Benne Khali Dosa means butter in Kannada",
    textLength: 59,
  },
  {
    id: 114,
    text: "Akki Roti' a popular choice at Carnatic Cafe is a regular in Karnataka villages",
    textLength: 79,
  },
  {
    id: 115,
    text: 'There are two types of Folk Meal available at Carnatic — Raagi Roti & Akki Roti',
    textLength: 79,
  },
  {
    id: 116,
    text: "Bombat' in Bombat Dosa at Carnatic Cafe is an old Kannada slang for fantastic",
    textLength: 77,
  },
  {
    id: 117,
    text: "The dosa 'Manina Maga' at Carnatic Cafe stands for 'son of the land' in Kannada",
    textLength: 79,
  },
  {
    id: 118,
    text: 'RYU Bar offers more than 50 signature cocktails along with sake & shochu',
    textLength: 72,
  },
  {
    id: 119,
    text: 'The cocktails at RYU are curated by an award winning mixologist from Malaysia',
    textLength: 77,
  },
  {
    id: 120,
    text: 'Melthouse at 32nd is famous for its grilled cheese patty melt sandwich',
    textLength: 70,
  },
  {
    id: 121,
    text: "Frozen Fun's Chef has 32 years of experience in traditional gelato making",
    textLength: 73,
  },
  {
    id: 122,
    text: "Frozen Fun revamped to a modern black ice-cream kiosk, to fit 32nd's aesthetic",
    textLength: 78,
  },
  {
    id: 123,
    text: "Frozen Fun serves gelato infused with imported liquor in its offering 'Intox'",
    textLength: 77,
  },
  {
    id: 124,
    text: "Frozen Fun first established their outlet 'CreamChoc' in the year 2014 in Goa",
    textLength: 78,
  },
  {
    id: 125,
    text: "Frozen Fun's co-founder Vasuki Punj is a lawyer by profession",
    textLength: 61,
  },
];
