import React, {
  Suspense,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { Platform, Linking } from 'react-native';
import * as Device from 'expo-device';
import { useLazyLoadQuery } from 'react-relay';
import { isEmpty } from 'lodash';
import { useFocusEffect } from '@react-navigation/native';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Card,
  CheckBox,
  Chip,
  IconButton,
  Layout,
  Modal,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useUserStore from '../../../stores/userStore';
import {
  navigateBack,
  paymentOrderTypeEnum,
  applyEllipsis,
  toRupees,
  handleStorePress,
  getFormatedDate,
  DateFormatsEnum,
  MembershipTypes,
} from '../../../utilities/helper';
import { Loading } from '../../../components';
import PaymentFooter from './PaymentFooter';
import {
  fetchConfig,
  paymentAmountScreenQuery,
} from './api/paymentAmountScreenQuery';
import BillingCard from './BillingCard';
import AmountSavedComp from './AmountSavedComp';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import PayWithPointsComp from './PayWithPointsComp';
import CouponBox from './CouponBox';
import { getSourceDetails } from './PaymentScreen';
import useAuthStore from '../../../stores/authStore';
import { Appearance, FontWeights, State } from '../../../themes/new/helper';
import CouponApplied from './CouponApplied';
import {
  isValueNullOrEmpty,
  normalizeToNumberOrZero,
} from '../../../utilities/Utility';
import PaymentBreakUp from './PaymentBreakUp';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { getTheme } from '../../../themes/new/theme';
import {
  BlackMembershipPurchaseCard,
  BlackMembershipGenericPopUp,
  BlackMembershipActiveCard,
  BlackMembershipBenefits,
} from '../plusMembership';
import useLoginModalStore from '../../../stores/loginModalStore';
import CustomScrollView from '../../../components/new/custom/CustomScrollView';
import CustomFlatList from '../../../components/new/custom/CustomFlatList';
import {
  getActiveBlackMembershipId,
  extractBlackMembershipAddonConfig,
  isCustomerBlackMember,
  isStandaloneBlackMembershipBeignPurchased,
} from '../plusMembership/helpers';
import { getEventCancellationInfo } from '../eventsHome/helpers/helpers';
import CancellationInfoBanner from './CancellationInfoBanner';
import MembershipBenefitStrip from './MembershipBenefitsStrip';

type TipConfig = {
  label: string;
  defaultIdx: number;
  values: {
    price: number;
  }[];
};

export enum BS_MODES {
  NONE,
  BREAK_UP,
  CSR_INFO,
  BLACK_MEMBER_BENEFITS,
  BLACK_MEMBER_INVITATION_ON_PAY,
  DOWNLOAD_APP_PROMT_IF_POINTS_USED_ON_WEB,
  EVENT_CANCELLATION_INFO,
  BLACK_MEMBER_PROMPT,
  DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB,
}

const DARK_BOTTOM_SHEET_WHITELIST = [
  BS_MODES.BLACK_MEMBER_BENEFITS,
  BS_MODES.BLACK_MEMBER_INVITATION_ON_PAY,
  BS_MODES.DOWNLOAD_APP_PROMT_IF_POINTS_USED_ON_WEB,
  BS_MODES.DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB,
];

const getBottomSheetStyles = (bsMode: BS_MODES) => {
  if (DARK_BOTTOM_SHEET_WHITELIST.includes(bsMode)) {
    // dark styles
    return {
      containerStyle: {
        backgroundColor: 'black',
        paddingBottom: 0,
        overflow: 'hidden',
      },
      indicatorStyle: {
        margin: 10,
        zIndex: 10,
        position: 'absolute',
        backgroundColor: getTheme()?.colors?.primary[100],
      },
    };
  }

  return {
    indicatorStyle: {
      marginTop: 10,
    },
  };
};

const resolveTipConfig = (orderType: string): TipConfig | undefined => {
  switch (orderType) {
    case paymentOrderTypeEnum.PARKING: {
      return {
        defaultIdx: 0,
        label: 'Tip Your Valet Driver',
        values: [
          { price: 0 },
          { price: 10 },
          { price: 30 },
          { price: 50 },
          { price: 100 },
          { price: 500 },
        ],
      };
    }
    case paymentOrderTypeEnum.restaurant: {
      return {
        defaultIdx: 0,
        label: 'Pay Tip',
        values: [
          { price: 0 },
          { price: 10 },
          { price: 30 },
          { price: 50 },
          { price: 100 },
          { price: 500 },
        ],
      };
    }
    default: {
      return {
        defaultIdx: 0,
        label: 'Pay Tip',
        values: [
          { price: 0 },
          { price: 10 },
          { price: 30 },
          { price: 50 },
          { price: 100 },
          { price: 500 },
        ],
      };
    }
  }
};

const resolveTipEnableStatus = (
  orderType: paymentOrderTypeEnum,
  config: Record<string, any>,
): boolean => {
  const tipConfig = config?.payBillConfig?.tipStatus ?? [];
  const matchedConfig = tipConfig.find((c) => c?.refType === orderType);
  if (typeof matchedConfig !== 'undefined') {
    return matchedConfig?.isTipEnabled;
  }
  return false;
};

const getEnabledCharity = (csrConfig) => {
  let enabledCharityCount = 0;
  let charity = null;
  csrConfig.forEach((c) => {
    if (c?.isActive) {
      charity = c;
      enabledCharityCount += 1;
    }
  });
  if (enabledCharityCount === 1) return charity;
  return null;
};

const PaymentAmountScreen = ({
  backActionHandler,
  tipEnabled,
  charity,
  variables,
  queryOptions,
  payWithPointsState,
  setPayWithPointsState,
  navigation,
  route,
  setTitle,
  appliedCoupon,
  setAppliedCoupon,
  retry,
  blackMembershipConfig,
}) => {
  const paymentFooterRef = useRef();
  const {
    orderType,
    orderId,
    amount,
    slotId,
    eventDate,
    tickets,
    paymentMethod,
  } = route.params ?? {};
  const { workshopDetails = null } = variables;
  const { bottom } = useSafeAreaInsets();
  const [showCouponApplied, setShowCouponApplied] = useState(false);
  const [blackMembershipAdded, setBlackMembershipAdded] = useState(false);
  const [hideAnimation, setHideAnimation] = useState(false);
  const { isLoggedIn } = useAuthStore((state) => state);
  const isCustomerAlreadyBlackMember = useUserStore(
    (state) => state?.blackMembership?.active,
  );
  let pointsWidgetMargin = 'none';
  const isCustomerAlreadyBlackMemberAndOnWeb =
    isCustomerAlreadyBlackMember && Platform.OS === 'web';
  const standaloneBlackMembershipBeignPurchased =
    isStandaloneBlackMembershipBeignPurchased(orderType, orderId, [
      blackMembershipConfig,
    ]);
  const [eventCancellationInfo, setEventCancellationInfo] = useState<{
    isRefundableIfCancelled: boolean;
    refundLastDate: {
      date: Date;
      formatOne: string;
      formatTwo: string;
    };
    eventDate: string;
  } | null>(null);

  useEffect(() => {
    if (
      orderType === paymentOrderTypeEnum.EVENT &&
      typeof eventDate === 'string'
    ) {
      setEventCancellationInfo({
        ...getEventCancellationInfo(new Date(eventDate)),
        eventDate: getFormatedDate(
          new Date(eventDate),
          DateFormatsEnum.DateMonthYear,
        ) as string,
      });
    }
  }, [orderType, eventDate]);

  useFocusEffect(
    React.useCallback(() => {
      if (!hideAnimation) {
        if (!isValueNullOrEmpty(appliedCoupon)) {
          setQueryVariables((prevVariables) => {
            return {
              ...prevVariables,
              couponCode: appliedCoupon?.couponCode,
            };
          });
          setShowCouponApplied(true);
        } else {
          setQueryVariables((prevVariables) => {
            return {
              ...prevVariables,
              couponCode: appliedCoupon?.couponCode,
            };
          });
        }
      } else {
        setHideAnimation(false);
      }
    }, [appliedCoupon]),
  );

  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const bsDismissCbRef = useRef<{ onDismiss: () => void | null }>({
    onDismiss: null,
  });
  const [bsMode, setBsMode] = useState<BS_MODES>(BS_MODES.NONE);
  const isCharityEnabled = Boolean(charity && charity?._id);

  const [charityWidgetState, setCharityWidgetState] = useState({
    applied: false,
    value:
      isCharityEnabled && charity?.contribution?.type === 'FIXED'
        ? charity?.contribution?.value
        : 0,
  });

  /** this is `true` if charity is enabled from backend and user has selected the charity from checkbox */
  const isCharityEnabledAndApplied =
    isCharityEnabled && charityWidgetState?.applied;

  const tipConfig: TipConfig | undefined = useMemo(
    () => resolveTipConfig(orderType),
    [orderType],
  );

  const [selectedTipIndex, setSelectedTipIndex] = useState(
    tipConfig?.defaultIdx ?? 0,
  );

  (function modifyQueryVariablesConditionally() {
    if (appliedCoupon?.couponCode) {
      variables.couponCode = appliedCoupon?.couponCode;
    }

    if (tipEnabled) {
      variables.tipAmount = tipConfig?.values[selectedTipIndex]?.price;
    }

    if (isCharityEnabledAndApplied) {
      variables.csrContributionId = charity?._id;
    }

    if (
      orderType !== paymentOrderTypeEnum.ADDONS &&
      !isCustomerAlreadyBlackMember &&
      blackMembershipConfig?._id
    ) {
      // to fetch price components on applying membership for membership card display purpose
      variables.fetchPreAppliedAddon = true;
      variables.preAppliedAddon = { orderId: blackMembershipConfig?._id };
    } else {
      variables.fetchPreAppliedAddon = false;
    }
    // for black membership standalone purchase
    if (orderType === paymentOrderTypeEnum.ADDONS) {
      /**
       * NOTE: -------------------------------------------------------------------------------------------
       * amount = 1 is just a workaround to prevent API error on backend as it has to be +ve float value.
       * In this case amount = null or amount= undefined is not possible to pass in relay query definition
       * `paymentAmountScreenQuery`due to complicated query. This needs to be handled elegantly in future.
       */
      variables.amount = 1;
    }
  })();

  const [queryVariables, setQueryVariables] = useState({ ...variables });

  const { getNetPrice: getNetPriceData, getNetPriceWithMembership } =
    useLazyLoadQuery(paymentAmountScreenQuery, queryVariables, queryOptions);

  const [data, setData] = useState(getNetPriceData);

  useEffect(() => {
    setData(getNetPriceData);
  }, [getNetPriceData]);

  useEffect(() => {
    if (appliedCoupon?.couponCode) {
      const res = {
        couponCode: appliedCoupon?.couponCode,
        savedAmount: getNetPriceData?.priceDetails?.couponAmount ?? 0,
        cashbackAmount:
          getNetPriceData?.priceDetails?.couponCashbackAmount ?? 0,
      };
      setHideAnimation(true);
      setTimeout(() => {
        setAppliedCoupon(res);
      }, 200);
    }
  }, [blackMembershipAdded]);

  const isBillPayEnabled = data?.sourceDetails?.restaurant?.payBill?.payEnabled;

  const canUsePoints =
    orderType === paymentOrderTypeEnum.restaurant
      ? data?.sourceDetails?.restaurant?.payBill?.pointsEnabled?.redeem
      : true;

  const rewardsAmountCanUsed = canUsePoints
    ? data?.priceDetails?.rewardsUsable
    : 0;

  const rewardsUsable = data?.priceDetails?.rewardsUsable ?? 0;
  let title = '';
  // --------------------
  if (orderType === paymentOrderTypeEnum.INVESTMENT_LEAD) {
    pointsWidgetMargin = '2xl';
    title = 'Payment';
  } else if (standaloneBlackMembershipBeignPurchased) {
    title = 'Plus Membership';
  } else {
    title = getSourceDetails(orderType, data)?.title || '';
  }
  setTitle(title);

  useEffect(() => {
    analytics.landed(title, orderType, orderId);
  }, []);

  const cashbackPoints = normalizeToNumberOrZero(data?.cashbackAmount);
  const cashbackPointsPercentage = data?.cashbackPercentage;
  const discountAmount = data?.priceDetails?.discountGiven;
  const discountPercentage = data?.discountPercentage;
  const rewardsAmountUsed = payWithPointsState
    ? data?.priceDetails?.rewardsUsed
    : 0;
  const netAmount = data?.priceDetails?.netAmount;
  const tipAmountApplied = data?.priceDetails?.tipAmount ?? 0;
  const charityAmountApplied = data?.priceDetails?.csrAmount ?? 0;
  const membershipAmountApplied = data?.priceDetails?.addonAmount ?? 0;
  const totalSavings =
    discountAmount + rewardsAmountUsed + data?.priceDetails?.couponAmount;
  const billAmount = data?.priceDetails?.grossAmount; // naming on backend is not correct. grossAmount on backend is actually bill amount
  const grossAmount =
    billAmount +
    tipAmountApplied +
    charityAmountApplied +
    membershipAmountApplied;
  const couponResData = data?.extras?.coupons;
  const couponCashbackAmount = normalizeToNumberOrZero(
    getNetPriceData?.priceDetails?.couponCashbackAmount,
  );
  const showCouponBox = orderType !== paymentOrderTypeEnum.INVESTMENT_LEAD;
  // --------------------
  const totalCashback = cashbackPoints + couponCashbackAmount;

  if (!isBillPayEnabled && orderType === paymentOrderTypeEnum.restaurant) {
    return (
      <View flex={1} justifyContent="center" alignItems="center">
        <Text>Access Denied</Text>
      </View>
    );
  }

  const handleTipSelection = async (idx) => {
    if (tipEnabled === false) return;
    const tipAmount = tipConfig?.values[idx]?.price;

    analytics.tipAdded(orderType, orderId, tipAmount, title);
    const gVars = {
      ...queryVariables,
      //  `useRewardPoints` must be true if allowed by UI as well as Entity
      useRewardPoints: payWithPointsState && canUsePoints,
      tipAmount,
    };
    if (appliedCoupon) {
      gVars.couponCode = appliedCoupon?.couponCode;
    }
    setQueryVariables(gVars);
    setSelectedTipIndex(idx);
  };

  const handleCharitySelection = async () => {
    const isBeignApplied = !charityWidgetState?.applied;
    analytics.donationCheckBoxClicked(
      orderType,
      orderId,
      isBeignApplied,
      title,
    );
    setQueryVariables({
      ...queryVariables,
      csrContributionId: isBeignApplied ? charity?._id ?? '' : '',
      //  `useRewardPoints` must be true if allowed by UI as well as Entity
      useRewardPoints: payWithPointsState && canUsePoints,
    });
    setCharityWidgetState({
      ...charityWidgetState,
      applied: !charityWidgetState?.applied,
    });
  };

  const openCharityDetailsModal = () => {
    setBsMode(BS_MODES.CSR_INFO);
    bottomSheetModalRef?.current?.show();
  };

  const handlePayWithPointsSelection = (value: boolean) => {
    if (Platform.OS === 'web') {
      analytics.payWithPointsToggled(orderType, orderId, false, title);
      showDownloadAppBottomSheetIfPointsAppliedOnWeb();
      return;
    }
    analytics.payWithPointsToggled(orderType, orderId, value, title);
    setPayWithPointsState(value);
    setQueryVariables({
      ...queryVariables,
      useRewardPoints: value,
    });
  };

  const handleCharityLink = async (webLink: string) => {
    try {
      Linking.openURL(webLink);
    } catch (e) {
      console.log(e);
    }
  };

  const renderTip = ({ item, index }) => {
    const isExist = index === selectedTipIndex;
    return (
      <Chip
        key={`TIP_REAS0N_${item?.price}`}
        state={isExist ? State.SELECTED : State.ACTIVE}
        appearance={Appearance.FILLED}
        onPress={() => handleTipSelection(index)}
        size="xs"
        mr="sm+md"
        mb="xl"
        iconLeft=""
        iconRight=""
        isUpperCase={false}
      >
        {toRupees(item?.price)}
      </Chip>
    );
  };

  const openBreakUpPanel = () => {
    analytics.viewBreakUpClicked(orderType, orderId, title);
    setBsMode(BS_MODES.BREAK_UP);
    bottomSheetModalRef?.current?.show();
  };

  const onBsDismiss = () => {
    setBsMode(BS_MODES.NONE);
    const innerFn = bsDismissCbRef?.current?.onDismiss;
    bsDismissCbRef.current.onDismiss = null;
    if (typeof innerFn === 'function') {
      innerFn();
    }
  };

  const generateBreakUpData = () => {
    const breakUpData: Record<string, any> = {
      ...data,
      billAmount,
    };
    if (orderType === paymentOrderTypeEnum.EVENT) {
      breakUpData.slotId = slotId;
      breakUpData.tickets = JSON.parse(tickets);
    }
    return breakUpData;
  };

  const navigateToEditAmountScreen = () => {
    firebaseEventLogger('PaymentScreenEditAmountClicked', {
      orderType,
      id: orderId,
    });
    setAppliedCoupon(null);
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace('paymentScreen', {
        orderId,
        orderType,
      });
    }
  };
  const addBlackMembershipToCart = () => {
    setQueryVariables({
      ...queryVariables,
      addons: {
        orderId: blackMembershipConfig?._id,
      },
    });
    setBlackMembershipAdded(true);
  };

  const removeBlackMembershipFromCart = () => {
    analytics.blackMembershipRemovedFromCart(orderType, orderId, title);
    setQueryVariables({
      ...queryVariables,
      addons: null,
    });
    setBlackMembershipAdded(false);
  };

  const allowPayNow = (): boolean => {
    analytics.payNowClicked(orderType, orderId, title);
    if (orderType === paymentOrderTypeEnum.INVESTMENT_LEAD) return true;
    if (orderType === paymentOrderTypeEnum.ADDONS) return true;
    if (isCustomerAlreadyBlackMemberAndOnWeb) {
      showDownloadAppBottomSheetIfBlackMemberPaysOnWeb();
      return false;
    }
    if (!isCustomerAlreadyBlackMember && !blackMembershipAdded) {
      showBlackMemberInvitationIfNonBlackMemberPays();
      return false;
    }
    return true;
  };

  const showDownloadAppBottomSheetIfPointsAppliedOnWeb = () => {
    setBsMode(BS_MODES.DOWNLOAD_APP_PROMT_IF_POINTS_USED_ON_WEB);
    bottomSheetModalRef.current?.show();
  };
  const showDownloadAppBottomSheetIfBlackMemberPaysOnWeb = () => {
    setBsMode(BS_MODES.DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB);
    bottomSheetModalRef.current?.show();
  };
  const showBlackMemberInvitationIfNonBlackMemberPays = () => {
    if (!getNetPriceWithMembership) return;
    setBsMode(BS_MODES.BLACK_MEMBER_INVITATION_ON_PAY);
    bottomSheetModalRef.current?.show();
  };
  const showEventCancellationDetailsBottomSheet = () => {
    setBsMode(BS_MODES.EVENT_CANCELLATION_INFO);
    bottomSheetModalRef.current?.show();
  };

  return (
    <View flex={1} pb="9xl">
      {!!totalSavings && (
        <View mb="2xl">
          <AmountSavedComp
            text={`You’re saving ₹${Math.trunc(totalSavings)} on this bill`}
          />
        </View>
      )}
      <CustomScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={{ flex: 1 }}
        // contentContainerStyle={{ flexGrow: 1, width: '100%' }}
      >
        <View mx="2xl" mt="xl">
          {orderType !== paymentOrderTypeEnum.INVESTMENT_LEAD && (
            <>
              {isCustomerAlreadyBlackMember && (
                <View mb="2xl">
                  <BlackMembershipActiveCard
                    data={
                      Platform.OS !== 'web'
                        ? {
                            cashback: getNetPriceData?.cashbackAmount,
                            discount:
                              getNetPriceData?.priceDetails?.discountGiven,
                          }
                        : 'As a member, save upto 50% and get 10% cashback by paying via 32nd Club app!'
                    }
                  />
                </View>
              )}
              {!isCustomerAlreadyBlackMember &&
                getNetPriceWithMembership &&
                blackMembershipConfig?.price && (
                  <View mb="2xl">
                    <BlackMembershipPurchaseCard
                      membershipData={getNetPriceWithMembership}
                      membershipConfig={blackMembershipConfig}
                      membershipAdded={blackMembershipAdded}
                      onRemoveMembership={removeBlackMembershipFromCart}
                      onAddMembership={() => {
                        analytics.blackMembershipAddedToCart(
                          orderType,
                          orderId,
                          title,
                        );
                        addBlackMembershipToCart();
                      }}
                      onViewBenefits={() => {
                        analytics.memberBenefitsClicked(
                          orderType,
                          orderId,
                          title,
                        );
                        setBsMode(BS_MODES.BLACK_MEMBER_BENEFITS);
                        bottomSheetModalRef?.current?.show();
                      }}
                    />
                  </View>
                )}
            </>
          )}
          {/* ------------- TIP WIDGET ------------- */}
          <View>
            {tipEnabled && typeof tipConfig !== undefined && (
              <View mb="3xl">
                <Text size="md" color="primary.200" weight="medium">
                  {tipConfig?.label ?? ''}
                </Text>
                <View flexDirection="row" mt="3xl">
                  <CustomFlatList
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    data={tipConfig?.values ?? []}
                    renderItem={renderTip}
                  />
                </View>
              </View>
            )}
          </View>
          {/* --------------------------- */}
          {!!(rewardsUsable > 0) && !!canUsePoints && (
            <View mb="4xl" mt={pointsWidgetMargin}>
              <PayWithPointsComp
                rewardsAmountCanUsed={rewardsAmountCanUsed}
                payWithPointsState={payWithPointsState}
                setPayWithPointsState={handlePayWithPointsSelection}
              />
            </View>
          )}
          {showCouponBox && (
            <View mb="4xl">
              <Text size="md" color="primary.300" weight="medium" mb="2xl">
                Offers & Benefits
              </Text>
              <CouponBox
                appliedCoupon={appliedCoupon}
                couponResData={couponResData}
                removeCoupon={() => {
                  setAppliedCoupon(null);
                  firebaseEventLogger('paymentScreen__removeCoupon_Tap', {
                    orderType,
                    id: orderId,
                    name: title,
                  });
                }}
                handleCoupons={() => {
                  navigation.navigate('CouponsComponent', {
                    refId: getSourceDetails(orderType, data)?.refId,
                    netPriceVariables: { ...queryVariables },
                    orderType,
                  });
                  firebaseEventLogger('paymentScreen__viewAllCoupons_Tap', {
                    orderType,
                    id: orderId,
                    name: title,
                  });
                }}
              />
            </View>
          )}
          {orderType === paymentOrderTypeEnum.EVENT &&
            eventCancellationInfo?.isRefundableIfCancelled && (
              <View mb="4xl">
                <CancellationInfoBanner
                  onPress={() => {
                    analytics.freeCancellationBannerClicked(
                      orderType,
                      orderId,
                      title,
                    );
                    showEventCancellationDetailsBottomSheet();
                  }}
                  title="Free Cancellation"
                  message={`Get full refund if cancelled before ${eventCancellationInfo.eventDate}`}
                />
              </View>
            )}
          <View>
            <Text color="primary.300" mb="2xl" size="md" weight="medium">
              Bill Summary
            </Text>
          </View>
          {/* -------------------------- */}
          <View pb="4xl">
            <BillingCard
              onViewBreakUp={openBreakUpPanel}
              onEdit={navigateToEditAmountScreen}
              orderType={orderType}
              grossAmount={billAmount}
              netAmount={netAmount}
              cashbackPoints={cashbackPoints.toString()}
              tipEnabled={tipEnabled}
              tipAmount={tipAmountApplied}
              charityEnabled={isCharityEnabledAndApplied}
              charityAmount={charityAmountApplied}
              cashbackPointsPercentage={cashbackPointsPercentage}
              discountAmount={discountAmount}
              discountPercentage={discountPercentage}
              rewardsAmountUsed={rewardsAmountUsed}
              appliedCoupon={appliedCoupon}
              blackMembershipAmount={
                blackMembershipAdded
                  ? getNetPriceData?.priceDetails?.addonAmount ?? 0
                  : 0
              }
              discountPromo={
                isCustomerAlreadyBlackMemberAndOnWeb &&
                orderType !== paymentOrderTypeEnum.INVESTMENT_LEAD
                  ? {
                      text: 'Get Upto 50% Off',
                      cb: () => {
                        showDownloadAppBottomSheetIfBlackMemberPaysOnWeb();
                      },
                    }
                  : null
              }
            />
          </View>
          {isCharityEnabled && charity?.contribution?.type === 'FIXED' && (
            <View mb="9xl">
              <Card py="sm">
                <View>
                  <View
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <View flexDirection="row" alignItems="center">
                      <Text
                        size="sm"
                        weight={FontWeights.MEDIUM}
                        color="primary.400"
                      >
                        {`Donate to '${applyEllipsis(charity?.title, 18)}'`}
                      </Text>
                      <IconButton
                        pl="lg"
                        name="info-outline-300"
                        appearance="ghost"
                        iconSize="md"
                        iconColor="primary.500"
                        onPress={openCharityDetailsModal}
                      />
                    </View>
                    <CheckBox
                      ml={0}
                      flex={1}
                      size="sm"
                      checked={charityWidgetState?.applied}
                      onPress={handleCharitySelection}
                      state={State.ACTIVE}
                    >
                      {toRupees(charity?.contribution?.value ?? 0)}
                    </CheckBox>
                  </View>
                </View>
              </Card>
            </View>
          )}
        </View>
        <View mt={getTheme().space['9xl+9xl'] + bottom} />
      </CustomScrollView>

      <View position="absolute" bottom={0} left={0} right={0}>
        <PaymentFooter
          ref={paymentFooterRef}
          billAmount={billAmount}
          grossAmount={grossAmount}
          netAmount={netAmount}
          tipAmount={
            tipEnabled
              ? (tipConfig?.values[selectedTipIndex]?.price as number)
              : 0
          }
          charityId={isCharityEnabledAndApplied ? charity?._id ?? null : null}
          charityAmount={isCharityEnabledAndApplied ? charityAmountApplied : 0}
          discountAmount={discountAmount}
          rewardsAmountUsed={rewardsAmountUsed}
          cashbackPoints={cashbackPoints}
          payWithPoints={payWithPointsState}
          cashbackPointsPercentage={cashbackPointsPercentage}
          navigation={navigation}
          orderType={orderType}
          route={route}
          couponCashbackAmount={
            couponResData?.isApplied ? couponCashbackAmount : 0
          }
          couponCode={couponResData?.isApplied ? appliedCoupon?.couponCode : ''}
          membershipConf={{
            isBlackMembershipPurchasedInThisTransaction:
              standaloneBlackMembershipBeignPurchased ||
              (blackMembershipAdded && !isCustomerAlreadyBlackMember),
            isMembershipPurchasedStandalone:
              standaloneBlackMembershipBeignPurchased,
            membershipId: blackMembershipConfig?._id,
          }}
          allowPayOnPress={allowPayNow}
          StripContent={
            <MembershipBenefitStrip
              cashback={totalCashback} // points cashback + coupon cashback
              fallbackContent={
                isCustomerAlreadyBlackMemberAndOnWeb
                  ? 'Get 10% cashback if you pay via 32nd Club app!'
                  : ''
              }
            />
          }
          paymentMethod={paymentMethod}
        />
      </View>
      <Modal
        bg="blur"
        visible={showCouponApplied}
        onBackdropPress={() => {
          setShowCouponApplied(false);
        }}
        onRequestClose={() => {
          setShowCouponApplied(false);
        }}
        showBottomCloseButton
        handleClose={() => setShowCouponApplied(false)}
      >
        <CouponApplied
          appliedCoupon={appliedCoupon}
          closeModal={() => setShowCouponApplied(false)}
        />
      </Modal>
      <BottomSheetV2
        ref={bottomSheetModalRef}
        onClose={onBsDismiss}
        {...getBottomSheetStyles(bsMode)}
      >
        {bsMode === BS_MODES.BREAK_UP && (
          <View mt="xl" px="2xl">
            <PaymentBreakUp
              data={generateBreakUpData()}
              orderType={orderType}
            />
          </View>
        )}
        {bsMode === BS_MODES.CSR_INFO && isCharityEnabled && charity?.title && (
          <View p="2xl">
            <View mb="2xl" borderBottomWidth="xs" borderBottomColor="grey.100">
              <Text mb="lg" size="md" weight="medium" color="primary.300">
                {`'${charity?.title}'`}- Donation Details
              </Text>
            </View>
            <View mb="4xl">
              <Text size="sm" color="primary.300">
                {charity?.description}
              </Text>
            </View>
            {charity?.finePrint?.details?.length && (
              <View flexDirection="row">
                <Text size="sm" color="primary.300">
                  you can check more about them here -{' '}
                  <Text
                    onPress={() =>
                      handleCharityLink(charity?.finePrint?.details[0])
                    }
                    size="sm"
                    color="info.500"
                  >
                    {charity?.finePrint?.details[0]}
                  </Text>
                </Text>
              </View>
            )}
          </View>
        )}
        {bsMode === BS_MODES.BLACK_MEMBER_BENEFITS && (
          <View>
            <BlackMembershipBenefits
              bmConfig={blackMembershipConfig}
              addedToCart={blackMembershipAdded}
              onAddToCart={() => {
                if (blackMembershipAdded) {
                  analytics.blackMembershipBenefitsAwesomeClicked(
                    orderType,
                    orderId,
                    title,
                  );
                } else {
                  analytics.blackMembershipAddedToCart(
                    orderType,
                    orderId,
                    title,
                  );
                  bsDismissCbRef.current.onDismiss = () => {
                    addBlackMembershipToCart();
                  };
                }
                bottomSheetModalRef?.current?.hide();
              }}
            />
          </View>
        )}
        {bsMode === BS_MODES.BLACK_MEMBER_INVITATION_ON_PAY && (
          <View>
            <BlackMembershipGenericPopUp
              text={
                getNetPriceWithMembership?.priceDetails?.discountGiven
                  ? `Do you want to save ${toRupees(
                      getNetPriceWithMembership?.priceDetails?.discountGiven,
                    )} and get ${toRupees(
                      getNetPriceWithMembership?.cashbackAmount,
                    )} cashback?`
                  : `Do you want to get ${toRupees(
                      getNetPriceWithMembership?.cashbackAmount,
                    )} cashback?`
              }
              btnOneText="Yes, Add Membership"
              btnTwoText="No, Pay Without Membership"
              onPressOne={() => {
                analytics.blackMembershipAddedToCart(orderType, orderId, title);
                // purchase membership and let the user review the details without continuing "pay now" action
                if (!blackMembershipAdded) {
                  bsDismissCbRef.current.onDismiss = () => {
                    addBlackMembershipToCart();
                  };
                }
                bottomSheetModalRef?.current?.hide();
              }}
              onPressTwo={() => {
                analytics.payWithoutMembershipClicked(
                  orderType,
                  orderId,
                  title,
                );
                // continue to paynow action without purchasing membership
                if (typeof paymentFooterRef.current?.payNow === 'function') {
                  bsDismissCbRef.current.onDismiss = () => {
                    paymentFooterRef.current?.payNow();
                  };
                }
                bottomSheetModalRef?.current?.hide();
              }}
            />
          </View>
        )}
        {bsMode === BS_MODES.DOWNLOAD_APP_PROMT_IF_POINTS_USED_ON_WEB && (
          <View>
            <BlackMembershipGenericPopUp
              text={`You can use your ${toRupees(
                rewardsUsable,
              )} points only if you pay via 32nd Club app!`}
              btnOneText="Pay With 32nd Club App"
              btnTwoText="Pay Without 32nd Club App"
              onPressOne={() => {
                analytics.downloadNowClicked(orderType, orderId, title);
                bsDismissCbRef.current.onDismiss = () => {
                  handleStorePress(Device.osName);
                };
                bottomSheetModalRef.current?.hide();
              }}
              onPressTwo={() => {
                analytics.continueWithoutPointsClicked(
                  orderType,
                  orderId,
                  title,
                );
                bottomSheetModalRef?.current?.hide();
              }}
            />
          </View>
        )}
        {bsMode === BS_MODES.EVENT_CANCELLATION_INFO && (
          <View p="2xl" bg="background.secondary.elevation">
            <View mb="2xl" pb="lg" borderBottomWidth="xs" borderColor="grey.50">
              <Text size="md" color="primary.300" weight={FontWeights.MEDIUM}>
                Cancellation & Refund
              </Text>
            </View>
            <View mb="2xl" flexDirection="row" justifyContent="space-between">
              <Text size="sm" color="primary.500">
                Untill {eventCancellationInfo?.refundLastDate?.formatTwo}
              </Text>
              <Text size="xs" color="success.500">
                (Full refund){' '}
                <Text color="primary.500" size="sm">
                  {toRupees(grossAmount)}
                </Text>
              </Text>
            </View>
            <View flexDirection="row" justifyContent="space-between">
              <Text size="sm" color="primary.500">
                Post {eventCancellationInfo?.refundLastDate?.formatTwo}
              </Text>
              <Text size="xs" color="primary.200">
                Non-refundable
              </Text>
            </View>
          </View>
        )}
        {bsMode === BS_MODES.DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB && (
          <View>
            <BlackMembershipGenericPopUp
              text="As a member you can save upto 50% if you pay via 32nd Club app!"
              btnOneText="Pay With 32nd Club App"
              btnTwoText="Pay Without 32nd Club App"
              onPressOne={() => {
                bsDismissCbRef.current.onDismiss = () => {
                  handleStorePress(Device.osName);
                };
                bottomSheetModalRef.current?.hide();
              }}
              onPressTwo={() => {
                // continue to paynow action on web without availing plus membership benefits
                if (typeof paymentFooterRef.current?.payNow === 'function') {
                  bsDismissCbRef.current.onDismiss = () => {
                    paymentFooterRef.current?.payNow();
                  };
                }
                bottomSheetModalRef?.current?.hide();
              }}
            />
          </View>
        )}
      </BottomSheetV2>
    </View>
  );
};

const PaymentAmountScreenWrapper = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const [title, setTitle] = useState('');
  const { orderId, orderType, amount, slotId, tickets } = route.params ?? {};
  const [isConfigFetched, setIsConfigFetched] = useState(false);
  const [tipEnabled, setTipEnabled] = useState(false);
  const [enabledCharity, setEnabledCharity] = useState(null);
  const [payWithPointsState, setPayWithPointsState] = useState(false);
  const { isLoggedIn } = useAuthStore((state) => state);
  const { id: userId, setBlackMembership } = useUserStore((state) => state);
  const [prevState, setPrevState] = useState(isLoggedIn);
  const componentState = useLoginModalStore((state) => state);
  const appliedCoupon = componentState?.appliedCoupon ?? null;
  const [blackMembershipConfig, setBlackMembershipConfig] = useState(null);

  // ===================
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const refreshConfig = () => {
    setIsConfigFetched(false);
    makeConfigRequest(() => {
      refresh();
    });
  };

  const makeConfigRequest = (cb?) => {
    fetchConfig({
      addonsFilter: {
        type: MembershipTypes.PLUS_MEMBERSHIP,
      },
      userId: isLoggedIn ? userId : '',
      isLoggedIn,
    })
      .then((res) => {
        const charity = getEnabledCharity(res?.findCharities);
        const isTipEnabled = resolveTipEnableStatus(orderType, res?.findConfig);
        const bmAddon = extractBlackMembershipAddonConfig(res?.findAddons);
        setBlackMembershipConfig(bmAddon ?? null);
        setBlackMemberDataInUserStore(res?.findUserById, bmAddon);
        setEnabledCharity(charity);
        setTipEnabled(isTipEnabled);
        setIsConfigFetched(true);
        if (typeof cb === 'function') cb();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  if (prevState !== isLoggedIn) {
    setPrevState(true);
    refresh();
  }

  useEffect(() => {
    refreshConfig();
  }, [isLoggedIn]);
  /**
   * We fetch user membership status from backend
   * and update in store before proceeding further paybill journey
   * to prevent misconfigured API calls in `getNetPrice`
   * */
  const setBlackMemberDataInUserStore = useCallback(
    (user, addon) => {
      if (isLoggedIn && user && addon) {
        const isActivelyABlaclMember = isCustomerBlackMember(user, addon);
        const activeBlackMembershipId = getActiveBlackMembershipId(user, addon);
        if (isActivelyABlaclMember && activeBlackMembershipId) {
          setBlackMembership(activeBlackMembershipId, true);
        }
      } else {
        setBlackMembership('', false);
      }
    },
    [isLoggedIn],
  );

  useEffect(() => {
    makeConfigRequest();
  }, [isLoggedIn, userId]);

  const variables: Record<string, any> = {
    amount: Number(amount),
    orderId,
    useRewardPoints: payWithPointsState,
    orderType,
  };

  if (orderType === paymentOrderTypeEnum.EVENT) {
    variables.workshopDetails = {
      slotId,
      tickets: !isEmpty(JSON.parse(tickets))
        ? Object.values(JSON.parse(tickets))
        : [],
    };
  }

  const backActionHandler = () => {
    navigateBack(navigation, userRole);
    componentState.setAppliedCoupon(null);
  };

  return (
    <Layout level={2}>
      <View zIndex={1}>
        <TopNavigation
          title={title}
          level=""
          textSize="md"
          textColor="primary.400"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={backActionHandler}
            />
          }
        />
      </View>
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense
            fallback={
              <View mt="8xl">
                <Loading />
              </View>
            }
          >
            <View flex={1}>
              <View flex={1}>
                {isConfigFetched && (
                  <PaymentAmountScreen
                    queryOptions={refreshedQueryOptions}
                    variables={variables}
                    navigation={navigation}
                    tipEnabled={tipEnabled}
                    charity={enabledCharity}
                    route={route}
                    retry={refresh}
                    backActionHandler={backActionHandler}
                    setPayWithPointsState={setPayWithPointsState}
                    payWithPointsState={payWithPointsState}
                    setTitle={setTitle}
                    appliedCoupon={appliedCoupon}
                    setAppliedCoupon={(val) =>
                      componentState.setAppliedCoupon(val)
                    }
                    blackMembershipConfig={blackMembershipConfig}
                  />
                )}
              </View>
            </View>
          </Suspense>
        </NewErrorBoundary>
      </View>
    </Layout>
  );
};

const analytics = {
  landed: (title: string, orderType: paymentOrderTypeEnum, orderId: string) => {
    firebaseEventLogger('paymentAmountScreen__land_Landing', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  blackMembershipAddedToCart: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__bmAddTocart_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  blackMembershipRemovedFromCart: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__bmARemoveFromCart_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  memberBenefitsClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__viewBmBenefits_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  payWithPointsToggled: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    applied: boolean,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__payWithPoints_Toggle', {
      orderType,
      id: orderId,
      applied,
      name: title,
    });
  },
  // app/play store
  downloadNowClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__downloadNow_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  continueWithoutPointsClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__withoutPoints_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  viewBreakUpClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title,
  ) => {
    firebaseEventLogger('paymentAmountScreen__viewBreakup_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  freeCancellationBannerClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__freeCancellation_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  donationCheckBoxClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    applied: boolean,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__donate_checkbox', {
      orderType,
      id: orderId,
      applied,
      name: title,
    });
  },
  payWithoutMembershipClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__payWithoutMembership_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  blackMembershipBenefitsAwesomeClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__benefitsAwesome_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  tipAdded: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    amount: number,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__tipAdded_Tap', {
      orderType,
      id: orderId,
      tipAmount: amount,
      name: title,
    });
  },
  payNowClicked: (
    orderType: paymentOrderTypeEnum,
    orderId: string,
    title: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__payNowClicked_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
};

export default PaymentAmountScreenWrapper;
