/**
 * @generated SignedSource<<0d094728cd1af2c445459d9fed7a16fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumRestaurantDiscountGetTCDays = "Weekday" | "Weekend" | "%future added value";
export type EnumRestaurantDiscountGetTCMealTime = "dinner" | "lunch" | "%future added value";
export type restaurantpartnerDiscountfilterInput = {
  AND?: ReadonlyArray<FilterFindManyRestaurantDiscountGetTCInput> | null;
  OR?: ReadonlyArray<FilterFindManyRestaurantDiscountGetTCInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyRestaurantDiscountGetTCOperatorsInput | null;
  days?: EnumRestaurantDiscountGetTCDays | null;
  discounts?: FilterFindManyrestaurantDiscountDiscountsInput | null;
  isActive?: boolean | null;
  mealTime?: EnumRestaurantDiscountGetTCMealTime | null;
  restaurantId?: any | null;
};
export type FilterFindManyrestaurantDiscountDiscountsInput = {
  _id?: any | null;
  allUser?: number | null;
  corporateUser?: number | null;
  newUser?: number | null;
};
export type FilterFindManyRestaurantDiscountGetTCOperatorsInput = {
  _id?: FilterFindManyRestaurantDiscountGetTC_idOperatorsInput | null;
};
export type FilterFindManyRestaurantDiscountGetTC_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FilterFindManyRestaurantDiscountGetTCInput = {
  AND?: ReadonlyArray<FilterFindManyRestaurantDiscountGetTCInput> | null;
  OR?: ReadonlyArray<FilterFindManyRestaurantDiscountGetTCInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyRestaurantDiscountGetTCOperatorsInput | null;
  created_at?: any | null;
  days?: EnumRestaurantDiscountGetTCDays | null;
  discounts?: FilterFindManyrestaurantDiscountDiscountsInput | null;
  isActive?: boolean | null;
  mealTime?: EnumRestaurantDiscountGetTCMealTime | null;
  restaurantId?: any | null;
  updated_at?: any | null;
};
export type discountApiDiscountScreenQuery$variables = {
  filter?: restaurantpartnerDiscountfilterInput | null;
};
export type discountApiDiscountScreenQuery$data = {
  readonly findConfig: {
    readonly corporateConfig: ReadonlyArray<{
      readonly image: string | null;
      readonly name: string;
    } | null> | null;
  } | null;
  readonly getRestaurantpartnerDiscount: ReadonlyArray<{
    readonly _id: any;
    readonly days: EnumRestaurantDiscountGetTCDays;
    readonly discounts: {
      readonly allUser: number;
      readonly corporateUser: number;
      readonly newUser: number;
    };
    readonly isActive: boolean | null;
    readonly mealTime: EnumRestaurantDiscountGetTCMealTime;
    readonly restaurantId: any;
  }>;
};
export type discountApiDiscountScreenQuery = {
  response: discountApiDiscountScreenQuery$data;
  variables: discountApiDiscountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "RestaurantDiscountGetTC",
    "kind": "LinkedField",
    "name": "getRestaurantpartnerDiscount",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "restaurantId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurantDiscountDiscounts",
        "kind": "LinkedField",
        "name": "discounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "corporateUser",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "days",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mealTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "config",
    "kind": "LinkedField",
    "name": "findConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "configCorporateConfig",
        "kind": "LinkedField",
        "name": "corporateConfig",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "discountApiDiscountScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "discountApiDiscountScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9149d76c4af4d923d255d7af2ba6414e",
    "id": null,
    "metadata": {},
    "name": "discountApiDiscountScreenQuery",
    "operationKind": "query",
    "text": "query discountApiDiscountScreenQuery(\n  $filter: restaurantpartnerDiscountfilterInput\n) {\n  getRestaurantpartnerDiscount(filter: $filter) {\n    _id\n    restaurantId\n    discounts {\n      allUser\n      newUser\n      corporateUser\n    }\n    days\n    mealTime\n    isActive\n  }\n  findConfig {\n    corporateConfig {\n      name\n      image\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5c090ee7c4edcb5343c23013e48c057";

export default node;
