/* eslint-disable import/prefer-default-export */
import { create } from 'zustand';
import downloadAppSlice, {
  downloadAppSliceReturnType,
} from './downloadAppSlice';

export const useDownloadAppStore = create<downloadAppSliceReturnType>(
  (...a) => ({
    ...downloadAppSlice(...a),
  }),
);
