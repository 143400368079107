/**
 * @generated SignedSource<<13500314eb3bc892075412fa7b584db3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumUserContactEmailType = "PERSONAL" | "WORK" | "%future added value";
export type searchUsersFilterInput = {
  _id?: any | null;
  contact?: FilterFindManyUserContactInput | null;
  registration?: searchUsersFilterInputRegistration | null;
};
export type FilterFindManyUserContactInput = {
  _id?: any | null;
  email?: ReadonlyArray<FilterFindManyUserContactEmailInput | null> | null;
  phone?: FilterFindManyUserContactPhoneInput | null;
};
export type FilterFindManyUserContactPhoneInput = {
  _id?: any | null;
  number?: string | null;
  prefix?: string | null;
};
export type FilterFindManyUserContactEmailInput = {
  _id?: any | null;
  addr?: string | null;
  type?: EnumUserContactEmailType | null;
};
export type searchUsersFilterInputRegistration = {
  plate?: string | null;
};
export type selectCustomerScreenQuery$variables = {
  filter: searchUsersFilterInput;
};
export type selectCustomerScreenQuery$data = {
  readonly searchUsers: ReadonlyArray<{
    readonly _id: any;
    readonly contact: {
      readonly phone: {
        readonly number: string;
        readonly prefix: string;
        readonly verified: boolean | null;
      };
    };
    readonly name: {
      readonly first: string;
      readonly last: string | null;
    };
    readonly parkingVisitCount: number | null;
    readonly profileImage: string | null;
  }>;
};
export type selectCustomerScreenQuery = {
  response: selectCustomerScreenQuery$data;
  variables: selectCustomerScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "searchUsers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserName",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContactPhone",
            "kind": "LinkedField",
            "name": "phone",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verified",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingVisitCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectCustomerScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectCustomerScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0427bd04266b6346a2476b935f194cee",
    "id": null,
    "metadata": {},
    "name": "selectCustomerScreenQuery",
    "operationKind": "query",
    "text": "query selectCustomerScreenQuery(\n  $filter: searchUsersFilterInput!\n) {\n  searchUsers(filter: $filter) {\n    _id\n    name {\n      first\n      last\n    }\n    contact {\n      phone {\n        prefix\n        number\n        verified\n      }\n    }\n    profileImage\n    parkingVisitCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ea2ebc9694886290f3bfd039b292ef5";

export default node;
