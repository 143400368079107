import { useLazyLoadQuery } from 'react-relay';
import { controllerDailyBonusQuery } from '../API/controllerDailyBonusQuery';
import controllerDailyBonusTasksQuery from '../API/controllerDailyBonusTasksQuery';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { controllerBonusAmount } from '../../../utilities/Constant';
import { controllerDailyBonusTasksQuery$data } from '../API/__generated__/controllerDailyBonusTasksQuery.graphql';
import { controllerDailyBonusQuery$data } from '../API/__generated__/controllerDailyBonusQuery.graphql';

type DailyControllerRewardedTaskType =
  controllerDailyBonusTasksQuery$data['findDailyControllerRewards'][number];
type DailyControllerTaskBonusType =
  controllerDailyBonusQuery$data['dailyControllerTaskBonus'];

enum RewardType {
  CONTROLLER_PARKING_ASSIGNMENT = 'CONTROLLER_PARKING_ASSIGNMENT',
  CONTROLLER_PARKING_ONBOARDING = 'CONTROLLER_PARKING_ONBOARDING',
  CONTROLLER_PARKING_COMPLETION = 'CONTROLLER_PARKING_COMPLETION',
  CONTROLLER_REPARKING_COMPLETION = 'CONTROLLER_REPARKING_COMPLETION',
  CONTROLLER_RECALL_ASSIGNMENT = 'CONTROLLER_RECALL_ASSIGNMENT',
  CONTROLLER_RECALL_ARRIVAL = 'CONTROLLER_RECALL_ARRIVAL',
}

interface DailyRewardsFull extends DailyControllerRewardedTaskType {
  label: string;
}

interface ControllerLogicReturnType {
  dailyRewards: DailyRewardsFull[];
  onTimeBonus: number; // Rupees
  delayPenalty: number; // Rupees
  noDailyRewards: boolean;
  onTimeTaskCount: number;
  delayedTaskCount: number;
  bonusAmountTotal: number;
}

/* eslint-disable-next-line import/prefer-default-export */
export const useControllerBonusTaskBreakdownLogic = (
  date: string,
  queryOptions: NewErrorBoundaryParentState,
): ControllerLogicReturnType => {
  const {
    dailyControllerTaskBonus,
  }: {
    dailyControllerTaskBonus: DailyControllerTaskBonusType;
  } = useLazyLoadQuery(
    controllerDailyBonusQuery,
    {
      filter: { date },
    },
    {
      ...queryOptions,
      neworkCacheConfig: { force: true },
    },
  );

  const {
    findDailyControllerRewards,
  }: { findDailyControllerRewards: DailyControllerRewardedTaskType[] } =
    useLazyLoadQuery(
      controllerDailyBonusTasksQuery,
      {
        filter: { date },
      },
      {
        ...queryOptions,
        neworkCacheConfig: { force: true },
      },
    );

  const { bonus } = dailyControllerTaskBonus ?? {};

  const dailyRewards: DailyRewardsFull[] = findDailyControllerRewards.map(
    (reward) => {
      const taskTypeLabel = (() => {
        switch (reward?.category) {
          case RewardType.CONTROLLER_PARKING_ONBOARDING:
            return 'Onboarding Task';
          case RewardType.CONTROLLER_PARKING_ASSIGNMENT:
            return reward?.valetCode
              ? `Parking Assignment Task |  ${reward?.valetCode}`
              : 'Parking Assignment Task';
          case RewardType.CONTROLLER_PARKING_COMPLETION:
            return `Parking Completion Task | ${reward?.valetCode}`;
          case RewardType.CONTROLLER_REPARKING_COMPLETION:
            return `Reparking Completion Task | ${reward?.valetCode}`;
          case RewardType.CONTROLLER_RECALL_ASSIGNMENT:
            return `Recall Assignment Task | ${reward?.valetCode}`;
          case RewardType.CONTROLLER_RECALL_ARRIVAL:
            return `Recall Arrival Task | ${reward?.valetCode}`;
          default:
            return '';
        }
      })();

      return {
        ...reward,
        label: taskTypeLabel,
      };
    },
  );

  return {
    dailyRewards,
    onTimeBonus: controllerBonusAmount.onTime, // Rupees
    delayPenalty: controllerBonusAmount.delayed, // Rupees
    noDailyRewards: findDailyControllerRewards?.length === 0,
    onTimeTaskCount: bonus?.onTimeTaskCount ?? 0,
    delayedTaskCount: bonus?.delayedTaskCount ?? 0,
    bonusAmountTotal: bonus?.bonusAmount ?? 0,
  };
};
