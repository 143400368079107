import React from 'react';
import { Platform, ScrollView, ScrollViewProps } from 'react-native';
import Animated from 'react-native-reanimated';

const CustomScrollView = (props: ScrollViewProps) => {
  // Using this hack for support on web
  const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

  if (Platform.OS === 'web') {
    return <AnimatedScrollView {...props}>{props.children}</AnimatedScrollView>;
  }
  return <ScrollView {...props}>{props.children}</ScrollView>;
};

export default CustomScrollView;
