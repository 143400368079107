import React, { useState, useEffect } from 'react';
import { CheckBoxGroup, RadioGroup } from '../../../components/new';

const VariationsGroup = (props) => {
  const { options, setSelectedOptions, selectedMenuItem } = props;

  const initialVars = options.item.map((ele) => {
    let checked = false;
    if (selectedMenuItem.options) {
      const exsist = selectedMenuItem.options.filter(
        (item) => item.id === ele._id,
      );
      checked = Boolean(exsist.length);
    } else {
      checked = ele.default;
    }
    return {
      id: ele._id,
      checked,
      name: ele.title,
      label: ele.price?.toString() || '0',
      labelWarning: ele.labelWarning ? ele.labelWarning : '',
    };
  });
  const [radio, setRadio] = useState(initialVars);
  const [checkboxes, setCheckboxes] = useState(initialVars);

  useEffect(() => {
    setSelectedOptions((prev) => {
      return {
        ...prev,
        [options.category]: {
          selected:
            options.type === 'variation'
              ? radio.filter((item) => item.checked)
              : checkboxes.filter((item) => item.checked),
          maxSelect: options.maxSelect,
          minSelect: options.minSelect,
          type: options.type,
          category: options.category,
        },
      };
    });
  }, [radio, checkboxes]);
  if (options.type === 'variation') {
    return (
      <RadioGroup
        radio={radio}
        setRadio={setRadio}
        title={options.category}
        isRequired={0}
        size="lg"
        icon="right"
        collapsedCount={10}
        type={options.type}
      />
    );
  }
  return (
    <CheckBoxGroup
      checkboxes={checkboxes}
      setCheckboxes={setCheckboxes}
      title={options.category}
      size="lg"
      icon="right"
      maxSelected={options.maxSelect}
      minSelected={options.minSelect}
      collapsedCount={10}
    />
  );

  return null;
};

export default VariationsGroup;
