/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';
import { commitMutationPromise } from '../../../utilities/helper';

export const reparkQuery = (data) => {
  const mutation = graphql`
    mutation reparkQuery($taskId: ID!, $valetId: ID!, $taskEndLocationId: ID) {
      reparkTask(
        taskId: $taskId
        valetId: $valetId
        taskEndLocationId: $taskEndLocationId
      ) {
        _id
        vehicle {
          brand {
            name
            brandLogo
          }
          registration {
            plate
          }
          color
        }
        taskType
        taskStartLocation {
          locationId
          name
          parkingSpaces {
            floor {
              floorName
              floorId
              bay {
                bayId
                bayName
              }
            }
          }
        }
        taskEndLocation {
          locationId
          name
          parkingSpaces {
            floor {
              floorName
              floorId
              bay {
                bayId
                bayName
              }
            }
          }
        }
        campus {
          _id
          name
        }
        history {
          _id
          action
          actor
          event_at
        }
        assignedTo
        valet {
          name {
            first
            last
          }
          identityCode
          contact {
            phone {
              prefix
              number
            }
          }
        }
        user {
          name {
            first
            last
          }
          contact {
            phone {
              prefix
              number
            }
          }
        }
        ETA {
          _id
          startAt
          delayAt
          originalPredictionTime
          pendingRecall
          available
          busy
        }
        vehicleId
        ownerId
        parkingId
        parkingTag
        createdBy {
          status
        }
      }
    }
  `;
  const variables = {
    valetId: data.valetId,
    taskId: data.taskId,
    taskEndLocationId: data.taskEndLocationId,
  };
  // commitMutation(environment, {
  //   mutation,
  //   variables,
  //   onCompleted: (response, error) => {
  //     callback(response, error, data);
  //   },
  //   onError: (err) => {
  //     errorCallback(err);
  //   },
  // });
  return commitMutationPromise(mutation, variables);
};
