import React, { PropsWithChildren } from 'react';
import View from '../../basic/View/View';

type BonusListItemCardProps = PropsWithChildren<void>;

const BonusListItemCard = ({ children }: BonusListItemCardProps) => {
  return (
    <View
      bg="primary.10"
      shadow="sm"
      borderWidth="xs"
      borderRadius="md"
      borderColor="primary.50"
    >
      {children}
    </View>
  );
};

export default BonusListItemCard;
