/**
 * @generated SignedSource<<06991218ccb61cf077a33d9ad351f61c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type EnumParkingStatus = "FAULTY_PARK" | "FAULTY_RECALL" | "INCOMING" | "MISSED_EXIT" | "OUT" | "OUTGOING" | "PARKED" | "%future added value";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type parkingApiFindParkingFragment$data = {
  readonly findParkingV2: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly journey: ReadonlyArray<{
          readonly taskID: any;
        } | null> | null;
        readonly parked_at: any | null;
        readonly parkingLocation: {
          readonly locationId: any | null;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly bay: {
                readonly bayId: any | null;
                readonly bayName: string | null;
              } | null;
              readonly floorId: any | null;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly parkingTag: string | null;
        readonly status: EnumParkingStatus;
        readonly tasks: ReadonlyArray<{
          readonly _id: any;
          readonly assignedTo: any | null;
          readonly campus: {
            readonly _id: any;
            readonly name: string;
          } | null;
          readonly history: ReadonlyArray<{
            readonly _id: any | null;
            readonly action: EnumtaskHistoryAction;
            readonly actor: any | null;
            readonly event_at: any | null;
          } | null>;
          readonly isVehicleWhitelisted: boolean | null;
          readonly ownerId: any | null;
          readonly parkingId: any | null;
          readonly parkingTag: string | null;
          readonly taskEndLocation: {
            readonly locationId: any;
            readonly name: string | null;
            readonly parkingSpaces: {
              readonly floor: {
                readonly bay: {
                  readonly bayId: any;
                  readonly bayName: string | null;
                } | null;
                readonly floorId: any;
                readonly floorName: string | null;
              } | null;
            } | null;
          } | null;
          readonly taskStartLocation: {
            readonly locationId: any;
            readonly name: string | null;
            readonly parkingSpaces: {
              readonly floor: {
                readonly bay: {
                  readonly bayId: any;
                  readonly bayName: string | null;
                } | null;
                readonly floorId: any;
                readonly floorName: string | null;
              } | null;
            } | null;
          } | null;
          readonly taskType: EnumtaskTaskType;
          readonly user: {
            readonly contact: {
              readonly phone: {
                readonly number: string;
                readonly prefix: string;
              };
            };
            readonly name: {
              readonly first: string;
              readonly last: string | null;
            };
          } | null;
          readonly valet: {
            readonly contact: {
              readonly phone: {
                readonly number: string;
                readonly prefix: string;
              };
            };
            readonly name: {
              readonly first: string;
              readonly last: string | null;
            };
          } | null;
          readonly vehicle: {
            readonly brand: {
              readonly brandLogo: string | null;
              readonly name: string | null;
            } | null;
            readonly color: string | null;
            readonly registration: {
              readonly plate: string;
            } | null;
          } | null;
          readonly vehicleId: any | null;
        } | null> | null;
      };
    }>;
  } | null;
  readonly " $fragmentType": "parkingApiFindParkingFragment";
};
export type parkingApiFindParkingFragment$key = {
  readonly " $data"?: parkingApiFindParkingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"parkingApiFindParkingFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "parkingApiFindParkingFragment"
};

(node as any).hash = "1beac5dc7cf6354286bcba400e148581";

export default node;
