import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const createDiscount = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation discountApiMutation(
      $record: restaurantpartnerDiscountrecordInput!
    ) {
      addRestaurantpartnerDiscount(record: $record) {
        record {
          restaurantId
          discounts {
            allUser
            newUser
            corporateUser
          }
          days
          mealTime
          isActive
          _id
        }
      }
    }
  `;
  const variables = {
    id: data._id,
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};

export const discountScreenQuery = graphql`
  query discountApiDiscountScreenQuery(
    $filter: restaurantpartnerDiscountfilterInput
  ) {
    getRestaurantpartnerDiscount(filter: $filter) {
      _id
      restaurantId
      discounts {
        allUser
        newUser
        corporateUser
      }
      days
      mealTime
      isActive
    }
    findConfig {
      corporateConfig {
        name
        image
      }
    }
  }
`;
