import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

export enum NotificationModalUserActions {
  CLOSED_MODAL_DENIED = 'closed_modal_denied',
  ALLOWED = 'allowed',
  ASK_ME_LATER = 'ask_me_later',
}

export interface NotificationModalStoreInterface {
  isOpen: boolean;
  willOpen: boolean;
  lastUserAction: NotificationModalUserActions | null;
  expoToken: string;
  title: string;
  subTitle: string;
  denyButtonText: string;
  allowButtonText: string;
  setIsOpen: (open: boolean) => void;
  setWillOpen: (open: boolean) => void;
  setLastUserAction: (action: NotificationModalUserActions) => void;
  setExpoToken: (token: string) => void;
  setUiText: (
    title: string,
    subTitle: string,
    buttonDeny: string,
    buttonAllow: string,
  ) => void;
  resetUiText: () => void;
  lastOpenTime: Date | null;
}

const useNotificationModalStore = create<NotificationModalStoreInterface>()(
  devtools(
    persist(
      (set, get) => ({
        isOpen: false,
        willOpen: false,
        lastUserAction: null,
        expoToken: '',
        title: 'Stay Updated',
        subTitle:
          'Stay updated with our latest offers and updates! Enable notifications to receive timely alerts and important information.',
        denyButtonText: 'Ask me later',
        allowButtonText: 'Allow',
        lastOpenTime: null,
        setIsOpen: (open: boolean) =>
          set((state) => {
            if (!open) {
              return {
                isOpen: false,
              };
            }
            const currentTime = new Date();
            const lastOpenTime = state.lastOpenTime
              ? new Date(state.lastOpenTime)
              : null;
            const canOpen =
              !lastOpenTime ||
              currentTime.getTime() - lastOpenTime.getTime() >
                24 * 60 * 60 * 1000;
            if (canOpen) {
              return {
                isOpen: canOpen,
                lastOpenTime: new Date(),
              };
            }
            return {
              isOpen: false,
            };
          }),
        setWillOpen: (open: boolean) =>
          set(() => {
            return {
              willOpen: open,
            };
          }),
        setLastUserAction: (action: NotificationModalUserActions) =>
          set(() => {
            return {
              lastUserAction: action,
            };
          }),
        setExpoToken: (token: string) =>
          set(() => {
            return {
              expoToken: token,
            };
          }),
        setUiText: (
          title: string,
          subTitle: string,
          buttonDeny: string,
          buttonAllow: string,
        ) =>
          set(() => {
            return {
              title,
              subTitle,
              denyButtonText: buttonDeny,
              allowButtonText: buttonAllow,
            };
          }),
        resetUiText: () =>
          set(() => {
            return {
              title: 'Stay Updated',
              subTitle:
                'Stay updated with our latest offers and updates! Enable notifications to receive timely alerts and important information.',
              denyButtonText: 'Ask me later',
              allowButtonText: 'Allow',
            };
          }),
      }),
      {
        name: 'NotificationModalStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'NotificationModalStore',
    },
  ),
);

export default useNotificationModalStore;
