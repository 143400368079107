import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface AuditorTransactionStoreInterface {
  transactions?: [];
}

interface useAuditorTransactionStorePropTypes {
  (state?: object);
}

const useAuditorTransactionStore = <useAuditorTransactionStorePropTypes>(
  create()(
    devtools(
      persist(
        (set) => ({
          transactions: [],
          openedCard: '',
          setOpenedCard: ({ id }) => {
            set(() => {
              return { openedCard: id };
            });
          },
          newTransactions: [],
          setTransactions: ({ data }) => {
            set(() => {
              return { transactions: data };
            });
          },
          setNewTransactions: ({ data }) => {
            set(() => {
              return { newTransactions: data };
            });
          },
        }),
        {
          name: 'AuditorTransactions',
          storage: createJSONStorage(() => AsyncStorage),
        },
      ),
      {
        name: 'AuditorTransactions',
      },
    ),
  )
);

export default useAuditorTransactionStore;
