import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const recallScreenQuery = graphql`
  query recallScreenQuery(
    $taskFilter: findTaskFilterInput!
    $sort: SortFindManytaskInput
  ) {
    ...recallScreenQueryFindTaskFragment
      @arguments(filter: $taskFilter, sort: $sort)
  }
`;

export const recallScreenQueryFindTaskFragment = graphql`
  fragment recallScreenQueryFindTaskFragment on Query
  @refetchable(queryName: "recallScreenQueryFindTaskQuery")
  @argumentDefinitions(
    filter: { type: "findTaskFilterInput!" }
    sort: { type: "SortFindManytaskInput" }
  ) {
    findTasks(filter: $filter, sort: $sort) {
      _id
      vehicle {
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
      }
      taskType
      taskStartLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      campus {
        _id
        name
      }
      history {
        _id
        action
        actor
        event_at
      }
      assignedTo
      valet {
        _id
        name {
          first
          last
        }
        identityCode
        contact {
          phone {
            prefix
            number
          }
        }
      }
      user {
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      ETA {
        _id
        startAt
        delayAt
        originalPredictionTime
        pendingRecall
        available
        busy
      }
      vehicleId
      ownerId
      parkingId
      parkingTag
    }
  }
`;
