/* eslint-disable react/require-default-props */
import React from 'react';
import View from '../../basic/View/View';
import Text from '../Typography/Text/Text';
import { FontWeights } from '../../../../themes/new/helper';

interface TimerProps {
  title1?: string;
  title2?: string;
  title1Color?: string;
  title2Color?: string;
}

const RectangularTimer = ({
  title1 = '',
  title2 = '',
  title1Color = 'primary.100',
  title2Color = 'primary.400',
}: TimerProps) => {
  return (
    <View
      p="sm"
      borderWidth="xs"
      borderColor="primary.50"
      borderRadius="sm"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Text color={title1Color} size="sm">
        {title1}
      </Text>
      <Text color={title1Color} size="sm" weight={FontWeights.MEDIUM}>
        {' '}
        :{' '}
      </Text>
      <Text color={title2Color} size="sm" weight={FontWeights.MEDIUM}>
        {title2}
      </Text>
    </View>
  );
};

export default RectangularTimer;
