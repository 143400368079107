import React, { useEffect, useState } from 'react';
import { CountryPicker } from 'react-native-country-picker-modal/lib/CountryPicker';
import { Country, getCallingCode } from 'react-native-country-picker-modal';
import { CountryCode } from 'react-native-country-picker-modal/lib/types';
import { Platform, TextInput } from 'react-native';
import { useTheme } from 'styled-components/native';
import { View, Text, Icon } from '../index';
import Pressable from '../basic/Pressable/Pressable';
import { getTheme } from '../../../themes/new/theme';
import IpGeoLocationService from '../../../services/IpGeoLocationService';

const countryJsonData = require('../../../../node_modules/react-native-country-picker-modal/lib/assets/data/countries-emoji.json');

const getCountryCode = (callingCode: string): string => {
  const CountryCodeArray = Object.keys(countryJsonData);
  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < CountryCodeArray.length; j++) {
    const countryCode = CountryCodeArray[j];
    const countryDetails = countryJsonData[countryCode];
    const countryCallingCodes: string[] = countryDetails.callingCode;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < countryCallingCodes.length; index++) {
      if (callingCode === countryCallingCodes[index]) {
        return countryCode;
      }
    }
  }
  return '';
};

interface InternationalPhoneInputProps {
  label: string;
  autofocus?: boolean;
  onChangeNumber: (number: string) => void;
  onChangeCountry: (callingCode: string) => void;
  defaultValue?: string;
  defaultCallingCode?: string;
  viewOnly?: boolean;
  inputViewOnly?: boolean;
}

const IpLocationService = new IpGeoLocationService();

const InternationalPhoneInput = (props: InternationalPhoneInputProps) => {
  const theme = useTheme();
  const [countryCode, setCountryCode] = useState<CountryCode>('IN');
  const [countryCallingCode, setCountryCallingCode] = useState<string>(
    props.defaultCallingCode || '91',
  );

  const [isCountryPickerOpen, setIsCountryPickerOpen] =
    useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>(
    props.defaultValue
      ? `${props.defaultValue.slice(0, 5)} ${props.defaultValue.slice(5)}`
      : '',
  );

  useEffect(() => {
    if (props.defaultCallingCode) {
      const defaultCountryCode = getCountryCode(
        props.defaultCallingCode,
      ) as CountryCode;
      setCountryCode(defaultCountryCode);
    } else {
      IpLocationService.getLocationDetails()
        .then(async (response) => {
          setCountryCode(response.country_code2 as CountryCode);
          const res = await getCallingCode(
            response.country_code2 as CountryCode,
          );
          setCountryCallingCode(res);
          props.onChangeCountry(res);
        })
        .catch(() => {
          setCountryCode('IN');
          setCountryCallingCode('91');
          props.onChangeCountry('91');
        });
    }
  }, []);

  const handleOnCountryCodeSelect = (country: Country) => {
    setCountryCallingCode(country.callingCode[0]);
    setCountryCode(country.cca2);
    props.onChangeCountry(country.callingCode[0]);
  };

  const handleCountryPickerPress = () => {
    setIsCountryPickerOpen(true);
  };

  const handleCountryPickerModalClose = () => {
    setIsCountryPickerOpen(false);
  };

  const handlePhoneTextChange = (value) => {
    let formattedValue = value;

    if (value.length > 5 && !value.includes(' ')) {
      formattedValue = `${value.slice(0, 5)} ${value.slice(5)}`;
    }

    setPhoneNumber(formattedValue);
    props.onChangeNumber(value.split(' ').join(''));
  };

  return (
    <View mb="4xl" pointerEvents={props.viewOnly ? 'none' : 'auto'}>
      <Text color="primary.300" weight="regular" size="md" mb="lg">
        {props.label}
      </Text>
      <View>
        <View
          px="xl"
          py="sm"
          flexDirection="row"
          alignItems="center"
          borderColor="primary.50"
          borderWidth={1}
          borderRadius={8}
        >
          <Pressable
            onPress={handleCountryPickerPress}
            style={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <View style={{ opacity: props.viewOnly ? 0.5 : 1 }}>
              <CountryPicker
                countryCode={countryCode}
                visible={isCountryPickerOpen}
                withFilter
                withFlag
                withCallingCode
                onSelect={handleOnCountryCodeSelect}
                onClose={handleCountryPickerModalClose}
              />
            </View>
            <Text
              color={props.viewOnly ? 'primary.100' : 'primary.200'}
              weight="regular"
              size="md"
              mr="sm"
            >
              +{countryCallingCode}
            </Text>
            <Icon
              name="expand-outline-center-300"
              color="primary.300"
              size="md"
            />
          </Pressable>
          {/* @ts-ignore */}
          <View
            ml="lg"
            mr="lg"
            backgroundColor="primary.50"
            style={{ height: '80%', width: 1 }}
          />
          <Pressable
            style={{
              height: '100%',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <TextInput
              autoFocus={props.autofocus ?? true}
              placeholder="99999 99999"
              placeholderTextColor={
                getTheme(theme?.currentThemeMode).colors.primary[50]
              }
              selectionColor={
                props.viewOnly || props.inputViewOnly
                  ? getTheme(theme?.currentThemeMode).colors.primary['300']
                  : getTheme(theme?.currentThemeMode).colors.primary['500']
              }
              value={phoneNumber}
              editable={!props.viewOnly && !props.inputViewOnly}
              keyboardType="number-pad"
              onChangeText={handlePhoneTextChange}
              style={[
                {
                  height: '100%',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  fontSize: getTheme().fontSizes.md,
                  color:
                    props.viewOnly || props.inputViewOnly
                      ? getTheme(theme?.currentThemeMode).colors.primary['100']
                      : getTheme(theme?.currentThemeMode).colors.primary['500'],
                },
                Platform.OS === 'web' ? { outlineStyle: 'none' } : {},
              ]}
            />
          </Pressable>
          {props.viewOnly && (
            <Icon name="check-filled-400" color="primary.400" size="2xl" />
          )}
        </View>
      </View>
    </View>
  );
};

export default InternationalPhoneInput;
