import React from 'react';
import { Icon, View, Text } from '../../../components/new';

const NoResultsView = ({ text }) => {
  return (
    <View alignItems="center">
      <Icon name="search-outline-400" size="3xl+3xl" color="primary.100" />
      <Text mt="2xl" size="xl" weight="medium" color="primary.100">
        {text}
      </Text>
    </View>
  );
};

export default NoResultsView;
