/* eslint-disable import/prefer-default-export, no-nested-ternary */
import moment from 'moment';
import { getPreviousDates } from '../../../utilities/dateUtilities';
import { taskFilter, taskStatus, taskType } from './constants';
import {
  DateFormatsEnum,
  formatPlateNo,
  getFormatedDate,
  taskHistoryActionTypes,
} from '../../../utilities/helper';
import { StateType } from './types';

const weekDayList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const highlightColor = 'error.500';
export const dateFormat = 'YYYY/MM/DD';

export const secToTime = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');
  let h = duration.hours().toString();
  let m = duration.minutes().toString();
  let s = duration.seconds().toString();

  // adding leading zero for standard time formating
  h = h.length === 1 ? `0${h}` : h;
  m = m.length === 1 ? `0${m}` : m;
  s = s.length === 1 ? `0${s}` : s;

  let timeStr: string;

  if (parseInt(h, 10) > 0) {
    timeStr = `${h}:${m}:${s}`;
  } else {
    // removing hours slot if zero
    timeStr = `${m}:${s}`;
  }
  return timeStr;
};
/**
 *
 * @description - `DayPicker` requires timestamps between 7 days in seconds to fetch data from api
 */
export const getPickerTimeStamps = (): { sTS: number; eTS: number } => {
  const today = new Date();
  today.setUTCHours(0, 0, 0);
  const range = getPreviousDates(today, 7);
  const sTS = Math.trunc(range[0].getTime() / 1000); // converting to seconds;
  const eTS = Math.trunc(range[range.length - 1].getTime() / 1000); // converting to seconds;
  return { sTS, eTS };
};

export const isMetricWithAnyDelayedTask = (dayMetric: any) => {
  const {
    assignmentScore: a,
    deliveryScore: d,
    onboardingScore: o,
    parkingScore: p,
  } = dayMetric;

  const scores = [a, d, o, p];

  return scores.some((score: number) => score > 0 && score <= 75);
};

export const resolveColor = (status: string) => {
  switch (status) {
    case taskStatus.POOR:
      return 'error.500';
    case taskStatus.AVERAGE:
      return 'warning.500';
    case taskStatus.GOOD:
      return 'success.500';
    default:
      return 'primary.300';
  }
};

export const isTaskPoor = (score: number) => score > 0 && score <= 75;
export const isTaskAverage = (score: number) => score > 75 && score < 90;
export const isTaskGood = (score: number) => score >= 90 && score <= 100;

export const getTaskStatus = (score: number) => {
  if (isTaskPoor(score)) return taskStatus.POOR;
  if (isTaskAverage(score)) return taskStatus.AVERAGE;
  if (isTaskGood(score)) return taskStatus.GOOD;
  return '';
};

export const buildPickerData = (
  fetchedData: any,
): StateType['dayPickerData'] => {
  return fetchedData.map((metrics: any): StateType['dayPickerData'][number] => {
    let data: StateType['dayPickerData'][number] = null;
    const dateObj = moment(metrics.date, 'YYYY/MM/DD').toDate();
    dateObj.setHours(0, 0, 0);

    const hasDelayedTask = isMetricWithAnyDelayedTask(metrics);
    const day = weekDayList[dateObj.getDay()];
    const date = dateObj.getDate();

    data = {
      day,
      date,
      dateString: metrics.date,
    };

    if (hasDelayedTask) data.highlightColor = highlightColor;

    return data;
  });
};

export const buildMetricsData = (
  fetchedMetrics: any,
): StateType['metricsData'] => {
  const metrics: StateType['metricsData'] = {
    date: fetchedMetrics.date,
    onboarding: {
      label: 'Onboarding',
      score: `${Number(fetchedMetrics.onboardingScore).toFixed(0)}%`,
      status: getTaskStatus(
        Number(Number(fetchedMetrics.onboardingScore).toFixed(0)),
      ),
      time: secToTime(fetchedMetrics.onboardingTime),
    },
    assignment: {
      label: 'Assignment',
      score: `${Number(fetchedMetrics.assignmentScore).toFixed(0)}%`,
      status: getTaskStatus(
        Number(Number(fetchedMetrics.assignmentScore).toFixed(0)),
      ),
      time: secToTime(fetchedMetrics.assignmentTime),
    },
    delivery: {
      label: 'Delivery',
      score: `${Number(fetchedMetrics.deliveryScore).toFixed(0)}%`,
      status: getTaskStatus(
        Number(Number(fetchedMetrics.deliveryScore).toFixed(0)),
      ),
      time: secToTime(fetchedMetrics.deliveryTime),
    },
    parking: {
      label: 'Parking',
      score: `${Number(fetchedMetrics.parkingScore).toFixed(0)}%`,
      status: getTaskStatus(
        Number(Number(fetchedMetrics.parkingScore).toFixed(0)),
      ),
      time: secToTime(fetchedMetrics.parkingTime),
    },
  };

  return metrics;
};

export const buildTasksData = (fetchedTask: any) => {
  const isParking = fetchedTask.taskType === taskType.PARK;
  const isReparking = fetchedTask.taskType === taskType.REPARK;
  const completedActionTime = fetchedTask.history.filter(
    (h) => h.action === taskHistoryActionTypes.completed,
  )[0].event_at;
  const taskCompletionTime = `${getFormatedDate(
    completedActionTime,
    DateFormatsEnum.DateMonthYear,
  )} | ${getFormatedDate(completedActionTime, DateFormatsEnum.TIME)}`;
  const taskData = {} as StateType['tasksData'][number];
  taskData.taskType = fetchedTask.taskType;
  taskData.tagId = fetchedTask?.parkingTag;
  taskData.date = taskCompletionTime;
  taskData.valetId = fetchedTask?.valet?.identityCode;
  taskData.location =
    isParking || isReparking
      ? fetchedTask?.taskStartLocation?.name
      : fetchedTask?.taskEndLocation?.name;

  taskData.vehicleNumber = formatPlateNo(
    fetchedTask?.vehicle.registration.plate,
  );

  taskData.timing = [
    {
      label: isParking || isReparking ? 'Onboard' : 'Assign',
      delayed:
        isParking || isReparking
          ? !!fetchedTask?.metrics?.isOnbardingDelayed
          : !!fetchedTask?.metrics?.isAssignmentDelayed,
      time:
        isParking || isReparking
          ? secToTime(fetchedTask?.metrics?.taskOnboardeIn) ?? 'NA'
          : secToTime(
              fetchedTask?.metrics?.acceptedIn ??
                fetchedTask?.metrics?.assignedIn,
            ) ?? 'NA',
    },
    {
      label: isParking ? 'Park' : isReparking ? 'Repark' : 'Delivery',
      delayed:
        isParking || isReparking
          ? !!fetchedTask?.metrics?.isParkingDelayed
          : !fetchedTask?.metrics?.isWithinETA,
      time:
        isParking || isReparking
          ? secToTime(fetchedTask?.metrics?.valetTookInCompletion) ?? 'NA'
          : secToTime(fetchedTask?.metrics?.taskCompletionIn) ?? 'NA',
    },
  ];

  return taskData;
};

export const getFilteredTasks = (
  filter: string,
  allTasks: StateType['tasksData'],
) => {
  return allTasks;
  // switch (filter) {
  //   case taskFilter.ALL:
  //     return allTasks;
  //   case taskFilter.DELAYED: {
  //     return allTasks.filter((t) => t.timing[0].delayed || t.timing[1].delayed);
  //   }
  //   case taskFilter.ON_TIME: {
  //     return allTasks.filter(
  //       (t) => !t.timing[0].delayed && !t.timing[1].delayed,
  //     );
  //   }
  //   default:
  //     return allTasks;
  // }
};
