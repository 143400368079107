/**
 * @generated SignedSource<<14f2ec52d36dcc9d45a081c4a1858562>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Enumrestaurants2Amenities = "Bar_Area" | "Buffet" | "Catering" | "Charging_Point" | "Coat_Check" | "Dance_Floor" | "Delivery" | "Event_Space" | "Family_Play_Area" | "Fine_Dining" | "Karaoke" | "Kids_Menu" | "Late_Night_Dining" | "Live_Cooking_Stations" | "Live_Sports_Screening" | "Meeting_Rooms" | "On_Site_Parking" | "Outdoor_Barbecue" | "Outdoor_Coolers" | "Outdoor_Heaters" | "Outdoor_Seating" | "Patio" | "Photobooth" | "Private_Dining" | "Pure_Veg" | "Reservations" | "Rooftop_Dining" | "Serves_Alcohol" | "Smoking_Area" | "Takeout" | "Valet_Parking" | "Wheelchair_Accessible" | "Wifi" | "%future added value";
export type Enumrestaurants2Occasion = "celebration" | "corporate" | "date_night" | "reunion" | "%future added value";
export type Enumrestaurants2Type = "RESTAURANT" | "STORE" | "%future added value";
export type SortConnectionrestaurants2Enum = "CASHBACK_ASC" | "CASHBACK_DESC" | "COST_ASC" | "COST_DESC" | "DISCOUNT_ASC" | "DISCOUNT_DESC" | "DISPLAYNAME_ASC" | "DISPLAYNAME_DESC" | "RATING_ASC" | "RATING_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type findRestaurantsFilterInput = {
  _id?: any | null;
  amenities?: ReadonlyArray<Enumrestaurants2Amenities | null> | null;
  campus: FilterFindManyrestaurants2CampusInput;
  cusines?: ReadonlyArray<string | null> | null;
  imageSection?: string | null;
  occasion?: ReadonlyArray<Enumrestaurants2Occasion | null> | null;
  openHours?: string | null;
  outdoorAndEventFlag?: boolean | null;
  outdoorSeating?: boolean | null;
  overAllRating?: number | null;
  ownerId?: any | null;
  pureVeg?: boolean | null;
  search?: findRestaurantsFilterInputSearch | null;
  serveAlcohol?: boolean | null;
  type?: Enumrestaurants2Type | null;
  withOffer?: boolean | null;
};
export type FilterFindManyrestaurants2CampusInput = {
  _id: any;
};
export type findRestaurantsFilterInputSearch = {
  restaurantTitle?: string | null;
};
export type restaurantListingQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  filter: findRestaurantsFilterInput;
  id: any;
  includeFindUser: boolean;
  sort?: SortConnectionrestaurants2Enum | null;
};
export type restaurantListingQuery$data = {
  readonly findConfig: {
    readonly " $fragmentSpreads": FragmentRefs<"restaurantListingQuery_findCashbackFragment">;
  } | null;
  readonly findUserById?: {
    readonly " $fragmentSpreads": FragmentRefs<"restaurantListingQuery_findUserFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"restaurantListingQueryFragment">;
};
export type restaurantListingQuery = {
  response: restaurantListingQuery$data;
  variables: restaurantListingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeFindUser"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v6 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v7 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v8 = [
  {
    "kind": "Variable",
    "name": "_id",
    "variableName": "id"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v7/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "restaurantListingQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "restaurantListingQueryFragment"
      },
      {
        "condition": "includeFindUser",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "findUserById",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "restaurantListingQuery_findUserFragment"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "config",
        "kind": "LinkedField",
        "name": "findConfig",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "restaurantListingQuery_findCashbackFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "restaurantListingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "restaurants2Connection",
        "kind": "LinkedField",
        "name": "findRestaurants2V2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2Edge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "menuId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "restaurants2Description",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "summary",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tagLine",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "restaurants2DescriptionCusines",
                        "kind": "LinkedField",
                        "name": "cusines",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "primary",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "restaurants2DescriptionTags",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "restaurants2DescriptionTagsIcon",
                            "kind": "LinkedField",
                            "name": "icon",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "iconName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "color",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cost2pax",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overAllRating",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "restaurants2MediaGallery",
                    "kind": "LinkedField",
                    "name": "mediaGallery",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sectionTitle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "restaurants2MediaGalleryMedia",
                        "kind": "LinkedField",
                        "name": "media",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cover",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "prime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mediaURL",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mediaType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sectionCover",
                            "storageKey": null
                          },
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "coverVideo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payBillPercentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payBillCashback",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "restaurants2PayBill",
                    "kind": "LinkedField",
                    "name": "payBill",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "payEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "restaurants2PayBillPointsEnabled",
                        "kind": "LinkedField",
                        "name": "pointsEnabled",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "redeem",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reward",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "filters": [
          "filter",
          "sort"
        ],
        "handle": "connection",
        "key": "restaurantListingQueryFragment_findRestaurants2V2",
        "kind": "LinkedHandle",
        "name": "findRestaurants2V2"
      },
      {
        "condition": "includeFindUser",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "findUserById",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserWallet",
                "kind": "LinkedField",
                "name": "wallet",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentBalance",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "config",
        "kind": "LinkedField",
        "name": "findConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "configBillUploadConfig",
            "kind": "LinkedField",
            "name": "billUploadConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cashbackPercentage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94536777156a36eb825713480d05516b",
    "id": null,
    "metadata": {},
    "name": "restaurantListingQuery",
    "operationKind": "query",
    "text": "query restaurantListingQuery(\n  $filter: findRestaurantsFilterInput!\n  $includeFindUser: Boolean!\n  $id: MongoID!\n  $sort: SortConnectionrestaurants2Enum\n  $count: Int\n  $cursor: String\n) {\n  ...restaurantListingQueryFragment_1GWUVg\n  findUserById(_id: $id) @include(if: $includeFindUser) {\n    ...restaurantListingQuery_findUserFragment\n  }\n  findConfig {\n    ...restaurantListingQuery_findCashbackFragment\n  }\n}\n\nfragment restaurantListingQueryFragment_1GWUVg on Query {\n  findRestaurants2V2(filter: $filter, sort: $sort, first: $count, after: $cursor) {\n    count\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        _id\n        displayName\n        logo\n        menuId\n        description {\n          summary\n          tagLine\n          cusines {\n            _id\n            name\n            primary\n          }\n          tags {\n            name\n            icon {\n              iconName\n              color\n            }\n            _id\n          }\n          cost2pax\n        }\n        overAllRating\n        mediaGallery {\n          _id\n          sectionTitle\n          description\n          media {\n            cover\n            prime\n            mediaURL\n            mediaType\n            sectionCover\n            displayOrder\n            coverVideo\n          }\n          displayOrder\n        }\n        payBillPercentage\n        payBillCashback\n        payBill {\n          payEnabled\n          pointsEnabled {\n            redeem\n            reward\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment restaurantListingQuery_findCashbackFragment on config {\n  billUploadConfig {\n    cashbackPercentage\n  }\n}\n\nfragment restaurantListingQuery_findUserFragment on User {\n  wallet {\n    currentBalance\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f06058877884358d8c480848d9a34d6";

export default node;
