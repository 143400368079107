/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const eventsHomeQuery = graphql`
  query eventsHomeQuery(
    $filter: findEventInput!
    $filterAllEvents: findEventInput!
    $addonsFilter: FilterFindManyaddonInput
    $limit: Int
  ) {
    findEventsCurrent: findEvents(filter: $filter) {
      _id
      campus {
        _id
      }
      title
      description
      venue
      basePrice
      ageLimit
      media {
        cover {
          mediaURL
          mediaType
          cover
          displayOrder
          thumbnailURL
          _id
        }
        past {
          mediaURL
          mediaType
          cover
          displayOrder
          thumbnailURL
          _id
        }
        testimonials {
          mediaURL
          mediaType
          cover
          displayOrder
          thumbnailURL
          _id
        }
      }
      mediaCoverages {
        imageUrl
        hyperLink
      }
      faqs {
        question
        answer
      }
      host {
        name
        description
        socialMediaURL
        media {
          mediaURL
          mediaType
          cover
          displayOrder
          thumbnailURL
          _id
        }
      }
      motivation {
        title
        logo
      }
      timeline {
        title
        logo
        duration
        media {
          mediaURL
          mediaType
          cover
          displayOrder
          thumbnailURL
          _id
        }
      }
      eventTiming {
        _id
        date
        slot {
          startTime
          endTime
          slotSpace
          slotsLeft
          slotTag
          _id
          availableTickets {
            name
            price
            basePrice
            personAllowed
            isActive
            slotsLeft
            _id
          }
        }
      }
      venueDetails {
        venue
        geo {
          lat
          long
        }
      }
      termsAndConditions
      inclusion {
        itemsToProvide
        itemsToBring
      }
      OurParticipant {
        media {
          mediaType
          mediaURL
          thumbnailURL
        }
        name
        comment
      }
    }
    findAddons(filter: $addonsFilter) {
      _id
      type
      additionalCashback {
        refType
        cashback {
          value
          type
        }
      }
      additionalDiscount {
        refType
        discount {
          value
          type
        }
      }
    }
    findEventsAll: findEvents(filter: $filterAllEvents, limit: $limit) {
      _id
      title
      media {
        cover {
          mediaURL
          mediaType
          cover
          displayOrder
          thumbnailURL
          _id
        }
      }
      eventTiming {
        _id
        date
        slot {
          startTime
          endTime
          slotSpace
          slotsLeft
          slotTag
          _id
          availableTickets {
            name
            price
            basePrice
            personAllowed
            isActive
            slotsLeft
            _id
          }
        }
      }
    }
    findAddons(filter: $addonsFilter) {
      _id
      type
      additionalCashback {
        refType
        cashback {
          value
          type
        }
      }
      additionalDiscount {
        refType
        discount {
          value
          type
        }
      }
    }
  }
`;
