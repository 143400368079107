/* eslint-disable no-nested-ternary */
import { variant, color, space } from 'styled-system';
import styled from 'styled-components/native';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StyledText from '../Typography/Text/Text';
import View from '../../basic/View/View';
import StyledIcon from '../Icon/Icon';
import {
  Directions,
  State,
  ThemeVariables,
} from '../../../../themes/new/helper';

const Icon = styled(StyledIcon).attrs(({ iconSize, iconColor }) => ({
  size: iconSize === 'lg' ? '2xl' : '2xl',
  color: iconColor,
}))``;

const Text = styled(StyledText).attrs(({ textColor, textSize, textLabel }) => {
  return {
    color: textColor,
    size: textLabel ? 'md' : textSize,
  };
})``;

const stateVariants = ({ theme, ...rest }) => {
  return variant({
    prop: ThemeVariables.STATE,
    variants: {
      active: {},
      disabled: {},
    },
  });
};

const getColor = (state: any, checked: any, labelWarning: string) => {
  switch (state) {
    case State.ACTIVE:
      return labelWarning
        ? 'primary.50'
        : checked
        ? 'primary.500'
        : 'primary.300';
      break;
    case State.DISABLED:
      return 'primary.50';
      break;
    default:
      return checked ? 'primary.500' : 'primary.300';
  }
};
const sizeVariants = ({ ...rest }) => {
  return variant({
    prop: ThemeVariables.SIZE,
    variants: {
      lg: {},
    },
  });
};
const renderIcon = ({ size, isChecked, state, icon, labelWarning }) => {
  return (
    <View
      p="lg"
      borderWidth="xs"
      borderColor="transparent"
      ml={icon === Directions.RIGHT ? '2xl' : 0}
    >
      <Icon
        name={isChecked ? 'checkbox-outline-300' : 'checkbox-empty-outline-300'}
        iconSize={size}
        iconColor={getColor(state, isChecked, labelWarning)}
      />
    </View>
  );
};

const StyledCheckBox = styled.Pressable`
  ${color}
  ${space}
  ${(props) => sizeVariants(props)}
  ${(props) => stateVariants(props)}
`;

const CheckBox = ({
  children,
  size,
  label,
  labelWarning,
  state,
  icon,
  checked,
  onPress,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const toggleChecked = (value: boolean) => {
    if (onPress) {
      onPress();
    } else {
      setIsChecked(!value);
      checked = !value;
    }
  };
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  return (
    <StyledCheckBox
      {...rest}
      checked={checked}
      state={state}
      size={size}
      disabled={state === 'disabled' || !!labelWarning}
      onPress={() => {
        toggleChecked(isChecked);
      }}
    >
      <View
        alignItems="center"
        flexDirection="row"
        justifyContent={
          icon !== Directions.RIGHT ? 'flex-start' : 'space-between'
        }
      >
        {icon === Directions.LEFT &&
          renderIcon({ state, isChecked, size, icon, labelWarning })}
        <View
          flex={1}
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          // pr="2xl"
        >
          <View flex={1}>
            <Text
              textSize={size}
              textColor={getColor(state, isChecked, labelWarning)}
              selectable={false}
              numberOfLines={2}
              ellipsizemode="tail"
            >
              {children}
            </Text>
          </View>
          <View
            alignItems="center"
            flexDirection="row"
            justifyContent="flex-end"
          >
            {labelWarning ? (
              <Text
                textSize={size}
                textLabel
                textColor={getColor(state, isChecked, labelWarning)}
                selectable={false}
                numberOfLines={1}
                ellipsizemode="tail"
              >
                {labelWarning}
              </Text>
            ) : label ? (
              <Text
                textSize={size}
                textLabel
                textColor={getColor(state, isChecked, labelWarning)}
                selectable={false}
                numberOfLines={1}
                ellipsizemode="tail"
              >
                +
                <Text
                  textSize="sm"
                  textIcon
                  textColor={getColor(state, isChecked, labelWarning)}
                  selectable={false}
                  numberOfLines={1}
                  ellipsizemode="tail"
                >
                  {' '}
                  &#8377;
                </Text>
                {label}
              </Text>
            ) : (
              <Text
                textSize={size}
                textLabel
                textColor={getColor(state, isChecked, labelWarning)}
                selectable={false}
                numberOfLines={1}
                ellipsizemode="tail"
              />
            )}
          </View>
        </View>
        {icon === Directions.RIGHT &&
          renderIcon({ state, isChecked, size, icon, labelWarning })}
      </View>
    </StyledCheckBox>
  );
};

CheckBox.propTypes = {
  size: PropTypes.string,
  state: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  labelWarning: PropTypes.string,
};

CheckBox.defaultProps = {
  size: 'lg',
  state: State.ACTIVE,
  checked: false,
  icon: Directions.RIGHT,
  label: '',
  labelWarning: '',
};

export default CheckBox;
