import React, { useState } from 'react';
import Animated, {
  useAnimatedStyle,
  Easing,
  withTiming,
  withDelay,
} from 'react-native-reanimated';
import { variant } from 'styled-system';
import styled from 'styled-components/native';
import View from '../../basic/View/View';
import IconButton from '../IconButton/IconButton';
import Text from '../Typography/Text/Text';
import {
  menuAddButtonAppearanceEnum,
  menuAddButtonSizeEnum,
  menuAddButtonStateEnum,
} from './varAndEnum';
import {
  // iconBgResolver,
  iconColorResolver,
  leftIconPaddingResolver,
  rightIconPaddingResolver,
  textColorResolver,
} from './helpers';
import { Appearance, ThemeVariables } from '../../../../themes/new/helper';

const stateVariants = ({ theme, ...rest }) => {
  return variant({
    prop: ThemeVariables.STATE,
    variants: {
      active: {},
      disabled: {
        borderColor: theme.colors.primary[50],
        bg: theme.colors.primary[50],
      },
    },
  });
};

const appearanceVariants = ({ theme, state, ...rest }) => {
  return variant({
    prop: ThemeVariables.APPEARANCE,
    variants: {
      solid: {
        borderWidth: theme.borderWidths.xs,
        borderColor:
          state === menuAddButtonStateEnum.disabled
            ? theme.colors.primary[50]
            : theme.colors.primary[500],
        bg:
          state === menuAddButtonStateEnum.disabled
            ? theme.colors.primary[50]
            : theme.colors.primary[500],
      },
      outline: {
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors.primary[200],
        bg: 'primary.10',
      },
    },
  });
};

const StyledView = styled(View)(
  {
    flexDirection: 'row',
    borderRadius: '999px',
  },
  (props) => appearanceVariants(props),
  (props) => stateVariants(props),
);

const StyledAnimatedView = styled(Animated.View)``;
// marginright: ${({ theme }) => theme.space.lg};

export default function MenuAddButton({
  appearance = menuAddButtonAppearanceEnum.solid,
  state = menuAddButtonStateEnum.active,
  leftIconState = menuAddButtonStateEnum.active,
  rightIconState = menuAddButtonStateEnum.active,
  count,
  onLeftPress,
  onRightPress,
  size = menuAddButtonSizeEnum.lg,
}) {
  const [buttonLayout, setButtonLayout] = useState({ height: 0, width: 0 });
  // const [textLayout, setTextLayout] = useState({ height: 0, width: 0 });
  // state to know if user has yet clicked once, then only we are starting the animation
  const [interactedYet, setInteractedYet] = useState(false);

  const animatedStyles = useAnimatedStyle(() => {
    const tempWidth = count > 0 ? buttonLayout.width : 0;
    const tempOpacity = count > 0 ? 1 : 0;

    return {
      zIndex: 1,
      overflow: 'hidden',
      opacity: count > 0 ? withDelay(100, withTiming(tempOpacity)) : 0,
      width: withTiming(tempWidth, {
        duration: interactedYet ? 250 : 0,
        // easing: Easing.bezier(0.25, 0.5, 0.75, 1),
      }),

      // height: withTiming(tempHeight, {
      //   duration: 0,
      //   easing: Easing.bezier(0.25, 0.5, 0.75, 1),
      // }),
    };
  }, [buttonLayout, count]);

  const animatedStylesForText = useAnimatedStyle(() => {
    const tempOpacity = count > 0 ? 1 : 0;

    return {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      opacity: count > 0 ? withDelay(100, withTiming(tempOpacity)) : 0,
    };
  }, [count]);

  const styleFinder = () => {
    // if the count is > 0 , its width needs to be zero
    if (interactedYet || count === 0) {
      return animatedStyles; // TODO: fix
    }
    return {};
  };

  return (
    <StyledView state={state} appearance={appearance} overflow="hidden">
      <View flexDirection="row" zIndex={1}>
        <View>
          <StyledAnimatedView
            // only allow animation if count changes from the initial value and also at value 0 it can't have full width
            style={[styleFinder()]}
          >
            <IconButton
              // size={size}
              // count={count}
              // mr="lg"
              name="subtract-outline-400"
              shadow="none"
              iconSize="md"
              iconColor={iconColorResolver(appearance, state)}
              appearance={Appearance.GHOST}
              state={state === leftIconState ? state : leftIconState}
              onPress={() => {
                if (!interactedYet) {
                  setInteractedYet(true);
                }
                onLeftPress();
              }}
              withoutBorder
              zIndex={2}
              paddingLeft={leftIconPaddingResolver(size, count).paddingLeft}
              paddingRight={leftIconPaddingResolver(size, count).paddingRight}
              paddingTop={leftIconPaddingResolver(size, count).paddingTop}
              paddingBottom={leftIconPaddingResolver(size, count).paddingBottom}
            />
          </StyledAnimatedView>
        </View>
        <View>
          <IconButton
            // size={size}
            // count={count}
            onLayout={(e) => setButtonLayout(e.nativeEvent.layout)}
            name={count > 0 ? 'add-outline2-400' : 'add-outline-300'}
            shadow="none"
            iconSize={count > 0 ? 'md' : '2xl'}
            iconColor={iconColorResolver(appearance, state)}
            state={state === rightIconState ? state : rightIconState}
            onPress={() => {
              if (!interactedYet) {
                setInteractedYet(true);
              }
              onRightPress();
            }}
            appearance={Appearance.GHOST}
            withoutBorder
            paddingLeft={rightIconPaddingResolver(size, count).paddingLeft}
            paddingRight={rightIconPaddingResolver(size, count).paddingRight}
            paddingTop={rightIconPaddingResolver(size, count).paddingTop}
            paddingBottom={rightIconPaddingResolver(size, count).paddingBottom}
          />
        </View>
      </View>
      <Animated.View style={animatedStylesForText}>
        <View
          alignItems="center"
          justifyContent="center"
          position="absolute"
          flexDirection="column"
          // zIndex={0}
          left={0}
          right={0}
          top={0}
          bottom={0}
          flex={1}
          // py={size === menuAddButtonSizeEnum.md ? 'md' : 'lg'}
          minWidth={24}
          textAlign="center"
          // onLayout={(e) => setTextLayout(e.nativeEvent.layout)}
        >
          <View>
            <Text
              size="md"
              numberOfLines={1}
              color={textColorResolver(appearance, state)}
            >{`${count === 0 ? 1 : count}`}</Text>
          </View>
        </View>
      </Animated.View>
    </StyledView>
  );
}
