/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const transactionsScreenQuery = graphql`
  query transactionsScreenQuery(
    $sort: [PaymentHistorySortEnum]
    $userId: MongoID!
  ) {
    findUserById(_id: $userId) {
      wallet {
        totalBalance
        currentBalance
        moneySaved
        earnedAmount
      }
    }
    getPaymentHistory(sort: $sort) {
      userId
      displayName
      priceDetails {
        grossAmount
        discountGiven
        rewardsUsed
        netAmount
        tipAmount
        couponAmount
        couponCashbackAmount
        csrAmount
      }
      couponCode
      rewardPoints {
        type
        amount
        source {
          type
        }
      }
      status
      _id
      created_at
      orderId
    }
  }
`;
