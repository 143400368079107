export interface Rect {
  minX: number;
  minY: number;
  width: number;
  height: number;
}

interface Contains {
  outside: Rect;
  inside: Rect;
}

export function contains({ outside, inside }: Contains) {
  const outsideMaxX = outside.minX + outside.width;
  const insideMaxX = inside.minX + inside.width;

  const outsideMaxY = outside.minY + outside.height;
  const insideMaxY = inside.minY + inside.height;

  if (inside.minX < outside.minX) {
    return false;
  }

  if (inside.minY < outside.minY) {
    return false;
  }

  return true;
}

export function contains2({ outside, inside }: Contains) {
  const outsideMaxX = outside.minX + outside.width;
  const insideMaxX = inside.minX + inside.width;

  const outsideMaxY = outside.minY + outside.height;
  const insideMaxY = inside.minY + inside.height;

  const xIntersect = Math.max(
    0,
    Math.min(insideMaxX, outsideMaxX) - Math.max(inside.minX, outside.minX),
  );
  const yIntersect = Math.max(
    0,
    Math.min(insideMaxY, outsideMaxY) - Math.max(inside.minY, outside.minY),
  );
  const intersectArea = xIntersect * yIntersect;

  const insideArea = inside.width * inside.height;
  const outsideArea = outside.width * outside.height;

  const unionArea = insideArea + outsideArea - intersectArea;

  return unionArea === outsideArea;
}

export const ACTIONS = {
  NO_FACE_FOUND: 'NO_FACE_FOUND',
  ON_ERROR: 'ON_ERROR',
  SET_FACE_INSIDE_AREA: 'SET_FACE_INSIDE_AREA',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_CURRENT_STEP_STATUS: 'SET_CURRENT_STEP_STATUS',
  SET_IS_SHOW_COMPLETED: 'SET_IS_SHOW_COMPLETED',
  SET_INSTRUCTION_TEXT: 'SET_INSTRUCTION_TEXT',
  RESET: 'RESET',
  SET_ERROR: 'SET_ERROR',
};

export const STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
};

export const STEPS = {
  BLINK: 1,
  SMILE: 2,
};

export const detections = {
  BLINK: { instruction: 'Blink Both Eyes', minProbability: 0.3 },
  SMILE: { instruction: 'Smile', minProbability: 0.7 },
};

export const instructionsText = {
  initialPrompt: 'Position Your Face In The Circle',
  tooClose: 'Move your phone further',
  multipleFaces: 'Multiple Faces Detected.',
  tooFar: 'Move your phone closer',
  Completed: 'Completed',
};
