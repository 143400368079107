import { useFragment } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { get } from 'lodash';
import { useActionSheet } from '@expo/react-native-action-sheet';
import useProfileCardData from '../hooks/useProfileCardData';
import {
  findAddonFragment,
  findUserByIdFragment,
} from '../API/profileCardQuery';
import { isCustomerBlackMember } from '../../plusMembership/helpers';
import S3Delete from '../../../../utilities/S3Delete';
import { getTheme } from '../../../../themes/new/theme';
import useUserStore from '../../../../stores/userStore';
import { normalizeToNumberOrZero } from '../../../../utilities/Utility';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../utilities/firbaseAnalytics';

const theme = getTheme();
const EDIT_OPTIONS = ['Choose Photo', 'Take Photo', 'Remove Photo', 'Cancel'];

const useUserProfileCard = (refreshAPI, parantApiData) => {
  const navigation = useNavigation();
  const { showActionSheetWithOptions } = useActionSheet();
  const userId = useUserStore((state) => state.id);
  const { apiData, openImagePickerAsync } = useProfileCardData({
    refreshAPI,
    apiData: parantApiData,
  });
  const userData = useFragment(
    findUserByIdFragment,
    parantApiData?.findUserById,
  );
  const { moneySaved, earnedAmount: earnedAmt } = userData?.wallet ?? {};
  const savedAmount = normalizeToNumberOrZero(moneySaved);
  const earnedAmount = normalizeToNumberOrZero(earnedAmt);
  const hasSavedMoney = Boolean(savedAmount);
  const hasEarnedAmount = Boolean(earnedAmount);
  const membershipAddon = useFragment(
    findAddonFragment,
    parantApiData?.findAddons,
  );
  const isBlackMember = isCustomerBlackMember(userData, membershipAddon[0]);
  const isDarkTheme = isBlackMember;
  const fullName = `${apiData?.name?.first || ''} ${apiData?.name?.last || ''}`;

  const handleCTAPress = () => {
    firebaseEventLogger('account__savingsLink_Tap', {
      buttonName: 'savingsLink',
      screenName: 'account',
      userType: 'user',
      interactionType: InteractionType.TAP,
    });
    navigation.navigate('blackMembershipScreen');
  };

  const handleProfilePicEdit = () => {
    showActionSheetWithOptions(
      {
        options: EDIT_OPTIONS,
        cancelButtonIndex: EDIT_OPTIONS?.length - 1,
        destructiveButtonIndex: EDIT_OPTIONS?.length - 2,
        destructiveColor: get(theme.colors, 'error.500'),
        containerStyle: {
          backgroundColor: get(theme.colors, 'primary.10'),
        },
        textStyle: {
          color: get(theme.colors, 'info.500'),
          textAlign: 'center',
          paddingLeft: get(theme.space, '2xl'),
        },
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          openImagePickerAsync('gallery');
        }
        if (buttonIndex === 1) {
          openImagePickerAsync('camera');
        }
        if (buttonIndex === 2 && apiData?.profileImage) {
          S3Delete(apiData?.profileImage, userId, refreshAPI);
        }
      },
    );
  };

  return {
    isBlackMember,
    isDarkTheme,
    savedAmount,
    earnedAmount,
    fullName,
    profileImage: apiData.profileImage,
    hasSavedMoney,
    hasEarnedAmount,
    handleCTAPress,
    handleProfilePicEdit,
  };
};

export default useUserProfileCard;
