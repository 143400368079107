import React, { useState, Suspense, useCallback } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { ScrollView } from 'react-native';
import {
  Layout,
  Text,
  View,
  TopNavigation,
  IconButton,
  Heading,
  Card,
} from '../../../components/new';
import {
  getFormatedDate,
  getOptions,
  navigateBack,
  DateFormatsEnum,
} from '../../../utilities/helper';
import Loading from '../../../components/loading.component';
import NewErrorView from '../../../utilities/NewErrorView';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import useUserStore from '../../../stores/userStore';
import useCommonStore from '../../../stores/commonStore';

const orderSummaryQuery = graphql`
  query orderSummaryQuery($filter: findOrdersFilterInput) {
    findOrders(filter: $filter) {
      _id
      tableNo
      orderDate
      menuItems {
        itemId
        quantity
        defaultprice
        itemName
        itemVariation {
          _id
          name
          price
        }
        addonItem {
          _id
          name
          price
          quantity
        }
      }
      priceDetails {
        _id
        subTotal
        total
        tax {
          _id
          taxType
          amount
        }
      }
      restaurant {
        displayName
      }
    }
  }
`;

const RenderSummaryScreen = (props) => {
  const { variables, navigation } = props;

  const { findOrders } = useLazyLoadQuery(orderSummaryQuery, variables, {
    networkCacheConfig: { force: true },
  });
  const { tableNo, menuItems, priceDetails, orderDate, restaurant } =
    findOrders[0];

  const getPrice = (item) => {
    let price = item.defaultprice ?? 0;
    if (item.addonItem?.length) {
      price += item.addonItem.reduce((prev, curr) => {
        return prev + Number(curr.price);
      }, 0);
    }
    return price;
  };

  return (
    <View height="100%">
      <TopNavigation
        title={restaurant.displayName ?? ''}
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, 'user')}
          />
        }
      />
      <ScrollView>
        <View
          mt="3xl"
          px="2xl"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size="2xl" weight="medium" color="primary.300">
            Order Summary
          </Heading>
          <Text size="sm" color="primary.100">
            Table No. {tableNo}
          </Text>
        </View>
        <View px="2xl" py="2xl">
          <Card>
            {menuItems.map((item, index) => {
              return (
                <View
                  key={item.itemId}
                  mb={item.itemVariation.length ? '4xl' : '2xl'}
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <View flex={3}>
                    <Text size="md" color="primary.400" weight="medium">
                      {item.itemName}
                    </Text>
                    <Text mb="xs" size="md" color="primary.400">
                      {`${item.quantity} x ₹${getPrice(item)}`}
                    </Text>
                    {item.addonItem.length ? (
                      <Text
                        size="xs"
                        color="primary.200"
                        numberOfLines={2}
                        ellipsizeMode="tail"
                      >
                        {getOptions(item.addonItem)}
                      </Text>
                    ) : null}
                  </View>
                  <View flex={2} alignItems="flex-end">
                    <Text mb="xs" size="sm" color="primary.500">
                      {`₹${item.quantity * getPrice(item)}`}
                    </Text>
                  </View>
                </View>
              );
            })}
          </Card>
        </View>
        <View mb="2xl">
          <Text size="sm" color="primary.100" textAlign="center">
            {`${getFormatedDate(
              new Date(orderDate),
              DateFormatsEnum.DateMonthYear,
            )} at ${getFormatedDate(
              new Date(orderDate),
              DateFormatsEnum.TIME,
            )}`}
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

const OrderSummary = ({ navigation, route }) => {
  const { orderId } = route.params;
  const [netStatus, setNetStatus] = useState(true);
  const userId = useUserStore((state) => state.id);
  const campusId = useCommonStore((state) => state.campusId);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  return (
    <View flex={1}>
      <Layout level={2}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <RenderSummaryScreen
              variables={{
                filter: { _id: orderId, campus: { _id: campusId } },
              }}
              navigation={navigation}
            />
          </Suspense>
        </NewErrorBoundary>
        <DisconnectedDropover
          setNetStatus={setNetStatus}
          text="No Internet Connection"
          icon="wifi-off-outline"
        />
      </Layout>
    </View>
  );
};

export default OrderSummary;
