/**
 * @generated SignedSource<<b0eca899a296b6bedb24d52a0d504fff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumTicketModelTCwoUserIssuedByRefType = "CAMPUS_LOCATION" | "EVENT" | "POPUP_STORE" | "RESTAURANT" | "%future added value";
export type EnumticketStatus = "ACTIVE" | "CANCELLED" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumticketTicketType = "EVENT_TICKET" | "PRODUCT_TICKET" | "REWARD_TICKET" | "%future added value";
export type SortFindOneticketInput = "_ID_ASC" | "_ID_DESC" | "%future added value";
export type claimTicketfilterInput = {
  _id?: any | null;
  uId?: number | null;
};
export type claimApiTicketMutation$variables = {
  filter?: claimTicketfilterInput | null;
  sort?: SortFindOneticketInput | null;
};
export type claimApiTicketMutation$data = {
  readonly claimTicket: {
    readonly _id: any;
    readonly additionalFields: {
      readonly event: {
        readonly endTime: any;
        readonly spaces: number | null;
        readonly startTime: any;
      } | null;
    } | null;
    readonly canBeClaimedBy: ReadonlyArray<{
      readonly displayName: string;
      readonly venue: {
        readonly addressLine1: string | null;
        readonly geo: {
          readonly lat: string;
          readonly long: string;
        } | null;
      } | null;
    } | null> | null;
    readonly expires_on: any | null;
    readonly finePrint: {
      readonly details: ReadonlyArray<string | null> | null;
      readonly summary: string | null;
    } | null;
    readonly issuedBy: {
      readonly _id: any | null;
      readonly refType: EnumTicketModelTCwoUserIssuedByRefType;
    };
    readonly rewardId: any | null;
    readonly status: EnumticketStatus | null;
    readonly thumbnailImg: string;
    readonly ticketType: EnumticketTicketType;
    readonly title: string;
    readonly uId: number | null;
    readonly user: {
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
  } | null;
};
export type claimApiTicketMutation = {
  response: claimApiTicketMutation$data;
  variables: claimApiTicketMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "ticket",
    "kind": "LinkedField",
    "name": "claimTicket",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ticketType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailImg",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expires_on",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TicketModelTCwoUserIssuedBy",
        "kind": "LinkedField",
        "name": "issuedBy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refType",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TicketModelTCwoUserAdditionalFields",
        "kind": "LinkedField",
        "name": "additionalFields",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TicketModelTCwoUserAdditionalFieldsEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spaces",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TicketModelTCwoUserCanBeClaimedBy",
        "kind": "LinkedField",
        "name": "canBeClaimedBy",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TicketModelTCwoUserCanBeClaimedByVenue",
            "kind": "LinkedField",
            "name": "venue",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketModelTCwoUserCanBeClaimedByVenueGeo",
                "kind": "LinkedField",
                "name": "geo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "long",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine1",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TicketModelTCwoUserFinePrint",
        "kind": "LinkedField",
        "name": "finePrint",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "summary",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rewardId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserName",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "claimApiTicketMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "claimApiTicketMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3e46dfd3c8a0647c53e241ca2c2a8c49",
    "id": null,
    "metadata": {},
    "name": "claimApiTicketMutation",
    "operationKind": "mutation",
    "text": "mutation claimApiTicketMutation(\n  $filter: claimTicketfilterInput\n  $sort: SortFindOneticketInput\n) {\n  claimTicket(filter: $filter, sort: $sort) {\n    _id\n    ticketType\n    thumbnailImg\n    uId\n    expires_on\n    title\n    issuedBy {\n      refType\n      _id\n    }\n    status\n    additionalFields {\n      event {\n        startTime\n        endTime\n        spaces\n      }\n    }\n    canBeClaimedBy {\n      venue {\n        geo {\n          lat\n          long\n        }\n        addressLine1\n      }\n      displayName\n    }\n    finePrint {\n      summary\n      details\n    }\n    rewardId\n    user {\n      name {\n        first\n        last\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "45a64d65faaef8cd4f61cc6234c2f02c";

export default node;
