/**
 * @generated SignedSource<<f68fd42502520997a6f696dd9b0cb6ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumvaletWorkLogWorkLogHistoryType = "break_end" | "break_start" | "punch_in" | "punch_out" | "%future added value";
export type valetWorkLogRecordInput = {
  date: any;
  valetID: any;
  workLogHistory?: ReadonlyArray<valetWorkLogWorkLogHistoryInput | null> | null;
};
export type valetWorkLogWorkLogHistoryInput = {
  _id?: any | null;
  time: any;
  type?: EnumvaletWorkLogWorkLogHistoryType | null;
};
export type valetMyTasksApiAddMutation$variables = {
  record: valetWorkLogRecordInput;
};
export type valetMyTasksApiAddMutation$data = {
  readonly createValetWorkLog: {
    readonly record: {
      readonly workLogHistory: ReadonlyArray<{
        readonly time: any;
        readonly type: EnumvaletWorkLogWorkLogHistoryType | null;
      } | null> | null;
    } | null;
  } | null;
};
export type valetMyTasksApiAddMutation = {
  response: valetMyTasksApiAddMutation$data;
  variables: valetMyTasksApiAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOnevaletWorkLogPayload",
    "kind": "LinkedField",
    "name": "createValetWorkLog",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "valetWorkLog",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "valetWorkLogWorkLogHistory",
            "kind": "LinkedField",
            "name": "workLogHistory",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "valetMyTasksApiAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "valetMyTasksApiAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8cf0da4d1e138b62c4a9f9829578d63e",
    "id": null,
    "metadata": {},
    "name": "valetMyTasksApiAddMutation",
    "operationKind": "mutation",
    "text": "mutation valetMyTasksApiAddMutation(\n  $record: valetWorkLogRecordInput!\n) {\n  createValetWorkLog(record: $record) {\n    record {\n      workLogHistory {\n        time\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a61f4b67f0fe46a6aa256ea42f326fe";

export default node;
