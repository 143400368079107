import React, { memo } from 'react';
import { Text, View } from '../../../../components/new';

const areEqual = () => {
  return true;
};

const HorizonatalFrame = ({ data, bgColor = '' }) => {
  return (
    <View bg={bgColor}>
      <View
        alignItems="center"
        py="4xl"
        px="2xl"
        flexDirection="row"
        borderTopWidth={1}
        borderBottomWidth={1}
        borderColor="static.gold"
      >
        <View flex={1}>
          <Text size="xl" weight="medium" color="static.gold" pb="sm">
            {data?.heading}
          </Text>
          <Text size="md" color="primary.300">
            {data?.subHeading}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default memo(HorizonatalFrame, areEqual);
