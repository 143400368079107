/* eslint-disable camelcase */
import React, { Suspense, useEffect, useState } from 'react';
import { Camera, CameraType } from 'expo-camera';
import {
  Linking,
  Pressable,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Loading } from '../../components';
import { Button, Icon, IconButton, Layout, View } from '../../components/new';
import NewErrorBoundary from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { getTheme, themeModes } from '../../themes/new/theme';
import { anprConfig } from '../../utilities/Constant';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { navigateBack } from '../../utilities/helper';
import useUserStore from '../../stores/userStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';

const darkTheme = getTheme(themeModes.dark);
const overlayColor = darkTheme?.colors?.opacity[70];
const { space } = darkTheme;
const { api, token, maxScans } = anprConfig;

interface AnprResponse {
  results: {
    plate: string;
    dscore: number; // Confidence level for plate detection.
    score: number; // Confidence level for reading the license plate text.
    region: {
      code: string;
      score: number;
    };
  }[];
}

enum PlateScanStatusEnum {
  IDLE = 'IDLE',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

const MIN_PLATE_TEXT_RECOGNITION_SCORE = 0.7; // will consider scores greater than this values
const PLATE_DETECTION_THRESHOLD = 0.3;

const AnprDetectionScreen = ({ navigation }) => {
  const isFocused = useIsFocused();
  const { width } = useWindowDimensions();
  const [camera, setCamera] = useState(null);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [cameraPermission, setCameraPermission] = useState(permission?.granted);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [scanAttemptCount, setScanAttemptCount] = useState(1);
  const [scanStatus, setScanStatus] = useState<PlateScanStatusEnum>(
    PlateScanStatusEnum.IDLE,
  );
  const [flash, setFlash] = useState(Camera.Constants.FlashMode.off);
  const role = useUserStore((state) => state.role);
  const detectionSquareWidth = Math.min(Math.max(width * 0.8, 300), 400);

  useEffect(() => {
    if (isFocused) {
      setScanAttemptCount(1);
      setScanStatus(PlateScanStatusEnum.IDLE);
    }
  }, [isFocused, permission]);

  const validateAndExtractPlateNumber = (
    anprResponse: AnprResponse,
  ): string | null => {
    if (anprResponse?.results?.length) {
      const vehicleInfo = anprResponse?.results[0];
      if (vehicleInfo?.score > MIN_PLATE_TEXT_RECOGNITION_SCORE) {
        const plate = vehicleInfo?.plate;
        return plate;
      }
      return null;
    }
    return null;
  };

  const captureImage = async () => {
    setScanStatus(PlateScanStatusEnum.PROCESSING);
    const data = await camera.takePictureAsync({
      base64: true,
      quality: 0.1,
    });
    const body = new FormData();
    body.append('upload', data.base64);
    body.append('regions', 'in'); // Change to your country
    body.append(
      'config',
      JSON.stringify({ threshold_d: PLATE_DETECTION_THRESHOLD }),
    );
    try {
      const response = await fetch(api, {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
        },
        body,
      });
      const json = await response.json();

      setScanAttemptCount((prevCount) => {
        prevCount += 1;
        return prevCount;
      });
      const plateNumber = validateAndExtractPlateNumber(json);
      if (plateNumber) {
        setScanStatus(PlateScanStatusEnum.SUCCESS);
        setTimeout(() => {
          navigation.navigate('AddVehicleScreen', {
            vehicleNumber: plateNumber,
          });
        }, 500);
      } else {
        if (scanAttemptCount > 1) {
          firebaseEventLogger('anprDetection__scanVehicle_Data', {
            buttonName: 'scanVehicle',
            screenName: 'anprDetection',
            userType: 'Valet, Controller, ValetSupervisor',
            interactionType: 'Data',
            status: 'failure',
            attemptCount: scanAttemptCount,
          });
        }
        setScanStatus(PlateScanStatusEnum.FAILURE);
        setTimeout(() => {
          setScanStatus(PlateScanStatusEnum.IDLE);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      if (scanAttemptCount > 1) {
        firebaseEventLogger('anprDetection__scanVehicle_Data', {
          buttonName: 'scanVehicle',
          screenName: 'anprDetection',
          userType: 'Valet, Controller, ValetSupervisor',
          interactionType: 'Data',
          status: 'failure',
          attemptCount: scanAttemptCount,
        });
      }
      setScanStatus(PlateScanStatusEnum.FAILURE);
      setTimeout(() => {
        setScanStatus(PlateScanStatusEnum.IDLE);
      }, 1000);
    }
  };

  const getDetectionSquareColor = (status: PlateScanStatusEnum) => {
    switch (status) {
      case PlateScanStatusEnum.IDLE:
        return 'primary.50';
      case PlateScanStatusEnum.FAILURE:
        return 'error.500';
      case PlateScanStatusEnum.SUCCESS:
        return 'success.500';
      case PlateScanStatusEnum.PROCESSING:
        return 'info.500';
      default:
        return 'primary.50';
    }
  };

  const requestPermissionAgain = () => {
    Linking.openSettings();
  };

  useEffect(() => {
    if (!permission?.granted) {
      requestPermission()
        .then((res) => {
          if (res?.canAskAgain === false && res?.status !== 'granted') {
            dispatchSnackbar({
              msg: 'Please grant permission',
              status: SnackbarStatus.error,
              version: SnackbarVersion.v1,
            });
            setTimeout(() => {
              requestPermissionAgain();
            }, 1000);
          } else if (res?.status !== 'granted') {
            dispatchSnackbar({
              msg: 'Permission denied!',
              status: SnackbarStatus.error,
              version: SnackbarVersion.v1,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const cameraBtnEnabled =
    scanAttemptCount <= maxScans && scanStatus === PlateScanStatusEnum.IDLE;

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={1}
        fallback={<NewErrorView errorMsg="Sorry something went wrong" />}
      >
        <Suspense fallback={<Loading />}>
          {isFocused && (
            <View flex={1}>
              <View
                zIndex={10}
                bg="transparent"
                py="2xl"
                width="100%"
                position="absolute"
                top={0}
                alignItems="flex-start"
              >
                <View
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <IconButton
                    name="back-outline-300"
                    size="3xl"
                    mt="xl"
                    appearance="ghost"
                    iconColor="primary.10"
                    onPress={() => navigateBack(navigation, role)}
                  />
                  <Pressable
                    onPress={() =>
                      setFlash(
                        flash === Camera.Constants.FlashMode.off
                          ? Camera.Constants.FlashMode.torch
                          : Camera.Constants.FlashMode.off,
                      )
                    }
                    style={{
                      flexDirection: 'column',
                      paddingTop: space['2xl'],
                      paddingRight: space['2xl'],
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon
                      size="3xl"
                      color="primary.10"
                      name={flash ? 'flash-on-300' : 'flash-off-300'}
                    />
                  </Pressable>
                </View>
              </View>
              <View height="100%">
                <View flex={1} bg="black">
                  <Camera
                    ref={(ref) => setCamera(ref)}
                    style={styles.camera}
                    type={CameraType.back}
                    flashMode={flash}
                    ratio="16:9"
                    autoFocus={(Camera.Constants.AutoFocus as any).on}
                  >
                    <View flex={1} alignItems="center" justifyContent="center">
                      <View flex={1} bg={overlayColor} width="100%" />
                      <View
                        width={width}
                        height={detectionSquareWidth}
                        flexDirection="row"
                      >
                        <View bg={overlayColor} flex={1} />
                        <View
                          borderColor={getDetectionSquareColor(scanStatus)}
                          borderWidth="md"
                          flexBasis={detectionSquareWidth}
                        />
                        <View bg={overlayColor} flex={1} />
                      </View>
                      <View flex={1} bg={overlayColor} width="100%" />
                    </View>
                  </Camera>
                </View>
                <View
                  pt="4xl"
                  bg={darkTheme.colors.opacity[70]}
                  flexBasis={160}
                  alignItems="center"
                >
                  <Pressable
                    onPress={captureImage}
                    disabled={!cameraBtnEnabled}
                  >
                    <View
                      p="sm"
                      borderWidth="md"
                      borderColor={
                        cameraBtnEnabled
                          ? darkTheme.colors.primary['500']
                          : darkTheme.colors.grey[300]
                      }
                      borderRadius="full"
                    >
                      <View
                        width={48}
                        height={48}
                        borderRadius="full"
                        bg={
                          cameraBtnEnabled
                            ? darkTheme.colors.primary['500']
                            : darkTheme.colors.grey[300]
                        }
                      />
                    </View>
                  </Pressable>
                  {scanAttemptCount > maxScans && (
                    <View mt="xl">
                      <Button
                        state="active"
                        status="info"
                        size="md"
                        appearance="ghost"
                        iconLeft="add-outline2-400"
                        onPress={() => {
                          firebaseEventLogger(
                            'anprDetection__addManualVrn_Tap',
                            {
                              buttonName: 'addManualVrn',
                              screenName: 'anprDetection',
                              userType: 'Valet, Controller, ValetSupervisor',
                              interactionType: 'Tap',
                              attemptCount: scanAttemptCount,
                            },
                          );
                          navigation.replace('AddVehicleScreen');
                        }}
                      >
                        Add Car Manually
                      </Button>
                    </View>
                  )}
                </View>
              </View>
            </View>
          )}
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

const styles = StyleSheet.create({
  camera: {
    flex: 1,
    justifyContent: 'center',
  },
});

export default AnprDetectionScreen;
