import { useContext } from 'react';
import { Divider, Text, View } from '../../../components/new';
import { RecentSearchContext } from './RecentSearchContextProvider';
import SearchListItem from './SearchListItem';

const TrendingListSection = ({ navigation, trendingList }) => {
  const { addData: addRecentSearchItem } = useContext(RecentSearchContext);

  return (
    <View>
      <View mb="xl" height={40} justifyContent="center">
        {!!trendingList.length && (
          <Text color="primary.300" size="sm">
            Trending Restaurants
          </Text>
        )}
      </View>
      {trendingList.map((item) => (
        <View mb="2xl" key={item.displayName}>
          <SearchListItem
            data={item}
            showRating
            onPress={() => {
              addRecentSearchItem({
                displayName: item.displayName,
                _id: item._id,
              });
              // This restaurandId is for staging
              // TODO: remove this static navigation
              navigation.navigate('RestaurantScreen', {
                restaurantId: item._id,
              });
            }}
          />
          <Divider mt="2xl" />
        </View>
      ))}
    </View>
  );
};

export default TrendingListSection;
