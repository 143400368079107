import React from 'react';
import { Image } from 'expo-image';
import { Button, Text, View } from '../../../components/new';
import { Appearance, State, Status } from '../../../themes/new/helper';

interface ClaimWelcomeRewardProps {
  onRecall: () => void;
  onClaim: () => void;
  data: {
    image: string;
    recallButtonText: string;
    claimRewardText: string;
    titleText: string;
  };
  recalling?: boolean;
}
const ClaimWelcomeReward = ({
  onRecall,
  onClaim,
  data,
  recalling = false,
}: ClaimWelcomeRewardProps) => {
  return (
    <View px="2xl" py="2xl">
      <Image
        style={{
          height: 120,
          width: 120,
          alignSelf: 'center',
          marginBottom: 16,
        }}
        source={data.image}
      />
      <Text
        size="xl"
        weight="medium"
        textAlign="center"
        color="primary.400"
        mb="4xl"
      >
        {data.titleText}
      </Text>
      <Button
        state={recalling ? State.DISABLED : State.ACTIVE}
        status={Status.PRIMARY}
        appearance={Appearance.FILLED}
        size="lg"
        loading={recalling}
        onPress={onRecall}
      >
        {data.recallButtonText}
      </Button>
      <Button
        state={recalling ? State.DISABLED : State.ACTIVE}
        appearance={Appearance.OUTLINE}
        size="lg"
        onPress={onClaim}
        mt="2xl"
      >
        {data.claimRewardText}
      </Button>
    </View>
  );
};

export default ClaimWelcomeReward;
