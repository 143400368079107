import React, { useEffect } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import RazorpayCheckout from 'react-native-razorpay';
import { BlurView, Button, Text, View } from '../../../components/new';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import { InputStates } from '../../../components/new/primitive/Input/helpers';
import useAuthStore from '../../../stores/authStore';
import useLoginModalStore from '../../../stores/loginModalStore';
import {
  paymentOrderTypeEnum,
  PaymentStatusEnum,
} from '../../../utilities/helper';
import { reservationConfirmationScreenInitiatePaymentMutation } from './API/reservationConfirmationScreen';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import { updatePaymentAPI } from '../payment/api/paymentAmountScreenQuery';
import useUserStore from '../../../stores/userStore';
import FacebookPixelLogger from '../../../utilities/FacebookPixelLogger';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const ReservationConfirmationFooter = ({
  grossAmount = 0,
  netAmount = 0,
  payWithPoints,
  route,
}) => {
  const buttonDisabled = false;
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();

  const userStore = useUserStore((state) => state);
  const userContact = userStore?.contact;
  const userId = userStore.id;
  const params = JSON.parse(JSON.stringify(route?.params || {}));
  const fullName = `${userStore.firstName} ${userStore.lastName}`;
  const { slotId, eventId, tickets } = route.params || {};

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const loginModalStore = useLoginModalStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js');
  }, []);

  const optionsMaker = ({ invoiceId, orderId }) => {
    const optionsBase = {
      // description: 'Credits towards consultation',
      image:
        'https://32nd.s3.ap-south-1.amazonaws.com/assets/icons/footer-logo.svg',
      currency: 'INR',
      key: process.env.RAZORPAY_KEY,
      amount: Number(netAmount) * 100,
      timeout: 60 * 10,
      name: '32nd',

      prefill: {
        contact: `${userContact?.phone?.number}`,
        name: fullName,
      },
      // eslint-disable-next-line camelcase
      order_id: orderId,
      theme: { color: '#111' },
      retry: false,
    };

    if (Platform.OS === 'web') {
      optionsBase.handler = (response) => {
        if (
          (response.razorpay_signature,
          response.razorpay_order_id,
          response.razorpay_payment_id)
        ) {
          updatePaymentAPI({
            pgPaymentId: response.razorpay_payment_id,
            pgOrderId: response.razorpay_order_id,
            pgSignature: response.razorpay_signature,
          })
            .then((ress) => {
              handleRedirect({
                paymentStatus: ress?.updatePayment.status,
                eventId,
                invoiceId,
              });
            })
            .catch((e) => {
              handleRedirect({
                paymentStatus: PaymentStatusEnum.PENDING,
                eventId,
                invoiceId,
              });
            });
        }
      };
    }
    return optionsBase;
  };

  const openRazorpayNative = ({ invoiceId, orderId }) => {
    return RazorpayCheckout.open(optionsMaker({ invoiceId, orderId }))
      .then((data) => {
        updatePaymentAPI({
          pgPaymentId: data.razorpay_payment_id,
          pgOrderId: data.razorpay_order_id,
          pgSignature: data.razorpay_signature,
        })
          .then((ress) => {
            handleRedirect({
              paymentStatus: ress?.updatePayment.status,
              eventId,
              invoiceId,
            });
          })
          .catch((e) => {
            handleRedirect({
              paymentStatus: PaymentStatusEnum.PENDING,
              eventId,
              invoiceId,
            });
          });
      })
      .catch((error) => {
        updatePaymentAPI({ pgOrderId: orderId })
          .then((ress) => {
            handleRedirect({
              paymentStatus: ress?.updatePayment.status,
              eventId,
              invoiceId,
            });
          })
          .catch((e) => {
            handleRedirect({
              paymentStatus: PaymentStatusEnum.PENDING,
              eventId,
              invoiceId,
            });
          });
      });
  };

  const openRazorpayWeb = async ({ invoiceId, orderId }) => {
    const options = optionsMaker({ invoiceId, orderId });
    // @ts-ignore
    const rzp1 = new window.Razorpay(options);

    rzp1.on('payment.failed', function (response) {
      try {
        rzp1.close();
        handleRedirect({
          paymentStatus: PaymentStatusEnum.FAILED,
          eventId,
          invoiceId,
        });
      } catch (e) {
        console.log('--->>', e);
      }
    });
    rzp1.open();
  };

  const handleRedirect = ({ paymentStatus, invoiceId, eventId: resId }) => {
    if (paymentStatus === PaymentStatusEnum.SUCCESSFUL) {
      FacebookPixelLogger('track', 'Purchase');
      navigation.navigate('afterPaymentScreen', {
        eventId,
        invoiceId,
        paymentStatus: PaymentStatusEnum.SUCCESSFUL,
      });
    } else if (paymentStatus === PaymentStatusEnum.FAILED) {
      navigation.navigate('afterPaymentScreen', {
        eventId,
        invoiceId,
        paymentStatus: PaymentStatusEnum.FAILED,
        slotId,
      });
    } else if (paymentStatus === PaymentStatusEnum.PENDING) {
      navigation.navigate('afterPaymentScreen', {
        eventId,
        invoiceId,
        paymentStatus: PaymentStatusEnum.PENDING,
      });
    }
  };

  const initiatePayment = () => {
    reservationConfirmationScreenInitiatePaymentMutation({
      amount: netAmount,
      orderType: paymentOrderTypeEnum.event,
      orderId: eventId,
      useRewardPoints: payWithPoints,
      workshopDetails: {
        slotId,
        tickets: Object.values(tickets),
      },
    })
      .then((res) => {
        const status = res?.initiatePayment.status;
        if (status === PaymentStatusEnum.SUCCESSFUL) {
          handleRedirect({
            paymentStatus: PaymentStatusEnum.SUCCESSFUL,
            eventId,
            invoiceId: res?.initiatePayment?._id,
          });
          navigation.navigate('afterPaymentScreen', {
            paymentStatus: PaymentStatusEnum.SUCCESSFUL,
            eventId,
            invoiceId: res?.initiatePayment?._id,
          });
        } else if (status === PaymentStatusEnum.PENDING) {
          if (Platform.OS === 'web') {
            openRazorpayWeb({
              invoiceId: res?.initiatePayment?._id,
              orderId: res?.initiatePayment?.orderId,
            });
          } else {
            openRazorpayNative({
              invoiceId: res?.initiatePayment?._id,
              orderId: res?.initiatePayment?.orderId,
            });
          }
        } else if (status === PaymentStatusEnum.FAILED) {
          handleRedirect({
            paymentStatus: PaymentStatusEnum.FAILED,
            eventId,
            invoiceId: res?.initiatePayment?._id,
          });
          dispatchSnackbar({
            msg: 'payment failed! Please try again',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePress = () => {
    firebaseEventLogger('event__slot__payment__Tap', {
      ...params,
      ticketsInfo: JSON.stringify(params?.ticketsInfo),
      eventId,
      buttonName: 'proceed',
      screenName: 'eventConfirmation',
      userType: 'user',
      interactionType: 'tap',
      netAmount,
      grossAmount,
      payWithPoints,
    });
    FacebookPixelLogger('track', 'InitiateCheckout');
    if (isLoggedIn) {
      initiatePayment();
    } else {
      loginModalStore.setIsOpen(true);
    }
  };
  return (
    <View>
      <BlurView level="4">
        <View pb={insets.bottom} bg="opacity.90">
          <View>
            <View
              flexDirection="row"
              p="2xl"
              borderTopWidth="xs"
              borderColor="primary.50"
            >
              <View flex={1}>
                <View flexDirection="row">
                  {!!(netAmount !== grossAmount) && (
                    <View justifyContent="center">
                      <Text
                        size="md"
                        weight="medium"
                        color="primary.100"
                        style={{
                          textDecorationLine: 'line-through',
                        }}
                      >
                        ₹{grossAmount}
                      </Text>
                    </View>
                  )}
                  <View ml="sm">
                    <Text size="xl" weight="medium" color="primary.500">
                      ₹{netAmount}
                    </Text>
                  </View>
                </View>
                <Text size="xs" color="primary.200">
                  Total Amount
                </Text>
              </View>
              <View flex={1}>
                <Button
                  state={
                    buttonDisabled ? InputStates.disabled : InputStates.active
                  }
                  size="lg"
                  onPress={handlePress}
                >
                  {/* Pay Now */}
                  Proceed To Pay
                </Button>
              </View>
            </View>
          </View>
        </View>
      </BlurView>
    </View>
  );
};

export default ReservationConfirmationFooter;
