import AWS from 'aws-sdk';

// Configure the AWS SDK
AWS.config.update({
  credentials: {
    accessKeyId: 'AKIAQBSFXYZ4V6RGBYK2',
    secretAccessKey: 'YEVbdTkMULkDCqGlaumkKULiaAO/hjPr/SXHFpL0',
  },
  region: 'ap-south-1', // Replace with your desired region
});

// eslint-disable-next-line import/prefer-default-export
export const s3Client = new AWS.S3();
// export const awsRekognitionClient = new AWS.Rekognition();
