import React from 'react';
import { useTheme } from 'styled-components/native';
import { StatusBar } from 'expo-status-bar';
import { getTheme, radii } from '../../../themes/new/theme';
import { View } from '../../../components/new';
import { AppTheme } from '../../../stores/commonStore';
import CachedImageBackground from '../../../components/new/custom/CachedImageBackground';

type BonusOverviewBGProps = {
  borderRadius?: number;
  children: JSX.Element;
  showGreenBg?: boolean;
};

const TransactionBg = ({
  borderRadius = radii.lg + radii.sm,
  children = <></>,
  showGreenBg = false,
}: BonusOverviewBGProps) => {
  const theme = useTheme();
  const StatusBarStyle =
    theme?.currentThemeMode === AppTheme.LIGHT ? AppTheme.DARK : AppTheme.LIGHT;
  const imageSource = showGreenBg
    ? theme?.currentThemeMode === AppTheme.LIGHT
      ? {
          uri: 'https://ik.imagekit.io/32ndhub/assets/background-blur-green-1.webp',
        }
      : { uri: 'https://ik.imagekit.io/32ndhub/assets/bg_ontime_blur.png' }
    : require('../../../../assets/images/black_membership/mesh-bg-h.png');

  const styleObj = showGreenBg
    ? {}
    : {
        borderBottomWidth: getTheme().borderWidths.xs,
        borderBottomColor: getTheme(theme?.currentThemeMode).colors.gold[500],
      };
  return (
    <>
      <StatusBar style={StatusBarStyle} />
      <View
        bg="primary.500"
        borderBottomLeftRadius={borderRadius}
        borderBottomRightRadius={borderRadius}
      >
        <CachedImageBackground
          source={imageSource}
          style={{
            overflow: 'hidden',
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            ...styleObj,
          }}
        >
          {children}
        </CachedImageBackground>
      </View>
    </>
  );
};

export default TransactionBg;
