import React from 'react';
import { Card, Text, Toggle, View } from '../../../components/new';
import PaymentsImage from '../../../../assets/images/payments.webp';
import CachedImage from '../../../components/new/custom/CachedImage';

const PayWithPointsComp = ({
  payWithPointsState = false,
  setPayWithPointsState = (value: boolean) => {},
  rewardsAmountCanUsed = 0,
}) => {
  //   if (!rewardsAmountCanUsed) {
  //     return null;
  //   }
  return (
    <Card withBorder>
      <View flexDirection="row" alignItems="center">
        <View>
          <CachedImage
            source={PaymentsImage}
            style={{ height: 24, width: 24 }}
          />
        </View>
        <View ml="lg">
          <Text size="md" color="primary.400" weight="medium">
            Pay With Points
          </Text>
        </View>
        <View alignItems="flex-end" flex={1}>
          <View flexDirection="row" alignItems="center">
            <View mr="lg">
              <Text
                size="sm"
                weight="medium"
                color={payWithPointsState ? 'success.500' : 'primary.400'}
              >
                {`${
                  payWithPointsState ? 'Saved' : 'Save'
                } ₹${rewardsAmountCanUsed}`}
              </Text>
            </View>
            <View>
              <Toggle
                isOn={payWithPointsState}
                onToggle={() => {
                  setPayWithPointsState(!payWithPointsState);
                }}
              />
            </View>
          </View>
        </View>
      </View>
    </Card>
  );
};

export default PayWithPointsComp;
