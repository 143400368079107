/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, View, Text } from '../../components/new';
import { getHoursAndMinutesAgoFromSeconds } from '../../utilities/helper';
import TaskCard from '../../components/new/primitive/TaskCard/TaskCard';
import usePartnerTransactionStore from '../../stores/partner/partnerTransactionStore';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { Appearance, FontWeights, Status } from '../../themes/new/helper';
import { handlePhone } from '../../utilities/handleLinking';

const RenderItem = ({
  item,
  // valetWorkLog,
  refresh,
}) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const navigation = useNavigation<any>();
  const { openedCard, setOpenedCard } = usePartnerTransactionStore(
    (state) => state,
  );

  const heading = item?.restaurant?.displayName;
  // ------ finding arrived time from arrived status ------
  const createdAt = new Date(item?.created_at).valueOf();

  // ------ finding current time ------
  const currentTime = new Date().valueOf();

  // ------ finding difference between arrived status time and current time ------
  const difference = currentTime - createdAt;
  const subHeading = getHoursAndMinutesAgoFromSeconds(difference / 1000);
  const timeDiff = difference / (1000 * 60 * 60 * 24);
  const subHeadingColor =
    timeDiff >= 1
      ? timeDiff < 1.5
        ? 'warning.500'
        : 'error.500'
      : 'primary.100';

  const topRightCornerComponent = () => (
    <Text size="sm" weight={FontWeights.MEDIUM}>
      ₹{item?.userInputs?.priceDetails?.netAmount}
    </Text>
  );

  const bottomLeftCTAComponent = () => {
    return (
      <Button
        size="md"
        appearance={Appearance.OUTLINE}
        status={Status.PRIMARY}
        shadow="sm"
        onPress={() => {
          firebaseEventLogger('BillReviewerHomeScreen__callCustomer_Tap', {
            _id: item?._id,
            restaurantName: heading,
          });
          const { prefix, number } = item?.user?.contact?.phone ?? {};
          handlePhone(prefix + number);
        }}
      >
        Call Customer
      </Button>
    );
  };

  const bottomRightCTAComponent = () => {
    return (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        shadow="sm"
        onPress={() => {
          firebaseEventLogger('BillReviewerHomeScreen__viewBillDetails_Tap', {
            _id: item?._id,
            restaurantName: heading,
          });
          navigation.navigate('ViewBillScreenWrapper', {
            restaurantName: heading,
            itemId: item?._id,
            bucket: item?.userInputs?.billImage?.bucket,
            key: item?.userInputs?.billImage?.key,
          });
        }}
      >
        View Bill Details
      </Button>
    );
  };

  return (
    <>
      <View px="2xl" my="lg">
        <TaskCard
          heading={heading}
          subHeading={subHeading}
          subHeadingColor={subHeadingColor}
          topRightCorner={topRightCornerComponent()}
          bottomLeftCTA={bottomLeftCTAComponent()}
          bottomRightCTA={bottomRightCTAComponent()}
          swipeEnabled={false}
          shadow="md"
          blur={item?.blur}
          preExpanded={openedCard === item?._id}
          onCardPressCB={() => {
            if (openedCard !== item?._id) {
              setOpenedCard({ id: item?._id });
            } else {
              setOpenedCard({ id: '' });
            }
          }}
        />
      </View>
    </>
  );
};

export default RenderItem;
