/**
 * @generated SignedSource<<612b1833369b47a2723e8e0cac8a212f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumTicketModelTCwoUserCanBeClaimedByRefType = "CAMPUS_LOCATION" | "RESTAURANT" | "%future added value";
export type EnumTicketModelTCwoUserIssuedByRefType = "CAMPUS_LOCATION" | "EVENT" | "POPUP_STORE" | "RESTAURANT" | "%future added value";
export type EnumticketDisplayType = "COUPON" | "QR" | "%future added value";
export type EnumticketStatus = "ACTIVE" | "CANCELLED" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumticketTicketType = "EVENT_TICKET" | "PRODUCT_TICKET" | "REWARD_TICKET" | "%future added value";
export type FilterFindManyticketInput = {
  AND?: ReadonlyArray<FilterFindManyticketInput> | null;
  OR?: ReadonlyArray<FilterFindManyticketInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyticketOperatorsInput | null;
  additionalFields?: FilterFindManyTicketModelTCwoUserAdditionalFieldsInput | null;
  canBeClaimedBy?: ReadonlyArray<FilterFindManyTicketModelTCwoUserCanBeClaimedByInput | null> | null;
  couponId?: any | null;
  coverImg?: string | null;
  displayType?: EnumticketDisplayType | null;
  expires_on?: any | null;
  finePrint?: FilterFindManyTicketModelTCwoUserFinePrintInput | null;
  invoiceId?: any | null;
  isPrimary?: boolean | null;
  issuedBy?: FilterFindManyTicketModelTCwoUserIssuedByInput | null;
  popUpImg?: string | null;
  rewardId?: any | null;
  status?: EnumticketStatus | null;
  thumbnailImg?: string | null;
  ticketType?: EnumticketTicketType | null;
  title?: string | null;
  uId?: number | null;
  updated_at?: any | null;
};
export type FilterFindManyTicketModelTCwoUserIssuedByInput = {
  _id?: any | null;
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserIssuedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserCanBeClaimedByInput = {
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserCanBeClaimedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsInput = {
  _id?: any | null;
  event?: FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput = {
  _id?: any | null;
  endTime?: any | null;
  invoicePdfPath?: string | null;
  spaces?: number | null;
  startTime?: any | null;
};
export type FilterFindManyTicketModelTCwoUserFinePrintInput = {
  _id?: any | null;
  details?: ReadonlyArray<string | null> | null;
  summary?: string | null;
};
export type FilterFindManyticketOperatorsInput = {
  _id?: FilterFindManyticket_idOperatorsInput | null;
};
export type FilterFindManyticket_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type MyBookingScreenQuery$variables = {
  count: number;
  filter: FilterFindManyticketInput;
};
export type MyBookingScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MyBookingScreenTicketQueryFragment">;
};
export type MyBookingScreenQuery = {
  response: MyBookingScreenQuery$data;
  variables: MyBookingScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyBookingScreenQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MyBookingScreenTicketQueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyBookingScreenQuery",
    "selections": [
      {
        "alias": "tickets",
        "args": (v3/*: any*/),
        "concreteType": "ticketConnection",
        "kind": "LinkedField",
        "name": "findTickets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ticketEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ticket",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ticketType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coverImg",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnailImg",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expires_on",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "invoiceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserIssuedBy",
                    "kind": "LinkedField",
                    "name": "issuedBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "refType",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserAdditionalFields",
                    "kind": "LinkedField",
                    "name": "additionalFields",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketModelTCwoUserAdditionalFieldsEvent",
                        "kind": "LinkedField",
                        "name": "event",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserCanBeClaimedBy",
                    "kind": "LinkedField",
                    "name": "canBeClaimedBy",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketModelTCwoUserCanBeClaimedByVenue",
                        "kind": "LinkedField",
                        "name": "venue",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TicketModelTCwoUserCanBeClaimedByVenueGeo",
                            "kind": "LinkedField",
                            "name": "geo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lat",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "long",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addressLine1",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rewardId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "tickets",
        "args": (v3/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "ticketQueryFragment__tickets",
        "kind": "LinkedHandle",
        "name": "findTickets"
      }
    ]
  },
  "params": {
    "cacheID": "fd9ae0a734e655910445487b2e640e80",
    "id": null,
    "metadata": {},
    "name": "MyBookingScreenQuery",
    "operationKind": "query",
    "text": "query MyBookingScreenQuery(\n  $filter: FilterFindManyticketInput!\n  $count: Int!\n) {\n  ...MyBookingScreenTicketQueryFragment_26eJ3g\n}\n\nfragment MyBookingScreenTicketQueryFragment_26eJ3g on Query {\n  tickets: findTickets(filter: $filter, first: $count) {\n    edges {\n      node {\n        _id\n        ticketType\n        coverImg\n        thumbnailImg\n        uId\n        expires_on\n        invoiceId\n        title\n        issuedBy {\n          refType\n          _id\n        }\n        status\n        additionalFields {\n          event {\n            startTime\n            endTime\n          }\n        }\n        canBeClaimedBy {\n          venue {\n            geo {\n              lat\n              long\n            }\n            addressLine1\n          }\n        }\n        rewardId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2bb059bef3e9fc2ddef053d3afe6e8b5";

export default node;
