/**
 * @generated SignedSource<<3390086156da00d0f172c96d253ae706>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type searchValetFilterInput = {
  role?: FilterFindManyUserRoleInput | null;
  search?: searchValetFilterInputSearch | null;
};
export type FilterFindManyUserRoleInput = {
  meta?: FilterFindManyUserRoleMetaInput | null;
  name?: EnumUserRoleName | null;
};
export type FilterFindManyUserRoleMetaInput = {
  campus?: FilterFindManyUserRoleMetaCampusInput | null;
};
export type FilterFindManyUserRoleMetaCampusInput = {
  _id?: any | null;
  name?: string | null;
};
export type searchValetFilterInputSearch = {
  nameOrPhoneOrCode?: string | null;
};
export type assignTaskQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  searchFilter?: searchValetFilterInput | null;
  taskFilter: findTaskFilterInput;
};
export type assignTaskQuery$data = {
  readonly findTasks: ReadonlyArray<{
    readonly _id: any;
    readonly assignedTo: any | null;
    readonly campus: {
      readonly _id: any;
      readonly name: string;
    } | null;
    readonly history: ReadonlyArray<{
      readonly _id: any | null;
      readonly action: EnumtaskHistoryAction;
      readonly actor: any | null;
      readonly event_at: any | null;
    } | null>;
    readonly ownerId: any | null;
    readonly parkingETA: {
      readonly parkingTimePrediction: ReadonlyArray<{
        readonly acceptedToCompleteTime: any | null;
        readonly locationId: any | null;
      } | null> | null;
    } | null;
    readonly parkingId: any | null;
    readonly parkingTag: string | null;
    readonly taskType: EnumtaskTaskType;
    readonly user: {
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
    readonly valet: {
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly identityCode: string | null;
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
    readonly vehicle: {
      readonly brand: {
        readonly brandLogo: string | null;
        readonly name: string | null;
      } | null;
      readonly color: string | null;
      readonly registration: {
        readonly plate: string;
      } | null;
    } | null;
    readonly vehicleId: any | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"assignTaskQueryFragment">;
};
export type assignTaskQuery = {
  response: assignTaskQuery$data;
  variables: assignTaskQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchFilter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskFilter"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identityCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserContactPhone",
      "kind": "LinkedField",
      "name": "phone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filter",
      "variableName": "taskFilter"
    }
  ],
  "concreteType": "task",
  "kind": "LinkedField",
  "name": "findTasks",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "taskVehicle",
      "kind": "LinkedField",
      "name": "vehicle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "taskVehicleBrand",
          "kind": "LinkedField",
          "name": "brand",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "brandLogo",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "taskVehicleRegistration",
          "kind": "LinkedField",
          "name": "registration",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "campusLocation",
      "kind": "LinkedField",
      "name": "campus",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "taskHistory",
      "kind": "LinkedField",
      "name": "history",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "event_at",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignedTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "valet",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vehicleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parkingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parkingTag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "taskParkingETA",
      "kind": "LinkedField",
      "name": "parkingETA",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "taskParkingETAParkingTimePrediction",
          "kind": "LinkedField",
          "name": "parkingTimePrediction",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acceptedToCompleteTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "searchFilter"
},
v11 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v10/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "assignTaskQuery",
    "selections": [
      (v9/*: any*/),
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v10/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "assignTaskQueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "assignTaskQuery",
    "selections": [
      (v9/*: any*/),
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "SearchValetConnection",
        "kind": "LinkedField",
        "name": "searchValetV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchValetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchValet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserRole",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "workLogStatus",
                    "kind": "LinkedField",
                    "name": "workLogStatus",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "time",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taskCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "assignTaskQueryFragment_searchValetV2",
        "kind": "LinkedHandle",
        "name": "searchValetV2"
      }
    ]
  },
  "params": {
    "cacheID": "e3e8ababe8d96823f636ad4ab22b8846",
    "id": null,
    "metadata": {},
    "name": "assignTaskQuery",
    "operationKind": "query",
    "text": "query assignTaskQuery(\n  $taskFilter: findTaskFilterInput!\n  $searchFilter: searchValetFilterInput\n  $count: Int\n  $cursor: String\n) {\n  findTasks(filter: $taskFilter) {\n    _id\n    vehicle {\n      brand {\n        name\n        brandLogo\n      }\n      registration {\n        plate\n      }\n      color\n    }\n    taskType\n    campus {\n      _id\n      name\n    }\n    history {\n      _id\n      action\n      actor\n      event_at\n    }\n    assignedTo\n    valet {\n      name {\n        first\n        last\n      }\n      identityCode\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n    user {\n      name {\n        first\n        last\n      }\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n    vehicleId\n    ownerId\n    parkingId\n    parkingTag\n    parkingETA {\n      parkingTimePrediction {\n        locationId\n        acceptedToCompleteTime\n      }\n    }\n  }\n  ...assignTaskQueryFragment_4js7j0\n}\n\nfragment assignTaskQueryFragment_4js7j0 on Query {\n  searchValetV2(filter: $searchFilter, first: $count, after: $cursor) {\n    count\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      endCursor\n    }\n    edges {\n      node {\n        _id\n        name {\n          first\n          last\n        }\n        role {\n          name\n        }\n        contact {\n          phone {\n            prefix\n            number\n          }\n        }\n        identityCode\n        workLogStatus {\n          status\n          time\n          lastStatus\n          taskCount\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ec4fb397c6e8042e7d366fe85955d26";

export default node;
