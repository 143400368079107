/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRevenueOverviewScreen } from './useRevenueOverviewScreen';
import { IconButton, Text, View } from '../../../../components/new';
import TransactionSummaryBanner from '../../../../components/new/custom/transaction/TransactionSummaryBanner';
import TransactionList from '../../components/TransactionList';
import RevenueDetailsBottomSheet from './RevenueDetailsBottomSheet';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import { dateUtils } from '../../../../utilities/helper';

interface RevenueOverviewScreenInnerProps {
  queryOptions: NewErrorBoundaryParentState;
}

const RevenueOverviewScreenInner = ({
  queryOptions,
}: RevenueOverviewScreenInnerProps) => {
  const {
    bsRef,
    bsMode,
    revenueReport,
    transactions,
    clickedTransaction,
    handleBack,
    fetchMoreTransactions,
    showRevenuePanelBS,
    showTransactionDetailsBS,
    handleBSDismiss,
  } = useRevenueOverviewScreen(queryOptions);

  const label = dateUtils.isToday(revenueReport?.date)
    ? 'Today'
    : dateUtils.isYesterday(revenueReport?.date)
    ? 'Yesterday'
    : revenueReport?.formated?.date ?? '';

  return (
    <View flex={1}>
      {revenueReport && (
        <>
          <TransactionSummaryBanner
            onBack={handleBack}
            amount={revenueReport.formated.revenueAmount}
            status="SUCCESSFUL"
            BottomContent={
              <View flexDirection="row" alignItems="center">
                <Text size="sm" weight="regular" color="primary.10">
                  Total Revenue ({label})
                </Text>
                <IconButton
                  onPress={showRevenuePanelBS}
                  appearance="ghost"
                  iconColor="primary.10"
                  name="info-outline-300"
                  size="md"
                  iconSize="xl"
                />
              </View>
            }
          />
          <View flex={1}>
            <Text
              px="2xl"
              mt="2xl"
              size="md"
              weight="medium"
              color="primary.400"
            >
              Total Revenue ({label})
            </Text>
            <TransactionList
              onScrollEnd={fetchMoreTransactions}
              data={transactions.map((txn) => ({
                orderId: txn.orderId,
                date: txn.formated.time,
                amount: txn.formated.billAmount,
                paymentStatus: txn.paymentStatus,
              }))}
              onItemPress={(txn) => showTransactionDetailsBS(txn.orderId)}
            />
          </View>
          <RevenueDetailsBottomSheet
            ref={bsRef}
            bsMode={bsMode}
            onDismiss={handleBSDismiss}
            transaction={clickedTransaction}
            revenueReport={revenueReport}
          />
        </>
      )}
    </View>
  );
};

export default RevenueOverviewScreenInner;
