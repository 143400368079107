import React, { Suspense, useCallback, useRef, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { debounce } from 'lodash';
import useSearchParkingScreenData from './hooks/useSearchParkingScreenData';
import useCommonStore from '../../../../stores/commonStore';
import {
  Divider,
  Icon,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import useUserStore from '../../../../stores/userStore';
import { formatPlateNo, navigateBack } from '../../../../utilities/helper';
import SearchInput from '../../../../components/new/composites/SearchInput/SearchInput';
import baseTheme from '../../../../themes/new/theme';
// import AutoBottomSheet from './AutoBottomSheet';
import VehicleListItem from '../../../../components/new/custom/VehicleListItem';
import { giveTags } from '../../../new/trackMyVehicle/helper/helper';
import VehicleStatusTag from '../../../../components/new/ui/VehicleStatusTags/VehicleStatusTag';
import SearchParkingScreenPlaceholder from './SearchParkingScreenPlaceholder';
import AppConstants from '../../../../utilities/AppConstants';
// import VehicleStatusTag from '../../../../components/new/ui/VehicleStatusTags/VehicleStatusTag';

const getRequiredInfo = (i) => {
  const tempItem = giveTags([i])?.[0];
  const item = i?.tasks?.slice(-1)?.[0] || [];

  const parkingTag = i?.parkingTag;
  const plateNo = formatPlateNo(item?.vehicle?.registration?.plate || '');
  const title = `${plateNo} ${parkingTag ? `(#${parkingTag})` : ''}`;
  const profileImage = item?.user?.profileImage;
  const { IMAGEKIT_PROD_URI } = AppConstants;
  const modifiedImageURI = profileImage
    ? IMAGEKIT_PROD_URI + profileImage
    : 'https://ik.imagekit.io/32ndhub/assets/nouser.png';

  const phoneNumberPrefix = item?.user?.contact?.phone?.prefix || '';
  const phoneNumber = item?.user?.contact?.phone?.number || '';
  const fullPhoneNumber = `${phoneNumberPrefix}-${phoneNumber}`;
  const subText = fullPhoneNumber || ' ';
  const id = item?._id;

  return {
    subText,
    heading: title,
    imageLink: modifiedImageURI,
    statusTag: tempItem?.taskTagFE,
    id,
  };
};

const SearchParkingScreen = ({ refreshedQueryOptions, variables }) => {
  const navigation = useNavigation();

  const { apiData } = useSearchParkingScreenData({
    refreshedQueryOptions,
    variables,
  });

  return (
    <View flex={1}>
      <View flex={1}>
        <FlashList
          keyboardShouldPersistTaps="handled"
          data={apiData?.searchParking || []}
          renderItem={({ item, index }) => {
            const { heading, subText, imageLink, statusTag, id } =
              getRequiredInfo(item);

            return (
              <View mb="2xl" mx="2xl">
                <View pb="lg">
                  <VehicleListItem
                    // allowSwipeAction={allowDeletion}
                    id={id}
                    imageLink={imageLink}
                    heading={heading}
                    subText={subText}
                    contentRight={<VehicleStatusTag status={statusTag} />}
                    onPress={() => {
                      navigation.navigate('taskJourney', { taskId: id });
                    }}
                    // status={status}
                    // refresh={refreshAPI}
                  />
                </View>
                <Divider />
              </View>
            );
          }}
          estimatedItemSize={76}
          drawDistance={500}
          contentContainerStyle={{
            paddingBottom: baseTheme.space['4xl'],
            paddingTop: baseTheme.space['4xl'],
          }}
          ListEmptyComponent={() => (
            <View
              flex={1}
              alignItems="center"
              justifyContent="center"
              mt="9xl+9xl+9xl"
            >
              <View>
                <Icon
                  name="search-outline-400"
                  color="primary.100"
                  size="9xl"
                />
              </View>
              <Text color="primary.100" size="xl" weight="medium">
                {`No Result for "${variables?.filter?.search?.vehicleOrPhoneOrTag}"`}
              </Text>
            </View>
          )}
        />
      </View>
    </View>
  );
};

const variableMaker = ({ campusId, value }) => {
  const tempVariable = {
    filter: {
      campus: { _id: campusId },
      search: {
        vehicleOrPhoneOrTag: value,
      },
    },
  };

  return tempVariable;
};

const SearchParkingScreenWrapper = ({ navigation, route }) => {
  const { searchText } = route?.params ?? {};
  const inputRef = useRef();
  const campusId = useCommonStore((state) => state.campusId);
  const { id: userId, role: userRole } = useUserStore((state) => state);
  const [inputTouched, setInputTouched] = useState(false);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
      networkCacheConfig: { force: true },
    });

  const [value, setValue] = useState(searchText || '');

  const [variable, setVariable] = useState(variableMaker({ campusId, value }));

  const debouncedVariableMaker = useCallback(
    debounce((v) => {
      if (v?.length && v.length >= 4) {
        setVariable(variableMaker({ campusId, value: v }));
        refreshAPI();
      }
    }, 1000),
    [],
  );

  const combineValueSetter = (v) => {
    setValue(v);
    debouncedVariableMaker(v);
  };

  const refreshAPI = () => {
    setRefreshedQueryOptions((prev) => {
      return {
        ...prev,
        fetchKey: (prev?.fetchKey || 0) + 1,
      };
    });
  };

  useFocusEffect(
    useCallback(() => {
      inputRef?.current?.focus();
    }, []),
  );

  return (
    <Layout level={2}>
      <TopNavigation
        title="Search Car"
        textSize="md"
        appearance="ghost"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            onPress={() => navigateBack(navigation, userRole)}
          />
        }
        ChildrenElement={
          <View pt="xs" pb="2xl" mx="2xl">
            <SearchInput
              inputRef={inputRef}
              autoFocus
              errorHelperText={
                (value?.length >= 1 && value.length < 4) ||
                (inputTouched && value?.length < 4)
                  ? 'Input at least 4 characters'
                  : ''
              }
              leftIconName=""
              placeholder="Search by TagID/Number/VRN"
              value={value}
              onChangeText={(v) => {
                if (!inputTouched) {
                  setInputTouched(true);
                }
                combineValueSetter(v);
              }}
              onRightIconClick={() => {
                combineValueSetter('');
              }}
            />
          </View>
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions?.fetchKey}
        fallback={<View />}
      >
        <Suspense fallback={<SearchParkingScreenPlaceholder />}>
          <View flex={1}>
            <SearchParkingScreen
              refreshAPI={refreshAPI}
              refreshedQueryOptions={refreshedQueryOptions}
              variables={variable}
            />
          </View>
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default SearchParkingScreenWrapper;
