import React, { forwardRef } from 'react';
import TransactionDetailsPanel from '../../components/TransactionDetailsPanel';
import { ITransaction, TransactionsRevenueReport } from '../../types';
import { Text, View } from '../../../../components/new';
import TransactionsReportPanel from '../../components/TransactionsReportPanel';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';

export enum BSMode {
  DISMISSED = 'DISMISSED',
  REVENUE_REPORT = 'REVENUE_REPORT',
  TRANSACTION = 'TRANSACTION',
}

interface RevenueDetailsBottomSheetProps {
  onDismiss: () => void;
  bsMode: BSMode;
  revenueReport: TransactionsRevenueReport;
  transaction: ITransaction | null;
}

const RevenueDetailsBottomSheet = forwardRef(
  (props: RevenueDetailsBottomSheetProps, ref) => {
    const { transaction, revenueReport, bsMode, onDismiss } = props;

    return (
      <BottomSheetV2 ref={ref} onClose={onDismiss} insets={false}>
        <View>
          {bsMode !== BSMode.DISMISSED && (
            <View px="2xl" pt="2xl">
              {bsMode === BSMode.REVENUE_REPORT &&
                renderRevenueSummaryPanel(revenueReport)}
              {bsMode === BSMode.TRANSACTION &&
                renderTransactionDetailsPanel(transaction)}
            </View>
          )}
        </View>
      </BottomSheetV2>
    );
  },
);

const renderTransactionDetailsPanel = (txn: ITransaction | null) =>
  txn ? <TransactionDetailsPanel transaction={txn} mode="REVENUE" /> : <></>;

const renderRevenueSummaryPanel = (revenueReport: TransactionsRevenueReport) =>
  revenueReport ? (
    <View>
      <Text mb="2xl" size="md" weight="regular" color="singletone.black">
        How is collected amount calculated ?
      </Text>
      <TransactionsReportPanel
        mode="REVENUE"
        report={{
          revenueAmount: revenueReport.formated.revenueAmount,
          collectedAmount: revenueReport.formated.collectedAmount,
          discountAmount: revenueReport.formated.discountAmount,
          points: revenueReport.formated.redeemedLoyaltyPoints,
        }}
      />
    </View>
  ) : (
    <></>
  );

export default RevenueDetailsBottomSheet;
