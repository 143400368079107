import React, { useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import {
  IconButton,
  Modal,
  TopNavigation,
  View,
} from '../../../components/new';
import { navigateBack } from '../../../utilities/helper';

import ConfirmationCard from '../../../components/new/custom/ConfirmationCard';
import SurveyComp from './SurveyComp';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import LightThemeWrapper from '../wrapperComp/LightThemeWrapper';
import surveySubscriptionAPI from './API/surveySubscription';
import SurveyResultComp from './SurveyResultComp';
import useUserStore from '../../../stores/userStore';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import FacebookPixelLogger from '../../../utilities/FacebookPixelLogger';

const SurveyScreen = ({ navigation, route }) => {
  const {
    phoneNumber,
    TYPEFORMID,
    SURVEYID,
    IsInvestment = false,
  } = route.params;
  const { role: userRole, id: userId } = useUserStore((state) => state);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  let cancelSubscriptionUpdateData = { dispose: () => {} };

  const cancelUpdateTaskSubscription = (disposable) => {
    cancelSubscriptionUpdateData = disposable;
  };

  const backHandler = () => {
    setShowConfirmation(true);
  };
  const showResult = surveySubmitted;
  const ThemeWrapper = showResult ? DarkThemeWrapper : LightThemeWrapper;

  useFocusEffect(
    React.useCallback(() => {
      surveySubscriptionAPI(
        { userId, formId: TYPEFORMID },
        (res) => {
          if (res?.surveySubmission?.form?.submittedAt) {
            if (IsInvestment) {
              firebaseEventLogger('PropertyDetails__interestRegistered_Data', {
                buttonName: 'interestRegistered',
                screenName: 'PropertyDetails',
                userType: 'user',
                interactionType: 'data',
              });
              navigation.replace('ThankYouScreen');
              FacebookPixelLogger('track', 'SubmitApplication');
              return;
            }
            setSurveySubmitted(true);
            navigateBack(navigation, userRole);
          }
        },
        cancelUpdateTaskSubscription,
      );
      return () => {
        cancelSubscriptionUpdateData.dispose();
      };
    }, []),
  );

  return (
    <ThemeWrapper>
      <View flex={1}>
        <TopNavigation
          level="1"
          appearance="ghost"
          title={
            showResult
              ? ''
              : IsInvestment
              ? 'Provide your details'
              : 'Take a survey'
          }
          IconLeft={
            <IconButton
              onPress={backHandler}
              appearance="ghost"
              name={showResult ? '' : 'back-outline-300'}
            />
          }
          IconRight={
            <IconButton
              onPress={backHandler}
              appearance="ghost"
              name={showResult ? 'close-outline-300' : ''}
            />
          }
        />
        {!showResult && (
          <SurveyComp
            phoneNumber={phoneNumber}
            TYPEFORMID={TYPEFORMID}
            SURVEYID={SURVEYID}
            IsInvestment={IsInvestment}
          />
        )}
        {showResult && (
          <DarkThemeWrapper>
            <SurveyResultComp
              navigation={navigation}
              rewardText="A free Parking at 32nd"
            />
          </DarkThemeWrapper>
        )}
        <Modal
          bg="blur"
          visible={showConfirmation}
          onBackdropPress={() => {
            setShowConfirmation(false);
          }}
          onRequestClose={() => {
            setShowConfirmation(false);
          }}
          showBottomCloseButton
          handleClose={() => setShowConfirmation(false)}
        >
          <ConfirmationCard
            heading={
              IsInvestment
                ? 'Complete the enquiry'
                : 'Your progress will be lost'
            }
            subHeading={
              IsInvestment
                ? `You're one step away from providing key details needed to Prioritize your inquiry.`
                : 'Your survey will start from the beginning if you go back, are you sure you want to continue?'
            }
            buttonOneText={IsInvestment ? 'Leave' : 'Leave Page'}
            buttonTwoText={IsInvestment ? 'Provide details' : 'Continue'}
            img={null}
            onButtonOnePress={() => {
              navigateBack(navigation, userRole);
            }}
            onButtonTwoPress={() => {
              setShowConfirmation(false);
            }}
          />
        </Modal>
      </View>
    </ThemeWrapper>
  );
};

export default SurveyScreen;
