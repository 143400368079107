import React from 'react';
import { Text, View } from '../..';
import TaskCard from '../../primitive/TaskCard/TaskCard';
import { PaymentStatusEnum } from '../../../../utilities/helper';
import TransactionStatus from './TransactionStatus';
import Badge, { BadgeProps } from '../../primitive/Badge/Badge';

interface TransactionListItemProps {
  onPress: () => void;
  orderId: string;
  time: string;
  amount: string;
  paymentStatus: keyof typeof PaymentStatusEnum;
}

const TransactionListItem = ({
  orderId,
  amount,
  paymentStatus,
  time,
  onPress,
}: TransactionListItemProps) => {
  const [text, clrStatus] =
    resolvePaymentStatusTextAndColorStatus(paymentStatus);

  return (
    <>
      {/* @ts-ignore */}
      <TaskCard
        // @ts-ignore
        heading={
          <View flexDirection="row" alignItems="center">
            <Text
              mr="lg"
              size="lg"
              weight="medium"
              color="primary.400"
            >{`#${orderId}`}</Text>
            <TransactionStatus text={text} status={paymentStatus} />
          </View>
        }
        subHeading={time}
        topRightCorner={
          <Badge status={clrStatus}>
            <Text size="sm" weight="medium" color="primary.400">
              {amount}
            </Text>
          </Badge>
        }
        swipeEnabled={false}
        shadow="md"
        onCardPressCB={() => onPress()}
      />
    </>
  );
};

export default TransactionListItem;

const resolvePaymentStatusTextAndColorStatus = (
  status: TransactionListItemProps['paymentStatus'],
): [string, BadgeProps['status']] => {
  switch (status) {
    case PaymentStatusEnum.SUCCESSFUL:
      return ['Paid', 'success'];
    case PaymentStatusEnum.FAILED:
      return ['Failed', 'error'];
    case PaymentStatusEnum.PENDING:
      return ['Pending', 'warning'];
    case PaymentStatusEnum.REFUNDED:
      return ['Refunded', 'default'];
    default:
      return ['', 'default'];
  }
};
