import React, { useMemo } from 'react';
import ParkingBillBreakUp from './ParkingBillBreakUp';
import EventBillBreakUp from './EventBillBreakUp';
import { paymentOrderTypeEnum } from '../../../../utilities/helper';

interface PaymentBreakUpProps {
  data: Record<string, any>;
  orderType: paymentOrderTypeEnum;
}

const resolveBreakUpComponent = (orderType: paymentOrderTypeEnum) => {
  switch (orderType) {
    case paymentOrderTypeEnum.PARKING:
      return ParkingBillBreakUp;
    case paymentOrderTypeEnum.EVENT:
      return EventBillBreakUp;
    default:
      return null;
  }
};

const PaymentBreakUp = ({ data, orderType }: PaymentBreakUpProps) => {
  const BreakUpComponent = useMemo(
    () => resolveBreakUpComponent(orderType),
    [orderType],
  );

  if (!BreakUpComponent) return null;

  return <BreakUpComponent data={data} />;
};

export default PaymentBreakUp;
