/**
 * @generated SignedSource<<ae899a0c5b5a204a0b5f185e93197604>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumStaffRewardCategory = "CONTROLLER_PARKING_ASSIGNMENT" | "CONTROLLER_PARKING_COMPLETION" | "CONTROLLER_PARKING_ONBOARDING" | "CONTROLLER_RECALL_ARRIVAL" | "CONTROLLER_RECALL_ASSIGNMENT" | "CONTROLLER_REPARKING_COMPLETION" | "VALET_PARK" | "VALET_RECALL" | "VALET_REPARK" | "%future added value";
export type EnumStaffRewardStatus = "CANCELLED" | "REDEEMED" | "REWARDED" | "%future added value";
export type EnumStaffRewardTaskTaskStatus = "DELAYED" | "ON_TIME" | "%future added value";
export type EnumStaffRewardTaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findStaffRewardsFilterInput = {
  category?: EnumStaffRewardCategory | null;
  status?: EnumStaffRewardStatus | null;
  task?: FilterFindManyStaffRewardTaskInput | null;
};
export type FilterFindManyStaffRewardTaskInput = {
  taskId?: any | null;
  taskStatus?: EnumStaffRewardTaskTaskStatus | null;
  taskType?: EnumStaffRewardTaskTaskType | null;
};
export type endTaskScreenQuery$variables = {
  filter?: findStaffRewardsFilterInput | null;
};
export type endTaskScreenQuery$data = {
  readonly findStaffRewards: ReadonlyArray<{
    readonly _id: any;
    readonly task: {
      readonly taskId: any;
      readonly taskStatus: EnumStaffRewardTaskTaskStatus | null;
    } | null;
    readonly value: number;
  }>;
};
export type endTaskScreenQuery = {
  response: endTaskScreenQuery$data;
  variables: endTaskScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "StaffReward",
    "kind": "LinkedField",
    "name": "findStaffRewards",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StaffRewardTask",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "endTaskScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "endTaskScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8dc7b1ce15a7bec38860324862ebad1e",
    "id": null,
    "metadata": {},
    "name": "endTaskScreenQuery",
    "operationKind": "query",
    "text": "query endTaskScreenQuery(\n  $filter: findStaffRewardsFilterInput\n) {\n  findStaffRewards(filter: $filter) {\n    _id\n    value\n    task {\n      taskId\n      taskStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7abf3febfd362bb4fa2669c968cf0bd9";

export default node;
