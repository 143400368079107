import React from 'react';
import { Text, View } from '../../../../components/new';
import { toCurrency } from '../../../../utilities/Utility';

const RoiDetails = ({ data }) => {
  return (
    <View bg={data?.bgColor}>
      <View my="2xl" px="2xl">
        <Text size="md" color="primary.300">
          Returns
        </Text>
        <View
          mt="2xl"
          py="2xl"
          px="2xl"
          borderWidth={1}
          borderRadius="lg"
          borderColor="grey.50"
          width="100%"
          bg="background.primary.elevation"
        >
          <View flexDirection="row" justifyContent="space-between" pb="2xl">
            <Text size="sm" color="primary.400">
              Rental yield per annum
            </Text>
            <Text size="sm" color="primary.400">
              {data?.investment?.roi?.rentalYieldPostOps}%
            </Text>
          </View>
          <View flexDirection="row" justifyContent="space-between" pb="2xl">
            <Text size="sm" color="primary.400">
              {data?.investment?.assuredBuyBack
                ? 'Assured buyback'
                : 'Estimated capital appreciation'}
            </Text>
            <Text size="sm" color="primary.400">
              {data?.investment?.assuredBuyBack
                ? `₹${toCurrency(
                    data?.investment?.assuredBuyBack *
                      data?.investment?.units?.perUnitAmount,
                  )}`
                : `${data?.investment?.roi?.capitalAppriciation}%`}
            </Text>
          </View>
          <View flexDirection="row" justifyContent="space-between">
            <Text size="sm" color="primary.400">
              Rental yield per Pre operations
            </Text>
            <Text size="sm" color="primary.400">
              {data?.investment?.roi?.rentalYieldPreOps}%
            </Text>
          </View>
        </View>
        <Text size="md" color="primary.300" mt="4xl">
          Returns breakup
        </Text>
        <View
          mt="2xl"
          py="2xl"
          px="2xl"
          borderWidth={1}
          borderRadius="lg"
          borderColor="grey.50"
          width="100%"
          bg="background.primary.elevation"
        >
          <View flexDirection="row" justifyContent="space-between" pb="2xl">
            <Text size="sm" color="primary.400">
              Rental income
            </Text>
            <Text size="sm" color="primary.400">
              ₹{toCurrency(data?.rentalIncome)}
            </Text>
          </View>
          <View flexDirection="row" justifyContent="space-between" pb="2xl">
            <Text size="sm" color="primary.400">
              Sale price
            </Text>
            <Text size="sm" color="primary.400">
              ₹{toCurrency(data?.salePrice)}
            </Text>
          </View>
          <View flexDirection="row" justifyContent="space-between" pb="2xl">
            <Text size="sm" color="primary.400">
              Total return
            </Text>
            <Text size="sm" color="primary.400">
              ₹{toCurrency(data?.totalReturn)}
            </Text>
          </View>
          <View flexDirection="row" justifyContent="space-between" pb="2xl">
            <Text size="sm" color="primary.400">
              Total Invested
            </Text>
            <Text size="sm" color="primary.400">
              -₹{toCurrency(data?.totalInvested)}
            </Text>
          </View>
          <View backgroundColor="grey.50" height={1} width="100%" />
          <View flexDirection="row" justifyContent="space-between" pt="lg">
            <Text size="sm" color="success.500" weight="medium">
              Total Profit
            </Text>
            <Text size="sm" color="success.500" weight="medium">
              ₹{toCurrency(data?.totalProfit)}
            </Text>
          </View>
        </View>
        <Text size="md" color="primary.300" mt="4xl">
          Portfolio performance
        </Text>
        <View
          mt="2xl"
          py="2xl"
          px="2xl"
          borderWidth={1}
          borderRadius="lg"
          borderColor="grey.50"
          width="100%"
          bg="background.primary.elevation"
        >
          <View flexDirection="row" justifyContent="space-between" pb="2xl">
            <Text size="sm" color="primary.400">
              Target IRR
            </Text>
            <Text size="sm" color="primary.400">
              {data?.targetIRR}%
            </Text>
          </View>
          <View flexDirection="row" justifyContent="space-between">
            <Text size="sm" color="primary.400">
              Investment multiplier
            </Text>
            <Text size="sm" color="primary.400">
              {data?.InvMultiplier}x
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default RoiDetails;
