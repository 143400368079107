import React, { memo, useCallback } from 'react';
import { FlashList } from '@shopify/flash-list';
import { debounce } from 'lodash';
import { useWindowDimensions, Image, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Card, Heading, Icon, Text, View } from '../../../components/new';
import useBoundStore from '../../../stores';
import theme, { radii } from '../../../themes/new/theme';
import {
  capitalizeAfterSpace,
  foodType,
  imageTransformation,
} from '../../../utilities/helper';
import AddButton from './AddButton';
import useCommonStore from '../../../stores/commonStore';
import { FontWeights } from '../../../themes/new/helper';

const RenderItem = memo(
  ({
    item,
    setRemoveItem,
    setSelectedMenuItem,
    bottomSheetModalRef,
    setRepeatLast,
    width,
    // restaurantName,
    restaurantId,
    setVisible,
    restaurant,
  }: {
    item: any;
    setRemoveItem: any;
    setSelectedMenuItem: any;
    bottomSheetModalRef: any;
    setRepeatLast: any;
    width: number;
    restaurantId: number;
    setVisible: any;
    restaurant: any;
  }) => {
    const getIcons = (menuItem) => {
      const icons = [];

      if (menuItem?.type) {
        icons.push(
          <Icon
            size="xl"
            name={getIcon(menuItem.type)}
            color={getIconColor(menuItem.type)}
          />,
        );
      }
      if (menuItem.recomended) {
        icons.push(
          <Icon
            size="2xl"
            name={
              Platform.OS === 'ios'
                ? 'recommended-filled-300'
                : 'recommended-filled-300-Baseline'
            }
            color="info.500"
          />,
        );
      } else if (menuItem.bestSeller) {
        icons.push(
          <Icon
            size="2xl"
            name={
              Platform.OS === 'ios'
                ? 'popular-filled-300'
                : 'popular-filled-300-Baseline'
            }
            color="warning.500"
          />,
        );
      } else if (menuItem?.chefRecomended) {
        icons.push(
          <Icon
            size="2xl"
            name={
              Platform.OS === 'ios'
                ? 'chef-filled-300'
                : 'chef-filled-300-Baseline'
            }
            color="warning.500"
          />,
        );
      }
      return icons.slice(0, 3);
    };

    const screenOrientationValue = useCommonStore((state) => state.orientation);

    const getIcon = (name) => {
      switch (name) {
        case foodType.VEG:
          return 'pureveg-400';
        case foodType.NON_VEG:
          return 'chicken-400';
        case foodType.CONTAINS_EGG:
          return 'egg-400';
        case foodType.VEGAN:
          return 'vegan-filled-300';
        default:
          return 'pureveg-400';
      }
    };

    const getIconColor = (name) => {
      switch (name) {
        case foodType.VEG:
        case foodType.VEGAN:
          return 'success.500';
        case foodType.NON_VEG:
          return 'error.500';
        case foodType.CONTAINS_EGG:
          return 'warning.500';
        default:
          return 'primary.500';
      }
    };

    return (
      <View px="2xl">
        {/* render sectionTitle */}
        {!!item.sectionTitle && (
          <View mt="7xl">
            <View mb={item.description ? 'sm' : 'xl'}>
              <Heading
                size="2xl"
                weight={FontWeights.REGULAR}
                color="primary.300"
              >
                {capitalizeAfterSpace(item?.sectionTitle?.toLowerCase())}
              </Heading>
            </View>
            {item.description ? (
              <Text
                size="sm"
                color="primary.200"
                numberOfLines={3}
                ellipsizeMode="tail"
                mb="xl"
              >
                {item.description}
              </Text>
            ) : null}
          </View>
        )}
        {/* render Card */}
        {!item.sectionTitle && (
          <Card mt="lg" mb="2xl" shadow="lg" borderRadius={radii.lg}>
            {item.media &&
            item.media[0]?.mediaUrl &&
            item.media[0]?.type === 'image' ? (
              <View mb="lg">
                <Image
                  source={{
                    uri: imageTransformation(
                      item.media[0].mediaUrl,
                      width > 1200 && screenOrientationValue < 3
                        ? 375 - 4 * theme.space['2xl']
                        : width - 4 * theme.space['2xl'],
                      '5-3',
                    ),
                  }}
                  style={{
                    width: '100%',
                    aspectRatio: 5 / 3,
                    borderTopRightRadius: radii.md,
                    borderTopLeftRadius: radii.md,
                  }}
                  resizeMode="cover"
                />
              </View>
            ) : null}
            <View
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <View
                flexDirection="row"
                alignItems="center"
                flex={1}
                justifyContent="flex-start"
                // flexWrap="wrap"
              >
                {/* ==== TODO === */}
                <Text
                  size="xl"
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  color="primary.500"
                  weight="medium"
                >
                  {item.title.trim()} {getIcons(item)}
                </Text>
              </View>
            </View>
            <Text size="md" my="sm" color="primary.400">
              &#8377; {item.defaultPrice}
            </Text>
            <Text
              size="sm"
              color="primary.200"
              numberOfLines={3}
              ellipsizeMode="tail"
            >
              {item.description.summary.replace(/\r?\n|\r/, ' ')}
            </Text>
            <View
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="space-between"
              mt={item.description.summary ? 'xl' : 'sm'}
            >
              <View flexDirection="row" flex={3} flexWrap="wrap">
                {item.description.tags.slice(0, 3).map((tag, tagIndex) => (
                  <Text size="xs" category="tag" color="primary.200">
                    {tag}
                    {tagIndex < item.description.tags.length - 1 ? ', ' : ''}
                  </Text>
                ))}
              </View>
              <View flex={2} alignItems="flex-end">
                <AddButton
                  menuItem={item}
                  setSelectedMenuItem={setSelectedMenuItem}
                  ref={bottomSheetModalRef}
                  setRemoveItem={setRemoveItem}
                  isEdit={false}
                  setRepeatLast={setRepeatLast}
                  btnState={item.available}
                  // restaurantName={restaurantName}
                  restaurantId={restaurantId}
                  setVisible={setVisible}
                  restaurant={restaurant}
                />
              </View>
            </View>
          </Card>
        )}
      </View>
    );
  },
);

const areEqual = (prevProps, currentProps) => {
  if (prevProps.restaurantId !== currentProps.restaurantId) {
    return false;
  }
  return true;
};

const MenuListSection = memo(
  ({
    headersData,
    newMenuData,
    setRemoveItem,
    setSelectedIndex,
    bottomSheetModalRef,
    setSelectedMenuItem,
    flatlistRef,
    setRepeatLast,
    // restaurantName,
    restaurantId,
    setVisible,
    // restaurantposId,
    restaurant,
  }) => {
    const { width } = useWindowDimensions();
    const insets = useSafeAreaInsets();
    const cart = useBoundStore((state) => state.cart);

    const updateSelectedDebounce = debounce((i) => {
      setSelectedIndex(i);
    }, 700);

    const handleViewableItemsChanged = useCallback(
      ({ viewableItems, changed, ...other }) => {
        if (viewableItems[1]?.item.categoryId) {
          const id = viewableItems[1]?.item.categoryId;
          const index = headersData.findIndex((ele) => ele.categoryId === id);
          if (index >= 0) {
            updateSelectedDebounce(index);
          }
        }
      },
      [],
    );

    // const viewabilityConfig = {
    //   viewAreaCoveragePercentThreshold: 100,
    // };

    return (
      <>
        <View height={116 + insets.top} />
        <FlashList
          ref={flatlistRef}
          estimatedItemSize={400}
          renderItem={(props) => (
            <RenderItem
              {...props}
              setRemoveItem={setRemoveItem}
              bottomSheetModalRef={bottomSheetModalRef}
              setSelectedMenuItem={setSelectedMenuItem}
              setRepeatLast={setRepeatLast}
              width={width}
              // restaurantName={restaurantName}
              restaurantId={restaurantId}
              setVisible={setVisible}
              restaurant={restaurant}
            />
          )}
          data={newMenuData}
          keyExtractor={(item) => {
            return item._id;
          }}
          // getItemType={(item) => {
          //   return item.listType;
          // }}
          onViewableItemsChanged={handleViewableItemsChanged}
          // viewabilityConfig={viewabilityConfig}
          ListFooterComponent={<View mb="6xl" pb={cart.length ? 66 : 0} />} // TODO: make padding dynamic
        />
      </>
    );
  },
  areEqual,
);

export default MenuListSection;
