/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';

import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
} from 'react-native';
import * as Linking from 'expo-linking';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { loginUser, addUser, generateOTP } from '../../relay/authApi';
import { updateUser } from '../../relay/userApi';
import { AddVehicle } from '../../relay/vehicleApi';
// import OTPInput from '../../components/otpInput.component';
import { navigateBack, phoneFormat } from '../../utilities/helper';
import {
  Layout,
  View,
  Text,
  TopNavigation,
  IconButton,
  Button,
  OTPInput,
} from '../../components/new';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import CustomPressable from '../../components/new/primitive/CustomPressable/CustomPressable';
import { InputStates } from '../../components/new/primitive/OTPInput/helpers';
import SuccessLoader from '../../components/successLoader.component';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import findCampusLocation from '../../relay/campusLocationApi';
import useAuthStore from '../../stores/authStore';
import useUserStore from '../../stores/userStore';
import useCommonStore from '../../stores/commonStore';
import AppConstants from '../../utilities/AppConstants';
import VideoMomentsStore from '../../stores/VideoMomentsStore';

const RESEND_OTP_TIME_LIMIT = 30; // 30 secs
let resendOtpTimerInterval;
const BASE_URL =
  AppConstants.APP_ENV === 'production'
    ? 'https://club.32nd.com/'
    : 'https://staging.club.32nd.com/';

const OTPInputScreen = ({ navigation, route }) => {
  const [isWhatsappVisible, setIsWhatsappVisible] = useState(false);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [isQrMode, setIsQrMode] = useState(false);
  const userRole = useUserStore((state) => state.role);
  const [focusOnload, setFocusOnLoad] = useState(true);
  // const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [resendOTPCount, setResendOTPCount] = useState(1);

  const [successLoader, setSuccessLoader] = useState(false);
  const { phone } =
    route.params && route.params.phone
      ? route.params
      : route.params.record.contact;

  // const errorColor = theme['color-danger-default'];
  const errorColor = 'red';
  const timeConvert = (time) => {
    const minutes = `0${Math.floor(time / 60)}`;
    // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    const seconds = `0${time - minutes * 60}`;
    const updatedTime = `${minutes.substr(-2)}:${seconds.substr(-2)}`;
    return updatedTime;
  };
  const scrollRef = useRef('');
  const [resendButtonTime, setResendButtonTime] = useState(
    RESEND_OTP_TIME_LIMIT,
  );
  const [buttonDisable, setButtonDisable] = useState(false);
  const [OtpTime, setOtpConvertedTime] = useState(
    timeConvert(RESEND_OTP_TIME_LIMIT),
  );
  const [otpArrayVal, setOtpArrayVal] = useState([]);
  const [otpError, setOtpError] = useState('');
  const [isReset, setIsReset] = useState(false);
  const { bottom, top } = useSafeAreaInsets();

  const [autoSubmit, setAutoSubmit] = useState(false);

  useEffect(() => {
    if (autoSubmit) {
      submitOTP();
    }
  }, [autoSubmit]);

  useEffect(() => {
    if (otpArrayVal.join('').length < 4 || otpArrayVal.join('').length > 4) {
      setButtonDisable(true);
    } else {
      setLoading(false);
      setSuccessLoader(false);
      setButtonDisable(false);
    }
  }, [otpArrayVal]);

  useEffect(() => {
    startResendOtpTimer();
    return () => {
      if (resendOtpTimerInterval) {
        clearInterval(resendOtpTimerInterval);
      }
    };
  }, [resendButtonTime, otpArrayVal]);

  const startResendOtpTimer = () => {
    if (resendOtpTimerInterval) {
      clearInterval(resendOtpTimerInterval);
    }
    resendOtpTimerInterval = setInterval(() => {
      if (resendButtonTime <= 0) {
        clearInterval(resendOtpTimerInterval);
      } else {
        setResendButtonTime(resendButtonTime - 1);
        setOtpConvertedTime(timeConvert(resendButtonTime - 1));
      }
    }, 1000);
  };

  function showSuccessSnack() {
    dispatchSnackbar({
      msg: `OTP has been sent`,
      status: SnackbarStatus.success,
      version: SnackbarVersion.v2,
    });
  }

  const handleResendOtp = (response) => {
    if (response.generateOTP !== null) {
      setLoading(false);
      setSuccessLoader(false);
      setIsReset(true);
      setOtpError('');
      showSuccessSnack();
      // setOtpError('OTP Sent!');
    } else {
      setLoading(false);
      setSuccessLoader(false);
      setOtpError('Could not re-send OTP.');
    }
  };
  //
  const setUserDetails = useUserStore((state) => state.setUserDetails);
  const setLoginDetails = useAuthStore((state) => state.setLoginDetails);
  const setCampusId = useCommonStore((state) => state.setCampusId);

  const onResendOtpButtonPress = () => {
    setResendButtonTime(RESEND_OTP_TIME_LIMIT);
    setOtpConvertedTime(timeConvert(RESEND_OTP_TIME_LIMIT));
    startResendOtpTimer();
    setIsReset(true);
    setOtpError('');
    const data = {};
    (data as any).phone = phone;
    (data as any).resendOTPCount = resendOTPCount;
    setResendOTPCount((prevState) => prevState + 1);
    generateOTP(data, handleResendOtp);
  };

  const setShowFirstLoginParkingRewardModal = useCommonStore(
    (state) => state.setShowFirstLoginParkingRewardModal,
  );

  const setScannedS3Imagekey = VideoMomentsStore(
    (state) => state.setScannedS3Imagekey,
  );

  const handleLoginUser = (response, error, data) => {
    if (response?.loginUser?.record?.photoMomentsSearchImage) {
      setScannedS3Imagekey(response.loginUser.record.photoMomentsSearchImage);
    }
    setUserDetails(
      response?.loginUser?.record?.name?.first,
      response?.loginUser?.record?.name?.last,
      response?.loginUser?.record?.role?.name,
      response?.loginUser?.record?.role?.permission,
      response?.loginUser?.record?._id,
      response?.loginUser?.record?.contact,
      response?.loginUser?.record?.role?.name,
      response?.loginUser?.record?.role?.secondaryRole,
      response?.loginUser?.record?.profileImage,
    );
    setLoginDetails(
      true,
      response.loginUser.record.token,
      response.loginUser.record.refreshToken,
    );
    setButtonDisable(false);
    setLoading(false);
    setSuccessLoader(false);
    if (response.loginUser !== null) {
      const { name, permission } = response.loginUser.record.role;
      const { first } = response.loginUser.record.name;
      const { typeForm } = response.loginUser.record;
      const { token } = response.loginUser.record;
      const { refreshToken } = response.loginUser.record;
      const { _id } = response.loginUser.record;
      const campusID = response.loginUser.record.role.meta?.campus?._id;
      if (name !== 'admin') {
        const obj = {
          user: name,
          userToken: token,
          refreshToken,
          userId: _id,
          userName: first,
          typeForm,
          permission,
        };
        if (name !== 'user') {
          if (campusID) {
            setCampusId(campusID);
          } else {
            setLoading(false);
            setSuccessLoader(false);
            setOtpError('User not associated to any campus.');
            return;
          }
        }
        if (name === 'user') {
          if (response?.loginUser?.record?.firstLoginParkingReward === true) {
            setTimeout(() => {
              setShowFirstLoginParkingRewardModal(true);
            }, 500);
          }
          findCampusLocation({})
            .then((res) => {
              setCampusId(res?.findCampusLocation[0]?._id);
              setSuccessLoader(true);
              setTimeout(() => {
                navigation.navigate('user');
              }, 500);
            })
            .catch((err) => {
              // console.log(err);
            });
        }
      } else {
        setLoading(false);
        setSuccessLoader(false);
        setOtpError('Please login with correct user type.');
      }
    } else if (error !== null) {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      setOtpError(error[0].message);
    } else {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      setOtpError('Please enter correct OTP.');
    }
  };

  const handleAddUser = (response, error, data) => {
    if (response.addUser !== null) {
      loginUser(data, handleLoginUser, OTPErrorCallback);
    } else if (error !== null) {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      setOtpError(error[0].message);
    } else {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      setOtpError('Please enter correct OTP.');
    }
  };
  const handleAddVehicle = (response, error, data) => {
    if (response.addVehicle !== null) {
      const assignData = {};
      (assignData as any).brand = data.record.brand;
      (assignData as any).color = data.record.color;
      (assignData as any).plate = data.record.registration.plate;
      (assignData as any).ownerID = data.record.ownerID;
      setSuccessLoader(true);
      setTimeout(() => {
        navigation.navigate('assignDriver', assignData);
      }, 2000);
    } else if (error !== null) {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      setOtpError(error[0].message);
    }
  };

  const ApiErrorHandler = () => {
    setButtonDisable(false);
    setLoading(false);
    setSuccessLoader(false);
  };
  const updateErrorHandler = (err) => {
    setButtonDisable(false);
    setLoading(false);
    setSuccessLoader(false);
    if (err) {
      setOtpError('Incorrect Code Try Again');
    }
  };

  const OTPErrorCallback = (err) => {
    setLoading(false);
    setSuccessLoader(false);
    setButtonDisable(false);

    setOtpError('Entered OTP was wrong');
  };

  const handleAddUserValet = (response, error, data) => {
    if (response.addUser !== null) {
      const vehicleData = {};
      (vehicleData as any).record = {};
      (vehicleData as any).record.registration = {};
      (vehicleData as any).record.brand = data.vehicle.brandName;
      (vehicleData as any).record.registration.plate = data.vehicle.plateNumber;
      (vehicleData as any).record.color = data.vehicle.carColor;
      (vehicleData as any).record.ownerID = response.addUser.record._id;
      if (Object.keys(data.vehicle).length !== 0) {
        AddVehicle(vehicleData, handleAddVehicle, ApiErrorHandler);
      } else {
        setSuccessLoader(true);
        setTimeout(() => {
          navigation.navigate('assignDriver');
        }, 2000);
      }
    } else if (error !== null) {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      // setOtpError(error[0].message);
      setOtpError('Incorrect Code Try Again');
    } else {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      setOtpError('Please enter correct OTP.');
    }
  };
  const handleUpdateOtpUser = (response, error) => {
    if (response.updateUser !== null) {
      if (route?.params?.role === 'user') {
        // navigation.navigate('editProfile', { role: 'user' });
        navigation.navigate('account');
      } else {
        navigateBack(navigation, userRole);
      }
      setSuccessLoader(true);
    } else if (error !== null) {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      // setOtpError(error[0].message);
      setOtpError('Incorrect Code Try Again');
    } else {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      setOtpError('Please enter correct OTP.');
    }
  };

  const whatsAppLogin = async () => {
    try {
      const url = `https://32nd.authlink.me/?redirectUri=${BASE_URL}login`;
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const submitOTP = () => {
    // setIsReset(false);
    setButtonDisable(true);
    setLoading(true);
    // const data = cloneDeep(route.params);
    const data = route.params;
    const otp = otpArrayVal.join('');
    if (otp.length === 4) {
      data.pin = otp;
      if (data.id) {
        delete data.pin;
        data.record.pin = otp;
        updateUser(data, handleUpdateOtpUser, updateErrorHandler);
      } else if (data.vehicle) {
        addUser(data, handleAddUserValet, OTPErrorCallback);
      } else if (data.name && !data.id && !data.vehicle) {
        addUser(data, handleAddUser, OTPErrorCallback);
      } else {
        loginUser(data, handleLoginUser, OTPErrorCallback);
      }
    } else {
      setButtonDisable(false);
      setLoading(false);
      setSuccessLoader(false);
      setOtpError('Please enter correct OTP.');
    }
  };

  const getAsyncStorageData = async () => {
    const loginMode = await AsyncStorage.getItem('loginMode');
    if (loginMode === 'qrMode') {
      setIsQrMode(true);
    } else {
      setIsQrMode(false);
    }
  };

  useEffect(() => {
    setFocusOnLoad(true);
    getAsyncStorageData();
  }, []);

  useEffect(() => {
    if (route.name === 'otpScreen') {
      setIsWhatsappVisible(true);
    }
  }, [route.params]);

  useEffect(() => {
    if (
      route.params &&
      route.params.phone &&
      route?.params?.source !== 'mobileLoginScreen'
    ) {
      const data = {};
      (data as any).phone = phone;
      generateOTP(data, () => {});
    }
  }, []);

  return (
    <Layout level={2}>
      {successLoader !== true && (
        <TopNavigation
          title="32nd"
          appearance="ghost"
          level="1"
          // textSize="xl"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              // onPress={() => navigation.goBack()}
              onPress={() => navigateBack(navigation, userRole)}
            />
          }
        />
      )}
      <KeyboardAvoidingView
        {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
        // style={
        //   Platform.OS === 'web' && Device.osName === 'iOS' ? {} : { flex: 1 }
        // }
        style={{ flex: 1 }}
      >
        {successLoader === false && (
          <ScrollView
            // @ts-ignore
            ref={scrollRef}
            px="2xl"
            keyboardShouldPersistTaps="handled"
          >
            <View px="2xl">
              <Text size="3xl" color="primary.200" mt="4xl">
                Verify With OTP
              </Text>
              <Text
                style={styles.textSubHeading}
                size="sm"
                color="primary.200"
                mt="sm"
                mb="9xl"
              >
                Enter the 4 digit OTP sent on{' '}
                <Text size="md" fontWeight="500" color="primary.400">
                  {phone.prefix
                    ? `${phone.prefix}-${phoneFormat(phone.number)}`
                    : ''}
                </Text>
              </Text>

              {isReset === true ? (
                <View>
                  <OTPInput
                    label="OTP"
                    digits={4}
                    // autoFocus={focusOnload}
                    autoFocus
                    onFocus={() => {
                      setOtpError('');
                    }}
                    setOtpArrayVal={setOtpArrayVal}
                    setAutoSubmit={setAutoSubmit}
                    helperText={otpError}
                    state={
                      otpError && otpError !== 'OTP Sent!'
                        ? InputStates.error
                        : InputStates.default
                    }
                  />
                </View>
              ) : (
                <OTPInput
                  label="OTP"
                  digits={4}
                  // autoFocus={focusOnload}
                  onFocus={() => {
                    setOtpError('');
                  }}
                  autoFocus
                  setOtpArrayVal={setOtpArrayVal}
                  setAutoSubmit={setAutoSubmit}
                  helperText={otpError}
                  state={
                    otpError && otpError !== 'OTP Sent!'
                      ? InputStates.error
                      : InputStates.default
                  }
                />
              )}
              <View style={{ marginTop: 50 }}>
                <Button
                  size="lg"
                  status="primary"
                  state={
                    buttonDisable ? InputStates.disabled : InputStates.active
                  }
                  onPress={submitOTP}
                >
                  Verify
                </Button>
              </View>
              <View
                minHeight={22}
                px="lg"
                py="lg"
                my="lg"
                // py="lg"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Text size="sm" mr={3} color="primary.100">
                  Haven’t received a SMS?
                </Text>
                {resendButtonTime > 0 ? (
                  <Text status="primary" state="active">
                    Resend OTP <Text size="sm">{OtpTime}</Text>
                  </Text>
                ) : (
                  <Button
                    status="info"
                    appearance="ghost"
                    state="active"
                    size="md"
                    style={{ paddingVertical: 0 }}
                    onPress={() => {
                      onResendOtpButtonPress();
                    }}
                  >
                    Resend OTP
                  </Button>
                )}
              </View>
              {isWhatsappVisible && isQrMode === false && (
                <View
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <View width={85} height={1} backgroundColor="primary.50">
                    {}
                  </View>
                  <View mx="2xl">
                    <Text weight="medium" size="xs" color="primary.50">
                      OR
                    </Text>
                  </View>
                  <View width={85} height={1} backgroundColor="primary.50">
                    {}
                  </View>
                </View>
              )}
              <View
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                my="md"
              >
                {isWhatsappVisible && isQrMode === false && (
                  <CustomPressable
                    appearance="ghost"
                    size="lg"
                    textSize="sm"
                    iconSize="sm"
                    leftStyle={{ width: 20, height: 20 }}
                    // state={resendButtonTime > 0 ? 'disabled' : 'active'}
                    state="active"
                    status="primary"
                    leftContent={require('../../../assets/whatsapp.png')}
                    mb="4xl"
                    onPress={() => {
                      whatsAppLogin();
                    }}
                  >
                    Continue Via WhatsApp
                  </CustomPressable>
                )}
              </View>
            </View>
          </ScrollView>
        )}

        {/* {loading === true && <Loading />} */}
        {successLoader === true && <SuccessLoader />}
      </KeyboardAvoidingView>

      <SafeAreaView edges={['bottom']} />
      <DisconnectedDropover
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default OTPInputScreen;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // paddingHorizontal: 16,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  textSubHeading: {
    marginVertical: 24,
  },
  view: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  timerView: {
    marginTop: 8,
    flexDirection: 'row',
    alignSelf: 'center',
  },
  errorView: {
    marginTop: 4,
    alignSelf: 'center',
  },
});
