import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

export const valetDailyBonusQuery = graphql`
  query valetDailyBonusQuery($filter: DailyValetTaskBonusFilter!) {
    dailyValetTaskBonus(filter: $filter) {
      date
      bonus {
        onTimeTaskCount
        delayedTaskCount
        bonusAmount
      }
    }
  }
`;

const fetchValetDailyBonus = (data) => {
  const variables = { filter: data };
  const request = fetchQuery(environment, valetDailyBonusQuery, variables);
  return request.toPromise();
};

export default fetchValetDailyBonus;
