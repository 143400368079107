import { graphql, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

const query = graphql`
  query getPhotoMomentsUserMappingApiQuery {
    getPhotoMomentsUserMapping {
      userId
      likedImages
      deletedImages
    }
  }
`;

const getPhotoMomentsUserPhotosMappingApi = async () => {
  const request = fetchQuery(environment, query);
  return request.toPromise();
};
export default getPhotoMomentsUserPhotosMappingApi;
