import React, { Suspense, useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { View, Layout } from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import LoyaltyScreenInner from './LoyaltyScreenInner';
import { Loading } from '../../../components';

const LoyaltyScreen = () => {
  const [netStatus, setNetStatus] = useState(true);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  return (
    <Layout level={2}>
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <LoyaltyScreenInner
              queryOptions={refreshedQueryOptions}
              refresh={refresh}
            />
          </Suspense>
        </NewErrorBoundary>
      </View>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
        bottomNavigator
      />
    </Layout>
  );
};

export default LoyaltyScreen;
