import React, { useState } from 'react';
import { Image, ImageSourcePropType, useWindowDimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import { Text, View } from '../../index';
import CarouselDots from './CarouselDots';

const CarouselData: CarouselItemProps[] = [
  {
    index: 1,
    image: require('../../../../../assets/images/LoginCarouselStep1.png'),
    title: 'Recall From Anywhere',
    subTitle: 'Avoid the hassle of waiting for your car',
  },
  {
    index: 2,
    image: require('../../../../../assets/images/LoginCarouselStep2.png'),
    title: '1 Point = ₹ 1',
    subTitle: 'Join the 32nd Club and earn 32nd points',
  },
  {
    index: 3,
    image: require('../../../../../assets/images/LoginCarouselStep3.png'),
    title: 'Members Save More',
    subTitle: 'Get exclusive discounts on every transaction',
  },
  {
    index: 4,
    image: require('../../../../../assets/images/LoginCarouselStep4.png'),
    title: 'Capture Every Moment',
    subTitle: 'Get clicked by professional photographers',
  },
];

const SelectLoginMethodCarousel = () => {
  const [data] = useState<CarouselItemProps[]>(CarouselData);
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <View
      style={{
        height: windowHeight < 740 ? 380 : 450,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Carousel
        loop
        width={windowWidth - 64}
        height={windowHeight < 740 ? 370 : 440}
        data={data}
        scrollAnimationDuration={100}
        autoPlayInterval={3000}
        onSnapToItem={(index) => {
          setActiveIndex(index);
        }}
        renderItem={({ index, item }) => (
          <CarouselItem
            key={item.index}
            image={item.image}
            title={item.title}
            subTitle={item.subTitle}
            index={item.index}
          />
        )}
        autoPlay
      />
      <CarouselDots numberOfDots={data.length} activeIndex={activeIndex} />
    </View>
  );
};

interface CarouselItemProps {
  index: number;
  image: ImageSourcePropType;
  title: string;
  subTitle: string;
}
const CarouselItem = (props: CarouselItemProps) => {
  const { index } = props;
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: 8,
      }}
    >
      <Image
        style={{
          width: windowHeight < 740 ? 220 : 280,
          height: windowHeight < 740 ? (330 * 220) / 280 : 330,
        }}
        resizeMode="contain"
        source={props.image}
      />
      <Text size="lg" mt="4xl" mb="lg" color="primary.400" textAlign="center">
        {props.title}
      </Text>
      <Text size="sm" mb="4xl" color="primary.200" textAlign="center">
        {props.subTitle}
      </Text>
    </View>
  );
};

export default SelectLoginMethodCarousel;
