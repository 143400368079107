import React from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import {
  Button,
  IconButton,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import { space } from '../../../themes/new/theme';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';

const ThankYouScreen = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();

  return (
    <DarkThemeWrapper>
      <View flex={1} bg="background.primary.base">
        <TopNavigation
          title=""
          appearance="ghost"
          level="1"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="sm"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigation.popToTop()}
            />
          }
        />
        <View flex={1} justifyContent="center" alignItems="center">
          <CustomLottieView
            autoPlay
            loop
            source={require('../../../../assets/lottie/investment_success.json')}
            style={{ width: 196, height: 196 }}
            containerWidth={196}
          />
          <Text
            size="2xl"
            weight="medium"
            color="primary.500"
            pb="lg"
            textAlign="center"
            px="2xl"
            pt="4xl"
          >
            Thank you for your interest!
          </Text>
          <Text size="md" color="primary.300" textAlign="center" px="2xl">
            Expect us to reach out to you soon to discuss exclusive details-
            Stay tuned!
          </Text>
          <View height={space['9xl+9xl']} />
          <View
            width="100%"
            position="absolute"
            bottom={0}
            p="2xl"
            mb={Platform.OS === 'web' ? 0 : insets.bottom}
          >
            <Button
              state="active"
              status="primary"
              size="md"
              onPress={() => navigation.popToTop()}
            >
              Continue Browsing
            </Button>
          </View>
        </View>
      </View>
    </DarkThemeWrapper>
  );
};

export default ThankYouScreen;
