import * as React from 'react';
import { variant } from 'styled-system';
import styled from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ThemeVariables } from '../../../../themes/new/helper';

const layoutVariants = ({ theme }) => {
  return variant({
    prop: ThemeVariables.LEVEL,
    variants: {
      1: {
        'background-color': theme.colors.background.secondary.base,
      },
      2: {
        'background-color': theme.colors.background.primary.base,
      },
      // 3: {
      //   'background-color': theme.colors.background.primary.base,
      // },
      // 4: {
      //   'background-color': theme.colors.background.secondary.base,
      // },
    },
  });
};

const StyledLayout = styled.View`
  flex: 1;
  ${(props) => layoutVariants(props)};
`;

const Layout = ({ children, ...rest }) => {
  const { edges } = rest;
  return (
    <StyledLayout {...rest}>
      {edges && edges?.length ? (
        <SafeAreaView edges={edges} style={{ flex: 1 }}>
          {children}
        </SafeAreaView>
      ) : (
        <>{children}</>
      )}
    </StyledLayout>
  );
};

Layout.defaultProps = {
  level: 1,
};

export default Layout;
