/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { Image, TouchableOpacity, useWindowDimensions } from 'react-native';
import { Card, Icon, Text, View } from '../../components/new';
import { radii, space } from '../../themes/new/theme';

const VideoCard = ({
  item,
  showMargin,
  showTopMargin,
  retryUpload,
  processLongPress,
}) => {
  const { width } = useWindowDimensions();

  return (
    <Card
      category="none"
      ml={showMargin ? 'lg' : '2xl'}
      borderRadius="lg"
      mb="2xl"
      height={228}
      width={(width - 48) / 2}
      justifyContent="center"
      alignItems="center"
      mt={showTopMargin ? '4xl' : 0}
    >
      <TouchableOpacity
        onPress={retryUpload}
        style={{ position: 'absolute', flex: 1 }}
        onLongPress={() => processLongPress(item)}
      >
        <>
          <View height={154}>
            <Image
              source={{ uri: item?.uri }}
              style={{
                height: '100%',
                width: (width - 48) / 2,
                borderTopLeftRadius: radii.lg,
                borderTopRightRadius: radii.lg,
              }}
            />
          </View>
          <View
            height={74}
            width={(width - 48) / 2}
            justifyContent="center"
            pl="2xl"
          >
            <Text size="sm" color="primary.500" numberOfLines={1}>
              {item?.videoId?.substring(28)}
            </Text>
            <Text size="xs" color="primary.200">
              {item.subTitle1}
            </Text>
            <Text size="xs" color="primary.200">
              {item.subTitle2}
            </Text>
          </View>
          {!item?.isSelected ? (
            <View
              width={(width - 48) / 2}
              borderRadius="md"
              height={228}
              position="absolute"
              justifyContent="center"
              alignItems="center"
            >
              <View
                height={space['9xl']}
                width={space['9xl']}
                borderRadius={space['7xl']}
                bg="opacity.40"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                alignSelf="center"
                mt="9xl+9xl"
              >
                <Icon size="2xl" name="refresh-300" color="primary.10" />
              </View>
            </View>
          ) : null}
          {item?.isSelected ? (
            <View
              width={(width - 48) / 2}
              borderRadius="md"
              height={228}
              backgroundColor="rgba(0,0,0,0.5)"
              position="absolute"
              justifyContent="center"
              alignItems="center"
            >
              <Icon size="3xl" name="check-filled-400" color="primary.10" />
            </View>
          ) : null}
        </>
      </TouchableOpacity>
    </Card>
  );
};
export default VideoCard;
