/* eslint-disable no-nested-ternary */
import React from 'react';
import { FlatList } from 'react-native';
import { Pressable, View } from '../../../../components/new';
import TransactionSummaryListItem from '../../../../components/new/custom/transaction/TransactionSummaryListItem';
import { TransactionsRevenueReport } from '../../types';
import { getTheme } from '../../../../themes/new/theme';
import EmptyListView from '../../components/EmptyListView';

const theme = getTheme();
interface EarningReportListProps {
  earningsReports: TransactionsRevenueReport[];
  onItemPress: (earningsReport: TransactionsRevenueReport) => void;
}

const EarningReportList = ({
  earningsReports,
  onItemPress,
}: EarningReportListProps) => {
  return (
    <View flex={1}>
      <FlatList
        contentContainerStyle={{
          paddingHorizontal: theme.space['2xl'],
        }}
        data={earningsReports}
        keyExtractor={(item) => item?.formated.date}
        renderItem={({ item }) => {
          return (
            <View mb="2xl">
              <Pressable onPress={() => onItemPress(item)}>
                <TransactionSummaryListItem
                  amount={item.formated.collectedAmount}
                  date={item.formated.date}
                  label={`${item.transactionsCount} ${
                    item.transactionsCount === 1
                      ? 'Transaction'
                      : 'Transactions'
                  }`}
                  paymentStatus="SUCCESSFUL"
                />
              </Pressable>
            </View>
          );
        }}
        ListEmptyComponent={NoEarningsView}
      />
    </View>
  );
};

export default EarningReportList;

const NoEarningsView = () => (
  <View mt="122">
    <EmptyListView
      label="No Earnings Yet!"
      msg="There are no earnings at the moment, check back again"
    />
  </View>
);
