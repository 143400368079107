import React from 'react';
import QRCode from 'react-native-qrcode-svg';
import { useWindowDimensions } from 'react-native';
import AppConstants from '../../../utilities/AppConstants';

interface AppQrCodeProps {
  orderId: string;
  orderType: string;
  qrCodeAspectRatio: number;
  amount: number;
}

const AppQrCode = ({
  orderId = '',
  orderType = '',
  qrCodeAspectRatio = 0.73,
  amount = 0,
}: AppQrCodeProps) => {
  const { width } = useWindowDimensions();
  const WebUrl = AppConstants.WEB_URL;
  const generatedURL = `${WebUrl}/paymentAmountScreen?orderId=${orderId}&orderType=${orderType}&amount=${amount}`;
  return <QRCode value={generatedURL} size={width * qrCodeAspectRatio} />;
};

export default AppQrCode;
