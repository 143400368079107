import React, { useEffect, useState } from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  Icon,
  Pressable,
  Text,
  View,
} from '../../../../components/new';
import { Appearance, State, Status } from '../../../../themes/new/helper';
import CustomPressable from '../../../../components/new/primitive/CustomPressable/CustomPressable';
import InvestmentFileComponent from './InvestmentFileComponent';
import GradientBackground from '../../../../components/new/custom/GradientBackground';
import {
  INVESTMENT_APPLICATION_STATUS,
  PaymentStatusEnum,
  openWhatsApp,
} from '../../../../utilities/helper';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import { handlePhone } from '../../../../utilities/handleLinking';
import { toCurrency } from '../../../../utilities/Utility';
import useUserStore from '../../../../stores/userStore';
import { space } from '../../../../themes/new/theme';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';

const InvestmentApplicationComp = ({ data, bgColor = '', propertyData }) => {
  const { width } = useWindowDimensions();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { firstName, lastName, id } = useUserStore((state) => state);
  const navigation = useNavigation();
  const [statusData, setStatusData] = useState({
    heading: 'Application Received!',
    subHeading:
      'We will notify you about the next steps once your application is accepted for this investment opportunity, Stay tuned!',
    btnText: 'Paid',
  });
  const docData = {
    heading: '',
    subHeading: '',
    data: [
      {
        type: 'PDF',
        link: data?.investmentDetails?.investmentDocs?.offerLetter,
        icon: 'https://ik.imagekit.io/32ndhub/investment/common/pdf.jpg',
        name: 'Offer Letter',
        description: `${firstName ?? ''} ${lastName ?? ''}`,
        _id: `inv_offer_letter${id}_${propertyData?._id}`,
      },
      {
        type: 'PDF',
        link: data?.investmentDetails?.investmentDocs?.termsDoc,
        icon: 'https://ik.imagekit.io/32ndhub/investment/common/pdf.jpg',
        name: 'Terms document',
        description: `${firstName ?? ''} ${lastName ?? ''}`,
        _id: `inv_terms_doc${id}_${propertyData?._id}`,
      },
      {
        type: 'PDF',
        link: data?.investmentDetails?.investmentDocs?.agreementDoc,
        icon: 'https://ik.imagekit.io/32ndhub/investment/common/pdf.jpg',
        name: 'Agreement document',
        description: `${firstName ?? ''} ${lastName ?? ''}`,
        _id: `inv_agreement_doc${id}_${propertyData?._id}`,
      },
    ],
  };

  useEffect(() => {
    if (data?.status === INVESTMENT_APPLICATION_STATUS.APPLICATION_RECEIVED) {
      if (data?.investmentDetails?.investmentDocs?.offerLetter) {
        setStatusData({
          heading: 'Offer Letter Issued!',
          subHeading:
            'We will notify you about the next steps once your application is accepted for this investment opportunity, Stay tuned!',
          btnText: 'Paid',
        });
      }
    } else if (
      data?.status === INVESTMENT_APPLICATION_STATUS.REFUND_INITIATED
    ) {
      setStatusData({
        heading: 'Refund Initiated!',
        subHeading:
          'We will notify you once your refund is completed, Stay tuned!',
        btnText: 'Refund Requested',
      });
    }
  }, []);

  const gradientColors = [
    { offset: '0', color: '#121212', opacity: 0 },
    { offset: '1', color: '#0A461D', opacity: 1 },
  ];

  const handleWhatsapp = () => {
    firebaseEventLogger('PropertyDetails__whatsappManager_Tap', {
      screenName: 'whatsappManager',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn: true,
      propertyId: propertyData?._id,
    });
    if (data?.investmentDetails?.investmentManagerDetails?.contact?.whatsapp) {
      openWhatsApp(
        `Hi, I would like to know about my application`,
        data?.investmentDetails?.investmentManagerDetails?.contact?.whatsapp,
      );
    } else {
      dispatchSnackbar({
        msg: 'Whatsapp number not found!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
    }
  };

  const handleCall = () => {
    firebaseEventLogger('PropertyDetails__callManager_Tap', {
      screenName: 'callManager',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn: true,
      propertyId: propertyData?._id,
    });
    if (data?.investmentDetails?.investmentManagerDetails?.contact?.phone) {
      handlePhone(
        data?.investmentDetails?.investmentManagerDetails?.contact?.phone,
      );
    } else {
      dispatchSnackbar({
        msg: 'Phone number not found!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
    }
  };

  return (
    <View pb="9xl" alignItems="center" bg={bgColor}>
      <GradientBackground
        backgroundOpacity={1}
        gradientColors={gradientColors}
        id="inv_application_vagator"
        style={{
          width,
          height: 280,
          position: 'absolute',
        }}
        x1="1"
        y1="0"
        x2="0"
        y2="1"
      />
      <View alignItems="center" height={280} px="2xl" pt="9xl">
        <Icon name="check-filled-400" color="primary.400" size="8xl" />
        <Text size="2xl" color="primary.400" weight="medium" mt="lg">
          {statusData?.heading}
        </Text>
        <Text
          size="xs"
          color="primary.300"
          mt="lg"
          textAlign="center"
          numberOfLines={2}
        >
          {statusData?.subHeading}
        </Text>
      </View>
      <View
        height={358}
        width={width - 32}
        bg="background.primary.elevation"
        justifyContent="center"
        alignItems="center"
        px="2xl"
        top={-60}
        borderRadius="xl"
      >
        <Image
          source={
            data?.investmentDetails?.investmentManagerDetails?.profileImage
              ? {
                  uri: data?.investmentDetails?.investmentManagerDetails
                    ?.profileImage,
                }
              : require('../../../../../assets/images/no-image.png')
          }
          style={{ height: 100, width: 100, borderRadius: 50 }}
        />
        <Text size="md" color="primary.500" weight="medium" mt="lg">
          {data?.investmentDetails?.investmentManagerDetails?.name ??
            'Assigning Investment Expert'}
        </Text>
        <Text
          size="xs"
          color="primary.300"
          mt="lg"
          textAlign="center"
          numberOfLines={3}
        >
          {data?.investmentDetails?.investmentManagerDetails?.intro ??
            `An investment expert will be assigned to you within 24 hours. We'll notify you once they are assigned to assist with your investment journey and portfolio growth!`}
        </Text>
        <View width="100%">
          <Button
            appearance={Appearance.FILLED}
            status={Status.PRIMARY}
            state={
              data?.investmentDetails?.investmentManagerDetails?.contact?.phone
                ? State.ACTIVE
                : State.DISABLED
            }
            size="md"
            mt="2xl"
            onPress={handleCall}
          >
            <View flexDirection="row" alignItems="center" top={space.xs}>
              <Icon name="call-filled-300" color="primary.10" size="xl" />
              <Text size="md" color="primary.10" ml="sm">
                Call Expert
              </Text>
            </View>
          </Button>
          <View
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mt="2xl"
          >
            <CustomPressable
              appearance="ghost"
              size="lg"
              textSize="md"
              iconSize="sm"
              leftStyle={{ width: 20, height: 20 }}
              state={
                data?.investmentDetails?.investmentManagerDetails?.contact
                  ?.whatsapp
                  ? State.ACTIVE
                  : State.DISABLED
              }
              status={Status.PRIMARY}
              color="primary.500"
              leftContent={require('../../../../../assets/whatsapp.png')}
              onPress={handleWhatsapp}
            >
              Chat On WhatsApp
            </CustomPressable>
          </View>
        </View>
      </View>
      <View width={width} top={-40}>
        {data?.investmentDetails?.investmentDocs ? (
          <InvestmentFileComponent
            data={docData}
            hidePadding
            propertyId={propertyData?._id}
          />
        ) : null}
        <Pressable
          onPress={() => {
            firebaseEventLogger('PropertyDetails__viewPayment_Tap', {
              screenName: 'viewPayment',
              userType: 'user',
              interactionType: 'tap',
              isLoggedIn: true,
              propertyId: propertyData?._id,
              orderId: data?._id,
            });
            const qparams = {
              orderId: data?._id,
              status: PaymentStatusEnum.SUCCESSFUL,
            };
            navigation.navigate('transactionScreen', qparams);
          }}
        >
          <View
            bg="background.primary.elevation"
            borderRadius="xl"
            p="2xl"
            mx="2xl"
            mt="2xl"
            flexDirection="row"
            justifyContent="space-between"
          >
            <View
              borderWidth="xs"
              borderColor="success.500"
              py="xs"
              px="md"
              borderRadius="md+lg"
            >
              <Text size="2xs" color="success.500">
                {statusData?.btnText}
              </Text>
            </View>
            <View flexDirection="row" alignItems="center">
              <Text size="sm" color="primary.500" mr="lg">
                ₹{toCurrency(propertyData?.investment.data.application.amount)}
              </Text>
              <Icon name="forward-outline-300" color="primary.500" size="md" />
            </View>
          </View>
        </Pressable>
        <View
          borderTopWidth={1}
          borderColor="grey.50"
          mt="2xl"
          mx="2xl"
          alignItems="center"
        >
          <Text size="sm" color="primary.200" pt="lg">
            Your application fee is 100% refundable ⓘ
          </Text>
        </View>
      </View>
    </View>
  );
};

export default InvestmentApplicationComp;
