/* eslint-disable import/prefer-default-export */
import { PaymentStatusEnum } from '../../../../../../utilities/helper';

export const resolveStatusText = (status: keyof typeof PaymentStatusEnum) => {
  switch (status) {
    case PaymentStatusEnum.SUCCESSFUL:
      return 'Paid';
    case PaymentStatusEnum.FAILED:
      return 'Failed';
    case PaymentStatusEnum.PENDING:
      return 'Pending';
    case PaymentStatusEnum.REFUNDED:
      return 'Refunded';
    default:
      return 'Refunded';
  }
};
