/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { Button, Text, View } from '../../components/new';
import photoMoments from '../../screens/Strings';
import { space } from '../../themes/new/theme';

const Confirmface = ({ imagePath = '', recordVideo, captureAgain }) => {
  const { height } = useWindowDimensions();

  return (
    <View
      bg="background.primary.base"
      flex={1}
      p="2xl"
      pt="xl"
      mt="9xl+9xl+3xl"
    >
      <Text weight="medium" size="2xl" color="primary.500">
        {photoMoments.confirmFace}
      </Text>
      <Text size="md" color="primary.300">
        {photoMoments.confirmFaceSubText}
      </Text>
      <Image
        style={{
          height: height * 0.55,
          width: '100%',
          marginTop: space['2xl'],
        }}
        source={{ uri: imagePath }}
      />
      <View mt="2xl" position="absolute" bottom="2xl" width="100%" mx="2xl">
        <Button
          size="lg"
          appearance="filled"
          status="primary"
          state="active"
          onPress={recordVideo}
        >
          {photoMoments.record}
        </Button>
        <Button
          size="lg"
          appearance="outline"
          status="primary"
          state="active"
          mt="2xl"
          onPress={captureAgain}
        >
          {photoMoments.discard}
        </Button>
      </View>
    </View>
  );
};
export default Confirmface;
