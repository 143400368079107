import React, { useRef } from 'react';
import { Video, ResizeMode } from 'expo-av';
import { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import { Platform, useWindowDimensions } from 'react-native';
import { View, Pressable, Icon, Text } from '../index';
import { getTheme, themeModes } from '../../../themes/new/theme';
import { FontWeights } from '../../../themes/new/helper';
import CachedImage from './CachedImage';
import {
  dynamicHeightMaker,
  videoTransformation,
} from '../../../utilities/helper';

interface VideoWithThumbnailProps {
  thumbnailSource: string;
  videoSource: string;
  thumbnailWidth?: number;
  aspectRatio?: number;
  videoTitle: string;
  videoSubTitle: string;
  iconSize: string;
  subtitleWeight: string;
  subTitleColor: string;
  subtitleSize: string;
  onPress?: () => void;
}

const VideoWithThumbnail = ({
  thumbnailSource = '',
  videoSource = '',
  thumbnailWidth = 0,
  aspectRatio = 1,
  videoTitle = '',
  videoSubTitle = '',
  subtitleWeight = 'regular',
  iconSize = '9xl',
  subTitleColor = 'primary.50',
  subtitleSize = 'sm',
  onPress = () => {},
}: VideoWithThumbnailProps) => {
  const { width, height } = useWindowDimensions();
  const videoRef = useRef(null);
  const widthProp = thumbnailWidth || width;

  const loadVideo = async () => {
    videoRef?.current?.presentFullscreenPlayer();
  };

  const onFullscreenUpdate = ({ fullscreenUpdate }) => {
    if (fullscreenUpdate === 3) {
      videoRef?.current?.setStatusAsync({ shouldPlay: false });
    }
    if (fullscreenUpdate === 1) {
      videoRef?.current?.setStatusAsync({
        shouldPlay: true,
        positionMillis: 0,
      });
    }
  };

  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider
          theme={
            theme.currentThemeMode === themeModes.light
              ? getTheme(themeModes.light)
              : getTheme(themeModes.dark)
          }
        >
          <Pressable
            onPress={() => {
              loadVideo();
              onPress();
            }}
          >
            <CachedImage
              source={{ uri: thumbnailSource }}
              style={{
                width: thumbnailWidth || width,
                height: dynamicHeightMaker(
                  thumbnailWidth || width,
                  aspectRatio,
                ),
                resizeMode: 'cover',
                //   aspectRatio,
              }}
            />
            <View
              position="absolute"
              width={thumbnailWidth || width}
              height={dynamicHeightMaker(thumbnailWidth || width, aspectRatio)}
              backgroundColor={
                theme.currentThemeMode === themeModes.light
                  ? getTheme(themeModes.dark).colors.opacity[50]
                  : getTheme(themeModes.light).colors.opacity[50]
              }
            >
              <View flex={1} alignItems="center" justifyContent="center">
                <Icon
                  name="play-outline-300"
                  size={iconSize}
                  color="static.white"
                />
              </View>
            </View>
            {videoTitle || videoSubTitle ? (
              <View p="2xl" position="absolute" bottom={0}>
                {videoTitle ? (
                  <Text
                    color="primary.10"
                    size="2xl"
                    weight={FontWeights.MEDIUM}
                    numberOfLines={1}
                  >
                    {videoTitle}
                  </Text>
                ) : (
                  <></>
                )}
                {videoSubTitle ? (
                  <Text
                    color={subTitleColor}
                    size={subtitleSize}
                    mt="sm"
                    numberOfLines={2}
                    weight={subtitleWeight}
                  >
                    {videoSubTitle}
                  </Text>
                ) : (
                  <></>
                )}
              </View>
            ) : (
              <></>
            )}
          </Pressable>
          <Video
            ref={videoRef}
            source={{
              uri: videoTransformation(videoSource, widthProp, aspectRatio),
            }}
            style={{
              opacity: 0,
            }}
            resizeMode={ResizeMode.COVER}
            onFullscreenUpdate={onFullscreenUpdate}
            useNativeControls={Platform.OS !== 'ios'}
          />
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

export default VideoWithThumbnail;
