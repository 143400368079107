import React from 'react';
import { FlatList, Platform } from 'react-native';
import { Text, View } from '../index';
import { Appearance, State } from '../../../themes/new/helper';
import Chip from '../primitive/Chip/Chip';

export interface EventListingDataInfoFull {
  startDate: Date;
  endDate: Date;
  name: string;
}

export interface EventListingDataInfo {
  startDate: Date;
  endDate: Date;
}

export interface ListingFilterDataType {
  name: string;
  selected: boolean;
  data: EventListingDataInfo;
}

interface ListingFiltersProps {
  data: ListingFilterDataType[];
  onPress: (data: ListingFilterDataType) => void;
  isVisible: boolean;
}
const ListingFilters = (props: ListingFiltersProps) => {
  if (!props.isVisible) {
    return null;
  }
  return (
    <FlatList
      data={props.data}
      horizontal
      showsHorizontalScrollIndicator={false}
      renderItem={(item) => {
        return (
          <View ml={item.index === 0 ? '2xl' : 0} mr="lg">
            <Chip
              state={item.item.selected ? State.SELECTED : State.ACTIVE}
              // @ts-ignore
              onPress={() => {
                props.onPress(item.item);
              }}
              size="xs"
              appearance={Appearance.FILLED}
            >
              {item.item.name}
            </Chip>
          </View>
        );
      }}
    />
  );
};

export default ListingFilters;
