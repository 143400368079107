/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, Suspense, useRef } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import {
  Layout,
  View,
  IconButton,
  TopNavigation,
  Button,
  MobileInput,
  Input,
} from '../../components/new';
import Loading from '../../components/loading.component';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useCommonStore from '../../stores/commonStore';
import useUserStore, { UserRole } from '../../stores/userStore';
import RadioChipGroup from '../../components/new/composites/RadioChipGroup/RadioChipGroup';
import {
  Capitalise,
  defaultPhoneNumberPrefix,
  navigateBack,
  phoneFormat,
} from '../../utilities/helper';
import { InputStates } from '../../components/new/primitive/Input/helpers';
import { addValet } from './API/commonApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';

interface AddTeamMemberProps {
  queryOptions: any;
  variables: any;
  fetchKey: any;
}

const AddTeamMemberScreen = ({
  queryOptions,
  variables,
  fetchKey,
}: AddTeamMemberProps) => {
  const navigation = useNavigation<any>();
  const { role } = useUserStore((state) => state);
  const { campusId } = useCommonStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [addMemberButtonDisabled, setAddMemberButtonDisabled] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneNumberInputState, setPhoneNumberInputState] = useState(
    InputStates.default,
  );
  const [userName, setUserName] = useState('');
  const [valetCode, setValetCode] = useState('');
  const [errorHelperText, setErrorHelperText] = useState('');
  const [nameHelperText, setNameHelperText] = useState('');
  const [nameInputState, setNameInputState] = useState(InputStates.default);
  const [valetCodeHelperText, setValetCodeHelperText] = useState('');
  const [valetCodeInputState, setValetCodeInputState] = useState(
    InputStates.default,
  );
  const insets = useSafeAreaInsets();
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const scrollViewRef = useRef(null);

  const teamData = [
    {
      id: 0,
      name: Capitalise(UserRole.VALET),
      selected: false,
      key: UserRole.VALET,
    },
    {
      id: 1,
      name: 'Supervisor',
      selected: false,
      key: UserRole.VALET_SUPERVISOR,
    },
    {
      id: 2,
      name: Capitalise(UserRole.CONTROLLER),
      selected: false,
      key: UserRole.CONTROLLER,
    },
    {
      id: 3,
      name: Capitalise(UserRole.CASHIER),
      selected: false,
      key: UserRole.CASHIER,
    },
  ];

  const [teamRoleData, setTeamRoleData] = useState(teamData);

  // ------ handle on selecting particular role ------
  const onPressSelectedRole = (id) => {
    const data = teamRoleData.map((item) => {
      if (item.id === id) {
        if (item?.name === Capitalise(UserRole.VALET)) {
          setTimeout(() => {
            scrollViewRef?.current?.scrollToEnd();
          }, 2000);
        }
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    setTeamRoleData(data);
  };

  // ------ handle on name change for input ------
  const handleOnNameChange = (value) => {
    setUserName(value);
  };

  const handleOnFocusOutName = () => {
    if (userName?.length < 3) {
      setNameHelperText('Input minimum 3 characters');
      setNameInputState(InputStates.error);
    }
  };

  const handleOnFocusName = () => {
    setNameHelperText('');
  };

  // ------ handle on valet code change for input ------
  const handleOnValetCodeChange = (value) => {
    setValetCode(value);
  };

  const handleOnFocusOutValetCode = () => {
    if (valetCode?.length < 2) {
      setValetCodeHelperText('Input minimum 2 characters');
      setValetCodeInputState(InputStates.error);
    }
  };

  const handleOnFocusValetCode = () => {
    setValetCodeHelperText('');
  };

  // ------ handle on phone number change for input ------
  const handleOnNumberChange = (value) => {
    setMobileNumber(phoneFormat(value));

    if (value?.length === 11) {
      inputRef2?.current?.focus();
    }

    if (value?.length > 11) {
      setErrorHelperText('^Mobile Number should not exceed 10 digits');
      setPhoneNumberInputState(InputStates.error);
    }
  };

  const handleOnFocusOutNumber = () => {
    if (mobileNumber?.length <= 10) {
      setErrorHelperText('Mobile number should be of 10 digits');
      setPhoneNumberInputState(InputStates.error);
    }
  };

  const handleOnFocusNumber = () => {
    setErrorHelperText('');
  };

  const findSelectedRole = teamRoleData.filter((item) => item?.selected);

  const handleAddMember = () => {
    setAddMemberButtonDisabled(true);
    const mobile = mobileNumber.split(' ').join('');
    const user = addValet(
      {
        record: {
          name: { first: userName?.trim() },
          contact: {
            phone: {
              prefix: defaultPhoneNumberPrefix,
              number: mobile,
            },
          },
          role: {
            name: findSelectedRole[0]?.key,
            meta: { campus: { _id: campusId } },
          },
          identityCode: valetCode,
        },
      },
      (res, err) => {
        setAddMemberButtonDisabled(false);
        if (res) {
          dispatchSnackbar({
            msg: `${findSelectedRole[0]?.name} added successfully!`,
            status: SnackbarStatus.success,
            version: SnackbarVersion.v2,
          });
          navigateBack(navigation, role);
        }
        if (err) {
          dispatchSnackbar({
            msg: 'Something went wrong!',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
          });
        }
      },
      (err) => {
        setAddMemberButtonDisabled(false);
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
        console.log(err);
      },
    );
  };

  const checkIfAddMemberButtonDisabled = () => {
    if (
      mobileNumber?.length >= 11 &&
      errorHelperText?.length <= 0 &&
      userName?.length >= 3 &&
      findSelectedRole &&
      findSelectedRole?.length
    ) {
      if (findSelectedRole[0]?.key === UserRole.VALET) {
        if (valetCode?.length >= 2) {
          return false;
        }

        return true;
      }

      return false;
    }
    if (!addMemberButtonDisabled) {
      return true;
    }

    return false;
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
    >
      <ScrollView
        ref={scrollViewRef}
        style={{ flex: 1 }}
        keyboardShouldPersistTaps="handled"
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View flex={1}>
            <View px="2xl" mt="4xl">
              <View flexDirection="row" alignItems="center">
                <MobileInput
                  inputRef={inputRef1}
                  state={phoneNumberInputState}
                  value={mobileNumber}
                  label="Mobile Number"
                  returnKeyType="next"
                  keyboardType="numeric"
                  placeholder="12345 567890"
                  countryCode={defaultPhoneNumberPrefix}
                  onChangeText={handleOnNumberChange}
                  helperText={errorHelperText}
                  maxLength={11}
                  autoFocus
                  onSubmitEditing={() => {
                    inputRef2?.current?.focus();
                  }}
                  blurOnSubmit={false}
                  onFocus={handleOnFocusNumber}
                  onBlur={handleOnFocusOutNumber}
                />
              </View>
              <View mt="4xl">
                <Input
                  value={userName}
                  inputRef={inputRef2}
                  placeholder="Eg: Sunny Rao"
                  label="Name"
                  //   autoFocus
                  onChangeText={handleOnNameChange}
                  helperText={nameHelperText}
                  onSubmitEditing={() => {}}
                  state={nameInputState}
                  onFocus={handleOnFocusName}
                  onBlur={handleOnFocusOutName}
                />
              </View>

              <View mt="4xl">
                <RadioChipGroup
                  data={teamRoleData}
                  onPress={(id) => onPressSelectedRole(id)}
                  title="Role"
                />
              </View>
              {findSelectedRole &&
              findSelectedRole?.length &&
              findSelectedRole[0]?.key === UserRole.VALET ? (
                <Input
                  value={valetCode}
                  state={valetCodeInputState}
                  placeholder="01"
                  label="Valet Code"
                  autoFocus
                  onChangeText={handleOnValetCodeChange}
                  helperText={valetCodeHelperText}
                  keyboardType="numeric"
                  maxLength={3}
                  inputRef={inputRef3}
                  onFocus={() => {
                    setTimeout(() => {
                      scrollViewRef?.current?.scrollToEnd();
                      handleOnFocusValetCode();
                    }, 200);
                  }}
                  onBlur={handleOnFocusOutValetCode}
                />
              ) : (
                <></>
              )}
            </View>
            <View height={50} />
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
      <View bg="background.primary.base" m="2xl">
        <Button
          size="lg"
          status="primary"
          state={checkIfAddMemberButtonDisabled() ? 'disabled' : 'active'}
          // my="2xl"
          onPress={handleAddMember}
        >
          Add{' '}
          {findSelectedRole && findSelectedRole?.length
            ? findSelectedRole[0]?.name
            : 'Member'}
        </Button>
      </View>
    </KeyboardAvoidingView>
  );
};

const AddTeamMemberScreenWrapper = ({ navigation, route }) => {
  const campusID = useCommonStore((state) => state.campusId);
  const { role } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Add Team Member"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <AddTeamMemberScreen
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default AddTeamMemberScreenWrapper;
