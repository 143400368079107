import React, { useCallback, useState } from 'react';
import LottieView from 'lottie-react-native';
import { ScrollView, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { View, Text, Divider, RadioGroup, Button } from '../new';
import useCampusLocation from '../../stores/campusLocation';
import useUserStore, { ControllerRole } from '../../stores/userStore';
import fetchValetWorkLogQuery from '../../screens/valet/new/API/valetWorkLogQuery';
import AddWorkLog from '../../relay/valetMyTasksApi';
import BottomSheetV2 from '../new/composites/BottomSheet/BottomSheetV2';
import CustomLottieView from '../new/custom/CustomLottieView';

const roleData = [
  {
    id: 0,
    name: 'Recall Controller',
    checked: true,
    type: ControllerRole.RECALL,
  },
  {
    id: 1,
    name: 'Parking Controller',
    type: ControllerRole.PARK,
  },
];

enum BottomSheetSteps {
  LOCATION,
  ROLE,
  SUCCESS,
}

interface Props {
  punchInCallback?: any;
}

const ControllerBottomSheet = React.forwardRef<ActionSheetRef, Props>(
  ({ punchInCallback }, ref) => {
    const [bottomsheetState, setBottomsheetState] = useState(
      BottomSheetSteps.LOCATION,
    );
    const { height } = useWindowDimensions();
    const { campusLocations, setCampusLocations } = useCampusLocation(
      (state) => state,
    );

    const { setControllerRole, id } = useUserStore((state) => state);
    const [radio, setRadio] = useState(campusLocations);
    const [selectedCampusLocation, setSelectedCampusLocation] = useState([]);
    const [status, setStatus] = useState('');
    const insets = useSafeAreaInsets();

    const handleSheetChanges = useCallback((index: number) => {
      if (index < 0) {
        setBottomsheetState(BottomSheetSteps.LOCATION);
        setRadio(campusLocations);
      }
    }, []);

    const backHandler = () => {
      setRadio(campusLocations);
      setBottomsheetState(BottomSheetSteps.LOCATION);
    };

    const nextHandler = () => {
      fetchValetWorkLogQuery({
        valetID: id,
        date: new Date(),
      }).then(({ findValetWorkLog }) => {
        if (findValetWorkLog) {
          const { type } = findValetWorkLog.workLogHistory.slice(-1)[0];
          setStatus(type);
        }
        setSelectedCampusLocation(radio);
        setRadio(JSON.parse(JSON.stringify(roleData)));
        setBottomsheetState(BottomSheetSteps.ROLE);
      });
    };

    const punchInHandler = () => {
      if (!status || status === 'punch_out') {
        AddWorkLog({
          date: new Date().toISOString(),
          valetID: id,
          workLogHistory: [
            {
              time: new Date().toISOString(),
              type: 'punch_in',
            },
          ],
        })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const selected: typeof roleData = radio.filter((item) => item.checked);
      setBottomsheetState(BottomSheetSteps.SUCCESS);
      setTimeout(() => {
        setRadio(campusLocations);
        setBottomsheetState(BottomSheetSteps.LOCATION);
        setControllerRole(selected[0].type);
        setCampusLocations({ data: selectedCampusLocation });
        if ((!status || status === 'punch_out') && punchInCallback) {
          punchInCallback();
        }
        ref?.current?.hide();
      }, 1400);
    };

    return (
      <BottomSheetV2 ref={ref} onClose={handleSheetChanges}>
        <View>
          {bottomsheetState !== BottomSheetSteps.SUCCESS ? (
            <View justifyContent="space-between">
              <View>
                <Text size="xl" color="primary.200" px="2xl">
                  {bottomsheetState === BottomSheetSteps.LOCATION
                    ? 'Select Location'
                    : 'Select Role'}
                </Text>
                <View mt="lg" px="2xl">
                  <Divider />
                </View>
                <View mt="2xl">
                  <ScrollView
                    style={{ maxHeight: height * 0.25 }}
                    scrollIndicatorInsets={{ right: 1 }}
                    contentContainerStyle={{
                      paddingHorizontal: 16,
                    }}
                  >
                    <RadioGroup
                      radio={radio}
                      setRadio={setRadio}
                      size="lg"
                      icon="right"
                    />
                  </ScrollView>
                </View>
              </View>
              <View px="2xl">
                {bottomsheetState === BottomSheetSteps.LOCATION ? (
                  <Button
                    appearance="filled"
                    status="primary"
                    size="lg"
                    onPress={nextHandler}
                    mt="4xl"
                  >
                    Next
                  </Button>
                ) : (
                  <View flexDirection="row">
                    <View flex={1} mr="xl">
                      <Button
                        appearance="outline"
                        status="primary"
                        size="lg"
                        onPress={backHandler}
                        mt="4xl"
                      >
                        Go Back
                      </Button>
                    </View>
                    <View flex={1}>
                      <Button
                        appearance="filled"
                        status="primary"
                        size="lg"
                        onPress={punchInHandler}
                        mt="4xl"
                      >
                        {status && status !== 'punch_out'
                          ? 'Change Role'
                          : 'Punch In'}
                      </Button>
                    </View>
                  </View>
                )}
              </View>
            </View>
          ) : (
            <View justifyContent="center" alignItems="center">
              <CustomLottieView
                style={{ width: 200, height: 200 }}
                containerWidth={200}
                autoPlay
                loop
                source={require('../../../assets/lottie/tick.json')}
              />
              <Text size="2xl" mb="9xl">
                {status && status !== 'punch_out'
                  ? 'Role Changed Successfully'
                  : 'Punched In Successfully'}
              </Text>
            </View>
          )}
        </View>
      </BottomSheetV2>
    );
  },
);

export default React.memo(ControllerBottomSheet);
