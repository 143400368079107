import React from 'react';
import { Dimensions } from 'react-native';
import { Text, View } from '../../../components/new';
import { dynamicHeightMaker } from '../../../utilities/helper';
import CachedImage from '../../../components/new/custom/CachedImage';

const RestaurantListEmptyPlaceholder = () => {
  return (
    <View alignItems="center" justifyContent="center" pt="4xl">
      <CachedImage
        source={require('../../../../assets/images/businessman-searching-for-an-answer.webp')}
        style={{
          width: Dimensions.get('screen').width * 0.497,
          height: dynamicHeightMaker(Dimensions.get('screen').width * 0.497, 1),
          resizeMode: 'contain',
        }}
      />
      <View alignItems="center" justifyContent="center">
        <Text size="md" color="primary.100">
          Oh No!
        </Text>
        <Text size="md" color="primary.100">
          Sorry, no restaurants found
        </Text>
      </View>
    </View>
  );
};

export default RestaurantListEmptyPlaceholder;
