import React, { ReactNode, useCallback, useRef, useState } from 'react';
import useLoginModalStore, {
  LoginModalScreenSteps,
} from '../../stores/loginModalStore';
import { Button, Input, Text, View } from '../new';
import { InputStates } from '../new/primitive/Input/helpers';
import validateWrapper from '../../utilities/validationWrapper';
import {
  AddUserPayload,
  addUserV2,
  generateOTP,
  LoginUserPayload,
  loginUserV2,
} from '../../relay/authApi';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { handleLoginUser } from '../../utilities/loginHelper';

export interface MobilePayload {
  phone: {
    prefix: string;
    number: string;
  };
}
const SignupScreen = () => {
  const componentState = useLoginModalStore((state) => state);

  const getSavedUserName = () => {
    const firstName = componentState?.signupData?.name?.first?.trim() || '';
    const lastName = componentState?.signupData?.name?.last?.trim() || '';
    const fullName = firstName + (lastName ? ` ${lastName}` : '');

    return fullName;
  };
  const savedUserName = getSavedUserName();

  const [userErrorText, setUserErrorText] = useState('');
  const [userName, setUserName] = useState(savedUserName?.trim());
  const [inputStatus, setInputStatus] = useState(InputStates.default);
  const [buttonDisabled, setButtonDisabled] = useState(!savedUserName?.length);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleUserName = (val) => {
    const error = validateWrapper('fullName', val);
    setUserName(val);
    if (error) {
      setUserErrorText(error);
      setInputStatus(InputStates.error);
      setButtonDisabled(true);
    } else {
      setUserErrorText('');
      setInputStatus(InputStates.default);
      setButtonDisabled(false);
    }
  };

  const handleGenerateOtp = (response, data) => {
    setButtonDisabled(false);
    if (response.generateOTP) {
      componentState.setCurrentScreen(LoginModalScreenSteps.ENTER_OTP);
    } else {
      setUserErrorText('Error in generating OTP');
    }
  };
  const validationClear = () => {
    setUserErrorText('');
  };
  const onContinue = () => {
    firebaseEventLogger('loginSignupHome__continueSignUp_Tap', {
      buttonName: 'continueSignUp',
      screenName: 'loginSignupHome',
      userType: 'user',
      interactionType: 'tap',
    });
    const error = validateWrapper('fullName', userName);
    const comingdata: MobilePayload = {
      phone: {
        number: componentState.mobileNumber,
        prefix: `+${componentState.countryCallingCode}`,
      },
    };
    const data = {
      ...comingdata,
      name: {
        first: '',
        last: '',
      },
    };
    const nameArr = userName.trim().split(' ');
    if (nameArr.length > 1) {
      data.name.first = nameArr[0];
      data.name.last = nameArr.slice(1).join(' ').replace(/\s\s+/g, ' ').trim();
    } else {
      data.name.first = userName;
    }
    if (!error) {
      componentState.setSignupPayload({
        name: { first: data.name.first, last: data.name.last },
      });
      if (componentState.waId) {
        if (componentState.secondarySignup) {
          const loginPayload: LoginUserPayload = {
            name: {
              first: data.name.first,
              last: data.name.last,
            },
            contact: {
              phone: {
                number: componentState.mobileNumber,
                prefix: `+${componentState.countryCallingCode}`,
              },
            },
            pin: '6789',
            waId: componentState.waId,
          };
          loginUserV2(loginPayload)
            .then((res) => {
              handleLoginUser(res);
            })
            .catch((err) => {});
        } else {
          const addUserPayload: AddUserPayload = {
            contact: {
              phone: {
                number: componentState.mobileNumber,
                prefix: `+${componentState.countryCallingCode}`,
              },
            },
            name: { first: data.name.first, last: data.name.last },
            pin: '6789',
            waId: componentState.waId,
          };
          addUserV2(addUserPayload)
            .then((res) => {
              const loginPayload: LoginUserPayload = {
                contact: {
                  phone: {
                    number: componentState.mobileNumber,
                    prefix: `+${componentState.countryCallingCode}`,
                  },
                },
                pin: '6789',
                waId: componentState.waId,
              };
              return loginUserV2(loginPayload);
            })
            .then((res) => {
              handleLoginUser(res);
            })
            .catch((err) => {});
        }
      } else {
        setButtonDisabled(true);
        setUserErrorText('');
        generateOTP(data, handleGenerateOtp);
      }
    }
  };
  const refCallback = useCallback(
    (InputRef) => (node: ReactNode) => {
      InputRef.current = node;
    },
    [],
  );

  return (
    <View px="2xl">
      <View mt="4xl">
        <Text size="3xl" mb="7xl" color="primary.200">
          {componentState.signUpScreenTitle}
        </Text>
        <Input
          state={userErrorText === '' ? InputStates.default : InputStates.error}
          inputMode="text"
          label="Full Name"
          placeholder="Your full name"
          helperText={userErrorText}
          value={userName}
          onChangeText={handleUserName}
          borderColor="primary.50"
          style={{ borderColor: 'white' }}
          onBlur={() => {
            setUserErrorText('');
          }}
          autoFocus
          autoCapitalize="words"
          returnKeyType="done"
          inputRef={refCallback(inputRef)}
          onFocus={validationClear}
        />
      </View>
      <View bottom="xl" style={{ paddingVertical: 60 }}>
        <Button
          size="lg"
          appearance="filled"
          status="primary"
          state={buttonDisabled ? InputStates.disabled : InputStates.active}
          onPress={onContinue}
        >
          Continue
        </Button>
      </View>
    </View>
  );
};

export default SignupScreen;
