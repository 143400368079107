/* eslint-disable import/prefer-default-export */

import { graphql, fetchQuery } from 'react-relay';
import { useEffect } from 'react';
import environment from '../relay/relayEnvironment';
import useAuthStore from '../stores/authStore';
import useUserStore, { UserRole } from '../stores/userStore';
import {
  getActiveBlackMembershipId,
  isCustomerBlackMember,
} from '../screens/new/plusMembership/helpers';
import { MembershipTypes } from '../utilities/helper';

const membershipQuery = graphql`
  query useBlackMembershipHelperQuery(
    $userId: MongoID!
    $addonsFilter: FilterFindManyaddonInput
  ) {
    findUserById(_id: $userId) {
      memberships {
        membershipAddonId
        status
      }
    }
    membershipConfig: findAddons(filter: $addonsFilter) {
      _id
      type
    }
  }
`;

const fetchMembershipData = (variables) => {
  return fetchQuery(environment, membershipQuery, variables, {
    force: true,
  }).toPromise();
};

export const useBlackMembershipHelper = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { id: userId, role } = useUserStore((state) => state);
  const setBlackMembership = useUserStore((state) => state.setBlackMembership);
  const { active: isCurrentlyActive } = useUserStore(
    (state) => state.blackMembership,
  );
  useEffect(() => {
    (async () => {
      if (isLoggedIn && role === UserRole.USER) {
        try {
          const { findUserById, membershipConfig } = await fetchMembershipData({
            userId,
            addonsFilter: {
              type: MembershipTypes.PLUS_MEMBERSHIP,
            },
          });
          const isCustomerABlackMember = isCustomerBlackMember(
            findUserById,
            membershipConfig,
          );
          const activeBlackMembershipId = getActiveBlackMembershipId(
            findUserById,
            membershipConfig,
          );
          if (isCustomerABlackMember && activeBlackMembershipId) {
            setBlackMembership(activeBlackMembershipId, true);
          } else {
            setBlackMembership('', false);
          }
        } catch (e) {
          console.log(e);
          // let the store persist what it was having earlier
        }
      } else if (isCurrentlyActive) setBlackMembership('', false);
    })();
  }, [isLoggedIn, role]);
};
