import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const cashierHomeScreenQuery = graphql`
  query cashierHomeScreenQuery($taskFilter: findTaskFilterInput!) {
    ...cashierHomeScreenQueryFindTaskFragment @arguments(filter: $taskFilter)
  }
`;

export const cashierHomeScreenFindTaskFragment = graphql`
  fragment cashierHomeScreenQueryFindTaskFragment on Query
  @refetchable(queryName: "cashierHomeScreenFindTaskQuery")
  @argumentDefinitions(filter: { type: "findTaskFilterInput!" }) {
    findTasks(filter: $filter) {
      _id
      vehicle {
        registration {
          plate
        }
      }
      taskType
      campus {
        _id
      }
      history {
        _id
        action
        actor
        event_at
      }
      isVehicleWhitelisted
      ETA {
        _id
        startAt
        delayAt
        originalPredictionTime
        pendingRecall
        available
        busy
      }
      parking {
        status
        parkingInvoice {
          timings {
            entryTime
            exitTime
            chargeTime
          }
          paymentDetails {
            isPaid
            mode
            focReason
            amount
          }
          charges {
            chargesBreakup {
              duration
              payable
            }
            discountAmount
            cashierDiscountAmount
            totalPayable
          }
        }
      }
      parkingId
    }
  }
`;
