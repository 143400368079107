import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

export const AddVehicle = (data, callback, errorCallBackAdd) => {
  const mutation = graphql`
    mutation vehicleApiAddUserMutation($record: addVehicleRecordInput!) {
      addVehicle(record: $record) {
        record {
          _id
          brand {
            name
            brandLogo
          }
          color
          registration {
            plate
          }
          ownerID
        }
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallBackAdd(err);
    },
  });
};

export const updateVehicle = (data, callback, errorCallBack, rowInfo) => {
  const mutation = graphql`
    mutation vehicleApiUpdateMutation(
      $id: MongoID!
      $record: updateVehicleRecordInput!
    ) {
      updateVehicle(_id: $id, record: $record) {
        record {
          _id
          brand {
            _id
            name
          }
          color
          registration {
            plate
          }
        }
      }
    }
  `;
  const variables = data;
  let errors = null;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      errors = error;
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallBack(err);
    },
    updater: (proxyStore, payload) => {
      if (errors === null) {
        const { brand, registration } = payload.updateVehicle.record;
        const { plate } = registration;
        const { ownerID } = variables.record;
        const r = proxyStore.get(
          `client:root:findVehicles(filter:{"ownerID":"${ownerID}"}):${rowInfo}`,
        );
        const r1 = proxyStore.get(
          `client:root:findVehicles(filter:{"ownerID":"${ownerID}"}):${rowInfo}:registration`,
        );
        r.setValue(brand, 'brand.name');
        r1.setValue(plate, 'plate');
      }
    },
  });
};
export const removeVehicle = (data, callback, errorCallBack) => {
  const mutation = graphql`
    mutation vehicleApiRemoveMutation($id: MongoID!) {
      removeVehicle(_id: $id) {
        recordId
      }
    }
  `;
  const variables = {
    id: data,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response, data);
    },
    onError: (err) => {
      errorCallBack(err);
    },
  });
};
export const findVehicleDetails = (data) => {
  const query = graphql`
    query vehicleApiQuery($filter: findVehiclesFilterInput!) {
      findVehicles(filter: $filter) {
        _id
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
        ownerID
      }
    }
  `;
  const variables = {
    filter: {
      ownerID: data.ownerID,
      registration: {
        plate: data.plateNumber,
      },
    },
  };

  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};
