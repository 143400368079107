import React, { useEffect, useState } from 'react';
import { Image, useWindowDimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import { Text, View } from '../../../../components/new';
import {
  dynamicHeightMaker,
  imageTransformation,
} from '../../../../utilities/helper';
import VideoWithThumbnail from '../../../../components/new/custom/VideoWithThumbnail';
import VideoPlayer from '../../../../components/new/custom/VideoPlayer';
import { colors, space } from '../../../../themes/new/theme';
import GradientBackground from '../../../../components/new/custom/GradientBackground';

const InvestmentHighlightsComp = ({
  data,
  selectedIndex,
  index,
  bgColor = '',
}) => {
  const { width } = useWindowDimensions();
  const [play, setPlay] = useState(false);
  const aspectRatio = 220 / 330;

  useEffect(() => {
    if (selectedIndex === index) {
      setPlay(true);
    } else {
      setPlay(false);
    }
  }, [selectedIndex]);

  const baseOptions = {
    vertical: false,
    width: width - 60,
    height: (width - 60) * aspectRatio,
  } as const;

  const gradientColors = [
    { offset: '0', color: colors.gradient[0], opacity: 0 },
    { offset: '1', color: colors.singletone.black, opacity: 1 },
  ];

  const RenderItem = ({ item }) => (
    <View key={`carousal_title${item?.title}`} ml="2xl">
      <View
        position="absolute"
        bottom={0}
        zIndex={2}
        height={dynamicHeightMaker(width - 60, aspectRatio)}
        width={width - 78}
      >
        <GradientBackground
          backgroundOpacity={1}
          gradientColors={gradientColors}
          flex={1}
          id="invest_highlights_gradient"
        />
      </View>
      <Image
        source={{
          uri: imageTransformation(
            item?.mediaURL ?? item?.thumbnailURL,
            width - 78,
            '1-1',
          ),
        }}
        style={{
          height: dynamicHeightMaker(width - 60, aspectRatio),
          width: width - 78,
          backgroundColor: colors.opacity[60],
        }}
      />
      <View
        position="absolute"
        left={space['2xl']}
        bottom={space['2xl']}
        zIndex={99}
      >
        {item?.title ? (
          <Text size="md" color="primary.500" weight="medium">
            {item?.title}
          </Text>
        ) : null}
        {item?.description ? (
          <Text
            size="xs"
            color="primary.400"
            mt="sm"
            numberOfLines={2}
            mr="2xl"
            flex={1}
          >
            {item?.description}
          </Text>
        ) : null}
      </View>
    </View>
  );

  const renderContent = ({ item, itemIndex }) => {
    if (!item?.media?.length) return null;
    return (
      <View
        alignItems="center"
        py="9xl+9xl"
        px="2xl"
        key={`Image_Frame_${item?._id}`}
        bg={
          bgColor === 'background.primary.base'
            ? itemIndex % 2 === 0
              ? 'background.primary.base'
              : 'background.secondary.base'
            : itemIndex % 2 === 0
            ? 'background.secondary.base'
            : 'background.primary.base'
        }
      >
        {item?.heading ? (
          <Text size="xs" color="primary.300" pb="lg" textAlign="center">
            {item?.heading}
          </Text>
        ) : null}
        {item?.subHeading ? (
          <Text size="2xl" color="primary.500" textAlign="center">
            {item?.subHeading}
          </Text>
        ) : null}
        <View mt="9xl">
          {item?.media?.length > 1 ? (
            <Carousel
              {...baseOptions}
              loop={false}
              style={{ width }}
              data={item?.media}
              scrollAnimationDuration={300}
              autoPlayInterval={3000}
              renderItem={RenderItem}
            />
          ) : item?.media?.[0]?.mediaType === 'VIDEO' ? (
            item?.media?.[0]?.autoPlayable ? (
              <VideoPlayer
                id={`Video_id${item?.media?.[0]?._id}`}
                url={item?.media?.[0]?.mediaURL}
                aspectRatio={data?.aspectRatio ?? 1.34}
                play={play}
                setPlay={setPlay}
                hideControls
                showPlayPause
                isLooping
                width={data?.width ?? width - 32}
                // videoBg={colors.backdrop['500']}
                footerTitle={item?.media?.[0]?.title}
                footerSubTitle={item?.media?.[0]?.description}
              />
            ) : (
              <VideoWithThumbnail
                aspectRatio={1.28}
                thumbnailWidth={width - 72}
                thumbnailSource={imageTransformation(
                  item?.media?.[0]?.thumbnailURL,
                  width - 72,
                )}
                videoSource={item?.media?.[0]?.mediaURL}
                onPress={() => null}
                videoTitle=""
                videoSubTitle=""
              />
            )
          ) : (
            <Image
              source={{
                uri: imageTransformation(
                  item?.media?.[0]?.mediaURL,
                  width - 32,
                ),
              }}
              style={{
                height: dynamicHeightMaker(width - 32, 1.33),
                width: width - 32,
                resizeMode: 'cover',
              }}
            />
          )}
        </View>
      </View>
    );
  };

  return (
    <View>
      {data?.data?.map((item, itemIndex) => renderContent({ item, itemIndex }))}
    </View>
  );
};

export default InvestmentHighlightsComp;
