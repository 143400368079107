import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import View from '../../basic/View/View';
import CheckBox from '../../primitive/Checkbox/CheckBox';
import Text from '../../primitive/Typography/Text/Text';
import Button from '../../primitive/Button/Button';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';

const CheckBoxGroup = ({
  size,
  icon,
  checkboxes,
  setCheckboxes,
  maxSelected,
  minSelected,
  collapsedCount,
  title,
  ...rest
}) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  // Remove default selected values if item is not available or sold out.
  checkboxes.map((item) => {
    if ((item.labelWarning || item.state === 'disabled') && item.checked) {
      item.checked = false;
    }
    return item;
  });

  if (
    maxSelected &&
    checkboxes.filter((item) => item.checked).length > maxSelected
  ) {
    let count = 0;
    checkboxes.forEach((element) => {
      if (element.checked && count < maxSelected) {
        count += 1;
      } else {
        element.checked = false;
      }
    });
  }

  const disabled = checkboxes.filter(
    (item) => item.labelWarning || item.state === 'disabled',
  );

  checkboxes = checkboxes.filter(
    (item) => !(item.labelWarning || item.state === 'disabled'),
  );

  disabled.map((item) => checkboxes.push(item));

  const toggleCheckbox = (id: React.Key, index: React.Key) => {
    const checkboxData = [...checkboxes];
    const checkedState = !checkboxData[index].checked;
    checkboxData[index].checked = checkedState;
    const selectedCheckBoxes = checkboxData.filter(
      (item) => item.checked === true,
    );
    if (
      maxSelected !== 0 &&
      checkedState &&
      selectedCheckBoxes.length > maxSelected
    ) {
      checkboxData[index].checked = !checkedState;
      // alert('You have already selected maximum items');
      dispatchSnackbar({
        msg: 'You have already selected maximum items',
        status: SnackbarStatus.info,
        version: SnackbarVersion.v2,
      });
    }

    if (minSelected !== 0 && selectedCheckBoxes.length < minSelected) {
      // console.log('You have not selected minimum items');
    }
    setCheckboxes(checkboxData);
  };

  const optionsText = (max, min) => {
    let text = '';
    if (max === min && min === 1) {
      text = `Choose any ${min}`;
    } else if (max === min && min !== 0) {
      text = `Choose any ${min}`;
    } else if (max === 0 && min === 0) {
      text = `Choose upto ${checkboxes.length}`;
    } else if (max > 0 && min === 0) {
      return `Choose upto ${maxSelected}`;
    } else if (max === 0 && min === 1) {
      return `Choose any ${minSelected}`;
    } else if (max === 0 && min > 1) {
      return `Choose any ${minSelected}`;
    } else {
      text = `Choose ${min} to ${max}`;
    }

    return text;
  };

  const [collapsedCnt, setCollapsedCnt] = useState(collapsedCount);

  useEffect(() => {
    setCollapsedCnt(collapsedCount);
  }, [collapsedCount]);

  useEffect(() => {}, [checkboxes]);
  return (
    <View>
      {title && (
        <View
          mb="lg"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <View flex={1}>
            <Text size="2xl" weight="medium" numberOfLines={2} pr="2xl">
              {title}
            </Text>
          </View>
          <View>
            <Text
              size="xs"
              numberOfLines={1}
              mr="lg"
              color="primary.100"
              weight={minSelected !== 0 ? 'medium' : 'regular'}
            >
              {minSelected !== 0 && (
                <Text
                  size="xs"
                  numberOfLines={1}
                  ellipsizemode="tail"
                  color="error.500"
                  weight={minSelected !== 0 ? 'medium' : 'regular'}
                >
                  *{' '}
                </Text>
              )}
              {optionsText(maxSelected, minSelected)}
            </Text>
          </View>
        </View>
      )}
      {checkboxes
        .sort((a, b) => {
          return Number(a.label) - Number(b.label);
        })
        .slice(0, collapsedCnt || checkboxes.length)
        .map(
          (
            item: {
              state: any;
              checked: any;
              id: React.Key;
              label: any;
              labelWarning: any;
              name: any;
            },
            index: React.Key,
          ) => (
            <View key={item.id || index} mb="lg">
              <CheckBox
                size={size}
                icon={icon}
                checked={item.checked ? item.checked : false}
                onPress={() => toggleCheckbox(item.id, index)}
                label={Number(item.label) > 0 ? item.label : ''}
                labelWarning={item.labelWarning ? item.labelWarning : ''}
                state={item.state ? item.state : 'active'}
              >
                {item.name}
              </CheckBox>
            </View>
          ),
        )}
      <View alignItems="flex-start">
        {collapsedCnt && collapsedCnt < checkboxes.length && (
          <Button
            mb="lg"
            size="lg"
            status="info"
            appearance="ghost"
            iconRight="expand-outline-300"
            onPress={() => setCollapsedCnt(checkboxes.length)}
          >
            +{checkboxes.length - collapsedCnt}{' '}
            {checkboxes.length - collapsedCnt === 1 ? 'Option' : 'Options'}
          </Button>
        )}
      </View>
    </View>
  );
};
CheckBoxGroup.propTypes = {
  maxSelected: PropTypes.number,
  minSelected: PropTypes.number,
  collapsedCount: PropTypes.number,
  checkboxes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.string,
  icon: PropTypes.string,
};

CheckBoxGroup.defaultProps = {
  maxSelected: 0, // 0 -> No Max Limit on Selections
  minSelected: 0, // 0 -> No Min Limit on Selections
  collapsedCount: 0,
  checkboxes: [],
  size: 'lg',
  icon: 'right',
};

export default CheckBoxGroup;
