import React from 'react';
import { FlatList, useWindowDimensions } from 'react-native';
import { Text, View } from '../../../components/new';
import { getTheme } from '../../../themes/new/theme';
import VideoOrImageComp from './VideoOrImageComp';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import useUserStore, { UserRole } from '../../../stores/userStore';
import useAuthStore from '../../../stores/authStore';

const OurParticipantSection = ({ data = [], eventId }) => {
  const { width } = useWindowDimensions();
  const { id } = useUserStore((state) => state);
  const { isLoggedIn } = useAuthStore((state) => state);

  return (
    <>
      <View mt="9xl" mx="2xl">
        <Text size="lg" color="primary.500" weight="medium">
          Hear From Our Participants
        </Text>
      </View>
      <FlatList
        data={data}
        style={{ marginTop: getTheme().space['4xl'] }}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item, index }) => {
          return (
            <View
              borderRadius="md"
              overflow="hidden"
              bg="background.primary.elevation"
              alignItems="center"
              width={220}
              mr="2xl"
              ml={index === 0 ? '2xl' : 0}
            >
              <VideoOrImageComp
                media={{
                  mediaURL: item?.media?.[0]?.mediaURL,
                  mediaType: item?.media?.[0]?.mediaType,
                  thumbnailURL: item?.media?.[0]?.thumbnailURL,
                }}
                aspectRatio={1.34}
                widthProp={220}
                imgStyleProps={{ width: 220, height: 295 }}
                title={item?.name}
                onPress={() => {
                  firebaseEventLogger('eventsHome__OurParticipant__Tap', {
                    buttonName: 'testimonials',
                    screenName: 'eventsHome',
                    userType: UserRole.USER,
                    interactionType: InteractionType.TAP,
                    eventId,
                    userId: id,
                    isLoggedIn,
                    sectionName: 'Testimonials',
                    mediaId: item?._id,
                  });
                }}
              />
            </View>
          );
        }}
      />
    </>
  );
};

export default OurParticipantSection;
