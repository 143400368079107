/**
 * @generated SignedSource<<bdc5d03f855b3196dce8ffbc8ac216d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type taskMetricsQuery$variables = {
  endTimeStamp: number;
  startTimeStamp: number;
};
export type taskMetricsQuery$data = {
  readonly getTaskMetrics: {
    readonly assignmentScore: number | null;
    readonly assignmentTime: number | null;
    readonly deliveryScore: number | null;
    readonly deliveryTime: number | null;
    readonly onboardingScore: number | null;
    readonly onboardingTime: number | null;
    readonly parkingScore: number | null;
    readonly parkingTime: number | null;
  } | null;
};
export type taskMetricsQuery = {
  response: taskMetricsQuery$data;
  variables: taskMetricsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endTimeStamp"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startTimeStamp"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endTimeStamp",
        "variableName": "endTimeStamp"
      },
      {
        "kind": "Variable",
        "name": "startTimeStamp",
        "variableName": "startTimeStamp"
      }
    ],
    "concreteType": "taskMetricsTC",
    "kind": "LinkedField",
    "name": "getTaskMetrics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assignmentScore",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assignmentTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliveryScore",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliveryTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingScore",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onboardingScore",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onboardingTime",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "taskMetricsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "taskMetricsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f30da1d8647cbb970b690c8c94e299d1",
    "id": null,
    "metadata": {},
    "name": "taskMetricsQuery",
    "operationKind": "query",
    "text": "query taskMetricsQuery(\n  $startTimeStamp: Int!\n  $endTimeStamp: Int!\n) {\n  getTaskMetrics(startTimeStamp: $startTimeStamp, endTimeStamp: $endTimeStamp) {\n    assignmentScore\n    assignmentTime\n    deliveryScore\n    deliveryTime\n    parkingScore\n    parkingTime\n    onboardingScore\n    onboardingTime\n  }\n}\n"
  }
};
})();

(node as any).hash = "518f9602eec194cad6bb841a8e0ce044";

export default node;
