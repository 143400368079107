import React from 'react';
import CustomLottieView from './CustomLottieView';
import View from '../basic/View/View';

const PaginationLoader = () => {
  return (
    <View justifyContent="center" alignItems="center">
      <CustomLottieView
        autoPlay
        loop
        source={require('../../../../assets/lottie/pagination-loader.json')}
        style={{ height: 140, width: 150 }}
        containerWidth={150}
      />
    </View>
  );
};

export default PaginationLoader;
