import React from 'react';
import { Placeholder, Fade } from 'rn-placeholder';
import { View } from '../../../../components/new';
import { RewardItemPlaceholder } from '../components';

const RewardsScreenPlacehodler = () => {
  return (
    <Placeholder Animation={Fade}>
      <View style={{ height: 720 }}>
        {/* <View px="2xl" pt="4xl" bg="primary.10" pb="2xl">
          <RewardPointsCardPlaceholder />
        </View> */}
        <View
          mt="4xl"
          style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            paddingHorizontal: 16,
          }}
        >
          <RewardItemPlaceholder />
          <RewardItemPlaceholder />
          <RewardItemPlaceholder />
          <RewardItemPlaceholder />
          <RewardItemPlaceholder />
          <RewardItemPlaceholder />
        </View>
      </View>
    </Placeholder>
  );
};

export default RewardsScreenPlacehodler;
