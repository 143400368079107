import React, { useState } from 'react';
import { Animated, StyleSheet, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { IconButton, TopNavigation, View } from '../../../../components/new';
import { Appearance } from '../../../../themes/new/helper';
import TransactionBg from '../TransactionBg';

interface ParallaxAnimationTransactionProps {
  maxHeight?: number;
  minHeight?: number;
  children: JSX.Element;
  onPressIcon?: () => void;
  leftIconName?: string;
  iconRight?: JSX.Element;
  AnimationContent: JSX.Element;
  bottomContent: JSX.Element;
}

const ParallaxAnimationTransaction = ({
  maxHeight,
  minHeight,
  children,
  onPressIcon,
  leftIconName,
  iconRight = <View />,
  AnimationContent = <View />,
  bottomContent = <View />,
}: ParallaxAnimationTransactionProps) => {
  const { width, height } = useWindowDimensions();
  const [topNavigationHeight, setTopNavigationHeight] = useState(0);
  const [topNavigationStatus, setTopNavigationStatus] = useState({
    level: 'none',
    appearance: 'ghost',
  });
  const { top } = useSafeAreaInsets();
  const onLayoutTopNavigation = ({ nativeEvent }) => {
    if (topNavigationHeight === 0) {
      setTopNavigationHeight(nativeEvent?.layout?.height);
    }
  };
  const HEADER_MAX_HEIGHT = React.useMemo(
    () => maxHeight || height * 0.35,
    [maxHeight],
  );
  const HEADER_MIN_HEIGHT = React.useMemo(() => minHeight || 0, [minHeight]);

  const scrollPositionY = React.useMemo(() => new Animated.Value(0), []);

  const HEADER_SCROLL_DISTANCE = React.useMemo(
    () => HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT,
    [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
  );

  // translating whole header view
  const headerTranslate = scrollPositionY.interpolate({
    inputRange: [0, HEADER_SCROLL_DISTANCE],
    outputRange: [0, HEADER_SCROLL_DISTANCE / 4],
    extrapolate: 'clamp',
  });

  // decreaing and increasing opacity of image on the basis of scroll distance
  const imageOpacity = scrollPositionY.interpolate({
    inputRange: [
      0,
      HEADER_SCROLL_DISTANCE / 6,
      HEADER_SCROLL_DISTANCE - (top + topNavigationHeight),
    ],
    outputRange: [1, 1, 0],
    extrapolate: 'clamp',
  });

  // translating image from its position
  const imageTranslate = scrollPositionY.interpolate({
    inputRange: [0, HEADER_SCROLL_DISTANCE],
    outputRange: [0, 100],
    extrapolate: 'clamp',
  });

  // listener to check if translated value reaches particular
  //  point and changing level & appearance of topNavigation
  headerTranslate.addListener(({ value }) => {
    if (value > 0 && topNavigationHeight > 0) {
      const scrollDistance =
        (HEADER_SCROLL_DISTANCE - topNavigationHeight - top) / 4 - 20;

      if (value >= scrollDistance) {
        setTopNavigationStatus({ level: '1', appearance: 'filled' });
      }

      if (value < scrollDistance) {
        setTopNavigationStatus({ level: 'none', appearance: 'ghost' });
      }
    } else {
      setTopNavigationStatus({ level: 'none', appearance: 'ghost' });
    }
  });
  return (
    <>
      <Animated.ScrollView
        scrollEventThrottle={1}
        showsVerticalScrollIndicator={false}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: { y: scrollPositionY },
              },
            },
          ],
          {
            useNativeDriver: true,
          },
        )}
        style={{
          backgroundColor: 'transparent',
        }}
        bounces={false}
      >
        <Animated.View
          style={[
            styles.header,
            { height: HEADER_SCROLL_DISTANCE },
            { transform: [{ translateY: headerTranslate }] },
          ]}
        >
          <>
            <Animated.View
              style={[
                styles.background,
                {
                  opacity: imageOpacity,
                  height: HEADER_MAX_HEIGHT,
                  transform: [{ translateY: imageTranslate }],
                },
              ]}
            >
              <TransactionBg showGreenBg>{AnimationContent}</TransactionBg>
            </Animated.View>
          </>
        </Animated.View>
        <View mt={HEADER_MAX_HEIGHT - 16}>{children}</View>
      </Animated.ScrollView>
      <View
        width="100%"
        position="absolute"
        top={0}
        onLayout={onLayoutTopNavigation}
        zIndex={2}
        bg={
          topNavigationStatus?.level === 'none' ? 'transparent' : 'primary.10'
        }
      >
        <TopNavigation
          IconLeft={
            leftIconName ? (
              <View flexDirection="row" alignItems="center">
                <IconButton
                  name="back-outline-300"
                  size="md"
                  appearance={Appearance.GHOST}
                  iconSize="xl"
                  iconColor="primary.500"
                  onPress={onPressIcon}
                />
              </View>
            ) : null
          }
          IconRight={iconRight}
          appearance={Appearance.GHOST}
          level="none"
          ChildrenElement={
            topNavigationStatus.level !== 'none' ? bottomContent : <></>
          }
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    // resizeMode: 'cover',
  },
});

export default ParallaxAnimationTransaction;
