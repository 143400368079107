/**
 * @generated SignedSource<<d3a2b7797cdcb62f9aabef8b4a30575c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeScreenQuery_photoMomentWidgetFragment$data = ReadonlyArray<{
  readonly assetId: string;
  readonly assetLink: string;
  readonly assetType: string;
  readonly clickedOn: any | null;
  readonly similarity: number | null;
  readonly thumbnail: string | null;
  readonly thumbnailId: string | null;
  readonly " $fragmentType": "homeScreenQuery_photoMomentWidgetFragment";
}>;
export type homeScreenQuery_photoMomentWidgetFragment$key = ReadonlyArray<{
  readonly " $data"?: homeScreenQuery_photoMomentWidgetFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeScreenQuery_photoMomentWidgetFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "homeScreenQuery_photoMomentWidgetFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assetId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assetLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clickedOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "similarity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailId",
      "storageKey": null
    }
  ],
  "type": "PhotoMomentsV3",
  "abstractKey": null
};

(node as any).hash = "08a1764fd3e718a0a612c73160bdc70d";

export default node;
