import { graphql } from 'react-relay';

// eslint-disable-next-line import/prefer-default-export
export const restaurantListingQuery = graphql`
  query restaurantListingQuery(
    $filter: findRestaurantsFilterInput!
    $includeFindUser: Boolean!
    $id: MongoID!
    $sort: SortConnectionrestaurants2Enum
    $count: Int
    $cursor: String
  ) {
    ...restaurantListingQueryFragment
      @arguments(filter: $filter, sort: $sort, count: $count, cursor: $cursor)

    findUserById(_id: $id) @include(if: $includeFindUser) {
      ...restaurantListingQuery_findUserFragment
    }
    findConfig {
      ...restaurantListingQuery_findCashbackFragment
    }
  }
`;

export const restaurantListingFragment = graphql`
  fragment restaurantListingQueryFragment on Query
  @refetchable(queryName: "restaurantListingFragmentQuery")
  @argumentDefinitions(
    filter: { type: "findRestaurantsFilterInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
    sort: { type: "SortConnectionrestaurants2Enum" }
  ) {
    findRestaurants2V2(
      filter: $filter
      sort: $sort
      first: $count
      after: $cursor
    ) @connection(key: "restaurantListingQueryFragment_findRestaurants2V2") {
      count
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          _id
          displayName
          logo
          menuId
          description {
            summary
            tagLine
            cusines {
              _id
              name
              primary
            }
            tags {
              name
              icon {
                iconName
                color
              }
              _id
            }
            cost2pax
          }
          overAllRating
          mediaGallery {
            _id
            sectionTitle
            description
            media {
              cover
              prime
              mediaURL
              mediaType
              sectionCover
              displayOrder
              coverVideo
            }
            displayOrder
          }
          payBillPercentage
          payBillCashback
          payBill {
            payEnabled
            pointsEnabled {
              redeem
              reward
            }
          }
        }
      }
    }
  }
`;

export const restaurantListingFindUserFragment = graphql`
  fragment restaurantListingQuery_findUserFragment on User {
    wallet {
      currentBalance
    }
  }
`;

export const restaurantListingFindCashbackFragment = graphql`
  fragment restaurantListingQuery_findCashbackFragment on config {
    billUploadConfig {
      cashbackPercentage
    }
  }
`;
