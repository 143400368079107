import React from 'react';
import { ScrollView } from 'react-native';
import {
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import { navigateBack } from '../../utilities/helper';
import useUserStore from '../../stores/userStore';

const TicketTnCScreen = ({ navigation, route }) => {
  const { details = [] } = route.params;
  const role = useUserStore((state) => state.role);

  return (
    <Layout level={2}>
      <TopNavigation
        title="Terms and Conditions"
        textColor="primary.500"
        textSize="md"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="sm"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
      />
      <View flex={1}>
        <ScrollView>
          <View mt="7xl" px="2xl">
            {details.length &&
              details.map((d) => (
                <View mb="3xl" pr="3xl" pl="lg" flexDirection="row">
                  <Text
                    mt="lg"
                    alignSelf="flex-start"
                    color="primary.200"
                    style={{ fontSize: 8 }}
                  >{`\u25CF`}</Text>
                  <Text ml="lg" size="sm" color="primary.200">
                    {d}
                  </Text>
                </View>
              ))}
          </View>
        </ScrollView>
      </View>
    </Layout>
  );
};

export default TicketTnCScreen;
