import React, { useEffect, useRef } from 'react';
import { TouchableOpacity } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Divider, Icon, Text, View } from '../../../components/new';
import { FaqScreenData } from '../../../stores/commonStore';
import { FontWeights } from '../../../themes/new/helper';

interface FaqItemProps extends FaqScreenData {
  isOpen: boolean;
  onPress: (index: number) => void;
}

const FaqItem = (props: FaqItemProps) => {
  const { isOpen, onPress } = props;
  const contentHeight = useRef<number | null>(null);
  const height = useSharedValue(isOpen ? contentHeight.current || 0 : 0);

  const onToggleAnimation = () => {
    // Toggle the height value based on isExpanded
    height.value = isOpen ? 0 : contentHeight.current || 0;
    onPress(props.index);
  };

  const animationStyle = useAnimatedStyle(() => {
    return {
      height: withTiming(height.value, { duration: 300 }),
    };
  });

  const onContentLayout = (event: any) => {
    // Measure the content height when it's laid out
    contentHeight.current = event.nativeEvent.layout.height;
    // If it's currently expanded, update the height immediately
    if (isOpen) {
      height.value = contentHeight.current || 0;
    }
  };

  useEffect(() => {
    if (!props.isOpen) {
      height.value = 0;
    }
  }, [props.isOpen]);

  return (
    <View>
      <TouchableOpacity
        onPress={onToggleAnimation}
        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Text
          size="md"
          color={props?.isOpen ? 'primary.400' : 'primary.300'}
          weight={props?.isOpen ? FontWeights.MEDIUM : FontWeights.REGULAR}
          mb={12}
          style={{ maxWidth: '80%' }}
        >
          {props.title}
        </Text>
        <Icon
          size="2xl"
          shape="square"
          color="primary.500"
          name={!isOpen ? 'add-outline-300' : 'subtract-outline-300'}
        />
      </TouchableOpacity>
      <View
        onLayout={onContentLayout}
        style={{ position: 'absolute', top: -40000 }}
      >
        <Text size="sm" color="primary.400" flex={1}>
          {props.about}
        </Text>
      </View>
      <Animated.View style={[{ overflow: 'hidden' }, animationStyle]}>
        <Text size="sm" color="primary.200" flex={1}>
          {props.about}
        </Text>
      </Animated.View>
      <Divider level={1} mb={18} mt={isOpen ? 18 : 0} />
    </View>
  );
};

export default FaqItem;
