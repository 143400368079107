import React from 'react';
import { Card, View, Text, Icon } from '../../../../components/new';
import TaskCard from '../../../../components/new/primitive/TaskCard/TaskCard';
import RectangularTimer from '../../../../components/new/primitive/Timer/RectangularTimer';
import { TaskDataType } from '../types';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';

const TaskInfoCard = ({
  date,
  timing,
  valetId,
  location,
  tagId,
  vehicleNumber,
}: TaskDataType) => {
  const renderValetId = (id) => {
    return id ? (
      <View
        borderRadius="full"
        borderWidth="xs"
        borderColor="primary.100"
        py="sm"
        px="md"
        bg="primary.50"
      >
        <Text size="sm" color="primary.500">
          {id}
        </Text>
      </View>
    ) : (
      <></>
    );
  };

  return (
    <Card p="none" m="none" mb="2xl">
      <TaskCard
        heading={tagId ? `${vehicleNumber} (#${tagId})` : vehicleNumber}
        subHeading={date}
        topRightCorner={renderValetId(valetId)}
        shadow="md"
        bottomRightCorner={
          <View flexDirection="row" alignItems="center">
            <Icon size="md" name="locationon_outline" color="primary.400" />
            <Text size="xs" ml="xs">
              {location}
            </Text>
          </View>
        }
        bottomLeftCorner={
          <View flexDirection="row">
            <View mr="lg">
              <RectangularTimer
                title1={timing[0].label}
                title2={timing[0].time}
                title2Color={timing[0].delayed ? 'error.500' : 'success.500'}
              />
            </View>
            <View>
              <RectangularTimer
                title1={timing[1].label}
                title2={timing[1].time}
                title2Color={timing[1].delayed ? 'error.500' : 'success.500'}
              />
            </View>
          </View>
        }
        swipeEnabled={false}
      />
    </Card>
  );
};

export default TaskInfoCard;
