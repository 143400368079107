/* eslint-disable no-nested-ternary */
import React from 'react';
import { Image } from 'react-native';
import {
  DateFormatsEnum,
  dateDiffInDays,
  getFormatedDate,
} from '../../../utilities/helper';
import { Text, View, Pressable } from '../index';

const BookingCard = (props) => {
  const { imageURL, reward, expireAt, isExpired, isCancelled, onPress } =
    props ?? {};
  const date = new Date(expireAt);
  const today = new Date();
  const difference = dateDiffInDays(today, date);

  const shadowProps =
    isExpired || isCancelled
      ? { borderColor: 'primary.50' }
      : { borderColor: 'transparent', shadow: 'sm' };

  return (
    <View
      flex={1}
      borderRadius="md"
      borderWidth="xs"
      bg="background.primary.elevation"
      {...shadowProps}
    >
      <Pressable
        style={{ flex: 1 }}
        onPress={() => {
          if (!isExpired) {
            onPress();
          }
        }}
      >
        {isCancelled && (
          <View
            zIndex={2}
            justifyContent="center"
            top="50%"
            style={{ transform: [{ translateY: -20 }] }}
          >
            <View width="100%" py="lg" alignItems="center" bg="error.50">
              <Text textAlign="center" size="md" color="error.500">
                Cancelled
              </Text>
            </View>
          </View>
        )}
        <View p="2xl" justifyContent="center" alignItems="center">
          {isExpired || isCancelled ? (
            <View
              position="absolute"
              zIndex={1}
              width="100%"
              height="100%"
              backgroundColor="primary.10"
              opacity={0.7}
            />
          ) : (
            <></>
          )}

          <View width="100%" pl="3xl" pr="sm+md">
            <Image
              source={{
                uri: imageURL,
              }}
              style={{
                width: '100%',
                aspectRatio: 1.09,
                resizeMode: 'cover',
              }}
            />
          </View>
          <Text
            mt="2xl"
            size="lg"
            color="primary.500"
            weight="medium"
            numberOfLines={1}
            ellipsizemode="tail"
          >
            {reward}
          </Text>
          {isCancelled ? (
            <></>
          ) : difference <= 5 && difference >= 0 && !isExpired ? (
            <Text mt="lg" size="xs" color="warning.500" numberOfLines={1}>
              Expires
              {difference > 1
                ? ` in ${difference} days`
                : difference === 0
                ? ` today`
                : ` in ${difference} day`}
            </Text>
          ) : (
            <Text mt="lg" size="xs" color="primary.100" numberOfLines={1}>
              {isExpired ? `Expired on ${expireAt}` : `Valid till ${expireAt}`}
            </Text>
          )}
        </View>
        {isExpired ? (
          <View
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            alignItems="center"
            justifyContent="center"
          >
            <View
              width="100%"
              alignItems="center"
              justifyContent="center"
              backgroundColor="primary.50"
              py="lg"
            >
              <Text color="primary.100" size="md">
                Expired
              </Text>
            </View>
          </View>
        ) : (
          <></>
        )}
      </Pressable>
    </View>
  );
};

export default BookingCard;
