export const enum ThemeVariables {
  APPEARANCE = 'appearance',
  STATE = 'state',
  STATUS = 'status',
  SIZE = 'size',
  SHAPE = 'shape',
  LEVEL = 'level',
  CATEGORY = 'category',
}
export const enum Appearance {
  GHOST = 'ghost',
  FILLED = 'filled',
  OUTLINE = 'outline',
}

export const enum State {
  ACTIVE = 'active',
  PRESSED = 'pressed',
  DISABLED = 'disabled',
  SELECTED = 'selected',
}

export const enum Shape {
  ROUNDED = 'rounded',
  SQUARE = 'square',
  RECTANGLE = 'rectangle',
}

export const enum Status {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  GOLD = 'gold',
}

export const enum ResStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export const enum FontWeights {
  REGULAR = 'regular',
  MEDIUM = 'medium',
}

export const enum Directions {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}
