import React, { useRef } from 'react';
import { useWindowDimensions } from 'react-native';
import Button from '../../primitive/Button/Button';
import Gradient from './components/Gradient';
import Divider from './components/Divider';
import View from '../../basic/View/View';
import Header from './components/Header';
import Label from './components/Label';
import { handleDirections } from '../../../../utilities/handleLinking';
import PartnersBottomSheet from './components/PartnersBottomSheet';

type LocationType = { location: string; lat: string; long: string };
export interface BaseTicketProps {
  baseWidth?: string | number;
  headerTitle: string;
  headerImage: string;
  customerName: string;
  ticketId: string;
  expiryDate: string;
  redeemableAt: LocationType[];
  MoreLabels?: JSX.Element;
  BottomContent?: React.ReactNode;
  cutoutCircleClr?: string;
}

const BaseTicket = ({
  baseWidth,
  headerTitle,
  headerImage,
  customerName,
  ticketId,
  expiryDate,
  redeemableAt,
  cutoutCircleClr,
  MoreLabels,
  BottomContent,
}: BaseTicketProps) => {
  const bsRef = useRef();
  const { width } = useWindowDimensions();
  const isMultiplePartners = redeemableAt.length > 1;
  const locationTitle = isMultiplePartners
    ? `${redeemableAt.length} Partners`
    : redeemableAt[0]?.location ?? '';
  const ticketWidth = baseWidth ?? Math.min(390, Math.max(width, 350));

  const openPartnersBottomSheet = () => {
    bsRef.current?.show();
  };

  return (
    <View width={ticketWidth} p="2xl" flex={1} minHeight={550}>
      <Gradient>
        <View flex={1}>
          <View px="2xl">
            <Header title={headerTitle} image={headerImage} />
            <View
              mb="2xl"
              mt="2xl"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Label title="Customer Name" text={customerName} />
              <Label position="RIGHT" title="Ticket ID" text={ticketId} />
            </View>
            <View
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Label title="Redeemable At" text={locationTitle} />
              <Label position="RIGHT" title="Expires On" text={expiryDate} />
            </View>
            <View mb="2xl" alignItems="flex-start">
              {isMultiplePartners ? (
                <Button
                  p="0"
                  appearance="ghost"
                  status="info"
                  onPress={openPartnersBottomSheet}
                >
                  View Partners
                </Button>
              ) : (
                <Button
                  p="0"
                  appearance="ghost"
                  status="info"
                  onPress={() => openMap(redeemableAt[0])}
                >
                  Open In Maps
                </Button>
              )}
            </View>
            {MoreLabels}
          </View>
          <Divider circleBg={cutoutCircleClr} />
          <View flex={1}>{BottomContent}</View>
        </View>
      </Gradient>
      <PartnersBottomSheet
        partnerDetails={isMultiplePartners ? redeemableAt : []}
        ref={bsRef}
      />
    </View>
  );
};

export default BaseTicket;

const openMap = (redeemableAt: LocationType) => {
  if (redeemableAt) {
    handleDirections(redeemableAt.lat, redeemableAt.long);
  }
};
