/**
 * @generated SignedSource<<72a826b80fa8b869b4ddb0319a198c5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumbillUploadStatus = "CREATED" | "MATCHED" | "PROCESSED" | "PROCESSING" | "PROCESSING_FAILED" | "UNMATCHED" | "UNVERIFIED" | "VERIFICATION_FAILED" | "VERIFIED" | "%future added value";
export type EnumbillUploadUserInputsOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type createBillInput = {
  userInputs?: billUploadUserInputsInput | null;
};
export type billUploadUserInputsInput = {
  billImage?: billUploadUserInputsBillImageInput | null;
  orderId: any;
  orderType: EnumbillUploadUserInputsOrderType;
  priceDetails?: billUploadUserInputsPriceDetailsInput | null;
};
export type billUploadUserInputsPriceDetailsInput = {
  netAmount: number;
};
export type billUploadUserInputsBillImageInput = {
  bucket: string;
  key: string;
};
export type scanBillApiCreateBillMutation$variables = {
  record: createBillInput;
};
export type scanBillApiCreateBillMutation$data = {
  readonly createBill: {
    readonly record: {
      readonly _id: any;
      readonly ocrResponseParsed: {
        readonly address: {
          readonly addressBlock: string | null;
          readonly city: string | null;
          readonly full: string | null;
          readonly street: string | null;
          readonly zipCode: string | null;
        } | null;
        readonly bill: {
          readonly billId: string | null;
          readonly date: any | null;
          readonly finalAmount: number | null;
          readonly subTotal: number | null;
        } | null;
        readonly vendor: {
          readonly gstNumber: string | null;
          readonly name: string | null;
        } | null;
      } | null;
      readonly processing: {
        readonly msg: string;
        readonly success: boolean;
      } | null;
      readonly status: EnumbillUploadStatus | null;
    } | null;
  } | null;
};
export type scanBillApiCreateBillMutation = {
  response: scanBillApiCreateBillMutation$data;
  variables: scanBillApiCreateBillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOnebillUploadPayload",
    "kind": "LinkedField",
    "name": "createBill",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "billUpload",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "billUploadProcessing",
            "kind": "LinkedField",
            "name": "processing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "success",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "msg",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "billUploadOcrResponseParsed",
            "kind": "LinkedField",
            "name": "ocrResponseParsed",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "billUploadOcrResponseParsedAddress",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "full",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zipCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressBlock",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "billUploadOcrResponseParsedVendor",
                "kind": "LinkedField",
                "name": "vendor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gstNumber",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "billUploadOcrResponseParsedBill",
                "kind": "LinkedField",
                "name": "bill",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "finalAmount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "scanBillApiCreateBillMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "scanBillApiCreateBillMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fefebe44d0e10748c98a243f2a92c4a1",
    "id": null,
    "metadata": {},
    "name": "scanBillApiCreateBillMutation",
    "operationKind": "mutation",
    "text": "mutation scanBillApiCreateBillMutation(\n  $record: createBillInput!\n) {\n  createBill(record: $record) {\n    record {\n      _id\n      status\n      processing {\n        success\n        msg\n      }\n      ocrResponseParsed {\n        address {\n          full\n          street\n          city\n          zipCode\n          addressBlock\n        }\n        vendor {\n          name\n          gstNumber\n        }\n        bill {\n          date\n          billId\n          subTotal\n          finalAmount\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3abfb3e2a81aca743f5a1b7195212a47";

export default node;
