/**
 * @generated SignedSource<<a6d081e40726c3c37f732436edc9b853>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Enumrestaurants2Amenities = "Bar_Area" | "Buffet" | "Catering" | "Charging_Point" | "Coat_Check" | "Dance_Floor" | "Delivery" | "Event_Space" | "Family_Play_Area" | "Fine_Dining" | "Karaoke" | "Kids_Menu" | "Late_Night_Dining" | "Live_Cooking_Stations" | "Live_Sports_Screening" | "Meeting_Rooms" | "On_Site_Parking" | "Outdoor_Barbecue" | "Outdoor_Coolers" | "Outdoor_Heaters" | "Outdoor_Seating" | "Patio" | "Photobooth" | "Private_Dining" | "Pure_Veg" | "Reservations" | "Rooftop_Dining" | "Serves_Alcohol" | "Smoking_Area" | "Takeout" | "Valet_Parking" | "Wheelchair_Accessible" | "Wifi" | "%future added value";
export type Enumrestaurants2Occasion = "celebration" | "corporate" | "date_night" | "reunion" | "%future added value";
export type Enumrestaurants2Type = "RESTAURANT" | "STORE" | "%future added value";
export type SortFindManyrestaurants2Input = "CASHBACK_ASC" | "CASHBACK_DESC" | "COST_ASC" | "COST_DESC" | "DISCOUNT_ASC" | "DISCOUNT_DESC" | "DISPLAYNAME_ASC" | "DISPLAYNAME_DESC" | "RATING_ASC" | "RATING_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type findRestaurantsFilterInput = {
  _id?: any | null;
  amenities?: ReadonlyArray<Enumrestaurants2Amenities | null> | null;
  campus: FilterFindManyrestaurants2CampusInput;
  cusines?: ReadonlyArray<string | null> | null;
  imageSection?: string | null;
  occasion?: ReadonlyArray<Enumrestaurants2Occasion | null> | null;
  openHours?: string | null;
  outdoorAndEventFlag?: boolean | null;
  outdoorSeating?: boolean | null;
  overAllRating?: number | null;
  ownerId?: any | null;
  pureVeg?: boolean | null;
  search?: findRestaurantsFilterInputSearch | null;
  serveAlcohol?: boolean | null;
  type?: Enumrestaurants2Type | null;
  withOffer?: boolean | null;
};
export type FilterFindManyrestaurants2CampusInput = {
  _id: any;
};
export type findRestaurantsFilterInputSearch = {
  restaurantTitle?: string | null;
};
export type storeListQuery$variables = {
  filter: findRestaurantsFilterInput;
  id: any;
  includeFindUser: boolean;
  sort?: SortFindManyrestaurants2Input | null;
};
export type storeListQuery$data = {
  readonly findRestaurants2: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"storeListFragment">;
  }>;
  readonly findUserById?: {
    readonly wallet: {
      readonly currentBalance: number | null;
    } | null;
  } | null;
};
export type storeListQuery = {
  response: storeListQuery$data;
  variables: storeListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeFindUser"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v5 = {
  "condition": "includeFindUser",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "_id",
          "variableName": "id"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "findUserById",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserWallet",
          "kind": "LinkedField",
          "name": "wallet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentBalance",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "storeListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "restaurants2",
        "kind": "LinkedField",
        "name": "findRestaurants2",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "storeListFragment"
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "storeListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "restaurants2",
        "kind": "LinkedField",
        "name": "findRestaurants2",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "menuId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2Description",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "summary",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagLine",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2DescriptionCusines",
                "kind": "LinkedField",
                "name": "cusines",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primary",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2DescriptionTags",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "restaurants2DescriptionTagsIcon",
                    "kind": "LinkedField",
                    "name": "icon",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iconName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cost2pax",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "overAllRating",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2MediaGallery",
            "kind": "LinkedField",
            "name": "mediaGallery",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sectionTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2MediaGalleryMedia",
                "kind": "LinkedField",
                "name": "media",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cover",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediaURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediaType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sectionCover",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payBillPercentage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2PayBill",
            "kind": "LinkedField",
            "name": "payBill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2PayBillPointsEnabled",
                "kind": "LinkedField",
                "name": "pointsEnabled",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "redeem",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reward",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "d1f19998ac284b8c51e613f73f9b69b0",
    "id": null,
    "metadata": {},
    "name": "storeListQuery",
    "operationKind": "query",
    "text": "query storeListQuery(\n  $filter: findRestaurantsFilterInput!\n  $includeFindUser: Boolean!\n  $id: MongoID!\n  $sort: SortFindManyrestaurants2Input\n) {\n  findRestaurants2(filter: $filter, sort: $sort) {\n    ...storeListFragment\n  }\n  findUserById(_id: $id) @include(if: $includeFindUser) {\n    wallet {\n      currentBalance\n    }\n  }\n}\n\nfragment storeListFragment on restaurants2 {\n  _id\n  displayName\n  logo\n  menuId\n  description {\n    summary\n    tagLine\n    cusines {\n      _id\n      name\n      primary\n    }\n    tags {\n      name\n      icon {\n        iconName\n        color\n      }\n      _id\n    }\n    cost2pax\n  }\n  overAllRating\n  mediaGallery {\n    _id\n    sectionTitle\n    description\n    media {\n      cover\n      prime\n      mediaURL\n      mediaType\n      sectionCover\n      displayOrder\n    }\n    displayOrder\n  }\n  payBillPercentage\n  payBill {\n    payEnabled\n    pointsEnabled {\n      redeem\n      reward\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf2e09e88231769f158cf94302e904ee";

export default node;
