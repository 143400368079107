import React from 'react';
import { Placeholder, Fade } from 'rn-placeholder';
import { View } from '../../../../components/new';
import PointsCardPlacholder from '../components/PointsCardPlacholder';
import PointItemPlaceholder from '../components/PointItemPlaceholder';

const PointsScreenPlacehodler = () => {
  return (
    <Placeholder Animation={Fade}>
      <View style={{ height: 720 }}>
        <View px="2xl" pt="4xl" bg="primary.10" mb="4xl">
          <PointsCardPlacholder />
        </View>
        <View mt="4xl" px="2xl">
          <PointItemPlaceholder />
          <PointItemPlaceholder />
          <PointItemPlaceholder />
          <PointItemPlaceholder />
          <PointItemPlaceholder />
          <PointItemPlaceholder />
        </View>
      </View>
    </Placeholder>
  );
};

export default PointsScreenPlacehodler;
