/**
 * @generated SignedSource<<ba66b13aeed6b816e60fac7a9c2349d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Enumrestaurants2Amenities = "Bar_Area" | "Buffet" | "Catering" | "Charging_Point" | "Coat_Check" | "Dance_Floor" | "Delivery" | "Event_Space" | "Family_Play_Area" | "Fine_Dining" | "Karaoke" | "Kids_Menu" | "Late_Night_Dining" | "Live_Cooking_Stations" | "Live_Sports_Screening" | "Meeting_Rooms" | "On_Site_Parking" | "Outdoor_Barbecue" | "Outdoor_Coolers" | "Outdoor_Heaters" | "Outdoor_Seating" | "Patio" | "Photobooth" | "Private_Dining" | "Pure_Veg" | "Reservations" | "Rooftop_Dining" | "Serves_Alcohol" | "Smoking_Area" | "Takeout" | "Valet_Parking" | "Wheelchair_Accessible" | "Wifi" | "%future added value";
export type Enumrestaurants2DescriptionCusinesName = "american" | "asian" | "bakery" | "bar" | "bengali" | "beverages" | "bihari" | "bubble_tea" | "cafe" | "chinese" | "coastal_cuisine" | "coffee" | "continental" | "desserts" | "european" | "fastfood" | "french" | "goan" | "greek" | "healthy" | "himalayan" | "ice_cream" | "indian" | "italian" | "japanese" | "kashmiri" | "korean" | "lebanese" | "malaysian" | "mediterranean" | "mexican" | "momos" | "mughlai" | "north_eastern" | "north_indian" | "pizza" | "punjabi" | "rajasthani" | "rolls" | "russian" | "salad" | "seafood" | "shake" | "south_indian" | "sushi" | "thai" | "turkish" | "vietnamese" | "waffle" | "%future added value";
export type Enumrestaurants2DescriptionTagsIconColor = "error" | "info" | "success" | "%future added value";
export type Enumrestaurants2MediaGalleryMediaMediaType = "image" | "video" | "%future added value";
export type Enumrestaurants2MediaGallerySectionTitle = "Ambience" | "Awards___Recognitions" | "Food" | "Founder_Story" | "%future added value";
export type Enumrestaurants2OpenHoursDay = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "%future added value";
export type Enumrestaurants2SpecialityIconColor = "error" | "info" | "success" | "%future added value";
export type Enumrestaurants2SpecialityName = "BBQ" | "Brunch" | "DJ" | "Farm_to_Table" | "Fusion_Cocktails" | "Fusion_Food" | "Grilled_Food" | "Happy_Hour" | "Healthy_Food" | "Interactive_Dining" | "Live_Kitchen" | "Live_Music" | "Organic_Products" | "Pet_Friendly" | "Seasonal_Menu_Items" | "Vegan" | "Wine_Selection" | "%future added value";
export type Enumrestaurants2Type = "RESTAURANT" | "STORE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type restaurantDetail_findRestaurants2$data = ReadonlyArray<{
  readonly _id: any;
  readonly address: {
    readonly _id: any | null;
    readonly addressLine1: string;
    readonly geo: {
      readonly lat: string;
      readonly long: string;
    };
  };
  readonly amenities: ReadonlyArray<Enumrestaurants2Amenities | null> | null;
  readonly campus: {
    readonly _id: any;
  } | null;
  readonly contact: {
    readonly phone: ReadonlyArray<{
      readonly number: string;
      readonly prefix: string;
    } | null> | null;
  } | null;
  readonly description: {
    readonly cost2pax: number | null;
    readonly cusines: ReadonlyArray<{
      readonly _id: any | null;
      readonly name: Enumrestaurants2DescriptionCusinesName | null;
      readonly primary: boolean | null;
    } | null> | null;
    readonly summary: string | null;
    readonly tagLine: ReadonlyArray<string | null> | null;
    readonly tags: ReadonlyArray<{
      readonly _id: any | null;
      readonly icon: {
        readonly color: Enumrestaurants2DescriptionTagsIconColor | null;
        readonly iconName: string | null;
      } | null;
      readonly name: string | null;
    } | null> | null;
  };
  readonly displayName: string;
  readonly externalLinks: ReadonlyArray<{
    readonly _id: any | null;
    readonly posterURL: string;
    readonly publisher: string | null;
    readonly readingTime: number | null;
    readonly title: string;
    readonly webURL: string;
  } | null> | null;
  readonly logo: string | null;
  readonly mediaGallery: ReadonlyArray<{
    readonly _id: any | null;
    readonly description: string | null;
    readonly displayOrder: number;
    readonly media: ReadonlyArray<{
      readonly _id: any | null;
      readonly cover: boolean | null;
      readonly coverVideo: boolean | null;
      readonly description: string | null;
      readonly displayOrder: number;
      readonly mediaType: Enumrestaurants2MediaGalleryMediaMediaType;
      readonly mediaURL: string;
      readonly prime: boolean | null;
      readonly sectionCover: boolean | null;
      readonly title: string | null;
    } | null> | null;
    readonly sectionTitle: Enumrestaurants2MediaGallerySectionTitle;
  } | null> | null;
  readonly menu: {
    readonly menu: ReadonlyArray<{
      readonly _id: any | null;
      readonly sectionTitle: string;
    } | null> | null;
    readonly restaurantId: any | null;
  } | null;
  readonly menuId: any | null;
  readonly openHours: ReadonlyArray<{
    readonly closesAt: string | null;
    readonly day: Enumrestaurants2OpenHoursDay | null;
    readonly opensAt: string | null;
  } | null> | null;
  readonly openStatus: {
    readonly status: string | null;
  } | null;
  readonly overAllRating: number | null;
  readonly payBill: {
    readonly payEnabled: boolean | null;
    readonly pointsEnabled: {
      readonly redeem: boolean | null;
      readonly reward: boolean | null;
    } | null;
  } | null;
  readonly payBillCashback: number | null;
  readonly popularMenuItems: ReadonlyArray<{
    readonly id: string | null;
    readonly images: ReadonlyArray<{
      readonly _id: string | null;
      readonly mediaUrl: string | null;
      readonly type: string | null;
    } | null> | null;
    readonly title: string | null;
  } | null> | null;
  readonly ratingTagLine: string | null;
  readonly ratings: ReadonlyArray<{
    readonly _id: any | null;
    readonly displayIcon: string;
    readonly platform: string;
    readonly ratingCount: number | null;
    readonly ratingScore: number | null;
  } | null> | null;
  readonly speciality: ReadonlyArray<{
    readonly icon: {
      readonly color: Enumrestaurants2SpecialityIconColor | null;
      readonly iconName: string | null;
    } | null;
    readonly name: Enumrestaurants2SpecialityName | null;
  } | null> | null;
  readonly type: Enumrestaurants2Type;
  readonly " $fragmentType": "restaurantDetail_findRestaurants2";
}>;
export type restaurantDetail_findRestaurants2$key = ReadonlyArray<{
  readonly " $data"?: restaurantDetail_findRestaurants2$data;
  readonly " $fragmentSpreads": FragmentRefs<"restaurantDetail_findRestaurants2">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "iconName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "color",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionTitle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "restaurantDetail_findRestaurants2",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "menuId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2Description",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tagLine",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2DescriptionCusines",
          "kind": "LinkedField",
          "name": "cusines",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primary",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2DescriptionTags",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "restaurants2DescriptionTagsIcon",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cost2pax",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amenities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overAllRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2OpenHours",
      "kind": "LinkedField",
      "name": "openHours",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "day",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "opensAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closesAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2Ratings",
      "kind": "LinkedField",
      "name": "ratings",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayIcon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratingScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratingCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ratingTagLine",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2AddressGeo",
          "kind": "LinkedField",
          "name": "geo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "long",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2Campus",
      "kind": "LinkedField",
      "name": "campus",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2MediaGallery",
      "kind": "LinkedField",
      "name": "mediaGallery",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2MediaGalleryMedia",
          "kind": "LinkedField",
          "name": "media",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v6/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaURL",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sectionCover",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cover",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverVideo",
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2ExternalLinks",
      "kind": "LinkedField",
      "name": "externalLinks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readingTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "posterURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publisher",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2Contact",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2ContactPhone",
          "kind": "LinkedField",
          "name": "phone",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prefix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "openStatus",
      "kind": "LinkedField",
      "name": "openStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Menu",
      "kind": "LinkedField",
      "name": "menu",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "restaurantId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MenuMenu",
          "kind": "LinkedField",
          "name": "menu",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "popularDishTypeOutput",
      "kind": "LinkedField",
      "name": "popularMenuItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "popularDishMediaTypeOutput",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaUrl",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2PayBill",
      "kind": "LinkedField",
      "name": "payBill",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2PayBillPointsEnabled",
          "kind": "LinkedField",
          "name": "pointsEnabled",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "redeem",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reward",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payBillCashback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2Speciality",
      "kind": "LinkedField",
      "name": "speciality",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2SpecialityIcon",
          "kind": "LinkedField",
          "name": "icon",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "restaurants2",
  "abstractKey": null
};
})();

(node as any).hash = "f21a9c1b6fd856cfdbb88c5357fae73e";

export default node;
