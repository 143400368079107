/**
 * @generated SignedSource<<aa2d9a22ff026e70c1910537aa18820a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumbillUploadStatus = "CREATED" | "MATCHED" | "PROCESSED" | "PROCESSING" | "PROCESSING_FAILED" | "UNMATCHED" | "UNVERIFIED" | "VERIFICATION_FAILED" | "VERIFIED" | "%future added value";
export type scanBillApiSubmitBillMutation$variables = {
  id: any;
};
export type scanBillApiSubmitBillMutation$data = {
  readonly submitBill: {
    readonly record: {
      readonly _id: any;
      readonly invoiceId: any | null;
      readonly ocrResponseParsed: {
        readonly address: {
          readonly addressBlock: string | null;
          readonly city: string | null;
          readonly full: string | null;
          readonly street: string | null;
          readonly zipCode: string | null;
        } | null;
        readonly bill: {
          readonly billId: string | null;
          readonly date: any | null;
          readonly finalAmount: number | null;
          readonly subTotal: number | null;
        } | null;
        readonly vendor: {
          readonly gstNumber: string | null;
          readonly name: string | null;
        } | null;
      } | null;
      readonly processing: {
        readonly msg: string;
        readonly success: boolean;
      } | null;
      readonly status: EnumbillUploadStatus | null;
    } | null;
  } | null;
};
export type scanBillApiSubmitBillMutation = {
  response: scanBillApiSubmitBillMutation$data;
  variables: scanBillApiSubmitBillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      }
    ],
    "concreteType": "UpdateByIdbillUploadPayload",
    "kind": "LinkedField",
    "name": "submitBill",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "billUpload",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "billUploadProcessing",
            "kind": "LinkedField",
            "name": "processing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "success",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "msg",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "billUploadOcrResponseParsed",
            "kind": "LinkedField",
            "name": "ocrResponseParsed",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "billUploadOcrResponseParsedAddress",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "full",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zipCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressBlock",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "billUploadOcrResponseParsedVendor",
                "kind": "LinkedField",
                "name": "vendor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gstNumber",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "billUploadOcrResponseParsedBill",
                "kind": "LinkedField",
                "name": "bill",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "finalAmount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "scanBillApiSubmitBillMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "scanBillApiSubmitBillMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "87057bc444d83223aac405b4fd6a48c1",
    "id": null,
    "metadata": {},
    "name": "scanBillApiSubmitBillMutation",
    "operationKind": "mutation",
    "text": "mutation scanBillApiSubmitBillMutation(\n  $id: MongoID!\n) {\n  submitBill(_id: $id) {\n    record {\n      _id\n      status\n      processing {\n        success\n        msg\n      }\n      invoiceId\n      ocrResponseParsed {\n        address {\n          full\n          street\n          city\n          zipCode\n          addressBlock\n        }\n        vendor {\n          name\n          gstNumber\n        }\n        bill {\n          date\n          billId\n          subTotal\n          finalAmount\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4272cdb8f9670c61bcb14acccfbda73";

export default node;
