/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, View, Text } from '../../components/new';
import {
  findTaskHistoryLastAction,
  formatPlateNo,
  getHoursAndMinutesAgoFromSeconds,
  ParkingStatus,
  PaymentTypes,
  taskHistoryActionTypes,
} from '../../utilities/helper';
import TaskCard from '../../components/new/primitive/TaskCard/TaskCard';
import useCashierStore from '../../stores/cashier/taskStore';
import { updateParking } from '../../relay/parkingApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import { updateTaskForSupervisor } from '../../relay/taskApi';

const RenderItem = ({ item, refresh }) => {
  const navigation = useNavigation<any>();
  const { openedCard, setOpenedCard } = useCashierStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const bottomSheetModalRef = useRef(null);

  const handlePresentModalPress = React.useCallback(() => {
    bottomSheetModalRef?.current?.show();
  }, []);

  const handleOnDismiss = React.useCallback(() => {
    bottomSheetModalRef?.current?.hide();
  }, []);

  // ------ vehcile number ------
  const formattedVehicleNumber = formatPlateNo(
    item?.vehicle?.registration?.plate,
  );
  const heading = formattedVehicleNumber;

  // ------ finding delayAt from ETA ------
  const delayAt = new Date(item?.ETA?.delayAt).valueOf();

  // ------ finding arrived status from history ------
  const arrivedStatus =
    item &&
    item?.history &&
    item?.history?.length &&
    item?.history?.filter(
      (ele) => ele?.action === taskHistoryActionTypes.arrived,
    );

  // ------ finding arrived time from arrived status ------
  const taskArrivedTime = new Date(arrivedStatus[0]?.event_at).valueOf();

  // ------ finding current time ------
  const currentTime = new Date().valueOf();

  // ------ finding difference between arrived status time and current time ------
  const difference = currentTime - taskArrivedTime;

  const timerObj = {
    title1: getHoursAndMinutesAgoFromSeconds(difference / 1000),
    title1Color: 'primary.100',
  };
  if (difference / 1000 >= 900) {
    timerObj.title1Color = 'error.500';
  }

  // ------ finding last action from history ------
  const lastAction = findTaskHistoryLastAction(item);

  // ------ check if vehicle is delayed or not ------
  const isDelayed = taskArrivedTime > delayAt;

  // ------ vehicle is whiteListed or not ------
  const isVehicleWhitelisted = item?.isVehicleWhitelisted;

  // ------ vehicled has paid charges or not ------
  const isPaid =
    item &&
    item?.parking &&
    item?.parking?.parkingInvoice &&
    item?.parking?.parkingInvoice?.paymentDetails &&
    item?.parking?.parkingInvoice?.paymentDetails?.isPaid;

  const checkIfAmountGreaterThanZero = ({ amount }) => {
    if (
      (amount !== null || amount !== undefined) &&
      typeof amount !== 'object'
    ) {
      return true;
    }
    return false;
  };

  // ------ total payable amount ------
  const totalPayable =
    item &&
    item?.parking &&
    item?.parking?.parkingInvoice &&
    item?.parking?.parkingInvoice?.charges &&
    item?.parking?.parkingInvoice?.charges?.cashierDiscountAmount &&
    checkIfAmountGreaterThanZero({
      amount: item?.parking?.parkingInvoice?.charges?.cashierDiscountAmount,
    })
      ? `₹${
          item?.parking?.parkingInvoice?.charges?.totalPayable -
          item?.parking?.parkingInvoice?.charges?.cashierDiscountAmount
        }`
      : item &&
        item?.parking &&
        item?.parking?.parkingInvoice &&
        item?.parking?.parkingInvoice?.charges &&
        checkIfAmountGreaterThanZero(
          item?.parking?.parkingInvoice?.charges?.totalPayable,
        )
      ? `₹${item?.parking?.parkingInvoice?.charges?.totalPayable}`
      : '₹0';

  const handelUpdateParking = async (response) => {
    if (response.addParking !== null) {
      handleOnDismiss();
      refresh();
    }
  };
  const updateApiErrorHandler = (error) => {
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v2,
    });
  };

  const handleUpdateTask = (focReason, otherText) => {
    const data = {
      id: item?.parkingId,
      record: {
        parkingInvoice: {
          paymentDetails: {
            isPaid: true,
            mode: PaymentTypes.FOC,
            focReason,
            message: otherText?.length ? otherText : null,
            amount: 0,
          },
        },
        status: ParkingStatus.OUT,
      },
    };
    updateParking(data, handelUpdateParking, updateApiErrorHandler);
  };

  const handelUpdateTaskForPaidAndWhiteListed = async (response) => {
    if (response.updateTask !== null) {
      dispatchSnackbar({
        msg: 'Mark Exited Successfully!',
        status: SnackbarStatus.success,
        version: SnackbarVersion.v2,
      });
      refresh();
    }
  };

  // ------ handle on pressing approve exit update task history ------
  const handleApproveExitForPaidAndWhiteListed = () => {
    const data = {};
    (data as any)._id = item?._id;
    (data as any).record = {};
    (data as any).record.history = [];
    /*
      update history
      if last action is arrived then update status as completed & exited both
      else update status as exited
      */
    (data as any).record.history = [{ action: taskHistoryActionTypes.EXITED }];
    updateTaskForSupervisor(
      data,
      handelUpdateTaskForPaidAndWhiteListed,
      updateApiErrorHandler,
    );
  };

  const headerRightComponent = () =>
    isVehicleWhitelisted ? (
      <View flexDirection="row" alignItems="center">
        <View width="3xs" height="3xs" bg="primary.100" borderRadius="full" />
        <Text size="xs" color="primary.100" ml="sm">
          Whitelisted
        </Text>
      </View>
    ) : isPaid ? (
      <View flexDirection="row" alignItems="center">
        <View width="3xs" height="3xs" bg="success.500" borderRadius="full" />
        <Text size="xs" color="success.500" ml="sm">
          Paid {totalPayable}
        </Text>
      </View>
    ) : isDelayed ? (
      <View flexDirection="row" alignItems="center">
        <View width="3xs" height="3xs" bg="error.500" borderRadius="full" />
        <Text size="xs" color="error.500" ml="sm">
          Delayed
        </Text>
      </View>
    ) : (
      <></>
    );

  const topRightCornerComponent = () =>
    isVehicleWhitelisted ? (
      <View
        borderWidth="xs"
        borderRadius="sm"
        borderColor="primary.50"
        bg="primary.50"
        p="sm"
      >
        <Text size="sm" color="primary.400" weight="medium">
          ₹0
        </Text>
      </View>
    ) : isPaid ? (
      <View
        borderWidth="xs"
        borderRadius="sm"
        borderColor="success.10"
        bg="success.10"
        p="sm"
      >
        <Text size="sm" color="primary.400" weight="medium">
          ₹0
        </Text>
      </View>
    ) : (
      <View
        borderWidth="xs"
        borderRadius="sm"
        borderColor="info.10"
        bg="info.10"
        p="sm"
      >
        <Text size="sm" color="primary.400" weight="medium">
          {totalPayable}
        </Text>
      </View>
    );

  const bottomLeftCTAComponent = () => {
    return isPaid || isVehicleWhitelisted ? (
      <Button
        size="md"
        appearance="filled"
        status="primary"
        onPress={() => {
          handleApproveExitForPaidAndWhiteListed();
        }}
        shadow="sm"
      >
        Approve Exit
      </Button>
    ) : (
      <Button
        size="md"
        appearance="filled"
        status="primary"
        onPress={() => {
          navigation.navigate('CashierPaymentScreen', {
            taskID: item?._id,
            vehicleNumber: item?.vehicle?.registration?.plate,
          });
        }}
        shadow="sm"
      >
        Collect Fee
      </Button>
    );
  };

  return (
    <>
      <View px="2xl" my="lg">
        <TaskCard
          heading={heading}
          subHeading={timerObj.title1}
          subHeadingColor={timerObj.title1Color}
          headingRight={headerRightComponent()}
          topRightCorner={topRightCornerComponent()}
          bottomLeftCTA={bottomLeftCTAComponent()}
          swipeEnabled={false}
          shadow="md"
          blur={item?.blur}
          preExpanded={openedCard === item?._id}
          onCardPressCB={() => {
            if (openedCard !== item?._id) {
              setOpenedCard({ id: item?._id });
            } else {
              setOpenedCard({ id: '' });
            }
          }}
        />
      </View>
    </>
  );
};

export default RenderItem;
