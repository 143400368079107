import React, { Suspense, useCallback, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { IconButton, Layout, TopNavigation } from '../../components/new';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import RewardList from './RewardList';
import useUserStore from '../../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { RewardScreenPlaceholder } from './shimmerPlaceholders';
import { navigateBack } from '../../utilities/helper';
import { pagination } from '../../utilities/Constant';
import useAuthStore from '../../stores/authStore';
import NotLoggedInPlaceholderV2 from '../../components/new/custom/NotLoggedInPlaceholderV2';

const rewardScreenQuery = graphql`
  query RewardsScreenQuery(
    $filter: FilterFindManyticketInput!
    $userId: MongoID!
    $count: Int!
  ) {
    ...RewardListTicketQueryFragment @arguments(filter: $filter, count: $count)
    findUserById(_id: $userId) {
      _id
      wallet {
        totalBalance
        currentBalance
      }
    }
  }
`;

const RewardsScreen = ({ navigation, route }) => {
  const [netStatus, setNetStatus] = useState(true);
  const userId = useUserStore((state) => state.id);
  const userRole = useUserStore((state) => state.role);
  const { name } = route ?? {};
  const { backToHome } = route?.params ?? {};
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    useCallback(() => {
      // setRandomKey(Math.random());
      refresh();
      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, []),
  );

  const RenderRewardsScreen = (props) => {
    const { variables } = props;
    // const { findReward, findUserById } =
    const { findUserById, ...rewardsFragmentRef } =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useLazyLoadQuery(rewardScreenQuery, variables, {
        ...refreshedQueryOptions,
        networkCacheConfig: { force: true },
      });

    return (
      <RewardList navigation={navigation} findRewardRef={rewardsFragmentRef} />
    );
  };

  const onPressLeftIcon = () => {
    if (backToHome) {
      navigation.popToTop();
    } else {
      navigateBack(navigation, userRole);
    }
  };

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="32nd Rewards" />;
  }

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          name === 'RewardsScreen' || name === 'NotLoggedInRewardsScreen' ? (
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconSize="2xl"
              iconColor="primary.500"
              shape="square"
              onPress={onPressLeftIcon}
            />
          ) : undefined
        }
        appearance="ghost"
        level="1"
        title="32nd Rewards"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<RewardScreenPlaceholder />}>
          <RenderRewardsScreen
            variables={{
              filter: {
                ticketType: 'REWARD_TICKET',
                displayType: 'QR',
              },
              count: pagination.defaultPageSize,
              userId,
            }}
            fetchKey={refreshedQueryOptions.fetchKey}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default RewardsScreen;
