import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Widget } from '@typeform/embed-react';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { View } from '../../../components/new';
import useUserStore from '../../../stores/userStore';

const SurveyComp = ({
  phoneNumber,
  TYPEFORMID = '',
  SURVEYID = '',
  IsInvestment = false,
}) => {
  const phone = phoneNumber;
  const userId = useUserStore((state) => state.id);
  const firstName = useUserStore((state) => state?.firstName);
  const lastName = useUserStore((state) => state?.lastName);
  const name = `${firstName}${lastName ? ` ${lastName}` : ''}`;
  let propertyid = '1';
  let url = '';

  if (IsInvestment) {
    propertyid = SURVEYID;
    url = `https://form.typeform.com/to/${TYPEFORMID}#name=${name}&phone=${phone}&userid=${userId}&propertyid=${propertyid}`;
  } else {
    url = `https://form.typeform.com/to/${TYPEFORMID}#phone=${phone}&userid=${userId}`;
  }

  return (
    <View flex={1}>
      {Platform.OS === 'web' && (
        <View flex={1}>
          <>
            <Widget
              id={TYPEFORMID}
              style={{ width: '100%', height: '100%' }}
              hidden={{
                phone,
                userId,
                name,
                propertyid,
              }}
              fullScreen={false}
              autoResize={false}
            />
          </>
        </View>
      )}
      {Platform.OS !== 'web' && (
        <View flex={1}>
          <WebView
            source={{
              uri: url,
            }}
            automaticallyAdjustContentInsets
            allowsBackForwardNavigationGestures
            autoManageStatusBarEnabled
            androidHardwareAccelerationDisabled
          />
        </View>
      )}
    </View>
  );
};

export default SurveyComp;
