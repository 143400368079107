import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Text, View } from '../../../components/new';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { FontWeights } from '../../../themes/new/helper';

const ScanStatusProcessing = () => {
  const { width, height } = useWindowDimensions();

  return (
    <View
      position="absolute"
      bg="opacity.80"
      top={0}
      bottom={0}
      left={0}
      right={0}
      width={width}
      height={height}
      alignItems="center"
      justifyContent="center"
      px="4xl"
    >
      <CustomLottieView
        autoPlay
        loop
        source={require('../../../../assets/lottie/extracting-details.json')}
        style={{ width: 200, height: 200 }}
        containerWidth={200}
      />
      <Text
        size="xl"
        weight={FontWeights.MEDIUM}
        color="primary.500"
        mt="sm"
        textAlign="center"
      >
        Extracting bill details..
      </Text>
    </View>
  );
};

export default ScanStatusProcessing;
