import React from 'react';
import { Placeholder, Fade } from 'rn-placeholder';
import { View } from '../../../../components/new';
import PointItemPlaceholder from '../../../new/shimmerPlaceholders/components/PointItemPlaceholder';

const SearchParkingScreenPlaceholder = () => {
  return (
    <Placeholder Animation={Fade}>
      <View style={{ height: 720 }}>
        <View mt="4xl" px="2xl">
          <PointItemPlaceholder />
          <PointItemPlaceholder />
          <PointItemPlaceholder />
          <PointItemPlaceholder />
          <PointItemPlaceholder />
          <PointItemPlaceholder />
        </View>
      </View>
    </Placeholder>
  );
};

export default SearchParkingScreenPlaceholder;
