import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Input, View } from '../../../components/new';

const SearchBar = ({ onClick }) => {
  const { top } = useSafeAreaInsets();

  return (
    <View shadow="sm" pt={top} bg="primary.10" zIndex={1}>
      <View p="2xl" mt="sm+md">
        <Input
          editable={false}
          disabled
          rightIconName="search-outline-400"
          placeholder="Search order id"
          onPressMiddleArea={onClick}
        />
      </View>
    </View>
  );
};

export default SearchBar;
