import styled from 'styled-components/native';
import { variant, border } from 'styled-system';
import View from '../../basic/View/View';
import { getTheme, themeModes } from '../../../../themes/new/theme';
import { ThemeVariables } from '../../../../themes/new/helper';

export const variantsKey = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 };

const sizeVariants = ({ theme }) => {
  // if global theme is dark use light and vice versa
  const localTheme = getTheme(
    theme.currentThemeMode === themeModes.light
      ? themeModes.dark
      : themeModes.light,
  );

  return variant({
    prop: ThemeVariables.LEVEL,
    variants: {
      [variantsKey[1]]: {
        borderColor: localTheme.colors.opacity[10],
      },
      [variantsKey[2]]: {
        borderColor: localTheme.colors.opacity[20],
      },
      [variantsKey[3]]: {
        borderColor: localTheme.colors.opacity[30],
      },
      [variantsKey[4]]: {
        borderColor: localTheme.colors.opacity[40],
      },
      [variantsKey[5]]: {
        borderColor: localTheme.colors.opacity[50],
      },
    },
  });
};

const horizontalVariants = ({ theme }) => {
  const localTheme = getTheme(
    theme.currentThemeMode === themeModes.light
      ? themeModes.dark
      : themeModes.light,
  );

  return variant({
    prop: ThemeVariables.LEVEL,
    variants: {
      [variantsKey[1]]: {
        backgroundColor: localTheme.colors.opacity[10],
      },
      [variantsKey[2]]: {
        backgroundColor: localTheme.colors.opacity[20],
      },
      [variantsKey[3]]: {
        backgroundColor: localTheme.colors.opacity[30],
      },
      [variantsKey[4]]: {
        backgroundColor: localTheme.colors.opacity[40],
      },
      [variantsKey[5]]: {
        backgroundColor: localTheme.colors.opacity[50],
      },
    },
  });
};

const StyledView = styled(View)`
  border-bottom-width: 1;
  ${(props) => sizeVariants(props)}
  ${border}
`;

const HorizontalStyledView = styled(View)`
  width: 1;
  flex: 1;
  ${(props) => horizontalVariants(props)} ${border};
`;

const Divider = ({ level = 1, ...props }) => {
  if (props?.horizontal) {
    return (
      <View alignItems="center" justifyContent="center" flex={1}>
        <HorizontalStyledView level={level} {...props} />
      </View>
    );
  }
  return <StyledView level={level} {...props} />;
};

export default Divider;
