/**
 * @generated SignedSource<<34a2f2ed6d2417543de0cc54d5ff7221>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumUserContactEmailType = "PERSONAL" | "WORK" | "%future added value";
export type editProfileScreenQuery$variables = {
  userId: any;
};
export type editProfileScreenQuery$data = {
  readonly findUserById: {
    readonly _id: any;
    readonly contact: {
      readonly email: ReadonlyArray<{
        readonly addr: string | null;
        readonly type: EnumUserContactEmailType | null;
        readonly verified: boolean | null;
      } | null> | null;
      readonly phone: {
        readonly number: string;
        readonly prefix: string;
      };
    };
    readonly name: {
      readonly first: string;
      readonly last: string | null;
    };
  } | null;
};
export type editProfileScreenQuery = {
  response: editProfileScreenQuery$data;
  variables: editProfileScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "findUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserName",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContactPhone",
            "kind": "LinkedField",
            "name": "phone",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContactEmail",
            "kind": "LinkedField",
            "name": "email",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addr",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verified",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editProfileScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editProfileScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "325c43e8349ccaa33f70b54909c242ae",
    "id": null,
    "metadata": {},
    "name": "editProfileScreenQuery",
    "operationKind": "query",
    "text": "query editProfileScreenQuery(\n  $userId: MongoID!\n) {\n  findUserById(_id: $userId) {\n    _id\n    name {\n      first\n      last\n    }\n    contact {\n      phone {\n        number\n        prefix\n      }\n      email {\n        addr\n        verified\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c5d766c1cba96fd9d02c67a0861de57";

export default node;
