import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { View, Text, Pressable } from '../../../../components/new';
import { getTheme, space } from '../../../../themes/new/theme';
import useAuthStore from '../../../../stores/authStore';
import { AppTheme } from '../../../../stores/commonStore';
import CachedImage from '../../../../components/new/custom/CachedImage';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../utilities/firbaseAnalytics';

const shadowProps =
  Platform.OS === 'web' || Platform.OS === 'ios'
    ? { borderColor: 'primary.50', shadow: 'sm' }
    : { borderColor: 'primary.50' };

const FeatureTiles = ({ tickets }) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const categoryArr = [
    {
      title: 'My Car',
      navigator: 'track',
      img:
        getTheme(theme?.currentThemeMode).currentThemeMode === AppTheme.LIGHT
          ? require('../../../../../assets/images/my_car_new.webp')
          : require('../../../../../assets/images/my_car_new_dark.webp'),
      eventName: 'home__myCar_Tap',
      screen: 'home',
      buttonName: 'myCar',
      notifyButtonCount: 0,
    },
    {
      title: '32nd Rewards',
      navigator: isLoggedIn ? 'RewardsScreen' : 'NotLoggedInRewardsScreen',
      img:
        getTheme(theme?.currentThemeMode).currentThemeMode === AppTheme.LIGHT
          ? require('../../../../../assets/images/rewards_new.webp')
          : require('../../../../../assets/images/rewards_new_dark.webp'),
      eventName: 'home__rewards_Tap',
      screen: 'home',
      buttonName: 'rewards',
      notifyButtonCount: tickets?.length,
    },
    {
      title: '32nd Moments',
      navigator: 'PhotoMoments',
      img:
        getTheme(theme?.currentThemeMode).currentThemeMode === AppTheme.LIGHT
          ? require('../../../../../assets/images/moments_new.webp')
          : require('../../../../../assets/images/moments_new_dark.webp'),
      eventName: 'home__moments_Tap',
      screen: 'home',
      buttonName: 'moments',
      notifyButtonCount: 0,
    },
  ];

  const { width } = useWindowDimensions();

  return (
    <View mx="2xl" flexDirection="row" mt="4xl">
      {categoryArr.map((item, index) => (
        <Pressable
          onPress={() => {
            firebaseEventLogger(item.eventName, {
              screen: item.screen,
              button: item.buttonName,
              interactionType: InteractionType.TAP,
            });
            global.currentRoute = item?.buttonName;
            navigation.navigate(item?.navigator, { showBackIcon: true });
          }}
          style={{
            marginLeft: index !== 0 ? space['2xl'] : 0,
          }}
          key={`featureTitle${item?.title}`}
        >
          <View
            borderRadius="md"
            shadow="sm"
            borderWidth="xs"
            borderColor="primary.50"
            backgroundColor="background.primary.elevation"
            {...shadowProps}
            width={width / 3 - 64 / 3}
            height={space['9xl+9xl']}
            justifyContent="center"
            alignItems="center"
          >
            <CachedImage
              source={item?.img}
              style={{ height: space['9xl'], width: space['9xl'] }}
            />
            <Text size="xs" color="primary.500" numberOfLines={1} mt="sm">
              {item?.title}
            </Text>
          </View>
          {item?.notifyButtonCount > 0 ? (
            <View
              height={space['3xl']}
              width={space['3xl']}
              borderRadius={space['3xl']}
              bg="error.500"
              position="absolute"
              right={-4}
              top={-4}
              justifyContent="center"
              alignItems="center"
            >
              <Text size="xs" color="background.primary.elevation">
                {item?.notifyButtonCount > 9 ? '9+' : item?.notifyButtonCount}
              </Text>
            </View>
          ) : null}
        </Pressable>
      ))}
    </View>
  );
};

export default FeatureTiles;
