import React, { useState } from 'react';
import { Dimensions, ImageSourcePropType } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import { Text, View } from '../../index';
import CarouselProgressTiles from './CarouselProgressTiles';
import theme from '../../../../themes/new/theme';
import CustomLottieView from '../../custom/CustomLottieView';

interface CarouselDataType {
  title: string;
  subTitle: string;
  lottie: ImageSourcePropType;
}

const carouselData: CarouselDataType[] = [
  {
    title: 'Upto 30% Off On Parking',
    subTitle:
      'Get upto 30% off on parking fee and earn 32nd points on every visit!',
    lottie: require('../../../../../assets/lottie/welcomeModalStep1.json'),
  },
  {
    title: 'Upto 15% off',
    subTitle:
      'Get upto 15% off on dining bill payments at selected restaurants!',
    lottie: require('../../../../../assets/lottie/welcomeModalStep2.json'),
  },
  {
    title: 'Quick Recall',
    subTitle: 'Enjoy faster car recall as a member and never wait again!',
    lottie: require('../../../../../assets/lottie/welcomeModalStep3.json'),
  },
  {
    title: '32nd Photo Moments',
    subTitle: 'Get instant access to your photos & videos captured on campus!',
    lottie: require('../../../../../assets/lottie/welcomeModalStep4.json'),
  },
  {
    title: '32nd Points',
    subTitle:
      'Earn and Redeem points on every visit and transaction (1 point = ₹1)!',
    lottie: require('../../../../../assets/lottie/welcomeModalStep5.json'),
  },
];

const CarouselItem = (props: CarouselDataType) => {
  return (
    <View
      alignItems="center"
      padding="2xl"
      borderRadius="2xl"
      borderWidth="xs"
      borderColor="static.gold"
      mx="md"
    >
      <CustomLottieView
        style={{ width: 80, height: 80, marginBottom: 24 }}
        containerWidth={80}
        autoPlay
        resizeMode="contain"
        loop
        // @ts-ignore
        source={props?.lottie}
      />
      {/* @ts-ignore */}
      <Text size="xl" color="primary.500" mb="lg" textAlign="center">
        {props.title}
      </Text>
      {/* @ts-ignore */}
      <Text size="md" color="primary.100" textAlign="center">
        {props.subTitle}
      </Text>
    </View>
  );
};

const ClubMemberShipCarousel = () => {
  const [data, setData] = useState<CarouselDataType[]>(carouselData);
  const { width } = Dimensions.get('window');
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <View height={250}>
      <Carousel
        loop
        width={width - 32}
        height={250}
        data={data}
        scrollAnimationDuration={200}
        autoPlayInterval={5000}
        onSnapToItem={(index) => {
          setActiveIndex(index);
        }}
        renderItem={({ index, item }) => (
          <CarouselItem
            title={item.title}
            subTitle={item.subTitle}
            lottie={item.lottie}
            key={index}
          />
        )}
        autoPlay
      />
      <View
        style={{
          marginHorizontal: 16,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CarouselProgressTiles
          time={5000}
          numberOfBars={data.length}
          activeIndex={activeIndex}
          availableWidth={250}
          inactiveBarColor={theme.colors.background.secondary.base}
          activeBarColor={theme.colors.primary['500']}
          borderRadius={40}
        />
      </View>
    </View>
  );
};

export default ClubMemberShipCarousel;
