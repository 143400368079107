import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Button, Text, View } from '../../../../components/new';
import { Appearance, FontWeights } from '../../../../themes/new/helper';
import CancellationInfoBanner from '../../payment/CancellationInfoBanner';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';
import useEventCancellationScreen from './useEventCancellationScreen';
import {
  applyEllipsis,
  navigateBack,
  toRupees,
} from '../../../../utilities/helper';
import { UserRole } from '../../../../stores/userStore';
import { eventAnalytics } from '../analytics/eventAnalytics';

const EventCancellationScreenInner = ({
  invoiceId,
  queryVariables,
  queryOptions,
  setAnalyticsData,
}) => {
  const navigation = useNavigation();
  const { bottom } = useSafeAreaInsets();
  const { bsRef, eventInfo, eventCancellationInfo } =
    useEventCancellationScreen(queryVariables, queryOptions);
  const {
    eventId,
    ticketId,
    eventName,
    eventDate,
    eventTime,
    noOfTickets,
    userName,
  } = eventInfo ?? {};
  const { isRefundableIfCancelled, refundLastDate, refundAmount } =
    eventCancellationInfo ?? {};

  useEffect(() => {
    eventAnalytics.cancelBookingScreen.landed(eventId, ticketId);
  }, [eventId, ticketId]);

  useEffect(() => {
    if (ticketId && eventId) setAnalyticsData(eventId, ticketId);
  }, [ticketId, eventId]);

  const navigateToConcellationConfirmationScreen = () => {
    eventAnalytics.cancelBookingScreen.cancelBooking(eventId, ticketId);
    navigation.navigate('eventConfirmCancellation', {
      invoiceId,
    });
  };

  return (
    <View flex={1}>
      <View flex={1} px="2xl" justifyContent="space-between">
        <View>
          {/* Event Name */}
          <View mt="4xl" mb="2xl">
            <Text
              numberOfLines={1}
              size="md"
              weight={FontWeights.MEDIUM}
              color="primaty.400"
            >
              {eventName}
            </Text>
          </View>
          {/* Event Details Card */}
          <View
            mb="4xl"
            p="2xl"
            bg="background.primary.elevation"
            borderWidth="xs"
            borderColor="grey.50"
            borderRadius="lg"
          >
            <TicketInfoRow
              title="Name"
              value={applyEllipsis(userName, 30)}
              withBottomMargin
            />
            <TicketInfoRow
              title="No. of tickets"
              value={noOfTickets}
              withBottomMargin
            />
            <TicketInfoRow
              title="Event Date"
              value={eventDate}
              withBottomMargin
            />
            <TicketInfoRow title="Event Time" value={eventTime} />
          </View>
          {/* Cancellation Info Banner */}
          {isRefundableIfCancelled && (
            <CancellationInfoBanner
              onPress={() => {
                eventAnalytics.cancelBookingScreen.freeCancellationBannerClicked(
                  eventId,
                  ticketId,
                );
                bsRef.current?.show();
              }}
              title="Free Cancellation"
              message={`You are eligible for free cancellation till ${refundLastDate?.formatOne}`}
            />
          )}
        </View>
        <FooterControls
          onBack={() => {
            eventAnalytics.cancelBookingScreen.backClicked(eventId, ticketId);
            navigateBack(navigation, UserRole.USER);
          }}
          onCancelBooking={navigateToConcellationConfirmationScreen}
          bottomSpacing={bottom}
        />
      </View>
      {/* Cancellation details Bottom Sheet */}
      <BottomSheetV2 ref={bsRef} insets={false}>
        <BottomSheetContent
          eventDate={refundLastDate?.formatTwo}
          refundAmount={refundAmount}
        />
      </BottomSheetV2>
    </View>
  );
};

const TicketInfoRow = ({
  title,
  value,
  withBottomMargin = false,
}: {
  title: string;
  value: string;
  withBottomMargin?: boolean;
}) => (
  <View
    mb={withBottomMargin ? '2xl' : 'none'}
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
  >
    <Text size="sm" color="primary.400">
      {title}
    </Text>
    <Text size="sm" color="primary.300">
      {value}
    </Text>
  </View>
);

const FooterControls = ({ onBack, onCancelBooking, bottomSpacing }) => (
  <View
    bg="background.primary.elevation"
    borderTopWidth="xs"
    borderColor="grey.50"
    pb={bottomSpacing}
  >
    <View py="2xl" flexDirection="row">
      <View flex={1} mr="md">
        <Button size="lg" onPress={onBack} appearance={Appearance.OUTLINE}>
          Go Back
        </Button>
      </View>
      <View flex={1} ml="md">
        <Button
          size="lg"
          onPress={onCancelBooking}
          appearance={Appearance.FILLED}
        >
          Cancel Booking
        </Button>
      </View>
    </View>
  </View>
);

const BottomSheetContent = ({ eventDate, refundAmount }) => (
  <View p="2xl" bg="background.secondary.elevation">
    <View mb="2xl" pb="lg" borderBottomWidth="xs" borderColor="grey.50">
      <Text size="md" color="primary.300" weight={FontWeights.MEDIUM}>
        Cancellation & Refund
      </Text>
    </View>
    <View mb="2xl" flexDirection="row" justifyContent="space-between">
      <Text size="sm" color="primary.500">
        Untill {eventDate}
      </Text>
      <Text size="xs" color="success.500">
        (Full refund){' '}
        <Text color="primary.500" size="sm">
          {toRupees(refundAmount)}
        </Text>
      </Text>
    </View>
    <View flexDirection="row" justifyContent="space-between">
      <Text size="sm" color="primary.500">
        Post {eventDate}
      </Text>
      <Text size="xs" color="primary.200">
        Non-refundable
      </Text>
    </View>
  </View>
);

export default EventCancellationScreenInner;
