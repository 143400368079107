import React from 'react';
import { Image } from 'react-native';
import { View, Text } from '../../../components/new';

type TopContentProps = {
  isLoyalityEnabled: boolean;
  loyalCustomerCount: number;
};

const TopContent = ({
  isLoyalityEnabled = false,
  loyalCustomerCount = 0,
}: TopContentProps) => {
  return isLoyalityEnabled ? (
    <View alignItems="center" mt="9xl+9xl" mb="4xl">
      <Text size="3xl" weight="medium" color="static.gold">
        {loyalCustomerCount}
      </Text>
      <Text size="sm" mt="sm" color="primary.10">
        Loyal Customers
      </Text>
    </View>
  ) : (
    <View alignItems="center" mt="2xl" mb="4xl">
      <Image
        source={require('../../../../assets/images/icon.png')}
        style={{
          width: 40,
          height: 40,
          resizeMode: 'cover',
        }}
      />
      <Text mt="lg" size="5xl" color="static.gold" weight="medium">
        CLUB
      </Text>
      <Text mt="lg" size="md" color="primary.50">
        Loyalty Program
      </Text>
    </View>
  );
};

export default TopContent;
