/**
 * @generated SignedSource<<b7e00bbfa894570318b84f3ff358d6e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumPopupOrdersTCPaymentType = "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnumPopupOrdersTCPopUpStoreDetailsDeliveryStatus = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type EnumPopupOrdersTCStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type updatePopupOrderRecordInput = {
  amount?: number | null;
  paymentType?: EnumPopupOrdersTCPaymentType | null;
  popUpStoreDetails?: UpdateByIdPopupOrdersTCPopUpStoreDetailsInput | null;
  status?: EnumPopupOrdersTCStatus | null;
};
export type UpdateByIdPopupOrdersTCPopUpStoreDetailsInput = {
  deliveryStatus?: EnumPopupOrdersTCPopUpStoreDetailsDeliveryStatus | null;
};
export type updatePopupOrderUpdatePopupOrderMutation$variables = {
  id: any;
  record: updatePopupOrderRecordInput;
};
export type updatePopupOrderUpdatePopupOrderMutation$data = {
  readonly updatePopupOrder: {
    readonly record: {
      readonly _id: any;
      readonly amount: number;
      readonly paymentType: EnumPopupOrdersTCPaymentType | null;
      readonly popUpStoreDetails: {
        readonly deliveryStatus: EnumPopupOrdersTCPopUpStoreDetailsDeliveryStatus | null;
        readonly displayName: string | null;
      } | null;
      readonly referenceModelName: string | null;
      readonly status: EnumPopupOrdersTCStatus | null;
      readonly user: {
        readonly _id: any;
        readonly contact: {
          readonly phone: {
            readonly number: string;
            readonly prefix: string;
          };
        };
      } | null;
    } | null;
  } | null;
};
export type updatePopupOrderUpdatePopupOrderMutation = {
  response: updatePopupOrderUpdatePopupOrderMutation$data;
  variables: updatePopupOrderUpdatePopupOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdPopupOrdersTCPayload",
    "kind": "LinkedField",
    "name": "updatePopupOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PopupOrdersTC",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referenceModelName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContact",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserContactPhone",
                    "kind": "LinkedField",
                    "name": "phone",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prefix",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupOrdersTCPopUpStoreDetails",
            "kind": "LinkedField",
            "name": "popUpStoreDetails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deliveryStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePopupOrderUpdatePopupOrderMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePopupOrderUpdatePopupOrderMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c7a43692a224e4a4148981c035c3f764",
    "id": null,
    "metadata": {},
    "name": "updatePopupOrderUpdatePopupOrderMutation",
    "operationKind": "mutation",
    "text": "mutation updatePopupOrderUpdatePopupOrderMutation(\n  $id: MongoID!\n  $record: updatePopupOrderRecordInput!\n) {\n  updatePopupOrder(record: $record, _id: $id) {\n    record {\n      _id\n      status\n      amount\n      referenceModelName\n      user {\n        _id\n        contact {\n          phone {\n            prefix\n            number\n          }\n        }\n      }\n      paymentType\n      popUpStoreDetails {\n        deliveryStatus\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "030b450105e72eea79c77dbd73fafdd8";

export default node;
