/**
 * @generated SignedSource<<406e18ff80d403f230163bfd99006005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentOrderHistorySortEnum = "ASC" | "DESC" | "STATUS_ASC" | "STATUS_DESC" | "%future added value";
export type PaymentOrderHistoryStatustEnumv2 = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type PaymentOrderStatustEnum = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type findpaymentOrderfilterInput = {
  _id?: any | null;
  campus: FilterFindManyPopupOrdersTCCampusInput;
  date?: findpaymentOrderfilterInputDate | null;
  orderStatus?: ReadonlyArray<PaymentOrderStatustEnum | null> | null;
  popUpStoreDetails?: FilterFindManyPopupOrdersTCPopUpStoreDetailsInput | null;
  referenceModelId?: any | null;
  search?: string | null;
  status?: ReadonlyArray<PaymentOrderHistoryStatustEnumv2 | null> | null;
};
export type FilterFindManyPopupOrdersTCPopUpStoreDetailsInput = {
  displayName?: string | null;
};
export type FilterFindManyPopupOrdersTCCampusInput = {
  _id?: any | null;
};
export type findpaymentOrderfilterInputDate = {
  end?: string | null;
  start?: string | null;
};
export type partnerHomeScreenQuery__paymentHistoryFragmentV2Query$variables = {
  count: number;
  cursor?: string | null;
  filter?: findpaymentOrderfilterInput | null;
  sort?: ReadonlyArray<PaymentOrderHistorySortEnum | null> | null;
};
export type partnerHomeScreenQuery__paymentHistoryFragmentV2Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"partnerHomeScreenQuery__paymentHistoryFragmentV2">;
};
export type partnerHomeScreenQuery__paymentHistoryFragmentV2Query = {
  response: partnerHomeScreenQuery__paymentHistoryFragmentV2Query$data;
  variables: partnerHomeScreenQuery__paymentHistoryFragmentV2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v2 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "partnerHomeScreenQuery__paymentHistoryFragmentV2Query",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "partnerHomeScreenQuery__paymentHistoryFragmentV2"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "partnerHomeScreenQuery__paymentHistoryFragmentV2Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PopupOrdersTCConnection",
        "kind": "LinkedField",
        "name": "findPopupOrders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupOrdersTCEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupOrdersTC",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemDetails",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referenceModelName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserContact",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserContactPhone",
                            "kind": "LinkedField",
                            "name": "phone",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "prefix",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "number",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "invoice",
                    "kind": "LinkedField",
                    "name": "invoice",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "orderType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "orderId",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiePaymentDetailsTC",
                        "kind": "LinkedField",
                        "name": "paymentDetails",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "payment",
                            "kind": "LinkedField",
                            "name": "razorpay",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pgOrderId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PaytmDqrOrders",
                            "kind": "LinkedField",
                            "name": "paytmQr",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "transactionId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopupOrdersTCPopUpStoreDetails",
                    "kind": "LinkedField",
                    "name": "popUpStoreDetails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deliveryStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "filter",
          "sort"
        ],
        "handle": "connection",
        "key": "partnerHomeScreenQuery__paymentHistoryFragmentV2Query__findPopupOrders",
        "kind": "LinkedHandle",
        "name": "findPopupOrders"
      }
    ]
  },
  "params": {
    "cacheID": "6a63bbcca55ac115595eb60b0b10519b",
    "id": null,
    "metadata": {},
    "name": "partnerHomeScreenQuery__paymentHistoryFragmentV2Query",
    "operationKind": "query",
    "text": "query partnerHomeScreenQuery__paymentHistoryFragmentV2Query(\n  $count: Int!\n  $cursor: String\n  $filter: findpaymentOrderfilterInput\n  $sort: [PaymentOrderHistorySortEnum]\n) {\n  ...partnerHomeScreenQuery__paymentHistoryFragmentV2_1GWUVg\n}\n\nfragment partnerHomeScreenQuery__paymentHistoryFragmentV2_1GWUVg on Query {\n  findPopupOrders(filter: $filter, sort: $sort, first: $count, after: $cursor) {\n    edges {\n      node {\n        _id\n        status\n        created_at\n        amount\n        itemDetails\n        referenceModelName\n        user {\n          _id\n          contact {\n            phone {\n              prefix\n              number\n            }\n          }\n        }\n        invoice {\n          _id\n          orderType\n          orderId\n          created_at\n          paymentDetails {\n            razorpay {\n              pgOrderId\n            }\n            paytmQr {\n              transactionId\n            }\n          }\n        }\n        paymentType\n        popUpStoreDetails {\n          deliveryStatus\n          displayName\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6549df160750194457689bdf665eae1b";

export default node;
