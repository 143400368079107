/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Image } from 'react-native';
import { uniqueId } from 'lodash';
import { Text, View } from '../index';
import GradientBackground from './GradientBackground';
import { getTheme, themeModes } from '../../../themes/new/theme';
import Icon from '../primitive/Icon/Icon';

interface PointCardProps {
  amountSaved?: number;
  pointsEarned?: number;
  pointsLeft?: number;
}

const RewardPointsCard = ({
  amountSaved = 0,
  pointsEarned = 0,
  pointsLeft = 0,
}: PointCardProps) => {
  const gradientColors = [
    {
      offset: '0',
      color: getTheme(themeModes.light).colors.primary[500],
      opacity: 1,
    },
    {
      offset: '1',
      color: getTheme(themeModes.light).colors.primary[500],
      opacity: 0.6,
    },
  ];
  return (
    <GradientBackground
      backgroundOpacity={1}
      gradientColors={gradientColors}
      id={uniqueId('rpc')}
      showBorder
      strokeBorderRadius={getTheme(themeModes.light).radii.xl}
      strokeBorderWidth={getTheme(themeModes.light).borderWidths.sm}
      strokeBorderColor="transparent"
      //   px="2xl"
      //   py="4xl"
    >
      <View px="2xl" py="4xl">
        {amountSaved !== 0 ? (
          <View flexDirection="row" alignItems="center" justifyContent="center">
            <Icon name="rupee-outline-300" color="primary.10" size="xl" />
            <Text size="xl" weight="medium" color="primary.10">
              {`${amountSaved} Saved`}
            </Text>
          </View>
        ) : (
          <View flexDirection="row" alignItems="center" justifyContent="center">
            <View flexDirection="row" alignItems="center">
              <Text size="xl" weight="medium" color="primary.10" mr="sm">
                1
              </Text>
              <Image
                source={require('../../../../assets/images/coins.webp')}
                resizeMode="contain"
                style={{ width: 20, height: 20 }}
              />
            </View>
            <Text size="xl" weight="medium" color="primary.10" mx="xl">
              =
            </Text>
            <View flexDirection="row" alignItems="center">
              <Icon name="rupee-outline-300" color="primary.10" size="xl" />
              <Text size="xl" weight="medium" color="primary.10" ml="sm">
                1
              </Text>
            </View>
          </View>
        )}
        <View
          mt="2xl"
          borderRadius="md"
          borderColor="primary.500"
          borderWidth="xs"
          backgroundColor="primary.500"
          px="2xl"
          py="lg"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <View flexDirection="row" alignItems="center">
            <Text size="sm" weight="medium" color="primary.10" mr="xs">
              {pointsEarned}
            </Text>
            <Image
              source={require('../../../../assets/images/coins.webp')}
              resizeMode="contain"
              style={{ width: 16, height: 16 }}
            />
            <Text size="xs" color="primary.200" ml="lg">
              Earned
            </Text>
          </View>
          <Text color="primary.100" size="xs">
            |
          </Text>
          <View flexDirection="row" alignItems="center">
            <Text size="sm" weight="medium" color="primary.10" mr="xs">
              {pointsLeft}
            </Text>
            <Image
              source={require('../../../../assets/images/coins.webp')}
              resizeMode="contain"
              style={{ width: 16, height: 16 }}
            />
            <Text size="xs" color="primary.200" ml="lg">
              Left
            </Text>
          </View>
        </View>
      </View>
    </GradientBackground>
  );
};

export default RewardPointsCard;
