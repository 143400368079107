import React from 'react';
import { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { BlurView, Button, View, Text } from '..';
import useBoundStore from '../../../stores';
import { getTheme, themeModes } from '../../../themes/new/theme';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

export const getCount = (cart) => {
  return cart.reduce((prev, curr) => {
    return prev + curr.quantity;
  }, 0);
};

const SelectedCartFooter = ({ restaurantId, restaurantName }) => {
  const cart = useBoundStore((state) => state.cart);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<any>();

  const reviewOrder = () => {
    firebaseEventLogger('MenuFooterNextClicked', {
      restaurantId,
      restaurantName,
    });
    navigation.navigate('order', {
      restaurant: restaurantId,
      restaurantName,
    });
  };

  return (
    <>
      {cart.length && restaurantId === cart[0].restaurant?._id ? (
        <ThemeConsumer>
          {(theme) => (
            <ThemeProvider
              theme={
                theme.currentThemeMode === themeModes.light
                  ? getTheme(themeModes.dark)
                  : getTheme(themeModes.light)
              }
            >
              <BlurView level={4}>
                <View pb={insets.bottom} bg="opacity.95">
                  <View
                    p="2xl"
                    pt="2xl"
                    pb="2xl"
                    // pb={insets.bottom}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <View flex={2} mr="md">
                      <Text size="lg" weight="medium" color="primary.500">
                        {getCount(cart) > 1
                          ? `${getCount(cart)} Items Selected`
                          : `${getCount(cart)} Item Selected`}
                      </Text>
                      <Text size="sm" color="primary.200" numberOfLines={1}>
                        {restaurantName}
                      </Text>
                    </View>
                    <View flex={1}>
                      <Button
                        iconLeft=""
                        iconRight="forward-outline-300"
                        state=""
                        appearance="filled"
                        size="lg"
                        rounded="md"
                        iconSize="md"
                        onPress={reviewOrder}
                      >
                        Next
                      </Button>
                    </View>
                  </View>
                </View>
              </BlurView>
            </ThemeProvider>
          )}
        </ThemeConsumer>
      ) : null}
    </>
  );
};

export default SelectedCartFooter;
