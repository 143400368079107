import React, { useState, useCallback } from 'react';
import { ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  View,
  Text,
  IconButton,
  TopNavigation,
  Icon,
  Pressable,
} from '../../components/new';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useCommonStore from '../../stores/commonStore';
import useUserStore from '../../stores/userStore';
import Ticket, { TicketVariant } from '../../components/new/composites/Ticket';
import {
  DateFormatsEnum,
  applyEllipsis,
  getFormatedDate,
  getFullName,
  navigateBack,
} from '../../utilities/helper';

const TicketScreen = (props) => {
  const { data } = props;
  const navigation = useNavigation();

  const expiredOn = new Date(data?.expires_on);
  const noOfPeople = parseFloat(data?.additionalFields?.event?.spaces);
  const customerName = getFullName(
    data?.user?.name?.first,
    data?.user?.name?.last,
  );

  const evenStartDate = getFormatedDate(
    new Date(data?.additionalFields?.event?.startTime),
    DateFormatsEnum.DateMonthYear,
  );

  const eventStartTime = getFormatedDate(
    new Date(data?.additionalFields?.event?.startTime),
    DateFormatsEnum.TIME,
  );

  const navigateToTnCScreen = () => {
    const tncList = data?.finePrint?.details ?? [];
    if (tncList?.length <= 0) {
      return;
    }
    navigation.navigate('ticketTnC', { details: tncList });
  };

  return (
    <View alignItems="center" flex={1}>
      <ScrollView style={{ flex: 1 }}>
        <Ticket
          variant={
            data?.ticketType === 'REWARD_TICKET'
              ? TicketVariant.REWARD
              : TicketVariant.EVENT
          }
          eventDate={evenStartDate as string}
          eventStartTime={eventStartTime as string}
          headerImage={data?.thumbnailImg}
          customerName={
            typeof noOfPeople === 'number' && noOfPeople > 1
              ? `${customerName} +${noOfPeople - 1}`
              : customerName
          }
          expiryDate={
            getFormatedDate(expiredOn, DateFormatsEnum.DateMonthYear) as string
          }
          redeemableAt={[
            {
              lat: data?.canBeClaimedBy[0]?.venue?.geo?.lat,
              long: data?.canBeClaimedBy[0]?.venue?.geo?.long,
              location: applyEllipsis(data?.canBeClaimedBy[0]?.displayName, 20),
            },
          ]}
          headerTitle={data?.title}
          ticketId={data?.uId}
          state={data?.state}
          key={data?._id}
        />
      </ScrollView>
      <View px="4xl" pb="2xl" mb="4xl" flexDirection="row" alignItems="center">
        <Pressable onPress={navigateToTnCScreen}>
          <Icon name="info-outline-300" size="2xl" color="primary.200" />
        </Pressable>
        <Text size="xs" color="primary.200" ml="md" textAlign="center">
          {data?.finePrint?.summary}
        </Text>
      </View>
    </View>
  );
};

const TicketScreenWrapper = ({ navigation, route }) => {
  const { ticketId, ticketTitle, data } = route?.params ?? {};
  const campusID = useCommonStore((state) => state.campusId);
  const { role } = useUserStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const navigateToTnCScreen = () => {
    const tncList = data?.finePrint?.details ?? [];
    navigation.navigate('ticketTnC', { details: tncList });
  };

  return (
    <Layout level="3">
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        IconRight={
          data?.finePrint?.details?.length ? (
            <IconButton
              name="menu-dots-400"
              size="md"
              appearance="ghost"
              onPress={navigateToTnCScreen}
            />
          ) : (
            <View />
          )
        }
        title={ticketTitle}
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <TicketScreen data={data} />
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default TicketScreenWrapper;
