import React, { memo } from 'react';
import { FlatList, Image, useWindowDimensions } from 'react-native';
import { Text, View } from '../../../../components/new';
import { space } from '../../../../themes/new/theme';
import { imageTransformation } from '../../../../utilities/helper';

const areEqual = () => {
  return true;
};

const NearBy = ({ data, bgColor = '' }) => {
  const { width } = useWindowDimensions();

  const RenderItem = ({ item, index }) => (
    <View
      width={(width - 32) / 3}
      alignItems="center"
      mt={index > 2 ? '4xl' : 0}
    >
      <Image
        source={{ uri: imageTransformation(item?.icon, 40, '1-1') }}
        style={{
          height: space['9xl'],
          width: space['9xl'],
          resizeMode: 'contain',
        }}
      />
      <Text
        pt="lg"
        size="md"
        weight="medium"
        color="primary.400"
        textAlign="center"
      >
        {item?.title}
      </Text>
      <Text pt="sm" size="xs" color="primary.400">
        {item?.subtitle}
      </Text>
    </View>
  );

  return (
    <View py="9xl+9xl" bg={bgColor} px="2xl" alignItems="center">
      <FlatList
        data={data?.data}
        renderItem={RenderItem}
        numColumns={3}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    </View>
  );
};

export default memo(NearBy, areEqual);
