/* eslint-disable react/require-default-props */
import * as React from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Swipeable } from 'react-native-gesture-handler';
import View from '../../basic/View/View';
import Text from '../Typography/Text/Text';
import Pressable from '../../basic/Pressable/Pressable';
import { getTheme } from '../../../../themes/new/theme';
import { FontWeights } from '../../../../themes/new/helper';

const config = {
  duration: 200,
  easing: Easing.bezier(0.17, 0.67, 0.83, 0.67),
  useNativeDriver: true,
};

interface CardProps {
  heading?: string;
  headingColor?: string;
  subHeading?: string;
  subHeadingColor?: string;
  headingRight?: JSX.Element;
  topRightCorner?: JSX.Element;
  bottomLeftCorner?: JSX.Element;
  bottomRightCorner?: JSX.Element;
  bottomLeftCTA?: JSX.Element;
  bottomRightCTA?: JSX.Element;
  swipeEnabled?: boolean;
  marginHorizontal: string;
  onPressSwipeRight: () => void;
  onSwipeableOpen: () => void;
  onSwipeableClose: () => void;
  onCardPressCB: () => void;
  preExpanded: boolean;
  blur?: boolean;
  shadow?: string;
  bg?: string;
  alwaysOpened: boolean;
  cardBorderColor: string;
  swipeRightText?: string;
}

const TaskCard = ({
  heading = '',
  headingColor = 'primary.400',
  subHeading = '',
  subHeadingColor = 'primary.100',
  headingRight = <></>,
  topRightCorner = <></>,
  bottomLeftCorner = <></>,
  bottomRightCorner = <></>,
  bottomLeftCTA = <></>,
  bottomRightCTA = <></>,
  swipeEnabled = false,
  marginHorizontal = 'none',
  onPressSwipeRight = () => {},
  onSwipeableOpen = () => {},
  onSwipeableClose = () => {},
  preExpanded = false,
  blur = false,
  shadow = 'none',
  bg = 'background.primary.elevation',
  alwaysOpened = false,
  onCardPressCB = () => {},
  cardBorderColor = 'transparent',
  swipeRightText = 'Mark Exited',
}: CardProps) => {
  const [isExpanded, setIsExpanded] = React.useState(preExpanded);
  const [isAlwaysOpened, setIsAlwaysOpened] = React.useState(false);
  const [cardHeightExpanded, setCardHeightExpanded] = React.useState(0);
  const [cardHeight, setCardHeight] = React.useState(0);
  const [expandedContentHeight, setExpandedContentHeight] = React.useState(0);

  React.useEffect(() => {
    if (
      cardHeightExpanded !== 0 &&
      expandedContentHeight !== 0 &&
      !isExpanded
    ) {
      setCardHeight(cardHeightExpanded - (expandedContentHeight + 16));
      if (preExpanded) {
        setIsExpanded(true);
      }
      if (alwaysOpened) {
        setIsAlwaysOpened(true);
      }
    }
  }, [cardHeightExpanded, expandedContentHeight]);

  React.useEffect(() => {
    if (preExpanded) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [preExpanded]);

  const heightValue = useSharedValue(0);

  const style = useAnimatedStyle(
    () => ({
      maxHeight: withTiming(heightValue.value, config),
    }),
    [cardHeightExpanded, expandedContentHeight, cardHeight],
  );

  function isReactFragment(variableToInspect) {
    if (variableToInspect.type) {
      return variableToInspect.type === React.Fragment;
    }
    return variableToInspect === React.Fragment;
  }

  React.useEffect(() => {
    if (isExpanded) {
      heightValue.value = cardHeightExpanded;
    } else {
      heightValue.value = 0;
    }
  }, [isExpanded]);

  const RenderRight = (progress, dragX) => {
    return (
      <View
        backgroundColor="error.500"
        alignItems="center"
        justifyContent="center"
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
        borderColor="error.500"
        // mr="2xl"
        ml={
          marginHorizontal !== '' && marginHorizontal?.length > 0
            ? -8 - getTheme().space[marginHorizontal]
            : -24
        }
        pr="2xl"
        pl="2xl"
        // my={1}
        onStartShouldSetResponder={onPressSwipeRight}
      >
        <Text size="md" weight="medium" color="primary.10">
          {swipeRightText}
        </Text>
      </View>
    );
  };

  const onLayout = React.useCallback((event) => {
    setCardHeightExpanded(event?.nativeEvent?.layout?.height);
  }, []);

  return (
    <Swipeable
      useNativeAnimations
      overshootRight={false}
      renderRightActions={RenderRight}
      cancelsTouchesInView
      enabled={!blur && swipeEnabled}
      onSwipeableOpen={onSwipeableOpen}
      onSwipeableClose={onSwipeableClose}
      containerStyle={{
        ...getTheme().boxShadows[shadow],
        borderRadius: getTheme().radii.md,
        borderWidth: getTheme().borderWidths.xs,
        borderColor: cardBorderColor,
      }}
    >
      <Pressable
        onPress={() => {
          if (
            ((bottomLeftCTA && !isReactFragment(bottomLeftCTA)) ||
              (bottomRightCTA && !isReactFragment(bottomRightCTA))) &&
            !alwaysOpened
          ) {
            setIsExpanded(!isExpanded);
          }
          onCardPressCB();
        }}
        disabled={blur}
      >
        {!!blur && (
          <View
            backgroundColor="opacity.60"
            height="100%"
            width="100%"
            position="absolute"
            zIndex={1}
            borderRadius="md"
          />
        )}
        <View
          bg={bg}
          p="2xl"
          borderRadius="md"
          borderWidth="xs"
          borderColor="transparent"
          onLayout={onLayout}
        >
          <View
            flexDirection="row"
            // alignItems="center"
            justifyContent="space-between"
          >
            <View flex={1}>
              <View
                flexDirection="row"
                //   justifyContent="center"
                alignItems="center"
              >
                <Text
                  size="lg"
                  weight={FontWeights.MEDIUM}
                  color={headingColor}
                  numberOfLines={1}
                  ellipsizemode="tail"
                >
                  {heading}
                </Text>
                <View ml="lg">{headingRight}</View>
              </View>
              <Text
                color={subHeadingColor}
                size="sm"
                weight={FontWeights.MEDIUM}
                mt="sm"
              >
                {subHeading}
              </Text>
            </View>
            <View>{topRightCorner}</View>
          </View>
          {!isReactFragment(bottomLeftCorner) ||
          !isReactFragment(bottomRightCorner) ? (
            <View
              flexDirection="row"
              mt="2xl"
              alignItems="center"
              justifyContent="space-between"
            >
              <View>{bottomLeftCorner}</View>
              <View>{bottomRightCorner}</View>
            </View>
          ) : (
            <></>
          )}
          {!blur ? (
            <Animated.View
              style={[
                style,
                {
                  // borderRadius: getTheme().radii.md,
                  // borderWidth: getTheme().borderWidths.xs,
                  // borderColor: 'transparent',
                  overflow: 'hidden',
                },
              ]}
            >
              <View
                flexDirection="row"
                mt="2xl"
                alignItems="center"
                // justifyContent="space-between"
                onLayout={(event) => {
                  setExpandedContentHeight(event?.nativeEvent?.layout?.height);
                }}
              >
                <View flex={1}>{bottomLeftCTA}</View>
                {bottomRightCTA && !isReactFragment(bottomRightCTA) ? (
                  <View flex={1} ml="sm+md">
                    {bottomRightCTA}
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </Animated.View>
          ) : (
            <></>
          )}
          {alwaysOpened ? (
            <View
              flexDirection="row"
              mt="2xl"
              alignItems="center"
              // justifyContent="space-between"
              onLayout={(event) => {
                setExpandedContentHeight(event?.nativeEvent?.layout?.height);
              }}
            >
              <View flex={1}>{bottomLeftCTA}</View>
              {bottomRightCTA && !isReactFragment(bottomRightCTA) ? (
                <View flex={1} ml="sm+md">
                  {bottomRightCTA}
                </View>
              ) : (
                <></>
              )}
            </View>
          ) : (
            <></>
          )}
        </View>
        {/* </Animated.View> */}
      </Pressable>
    </Swipeable>
  );
};

export default TaskCard;
