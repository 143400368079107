import * as React from 'react';
import { Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  IconButton,
  Modal,
  Text,
  View,
} from '../../../../components/new';
import ValetParkingImage from '../../../../../assets/images/parking-valet.webp';
import { reparkQuery } from '../../API/reparkQuery';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { formatPlateNo } from '../../../../utilities/helper';
import useTaskStore from '../../../../stores/taskStore';

const AssignReparkingConfirmation = ({
  item,
  modalVisible,
  setModalVisible,
}) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const navigation = useNavigation();
  const { arrivedTasks, setArrivedTasks } = useTaskStore((state) => state);
  const handleRepark = () => {
    const data = {
      valetId: item?.valet?._id,
      taskId: item?._id,
      taskEndLocationId: item?.taskStartLocation?.locationId,
    };
    reparkQuery(data)
      .then((res) => {
        setModalVisible(false);
        const arr = arrivedTasks.filter((i) => i._id !== item?._id);
        setArrivedTasks({ data: arr });
        dispatchSnackbar({
          msg: `Task moved to parking`,
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
          position: 'top',
        });
      })
      .catch((err) => {
        setModalVisible(false);
        if (err?.res?.errors[0]?.extensions?.code === 104) {
          navigation.popToTop();
        }
        dispatchSnackbar({
          msg: err?.res?.errors[0]?.message || 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          position: 'top',
        });
      });
  };

  const valetFulName = `${item?.valet?.name?.first || ''} ${
    item?.valet?.name?.last || ''
  }`;

  return (
    <Modal
      bg="blur"
      visible={modalVisible}
      onBackdropPress={() => {
        setModalVisible(false);
      }}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View bg="background.primary.base" py="4xl" px="7xl" borderRadius="lg">
        <View alignItems="center" mb="4xl">
          <Image style={{ width: 60, height: 60 }} source={ValetParkingImage} />
        </View>
        <View mb="4xl">
          <View mb="xl">
            <Text
              size="2xl"
              weight="medium"
              textAlign="center"
              color="singletone.black"
            >
              Assign Re-Parking Task to
            </Text>
            <Text
              size="2xl"
              weight="medium"
              textAlign="center"
              color="singletone.black"
            >
              {valetFulName}
            </Text>
          </View>
          <Text textAlign="center" color="primary.300">
            Tag ID - #{item?.parkingTag}
          </Text>
          <Text textAlign="center" color="primary.300">
            VRN - {formatPlateNo(item?.vehicle?.registration?.plate)}
          </Text>
        </View>
        <Button onPress={handleRepark} appearance="filled">
          Assign Re-Parking Task
        </Button>
      </View>
      <View mt="2xl" width="90%" alignItems="center">
        <IconButton
          name="close-outline-300"
          size="xl"
          bg="primary.500"
          iconColor="primary.50"
          onPress={() => {
            setModalVisible(false);
          }}
        />
      </View>
    </Modal>
  );
};

export default AssignReparkingConfirmation;
