import { graphql, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';
import fetchTaskMetricsQuery from '../screens/valetSupervisor/API/taskMetricsQuery';

type MetricsQueryParams = {
  startTimeStamp: number;
  endTimeStamp: number;
};

type TaskQueryParams = {
  filter: any;
  sort?: any;
};

const mainQuery = graphql`
  query supervisorOverviewApiMainQuery(
    $startTimeStamp: Int!
    $endTimeStamp: Int!
    $parkingLocationFilter: findParkingLocationFilterInput!
  ) {
    getTaskMetricsDateWise(
      startTimeStamp: $startTimeStamp
      endTimeStamp: $endTimeStamp
    ) {
      metrics {
        date
        assignmentTime
        assignmentScore
        deliveryTime
        deliveryScore
        parkingTime
        parkingScore
        onboardingTime
        onboardingScore
      }
    }
    findParkingLocation(filter: $parkingLocationFilter) {
      ...searchParkingList_findParkingLocation
    }
  }
`;

const tasksQuery = graphql`
  query supervisorOverviewApiTasksQuery(
    $taskLimit: Int
    $taskFilter: findTaskFilterInput!
    $sort: SortFindManytaskInput
  ) {
    findTasks(filter: $taskFilter, limit: $taskLimit, sort: $sort) {
      taskType
      vehicle {
        brand {
          name
        }
        registration {
          plate
        }
      }
      history {
        action
        event_at
      }
      valet {
        identityCode
      }
      metrics {
        isWithinETA
        isParkingDelayed
        isOnbardingDelayed
        isAssignmentDelayed
        assignedIn
        acceptedIn
        taskOnboardeIn
        taskCompletionIn
        valetTookInCompletion
      }
      taskStartLocation {
        name
      }
      taskEndLocation {
        name
      }
      parkingTag
    }
  }
`;

const fetchTaskMetrics = function (data: MetricsQueryParams) {
  const query = graphql`
    query supervisorOverviewApiAllMetricQuery(
      $startTimeStamp: Int!
      $endTimeStamp: Int!
    ) {
      getTaskMetricsDateWise(
        startTimeStamp: $startTimeStamp
        endTimeStamp: $endTimeStamp
      ) {
        metrics {
          date
          assignmentTime
          assignmentScore
          deliveryTime
          deliveryScore
          parkingTime
          parkingScore
          onboardingTime
          onboardingScore
        }
      }
    }
  `;

  const variables = {
    startTimeStamp: data.startTimeStamp,
    endTimeStamp: data.endTimeStamp,
  };

  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};

export default {
  mainQuery,
  tasksQuery,
  fetchTaskMetrics,
};
