import React from 'react';
import { StyleSheet } from 'react-native';
import { Text, View } from '../../../../components/new';
import Pressable from '../../../../components/new/basic/Pressable/Pressable';
import DarkThemeWrapper from '../../wrapperComp/DarkThemeWrapper';
import { State } from '../../../../themes/new/helper';
import { getTheme, themeModes } from '../../../../themes/new/theme';
import CachedImageBackground from '../../../../components/new/custom/CachedImageBackground';

const GOLD_METAL_BG = require('../../../../../assets/images/black_membership/golden-metal-texture.png');

const lightTheme = getTheme(themeModes.light);
const darkTheme = getTheme(themeModes.dark);

type GoldBtnState = Exclude<`${State}`, 'selected'>;

const GoldButton = ({
  state,
  onPress,
  text,
}: {
  onPress: () => void;
  text: string;
  state?: GoldBtnState;
}) => {
  return (
    <DarkThemeWrapper>
      <View overflow="hidden" borderRadius="md">
        <CachedImageBackground
          source={GOLD_METAL_BG}
          style={styles.bgImage}
          imageStyle={{
            resizeMode: 'cover',
          }}
        >
          <Pressable
            onPress={onPress}
            disabled={state === 'disabled'}
            pressStyle={{ backgroundColor: darkTheme.colors.opacity[20] }}
            style={{
              backgroundColor:
                state === State.DISABLED ? lightTheme.colors.primary[50] : '',
            }}
          >
            <Text
              textAlign="center"
              px="8xl"
              py="lg"
              size="md"
              color={
                state === State.DISABLED
                  ? lightTheme.colors.primary[100]
                  : 'static.black'
              }
            >
              {text}
            </Text>
          </Pressable>
        </CachedImageBackground>
      </View>
    </DarkThemeWrapper>
  );
};

const styles = StyleSheet.create({
  bgImage: {
    justifyContent: 'center',
    overflow: 'hidden',
  },
});

export default GoldButton;
