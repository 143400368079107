/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const makeTrackMyVehicleScreenQueryVars = ({
  userId = '',
  status = ['INCOMING', 'PARKED', 'OUTGOING', 'OUT'],
}) => {
  const daysDiff = 1;
  const toDate = new Date();
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - daysDiff);
  const tempFilter = {
    ownerId: userId,
    status,
  };
  return {
    filter: tempFilter,
  };
};

export const trackMyVehicleScreenQuery = graphql`
  query trackMyVehicleScreenQuery(
    $historyFilter: findParkingsFilterInput!
    $count: Int!
    $cursor: String
  ) {
    ...trackMyVehicleScreenQueryParkingFragment
      @arguments(filter: $historyFilter, count: $count, cursor: $cursor)
  }
`;

export const trackMyVehicleScreenParkingFragment = graphql`
  fragment trackMyVehicleScreenQueryParkingFragment on Query
  @refetchable(queryName: "trackMyVehicleScreenParkingQuery")
  @argumentDefinitions(
    filter: { type: "findParkingsFilterInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findParkingV2(filter: $filter, first: $count, after: $cursor)
      @connection(key: "trackMyVehicleScreenParkingFragment__findParkingV2") {
      edges {
        node {
          _id
          journey {
            taskID
          }
          vehicle {
            brand {
              name
              brandLogo
            }
            color
            registration {
              plate
            }
          }
          parkingRewardsGiven
          campus {
            _id
            contact {
              phone {
                prefix
                number
              }
            }
          }
          feedback {
            _id
            type
            feedbacks {
              topic
              summary
              ratingType
              rating
              tags
            }
          }
          parkingInvoice {
            paymentDetails {
              isPaid
              amount
              mode
            }
            charges {
              chargesBreakup {
                duration
                payable
              }
              discountAmount
              totalPayable
              valetTip
            }
            invoicePdfPath
          }
          pointsData {
            source {
              type
            }
            amount
          }
          parked_at
          tasks {
            _id
            taskType
            isVehicleWhitelisted
            user {
              name {
                first
                last
              }
              contact {
                phone {
                  prefix
                  number
                }
              }
            }
            taskStartLocation {
              locationId
              name
              parkingSpaces {
                floor {
                  floorId
                  floorName
                  bay {
                    bayId
                    bayName
                  }
                }
              }
            }
            taskEndLocation {
              locationId
              name
              parkingSpaces {
                floor {
                  floorId
                  floorName
                  bay {
                    bayId
                    bayName
                  }
                }
              }
            }
            ETA {
              delayAt
              createToArrivedTime
              createToAcceptedTime
              acceptedToArrivedTime
              acceptedToArrivedCustomerTime
            }
            valet {
              contact {
                phone {
                  prefix
                  number
                }
              }
              name {
                first
                last
              }
              profileImage
            }
            history {
              action
              event_at
            }
            vehicle {
              brand {
                name
                brandLogo
              }
              color
              registration {
                plate
              }
            }
            parkingTag
            parkingId
            campus {
              _id
              name
            }
            ownerId
            createdByUser {
              name {
                first
                _id
              }
              role {
                name
              }
            }
            points {
              _id
              amount
              type
              source {
                type
              }
            }
          }
          invoicesData {
            priceDetails {
              discountGiven
              rewardsUsed
              netAmount
            }
          }
          status
          parkingTag
          rewardData {
            parkingId
            rewardOrigin
            uniqueHash
            rewardType
            rewardUniqueId
            isUsed
            isExpired
            expireAt
            _id
            updated_at
            created_at
            deleted
            deletedAt
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
