import React, { PropsWithChildren, ReactElement } from 'react';
import View from '../../basic/View/View';

type BonusInfoCardProps = {
  children: ReactElement | Array<ReactElement>;
};

const BonusInfoCard = ({ children }: BonusInfoCardProps) => {
  return (
    <View
      pt="md"
      pb="lg"
      alignItems="center"
      justifyContent="center"
      width="100%"
      borderWidth="xs"
      borderRadius="md"
      borderColor="primary.400"
      bg="primary.500"
    >
      {children}
    </View>
  );
};

export default BonusInfoCard;
