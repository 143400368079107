/**
 * @generated SignedSource<<644bfb48d799af4dee59822c682fc362>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumaddonAdditionalCashbackRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonAdditionalDiscountRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonPriceDiscountBaseDiscountType = "PERCENTAGE" | "%future added value";
export type EnumaddonType = "a_32ND_BLACK_MEMBERSHIP" | "%future added value";
export type EnumeventMediaCoverMediaType = "image" | "video" | "%future added value";
export type SlotTagColorEnumITC = "DISABLED" | "ERROR" | "INFO" | "NONE" | "SUCCESS" | "WARNING" | "%future added value";
export type findEventInput = {
  _id?: any | null;
  campus: FilterFindManyeventCampusInput;
  eventTiming?: ReadonlyArray<FilterFindManyeventEventTimingInput | null> | null;
  host?: FilterFindManyeventHostInput | null;
  isActive: boolean;
  priorityEventId?: string | null;
  title?: string | null;
  upcomingEvent?: boolean | null;
  venue?: string | null;
};
export type FilterFindManyeventCampusInput = {
  _id: any;
};
export type FilterFindManyeventHostInput = {
  name?: string | null;
};
export type FilterFindManyeventEventTimingInput = {
  _id?: any | null;
  date?: any | null;
  slot?: FilterFindManyeventEventTimingSlotInput | null;
};
export type FilterFindManyeventEventTimingSlotInput = {
  _id?: any | null;
  availableTickets?: ReadonlyArray<FilterFindManyeventEventTimingSlotAvailableTicketsInput | null> | null;
  endTime?: any | null;
  isActive?: boolean | null;
  slotSpace?: number | null;
  slotTag?: string | null;
  startTime?: any | null;
};
export type FilterFindManyeventEventTimingSlotAvailableTicketsInput = {
  _id?: any | null;
  isActive?: boolean | null;
  maxSpace?: number | null;
  name?: string | null;
  personAllowed?: number | null;
  price?: number | null;
};
export type FilterFindManyaddonInput = {
  AND?: ReadonlyArray<FilterFindManyaddonInput> | null;
  OR?: ReadonlyArray<FilterFindManyaddonInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyaddonOperatorsInput | null;
  additionalCashback?: ReadonlyArray<FilterFindManyaddonAdditionalCashbackInput | null> | null;
  additionalDiscount?: ReadonlyArray<FilterFindManyaddonAdditionalDiscountInput | null> | null;
  expiryDuration?: FilterFindManyaddonExpiryDurationInput | null;
  isMembershipType?: boolean | null;
  name?: string | null;
  price?: number | null;
  priceDiscount?: ReadonlyArray<FilterFindManyaddonPriceDiscountInput | null> | null;
  tier?: number | null;
  type?: EnumaddonType | null;
};
export type FilterFindManyaddonPriceDiscountInput = {
  _id?: any | null;
  baseDiscount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
};
export type FilterFindManyaddonPriceDiscountBaseDiscountInput = {
  _id?: any | null;
  type?: EnumaddonPriceDiscountBaseDiscountType | null;
  value?: number | null;
};
export type FilterFindManyaddonExpiryDurationInput = {
  _id?: any | null;
  days?: number | null;
};
export type FilterFindManyaddonAdditionalCashbackInput = {
  _id?: any | null;
  cashback?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refId?: any | null;
  refType?: EnumaddonAdditionalCashbackRefType | null;
};
export type FilterFindManyaddonAdditionalDiscountInput = {
  _id?: any | null;
  discount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refType?: EnumaddonAdditionalDiscountRefType | null;
};
export type FilterFindManyaddonOperatorsInput = {
  _id?: FilterFindManyaddon_idOperatorsInput | null;
  tier?: FilterFindManyaddonTierOperatorsInput | null;
  type?: FilterFindManyaddonTypeOperatorsInput | null;
};
export type FilterFindManyaddonTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnumaddonType | null;
  gte?: EnumaddonType | null;
  in?: ReadonlyArray<EnumaddonType | null> | null;
  lt?: EnumaddonType | null;
  lte?: EnumaddonType | null;
  ne?: EnumaddonType | null;
  nin?: ReadonlyArray<EnumaddonType | null> | null;
};
export type FilterFindManyaddonTierOperatorsInput = {
  exists?: boolean | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number | null> | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
  nin?: ReadonlyArray<number | null> | null;
};
export type FilterFindManyaddon_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type reservationScreenQuery$variables = {
  addonsFilter?: FilterFindManyaddonInput | null;
  filter: findEventInput;
  isMember: boolean;
};
export type reservationScreenQuery$data = {
  readonly findAddons?: ReadonlyArray<{
    readonly _id: any;
    readonly additionalCashback: ReadonlyArray<{
      readonly cashback: {
        readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
        readonly value: number | null;
      };
      readonly refType: EnumaddonAdditionalCashbackRefType;
    } | null> | null;
    readonly additionalDiscount: ReadonlyArray<{
      readonly discount: {
        readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
        readonly value: number | null;
      };
      readonly refType: EnumaddonAdditionalDiscountRefType;
    } | null> | null;
    readonly type: EnumaddonType;
  }>;
  readonly findEvents: ReadonlyArray<{
    readonly _id: any;
    readonly campus: {
      readonly _id: any;
    };
    readonly description: string;
    readonly eventTiming: ReadonlyArray<{
      readonly _id: any | null;
      readonly date: any;
      readonly slot: {
        readonly _id: any | null;
        readonly availableTickets: ReadonlyArray<{
          readonly _id: any | null;
          readonly basePrice: number;
          readonly isActive: boolean | null;
          readonly name: string;
          readonly personAllowed: number;
          readonly price: number;
          readonly slotsLeft: number | null;
        } | null>;
        readonly endTime: any;
        readonly slotSpace: number;
        readonly slotTag: string;
        readonly slotTagColor: SlotTagColorEnumITC | null;
        readonly slotsLeft: number | null;
        readonly startTime: any;
      };
    } | null>;
    readonly faqs: ReadonlyArray<{
      readonly answer: string;
      readonly question: string;
    } | null> | null;
    readonly host: {
      readonly description: string;
      readonly media: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly name: string;
    } | null;
    readonly media: {
      readonly cover: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly past: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly testimonials: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
    } | null;
    readonly mediaCoverages: ReadonlyArray<{
      readonly hyperLink: string | null;
      readonly imageUrl: string;
    } | null> | null;
    readonly motivation: ReadonlyArray<{
      readonly logo: string;
      readonly title: string;
    } | null> | null;
    readonly timeline: ReadonlyArray<{
      readonly duration: number | null;
      readonly logo: string;
      readonly media: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly title: string;
    } | null> | null;
    readonly title: string;
    readonly venue: string;
  }>;
};
export type reservationScreenQuery = {
  response: reservationScreenQuery$data;
  variables: reservationScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addonsFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isMember"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mediaURL",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mediaType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cover",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayOrder",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "eventMediaCover",
  "kind": "LinkedField",
  "name": "media",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotsLeft",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refType",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  (v11/*: any*/)
],
v14 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "event",
    "kind": "LinkedField",
    "name": "findEvents",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "eventCampus",
        "kind": "LinkedField",
        "name": "campus",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "venue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventMedia",
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "eventMediaCover",
            "kind": "LinkedField",
            "name": "cover",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "eventMediaCover",
            "kind": "LinkedField",
            "name": "past",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "eventMediaCover",
            "kind": "LinkedField",
            "name": "testimonials",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventMediaCoverages",
        "kind": "LinkedField",
        "name": "mediaCoverages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hyperLink",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventFaqs",
        "kind": "LinkedField",
        "name": "faqs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "question",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answer",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventHost",
        "kind": "LinkedField",
        "name": "host",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v5/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventMotivation",
        "kind": "LinkedField",
        "name": "motivation",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventTimeline",
        "kind": "LinkedField",
        "name": "timeline",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventEventTiming",
        "kind": "LinkedField",
        "name": "eventTiming",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "eventEventTimingSlot",
            "kind": "LinkedField",
            "name": "slot",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slotSpace",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slotTag",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slotTagColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "eventEventTimingSlotAvailableTickets",
                "kind": "LinkedField",
                "name": "availableTickets",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "basePrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personAllowed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "condition": "isMember",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filter",
            "variableName": "addonsFilter"
          }
        ],
        "concreteType": "addon",
        "kind": "LinkedField",
        "name": "findAddons",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "addonAdditionalCashback",
            "kind": "LinkedField",
            "name": "additionalCashback",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "addonPriceDiscountBaseDiscount",
                "kind": "LinkedField",
                "name": "cashback",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "addonAdditionalDiscount",
            "kind": "LinkedField",
            "name": "additionalDiscount",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "addonPriceDiscountBaseDiscount",
                "kind": "LinkedField",
                "name": "discount",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "reservationScreenQuery",
    "selections": (v14/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "reservationScreenQuery",
    "selections": (v14/*: any*/)
  },
  "params": {
    "cacheID": "54ee26e22c47f417b33d86d700137615",
    "id": null,
    "metadata": {},
    "name": "reservationScreenQuery",
    "operationKind": "query",
    "text": "query reservationScreenQuery(\n  $filter: findEventInput!\n  $addonsFilter: FilterFindManyaddonInput\n  $isMember: Boolean!\n) {\n  findEvents(filter: $filter) {\n    _id\n    campus {\n      _id\n    }\n    title\n    description\n    venue\n    media {\n      cover {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n      past {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n      testimonials {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n    }\n    mediaCoverages {\n      imageUrl\n      hyperLink\n    }\n    faqs {\n      question\n      answer\n    }\n    host {\n      name\n      description\n      media {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n    }\n    motivation {\n      title\n      logo\n    }\n    timeline {\n      title\n      logo\n      duration\n      media {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n    }\n    eventTiming {\n      _id\n      date\n      slot {\n        startTime\n        endTime\n        slotSpace\n        slotsLeft\n        slotTag\n        slotTagColor\n        availableTickets {\n          name\n          price\n          basePrice\n          personAllowed\n          isActive\n          slotsLeft\n          _id\n        }\n        _id\n      }\n    }\n  }\n  findAddons(filter: $addonsFilter) @include(if: $isMember) {\n    _id\n    type\n    additionalCashback {\n      refType\n      cashback {\n        value\n        type\n      }\n    }\n    additionalDiscount {\n      refType\n      discount {\n        value\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8833fe663d1b26db7de04bb030477183";

export default node;
