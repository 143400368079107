import React, { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import { PermissionStatus } from 'expo-modules-core/src/PermissionsInterface';
import Constants from 'expo-constants';
import { OneSignal } from 'react-native-onesignal';
import { Button, Text, View } from '../new';
import useNotificationModalStore, {
  NotificationModalUserActions,
} from '../../stores/notificationModalStore';
import useAuthStore from '../../stores/authStore';
import { mapTokenToUser } from '../../relay/notificationTokenMappingApis';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import useLoginModalStore from '../../stores/loginModalStore';
import { showNotificationPopupLogic } from '../../utilities/Utility';
import BottomSheetV2 from '../new/composites/BottomSheet/BottomSheetV2';
import CustomLottieView from '../new/custom/CustomLottieView';
import mixpanel from '../../utilities/mixpanelConfig';
import useUserStore from '../../stores/userStore';

export enum NotificationType {
  NOTIFICATION = 'NOTIFICATION',
  IN_APP_POPUP = 'IN_APP_POPUP',
}

export interface NotificationPayload {
  url?: string;
  type: NotificationType;
}

Notifications.setNotificationHandler({
  handleNotification: async (data) => {
    const payloadData: NotificationPayload = data.request.content
      .data as NotificationPayload;

    switch (payloadData.type) {
      case NotificationType.IN_APP_POPUP: {
        // handle In app popup support here
        return {
          shouldShowAlert: false,
          shouldPlaySound: false,
          shouldSetBadge: false,
        };
      }
      case NotificationType.NOTIFICATION: {
        return {
          shouldShowAlert: true,
          shouldPlaySound: true,
          shouldSetBadge: true,
        };
      }
      default: {
        return {
          shouldShowAlert: true,
          shouldPlaySound: true,
          shouldSetBadge: true,
        };
      }
    }
  },
});

const NotificationPermissionModal = () => {
  const componentState = useNotificationModalStore((state) => state);
  const { id, firstName, lastName, contact } = useUserStore((state) => state);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const loginState = useLoginModalStore((state) => state);
  const [lastAction, setLastAction] = useState<NotificationModalUserActions>();

  const bottomSheetModalRef = useRef(null);

  useEffect(() => {
    if (componentState.isOpen && !loginState.showWelcomeModal) {
      setTimeout(() => {
        bottomSheetModalRef?.current?.show();
      }, 400);
    } else {
      bottomSheetModalRef?.current?.hide();
    }
  }, [componentState.isOpen, loginState.showWelcomeModal]);

  useEffect(() => {
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  }, []);

  useEffect(() => {
    if (
      componentState.lastUserAction !==
      NotificationModalUserActions.ASK_ME_LATER
    ) {
      showNotificationPopupLogic();
    }
    if (isLoggedIn) {
      mixpanel.identify(`${id}`).then(() => {
        mixpanel.getPeople().set('userId', id);
        mixpanel.getPeople().set('name', `${firstName} ${lastName}`);
        mixpanel.getPeople().set('lastName', lastName);
        // @ts-ignore
        mixpanel.getPeople().set('phone', contact?.phone?.number);
        // @ts-ignore
        mixpanel.getPeople().set('prefix', contact?.phone?.prefix);
      });
    }
  }, [isLoggedIn]);

  const handleOnDismiss = () => {
    if (lastAction) {
      componentState.setLastUserAction(lastAction);
    } else {
      componentState.setLastUserAction(
        NotificationModalUserActions.ASK_ME_LATER,
      );
    }
    componentState.setWillOpen(false);
    componentState.setIsOpen(false);
  };

  const handleAskMeLater = () => {
    firebaseEventLogger('NOTIFICATION_MODAL_ASK_ME_LATER_CLICKED', {
      interactionType: 'tap',
    });
    setLastAction(NotificationModalUserActions.ASK_ME_LATER);
    componentState.setWillOpen(false);
    componentState.setLastUserAction(NotificationModalUserActions.ASK_ME_LATER);
    componentState.setIsOpen(false);
  };
  const handleAllow = async () => {
    // Also need enable notifications to complete OneSignal setup
    await OneSignal.Notifications.requestPermission(true);
    firebaseEventLogger('NOTIFICATION_MODAL_ALLOW_CLICKED', {
      interactionType: 'tap',
    });
    // request for permission
    const { status } = await Notifications.requestPermissionsAsync();
    if (status === PermissionStatus.GRANTED) {
      setLastAction(NotificationModalUserActions.ALLOWED);
      firebaseEventLogger('NOTIFICATION_GRANTED_CLICKED', {
        interactionType: 'tap',
      });
      const token = (
        await Notifications.getExpoPushTokenAsync({
          projectId: Constants.expoConfig.extra.eas.projectId,
        })
      ).data;
      await mapTokenToUser(token)
        .then((res) => {
          console.log('==success', res);
        })
        .catch((err) => {
          console.log('---err--', err);
        });
      componentState.setExpoToken(token);
      componentState.setWillOpen(false);
      componentState.setIsOpen(false);
    } else {
      firebaseEventLogger('NOTIFICATION_DENIED_CLICKED', {
        interactionType: 'tap',
      });
      setLastAction(NotificationModalUserActions.CLOSED_MODAL_DENIED);
      componentState.setWillOpen(false);
      componentState.setIsOpen(false);
    }
  };

  if (!componentState.isOpen) {
    return null;
  }

  return (
    <BottomSheetV2
      ref={bottomSheetModalRef}
      onClose={handleOnDismiss}
      insets={false}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginHorizontal: 16,
          marginTop: 12,
        }}
      >
        <CustomLottieView
          autoPlay
          loop
          resizeMode="cover"
          source={require('../../../assets/lottie/green-bell.json')}
          style={{ width: 200, height: 200, marginBottom: 8 }}
          containerWidth={200}
        />
        <Text
          color="primary.400"
          weight="medium"
          style={{ fontSize: 20, marginBottom: 8, textAlign: 'center' }}
        >
          {componentState.title}
        </Text>
        <Text
          color="primary.200"
          style={{
            fontSize: 14,
            fontWeight: '200',
            textAlign: 'center',
            marginBottom: 32,
          }}
        >
          {componentState.subTitle}
        </Text>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Button
            appearance="outline"
            style={{ flex: 1, marginRight: 18 }}
            onPress={handleAskMeLater}
          >
            {componentState.denyButtonText}
          </Button>
          <Button style={{ flex: 1 }} onPress={handleAllow}>
            {componentState.allowButtonText}
          </Button>
        </View>
      </View>
    </BottomSheetV2>
  );
};

export default NotificationPermissionModal;
