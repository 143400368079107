/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Camera, CameraType, FlashMode, AutoFocus } from 'expo-camera';
import {
  AppState,
  Image,
  Linking,
  Platform,
  useWindowDimensions,
} from 'react-native';
import uuid from 'react-native-uuid';
import { IconButton, TopNavigation, View } from '../../../components/new';
import { getTheme } from '../../../themes/new/theme';
import {
  dynamicHeightMaker,
  navigateBack,
  PaymentStatusEnum,
  SCAN_STATUS,
  UPLOAD_BILL_STATUS,
} from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import { Appearance } from '../../../themes/new/helper';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import {
  checkCameraVisible,
  checkImageVisible,
  checkStatusNotCapturing,
} from './validation';
import ScanStatusIdle from './ScanStatusIdle';
import BottomCTA from './BottomCTA';
import { uploadImageToS3Query } from '../../../relay/microServicesApis';
import { createBill, submitBill } from './scanBillApi';
import ScanStatusProcessing from './ScanStatusProcessing';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import useAuthStore from '../../../stores/authStore';
import useLoginModalStore from '../../../stores/loginModalStore';

const MAX_SCANS = 2;

const ScanBillScreen = ({ navigation, route }) => {
  const { orderType, orderId, amount, cashbackPercentage } =
    route?.params ?? {};
  const overlayColor = 'primary.10';
  const { isLoggedIn } = useAuthStore((state) => state);
  const { setIsOpen } = useLoginModalStore((state) => state);
  const { width, height } = useWindowDimensions();
  const [camera, setCamera] = useState(null);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [scanAttemptCount, setScanAttemptCount] = useState(0);
  const [detectedBillImage, setDetectedBillImage] = useState('');
  const [scanStatus, setScanStatus] = useState<SCAN_STATUS>(SCAN_STATUS.IDLE);
  const [createBillId, setCreateBillId] = useState('');
  const [flash, setFlash] = useState(FlashMode.off);
  const role = useUserStore((state) => state.role);
  const detectionSquareWidth = width - 2 * getTheme().space['2xl'];
  const [extractingDetails, setExtractingDetails] = useState(false);
  const [topNavigationHeight, setTopNavigationHeight] = useState(0);
  const [submitBillDetails, setSubmitBillDetails] = useState(false);
  const [
    hasReturnedFromPermissionSettings,
    setHasReturnedFromPermissionSettings,
  ] = useState(false);
  const [recheckPermission, setRecheckPermission] = useState(false);
  const [appState, setAppState] = useState(AppState.currentState);
  useEffect(() => {
    if (Platform.OS === 'web') return;
    const handleAppStateChange = (nextAppState) => {
      if (appState.match(/inactive|background/) && nextAppState === 'active') {
        setHasReturnedFromPermissionSettings(true);
      }
      setAppState(nextAppState);
    };
    const subscription = AppState.addEventListener(
      'change',
      handleAppStateChange,
    );
    return () => {
      subscription.remove();
    };
  }, [appState]);
  const onLayoutTopNavigation = ({ nativeEvent }) => {
    if (topNavigationHeight === 0) {
      setTopNavigationHeight(nativeEvent?.layout?.height);
    }
  };

  const captureImage = async () => {
    const data = await camera.takePictureAsync({
      base64: true,
      quality: 0.2,
    });
    firebaseEventLogger(`scanBill__captureImage_screen`, {});
    setDetectedBillImage(data.base64);
    setScanStatus(SCAN_STATUS.PROCESSING);
  };

  const requestPermissionAgain = () => {
    Linking.openSettings();
  };

  const requestCameraPermission = () => {
    requestPermission()
      .then((res) => {
        if (res?.canAskAgain === false && res?.status !== 'granted') {
          dispatchSnackbar({
            msg: 'Please grant permission',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
          setTimeout(() => {
            requestPermissionAgain();
          }, 1000);
        } else if (res?.status !== 'granted') {
          dispatchSnackbar({
            msg: 'Permission denied!',
            status: SnackbarStatus.error,
            version: SnackbarVersion.v1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (permission && permission?.status !== 'denied' && !permission?.granted) {
      requestCameraPermission();
    }
  }, [permission]);

  useEffect(() => {
    if (hasReturnedFromPermissionSettings && recheckPermission) {
      setHasReturnedFromPermissionSettings(false);
      setRecheckPermission(false);
      requestCameraPermission();
    }
  }, [permission, hasReturnedFromPermissionSettings, recheckPermission]);

  const onSubmitBill = async ({ createBillIdProp }) => {
    setSubmitBillDetails(true);
    const data = {
      id: createBillIdProp || createBillId,
    };
    submitBill(
      data,
      (response) => {
        if (response && response?.submitBill && response?.submitBill?.record) {
          firebaseEventLogger(`scanBill__submitBillSuccess_screen`, {
            image: detectedBillImage,
            billId: response?.submitBill?.record?._id,
          });
          setExtractingDetails(false);
          setSubmitBillDetails(false);
          dispatchSnackbar({
            msg: 'Bill uploaded successfully!',
            status: SnackbarStatus.success,
            version: SnackbarVersion.v3,
            removeAfter: 4000,
          });
          navigation.navigate('AfterSubmitScreen', {
            infoText: 'Bill Uploaded Successfully',
            subText: `Earn ${cashbackPercentage}% cashback in your 32nd points account after verification.`,
            countDown: 5,
            status: PaymentStatusEnum.SUCCESSFUL,
            invoiceId: response?.submitBill?.record?.invoiceId,
          });
        }
      },
      (err) => {
        firebaseEventLogger(`scanBill__submitBillError_screen`, {
          image: detectedBillImage,
        });
        setScanStatus(SCAN_STATUS.PROCESSING);
        setExtractingDetails(false);
        setSubmitBillDetails(false);
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v3,
        });
      },
    );
  };

  const onPressContinue = async () => {
    firebaseEventLogger(`scanBill__continueTap_screen`, {
      image: detectedBillImage,
    });
    setExtractingDetails(true);
    setScanStatus(SCAN_STATUS.STARTED);
    const date = new Date();
    const pictureKey = `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()}/${uuid.v4()}.jpeg`;
    const payload = {
      base64Image: detectedBillImage,
      bucketName: 'billuploads',
      contentType: 'image/jpeg',
      key: pictureKey,
    };
    const res = await uploadImageToS3Query(payload);
    const record = {
      userInputs: {
        orderType,
        orderId,
        priceDetails: {
          netAmount: Number(amount),
        },
        billImage: {
          bucket: res?.uploadImageToS3?.bucketName,
          key: res?.uploadImageToS3?.key,
        },
      },
    };
    createBill(
      { record },
      (response) => {
        if (response && response?.createBill && response?.createBill?.record) {
          firebaseEventLogger(`scanBill__createBillSuccess_screen`, {
            image: detectedBillImage,
            billId: response?.createBill?.record?._id,
          });
          setCreateBillId(response?.createBill?.record?._id);
          if (
            response?.createBill?.record?.processing?.success &&
            (response?.createBill?.record?.status ===
              UPLOAD_BILL_STATUS.PROCESSED ||
              response?.createBill?.record?.status ===
                UPLOAD_BILL_STATUS.MATCHED)
          ) {
            onSubmitBill({
              createBillIdProp: response?.createBill?.record?._id,
            });
          }
          if (!response?.createBill?.record?.processing?.success) {
            firebaseEventLogger(`scanBill__billFailed_screen`, {
              image: detectedBillImage,
              billId: response?.createBill?.record?._id,
            });
            setExtractingDetails(false);
            setScanStatus(SCAN_STATUS.FAILURE);
            setScanAttemptCount(scanAttemptCount + 1);
            dispatchSnackbar({
              msg:
                response?.createBill?.record?.processing?.msg ||
                'Something went wrong!',
              status: SnackbarStatus.error,
              version: SnackbarVersion.v3,
              removeAfter: 5000,
            });
          }
        }
      },
      (err) => {
        firebaseEventLogger(`scanBill__createBillError_screen`, {
          image: detectedBillImage,
          err: JSON.stringify(err),
        });
        setExtractingDetails(false);
        setScanStatus(SCAN_STATUS.PROCESSING);
        console.log('err----', err);
        dispatchSnackbar({
          msg: 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v3,
        });
      },
    );
  };

  return (
    <DarkThemeWrapper>
      <View bg={overlayColor}>
        <View
          zIndex={10}
          bg={overlayColor}
          width="100%"
          position="absolute"
          onLayout={onLayoutTopNavigation}
        >
          <TopNavigation
            appearance="ghost"
            level="none"
            IconLeft={
              <IconButton
                name={
                  checkStatusNotCapturing(scanStatus)
                    ? 'back-outline-300'
                    : 'close-outline-300'
                }
                size="md"
                appearance={Appearance.GHOST}
                onPress={() => navigateBack(navigation, role)}
              />
            }
            IconRight={
              checkStatusNotCapturing(scanStatus) ? (
                <></>
              ) : (
                <IconButton
                  name={flash ? 'flash-on-300' : 'flash-off-300'}
                  size="md"
                  mt="xl"
                  appearance={Appearance.GHOST}
                  onPress={() =>
                    setFlash(
                      flash === FlashMode.off ? FlashMode.torch : FlashMode.off,
                    )
                  }
                />
              )
            }
          />
        </View>
        <View height="100%">
          {checkCameraVisible(scanStatus) ? (
            <Camera
              ref={(ref) => setCamera(ref)}
              style={{
                flex: 1,
                marginTop: topNavigationHeight + getTheme().space['4xl'],
              }}
              type={CameraType.back}
              flashMode={flash}
              ratio="4:3"
              autoFocus={AutoFocus.on}
            >
              <View alignItems="center">
                <View bg={overlayColor} width={width} />
                <View width={width} height={height} flexDirection="row">
                  <View bg={overlayColor} flex={1} />
                  <View
                    borderColor="transparent"
                    borderWidth="md"
                    flexBasis={detectionSquareWidth}
                  />
                  <View bg={overlayColor} flex={1} />
                </View>
                <View flex={1} bg={overlayColor} width="100%" />
              </View>
            </Camera>
          ) : (
            <></>
          )}
          {checkImageVisible(scanStatus, detectedBillImage) ? (
            <View
              flex={1}
              alignItems="center"
              mt={topNavigationHeight + getTheme().space['4xl']}
            >
              <Image
                source={{
                  uri: `data:image/jpeg;base64,${detectedBillImage}`,
                }}
                style={{
                  marginTop: getTheme().space['sm+md'],
                  width: width - 2 * getTheme().space['2xl'],
                  height: dynamicHeightMaker(
                    width - 2 * getTheme().space['2xl'],
                    1.56,
                  ),
                }}
                resizeMode="cover"
              />
            </View>
          ) : (
            <></>
          )}
          {scanStatus === SCAN_STATUS.IDLE ? (
            <ScanStatusIdle
              onPressContinue={() => {
                if (!isLoggedIn) {
                  setIsOpen(true);
                  return;
                }
                setScanStatus(SCAN_STATUS.REQUESTED);
              }}
            />
          ) : (
            <></>
          )}
          {(scanStatus === SCAN_STATUS.PROCESSING ||
            scanStatus === SCAN_STATUS.STARTED) &&
          extractingDetails ? (
            <ScanStatusProcessing />
          ) : (
            <></>
          )}
          <View px="2xl">
            <BottomCTA
              scanStatus={scanStatus}
              overlayColor={overlayColor}
              captureImage={captureImage}
              onPressContinue={onPressContinue}
              onPressRetake={() => {
                firebaseEventLogger(`scanBill__retake_tap`);
                setScanStatus(SCAN_STATUS.REQUESTED);
              }}
              extractingDetails={extractingDetails}
              MAX_SCAN_ATTEMT_COUNT={MAX_SCANS}
              onPressStartAgain={() => {
                firebaseEventLogger(`scanBill__startAgain_tap`);
                setScanStatus(SCAN_STATUS.REQUESTED);
              }}
              onPressSubmitBill={onSubmitBill}
              scanAttemptCount={scanAttemptCount}
              submitBillDetails={submitBillDetails}
            />
          </View>
        </View>
      </View>
    </DarkThemeWrapper>
  );
};

export default ScanBillScreen;
