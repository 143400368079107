/* eslint-disable no-extra-boolean-cast */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ImageViewer from 'react-native-image-zoom-viewer';
import {
  ActivityIndicator,
  useWindowDimensions,
  Platform,
  Pressable,
  Image,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import _ from 'lodash';
import QRCode from 'react-native-qrcode-svg';
import { ActionSheetRef } from 'react-native-actions-sheet';
import {
  IconButton,
  Layout,
  View,
  TopNavigation,
  Text,
  Divider,
  Button,
  Icon,
  Modal,
} from '../../components/new';
import {
  DateFormatsEnum,
  getFormatedDate,
  imageTransformation,
  navigateBack,
} from '../../utilities/helper';
import FooterBar from './FooterBar';
import photoMoments from '../../screens/Strings';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import getPhotoMomentsUserPhotosMappingApi from '../../relay/getPhotoMomentsUserMappingApi';
import { groupPhotosOnDate, isValueNullOrEmpty } from '../../utilities/Utility';
import deleteImageApi from '../../relay/deleteImageForPhotoMomentsApi';
import favoriteImageApi from '../../relay/toggleImageFavForPhotoMomentsApi';
import useUserStore from '../../stores/userStore';
import { space } from '../../themes/new/theme';
import VideoPlayer from '../../components/new/custom/VideoPlayer';
import useVideoMomentsStore from '../../stores/VideoMomentsStore';
import BottomSheetV2 from '../../components/new/composites/BottomSheet/BottomSheetV2';
import CustomLottieView from '../../components/new/custom/CustomLottieView';
import LightThemeWrapper from '../new/wrapperComp/LightThemeWrapper';

let galleryList = [];

const PhotoViewer = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const id = useUserStore((state) => state.id);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const bottomSheetViewerRef = useRef<ActionSheetRef>(null);
  const { height, width } = useWindowDimensions();
  const insets = useSafeAreaInsets();
  const resData = route?.params?.data;
  const photoGallery = route?.params?.photoGallery ?? [];
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteList, setFavoriteList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [title, setTitle] = useState(resData?.assetId);
  const [loaded, setLoaded] = useState(false);
  const [hideProperty, setHideProperty] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [isQRCode, setIsQRCode] = useState(false);
  const { setPhotoMomentsForWidget } = useVideoMomentsStore((state) => state);
  const [downloadedModalVisible, setDownloadedModalVisible] = useState(false);
  const { lastPhotoDownloadPopUpDate, setLastPhotoDownloadPopUpDate } =
    useVideoMomentsStore((state) => state);

  useEffect(() => {
    getUserMapping(false);
    return () => {
      galleryList = [];
    };
  }, []);

  const getUserMapping = (deleted) => {
    getPhotoMomentsUserPhotosMappingApi()
      .then((response) => {
        const { userId, likedImages, deletedImages } =
          response?.getPhotoMomentsUserMapping;
        if (!isValueNullOrEmpty(userId)) {
          checkFavoriteList(likedImages);
          checkDeleted({ deleted, deletedImages });
        }
      })
      .catch((error) => console.log(error));
  };

  const checkFavoriteList = async (likedImages) => {
    const favorites = await likedImages;
    if (favorites) {
      const savedFavorites = favorites;
      const checkIsFav = savedFavorites.find((item) => item === title);
      setIsFavorite(!!checkIsFav);
      setFavoriteList(favorites);
    }
    setIsFavorite(!isFavorite);
  };

  const checkDeleted = async ({ deleted, deletedImages }) => {
    const deletedPictures = await deletedImages;
    modifyPictures({
      deletedPictures: !!deletedPictures ? deletedPictures : [],
      deleted,
    });
  };

  const modifyPictures = ({ deletedPictures, deleted }) => {
    galleryList = [];
    photoGallery.forEach((item) => {
      const eachItem = { ...item };
      if (_.isArray(deletedPictures) && deletedPictures?.length) {
        const exist = deletedPictures.find((i) => i === eachItem.assetId);
        if (!!exist) {
          return;
        }
        galleryList.push({
          url: imageTransformation(
            eachItem?.assetType === 'VIDEO'
              ? eachItem?.thumbnail
              : `https://ik.imagekit.io/32ndhub/photoMoment/${eachItem?.assetId}`,
            width,
          ),
          ...eachItem,
        });
      } else {
        galleryList.push({
          url: imageTransformation(
            eachItem?.assetType === 'VIDEO'
              ? eachItem?.thumbnail
              : `https://ik.imagekit.io/32ndhub/photoMoment/${eachItem?.assetId}`,
            width,
          ),
          ...eachItem,
        });
      }
    });
    if (deleted) {
      const { groupPhotoMomentsOnDate, photoMomentsWidgetHeader }: any =
        groupPhotosOnDate(galleryList);
      const totalPhotoMoments = galleryList?.length;
      setPhotoMomentsForWidget(
        groupPhotoMomentsOnDate,
        photoMomentsWidgetHeader,
        totalPhotoMoments,
      );
    }

    if (galleryList?.length && photoGallery?.length) {
      const selIndex = galleryList.findIndex((item) => item?.assetId === title);
      if (selIndex > -1) {
        setCurrentIndex(selIndex);
      }
    }
    if (deleted) {
      const galleryLength = galleryList?.length;
      if (galleryLength === 0) {
        navigateBack(navigation, userRole);
      } else if (currentIndex >= galleryLength - 1) {
        setCurrentIndex(currentIndex - 1);
        setTitle(galleryList?.[currentIndex - 1]?.assetId);
      } else if (currentIndex < galleryLength) {
        setCurrentIndex(currentIndex);
        setTitle(galleryList?.[currentIndex]?.assetId);
      }
    }
    setLoaded(true);
  };

  const makeFavorite = () => {
    const val = favoriteList.find((item) => title === item);
    if (!val) {
      firebaseEventLogger('photoViewer__favoritePicture_Tap', {
        buttonName: 'favoritePicture',
        screenName: 'photoViewer',
        userType: 'user',
        interactionType: 'tap',
        imageId: title,
        isVideo: galleryList?.[currentIndex]?.assetType === 'VIDEO',
      });
    } else {
      firebaseEventLogger('photoViewer__unfavoritePicture_Tap', {
        buttonName: 'unfavoritePicture',
        screenName: 'photoViewer',
        userType: 'user',
        interactionType: 'tap',
        imageId: title,
        isVideo: galleryList?.[currentIndex]?.assetType === 'VIDEO',
      });
    }
    const data = {
      isFavourite: !val,
      imageId: title,
    };
    favoriteImageApi(data)
      .then((res) => favSuccess(res))
      .catch((err) => {
        onError();
      });
  };

  const favSuccess = (response) => {
    if (response?.toggleImageFavForPhotoMoments?.success) {
      getUserMapping(false);
      global.changesNeeded = true;
    } else {
      onError();
    }
  };

  const deletePicture = () => {
    firebaseEventLogger('photoViewer__deletePicture_Tap', {
      buttonName: 'deletePicture',
      screenName: 'photoViewer',
      userType: 'user',
      interactionType: 'tap',
      imageId: title,
      isVideo: galleryList?.[currentIndex]?.assetType === 'VIDEO',
    });
    const data = {
      imageId: [title],
    };
    deleteImageApi(data)
      .then((res) => deleteSuccess(res))
      .catch((err) => {
        onError();
      });
  };

  const deleteSuccess = (response) => {
    if (response?.deleteImageForUserPhotoMoments?.success) {
      dispatchSnackbar({
        msg: `Media File${photoMoments.photoDeleted}`,
        status: SnackbarStatus.success,
        version: SnackbarVersion.v1,
      });
      handleOnDismiss();
      getUserMapping(true);
      setLoaded(false);
      global.changesNeeded = true;
    } else {
      setLoaded(false);
      onError();
    }
  };

  const onError = () => {
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v1,
    });
  };

  const deleteItem = () => {
    setIsQRCode(false);
    bottomSheetViewerRef.current?.show();
  };

  const checkFavorite = () => {
    const checkIsFav = favoriteList.find((item) => item === title);
    return !!checkIsFav;
  };

  const generateQRCode = () => {
    firebaseEventLogger('photoViewer__generateQRCode_Tap', {
      buttonName: 'generateQRCode',
      screenName: 'photoViewer',
      userType: 'user',
      interactionType: 'tap',
      imageId: title,
    });
    setIsQRCode(true);
    bottomSheetViewerRef.current?.show();
  };

  const handlePlayVideo = () => {
    firebaseEventLogger('photoViewer__playVideo_Tap', {
      buttonName: 'playVideo',
      screenName: 'photoViewer',
      userType: 'user',
      interactionType: 'tap',
      isPlay: !playVideo,
    });
    setPlayVideo(!playVideo);
  };

  const renderMainView = () => {
    return (
      <View
        position="absolute"
        height={height}
        width={width}
        top={Platform.OS === 'ios' ? 0 : insets.top}
      >
        <ImageViewer
          imageUrls={galleryList}
          renderIndicator={() => null}
          saveToLocalByLongPress={false}
          index={currentIndex}
          enablePreload
          flipThreshold={500}
          maxOverflow={10000}
          onClick={() => setHideProperty(!hideProperty)}
          loadingRender={() => <ActivityIndicator size="large" />}
          failImageSource={{
            url: 'https://ik.imagekit.io/32ndhub/assets/nouser.png',
          }}
          onSwipeDown={() => navigateBack(navigation, userRole)}
          enableSwipeDown
          useNativeDriver
          onChange={(v) => {
            setTimeout(() => {
              firebaseEventLogger('photoViewer__onChangePhoto_Misc', {
                buttonName: 'onChangePhoto',
                screenName: 'photoViewer',
                userType: 'user',
                interactionType: 'tap',
                imageId: galleryList?.[v]?.assetId,
                isVideo: galleryList?.[v]?.assetType === 'VIDEO',
              });
              setCurrentIndex(v);
              setTitle(galleryList?.[v]?.assetId);
              // if (galleryList?.[v]?.assetType === 'VIDEO') {
              //   setTimeout(() => {
              //     setCurrentIndex(v);
              //     setTitle(galleryList?.[v]?.assetId);
              //   }, 200);
              //   handlePlayVideo();
              // }
            }, 100);
          }}
        />
        {galleryList?.[currentIndex]?.assetType === 'VIDEO' ? (
          <View
            position="absolute"
            alignSelf="center"
            top={height / 2 - space['3xl']}
            justifyContent="center"
            alignItems="center"
            zIndex={99}
          >
            <Pressable style={{ flex: 1 }} onPress={handlePlayVideo}>
              {playVideo ? null : (
                <Icon name="play-outline-300" size="9xl" color="primary.10" />
              )}
            </Pressable>
          </View>
        ) : null}
        {playVideo ? (
          <View top={Platform.OS === 'ios' ? 0 : insets.top} zIndex={97}>
            <VideoPlayer
              id="Video_Moments_Viewer"
              url={galleryList?.[currentIndex]?.assetLink}
              isVideoMoments
              showPlayPause={false}
              setPlay={setPlayVideo}
              showControls
              height={height}
              width={width}
            />
          </View>
        ) : null}
      </View>
    );
  };

  const handleOnDismiss = useCallback(() => {
    bottomSheetViewerRef.current?.hide();
    setIsQRCode(false);
  }, []);

  const renderTitle = () => {
    const val = getFormatedDate(
      galleryList?.[currentIndex]?.clickedOn,
      DateFormatsEnum.DateMonthYear,
    );
    return val;
  };

  const renderDelete32ndMoment = () => (
    <View justifyContent="center" pt="2xl" px="2xl">
      <Text size="xl" color="primary.200">
        {photoMoments.deleteItem}
      </Text>
      <View mt="xl">
        <Divider level={1} />
      </View>
      <Text size="s" color="primary.200" mt="3xl">
        {photoMoments.deleteStatement}
      </Text>
      <View px="2xl" my="4xl">
        <Divider level={1} />
      </View>
      <View flexDirection="row" alignItems="center">
        <Button
          style={{
            width: (width - 3 * 16) / 2,
            marginRight: 16,
          }}
          state="active"
          status="error"
          appearance="filled"
          size="lg"
          onPress={() => {
            deletePicture();
          }}
        >
          Confirm
        </Button>
        <Button
          style={{ width: (width - 3 * 16) / 2 }}
          state="active"
          status="primary"
          appearance="filled"
          size="lg"
          onPress={() => {
            handleOnDismiss();
          }}
        >
          Cancel
        </Button>
      </View>
    </View>
  );

  const renderGenerateQRCode = () => (
    <View pt="2xl" mx="2xl">
      <View flexDirection="row" justifyContent="space-between">
        <View width={width * 0.5 - 16} alignItems="center">
          <Image
            source={require('../../../assets/images/32ndMomentBooth.webp')}
            style={{ height: width * 0.3, width: width * 0.3 }}
            resizeMode="contain"
          />
          <Text fontSize="xs" color="primary.200" mt="lg">
            Step 1
          </Text>
          <Text
            lineHeight="sm"
            letterSpacing="sm"
            fontSize="sm"
            color="primary.300"
            mt="xs"
            textAlign="center"
          >
            Show the QR code at the 32nd Moments booth
          </Text>
        </View>

        <View width={width * 0.5 - 16} alignItems="center">
          <Image
            source={require('../../../assets/images/32ndMomentPhoto.webp')}
            style={{ height: width * 0.3, width: width * 0.3 }}
            resizeMode="contain"
          />
          <Text fontSize="xs" color="primary.200" mt="lg">
            Step 2
          </Text>
          <Text
            lineHeight="sm"
            letterSpacing="sm"
            fontSize="sm"
            color="primary.300"
            mt="xs"
            textAlign="center"
          >
            Get a print of your favourite photos instantly
          </Text>
        </View>
      </View>
      <View borderColor="grey.50" mt="9xl" width="100%" borderWidth={1} />

      <View width="100%" justifyContent="center" alignItems="center" mt="2xl">
        <QRCode
          value={JSON.stringify({ assetId: title, userId: id })}
          size={width * 0.3}
        />
      </View>
    </View>
  );

  const onDownloadSuccess = () => {
    setTimeout(() => {
      const currentDate = new Date().toDateString();
      if (
        (!lastPhotoDownloadPopUpDate ||
          lastPhotoDownloadPopUpDate !== currentDate) &&
        galleryList?.[currentIndex]?.assetType !== 'VIDEO'
      ) {
        setDownloadedModalVisible(true);
        setLastPhotoDownloadPopUpDate(currentDate);
      }
    }, 1000);
  };

  const onPressGetPrint = () => {
    setDownloadedModalVisible(false);
    generateQRCode();
  };

  const renderGetPrintModal = () => {
    return (
      <Modal
        visible={downloadedModalVisible}
        handleClose={() => setDownloadedModalVisible(false)}
        showBottomCloseButton
        transparent
        bg="blur"
      >
        <View backgroundColor="background.secondary.base" borderRadius={10}>
          <View alignItems="center" px="2xl" py="4xl">
            <Text
              lineHeight="2xl"
              textAlign="center"
              fontSize="2xl"
              color="primary.500"
              fontFamily="bodyMedium"
            >{`Isn't that a great photo?`}</Text>
            <Text
              lineHeight="2xl"
              textAlign="center"
              fontSize="2xl"
              color="primary.500"
              fontFamily="bodyMedium"
            >
              We can also print it for you!
            </Text>

            <View mt="4xl">
              <CustomLottieView
                autoPlay
                source={require('../../../assets/lottie/print.json')}
                style={{ width: width * 0.4, height: width * 0.4 }}
                containerWidth={width * 0.4}
              />
            </View>

            <Text
              fontSize="md"
              textAlign="center"
              color="primary.300"
              mt="xl"
              lineHeight="md"
            >
              Visit the 32nd Moments booth opposite Starbucks to get a HD print
              of your photos
            </Text>
            <Button
              status="primary"
              appearance="filled"
              state="active"
              size="lg"
              mt="4xl"
              onPress={onPressGetPrint}
              style={{ width: '100%' }}
            >
              Get The Print Now
            </Button>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <LightThemeWrapper>
      <Layout level={2}>
        <View flex={1} backgroundColor="primary.500">
          {hideProperty || playVideo ? null : (
            <View style={{ zIndex: 1 }} backgroundColor="primary.500">
              <TopNavigation
                title={renderTitle()}
                appearance="ghost"
                level="2"
                textColor="grey.10"
                textSize="md"
                IconLeft={
                  <IconButton
                    name="back-outline-300"
                    size="sm"
                    appearance="ghost"
                    iconColor="grey.10"
                    onPress={() => {
                      navigateBack(navigation, userRole);
                    }}
                  />
                }
              />
            </View>
          )}

          {loaded ? (
            <>
              {renderMainView()}
              {hideProperty || playVideo ? null : (
                <View position="absolute" width={width} bottom={insets.bottom}>
                  <FooterBar
                    data={galleryList?.[currentIndex]}
                    onDelete={deleteItem}
                    onFavorite={makeFavorite}
                    favorite={checkFavorite()}
                    downloadSuccess={onDownloadSuccess}
                    generateQR={generateQRCode}
                    fromViewer
                    isVideo={galleryList?.[currentIndex]?.assetType === 'VIDEO'}
                    instagramShare
                  />
                </View>
              )}
            </>
          ) : (
            <ActivityIndicator
              size="large"
              color="#000"
              style={{ marginTop: 300 }}
            />
          )}
          <BottomSheetV2
            ref={bottomSheetViewerRef}
            onClose={handleOnDismiss}
            insets={false}
          >
            {isQRCode ? renderGenerateQRCode() : renderDelete32ndMoment()}
          </BottomSheetV2>
        </View>

        {renderGetPrintModal()}
      </Layout>
    </LightThemeWrapper>
  );
};
export default PhotoViewer;
