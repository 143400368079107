/**
 * @generated SignedSource<<6facff7ef5c892013118c0c1c5d18f38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type updateTaskRecordInput = {
  assignedTo?: any | null;
  cancelledReason?: string | null;
  history?: ReadonlyArray<UpdateByIdtaskHistoryInput | null> | null;
  ownerId?: any | null;
  parkingId?: any | null;
  taskEndLocation?: UpdateByIdtaskTaskEndLocationInput | null;
  vehicleId?: any | null;
};
export type UpdateByIdtaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type UpdateByIdtaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
  parkingSpaces?: UpdateByIdtaskTaskEndLocationParkingSpacesInput | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesInput = {
  floor?: UpdateByIdtaskTaskEndLocationParkingSpacesFloorInput | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesFloorInput = {
  _id?: any | null;
  bay?: UpdateByIdtaskTaskEndLocationParkingSpacesFloorBayInput | null;
  floorId?: any | null;
};
export type UpdateByIdtaskTaskEndLocationParkingSpacesFloorBayInput = {
  _id?: any | null;
  bayId?: any | null;
};
export type taskApiMutation$variables = {
  id: any;
  record: updateTaskRecordInput;
};
export type taskApiMutation$data = {
  readonly updateTask: {
    readonly record: {
      readonly _id: any;
      readonly assignedTo: any | null;
      readonly history: ReadonlyArray<{
        readonly action: EnumtaskHistoryAction;
        readonly event_at: any | null;
      } | null>;
      readonly parkingId: any | null;
      readonly taskEndLocation: {
        readonly locationId: any;
        readonly name: string | null;
        readonly parkingSpaces: {
          readonly floor: {
            readonly bay: {
              readonly bayId: any;
              readonly bayName: string | null;
            } | null;
            readonly floorId: any;
            readonly floorName: string | null;
          } | null;
        } | null;
      } | null;
      readonly taskStartLocation: {
        readonly locationId: any;
        readonly name: string | null;
        readonly parkingSpaces: {
          readonly floor: {
            readonly bay: {
              readonly bayId: any;
              readonly bayName: string | null;
            } | null;
            readonly floorId: any;
            readonly floorName: string | null;
          } | null;
        } | null;
      } | null;
      readonly taskType: EnumtaskTaskType;
      readonly user: {
        readonly contact: {
          readonly phone: {
            readonly number: string;
            readonly prefix: string;
          };
        };
        readonly name: {
          readonly first: string;
          readonly last: string | null;
        };
      } | null;
      readonly vehicle: {
        readonly brand: {
          readonly brandLogo: string | null;
          readonly name: string | null;
        } | null;
        readonly color: string | null;
        readonly registration: {
          readonly plate: string;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type taskApiMutation = {
  response: taskApiMutation$data;
  variables: taskApiMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdtaskPayload",
    "kind": "LinkedField",
    "name": "updateTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleBrand",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandLogo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskHistory",
            "kind": "LinkedField",
            "name": "history",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "event_at",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskStartLocation",
            "kind": "LinkedField",
            "name": "taskStartLocation",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskStartLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                        "kind": "LinkedField",
                        "name": "bay",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskEndLocation",
            "kind": "LinkedField",
            "name": "taskEndLocation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskEndLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                        "kind": "LinkedField",
                        "name": "bay",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignedTo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserName",
                "kind": "LinkedField",
                "name": "name",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContact",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserContactPhone",
                    "kind": "LinkedField",
                    "name": "phone",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prefix",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taskApiMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taskApiMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "5c43f5952563494040d6eb6b55ddfedd",
    "id": null,
    "metadata": {},
    "name": "taskApiMutation",
    "operationKind": "mutation",
    "text": "mutation taskApiMutation(\n  $id: MongoID!\n  $record: updateTaskRecordInput!\n) {\n  updateTask(record: $record, _id: $id) {\n    record {\n      _id\n      vehicle {\n        registration {\n          plate\n        }\n        brand {\n          name\n          brandLogo\n        }\n        color\n      }\n      history {\n        event_at\n        action\n      }\n      taskStartLocation {\n        name\n        locationId\n        parkingSpaces {\n          floor {\n            floorName\n            floorId\n            bay {\n              bayId\n              bayName\n            }\n          }\n        }\n      }\n      taskEndLocation {\n        locationId\n        name\n        parkingSpaces {\n          floor {\n            floorName\n            floorId\n            bay {\n              bayId\n              bayName\n            }\n          }\n        }\n      }\n      assignedTo\n      taskType\n      user {\n        name {\n          first\n          last\n        }\n        contact {\n          phone {\n            prefix\n            number\n          }\n        }\n      }\n      parkingId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86fb0bb64cb44ff50e7362d52f083888";

export default node;
