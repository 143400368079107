/**
 * @generated SignedSource<<e18e4d58caac9f29b4fc6668140459b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumpopUpStoreDescriptionTagsIconColor = "error" | "info" | "success" | "%future added value";
export type findPopUpStoresFilterInput = {
  _id?: any | null;
  campus: FilterFindManypopUpStoreCampusInput;
  description?: FilterFindManypopUpStoreDescriptionInput | null;
  displayName?: string | null;
  ownerId?: any | null;
};
export type FilterFindManypopUpStoreDescriptionInput = {
  tags?: ReadonlyArray<FilterFindManypopUpStoreDescriptionTagsInput | null> | null;
};
export type FilterFindManypopUpStoreDescriptionTagsInput = {
  _id?: any | null;
  icon?: FilterFindManypopUpStoreDescriptionTagsIconInput | null;
  name?: string | null;
};
export type FilterFindManypopUpStoreDescriptionTagsIconInput = {
  _id?: any | null;
  color?: EnumpopUpStoreDescriptionTagsIconColor | null;
  iconName?: string | null;
};
export type FilterFindManypopUpStoreCampusInput = {
  _id?: any | null;
};
export type printMomentsQuery$variables = {
  filter?: findPopUpStoresFilterInput | null;
};
export type printMomentsQuery$data = {
  readonly findPopUpStores: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
      };
    }>;
  } | null;
};
export type printMomentsQuery = {
  response: printMomentsQuery$data;
  variables: printMomentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "popUpStoreConnection",
    "kind": "LinkedField",
    "name": "findPopUpStores",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "popUpStoreEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "popUpStore",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "printMomentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "printMomentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4fd393bdbfe1b9221a9478bb690d5881",
    "id": null,
    "metadata": {},
    "name": "printMomentsQuery",
    "operationKind": "query",
    "text": "query printMomentsQuery(\n  $filter: findPopUpStoresFilterInput\n) {\n  findPopUpStores(filter: $filter) {\n    edges {\n      node {\n        _id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c543aaab8cdfc8f2879936b408c234c";

export default node;
