/**
 * @generated SignedSource<<fe5555300531a49dfae742b8add47b4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailRecipientEnum = "CONTACT" | "PRODUCT" | "%future added value";
export type EmailSubjectEnum = "RESTAURANT_PARTNER_REQUEST_CHANGES" | "%future added value";
export type SendMailInput = {
  body: EmailBodyInput;
  subjectType: EmailSubjectEnum;
  to: EmailRecipientEnum;
};
export type EmailBodyInput = {
  message: string;
};
export type requestChangeMailMutation$variables = {
  record: SendMailInput;
};
export type requestChangeMailMutation$data = {
  readonly sendMail: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly status: number;
  } | null;
};
export type requestChangeMailMutation = {
  response: requestChangeMailMutation$data;
  variables: requestChangeMailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "SendMailOutput",
    "kind": "LinkedField",
    "name": "sendMail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SendMailError",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "requestChangeMailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "requestChangeMailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "426630e93cec1f2fae76778ff3fac311",
    "id": null,
    "metadata": {},
    "name": "requestChangeMailMutation",
    "operationKind": "mutation",
    "text": "mutation requestChangeMailMutation(\n  $record: SendMailInput!\n) {\n  sendMail(record: $record) {\n    status\n    error {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ff51d4c719ad52b5a82dd29efbe99bc";

export default node;
