/* eslint-disable import/prefer-default-export */
import { UserRole } from '../../../../stores/userStore';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../utilities/firbaseAnalytics';

const USER_TYPE = UserRole.USER;

const ticketScreen = {
  backClicked: (eventId: string) => {
    firebaseEventLogger('ticketScreen__goBack_Tap', {
      buttonName: 'goBack',
      screenName: 'ticketScreen',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
    });
  },
  manageBookingClicked: (eventId: string) => {
    firebaseEventLogger('ticketScreen__manageBooking_Tap', {
      buttonName: 'manageBooking',
      screenName: 'ticketScreen',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
    });
  },
  cancelBookingClicked: (eventId: string) => {
    firebaseEventLogger('ticketScreen__cancelBooking_Tap', {
      buttonName: 'cancelBooking',
      screenName: 'ticketScreen',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
    });
  },
};

const cancelBookingScreen = {
  landed: (eventId: string, ticketId: string) => {
    firebaseEventLogger('cancelBooking__land_Landing', {
      buttonName: 'land',
      screenName: 'cancelBooking',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
      eventId,
      ticketId,
    });
  },
  cancelBooking: (eventId: string, ticketId: string) => {
    firebaseEventLogger('cancelBooking__cancelBooking_Tap', {
      buttonName: 'cancelBooking',
      screenName: 'cancelBooking',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
      ticketId,
    });
  },
  backClicked: (eventId: string, ticketId: string) => {
    firebaseEventLogger('cancelBooking__goBack_Tap', {
      buttonName: 'goBack',
      screenName: 'cancelBooking',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
      ticketId,
    });
  },
  freeCancellationBannerClicked: (eventId: string, ticketId: string) => {
    firebaseEventLogger('cancelBooking__freeCancellation_Tap', {
      buttonName: 'freeCancellation',
      screenName: 'cancelBooking',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
      ticketId,
    });
  },
};

const confirmCancellationScreen = {
  landed: (eventId: string, ticketId: string) => {
    firebaseEventLogger('confirmCancel__land_Landing', {
      buttonName: 'land',
      screenName: 'confirmCancel',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
      eventId,
      ticketId,
    });
  },
  backClicked: (invoiceId: string) => {
    firebaseEventLogger('confirmCancel__goBack_Tap', {
      buttonName: 'goBack',
      screenName: 'confirmCancel',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      invoiceId,
    });
  },
  cancellationPerformed: (
    eventId: string,
    ticketId: string,
    reason: string,
  ) => {
    firebaseEventLogger('confirmCancel__proceedCancel_Tap', {
      buttonName: 'proceedCancel',
      screenName: 'confirmCancel',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
      ticketId,
      reason,
    });
  },
  cancellationSuccessful: (
    eventId: string,
    ticketId: string,
    reason: string,
  ) => {
    firebaseEventLogger('confirmCancel__cancelSuccess_Data', {
      buttonName: 'cancelSuccess',
      screenName: 'confirmCancel',
      userType: USER_TYPE,
      interactionType: InteractionType.DATA,
      eventId,
      ticketId,
      reason,
    });
  },
  cancellationFailed: (eventId: string, ticketId: string, reason: string) => {
    firebaseEventLogger('confirmCancel__cancelFailure_Data', {
      buttonName: 'cancelFailure',
      screenName: 'confirmCancel',
      userType: USER_TYPE,
      interactionType: InteractionType.DATA,
      eventId,
      ticketId,
      reason,
    });
  },
  reasonSelected: (eventId: string, ticketId: string, reason: string) => {
    firebaseEventLogger('confirmCancel__reasonSelected_Tap', {
      buttonName: 'reasonSelected',
      screenName: 'confirmCancel',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
      ticketId,
      reason,
    });
  },
  reasonApplied: (eventId: string, ticketId: string, reason: string) => {
    firebaseEventLogger('confirmCancel__reasonApplied_Tap', {
      buttonName: 'reasonApplied',
      screenName: 'confirmCancel',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      eventId,
      ticketId,
      reason,
    });
  },
};

export const eventAnalytics = {
  ticketScreen,
  cancelBookingScreen,
  confirmCancellationScreen,
};
