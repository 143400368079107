import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import PhotoMomentsStaffTabBar from './photoMomentsStaffTabBar';
import UserAccountScreen from '../screens/user/userAccountScreen';
import PhotoMomentsPartnerHome from '../screens/photoMomentsPartner/photoMomentsPartnerHome';
import PhotoMoments from '../screens/PhotoMoments';
import PhotoViewer from '../screens/photomoments/PhotoViewer';
import FaqScreen from '../screens/new/faq/FaqScreen';
import PhotoMomentsScanFaceWeb from '../screens/photoMomentsPartner/photoMomentsScanFaceWeb';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import About from '../screens/new/About';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import OTPInputScreen from '../screens/auth/OTPInputScreen';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const PhotoMomentsPartnerTabNavigator = () => {
  return (
    <Tab.Navigator
      // @ts-ignore
      keyboardHidesTabBar="true"
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <PhotoMomentsStaffTabBar {...props} />}
    >
      <Tab.Screen name="home" component={PhotoMomentsPartnerHome} />
      <Tab.Screen name="staffAccount" component={UserAccountScreen} />
    </Tab.Navigator>
  );
};

const PhotoMomentsPartnerNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="user" component={PhotoMomentsPartnerTabNavigator} />
      <Stack.Screen name="scanFace" component={PhotoMomentsScanFaceWeb} />
      <Stack.Screen name="PhotoMoments" component={PhotoMoments} />
      <Stack.Screen name="PhotoViewer" component={PhotoViewer} />
      <Stack.Screen name="faq" component={FaqScreen} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen
        name="editProfile"
        options={{ gestureEnabled: false }}
        component={EditProfileScreen}
      />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="phoneVerify" component={OTPInputScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
    </Stack.Navigator>
  );
};

export default PhotoMomentsPartnerNavigator;
