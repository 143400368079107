import { graphql, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

const query = graphql`
  query findTypeFormResponsesApiQuery(    
    $typeFormfilter: findTypeFormResponseFilterInput
    $typeFormSort: SortFindManytypeformResponseInput
    $typeFormLimit: Int
) {
    findTypeFormResponses(filter: $typeFormfilter, sort: $typeFormSort, limit: $typeFormLimit)  {
        eventId
        formId
        hidden
        landedAt
        submittedAt
    }
  }
`;

const findTypeFormResponsesApi = async (variables) => {
    const request = fetchQuery(environment, query, variables);
    return request.toPromise();
};
export default findTypeFormResponsesApi;