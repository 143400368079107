/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useRef,
} from 'react';
import { Animated, Image, RefreshControl } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { IconButton, Text, View } from '../../../../components/new';
import TabSwitcher from '../TabSwitcher';
import Layout from '../../../../components/new/primitive/Layout/Layout';
import Loading from '../../../../components/loading.component';
import DisconnectedDropover from '../../../../components/disconnectedpopover.component';
import LocationDropDown from '../LocationDropDown';
import useCampusLocation from '../../../../stores/campusLocation';
import {
  doingEntry,
  findAndBlurTask,
  findAndRemoveTask,
  findAndReplaceTask,
  isAlreadyInList,
  managerType,
  sortByAccepted,
} from '../../helpers/helpers';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import RenderItem from './RenderItem';
import BottomScoreBoard from './BottomScoreBoard';
import {
  parkingScreenQuery,
  parkingScreenQueryFindTaskFragment,
} from '../../API/parkingScreenQuery';
import useCommonStore from '../../../../stores/commonStore';
import useTaskStore from '../../../../stores/taskStore';
import {
  findTaskHistoryLastAction,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../../utilities/helper';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import RefreshBubble from '../../../../components/new/primitive/Bubble/RefreshBubble';
import supervisorTaskUpdatedSubscription from '../../API/supervisorTaskUpdatedSubscription';
import supervisorTaskCreatedSubscription from '../../API/supervisorTaskCreatedSubscription';
import useSound from '../../../../hooks/useSound';
import { getTheme } from '../../../../themes/new/theme';
import useUserStore, { UserRole } from '../../../../stores/userStore';
import { giveRankAndSort } from './helpers/helpers';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../utilities/firbaseAnalytics';
import { network } from '../../../../utilities/Constant';

const { pullToRefreshFetchPolicy } = network;

const emptyTaskImg = require('../../../../../assets/images/task-empty.webp');

const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);

const parkingScreenInfo = (data = null) => ({
  [managerType.supervisor]: {
    sub: {
      createSub: {
        history: [taskHistoryActionTypes.created],
      },
      updateSub: {
        history: [
          taskHistoryActionTypes.assigned,
          taskHistoryActionTypes.accepted,
          taskHistoryActionTypes.onboarded,
          taskHistoryActionTypes.started,
          taskHistoryActionTypes.arrived,
          taskHistoryActionTypes.cancelled,
          taskHistoryActionTypes.incomplete,
        ],
      },
    },
    tabsData: [
      {
        name: 'New Tasks',
        sort: '_ID_DESC',
        filter: {
          history: [{ action: taskHistoryActionTypes.created }],
          taskStartLocation: {
            locationId: data?.locationId,
          },
        },
      },
      {
        name: 'Ongoing Tasks',
        filter: {
          history: [
            { action: taskHistoryActionTypes.onboarded },
            { action: taskHistoryActionTypes.assigned },
            { action: taskHistoryActionTypes.accepted },
            { action: taskHistoryActionTypes.started },
          ],
          taskStartLocation: {
            locationId: data?.locationId,
          },
        },
      },
    ],
  },
  [managerType.controller]: {
    sub: {
      createSub: {
        history: [''],
      },
      updateSub: {
        history: [
          taskHistoryActionTypes.arrived,
          taskHistoryActionTypes.completed,
          taskHistoryActionTypes.cancelled,
          taskHistoryActionTypes.incomplete,
        ],
      },
    },
    tabsData: [
      {
        name: '',
        sort: 'CREATED_AT_DESC',
        filter: {
          history: [
            {
              action: taskHistoryActionTypes.created,
            },
            {
              action: taskHistoryActionTypes.accepted,
            },
            {
              action: taskHistoryActionTypes.onboarded,
            },
            {
              action: taskHistoryActionTypes.started,
            },
            {
              action: taskHistoryActionTypes.arrived,
            },
          ],
          taskEndLocation: {
            locationId: data?.locationId,
          },
        },
      },
    ],
  },
});

const getManagerType = (isSupervisor) => {
  if (isSupervisor) {
    return managerType.supervisor;
  }
  return managerType.controller;
};

const RenderTabSwitcher = ({ isSupervisor, handleTabSwitch, selected }) => {
  return (
    <TabSwitcher
      tabOneText={`${
        parkingScreenInfo(null)[getManagerType(isSupervisor)].tabsData[0].name
      }`}
      tabTwoText={`${
        parkingScreenInfo(null)[getManagerType(isSupervisor)].tabsData[1]?.name
      }`}
      handleTabSwitch={handleTabSwitch}
      currentTab={selected}
      mx="2xl"
    />
  );
};

const ParkingScreen = (props) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const {
    queryOptions,
    variables,
    isSupervisor,
    selectedIndex,
    refresh,
    navigation,
  } = props;
  const {
    // ------ for other parking controller ------
    parkingTasks,
    setParkingTasks,
    newParkingTasks, // for subscription bubble
    setNewParkingTasks, // for subscription
    // ------ for New tasks tab for 32nd Supervisor ------
    parkingNotOnboardedTasks,
    setParkingNotOnboardedTasks,
    newParkingNotOnboardedTasks, // for subcription bubble
    setNewParkingNotOnboardedTasks, // for subscription
    // ------ for Ongoing tasks tab for 32nd Supervisor
    parkingOnboardedTasks,
    setParkingOnboardedTasks,
    newParkingOnboardedTasks, // for subscription bubble
    setNewParkingOnboardedTasks, // for subscription
  } = useTaskStore((state) => state);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const findTasksRef = useLazyLoadQuery(parkingScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });

  const [{ findTasks }, refetchTasks] = useRefetchableFragment(
    parkingScreenQueryFindTaskFragment,
    findTasksRef,
  );
  const [refreshing, setRefreshing] = useState(false);

  const setData = () => {
    if (isSupervisor) {
      if (selectedIndex === 0) {
        // only show tasks which have created action in history action
        setParkingNotOnboardedTasks({ data: findTasks });
      } else {
        // only show tasks which have onboarded, assigned, accepted, started action in history action
        setParkingOnboardedTasks({ data: giveRankAndSort(findTasks) });
      }
    } else {
      const newList = sortByAccepted(findTasks);
      setParkingTasks({ data: newList });
    }
  };

  useEffect(() => {
    setData();
  }, [selectedIndex, isSupervisor, findTasks, refreshing]);

  const noTasks = () => (
    <View justifyContent="center" alignItems="center" mt="40%">
      <Image
        source={emptyTaskImg}
        style={{ width: 80, height: 80 }}
        resizeMode="contain"
      />
      <Text size="xl" color="grey.200" weight="medium" mt="2xl">
        No tasks available
      </Text>
    </View>
  );

  const onPullToRefresh = useCallback(() => {
    setRefreshing(true);
    refetchTasks(variables, {
      fetchPolicy: pullToRefreshFetchPolicy,
      onComplete: () => setRefreshing(false),
    });
  }, [variables]);

  const renderItem = ({ item }) => (
    <RenderItem
      item={item}
      isSupervisor={isSupervisor}
      selectedIndex={selectedIndex}
      dispatchSnackbar={dispatchSnackbar}
    />
  );

  let refreshBubbleLength = 0;

  if (isSupervisor && selectedIndex === 0) {
    refreshBubbleLength = newParkingNotOnboardedTasks.length;
  }
  if (isSupervisor && selectedIndex === 1) {
    refreshBubbleLength = newParkingOnboardedTasks.length;
  }
  if (!isSupervisor) {
    refreshBubbleLength = newParkingTasks.length;
  }

  return (
    <>
      <View alignItems="center" zIndex={1}>
        <RefreshBubble
          onPress={() => {
            refresh();
            setNewParkingTasks({ data: [] });
            setNewParkingOnboardedTasks({ data: [] });
            setNewParkingNotOnboardedTasks({ data: [] });
          }}
          length={refreshBubbleLength}
        />
      </View>
      <View flex={1}>
        <AnimatedFlashList
          style={{
            flex: 1,
            marginTop: getTheme().space['2xl'],
            paddingBottom: getTheme().space['4xl'],
          }}
          data={
            isSupervisor
              ? selectedIndex === 0
                ? parkingNotOnboardedTasks
                : parkingOnboardedTasks
              : parkingTasks
          }
          keyExtractor={(item) => item?._id}
          estimatedItemSize={200}
          renderItem={renderItem}
          showsVerticalScrollIndicator
          ListEmptyComponent={noTasks}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onPullToRefresh}
            />
          }
        />
      </View>
    </>
  );
};

const ParkingScreenWrapper = ({ navigation }) => {
  const [selected, setSelected] = useState(0);
  const { id: userId, role } = useUserStore((state) => state);

  const campusID = useCommonStore((state) => state.campusId);
  const { bottom } = useSafeAreaInsets();

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });

  const [netStatus, setNetStatus] = useState(true);
  const {
    // ------ for other parking controller ------
    parkingTasks,
    setParkingTasks,
    newParkingTasks, // for subscription bubble
    setNewParkingTasks, // for subscription
    // ------ for New tasks tab for 32nd Supervisor ------
    parkingNotOnboardedTasks,
    setParkingNotOnboardedTasks,
    newParkingNotOnboardedTasks, // for subcription bubble
    setNewParkingNotOnboardedTasks, // for subscription
    // ------ for Ongoing tasks tab for 32nd Supervisor
    parkingOnboardedTasks,
    setParkingOnboardedTasks,
    newParkingOnboardedTasks, // for subscription bubble
    setNewParkingOnboardedTasks, // for subscription
    // ------ for setting card opened ------
    setParkingTaskOpened,
  } = useTaskStore((state) => state);

  const { campusLocations, currentLocation } = useCampusLocation(
    (state) => state,
  );
  // it can be supervisor or controller
  // supervisor - doing vehicle entry at the porch of 32nd
  // controller - doing vehicle entry in parkings
  const isSupervisor = doingEntry(campusLocations);
  const { tabsData } = parkingScreenInfo({ locationId: currentLocation?._id })[
    getManagerType(isSupervisor)
  ];
  const tabData = tabsData[selected] ? tabsData[selected] : tabsData[0];
  const taskFilter = {
    campus: { _id: campusID },
    taskType: taskTypes.park,
    ...tabData.filter,
  };
  const { sort } = tabData;

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => {
        return {
          fetchKey: (prev?.fetchKey ?? 0) + 1,
          fetchPolicy: 'store-and-network',
        };
      });
    },
    [
      /* ... */
    ],
  );

  useEffect(() => {
    if (campusLocations && campusLocations?.length) {
      refresh();
    }
  }, [campusLocations]);

  useFocusEffect(
    useCallback(() => {
      refresh();
      setParkingTaskOpened('');

      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  const { playSound: playTaskArrivedSound } = useSound({
    soundOrigin: require('../../../../../assets/notification.mp3'),
  });

  const handleTaskCreationSub = (res) => {
    const taskCreated = res?.taskCreated;
    const lastAction = findTaskHistoryLastAction(taskCreated);
    if (
      taskCreated &&
      (taskCreated.taskType === taskTypes.park ||
        taskCreated.taskType === taskTypes.repark)
    ) {
      // FOR SUPERVISOR=======
      // ========================

      // --TAB 0--
      if (isSupervisor && selected === 0) {
        if (!isAlreadyInList(taskCreated, parkingNotOnboardedTasks)) {
          playTaskArrivedSound();
          setNewParkingNotOnboardedTasks({
            data: [...newParkingNotOnboardedTasks, taskCreated],
          });
        }
      }
      // FOR CONTROLLER=======
      // ===========================
      // ============
    }
  };

  const handleTaskUpdateSubscription = (res) => {
    // ============
    const taskUpdated = res?.taskUpdated;
    const lastAction = findTaskHistoryLastAction(taskUpdated);
    if (
      taskUpdated &&
      (taskUpdated.taskType === taskTypes.park ||
        taskUpdated.taskType === taskTypes.repark)
    ) {
      // FOR SUPERVISOR=======
      // ========================

      // --TAB 0--
      if (isSupervisor && selected === 0) {
        // if (
        //   lastAction === taskHistoryActionTypes.assigned ||
        //   lastAction === taskHistoryActionTypes.accepted
        // ) {
        //   // update the card if in the list
        //   const newList = findAndReplaceTask(
        //     taskUpdated,
        //     parkingNotOnboardedTasks,
        //   );
        //   setParkingNotOnboardedTasks({ data: newList });
        // }

        if (
          lastAction === taskHistoryActionTypes.onboarded ||
          lastAction === taskHistoryActionTypes.started ||
          lastAction === taskHistoryActionTypes.assigned ||
          lastAction === taskHistoryActionTypes.accepted
        ) {
          // blur card if in rendedred list, if not remove the item from new available list if its there
          if (isAlreadyInList(taskUpdated, parkingNotOnboardedTasks)) {
            const newList = findAndBlurTask(
              taskUpdated,
              parkingNotOnboardedTasks,
            );
            setParkingNotOnboardedTasks({ data: newList });
          } else if (
            isAlreadyInList(taskUpdated, newParkingNotOnboardedTasks)
          ) {
            const newBubbleList = findAndRemoveTask(
              taskUpdated,
              newParkingNotOnboardedTasks,
            );
            setNewParkingNotOnboardedTasks({ data: newBubbleList });
          }
        }
      }
    }
    // --TAB 1--
    if (isSupervisor && selected === 1) {
      if (
        lastAction === taskHistoryActionTypes.onboarded ||
        lastAction === taskHistoryActionTypes.accepted
      ) {
        //  insert item to onboarded new item available bubble if not available in rendered list
        if (!isAlreadyInList(taskUpdated, parkingOnboardedTasks)) {
          setNewParkingOnboardedTasks({
            data: [...newParkingOnboardedTasks, taskUpdated],
          });
        }
      }
      // change status of the card on the basis of action
      if (
        lastAction === taskHistoryActionTypes.assigned ||
        lastAction === taskHistoryActionTypes.accepted ||
        lastAction === taskHistoryActionTypes.started ||
        lastAction === taskHistoryActionTypes.onboarded
      ) {
        const newList = findAndReplaceTask(taskUpdated, parkingOnboardedTasks);
        setParkingOnboardedTasks({ data: newList });
      }
      if (
        // lastAction === taskHistoryActionTypes.started ||
        lastAction === taskHistoryActionTypes.arrived ||
        lastAction === taskHistoryActionTypes.completed ||
        lastAction === taskHistoryActionTypes.cancelled ||
        lastAction === taskHistoryActionTypes.incomplete
      ) {
        // blur card if in rendedred list, if not remove the item from new available list if its there
        if (isAlreadyInList(taskUpdated, parkingOnboardedTasks)) {
          const newList = findAndBlurTask(taskUpdated, parkingOnboardedTasks);
          setParkingOnboardedTasks({ data: newList });
        } else if (isAlreadyInList(taskUpdated, newParkingOnboardedTasks)) {
          const newBubbleList = findAndRemoveTask(
            taskUpdated,
            newParkingOnboardedTasks,
          );
          setNewParkingOnboardedTasks({ data: newBubbleList });
        }
      }
    }
    // FOR SUPERVISOR:end=======

    // FOR CONTROLLER=======
    if (!isSupervisor) {
      if (lastAction === taskHistoryActionTypes.arrived) {
        // insert item to onboarded new item available bubble if not available in rendered list
        if (!isAlreadyInList(taskUpdated, parkingTasks)) {
          playTaskArrivedSound();
          setNewParkingTasks({
            data: [...newParkingTasks, taskUpdated],
          });
        } else {
          const newList = findAndReplaceTask(taskUpdated, parkingTasks);
          setParkingTasks({ data: newList });
        }
      }
      if (
        lastAction === taskHistoryActionTypes.completed ||
        lastAction === taskHistoryActionTypes.cancelled ||
        lastAction === taskHistoryActionTypes.incomplete
      ) {
        // blur card if in rendedred list, if not remove the item from new available list if its there
        if (isAlreadyInList(taskUpdated, parkingTasks)) {
          const newList = findAndBlurTask(taskUpdated, parkingTasks);
          setParkingTasks({ data: newList });
        } else if (isAlreadyInList(taskUpdated, newParkingTasks)) {
          const newBubbleList = findAndRemoveTask(taskUpdated, newParkingTasks);
          setNewParkingTasks({ data: newBubbleList });
        }
      }
      // setNewParkingOnboardedTasks({
      //   data: [...newParkingOnboardedTasks, taskUpdated],
      // });
    }
    // ===========================
  };
  const handleTaskCreationSubRef = useRef(handleTaskCreationSub);
  const handleTaskUpdationSubRef = useRef(handleTaskUpdateSubscription);

  useEffect(() => {
    handleTaskCreationSubRef.current = handleTaskCreationSub;
    handleTaskUpdationSubRef.current = handleTaskUpdateSubscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selected,
    parkingTasks,
    newParkingTasks,
    // ----------------
    parkingNotOnboardedTasks,
    newParkingNotOnboardedTasks,
    // ----------------
    parkingOnboardedTasks,
    newParkingOnboardedTasks,
    // ----------------
  ]);

  useFocusEffect(
    useCallback(() => {
      // TODO : apply subscription
      let cancelSubscriptionCreateData = {
        dispose: () => {},
      };
      let cancelSubscriptionUpdateData = {
        dispose: () => {},
      };
      const cancelCreateTaskSubscription = (disposable) => {
        cancelSubscriptionCreateData = disposable;
      };
      const cancelUpdateTaskSubscription = (disposable) => {
        cancelSubscriptionUpdateData = disposable;
      };
      const taskCreatedReq = {
        campusId: campusID,
        userId,
        taskType: taskTypes.park,
        history:
          parkingScreenInfo()[getManagerType(isSupervisor)].sub.createSub
            .history,
        // event_at: new Date().toISOString(),
        parkingLocationId: isSupervisor ? null : currentLocation._id,
      };
      const taskUpdatedDataReq = {
        campusId: campusID,
        userId,
        taskType: [taskTypes.park, taskTypes.repark],
        history:
          parkingScreenInfo()[getManagerType(isSupervisor)].sub.updateSub
            .history,
        // event_at: new Date().toISOString(),
        parkingLocationId: isSupervisor ? null : currentLocation._id,
      };
      supervisorTaskCreatedSubscription(
        taskCreatedReq,
        (data) => handleTaskCreationSubRef.current(data),
        cancelCreateTaskSubscription,
      );
      supervisorTaskUpdatedSubscription(
        taskUpdatedDataReq,
        (data) => handleTaskUpdationSubRef.current(data),
        cancelUpdateTaskSubscription,
      );

      return () => {
        // Useful for cleanup functions
        cancelSubscriptionCreateData.dispose();
        cancelSubscriptionUpdateData.dispose();
      };
    }, []),
  );

  const handleTabSwitch = (val) => {
    setSelected(val);
    if (val === 0) {
      setNewParkingNotOnboardedTasks({ data: [] });
    } else {
      setNewParkingOnboardedTasks({ data: [] });
    }
    refresh();
  };

  return (
    <Layout level={2} edges={['top']}>
      <View
        flexDirection="row"
        mb="4xl"
        px="2xl"
        mt="2xl"
        alignItems="center"
        justifyContent="space-between"
      >
        <View py="xs">
          <Text size="2xl" color="primary.300">
            Parking Tasks
          </Text>
        </View>
        <View>{role !== 'controller' ? <LocationDropDown /> : null}</View>
      </View>
      {isSupervisor ? (
        <RenderTabSwitcher
          handleTabSwitch={handleTabSwitch}
          selected={selected}
          isSupervisor={isSupervisor}
        />
      ) : (
        <></>
      )}
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <ParkingScreen
            variables={{ taskFilter, sort }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            isSupervisor={isSupervisor}
            selectedIndex={selected}
            refresh={refresh}
            navigation={navigation}
          />
          <View
            position="absolute"
            right="2xl"
            bottom={bottom + getTheme().space['5xl+6xl']}
          >
            <IconButton
              size="3xl"
              iconColor="primary.10"
              // text="SCAN"
              iconSize="xl"
              name={isSupervisor ? 'addcar-400' : 'barcode-reader-300'}
              onPress={() => {
                if (isSupervisor) {
                  firebaseEventLogger('vehicleEntry__enterVehicle_Tap', {
                    buttonName: 'enterVehicle',
                    screenName: 'vehicleEntry',
                    userType: `${role}`,
                    interactionType: InteractionType.TAP,
                    userId: `${userId}`,
                  });
                  navigation.navigate('anprDetectionScreen');
                } else {
                  firebaseEventLogger('valetSupervisorPark__scan_Tap', {
                    buttonName: 'scan',
                    screenName: 'valetSupervisorPark',
                    userType: `${role}`,
                    interactionType: InteractionType.TAP,
                    userId: `${userId}`,
                  });
                  navigation.navigate('controllerScan');
                }
              }}
            />
          </View>
          <BottomScoreBoard key={selected} isSupervisor={isSupervisor} />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default ParkingScreenWrapper;
