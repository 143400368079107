/**
 * @generated SignedSource<<47ed1594d0f78c6bbf61a9f2eb88ce0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumRewardRewardType = "Event" | "FreeBubbleTea" | "FreeFridgeMagnet" | "FreeKeyChain" | "FreeParking" | "FreePostCard" | "FreeSticker" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RewardList_findReward$data = ReadonlyArray<{
  readonly _id: any;
  readonly created_at: any | null;
  readonly expireAt: any;
  readonly isExpired: boolean | null;
  readonly isUsed: boolean | null;
  readonly ownerId: any;
  readonly parkingId: any | null;
  readonly rewardOrigin: string | null;
  readonly rewardType: EnumRewardRewardType;
  readonly rewardUniqueId: string | null;
  readonly updated_at: any | null;
  readonly " $fragmentType": "RewardList_findReward";
}>;
export type RewardList_findReward$key = ReadonlyArray<{
  readonly " $data"?: RewardList_findReward$data;
  readonly " $fragmentSpreads": FragmentRefs<"RewardList_findReward">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RewardList_findReward",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parkingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rewardType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUsed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExpired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expireAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rewardUniqueId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rewardOrigin",
      "storageKey": null
    }
  ],
  "type": "Reward",
  "abstractKey": null
};

(node as any).hash = "f30cefabdce333315fd4325b283a3be6";

export default node;
