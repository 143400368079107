/**
 * @generated SignedSource<<0a57e06ee47405d4f317a99cef78488e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumticketTicketType = "EVENT_TICKET" | "PRODUCT_TICKET" | "REWARD_TICKET" | "%future added value";
export type EventLogTypes = "SIGNUP_REWARD_CHECK" | "TASK_DELAYED_CLAIM" | "%future added value";
export type checkClaimWelcomeRewardMutation$variables = {
  eventType: EventLogTypes;
};
export type checkClaimWelcomeRewardMutation$data = {
  readonly logEvent: {
    readonly data: {
      readonly rewardTicket: {
        readonly _id: any;
        readonly rewardId: any | null;
        readonly thumbnailImg: string;
        readonly ticketType: EnumticketTicketType;
        readonly title: string;
        readonly uId: number | null;
      } | null;
    } | null;
    readonly error: string | null;
    readonly success: boolean;
  } | null;
};
export type checkClaimWelcomeRewardMutation = {
  response: checkClaimWelcomeRewardMutation$data;
  variables: checkClaimWelcomeRewardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eventType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "eventType",
        "variableName": "eventType"
      }
    ],
    "concreteType": "eventLoggedOTC",
    "kind": "LinkedField",
    "name": "logEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventLoggedDataOTC",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ticket",
            "kind": "LinkedField",
            "name": "rewardTicket",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ticketType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailImg",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rewardId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkClaimWelcomeRewardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkClaimWelcomeRewardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd4b4ad8c8c4798ae2b766d65d9811ff",
    "id": null,
    "metadata": {},
    "name": "checkClaimWelcomeRewardMutation",
    "operationKind": "mutation",
    "text": "mutation checkClaimWelcomeRewardMutation(\n  $eventType: EventLogTypes!\n) {\n  logEvent(eventType: $eventType) {\n    success\n    error\n    data {\n      rewardTicket {\n        _id\n        title\n        ticketType\n        thumbnailImg\n        rewardId\n        uId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "56a6a59c9d7b0c70c2ca6284e59c52be";

export default node;
