import { graphql, requestSubscription } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

const valetTaskCreatedSubscription = graphql`
  subscription valetTaskCreatedSubscriptionApiSubscription(
    $campusId: ID!
    $userId: ID
    $event_at: Date
  ) {
    taskCreated(campusId: $campusId, userId: $userId, event_at: $event_at) {
      _id
      vehicle {
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
      }
      taskType
      taskStartLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      campus {
        _id
        name
      }
      history {
        _id
        action
        actor
        event_at
      }
      assignedTo
      valet {
        _id
        name {
          first
          last
        }
        identityCode
        contact {
          phone {
            prefix
            number
          }
        }
      }
      user {
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      ETA {
        _id
        startAt
        delayAt
        originalPredictionTime
        pendingRecall
        available
        busy
      }
      vehicleId
      ownerId
      parkingId
      parkingTag
      createdBy {
        status
      }
    }
  }
`;

export default (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: valetTaskCreatedSubscription,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      console.log('error', error);
      // console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
