import { useLazyLoadQuery } from 'react-relay';
import { discountScreenQuery } from './API/discountApi';
import { discountApiDiscountScreenQuery$data } from './API/__generated__/discountApiDiscountScreenQuery.graphql';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';

/* eslint-disable-next-line import/prefer-default-export */
export const useCreateDiscount = (
  queryOptions: NewErrorBoundaryParentState,
  refresh: () => void,
  discountId: string,
) => {
  const {
    getRestaurantpartnerDiscount,
    findConfig,
  }: discountApiDiscountScreenQuery$data = useLazyLoadQuery(
    discountScreenQuery,
    {
      filter: {
        isActive: true,
        _id: discountId,
      },
    },
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  return {
    getRestaurantpartnerDiscount,
    findConfig,
  };
};
