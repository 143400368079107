import { debounce } from 'lodash';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLazyLoadQuery } from 'react-relay';

import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import { Input, Layout, View } from '../../../components/new';
// import SearchInput from '../../../components/new/composites/SearchInput/SearchInput';
// import { useSearchPageStore } from '../../../stores/searchPage/searchPageStore';
import { navigateBack } from '../../../utilities/helper';
import {
  makeSearchScreenQueryvariable,
  newSearchScreenQuery,
} from './API/newSearchScreenQuery';
// import NoMatchSearchSection from './NoMatchSearchSection';
import RecentSearchProvider from './RecentSearchContextProvider';
import RecentSearchSection from './RecentSearchSection';
import SearchResultSection from './SearchResultSection';
import TrendingListSection from './TrendingListSection';
import useCommonStore from '../../../stores/commonStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useUserStore from '../../../stores/userStore';
import SearchParkingScreenPlaceholder from '../../valetSupervisor/new/searchParkingScreen/SearchParkingScreenPlaceholder';

const ResultSection = ({
  searchInputValue,
  debouncedInputValue,
  navigation,
  fetchKey,
}) => {
  const campusID = useCommonStore((state) => state.campusId);
  const { searchRestaurants2 } = useLazyLoadQuery(
    newSearchScreenQuery,
    makeSearchScreenQueryvariable(debouncedInputValue, campusID),
    {
      fetchKey,
      fetchPolicy: 'network-only',
      networkCacheConfig: { force: false },
    },
  );
  return (
    <>
      {!searchInputValue && (
        <>
          <RecentSearchSection navigation={navigation} />
          <TrendingListSection
            navigation={navigation}
            trendingList={searchRestaurants2}
          />
        </>
      )}
      {!!searchInputValue &&
        searchInputValue.trim() === debouncedInputValue.trim() && (
          <>
            <SearchResultSection
              navigation={navigation}
              searchInputValue={searchInputValue}
              searchResultList={searchRestaurants2}
            />
          </>
        )}
      {/* <>
          <View mb="xs+2xl" />
          <NoMatchSearchSection />
        </> */}
    </>
  );
};

export const ResultSectionWithErrorBoundary = ({
  searchInputValue,
  ...rest
}) => {
  const [netStatus, setNetStatus] = useState(false);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <NewErrorBoundary
      fetchKey={refreshedQueryOptions.fetchKey}
      fallback={
        <NewErrorView errorMsg="Sorry something went wrong" reload={refresh} />
      }
    >
      <Suspense fallback={<SearchParkingScreenPlaceholder />}>
        <ResultSection
          fetchKey={refreshedQueryOptions.fetchKey}
          searchInputValue={searchInputValue}
          {...rest}
        />
      </Suspense>
    </NewErrorBoundary>
  );
};

const NewSearchScreen = ({
  navigation,
  route,
  onLeftIconClick,
  openKeyboardOnMount = false,
  removeSafeArea = false,
}) => {
  // const {
  //   searchPageInput: searchInputValue,
  //   dispatchSearchPageInput: setsearchInputValue,
  // } = useSearchPageStore((state) => state);
  const openKeyboardOnMountParam = route.params?.openKeyboardOnMount;
  const [searchInputValue, setsearchInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] =
    useState(searchInputValue);
  const [inFocus, setInFocus] = useState(false);

  const debounced = useCallback(
    debounce((text) => {
      setDebouncedInputValue(text.trim());
      // setsearchInputValue(text);
    }, 300),
    [],
  );

  const userRole = useUserStore((state) => state.role);

  // common function to update search and debounce search
  const updateSearch = (text) => {
    setsearchInputValue(text);
    // apply debounce
    debounced(text);
  };

  const handleChangeText = (text) => {
    updateSearch(text);
  };

  const handleRightIconClick = () => {
    updateSearch('');
  };

  const handleLeftIconClick = () => {
    if (onLeftIconClick) {
      onLeftIconClick();
    } else {
      if (navigation.getState().type === 'stack') {
        navigateBack(navigation, userRole);
      }
      inputRef.current?.blur();
      updateSearch('');
    }
  };

  const handleOnFocus = () => {
    if (!inFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
        setInFocus(true);
      }, 200);
    }
  };

  const handleOnBlur = () => {
    setTimeout(() => {
      setInFocus(false);
    }, 150);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (openKeyboardOnMount || openKeyboardOnMountParam) {
      inputRef.current.focus();
    }

    // ==========&&
    // handle hardware back button, it should behave like app back button specific to this screen
    // const backAction = () => {
    //   handleLeftIconClick();
    //   return true;
    // };

    // const backHandler = BackHandler.addEventListener(
    //   'hardwareBackPress',
    //   backAction,
    // );

    // return () => {
    //   backHandler.remove();
    // };
    // ==========&&

    // inputRef.current.focus();
  }, []);

  useEffect(() => {
    setDebouncedInputValue(searchInputValue.trim());

    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      if (inputRef) {
        // when ever user comes back to this screen reset the debounced value to the search value imidiatly so api hits with latest input
        // if it has some value , then it should automatically get focus
        if (
          openKeyboardOnMount ||
          openKeyboardOnMountParam ||
          searchInputValue
        ) {
          inputRef.current?.focus();
        }
        // ==========
      }
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation, searchInputValue]);
  return (
    //  RecentSearch is HOC - to provide context from local storage
    <Layout level={2} edges={removeSafeArea ? [] : ['top']}>
      <RecentSearchProvider>
        <View px="2xl" paddingTop="lg" bg="background.primary.base">
          <View mb="2xl">
            {/* <SearchInput
              inputRef={inputRef}
              placeholder="Search by dish or restaurant..."
              value={searchInputValue}
              onChangeText={handleChangeText}
              onLeftIconClick={handleLeftIconClick}
              onRightIconClick={handleRightIconClick}
            /> */}
            <Input
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              autoFocus={Boolean(
                openKeyboardOnMount ||
                  openKeyboardOnMountParam ||
                  searchInputValue,
              )}
              inputRef={inputRef}
              placeholder="Search by dish or restaurant..."
              value={searchInputValue}
              onChangeText={handleChangeText}
              leftIconName={
                inFocus ||
                searchInputValue ||
                navigation.getState().type === 'stack'
                  ? 'back-outline-300'
                  : ''
              }
              rightIconName={
                searchInputValue ? 'close-outline-300' : 'search-outline-400'
              }
              onLeftIconClick={handleLeftIconClick}
              onRightIconClick={handleRightIconClick}
            />
          </View>
        </View>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <KeyboardAvoidingView
            {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
            style={{ flex: 1 }}
          >
            <ScrollView
              style={{ flex: 1 }}
              keyboardShouldPersistTaps="always"
              // showsVerticalScrollIndicator={false}
            >
              <View px="2xl">
                <ResultSectionWithErrorBoundary
                  navigation={navigation}
                  searchInputValue={searchInputValue}
                  debouncedInputValue={debouncedInputValue}
                />
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
        </TouchableWithoutFeedback>
      </RecentSearchProvider>
    </Layout>
  );
};

export default NewSearchScreen;
