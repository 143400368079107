import React, { memo } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import moment from 'moment';
import { View, Text } from '../../../components/new';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import { FontWeights } from '../../../themes/new/helper';

const areEqual = () => {
  return true;
};

const data = [
  {
    id: 0,
    posterURL: require('../../../../assets/images/payments.webp'),
    title: 'What does this mean?',
    description:
      'If car delivery misses guaranteed time, get 70 points worth ₹70',
  },
  {
    id: 1,
    posterURL: require('../../../../assets/images/cellphone.png'),
    title: 'How to redeem?',
    description:
      'You can utilise the 32nd points in your account to pay for your parking fee & restaurant bill',
  },
  {
    id: 2,
    posterURL: require('../../../../assets/images/calender.png'),
    title: 'What’s the validity?',
    description: '32nd points are valid for 1 year from the date of issue',
  },
];

const shadowProps =
  Platform.OS === 'web' || Platform.OS === 'ios'
    ? { borderColor: 'primary.50', shadow: 'sm' }
    : { borderColor: 'primary.50' };

const MoreDetails = ({
  tag,
  taskCreatedTimeStamp,
  parked_at,
  isParked,
  parkingCharges,
  historyLast,
}) => {
  const { width } = useWindowDimensions();

  return (
    <View px="2xl" mt="5xl">
      <Text size="md" color="primary.300">
        Car Details
      </Text>
      <View flexDirection="row" justifyContent="space-between" pt="lg">
        <View
          borderRadius="lg"
          borderWidth="xs"
          backgroundColor="background.primary.elevation"
          {...shadowProps}
          justifyContent="center"
          alignItems="center"
          py="lg"
          width={(width - 48) / 3}
        >
          <Text size="sm" weight={FontWeights.MEDIUM} color="primary.400">
            {isValueNullOrEmpty(tag) ? '-' : `#${tag}`}
          </Text>
          <Text size="xs" color="primary.200" pt="sm">
            Valet Tag ID
          </Text>
        </View>
        <View
          borderRadius="lg"
          borderWidth="xs"
          backgroundColor="background.primary.elevation"
          {...shadowProps}
          justifyContent="center"
          alignItems="center"
          py="lg"
          width={(width - 48) / 3}
        >
          <Text size="sm" weight={FontWeights.MEDIUM} color="primary.400">
            {!isValueNullOrEmpty(parked_at)
              ? moment(parked_at).format('hh:mm A')
              : !isValueNullOrEmpty(historyLast?.event_at)
              ? moment(historyLast?.event_at).format('hh:mm A')
              : '-'}
          </Text>
          <Text size="xs" color="primary.200" pt="sm">
            Entry Time
          </Text>
        </View>
        <View
          borderRadius="lg"
          borderWidth="xs"
          backgroundColor="background.primary.elevation"
          {...shadowProps}
          justifyContent="center"
          alignItems="center"
          py="lg"
          width={(width - 48) / 3}
        >
          <Text size="sm" weight={FontWeights.MEDIUM} color="primary.400">
            {taskCreatedTimeStamp && !isParked
              ? moment(taskCreatedTimeStamp).format('hh:mm A')
              : '-'}
          </Text>
          <Text size="xs" color="primary.200" pt="sm">
            Recall Time
          </Text>
        </View>
      </View>
      <Text size="md" color="primary.300" pt="4xl">
        Rate Card
      </Text>
      <View
        flexDirection="row"
        justifyContent="space-between"
        pt="lg"
        pb={isParked ? '4xl' : 0}
      >
        <View
          borderRadius="lg"
          borderWidth="xs"
          backgroundColor="background.primary.elevation"
          {...shadowProps}
          justifyContent="center"
          alignItems="center"
          py="lg"
          width={(width - 48) / 2}
        >
          <Text size="sm" weight={FontWeights.MEDIUM} color="primary.400">
            {parkingCharges?.basePrice
              ? `₹${parkingCharges?.basePrice}`
              : '₹100'}
          </Text>
          <Text size="xs" color="primary.200" pt="sm">
            0-{parkingCharges?.baseDuration ?? '3'}hrs
          </Text>
        </View>
        <View
          borderRadius="lg"
          borderWidth="xs"
          backgroundColor="background.primary.elevation"
          {...shadowProps}
          justifyContent="center"
          alignItems="center"
          py="lg"
          width={(width - 48) / 2}
        >
          <Text size="sm" weight={FontWeights.MEDIUM} color="primary.400">
            {parkingCharges?.additionalPrice
              ? `₹${parkingCharges?.additionalPrice}`
              : '₹100'}
          </Text>
          <Text size="xs" color="primary.200" pt="sm">
            Every Additional hour
          </Text>
        </View>
      </View>
    </View>
  );
};

export default memo(MoreDetails, areEqual);
