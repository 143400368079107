import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, Icon, Text, View } from '../../../../../../components/new';

type CTA = { label: string; navLink?: string; navParams?: Record<string, any> };
type SavingsListItemProps = {
  icon: string;
  title: string;
  subTitle: string;
  info: string | CTA;
};

const SavingsListItem = ({
  icon,
  title,
  subTitle,
  info,
}: SavingsListItemProps) => {
  const navigation = useNavigation();
  const CTAinfo = info as CTA;

  return (
    <View px="2xl" py="lg" borderRadius="lg" bg="background.primary.elevation">
      <View flexDirection="row" alignItems="center">
        <Icon name={icon} size="6xl" color="primary.100" />
        <View
          flex={1}
          ml="2xl"
          flexDirection="row"
          justifyContent="space-between"
        >
          <View flex={1}>
            <Text
              numberOfLines={1}
              ellipsizemode="tail"
              mb="sm"
              size="md"
              color="primary.500"
            >
              {title}
            </Text>
            <Text size="xs" color="primary.300">
              {subTitle}
            </Text>
          </View>
          <View justifyContent="center">
            {typeof info === 'string' && (
              <View>
                <Text mb="xs" size="md" color="gold.500">
                  {info}
                </Text>
                <Text textAlign="right" size="xs" color="primary.300">
                  Saved
                </Text>
              </View>
            )}
            {typeof CTAinfo?.navLink === 'string' && (
              <Button
                state="active"
                status="info"
                appearance="ghost"
                size="sm"
                iconRight="keyboard-arrow-right-300"
                onPress={() =>
                  navigation.navigate(
                    CTAinfo?.navLink,
                    CTAinfo?.navParams ?? {},
                  )
                }
              >
                {CTAinfo?.label}
              </Button>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default SavingsListItem;
