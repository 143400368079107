/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Layout, Text, View } from '../../../components/new';
import PrizeImage from '../../../../assets/images/prize.webp';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import CachedImage from '../../../components/new/custom/CachedImage';

const SurveyResultComp = ({ navigation, rewardText }) => {
  const won = true;

  return (
    <>
      <Layout level={2} flex={1}>
        <View px="2xl">
          <Text
            color="primary.500"
            fontFamily="heading"
            textAlign="center"
            fontSize={48}
            lineHeight={60}
          >
            {won ? 'Congratulations' : 'Better Luck Next Time'}
          </Text>
          <View alignItems="center" justifyContent="center">
            <View>
              <CustomLottieView
                style={{ opacity: 0.3, height: 390, width: 390 }}
                autoPlay
                loop
                source={require('../../../../assets/lottie/light_lottie.json')}
                containerWidth={390}
              />
            </View>
            {!!won && (
              <>
                <View position="absolute">
                  <CachedImage
                    source={PrizeImage}
                    style={{ height: 200, width: 200 }}
                  />
                </View>
              </>
            )}
            {!!won && (
              <>
                <View
                  alignItems="center"
                  position="absolute"
                  bottom="xs"
                  left={0}
                  right={0}
                >
                  {rewardText && !!won && (
                    <>
                      <Text size="xl" color="primary.300">
                        YOU WON
                      </Text>
                      <Text size="3xl" color="primary.500">
                        {rewardText}
                      </Text>
                    </>
                  )}
                </View>
              </>
            )}
          </View>
        </View>
        <View position="absolute" bottom="9xl" left={0} right={0} px="2xl">
          <Button
            size="xl"
            onPress={() => {
              navigation.navigate('RewardsScreen');
            }}
          >
            View Rewards
          </Button>
        </View>
      </Layout>
    </>
  );
};

export default SurveyResultComp;
