/* eslint-disable import/prefer-default-export */
export enum TicketVariant {
  EVENT = 'EVENT',
  REWARD = 'REWARD',
}

export enum RedeemMode {
  QR = 'QR',
  COUPON = 'COUPON',
}
