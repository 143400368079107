import React from 'react';
import { PlaceholderLine } from 'rn-placeholder';
import { useWindowDimensions } from 'react-native';
import { View } from '../../../../../components/new';
import { VehicleHistoryCardPlaceholder } from '../components';

const TrackMyVehicleScreenPlaceholder = () => {
  const { height, width } = useWindowDimensions();
  return (
    <View mt="4xl" style={{ height }}>
      <View px="2xl">
        <PlaceholderLine
          height={200}
          style={{ width: width - 32, borderRadius: 16 }}
        />
        <View top={-180}>
          <View mb="3xl">
            <VehicleHistoryCardPlaceholder />
          </View>
          <View mb="3xl">
            <VehicleHistoryCardPlaceholder />
          </View>
          <View mb="3xl">
            <VehicleHistoryCardPlaceholder />
          </View>
        </View>
      </View>
    </View>
  );
};

export default TrackMyVehicleScreenPlaceholder;
