/**
 * @generated SignedSource<<825b27aa12fb7a490b7d9ff48e2aece5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type reparkQuery$variables = {
  taskEndLocationId?: string | null;
  taskId: string;
  valetId: string;
};
export type reparkQuery$data = {
  readonly reparkTask: {
    readonly ETA: {
      readonly _id: any | null;
      readonly available: number | null;
      readonly busy: number | null;
      readonly delayAt: any | null;
      readonly originalPredictionTime: number | null;
      readonly pendingRecall: number | null;
      readonly startAt: any | null;
    } | null;
    readonly _id: any;
    readonly assignedTo: any | null;
    readonly campus: {
      readonly _id: any;
      readonly name: string;
    } | null;
    readonly createdBy: {
      readonly status: string | null;
    } | null;
    readonly history: ReadonlyArray<{
      readonly _id: any | null;
      readonly action: EnumtaskHistoryAction;
      readonly actor: any | null;
      readonly event_at: any | null;
    } | null>;
    readonly ownerId: any | null;
    readonly parkingId: any | null;
    readonly parkingTag: string | null;
    readonly taskEndLocation: {
      readonly locationId: any;
      readonly name: string | null;
      readonly parkingSpaces: {
        readonly floor: {
          readonly bay: {
            readonly bayId: any;
            readonly bayName: string | null;
          } | null;
          readonly floorId: any;
          readonly floorName: string | null;
        } | null;
      } | null;
    } | null;
    readonly taskStartLocation: {
      readonly locationId: any;
      readonly name: string | null;
      readonly parkingSpaces: {
        readonly floor: {
          readonly bay: {
            readonly bayId: any;
            readonly bayName: string | null;
          } | null;
          readonly floorId: any;
          readonly floorName: string | null;
        } | null;
      } | null;
    } | null;
    readonly taskType: EnumtaskTaskType;
    readonly user: {
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
    readonly valet: {
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly identityCode: string | null;
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
    readonly vehicle: {
      readonly brand: {
        readonly brandLogo: string | null;
        readonly name: string | null;
      } | null;
      readonly color: string | null;
      readonly registration: {
        readonly plate: string;
      } | null;
    } | null;
    readonly vehicleId: any | null;
  } | null;
};
export type reparkQuery = {
  response: reparkQuery$data;
  variables: reparkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskEndLocationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "valetId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "UserName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UserContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserContactPhone",
      "kind": "LinkedField",
      "name": "phone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "taskEndLocationId",
        "variableName": "taskEndLocationId"
      },
      {
        "kind": "Variable",
        "name": "taskId",
        "variableName": "taskId"
      },
      {
        "kind": "Variable",
        "name": "valetId",
        "variableName": "valetId"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "reparkTask",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "taskVehicle",
        "kind": "LinkedField",
        "name": "vehicle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleBrand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandLogo",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleRegistration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskTaskStartLocation",
        "kind": "LinkedField",
        "name": "taskStartLocation",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskStartLocationParkingSpaces",
            "kind": "LinkedField",
            "name": "parkingSpaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                "kind": "LinkedField",
                "name": "floor",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                    "kind": "LinkedField",
                    "name": "bay",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskTaskEndLocation",
        "kind": "LinkedField",
        "name": "taskEndLocation",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskEndLocationParkingSpaces",
            "kind": "LinkedField",
            "name": "parkingSpaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                "kind": "LinkedField",
                "name": "floor",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                    "kind": "LinkedField",
                    "name": "bay",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "campusLocation",
        "kind": "LinkedField",
        "name": "campus",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskHistory",
        "kind": "LinkedField",
        "name": "history",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "event_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assignedTo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "valet",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identityCode",
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskETA",
        "kind": "LinkedField",
        "name": "ETA",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "delayAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "originalPredictionTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingRecall",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "available",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "busy",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "vehicleId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ownerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingTag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "createdBy",
        "kind": "LinkedField",
        "name": "createdBy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "reparkQuery",
    "selections": (v11/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "reparkQuery",
    "selections": (v11/*: any*/)
  },
  "params": {
    "cacheID": "987e32876ae822a4fbae39e9881396e5",
    "id": null,
    "metadata": {},
    "name": "reparkQuery",
    "operationKind": "mutation",
    "text": "mutation reparkQuery(\n  $taskId: ID!\n  $valetId: ID!\n  $taskEndLocationId: ID\n) {\n  reparkTask(taskId: $taskId, valetId: $valetId, taskEndLocationId: $taskEndLocationId) {\n    _id\n    vehicle {\n      brand {\n        name\n        brandLogo\n      }\n      registration {\n        plate\n      }\n      color\n    }\n    taskType\n    taskStartLocation {\n      locationId\n      name\n      parkingSpaces {\n        floor {\n          floorName\n          floorId\n          bay {\n            bayId\n            bayName\n          }\n        }\n      }\n    }\n    taskEndLocation {\n      locationId\n      name\n      parkingSpaces {\n        floor {\n          floorName\n          floorId\n          bay {\n            bayId\n            bayName\n          }\n        }\n      }\n    }\n    campus {\n      _id\n      name\n    }\n    history {\n      _id\n      action\n      actor\n      event_at\n    }\n    assignedTo\n    valet {\n      name {\n        first\n        last\n      }\n      identityCode\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n    user {\n      name {\n        first\n        last\n      }\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n    ETA {\n      _id\n      startAt\n      delayAt\n      originalPredictionTime\n      pendingRecall\n      available\n      busy\n    }\n    vehicleId\n    ownerId\n    parkingId\n    parkingTag\n    createdBy {\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2cd43841aaf7cbbc885e5945ad20f07d";

export default node;
