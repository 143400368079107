/**
 * @generated SignedSource<<10afd55526fc5ce2e00754c814526833>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FeedbackRatingType = "EMOJI" | "NO_RATING" | "STARS" | "%future added value";
export type FeedbackTagsType = "FOOD_QUANTITY" | "FOOD_SERVICE_DELAYED" | "FOOD_SERVICE_REQUESTED_TIP" | "FOOD_SERVICE_UNPROFESSIONAL" | "FOOD_STALE" | "FOOD_TASTE_ISSUES" | "PAYMENT_SERVICE_MODE_UNAVALIABLE" | "PAYMENT_SERVICE_TRANSACTION_FAILED" | "VALET_IMPOLITE" | "VALET_SERVICE_CAR_DAMAGED" | "VALET_SERVICE_DELAYED" | "VALET_SERVICE_INAPPRORIATE_PRICE" | "VALET_SERVICE_MISSING_ITEM" | "VALET_UNFAIR_TIP" | "VALET_UNHYGENIC" | "VALET_UNRESPONSIVE" | "%future added value";
export type FeedbackTopic = "FOOD" | "FOOD_SERVICE" | "OTHER" | "OVERALL" | "PAYMENT_SERVICE" | "VALET_DRIVER" | "VALET_SERVICE" | "%future added value";
export type FeedbackType = "DINE_OUT" | "EVENT" | "GENERAL" | "VALET" | "%future added value";
export type FeedbackDetails = {
  rating?: number | null;
  ratingType?: FeedbackRatingType | null;
  summary?: string | null;
  tags?: ReadonlyArray<FeedbackTagsType | null> | null;
  topic: FeedbackTopic;
};
export type feedbackApiAddMutation$variables = {
  feedbacks?: ReadonlyArray<FeedbackDetails | null> | null;
  invoiceId?: string | null;
  parkingId?: string | null;
  restaurantId?: string | null;
  type: FeedbackType;
};
export type feedbackApiAddMutation$data = {
  readonly addFeedback: {
    readonly _id: any;
    readonly pointsRewarded: number | null;
  } | null;
};
export type feedbackApiAddMutation = {
  response: feedbackApiAddMutation$data;
  variables: feedbackApiAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "feedbacks"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "invoiceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parkingId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "restaurantId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "feedbacks",
        "variableName": "feedbacks"
      },
      {
        "kind": "Variable",
        "name": "invoiceId",
        "variableName": "invoiceId"
      },
      {
        "kind": "Variable",
        "name": "parkingId",
        "variableName": "parkingId"
      },
      {
        "kind": "Variable",
        "name": "restaurantId",
        "variableName": "restaurantId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "feedback",
    "kind": "LinkedField",
    "name": "addFeedback",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pointsRewarded",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "feedbackApiAddMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "feedbackApiAddMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "392dbede7d85aa20534777580cf33c84",
    "id": null,
    "metadata": {},
    "name": "feedbackApiAddMutation",
    "operationKind": "mutation",
    "text": "mutation feedbackApiAddMutation(\n  $type: FeedbackType!\n  $feedbacks: [FeedbackDetails]\n  $parkingId: ID\n  $invoiceId: ID\n  $restaurantId: ID\n) {\n  addFeedback(type: $type, feedbacks: $feedbacks, parkingId: $parkingId, invoiceId: $invoiceId, restaurantId: $restaurantId) {\n    _id\n    pointsRewarded\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a319b48aafda6143ed63b2e2852f7d2";

export default node;
