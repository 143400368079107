import { mediaTypes } from '../../../utilities/helper';

const nudgeOne = require('../../../../assets/lottie/nudgeOne.json');
const nudgeTwo = require('../../../../assets/lottie/nudgeTwo.json');
const nudgeThree = require('../../../../assets/lottie/nudgeThree.json');
const nudgeFour = require('../../../../assets/lottie/nudgeFour.json');
const walletImage = require('../../../../assets/images/wallet.webp');

export enum bottomNudgeType {
  photoMoments = 'photoMoments',
  myCar = 'myCar',
  restaurantPay = 'restaurantPay',
  parkingPay = 'parkingPay',
}
export type bottomNudgesSliceReturnType = {
  bottomNudgeName: string;
  currentCarousalData: any;
  openBottomNudge: ({
    bottomNudgeName,
  }: {
    bottomNudgeName: bottomNudgeType;
  }) => void;
  closeBottomNudge: () => void;
};

const bottomNudgeDataMaker = () => ({
  [bottomNudgeType.photoMoments]: {
    activeIndex: 0,
    openedFirstTime: false,
    carousalData: [
      {
        title:
          'Join 32nd Club to securely scan your face and get instant access to your photos!',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeFour,
        },
      },
      {
        title: `Did you know?\n 32nd Club members get flat 15% discount on valet parking.`,
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeThree,
        },
      },
      {
        title:
          'Members also get instant discounts and assured cashback on bill payments.',
        subTitle: '',
        media: {
          mediaType: mediaTypes.IMAGE,
          src: walletImage,
        },
      },
      {
        title:
          'It is a one-of-a-kind loyalty club experience that you just cannot miss!',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeTwo,
        },
      },
    ],
  },
  [bottomNudgeType.myCar]: {
    activeIndex: 0,
    openedFirstTime: false,
    carousalData: [
      {
        title:
          'Become a 32nd Club member and get flat 15% discount on valet parking!',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeOne,
        },
      },
      {
        title:
          '32nd members can recall their vehicle from anywhere to get timely delivery.',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeThree,
        },
      },
      {
        title:
          'Did you know? Members get compensation in case of delayed delivery.',
        subTitle: '',
        media: {
          mediaType: mediaTypes.IMAGE,
          src: walletImage,
        },
      },
      {
        title:
          'Get access to many more such exclusive perks and offers on the app!',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeTwo,
        },
      },
    ],
  },
  [bottomNudgeType.restaurantPay]: {
    activeIndex: 0,
    openedFirstTime: false,
    carousalData: [
      {
        title:
          'Become a 32nd Club member and get instant discounts on your bill!',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeOne,
        },
      },
      {
        title:
          'Members also earn cashback points that can be redeemed at 32nd Avenue.',
        subTitle: '',
        media: {
          mediaType: mediaTypes.IMAGE,
          src: walletImage,
        },
      },
      {
        title:
          'Avail flat 15% discount on valet parking as a 32nd Club member!',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeThree,
        },
      },
    ],
  },
  [bottomNudgeType.parkingPay]: {
    openedFirstTime: false,
    activeIndex: 0,
    carousalData: [
      {
        title:
          'Become a 32nd Club member and get instant discounts on your bill!',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeOne,
        },
      },
      {
        title:
          'Members also earn cashback points that can be redeemed at 32nd Avenue.',
        subTitle: '',
        media: {
          mediaType: mediaTypes.IMAGE,
          src: walletImage,
        },
      },
      {
        title:
          'Avail flat 15% discount on valet parking as a 32nd Club member!',
        subTitle: '',
        media: {
          mediaType: mediaTypes.LOTTIE,
          src: nudgeThree,
        },
      },
    ],
  },
});

const carousalInterval = 3000;

const bottomNudgesSlice = (set, get) => {
  const data = bottomNudgeDataMaker();
  return {
    bottomNudgeName: '',
    currentCarousalTimer: null,
    currentCarousalData: null,
    openBottomNudge: ({ bottomNudgeName }) => {
      if (data[bottomNudgeName]?.openedFirstTime) {
        return;
      }
      set(() => {
        const o = JSON.parse(JSON.stringify(data[bottomNudgeName]));
        const localTimer = setInterval(() => {
          set((state) => {
            if (state?.currentCarousalData) {
              const currentIndex = state?.currentCarousalData?.activeIndex;
              let nextIndex = 0;

              if (o?.carousalData?.length - 1 > currentIndex) {
                nextIndex = currentIndex + 1;
              }
              return {
                currentCarousalData: { ...o, activeIndex: nextIndex },
              };
            }
            return {
              currentCarousalData: { ...o },
            };
          });
        }, carousalInterval);
        return {
          bottomNudgeName,
          currentCarousalTimer: localTimer,
          currentCarousalData: { ...o },
        };
      });
    },
    closeBottomNudge: () => {
      set((state) => {
        // if (state?.bottomNudgeName) {
        //   data[state?.bottomNudgeName].openedFirstTime = true;
        // }
        clearInterval(state.currentCarousalTimer);
        return {
          bottomNudgeName: '',
          currentCarousalTimer: null,
        };
      });
    },
  };
};

export default bottomNudgesSlice;
