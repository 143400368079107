import React from 'react';
import { useWindowDimensions, StyleSheet } from 'react-native';
import { View, Text } from '../../components/new';
import { getTheme } from '../../themes/new/theme';
import DarkThemeWrapper from './wrapperComp/DarkThemeWrapper';
import { dynamicHeightMaker } from '../../utilities/helper';
import CachedImage from '../../components/new/custom/CachedImage';
import CachedImageBackground from '../../components/new/custom/CachedImageBackground';

interface WalkInOfferCardProps {
  title: string;
  imageSrc?: string;
  subTitle?: string;
  bottomText?: string;
  widthProp?: number;
  aspectRatio?: number;
  forBlackMember?: boolean;
}

const WalkInOfferCard = ({
  title = '',
  imageSrc = '',
  subTitle = '',
  bottomText = '',
  widthProp = 0,
  aspectRatio = 1,
  forBlackMember = false,
}: WalkInOfferCardProps) => {
  const { width } = useWindowDimensions();

  const CardComponent = () => {
    const borderProps = !forBlackMember
      ? { borderWidth: 'xs', borderColor: 'grey.50' }
      : {};

    return (
      <View
        bg="background.primary.elevation"
        borderRadius="md"
        {...borderProps}
        width={widthProp || width}
        height={dynamicHeightMaker(widthProp || width, aspectRatio)}
      >
        <View px="2xl" my="2xl">
          <CachedImage
            source={imageSrc}
            style={{
              width: getTheme().sizes['2xl'],
              height: getTheme().sizes['2xl'],
              resizeMode: 'contain',
              alignSelf: 'center',
            }}
          />
          <Text
            size="lg"
            weight="medium"
            color="primary.500"
            mt="lg"
            textAlign="center"
          >
            {title}
          </Text>
          <Text size="xs" color="primary.100" mt="lg" textAlign="center">
            {subTitle}
          </Text>
        </View>
        {bottomText && bottomText?.length ? (
          <View
            style={{
              ...StyleSheet.absoluteFillObject,
              justifyContent: 'flex-end',
              borderBottomLeftRadius: getTheme().radii.md,
              borderBottomRightRadius: getTheme().radii.md,
              overflow: 'hidden',
            }}
          >
            {forBlackMember ? (
              <CachedImageBackground
                source={require('../../../assets/images/black_membership/golden-metal-texture.png')}
                style={{
                  width: '100%',
                  justifyContent: 'center',
                }}
                imageStyle={{
                  resizeMode: 'cover',
                }}
              >
                <Text
                  size="xs"
                  px="2xl"
                  py="lg"
                  color="primary.10"
                  textAlign="center"
                >
                  {bottomText}
                </Text>
              </CachedImageBackground>
            ) : (
              <View bg="primary.400">
                <Text
                  size="xs"
                  px="2xl"
                  py="lg"
                  color="primary.10"
                  textAlign="center"
                >
                  {bottomText}
                </Text>
              </View>
            )}
          </View>
        ) : (
          <></>
        )}
      </View>
    );
  };
  return forBlackMember ? (
    <DarkThemeWrapper>
      <CardComponent />
    </DarkThemeWrapper>
  ) : (
    <CardComponent />
  );
};

export default WalkInOfferCard;
