import React, { useEffect } from 'react';
import { Image, Pressable } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';
import {
  Avatar,
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../index';
import { navigateBack } from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import useLoginModalStore from '../../../stores/loginModalStore';
import AppConstants from '../../../utilities/AppConstants';

interface NotLoggedInPlaceholderV2Props {
  header: string;
  buttonAction?: () => void;
  title?: string;
  subTitle?: string;
  showRightICon?: boolean;
}

const NotLoggedInPlaceholderV2 = (props: NotLoggedInPlaceholderV2Props) => {
  const {
    header,
    buttonAction = () => {
      setAuthModalOpen(true);
    },
    title = 'Login to join 32nd Club now!',
    subTitle = 'Unlock member exclusive convenience, offers, rewards and much more at 32nd Avenue',
    showRightICon = false,
  } = props;
  const navigation = useNavigation<any>();
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const { profileImage, firstName, lastName } = useUserStore((state) => state);
  const { IMAGEKIT_PROD_URI } = AppConstants;

  const imageLink = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';

  const handlePress = () => {
    buttonAction();
  };

  useEffect(() => {
    if (header !== 'Settings') {
      setTimeout(() => {
        setAuthModalOpen(true);
      }, 100);
    }
  }, []);

  return (
    <Layout level={2}>
      <TopNavigation
        title={header}
        appearance="ghost"
        level="1"
        textSize="md"
        textColor="primary.400"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="sm"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, 'user')}
          />
        }
        IconRight={
          showRightICon ? (
            <Pressable
              onPress={() => {
                navigation.navigate('account');
              }}
              hitSlop={{
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              }}
            >
              <View mr="lg">
                <Avatar
                  level={1}
                  source={imageLink}
                  resizeMode="cover"
                  name={`${firstName ?? ''} ${lastName ?? ''}`}
                />
              </View>
            </Pressable>
          ) : undefined
        }
      />
      <View px="2xl" mt="8xl" flex={1}>
        <Image
          style={{
            height: 200,
            width: 200,
            marginTop: 24,
            alignSelf: 'center',
          }}
          source={require('../../../../assets/images/non-logged-in-placeholder.webp')}
        />
        <Text
          color="primary.500"
          size="2xl"
          weight="regular"
          mt="4xl"
          alignSelf="center"
          style={{ textAlign: 'center' }}
        >
          {title}
        </Text>
        <Text
          color="primary.300"
          mt="lg"
          alignSelf="center"
          size="md"
          style={{ textAlign: 'center' }}
        >
          {subTitle}
        </Text>
        <Button mt="4xl" size="md" onPress={handlePress}>
          Join 32nd club
        </Button>
      </View>
    </Layout>
  );
};

export default NotLoggedInPlaceholderV2;
