import React from 'react';
import { Card, Divider, Pressable, Text, View } from '../../../components/new';
import PaymentsImage from '../../../../assets/images/payments.webp';
import { paymentOrderTypeEnum, toRupees } from '../../../utilities/helper';
import { getTheme, themeModes } from '../../../themes/new/theme';
import CachedImage from '../../../components/new/custom/CachedImage';

const darkTheme = getTheme(themeModes.dark);

const BillingCard = ({
  orderType,
  grossAmount = '',
  netAmount = '',
  isParking = false,
  valetTip = 0,
  tipEnabled = false,
  tipAmount = 0,
  charityEnabled = false,
  charityAmount = 0,
  cashbackPoints = '',
  cashbackPointsPercentage = '',
  discountAmount = '',
  discountPercentage = 0,
  rewardsAmountUsed = '',
  paid = false,
  appliedCoupon = null,
  onViewBreakUp,
  onEdit,
  blackMembershipAmount = 0,
  addonAmount = 0,
  discountPromo,
}) => {
  const showBreakUpBtn =
    typeof onViewBreakUp === 'function' &&
    (orderType === paymentOrderTypeEnum.PARKING ||
      orderType === paymentOrderTypeEnum.EVENT);
  const showEditBtn =
    typeof onEdit === 'function' &&
    (orderType === paymentOrderTypeEnum.restaurant ||
      orderType === paymentOrderTypeEnum.store);

  return (
    <Card withBorder>
      <View
        flexDirection="row"
        mb="2xl"
        alignItems="center"
        justifyContent="space-between"
      >
        <View flexDirection="row" alignItems="center">
          <Text size="sm" color="primary.400">
            Bill Amount
          </Text>
          {showBreakUpBtn && (
            <Pressable onPress={onViewBreakUp}>
              <Text ml="lg" size="xs" color="info.500">
                View breakup
              </Text>
            </Pressable>
          )}
          {showEditBtn && (
            <Pressable onPress={onEdit}>
              <Text ml="lg" size="xs" color="info.500">
                Edit
              </Text>
            </Pressable>
          )}
        </View>
        <Text size="sm" color="primary.400">
          ₹{grossAmount}
        </Text>
      </View>
      {typeof discountPromo?.text === 'string' && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <View flexDirection="row" alignItems="center">
            <Text size="sm" color="primary.400">
              32nd Plus discount
            </Text>
          </View>
          <Pressable onPress={() => discountPromo?.cb()}>
            <Text size="sm" color="info.500">
              {discountPromo?.text}
            </Text>
          </Pressable>
        </View>
      )}
      {Boolean(blackMembershipAmount) && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            32nd Plus membership
          </Text>
          <View flexDirection="row">
            <Text
              mr="lg"
              size="sm"
              color={darkTheme.colors.primary[100]}
              style={{
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
              }}
            >
              1999
            </Text>
            <Text size="sm" color="primary.400">
              ₹{blackMembershipAmount}
            </Text>
          </View>
        </View>
      )}
      {tipEnabled && Boolean(tipAmount) && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            {orderType === paymentOrderTypeEnum.PARKING ? 'Valet Tip' : 'Tip'}
          </Text>
          <Text size="sm" color="primary.400">
            ₹{tipAmount}
          </Text>
        </View>
      )}
      {charityEnabled && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            Donation Amount
          </Text>
          <Text size="sm" color="primary.400">
            {toRupees(charityAmount)}
          </Text>
        </View>
      )}
      {Number(addonAmount) !== 0 && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            32nd Plus Membership
          </Text>
          <Text size="sm" color="primary.400">
            {toRupees(addonAmount)}
          </Text>
        </View>
      )}
      {Number(discountAmount) !== 0 && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="success.500">
            32nd Plus Savings
          </Text>
          <Text size="sm" color="success.500">
            -₹{discountAmount}
          </Text>
        </View>
      )}
      {!!(Number(rewardsAmountUsed) > 0) && (
        <View flexDirection="row" mb="2xl">
          <View flexDirection="row">
            <Text size="sm" color="success.500">
              Savings via 32nd
            </Text>
            <View ml="sm">
              <CachedImage
                source={PaymentsImage}
                style={{ height: 24, width: 24 }}
              />
            </View>
          </View>
          <View flex={1} alignItems="flex-end">
            <Text size="sm" color="success.500">
              -₹{rewardsAmountUsed}
            </Text>
          </View>
        </View>
      )}
      {appliedCoupon && appliedCoupon?.savedAmount > 0 ? (
        <View flexDirection="row" mb="2xl">
          <Text size="sm" color="success.500">
            {`Coupon (${appliedCoupon?.couponCode})`}
          </Text>
          <View flex={1} alignItems="flex-end">
            <Text size="sm" color="success.500">
              {`-₹${appliedCoupon?.savedAmount}`}
            </Text>
          </View>
        </View>
      ) : null}
      <Divider />
      <View flexDirection="row" mt="lg">
        <View>
          <Text size="sm" color="primary.500" weight="medium">
            You {paid ? 'Paid' : 'Pay'}
          </Text>
        </View>
        <View flex={1} alignItems="flex-end">
          <Text size="sm" color="primary.500" weight="medium">
            ₹{netAmount}
          </Text>
        </View>
      </View>
    </Card>
  );
};

export default BillingCard;
