/**
 * @generated SignedSource<<57b116b8c2bfbdd362120e4de442b189>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type startRideScreenQuery$variables = {
  filter: findTaskFilterInput;
};
export type startRideScreenQuery$data = {
  readonly findTasks: ReadonlyArray<{
    readonly _id: any;
    readonly campus: {
      readonly _id: any;
    } | null;
    readonly history: ReadonlyArray<{
      readonly _id: any | null;
      readonly action: EnumtaskHistoryAction;
      readonly actor: any | null;
      readonly event_at: any | null;
    } | null>;
    readonly taskEndLocation: {
      readonly locationId: any;
      readonly name: string | null;
      readonly parkingSpaces: {
        readonly floor: {
          readonly bay: {
            readonly bayId: any;
            readonly bayName: string | null;
          } | null;
          readonly floorId: any;
          readonly floorName: string | null;
        } | null;
      } | null;
    } | null;
    readonly taskStartLocation: {
      readonly locationId: any;
      readonly name: string | null;
      readonly parkingSpaces: {
        readonly floor: {
          readonly bay: {
            readonly bayId: any;
            readonly bayName: string | null;
          } | null;
          readonly floorId: any;
          readonly floorName: string | null;
        } | null;
      } | null;
    } | null;
    readonly taskType: EnumtaskTaskType;
    readonly user: {
      readonly _id: any;
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
          readonly verified: boolean | null;
        };
      };
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
      readonly parkingVisitCount: number | null;
    } | null;
    readonly valet: {
      readonly _id: any;
      readonly identityCode: string | null;
    } | null;
    readonly vehicle: {
      readonly registration: {
        readonly plate: string;
      } | null;
    } | null;
  }>;
};
export type startRideScreenQuery = {
  response: startRideScreenQuery$data;
  variables: startRideScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "findTasks",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "taskVehicle",
        "kind": "LinkedField",
        "name": "vehicle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleRegistration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskTaskStartLocation",
        "kind": "LinkedField",
        "name": "taskStartLocation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskStartLocationParkingSpaces",
            "kind": "LinkedField",
            "name": "parkingSpaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                "kind": "LinkedField",
                "name": "floor",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                    "kind": "LinkedField",
                    "name": "bay",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskTaskEndLocation",
        "kind": "LinkedField",
        "name": "taskEndLocation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskEndLocationParkingSpaces",
            "kind": "LinkedField",
            "name": "parkingSpaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                "kind": "LinkedField",
                "name": "floor",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                    "kind": "LinkedField",
                    "name": "bay",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskHistory",
        "kind": "LinkedField",
        "name": "history",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "event_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "valet",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identityCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserName",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContactPhone",
                "kind": "LinkedField",
                "name": "phone",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prefix",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verified",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingVisitCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "campusLocation",
        "kind": "LinkedField",
        "name": "campus",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "startRideScreenQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "startRideScreenQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "9c7842baebb8364d96a5238acb42d5db",
    "id": null,
    "metadata": {},
    "name": "startRideScreenQuery",
    "operationKind": "query",
    "text": "query startRideScreenQuery(\n  $filter: findTaskFilterInput!\n) {\n  findTasks(filter: $filter) {\n    _id\n    vehicle {\n      registration {\n        plate\n      }\n    }\n    taskStartLocation {\n      locationId\n      name\n      parkingSpaces {\n        floor {\n          floorName\n          floorId\n          bay {\n            bayId\n            bayName\n          }\n        }\n      }\n    }\n    taskEndLocation {\n      locationId\n      name\n      parkingSpaces {\n        floor {\n          floorName\n          floorId\n          bay {\n            bayId\n            bayName\n          }\n        }\n      }\n    }\n    history {\n      _id\n      action\n      actor\n      event_at\n    }\n    valet {\n      _id\n      identityCode\n    }\n    user {\n      _id\n      name {\n        first\n        last\n      }\n      contact {\n        phone {\n          prefix\n          number\n          verified\n        }\n      }\n      parkingVisitCount\n    }\n    taskType\n    campus {\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d85927c302a1fa222f5f65f3fdc68c3f";

export default node;
