/* eslint-disable import/prefer-default-export */
import { useRef, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  dateUtils,
  navigateBack,
  toISOStringWithTimezone,
} from '../../../../utilities/helper';
import { ITransaction } from '../../types';
import { BSMode } from './RevenueDetailsBottomSheet';
import useUserStore from '../../../../stores/userStore';
import { useTransactionsRevenueReport } from '../../API/fragments/transactionsRevenueReportFragment';
import { revenueOverviewScreenQuery } from './revenueOverviewScreenQuery';
import { revenueOverviewScreenQuery$variables } from './__generated__/revenueOverviewScreenQuery.graphql';
import { useTransactions } from '../../API/fragments/transactionsFragment/useTransactions';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import { pagination } from '../../../../utilities/Constant';

export const useRevenueOverviewScreen = (
  queryOptions: NewErrorBoundaryParentState,
) => {
  const bsRef = useRef();
  const navigation = useNavigation();
  const route = useRoute();
  const { date, restaurantId } = route.params;
  const userRole = useUserStore((state) => state.role);

  const variables = buildQueryVariables(date, restaurantId);
  const fragmentRef = useLazyLoadQuery(
    revenueOverviewScreenQuery,
    variables,
    queryOptions,
  );

  const {
    transactions,
    fetchMoreTransactions: fetchMore,
    hasMoreTransactions,
  } = useTransactions(fragmentRef);

  const [clickedTransaction, setClickedTransaction] =
    useState<ITransaction | null>(null);
  const [bsMode, setBSMode] = useState<BSMode>(BSMode.DISMISSED);
  const { revenueReports } = useTransactionsRevenueReport(fragmentRef);

  const showRevenuePanelBS = () => {
    setBSMode(BSMode.REVENUE_REPORT);
    bsRef.current?.show();
  };

  const showTransactionDetailsBS = (orderId: ITransaction['orderId']) => {
    const txn = transactions.filter((t) => t.orderId === orderId)[0];
    setClickedTransaction(txn);
    setBSMode(BSMode.TRANSACTION);
    bsRef.current?.show();
  };

  const fetchMoreTransactions = () => {
    if (hasMoreTransactions) fetchMore(10);
  };

  const handleBSDismiss = () => {
    setBSMode(BSMode.DISMISSED);
  };

  return {
    bsRef,
    bsMode,
    transactions,
    clickedTransaction,
    transactionsSumary: revenueReports[0],
    revenueReport: revenueReports[0],
    fetchMoreTransactions,
    handleBSDismiss,
    showRevenuePanelBS,
    showTransactionDetailsBS,
    handleBack: () => navigateBack(navigation, userRole),
  };
};

const buildQueryVariables = (
  date: string,
  restaurantId: string,
): revenueOverviewScreenQuery$variables => {
  const startDate = dateUtils.toStartOfDay(new Date(date));
  const endDate = dateUtils.toEndOfDay(new Date(date));

  return {
    revenueReportFilter: {
      date: {
        start: toISOStringWithTimezone(startDate),
        end: toISOStringWithTimezone(endDate),
      },
      groupBy: 'DAY',
      orderId: restaurantId,
    },
    sort: 'CREATED_AT_DESC',
    transactionsFilter: {
      orderId: restaurantId,
      status: 'SUCCESSFUL',
      datePeriod: {
        from: date,
        period: 'DAILY',
      },
    },
    count: pagination.defaultPageSize,
  };
};
