/* eslint-disable import/prefer-default-export */
export const taskType = {
  PARK: 'park',
  REPARK: 'repark',
  RECALL: 'recall',
};

export const taskStatus = {
  POOR: 'POOR',
  AVERAGE: 'AVERAGE',
  GOOD: 'GOOD',
};

export const taskFilter = {
  ALL: 'ALL',
  ON_TIME: 'ON_TIME',
  DELAYED: 'DELAYED',
};

export const filterComponentConfig = [
  {
    id: 0,
    name: taskFilter.DELAYED,
    disabled: false,
    sectionTitle: 'Delayed Tasks',
  },
  // {
  //   id: 1,
  //   name: taskFilter.ON_TIME,
  //   disabled: false,
  //   sectionTitle: 'On-Time Tasks',
  // },
  // {
  //   id: 2,
  //   name: taskFilter.ALL,
  //   disabled: false,
  //   sectionTitle: 'All Tasks',
  // },
];
