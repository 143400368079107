/* eslint-disable */
import React from 'react';
import { Platform } from 'react-native';
import * as Sentry from '@sentry/react-native';

/**
 * This error boundary is created as a workaround to deal with the `ref` related bug in 
 * `Animated.FlatList` where Object.freeze(ref) was throwing error.
 */
const TARGET_ERROR_MSG = "Cannot assign to read only property 'current' of object '#<Object>'"

const webOrDev = __DEV__ || Platform.OS === 'web';
const crashlyticsModule = webOrDev
  ? null
  : require('@react-native-firebase/crashlytics');

export interface NewErrorBoundaryParentState {
  fetchKey: number;
  fetchPolicy: string;
  networkCacheConfig?: {};
}

interface NewErrorBoundaryProps {
  fetchKey: number;
  fallback: any;
  children: any;
}

interface NewErrorBoundaryState {
  hasError: boolean;
}

class NewErrorBoundary extends React.Component<
  NewErrorBoundaryProps,
  NewErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.fetchKey !== this.props.fetchKey) {
      this.setState({ hasError: false });
    }
  }

  static getDerivedStateFromError(error) {
    if (Platform.OS === 'web' && error?.message === TARGET_ERROR_MSG) {
      return { hasError: false, error };
    }
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    if (Platform.OS === 'web' && error?.message === TARGET_ERROR_MSG) {
      return
    }
    console.error('Error caught in error boundary ->\n', error, info);
    if (!__DEV__) {
      Sentry.captureException(error, info)
    }
    if (Platform.OS === 'web' || __DEV__) return;
    crashlyticsModule.firebase.crashlytics().log(error, info);
  }

  render() {
    if (this.state.hasError) {
      if (isJSXElement(this.props.fallback)) return this.props.fallback
      if (isFunctionalComponent(this.props.fallback)) return this.props.fallback(this.state);
      return this.props.fallback
    }
    return this.props.children;
  }
}

// Function to check if a variable is a React functional component
const isFunctionalComponent = (component) => {
  return typeof component === 'function' && (
    component.prototype == null || !component.prototype.isReactComponent
  );
};

// Function to check if a variable is a JSX element
const isJSXElement = (element) => {
  return React.isValidElement(element);
};

export default NewErrorBoundary;
