import { Image } from 'react-native';
import React from 'react';
import { View, Text } from '..';
import { getStyledPremiumSingleFixed } from '../../../utilities/Utility';
import { FontWeights } from '../../../themes/new/helper';

const PointsCard: React.FC<{
  currentBalance: number;
  moneySaved: number;
}> = ({ currentBalance = 0, moneySaved = 0 }) => {
  return (
    <View
      mt="4xl"
      bg="static.black"
      borderRadius="xl"
      borderWidth="xs"
      borderColor="static.gold"
      shadow="md"
    >
      <View px="2xl" py="2xl">
        <View alignItems="center">
          <Text size="sm" color="static.gold">
            32nd Points Balance
          </Text>
          <View flexDirection="row" alignItems="center" mt="sm">
            <Text size="2xl" color="static.gold" weight={FontWeights.MEDIUM}>
              {`₹${currentBalance}`}
            </Text>
            <Image
              source={require('../../../../assets/images/coins.webp')}
              resizeMode="contain"
              style={{ width: 20, height: 20, marginLeft: 1 }}
            />
          </View>
          <Text size="2xs" color="primary.200" mt="sm" mb="lg">
            1 point = ₹ 1
          </Text>

          <View bg="primary.400" height={1} width="100%" />

          <Text color="primary.100" mt="lg" size="xs" textAlign="center">
            {`Earned ${
              moneySaved ? getStyledPremiumSingleFixed(moneySaved, 1) : '₹0'
            }`}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PointsCard;
