/* eslint-disable no-nested-ternary */
import React from 'react';
import { View, Text, Divider } from '../index';

interface BillSummaryProps {
  label: string;
  summary: [];
  summaryResult: {
    leftText: string;
    rightText: string;
  };
}

const BillSummary = ({
  label = '',
  summary = [],
  summaryResult = {
    leftText: '',
    rightText: '',
  },
}: BillSummaryProps) => {
  const { leftText, rightText } = summaryResult ?? {};

  return (
    <>
      {label && label?.length ? (
        <Text size="md" weight="medium" color="primary.200">
          {label}
        </Text>
      ) : (
        <></>
      )}
      <View
        mt={label && label?.length ? '2xl' : 0}
        borderRadius="lg"
        borderWidth="xs"
        borderColor="primary.50"
        bg="background.primary.base"
        p="2xl"
      >
        {summary &&
          summary?.length &&
          summary?.map((item) => {
            const {
              id,
              leftText: summaryItemLeftText,
              leftTextColor,
              rightText: summaryItemRightText,
              rightTextColor,
            } = item ?? {};
            return (
              <View
                flexDirection="row"
                justifyContent="space-between"
                mt={id !== 0 ? '2xl' : 0}
              >
                {summaryItemLeftText && summaryItemLeftText !== '' ? (
                  <Text
                    size="sm"
                    color={
                      leftTextColor && leftTextColor !== ''
                        ? leftTextColor
                        : 'primary.300'
                    }
                  >
                    {summaryItemLeftText}
                  </Text>
                ) : (
                  <></>
                )}
                {summaryItemRightText && summaryItemRightText !== '' ? (
                  <Text
                    size="sm"
                    color={
                      rightTextColor && rightTextColor !== ''
                        ? rightTextColor
                        : 'primary.300'
                    }
                  >
                    {summaryItemRightText}
                  </Text>
                ) : (
                  <></>
                )}
              </View>
            );
          })}
        <View mt="2xl">
          <Divider level={1} />
        </View>
        {leftText && rightText && leftText !== '' && rightText !== '' ? (
          <View flexDirection="row" justifyContent="space-between" mt="lg">
            <Text size="sm" color="primary.500" weight="medium">
              {leftText}
            </Text>
            <Text size="sm" color="primary.500" weight="medium">
              {rightText}
            </Text>
          </View>
        ) : (
          <></>
        )}
      </View>
    </>
  );
};

export default BillSummary;
