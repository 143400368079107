import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

export const parkingLocationsQuery = graphql`
  query parkingLocationQuery($filter: findParkingLocationFilterInput!) {
    findParkingLocation(filter: $filter) {
      _id
      name
      campus {
        _id
      }
      parkingSpaces {
        floor {
          _id
          floorName
          bay {
            _id
            bayName
            parkingSpots
            parkingOccupied
            parkingVacant
          }
        }
      }
    }
  }
`;

const fetchParkingLocationsQuery = (data) => {
  const variables = { filter: data };
  const request = fetchQuery(environment, parkingLocationsQuery, variables);
  return request.toPromise();
};

export default fetchParkingLocationsQuery;
