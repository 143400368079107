import React from 'react';
import { Image } from 'react-native';
import { Icon, Pressable, Text, View } from '../../../../components/new';
import PaymentsImage from '../../../../../assets/images/payments.webp';
import { FontWeights } from '../../../../themes/new/helper';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { getTheme } from '../../../../themes/new/theme';
import { paymentOrderTypeEnum } from '../../../../utilities/helper';

const TotalSaved = ({ totalSaved, cashback, isPointsAvailable, orderType }) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const onPressInfo = () => {
    const message =
      orderType === paymentOrderTypeEnum.event
        ? 'Cashback will be credited after the event'
        : 'Cashback will be credited in 3-5 business days';
    dispatchSnackbar({
      msg: message,
      status: SnackbarStatus.info,
      version: SnackbarVersion.v3,
      removeAfter: 5000,
    });
  };

  return (
    <View
      bg={isPointsAvailable || cashback <= 0 ? 'success.500' : 'primary.50'}
      py="lg"
    >
      <View flexDirection="row" alignItems="center" justifyContent="center">
        {isPointsAvailable || cashback <= 0 ? (
          <>
            {totalSaved > 0 ? (
              <Text size="xs" color="static.white">
                🎉 You saved ₹{parseInt(totalSaved, 10)}
              </Text>
            ) : (
              <></>
            )}
            {totalSaved > 0 && cashback > 0 ? (
              <Text size="xs" color="static.white">
                {' '}
                and earned
              </Text>
            ) : (
              <></>
            )}
            {cashback ? (
              <View flexDirection="row" alignItems="center">
                <Text size="xs" color="static.white">
                  {totalSaved > 0 ? '' : 'You earned'} ₹{cashback}{' '}
                </Text>
                <Image
                  source={PaymentsImage}
                  style={{ height: 18, width: 18 }}
                />
                <Text size="xs" color="static.white">
                  {' '}
                  on this bill!
                </Text>
              </View>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Text size="xs" color="primary.300" weight={FontWeights.MEDIUM}>
              ₹{cashback} cashback will be credited in 3-5 business days
            </Text>
            <Pressable
              onPress={onPressInfo}
              style={{
                marginLeft: getTheme().space.sm,
              }}
            >
              <Icon name="info-outline-300" color="primary.300" size="md" />
            </Pressable>
          </>
        )}
      </View>
    </View>
  );
};

export default TotalSaved;
