import { RefObject, useEffect, useRef, useState } from 'react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { useNodeFromPagination } from '../../../../hooks/useNodeFromPagination';
import {
  eventCancellationInvoiceFragment,
  eventCancellationScreenQuery,
  eventCancellationTicketQueryFragment,
} from './eventCancellationScreenQuery';
import { getEventCancellationInfo } from '../helpers/helpers';
import {
  DateFormatsEnum,
  capitalizeAfterSpace,
  getFormatedDate,
} from '../../../../utilities/helper';
import { normalizeToNumberOrZero } from '../../../../utilities/Utility';

type EventInfo = {
  eventDateFull: string;
  eventName: string;
  eventDate: string;
  eventTime: string;
  noOfTickets: string;
  userName: string;
};

type EventCancellationInfo = {
  isRefundableIfCancelled: boolean;
  refundAmount: number;
  refundLastDate: {
    date: Date;
    formatOne: string;
    formatTwo: string;
  };
};

const useEventCancellationScreen = (
  queryVariables,
  queryOptions,
): {
  bsRef: RefObject<ActionSheetRef>;
  eventInfo: EventInfo & { eventId: string; ticketId: string };
  eventCancellationInfo: EventCancellationInfo;
} => {
  const bsRef = useRef();
  const ticketAndInvoiceRef = useLazyLoadQuery(
    eventCancellationScreenQuery,
    queryVariables,
    queryOptions,
  );
  const ticketFragment = useFragment(
    eventCancellationTicketQueryFragment,
    ticketAndInvoiceRef,
  );
  const invoiceFragment = useFragment(
    eventCancellationInvoiceFragment,
    ticketAndInvoiceRef,
  );
  const tickets = useNodeFromPagination(ticketFragment?.tickets);
  const invoice = useNodeFromPagination(invoiceFragment?.findInvoiceV2)[0];

  const {
    eventName,
    eventTime,
    eventDate,
    eventDateFull,
    userName,
    noOfTickets,
  } = extractEventInfo(tickets) ?? {};
  const [eventCancellationInfo, setEventCancellationInfo] =
    useState<EventCancellationInfo | null>(null);

  useEffect(() => {
    if (typeof eventDate === 'string') {
      const cancellationInfo = getEventCancellationInfo(
        new Date(eventDateFull),
      );
      const netAmount = normalizeToNumberOrZero(
        invoice?.priceDetails?.netAmount,
      );
      const pointsAmount = normalizeToNumberOrZero(
        invoice?.priceDetails?.rewardsUsed,
      );
      const totalPaidAmount = netAmount + pointsAmount; // upi + points;
      const refundAmount = cancellationInfo?.isRefundableIfCancelled
        ? totalPaidAmount
        : 0;
      setEventCancellationInfo({
        ...cancellationInfo,
        refundAmount,
      });
    }
  }, [eventDateFull, invoice]);

  return {
    bsRef,
    eventInfo: {
      ticketId: tickets[0]?._id,
      eventId: invoice?.orderId ?? '',
      eventDateFull,
      eventName,
      eventTime,
      eventDate,
      userName,
      noOfTickets,
    },
    eventCancellationInfo,
  };
};

const extractEventInfo = (tickets): EventInfo | null => {
  if (!tickets?.length) return null;
  const ticket = tickets[0];
  const fullName = `${ticket?.user?.name?.first || ''} ${
    ticket?.user?.name?.last || ''
  }`;
  const { startTime } = ticket?.additionalFields?.event ?? {};
  const eventDate = startTime
    ? (getFormatedDate(
        new Date(startTime),
        DateFormatsEnum.DateMonthYear,
      ) as string)
    : '';
  const eventTime = startTime
    ? (getFormatedDate(new Date(startTime), DateFormatsEnum.TIME) as string)
    : '';

  return {
    eventDateFull: startTime,
    eventDate,
    eventTime,
    eventName: capitalizeAfterSpace(ticket?.title),
    userName: capitalizeAfterSpace(fullName),
    noOfTickets: tickets?.length,
  };
};

export default useEventCancellationScreen;
