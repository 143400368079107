import React from 'react';
import { IconButton } from '../../../../../components/new';

const BackButton = ({ onBack }: { onBack: () => void }) => {
  return (
    // @ts-ignore
    <IconButton
      name="back-outline-300"
      size="3xl"
      shape="rounded"
      iconSize="3xl"
      appearance="filled"
      bg="primary.10"
      iconColor="primary.500"
      onPress={onBack}
    />
  );
};

export default BackButton;
