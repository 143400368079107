import { useRefetchableFragment } from 'react-relay';
import { useCallback } from 'react';
import { transactionsEarningReportFragment } from './transactionsEarningReportFragment';
import { transactionsEarningReportFragment$key } from './__generated__/transactionsEarningReportFragment.graphql';
import { buildTransactionsEarningsReportViewData } from '../../../utils';
import { network } from '../../../../../utilities/Constant';

const { pullToRefreshFetchPolicy } = network;

const useTransactionsEarningReport = (
  fragmentRef: transactionsEarningReportFragment$key,
) => {
  const [data, refetch] = useRefetchableFragment(
    transactionsEarningReportFragment,
    fragmentRef,
  );

  const earningReports = buildTransactionsEarningsReportViewData(
    data.earingReports,
  );

  const refetchInner = useCallback(
    (onComplete, variables = {}) => {
      refetch(variables, {
        fetchPolicy: pullToRefreshFetchPolicy,
        onComplete: () => {
          if (typeof onComplete === 'function') {
            onComplete();
          }
        },
      });
    },
    [refetch],
  );

  return {
    refetchEarningReports: refetchInner,
    earningReports,
  };
};

export default useTransactionsEarningReport;
