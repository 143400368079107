import * as React from 'react';
import { Image } from 'react-native';
import { Button, Modal, Text, View } from '../../../../components/new';
import KeyHandOffImage from '../../../../../assets/images/lease.webp';
import { formatPlateNo, getFullName } from '../../../../utilities/helper';
import { Appearance, State, Status } from '../../../../themes/new/helper';

const AssignRecallTaskConfirmation = ({
  task,
  valet,
  modalVisible,
  setModalVisible,
  handleKeyHandOffPress,
  assignTaskDisabled,
}) => {
  return (
    <Modal
      bg="blur"
      visible={modalVisible}
      onBackdropPress={() => {
        setModalVisible(false);
      }}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View
        width="90%"
        maxWidth={358}
        maxHeight={500}
        bg="background.primary.base"
        borderRadius={10}
        py="4xl"
        px="2xl"
      >
        <View alignItems="center" mb="4xl">
          <Image style={{ width: 60, height: 60 }} source={KeyHandOffImage} />
        </View>
        <View mb="4xl">
          <View mb="xl">
            <Text size="2xl" weight="medium" textAlign="center">
              Tag ID #{task?.parkingTag}
            </Text>
          </View>
          <Text textAlign="center" color="primary.300" size="md">
            Valet -{' '}
            {`${getFullName(valet?.name?.first, valet?.name?.last)} (${
              valet?.identityCode
            })`}
          </Text>
          <Text textAlign="center" color="primary.300" size="md">
            VRN - {formatPlateNo(task?.vehicle?.registration?.plate)}
          </Text>
        </View>
        <Button
          size="lg"
          status={Status.PRIMARY}
          appearance={Appearance.FILLED}
          state={assignTaskDisabled ? State.DISABLED : State.ACTIVE}
          onPress={handleKeyHandOffPress}
        >
          Key Handed Off
        </Button>
      </View>
    </Modal>
  );
};

export default AssignRecallTaskConfirmation;
