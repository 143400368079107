import React, { Suspense, useState } from 'react';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { View } from '../../../components/new';
import Layout from '../../../components/new/primitive/Layout/Layout';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import { InvestPagePlaceholder } from './shimmerPlaceholders';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import InvestmentPage from './components/InvestmentPage';
import { Loading } from '../../../components';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import useAuthStore from '../../../stores/authStore';

const InvestScreen = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    React.useCallback(() => {
      firebaseEventLogger('investmentPage__investment_Landing', {
        buttonName: 'investment',
        screenName: 'investmentPage',
        userType: 'user',
        interactionType: 'tap',
        isLoggedIn,
      });
    }, []),
  );

  return (
    <Layout level={2}>
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense
            fallback={
              Platform.OS === 'web' ? <Loading /> : <InvestPagePlaceholder />
            }
          >
            <InvestmentPage
              queryOptions={refreshedQueryOptions}
              refresh={refresh}
            />
          </Suspense>
        </NewErrorBoundary>
      </View>
      <DisconnectedDropover
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default InvestScreen;
