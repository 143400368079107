import { graphql } from 'react-relay';

const searchParkingScreenQuery = graphql`
  query searchParkingScreenQuery($filter: searchParkingsFilterInput!) {
    searchParking(filter: $filter) {
      parkingTag
      tasks {
        _id
        taskType
        # parkingTag
        vehicle {
          registration {
            plate
          }
        }
        history {
          action
        }
        user {
          profileImage
          contact {
            phone {
              prefix
              number
            }
          }
        }
      }
    }
  }
`;

export default searchParkingScreenQuery;
