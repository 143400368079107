/**
 * @generated SignedSource<<b0bd6692bedf3009b3acddb2433432b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumParkingParkingInvoicePaymentDetailsFocReason = "CUSTOMER_ISSUE" | "EMPLOYEE_EXIT" | "FREE_PARKING_TICKET" | "MISSED_EXIT" | "OTHER" | "VALET_EXIT" | "VIP_EXIT" | "%future added value";
export type EnumParkingParkingInvoicePaymentDetailsMode = "APP" | "CASH" | "FASTAG" | "FOC" | "ONLINE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CashierOverviewScreenTaskMetricsFragment$data = {
  readonly overview: {
    readonly cash: number;
    readonly online: number;
    readonly total: number;
  } | null;
  readonly parkings: ReadonlyArray<{
    readonly _id: any;
    readonly exitTime: any;
    readonly journey: ReadonlyArray<{
      readonly taskID: any;
    } | null> | null;
    readonly parkingInvoice: {
      readonly charges: {
        readonly chargesBreakup: ReadonlyArray<{
          readonly _id: any | null;
          readonly duration: number | null;
          readonly payable: number | null;
        } | null> | null;
        readonly discountAmount: number | null;
        readonly totalPayable: number | null;
      } | null;
      readonly paymentDetails: {
        readonly amount: number | null;
        readonly focReason: EnumParkingParkingInvoicePaymentDetailsFocReason | null;
        readonly isPaid: boolean;
        readonly mode: EnumParkingParkingInvoicePaymentDetailsMode | null;
      } | null;
    } | null;
    readonly vehicle: {
      readonly registration: {
        readonly plate: string;
      };
    };
    readonly vehicleIsWhitelisted: boolean;
  } | null> | null;
  readonly " $fragmentType": "CashierOverviewScreenTaskMetricsFragment";
};
export type CashierOverviewScreenTaskMetricsFragment$key = {
  readonly " $data"?: CashierOverviewScreenTaskMetricsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CashierOverviewScreenTaskMetricsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CashierOverviewScreenTaskMetricsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ParkingCashierTC",
      "kind": "LinkedField",
      "name": "parkings",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ParkingVehicle",
          "kind": "LinkedField",
          "name": "vehicle",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ParkingVehicleRegistration",
              "kind": "LinkedField",
              "name": "registration",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "plate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ParkingParkingInvoice",
          "kind": "LinkedField",
          "name": "parkingInvoice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ParkingParkingInvoicePaymentDetails",
              "kind": "LinkedField",
              "name": "paymentDetails",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isPaid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "focReason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ParkingParkingInvoiceCharges",
              "kind": "LinkedField",
              "name": "charges",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                  "kind": "LinkedField",
                  "name": "chargesBreakup",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "duration",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "payable",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discountAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalPayable",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exitTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vehicleIsWhitelisted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ParkingJourney",
          "kind": "LinkedField",
          "name": "journey",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taskID",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "cashierOverviewDetails",
      "kind": "LinkedField",
      "name": "overview",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "online",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "cashierTaskMetricsTC",
  "abstractKey": null
};
})();

(node as any).hash = "79f64fb43f98021365dcbaf4aa223eb1";

export default node;
