import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  RefObject,
  useImperativeHandle,
  useCallback,
} from 'react';
import { TextInput } from 'react-native';
import { View } from '../../../../../components/new';

const HiddenInput = forwardRef((_, ref: RefObject<any>) => {
  const [hiddenInput, sethiddenInput] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef?.current.focus();
    }
  }, []);

  const focusHiddenInput = useCallback(() => {
    if (inputRef.current) {
      inputRef?.current.focus();
    }
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        focusHiddenInput,
      };
    },
    [focusHiddenInput],
  );

  return (
    <View position="absolute" top={-999}>
      <TextInput
        ref={inputRef}
        blurOnSubmit={false}
        style={{
          width: 40,
          height: 40,
          margin: 12,
          borderWidth: 1,
          padding: 10,
          backgroundColor: '#fff',
        }}
        showSoftInputOnFocus={false}
        autoFocus
        onChangeText={sethiddenInput}
        value={hiddenInput}
      />
    </View>
  );
});

export default HiddenInput;
