/* eslint-disable import/prefer-default-export */
import { usePaginationFragment } from 'react-relay';
import { useMemo } from 'react';
import { useNodeFromPagination } from '../../../../../hooks/useNodeFromPagination';
import { buildTransactionViewData } from '../../../utils';
import transactionsFragment from './transactionsFragment';
import { transactionsFragment$key } from './__generated__/transactionsFragment.graphql';
import { network } from '../../../../../utilities/Constant';

const { pullToRefreshFetchPolicy } = network;

export const useTransactions = (fragmentRef: transactionsFragment$key) => {
  const {
    data,
    refetch,
    loadNext,
    hasNext: hasMoreTransactions,
  } = usePaginationFragment(transactionsFragment, fragmentRef);

  const transactionNodes = useNodeFromPagination(data.transactions);
  const transactions = useMemo(
    () => buildTransactionViewData(transactionNodes),
    [transactionNodes],
  );

  return {
    transactions,
    refetchTransactions: (onComplete, variables = {}) => {
      refetch(variables, {
        fetchPolicy: pullToRefreshFetchPolicy,
        onComplete: () => {
          if (typeof onComplete === 'function') {
            onComplete();
          }
        },
      });
    },
    hasMoreTransactions,
    fetchMoreTransactions: (count?: number) => loadNext(count),
  };
};
