/**
 * @generated SignedSource<<e05879284976788cb66617725aef310f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumcouponCouponBenefitType = "FLAT_CASHBACK" | "FLAT_DISCOUNT" | "PERCENTAGE_CASHBACK" | "PERCENTAGE_OFF" | "%future added value";
export type EnumcouponIssuanceStatus = "ACTIVE" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumcouponRedeemableAtRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type FilterFindManycouponInput = {
  AND?: ReadonlyArray<FilterFindManycouponInput> | null;
  OR?: ReadonlyArray<FilterFindManycouponInput> | null;
  _id?: any | null;
  _operators?: FilterFindManycouponOperatorsInput | null;
  briefText?: string | null;
  couponBenefit?: FilterFindManycouponCouponBenefitInput | null;
  couponCode?: string | null;
  couponExpiry?: FilterFindManycouponCouponExpiryInput | null;
  couponIssuanceQuota?: number | null;
  couponIssued?: number | null;
  couponUserUsageQuota?: number | null;
  created_at?: any | null;
  finePrint?: FilterFindManyTicketModelTCwoUserFinePrintInput | null;
  isEmployeeSpecificCoupon?: boolean | null;
  maxDiscountAmount?: number | null;
  minBillAmount?: number | null;
  redeemableAt?: ReadonlyArray<FilterFindManycouponRedeemableAtInput | null> | null;
  updated_at?: any | null;
};
export type FilterFindManycouponCouponBenefitInput = {
  _id?: any | null;
  type?: EnumcouponCouponBenefitType | null;
  value?: number | null;
};
export type FilterFindManyTicketModelTCwoUserFinePrintInput = {
  _id?: any | null;
  details?: ReadonlyArray<string | null> | null;
  summary?: string | null;
};
export type FilterFindManycouponRedeemableAtInput = {
  _id?: any | null;
  refId?: any | null;
  refType?: EnumcouponRedeemableAtRefType | null;
};
export type FilterFindManycouponCouponExpiryInput = {
  _id?: any | null;
  durationInDays?: number | null;
};
export type FilterFindManycouponOperatorsInput = {
  _id?: FilterFindManycoupon_idOperatorsInput | null;
};
export type FilterFindManycoupon_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FilterFindManycouponIssuanceInput = {
  AND?: ReadonlyArray<FilterFindManycouponIssuanceInput> | null;
  OR?: ReadonlyArray<FilterFindManycouponIssuanceInput> | null;
  _id?: any | null;
  _operators?: FilterFindManycouponIssuanceOperatorsInput | null;
  briefText?: string | null;
  couponBenefit?: FilterFindManycouponCouponBenefitInput | null;
  couponCode?: string | null;
  couponExpiryDate?: any | null;
  couponId?: any | null;
  created_at?: any | null;
  finePrint?: FilterFindManyTicketModelTCwoUserFinePrintInput | null;
  maxDiscountAmount?: number | null;
  minBillAmount?: number | null;
  redeemableAt?: ReadonlyArray<FilterFindManycouponRedeemableAtInput | null> | null;
  status?: EnumcouponIssuanceStatus | null;
  updated_at?: any | null;
};
export type FilterFindManycouponIssuanceOperatorsInput = {
  _id?: FilterFindManycouponIssuance_idOperatorsInput | null;
};
export type FilterFindManycouponIssuance_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type CouponsComponentQuery$variables = {
  count: number;
  cursor?: string | null;
  filter?: FilterFindManycouponInput | null;
  issuedCount: number;
  issuedFilter?: FilterFindManycouponIssuanceInput | null;
};
export type CouponsComponentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CouponsComponentQueryFindCouponsFragment" | "CouponsComponentQueryFindIssuedCouponsFragment">;
};
export type CouponsComponentQuery = {
  response: CouponsComponentQuery$data;
  variables: CouponsComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuedCount"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuedFilter"
},
v5 = {
  "kind": "Variable",
  "name": "cursor",
  "variableName": "cursor"
},
v6 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v7 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "issuedFilter"
},
v8 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "cursor"
},
v9 = [
  (v8/*: any*/),
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "couponCode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "briefText",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "TicketModelTCwoUserFinePrint",
  "kind": "LinkedField",
  "name": "finePrint",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "details",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "couponCouponBenefit",
  "kind": "LinkedField",
  "name": "couponBenefit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxDiscountAmount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = [
  "filter"
],
v20 = [
  (v8/*: any*/),
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "issuedCount"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CouponsComponentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CouponsComponentQueryFindCouponsFragment"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "issuedCount"
          },
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CouponsComponentQueryFindIssuedCouponsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CouponsComponentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "couponConnection",
        "kind": "LinkedField",
        "name": "findCoupons",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "couponEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "coupon",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "couponCouponExpiry",
                    "kind": "LinkedField",
                    "name": "couponExpiry",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "durationInDays",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "filters": (v19/*: any*/),
        "handle": "connection",
        "key": "CouponsComponentFindCouponsFragment__findCoupons",
        "kind": "LinkedHandle",
        "name": "findCoupons"
      },
      {
        "alias": null,
        "args": (v20/*: any*/),
        "concreteType": "couponIssuanceConnection",
        "kind": "LinkedField",
        "name": "findIssuedCoupons",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "couponIssuanceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "couponIssuance",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "couponExpiryDate",
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v20/*: any*/),
        "filters": (v19/*: any*/),
        "handle": "connection",
        "key": "CouponsComponentFindCouponsFragment__findIssuedCoupons",
        "kind": "LinkedHandle",
        "name": "findIssuedCoupons"
      }
    ]
  },
  "params": {
    "cacheID": "b8cc44bdc0639141020db3b69cc3aea9",
    "id": null,
    "metadata": {},
    "name": "CouponsComponentQuery",
    "operationKind": "query",
    "text": "query CouponsComponentQuery(\n  $filter: FilterFindManycouponInput\n  $issuedFilter: FilterFindManycouponIssuanceInput\n  $issuedCount: Int!\n  $count: Int!\n  $cursor: String\n) {\n  ...CouponsComponentQueryFindCouponsFragment_3KQYpM\n  ...CouponsComponentQueryFindIssuedCouponsFragment_4E7pA1\n}\n\nfragment CouponsComponentQueryFindCouponsFragment_3KQYpM on Query {\n  findCoupons(filter: $filter, first: $count, after: $cursor) {\n    edges {\n      node {\n        couponCode\n        briefText\n        finePrint {\n          details\n          summary\n        }\n        couponBenefit {\n          type\n          value\n        }\n        couponExpiry {\n          durationInDays\n        }\n        created_at\n        maxDiscountAmount\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment CouponsComponentQueryFindIssuedCouponsFragment_4E7pA1 on Query {\n  findIssuedCoupons(filter: $issuedFilter, first: $issuedCount, after: $cursor) {\n    edges {\n      node {\n        couponCode\n        briefText\n        finePrint {\n          details\n          summary\n        }\n        couponBenefit {\n          type\n          value\n        }\n        couponExpiryDate\n        created_at\n        maxDiscountAmount\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d31f01be31433458b0ac83c10310c19";

export default node;
