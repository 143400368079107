/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import SpInAppUpdates, {
  IAUUpdateKind,
  StartUpdateOptions,
  IAUInstallStatus,
} from 'sp-react-native-in-app-updates';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import * as Application from 'expo-application';
import { UpdateModelStatesEnum } from '../stores/commonStore';

export const UpdateText = {
  title: 'App Update Available!',
  message:
    'Update your app to unlock new features, enhanced security and a smoother experience',
  askMeLaterBtnText: ' Ask Me Later',
  upadteNowBtnText: 'Update Now',
};

export const installedVersion = Constants?.expoConfig?.version;
export const appBundleId = Application.applicationId;

export const onPressUpdateNow = async ({
  status = UpdateModelStatesEnum.PROMPT,
}) => {
  const inAppUpdates = new SpInAppUpdates(
    true, // isDebug
  );
  try {
    // curVersion is optional if you don't provide it will automatically take from the app using react-native-device-info
    await inAppUpdates
      .checkNeedsUpdate({
        country: 'IN',
        curVersion: installedVersion,
        bundleId: appBundleId,
      })
      .then((result) => {
        // console.log('res----', JSON.stringify(result))
        try {
          if (result.shouldUpdate) {
            const updateOptions: StartUpdateOptions = Platform.select({
              ios: {
                title: UpdateText.title,
                message: UpdateText.message,
                country: 'IN',
                bundleId: appBundleId,
                buttonUpgradeText: UpdateText.upadteNowBtnText,
                buttonCancelText: UpdateText.askMeLaterBtnText,
                forceUpgrade: status === UpdateModelStatesEnum.FORCE,
                updateType:
                  status === UpdateModelStatesEnum.FORCE
                    ? IAUUpdateKind.IMMEDIATE
                    : IAUUpdateKind.FLEXIBLE,
                bypassAlert: false,
              },
              android: {
                updateType:
                  status === UpdateModelStatesEnum.FORCE
                    ? IAUUpdateKind.IMMEDIATE
                    : IAUUpdateKind.FLEXIBLE,
              },
            });
            inAppUpdates.addStatusUpdateListener((downloadStatus) => {
              if (downloadStatus.status === IAUInstallStatus.DOWNLOADED) {
                inAppUpdates.installUpdate();
                inAppUpdates.removeStatusUpdateListener((finalStatus) => {
                  console.log('final status', finalStatus);
                });
              }
            });
            inAppUpdates.startUpdate(updateOptions);
          }
        } catch (error) {
          console.log('res err---', error);
        }
      })
      .catch((err) => {
        console.log('er----', err);
      });
  } catch (error) {
    console.log('errr---', error);
  }
};
