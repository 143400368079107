import React, { useState, useContext } from 'react';
import { Animated, StyleSheet, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { IconButton, TopNavigation, View, Text } from '../index';
import ImageCarousel from './ImageCarousel';
import useCommonStore, { AppTheme } from '../../../stores/commonStore';
import { Appearance } from '../../../themes/new/helper';
import { dynamicHeightMaker } from '../../../utilities/helper';

const ParallaxHeader = ({
  maxHeight,
  minHeight,
  children,
  images,
  onPressIcon,
  leftIconName,
  iconRight = <View />,
  scrollListener,
  onPress,
  navTitle,
  showNavigationCta = true,
  imageSectionAspectRatio = 1.5,
}) => {
  const theme = useTheme();
  const { width, height } = useWindowDimensions();
  const screenOrientationValue = useCommonStore((state) => state.orientation);

  const windowWidth = width > 1200 && screenOrientationValue < 3 ? 375 : width;
  const [topNavigationHeight, setTopNavigationHeight] = useState(0);
  const [topNavigationStatus, setTopNavigationStatus] = useState({
    level: 'none',
    appearance: 'filled',
  });
  const [showNavTitle, setShowNavTitle] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const { top } = useSafeAreaInsets();
  const onLayoutTopNavigation = ({ nativeEvent }) => {
    if (topNavigationHeight === 0) {
      setTopNavigationHeight(nativeEvent?.layout?.height);
    }
  };
  const HEADER_MAX_HEIGHT = React.useMemo(
    () =>
      maxHeight ||
      (width > 500
        ? height * 0.75
        : dynamicHeightMaker(windowWidth, imageSectionAspectRatio)),
    [maxHeight],
  );
  const HEADER_MIN_HEIGHT = React.useMemo(() => minHeight || 0, [minHeight]);

  const scrollPositionY = React.useMemo(() => new Animated.Value(0), []);

  const HEADER_SCROLL_DISTANCE = React.useMemo(
    () => HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT,
    [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
  );

  // translating whole header view
  const headerTranslate = scrollPositionY.interpolate({
    inputRange: [0, HEADER_SCROLL_DISTANCE],
    outputRange: [0, HEADER_SCROLL_DISTANCE / 4],
    extrapolate: 'clamp',
  });

  // decreaing and increasing opacity of image on the basis of scroll distance
  const imageOpacity = scrollPositionY.interpolate({
    inputRange: [
      0,
      HEADER_SCROLL_DISTANCE / 6,
      HEADER_SCROLL_DISTANCE - (top + topNavigationHeight),
    ],
    outputRange: [1, 1, 0],
    extrapolate: 'clamp',
  });

  // translating image from its position
  const imageTranslate = scrollPositionY.interpolate({
    inputRange: [0, HEADER_SCROLL_DISTANCE],
    outputRange: [0, 100],
    extrapolate: 'clamp',
  });

  // listener to check if translated value reaches particular
  //  point and changing level & appearance of topNavigation
  headerTranslate.addListener(({ value }) => {
    if (value > 0 && topNavigationHeight > 0) {
      const scrollDistance =
        (HEADER_SCROLL_DISTANCE - topNavigationHeight - top) / 4 - 5;

      if (value >= scrollDistance) {
        setTopNavigationStatus({ level: '1', appearance: 'ghost' });
        setShowVideo(false);
        setShowNavTitle(true);
      }

      if (value < scrollDistance) {
        setTopNavigationStatus({ level: 'none', appearance: 'filled' });
        setShowVideo(true);
        setShowNavTitle(false);
      }
    }
  });
  return (
    <>
      <Animated.ScrollView
        scrollEventThrottle={1}
        showsVerticalScrollIndicator={false}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: { y: scrollPositionY },
              },
            },
          ],
          {
            useNativeDriver: true,
            listener: scrollListener,
          },
        )}
        style={{ backgroundColor: 'transparent' }}
        bounces={false}
      >
        {images?.length && (
          <Animated.View
            style={[
              styles.header,
              { height: HEADER_SCROLL_DISTANCE },
              { transform: [{ translateY: headerTranslate }] },
            ]}
          >
            <>
              <Animated.View
                style={[
                  styles.background,
                  {
                    opacity: imageOpacity,
                    height: HEADER_MAX_HEIGHT,
                    transform: [{ translateY: imageTranslate }],
                  },
                ]}
              >
                <ImageCarousel
                  data={images}
                  windowWidth={windowWidth}
                  aspectRatio={imageSectionAspectRatio}
                  showVideo={showVideo}
                  dotsFromBottom={theme.space['4xl']}
                  onPress={onPress}
                  showNavigationCta={showNavigationCta}
                />
              </Animated.View>
            </>
          </Animated.View>
        )}
        <View
          borderTopLeftRadius="lg"
          borderTopRightRadius="lg"
          backgroundColor="background.primary.base"
          mt={HEADER_MAX_HEIGHT - 24}
        >
          {children}
        </View>
      </Animated.ScrollView>
      <View
        width="100%"
        position="absolute"
        top={0}
        onLayout={onLayoutTopNavigation}
        zIndex={2}
      >
        <TopNavigation
          IconLeft={
            leftIconName ? (
              <View flexDirection="row" alignItems="center">
                <IconButton
                  name="back-outline-300"
                  size="md"
                  appearance={topNavigationStatus?.appearance}
                  iconSize="xl"
                  iconColor={
                    topNavigationStatus?.appearance === Appearance.GHOST
                      ? theme.currentThemeMode === AppTheme.DARK
                        ? 'static.white'
                        : 'static.black'
                      : 'static.black'
                  }
                  shape="rounded"
                  bg="static.white"
                  onPress={onPressIcon}
                />
              </View>
            ) : null
          }
          title={navTitle && showNavTitle ? navTitle : ''}
          IconRight={iconRight}
          appearance="ghost"
          level={topNavigationStatus?.level}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'opacity.95',
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    // resizeMode: 'cover',
  },
});

export default ParallaxHeader;
