import { useFragment } from 'react-relay';
import { useCallback, useMemo, useRef } from 'react';
import * as ImagePicker from 'expo-image-picker';
import { Alert } from 'react-native';
import * as Linking from 'expo-linking';
import {
  findUserByIdFragment,
  findWorkLogFragment,
} from '../API/profileCardQuery';
import useUserStore from '../../../../stores/userStore';
import AddWorkLog from '../../../../relay/valetMyTasksApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import S3Upload from '../../../../utilities/S3Upload';
import { camelCaseToSeparatedWords } from '../../../../utilities/helper';
import useCampusLocation from '../../../../stores/campusLocation';
import { calculateOnlineTime } from '../helper/helper';
import AppConstants from '../../../../utilities/AppConstants';

const useProfileCardData = ({ refreshAPI, apiData }) => {
  // const { workLogHistory, workSummary } =

  const dataOne = useFragment(findWorkLogFragment, apiData?.findValetWorkLog);

  const workLogHistory = dataOne?.workLogHistory || [];
  const workSummary = dataOne?.workSummary;

  const fragResult = useFragment(findUserByIdFragment, apiData?.findUserById);

  const { name, userSince, profileImage } = fragResult || {};
  const userId = useUserStore((state) => state.id);
  const userRole = useUserStore((state) => state.role);
  const controllerRole = useUserStore((state) => state.controllerRole);
  const { currentLocation } = useCampusLocation((state) => state);

  const lastWorkLog = workLogHistory?.slice(-1)?.[0];

  const isPunchedIn = lastWorkLog?.type === 'punch_in';
  let onlineTime = '-';

  if (workLogHistory.length) {
    const { hours, minutes, seconds } = calculateOnlineTime(workLogHistory);
    if (hours !== '0' && hours !== '00') {
      // onlineTime = `${hours}h ${minutes}m ${seconds}s`;
      onlineTime = `${hours}h ${minutes}m`;
    } else if (minutes !== '0' && minutes !== '00') {
      // onlineTime = `${minutes}m ${seconds}s`;
      onlineTime = `${minutes}m`;
    } else if (seconds !== '0' && seconds !== '00') {
      // onlineTime = `${seconds}s`;
      onlineTime = `${0}m`;
    } else if (seconds === '0' || seconds === '00') {
      onlineTime = `0m`;
    }
  }

  const buttonActionText = isPunchedIn ? 'Punch-Out' : 'Punch-In';

  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const bottomSheetModalRef = useRef(null);
  const controllerBottomSheetModalRef = useRef(null);

  const initialModalSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], []);

  const handleOnDismissModal = useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  const showModal = useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);

  const openControllerBottomSheet = () => {
    controllerBottomSheetModalRef?.current?.show();
  };

  const addLog = async (type) =>
    AddWorkLog({
      date: new Date().toISOString(),
      valetID: userId,
      workLogHistory: [
        {
          time: new Date().toISOString(),
          type,
        },
      ],
    })
      .then(() => {
        handleOnDismissModal();
        refreshAPI();
      })
      .catch((error) => {
        handleOnDismissModal();
        refreshAPI();
        dispatchSnackbar({
          msg: 'Oops! Something went wrong!😓',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
      });

  const handlePunch = () => {
    if (isPunchedIn) {
      showModal();
    }
    if (!isPunchedIn) {
      addLog('punch_in');
    }
  };

  const openImagePickerAsync = async (mode) => {
    let pickerResult;
    if (mode === 'gallery') {
      const getGalleryPermission =
        await ImagePicker.getMediaLibraryPermissionsAsync();
      const requestGalleryPermission =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (
        getGalleryPermission.status === 'denied' ||
        requestGalleryPermission.status === 'denied'
      ) {
        Alert.alert(
          '32nd does not have access to your photos. To enable access tap Settings.',
          '',
          [
            {
              text: 'Cancel',
              onPress: () => {
                // console.log('Cancel Pressed')
              },
              style: 'cancel',
            },
            { text: 'Settings', onPress: () => Linking.openSettings() },
          ],
        );
      } else {
        pickerResult = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [3, 3],
          base64: true,
        });
      }
    } else {
      const getCameraPermission = await ImagePicker.getCameraPermissionsAsync();
      const requestCameraPermissions =
        await ImagePicker.requestCameraPermissionsAsync();
      if (
        getCameraPermission.status === 'denied' ||
        // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
        requestCameraPermissions === 'denied'
      ) {
        Alert.alert(
          '32nd does not have access to your Camera. To enable access tap Settings.',
          '',
          [
            {
              text: 'Cancel',
              onPress: () => {
                // console.log('Cancel Pressed')
              },
              style: 'cancel',
            },
            { text: 'Settings', onPress: () => Linking.openSettings() },
          ],
        );
      } else {
        pickerResult = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [3, 3],
          base64: true,
        });
      }
    }
    if (pickerResult.assets[0].base64) {
      S3Upload(pickerResult, { userId, userRole }, refreshAPI);
    }
  };

  const { IMAGEKIT_PROD_URI } = AppConstants;
  const modifiedImageURI = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';

  return {
    apiData: {
      workLogHistory,
      workSummary,
      name,
      userSince,
      profileImage: modifiedImageURI,
    },
    isPunchedIn,
    buttonActionText,
    addLog,
    showModal,
    bottomSheetModalRef,
    initialModalSnapPoints,
    handleOnDismissModal,
    handlePunch,
    onlineTime,
    openImagePickerAsync,
    userRole,
    controllerRole,
    roleText: camelCaseToSeparatedWords(userRole),
    controllerBottomSheetModalRef,
    location: currentLocation?.name || '',
    openControllerBottomSheet,
  };
};

export default useProfileCardData;
