/* eslint-disable import/no-extraneous-dependencies */
import React, { forwardRef } from 'react';
import ActionSheet, {
  ActionSheetProps,
  ActionSheetRef,
} from 'react-native-actions-sheet';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getTheme } from '../../../../themes/new/theme';

interface BottomSheetV2Props extends ActionSheetProps {
  insets?: boolean;
}

const BottomSheetV2 = forwardRef(
  (props: BottomSheetV2Props, ref: React.ForwardedRef<ActionSheetRef>) => {
    const theme = useTheme();
    const showInsets = Platform.OS === 'ios' ? props?.insets ?? true : true;
    const { bottom } = useSafeAreaInsets();

    const defaultActionSheetProps: ActionSheetProps = {
      useBottomSafeAreaPadding: true,
      indicatorStyle: {
        height: getTheme().sizes['5xs'],
        width: getTheme().sizes.xl,
        backgroundColor: getTheme(theme?.currentThemeMode).colors.primary[100],
      },
      gestureEnabled: true,
      overlayColor: getTheme(theme?.currentThemeMode).colors.backdrop[500],
      defaultOverlayOpacity: 0.8,
      animated: true,
      containerStyle: {
        backgroundColor: getTheme(theme?.currentThemeMode).colors.background
          .secondary.elevation,
        paddingBottom: showInsets ? (bottom < 12 ? 12 : bottom) : 0,
      },
      keyboardHandlerEnabled: Platform.OS !== 'ios',
    };
    const mergedProps: ActionSheetProps = {
      ...defaultActionSheetProps,
      ...props,
    };
    const { children } = mergedProps;
    return (
      <ActionSheet ref={ref} {...mergedProps}>
        {children}
      </ActionSheet>
    );
  },
);

export default BottomSheetV2;
