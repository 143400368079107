import React, { useCallback, useEffect, useState, useRef } from 'react';
import { fetchQuery } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { debounce } from 'lodash';
import environment from '../../../relay/relayEnvironment';
import { Input, Text, View } from '../../../components/new';
import {
  navigateBack,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../utilities/helper';
import { cashierCarSearchScreenQuery } from '../API/cashierCarSearchScreenQuery';
import TaskList from './components/TasksList';
import NoContentView from './components/NoContentView';
import { Loading } from '../../../components';

const fetchTasksQuery = debounce((variables, callbacks, subscriptionRef) => {
  subscriptionRef?.current?.unsubscribe(); // cancelling previous request on the way
  const observable$ = fetchQuery(
    environment,
    cashierCarSearchScreenQuery,
    variables,
  );
  const subscription = observable$.subscribe({
    next: callbacks.onData,
    error: callbacks.onError,
  });
  subscriptionRef.current = subscription;
}, 300);

const CashierCarSearchScreenInner = ({ role, campusId }) => {
  const navigation = useNavigation();
  const querySubscription = useRef(null);
  const [plateNumber, setPlateNumber] = useState('');
  const [tasks, setTasks] = useState([]);
  const [resultsFetchingCompleted, setResultsFetchingCompleted] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const noTasks = !tasks.length;

  useEffect(() => {
    /** dispose pending api requests in case component unmounts */
    return () => querySubscription?.current?.unsubscribe();
  }, []);

  const buildQueryVariables = useCallback((vehicleNo, campusID) => {
    return {
      taskFilter: {
        history: [
          { action: taskHistoryActionTypes.arrived },
          { action: taskHistoryActionTypes.completed },
        ],
        taskType: taskTypes.recall,
        search: {
          vehicleNo,
        },
        campus: {
          _id: campusID,
        },
      },
    };
  }, []);

  const fetchTasks = useCallback(
    (vehicleNo: string) => {
      const variables = buildQueryVariables(vehicleNo, campusId);
      setIsLoading(true);

      fetchTasksQuery(
        variables,
        {
          onData: ({ findTasks }) => {
            setIsLoading(false);
            setResultsFetchingCompleted(true);
            setTasks(findTasks);
            querySubscription.current = null;
          },
          onError: (e) => {
            console.log(e);
            setIsLoading(false);
            setResultsFetchingCompleted(true);
            setTasks([]);
            querySubscription.current = null;
          },
        },
        querySubscription,
      );
    },
    [campusId],
  );

  const handleInputChange = (vehicleNo: string) => {
    const vehicleNoWithoutSpace = vehicleNo.replace(/\s/g, '').toUpperCase();
    if (resultsFetchingCompleted) setResultsFetchingCompleted(false);
    if (vehicleNoWithoutSpace === plateNumber) return;
    setPlateNumber(vehicleNoWithoutSpace);

    if (vehicleNoWithoutSpace.length < 4) {
      if (vehicleNoWithoutSpace.length === 0) setTasks([]);
      return;
    }
    fetchTasks(vehicleNoWithoutSpace); // already debounced
  };

  const showNoContentView = resultsFetchingCompleted && noTasks;

  return (
    <View pb="xl" pt="26" zIndex={10}>
      <View px="2xl" mb="10">
        <Input
          autoFocus
          autoCapitalize="characters"
          leftIconName="back-outline-300"
          rightIconName="search-outline-400"
          placeholder="HR 26 AA 1234"
          value={plateNumber}
          onChangeText={handleInputChange}
          onLeftIconClick={() => navigateBack(navigation, role)}
        />
      </View>
      <View>
        {isLoading && (
          <View mt="6xl" height="100">
            <Loading />
          </View>
        )}
        {!isLoading && !noTasks && (
          <TaskList navigation={navigation} tasks={tasks} />
        )}
        {showNoContentView && <NoContentView searchedVehicleNo={plateNumber} />}
      </View>
    </View>
  );
};

export default CashierCarSearchScreenInner;
