import React, { useCallback, useEffect, useRef } from 'react';
import { Image, ImageBackground, useWindowDimensions } from 'react-native';
import { BlurView } from 'expo-blur';
import { useNavigation } from '@react-navigation/native';
import { View, Text } from '../../../components/new';
import useCommonStore from '../../../stores/commonStore';
import useUserStore from '../../../stores/userStore';
import BottomSheetV2 from '../composites/BottomSheet/BottomSheetV2';
import CustomLottieView from './CustomLottieView';
import { GoldButton } from '../../../screens/new/plusMembership';
import { getTheme } from '../../../themes/new/theme';
import DarkThemeWrapper from '../../../screens/new/wrapperComp/DarkThemeWrapper';

const MESH_BG = require('../../../../assets/images/black_membership/mesh-bg.png');

const ClubMemberBottomSheet = () => {
  const navigation = useNavigation();
  const bottomSheetModalRef = useRef(null);
  const { width } = useWindowDimensions();
  const { checkedClubBenefits, setCheckedClubBenefits } = useCommonStore(
    (state) => state,
  );
  const { blackMembership } = useUserStore((state) => state);
  const { active } = blackMembership ?? {};
  const handleClubModalPress = useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);

  const handleOnDismiss = useCallback(() => {
    setCheckedClubBenefits({ checked: true, deniedTime: new Date() });
    bottomSheetModalRef.current?.hide();
  }, []);

  const checkStatus = () => {
    if (checkedClubBenefits?.deniedTime) {
      const currentDate = new Date();
      const deniedTimeDate = new Date(checkedClubBenefits?.deniedTime);
      const time = Math.abs(Number(currentDate) - Number(deniedTimeDate));
      // eslint-disable-next-line no-useless-return
      if (Math.ceil(time / (1000 * 60 * 60 * 24)) <= 30) return;
      handleClubModalPress();
    } else {
      handleClubModalPress();
    }
  };

  useEffect(() => {
    if (active) {
      checkStatus();
    }
  }, []);

  const onPressViewAllBenefits = () => {
    handleOnDismiss();
    navigation.navigate('blackMembershipScreen');
  };

  return (
    <DarkThemeWrapper>
      <BottomSheetV2
        ref={bottomSheetModalRef}
        onClose={handleOnDismiss}
        insets={false}
        containerStyle={{
          backgroundColor: 'black',
          paddingBottom: 0,
          overflow: 'hidden',
        }}
        indicatorStyle={{
          margin: 10,
          zIndex: 10,
          position: 'absolute',
          backgroundColor: getTheme()?.colors?.primary[100],
        }}
      >
        <View height={400}>
          <ImageBackground
            source={MESH_BG}
            resizeMode="cover"
            style={{
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <View px="2xl">
              <View>
                <BlurView intensity={20} width="100%">
                  <View
                    borderRadius="lg"
                    borderWidth="xs"
                    borderColor="static.gold"
                    p="2xl"
                  >
                    <View alignSelf="center">
                      <CustomLottieView
                        autoPlay
                        loop
                        source={require('../../../../assets/lottie/black_success.json')}
                        style={{
                          height: 240,
                          width,
                          position: 'absolute',
                          alignSelf: 'center',
                        }}
                        containerWidth={width}
                      />
                    </View>
                    <Image
                      source={require('../../../../assets/images/black_membership/black-member-logo-3.webp')}
                      style={{
                        width: 150,
                        height: 100,
                        resizeMode: 'contain',
                        alignSelf: 'center',
                      }}
                    />
                    <Text
                      size="xl"
                      color="primary.500"
                      mt="lg"
                      textAlign="center"
                    >
                      You are now a 32nd Plus member!
                    </Text>
                    <Text
                      size="sm"
                      color="primary.300"
                      mt="lg"
                      textAlign="center"
                    >
                      Enjoy exclusive benefits on parking, event, bill payments
                      & more!
                    </Text>
                  </View>
                </BlurView>
              </View>
              <View my="2xl">
                <GoldButton
                  onPress={onPressViewAllBenefits}
                  text="View All Benefits & Savings"
                />
              </View>
            </View>
          </ImageBackground>
        </View>
      </BottomSheetV2>
    </DarkThemeWrapper>
  );
};

export default ClubMemberBottomSheet;
