import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const editAmountScreenQuery = graphql`
  query editAmountScreenQuery($taskFilter: findTaskFilterInput!) {
    findTasks(filter: $taskFilter) {
      _id
      parking {
        _id
        status
        parkingTag
        parkingInvoice {
          timings {
            entryTime
            exitTime
            chargeTime
          }
          paymentDetails {
            isPaid
            mode
            focReason
            amount
          }
          charges {
            chargesBreakup {
              duration
              payable
            }
            discountAmount
            totalPayable
          }
        }
      }
    }
  }
`;
