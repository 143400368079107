/**
 * @generated SignedSource<<52f290c2217c2dd7289f807f6c213543>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumUserContactEmailType = "PERSONAL" | "WORK" | "%future added value";
export type EnumUserPartnerRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumUserRolePermission = "staff" | "%future added value";
export type EnumUserRoleSecondaryRole = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type updateUserRecordInput = {
  contact?: UpdateByIdUserContactInput | null;
  createdBy?: any | null;
  deleted?: boolean | null;
  deletedAt?: any | null;
  devices?: ReadonlyArray<UpdateByIdUserDevicesInput | null> | null;
  firstLogin?: boolean | null;
  firstLoginAt?: any | null;
  identityCode?: string | null;
  isEmployee?: boolean | null;
  lastLogin?: any | null;
  name?: UpdateByIdUserNameInput | null;
  parkedCount?: number | null;
  partnerRefId?: any | null;
  partnerRefType?: EnumUserPartnerRefType | null;
  photoMomentsSearchImage?: string | null;
  pin?: string | null;
  profileImage?: string | null;
  role?: UpdateByIdUserRoleInput | null;
  typeForm?: UpdateByIdUserTypeFormInput | null;
  uniBoxCredentials?: UpdateByIdUserUniBoxCredentialsInput | null;
  visitCount?: number | null;
  wallet?: UpdateByIdUserWalletInput | null;
};
export type UpdateByIdUserNameInput = {
  _id?: any | null;
  first?: string | null;
  last?: string | null;
};
export type UpdateByIdUserContactInput = {
  _id?: any | null;
  email?: ReadonlyArray<UpdateByIdUserContactEmailInput | null> | null;
  phone?: UpdateByIdUserContactPhoneInput | null;
};
export type UpdateByIdUserContactPhoneInput = {
  _id?: any | null;
  number: string;
  prefix: string;
};
export type UpdateByIdUserContactEmailInput = {
  _id?: any | null;
  addr: string;
  type: EnumUserContactEmailType;
};
export type UpdateByIdUserDevicesInput = {
  OS?: string | null;
  _id?: any | null;
  deviceToken?: string | null;
  enabled?: boolean | null;
  expoToken?: string | null;
  modelName?: string | null;
  platform?: string | null;
};
export type UpdateByIdUserTypeFormInput = {
  _id?: any | null;
  landedAt?: any | null;
  submittedAt?: any | null;
};
export type UpdateByIdUserRoleInput = {
  _id?: any | null;
  meta?: UpdateByIdUserRoleMetaInput | null;
  name?: EnumUserRoleName | null;
  permission?: EnumUserRolePermission | null;
  secondaryRole?: ReadonlyArray<EnumUserRoleSecondaryRole | null> | null;
};
export type UpdateByIdUserRoleMetaInput = {
  _id?: any | null;
  campus?: UpdateByIdUserRoleMetaCampusInput | null;
};
export type UpdateByIdUserRoleMetaCampusInput = {
  _id?: any | null;
  name?: string | null;
};
export type UpdateByIdUserUniBoxCredentialsInput = {
  _id?: any | null;
  password?: string | null;
  username?: string | null;
};
export type UpdateByIdUserWalletInput = {
  _id?: any | null;
  currentBalance?: number | null;
  earnedAmount?: number | null;
  moneySaved?: number | null;
  totalBalance?: number | null;
};
export type userApiUpdateMutation$variables = {
  id: any;
  record: updateUserRecordInput;
};
export type userApiUpdateMutation$data = {
  readonly updateUser: {
    readonly record: {
      readonly _id: any;
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
      readonly photoMomentsSearchImage: string | null;
      readonly profileImage: string | null;
      readonly role: {
        readonly name: EnumUserRoleName | null;
      } | null;
    } | null;
  } | null;
};
export type userApiUpdateMutation = {
  response: userApiUpdateMutation$data;
  variables: userApiUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdUserPayload",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserName",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContactPhone",
                "kind": "LinkedField",
                "name": "phone",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prefix",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileImage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photoMomentsSearchImage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userApiUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userApiUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0c59bbbded3b652a5bb8da6df6151744",
    "id": null,
    "metadata": {},
    "name": "userApiUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation userApiUpdateMutation(\n  $id: MongoID!\n  $record: updateUserRecordInput!\n) {\n  updateUser(_id: $id, record: $record) {\n    record {\n      _id\n      name {\n        first\n        last\n      }\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n      role {\n        name\n      }\n      profileImage\n      photoMomentsSearchImage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb6d908fee14f71316d2afbec76838ca";

export default node;
