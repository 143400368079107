/* eslint-disable import/prefer-default-export */
import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

export const InvestmentPageQuery = graphql`
  query InvestmentPageQuery(
    $count: Int!
    $cursor: String
    $includeFindConsents: Boolean!
  ) {
    findInvestmentUserConsents @include(if: $includeFindConsents) {
      propertyId
      status
    }
    findInvestmentListingComponent {
      _id
      heading
      subHeading
      cover {
        autoPlayable
        title
        description
        cover
        displayOrder
        mediaType
        mediaURL
        thumbnailURL
        _id
      }
    }
    ...InvestmentPageQueryInvestmentPropertiesFragment
      @arguments(count: $count, cursor: $cursor)
  }
`;

export const InvestmentPageQueryInvestmentPropertiesFragment = graphql`
  fragment InvestmentPageQueryInvestmentPropertiesFragment on Query
  @refetchable(queryName: "InvestmentPageQueryInvestmentPropertiesQuery")
  @argumentDefinitions(count: { type: "Int" }, cursor: { type: "String" }) {
    findInvestmentProperties(first: $count, after: $cursor)
      @connection(
        key: "InvestmentPageQueryInvestmentPropertiesFragment__findInvestmentProperties"
      ) {
      count
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          investmentType
          intro {
            heading
            subHeading
            media {
              autoPlayable
              title
              description
              cover
              displayOrder
              mediaType
              mediaURL
              thumbnailURL
              _id
            }
            cover {
              mediaType
              mediaURL
              thumbnailURL
              _id
            }
          }
          project {
            managedBy {
              name
              promise {
                heading
                subHeading
                values {
                  heading
                  subHeading
                  icon
                  _id
                }
                _id
              }
            }
            designer
          }
          investment {
            data {
              investmentAmount {
                min
              }
              application {
                applied
                amount
              }
              investment {
                years {
                  min
                  max
                  default
                }
                units {
                  totalUnits
                  activeUnits
                  soldUnits
                  perUnitAmount
                }
                roi {
                  rentalYieldPostOps
                  rentalYieldPreOps
                  capitalAppriciation
                  xirr {
                    year
                    percentage
                    _id
                  }
                }
              }
            }
          }
          about {
            typeformId
            name
          }
          location {
            name
          }
          _id
        }
      }
    }
  }
`;

export const AddInvestmentUserConsents = (data, callback, errorCallBack) => {
  const mutation = graphql`
    mutation InvestmentPageQueryAddInvestmentUserConsentsMutation(
      $record: addInvestmentUserConsentsInput!
    ) {
      addInvestmentUserConsents(record: $record) {
        record {
          propertyId
          status
        }
        error {
          message
        }
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallBack(err);
    },
  });
};

export const FindInvestmentUserConsents = () => {
  const query = graphql`
    query InvestmentPageQueryFindInvestmentUserConsentsQuery {
      findInvestmentUserConsents {
        propertyId
        status
      }
    }
  `;
  return fetchQuery(environment, query).toPromise();
};
