import React from 'react';
import { Text, View } from '../../../../../components/new';
import usePlusMembershipProfile from './usePlusMembershipProfile';
import BlackMembershipSavingsSection from './components/BlackMembershipSavingsSection';
import CustomScrollView from '../../../../../components/new/custom/CustomScrollView';
import BlackMembershipBenefitsSection from './components/BlackMembershipBenefitsSection';
import BMPaymentFooter from './components/BMPaymentFooter';
import { NewErrorBoundaryParentState } from '../../../../../utilities/NewErrorBoundary';
import CachedImage from '../../../../../components/new/custom/CachedImage';

const BlackMemberLogoH = require('../../../../../../assets/images/black_membership/black-member-logo-2.webp');

interface PlusMembershipProfileScreenInnerProps {
  setEarnedPoints: (value: number) => void;
  queryOptions: NewErrorBoundaryParentState;
}

const PlusMembershipProfileScreenInner = ({
  setEarnedPoints,
  queryOptions,
}: PlusMembershipProfileScreenInnerProps) => {
  const {
    isBlackMember,
    membershipPrice,
    membershipDays,
    headerStripText,
    paymentStatsRef,
    eventDiscountPercentage,
    parkingDiscountPercentage,
    handleBuyMembership,
  } = usePlusMembershipProfile(setEarnedPoints, queryOptions);

  return (
    <>
      <CustomScrollView showsVerticalScrollIndicator={false}>
        <View>
          {/* HEADER */}
          <View>
            <View alignItems="center" mt="4xl" mb="2xl">
              <CachedImage
                source={BlackMemberLogoH}
                style={{ height: 44, width: 286 }}
              />
            </View>
            <View
              mx="2xl"
              py="sm"
              alignItems="center"
              borderWidth="xs"
              borderRadius="sm"
              borderColor="primary.50"
            >
              <Text textAlign="center" size="xs" color="primary.300">
                {headerStripText}
              </Text>
            </View>
          </View>
          {/* Savings Section */}
          {isBlackMember && (
            <View mt="4xl" mb="4xl">
              <BlackMembershipSavingsSection
                statsRef={paymentStatsRef}
                eventDiscount={eventDiscountPercentage}
              />
            </View>
          )}
          {/* Benefits Section */}
          <View mb="4xl" mt={!isBlackMember ? '4xl' : 'none'}>
            <BlackMembershipBenefitsSection
              isBlackMember={isBlackMember}
              eventsDiscount={eventDiscountPercentage}
              parkingDiscount={parkingDiscountPercentage}
            />
          </View>
        </View>
      </CustomScrollView>
      {!isBlackMember && (
        <BMPaymentFooter
          price={membershipPrice}
          duration={membershipDays}
          onBuyMembership={handleBuyMembership}
        />
      )}
    </>
  );
};

export default PlusMembershipProfileScreenInner;
