import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import CampusStaffHomeScreen from '../screens/campusStaff/campustStaffHomeScreen';
import CampusStaffTabBar from './campusStaffTabBar';
import CampusStaffAccountScreen from '../screens/campusStaff/CampusStaffAccountScreen';
import TicketTnCScreen from '../screens/new/TicketTnCScreen';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import About from '../screens/new/About';
import VideoLibrary from '../screens/videoMoments/VideoLibrary';
import OTPInputScreen from '../screens/auth/OTPInputScreen';
import StaffBarcodeScannerScreen from '../screens/user/staffBarcodeScannerScreen';
import TicketScreen from '../screens/user/ticketScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const CampusStaffHomeTab = () => (
  <Tab.Navigator
    // @ts-ignore
    keyboardHidesTabBar="true"
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <CampusStaffTabBar {...props} />}
  >
    <Tab.Screen name="home" component={CampusStaffHomeScreen} />
    <Tab.Screen name="campusStaffAcount" component={CampusStaffAccountScreen} />
  </Tab.Navigator>
);
const CampusStaffNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="campusStaff" component={CampusStaffHomeTab} />
      <Stack.Screen
        name="editProfile"
        options={{ gestureEnabled: false }}
        component={EditProfileScreen}
      />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="VideoLibrary" component={VideoLibrary} />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="phoneVerify" component={OTPInputScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
      <Stack.Screen
        name="StaffBarcodeScannerScreen"
        component={StaffBarcodeScannerScreen}
      />
      <Stack.Screen name="TicketScreen" component={TicketScreen} />
      <Stack.Screen name="ticketTnC" component={TicketTnCScreen} />
    </Stack.Navigator>
  );
};

export default CampusStaffNavigator;
