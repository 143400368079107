/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const findWorkLogFragment = graphql`
  fragment profileCardQueryFragment_findWorkLogFragment_Query on valetWorkLog {
    workLogHistory {
      time
      type
    }
    workSummary {
      tasksDone
    }
  }
`;

export const findUserByIdFragment = graphql`
  fragment profileCardQueryFragment_findUserById_Query on User {
    _id
    name {
      first
      last
    }
    identityCode
    role {
      name
    }
    userSince
    parkedCount
    visitCount
    profileImage
    memberships {
      membershipAddonId
      status
      expiryDate
    }
    wallet {
      moneySaved
      earnedAmount
    }
  }
`;

export const findAddonFragment = graphql`
  fragment profileCardQueryAddonFragment_findAddonQuery on addon
  @relay(plural: true) {
    _id
    type
  }
`;
