/**
 * @generated SignedSource<<e572ff47d193bc690607775c95e6ffca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumUserRolePermission = "staff" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RestaurantPartnerAccountScreenFindUserFragment$data = {
  readonly _id: any;
  readonly name: {
    readonly first: string;
    readonly last: string | null;
  };
  readonly parkedCount: number | null;
  readonly profileImage: string | null;
  readonly restaurants: {
    readonly _id: any;
    readonly displayName: string;
    readonly payBill: {
      readonly pointsEnabled: {
        readonly redeem: boolean | null;
        readonly reward: boolean | null;
      } | null;
    } | null;
  } | null;
  readonly role: {
    readonly name: EnumUserRoleName | null;
    readonly permission: EnumUserRolePermission | null;
  } | null;
  readonly userSince: string | null;
  readonly visitCount: number | null;
  readonly " $fragmentType": "RestaurantPartnerAccountScreenFindUserFragment";
};
export type RestaurantPartnerAccountScreenFindUserFragment$key = {
  readonly " $data"?: RestaurantPartnerAccountScreenFindUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RestaurantPartnerAccountScreenFindUserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RestaurantPartnerAccountScreenFindUserFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserName",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permission",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parkedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2",
      "kind": "LinkedField",
      "name": "restaurants",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2PayBill",
          "kind": "LinkedField",
          "name": "payBill",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "restaurants2PayBillPointsEnabled",
              "kind": "LinkedField",
              "name": "pointsEnabled",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "redeem",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reward",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "49d82a9b3c3865bdf29b34115a33a81d";

export default node;
