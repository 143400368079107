import React, { useState } from 'react';
import { Image } from 'react-native';
import Animated from 'react-native-reanimated';
import { Text, View } from '../../../../components/new';
import { rewardTypeEnums } from './helpers/slotMachineHelpers';
import TextContainerImage from '../../../../../assets/images/TextContainer.png';
import { radii } from '../../../../themes/new/theme';

const MsgBox = ({ showResultHeader, wonRewardType, boxStyles, points }) => {
  let headerText;
  let text = '';

  if (wonRewardType === rewardTypeEnums.freeParking) {
    text = 'A Free Parking Ticket';
  } else if (wonRewardType === rewardTypeEnums.freeBubbleTea) {
    text = 'A Free Bubble Tea';
  } else if (wonRewardType === rewardTypeEnums.freePostCard) {
    text = 'A Free Post Card';
  } else if (wonRewardType === rewardTypeEnums.freeFridgeMagnet) {
    text = 'A Free Fridge Magnet';
  } else if (wonRewardType === rewardTypeEnums.freeKeyChain) {
    text = 'A Free Key Chain';
  } else if (wonRewardType === rewardTypeEnums.freeSticker) {
    text = 'A Free Sticker';
  } else if (wonRewardType === rewardTypeEnums.freePoints) {
    text = points > 1 ? 'Points' : 'Point';
  } else if (wonRewardType === rewardTypeEnums.freeCoffee) {
    text = 'Free Coffee';
  } else if (wonRewardType === rewardTypeEnums.freeDimsum) {
    text = 'Free DimSum';
  } else if (wonRewardType === rewardTypeEnums.freePrint) {
    text = 'Free Print';
  }
  if (wonRewardType && showResultHeader) {
    if (wonRewardType === rewardTypeEnums.freePoints && points === 0) {
      headerText = (
        <Text size="xl" weight="medium" textAlign="center">
          Better Luck Next time
        </Text>
      );
    } else if (wonRewardType) {
      // setHeaderText('keep winning');
      headerText = (
        <View alignItems="center" justifyContent="center">
          <Text size="sm" weight="medium" color="primary.300">
            YOU WON
          </Text>
          <Text
            size="3xl"
            weight="medium"
            color="static.gold"
            style={{ flexDirection: 'row' }}
            textAlign="center"
          >
            {`${
              wonRewardType === rewardTypeEnums.freePoints ? points : ''
            } ${text}`}
          </Text>
        </View>
      );
    }
  } else {
    headerText = (
      <Text size="xl" weight="medium" textAlign="center">
        Spin to win 32ND points and other exciting rewards
      </Text>
    );
  }
  const [layout, setLayout] = useState(null);

  return (
    <View
      textAlign="center"
      alignItems="center"
      bg="transparent"
      height={110}
      overflow="hidden"
      borderRadius="md"
      width={layout?.width}
      onLayout={(e) => {
        if (e?.nativeEvent?.layout) {
          if (e.nativeEvent.layout.width === 0) {
            e.nativeEvent.layout.width = layout?.width;
          }
          setLayout(e.nativeEvent.layout);
        }
      }}
    >
      {/* back hidden */}
      <Animated.View
        style={[
          {
            height: 110,
            width: layout?.width,
            borderWidth: 1,
            borderRadius: radii.md,
            position: 'absolute',
            backgroundColor: 'transparent',
            zIndex: 10,
          },
          boxStyles,
        ]}
      />

      <View position="absolute" height={110} width={layout?.width} zIndex={1}>
        <Image
          source={TextContainerImage}
          style={{ height: 110, width: layout?.width }}
          resizeMode="cover"
        />
      </View>
      {/* bubble animation : text background */}

      {/* foreground text */}
      {showResultHeader && (
        <View position="absolute" zIndex={2}>
          <View
            px="2xl"
            py="4xl"
            height={110}
            alignItems="center"
            justifyContent="center"
          >
            {headerText}
          </View>
        </View>
      )}
    </View>
  );
};

export default MsgBox;
