/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const reservationScreenQuery = graphql`
  query reservationScreenQuery(
    $filter: findEventInput!
    $addonsFilter: FilterFindManyaddonInput
    $isMember: Boolean!
  ) {
    findEvents(filter: $filter) {
      _id
      campus {
        _id
      }
      title
      description
      venue
      media {
        cover {
          mediaURL
          mediaType
          cover
          displayOrder
        }
        past {
          mediaURL
          mediaType
          cover
          displayOrder
        }
        testimonials {
          mediaURL
          mediaType
          cover
          displayOrder
        }
      }
      mediaCoverages {
        imageUrl
        hyperLink
      }
      faqs {
        question
        answer
      }
      host {
        name
        description
        media {
          mediaURL
          mediaType
          cover
          displayOrder
        }
      }
      motivation {
        title
        logo
      }
      timeline {
        title
        logo
        duration
        media {
          mediaURL
          mediaType
          cover
          displayOrder
        }
      }
      eventTiming {
        _id
        date
        slot {
          startTime
          endTime
          slotSpace
          slotsLeft
          slotTag
          slotTagColor
          availableTickets {
            name
            price
            basePrice
            personAllowed
            isActive
            slotsLeft
            _id
          }
          _id
        }
      }
    }
    findAddons(filter: $addonsFilter) @include(if: $isMember) {
      _id
      type
      additionalCashback {
        refType
        cashback {
          value
          type
        }
      }
      additionalDiscount {
        refType
        discount {
          value
          type
        }
      }
    }
  }
`;
