import React from 'react';
import { Button, Icon, Text, View } from '../../../components/new';

const EventsInfoTile = ({
  text = '',
  icon = '',
  isRightBtn = '',
  iconColor = 'primary.300',
  rightBtnIcon = '',
  rightHandler = () => {},
}) => {
  return (
    <View
      flexDirection="row"
      alignItems="center"
      mb="2xl"
      justifyContent="space-between"
    >
      <View flexDirection="row" alignItems="center">
        {!!icon && <Icon name={icon} color={iconColor} size="2xl" />}
        <Text size="sm" color="primary.400" pl="sm">
          {text}
        </Text>
      </View>
      {!!isRightBtn && (
        <Button
          appearance="outline"
          status="primary"
          size="md"
          onPress={rightHandler}
          iconRight={rightBtnIcon}
        >
          {isRightBtn}
        </Button>
      )}
    </View>
  );
};

export default EventsInfoTile;
