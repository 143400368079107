/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import uuid from 'react-native-uuid';
import { Button, Text, View } from '../../components/new';
import photoMoments from '../../screens/Strings';
import VideoPlayer from '../../components/new/custom/VideoPlayer';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

const ConfirmVideo = ({ videoPath = null, saveVideo, discardRecord }) => {
  const { height, width } = useWindowDimensions();
  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const uploadVideo = async () => {
    setLoading(true);
    const bucketName = 'video-moments-inputs';
    const fileName = `${String(uuid.v4())}.mp4`;
    const uri = videoPath?.path;

    firebaseEventLogger('videoMoments__saveVideo_Tap', {
      buttonName: 'saveVideo',
      screenName: 'videoMoments',
      userType: 'VideoSupervisor',
      interactionType: 'tap',
      videoId: fileName,
    });

    await saveVideo({ bucketName, fileName, uri });
  };

  return (
    <View
      bg="background.primary.base"
      flex={1}
      p="2xl"
      pt="xl"
      mt="9xl+9xl+3xl"
    >
      <Text weight="medium" size="2xl" color="primary.500">
        {photoMoments.recorded}
      </Text>
      <Text size="md" color="primary.300">
        {photoMoments.confirmVideo}
      </Text>
      <View mt="2xl">
        <VideoPlayer
          id={`Video_Moments${String(uuid.v4())}`}
          url={videoPath?.path}
          play={play}
          setPlay={setPlay}
          aspectRatio={1}
          showFullScreen
          height={height * 0.55}
          width={width - 32}
        />
      </View>
      <View mt="2xl" position="absolute" bottom="2xl" width="100%" mx="2xl">
        <Button
          size="lg"
          appearance="filled"
          status="primary"
          state="active"
          loading={loading}
          onPress={uploadVideo}
        >
          {photoMoments.saveUpload}
        </Button>
        <Button
          size="lg"
          appearance="outline"
          status="primary"
          state="active"
          mt="2xl"
          onPress={discardRecord}
        >
          {photoMoments.discardRecord}
        </Button>
      </View>
    </View>
  );
};
export default ConfirmVideo;
