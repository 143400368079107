import React from 'react';
import { Text, View } from '../../../components/new';
import PaymentsImage from '../../../../assets/images/payments.webp';
import {
  transactionTypeEnum,
  walletTransactionEnum,
} from '../../../utilities/helper';
import CachedImage from '../../../components/new/custom/CachedImage';

const AmountSavedComp = ({
  text = '',
  isParking = false,
  discountAmount = 0,
  pointsData = [],
  rewardPoints = [],
}) => {
  let totalEarned = 0;
  let earnedPoints = 0;

  if (pointsData?.length) {
    const finalData = pointsData?.filter(
      (item) =>
        item?.source?.type === 'PARK_DELAY' ||
        item?.source?.type === 'CAMPUS_VISIT',
    );
    // eslint-disable-next-line no-return-assign
    finalData.map((item) => (totalEarned += item?.amount));
  }

  if (rewardPoints?.length) {
    rewardPoints?.forEach((j) => {
      if (
        (j?.source?.type === walletTransactionEnum.firstTxn ||
          j?.source?.type === walletTransactionEnum.subsequentTxn) &&
        j?.type === transactionTypeEnum.credit
      ) {
        earnedPoints += j?.amount;
      }
    });
  }

  return (
    <View>
      <View
        bg="success.10"
        p="lg"
        alignItems="center"
        borderRadius={isParking ? 0 : 'md'}
      >
        <View flexDirection="row" style={{ alignItems: 'center' }}>
          {!isParking ? (
            <View justifyContent="center">
              <Text size="sm" color="success.500">{`${text}  🎉`}</Text>
            </View>
          ) : (
            <>
              <View justifyContent="center">
                <Text size="sm" color="success.500">
                  You earned {totalEarned + earnedPoints}
                </Text>
              </View>
              <View ml="sm">
                <CachedImage
                  source={PaymentsImage}
                  style={{ height: 24, width: 24 }}
                />
              </View>
              <View justifyContent="center">
                <Text size="sm" color="success.500">
                  {' '}
                  & saved ₹{`${discountAmount} 🎉`}
                </Text>
              </View>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

export default AmountSavedComp;
