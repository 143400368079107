import React from 'react';
import { ThemeProvider } from 'styled-components/native';
import { getTheme, themeModes } from '../../../themes/new/theme';

const DarkThemeWrapper = ({ children }) => {
  const newTheme = getTheme(themeModes.dark);

  return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>;
};

export default DarkThemeWrapper;
