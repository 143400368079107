/**
 * @generated SignedSource<<3d1f967af3f5a622cec8a123974928a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type parkingScreenQueryFindTaskFragment$data = {
  readonly findTasks: ReadonlyArray<{
    readonly _id: any;
    readonly assignedTo: any | null;
    readonly campus: {
      readonly _id: any;
      readonly name: string;
    } | null;
    readonly history: ReadonlyArray<{
      readonly _id: any | null;
      readonly action: EnumtaskHistoryAction;
      readonly actor: any | null;
      readonly event_at: any | null;
    } | null>;
    readonly ownerId: any | null;
    readonly parkingETA: {
      readonly parkingTimePrediction: ReadonlyArray<{
        readonly acceptedToCompleteTime: any | null;
        readonly locationId: any | null;
      } | null> | null;
    } | null;
    readonly parkingId: any | null;
    readonly parkingTag: string | null;
    readonly taskEndLocation: {
      readonly locationId: any;
      readonly name: string | null;
      readonly parkingSpaces: {
        readonly floor: {
          readonly bay: {
            readonly bayId: any;
            readonly bayName: string | null;
          } | null;
          readonly floorId: any;
          readonly floorName: string | null;
        } | null;
      } | null;
    } | null;
    readonly taskStartLocation: {
      readonly locationId: any;
      readonly name: string | null;
      readonly parkingSpaces: {
        readonly floor: {
          readonly bay: {
            readonly bayId: any;
            readonly bayName: string | null;
          } | null;
          readonly floorId: any;
          readonly floorName: string | null;
        } | null;
      } | null;
    } | null;
    readonly taskType: EnumtaskTaskType;
    readonly user: {
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
    readonly valet: {
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
      readonly identityCode: string | null;
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
    readonly vehicle: {
      readonly brand: {
        readonly brandLogo: string | null;
        readonly name: string | null;
      } | null;
      readonly color: string | null;
      readonly registration: {
        readonly plate: string;
      } | null;
    } | null;
    readonly vehicleId: any | null;
  }>;
  readonly " $fragmentType": "parkingScreenQueryFindTaskFragment";
};
export type parkingScreenQueryFindTaskFragment$key = {
  readonly " $data"?: parkingScreenQueryFindTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"parkingScreenQueryFindTaskFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserContactPhone",
      "kind": "LinkedField",
      "name": "phone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./parkingScreenFindTasksQuery.graphql')
    }
  },
  "name": "parkingScreenQueryFindTaskFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "task",
      "kind": "LinkedField",
      "name": "findTasks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "taskVehicle",
          "kind": "LinkedField",
          "name": "vehicle",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "taskVehicleBrand",
              "kind": "LinkedField",
              "name": "brand",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "brandLogo",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "taskVehicleRegistration",
              "kind": "LinkedField",
              "name": "registration",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "plate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taskType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "taskTaskStartLocation",
          "kind": "LinkedField",
          "name": "taskStartLocation",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "taskTaskStartLocationParkingSpaces",
              "kind": "LinkedField",
              "name": "parkingSpaces",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                  "kind": "LinkedField",
                  "name": "floor",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                      "kind": "LinkedField",
                      "name": "bay",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "taskTaskEndLocation",
          "kind": "LinkedField",
          "name": "taskEndLocation",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "taskTaskEndLocationParkingSpaces",
              "kind": "LinkedField",
              "name": "parkingSpaces",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                  "kind": "LinkedField",
                  "name": "floor",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                      "kind": "LinkedField",
                      "name": "bay",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "campusLocation",
          "kind": "LinkedField",
          "name": "campus",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "taskHistory",
          "kind": "LinkedField",
          "name": "history",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "action",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "event_at",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignedTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "valet",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "identityCode",
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vehicleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parkingId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parkingTag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "taskParkingETA",
          "kind": "LinkedField",
          "name": "parkingETA",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "taskParkingETAParkingTimePrediction",
              "kind": "LinkedField",
              "name": "parkingTimePrediction",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "acceptedToCompleteTime",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8a97ba689f7b37ab5137696cf8be7575";

export default node;
