import React, { useEffect } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  createStackNavigator,
  CardStyleInterpolators,
} from '@react-navigation/stack';
import { Easing, Platform } from 'react-native';
import RestaurantPartnerTabBar from './restaurantPartnerBottomTabBar';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import RestaurantPartnerAccountScreen from '../screens/restaurantPartner/RestaurantPartnerAccountScreen';
import CongratulationScreen from '../screens/restaurantPartner/loyalty/CongratulationScreen';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import FaqScreen from '../screens/new/faq/FaqScreen';
import CollectionsScreen from '../screens/restaurantPartner/screens/CollectionsScreen';
import RevenueOverviewScreen from '../screens/restaurantPartner/screens/RevenueOverviewScreen';
import TransactionsScreen from '../screens/restaurantPartner/screens/TransactionsScreen';
import LoyaltyScreen from '../screens/restaurantPartner/loyalty/Loyalty';
import EarningsScreen from '../screens/restaurantPartner/screens/EarningsScreen';
import EarningsOverviewScreen from '../screens/restaurantPartner/screens/EarningsOverviewScreen';
import PayoutOverviewScreen from '../screens/restaurantPartner/screens/PayoutOverviewScreen';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import StaffBarcodeScannerScreen from '../screens/user/staffBarcodeScannerScreen';
import About from '../screens/new/About';
import TicketScreen from '../screens/user/ticketScreen';
import CustomWebview from '../screens/new/CustomWebview';
import DiscountScreen from '../screens/restaurantPartner/discounts/Discount';
import TrackDiscountScreen from '../screens/restaurantPartner/trackDiscount/TrackDiscountScreen';
import CreateDiscountScreen from '../screens/restaurantPartner/discounts/CreateDiscountScreen';
import TransactionSearchScreen from '../screens/restaurantPartner/screens/TransactionSearchScreen';
import useUserStore, { UserRole } from '../stores/userStore';
import RestaurantConfigScreen from '../screens/restaurantPartner/screens/RestaurantConfigScreen';
import RequestChangesFormScreen from '../screens/restaurantPartner/screens/RequestChangesFormScreen';
import RestaurantListing from '../screens/new/resturants/restaurantListing';

const Stack = createStackNavigator();
const { Navigator, Screen } = createBottomTabNavigator();

const RestaurantPartnerHomeTabBar = () => {
  const { role } = useUserStore((state) => state);
  return (
    <Navigator
      // @ts-expect-error TS(2769): No overload matches this call.
      keyboardHidesTabBar="true"
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <RestaurantPartnerTabBar {...props} />}
    >
      <Screen name="home" component={TransactionsScreen} />
      {role === UserRole.RESTAURANT_PARTNER_STAFF ? (
        <></>
      ) : (
        <>
          <Screen name="collections" component={CollectionsScreen} />
          <Screen name="earnings" component={EarningsScreen} />
        </>
      )}
      <Screen name="club" component={LoyaltyScreen} />
      <Screen name="account" component={RestaurantPartnerAccountScreen} />
    </Navigator>
  );
};

const RestaurantPartnerBottomTabNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="restaurantPartner"
        component={RestaurantPartnerHomeTabBar}
      />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen
        name="editProfile"
        options={{ gestureEnabled: false }}
        component={EditProfileScreen}
      />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
      <Stack.Screen
        name="StaffBarcodeScannerScreen"
        component={StaffBarcodeScannerScreen}
      />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="faq" component={FaqScreen} />
      <Stack.Screen name="revenueOverview" component={RevenueOverviewScreen} />
      <Stack.Screen
        name="earningsOverview"
        component={EarningsOverviewScreen}
      />
      <Stack.Screen name="payoutOverview" component={PayoutOverviewScreen} />
      <Stack.Screen
        name="congratulationScreen"
        component={CongratulationScreen}
      />
      <Stack.Screen name="TicketScreen" component={TicketScreen} />
      <Stack.Screen name="Discounts" component={DiscountScreen} />
      <Stack.Screen name="TrackDiscount" component={TrackDiscountScreen} />
      <Stack.Screen name="CreateDiscount" component={CreateDiscountScreen} />
      <Stack.Screen name="RestaurantPreview" component={RestaurantListing} />
      <Stack.Screen
        name="RequestRestaurantChanges"
        component={RequestChangesFormScreen}
      />
      <Stack.Screen
        name="RestaurantConfig"
        component={RestaurantConfigScreen}
      />
      <Stack.Screen
        name="CustomWebview"
        component={CustomWebview}
        options={{
          animationTypeForReplace: 'push',
          animationEnabled: true,
          transitionSpec: {
            open: {
              animation: 'timing',
              config: {
                duration: 300,
                easing: Easing.inOut(Easing.ease),
              },
            },
            close: {
              animation: 'timing',
              config: {
                duration: 100,
                easing: Easing.inOut(Easing.ease),
              },
            },
          },
          cardStyleInterpolator:
            Platform.OS === 'android'
              ? CardStyleInterpolators.forRevealFromBottomAndroid
              : CardStyleInterpolators.forVerticalIOS,
        }}
      />
      <Stack.Screen
        name="transactionSearch"
        component={TransactionSearchScreen}
      />
    </Stack.Navigator>
  );
};

export default RestaurantPartnerBottomTabNavigator;
