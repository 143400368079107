import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  Fade,
} from 'rn-placeholder';
import { View, Icon } from '../../../../components/new';
// import Icon from "../../../../components/new/primitive/Icon/Icon";

const UserAccountProfileBannerPlacholder = () => {
  return (
    <View bg="white" mt="4xl" shadow="sm" height="156" borderRadius="md">
      <View shadow="sm" style={styles.avatar}>
        <PlaceholderMedia isRound size={100} />
      </View>
      <View flexDirection="column" alignItems="center" mt="79">
        <PlaceholderLine height={22} width={50} style={{ margin: 0 }} />
        <PlaceholderLine
          height={14}
          width={35}
          style={{ margin: 0, marginTop: Platform.OS === 'web' ? 16 : -6 }}
        />
      </View>
    </View>
  );
};

const UserAccountListItemPlaceholder = () => {
  return (
    <View
      py="3xl"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      borderBottomWidth="1"
      borderBottomColor="primary.50"
    >
      <PlaceholderLine
        height={14}
        width={50}
        style={{ margin: 0, marginBottom: 0 }}
      />
      <Icon name="forward-outline-300" color="primary.50" size="lg" />
    </View>
  );
};

const UserAccountScreenPlaceholder = () => {
  return (
    <Placeholder Animation={Fade}>
      <View px="2xl" style={{ paddingTop: 80 }}>
        <UserAccountProfileBannerPlacholder />
        <View mt="4xl">
          <UserAccountListItemPlaceholder />
          <UserAccountListItemPlaceholder />
          <UserAccountListItemPlaceholder />
          <UserAccountListItemPlaceholder />
          <UserAccountListItemPlaceholder />
        </View>
      </View>
    </Placeholder>
  );
};

export default UserAccountScreenPlaceholder;

const styles = StyleSheet.create({
  avatar: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 100,
    top: -50,
    left: '50%',
    transform: [{ translateX: -50 }],
  },
});
