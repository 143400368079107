/**
 * @generated SignedSource<<60f4bf65a463f71e9a8b6c004ab819c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnuminvoiceOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnuminvoicePaymentType = "BILL_UPLOAD" | "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnuminvoicePopUpStoreDetailsDeliveryStatus = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type auditorDayWiseCollectionQuery__paymentHistoryFragment$data = ReadonlyArray<{
  readonly _id: any;
  readonly created_at: any | null;
  readonly orderId: any;
  readonly orderType: EnuminvoiceOrderType;
  readonly paymentDetails: {
    readonly paytmQr: {
      readonly transactionId: string | null;
    } | null;
    readonly razorpay: {
      readonly pgOrderId: string;
    } | null;
  } | null;
  readonly paymentType: EnuminvoicePaymentType | null;
  readonly popUpStoreDetails: {
    readonly deliveryStatus: EnuminvoicePopUpStoreDetailsDeliveryStatus | null;
    readonly displayName: string;
  } | null;
  readonly priceDetails: {
    readonly grossAmount: number;
  };
  readonly sourceDetails: {
    readonly popUpStore: {
      readonly owner: {
        readonly name: {
          readonly first: string;
          readonly last: string | null;
        };
      } | null;
    } | null;
  } | null;
  readonly status: EnuminvoiceStatus | null;
  readonly user: {
    readonly _id: any;
    readonly contact: {
      readonly phone: {
        readonly number: string;
        readonly prefix: string;
      };
    };
  } | null;
  readonly " $fragmentType": "auditorDayWiseCollectionQuery__paymentHistoryFragment";
}>;
export type auditorDayWiseCollectionQuery__paymentHistoryFragment$key = ReadonlyArray<{
  readonly " $data"?: auditorDayWiseCollectionQuery__paymentHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"auditorDayWiseCollectionQuery__paymentHistoryFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "auditorDayWiseCollectionQuery__paymentHistoryFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "invoicePriceDetails",
      "kind": "LinkedField",
      "name": "priceDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserContact",
          "kind": "LinkedField",
          "name": "contact",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserContactPhone",
              "kind": "LinkedField",
              "name": "phone",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "prefix",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "invoicePopUpStoreDetails",
      "kind": "LinkedField",
      "name": "popUpStoreDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceSourceDetailsTC",
      "kind": "LinkedField",
      "name": "sourceDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "popUpStore",
          "kind": "LinkedField",
          "name": "popUpStore",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "owner",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserName",
                  "kind": "LinkedField",
                  "name": "name",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "first",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "last",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiePaymentDetailsTC",
      "kind": "LinkedField",
      "name": "paymentDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "payment",
          "kind": "LinkedField",
          "name": "razorpay",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pgOrderId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaytmDqrOrders",
          "kind": "LinkedField",
          "name": "paytmQr",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transactionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "invoice",
  "abstractKey": null
};
})();

(node as any).hash = "40aa84ed2c58fe2094b57df8a50fc376";

export default node;
