/**
 * @generated SignedSource<<534408764b53002dd96df66067103c39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type paymentAmountScreenQueryUpdatePaymentMutation$variables = {
  pgOrderId: string;
  pgPaymentId?: string | null;
  pgSignature?: string | null;
};
export type paymentAmountScreenQueryUpdatePaymentMutation$data = {
  readonly updatePayment: {
    readonly _id: any;
    readonly created_at: any | null;
    readonly displayName: string;
    readonly orderId: any;
    readonly priceDetails: {
      readonly _id: any | null;
      readonly discountGiven: number;
      readonly grossAmount: number;
      readonly netAmount: number;
      readonly rewardsUsed: number;
    };
    readonly status: EnuminvoiceStatus | null;
    readonly userId: any | null;
  } | null;
};
export type paymentAmountScreenQueryUpdatePaymentMutation = {
  response: paymentAmountScreenQueryUpdatePaymentMutation$data;
  variables: paymentAmountScreenQueryUpdatePaymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pgOrderId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pgPaymentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pgSignature"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pgOrderId",
        "variableName": "pgOrderId"
      },
      {
        "kind": "Variable",
        "name": "pgPaymentId",
        "variableName": "pgPaymentId"
      },
      {
        "kind": "Variable",
        "name": "pgSignature",
        "variableName": "pgSignature"
      }
    ],
    "concreteType": "invoice",
    "kind": "LinkedField",
    "name": "updatePayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "invoicePriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountGiven",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardsUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netAmount",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentAmountScreenQueryUpdatePaymentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "paymentAmountScreenQueryUpdatePaymentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a21a042ac60a72443bb2285aa2b6fb09",
    "id": null,
    "metadata": {},
    "name": "paymentAmountScreenQueryUpdatePaymentMutation",
    "operationKind": "mutation",
    "text": "mutation paymentAmountScreenQueryUpdatePaymentMutation(\n  $pgOrderId: ID!\n  $pgPaymentId: ID\n  $pgSignature: String\n) {\n  updatePayment(pgOrderId: $pgOrderId, pgPaymentId: $pgPaymentId, pgSignature: $pgSignature) {\n    userId\n    displayName\n    priceDetails {\n      grossAmount\n      discountGiven\n      rewardsUsed\n      netAmount\n      _id\n    }\n    status\n    _id\n    created_at\n    orderId\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ebf0f3e91cd4a64b56ca23739b59497";

export default node;
