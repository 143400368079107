import React from 'react';
import { Text } from '../..';
import BonusListItem from '../bonus/BonusListItem';
import Badge from '../../primitive/Badge/Badge';
import { PaymentStatusEnum } from '../../../../utilities/helper';

type PaymentStatusType = keyof typeof PaymentStatusEnum;

interface TransactionSummaryListItemProps {
  paymentStatus: PaymentStatusType;
  date: string;
  amount: string;
  label: string;
}

const TransactionSummaryListItem = ({
  paymentStatus,
  date,
  amount,
  label,
}: TransactionSummaryListItemProps) => {
  return (
    <BonusListItem
      contentPrimary={date}
      contentSecondary={label}
      sizePri="sm"
      RightContent={
        <Badge status={resolveBadgeStatus(paymentStatus)}>
          <Text color="primary.500" size="sm" weight="regular">
            {amount}
          </Text>
        </Badge>
      }
    />
  );
};
const resolveBadgeStatus = (paymentStatus: PaymentStatusType) => {
  switch (paymentStatus) {
    case PaymentStatusEnum.SUCCESSFUL:
      return 'success';
    case PaymentStatusEnum.FAILED:
      return 'error';
    case PaymentStatusEnum.PENDING:
      return 'warning';
    case PaymentStatusEnum.REFUNDED:
      return 'default';
    default:
      return 'default';
  }
};

export default TransactionSummaryListItem;
