/* eslint-disable no-unsafe-optional-chaining */
import React, { useCallback } from 'react';
import moment from 'moment';
import { FlatList } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import View from '../../components/new/basic/View/View';
import Text from '../../components/new/primitive/Typography/Text/Text';

import BonusOverviewBanner from '../../components/new/custom/bonus/BonusOverviewBanner';
import BonusInfoBottomSheet from '../../components/new/custom/bonus/BonusInfoBottomSheet';
import BonusIncentiveInfoBox from '../../components/new/custom/bonus/BonusIncentiveInfoBox';
import BonusConversionInfo from '../../components/new/custom/bonus/BonusConversionInfo';
import useBonusInfoBottomSheet, {
  ContentVariant,
} from '../../components/new/custom/bonus/BonusInfoBottomSheet/useBonusInfoBottomSheet';
import BonusListItem from '../../components/new/custom/bonus/BonusListItem';
import BonusBadge from '../../components/new/custom/bonus/BonusBadge';
import NoContentView from '../../components/new/custom/bonus/NoContentView';
import GainLossIcon from '../../components/new/custom/bonus/GainLossIcon';
import {
  secToAbsoluteMinutes,
  secToHumanTime,
} from '../../utilities/dateUtilities';
import { toRupees } from '../../utilities/bonus';
import useUserStore from '../../stores/userStore';
import { navigateBack } from '../../utilities/helper';

type BonusTaskBreakdownScreenProps = {
  date: string;
  mode: number;
  queryOptions: any;
  useBusinessLogic: (
    date: string,
    queryOptions: any,
  ) => {
    onTimeBonus: number;
    delayPenalty: number;
    noDailyRewards: boolean;
    dailyRewards: Array<Record<string, any>>;
    onTimeTaskCount: number;
    delayedTaskCount: number;
    bonusAmountTotal: number | string;
  };
};

const BonusTaskBreakdownScreen = ({
  date,
  mode,
  queryOptions,
  useBusinessLogic,
}: BonusTaskBreakdownScreenProps) => {
  const navigation = useNavigation();
  const userRole = useUserStore((state) => state.role);

  const {
    onTimeBonus,
    delayPenalty,
    dailyRewards,
    onTimeTaskCount,
    delayedTaskCount,
    bonusAmountTotal,
  } = useBusinessLogic(date, queryOptions);

  const {
    ref: bonusInfoSheetRef,
    currentState,
    taskCount,
    dismiss,
    showBonusInfo,
    showOnTimeTaskInfo,
    showDelayedTaskInfo,
  } = useBonusInfoBottomSheet();

  // eslint-disable-next-line consistent-return
  const getBottomSheetContent = useCallback(
    (contentStateVariant: ContentVariant) => {
      // eslint-disable-next-line default-case
      switch (contentStateVariant) {
        case ContentVariant.BonusIncentiveInfo:
          return (
            <BonusIncentiveInfoBox
              onTimeBonus={toRupees(onTimeBonus)}
              delayPenalty={toRupees(delayPenalty)}
            />
          );
        case ContentVariant.OnTimeBonusInfo:
        case ContentVariant.DelayedBonusInfo:
          return (
            <BonusConversionInfo
              tasks={
                contentStateVariant === ContentVariant.OnTimeBonusInfo
                  ? onTimeTaskCount
                  : delayedTaskCount
              }
              onTime={contentStateVariant === ContentVariant.OnTimeBonusInfo}
              onTimeBonus={onTimeBonus}
              delayPenalty={delayPenalty}
            />
          );

        default:
          return <></>;
      }
    },
    [onTimeBonus, delayPenalty, onTimeTaskCount, delayedTaskCount],
  );

  return (
    <View flex={1}>
      <BonusOverviewBanner
        mode={mode}
        date={moment(date).format('ddd, Do MMM YYYY')}
        bonusAmount={`₹${bonusAmountTotal}`}
        onTimeTasks={onTimeTaskCount}
        delayedTasks={delayedTaskCount}
        navigateBack={() => navigateBack(navigation, userRole)}
        showDelayedDetails={() => showDelayedTaskInfo(delayedTaskCount)}
        showInfo={showBonusInfo}
        showOnTimeDetails={() => showOnTimeTaskInfo(onTimeTaskCount)}
      />
      <View flex={1} mt="4xl">
        <FlatList
          data={dailyRewards}
          renderItem={BonusTaskListItem}
          ListEmptyComponent={ListEmptyContent}
        />
      </View>

      <BonusInfoBottomSheet
        ref={bonusInfoSheetRef}
        onDismiss={dismiss}
        Content={getBottomSheetContent(currentState)}
        insets={false}
      />
    </View>
  );
};

const BonusTaskListItem = ({ item: reward }: any) => {
  const { taskStatus, delayedBy, taskDate } = reward?.task;
  const bonusAmountSgl = reward?.value ?? 0;
  const isOnTime = taskStatus === 'ON_TIME';

  return (
    <View key={taskDate + delayedBy} pb="2xl" px="2xl">
      <BonusListItem
        contentPrimary={reward?.label}
        contentSecondary={
          <View
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Text size="xs" weight="medium" color="primary.100">{`${moment(
              taskDate,
            ).format('hh:mma')} | `}</Text>
            <Text
              size="xs"
              weight="medium"
              color={isOnTime ? 'success.500' : 'error.500'}
            >
              {isOnTime
                ? 'On-Time'
                : `Delayed By ${secToHumanTime(
                    secToAbsoluteMinutes(delayedBy, 1) * 60,
                    'm',
                  )}`}
            </Text>
          </View>
        }
        sizePri="sm"
        RightContent={
          <BonusBadge variant={isOnTime ? 'gain' : 'loss'}>
            <GainLossIcon isGain={isOnTime} />
            <Text color="primary.500" size="sm" weight="regular">
              {`₹${bonusAmountSgl}`}
            </Text>
          </BonusBadge>
        }
      />
    </View>
  );
};

const ListEmptyContent = () => (
  <View>
    <NoContentView />
    <Text textAlign="center" color="primary.100">
      No Rewarded tasks
    </Text>
  </View>
);
export default BonusTaskBreakdownScreen;
