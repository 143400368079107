import moment from 'moment';
/* eslint-disable import/prefer-default-export, no-plusplus */
/**
 * @description Return an array of N consecutive previous dates from `selectedDate` including `selectedDate`, where N is `noOfDays`
 * @param {Date} selectedDate
 * @param {number} noOfDays
 * @returns {Date[]}
 */
export const getPreviousDates = (
  selectedDate: Date,
  noOfDays: number,
): Date[] => {
  const temp = new Date(selectedDate);
  const result: Date[] = [];

  for (let i = 0; i < noOfDays; i++) {
    const onePrevious = temp.setDate(temp.getDate() - 1);
    result.push(new Date(onePrevious));
  }
  const finalResult = result.reverse();
  finalResult.push(selectedDate); // also adding the selected date in last
  return finalResult;
};

export const secToTime = (seconds: number): string => {
  const duration = moment.duration(seconds, 'seconds');
  let h = duration.hours().toString();
  let m = duration.minutes().toString();
  let s = duration.seconds().toString();

  // adding leading zero for standard time formating
  h = h.length === 1 ? `0${h}` : h;
  m = m.length === 1 ? `0${m}` : m;
  s = s.length === 1 ? `0${s}` : s;

  let timeStr: string;

  if (parseInt(h, 10) > 0) {
    timeStr = `${h}:${m}:${s}`;
  } else {
    // removing hours slot if zero
    timeStr = `${m}:${s}`;
  }
  return timeStr;
};

export const secToHumanTime = (
  seconds: number,
  granularity: 'h' | 'm' | 's' = 's',
): string => {
  const duration = moment.duration(seconds, 'seconds');
  const h = Number(duration.hours().toString());
  const m = Number(duration.minutes().toString());
  const s = Number(duration.seconds().toString());

  // adding leading zero for standard time formating
  let timeStr = '';

  if (h > 0) {
    timeStr = `${h}h `;
    if (granularity === 'h') return timeStr.trim();
  }
  if (m > 0) {
    timeStr = `${timeStr}${m}m `;
    if (granularity === 'm') return timeStr.trim();
  }
  timeStr = `${timeStr}${s}s`;
  if (h === 0 && m === 0 && s === 0) timeStr = '0s';
  return timeStr;
};

/**
 *
 * @param {Number} seconds
 * @param {Number} threshold - Threshold is for deciding upperbound or lowerbound minute value. 0 < threshold < 60
 * @returns {Number} - Minutes value
 */
export const secToAbsoluteMinutes = (
  seconds: number,
  threshold = 30,
): number => {
  if (seconds < 0) throw new Error('Seconds must be greater than 0');
  if (threshold <= 0 || threshold >= 60)
    throw new Error('Threshold must be greater than 0 and less than 60');
  seconds = Number(seconds.toFixed());
  const secDiff = seconds % 60;
  if (secDiff >= threshold) {
    return Math.ceil(seconds / 60);
  }
  return Math.floor(seconds / 60);
};
