import React, { PropsWithChildren } from 'react';
import { ImageBackground } from 'react-native';
import { getTheme } from '../../../themes/new/theme';
import View from '../basic/View/View';

const MESH_BG = require('../../../../assets/images/black_membership/mesh-bg-h.png');
const MESH_BG_LIGHT = require('../../../../assets/images/black_membership/mesh-bg-light.webp');

const theme = getTheme();

type BgVariant = 'mesh_light' | 'mesh_dark';

interface BlackMembershipCardProps {
  borderRadius?: number;
  borderColor?: string;
  bgVariant?: BgVariant;
  shadow?: 'none' | 'sm' | 'md' | 'lg';
}

const DB_RD = theme.radii.lg; // default border radius
const DB_CL = 'gold.500'; // default border color

const resolveBgImage = (variant: BgVariant) => {
  switch (variant) {
    case 'mesh_dark': {
      return MESH_BG;
    }
    case 'mesh_light': {
      return MESH_BG_LIGHT;
    }
    default:
      return MESH_BG;
  }
};

const BlackMembershipCard = (
  props: PropsWithChildren<BlackMembershipCardProps>,
) => {
  const {
    shadow = 'none',
    borderRadius = DB_RD,
    borderColor = DB_CL,
    bgVariant = 'mesh_dark',
    children,
  } = props;
  const bgImg = resolveBgImage(bgVariant);

  return (
    <View
      shadow={shadow}
      borderRadius={borderRadius}
      minHeight={40}
      bg={bgVariant === 'mesh_light' ? 'white' : 'black'}
      borderWidth="xs"
      borderColor={borderColor}
      style={{
        flex: 1,
      }}
      overflow="hidden"
    >
      <ImageBackground
        source={bgImg}
        style={{ width: '100%', height: '100%' }}
        resizeMode="cover"
      >
        {children}
      </ImageBackground>
    </View>
  );
};

export default BlackMembershipCard;
