import React from 'react';
import styled from 'styled-components/native';
import { variant } from 'styled-system';
import { Image } from 'react-native';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import { imageTransformation } from '../../../../utilities/helper';
import useAuthStore from '../../../../stores/authStore';
import { ThemeVariables } from '../../../../themes/new/helper';

// export const variantsKey = { 1: 1, 2: 2, 3: 3 };
export const variantsKey = {
  0: {
    width: 32,
    height: 32,
  },
  1: {
    width: 40,
    height: 40,
  },
  2: {
    width: 60,
    height: 60,
  },
  3: {
    width: 100,
    height: 100,
  },
  4: {
    width: 72,
    height: 72,
  },
};

const sizeVariants = (props) => {
  return variant({
    prop: ThemeVariables.LEVEL,
    variants: {
      0: variantsKey[0],
      1: variantsKey[1],
      2: variantsKey[2],
      3: variantsKey[3],
      4: variantsKey[4],
    },
  });
};

const StyledView = styled(View)`
  borderradius: 80 ${(props) => sizeVariants(props)};
`;

const StyledTextView = styled(View)`
  border-radius: 999;
  align-items: center;
  justify-content: center;
  ${(props) => sizeVariants(props)};
`;

const StyledImage = styled(Image)`
borderWidth:1
borderColor:${(props) => props.theme.colors.primary[50]}
borderRadius: 80
  ${(props) => sizeVariants(props)}
`;

const getInitials = (text) => {
  const fullName = text.trim().split(' ');
  let initials = fullName[0].charAt(0);
  if (fullName.length > 1) {
    initials += fullName[fullName.length - 1].charAt(0);
  } else {
    initials += fullName[0].charAt(1);
  }
  return initials.toUpperCase();
};

const Avatar = ({ level = 1, resizeMode = 'contain', name, ...props }) => {
  let { source } = props;
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  if (!isLoggedIn && !source) {
    source = 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
  } else if (!name && !source) {
    source = 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
  }

  return (
    <>
      {!source && name ? (
        <StyledTextView
          level={level}
          bg="background.secondary.base"
          borderWidth="xs"
          borderColor="primary.50"
        >
          <Text size={level > 1 ? `${level}xl` : 'sm'}>
            {getInitials(name)}
          </Text>
        </StyledTextView>
      ) : (
        <StyledView level={level} {...props}>
          <StyledImage
            level={level}
            style={{ resizeMode }}
            source={{
              uri: imageTransformation(source, variantsKey[level].width),
            }}
          />
        </StyledView>
      )}
    </>
  );
};

export default Avatar;
