import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  Fade,
} from 'rn-placeholder';
import { View } from '../../../../../components/new';

const HomePagePlaceholder = () => {
  const { width } = useWindowDimensions();

  return (
    <Placeholder Animation={Fade}>
      <View px="2xl">
        <View flexDirection="row" justifyContent="space-between" marginTop={60}>
          <PlaceholderLine
            height={40}
            style={{ width: width - 82, borderRadius: 8 }}
          />
          <PlaceholderMedia isRound size={40} />
        </View>
        <PlaceholderLine
          height={200}
          style={{ width: width - 32, borderRadius: 16 }}
        />
        <View flexDirection="row" justifyContent="space-between" top={-160}>
          <PlaceholderLine
            height={200}
            style={{ width: width / 2 - 24, borderRadius: 16 }}
          />
          <PlaceholderLine
            height={200}
            style={{ width: width / 2 - 24, borderRadius: 16 }}
          />
        </View>
        <View flexDirection="row" justifyContent="space-between" top={-320}>
          <PlaceholderLine
            height={80}
            style={{ width: width / 3 - 64 / 3, borderRadius: 16 }}
          />
          <PlaceholderLine
            height={80}
            style={{ width: width / 3 - 64 / 3, borderRadius: 16 }}
          />
          <PlaceholderLine
            height={80}
            style={{ width: width / 3 - 64 / 3, borderRadius: 16 }}
          />
        </View>
      </View>
    </Placeholder>
  );
};

export default HomePagePlaceholder;

const styles = StyleSheet.create({
  avatar: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 100,
    top: -50,
    left: '50%',
    transform: [{ translateX: -50 }],
  },
});
