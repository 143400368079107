/* eslint-disable import/prefer-default-export */
export enum StarRatingVariants {
  sm = 'sm',
  md = 'md',
}

export const resolveTextSize = (variant) => {
  if (variant === StarRatingVariants.md) {
    return 'sm';
  }
  return 'xs';
};
