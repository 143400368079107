import React, { useState, useCallback, Suspense } from 'react';
import { ImageBackground, Pressable } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { graphql, useLazyLoadQuery, useFragment } from 'react-relay';
import moment from 'moment';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, View, Text } from '../../components/new';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import {
  BonusInfoCard,
  NoContentView,
} from '../../components/new/custom/bonus';
import {
  CashierOverviewScreenQuery$data,
  CashierOverviewScreenQuery$variables,
} from './__generated__/CashierOverviewScreenQuery.graphql';
import PointsListItem from '../new/PointsScreen/PointListItem';
import {
  Capitalise,
  formatPlateNo,
  maskedNumber,
  toISOStringWithTimezone,
} from '../../utilities/helper';

const bannerBgSrc = 'https://ik.imagekit.io/32ndhub/assets/bg_ontime_blur.png';

const cashierOverviewScreenQuery = graphql`
  query CashierOverviewScreenQuery($filter: cashierMetricsOverviewFilter!) {
    getCashierTaskMetrics(filter: $filter) {
      ...CashierOverviewScreenTaskMetricsFragment
    }
  }
`;

const cashierTaskMetricsFragment = graphql`
  fragment CashierOverviewScreenTaskMetricsFragment on cashierTaskMetricsTC {
    parkings {
      _id
      vehicle {
        registration {
          plate
        }
      }
      parkingInvoice {
        paymentDetails {
          isPaid
          mode
          focReason
          amount
        }
        charges {
          chargesBreakup {
            _id
            duration
            payable
          }
          discountAmount
          totalPayable
        }
      }
      exitTime
      vehicleIsWhitelisted
      journey {
        taskID
      }
    }
    overview {
      cash
      online
      total
    }
  }
`;

interface CashierOverviewProps {
  variables: CashierOverviewScreenQuery$variables;
  queryOptions: NewErrorBoundaryParentState;
}

const ParkingsListItem = ({ item }) => {
  const navigation = useNavigation();
  const { taskID } = item.journey?.slice(-1)[0] ?? { taskID: '' };

  let modeText = '';
  if (item.vehicleIsWhitelisted) {
    modeText = 'Whitelisted';
  } else {
    const { mode } = item.parkingInvoice?.paymentDetails ?? '';
    if (mode === 'FOC') {
      modeText =
        item.parkingInvoice?.paymentDetails.focReason.replace(/_/g, ' ') ?? '';
    } else {
      modeText = mode;
    }
  }

  return (
    <Pressable
      onPress={() => navigation.navigate('cashierReceipt', { taskID })}
    >
      <PointsListItem
        item={item}
        title={formatPlateNo(
          maskedNumber(item.vehicle?.registration.plate ?? ''),
        )}
        subTitle={`${
          modeText && Capitalise(modeText.toLowerCase())
        } |  ${moment(item.exitTime).format('hh:mm a')}`}
      />
    </Pressable>
  );
};

const CashierOverviewScreen = (props: CashierOverviewProps) => {
  const { queryOptions, variables } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const cashierOverviewScreenQueryRef: CashierOverviewScreenQuery$data =
    useLazyLoadQuery(
      cashierOverviewScreenQuery,
      { ...variables },
      {
        ...queryOptions,
        networkCacheConfig: { force: true },
      },
    );
  const getCashierTaskMetrics = useFragment(
    cashierTaskMetricsFragment,
    cashierOverviewScreenQueryRef.getCashierTaskMetrics,
  );

  return (
    <>
      <View
        bg="primary.500"
        borderBottomLeftRadius={16}
        borderBottomRightRadius={16}
      >
        <ImageBackground
          source={{ uri: bannerBgSrc }}
          style={{
            overflow: 'hidden',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          <View pt="5xl+6xl" px="2xl">
            <Text
              size="3xl"
              color="success.500"
              weight="medium"
              textAlign="center"
            >
              ₹{getCashierTaskMetrics.overview?.total ?? ''}
            </Text>
            <Text size="sm" color="primary.10" textAlign="center">
              Today&#39;s Collection
            </Text>
            <View mt="2xl" mb="4xl" flexDirection="row" justifyContent="center">
              <View flex={1} mr="xl">
                <BonusInfoCard>
                  <Text mb="sm" size="xl" weight="medium" color="primary.10">
                    ₹{getCashierTaskMetrics.overview?.cash ?? ''}
                  </Text>
                  <Text size="sm" color="primary.200">
                    Cash
                  </Text>
                </BonusInfoCard>
              </View>
              <View flex={1} ml="xl">
                <BonusInfoCard>
                  <Text mb="sm" size="xl" weight="medium" color="primary.10">
                    ₹{getCashierTaskMetrics.overview?.online ?? ''}
                  </Text>
                  <Text size="sm" color="primary.200">
                    Online
                  </Text>
                </BonusInfoCard>
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>
      <View flex={1}>
        <FlashList
          data={getCashierTaskMetrics.parkings}
          renderItem={({ item }) => {
            return <ParkingsListItem item={item} />;
          }}
          ListHeaderComponent={
            <Text size="md" weight="medium" mt="4xl" mb="2xl" px="2xl">
              {`All cars (${getCashierTaskMetrics.parkings.length})`}
            </Text>
          }
          estimatedItemSize={76}
          drawDistance={500}
          ListEmptyComponent={
            <View>
              <NoContentView />
              <Text textAlign="center" color="primary.100" mb="lg">
                No Collection yet
              </Text>
            </View>
          }
        />
      </View>
    </>
  );
};

const CashierOverviewScreenWrapper = () => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });
  const [netStatus, setNetStatus] = useState(true);

  const start = new Date();
  if (start.getHours() < 5) {
    start.setDate(start.getDate() - 1);
  }
  start.setHours(5, 0, 0, 0);

  const filter = {
    startTime: toISOStringWithTimezone(start),
    endTime: toISOStringWithTimezone(new Date()),
  };

  useFocusEffect(
    React.useCallback(() => {
      refresh();
    }, []),
  );

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'store-and-network',
    }));
  }, []);

  return (
    <Layout level="2">
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <CashierOverviewScreen
            variables={{ filter }}
            queryOptions={refreshedQueryOptions}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default CashierOverviewScreenWrapper;
