import React from 'react';
import { RefreshControl } from 'react-native';
import CollectionList from './CollectionList';
import { Text, View } from '../../../../components/new';
import { useCollectionsScreen } from './useCollectionsScreen';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import { useTransactionsRevenueReport } from '../../API/fragments/transactionsRevenueReportFragment';

interface CollectionsScreenInnerProps {
  queryOptions: NewErrorBoundaryParentState;
  refresh: () => void;
}

const CollectionsScreenInner = ({
  queryOptions,
  refresh,
}: CollectionsScreenInnerProps) => {
  const { revenueReports, pullToRefreshConf, navigateToRevenueOverviewScreen } =
    useCollectionsScreen(queryOptions, refresh);

  return (
    <View flex={1} pt="sm+md">
      {revenueReports.length && (
        <Text px="2xl" mb="2xl" size="2xl" weight="regular" color="primary.300">
          Collections
        </Text>
      )}
      <CollectionList
        RefreshControl={
          <RefreshControl
            refreshing={pullToRefreshConf.refreshing}
            onRefresh={pullToRefreshConf.onRefresh}
          />
        }
        revenueReports={revenueReports}
        onItemPress={navigateToRevenueOverviewScreen}
      />
    </View>
  );
};

export default CollectionsScreenInner;
