import React, { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import View from '../../../basic/View/View';

interface TicketQrCodeProps {
  data: string;
  aspectRatio?: number;
}

const TicketQrCode = ({ data, aspectRatio = 0 }: TicketQrCodeProps) => {
  const SIZE_ASPECT_RATIO = aspectRatio !== 0 ? aspectRatio : 1;
  const { width } = useWindowDimensions();
  const QrcodeWidth = width * 0.4;
  const size = useMemo(() => QrcodeWidth * SIZE_ASPECT_RATIO, [QrcodeWidth]);

  return (
    <View
      pt="2xl"
      pb="4xl"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <View
        p="xl"
        width={size}
        height={size}
        justifyContent="center"
        alignItems="center"
      >
        <QRCode value={data} size={size} />
      </View>
    </View>
  );
};

export default TicketQrCode;
