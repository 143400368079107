/* eslint-disable no-nested-ternary */
import React from 'react';
import { FlatList } from 'react-native';
import { Pressable, View } from '../../../../components/new';
import TransactionSummaryListItem from '../../../../components/new/custom/transaction/TransactionSummaryListItem';
import { TransactionsRevenueReport } from '../../types';
import { getTheme } from '../../../../themes/new/theme';
import { dateUtils } from '../../../../utilities/helper';
import EmptyListView from '../../components/EmptyListView';

const theme = getTheme();
interface CollectionListProps {
  revenueReports: TransactionsRevenueReport[];
  onItemPress: (revenueReport: TransactionsRevenueReport) => void;
  RefreshControl: JSX.Element;
}

const CollectionList = ({
  revenueReports,
  onItemPress,
  RefreshControl,
}: CollectionListProps) => {
  return (
    <View flex={1}>
      <FlatList
        contentContainerStyle={{
          paddingHorizontal: theme.space['2xl'],
        }}
        data={revenueReports}
        keyExtractor={(item) => item?.formated.date}
        renderItem={({ item }) => (
          <View mb="2xl">
            <Pressable onPress={() => onItemPress(item)}>
              <TransactionSummaryListItem
                amount={item.formated.revenueAmount}
                date={
                  dateUtils.isToday(item.date)
                    ? 'Today'
                    : dateUtils.isYesterday(item.date)
                    ? 'Yesterday'
                    : item.formated.date
                }
                label={`${item.transactionsCount} ${
                  item.transactionsCount === 1 ? 'Transaction' : 'Transactions'
                }`}
                paymentStatus="SUCCESSFUL"
              />
            </Pressable>
          </View>
        )}
        ListEmptyComponent={NoCollectionsView}
        refreshControl={RefreshControl}
      />
    </View>
  );
};

export default CollectionList;

const NoCollectionsView = () => (
  <View mt="122">
    <EmptyListView
      label="No Collections Yet!"
      msg="There are no collections at the moment, check back again"
    />
  </View>
);
