/* eslint-disable import/prefer-default-export */
import { graphql, commitMutation } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

export const cancelEventTicket = (data, callback, optionalData = {}) => {
  const mutation = graphql`
    mutation cancelTicketMutation(
      $record: requestEventTicketCancellationInput!
    ) {
      requestEventTicketCancellation(record: $record) {
        error {
          message
        }
      }
    }
  `;

  const variables = {
    record: {
      invoiceId: data?.invoiceId,
      reason: data?.reason,
    },
  };
  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response, data, optionalData);
    },
    onError: (err) => {
      callback(err);
      console.error(err);
    },
  });
};
