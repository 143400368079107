/**
 * @generated SignedSource<<92b2a249c0965b6451ae09652882e819>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DailyValetTaskBonusFilter = {
  date: string;
};
export type MonthlyValetTaskBonusFilter = {
  date: string;
};
export type valetLast12MonthsBonusFilter = {
  date: string;
};
export type valetOverviewAPIQuery$variables = {
  dailyBonusFilter?: DailyValetTaskBonusFilter | null;
  monthlyBonusFilter?: MonthlyValetTaskBonusFilter | null;
  yearlyBonusFilter?: valetLast12MonthsBonusFilter | null;
};
export type valetOverviewAPIQuery$data = {
  readonly dailyValetTaskBonus: {
    readonly bonus: {
      readonly bonusAmount: number | null;
      readonly delayedTaskCount: number | null;
      readonly onTimeTaskCount: number | null;
    } | null;
    readonly date: string | null;
  } | null;
  readonly monthlyValetTaskBonus: ReadonlyArray<{
    readonly bonusData: {
      readonly bonusAmount: number | null;
      readonly delayedTaskCount: number | null;
      readonly onTimeTaskCount: number | null;
    } | null;
    readonly date: string | null;
  } | null> | null;
  readonly valetLast12MonthsBonus: ReadonlyArray<{
    readonly bonusAmount: number | null;
    readonly delayedTaskCount: number | null;
    readonly month: string | null;
    readonly monthName: string | null;
    readonly onTimeTaskCount: number | null;
    readonly year: string | null;
  } | null> | null;
};
export type valetOverviewAPIQuery = {
  response: valetOverviewAPIQuery$data;
  variables: valetOverviewAPIQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dailyBonusFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "monthlyBonusFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "yearlyBonusFilter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onTimeTaskCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "delayedTaskCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bonusAmount",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "dailyBonusFilter"
      }
    ],
    "concreteType": "DailyValetBonusOutput",
    "kind": "LinkedField",
    "name": "dailyValetTaskBonus",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ValetBonus",
        "kind": "LinkedField",
        "name": "bonus",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "monthlyBonusFilter"
      }
    ],
    "concreteType": "ValetMonthBonus",
    "kind": "LinkedField",
    "name": "monthlyValetTaskBonus",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ValetMonthTaskBonus__BonusData",
        "kind": "LinkedField",
        "name": "bonusData",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "yearlyBonusFilter"
      }
    ],
    "concreteType": "ValetSingleMonthBonus",
    "kind": "LinkedField",
    "name": "valetLast12MonthsBonus",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "year",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "month",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "monthName",
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "valetOverviewAPIQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "valetOverviewAPIQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "6e86229582d14f49509c68930696310a",
    "id": null,
    "metadata": {},
    "name": "valetOverviewAPIQuery",
    "operationKind": "query",
    "text": "query valetOverviewAPIQuery(\n  $dailyBonusFilter: DailyValetTaskBonusFilter\n  $monthlyBonusFilter: MonthlyValetTaskBonusFilter\n  $yearlyBonusFilter: valetLast12MonthsBonusFilter\n) {\n  dailyValetTaskBonus(filter: $dailyBonusFilter) {\n    date\n    bonus {\n      onTimeTaskCount\n      delayedTaskCount\n      bonusAmount\n    }\n  }\n  monthlyValetTaskBonus(filter: $monthlyBonusFilter) {\n    date\n    bonusData {\n      onTimeTaskCount\n      delayedTaskCount\n      bonusAmount\n    }\n  }\n  valetLast12MonthsBonus(filter: $yearlyBonusFilter) {\n    year\n    month\n    monthName\n    onTimeTaskCount\n    delayedTaskCount\n    bonusAmount\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b0d23c2edd1bdacc45cba9aa43eaa6c";

export default node;
