import React, { useEffect, useRef } from 'react';
import { Button, Text, View } from '../../../../components/new';
import { taskHistoryActionTypes } from '../../../../utilities/helper';
import SelectRating from '../../../../components/RatingModalComponents/SelectRating';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';
import {
  Appearance,
  FontWeights,
  State,
  Status,
} from '../../../../themes/new/helper';

const TrackingCTA = ({
  btnText = '',
  onPressButton,
  status = '',
  parkingId = '',
  flashEffect = '',
  feedback = null,
  refreshFlash,
  isPaid,
  loading,
  delayedAndNotRedeemed,
  isVehicleWhitelisted = false,
}) => {
  useEffect(() => {
    if (!isValueNullOrEmpty(flashEffect)) {
      setTimeout(() => {
        refreshFlash();
      }, 5000);
    }
  }, [flashEffect]);

  const hasFeedBack = () => {
    if (
      feedback &&
      Object.keys(feedback)?.length &&
      feedback?.feedbacks &&
      feedback?.feedbacks?.length &&
      feedback?.feedbacks[0]?.rating
    ) {
      return true;
    }
    return false;
  };

  const animation = useRef(null);

  const renderRating = () => (
    <SelectRating
      isTYCScreen
      parkingId={parkingId}
      hasFeedBack={hasFeedBack()}
      selectedVal={feedback?.feedbacks?.[0]?.rating}
    />
  );

  if (!isValueNullOrEmpty(flashEffect)) {
    return (
      <View bg="opacity.30" borderWidth="xs" borderColor="opacity.10" p="2xl">
        <Text
          size="md"
          weight={FontWeights.MEDIUM}
          color="static.gold"
          numberOfLines={1}
          style={{ zIndex: 1 }}
          textAlign="center"
        >
          {flashEffect}
        </Text>
        <View width="100%" zIndex={0} position="absolute">
          <CustomLottieView
            autoPlay
            loop
            ref={animation}
            style={{
              alignSelf: 'center',
              width: '100%',
              height: 80,
            }}
            source={require('../../../../../assets/lottie/discount_celeb.json')}
          />
        </View>
      </View>
    );
  }

  return (
    <View py="2xl" bg="opacity.30" borderWidth="xs" borderColor="opacity.10">
      {((status === taskHistoryActionTypes.arrived ||
        status === taskHistoryActionTypes.completed) &&
        isPaid &&
        !delayedAndNotRedeemed) ||
      status === taskHistoryActionTypes.EXITED ||
      (isVehicleWhitelisted &&
        (status === taskHistoryActionTypes.arrived ||
          status === taskHistoryActionTypes.completed)) ? (
        renderRating()
      ) : (
        <Button
          state={loading ? State.DISABLED : State.ACTIVE}
          status={Status.PRIMARY}
          appearance={Appearance.FILLED}
          size="md"
          onPress={onPressButton}
          loading={loading}
          mx="2xl"
        >
          {btnText}
        </Button>
      )}
    </View>
  );
};

export default TrackingCTA;
