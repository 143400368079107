/* eslint-disable react/prop-types */
/* eslint-disable no-extra-boolean-cast */
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as ImagePicker from 'expo-image-picker';
import * as Linking from 'expo-linking';
import Constants from 'expo-constants';
import {
  ActivityIndicator,
  Alert,
  Image,
  Platform,
  Pressable,
  ScrollView,
} from 'react-native';
import { graphql, useLazyLoadQuery, useFragment } from 'react-relay';
// import useSubscription from 'react-relay/lib/relay-hooks/useSubscription';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useFocusEffect } from '@react-navigation/native';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import {
  Card,
  Divider,
  Icon,
  Layout,
  Modal,
  Text,
  View,
} from '../../components/new';
import CustomPressable from '../../components/new/primitive/CustomPressable/CustomPressable';
import S3Upload from '../../utilities/S3Upload';
import Avatar from '../../components/new/primitive/Avatar/Avatar';
import { imageTransformation, toTitleCase } from '../../utilities/helper';
import S3Delete from '../../utilities/S3Delete';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useUserStore, { UserRole } from '../../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import { UserAccountScreenPlaceholder } from '../user/shimmerPlaceholders';
import NewErrorView from '../../utilities/NewErrorView';
import OTAConfig from '../../../react-native.config';
import { isValueNullOrEmpty } from '../../utilities/Utility';
import { getTheme } from '../../themes/new/theme';
import useRestaurantPartnerStore from '../../stores/restaurantpartner/restaurantPartnerStore';
import AppConstants from '../../utilities/AppConstants';

const RestaurantPartnerAccountScreenQuery = graphql`
  query RestaurantPartnerAccountScreenQuery($userId: MongoID!) {
    findUserById(_id: $userId) {
      ...RestaurantPartnerAccountScreenFindUserFragment
    }
  }
`;

const restaurantPartnerAccountScreenFindUserByIdFragment = graphql`
  fragment RestaurantPartnerAccountScreenFindUserFragment on User {
    _id
    name {
      first
      last
    }
    role {
      name
      permission
    }
    userSince
    visitCount
    parkedCount
    profileImage
    restaurants {
      _id
      displayName
      payBill {
        pointsEnabled {
          redeem
          reward
        }
      }
    }
  }
`;
const RenderAccountScreen = (props) => {
  const userId = useUserStore((state) => state.id);
  const userRole = useUserStore((state) => state.role);
  const [ModalVisible, setModalVisible] = useState(false);
  const [options, setOptions] = useState([
    'Choose Photo',
    'Take Photo',
    'Remove Photo',
    'Cancel',
  ]);
  const [loading, setLoading] = useState(false);
  const { version } = Constants.expoConfig;
  const modifiedAppVersion =
    !isValueNullOrEmpty(OTAConfig.ota) && Platform.OS !== 'web'
      ? `${version}-${OTAConfig.ota}`
      : version;
  const { navigation, refresh, findUserByIdRef, userState } = props;
  const findUserById = useFragment(
    restaurantPartnerAccountScreenFindUserByIdFragment,
    findUserByIdRef,
  );
  const { isLoyaltyEnabled } = useRestaurantPartnerStore((state) => state);
  const { showActionSheetWithOptions } = useActionSheet();
  const editProfile = () => {
    navigation.navigate('editProfile', { role: UserRole.RESTAURANT_PARTNER });
  };
  const settings = () => {
    navigation.navigate('usersettings');
  };
  const handleAbout = () => {
    firebaseEventLogger('account__about_Tap', {
      buttonName: 'about',
      screenName: 'account',
      userType: userRole,
      interactionType: InteractionType.TAP,
      isLoggedIn: true,
    });
    navigation.navigate('About');
  };
  // const handleDiscounts = () => {
  //   firebaseEventLogger('account__discounts_Tap', {
  //     buttonName: 'discounts',
  //     screenName: 'account',
  //     userType: userRole,
  //     interactionType: InteractionType.TAP,
  //     isLoggedIn: true,
  //   });
  //   navigation.navigate('discounts');
  // };
  const fullName = `${findUserById.name.first} ${
    findUserById.name.last !== null ? findUserById.name.last : ''
  }`;
  const restaurantName = findUserById?.restaurants?.displayName;
  const { IMAGEKIT_PROD_URI } = AppConstants;
  // const placeHolder = 'https://ik.imagekit.io/32ndhub/assets/chauffeur.png';
  const placeHolder = 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
  const imageLink = findUserById.profileImage
    ? IMAGEKIT_PROD_URI + findUserById.profileImage
    : placeHolder;
  const openImagePickerAsync = async (mode) => {
    let pickerResult;
    if (mode === 'gallery') {
      const getGalleryPermission =
        await ImagePicker.getMediaLibraryPermissionsAsync();
      const requestGalleryPermission =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (
        getGalleryPermission.status === 'denied' ||
        requestGalleryPermission.status === 'denied'
      ) {
        Alert.alert(
          '32nd does not have access to your photos. To enable access tap Settings.',
          '',
          [
            {
              text: 'Cancel',
              onPress: () => {
                // console.log('Cancel Pressed')
              },
              style: 'cancel',
            },
            { text: 'Settings', onPress: () => Linking.openSettings() },
          ],
        );
      } else {
        pickerResult = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [3, 3],
          base64: true,
        });
      }
    } else {
      const getCameraPermission = await ImagePicker.getCameraPermissionsAsync();
      const requestCameraPermissions =
        await ImagePicker.requestCameraPermissionsAsync();
      if (
        getCameraPermission.status === 'denied' ||
        // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
        requestCameraPermissions === 'denied'
      ) {
        Alert.alert(
          '32nd does not have access to your Camera. To enable access tap Settings.',
          '',
          [
            {
              text: 'Cancel',
              onPress: () => {
                // console.log('Cancel Pressed')
              },
              style: 'cancel',
            },
            { text: 'Settings', onPress: () => Linking.openSettings() },
          ],
        );
      } else {
        pickerResult = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [3, 3],
          base64: true,
        });
      }
    }
    if (pickerResult?.assets?.[0]?.base64) {
      setLoading(true);
      firebaseEventLogger('account__postPicture_Data', {
        buttonName: 'postPicture',
        screenName: 'account',
        userType: userRole,
        interactionType: 'data',
      });
      const data = await S3Upload(pickerResult, { userId, userRole }, refresh);
      if (
        data &&
        data?.record &&
        data?.record?.profileImage &&
        data?.record?.profileImage !== ''
      ) {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!findUserById.profileImage) {
      setOptions(['Upload Photo', 'Take Photo', 'Cancel']);
    } else {
      setOptions(['Change Photo', 'Take Photo', 'Remove Photo', 'Cancel']);
    }
    // console.log('window width',windowWidth)
  }, []);

  const navigateToRestaurantConfigScreen = () => {
    if (findUserById?.restaurants?._id) {
      navigation.navigate('RestaurantConfig', {
        restaurantId: findUserById?.restaurants?._id,
        restaurantName: findUserById?.restaurants?.displayName,
      });
    }
  };

  const avatarHeight = 100;
  return (
    <>
      <ScrollView
        contentContainerStyle={{
          paddingTop: 80,
          paddingHorizontal: 16,
        }}
        bounces={false}
      >
        <View mt="4xl" zIndex={1}>
          <Card p={0} appearance="filled">
            <View
              style={{
                alignItems: 'center',
                zIndex: 1,
              }}
            >
              <View
                style={{
                  width: 100,
                  height: avatarHeight,
                  position: 'absolute',
                  top: -(avatarHeight / 2),
                }}
              >
                {loading ? (
                  <View
                    width={100}
                    height={100}
                    borderWidth="xs"
                    borderColor="rgba(158, 150, 150, .2)"
                    borderRadius={80}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ActivityIndicator />
                  </View>
                ) : (
                  <Pressable
                    onPress={() => {
                      setModalVisible(true);
                    }}
                  >
                    <Avatar level={3} source={imageLink} />
                  </Pressable>
                )}
                <Pressable
                  style={{ position: 'absolute', top: '73%', left: '71%' }}
                  onPress={() => {
                    firebaseEventLogger('account__profilePhotoName_Tap', {
                      buttonName: 'profilePhotoName',
                      screenName: 'account',
                      userType: userRole,
                      interactionType: 'tap',
                    });
                    showActionSheetWithOptions(
                      {
                        options,
                        cancelButtonIndex: 3,
                        destructiveButtonIndex:
                          (Platform.OS === 'android' ||
                            Platform.OS === 'web') &&
                          2,
                        destructiveColor: getTheme().colors.error[500],
                        containerStyle: {
                          backgroundColor: getTheme().colors.background[300],
                        },
                        textStyle: {
                          color: getTheme().colors.info[500],
                          textAlign: 'center',
                          paddingLeft: 4,
                        },
                      },
                      (buttonIndex) => {
                        if (buttonIndex === 0) {
                          openImagePickerAsync('gallery');
                        }
                        if (buttonIndex === 1) {
                          openImagePickerAsync('camera');
                        }
                        if (buttonIndex === 2 && findUserById.profileImage) {
                          S3Delete(findUserById.profileImage, userId, refresh);
                        }
                      },
                    );
                  }}
                  hitSlop={{ top: 10, bottom: 0, left: 0, right: 0 }}
                >
                  <Image
                    style={{ width: 24, height: 24 }}
                    source={require('../../../assets/edit-pencil.png')}
                  />
                </Pressable>
              </View>
            </View>
            <View style={{ height: 50 }} />
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
              mt="3xl"
              mb="4xl"
            >
              <Text
                size="2xl"
                color="primary.500"
                pb="sm"
                text
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {fullName}
              </Text>
              <Text size="xs" color="primary.200">
                Member since {findUserById.userSince}
              </Text>
            </View>
          </Card>
          <Modal
            visible={ModalVisible}
            onBackdropPress={() => {
              setModalVisible(!ModalVisible);
            }}
            onRequestClose={() => {
              setModalVisible(!ModalVisible);
            }}
          >
            <View
              width={280}
              // maxWidth={358}
              // maxHeight={500}
              height={280}
              // bg="background.500"
              borderRadius={280 / 2}
              // px="2xl"
              // py="4xl"
            >
              <Image
                source={{ uri: imageTransformation(imageLink, 280, '1-1') }}
                style={{
                  resizeMode: 'cover',
                  width: 280,
                  height: 280,
                  borderRadius: 280 / 2,
                }}
              />
            </View>
          </Modal>
        </View>
        <Pressable onPress={navigateToRestaurantConfigScreen}>
          <Card
            mt="2xl"
            p="2xl"
            category="none"
            borderColor="primary.50"
            borderWidth="xs"
          >
            <View
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <View flexDirection="row" alignItems="center">
                <Icon name="menu2-filled-300" size="6xl" color="primary.500" />
                <View justifyContent="flex-end" ml="xl">
                  <Text size="md" color="primary.300">
                    {restaurantName}
                  </Text>
                  <Text size="sm" color="primary.200">
                    Preview your restaurant page
                  </Text>
                </View>
              </View>
              <Icon
                name="keyboard-arrow-right-300"
                size="3xl"
                color="primary.500"
              />
            </View>
          </Card>
        </Pressable>
        <View style={{ flex: 1 }} mt="4xl">
          <View>
            <CustomPressable
              appearance="ghost"
              size="md"
              textSize="md"
              textColor="primary.500"
              iconSize="md"
              shadow="md"
              state="active"
              status="primary"
              rounded="lg"
              // leftStyle={{ width: 28, height: 28 }}
              iconColor="primary.400"
              leftContent={null}
              rightContent="forward-outline-300"
              // mb="2xl"
              onPress={() => {
                editProfile();
              }}
            >
              Edit Profile
            </CustomPressable>
            <Divider />
          </View>
          {/* {isLoyaltyEnabled ? ( */}
          <View>
            <CustomPressable
              appearance="ghost"
              size="md"
              textSize="md"
              textColor="primary.500"
              iconSize="md"
              shadow="md"
              state="active"
              status="primary"
              rounded="lg"
              // leftStyle={{ width: 28, height: 28 }}
              iconColor="primary.400"
              leftContent={null}
              rightContent="forward-outline-300"
              // mb="2xl"
              onPress={() => {
                navigation.navigate('StaffBarcodeScannerScreen', {
                  hideManual: true,
                });
              }}
            >
              Scan & Redeem Ticket
            </CustomPressable>
            <Divider />
          </View>
          {/* ) : (
            <></>
          )} */}
          {/* <View>
            <CustomPressable
              appearance="ghost"
              size="md"
              textSize="md"
              textColor="primary.500"
              iconSize="md"
              shadow="md"
              state="active"
              status="primary"
              rounded="lg"
              // leftStyle={{ width: 28, height: 28 }}
              iconColor="primary.400"
              leftContent={null}
              rightContent="forward-outline-300"
              // mb="2xl"
              onPress={() => {
                handleDiscounts();
              }}
            >
              Discounts
            </CustomPressable>
            <Divider />
          </View> */}
          <View>
            <CustomPressable
              appearance="ghost"
              size="md"
              textSize="md"
              textColor="primary.500"
              iconSize="md"
              shadow="md"
              state="active"
              status="primary"
              rounded="lg"
              // leftStyle={{ width: 28, height: 28 }}
              iconColor="primary.400"
              leftContent={null}
              rightContent="forward-outline-300"
              // mb="2xl"
              onPress={() => {
                handleAbout();
              }}
            >
              About
            </CustomPressable>
            <Divider />
          </View>
          <View>
            <CustomPressable
              appearance="ghost"
              size="md"
              textSize="md"
              iconSize="md"
              textColor="primary.500"
              shadow="md"
              state="active"
              status="primary"
              rounded="lg"
              // leftStyle={{ width: 28, height: 28 }}
              iconColor="primary.400"
              leftContent={null}
              rightContent="forward-outline-300"
              // mb="2xl"
              onPress={() => {
                settings();
              }}
            >
              Settings
            </CustomPressable>
            <Divider />
          </View>
          <View my="4xl" width="100%" alignItems="center">
            <Text size="sm" color="grey.300">
              Version {modifiedAppVersion}
            </Text>
          </View>
        </View>
      </ScrollView>
    </>
  );
};
const RestaurantPartnerAccountScreen = ({ navigation }) => {
  const userState = useUserStore((state) => state);
  const userID = userState?.id;
  const [netStatus, setNetStatus] = useState(true);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };
  useFocusEffect(
    useCallback(() => {
      refresh();
      return () => {
        // Do something when the screen is unfocused
      };
    }, []),
  );
  const RenderUserCard = (props) => {
    // eslint-disable-next-line react/prop-types
    const { queryOptions, variables } = props;
    const RestaurantPartnerAccountScreenQueryRef = useLazyLoadQuery(
      RestaurantPartnerAccountScreenQuery,
      variables,
      {
        ...queryOptions,
        networkCacheConfig: { force: true },
        fetchPolicy: 'network-only',
      },
    );
    return (
      <RenderAccountScreen
        findUserByIdRef={RestaurantPartnerAccountScreenQueryRef.findUserById}
        navigation={navigation}
        userId={userID}
        refresh={refresh}
        userState={userState}
      />
    );
  };
  return (
    <Layout level="1">
      <SafeAreaView edges={['top']} />
      {userID !== '' && (
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<UserAccountScreenPlaceholder />}>
            <RenderUserCard
              fetchKey={refreshedQueryOptions.fetchKey}
              refresh={refresh}
              queryOptions={refreshedQueryOptions ?? {}}
              variables={{ userId: userID, filter: { ownerId: userID } }}
            />
          </Suspense>
        </NewErrorBoundary>
      )}
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
        bottomNavigator
      />
    </Layout>
  );
};
export default RestaurantPartnerAccountScreen;
