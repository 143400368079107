import React, { useEffect, useReducer, useState } from 'react';
import { Pressable, useWindowDimensions } from 'react-native';
import * as Progress from 'react-native-progress';
import { Icon, IconButton, Text, View } from '../../../../components/new';
import {
  getTheme,
  radii,
  space,
  themeModes,
} from '../../../../themes/new/theme';
import {
  fancyTimeFormat,
  formatPlateNo,
  secondsDifferenceInDate,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../../utilities/helper';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import { Appearance, FontWeights, Shape } from '../../../../themes/new/helper';

let timerVal = 0;
let descTime = 0;

const TrackingDetails = ({
  onSelectInfo,
  isPaid = false,
  delaybyAcceptedOrArrived = false,
  isDelayed = false,
  taskData = {},
  status,
  handleCall,
  taskCreatedTimeStamp = 0,
  arrivedTime,
  rewardsText = '',
  checkClaimParking = () => {},
  checkBgColor = () => {},
}) => {
  const { width } = useWindowDimensions();
  const [progressStatusValue, setProgressStatusValue] = useState(0);
  const [isReparked, setIsReparked] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { valet, vehicle, history = [], ETA, taskType = '' } = taskData;

  const [claimInterval, setClaimInterval] = useState<any>();
  const [reparkInterval, setReparkInterval] = useState<any>();
  const [timerInterval, setTimerInterval] = useState<any>();

  const isParking =
    taskType === taskTypes.park || taskType === taskTypes.repark;

  const createToArrivedTimeStamp = !isValueNullOrEmpty(ETA?.createToArrivedTime)
    ? new Date(ETA?.createToArrivedTime).getTime()
    : 0;

  // task not accepted by the valet please consider this time + delayed also worked on the basis of this
  const createdLeftTime = !isValueNullOrEmpty(ETA?.createToArrivedTime)
    ? Math.round(
        (new Date(ETA?.createToArrivedTime).valueOf() - new Date().valueOf()) /
          60000,
      )
    : 0;

  // once task accepted by the valet please consider this time
  const acceptedLeftTime = !isValueNullOrEmpty(
    ETA?.acceptedToArrivedCustomerTime,
  )
    ? Math.round(
        (new Date(ETA?.acceptedToArrivedCustomerTime).valueOf() -
          new Date().valueOf()) /
          60000,
      )
    : 0;

  // tenth percentile of created to arrived time
  const tenthPercentile = createToArrivedTimeStamp
    ? Math.round((createToArrivedTimeStamp - taskCreatedTimeStamp) / 10000)
    : 0;

  useEffect(() => {
    if (
      isParking ||
      status === taskHistoryActionTypes.parking ||
      status === taskHistoryActionTypes.incomplete ||
      status === taskHistoryActionTypes.completed
    ) {
      clearInterval(claimInterval);
      clearInterval(reparkInterval);
      clearInterval(timerInterval);
      setIsReparked(false);
    }
  }, [isParking, status]);

  useEffect(() => {
    if (
      status === taskHistoryActionTypes.arrived &&
      taskData?.taskType === taskTypes.recall
    ) {
      if (arrivedTime && !isParking) {
        const timeDiff = secondsDifferenceInDate(arrivedTime) * -1;
        if (timeDiff > 180) {
          setIsReparked(true);
          setCurrentStatus('reparking');
          return;
        }
        clearInterval(claimInterval);
        const claimInterval2 = setInterval(() => {
          const arrivalDiff = 60 - secondsDifferenceInDate(arrivedTime) * -1;
          if (arrivalDiff <= 0) {
            clearInterval(claimInterval2);
            setCurrentStatus('reparking');
            if (timeDiff < 180) {
              countDownTimerFunction();
            }
          } else if (status !== currentStatus) {
            setCurrentStatus(status);
          }
        }, 1000);
        setClaimInterval(claimInterval2);
      } else {
        clearInterval(claimInterval);
        setCurrentStatus(status);
      }
      forceUpdate();
    }
  }, [status, arrivedTime]);

  const countDownTimerFunction = () => {
    const timeDiff = secondsDifferenceInDate(arrivedTime) * -1;
    clearInterval(reparkInterval);
    checkBgColor();
    const reparkInterval2 = setInterval(() => {
      if (timeDiff <= 0) {
        clearInterval(reparkInterval2);
        setIsReparked(true);
      } else {
        if (timerVal < 0) {
          clearInterval(reparkInterval2);
          setIsReparked(true);
        }
        timerVal = 180 - secondsDifferenceInDate(arrivedTime) * -1;
        forceUpdate();
      }
    }, 1000);
    setReparkInterval(reparkInterval);
  };

  useEffect(() => {
    setCurrentStatus(status);
    setProgressStatusValue(
      // eslint-disable-next-line no-nested-ternary
      isParking
        ? status === taskHistoryActionTypes.parking
          ? 70
          : 100
        : isDelayed
        ? 100
        : 0,
    );
    if (isValueNullOrEmpty(ETA?.createToArrivedTime) || isParking) return;
    const diff = secondsDifferenceInDate(ETA?.createToArrivedTime);
    if (diff > 0 && tenthPercentile > 0) {
      const completedPercentile =
        (tenthPercentile * 10 - diff) / tenthPercentile;
      setProgressStatusValue(completedPercentile * 10);
    }
  }, [isParking, status]);

  useEffect(() => {
    if (
      isValueNullOrEmpty(ETA?.createToArrivedTime) ||
      isValueNullOrEmpty(ETA?.acceptedToArrivedCustomerTime) ||
      isParking
    ) {
      return;
    }
    if (status === taskHistoryActionTypes.arrived) {
      setProgressStatusValue(100);
      return;
    }
    const taskAccepted = !!history?.find(
      (item) => item?.action === taskHistoryActionTypes.accepted,
    );
    clearInterval(timerInterval);
    const timerInterval2 = setInterval(() => {
      const diff = secondsDifferenceInDate(ETA?.createToArrivedTime);
      const arrivalDiff = secondsDifferenceInDate(
        ETA?.acceptedToArrivedCustomerTime,
      );
      if (diff > 0 && diff % tenthPercentile === 0) {
        const completedPercentile =
          (tenthPercentile * 10 - diff) / tenthPercentile;
        setProgressStatusValue(completedPercentile * 10);
      }
      if (diff <= 0) {
        if (!taskAccepted) {
          clearInterval(timerInterval2);
        }
        if (progressStatusValue !== 100) {
          setProgressStatusValue(100);
        }
        checkClaimParking();
      }
      if (taskAccepted) {
        if (arrivalDiff <= 0) {
          if (diff <= 0) {
            clearInterval(timerInterval2);
          }
        } else {
          const acceptedLeft = Math.round(
            (new Date(ETA?.acceptedToArrivedCustomerTime).valueOf() -
              new Date().valueOf()) /
              60000,
          );
          if (descTime !== acceptedLeft && descTime > 1) {
            forceUpdate();
          }
        }
      } else {
        const createdLeft = Math.round(
          (new Date(ETA?.createToArrivedTime).valueOf() -
            new Date().valueOf()) /
            60000,
        );
        if (descTime !== createdLeft && descTime > 1) {
          forceUpdate();
        }
      }
    }, 1000);
    setTimerInterval(timerInterval2);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(timerInterval2);
  }, [status, isParking]);

  // eslint-disable-next-line consistent-return
  const checkDescTime = () => {
    if (!isParking) {
      switch (status) {
        case taskHistoryActionTypes.created:
          return createdLeftTime;

        default:
          return acceptedLeftTime;
      }
    }
  };

  const getstatus = (s) => {
    const obj = {
      title: '',
      subStatus: '',
      longTitle: '',
      description: '',
      showTimer: false,
      showVoucher: false,
      footerIcon: 'info-outline-300',
    };
    descTime = checkDescTime();
    descTime = descTime > 0 ? descTime : 1;
    switch (s) {
      case taskHistoryActionTypes.parking:
        obj.title =
          taskData?.taskType === taskTypes.repark
            ? 'Car Re-Parking'
            : 'Car Parking';
        obj.subStatus = '';
        obj.longTitle = `🧑🏼‍✈️  ${`${
          valet?.name?.first ? `${valet?.name?.first}` : 'Valet'
        }`} is parking your car safely!`;
        obj.description = ``;
        break;
      case taskHistoryActionTypes.parked:
        obj.title = 'Car Parked';
        obj.subStatus = '';
        obj.longTitle = '☎️ Call the valet desk for any queries';
        obj.description = ``;
        break;
      case taskHistoryActionTypes.created:
        obj.title = 'Car Arriving in';
        obj.subStatus = isDelayed ? 'Slight Delay' : 'On-Time';
        obj.longTitle = '🔎  A valet will be assigned shortly';
        obj.description = `${descTime} minute${descTime > 1 ? 's' : ''}`;
        obj.showVoucher = !!isDelayed;
        break;
      case taskHistoryActionTypes.assigned: // 1 min
        obj.title = 'Car Arriving in';
        obj.subStatus = isDelayed ? 'Slight Delay' : 'On-Time';
        obj.longTitle = '🔎  A valet will be assigned shortly';
        obj.description = `${descTime} minute${descTime > 1 ? 's' : ''}`;
        obj.showVoucher = !!isDelayed;
        break;
      case taskHistoryActionTypes.accepted: // 3 min
        obj.title = 'Car Arriving in';
        obj.subStatus = isDelayed ? 'Slight Delay' : 'On-Time';
        obj.longTitle = '🧑🏼‍✈️  A valet has been assigned';
        obj.description = `${descTime} minute${descTime > 1 ? 's' : ''}`;
        obj.showVoucher = !!isDelayed;
        break;
      case taskHistoryActionTypes.started:
        obj.title = 'Car Arriving in';
        obj.subStatus = isDelayed ? 'Slight Delay' : 'On-Time';
        obj.longTitle = `🧑🏼‍✈️  ${`${
          valet?.name?.first ? `${valet?.name?.first}` : 'Valet'
        }`} is bringing your car`;
        obj.description = `${descTime} minute${descTime > 1 ? 's' : ''}`;
        obj.showVoucher = !!isDelayed;
        break;
      case taskHistoryActionTypes.arrived:
        obj.title = 'Car Arrived';
        obj.subStatus = isDelayed ? 'Slight Delayed' : 'On-Time';
        obj.longTitle = `🧑🏼‍✈️  ${`${
          valet?.name?.first ? `${valet?.name?.first}` : 'Valet'
        }`} is waiting for you`;
        obj.description = ``;
        obj.showVoucher = !!isDelayed;
        break;
      case taskHistoryActionTypes.reparking:
        obj.title = 'Car Arrived';
        obj.longTitle = `🧑🏼‍✈️  ${`${
          valet?.name?.first ? `${valet?.name?.first}` : 'Valet'
        }`} is waiting for you`;
        obj.description = '';
        obj.showTimer = true;
        obj.showVoucher = !!isDelayed;
        obj.footerIcon = 'info-outline-300';
        break;
      case taskHistoryActionTypes.abort:
      case taskHistoryActionTypes.cancelled:
      case taskHistoryActionTypes.incomplete:
        obj.title = 'Recall Ended';
        obj.description = 'You can call the valet if needed';
        break;
      case taskHistoryActionTypes.completed:
        obj.description = 'Thank you for visiting 32nd!';
        obj.showVoucher = !!isDelayed;
        break;
      case taskHistoryActionTypes.EXITED:
        obj.description = 'Thank you for visiting 32nd!';
        obj.showVoucher = !!isDelayed;
        break;
      default:
        throw new Error(`wrong status ${s}`);
    }
    return obj;
  };

  const returnBarWidth = () => {
    const res = width - space['9xl+4xl'];
    return res;
  };

  const barMainColor =
    // eslint-disable-next-line no-nested-ternary
    isParking
      ? getTheme(themeModes.dark).colors.success['500']
      : isReparked
      ? getTheme(themeModes.dark).colors.error['500']
      : currentStatus === taskHistoryActionTypes.reparking
      ? getTheme(themeModes.dark).colors.warning['500']
      : getTheme(themeModes.dark).colors.success['500'];

  const textColor =
    (delaybyAcceptedOrArrived ||
      isDelayed ||
      currentStatus === taskHistoryActionTypes.reparking) &&
    !isReparked
      ? getTheme(themeModes.dark).colors.warning['500']
      : getTheme(themeModes.dark).colors.error['500'];

  const digitTextColor =
    delaybyAcceptedOrArrived ||
    isDelayed ||
    currentStatus === taskHistoryActionTypes.reparking ||
    isReparked
      ? textColor
      : getTheme(themeModes.dark).colors.primary['100'];

  const timer = () => (
    <View
      bg="opacity.80"
      px="lg"
      borderRadius="md"
      flexDirection="row"
      py="sm"
      alignItems="center"
    >
      <Text size="xs" color="primary.300">
        Re-Parking In :
      </Text>
      <View width="xl">
        <Text size="xs" color={digitTextColor} weight={FontWeights.MEDIUM}>
          {` ${fancyTimeFormat(timerVal > 0 ? timerVal : 0)}`}
        </Text>
      </View>
    </View>
  );

  const renderSubStatus = () =>
    // eslint-disable-next-line no-nested-ternary
    getstatus(currentStatus)?.subStatus ? (
      <View
        flexDirection="row"
        alignItems="center"
        bg="opacity.80"
        borderRadius="md"
        py="sm"
        px="md"
      >
        <View
          borderRadius="full"
          borderWidth="xs"
          width="3xs"
          height="3xs"
          bg={
            delaybyAcceptedOrArrived || isDelayed
              ? 'warning.500'
              : 'success.500'
          }
          mr="md+sm"
        />
        <Text color="primary.400" size="xs">
          {getstatus(currentStatus)?.subStatus}
        </Text>
      </View>
    ) : currentStatus === 'reparking' ? (
      timer()
    ) : null;

  const TopSection = () => (
    <View
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      px="2xl"
      mt="2xl"
    >
      <View flexDirection="row" alignItems="center">
        <Text size="xs" color="primary.300">
          {formatPlateNo(vehicle?.registration?.plate)}
        </Text>
        {isPaid && (
          <View
            borderWidth="xs"
            borderColor="success.500"
            py="xs"
            px="md"
            borderRadius={radii['md+lg']}
            ml="xl"
          >
            <Text size="2xs" color="success.500">
              Paid
            </Text>
          </View>
        )}
      </View>
      <Pressable
        onPress={onSelectInfo}
        hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
      >
        <Icon name="info-outline-300" color="primary.300" size="xl" />
      </Pressable>
    </View>
  );

  const ProgressStatusSection = () => (
    <View px="2xl">
      <View
        flexDirection="row"
        justifyContent="space-between"
        py="lg"
        alignItems="center"
      >
        <View flex={1} mr="lg">
          <Text
            color="primary.500"
            size="xl"
            weight={FontWeights.MEDIUM}
            numberOfLines={1}
          >
            {`${getstatus(currentStatus)?.title} ${
              getstatus(currentStatus)?.description
            }`}
          </Text>
        </View>
        <View>{renderSubStatus()}</View>
      </View>
      <Progress.Bar
        progress={progressStatusValue / 100}
        width={returnBarWidth()}
        unfilledColor={getTheme(themeModes.dark).colors.primary['400']}
        color={barMainColor}
        borderWidth={0}
        useNativeDriver
        animationType="timing"
        animationConfig={{ duration: 5000, bounciness: 0.5 }}
      />
    </View>
  );

  const VehicleStatusSection = () => (
    <View
      px="2xl"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      my="2xl"
    >
      <Text color="primary.300" size="xs" mr="lg" numberOfLines={1}>
        {getstatus(currentStatus)?.longTitle}
      </Text>
      <IconButton
        name="call-filled-300"
        size="md"
        appearance={Appearance.FILLED}
        shape={Shape.ROUNDED}
        iconSize="2xl"
        onPress={handleCall}
        iconColor="static.black"
        bg="static.white"
      />
    </View>
  );

  if (
    status === taskHistoryActionTypes.completed ||
    status === taskHistoryActionTypes.EXITED
  ) {
    return (
      <View>
        <TopSection />
        <View flexDirection="row" alignItems="center" mt="2xl" px="2xl">
          <Icon name="verified-300" color="success.500" size="6xl" />
          <View ml="2xl" flex={1}>
            <Text
              size="xl"
              weight={FontWeights.MEDIUM}
              color="primary.500"
              numberOfLines={2}
              flex={1}
              flexWrap="wrap"
            >
              {getstatus(currentStatus)?.description}
            </Text>
            {isValueNullOrEmpty(rewardsText) ? null : (
              <Text size="xs" mt="xs" color="primary.300" numberOfLines={1}>
                {rewardsText}
              </Text>
            )}
          </View>
        </View>
      </View>
    );
  }
  return (
    <View>
      <TopSection />
      <ProgressStatusSection />
      <VehicleStatusSection />
    </View>
  );
};

export default TrackingDetails;
