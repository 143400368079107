/**
 * @generated SignedSource<<ea08155c7910bc1d0ff6bc767e22ba51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type addVehicleRecordInput = {
  brand?: VehicleBrandInput | null;
  color?: string | null;
  isWhitelisted?: boolean | null;
  ownerID: any;
  registration: VehicleRegistrationInput;
};
export type VehicleBrandInput = {
  _id?: any | null;
  brandLogo?: string | null;
  name?: string | null;
};
export type VehicleRegistrationInput = {
  _id?: any | null;
  city?: string | null;
  plate: string;
  state?: string | null;
};
export type vehicleApiAddUserMutation$variables = {
  record: addVehicleRecordInput;
};
export type vehicleApiAddUserMutation$data = {
  readonly addVehicle: {
    readonly record: {
      readonly _id: any;
      readonly brand: {
        readonly brandLogo: string | null;
        readonly name: string | null;
      } | null;
      readonly color: string | null;
      readonly ownerID: any;
      readonly registration: {
        readonly plate: string;
      };
    } | null;
  } | null;
};
export type vehicleApiAddUserMutation = {
  response: vehicleApiAddUserMutation$data;
  variables: vehicleApiAddUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOneVehiclePayload",
    "kind": "LinkedField",
    "name": "addVehicle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Vehicle",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VehicleBrand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandLogo",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VehicleRegistration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "vehicleApiAddUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "vehicleApiAddUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "edbee126cb97c78c47feb2a79c37a673",
    "id": null,
    "metadata": {},
    "name": "vehicleApiAddUserMutation",
    "operationKind": "mutation",
    "text": "mutation vehicleApiAddUserMutation(\n  $record: addVehicleRecordInput!\n) {\n  addVehicle(record: $record) {\n    record {\n      _id\n      brand {\n        name\n        brandLogo\n      }\n      color\n      registration {\n        plate\n      }\n      ownerID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b53c052350718677e6532795a04b32b0";

export default node;
