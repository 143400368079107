import React from 'react';
import styled from 'styled-components/native';
import { Animated } from 'react-native';
import { space, color, layout, flexbox, border, position } from 'styled-system';

const AnimatedStyledView = styled(Animated.View)`
  ${layout}
  ${space}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  ${({ shadow, theme }) =>
    shadow && shadow !== 'none' ? theme.boxShadows[shadow] : ''}
${({ shadow, theme }) => ({
    'shadow-color':
      shadow && shadow !== 'none' ? theme.colors.primary['500'] : 'transparent',
  })}
`;

const AnimatedView = ({ children, ...rest }) => {
  return <AnimatedStyledView {...rest}>{children || null}</AnimatedStyledView>;
};

export default AnimatedView;
