import React from 'react';
import { View, Avatar, Text, Pressable } from '..';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';

interface VehicleListItemProps {
  id: string;
  imageLink: string;
  heading: string;
  subText: string;
  onPress: null | (() => void);
  contentRight: any;
}

const VehicleListItem = ({
  id = '',
  imageLink = '',
  heading = '',
  subText = '',
  onPress = () => {},
  contentRight = null,
}: VehicleListItemProps) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  return (
    <View borderRadius="md" overflow="hidden">
      <Pressable onPress={onPress}>
        <View bg="background.primary.elevation" borderRadius="md">
          <View
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <View flexDirection="row" alignItems="center">
              <Avatar level={1} source={imageLink} />
              <View ml="2xl">
                <View flexDirection="row" alignItems="center">
                  <Text size="md" color="primary.500">
                    {heading}
                  </Text>
                </View>
                <View flexDirection="row" alignItems="center">
                  <Text size="xs" color="primary.100">
                    {subText}
                  </Text>
                </View>
              </View>
            </View>
            {contentRight}
          </View>
        </View>
      </Pressable>
    </View>
  );
};

export default VehicleListItem;
