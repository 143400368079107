import { useMemo, useRef, useState } from 'react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { useNavigation } from '@react-navigation/native';
import {
  eventConfirmCancellationInvoiceFragment,
  eventConfirmCancellationScreenQuery,
  eventConfirmCancellationTicketQueryFragment,
} from './eventConfirmCancellationScreenQuery';
import { useNodeFromPagination } from '../../../../hooks/useNodeFromPagination';
import { getEventCancellationInfo } from '../helpers/helpers';
import { normalizeToNumberOrZero } from '../../../../utilities/Utility';
import { State } from '../../../../themes/new/helper';
import { cancelEventTicket } from './cancelTicketMutation';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { eventAnalytics } from '../analytics/eventAnalytics';

export enum BS_MODES {
  NONE,
  BREAK_UP,
  CANCELLATION_REASONS,
}

export const ReasonLabels = {
  RESCHEDULE: 'Need to Reschedule',
  CHANHE_IN_PLANS: 'Change in Personal Plans',
  OTHER: 'Other (Please specify)',
};

export const RadioConfig = [
  { name: ReasonLabels.RESCHEDULE, checked: false },
  { name: ReasonLabels.CHANHE_IN_PLANS, checked: false },
  { name: ReasonLabels.OTHER, checked: false },
];

const useEventConfirmCancellationScreen = (queryVariables, queryOptions) => {
  const bsRef = useRef<ActionSheetRef>();
  const navigation = useNavigation();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherCancellationReason, setOtherCancellationReason] = useState('');
  const [reasonValidationError, setReasonValidationError] = useState('');
  const [cancelling, setCancelling] = useState(false);
  const [radioConfig, setRadioConfig] =
    useState<typeof RadioConfig>(RadioConfig);
  const [bsMode, setBsMode] = useState<BS_MODES>(BS_MODES.NONE);
  const ticketAndInvoiceRef = useLazyLoadQuery(
    eventConfirmCancellationScreenQuery,
    queryVariables,
    queryOptions,
  );
  const invoiceFragment = useFragment(
    eventConfirmCancellationInvoiceFragment,
    ticketAndInvoiceRef,
  );
  const ticketFragment = useFragment(
    eventConfirmCancellationTicketQueryFragment,
    ticketAndInvoiceRef,
  );
  const invoice = useNodeFromPagination(invoiceFragment?.findInvoiceV2)[0];
  const ticket = useNodeFromPagination(ticketFragment?.tickets)[0];
  const eventId = invoice?.orderId ?? '';
  const ticketId = ticket?._id ?? '';
  const isRefundableIfCancelled = useMemo(() => {
    if (ticket?.additionalFields?.event?.startTime) {
      const { isRefundableIfCancelled: refundable } = getEventCancellationInfo(
        new Date(ticket?.additionalFields?.event?.startTime),
      );
      return refundable;
    }
    return false;
  }, [invoice, ticket]);

  const netAmount = normalizeToNumberOrZero(invoice?.priceDetails?.netAmount);
  const pointsAmount = normalizeToNumberOrZero(
    invoice?.priceDetails?.rewardsUsed,
  );
  const totalAmountPaid = netAmount + pointsAmount; // upi + points
  const cancellationCharge = isRefundableIfCancelled ? 0 : totalAmountPaid;
  const refundAmount = totalAmountPaid - cancellationCharge;

  const onBsDismiss = () => {
    setBsMode(BS_MODES.NONE);
  };

  const showBreakUpBs = () => {
    setBsMode(BS_MODES.BREAK_UP);
    bsRef.current?.show();
  };

  /**
   * Syncs the `radioConfig` in state with the value of `selectedReason`,
   * so that when again opened via BS, it restores the radio choice to the
   * previosly selected choice in case the'Apply' btn is not clicked.
   */
  const syncRadioConfig = () => {
    const syncedConfig = radioConfig.map((rc) => ({
      ...rc,
      checked: rc?.name === selectedReason,
    }));
    setRadioConfig(syncedConfig);
  };

  const getProceedCtaState = () => {
    if (cancelling || !selectedReason) return State.DISABLED;
    if (selectedReason === ReasonLabels.OTHER) {
      if (otherCancellationReason && !reasonValidationError)
        return State.ACTIVE;
      return State.DISABLED;
    }
    return State.ACTIVE;
  };

  const showCancellationReasonsBs = () => {
    syncRadioConfig();
    setBsMode(BS_MODES.CANCELLATION_REASONS);
    bsRef.current?.show();
  };

  const handleCancellationRadioSelection = (
    updatedRadioConfig: typeof RadioConfig,
  ) => {
    const checkedRadio = updatedRadioConfig?.filter((d) => d?.checked)[0];
    eventAnalytics.confirmCancellationScreen.reasonSelected(
      eventId,
      ticketId,
      checkedRadio?.name,
    );
    setRadioConfig(updatedRadioConfig);
  };

  const handleCancellationReasonApply = () => {
    const checkedRadio = radioConfig.find((rc) => rc?.checked);
    eventAnalytics.confirmCancellationScreen.reasonApplied(
      eventId,
      ticketId,
      checkedRadio?.name,
    );
    setSelectedReason(checkedRadio?.name);
    bsRef.current?.hide();
  };

  const handleCancellation = () => {
    const reason =
      selectedReason === ReasonLabels.OTHER
        ? otherCancellationReason
        : selectedReason;
    if (reason) {
      eventAnalytics.confirmCancellationScreen.cancellationPerformed(
        eventId,
        ticketId,
        reason,
      );
      setCancelling(true);
      cancelEventTicket(
        {
          invoiceId: invoice?._id,
          reason,
        },
        (errorOrSuccess) => {
          setCancelling(false);
          const { errors } = errorOrSuccess?.res ?? {};
          if (errors && errors?.length) {
            dispatchSnackbar({
              msg: 'Failed to cancel ticket.',
              status: SnackbarStatus.error,
              version: SnackbarVersion.v3,
            });
            eventAnalytics.confirmCancellationScreen.cancellationFailed(
              eventId,
              ticketId,
              reason,
            );
          } else {
            eventAnalytics.confirmCancellationScreen.cancellationSuccessful(
              eventId,
              ticketId,
              reason,
            );
            navigation.navigate('eventCancellationStatus', {
              invoiceId: invoice?._id,
              isRefundable: isRefundableIfCancelled,
            });
          }
        },
      );
    } else {
      dispatchSnackbar({
        msg: 'No reason selected',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v3,
      });
    }
  };

  const handleOtherReasonChange = (value: string) => {
    setOtherCancellationReason(value);
  };

  const handleOtherReasonValidation = () => {
    if (otherCancellationReason.trim().length === 0) {
      setReasonValidationError('This field must not be empty');
      return;
    }
    if (otherCancellationReason.length < 10) {
      setReasonValidationError('Reason must not be less than 10 characters');
    }
  };

  const clearOtherReasonValidation = () => {
    setReasonValidationError('');
  };

  return {
    bsRef,
    bsMode,
    eventId,
    ticketId,
    radioConfig,
    selectedReason,
    isRefundableIfCancelled,
    otherCancellationReason,
    reasonValidationError,
    amountSummary: {
      paidAmount: totalAmountPaid,
      cancellationCharge,
      refundAmount,
    },
    amountBreakUp: {
      upiAmount: netAmount,
      pointsAmount,
      billAmount: totalAmountPaid,
    },
    proceedCTAState: getProceedCtaState(),
    onBsDismiss,
    showBreakUpBs,
    handleOtherReasonChange,
    handleOtherReasonValidation,
    clearOtherReasonValidation,
    showCancellationReasonsBs,
    handleCancellationRadioSelection,
    handleCancellationReasonApply,
    handleCancellation,
  };
};

export default useEventConfirmCancellationScreen;
