import React, { Suspense, useContext, useState } from 'react';
import { ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import CashierCarSearchScreenInner from './CashierCarSearchScreenInner';
import { Layout } from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import { Loading } from '../../../components';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import useCommonStore from '../../../stores/commonStore';
import useUserStore from '../../../stores/userStore';

const CashierCarSearchScreen = () => {
  const userRole = useUserStore((state) => state.role);
  const campusID = useCommonStore((state) => state.campusId);

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [netStatus, setNetStatus] = useState(true);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout style={{ flex: 1 }} level={1}>
      <SafeAreaView edges={['top']} />
      <ScrollView>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <CashierCarSearchScreenInner role={userRole} campusId={campusID} />
          </Suspense>
        </NewErrorBoundary>
      </ScrollView>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
        bottomNavigator
      />
    </Layout>
  );
};

export default CashierCarSearchScreen;
