import AsyncStorage from '@react-native-async-storage/async-storage';
import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

export interface PaymentModalStoreInterface {
  isOpen: boolean;
  chargesData: string;
  parkingID: string;
  paymentSuccess: boolean;
  entryTime: string;
  recallTime: string;
  additionalData: string;
  claimDelayCompensation: boolean;
  parkingCharges: string;
  taskTypeRecall: boolean;
  setIsOpen: (open: boolean) => void;
  setChargesData: (chargesData: string) => void;
  setParkingID: (parkingID: string) => void;
  setEntryTime: (entryTime: string) => void;
  setRecallTime: (recallTime: string) => void;
  setAdditionalData: (additionalData: string) => void;
  setPaymentSuccess: (paymentSuccess: boolean) => void;
  setClaimDelayCompensation: (claimDelayCompensation: boolean) => void;
  setParkingCharges: (parkingCharges: string) => void;
  setTaskTypeRecall: (taskTypeRecall: boolean) => void;
  setToDefault: () => void;
}

const usePaymentModalStore = create<PaymentModalStoreInterface>()(
  devtools(
    persist(
      (set) => ({
        isOpen: false,
        chargesData: '',
        parkingID: '',
        entryTime: '',
        recallTime: '',
        additionalData: '',
        paymentSuccess: false,
        claimDelayCompensation: false,
        parkingCharges: '',
        taskTypeRecall: false,
        setIsOpen: (open: boolean) =>
          set((state) => {
            return {
              isOpen: open,
            };
          }),
        setChargesData: (charges: string) =>
          set((state) => {
            return {
              chargesData: charges,
            };
          }),
        setParkingID: (id: string) =>
          set((state) => {
            return {
              parkingID: id,
            };
          }),
        setEntryTime: (entry: string) =>
          set((state) => {
            return {
              entryTime: entry,
            };
          }),
        setRecallTime: (recall: string) =>
          set((state) => {
            return {
              recallTime: recall,
            };
          }),
        setPaymentSuccess: (success: boolean) =>
          set((state) => {
            return {
              paymentSuccess: success,
            };
          }),
        setAdditionalData: (data: string) =>
          set((state) => {
            return {
              additionalData: data,
            };
          }),
        setClaimDelayCompensation: (claimDelayCompensation: boolean) =>
          set((state) => {
            return {
              claimDelayCompensation,
            };
          }),
        setParkingCharges: (parkingCharges: string) =>
          set((state) => {
            return {
              parkingCharges,
            };
          }),
        setTaskTypeRecall: (taskTypeRecall: boolean) =>
          set((state) => {
            return {
              taskTypeRecall,
            };
          }),
        setToDefault: () =>
          set(() => ({
            isOpen: false,
            chargesData: '',
            parkingID: '',
            additionalData: '',
            paymentSuccess: false,
          })),
      }),
      {
        name: 'FeedbackModalStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
  ),
);

export default usePaymentModalStore;
