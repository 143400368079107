import React from 'react';
import { Text, View } from '../../../components/new';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { FontWeights } from '../../../themes/new/helper';

interface EmptyListViewProps {
  label: string;
  msg: string;
}

const EmptyListView = ({ label, msg }: EmptyListViewProps) => {
  return (
    <View>
      <View mb="6xl" alignItems="center">
        <CustomLottieView
          style={{ width: 200, height: 200 }}
          autoPlay
          loop
          source={require('../../../../assets/lottie/no-transactions.json')}
          containerWidth={200}
        />
      </View>
      <Text
        mb="lg"
        size="2xl"
        weight={FontWeights.MEDIUM}
        color="primary.400"
        textAlign="center"
      >
        {label}
      </Text>
      <Text size="md" weight="regular" color="primary.200" textAlign="center">
        {msg}
      </Text>
    </View>
  );
};

export default EmptyListView;
