import { graphql, commitMutation } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

export const DetailsPageQuery = graphql`
  query DetailsPageQuery(
    $_id: MongoID!
    $filter: findInvestmentLeadInput
    $includeFindInvLead: Boolean!
    $sort: SortFindManyInvestmentPropertyLeadsGenerateModelInput
  ) {
    findInvestmentLead(filter: $filter, sort: $sort)
      @include(if: $includeFindInvLead) {
      sfdcLeadId
      status
      _id
      investmentPropertyId
      investmentDetails {
        investmentDocs {
          offerLetter
          termsDoc
          agreementDoc
        }
        region
        investmentManagerDetails {
          name
          profileImage
          intro
          contact {
            phone
            whatsapp
          }
        }
      }
    }
    findInvestmentProperty(_id: $_id) {
      investmentType
      intro {
        heading
        subHeading
        media {
          autoPlayable
          title
          description
          cover
          displayOrder
          mediaType
          mediaURL
          thumbnailURL
          _id
        }
        cover {
          autoPlayable
          title
          description
          cover
          displayOrder
          mediaType
          mediaURL
          thumbnailURL
          _id
        }
      }
      opportunity {
        heading
        subHeading
        usp {
          heading
          subHeading
          media {
            autoPlayable
            title
            description
            cover
            displayOrder
            mediaType
            mediaURL
            thumbnailURL
            _id
          }
          _id
        }
      }
      about {
        name
        typeformId
        highlights {
          heading
          subHeading
          media {
            autoPlayable
            title
            description
            cover
            displayOrder
            mediaType
            mediaURL
            thumbnailURL
            _id
          }
          _id
        }
      }
      location {
        heading
        subHeading
        media {
          autoPlayable
          title
          description
          cover
          displayOrder
          mediaType
          mediaURL
          thumbnailURL
          _id
        }
        nearbyPlaces {
          icon
          title
          subtitle
          _id
        }
        name
        geo {
          lat
          long
        }
      }
      propertyViewCount
      gallery {
        heading
        subHeading
        media {
          autoPlayable
          title
          description
          cover
          displayOrder
          mediaType
          mediaURL
          thumbnailURL
          _id
        }
      }
      process {
        heading
        subHeading
        flow {
          heading
          subHeading
          _id
        }
      }
      press {
        heading
        subheading
        articles {
          title
          description
          image {
            autoPlayable
            title
            description
            cover
            displayOrder
            mediaType
            mediaURL
            thumbnailURL
            _id
          }
          url
          readTime
          _id
        }
      }
      downloads {
        heading
        subHeading
        documents {
          type
          link
          icon
          name
          description
          _id
        }
      }
      project {
        managedBy {
          name
          promise {
            heading
            subHeading
            values {
              heading
              subHeading
              icon
              _id
            }
            _id
          }
          about {
            heading
            subHeading
            media {
              autoPlayable
              title
              description
              cover
              displayOrder
              mediaType
              mediaURL
              thumbnailURL
              _id
            }
            _id
          }
          _id
        }
        designer
      }
      investment {
        heading
        subHeading
        data {
          investmentAmount {
            min
            max
            default
          }
          investment {
            years {
              min
              max
              default
            }
            units {
              totalUnits
              activeUnits
              soldUnits
              perUnitAmount
            }
            roi {
              rentalYieldPostOps
              rentalYieldPreOps
              capitalAppriciation
              xirr {
                year
                percentage
                _id
              }
            }
            assuredBuyBack
          }
          application {
            applied
            amount
          }
          _id
        }
      }
      cta {
        heading
        subHeading
      }
      partners {
        name
        image
        _id
      }
      amenities {
        heading
        subHeading
        media {
          autoPlayable
          title
          description
          cover
          displayOrder
          mediaType
          mediaURL
          thumbnailURL
          _id
        }
      }
      subscriptionDetails {
        heading
        subHeading
        values {
          header
          details {
            key
            value
            _id
          }
        }
        documents {
          type
          link
          icon
          name
          description
          _id
        }
      }
      _id
    }
  }
`;

export const GenerateInvestmentLead = (data, callback, errorCallBack) => {
  const mutation = graphql`
    mutation DetailsPageQueryGenerateInvestmentLeadMutation(
      $record: GenerateInvestmentLeadRecordInput!
    ) {
      generateInvestmentLead(record: $record) {
        record {
          _id
          sfdcLeadId
          investmentPropertyId
          status
          investmentDetails {
            investmentDocs {
              offerLetter
              termsDoc
              agreementDoc
            }
            region
            investmentManagerDetails {
              name
              profileImage
              intro
              contact {
                phone
                whatsapp
              }
            }
          }
        }
        error {
          message
        }
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallBack(err);
    },
  });
};
