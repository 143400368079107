import React from 'react';
import { ImageBackground, ImageBackgroundProps } from 'expo-image';

const CachedImageBackground = (props: ImageBackgroundProps) => {
  const propsDefault: ImageBackgroundProps = {
    cachePolicy: 'disk',
    ...props,
  };
  return <ImageBackground {...propsDefault} />;
};

export default CachedImageBackground;
