import { graphql, requestSubscription } from 'react-relay';
import environment from './relayEnvironment';

const subscriptionUpdateTask = graphql`
  subscription taskUpdatedsubscriptionApiSubscription(
    $campusId: ID!
    $parkingLocationId: ID
    $userId: ID!
    $history: [EnumtaskHistoryAction]
    $event_at: Date
  ) {
    taskUpdated(
      campusId: $campusId
      parkingLocationId: $parkingLocationId
      userId: $userId
      history: $history
      event_at: $event_at
    ) {
      _id
      vehicle {
        registration {
          plate
        }
        brand {
          name
          brandLogo
        }
        color
      }
      ETA {
        delayAt
        createToArrivedTime
        createToAcceptedTime
        acceptedToArrivedTime
        acceptedToArrivedCustomerTime
      }
      history {
        event_at
        action
      }
      taskStartLocation {
        name
        locationId
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
          }
        }
      }
      assignedTo
      taskType
      user {
        name {
          first
          last
          _id
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      valet {
        contact {
          phone {
            prefix
            number
          }
        }
        name {
          first
          last
        }
        profileImage
      }
      isVehicleWhitelisted
      parkingId
      parkingTag
      parking {
        parked_at
        parkingTag
        feedback {
          feedbacks {
            rating
          }
        }
        invoicesData {
          priceDetails {
            discountGiven
            rewardsUsed
          }
        }
        parkingInvoice {
          paymentDetails {
            isPaid
            amount
          }
          charges {
            chargesBreakup {
              duration
              payable
            }
            discountAmount
            totalPayable
            valetTip
          }
          invoicePdfPath
        }
        pointsData {
          source {
            type
          }
          amount
        }
      }
      ownerId
      campus {
        _id
        name
        contact {
          phone {
            prefix
            number
          }
        }
      }
      createdByUser {
        name {
          first
          _id
        }
        role {
          name
        }
      }
      points {
        _id
        amount
        type
        source {
          type
        }
      }
    }
  }
`;

export default (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: subscriptionUpdateTask,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      console.log('error 2', error);
      // console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
