import React from 'react';
import { Image, Linking } from 'react-native';
import { Button, Layout, Text, View } from '../../../components/new';
import { getPhoneNumberFromCampusObj } from '../../../utilities/helper';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import useCommonStore from '../../../stores/commonStore';

const NoVehicleFoundScreen = ({ navigation, retry, fetchKey }) => {
  const campusDetails = useCommonStore((state) => state.campusDetails);
  const phoneNumber = getPhoneNumberFromCampusObj(campusDetails);

  const handleCallValetDesk = () => {
    firebaseEventLogger('noVehicle__callValet_Tap', {
      buttonName: 'callValet',
      screenName: 'noVehicle',
      userType: 'user',
      interactionType: 'tap',
    });
    Linking.openURL(`tel:${phoneNumber}`);
  };

  return (
    <Layout level={2} edges={['bottom']}>
      <View px="2xl" flex={1}>
        <View mb="9xl+9xl">{/* <Header>32nd</Header> */}</View>

        <View mb="lg">
          <Image
            source={require('../../../../assets/images/no-data-found.webp')}
            style={{
              width: 250,
              height: 200,
              alignSelf: 'center',
            }}
          />
        </View>
        <View mb="9xl">
          <Text
            color="primary.500"
            size="md"
            textAlign="center"
            mb="lg"
            weight="medium"
          >
            No Vehicle Found
          </Text>
          <Text color="primary.100" size="md" textAlign="center">
            Contact the valet desk for further help
          </Text>
        </View>
        {/* <View mb="2xl">
          <Button size="lg" onPress={handleSearch}>
            Track Via Tag ID
          </Button>
        </View> */}
        <View>
          <Button size="lg" appearance="outline" onPress={handleCallValetDesk}>
            Call Valet Desk
          </Button>
        </View>
      </View>
    </Layout>
  );
};

export default NoVehicleFoundScreen;
