import React, { useRef, useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import {
  Button,
  Chip,
  IconButton,
  Input,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import useBoundStore from '../../../stores';
import { navigateBack } from '../../../utilities/helper';
import { addParkingFeedback } from '../../../relay/parkingFeedbackApi';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import useUserStore from '../../../stores/userStore';

const data = [
  { title: 'SERVICE', id: 0 },
  { title: 'TIME TAKEN', id: 1 },
  { title: 'UNFAIR TIP', id: 2 },
  { title: 'PRICING ISSUE', id: 3 },
  { title: 'CAR DAMAGE', id: 4 },
  { title: 'Valet Behaviour', id: 5 },
];

function ParkingFeedbackScreen({ navigation, route }) {
  const { rating, parkingId, taskId } = route.params;
  const [feedbackText, setFeedbackText] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const inputRef = useRef(null);
  const scrollViewRef = useRef(null);
  const userId = useUserStore((state) => state.id);
  const userRole = useUserStore((state) => state.role);

  const { setFeedback } = useBoundStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const chipSelectHandler = (chip) => {
    let index = -1;
    for (let i = 0; i < selectedChips.length; i += 1) {
      if (selectedChips[i].id === chip.id) {
        index = i;
        break;
      }
    }
    if (index === -1) {
      if (selectedChips.length === 3) {
        dispatchSnackbar({
          msg: 'Maximum 3 chip selections allowed',
          status: SnackbarStatus.info,
          version: SnackbarVersion.v2,
        });
        return;
      }
      setSelectedChips([...selectedChips, chip]);
    } else {
      const selectedArray = JSON.parse(JSON.stringify(selectedChips));
      selectedArray.splice(index, 1);
      setSelectedChips(selectedArray);
    }
  };

  const getState = (chip) => {
    let isSelected = false;
    for (let i = 0; i < selectedChips.length; i += 1) {
      if (selectedChips[i].id === chip.id) {
        isSelected = true;
        break;
      }
    }
    if (isSelected) {
      return 'selected';
    }
    return 'active';
  };

  // const handleOnBlur = () => {
  //   console.log('handleOnBlur');
  // };

  const feedbackSubmitted = (res) => {
    setFeedback({ status: 'rated', taskId });
    setFeedbackText('');
    setSelectedChips([]);
    // navigation.navigate('trackYourCar', { taskId });
  };

  const focusHandler = () => {
    if (scrollViewRef.current) {
      setTimeout(() => {
        scrollViewRef.current.scrollToEnd();
      }, 400);
    }
  };

  const submitHandler = () => {
    if (selectedChips.length) {
      const feedbackFor = selectedChips.reduce((a, c) => {
        return [...a, c.title];
      }, []);
      const feedbackData = {
        record: {
          ownerId: userId,
          feedback: {
            feedbackFor,
            description: feedbackText,
            rating,
          },
        },
        id: parkingId,
      };
      addParkingFeedback(feedbackData, feedbackSubmitted, (err) => {
        // console.log(err)
      });
    } else {
      dispatchSnackbar({
        msg: 'Please select a chip option to continue.',
        status: SnackbarStatus.info,
        version: SnackbarVersion.v2,
      });
    }
  };

  return (
    // <View flex={1}>
    <Layout level={2}>
      <TopNavigation
        title="Feedback"
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, userRole)}
          />
        }
      />
      <KeyboardAvoidingView
        enabled={Platform.OS === 'ios'}
        behavior="padding"
        style={{ flex: 1 }}
      >
        <ScrollView
          ref={scrollViewRef}
          contentContainerStyle={{
            paddingHorizontal: 16,
            flexGrow: 1,
          }}
          bounces
          keyboardShouldPersistTaps="handled"
        >
          <View flex={1} mb={50}>
            <Text size="lg" color="primary.400" mt="2xl" mb="lg">
              We&#39;re sorry that we let you down, and we want to make things
              right.
            </Text>
            <Text size="sm" color="primary.100" mb="4xl">
              Please provide us feedback, and tell us what can be improved?
            </Text>
            <View flexDirection="row" flexWrap="wrap" mb="xl">
              {data.map((d) => {
                return (
                  <Chip
                    state={getState(d)}
                    key={d.id}
                    onPress={() => chipSelectHandler(d)}
                    size="xs"
                    iconLeft=""
                    iconRight=""
                    m="sm"
                  >
                    {d.title}
                  </Chip>
                );
              })}
            </View>
            <Input
              multiline
              numberOfLines={3}
              onFocus={focusHandler}
              // onBlur={handleOnBlur}
              // autoFocus={
              //   openKeyboardOnMount ||
              //   openKeyboardOnMountParam ||
              //   searchInputValue
              // }
              inputRef={inputRef}
              placeholder="Describe your issue..."
              value={feedbackText}
              onChangeText={setFeedbackText}
              style={{ height: 87 }}
              // autoFocus
            />
          </View>
        </ScrollView>
        <View px="2xl">
          <Button mt="4xl" size="lg" mb="7xl" onPress={submitHandler}>
            Submit Feedback
          </Button>
        </View>
      </KeyboardAvoidingView>
    </Layout>
    // </View>
  );
}

export default ParkingFeedbackScreen;
