import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const claimTicket = (data, callback, errorCallBack) => {
  const mutation = graphql`
    mutation claimApiTicketMutation(
      $filter: claimTicketfilterInput
      $sort: SortFindOneticketInput
    ) {
      claimTicket(filter: $filter, sort: $sort) {
        _id
        ticketType
        thumbnailImg
        uId
        expires_on
        title
        issuedBy {
          refType
          _id
        }
        status
        additionalFields {
          event {
            startTime
            endTime
            spaces
          }
        }
        canBeClaimedBy {
          venue {
            geo {
              lat
              long
            }
            addressLine1
          }
          displayName
        }
        finePrint {
          summary
          details
        }
        rewardId
        user {
          name {
            first
            last
          }
        }
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallBack(err);
    },
  });
};
