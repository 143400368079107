/**
 * @generated SignedSource<<60324a003f1147b169b1f24d7768ffff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumParkingStatus = "FAULTY_PARK" | "FAULTY_RECALL" | "INCOMING" | "MISSED_EXIT" | "OUT" | "OUTGOING" | "PARKED" | "%future added value";
export type findParkingsFilterInput = {
  _id?: any | null;
  campus?: FilterFindManyParkingCampusInput | null;
  dateRange?: findParkingsFilterInputDateRange | null;
  ownerId?: any | null;
  parkingHistory?: boolean | null;
  parkingLocation?: FilterFindManyParkingParkingLocationInput | null;
  status?: ReadonlyArray<EnumParkingStatus | null> | null;
  vehicle?: FilterFindManyParkingVehicleInput | null;
};
export type FilterFindManyParkingVehicleInput = {
  registration: FilterFindManyParkingVehicleRegistrationInput;
};
export type FilterFindManyParkingVehicleRegistrationInput = {
  plate: string;
};
export type FilterFindManyParkingParkingLocationInput = {
  _id?: any | null;
  locationId?: any | null;
  parkingSpaces?: FilterFindManyParkingParkingLocationParkingSpacesInput | null;
};
export type FilterFindManyParkingParkingLocationParkingSpacesInput = {
  floor?: FilterFindManyParkingParkingLocationParkingSpacesFloorInput | null;
};
export type FilterFindManyParkingParkingLocationParkingSpacesFloorInput = {
  _id?: any | null;
  bay?: FilterFindManyParkingParkingLocationParkingSpacesFloorBayInput | null;
  floorId?: any | null;
};
export type FilterFindManyParkingParkingLocationParkingSpacesFloorBayInput = {
  _id?: any | null;
  bayId?: any | null;
};
export type FilterFindManyParkingCampusInput = {
  _id: any;
};
export type findParkingsFilterInputDateRange = {
  from: string;
  to: string;
};
export type AddParkingDetailsScreenQuery$variables = {
  count: number;
  parkingFilter: findParkingsFilterInput;
};
export type AddParkingDetailsScreenQuery$data = {
  readonly findParking: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly parkingLocation: {
          readonly _id: any | null;
          readonly locationId: any | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly _id: any | null;
              readonly bay: {
                readonly _id: any | null;
                readonly bayId: any | null;
              } | null;
              readonly floorId: any | null;
            } | null;
          } | null;
        } | null;
      };
    }>;
  } | null;
};
export type AddParkingDetailsScreenQuery = {
  response: AddParkingDetailsScreenQuery$data;
  variables: AddParkingDetailsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parkingFilter"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = [
  {
    "alias": "findParking",
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "parkingFilter"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "count"
      }
    ],
    "concreteType": "ParkingConnection",
    "kind": "LinkedField",
    "name": "findParkingV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ParkingEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Parking",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingParkingLocation",
                "kind": "LinkedField",
                "name": "parkingLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParkingParkingLocationParkingSpaces",
                    "kind": "LinkedField",
                    "name": "parkingSpaces",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ParkingParkingLocationParkingSpacesFloor",
                        "kind": "LinkedField",
                        "name": "floor",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "floorId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParkingParkingLocationParkingSpacesFloorBay",
                            "kind": "LinkedField",
                            "name": "bay",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bayId",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddParkingDetailsScreenQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddParkingDetailsScreenQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e33413ef596727152731b68108bebe94",
    "id": null,
    "metadata": {},
    "name": "AddParkingDetailsScreenQuery",
    "operationKind": "query",
    "text": "query AddParkingDetailsScreenQuery(\n  $parkingFilter: findParkingsFilterInput!\n  $count: Int!\n) {\n  findParking: findParkingV2(filter: $parkingFilter, first: $count) {\n    edges {\n      node {\n        _id\n        parkingLocation {\n          locationId\n          parkingSpaces {\n            floor {\n              floorId\n              bay {\n                bayId\n                _id\n              }\n              _id\n            }\n          }\n          _id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "852011a8c7b594d8710e5e729df2b035";

export default node;
