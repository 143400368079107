/* eslint-disable no-unsafe-optional-chaining */
import React, { Suspense, useCallback, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { FlatList, Pressable } from 'react-native';
import {
  Avatar,
  Button,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import Loading from '../../components/loading.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import {
  getFormatedDate,
  navigateBack,
  DateFormatsEnum,
} from '../../utilities/helper';
import useUserStore from '../../stores/userStore';
import RewardPointsCard from '../../components/new/custom/RewardPointCard';
import Icon from '../../components/new/primitive/Icon/Icon';
import { isValueNullOrEmpty } from '../../utilities/Utility';
import useAuthStore from '../../stores/authStore';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import useLoginModalStore from '../../stores/loginModalStore';
import useCommonStore, { faqData } from '../../stores/commonStore';
import AppConstants from '../../utilities/AppConstants';
import CachedImage from '../../components/new/custom/CachedImage';

const rewardPointHistoryScreenScreenQuery = graphql`
  query RewardPointHistoryScreenQuery(
    $userId: MongoID!
    $filter: rewardHistoryFilterTC
    $sort: WalletHistorySortEnum
  ) {
    findUserById(_id: $userId) {
      _id
      wallet {
        totalBalance
        currentBalance
      }
    }
    getWalletHistory(filter: $filter, sort: $sort) {
      userId
      type
      amount
      created_at
      source {
        type
        name
      }
    }
  }
`;

const RenderPointsScreen = (props) => {
  const { variables, refreshedQueryOptions } = props;
  const { findUserById, getWalletHistory } =
    //   eslint-disable-next-line react-hooks/rules-of-hooks
    useLazyLoadQuery(rewardPointHistoryScreenScreenQuery, variables, {
      ...refreshedQueryOptions,
      networkCacheConfig: { force: true },
    });
  const { wallet } = findUserById ?? {};

  const RenderItem = ({ item, index }) => {
    const { type, amount, created_at, source } = item ?? {};
    const formattedDate = new Date(created_at);
    return (
      <View
        borderRadius="md"
        borderWidth="xs"
        borderColor="primary.50"
        backgroundColor="primary.10"
        px="2xl"
        py="lg"
        flexDirection="row"
        alignItems="center"
        mt="2xl"
        mb={index === getWalletHistory?.length - 1 ? '4xl' : 0}
      >
        <View
          borderRadius="full"
          borderWidth="xs"
          borderColor="primary.50"
          alignItems="center"
          justifyContent="center"
          p="lg"
        >
          <Icon name="logo-400" size="2xl" color="primary.500" />
        </View>
        <View ml="2xl">
          <Text size="xs" color="primary.500">
            {type === 'CREDIT' ? 'Earned' : 'Spent'} : {source?.name}
          </Text>
          <Text size="xs" color="primary.100" mt="xs">
            {getFormatedDate(formattedDate, DateFormatsEnum.DateMonthYear)} |{' '}
            {getFormatedDate(formattedDate, DateFormatsEnum.TIME)}
          </Text>
        </View>
        <View
          position="absolute"
          right="2xl"
          flexDirection="row"
          alignItems="center"
        >
          <Text
            size="md"
            color={type === 'DEBIT' ? 'error.500' : 'success.500'}
            mr="sm"
          >
            {amount}
          </Text>
          <CachedImage
            source={require('../../../assets/images/coins.webp')}
            style={{ width: 16, height: 16, resizeMode: 'contain' }}
          />
        </View>
      </View>
    );
  };

  const RenderPointCard = () => {
    const amountSaved = !isValueNullOrEmpty(wallet)
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        wallet?.totalBalance - wallet?.currentBalance
      : 0;
    const pointsEarned = wallet?.totalBalance;
    const pointsLeft = wallet?.currentBalance;

    return (
      <RewardPointsCard
        amountSaved={amountSaved}
        pointsEarned={pointsEarned}
        pointsLeft={pointsLeft}
      />
    );
  };

  return (
    <View px="2xl" flex={1}>
      <View mt="lg">
        <RenderPointCard />
      </View>
      <View mt="4xl" flex={1}>
        <Text size="md" color="primary.500" category="button">
          Points Activity
        </Text>
        <FlatList
          data={getWalletHistory}
          renderItem={RenderItem}
          horizontal={false}
          showsVerticalScrollIndicator={false}
        />
      </View>
    </View>
  );
};

const RewardPointHistoryScreen = ({ navigation, route }) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const userId = useUserStore((state) => state.id);
  const userRole = useUserStore((state) => state.role);
  const { profileImage, firstName, lastName } = useUserStore((state) => state);
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const { IMAGEKIT_PROD_URI } = AppConstants;

  const imageLink = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';

  const onLoginButtonPress = useCallback(() => {
    setAuthModalOpen(true);
  }, []);
  const sort = 'DESC';
  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const NotLoggedIn = () => {
    const setFaqData = useCommonStore((state) => state.setFaqScreenData);
    return (
      <View flex={1} justifyContent="center" px="2xl">
        <View alignItems="center">
          <CachedImage
            source={{
              uri: 'https://ik.imagekit.io/32ndhub/assets/coins_and_notes.png',
            }}
            style={{
              width: 170,
              height: 160,
              resizeMode: 'contain',
            }}
          />
          <View
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            my="4xl"
          >
            <View flexDirection="row" alignItems="center">
              <Text size="2xl" weight="medium" color="primary.500" mr="sm">
                1
              </Text>
              <CachedImage
                source={require('../../../assets/images/coins.webp')}
                style={{ width: 20, height: 20, top: 2, resizeMode: 'contain' }}
              />
            </View>
            <Text size="2xl" weight="medium" color="primary.500" mx="xl">
              =
            </Text>
            <View flexDirection="row" alignItems="center">
              <Icon name="rupee-outline-300" color="primary.500" size="2xl" />
              <Text size="2xl" weight="medium" color="primary.500" ml="sm">
                1
              </Text>
            </View>
          </View>
          <View
            width="100%"
            p="lg"
            backgroundColor="opacity.95"
            borderColor="primary.50"
            borderWidth="xs"
            borderRadius="md"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            onStartShouldSetResponder={() => {
              firebaseEventLogger('rewardsScreen_info_tap', {
                buttonName: 'how_to_earn_and_redeem_points',
                screenName: 'rewardsScreen',
                userType: 'user',
                interactionType: 'tap',
              });
              setFaqData(faqData);
              navigation.navigate('faq');
            }}
          >
            <Text size="xs" color="primary.300">
              How to earn & redeem points
            </Text>
            <Icon name="info-outline-300" color="info.500" size="md" />
          </View>
          <View width="100%" mt="4xl">
            <Button
              size="lg"
              onPress={() => {
                onLoginButtonPress();
              }}
            >
              Login To Earn Upto 200 Points
            </Button>
          </View>
        </View>
      </View>
    );
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconRight={
          <Pressable
            onPress={() => {
              navigation.navigate('account');
            }}
            hitSlop={{
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            }}
          >
            <View mr="lg">
              <Avatar
                level={1}
                source={imageLink}
                resizeMode="cover"
                name={`${firstName ?? ''} ${lastName ?? ''}`}
              />
            </View>
          </Pressable>
        }
        title="32nd Points"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          {isLoggedIn ? (
            <RenderPointsScreen
              variables={{
                filter: {},
                userId,
                sort,
              }}
              fetchKey={refreshedQueryOptions?.fetchKey}
              refreshedQueryOptions={refreshedQueryOptions}
            />
          ) : (
            <NotLoggedIn />
          )}
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default RewardPointHistoryScreen;
