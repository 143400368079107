import React from 'react';
import { View } from 'react-native';
import CachedImage from './new/custom/CachedImage';

const Loading = ({ background = null, ...props }) => {
  return (
    <View
      style={{
        width: '100%',
        flex: 1,
        backgroundColor: background,
        zIndex: 99999,
        justifyContent: 'center',
        alignItems: 'center',
        // opacity: 0.6,
        position: 'absolute',
        bottom: 0,
        top: 0,
      }}
    >
      <View>
        <CachedImage
          style={{ width: 100, height: 100, resizeMode: 'contain' }}
          source={require('../../assets/loading-gif.gif')}
        />
      </View>
    </View>
  );
};

export default Loading;
