import React from 'react';
import { StyleSheet, View } from 'react-native';
import { getOccupancyPercentageColor } from '../utilities/helper';
import { getTheme } from '../themes/new/theme';
import { Text } from './new';
import DotComponent from './dot.component';

type OwnProps = {
  findParkingLocation?: any; // TODO: propTypes.instanceOf(Object)
  screenName?: string;
  showVerticalLine?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ParkingLocationInfo.defaultProps;

const ParkingLocationInfo = (props: Props) => {
  const { findParkingLocation, screenName, showVerticalLine } = props;
  let totalParkingSpots = 0;
  let totalParkingOccupied = 0;
  let occupancyPercentage = 0;
  const floorDetail = findParkingLocation?.parkingSpaces?.floor || [];

  floorDetail.forEach((floorElement) => {
    (floorElement.bay || []).forEach((bayDetail) => {
      totalParkingSpots += bayDetail.parkingSpots;
      totalParkingOccupied += bayDetail.parkingOccupied;
    });
  });

  occupancyPercentage = Math.floor(
    (totalParkingOccupied / totalParkingSpots) * 100,
  );

  const dotComponent = getOccupancyPercentageColor(
    occupancyPercentage,
    0,
    getTheme().colors.primary[50],
  );

  if (totalParkingSpots === 0 && totalParkingOccupied === 0) {
    return <></>;
  }
  return (
    <View
      style={[
        screenName === 'parkingMapScreen'
          ? { paddingRight: 10 }
          : {
              flex: 1,
              borderRightWidth: showVerticalLine ? 1 : 0,
              borderRightColor: getTheme().colors.grey[10],
            },
      ]}
    >
      <Text size="lg" color="primary.500" textAlign="center">
        {totalParkingOccupied}/{totalParkingSpots}
      </Text>
      <View>
        {screenName === 'parkingMapScreen' ? null : (
          <Text
            style={[styles.locationTitle]}
            category="c1"
            appearance="subdued"
          >
            {findParkingLocation.name}
          </Text>
        )}

        <View style={[styles.dotContainer]}>
          <DotComponent
            totalDot={4}
            totalColorDot={dotComponent.totalColorDot}
            dotColor={dotComponent.dotColor}
            paginationDotSize={
              screenName === 'parkingMapScreen'
                ? { width: 5, height: 5 }
                : { width: 8, height: 8 }
            }
          />
        </View>
      </View>
    </View>
  );
};

export default ParkingLocationInfo;

ParkingLocationInfo.defaultProps = {
  findParkingLocation: {},
  screenName: '',
  showVerticalLine: true,
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  card: {
    paddingBottom: 10,
  },
  locationTitle: {
    alignSelf: 'center',
    textAlign: 'center',
    marginRight: 2,
    color: '#0d0d0d',
    opacity: 0.4,
  },
  dotContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    fontSize: 12,
    lineHeight: 18,
    marginTop: 4,
  },
});
