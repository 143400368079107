/**
 * @generated SignedSource<<8622b7ce88e1c3543ce97f8ba2ba32a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnuminvoiceOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnuminvoicePaymentType = "BILL_UPLOAD" | "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnuminvoicePopUpStoreDetailsDeliveryStatus = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type partnerHomeScreenQuery__paymentHistoryFragment$data = ReadonlyArray<{
  readonly _id: any;
  readonly created_at: any | null;
  readonly orderId: any;
  readonly orderType: EnuminvoiceOrderType;
  readonly paymentDetails: {
    readonly paytmQr: {
      readonly transactionId: string | null;
    } | null;
    readonly razorpay: {
      readonly pgOrderId: string;
    } | null;
  } | null;
  readonly paymentType: EnuminvoicePaymentType | null;
  readonly popUpStoreDetails: {
    readonly deliveryStatus: EnuminvoicePopUpStoreDetailsDeliveryStatus | null;
    readonly displayName: string;
  } | null;
  readonly priceDetails: {
    readonly grossAmount: number;
  };
  readonly status: EnuminvoiceStatus | null;
  readonly user: {
    readonly _id: any;
    readonly contact: {
      readonly phone: {
        readonly number: string;
        readonly prefix: string;
      };
    };
  } | null;
  readonly " $fragmentType": "partnerHomeScreenQuery__paymentHistoryFragment";
}>;
export type partnerHomeScreenQuery__paymentHistoryFragment$key = ReadonlyArray<{
  readonly " $data"?: partnerHomeScreenQuery__paymentHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"partnerHomeScreenQuery__paymentHistoryFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "partnerHomeScreenQuery__paymentHistoryFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "invoicePriceDetails",
      "kind": "LinkedField",
      "name": "priceDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserContact",
          "kind": "LinkedField",
          "name": "contact",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserContactPhone",
              "kind": "LinkedField",
              "name": "phone",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "prefix",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "invoicePopUpStoreDetails",
      "kind": "LinkedField",
      "name": "popUpStoreDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiePaymentDetailsTC",
      "kind": "LinkedField",
      "name": "paymentDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "payment",
          "kind": "LinkedField",
          "name": "razorpay",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pgOrderId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaytmDqrOrders",
          "kind": "LinkedField",
          "name": "paytmQr",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transactionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "invoice",
  "abstractKey": null
};
})();

(node as any).hash = "c165e382b4bd3666a78db9545d3b1713";

export default node;
