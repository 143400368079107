/**
 * @generated SignedSource<<1d58b85694fef8864e611e785e2df2f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumMenu1MenuItemsItemPriceType = "addon" | "variation" | "%future added value";
export type EnumMenu1MenuItemsMediaType = "gif" | "image" | "loopVideo" | "%future added value";
export type EnumMenu1MenuItemsMenuType = "bar" | "beverages" | "food" | "%future added value";
export type EnumMenu1MenuItemsTaxTaxType = "CGST" | "SGST" | "VAT" | "%future added value";
export type EnumMenu1MenuItemsType = "egg" | "non_veg" | "veg" | "vegan" | "%future added value";
export type findMenu1FilterInput = {
  _id?: any | null;
  menuItems?: ReadonlyArray<FilterFindManyMenu1MenuItemsInput | null> | null;
  restaurantId?: any | null;
};
export type FilterFindManyMenu1MenuItemsInput = {
  available?: boolean | null;
  bestSeller?: boolean | null;
  recomended?: boolean | null;
  title?: string | null;
  type?: EnumMenu1MenuItemsType | null;
};
export type menuScreenQuery$variables = {
  filter: findMenu1FilterInput;
};
export type menuScreenQuery$data = {
  readonly findMenu1: ReadonlyArray<{
    readonly _id: any;
    readonly menuCategory: ReadonlyArray<{
      readonly _id: any | null;
      readonly categoryId: string;
      readonly description: string | null;
      readonly displayOrder: number | null;
      readonly sectionTitle: string;
    } | null> | null;
    readonly menuItems: ReadonlyArray<{
      readonly _id: any | null;
      readonly available: boolean | null;
      readonly bestSeller: boolean | null;
      readonly categoryId: string;
      readonly chefRecomended: boolean | null;
      readonly defaultPrice: number;
      readonly description: {
        readonly summary: string | null;
        readonly tags: ReadonlyArray<string | null> | null;
      };
      readonly displayOrder: number | null;
      readonly itemPrice: ReadonlyArray<{
        readonly _id: any | null;
        readonly category: string;
        readonly item: ReadonlyArray<{
          readonly _id: any | null;
          readonly default: boolean | null;
          readonly price: number | null;
          readonly title: string | null;
        } | null> | null;
        readonly maxSelect: number | null;
        readonly minSelect: number;
        readonly type: EnumMenu1MenuItemsItemPriceType | null;
      } | null> | null;
      readonly media: ReadonlyArray<{
        readonly _id: any | null;
        readonly mediaUrl: string;
        readonly type: EnumMenu1MenuItemsMediaType | null;
      } | null> | null;
      readonly menuType: EnumMenu1MenuItemsMenuType | null;
      readonly newItem: boolean | null;
      readonly posId: string | null;
      readonly recomended: boolean | null;
      readonly spicy: boolean | null;
      readonly tax: ReadonlyArray<{
        readonly _id: any | null;
        readonly posId: string | null;
        readonly taxType: EnumMenu1MenuItemsTaxTaxType | null;
        readonly value: number | null;
      } | null> | null;
      readonly title: string | null;
      readonly type: EnumMenu1MenuItemsType | null;
    } | null> | null;
    readonly restaurant: {
      readonly _id: any;
      readonly address: {
        readonly addressLine1: string;
      };
      readonly contact: {
        readonly phone: ReadonlyArray<{
          readonly _id: any | null;
          readonly number: string;
          readonly prefix: string;
        } | null> | null;
      } | null;
      readonly displayName: string;
      readonly posId: string | null;
    } | null;
    readonly restaurantId: any | null;
  }>;
};
export type menuScreenQuery = {
  response: menuScreenQuery$data;
  variables: menuScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "posId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "Menu1",
    "kind": "LinkedField",
    "name": "findMenu1",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "restaurantId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurants2",
        "kind": "LinkedField",
        "name": "restaurant",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine1",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2ContactPhone",
                "kind": "LinkedField",
                "name": "phone",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prefix",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Menu1MenuCategory",
        "kind": "LinkedField",
        "name": "menuCategory",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sectionTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Menu1MenuItems",
        "kind": "LinkedField",
        "name": "menuItems",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "menuType",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Menu1MenuItemsDescription",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "summary",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Menu1MenuItemsMedia",
            "kind": "LinkedField",
            "name": "media",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mediaUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Menu1MenuItemsTax",
            "kind": "LinkedField",
            "name": "tax",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Menu1MenuItemsItemPrice",
            "kind": "LinkedField",
            "name": "itemPrice",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minSelect",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxSelect",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Menu1MenuItemsItemPriceItem",
                "kind": "LinkedField",
                "name": "item",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "default",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "available",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recomended",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chefRecomended",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bestSeller",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "spicy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newItem",
            "storageKey": null
          },
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "menuScreenQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "menuScreenQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "12095c29c04256c74b96c956544f92ce",
    "id": null,
    "metadata": {},
    "name": "menuScreenQuery",
    "operationKind": "query",
    "text": "query menuScreenQuery(\n  $filter: findMenu1FilterInput!\n) {\n  findMenu1(filter: $filter) {\n    restaurantId\n    restaurant {\n      _id\n      posId\n      displayName\n      address {\n        addressLine1\n      }\n      contact {\n        phone {\n          _id\n          prefix\n          number\n        }\n      }\n    }\n    _id\n    menuCategory {\n      _id\n      categoryId\n      sectionTitle\n      description\n      displayOrder\n    }\n    menuItems {\n      _id\n      posId\n      menuType\n      title\n      description {\n        summary\n        tags\n      }\n      media {\n        _id\n        type\n        mediaUrl\n      }\n      defaultPrice\n      tax {\n        _id\n        posId\n        value\n        taxType\n      }\n      itemPrice {\n        _id\n        category\n        minSelect\n        maxSelect\n        type\n        item {\n          _id\n          title\n          default\n          price\n        }\n      }\n      available\n      type\n      recomended\n      chefRecomended\n      bestSeller\n      spicy\n      newItem\n      displayOrder\n      categoryId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a69a2cb18a2c81c9a87ce1897cd2023";

export default node;
