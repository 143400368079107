import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import NotableMentionCard from '../../components/new/custom/NotableMentionCard';
import { IconButton, Layout, TopNavigation, View } from '../../components/new';
import { navigateBack } from '../../utilities/helper';
import { themeModes, getTheme } from '../../themes/new/theme';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import useUserStore from '../../stores/userStore';

const TimeGuaranteeScreen = ({ navigation, route }) => {
  const [netStatus, setNetStatus] = useState(true);
  const userRole = useUserStore((state) => state.role);

  const RenderTimeGuaranteeScreen = () => {
    const data = [
      {
        id: 0,
        posterURL: require('../../../assets/images/timer.png'),
        title: 'What does this mean?',
        description:
          'You can claim free parking if we don’t deliver your vehicle in time',
      },
      {
        id: 1,
        posterURL: require('../../../assets/images/reedem-user.png'),
        title: 'How to redeem?',
        description:
          'You will receive a free parking ticket, that you can display at the exit gate.',
      },
      {
        id: 2,
        posterURL: require('../../../assets/images/calender.png'),
        title: 'What’s the validity?',
        description: 'Your free parking code is valid for 15 days',
      },
    ];
    return (
      <ThemeConsumer>
        {(theme) => (
          <ThemeProvider
            theme={
              theme.currentThemeMode === themeModes.light
                ? getTheme(themeModes.light)
                : getTheme(themeModes.dark)
            }
          >
            <ScrollView
              contentContainerStyle={{
                padding: getTheme(themeModes.light).space['2xl'],
              }}
            >
              {data.map((item, index) => (
                <View mt={index !== 0 ? '2xl' : 0} key={item.id}>
                  <NotableMentionCard
                    posterURL={item.posterURL}
                    // webURL={item?.webURL}
                    title={item.title}
                    // readingTime={item?.readingTime}
                    description={item.description}
                    navigation={navigation}
                    theme={
                      theme.currentThemeMode === themeModes.light
                        ? getTheme(themeModes.light)
                        : getTheme(themeModes.dark)
                    }
                  />
                </View>
              ))}
            </ScrollView>
          </ThemeProvider>
        )}
      </ThemeConsumer>
    );
  };

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };
  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
        title="Time Guarantee"
      />
      <RenderTimeGuaranteeScreen />
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default TimeGuaranteeScreen;
