/* eslint-disable no-nested-ternary */
import React from 'react';
import { FlatList } from 'react-native';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import Pressable from '../../basic/Pressable/Pressable';
import { getTheme } from '../../../../themes/new/theme';
import IconButton from '../../primitive/IconButton/IconButton';

interface ItemProps {
  id: string;
  state: string;
  selected: boolean;
  name: string;
}
interface RadioChipGroupProps {
  data: ItemProps[];
  title: string;
  onPress: (id: string) => void;
  rightIcon: string;
  rightIconColor: string;
  onPressRightIcon: () => void;
}

const RadioChipGroup = ({
  data = [],
  title = '',
  onPress = () => {},
  rightIcon = '',
  rightIconColor = '',
  onPressRightIcon = () => {},
}: RadioChipGroupProps) => {
  const renderItem = ({ item, index }) => {
    return (
      <Pressable
        onPress={() => onPress(item?.id)}
        style={{
          flex: 0.5,
          marginLeft: index % 2 !== 0 ? getTheme().space['2xl'] : 0,
          marginTop: index > 1 ? getTheme().space['2xl'] : 0,
          borderRadius: getTheme().radii.lg,
          borderColor: item?.selected
            ? getTheme().colors.primary[500]
            : getTheme().colors.primary[50],
          borderWidth: getTheme().borderWidths.xs,
          backgroundColor: getTheme().colors.primary[10],
          paddingVertical: getTheme().space.lg,
          ...getTheme().boxShadows.sm,
        }}
        disabled={item?.state === 'disabled'}
      >
        <View flexDirection="row" alignItems="center">
          <IconButton
            size="md"
            iconSize="2xl"
            appearance="ghost"
            shape="square"
            name={
              item.selected
                ? 'radiobutton-checked-outline-300'
                : 'radiobutton-unchecked-outline-300'
            }
            iconColor={
              item.selected
                ? 'primary.500'
                : item?.state === 'disabled'
                ? 'grey.100'
                : 'primary.200'
            }
            onPress={() => onPress(item.id)}
          />
          <Text
            size="lg"
            color={
              item.selected
                ? 'primary.500'
                : item?.state === 'disabled'
                ? 'grey.100'
                : 'primary.200'
            }
          >
            {item.name}
          </Text>
        </View>
      </Pressable>
    );
  };
  return (
    <View>
      {title && title !== '' ? (
        rightIcon && rightIcon !== '' ? (
          <View flexDirection="row" justifyContent="space-between">
            <Text size="lg" color="primary.200">
              {title}
            </Text>
            <IconButton
              name={rightIcon}
              size="md"
              appearance="ghost"
              shape="square"
              iconSize="xl"
              iconColor={rightIconColor}
              onPress={onPressRightIcon}
            />
          </View>
        ) : (
          <Text size="lg" color="primary.200">
            {title}
          </Text>
        )
      ) : (
        <></>
      )}
      <FlatList
        data={data}
        renderItem={renderItem}
        contentContainerStyle={{
          marginTop: title && title !== '' ? getTheme().space['2xl'] : 0,
        }}
        numColumns={2}
        keyExtractor={(item) => item.id}
        ListFooterComponent={() => <View height="lg" />}
      />
    </View>
  );
};

export default RadioChipGroup;
