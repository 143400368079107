import React from 'react';
import { View, Text } from '../../../components/new';
import BonusInfoCard from '../../../components/new/custom/bonus/BonusInfoCard';

type InfoTileProps = {
  taskCount: number;
  status: string;
  statusText: string;
};

const resolveIndicatorClr = (status: string) => {
  switch (status) {
    case 'success':
      return 'success.500';
    case 'failed':
      return 'error.500';
    default:
      return 'primary.10';
  }
};

const InfoTile = ({ taskCount, status, statusText }: InfoTileProps) => {
  const indicatorClr = resolveIndicatorClr(status);

  return (
    <BonusInfoCard>
      <Text mb="sm" size="xl" weight="medium" color="primary.10">
        {taskCount}
      </Text>
      <View flexDirection="row" alignItems="center">
        <View
          borderRadius="md"
          mr="sm"
          width="6"
          height="6"
          bg={indicatorClr}
        />
        <Text size="sm" weight="regular" color="primary.200">
          {statusText}
        </Text>
      </View>
    </BonusInfoCard>
  );
};

export default InfoTile;
