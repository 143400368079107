import React, { useEffect, useRef, useState } from 'react';
import { useFragment } from 'react-relay';
import Carousel from 'react-native-reanimated-carousel';
import { Text, View } from '../../../../../components/new';
import { shuffle } from '../../../../../utilities/helper';
import BackButton from '../components/BackButton';
import { analytics } from '../analytics';
import CustomLottieView from '../../../../../components/new/custom/CustomLottieView';
import {
  addonFragment,
  getDiscountAndCashbackPercentage,
} from './findAddonsQuery';

const getSlideData = (discountPerc, cashbackPerc) => {
  const dataArr = [];

  if (discountPerc) {
    dataArr.push({
      id: dataArr.length,
      text: `${discountPerc}% discount for 32nd Plus Members`,
    });
  }
  if (cashbackPerc) {
    dataArr.push({
      id: dataArr.length,
      text: `${cashbackPerc}% cashback for 32nd Plus Members`,
    });
  }
  return dataArr;
};

const FetchingCarDetailsScreen = ({ onBack, addonRef }) => {
  const animation = useRef(null);
  const addons = useFragment(addonFragment, addonRef);
  const { discountPerc, cashbackPerc } =
    getDiscountAndCashbackPercentage(addons);

  const [width, setWidth] = useState(0);
  useEffect(() => {
    analytics.fetchingDetailsScreen.landed();
  }, []);

  const [data] = useState<{ id: number; text: string }[]>(
    shuffle(getSlideData(discountPerc, cashbackPerc)).slice(0, 4),
  );

  return (
    <View>
      <BackButton
        onBack={() => {
          analytics.fetchingDetailsScreen.backClicked();
          onBack();
        }}
      />
      <View p="lg" mt="9xl" alignItems="center">
        <Text size="7xl" lineheight="9xl" color="primary.500" weight="medium">
          Fetching Your Car Details
        </Text>
      </View>
      <View mt={178} alignItems="center">
        <CustomLottieView
          autoPlay
          ref={animation}
          loop
          source={require('../../../../../../assets/lottie/search-ripples.json')}
          style={{ height: 500 }}
        />
      </View>
      <View onLayout={(e) => setWidth(e?.nativeEvent?.layout?.width)}>
        {width && (
          <Carousel
            loop
            height={200}
            width={width}
            autoPlay
            autoPlayInterval={3000}
            data={data}
            scrollAnimationDuration={300}
            renderItem={({ index, item }) => (
              <View key={item.id}>
                <Text
                  textAlign="center"
                  size="4xl"
                  lineHeight="6xl"
                  weight="regular"
                  color="primary.300"
                >{`"${item.text}"`}</Text>
              </View>
            )}
          />
        )}
      </View>
    </View>
  );
};

export default FetchingCarDetailsScreen;
