/**
 * @generated SignedSource<<817f680b48a955aba4d75b855b64abb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumstoresDescriptionTagsIconColor = "error" | "info" | "success" | "%future added value";
export type EnumstoresMediaGalleryMediaMediaType = "image" | "video" | "%future added value";
export type EnumstoresMediaGallerySectionTitle = "Ambience" | "Awards___Recognitions" | "Founder_Story" | "Product" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type stores_findStore$data = ReadonlyArray<{
  readonly _id: any;
  readonly campus: {
    readonly _id: any;
  };
  readonly description: {
    readonly summary: string | null;
    readonly tagLine: ReadonlyArray<string | null> | null;
    readonly tags: ReadonlyArray<{
      readonly _id: any | null;
      readonly icon: {
        readonly _id: any | null;
        readonly color: EnumstoresDescriptionTagsIconColor | null;
        readonly iconName: string | null;
      } | null;
      readonly name: string | null;
    } | null> | null;
  };
  readonly displayName: string;
  readonly logo: string | null;
  readonly mediaGallery: ReadonlyArray<{
    readonly _id: any | null;
    readonly description: string | null;
    readonly displayOrder: number;
    readonly media: ReadonlyArray<{
      readonly _id: any | null;
      readonly cover: boolean | null;
      readonly displayOrder: number;
      readonly mediaType: EnumstoresMediaGalleryMediaMediaType;
      readonly mediaURL: string;
      readonly prime: boolean | null;
      readonly sectionCover: boolean | null;
    } | null> | null;
    readonly sectionTitle: EnumstoresMediaGallerySectionTitle;
  } | null> | null;
  readonly overAllRating: number | null;
  readonly " $fragmentType": "stores_findStore";
}>;
export type stores_findStore$key = ReadonlyArray<{
  readonly " $data"?: stores_findStore$data;
  readonly " $fragmentSpreads": FragmentRefs<"stores_findStore">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "stores_findStore",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesDescription",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "storesDescriptionTags",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "storesDescriptionTagsIcon",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "iconName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "color",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tagLine",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overAllRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesCampus",
      "kind": "LinkedField",
      "name": "campus",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesMediaGallery",
      "kind": "LinkedField",
      "name": "mediaGallery",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sectionTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "storesMediaGalleryMedia",
          "kind": "LinkedField",
          "name": "media",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cover",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaURL",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sectionCover",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "stores",
  "abstractKey": null
};
})();

(node as any).hash = "a639bcdb910c5a6f6981d023c3a8a3d3";

export default node;
