import React from 'react';
import { Text, View } from '../../../../components/new';
import CarousalComponent from '../../../../components/new/custom/Carousel/CarousalComponent';

const InvestBanners = ({ bgColor = '', data }) => {
  return (
    <View py="9xl" bg={bgColor} px="2xl">
      {data?.heading && (
        <Text size="xs" color="primary.300" pb="lg" textAlign="center">
          {data?.heading}
        </Text>
      )}
      {data?.subHeading && (
        <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
          {data?.subHeading}
        </Text>
      )}
      <View mt="9xl">
        <CarousalComponent data={data?.data} />
      </View>
    </View>
  );
};

export default InvestBanners;
