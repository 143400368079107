import {
  DateFormatsEnum,
  PaymentStatusEnum,
  formatPrice,
  getFormatedDate,
  maskedNumber,
} from '../../utilities/helper';
import { DatePeriodType } from './API/fragments/transactionsRevenueReportFragment/__generated__/transactionsRevenueReportFragment.graphql';
import {
  ITransaction,
  TransactionNodeGql,
  TransactionsRevenueReport,
  TransactionsRevenueReportGql,
  TransactionsEarningsReport,
  TransactionsEarningsReportGql,
} from './types';

export const buildTransactionViewData = (
  transactions: TransactionNodeGql[],
): ITransaction[] => {
  return transactions.map((transaction) => {
    const netAmountWithoutDonation =
      (transaction?.priceDetails?.netAmount ?? 0) -
      (transaction?.priceDetails?.csrAmount ?? 0);

    return {
      netAmountWithoutDonation,
      orderId: transaction?.uId ?? '',
      date: transaction?.created_at ?? ('' as string),
      paymentStatus: resolvePaymentStatus(transaction?.status ?? ''),
      billAmount: transaction?.priceDetails?.grossAmount ?? 0,
      tipAmount: transaction?.priceDetails?.tipAmount ?? 0,
      csrAmount: transaction?.priceDetails?.csrAmount ?? 0,
      netAmount: transaction?.priceDetails?.netAmount ?? 0,
      discount: transaction?.priceDetails?.discountGiven ?? 0,
      loyaltyPoints: transaction?.priceDetails?.rewardsUsed ?? 0,
      customerPhone: transaction?.user?.contact?.phone ?? {
        prefix: '',
        number: '',
      },
      formated: {
        customerPhone: resolveContact(transaction),
        date: getFormatedDate(
          new Date(transaction?.created_at ?? ''),
          DateFormatsEnum.DateMonthYear,
        ) as string,
        time: getFormatedDate(
          new Date(transaction?.created_at ?? ''),
          DateFormatsEnum.TIME,
        ) as string,
        billAmount: formatPrice(transaction?.priceDetails?.grossAmount ?? 0),
        tipAmount: formatPrice(transaction?.priceDetails?.tipAmount ?? 0),
        csrAmount: formatPrice(transaction?.priceDetails?.csrAmount ?? 0),
        discount: formatPrice(transaction?.priceDetails?.discountGiven ?? 0),
        netAmount: formatPrice(transaction?.priceDetails?.netAmount ?? 0),
        netAmountWithoutDonation: formatPrice(netAmountWithoutDonation),
        loyaltyPoints: formatPrice(transaction.priceDetails.rewardsUsed ?? 0),
      },
    };
  });
};

export const buildTransactionsRevenueReportViewData = (
  revenueReports: TransactionsRevenueReportGql[],
): TransactionsRevenueReport[] => {
  return revenueReports.map((rr) => {
    const date = new Date(rr.date ?? ('' as string));

    return {
      date,
      period: rr.period as DatePeriodType,
      revenueAmount: rr.grossAmount ?? 0,
      collectedAmount: rr.netAmount ?? 0,
      discountAmount: rr.discountGiven ?? 0,
      redeemedLoyaltyPoints: rr.rewardPointsUsed ?? 0,
      transactionsCount: rr.transactionsCount ?? 0,
      formated: {
        date: resolveDateFormat(date, rr.period as DatePeriodType),
        collectedAmount: formatPrice(rr.netAmount ?? 0),
        discountAmount: formatPrice(rr.discountGiven ?? 0),
        revenueAmount: formatPrice(rr.grossAmount ?? 0),
        redeemedLoyaltyPoints: formatPrice(rr.rewardPointsUsed ?? 0),
      },
    };
  });
};

export const buildTransactionsEarningsReportViewData = (
  earningsReports: TransactionsEarningsReportGql[],
): TransactionsEarningsReport[] => {
  return earningsReports.map((rr) => {
    const date = new Date(rr.date ?? ('' as string));

    return {
      date,
      period: rr.period as DatePeriodType,
      revenueAmount: rr.grossAmount ?? 0,
      collectedAmount: rr.netAmount ?? 0,
      payoutAmount: rr.payoutAmount ?? 0,
      discountAmount: rr.discountGiven ?? 0,
      redeemedLoyaltyPoints: rr.rewardPointsUsed ?? 0,
      transactionsCount: rr.transactionsCount ?? 0,
      grantedLoyaltyPoints: rr.rewardPointsGranted ?? 0,
      compensatedLoyaltyPoints: rr.rewardPointsCompensated ?? 0,
      formated: {
        date: resolveDateFormat(date, rr.period as DatePeriodType),
        collectedAmount: formatPrice(rr.netAmount ?? 0),
        payoutAmount: formatPrice(rr.payoutAmount ?? 0),
        discountAmount: formatPrice(rr.discountGiven ?? 0),
        revenueAmount: formatPrice(rr.grossAmount ?? 0),
        redeemedLoyaltyPoints: formatPrice(rr.rewardPointsUsed ?? 0),
        grantedLoyaltyPoints: formatPrice(rr.rewardPointsGranted ?? 0),
        compensatedLoyaltyPoints: formatPrice(rr.rewardPointsCompensated ?? 0),
      },
    };
  });
};

export const resolvePaymentStatus = (status: string): PaymentStatusEnum => {
  switch (status) {
    case 'PENDING':
      return PaymentStatusEnum.PENDING;
    case 'SUCCESSFUL':
      return PaymentStatusEnum.SUCCESSFUL;
    case 'FAILED':
      return PaymentStatusEnum.FAILED;
    case 'REFUNDED':
      return PaymentStatusEnum.REFUNDED;
    default:
      return PaymentStatusEnum.FAILED;
  }
};

export const resolveContact = (transaction: TransactionNodeGql): string => {
  const { prefix, number } = transaction?.user?.contact?.phone ?? {};
  if (prefix && number) {
    return prefix + maskedNumber(number);
  }
  return '';
};

export const resolveDateFormat = (
  date: Date,
  period: DatePeriodType,
): string => {
  switch (period) {
    case 'MONTH':
      return getFormatedDate(date, DateFormatsEnum.MonthYear) as string;
    case 'DAY':
      return getFormatedDate(date, DateFormatsEnum.DateMonthYear) as string;
    default:
      return getFormatedDate(date, DateFormatsEnum.DateMonthYear) as string;
  }
};
