/**
 * @generated SignedSource<<088377b5c12a4c1f6ba4cd569db4f510>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumUserRolePermission = "staff" | "%future added value";
export type CampusStaffAccountScreenQuery$variables = {
  userId: any;
};
export type CampusStaffAccountScreenQuery$data = {
  readonly findUserById: {
    readonly _id: any;
    readonly name: {
      readonly first: string;
      readonly last: string | null;
    };
    readonly parkedCount: number | null;
    readonly profileImage: string | null;
    readonly role: {
      readonly name: EnumUserRoleName | null;
      readonly permission: EnumUserRolePermission | null;
    } | null;
    readonly userSince: string | null;
    readonly visitCount: number | null;
  } | null;
};
export type CampusStaffAccountScreenQuery = {
  response: CampusStaffAccountScreenQuery$data;
  variables: CampusStaffAccountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "findUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserName",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRole",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permission",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userSince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "visitCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkedCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampusStaffAccountScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampusStaffAccountScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ad6997cc341ab4fa16018a1f3d140367",
    "id": null,
    "metadata": {},
    "name": "CampusStaffAccountScreenQuery",
    "operationKind": "query",
    "text": "query CampusStaffAccountScreenQuery(\n  $userId: MongoID!\n) {\n  findUserById(_id: $userId) {\n    _id\n    name {\n      first\n      last\n    }\n    role {\n      name\n      permission\n    }\n    userSince\n    visitCount\n    parkedCount\n    profileImage\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b9a093495167e06e1f0b972550a485c";

export default node;
