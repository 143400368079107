import React, { Suspense, useEffect, useState } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { View } from '../../../components/new';
import Layout from '../../../components/new/primitive/Layout/Layout';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import { InvestmentDetailsPlaceholder } from './shimmerPlaceholders';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import DetailsPage from './components/DetailsPage';
import FacebookPixelLogger from '../../../utilities/FacebookPixelLogger';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import { checkValidMongoIdByLength } from '../../../utilities/helper';
import { useThemedSystemNav } from '../../../hooks/useThemedSystemNav';
import { AppTheme } from '../../../stores/commonStore';

const PropertyDetails = ({ route }) => {
  const navigation = useNavigation<any>();

  const propertyId = route?.params?.propertyId ?? '';

  const propertyNotFound = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'user', params: { screen: 'invest' } }],
      }),
    );
  };

  useThemedSystemNav(AppTheme.DARK);

  useEffect(() => {
    FacebookPixelLogger('track', 'ViewContent');
    if (
      isValueNullOrEmpty(propertyId) ||
      !checkValidMongoIdByLength(propertyId)
    ) {
      propertyNotFound();
    }
  }, []);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2}>
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<InvestmentDetailsPlaceholder />}>
            <DetailsPage
              propertyId={propertyId}
              queryOptions={refreshedQueryOptions}
              refresh={refresh}
              propertyNotFound={propertyNotFound}
            />
          </Suspense>
        </NewErrorBoundary>
      </View>
      <DisconnectedDropover
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default PropertyDetails;
