import React from 'react';
import { View } from '../../components/new';
import AutoBottomSheet from '../../components/new/custom/AutoBottomSheet';

const ParkingDetailsBottomSheet = ({ bottomSheetModalRef, renderContent }) => {
  return (
    <AutoBottomSheet bottomSheetRef={bottomSheetModalRef}>
      <View>
        <View mt="xl">{renderContent}</View>
      </View>
    </AutoBottomSheet>
  );
};

export default ParkingDetailsBottomSheet;
