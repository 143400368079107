/**
 * @generated SignedSource<<4e9faf5d8e1503ba2e7990767e1779b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type findParkingLocationFilterInput = {
  campus: FilterFindManyparkingLocationCampusInput;
};
export type FilterFindManyparkingLocationCampusInput = {
  _id: any;
};
export type supervisorOverviewApiMainQuery$variables = {
  endTimeStamp: number;
  parkingLocationFilter: findParkingLocationFilterInput;
  startTimeStamp: number;
};
export type supervisorOverviewApiMainQuery$data = {
  readonly findParkingLocation: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"searchParkingList_findParkingLocation">;
  }>;
  readonly getTaskMetricsDateWise: {
    readonly metrics: ReadonlyArray<{
      readonly assignmentScore: number | null;
      readonly assignmentTime: number | null;
      readonly date: string | null;
      readonly deliveryScore: number | null;
      readonly deliveryTime: number | null;
      readonly onboardingScore: number | null;
      readonly onboardingTime: number | null;
      readonly parkingScore: number | null;
      readonly parkingTime: number | null;
    } | null> | null;
  } | null;
};
export type supervisorOverviewApiMainQuery = {
  response: supervisorOverviewApiMainQuery$data;
  variables: supervisorOverviewApiMainQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endTimeStamp"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parkingLocationFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startTimeStamp"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endTimeStamp",
      "variableName": "endTimeStamp"
    },
    {
      "kind": "Variable",
      "name": "startTimeStamp",
      "variableName": "startTimeStamp"
    }
  ],
  "concreteType": "taskMetricsDayWiseTC",
  "kind": "LinkedField",
  "name": "getTaskMetricsDateWise",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "taskMetricsDateTC",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignmentTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignmentScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parkingTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parkingScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onboardingTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onboardingScore",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "parkingLocationFilter"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "supervisorOverviewApiMainQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "parkingLocation",
        "kind": "LinkedField",
        "name": "findParkingLocation",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "searchParkingList_findParkingLocation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "supervisorOverviewApiMainQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "parkingLocation",
        "kind": "LinkedField",
        "name": "findParkingLocation",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "parkingLocationAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine1",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "parkingLocationParkingSpaces",
            "kind": "LinkedField",
            "name": "parkingSpaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "parkingLocationParkingSpacesFloor",
                "kind": "LinkedField",
                "name": "floor",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "floorName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "parkingLocationParkingSpacesFloorBay",
                    "kind": "LinkedField",
                    "name": "bay",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parkingSpots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parkingOccupied",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parkingVacant",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24663fa0dba48c151c9fcf287582f85a",
    "id": null,
    "metadata": {},
    "name": "supervisorOverviewApiMainQuery",
    "operationKind": "query",
    "text": "query supervisorOverviewApiMainQuery(\n  $startTimeStamp: Int!\n  $endTimeStamp: Int!\n  $parkingLocationFilter: findParkingLocationFilterInput!\n) {\n  getTaskMetricsDateWise(startTimeStamp: $startTimeStamp, endTimeStamp: $endTimeStamp) {\n    metrics {\n      date\n      assignmentTime\n      assignmentScore\n      deliveryTime\n      deliveryScore\n      parkingTime\n      parkingScore\n      onboardingTime\n      onboardingScore\n    }\n  }\n  findParkingLocation(filter: $parkingLocationFilter) {\n    ...searchParkingList_findParkingLocation\n  }\n}\n\nfragment searchParkingList_findParkingLocation on parkingLocation {\n  _id\n  name\n  address {\n    addressLine1\n  }\n  parkingSpaces {\n    floor {\n      _id\n      floorName\n      bay {\n        _id\n        bayName\n        parkingSpots\n        parkingOccupied\n        parkingVacant\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1329c9ef8ca62a1869821f95a858c0c9";

export default node;
