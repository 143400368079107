import { graphql, commitMutation } from 'react-relay';
import environment from './relayEnvironment';

const AddFeedback = (data, callback, onError) => {
  const mutation = graphql`
    mutation feedbackApiAddMutation(
      $type: FeedbackType!
      $feedbacks: [FeedbackDetails]
      $parkingId: ID
      $invoiceId: ID
      $restaurantId: ID
    ) {
      addFeedback(
        type: $type
        feedbacks: $feedbacks
        parkingId: $parkingId
        invoiceId: $invoiceId
        restaurantId: $restaurantId
      ) {
        _id
        pointsRewarded
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response, data);
    },
    onError: (err) => {
      console.error(err);
      onError(err);
    },
  });
};
export default AddFeedback;
