import { useEffect } from 'react';
import { Platform } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

const APP_STORE_LINK = 'https://apps.apple.com/in/app/32nd-club/id1523219759';
const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.guesthouser.hub';

const RedirectToPlatformStore = ({ navigation, route }) => {
  useEffect(() => {
    let params = route?.params;
    if (params) {
      params = {
        ...params,
      };
    } else {
      params = {};
    }

    // Mounting Detected
    firebaseEventLogger('REDIRECT_TO_PLATFORM_STORE', params).finally(() => {
      if (Platform.OS === 'web') {
        const { userAgent } = navigator;
        if (
          userAgent.match(/iOS|iPhone|iPad|iPod/i) ||
          userAgent.match(/Mac OS/i)
        ) {
          // This is an iOS device
          window.location.href = APP_STORE_LINK;
          // Linking.openURL(APP_STORE_LINK);
        } else {
          // Non IOS device
          window.location.href = PLAY_STORE_LINK;
        }
      } else if (params.deeplink) {
        const { deeplink } = params;
        const [screens] = deeplink.split('?');
        const [mainRoute, subRoute] = screens.split('/');

        try {
          if (mainRoute && subRoute) {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: mainRoute, params: { screen: subRoute } }],
              }),
            );
          } else if (mainRoute) {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: mainRoute }],
              }),
            );
          } else {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: 'user' }],
              }),
            );
          }
        } catch (e) {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: 'user' }],
            }),
          );
        }
      } else {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'user' }],
          }),
        );
      }
    });
  }, []);

  return null;
};

export default RedirectToPlatformStore;
