import React, { forwardRef, useMemo } from 'react';
import TransactionDetailsPanel from '../../components/TransactionDetailsPanel';
import { ITransaction } from '../../types';
import { Text, View } from '../../../../components/new';
import TransactionsReportPanel from '../../components/TransactionsReportPanel';
import { ReportMode, Report } from './types';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';

export enum BSMode {
  DISMISSED = 'DISMISSED',
  REPORT = 'REPORT',
  TRANSACTION = 'TRANSACTION',
}

interface ReportDetailsBottomSheetProps {
  onDismiss: () => void;
  reportMode: ReportMode;
  bsMode: BSMode;
  report: Report;
  transaction: ITransaction | null;
}

const ReportDetailsBottomSheet = forwardRef(
  (props: ReportDetailsBottomSheetProps, ref) => {
    const { transaction, report, reportMode, bsMode, onDismiss } = props;

    return (
      <BottomSheetV2 ref={ref} onClose={onDismiss} insets={false}>
        <View>
          {bsMode !== BSMode.DISMISSED && (
            <View px="2xl" pt="2xl">
              {bsMode === BSMode.REPORT &&
                renderRevenueSummaryPanel(report, reportMode)}
              {bsMode === BSMode.TRANSACTION &&
                renderTransactionDetailsPanel(transaction, reportMode)}
            </View>
          )}
        </View>
      </BottomSheetV2>
    );
  },
);

const renderTransactionDetailsPanel = (
  txn: ITransaction | null,
  reportMode: ReportMode,
) =>
  txn ? <TransactionDetailsPanel transaction={txn} mode={reportMode} /> : <></>;

const renderRevenueSummaryPanel = (report: Report, reportMode: ReportMode) =>
  report ? (
    <View>
      <Text mb="2xl" size="md" weight="regular" color="singletone.black">
        {reportMode === 'EARNINGS'
          ? 'How is my earnings calculated?'
          : 'How is collected amount calculated ?'}
      </Text>
      <TransactionsReportPanel
        mode={reportMode}
        report={{
          revenueAmount: report.formated.revenueAmount,
          collectedAmount: report.formated.collectedAmount,
          discountAmount: report.formated.discountAmount,
          points: report.formated.redeemedLoyaltyPoints,
        }}
      />
    </View>
  ) : (
    <></>
  );

export default ReportDetailsBottomSheet;
