import { graphql } from 'react-relay';

export const blackMembershipProfileQuery = graphql`
  query blackMembershipProfileQuery(
    $userId: MongoID!
    $addonFilter: FilterFindManyaddonInput
  ) {
    findUserById(_id: $userId) {
      ...blackMembershipProfileQueryUserFragment
    }
    getUserPaymentStats {
      ...blackMembershipProfileQueryPaymentStatsFragment
    }
    findAddons(filter: $addonFilter) {
      _id
      type
      price
      expiryDuration {
        days
      }
      additionalDiscount {
        refType
        discount {
          value
          type
        }
      }
    }
  }
`;

export const findUserFragment = graphql`
  fragment blackMembershipProfileQueryUserFragment on User {
    wallet {
      totalBalance
      currentBalance
      moneySaved
      earnedAmount
    }
    memberships {
      membershipAddonId
      status
      expiryDate
    }
  }
`;

export const getUserPaymentStatsFragment = graphql`
  fragment blackMembershipProfileQueryPaymentStatsFragment on UserPaymentStats {
    invoiceCount
    saved
    breakup {
      orderType
      invoiceCount
      saved
    }
  }
`;
