import React from 'react';
import styled from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Pressable } from 'react-native';
import Animated from 'react-native-reanimated';
import { View, Input, Avatar } from '../index';
import BlurView from '../basic/BlurView/BlurView';
import { navigateBack } from '../../../utilities/helper';
import styledTheme from '../../../themes/new/theme';
import useUserStore from '../../../stores/userStore';
import AppConstants from '../../../utilities/AppConstants';
import ChipFilter from './ChipFilter';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const Header = ({
  navigation,
  onPress,
  chipData,
  setChipData,
  animatedStyle,
  searchText,
  setOpenSearchScreen,
  placeholderText,
  hideChips,
}) => {
  // const [chipData, setChipData] = useState(FilterData);
  const {
    role: userRole,
    profileImage,
    firstName,
    lastName,
    blackMembership,
  } = useUserStore((state) => state);
  const { active: isBlackMember } = blackMembership ?? {};
  const { IMAGEKIT_PROD_URI } = AppConstants;

  const imageLink = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';

  const StyledView = styled.View`
    ${(props) => props.theme.boxShadows.sm}
    backgroundColor:${({ theme }) => theme.colors.opacity[95]}
  `;
  const { top } = useSafeAreaInsets();

  const { type } = navigation.getState() ?? {};
  const navigatToSearchScreen = () => {
    firebaseEventLogger('SearchBarClicked');
    navigation.navigate('newSearchScreen', {
      openKeyboardOnMount: true,
    });
  };
  return (
    <BlurView level="1" style={{ zIndex: 10 }}>
      <StyledView>
        <View
          px="2xl"
          // mt="lg"
          pt={top + styledTheme.space.lg}
          pb="xl"
          // backgroundColor="opacity.90"
          zIndex={10}
          flexDirection="row"
          alignItems="center"
        >
          <View flex={1}>
            <Input
              leftIconName={type !== 'tab' ? 'back-outline-300' : ''}
              rightIconName={
                searchText?.length > 0
                  ? 'close-outline-300'
                  : 'search-outline-400'
              }
              placeholder={
                placeholderText && placeholderText?.length
                  ? placeholderText
                  : 'Search...'
              }
              // onFocus={() => {
              //   navigation.navigate('newSearchScreen');
              //   // setOpenSearchScreen(true);
              // }}
              onRightIconClick={navigatToSearchScreen}
              onPressMiddleArea={navigatToSearchScreen}
              value={searchText}
              onLeftIconClick={() => navigateBack(navigation, userRole)}
            />
          </View>
          {type === 'tab' ? (
            <Pressable
              onPress={() => {
                firebaseEventLogger('account_avatar_clicked');
                navigation.navigate('account');
              }}
              hitSlop={{
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              }}
            >
              <View
                ml="lg"
                borderWidth="sm"
                borderColor={isBlackMember ? 'static.gold' : 'transparent'}
                borderRadius="full"
              >
                <Avatar
                  level={1}
                  source={imageLink}
                  name={`${firstName ?? ''} ${lastName ?? ''}`}
                  resizeMode="cover"
                />
              </View>
            </Pressable>
          ) : null}
        </View>
        {chipData && chipData?.length ? (
          <Animated.View style={[animatedStyle, { zIndex: 5 }]}>
            {!hideChips ? (
              <ChipFilter
                data={chipData}
                // selectedIndex={selectedIndex}
                // setSelectedIndex={setSelectedIndex}
                callbackFn={onPress}
              />
            ) : (
              <></>
            )}
          </Animated.View>
        ) : (
          <></>
        )}
      </StyledView>
    </BlurView>
  );
};

export default Header;
