/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import { BonusInfoCard } from '../../../components/new/custom/bonus';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import OverviewBanner from '../../../components/new/custom/OverviewBanner';
import {
  DateFormatsEnum,
  getFormatedDate,
  navigateBack,
  numberWithCommas,
} from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import NewErrorView from '../../../utilities/NewErrorView';
import { Loading } from '../../../components';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import DayWiseCollectionList from './DayWiseCollectionList';
import { usePartnerDayWiseCollectionScreen } from './usePartnerDayWiseCollection';

const DayWiseCollectionScreen = ({
  queryOptions,
}: {
  queryOptions: NewErrorBoundaryParentState;
}) => {
  const navigation = useNavigation<any>();
  const routeData = useRoute();
  const { date } = routeData?.params as Record<string, any>;
  const { bannerData, dayWiseCollectionList, hasNext, loadNext, refetch } =
    usePartnerDayWiseCollectionScreen(queryOptions, date);

  const { role } = useUserStore((state) => state);
  const { cashTotal, onlineTotal, amount } = bannerData;

  return (
    <View flex={1}>
      <OverviewBanner>
        <View zIndex={2}>
          <TopNavigation
            textSize="md"
            appearance="ghost"
            level="none"
            textColor="primary.10"
            IconLeft={
              <IconButton
                name="back-outline-300"
                size="md"
                appearance="ghost"
                iconColor="primary.10"
                onPress={() => navigateBack(navigation, role)}
              />
            }
          />
          <View alignItems="center" justifyContent="center">
            <Text size="3xl" weight="medium" color="success.500">
              ₹{numberWithCommas(amount)}
            </Text>
            <Text size="sm" mt="sm" color="primary.10">
              {getFormatedDate(new Date(date), DateFormatsEnum.DateMonthYear)}
            </Text>
          </View>

          <View mt="2xl" mb="4xl" flexDirection="row" justifyContent="center">
            <View width="171" ml="2xl" mr="lg">
              <BonusInfoCard>
                <Text mb="sm" size="xl" weight="medium" color="primary.10">
                  ₹{numberWithCommas(cashTotal)}
                </Text>
                <Text size="sm" weight="regular" color="primary.200">
                  Cash Collection
                </Text>
              </BonusInfoCard>
            </View>
            <View width="171" ml="lg" mr="2xl">
              <View>
                <BonusInfoCard>
                  <Text mb="sm" size="xl" weight="medium" color="primary.10">
                    ₹{numberWithCommas(onlineTotal)}
                  </Text>
                  <Text size="sm" weight="regular" color="primary.200">
                    Other Collections
                  </Text>
                </BonusInfoCard>
              </View>
            </View>
          </View>
        </View>
      </OverviewBanner>

      <>
        <DayWiseCollectionList
          collectionList={dayWiseCollectionList}
          hasNext={hasNext}
          loadNext={loadNext}
          refetch={refetch}
        />
      </>
    </View>
  );
};

const DayWiseCollectionScreenWrapper = ({ navigation, route }) => {
  const { taskId } = route?.params ?? {};
  const { role } = useUserStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <DayWiseCollectionScreen
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default DayWiseCollectionScreenWrapper;
