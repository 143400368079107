/**
 * @generated SignedSource<<358c0d9459c32b301e0ae15598334090>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumaddonAdditionalCashbackRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonAdditionalDiscountRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonPriceDiscountBaseDiscountType = "PERCENTAGE" | "%future added value";
export type EnumaddonType = "a_32ND_BLACK_MEMBERSHIP" | "%future added value";
export type SortConnectioneventEnum = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "SLOT_CLOSE_TIME_ASC" | "SLOT_CLOSE_TIME_DESC" | "SLOT_START_TIME_ASC" | "SLOT_START_TIME_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type findEventInput = {
  _id?: any | null;
  campus: FilterFindManyeventCampusInput;
  eventTiming?: ReadonlyArray<FilterFindManyeventEventTimingInput | null> | null;
  host?: FilterFindManyeventHostInput | null;
  isActive: boolean;
  priorityEventId?: string | null;
  title?: string | null;
  upcomingEvent?: boolean | null;
  venue?: string | null;
};
export type FilterFindManyeventCampusInput = {
  _id: any;
};
export type FilterFindManyeventHostInput = {
  name?: string | null;
};
export type FilterFindManyeventEventTimingInput = {
  _id?: any | null;
  date?: any | null;
  slot?: FilterFindManyeventEventTimingSlotInput | null;
};
export type FilterFindManyeventEventTimingSlotInput = {
  _id?: any | null;
  availableTickets?: ReadonlyArray<FilterFindManyeventEventTimingSlotAvailableTicketsInput | null> | null;
  endTime?: any | null;
  isActive?: boolean | null;
  slotSpace?: number | null;
  slotTag?: string | null;
  startTime?: any | null;
};
export type FilterFindManyeventEventTimingSlotAvailableTicketsInput = {
  _id?: any | null;
  isActive?: boolean | null;
  maxSpace?: number | null;
  name?: string | null;
  personAllowed?: number | null;
  price?: number | null;
};
export type FilterFindManyaddonInput = {
  AND?: ReadonlyArray<FilterFindManyaddonInput> | null;
  OR?: ReadonlyArray<FilterFindManyaddonInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyaddonOperatorsInput | null;
  additionalCashback?: ReadonlyArray<FilterFindManyaddonAdditionalCashbackInput | null> | null;
  additionalDiscount?: ReadonlyArray<FilterFindManyaddonAdditionalDiscountInput | null> | null;
  expiryDuration?: FilterFindManyaddonExpiryDurationInput | null;
  isMembershipType?: boolean | null;
  name?: string | null;
  price?: number | null;
  priceDiscount?: ReadonlyArray<FilterFindManyaddonPriceDiscountInput | null> | null;
  tier?: number | null;
  type?: EnumaddonType | null;
};
export type FilterFindManyaddonPriceDiscountInput = {
  _id?: any | null;
  baseDiscount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
};
export type FilterFindManyaddonPriceDiscountBaseDiscountInput = {
  _id?: any | null;
  type?: EnumaddonPriceDiscountBaseDiscountType | null;
  value?: number | null;
};
export type FilterFindManyaddonExpiryDurationInput = {
  _id?: any | null;
  days?: number | null;
};
export type FilterFindManyaddonAdditionalCashbackInput = {
  _id?: any | null;
  cashback?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refId?: any | null;
  refType?: EnumaddonAdditionalCashbackRefType | null;
};
export type FilterFindManyaddonAdditionalDiscountInput = {
  _id?: any | null;
  discount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refType?: EnumaddonAdditionalDiscountRefType | null;
};
export type FilterFindManyaddonOperatorsInput = {
  _id?: FilterFindManyaddon_idOperatorsInput | null;
  tier?: FilterFindManyaddonTierOperatorsInput | null;
  type?: FilterFindManyaddonTypeOperatorsInput | null;
};
export type FilterFindManyaddonTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnumaddonType | null;
  gte?: EnumaddonType | null;
  in?: ReadonlyArray<EnumaddonType | null> | null;
  lt?: EnumaddonType | null;
  lte?: EnumaddonType | null;
  ne?: EnumaddonType | null;
  nin?: ReadonlyArray<EnumaddonType | null> | null;
};
export type FilterFindManyaddonTierOperatorsInput = {
  exists?: boolean | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number | null> | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
  nin?: ReadonlyArray<number | null> | null;
};
export type FilterFindManyaddon_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type eventsListingQuery$variables = {
  addonsFilter?: FilterFindManyaddonInput | null;
  count: number;
  cursor?: string | null;
  filter: findEventInput;
  id: any;
  includeFindUser: boolean;
  sort?: SortConnectioneventEnum | null;
};
export type eventsListingQuery$data = {
  readonly findAddons: ReadonlyArray<{
    readonly _id: any;
    readonly additionalCashback: ReadonlyArray<{
      readonly cashback: {
        readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
        readonly value: number | null;
      };
      readonly refType: EnumaddonAdditionalCashbackRefType;
    } | null> | null;
    readonly additionalDiscount: ReadonlyArray<{
      readonly discount: {
        readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
        readonly value: number | null;
      };
      readonly refType: EnumaddonAdditionalDiscountRefType;
    } | null> | null;
    readonly expiryDuration: {
      readonly days: number;
    } | null;
    readonly name: string;
    readonly price: number;
    readonly type: EnumaddonType;
  }>;
  readonly findUserById?: {
    readonly wallet: {
      readonly currentBalance: number | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"eventsListingQueryfindEventsV2Fragment">;
};
export type eventsListingQuery = {
  response: eventsListingQuery$data;
  variables: eventsListingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addonsFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeFindUser"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v7 = {
  "condition": "includeFindUser",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "_id",
          "variableName": "id"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "findUserById",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserWallet",
          "kind": "LinkedField",
          "name": "wallet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentBalance",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refType",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  (v11/*: any*/)
],
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filter",
      "variableName": "addonsFilter"
    }
  ],
  "concreteType": "addon",
  "kind": "LinkedField",
  "name": "findAddons",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "addonAdditionalCashback",
      "kind": "LinkedField",
      "name": "additionalCashback",
      "plural": true,
      "selections": [
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "addonPriceDiscountBaseDiscount",
          "kind": "LinkedField",
          "name": "cashback",
          "plural": false,
          "selections": (v13/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "addonAdditionalDiscount",
      "kind": "LinkedField",
      "name": "additionalDiscount",
      "plural": true,
      "selections": [
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "addonPriceDiscountBaseDiscount",
          "kind": "LinkedField",
          "name": "discount",
          "plural": false,
          "selections": (v13/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "addonExpiryDuration",
      "kind": "LinkedField",
      "name": "expiryDuration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "days",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v16 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v17 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v15/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v16/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotsLeft",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "eventsListingQuery",
    "selections": [
      (v7/*: any*/),
      (v14/*: any*/),
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v15/*: any*/),
          (v16/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "eventsListingQueryfindEventsV2Fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "eventsListingQuery",
    "selections": [
      (v7/*: any*/),
      (v14/*: any*/),
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "eventConnection",
        "kind": "LinkedField",
        "name": "findEventsV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "eventEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "event",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "eventMedia",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "eventMediaCover",
                        "kind": "LinkedField",
                        "name": "cover",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mediaURL",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mediaType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cover",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayOrder",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "eventEventTiming",
                    "kind": "LinkedField",
                    "name": "eventTiming",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "eventEventTimingSlot",
                        "kind": "LinkedField",
                        "name": "slot",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                          },
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventEventTimingSlotAvailableTickets",
                            "kind": "LinkedField",
                            "name": "availableTickets",
                            "plural": true,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "basePrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "personAllowed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isActive",
                                "storageKey": null
                              },
                              (v18/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "filters": [
          "filter",
          "sort"
        ],
        "handle": "connection",
        "key": "eventsListingQueryFragment__findEventsV2",
        "kind": "LinkedHandle",
        "name": "findEventsV2"
      }
    ]
  },
  "params": {
    "cacheID": "ca8706e15393c435bb17e0674397ffcf",
    "id": null,
    "metadata": {},
    "name": "eventsListingQuery",
    "operationKind": "query",
    "text": "query eventsListingQuery(\n  $filter: findEventInput!\n  $sort: SortConnectioneventEnum\n  $id: MongoID!\n  $includeFindUser: Boolean!\n  $count: Int!\n  $cursor: String\n  $addonsFilter: FilterFindManyaddonInput\n) {\n  findUserById(_id: $id) @include(if: $includeFindUser) {\n    wallet {\n      currentBalance\n    }\n  }\n  findAddons(filter: $addonsFilter) {\n    _id\n    name\n    price\n    type\n    additionalCashback {\n      refType\n      cashback {\n        value\n        type\n      }\n    }\n    additionalDiscount {\n      refType\n      discount {\n        value\n        type\n      }\n    }\n    expiryDuration {\n      days\n    }\n  }\n  ...eventsListingQueryfindEventsV2Fragment_1GWUVg\n}\n\nfragment eventsListingQueryfindEventsV2Fragment_1GWUVg on Query {\n  findEventsV2(filter: $filter, sort: $sort, first: $count, after: $cursor) {\n    edges {\n      node {\n        _id\n        title\n        media {\n          cover {\n            mediaURL\n            mediaType\n            cover\n            displayOrder\n          }\n        }\n        eventTiming {\n          date\n          slot {\n            startTime\n            endTime\n            slotsLeft\n            availableTickets {\n              name\n              price\n              basePrice\n              personAllowed\n              isActive\n              slotsLeft\n              _id\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3716b1a83485965980e7c5f409286f28";

export default node;
