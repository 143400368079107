import React, { useState } from 'react';
import { Switch } from 'react-native';
import useCommonStore, { AppTheme } from '../../stores/commonStore';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';

const ToggleComponent = () => {
  const commonStore = useCommonStore((state) => state);
  const [isChecked, setIsChecked] = useState(
    commonStore.theme === AppTheme.DARK,
  );
  const themeChange = () => {
    const newTheme: AppTheme =
      commonStore.theme === AppTheme.LIGHT ? AppTheme.DARK : AppTheme.LIGHT;
    commonStore.setAppTheme(newTheme);
  };

  return (
    <Switch
      value={isChecked}
      onValueChange={() => {
        firebaseEventLogger('settings__themeToggle_Tap', {
          buttonName: 'themeToggle',
          screenName: 'settings',
          userType: 'user',
          interactionType: 'tap',
        });
        setIsChecked(!isChecked);
        themeChange();
      }}
    />
  );
};

export default ToggleComponent;
