import React from 'react';
import { Icon, View, Pressable } from '../new';

const StarComponent = ({ onPress, count = 0 }) => {
  const stars = [1, 2, 3, 4, 5];

  const renderIcon = (item) => (
    <Pressable onPress={() => onPress(item)} key={`stars_selected${item}`}>
      <Icon
        name={item <= count ? 'star-filled-300' : 'star-outline-300'}
        size="6xl"
        color={item <= count ? 'static.gold' : 'grey.200'}
      />
    </Pressable>
  );

  return (
    <View flexDirection="row">
      {stars?.length ? stars.map((item) => renderIcon(item)) : null}
    </View>
  );
};
export default StarComponent;
