import React from 'react';
import { View, StyleSheet, Image } from 'react-native';

// @ts-expect-error TS(2307): Cannot find module '../../../assets/check-black.gi... Remove this comment to see the full error message
import checkGif from '../../assets/check-black.gif';
import { getTheme } from '../themes/new/theme';

const SuccessLoader = () => {
  return (
    <View style={styles.animationContainer}>
      <Image source={checkGif} style={{ width: 100, height: 100 }} />
    </View>
  );
};

const styles = StyleSheet.create({
  animationContainer: {
    backgroundColor: getTheme().colors.static.white,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
});

export default SuccessLoader;
