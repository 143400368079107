/**
 * @generated SignedSource<<7391d2dae38639e62ae42d6241dd2ac1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type userApiDeleteUserMutation$variables = {
  _id: any;
};
export type userApiDeleteUserMutation$data = {
  readonly deleteUser: {
    readonly recordId: any | null;
  } | null;
};
export type userApiDeleteUserMutation = {
  response: userApiDeleteUserMutation$data;
  variables: userApiDeleteUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "_id"
      }
    ],
    "concreteType": "RemoveByIdUserSafeTCPayload",
    "kind": "LinkedField",
    "name": "deleteUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recordId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userApiDeleteUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userApiDeleteUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f4e2d282a0788ef17eb78e9a50a25d35",
    "id": null,
    "metadata": {},
    "name": "userApiDeleteUserMutation",
    "operationKind": "mutation",
    "text": "mutation userApiDeleteUserMutation(\n  $_id: MongoID!\n) {\n  deleteUser(_id: $_id) {\n    recordId\n  }\n}\n"
  }
};
})();

(node as any).hash = "47a4c3df54769031ec9953630883dacb";

export default node;
