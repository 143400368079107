import React, { memo, useState } from 'react';
import { FlatList } from 'react-native';
import { Text, View } from '../../../../components/new';
import FaqItem from '../../faq/FaqItem';
import { FAQs } from '../../../../utilities/InvestmentConstants';

const areEqual = () => {
  return true;
};

const InvestFAQs = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const handleItemPress = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <View pt="9xl" pb="9xl+9xl" alignItems="center">
      <Text size="xs" color="primary.300" pb="lg" textAlign="center">
        FAQs
      </Text>
      <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
        Frequently Asked Questions
      </Text>
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingTop: 24,
          paddingHorizontal: 16,
        }}
        renderItem={({ item, index }) => {
          return (
            <FaqItem
              title={item?.title}
              about={item?.about}
              index={index}
              isOpen={index === openIndex}
              onPress={handleItemPress}
            />
          );
        }}
        data={
          FAQs?.map((item, index) => ({
            index,
            title: item?.question,
            about: item?.answer,
          })) || []
        }
      />
    </View>
  );
};

export default memo(InvestFAQs, areEqual);
