/**
 * @generated SignedSource<<3eff6c8b7971a1092adcecc4ebe55020>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type SortFindManytaskInput = "CAMPUS___ID_ASC" | "CAMPUS___ID_DESC" | "CAMPUS___ID__ASSIGNEDTO_ASC" | "CAMPUS___ID__ASSIGNEDTO_DESC" | "CAMPUS___ID__ASSIGNEDTO__HISTORY__ACTION_ASC" | "CAMPUS___ID__ASSIGNEDTO__HISTORY__ACTION_DESC" | "CAMPUS___ID__ASSIGNEDTO__HISTORY__ACTION__CREATED_AT_ASC" | "CAMPUS___ID__ASSIGNEDTO__HISTORY__ACTION__CREATED_AT_DESC" | "CREATED_AT_DESC" | "DELAY_AT_ASC" | "DELAY_AT_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type supervisorOverviewApiTasksQuery$variables = {
  sort?: SortFindManytaskInput | null;
  taskFilter: findTaskFilterInput;
  taskLimit?: number | null;
};
export type supervisorOverviewApiTasksQuery$data = {
  readonly findTasks: ReadonlyArray<{
    readonly history: ReadonlyArray<{
      readonly action: EnumtaskHistoryAction;
      readonly event_at: any | null;
    } | null>;
    readonly metrics: {
      readonly acceptedIn: number | null;
      readonly assignedIn: number | null;
      readonly isAssignmentDelayed: boolean | null;
      readonly isOnbardingDelayed: boolean | null;
      readonly isParkingDelayed: boolean | null;
      readonly isWithinETA: boolean | null;
      readonly taskCompletionIn: number | null;
      readonly taskOnboardeIn: number | null;
      readonly valetTookInCompletion: number | null;
    } | null;
    readonly parkingTag: string | null;
    readonly taskEndLocation: {
      readonly name: string | null;
    } | null;
    readonly taskStartLocation: {
      readonly name: string | null;
    } | null;
    readonly taskType: EnumtaskTaskType;
    readonly valet: {
      readonly identityCode: string | null;
    } | null;
    readonly vehicle: {
      readonly brand: {
        readonly name: string | null;
      } | null;
      readonly registration: {
        readonly plate: string;
      } | null;
    } | null;
  }>;
};
export type supervisorOverviewApiTasksQuery = {
  response: supervisorOverviewApiTasksQuery$data;
  variables: supervisorOverviewApiTasksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskLimit"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "taskFilter"
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "taskLimit"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "findTasks",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskVehicle",
        "kind": "LinkedField",
        "name": "vehicle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleBrand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicleRegistration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskHistory",
        "kind": "LinkedField",
        "name": "history",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "event_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "valet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identityCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskMetrics",
        "kind": "LinkedField",
        "name": "metrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isWithinETA",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isParkingDelayed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnbardingDelayed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAssignmentDelayed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignedIn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "acceptedIn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskOnboardeIn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskCompletionIn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valetTookInCompletion",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskTaskStartLocation",
        "kind": "LinkedField",
        "name": "taskStartLocation",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "taskTaskEndLocation",
        "kind": "LinkedField",
        "name": "taskEndLocation",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingTag",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "supervisorOverviewApiTasksQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "supervisorOverviewApiTasksQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "16f96903a1693e94cc98a600cbe25698",
    "id": null,
    "metadata": {},
    "name": "supervisorOverviewApiTasksQuery",
    "operationKind": "query",
    "text": "query supervisorOverviewApiTasksQuery(\n  $taskLimit: Int\n  $taskFilter: findTaskFilterInput!\n  $sort: SortFindManytaskInput\n) {\n  findTasks(filter: $taskFilter, limit: $taskLimit, sort: $sort) {\n    taskType\n    vehicle {\n      brand {\n        name\n      }\n      registration {\n        plate\n      }\n    }\n    history {\n      action\n      event_at\n    }\n    valet {\n      identityCode\n    }\n    metrics {\n      isWithinETA\n      isParkingDelayed\n      isOnbardingDelayed\n      isAssignmentDelayed\n      assignedIn\n      acceptedIn\n      taskOnboardeIn\n      taskCompletionIn\n      valetTookInCompletion\n    }\n    taskStartLocation {\n      name\n    }\n    taskEndLocation {\n      name\n    }\n    parkingTag\n  }\n}\n"
  }
};
})();

(node as any).hash = "eca4d172fdf520045cca5f16550f2426";

export default node;
