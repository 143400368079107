import { taskType, taskFilter } from './constants';
import type { StateType, ActionType } from './types';

export const actionTypes: Record<string, string> = {
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_DAYPICKER_DATA: 'SET_DAYPICKER_DATA',
  SET_METRICS_DATA: 'SET_METRICS_DATA',
  SET_ALL_METRICS_DATA: 'SET_ALL_METRICS_DATA',
  SET_TASKS_DATA: 'SET_TASKS_DATA',
  SET_TASK_TYPE: 'SET_TASK_TYPE',
  SET_TASK_FILTER: 'SET_TASK_FILTER',
  SET_FILTERED_TASKS: 'SET_FILTERED_TASKS',
};

export const initialState: StateType = {
  selectedDate: null,
  dayPickerData: null,
  allMetricsData: null,
  metricsData: null,
  taskType: taskType.RECALL,
  taskFilter: taskFilter.DELAYED,
  tasksData: null,
  filteredTasks: null,
};

export const parkingOverviewReducer = (
  state: StateType,
  action: ActionType,
) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_DATE: {
      return {
        ...state,
        selectedDate: action.payload,
      };
    }

    case actionTypes.SET_TASK_TYPE: {
      return {
        ...state,
        taskType: action.payload,
      };
    }

    case actionTypes.SET_DAYPICKER_DATA: {
      return {
        ...state,
        dayPickerData: action.payload,
      };
    }
    case actionTypes.SET_ALL_METRICS_DATA: {
      return {
        ...state,
        allMetricsData: action.payload,
      };
    }
    case actionTypes.SET_METRICS_DATA: {
      return {
        ...state,
        metricsData: {
          ...action.payload,
        },
      };
    }
    case actionTypes.SET_TASKS_DATA: {
      return {
        ...state,
        tasksData: action.payload,
      };
    }
    case actionTypes.SET_TASK_FILTER: {
      return {
        ...state,
        taskFilter: action.payload,
      };
    }
    case actionTypes.SET_FILTERED_TASKS: {
      return {
        ...state,
        filteredTasks: action.payload,
      };
    }
    default:
      return state;
  }
};
