/**
 * @generated SignedSource<<1f778365a6e1933860425419a38caaa1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnuminvoiceOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnuminvoicePaymentType = "BILL_UPLOAD" | "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnuminvoicePopUpStoreDetailsDeliveryStatus = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type Periodicity = "DAILY" | "MONTHLY" | "%future added value";
export type findInvoiceInput = {
  AND?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  OR?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyinvoiceOperatorsInput | null;
  addons?: FilterFindManyinvoiceAddonsInput | null;
  couponCode?: string | null;
  created_at?: any | null;
  csrContributionId?: any | null;
  datePeriod?: DatePeriod | null;
  displayName?: string | null;
  orderId?: any | null;
  orderType?: EnuminvoiceOrderType | null;
  paymentTxnId?: any | null;
  paymentType?: EnuminvoicePaymentType | null;
  popUpStoreDetails?: FilterFindManyinvoicePopUpStoreDetailsInput | null;
  priceDetails?: FilterFindManyinvoicePriceDetailsInput | null;
  rewardTxnId?: any | null;
  search?: findInvoiceInputSearch | null;
  status?: EnuminvoiceStatus | null;
  uId?: string | null;
  userId?: any | null;
  workshopDetails?: FilterFindManyinvoiceWorkshopDetailsInput | null;
};
export type FilterFindManyinvoicePriceDetailsInput = {
  _id?: any | null;
  addonAmount?: number | null;
  cashbackAmount?: number | null;
  cashbackPercentage?: number | null;
  couponAmount?: number | null;
  couponCashbackAmount?: number | null;
  couponId?: any | null;
  csrAmount?: number | null;
  discountGiven?: number | null;
  discountId?: any | null;
  discountPercentage?: number | null;
  grossAmount?: number | null;
  netAmount?: number | null;
  rewardsUsed?: number | null;
  tipAmount?: number | null;
};
export type FilterFindManyinvoiceWorkshopDetailsInput = {
  slotId?: any | null;
  tickets?: ReadonlyArray<FilterFindManyinvoiceWorkshopDetailsTicketsInput | null> | null;
};
export type FilterFindManyinvoiceWorkshopDetailsTicketsInput = {
  spaces?: number | null;
  ticketId?: any | null;
};
export type FilterFindManyinvoicePopUpStoreDetailsInput = {
  _id?: any | null;
  deliveryStatus?: EnuminvoicePopUpStoreDetailsDeliveryStatus | null;
  displayName?: string | null;
};
export type FilterFindManyinvoiceAddonsInput = {
  orderId?: any | null;
};
export type FilterFindManyinvoiceOperatorsInput = {
  _id?: FilterFindManyinvoice_idOperatorsInput | null;
  orderType?: FilterFindManyinvoiceOrderTypeOperatorsInput | null;
  status?: FilterFindManyinvoiceStatusOperatorsInput | null;
};
export type FilterFindManyinvoiceStatusOperatorsInput = {
  exists?: boolean | null;
  gt?: EnuminvoiceStatus | null;
  gte?: EnuminvoiceStatus | null;
  in?: ReadonlyArray<EnuminvoiceStatus | null> | null;
  lt?: EnuminvoiceStatus | null;
  lte?: EnuminvoiceStatus | null;
  ne?: EnuminvoiceStatus | null;
  nin?: ReadonlyArray<EnuminvoiceStatus | null> | null;
};
export type FilterFindManyinvoiceOrderTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnuminvoiceOrderType | null;
  gte?: EnuminvoiceOrderType | null;
  in?: ReadonlyArray<EnuminvoiceOrderType | null> | null;
  lt?: EnuminvoiceOrderType | null;
  lte?: EnuminvoiceOrderType | null;
  ne?: EnuminvoiceOrderType | null;
  nin?: ReadonlyArray<EnuminvoiceOrderType | null> | null;
};
export type FilterFindManyinvoice_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FilterFindManyinvoiceInput = {
  AND?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  OR?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyinvoiceOperatorsInput | null;
  addons?: FilterFindManyinvoiceAddonsInput | null;
  couponCode?: string | null;
  created_at?: any | null;
  csrContributionId?: any | null;
  displayName?: string | null;
  orderId?: any | null;
  orderType?: EnuminvoiceOrderType | null;
  paymentTxnId?: any | null;
  paymentType?: EnuminvoicePaymentType | null;
  popUpStoreDetails?: FilterFindManyinvoicePopUpStoreDetailsInput | null;
  priceDetails?: FilterFindManyinvoicePriceDetailsInput | null;
  rewardTxnId?: any | null;
  status?: EnuminvoiceStatus | null;
  uId?: string | null;
  userId?: any | null;
  workshopDetails?: FilterFindManyinvoiceWorkshopDetailsInput | null;
};
export type DatePeriod = {
  from: string;
  period: Periodicity;
};
export type findInvoiceInputSearch = {
  uId?: string | null;
};
export type trackMyVehicleApiFindInvoiceQuery$variables = {
  filter: findInvoiceInput;
};
export type trackMyVehicleApiFindInvoiceQuery$data = {
  readonly findInvoice: ReadonlyArray<{
    readonly _id: any;
  }>;
};
export type trackMyVehicleApiFindInvoiceQuery = {
  response: trackMyVehicleApiFindInvoiceQuery$data;
  variables: trackMyVehicleApiFindInvoiceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "invoice",
    "kind": "LinkedField",
    "name": "findInvoice",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackMyVehicleApiFindInvoiceQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackMyVehicleApiFindInvoiceQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "082360e3f719c7d1332b6111d41c009f",
    "id": null,
    "metadata": {},
    "name": "trackMyVehicleApiFindInvoiceQuery",
    "operationKind": "query",
    "text": "query trackMyVehicleApiFindInvoiceQuery(\n  $filter: findInvoiceInput!\n) {\n  findInvoice(filter: $filter) {\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "86a729c8b9c460dd651b99816e074806";

export default node;
