/* eslint-disable no-nested-ternary */
import React from 'react';
import { Image } from 'react-native';
import {
  IconButton,
  Layout,
  Pressable,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import PrizeImage from '../../../../../assets/images/prize.webp';
import PaymentsImage from '../../../../../assets/images/payments.webp';
import GiftCardImage from '../../../../../assets/images/gift-card.webp';
import PolygonImage from '../../../../../assets/images/polygon.webp';
import winningScreenBg from '../../../../../assets/images/winningScreenBg.webp';
import { useSlotMachineStore } from '../../../../stores/games/slotMachine/slotMachineStore';
import LightThemeWrapper from '../../wrapperComp/LightThemeWrapper';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';

const ResultComp = ({ rewards, goBack, navigation }) => {
  const {
    points: localStorePoints,
    otherRewardsCount: localStoreOtherRewardsCount,
  } = useSlotMachineStore((state) => {
    return state;
  });

  const won = localStorePoints || localStoreOtherRewardsCount;

  return (
    <>
      <Layout level={2} flex={1}>
        <View position="absolute" style={{ height: '100%', width: '100%' }}>
          <Image
            source={winningScreenBg}
            style={{ height: '100%', width: '100%' }}
          />
        </View>
        <View px="2xl" zIndex={1}>
          <TopNavigation
            appearance=""
            level=""
            IconRight={
              <LightThemeWrapper>
                <IconButton
                  onPress={goBack}
                  name="close-outline-300"
                  // appearance="ghost"
                />
              </LightThemeWrapper>
            }
          />
          <Text
            color="primary.500"
            fontFamily="heading"
            textAlign="center"
            fontSize={48}
            lineHeight={60}
          >
            Congratulations,
          </Text>
          <View>
            <Text
              color="primary.500"
              fontFamily="heading"
              textAlign="center"
              fontSize={48}
              lineHeight={60}
            >
              You Won!
            </Text>
          </View>
          <View alignItems="center" justifyContent="center">
            <View>
              {/* <Image
                source={Contrats}
                style={{ height: 390, width: 390, opacity: 0.3 }}
              /> */}

              <CustomLottieView
                style={{ opacity: 0.3, width: 390, height: 390 }}
                autoPlay
                loop
                source={require('../../../../../assets/lottie/light_lottie.json')}
                containerWidth={390}
              />
            </View>
            {!!won && (
              <>
                <View position="absolute">
                  <Image
                    source={PrizeImage}
                    style={{ height: 200, width: 200 }}
                  />
                </View>
              </>
            )}
          </View>
          <View
            alignItems="center"
            position="absolute"
            bottom="xs"
            left={0}
            right={0}
          >
            {!won && (
              <View width={198}>
                <Text size="xl" width={196} textAlign="center">
                  Keep Spinning to win exciting offers
                </Text>
              </View>
            )}
          </View>
        </View>
        <View flexDirection="row" flex={1} jusfifyContent="space-between">
          {!!localStorePoints && (
            <View
              flex={1}
              alignItems="center"
              style={{ height: 144, width: 144 }}
            >
              <Pressable
                style={{
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={() => {
                  firebaseEventLogger('slotMachine_result_screen__points_tap', {
                    buttonName: 'points',
                    screenName: 'slotMachineResult',
                    userType: 'user',
                    interactionType: 'tap',
                  });
                  navigation.navigate('pointsScreen', { backToHome: true });
                }}
              >
                <Image
                  source={PolygonImage}
                  style={{ height: 144, width: 144 }}
                  resizeMode="contain"
                />
                <View position="absolute" zIndex={1}>
                  <View alignItems="center">
                    <View>
                      {/* <Icon name="flash-on-300" size="md" /> */}
                      <Image
                        source={PaymentsImage}
                        style={{ width: 40, height: 40 }}
                      />
                    </View>
                    <Text>{localStorePoints} Points</Text>
                  </View>
                </View>
              </Pressable>
            </View>
          )}
          {!!localStoreOtherRewardsCount && (
            <View
              flex={1}
              alignItems="center"
              style={{ height: 144, width: 144 }}
            >
              <Pressable
                style={{
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={() => {
                  // ===========
                  firebaseEventLogger(
                    'slotMachine_result_screen__rewards_tap',
                    {
                      buttonName: 'rewards',
                      screenName: 'slotMachineResult',
                      userType: 'user',
                      interactionType: 'tap',
                    },
                  );
                  // ===========
                  navigation.navigate('RewardsScreen', { backToHome: true });
                }}
              >
                <Image
                  source={PolygonImage}
                  style={{ height: 144, width: 144 }}
                  // resizeMethod="resize"
                  resizeMode="contain"
                />
                <View position="absolute" zIndex={1}>
                  <View alignItems="center">
                    <View>
                      <Image
                        source={GiftCardImage}
                        style={{ width: 40, height: 40 }}
                      />
                    </View>
                    <Text>{localStoreOtherRewardsCount} Rewards</Text>
                  </View>
                </View>
              </Pressable>
            </View>
          )}
        </View>
        {/* <View position="absolute" bottom="9xl" left={0} right={0} px="2xl">
          <Button
            size="xl"
            onPress={() => {
              navigation.navigate('user', {
                screen: 'rewards',
              });
            }}
          >
            {getTextVal(textTokens.viewRewards)}
          </Button>
        </View> */}
      </Layout>
    </>
  );
};

export default ResultComp;
