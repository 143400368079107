/**
 * @generated SignedSource<<24e230d913ab3f8ee5d2cbf582167a5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumRewardRewardType = "Event" | "FreeBubbleTea" | "FreeFridgeMagnet" | "FreeKeyChain" | "FreeParking" | "FreePostCard" | "FreeSticker" | "%future added value";
export type addRewardsRecordInput = {
  deleted?: boolean | null;
  expireAt: any;
  isExpired: boolean;
  isUsed?: boolean | null;
  isUsedAt?: any | null;
  ownerId: any;
  parkingId?: any | null;
  rewardOrigin?: string | null;
  rewardType: EnumRewardRewardType;
  uniqueHash?: string | null;
};
export type rewardApiMutation$variables = {
  records: ReadonlyArray<addRewardsRecordInput>;
};
export type rewardApiMutation$data = {
  readonly addReward: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly recordIds: ReadonlyArray<any>;
  } | null;
};
export type rewardApiMutation = {
  response: rewardApiMutation$data;
  variables: rewardApiMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "records"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "records",
    "variableName": "records"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recordIds",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "rewardApiMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateManyRewardPayload",
        "kind": "LinkedField",
        "name": "addReward",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "rewardApiMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateManyRewardPayload",
        "kind": "LinkedField",
        "name": "addReward",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f6755971b987d0d7d5a5b0c6adc7f1c",
    "id": null,
    "metadata": {},
    "name": "rewardApiMutation",
    "operationKind": "mutation",
    "text": "mutation rewardApiMutation(\n  $records: [addRewardsRecordInput!]!\n) {\n  addReward(records: $records) {\n    recordIds\n    error {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8fdc4f848ae23822c4deb4fae0a2edd";

export default node;
