/**
 * @generated SignedSource<<7c3cc7b61d82de98bcc53fecb00b44ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type findValetFilterInput = {
  _id?: any | null;
  role?: FilterFindManyUserRoleInput | null;
};
export type FilterFindManyUserRoleInput = {
  meta?: FilterFindManyUserRoleMetaInput | null;
  name?: EnumUserRoleName | null;
};
export type FilterFindManyUserRoleMetaInput = {
  campus?: FilterFindManyUserRoleMetaCampusInput | null;
};
export type FilterFindManyUserRoleMetaCampusInput = {
  _id?: any | null;
  name?: string | null;
};
export type userApiFetchValetQuery$variables = {
  filter: findValetFilterInput;
};
export type userApiFetchValetQuery$data = {
  readonly findValet: ReadonlyArray<{
    readonly _id: any;
    readonly identityCode: string | null;
    readonly name: {
      readonly first: string;
      readonly last: string | null;
    };
    readonly ongoingTask: {
      readonly _id: any;
      readonly parkingETA: {
        readonly parkingTimePrediction: ReadonlyArray<{
          readonly acceptedToCompleteTime: any | null;
          readonly locationId: any | null;
          readonly startToCompleteTime: any | null;
        } | null> | null;
      } | null;
      readonly parkingId: any | null;
      readonly taskEndLocation: {
        readonly locationId: any;
        readonly parkingSpaces: {
          readonly floor: {
            readonly bay: {
              readonly bayId: any;
            } | null;
            readonly floorId: any;
          } | null;
        } | null;
      } | null;
      readonly vehicle: {
        readonly registration: {
          readonly plate: string;
        } | null;
      } | null;
    } | null;
  }>;
};
export type userApiFetchValetQuery = {
  response: userApiFetchValetQuery$data;
  variables: userApiFetchValetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "Valet",
    "kind": "LinkedField",
    "name": "findValet",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserName",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identityCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "ongoingTask",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskEndLocation",
            "kind": "LinkedField",
            "name": "taskEndLocation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskEndLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "floorId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                        "kind": "LinkedField",
                        "name": "bay",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bayId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskParkingETA",
            "kind": "LinkedField",
            "name": "parkingETA",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskParkingETAParkingTimePrediction",
                "kind": "LinkedField",
                "name": "parkingTimePrediction",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "acceptedToCompleteTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startToCompleteTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userApiFetchValetQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userApiFetchValetQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2a6d4f2a7e50c405c908ede756331e84",
    "id": null,
    "metadata": {},
    "name": "userApiFetchValetQuery",
    "operationKind": "query",
    "text": "query userApiFetchValetQuery(\n  $filter: findValetFilterInput!\n) {\n  findValet(filter: $filter) {\n    _id\n    name {\n      first\n      last\n    }\n    identityCode\n    ongoingTask {\n      _id\n      parkingId\n      vehicle {\n        registration {\n          plate\n        }\n      }\n      taskEndLocation {\n        locationId\n        parkingSpaces {\n          floor {\n            floorId\n            bay {\n              bayId\n            }\n          }\n        }\n      }\n      parkingETA {\n        parkingTimePrediction {\n          locationId\n          acceptedToCompleteTime\n          startToCompleteTime\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b774aa4027c45a6416288fc31b20a3c4";

export default node;
