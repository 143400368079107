import React, { memo } from 'react';
import { Linking, Pressable, useWindowDimensions } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Text, View } from '../../../components/new';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import AppConstants from '../../../utilities/AppConstants';
import { getTheme, themeModes } from '../../../themes/new/theme';
import CachedImage from '../../../components/new/custom/CachedImage';

const openStore = (store) => {
  firebaseEventLogger('DownloadCard__download_Tap', {
    buttonName: 'download',
    screenName: 'DownloadCard',
    userType: 'user',
    interactionType: 'Tap',
  });
  const appURL = `https://apps.apple.com/in/app/32nd-hub/${AppConstants.APP_STORE_ID}`;
  const playURL = `http://play.google.com/store/apps/details?id=${AppConstants.PLAY_STORE_PROD_ID}`;
  Linking.openURL(store === 'apple' ? appURL : playURL);
};

const areEqual = () => {
  return true;
};

const DownloadCard = () => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  return (
    <View
      bg="background.primary.elevation"
      px="2xl"
      py="4xl"
      borderRadius="lg"
      shadow="sm"
      borderColor="grey.50"
      borderWidth="xs"
      elevation={4}
    >
      <Text size="xl" weight="medium" color="primary.500" textAlign="center">
        Why wait for your car, when your car can wait for you!
      </Text>
      <View flexDirection="row" mt="4xl" mx="2xl">
        <Pressable onPress={() => openStore('apple')}>
          <CachedImage
            source={
              getTheme(theme.currentThemeMode).currentThemeMode ===
              themeModes.light
                ? require('../../../../assets/images/appstore-light.png')
                : require('../../../../assets/images/appstore-dark.png')
            }
            style={{
              width: width * 0.35,
              height: 40,
              resizeMode: 'contain',
            }}
          />
        </Pressable>
        <Pressable
          style={{
            marginLeft: getTheme().space['2xl'],
          }}
          onPress={() => openStore('android')}
        >
          <CachedImage
            source={
              getTheme(theme.currentThemeMode).currentThemeMode ===
              themeModes.light
                ? require('../../../../assets/images/google-play-light.png')
                : require('../../../../assets/images/google-play-dark.png')
            }
            style={{
              width: width * 0.35,
              height: 40,
              resizeMode: 'contain',
            }}
          />
        </Pressable>
      </View>
    </View>
  );
};

export default memo(DownloadCard, areEqual);
