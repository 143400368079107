import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

export const qrCodeScreenTaskQuery = graphql`
  query qrCodeScreenTaskQuery($taskFilter: findTaskFilterInput!) {
    findTasks(filter: $taskFilter) {
      _id
      vehicle {
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
      }
      taskType
      taskStartLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      taskEndLocation {
        locationId
        name
        parkingSpaces {
          floor {
            floorName
            floorId
            bay {
              bayId
              bayName
            }
          }
        }
      }
      campus {
        _id
        name
      }
      history {
        _id
        action
        actor
        event_at
      }
      assignedTo
      valet {
        _id
        name {
          first
          last
        }
        identityCode
        contact {
          phone {
            prefix
            number
          }
        }
      }
      user {
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
          }
        }
      }
      ETA {
        _id
        startAt
        delayAt
        originalPredictionTime
        pendingRecall
        available
        busy
      }
      vehicleId
      ownerId
      parkingId
      parkingTag
      createdBy {
        status
      }
    }
  }
`;

const fetchQrCodeScreenTaskQuery = (data) => {
  const variables = { taskFilter: data };
  const request = fetchQuery(environment, qrCodeScreenTaskQuery, variables);
  return request.toPromise();
};

export default fetchQrCodeScreenTaskQuery;
