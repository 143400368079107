import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, useWindowDimensions } from 'react-native';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { Text, View } from '../../../components/new';
import FaqItem from '../faq/FaqItem';
import useCommonStore from '../../../stores/commonStore';
import { calculateMaxHeight } from '../../../utilities/helper';

const FaqBottomSheet = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const { height: screenHeight } = useWindowDimensions();
  const maxHeightPercentage = 0.7;
  const bottomSheetRef = useRef(null);
  const handleItemPress = (index: number) => {
    // Toggle the selected item and close all others
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const { faqScreenData, faqBottomSheetOpen, setFaqBottomSheetOpen } =
    useCommonStore((state) => state);

  useEffect(() => {
    if (faqBottomSheetOpen) {
      bottomSheetRef?.current?.show();
    } else {
      setFaqBottomSheetOpen(false);
      bottomSheetRef?.current?.hide();
    }
  }, [faqBottomSheetOpen]);

  const handleOnDismiss = useCallback(() => {
    bottomSheetRef?.current?.hide();
    setFaqBottomSheetOpen(false);
  }, []);

  return (
    <BottomSheetV2 ref={bottomSheetRef} onClose={handleOnDismiss}>
      <View
        style={{
          height: calculateMaxHeight(screenHeight, maxHeightPercentage),
        }}
      >
        <Text size="xl" color="primary.300" px="2xl" pt="2xl">
          How to earn and redeem points?
        </Text>
        <FlatList
          style={{ flex: 1 }}
          contentContainerStyle={{
            paddingTop: 24,
            paddingHorizontal: 16,
          }}
          renderItem={({ item, index }) => {
            return (
              <FaqItem
                title={item.title}
                about={item.about}
                index={index}
                isOpen={index === openIndex}
                onPress={handleItemPress}
              />
            );
          }}
          data={faqScreenData}
        />
      </View>
    </BottomSheetV2>
  );
};

export default FaqBottomSheet;
