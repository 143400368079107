export enum fieldNames {
  plateNumberLastFour = 'plateNumberLastFour',
  tagId = 'tagId',
}

const constraints = {
  fullName: {
    presence: {
      allowEmpty: false,
      message: '^Please enter full name',
    },
    length: {
      minimum: 3,
      message: '^Name must be at least 3 characters',
    },
    format: {
      pattern: '[a-z A-Z]+',
      flags: 'i',
      message: '^Name should contain only alphabets',
    },
  },
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your phone number',
    },
    length: {
      minimum: 13,
      maximum: 13,
      message: '^Mobile Number should not exceed 10 digits',
    },

    format: {
      pattern: '[0-9]',
      flags: 'i',
      message: '^Mobile Number should not exceed 10 digits',
    },
  },
  phoneNumber: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your phone number',
    },
    length: {
      minimum: 13,
      maximum: 13,
      message: '^Please enter a valid phone number',
    },
    format: {
      pattern: '[0-9+]+',
      flags: 'i',
      message: '^Phone number should contain only digits',
    },
  },
  plateNumber: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your vehicle number',
    },
    format: {
      pattern: '^[0-9A-Z]{8,10}$',
      flags: 'i',
      message: '^Please enter a valid vehicle number',
    },
  },
  brandName: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your car maker name',
    },
    format: {
      pattern: '[A-Z 0-9]+',
      flags: 'i',
      message: '^Please enter a valid car maker name',
    },
  },
  topicValue: {
    presence: {
      allowEmpty: false,
      message: '^Please select topic value',
    },
  },
  role: {
    presence: {
      allowEmpty: false,
      message: '^Please select role',
    },
  },
  description: {
    presence: {
      allowEmpty: false,
      message: '^Please enter description',
    },
    length: {
      minimum: 10,
      maximum: 1000,
      message: '^Description should have minimum of 10 characters',
    },
  },
  customerName: {
    presence: {
      allowEmpty: false,
      message: '^Please enter customer name',
    },
    length: {
      minimum: 2,
      message: '^Customer Name must be at least 2 characters',
    },
    format: {
      pattern: '[a-z A-Z]+',
      flags: 'i',
      message: '^Customer Name should contain only alphabets',
    },
  },
  parkingTag: {
    presence: {
      allowEmpty: false,
      message: '^Please enter a valid Tag Id',
    },
    length: {
      minimum: 6,
      maximum: 6,
      message: '^Tag ID must be 6 characters',
    },
    format: {
      pattern: '[0-9+]+',
      flags: 'i',
      message: '^Tag Id should contain only digits',
    },
  },
  driverCode: {
    presence: {
      allowEmpty: false,
      message: '^Please enter Driver Code',
    },
    length: {
      minimum: 2,
      message: '^Driver Code must be at least 2 characters',
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^Driver Code should contain Numeric values',
    },
  },
  [fieldNames.plateNumberLastFour]: {
    presence: {
      allowEmpty: false,
      message: '^Number should be of 4 digits',
    },
    length: {
      minimum: 4,
      maximum: 4,
      message: '^Number should be of 4 digits',
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^Please enter a valid Vehicle number',
    },
  },
  [fieldNames.tagId]: {
    presence: {
      allowEmpty: false,
      message: '^Please enter a valid Tag ID',
    },
    length: {
      minimum: 5,
      maximum: 6,
      message: '^Please enter a valid Tag ID',
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^Please enter a valid Tag ID',
    },
  },
  email: {
    email: true,
  },
};

export default constraints;
