import React, { useEffect, useState } from 'react';
import { Platform, KeyboardAvoidingView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNetInfo } from '@react-native-community/netinfo';
import { browserName, isIOS } from 'react-device-detect';
import { Icon, View, Text } from './new';

type OwnProps = {
  icon?: string;
  text?: string;
  bottomNavigator?: boolean;
  setNetStatus?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DisconnectedDropover.defaultProps;

const DisconnectedDropover = (props: Props) => {
  const { icon, text, bottomNavigator, setNetStatus } = props;
  const { bottom } = useSafeAreaInsets();
  const netinfo = useNetInfo(null);
  const [connected, setConnected] = useState(true);

  useEffect(() => {
    if (netinfo && netinfo?.isConnected !== null) {
      if (
        Platform.OS === 'web' &&
        isIOS &&
        (browserName === 'Safari' || browserName === 'Mobile Safari')
      ) {
        setConnected(true);
        setNetStatus(true);
      } else {
        setConnected(
          netinfo?.isInternetReachable !== null
            ? netinfo?.isConnected && netinfo?.isInternetReachable
            : netinfo?.isConnected,
        );
        setNetStatus(
          netinfo?.isInternetReachable !== null
            ? netinfo?.isConnected && netinfo?.isInternetReachable
            : netinfo?.isConnected,
        );
      }
    }
  }, [netinfo]);

  if (!connected) {
    return (
      <KeyboardAvoidingView
        style={{
          position: 'absolute',
          left: 15,
          right: 15,
          bottom: bottomNavigator ? 16 : bottom + 16,
          alignSelf: 'center',
          zIndex: 999,
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={16}
      >
        <View
          flexDirection="row"
          alignItems="center"
          p="xl"
          borderRadius={4}
          bg="error.400"
        >
          <Icon size="2xl" color="primary.10" name={icon} />
          <Text ml="3xl" color="primary.10" textAlign="center" size="md">
            {text}
          </Text>
        </View>
      </KeyboardAvoidingView>
    );
  }
  return <></>;
};

export default DisconnectedDropover;

DisconnectedDropover.defaultProps = {
  text: '',
  icon: 'wifi-off-outline',
  bottomNavigator: false,
  setNetStatus: () => {},
};
