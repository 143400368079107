import { commitMutation, graphql } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

const checkClaimWelcomeRewardMutation = (data): Promise<any> => {
  const mutation = graphql`
    mutation checkClaimWelcomeRewardMutation($eventType: EventLogTypes!) {
      logEvent(eventType: $eventType) {
        success
        error
        data {
          rewardTicket {
            _id
            title
            ticketType
            thumbnailImg
            rewardId
            uId
          }
        }
      }
    }
  `;
  const variables = data;

  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        resolve(response);
      },
      onError: (err) => {
        reject(err);
      },
    });
  });
};

export default checkClaimWelcomeRewardMutation;
