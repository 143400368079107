import { graphql, fetchQuery, commitMutation } from 'react-relay';
import { resolve } from 'react-native-svg/lib/typescript/lib/resolve';
import environment from './relayEnvironment';
import { commitMutationPromise } from '../utilities/helper';

export const findLoginUser = (data) => {
  const query = graphql`
    query userApiQuery($id: MongoID!) {
      findUserById(_id: $id) {
        _id
        name {
          first
          last
        }
        contact {
          phone {
            number
            prefix
          }
        }
      }
    }
  `;
  const variables = {
    id: data,
  };

  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};

export const updateUser = (data, callback, errorCallBack) => {
  const mutation = graphql`
    mutation userApiUpdateMutation(
      $id: MongoID!
      $record: updateUserRecordInput!
    ) {
      updateUser(_id: $id, record: $record) {
        record {
          _id
          name {
            first
            last
          }
          contact {
            phone {
              prefix
              number
            }
          }
          role {
            name
          }
          profileImage
          photoMomentsSearchImage
        }
      }
    }
  `;
  const variables = data;
  let errors = null;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      errors = error;
      callback(response, error, data);
    },
    onError: (err) => {
      errors = err;
      errorCallBack(err);
    },
    updater: (proxyStore) => {
      if (errors === null) {
        const root = proxyStore.getRootField('updateUser');
        if (root) {
          const record = root.getLinkedRecord('record');
          const name = record.getLinkedRecord('name');
          const first = name.getValue('first');
          const last = name.getValue('last');
          const r = proxyStore.get(
            `client:root:findUserById(_id:"${variables.id}"):name`,
          );
          if (r) {
            r.setValue(first, 'first');
            r.setValue(last, 'last');
            // r.setValue(profileImage, 'profileImage');
          }

          // update number in store

          const contact = record.getLinkedRecord('contact');
          const phone = contact.getLinkedRecord('phone');
          const number = phone.getValue('number');
          const userPhoneRecord = proxyStore.get(
            `client:root:findUserById(_id:"${variables.id}"):contact:phone`,
          );
          if (userPhoneRecord) {
            userPhoneRecord.setValue(number, 'number');
          }
        }
      }
    },
  });
};

export const updateUserV2 = (userId, record) => {
  const mutation = graphql`
    mutation userApiUpdateV2Mutation(
      $id: MongoID!
      $record: updateUserRecordInput!
    ) {
      updateUser(_id: $id, record: $record) {
        record {
          _id
          name {
            first
            last
          }
          contact {
            phone {
              prefix
              number
            }
          }
          role {
            name
          }
          profileImage
          photoMomentsSearchImage
        }
      }
    }
  `;
  const variables = {
    id: userId,
    record,
  };
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, error) => {
        if (error) {
          rej(error);
        }
        res(response);
      },
      onError: (err) => {
        rej(err);
      },
    });
  });
};

export const addValet = (data, callback, handleAddValetErrorCB) => {
  const mutation = graphql`
    mutation userApiAddValetMutation($record: addValetRecordInput!) {
      addValet(record: $record) {
        record {
          _id
          role {
            name
          }
        }
      }
    }
  `;
  const variables = {
    record: data,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      handleAddValetErrorCB(err);
      // console.log('error', err.res.errors[0].message);
    },
  });
};

export const removeValet = (_id) => {
  const mutation = graphql`
    mutation userApiRemoveValetMutation($_id: MongoID!) {
      removeValet(_id: $_id) {
        recordId
      }
    }
  `;
  const variables = { _id };
  return commitMutationPromise(mutation, variables);
};

export const removeUser = (_id, callback) => {
  const mutation = graphql`
    mutation userApiRemoveUserMutation($_id: MongoID!) {
      removeUser(_id: $_id) {
        recordId
      }
    }
  `;
  const variables = { _id };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error);
    },
    onError: (err) => console.error(err),
  });
};

export const deleteUser = (_id, callback) => {
  const mutation = graphql`
    mutation userApiDeleteUserMutation($_id: MongoID!) {
      deleteUser(_id: $_id) {
        recordId
      }
    }
  `;
  const variables = { _id };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error);
    },
    onError: (err) => console.error(err),
  });
};

export const removeToken = (filter, callback, errorCallBack) => {
  const mutation = graphql`
    mutation userApiRemoveTokenMutation($filter: updateUserAuthtoken!) {
      removeToken(filter: $filter) {
        _id
      }
    }
  `;
  const variables = { filter };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error);
    },
    onError: (error) => {
      errorCallBack(error);
    },
  });
};
export const getRefreshToken = (filter) => {
  const query = graphql`
    query userApiGetRefreshTokenQuery($filter: findRefreshAuthtoken!) {
      getRefreshToken(filter: $filter) {
        _id
        token
        refreshToken
      }
    }
  `;
  const variables = {
    filter,
  };

  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};

export const searchUserByVehicleNumber = (numberPlate: string) => {
  const query = graphql`
    query userApiSearchUserByVehiclePlateQuery(
      $filter: searchUsersFilterInput!
    ) {
      searchUsers(filter: $filter) {
        name {
          first
          last
        }
        contact {
          phone {
            prefix
            number
          }
        }
        vehicles {
          registration {
            plate
          }
        }
      }
    }
  `;
  const variables = {
    filter: {
      registration: {
        plate: numberPlate,
      },
    },
  };

  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};

export const findValet = (data) => {
  const query = graphql`
    query userApiFetchValetQuery($filter: findValetFilterInput!) {
      findValet(filter: $filter) {
        _id
        name {
          first
          last
        }
        identityCode
        ongoingTask {
          _id
          parkingId
          vehicle {
            registration {
              plate
            }
          }
          taskEndLocation {
            locationId
            parkingSpaces {
              floor {
                floorId
                bay {
                  bayId
                }
              }
            }
          }
          parkingETA {
            parkingTimePrediction {
              locationId
              acceptedToCompleteTime
              startToCompleteTime
            }
          }
        }
      }
    }
  `;
  const variables = {
    filter: data,
  };

  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};
