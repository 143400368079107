import React, { forwardRef } from 'react';
import styled from 'styled-components/native';
import {
  color,
  border,
  space,
  shadow,
  position,
  variant as variantStyle,
} from 'styled-system';
import { useWindowDimensions } from 'react-native';
import baseTheme from '../../../../themes/new/theme';
import Pressable from '../../basic/Pressable/Pressable';

import View from '../../basic/View/View';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import { InputStates } from '../Input/helpers';
import InputBase from '../Input/InputBase';

const variants = ({ theme }) => ({
  [InputStates.default]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.success]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'success.500',
    borderRadius: 'md',
  },
  [InputStates.error]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    // borderColor: 'error.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.active]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
    shadowColor: theme.colors.singletone.black,
    ...theme.boxShadows.md,
  },
  [InputStates.disabled]: {
    bg: 'grey.50',
    borderWidth: 'xs',
    color: 'primary.100',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.unstyled]: {
    bg: 'primary.10',
    borderWidth: '0px',
    borderColor: 'primary.50',
  },
});

const StyledInputView = styled(View)(
  {
    justifyContent: 'center',
  },
  color,
  border,
  space,
  shadow,
  (props) => variantStyle({ variants: variants(props) }),
);

const StyledPressabled = styled(Pressable)(
  {
    position: 'absolute',
    paddingTop: ({ theme }) => theme.space.lg,
    paddingBottom: ({ theme }) => theme.space.lg,
    paddingLeft: ({ theme }) => theme.space.lg,
    paddingRight: ({ theme }) => theme.space.lg,
    zIndex: 1,
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  position,
);

function SelectInputWithLabel({
  rightIconName = null,
  rightIconColor,
  state = InputStates.default,
  onRightIconClick = () => {},
  inputRef,
  countryCode,
  leftInputStyle,
  type,
  ...props
}) {
  const { width, height } = useWindowDimensions();
  // console.log('type',type)
  return (
    <View flexDirection="row">
      <StyledInputView
        variant={state}
        ml="sm"
        // width={width > 500 ? 375 * 0.77 : width * 0.77}
        borderWidth={1}
        flex={1}
        // paddingLeft={baseTheme.space.xl}
        // paddingRight={
        //   rightIconName ? baseTheme.space['9xl'] : baseTheme.space.xl
        // }
        // paddingTop={baseTheme.space.lg}
        // paddingBottom={baseTheme.space.lg}
      >
        <View
          paddingLeft={baseTheme.space.xl}
          paddingRight={
            rightIconName ? baseTheme.space['9xl'] : baseTheme.space.xl
          }
          paddingTop={baseTheme.space.lg}
          paddingBottom={baseTheme.space.lg}
        >
          <View py="xs">
            <InputBase
              type={type}
              ref={inputRef}
              state={
                state === InputStates.disabled
                  ? InputStates.disabled
                  : InputStates.unstyled
              }
              {...props}
            />
          </View>
        </View>
      </StyledInputView>
    </View>
  );
}

export default SelectInputWithLabel;
