/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { Image, RefreshControl, useWindowDimensions } from 'react-native';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { useNodeFromPagination } from '../../hooks/useNodeFromPagination';
import { Layout, View, Text, IconButton } from '../../components/new';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import RenderItem from './RenderItem';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useCommonStore from '../../stores/commonStore';
import useUserStore from '../../stores/userStore';
import { getTheme } from '../../themes/new/theme';
import useAuditorTransactionStore from '../../stores/auditor/auditorTransactionStore';
import {
  auditorHomeScreenQuery,
  paymentHistoryFragment,
} from './API/auditorHomeScreenQuery';
import {
  DeliveryStatus,
  dynamicHeightMaker,
  PaymentStatusEnum,
} from '../../utilities/helper';
import { pagination } from '../../utilities/Constant';
import PaginationLoader from '../../components/new/custom/PaginationLoader';
import CustomFlatList from '../../components/new/custom/CustomFlatList';

const noTransactions = require('../../../assets/images/no-transaction.webp');

const AuditorHomeScreen = (props) => {
  const {
    // ------ for auditor home screen ------
    transactions,
    setTransactions,
    newTransactions, // for subscription bubble
    setNewTransactions, // for subscription
  } = useAuditorTransactionStore((state) => state);
  const userId = useUserStore((state) => state.id);

  const { queryOptions, variables, refresh, navigation } = props;
  const [refreshing, setRefreshing] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getPaymentHistoryFragment = useLazyLoadQuery(
    auditorHomeScreenQuery,
    {
      ...variables,
      count: pagination.defaultPageSize,
    },
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const {
    data: getPaymentHistoryPaginated,
    hasNext,
    loadNext,
  } = usePaginationFragment(paymentHistoryFragment, getPaymentHistoryFragment);

  const getPaymentHistory = useNodeFromPagination(
    getPaymentHistoryPaginated?.findPopupOrders,
  );

  const { width } = useWindowDimensions();

  const setData = () => {
    setTransactions({ data: getPaymentHistory });
  };

  useEffect(() => {
    setData();
  }, [getPaymentHistory, refreshing]);

  const noRecentTransactions = () => (
    <View
      justifyContent="center"
      alignItems="center"
      mt="50%"
      flex={1}
      px="2xl"
    >
      <Image
        source={noTransactions}
        style={{
          width: width - 4 * getTheme().space['2xl'],
          height: dynamicHeightMaker(
            width - 4 * getTheme().space['2xl'],
            0.705,
          ),
        }}
        resizeMode="contain"
      />
      <Text size="2xl" weight="medium" color="primary.400" mt="4xl">
        No Transactions Yet!
      </Text>
      <Text size="md" color="primary.200" mt="lg" textAlign="center">
        Cash transactions will be visible once initiated by the partners
      </Text>
    </View>
  );

  const onPullToRefresh = useCallback(() => {
    setRefreshing(true);
    refetchHistory(
      {},
      {
        fetchPolicy: 'store-and-network',
        onComplete: () => setRefreshing(false),
      },
    );
  }, []);

  const renderItem = ({ item }) => <RenderItem item={item} refresh={refresh} />;

  return (
    <>
      <View flex={1}>
        <CustomFlatList
          scrollsToTop
          style={{
            flex: 1,
            marginTop: getTheme().space['2xl'],
            paddingBottom: getTheme().space['4xl'],
          }}
          data={transactions}
          keyExtractor={(item) => item?._id}
          onEndReachedThreshold={0.7}
          onEndReached={() => {
            if (hasNext) {
              loadNext(pagination.defaultPageSize);
            }
          }}
          renderItem={renderItem}
          showsVerticalScrollIndicator
          ListEmptyComponent={noRecentTransactions}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onPullToRefresh}
            />
          }
          ListFooterComponentStyle={{
            margin: 0,
            padding: 0,
          }}
          ListFooterComponent={() => {
            if (hasNext) return <PaginationLoader />;
            return null;
          }}
        />
      </View>
    </>
  );
};

const AuditorHomeScreenWrapper = ({ navigation }) => {
  const { campusId } = useCommonStore((state) => state);
  const userId = useUserStore((state) => state.id);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'store-and-network',
      }));
    },
    [
      /* ... */
    ],
  );

  useFocusEffect(
    useCallback(() => {
      refresh();
      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  const filter = {
    orderStatus: DeliveryStatus.UNDELIVERED,
    status: PaymentStatusEnum.PENDING,
    campus: {
      _id: campusId,
    },
  };

  return (
    <Layout level={2} edges={['top']}>
      <View
        flexDirection="row"
        mb="4xl"
        px="2xl"
        mt="2xl"
        alignItems="center"
        justifyContent="space-between"
      >
        <View py="xs">
          <Text size="2xl" color="primary.300">
            Unpaid Transactions
          </Text>
        </View>
        <IconButton
          size="md"
          iconSize="2xl"
          appearance="ghost"
          shape="rounded"
          name="search-outline-400"
          onPress={() => {
            navigation.navigate('AuditorSearchCodeScreen');
          }}
        />
      </View>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={null}>
          <AuditorHomeScreen
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
            variables={{
              filter,
              limit: 100,
              sort: 'ASC',
            }}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default AuditorHomeScreenWrapper;
