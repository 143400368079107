export type dispatchDownloadAppArgsType = {
  visible: boolean;
  title: string;
  dsc: string;
  btnText: string;
  imgSrc?: any;
  lottieImgSrc?: string;
  onBtnPress: () => any;
};

export type downloadAppSliceReturnType = {
  visible: boolean;
  title: string;
  dsc: string;
  btnText: string;
  imgSrc?: any;
  lottieImgSrc?: string;
  onBtnPress: () => any;
  hide: () => any;
  dispatchDownloadApp: (args: dispatchDownloadAppArgsType) => any;
};

const downloadAppSlice = (
  set: (...a: any) => any,
): downloadAppSliceReturnType => {
  return {
    visible: false,
    title: '',
    dsc: '',
    btnText: '',
    imgSrc: '',
    lottieImgSrc: '',
    onBtnPress: () => {},
    hide: () => {
      set(() => {
        return { visible: false };
      });
    },
    dispatchDownloadApp: ({
      visible,
      title,
      dsc,
      btnText,
      lottieImgSrc,
      imgSrc,
      onBtnPress,
    }) => {
      set(() => {
        return {
          visible,
          title,
          dsc,
          btnText,
          imgSrc,
          lottieImgSrc,
          onBtnPress,
        };
      });
    },
  };
};

export default downloadAppSlice;
