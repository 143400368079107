import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';
import { View } from '../../../../../components/new';

const TrackingWidgetPlaceHolder = () => {
  const { width } = useWindowDimensions();

  return (
    <Placeholder Animation={Fade}>
      <View mt="2xl" px="2xl" height={200}>
        <PlaceholderLine
          height={200}
          style={{ width: width - 32, borderRadius: 16 }}
        />
      </View>
    </Placeholder>
  );
};

export default TrackingWidgetPlaceHolder;
