/* eslint-disable no-nested-ternary */
import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import {
  variant,
  space,
  color,
  layout,
  flexbox,
  border,
  position,
} from 'styled-system';
import { BlurView as ExpoBlurView } from 'expo-blur';
import StyledView from '../View/View';

const View = styled(StyledView)`
  ${(props) => levelVariants(props)}
`;

const levelVariants = ({ theme }) =>
  variant({
    prop: 'level',
    variants: {
      1: {
        backgroundColor:
          Platform.OS === 'android'
            ? theme.colors.opacity[98]
            : theme.colors.opacity[95],
      },
      2: {
        backgroundColor:
          Platform.OS === 'android'
            ? theme.colors.opacity[95]
            : theme.colors.opacity[90],
      },
      3: {
        backgroundColor:
          Platform.OS === 'android'
            ? theme.colors.opacity[95]
            : theme.colors.opacity[90],
      },
      4: {
        backgroundColor:
          Platform.OS === 'android'
            ? theme.colors.opacity[90]
            : theme.colors.opacity[90],
      },
      backdrop: {
        backgroundColor:
          Platform.OS === 'android'
            ? theme.colors.overlay[500]
            : theme.colors.overlay[500],
      },
    },
  });

const StyledBlurView = styled(ExpoBlurView).attrs(({ level }) => ({
  intensity:
    level === '1'
      ? 20
      : level === '2' || level === 'backdrop'
      ? 30
      : level === '3'
      ? 20
      : level === '4'
      ? 10
      : 100,
  tint: 'default',
}))`
  ${layout}
  ${space}
  ${color}
  ${flexbox}
  ${border}
  ${position}
`;

const BlurView = ({ children, level, ...rest }) => (
  <>
    {level && level !== 'none' ? (
      <StyledBlurView level={level} {...rest}>
        {level === 'backdrop' ? (
          <View flex={1} level={level}>
            {children || null}
          </View>
        ) : (
          <View>{children || null}</View>
        )}
        {/* <View flex={level === 'backdrop' ? 1 : 0} level={level}>
          {children || null}
        </View> */}
      </StyledBlurView>
    ) : (
      <View>{children || null}</View>
    )}
  </>
);

BlurView.defaultProps = {
  level: 'none',
};

export default BlurView;
