import { useState, useRef, useCallback } from 'react';

export enum ContentVariant {
  BonusIncentiveInfo,
  OnTimeBonusInfo,
  DelayedBonusInfo,
}

const useBonusInfoBottomSheet = () => {
  const [currentState, setCurrentStatus] = useState(
    ContentVariant.BonusIncentiveInfo,
  );
  const [taskCount, setTasks] = useState(0);
  const bsRef = useRef(null);

  const showBonusInfo = () => {
    setCurrentStatus(ContentVariant.BonusIncentiveInfo);
    bsRef?.current?.show();
  };

  const showOnTimeTaskInfo = (tasks: number) => {
    setTasks(tasks ?? 0);
    setCurrentStatus(ContentVariant.OnTimeBonusInfo);
    bsRef?.current?.show();
  };

  const showDelayedTaskInfo = (tasks: number) => {
    setTasks(tasks ?? 0);
    setCurrentStatus(ContentVariant.DelayedBonusInfo);
    bsRef?.current?.show();
  };

  const dismiss = useCallback(() => {
    bsRef.current?.hide();
  }, []);

  return {
    ref: bsRef,
    taskCount,
    currentState,
    showBonusInfo,
    showOnTimeTaskInfo,
    showDelayedTaskInfo,
    dismiss,
  };
};

export default useBonusInfoBottomSheet;
