/* eslint-disable import/prefer-default-export */
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchQuery, readInlineData } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';
import { transactionsFragmentInline } from '../../API/fragments/transactionsFragment';
import { transactionsSearchScreenQuery } from './transactionsSearchScreenQuery';
import { unwrapPagedData } from '../../../../utilities/paginationUtilities';
import { buildTransactionViewData } from '../../utils';
import { ITransaction, TransactionNodeGql } from '../../types';
import { transactionsSearchScreenQuery$variables } from './__generated__/transactionsSearchScreenQuery.graphql';
import useRestaurantPartnerStore from '../../../../stores/restaurantpartner/restaurantPartnerStore';

const fetchTransactionsQuery = debounce(
  (
    variables: transactionsSearchScreenQuery$variables,
    callbacks,
    subscriptionRef,
  ) => {
    subscriptionRef?.current?.unsubscribe(); // cancelling previous request on the way
    const observable$ = fetchQuery(
      environment,
      transactionsSearchScreenQuery,
      variables,
    );
    const subscription = observable$.subscribe({
      next: callbacks.onData,
      error: callbacks.onError,
    });
    subscriptionRef.current = subscription;
  },
  300,
);

interface HookReturnType {
  bsRef: { current: any };
  orderId: string;
  transaction: ITransaction;
  noTransactions: boolean;
  showTransactions: boolean;
  showNoContentView: boolean;
  isLoading: boolean;
  clearSearch: () => void;
  handleOrderIdTyping: (orderIdTyped: string) => void;
  openTransactionDetailsPanel: () => void;
}

export const useTransactionSearchScreen = (): HookReturnType => {
  const querySubscription = useRef(null);
  const bsRef = useRef();
  const restaurantId = useRestaurantPartnerStore((state) => state.restaurantId);
  const [orderId, setOrderId] = useState('');
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [resultsFetchingCompleted, setResultsFetchingCompleted] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const noTransactions = !transactions.length;
  const showNoContentView = resultsFetchingCompleted && noTransactions;
  const showTransactions = !isLoading && !noTransactions;

  useEffect(() => {
    /** dispose pending api requests in case component unmounts */
    // @ts-ignore
    return () => querySubscription?.current?.unsubscribe();
  }, []);

  const buildQueryVariables = useCallback(
    (
      ordId: string,
      restId: string,
    ): transactionsSearchScreenQuery$variables => {
      return {
        filter: {
          search: {
            uId: ordId,
          },
          orderId: restId,
        },
        pageSize: 1,
      };
    },
    [],
  );

  const fetchTransactions = useCallback((ordId: string) => {
    const variables = buildQueryVariables(ordId, restaurantId);
    setIsLoading(true);

    fetchTransactionsQuery(
      variables,
      {
        onData: (transactionsFragmentRef) => {
          const { transactions: searchedTransactionsWithEdges } =
            readInlineData(transactionsFragmentInline, transactionsFragmentRef);
          const transactionNodes = unwrapPagedData<TransactionNodeGql>(
            searchedTransactionsWithEdges.edges,
          );
          const searchedTransactions =
            buildTransactionViewData(transactionNodes);
          setIsLoading(false);
          setResultsFetchingCompleted(true);
          setTransactions(searchedTransactions);
          querySubscription.current = null;
        },
        onError: (e) => {
          console.log(e);
          setIsLoading(false);
          setResultsFetchingCompleted(true);
          setTransactions([]);
          querySubscription.current = null;
        },
      },
      querySubscription,
    );
  }, []);

  const handleOrderIdTyping = (orderIdTyped: string) => {
    const orderIdWithoutSpace = orderIdTyped.replace(/\s/g, '').toUpperCase();
    if (resultsFetchingCompleted) setResultsFetchingCompleted(false);
    if (orderIdWithoutSpace === orderId) return;
    setOrderId(orderIdWithoutSpace);

    if (orderIdWithoutSpace.length < 6) {
      if (orderIdWithoutSpace.length === 0) setTransactions([]);
      return;
    }
    fetchTransactions(orderIdWithoutSpace); // already debounced
  };

  const openTransactionDetailsPanel = () => {
    bsRef?.current?.show();
  };

  const resetSearchField = () => {
    setOrderId('');
    setTransactions([]);
    setIsLoading(false);
    setResultsFetchingCompleted(false);
  };

  const clearSearch = () => {
    if (orderId?.length > 0) {
      resetSearchField();
    }
  };

  return {
    bsRef,
    orderId,
    isLoading,
    transaction: transactions[0],
    noTransactions,
    showTransactions,
    showNoContentView,
    clearSearch,
    handleOrderIdTyping,
    openTransactionDetailsPanel,
  };
};
