/**
 * @generated SignedSource<<781383e8955ad879bf2abcfc1b427088>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumorderStatus = "ACCEPTED" | "CANCELLED" | "COMPLETED" | "CREATED" | "DELAYED" | "DELIVERED" | "DISPATCHED" | "PREPARED" | "PREPARING" | "%future added value";
export type findOrdersFilterInput = {
  _id?: any | null;
  campus?: FilterFindManyorderCampusInput | null;
  restaurantId?: any | null;
  status?: ReadonlyArray<EnumorderStatus | null> | null;
  userId?: any | null;
};
export type FilterFindManyorderCampusInput = {
  _id?: any | null;
};
export type pastOrdersQuery$variables = {
  filter?: findOrdersFilterInput | null;
};
export type pastOrdersQuery$data = {
  readonly findOrders: ReadonlyArray<{
    readonly _id: any;
    readonly menuItems: ReadonlyArray<{
      readonly itemId: any;
      readonly itemName: string | null;
      readonly quantity: number | null;
    } | null> | null;
    readonly orderDate: any;
    readonly priceDetails: {
      readonly _id: any | null;
      readonly total: number | null;
    } | null;
    readonly restaurant: {
      readonly displayName: string;
      readonly logo: string | null;
    } | null;
  }>;
};
export type pastOrdersQuery = {
  response: pastOrdersQuery$data;
  variables: pastOrdersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "order",
    "kind": "LinkedField",
    "name": "findOrders",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "orderMenuItems",
        "kind": "LinkedField",
        "name": "menuItems",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "orderPriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurants2",
        "kind": "LinkedField",
        "name": "restaurant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pastOrdersQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pastOrdersQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "42d9b39f304ce56faa043dfc94288717",
    "id": null,
    "metadata": {},
    "name": "pastOrdersQuery",
    "operationKind": "query",
    "text": "query pastOrdersQuery(\n  $filter: findOrdersFilterInput\n) {\n  findOrders(filter: $filter) {\n    _id\n    orderDate\n    menuItems {\n      itemId\n      quantity\n      itemName\n    }\n    priceDetails {\n      _id\n      total\n    }\n    restaurant {\n      displayName\n      logo\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ebc7d76a409763e97950f77054698c3";

export default node;
