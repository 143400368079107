/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';
import { commitMutationPromise } from '../../../../utilities/helper';

const defaultMutation = graphql`
  mutation addVehicleMutation($record: addVehicleRecordInput!) {
    addVehicle(record: $record) {
      record {
        brand {
          _id
          name
          brandLogo
        }
        _id
      }
    }
  }
`;
export const addVehicleMutation = (variables, mutation = defaultMutation) => {
  return commitMutationPromise(mutation, variables);
};
