/**
 * @generated SignedSource<<35cf8ca740f2a06a5eb4d850f497683f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumaddonAdditionalCashbackRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonAdditionalDiscountRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonPriceDiscountBaseDiscountType = "PERCENTAGE" | "%future added value";
export type EnumaddonType = "a_32ND_BLACK_MEMBERSHIP" | "%future added value";
export type FilterFindManyaddonInput = {
  AND?: ReadonlyArray<FilterFindManyaddonInput> | null;
  OR?: ReadonlyArray<FilterFindManyaddonInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyaddonOperatorsInput | null;
  additionalCashback?: ReadonlyArray<FilterFindManyaddonAdditionalCashbackInput | null> | null;
  additionalDiscount?: ReadonlyArray<FilterFindManyaddonAdditionalDiscountInput | null> | null;
  expiryDuration?: FilterFindManyaddonExpiryDurationInput | null;
  isMembershipType?: boolean | null;
  name?: string | null;
  price?: number | null;
  priceDiscount?: ReadonlyArray<FilterFindManyaddonPriceDiscountInput | null> | null;
  tier?: number | null;
  type?: EnumaddonType | null;
};
export type FilterFindManyaddonPriceDiscountInput = {
  _id?: any | null;
  baseDiscount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
};
export type FilterFindManyaddonPriceDiscountBaseDiscountInput = {
  _id?: any | null;
  type?: EnumaddonPriceDiscountBaseDiscountType | null;
  value?: number | null;
};
export type FilterFindManyaddonExpiryDurationInput = {
  _id?: any | null;
  days?: number | null;
};
export type FilterFindManyaddonAdditionalCashbackInput = {
  _id?: any | null;
  cashback?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refId?: any | null;
  refType?: EnumaddonAdditionalCashbackRefType | null;
};
export type FilterFindManyaddonAdditionalDiscountInput = {
  _id?: any | null;
  discount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refType?: EnumaddonAdditionalDiscountRefType | null;
};
export type FilterFindManyaddonOperatorsInput = {
  _id?: FilterFindManyaddon_idOperatorsInput | null;
  tier?: FilterFindManyaddonTierOperatorsInput | null;
  type?: FilterFindManyaddonTypeOperatorsInput | null;
};
export type FilterFindManyaddonTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnumaddonType | null;
  gte?: EnumaddonType | null;
  in?: ReadonlyArray<EnumaddonType | null> | null;
  lt?: EnumaddonType | null;
  lte?: EnumaddonType | null;
  ne?: EnumaddonType | null;
  nin?: ReadonlyArray<EnumaddonType | null> | null;
};
export type FilterFindManyaddonTierOperatorsInput = {
  exists?: boolean | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number | null> | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
  nin?: ReadonlyArray<number | null> | null;
};
export type FilterFindManyaddon_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type findAddonsQuery$variables = {
  filter?: FilterFindManyaddonInput | null;
};
export type findAddonsQuery$data = {
  readonly findAddons: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"findAddonsQueryfragment">;
  }>;
};
export type findAddonsQuery = {
  response: findAddonsQuery$data;
  variables: findAddonsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refType",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "findAddonsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "addon",
        "kind": "LinkedField",
        "name": "findAddons",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "findAddonsQueryfragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "findAddonsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "addon",
        "kind": "LinkedField",
        "name": "findAddons",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "addonAdditionalDiscount",
            "kind": "LinkedField",
            "name": "additionalDiscount",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "addonPriceDiscountBaseDiscount",
                "kind": "LinkedField",
                "name": "discount",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "addonAdditionalCashback",
            "kind": "LinkedField",
            "name": "additionalCashback",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "addonPriceDiscountBaseDiscount",
                "kind": "LinkedField",
                "name": "cashback",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c27f7f1502f00e093afc083e9e837a1",
    "id": null,
    "metadata": {},
    "name": "findAddonsQuery",
    "operationKind": "query",
    "text": "query findAddonsQuery(\n  $filter: FilterFindManyaddonInput\n) {\n  findAddons(filter: $filter) {\n    ...findAddonsQueryfragment\n  }\n}\n\nfragment findAddonsQueryfragment on addon {\n  _id\n  type\n  additionalDiscount {\n    refType\n    discount {\n      type\n      value\n    }\n  }\n  additionalCashback {\n    refType\n    cashback {\n      type\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "caf9296f1fabe8c2bc7e0c4d222968a1";

export default node;
