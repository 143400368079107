import React, { useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { StatusBar } from 'react-native';
import { View, Layout } from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import ControllerOverviewScreenInner from './ControllerOverviewScreenInner';

const ControllerOverviewScreen = () => {
  const [netStatus, setNetStatus] = useState(true);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  return (
    <Layout level={2}>
      <StatusBar barStyle="light-content" />
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <ControllerOverviewScreenInner queryOptions={refreshedQueryOptions} />
        </NewErrorBoundary>
      </View>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
        bottomNavigator
      />
    </Layout>
  );
};

export default ControllerOverviewScreen;
