import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const trackDiscountScreenQuery = graphql`
  query trackDiscountApiDiscountScreenQuery {
    getRestaurantPartnerDiscountTrack {
      _id
      totalSales
      totalDiscount
      totalNetAmount
      totalRewardsUsed
      totalDiscountPercentage
      discountId
      discount {
        discounts {
          allUser
          newUser
          corporateUser
        }
        isActive
      }
    }
  }
`;
