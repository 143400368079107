/**
 * @generated SignedSource<<249a7de2af8015665bbe0627271c6533>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumUserMembershipsStatus = "ACTIVE" | "EXPIRED" | "PAUSED" | "%future added value";
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type profileCardQueryFragment_findUserById_Query$data = {
  readonly _id: any;
  readonly identityCode: string | null;
  readonly memberships: ReadonlyArray<{
    readonly expiryDate: any;
    readonly membershipAddonId: any;
    readonly status: EnumUserMembershipsStatus | null;
  } | null> | null;
  readonly name: {
    readonly first: string;
    readonly last: string | null;
  };
  readonly parkedCount: number | null;
  readonly profileImage: string | null;
  readonly role: {
    readonly name: EnumUserRoleName | null;
  } | null;
  readonly userSince: string | null;
  readonly visitCount: number | null;
  readonly wallet: {
    readonly earnedAmount: number | null;
    readonly moneySaved: number | null;
  } | null;
  readonly " $fragmentType": "profileCardQueryFragment_findUserById_Query";
};
export type profileCardQueryFragment_findUserById_Query$key = {
  readonly " $data"?: profileCardQueryFragment_findUserById_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"profileCardQueryFragment_findUserById_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileCardQueryFragment_findUserById_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserName",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identityCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parkedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMemberships",
      "kind": "LinkedField",
      "name": "memberships",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membershipAddonId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expiryDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserWallet",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moneySaved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "earnedAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c98111e9cdf69a04ff0d7624b0a7a5fc";

export default node;
