import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const addVehicleScreenQuery = graphql`
  query addVehicleScreenQuery($filter: findVehicleBrandFilterInput) {
    findVehicleBrand(filter: $filter) {
      _id
      brand {
        name
        brandLogo
      }
    }
  }
`;
