import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

export const valetWorkLogQuery = graphql`
  query valetWorkLogQuery($filter: findValetWorkLogFilterInput!) {
    findValetWorkLog(filter: $filter) {
      _id
      taskScore
      taskScoreTime
      workLogHistory {
        time
        type
      }
    }
  }
`;

const fetchValetWorkLogQuery = (data) => {
  const variables = { filter: data };
  const request = fetchQuery(environment, valetWorkLogQuery, variables);
  return request.toPromise();
};

export default fetchValetWorkLogQuery;
