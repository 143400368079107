import { graphql, requestSubscription } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

const cashierParkingUpdatedSubscription = graphql`
  subscription cashierParkingUpdatedSubscription(
    $parkingId: ID!
    $userId: ID!
  ) {
    parkingUpdated(parkingId: $parkingId, userId: $userId) {
      _id
      parkingInvoice {
        paymentDetails {
          isPaid
          amount
        }
      }
    }
  }
`;

export default (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: cashierParkingUpdatedSubscription,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      console.log('error', error);
      // console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
