/**
 * @generated SignedSource<<4fe9be1943aace10a456ec8d70b828c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumstoresCategories = "accessories" | "all" | "fashion" | "skincare" | "%future added value";
export type EnumstoresDescriptionTagsIconColor = "error" | "info" | "success" | "%future added value";
export type EnumstoresMediaGalleryMediaMediaType = "image" | "video" | "%future added value";
export type EnumstoresMediaGallerySectionTitle = "Ambience" | "Awards___Recognitions" | "Founder_Story" | "Product" | "%future added value";
export type EnumstoresOpenHoursDay = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "%future added value";
export type findGalleryFilterInput = {
  AND?: ReadonlyArray<FilterFindManystoresInput> | null;
  OR?: ReadonlyArray<FilterFindManystoresInput> | null;
  _id?: any | null;
  _operators?: FilterFindManystoresOperatorsInput | null;
  address?: FilterFindManystoresAddressInput | null;
  amenities?: ReadonlyArray<string | null> | null;
  campus?: FilterFindManystoresCampusInput | null;
  categories?: ReadonlyArray<EnumstoresCategories | null> | null;
  contact?: FilterFindManytaskTaskStartLocationContactInput | null;
  deleted?: boolean | null;
  deletedAt?: any | null;
  description?: FilterFindManystoresDescriptionInput | null;
  displayName?: string | null;
  externalLinks?: ReadonlyArray<FilterFindManystoresExternalLinksInput | null> | null;
  logo?: string | null;
  mediaGallery?: ReadonlyArray<FilterFindManystoresMediaGalleryInput | null> | null;
  openHours?: ReadonlyArray<FilterFindManystoresOpenHoursInput | null> | null;
  overAllRating?: number | null;
  posId?: string | null;
  ratingTagLine?: string | null;
  ratings?: ReadonlyArray<FilterFindManystoresRatingsInput | null> | null;
  search?: findGalleryFilterInputSearch | null;
  temporaryClose?: boolean | null;
  trending?: boolean | null;
};
export type FilterFindManystoresDescriptionInput = {
  _id?: any | null;
  summary?: string | null;
  tagLine?: ReadonlyArray<string | null> | null;
  tags?: ReadonlyArray<FilterFindManystoresDescriptionTagsInput | null> | null;
};
export type FilterFindManystoresDescriptionTagsInput = {
  _id?: any | null;
  icon?: FilterFindManystoresDescriptionTagsIconInput | null;
  name?: string | null;
};
export type FilterFindManystoresDescriptionTagsIconInput = {
  _id?: any | null;
  color?: EnumstoresDescriptionTagsIconColor | null;
  iconName?: string | null;
};
export type FilterFindManystoresOpenHoursInput = {
  _id?: any | null;
  closesAt?: string | null;
  day?: EnumstoresOpenHoursDay | null;
  isOpen?: boolean | null;
  opensAt?: string | null;
};
export type FilterFindManystoresRatingsInput = {
  _id?: any | null;
  displayIcon?: string | null;
  platform?: string | null;
  ratingCount?: number | null;
  ratingScore?: number | null;
};
export type FilterFindManystoresAddressInput = {
  _id?: any | null;
  addressLine1?: string | null;
  geo?: FilterFindManystoresAddressGeoInput | null;
};
export type FilterFindManystoresAddressGeoInput = {
  _id?: any | null;
  lat?: string | null;
  long?: string | null;
};
export type FilterFindManystoresCampusInput = {
  _id?: any | null;
};
export type FilterFindManystoresExternalLinksInput = {
  _id?: any | null;
  posterURL?: string | null;
  publisher?: string | null;
  readingTime?: number | null;
  title?: string | null;
  webURL?: string | null;
};
export type FilterFindManystoresMediaGalleryInput = {
  _id?: any | null;
  description?: string | null;
  displayOrder?: number | null;
  media?: ReadonlyArray<FilterFindManystoresMediaGalleryMediaInput | null> | null;
  sectionTitle?: EnumstoresMediaGallerySectionTitle | null;
};
export type FilterFindManystoresMediaGalleryMediaInput = {
  _id?: any | null;
  cover?: boolean | null;
  coverVideo?: boolean | null;
  description?: string | null;
  displayOrder?: number | null;
  mediaType?: EnumstoresMediaGalleryMediaMediaType | null;
  mediaURL?: string | null;
  prime?: boolean | null;
  sectionCover?: boolean | null;
  title?: string | null;
};
export type FilterFindManytaskTaskStartLocationContactInput = {
  _id?: any | null;
  phone?: ReadonlyArray<FilterFindManytaskTaskStartLocationContactPhoneInput | null> | null;
};
export type FilterFindManytaskTaskStartLocationContactPhoneInput = {
  _id?: any | null;
  number?: string | null;
  prefix?: string | null;
};
export type FilterFindManystoresOperatorsInput = {
  _id?: FilterFindManystores_idOperatorsInput | null;
  displayName?: FilterFindManystoresDisplayNameOperatorsInput | null;
};
export type FilterFindManystoresDisplayNameOperatorsInput = {
  exists?: boolean | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string | null> | null;
  lt?: string | null;
  lte?: string | null;
  ne?: string | null;
  nin?: ReadonlyArray<string | null> | null;
  regex?: any | null;
};
export type FilterFindManystores_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FilterFindManystoresInput = {
  AND?: ReadonlyArray<FilterFindManystoresInput> | null;
  OR?: ReadonlyArray<FilterFindManystoresInput> | null;
  _id?: any | null;
  _operators?: FilterFindManystoresOperatorsInput | null;
  address?: FilterFindManystoresAddressInput | null;
  amenities?: ReadonlyArray<string | null> | null;
  campus?: FilterFindManystoresCampusInput | null;
  categories?: ReadonlyArray<EnumstoresCategories | null> | null;
  contact?: FilterFindManytaskTaskStartLocationContactInput | null;
  deleted?: boolean | null;
  deletedAt?: any | null;
  description?: FilterFindManystoresDescriptionInput | null;
  displayName?: string | null;
  externalLinks?: ReadonlyArray<FilterFindManystoresExternalLinksInput | null> | null;
  logo?: string | null;
  mediaGallery?: ReadonlyArray<FilterFindManystoresMediaGalleryInput | null> | null;
  openHours?: ReadonlyArray<FilterFindManystoresOpenHoursInput | null> | null;
  overAllRating?: number | null;
  posId?: string | null;
  ratingTagLine?: string | null;
  ratings?: ReadonlyArray<FilterFindManystoresRatingsInput | null> | null;
  temporaryClose?: boolean | null;
  trending?: boolean | null;
};
export type findGalleryFilterInputSearch = {
  galleryTitle?: string | null;
};
export type storeScreenQuery$variables = {
  filter: findGalleryFilterInput;
};
export type storeScreenQuery$data = {
  readonly findStore: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"storeDetail_findStore">;
  }>;
};
export type storeScreenQuery = {
  response: storeScreenQuery$data;
  variables: storeScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "storeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "stores",
        "kind": "LinkedField",
        "name": "findStore",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "storeDetail_findStore"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "storeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "stores",
        "kind": "LinkedField",
        "name": "findStore",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "storesDescription",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "summary",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "storesDescriptionTags",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "storesDescriptionTagsIcon",
                    "kind": "LinkedField",
                    "name": "icon",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iconName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagLine",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "overAllRating",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "storesOpenHours",
            "kind": "LinkedField",
            "name": "openHours",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "day",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "opensAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closesAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "storesRatings",
            "kind": "LinkedField",
            "name": "ratings",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platform",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayIcon",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ratingScore",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ratingCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ratingTagLine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "storesAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "storesAddressGeo",
                "kind": "LinkedField",
                "name": "geo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "long",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "storesCampus",
            "kind": "LinkedField",
            "name": "campus",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "storesMediaGallery",
            "kind": "LinkedField",
            "name": "mediaGallery",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sectionTitle",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "storesMediaGalleryMedia",
                "kind": "LinkedField",
                "name": "media",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediaURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediaType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sectionCover",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cover",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coverVideo",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "storesExternalLinks",
            "kind": "LinkedField",
            "name": "externalLinks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readingTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "posterURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "webURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publisher",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskStartLocationContact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskStartLocationContactPhone",
                "kind": "LinkedField",
                "name": "phone",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prefix",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "categories",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35c3103e59f0d73eaa73d4f573e52a11",
    "id": null,
    "metadata": {},
    "name": "storeScreenQuery",
    "operationKind": "query",
    "text": "query storeScreenQuery(\n  $filter: findGalleryFilterInput!\n) {\n  findStore(filter: $filter) {\n    ...storeDetail_findStore\n  }\n}\n\nfragment storeDetail_findStore on stores {\n  _id\n  displayName\n  logo\n  description {\n    summary\n    tags {\n      _id\n      name\n      icon {\n        iconName\n        color\n        _id\n      }\n    }\n    tagLine\n  }\n  overAllRating\n  openHours {\n    day\n    opensAt\n    closesAt\n  }\n  ratings {\n    _id\n    platform\n    displayIcon\n    ratingScore\n    ratingCount\n  }\n  ratingTagLine\n  address {\n    _id\n    addressLine1\n    geo {\n      lat\n      long\n    }\n  }\n  campus {\n    _id\n  }\n  mediaGallery {\n    _id\n    sectionTitle\n    description\n    media {\n      _id\n      title\n      description\n      mediaURL\n      mediaType\n      sectionCover\n      cover\n      prime\n      coverVideo\n      displayOrder\n    }\n    displayOrder\n  }\n  externalLinks {\n    _id\n    title\n    readingTime\n    posterURL\n    webURL\n    publisher\n  }\n  contact {\n    phone {\n      prefix\n      number\n    }\n  }\n  categories\n}\n"
  }
};
})();

(node as any).hash = "dd67e931e0aaf233554902b8a0a0312f";

export default node;
