import React, { memo } from 'react';
import { Platform, Pressable } from 'react-native';
import Animated from 'react-native-reanimated';
import { View } from '../../../../components/new';
import { colors, fontSizes } from '../../../../themes/new/theme';
import StarIcon from './StarIcon';
import CustomIcon from '../../../../components/new/primitive/Icon/customIcon';

const RewardTag = ({
  title = '',
  rewardsCount = 0,
  styles,
  disabled = false,
  iconName = '',
  rewardVal = 0,
  pointVal = 0,
  active = 0,
  onPress = () => {},
}) => {
  const handleOnPress = () => {
    if (disabled) return false;
    return onPress();
  };

  const checkIconColor = () => {
    if (active === 0) {
      if (pointVal === 1) return 'black';
    } else if (active === 1) {
      if (rewardVal === 1) return 'black';
    }
    return colors.static.gold;
  };

  return (
    <Pressable onPress={() => handleOnPress()}>
      <Animated.View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'center',
            borderRadius: 4,
            paddingHorizontal: 8,
            paddingVertical: 4,
          },
          styles,
        ]}
      >
        <View justifyContent="center" mr="sm">
          {Platform.OS === 'web' ? (
            <CustomIcon
              icon={iconName}
              color={checkIconColor()}
              size={fontSizes.md}
            />
          ) : (
            <StarIcon
              icon={iconName}
              color={checkIconColor()}
              size={fontSizes.md}
            />
          )}
        </View>
        <View justifyContent="center">
          <Animated.Text style={[{ fontSize: fontSizes.sm }, styles]}>
            {title}
          </Animated.Text>
        </View>
        <View mx="lg" justifyContent="center">
          <Animated.Text
            style={[
              { fontSize: fontSizes.sm, color: colors.primary[100] },
              styles,
            ]}
          >
            |
          </Animated.Text>
        </View>
        <View justifyContent="center">
          <Animated.Text style={[{ fontSize: fontSizes.sm }, styles]}>
            {rewardsCount}
          </Animated.Text>
        </View>
      </Animated.View>
    </Pressable>
  );
};

const memoizedRewardTag = memo(RewardTag);

export default memoizedRewardTag;
