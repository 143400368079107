import React from 'react';

import { Button, Chip, Text, View } from '../../../../components/new';
import { Appearance, State } from '../../../../themes/new/helper';

const InvestmentCitySelection = ({
  region = '',
  selectCity = (val: string) => {},
  proceedSelection = () => {},
}) => {
  const cityArr = [
    {
      name: 'Delhi-NCR',
      id: 'ncr',
    },
    {
      name: 'Mumbai',
      id: 'mb',
    },
    {
      name: 'Bangalore',
      id: 'bg',
    },
    {
      name: 'Hyderabad',
      id: 'hy',
    },
    {
      name: 'Chennai',
      id: 'ch',
    },
    {
      name: 'Kolkata',
      id: 'ko',
    },
    {
      name: 'Pune',
      id: 'pn',
    },
    {
      name: 'Ahmedabad',
      id: 'ah',
    },
    {
      name: 'Other',
      id: 'ot',
    },
  ];

  return (
    <View px="2xl" py="4xl" bg="background.secondary.elevation">
      <Text size="lg" weight="medium" color="primary.300" pb="2xl">
        Select your current location so we can assign you your regional expert!
      </Text>
      <View borderTopWidth={1} borderColor="grey.50">
        <View
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {cityArr.map((item) => (
            <Chip
              key={item?.id}
              textColor="primary.400"
              state={region === item?.name ? State.SELECTED : State.ACTIVE}
              onPress={() => selectCity(item?.name)}
              size="xs"
              iconLeft=""
              mt="2xl"
              appearance={Appearance.FILLED}
            >
              {item?.name}
            </Chip>
          ))}
        </View>
      </View>
      <Button
        state={State.ACTIVE}
        status="primary"
        size="md"
        mt="9xl"
        onPress={proceedSelection}
      >
        Proceed
      </Button>
    </View>
  );
};

export default InvestmentCitySelection;
