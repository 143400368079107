import React, {
  Suspense,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Platform, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  IconButton,
  Layout,
  TopNavigation,
  View,
  Text,
} from '../../components/new';
import Loading from '../../components/loading.component';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import {
  Capitalise,
  DateFormatsEnum,
  applyEllipsis,
  getFormatedDate,
  navigateBack,
} from '../../utilities/helper';
import { getTheme } from '../../themes/new/theme';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useUserStore from '../../stores/userStore';
import { unwrapPagedData } from '../../utilities/paginationUtilities';
import Ticket, {
  RedeemMode,
  TicketVariant,
} from '../../components/new/composites/Ticket';
import LightThemeWrapper from './wrapperComp/LightThemeWrapper';

type PartnerLocationType = {
  displayName: string;
  venue: {
    geo: {
      lat: string;
      long: string;
    };
  };
};

const infoText = `This ticket grants admission for one person. Please present this ticket at the event's entry gate.`;

const freeParkingScreenQuery = graphql`
  query FreeParkingScreenQuery($filterTicket: FilterFindManyticketInput!) {
    tickets: findTickets(filter: $filterTicket, first: 1) {
      edges {
        node {
          _id
          ticketType
          coverImg
          thumbnailImg
          uId
          displayType
          user {
            name {
              first
              last
            }
          }
          expires_on
          title
          issuedBy {
            refType
            _id
          }
          finePrint {
            summary
            details
          }
          status
          additionalFields {
            event {
              startTime
              endTime
              spaces
            }
          }
          canBeClaimedBy {
            venue {
              geo {
                lat
                long
              }
              addressLine1
            }
            displayName
          }
          rewardId
        }
      }
    }
  }
`;

const mapPartnerLocations = (venues: PartnerLocationType[]) => {
  return venues.map((v) => ({
    location: applyEllipsis(v.displayName, 20),
    lat: v?.venue?.geo?.lat,
    long: v?.venue?.geo?.long,
  }));
};

const RenderFreeParkingScreen = forwardRef((props, ref) => {
  const navigation = useNavigation();
  const { variables, refreshedQueryOptions, userRole, showViewRewards } = props;
  const { tickets } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLazyLoadQuery(freeParkingScreenQuery, variables, {
      ...refreshedQueryOptions,
    });

  const ticket = unwrapPagedData(tickets?.edges ?? [])[0];
  const partnerLocations = mapPartnerLocations(ticket?.canBeClaimedBy);
  const noOfPeople = parseFloat(ticket?.additionalFields?.event?.spaces);
  const { first, last } = ticket?.user?.name ?? {};
  let customerName = '';
  if (first?.length) {
    customerName = Capitalise(first.trim());
  }
  if (last?.length) {
    customerName = `${customerName} ${Capitalise(last.trim())}`;
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        navigateToTncScreenFromParent: navigateToTnCScreen,
      };
    },
    [],
  );

  const onPressViewRewards = () => {
    if (Platform.OS === 'web') {
      if (navigation.canGoBack()) {
        navigation.navigate('RewardsScreen');
      } else {
        navigateBack(navigation, userRole);
      }
    } else {
      navigation.navigate('RewardsScreen');
    }
  };
  const navigateToTnCScreen = () => {
    const tncList = ticket?.finePrint?.details ?? [];
    if (tncList?.length <= 0) {
      return;
    }
    navigation.navigate('ticketTnC', { details: tncList });
  };

  return (
    <>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ alignItems: 'center' }}
      >
        {ticket && (
          <Ticket
            cutoutCircleClr={getTheme().colors.background.primary.base}
            variant={TicketVariant.REWARD}
            customerName={
              typeof noOfPeople === 'number' && noOfPeople > 1
                ? `${customerName} +${noOfPeople - 1}`
                : customerName
            }
            expiryDate={
              getFormatedDate(
                new Date(ticket?.expires_on),
                DateFormatsEnum.DateMonthYear,
              ) as string
            }
            headerImage={ticket?.thumbnailImg}
            headerTitle={ticket?.title}
            ticketId={ticket?.uId.toString()}
            redeemableAt={partnerLocations}
            redeemConfig={
              ticket?.displayType === 'COUPON'
                ? {
                    mode: RedeemMode.COUPON,
                    amount: '0',
                    onApply: () => console.log('copy coupon code'),
                  }
                : ticket?.displayType === 'QR'
                ? {
                    mode: RedeemMode.QR,
                  }
                : undefined
            }
          />
        )}
        {showViewRewards ? (
          <Button
            mt="8xl+8xl"
            size="lg"
            state="active"
            appearance="filled"
            status="primary"
            onPress={onPressViewRewards}
          >
            View Rewards
          </Button>
        ) : (
          <></>
        )}
      </ScrollView>
      {ticket && (
        <View
          width="100%"
          maxWidth={390}
          px="2xl"
          pb="2xl"
          mb="4xl"
          flexDirection="row"
          alignItems="center"
        >
          {ticket?.finePrint?.details?.length && (
            <IconButton
              name="info-outline-300"
              appearance="ghost"
              iconColor="primary.500"
              onPress={navigateToTnCScreen}
            />
          )}
          <Text size="xs" color="primary.200">
            {ticket?.finePrint?.summary}
          </Text>
        </View>
      )}
    </>
  );
});

const FreeParkingScreen = ({ navigation, route }) => {
  const ref = useRef();
  const { rewardId: ticketId, showViewRewards } =
    (route && route?.params) ?? {};
  const [netStatus, setNetStatus] = useState(true);
  const userRole = useUserStore((state) => state.role);

  const [title, setTitle] = useState('Redeem Reward');

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  return (
    <LightThemeWrapper>
      <Layout level={2}>
        <TopNavigation
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconSize="2xl"
              iconColor="primary.500"
              shape="square"
              onPress={onPressLeftIcon}
            />
          }
          IconRight={
            <IconButton
              name="menu-dots-400"
              size="md"
              appearance="ghost"
              onPress={() => {
                if (
                  typeof ref.current?.navigateToTncScreenFromParent ===
                  'function'
                ) {
                  ref.current?.navigateToTncScreenFromParent();
                }
              }}
            />
          }
          appearance="ghost"
          level="1"
          title={title}
        />
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <RenderFreeParkingScreen
              ref={ref}
              refreshedQueryOptions={refreshedQueryOptions}
              userRole={userRole}
              showViewRewards={showViewRewards}
              variables={{
                filterTicket: {
                  uId: parseInt(ticketId, 10),
                },
              }}
              fetchKey={refreshedQueryOptions.fetchKey}
            />
          </Suspense>
        </NewErrorBoundary>
        <DisconnectedDropover
          setNetStatus={setNetStatus}
          text="No Internet Connection"
          icon="wifi-off-outline"
        />
      </Layout>
    </LightThemeWrapper>
  );
};

export default FreeParkingScreen;
