import React, { FC, ReactElement, useRef, useState } from 'react';
import { FlatList, TouchableOpacity, Modal, Platform } from 'react-native';
import { get } from 'lodash';
import { useTheme } from 'styled-components/native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Divider, Icon, View, Text } from '../../../../components/new';

const borderWidth = 1;

interface Props {
  disabled: boolean;
  placeholder: string;
  selected: { optionLabel: string; value: string };
  label: string;
  data: Array<{ optionLabel: string; value: string }>;
  onSelect: (item: { optionLabel: string; value: string }) => void;
}

const Dropdown: FC<Props> = ({
  disabled,
  selected,
  placeholder,
  label,
  data,
  onSelect,
}) => {
  const DropdownButton = useRef();
  const [visible, setVisible] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);

  const toggleDropdown = (): void => {
    // eslint-disable-next-line no-unused-expressions
    visible ? setVisible(false) : openDropdown();
  };
  const theme = useTheme();
  const { top } = useSafeAreaInsets();

  const openDropdown = (): void => {
    DropdownButton?.current?.measure((_fx, _fy, _w, h, _px, py) => {
      if (Platform.OS === 'android') {
        setDropdownTop(py + h - top);
      } else {
        setDropdownTop(py + h);
      }
    });
    setVisible(true);
  };

  const onItemPress = (item): void => {
    onSelect(item);
    setVisible(false);
  };

  const renderItem = ({ item }): ReactElement<any, any> => (
    <TouchableOpacity onPress={() => onItemPress(item)}>
      <View px="xl">
        <Divider />
        <View pt="2xl" pb="lg">
          <Text size="md" color="primary.500">
            {item.optionLabel}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  const renderDropdown = (): ReactElement<any, any> => {
    return (
      <Modal visible={visible} transparent animationType="none">
        <View px="2xl" borderBottomLeftRadius="md">
          <TouchableOpacity
            style={{ width: '100%', height: '100%' }}
            onPress={() => setVisible(false)}
          >
            <View
              style={[
                {
                  position: 'absolute',
                  backgroundColor: get(theme.colors, 'primary.10'),
                  width: '100%',
                  borderLeftWidth: borderWidth,
                  borderRightWidth: borderWidth,
                  borderBottomWidth: borderWidth,
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  borderColor: get(theme.colors, 'primary.50'),
                },
                { top: dropdownTop },
              ]}
            >
              <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
          </TouchableOpacity>
        </View>
      </Modal>
    );
  };

  return (
    <>
      <Text color="primary.300" size="md" mb="lg">
        {label}
      </Text>
      <TouchableOpacity
        ref={DropdownButton}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          // backgroundColor: get(theme.colors, 'primary.10'),
          // height: 50,
          zIndex: 1,
          borderTopWidth: borderWidth,
          borderLeftWidth: borderWidth,
          borderRightWidth: borderWidth,
          borderBottomWidth: visible ? 0 : borderWidth,
          borderBottomLeftRadius: visible ? 0 : 8,
          borderBottomRightRadius: visible ? 0 : 8,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          overflow: 'hidden',
          borderColor: get(theme.colors, 'primary.50'),
        }}
        onPress={(...props) => {
          if (!disabled && !!data.length) {
            toggleDropdown(...props);
          }
        }}
      >
        <View bg="primary.10" flex={1}>
          {renderDropdown()}
          <View
            px="xl"
            bg="primary.10"
            py="lg"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              textAlign="left"
              size="md"
              color={
                selected && selected.optionLabel ? 'primary.500' : 'primary.100'
              }
            >
              {(selected && selected.optionLabel) || placeholder}
            </Text>
            <Icon name="down-outline-300" size="md" color="primary.50" />
          </View>
        </View>
      </TouchableOpacity>
    </>
  );
};

export default Dropdown;
