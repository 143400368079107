import { fetchQuery, graphql } from 'react-relay';
import environment from './relayEnvironment';

export interface UploadImageToS3Input {
  base64Image: string;
  bucketName: string;
  contentType: string;
  key: string;
}

const query = graphql`
  query microServicesApisUploadImageToS3Query($data: UploadImageToS3Input) {
    uploadImageToS3(data: $data) {
      bucketName
      key
    }
  }
`;

export const uploadImageToS3Query = (data: UploadImageToS3Input) => {
  const variables = data;
  const request = fetchQuery(environment, query, { data: variables });
  return request.toPromise();
};
