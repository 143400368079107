import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Image, Platform, ScrollView, useWindowDimensions } from 'react-native';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import * as FileSystem from 'expo-file-system';
import uuid from 'react-native-uuid';
import * as MediaLibrary from 'expo-media-library';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import {
  Button,
  Card,
  Divider,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import NewErrorView from '../../utilities/NewErrorView';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { Appearance, State, Status } from '../../themes/new/helper';
import useUserStore from '../../stores/userStore';
import {
  DateFormatsEnum,
  dynamicHeightMaker,
  getFormatedDate,
  navigateBack,
  UPLOAD_BILL_STATUS,
} from '../../utilities/helper';
import { getTheme } from '../../themes/new/theme';
import { useNodeFromPagination } from '../../hooks/useNodeFromPagination';
import {
  billUploadReviewerHomeScreenQuery,
  findBillsFragment,
} from './API/billUploadReviewerHomeScreenQuery';
import AppConstants from '../../utilities/AppConstants';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';

const ViewBillScreen = (props) => {
  const { queryOptions, variables, refresh, imageLink, viewOnly } = props;
  const { bottom } = useSafeAreaInsets();
  const navigation = useNavigation();
  const findBillsFragmentV2 = useLazyLoadQuery(
    billUploadReviewerHomeScreenQuery,
    {
      ...variables,
      count: 1,
    },
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const { data: findBillsPaginated } = usePaginationFragment(
    findBillsFragment,
    findBillsFragmentV2,
  );

  const findBills = useNodeFromPagination(findBillsPaginated?.findBills);
  const { _id, userInputs, created_at, ocrResponseParsed, restaurant } =
    findBills[0] ?? {};
  const { width } = useWindowDimensions();
  const createdAt = new Date(created_at);

  useEffect(() => {
    firebaseEventLogger('ViewBill__viewBill_Landing');
  }, []);

  return (
    <View flex={1}>
      <ScrollView
        contentContainerStyle={{
          marginBottom: 100,
        }}
      >
        <View px="2xl">
          <Image
            source={{
              uri: imageLink,
            }}
            style={{
              marginTop: getTheme().space['2xl'],
              width: width - 2 * getTheme().space['2xl'],
              height: dynamicHeightMaker(
                width - 2 * getTheme().space['2xl'],
                1,
              ),
            }}
            resizeMode="stretch"
          />
          <Card withBorder mt="4xl">
            <View
              flexDirection="row"
              mb="2xl"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text size="sm" color="primary.400">
                Bill Number
              </Text>
              <Text size="sm" color="primary.400">
                {ocrResponseParsed?.bill?.billId
                  ? `#${ocrResponseParsed?.bill?.billId}`
                  : ''}
              </Text>
            </View>
            <View
              flexDirection="row"
              mb="2xl"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text size="sm" color="primary.400">
                Business Name
              </Text>
              <Text size="sm" color="primary.400">
                {restaurant?.displayName}
              </Text>
            </View>
            <View
              flexDirection="row"
              mb="2xl"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text size="sm" color="primary.400">
                Bill Upload date
              </Text>
              <Text size="sm" color="primary.400">
                {`${getFormatedDate(
                  created_at,
                  DateFormatsEnum.DayDateMonthYear,
                )}, ${getFormatedDate(createdAt, DateFormatsEnum.TIME)}`}
              </Text>
            </View>
            <Divider />
            <View flexDirection="row" mt="lg">
              <View>
                <Text size="sm" color="primary.500" weight="medium">
                  Net Amount
                </Text>
              </View>
              <View flex={1} alignItems="flex-end">
                <Text size="sm" color="primary.500" weight="medium">
                  ₹{userInputs?.priceDetails?.netAmount}
                </Text>
              </View>
            </View>
          </Card>
        </View>
      </ScrollView>
      {viewOnly ? (
        <></>
      ) : (
        <>
          <View
            position="absolute"
            bottom={0}
            borderTopWidth="xs"
            width="100%"
            borderColor="primary.50"
            bg="background.primary.base"
          >
            <View flexDirection="row" alignItems="center" p="2xl" mb={bottom}>
              <View flex={1}>
                <Button
                  size="md"
                  state={State.ACTIVE}
                  status={Status.PRIMARY}
                  appearance={Appearance.OUTLINE}
                  onPress={() => {
                    firebaseEventLogger('viewBill__rejectBill_Tap', {
                      action: UPLOAD_BILL_STATUS.VERIFICATION_FAILED,
                      _id,
                    });
                    navigation.navigate('BillActionScreen', {
                      action: UPLOAD_BILL_STATUS.VERIFICATION_FAILED,
                      _id,
                      showInput: !ocrResponseParsed?.bill?.billId,
                    });
                  }}
                >
                  Reject Bill
                </Button>
              </View>
              <View flex={1} ml="sm+md">
                <Button
                  size="md"
                  state={State.ACTIVE}
                  status={Status.PRIMARY}
                  appearance={Appearance.FILLED}
                  onPress={() => {
                    firebaseEventLogger('viewBill__approveBill_Tap', {
                      action: UPLOAD_BILL_STATUS.VERIFICATION_FAILED,
                      _id,
                    });
                    navigation.navigate('BillActionScreen', {
                      action: UPLOAD_BILL_STATUS.VERIFIED,
                      _id,
                      showInput: !ocrResponseParsed?.bill?.billId,
                    });
                  }}
                >
                  Approve Bill
                </Button>
              </View>
            </View>
          </View>
        </>
      )}
    </View>
  );
};

const ViewBillScreenWrapper = ({ navigation, route }) => {
  const { restaurantName, bucket, key, itemId, viewOnly } = route?.params ?? {};
  const { role } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });
  const [netStatus, setNetStatus] = useState(true);
  const [permissionResponse, requestPermission] = MediaLibrary.usePermissions();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [loading, setLoading] = useState(false);
  const { width, height } = useWindowDimensions();

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'store-and-network',
      }));
    },
    [
      /* ... */
    ],
  );
  const filter = {
    _id: itemId,
  };

  const imageLink = `${AppConstants.IMAGEKIT_PROD_URI}/${bucket}/${key}`;

  const saveFile = async ({ fileUri }) => {
    const { status } = await requestPermission();
    if (status === 'granted') {
      try {
        const asset = await MediaLibrary.createAssetAsync(fileUri);
        const album = await MediaLibrary.getAlbumAsync('32nd');
        if (album == null) {
          await MediaLibrary.createAlbumAsync('32nd', asset, true);
        } else {
          MediaLibrary.addAssetsToAlbumAsync([asset], album.id, true);
        }
        setLoading(false);
        await FileSystem.deleteAsync(fileUri);
        dispatchSnackbar({
          msg: 'Bill downloaded successfully!',
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
        });
        firebaseEventLogger('viewBill__downloadSuccess_data', {
          buttonName: 'download',
          screenName: 'viewBill',
          imageLink,
        });
      } catch (error) {
        console.log('eeeee-', error);
        setLoading(false);
        dispatchSnackbar({
          msg: `Oops, Something went wrong!`,
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
        firebaseEventLogger('viewBill__downloadFailed_data', {
          buttonName: 'downloadFailed',
          screenName: 'viewBill',
          imageLink,
        });
      }
    }
  };

  const handleDownload = async () => {
    firebaseEventLogger('viewBill__download_Tap', {
      buttonName: 'download',
      screenName: 'viewBill',
      imageLink,
    });
    try {
      setLoading(true);
      let fileUri = `${FileSystem.documentDirectory}/${uuid.v4()}.jpeg`;
      fileUri = fileUri.replace(' ', '');
      const res = await FileSystem.downloadAsync(imageLink, fileUri);
      await saveFile({ fileUri: res.uri });
    } catch (err) {
      setLoading(false);
      firebaseEventLogger('viewBill__downloadFailed_Tap', {
        buttonName: 'downloadFailed',
        screenName: 'viewBill',
        imageLink,
      });
    }
  };

  return (
    <Layout level={2}>
      <TopNavigation
        title={`${restaurantName} Bill`}
        textSize="md"
        appearance={Appearance.FILLED}
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance={Appearance.GHOST}
            onPress={() => navigateBack(navigation, role)}
          />
        }
        IconRight={
          Platform.OS !== 'web' ? (
            <IconButton
              name="download-outline-400"
              size="md"
              appearance={Appearance.GHOST}
              iconColor="info.500"
              onPress={() => handleDownload()}
            />
          ) : (
            <View />
          )
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <>
            <ViewBillScreen
              fetchKey={refreshedQueryOptions?.fetchKey}
              queryOptions={refreshedQueryOptions}
              refresh={refresh}
              variables={{
                filter,
              }}
              imageLink={imageLink}
              viewOnly={viewOnly}
            />
            {loading && (
              <View
                justifyContent="center"
                alignItems="center"
                height={height}
                width={width}
                position="absolute"
                bottom={0}
              >
                <Loading />
              </View>
            )}
          </>
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default ViewBillScreenWrapper;
