import { useState, useCallback } from 'react';
import moment from 'moment';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { valetOverviewQuery } from './valetOverviewAPI';
import {
  EarningListItemType,
  PayoutListItemType,
  buildEarningListData,
  buildPayoutListData,
} from '../../../utilities/bonus';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { valetOverviewAPIQuery$data } from './__generated__/valetOverviewAPIQuery.graphql';

type DailyValetTaskBonusType =
  valetOverviewAPIQuery$data['dailyValetTaskBonus'];
type MonthlyValetTaskBonusType =
  valetOverviewAPIQuery$data['monthlyValetTaskBonus'];
type ValetLast12MonthsBonus =
  valetOverviewAPIQuery$data['valetLast12MonthsBonus'];

interface ValetOverviewScreenHookTypes {
  bannerData: {
    bonusAmount: number;
    onTimeTaskCount: number;
    delayedTaskCount: number;
  };
  hasNoEarningItems: boolean;
  hasNoPayoutItems: boolean;
  earningsTab: number;
  earningListItems: EarningListItemType[];
  payoutListItems: PayoutListItemType[];
  today: string;
  handleTasksScreenNavigation: (date: string, isToday?: boolean) => void;
  handleEarningTabSwitch: (idx: number) => void;
}

const todaysDate = moment().utc().toISOString();

/* eslint-disable-next-line import/prefer-default-export */
export const useValetOverviewScreen = (
  queryOptions: NewErrorBoundaryParentState,
): ValetOverviewScreenHookTypes => {
  const navigation = useNavigation();
  const [earningsTab, setEarningsTab] = useState(0);
  const [today, setToday] = useState(todaysDate);

  const {
    dailyValetTaskBonus,
    monthlyValetTaskBonus,
    valetLast12MonthsBonus,
  }: {
    dailyValetTaskBonus: DailyValetTaskBonusType;
    monthlyValetTaskBonus: MonthlyValetTaskBonusType;
    valetLast12MonthsBonus: ValetLast12MonthsBonus;
  } = useLazyLoadQuery(
    valetOverviewQuery,
    {
      dailyBonusFilter: {
        date: today,
      },
      monthlyBonusFilter: {
        date: today,
      },
      yearlyBonusFilter: {
        date: today,
      },
    },
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  useFocusEffect(
    useCallback(() => {
      setToday(moment().utc().toISOString());
    }, []),
  );

  const { bonusAmount, delayedTaskCount, onTimeTaskCount } =
    dailyValetTaskBonus?.bonus ?? {};
  const earningListItems = buildEarningListData<MonthlyValetTaskBonusType>(
    monthlyValetTaskBonus,
    today,
  );
  const payoutListItems = buildPayoutListData(valetLast12MonthsBonus);

  const handleEarningTabSwitch = useCallback(
    (idx: number) => {
      setEarningsTab(idx);
    },
    [setEarningsTab],
  );

  const handleTasksScreenNavigation = useCallback(
    (date: string, isToday = false) => {
      const params: { date: string; mode: string } = { date, mode: '' };
      if (isToday) params.mode = 'today';
      navigation.navigate('bonusTasks', params);
    },
    [navigation],
  );

  return {
    bannerData: {
      bonusAmount,
      onTimeTaskCount,
      delayedTaskCount,
    },
    hasNoEarningItems: !(earningListItems.length > 0),
    hasNoPayoutItems: !(payoutListItems.length > 0),
    earningsTab,
    earningListItems,
    payoutListItems,
    today,
    handleTasksScreenNavigation,
    handleEarningTabSwitch,
  };
};
