import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View } from '../../../../../components/new';

const CouponsPlaceholder = () => {
  const { width } = useWindowDimensions();
  const { top } = useSafeAreaInsets();

  return (
    <Placeholder Animation={Fade}>
      <View px="2xl" pt={top + 20}>
        <View top={20}>
          <PlaceholderLine
            height={120}
            style={{ width: width - 32, borderRadius: 16 }}
          />
        </View>
        <View top={-70}>
          <PlaceholderLine
            height={120}
            style={{ width: width - 32, borderRadius: 16 }}
          />
        </View>
        <View top={-160}>
          <PlaceholderLine
            height={120}
            style={{ width: width - 32, borderRadius: 16 }}
          />
        </View>
      </View>
    </Placeholder>
  );
};

export default CouponsPlaceholder;

const styles = StyleSheet.create({
  avatar: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 100,
    top: -50,
    left: '50%',
    transform: [{ translateX: -50 }],
  },
});
