import React from 'react';
import { PaymentStatusEnum } from '../../../../utilities/helper';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';

type TransactionStatusType = keyof typeof PaymentStatusEnum;

interface TransactionStatusProps {
  status: TransactionStatusType;
  size?: number;
  text: string;
}

const TransactionStatus = ({
  status,
  size = 8,
  text,
}: TransactionStatusProps) => {
  const color = resolveColor(status);
  return (
    <View flexDirection="row" alignItems="center">
      {/* @ts-ignore */}
      <View mr="sm" bg={color} width={size} height={size} borderRadius="2xl" />
      <Text size="xs" weight="regular" color={color}>
        {text}
      </Text>
    </View>
  );
};

export default TransactionStatus;

const resolveColor = (status: TransactionStatusType) => {
  switch (status) {
    case PaymentStatusEnum.SUCCESSFUL:
      return 'success.500';
    case PaymentStatusEnum.FAILED:
      return 'error.500';
    case PaymentStatusEnum.PENDING:
      return 'warning.500';
    case PaymentStatusEnum.REFUNDED:
      return 'grey.400';
    default:
      return 'grey.400';
  }
};
