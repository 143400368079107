import React, {
  Suspense,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import {
  Text,
  Layout,
  View,
  TopNavigation,
  IconButton,
} from '../../../components/new';
import { capitalizeAfterSpace, navigateBack } from '../../../utilities/helper';
import MenuScreenHeader from './MenuScreenHeaderSection';
import MenuListAndFooter from './MenuScreenListFooter';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useUserStore from '../../../stores/userStore';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const sortFn = (a, b) => {
  if (a.displayOrder < b.displayOrder) {
    return -1;
  }
  if (a.displayOrder > b.displayOrder) {
    return 1;
  }
  return 0;
};

// TODO: add restaurant again
const menuScreenQuery = graphql`
  query menuScreenQuery($filter: findMenu1FilterInput!) {
    findMenu1(filter: $filter) {
      restaurantId
      restaurant {
        _id
        posId
        displayName
        address {
          addressLine1
        }
        contact {
          phone {
            _id
            prefix
            number
          }
        }
      }
      _id
      menuCategory {
        _id
        categoryId
        sectionTitle
        description
        displayOrder
      }
      menuItems {
        _id
        posId
        menuType
        title
        description {
          summary
          tags
        }
        media {
          _id
          type
          mediaUrl
        }
        defaultPrice
        tax {
          _id
          posId
          value
          taxType
        }
        itemPrice {
          _id
          category
          minSelect
          maxSelect
          type
          item {
            _id
            title
            default
            price
          }
        }
        available
        type
        recomended
        chefRecomended
        bestSeller
        spicy
        newItem
        displayOrder
        categoryId
      }
    }
  }
`;

const NoMenu = ({ navigation }) => {
  const userRole = useUserStore((state) => state.role);

  return (
    <>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, userRole)}
          />
        }
      />
      <View flex={1} justifyContent="center" alignItems="center">
        <Text>Menu coming soon</Text>
      </View>
    </>
  );
};

const RenderMenuScreen = (props) => {
  const { queryOptions, variables, navigation, itemId } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const flatlistRef = useRef();

  const { findMenu1 } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLazyLoadQuery(menuScreenQuery, variables, {
      ...queryOptions,
      networkCacheConfig: { force: true },
    });

  useEffect(() => {
    const i = menuItems?.findIndex((item) => item._id === itemId);
    if (i > 0) {
      setTimeout(() => {
        flatlistRef?.current?.scrollToIndex({
          animated: true,
          index: i,
          // viewOffset: 116 + insets.top,
        });
      }, 100);
    }
  }, [flatlistRef]);

  if (!findMenu1.length) return <NoMenu navigation={navigation} />;
  const { restaurantId, menuCategory, menuItems, restaurant } = findMenu1[0];

  const restaurantName = restaurant?.displayName ?? '';
  // const restaurantposId = restaurant?.posId ?? '';
  const temp = [];
  let categories: Record<string, any>[] = [];
  let menu: Record<string, any>[] = [];
  menu = JSON.parse(JSON.stringify(menuItems));
  const menuCategoryData = menuCategory.map((item) => {
    return {
      ...item,
      sectionTitle: capitalizeAfterSpace(item?.sectionTitle?.toLowerCase()),
    };
  });
  categories = JSON.parse(JSON.stringify(menuCategoryData));

  /** handling zero price menu items and removing thise categories */
  menu = menu.filter((m) => m?.defaultPrice && m?.defaultPrice > 0); // removing zero price items
  const menuCategoryIds = menu.map((item) => item.categoryId);
  // keeping only categories for which menu item price is not zero
  categories = categories.filter((category) => {
    return menuCategoryIds.includes(category.categoryId);
  });

  categories.sort(sortFn);
  menu.sort(sortFn);
  categories.forEach((ele) => {
    const arr = menu.filter((item) => item.categoryId === ele.categoryId);
    temp.push(ele);
    temp.push(...arr);
  });

  return (
    <>
      <View height="100%">
        <MenuListAndFooter
          restaurantId={restaurantId}
          // menuData={menuItems}
          newMenuData={temp}
          headersData={categories}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          flatlistRef={flatlistRef}
          navigation={navigation}
          restaurantName={restaurantName}
          // restaurantposId={restaurantposId}
          restaurant={restaurant}
        />
        <View position="absolute" top={0} left={0} right={0} zIndex={10}>
          <MenuScreenHeader
            title={restaurantName}
            // menuData={headersData}
            newMenuData={temp}
            headersData={categories}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            // flatlistRef={flatlistRef}
            ref={flatlistRef}
            navigation={navigation}
          />
        </View>
      </View>
    </>
  );
};

const MenuScreen = ({ navigation, route }) => {
  const { restaurantId, itemId } = route.params;
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };
  const [netStatus, setNetStatus] = useState(true);

  useFocusEffect(
    useCallback(() => {
      firebaseEventLogger('DigitalMenuLanding', {
        restaurantId,
      });
    }, []),
  );

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <RenderMenuScreen
            refresh={refresh}
            queryOptions={refreshedQueryOptions ?? {}}
            variables={{ filter: { restaurantId } }}
            fetchKey={refreshedQueryOptions.fetchKey}
            navigation={navigation}
            itemId={itemId}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default MenuScreen;
