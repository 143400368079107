/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  variant,
  color,
  space,
  border,
  flexbox,
  typography,
} from 'styled-system';
import styled from 'styled-components/native';
import { get } from 'lodash';
import StyledText from '../Typography/Text/Text';
import Pressable from '../../basic/Pressable/Pressable';
import View from '../../basic/View/View';
import StyledIcon from '../Icon/Icon';
import { space as spaces } from '../../../../themes/new/theme';
import { Capitalise } from '../../../../utilities/helper';
import {
  Appearance,
  FontWeights,
  State,
  ThemeVariables,
} from '../../../../themes/new/helper';

const selectedChipTextColor = 'primary.10';

const getChipTextColor = (theme, textState, textColorProp) => {
  // override color in selected state
  if (textState === 'selected') {
    return get(theme.colors, selectedChipTextColor);
  }
  if (textColorProp) {
    return get(theme.colors, textColorProp);
  }
  if (textState === 'active') {
    return get(theme.colors, 'primary.100');
  }
  return get(theme.colors, 'primary.50');
};

const Icon = styled(StyledIcon).attrs(
  ({ iconSize, iconAppearance, theme, appearance, status }) => ({
    size: iconSize === 'xs' ? 'xl' : iconSize,
    color:
      iconAppearance === State.ACTIVE
        ? 'primary.400'
        : iconAppearance === State.SELECTED
        ? appearance === Appearance.FILLED
          ? status === 'success'
            ? 'primary.500'
            : 'primary.10'
          : 'primary.200'
        : 'primary.50',
  }),
)``;

const Text = styled(StyledText)`
  ${({ textState, theme, textColor, appearance }) => {
    return {
      color:
        get(theme.colors, textColor) ||
        (textState === State.ACTIVE
          ? theme.colors.primary['400']
          : textState === State.SELECTED
          ? appearance === Appearance.FILLED
            ? theme.colors.primary['10']
            : theme.colors.primary['200']
          : theme.colors.primary['50']),
    };
  }}
  ${typography}
`;

const StyledView = styled(View)`
  ${({ theme }) => ({
    paddingTop: theme.space['sm+md'],
    paddingBottom: theme.space['sm+md'],
  })}
`;

const sizeVariants = ({ theme, ...prop }) =>
  variant({
    prop: ThemeVariables.SIZE,
    variants: {
      xs: {
        borderRadius: theme.radii.full,
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors.primary[50],
      },
      md: {
        borderRadius: theme.radii.full,
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors.primary[50],
      },
    },
  });

const stateVariants = ({ theme, ...prop }) => {
  return variant({
    prop: ThemeVariables.STATE,
    variants: {
      active: {
        bg: theme.colors.primary[10],
        // color: theme.colors.primary[10],
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors.primary[50],
      },
      selected: {
        bg:
          prop?.appearance === Appearance.FILLED
            ? prop?.status === 'success'
              ? theme.colors.success[10]
              : theme.colors.primary['500']
            : theme.colors.primary[10],
        // color: theme.colors.primary[400],
        borderStyle: 'solid',
        borderWidth: theme.borderWidths.xs,
        borderColor:
          prop?.appearance === Appearance.FILLED
            ? prop?.status === 'success'
              ? theme.colors.success[500]
              : theme.colors.primary['200']
            : theme.colors.grey[300],
      },
      disabled: {
        bg: theme.colors.primary[10],
        // color: theme.colors.primary[50],
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors.primary[50],
      },
    },
  });
};

const StyledButton = styled(Pressable)`
  ${color}
  ${space}
  ${border}
  ${flexbox}
  ${(props) => sizeVariants(props)}
  ${(props) => stateVariants(props)}
`;

const Chip = ({
  children = 'Button',
  isUpperCase = true,
  size = 'xs',
  state = State.ACTIVE,
  iconLeft = '',
  iconRight = '',
  helperText = '',
  helperTextColor = 'primary.100',
  helperTextSize = 'xs',
  textColor = '',
  appearance = Appearance.OUTLINE,
  status = 'primary',
  ...rest
}: {
  children?: string;
  isUpperCase?: boolean;
  size?: string;
  state?: string;
  iconLeft?: string;
  iconRight?: string;
  helperText: string;
  helperTextColor: string;
  helperTextSize: string;
  textColor: string;
  appearance: string;
}) => (
  <>
    <StyledButton
      size={size}
      state={state}
      disabled={state === State.DISABLED}
      appearance={appearance}
      hitSlop={{
        top: spaces.sm,
        bottom: spaces.sm,
        left: spaces.sm,
        right: spaces.sm,
      }}
      status={status}
      {...rest}
    >
      <StyledView
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        px="xl"
        py="md"
      >
        {iconLeft && typeof iconLeft === 'string' && iconLeft.length !== 0 ? (
          <Icon
            iconSize="md"
            name={iconLeft}
            iconAppearance={state}
            appearance={appearance}
            status={status}
          />
        ) : (
          <></>
        )}
        <Text
          category="button"
          selectable={false}
          size={size}
          textState={state}
          weight={
            state === State.SELECTED ? FontWeights.MEDIUM : FontWeights.REGULAR
          }
          numberOfLines={1}
          ellipsizemode="tail"
          ml={iconLeft.length !== 0 ? 'sm' : 0}
          mr={iconRight.length !== 0 ? 'sm' : 0}
          textColor={
            state === State.SELECTED
              ? status === 'success'
                ? 'primary.500'
                : selectedChipTextColor
              : textColor
          }
        >
          {isUpperCase ? Capitalise(children) : children}
        </Text>
        {iconRight &&
        typeof iconRight === 'string' &&
        iconRight.length !== 0 ? (
          <Icon
            iconSize="md"
            name={iconRight}
            iconAppearance={state}
            appearance={appearance}
            status={status}
          />
        ) : (
          <></>
        )}
      </StyledView>
    </StyledButton>
    <StyledText
      numberOfLines={1}
      ellipsizemode="tail"
      textAlign="center"
      color={helperTextColor}
      size={helperTextSize}
      pt="xs"
    >
      {helperText}
    </StyledText>
  </>
);

export default Chip;
