/**
 * @generated SignedSource<<41a4aa1b6d437bc877f73bed905ad5e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type sentMessageQuery$variables = {
  id: any;
};
export type sentMessageQuery$data = {
  readonly sentloyaltyMsg: {
    readonly _id: any;
    readonly user: {
      readonly name: {
        readonly first: string;
        readonly last: string | null;
      };
    } | null;
  } | null;
};
export type sentMessageQuery = {
  response: sentMessageQuery$data;
  variables: sentMessageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      }
    ],
    "concreteType": "Loyalty",
    "kind": "LinkedField",
    "name": "sentloyaltyMsg",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserName",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sentMessageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sentMessageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2abe00cc3972267091cf55bd4de725e",
    "id": null,
    "metadata": {},
    "name": "sentMessageQuery",
    "operationKind": "query",
    "text": "query sentMessageQuery(\n  $id: MongoID!\n) {\n  sentloyaltyMsg(_id: $id) {\n    _id\n    user {\n      name {\n        first\n        last\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e4255f405fe7fd5e4da7391e810d3ad";

export default node;
