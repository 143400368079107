import React from 'react';
import { ThemeProvider } from 'styled-components/native';
import { getTheme, themeModes } from '../../../themes/new/theme';

const LightThemeWrapper = ({ children }) => {
  const newTheme = getTheme(themeModes.light);

  return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>;
};

export default LightThemeWrapper;
