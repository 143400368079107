import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const searchValetScreenQuery = graphql`
  query searchValetScreenQuery(
    $filter: searchValetFilterInput
    $count: Int
    $cursor: String
  ) {
    ...searchValetScreenQueryFragment
      @arguments(filter: $filter, count: $count, cursor: $cursor)
  }
`;

export const searchValetScreenQueryFragment = graphql`
  fragment searchValetScreenQueryFragment on Query
  @refetchable(queryName: "searchValetScreenFragmentQuery")
  @argumentDefinitions(
    filter: { type: "searchValetFilterInput" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    searchValetV2(filter: $filter, first: $count, after: $cursor)
      @connection(key: "searchValetScreenQueryFragment_searchValetV2") {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          _id
          name {
            first
            last
          }
          role {
            name
          }
          contact {
            phone {
              prefix
              number
            }
          }
          identityCode
          workLogStatus {
            status
            time
            lastStatus
            taskCount
          }
        }
      }
    }
  }
`;
