import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Divider,
  Pressable,
  Text,
  View,
} from '../../../components/new';
import {
  daysMaker,
  durationMaker,
  imageTransformation,
  modifyEventsPrice,
} from '../../../utilities/helper';
import EventsInfoTile from './EventsInfoTile';
import CachedImage from '../../../components/new/custom/CachedImage';

const EventCardSmall = ({ item, index, handleCardPress = () => {} }) => {
  const { _id, title, media, eventTiming } = item;
  const { date } = eventTiming[0] ?? {};
  const [img, setImg] = useState({});

  useEffect(() => {
    const ImgObj = media?.cover?.find((i) => i?.mediaType === 'image');
    setImg(ImgObj);
  }, []);

  return (
    <View ml={index === 0 ? 0 : '2xl'} mb="lg" width={240} minHeight={297}>
      <Pressable style={{ flex: 1 }} onPress={handleCardPress}>
        <Card height="100%" category="none">
          <CachedImage
            style={{
              width: '100%',
              height: 150,
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              resizeMode: 'cover',
            }}
            source={
              img?.mediaURL
                ? {
                    uri: imageTransformation(img?.mediaURL, 240, '240-150'),
                  }
                : require('../../../../assets/images/no-image.png')
            }
          />

          <View p="2xl">
            <Text
              size="md"
              numberOfLines={1}
              color="primary.400"
              weight="medium"
            >
              {title}
            </Text>
            <View justifyContent="space-between" flexDirection="row" pt="xl">
              {!eventTiming?.length ? (
                <View
                  alignItems="flex-start"
                  mb="2xl"
                  justifyContent="center"
                  flex={1}
                >
                  <View flexDirection="row" alignItems="center">
                    <Text size="sm" color="primary.400">
                      Coming soon
                    </Text>
                  </View>
                </View>
              ) : (
                <>
                  <EventsInfoTile
                    text={
                      date
                        ? daysMaker(eventTiming)?.length > 9
                          ? `${daysMaker(eventTiming).substring(0, 8)}...`
                          : daysMaker(eventTiming)
                        : 'N/A'
                    }
                    icon="calendar-outline-300"
                  />
                  <EventsInfoTile
                    text={date ? durationMaker(eventTiming) : 'Flexible'}
                    icon="clock-outline"
                  />
                </>
              )}
            </View>
            <View>
              <View mb="lg">
                <Divider />
              </View>
              <View flexDirection="row" justifyContent="space-between">
                <View justifyContent="center">
                  {Boolean(modifyEventsPrice(eventTiming)) && (
                    <View justifyContent="center">
                      <Text size="xs" color="primary.400">{`₹${Math.ceil(
                        modifyEventsPrice(eventTiming),
                      )} onwards`}</Text>
                    </View>
                  )}
                </View>
                <View justifyContent="center">
                  <Button
                    appearance="ghost"
                    status="info"
                    iconRight=""
                    onPress={handleCardPress}
                    size="sm"
                  >
                    View Details
                  </Button>
                </View>
              </View>
            </View>
          </View>
        </Card>
      </Pressable>
    </View>
  );
};

export default EventCardSmall;
