import React, { memo } from 'react';
import { Text, View } from '../../../../components/new';

const areEqual = () => {
  return true;
};

const InvestRecords = ({ bgColor = '' }) => {
  return (
    <View
      py="9xl"
      px="2xl"
      bg={bgColor}
      flexDirection="row"
      justifyContent="space-between"
    >
      <View alignItems="center" justifycontent="center" flex={1}>
        <Text color="primary.500" size="2xl">
          1100<Text size="md">+</Text>
        </Text>
        <Text color="primary.300" size="2xs">
          Satisfied Investors
        </Text>
      </View>
      <View
        flex={1}
        alignItems="center"
        justifycontent="center"
        borderLeftWidth={1}
        borderRightWidth={1}
        borderColor="grey.50"
        px="xl"
      >
        <Text color="primary.500" size="2xl">
          2.4 <Text size="md">million</Text>
        </Text>
        <Text color="primary.300" size="2xs">
          Sq. Ft. area delivered
        </Text>
      </View>
      <View alignItems="center" justifycontent="center" flex={1}>
        <Text color="primary.500" size="2xl">
          87<Text size="md">%</Text>
        </Text>
        <Text color="primary.300" size="2xs">
          RoI in 4 years
        </Text>
      </View>
    </View>
  );
};

export default memo(InvestRecords, areEqual);
