/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, Icon, Text, View } from '../../../components/new';
import { useAuditorOverviewScreen } from './useAuditorOverview';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import OverviewBanner from '../../../components/new/custom/OverviewBanner';
import { numberWithCommas } from '../../../utilities/helper';
import OverviewCollectionList from './OverviewCollectionList';

const AuditorOverviewScreenInner = ({
  queryOptions,
}: {
  queryOptions: NewErrorBoundaryParentState;
}) => {
  const { today, bannerData, collectionList } =
    useAuditorOverviewScreen(queryOptions);
  const navigation = useNavigation<any>();
  const { amount } = bannerData;

  return (
    <View flex={1}>
      <OverviewBanner>
        <View zIndex={2} mt="4xl">
          <View alignItems="center" justifyContent="center" my="4xl">
            <Text size="3xl" weight="medium" color="success.500">
              ₹{numberWithCommas(amount)}
            </Text>

            <Button
              mt="1xl"
              mb="md+sm"
              appearance="ghost"
              size="sm"
              onPress={() => {
                navigation.navigate('AuditorDayWiseCollectionScreen', {
                  date: today,
                });
              }}
            >
              <View
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Text size="sm" mr="sm" weight="regular" color="primary.10">
                  Today&#39;s Collections
                </Text>
                <Icon
                  size="md"
                  name="keyboard-arrow-right-300"
                  color="primary.10"
                />
              </View>
            </Button>
          </View>
        </View>
      </OverviewBanner>

      <>
        <OverviewCollectionList collectionList={collectionList} />
      </>
    </View>
  );
};

export default AuditorOverviewScreenInner;
