import React, { useEffect } from 'react';
import { View, Text } from '../../components/new';
import useUserStore from '../../stores/userStore';
import { PaymentStatusEnum } from '../../utilities/helper';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

const CashierPaymentCollectionScreen = ({ navigation, route }) => {
  const { text1, status } = route?.params ?? {};
  const { role } = useUserStore((state) => state);

  const navigateToHome = () => {
    navigation.popToTop();
  };

  useEffect(() => {
    setTimeout(() => {
      navigateToHome();
    }, 2200);
  }, []);

  return (
    <View flex={1} bg="primary.10" justifyContent="center" alignItems="center">
      <CustomLottieView
        loop
        autoPlay
        source={
          status && status === PaymentStatusEnum.FAILED
            ? require('../../../assets/lottie/failed_animation.json')
            : require('../../../assets/lottie/tick.json')
        }
        style={{
          width: 200,
          height: 200,
        }}
        containerWidth={200}
      />
      <View>
        <Text mt="4xl" size="2xl" weight="medium" color="primary.500">
          {text1}
        </Text>
      </View>
    </View>
  );
};
export default CashierPaymentCollectionScreen;
