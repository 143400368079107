import React from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import LightThemeWrapper from '../../../../screens/new/wrapperComp/LightThemeWrapper';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import IconButton from '../../primitive/IconButton/IconButton';
import TopNavigation from '../../composites/TopNavigation/TopNavigation';
import { getTheme } from '../../../../themes/new/theme';
import { PaymentStatusEnum } from '../../../../utilities/helper';

const bannerBgSrc = 'https://ik.imagekit.io/32ndhub/assets/bg_ontime_blur.png';
const theme = getTheme();
const { radii } = theme;

type StatusType = keyof typeof PaymentStatusEnum;

interface TransactionSummaryBannerProps {
  title?: string;
  amount: string;
  status: StatusType;
  onBack?: () => void;
  onInfo?: () => void;
  BottomContent?: React.ReactElement;
}

const TransactionSummaryBanner = ({
  title,
  amount,
  status,
  onBack,
  onInfo,
  BottomContent,
}: TransactionSummaryBannerProps) => {
  return (
    <LightThemeWrapper>
      <View
        bg="primary.500"
        borderBottomLeftRadius={radii.lg + radii.sm}
        borderBottomRightRadius={radii.lg + radii.sm}
      >
        <ImageBackground source={{ uri: bannerBgSrc }} style={styles.bannerImg}>
          <View pb="4xl" zIndex={2}>
            <TopNavigation
              title={title}
              textSize="md"
              appearance="ghost"
              level="none"
              textColor="primary.10"
              IconLeft={
                typeof onBack === 'function' && (
                  <IconButton
                    name="back-outline-300"
                    size="md"
                    appearance="ghost"
                    iconColor="primary.10"
                    onPress={onBack}
                  />
                )
              }
              IconRight={
                typeof onInfo === 'function' ? (
                  <IconButton
                    name="info-outline-300"
                    size="md"
                    appearance="ghost"
                    iconColor="primary.10"
                    onPress={() => onInfo()}
                  />
                ) : (
                  ''
                )
              }
            />
            <View alignItems="center" justifyContent="center">
              <Text size="3xl" weight="medium" color={resolveColor(status)}>
                {amount}
              </Text>
              {BottomContent ?? <></>}
            </View>
          </View>
        </ImageBackground>
      </View>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style="light" />
    </LightThemeWrapper>
  );
};

const styles = StyleSheet.create({
  bannerImg: {
    overflow: 'hidden',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});

const resolveColor = (status: StatusType) => {
  switch (status) {
    case PaymentStatusEnum.SUCCESSFUL:
      return 'success.500';
    case PaymentStatusEnum.FAILED:
      return 'error.500';
    case PaymentStatusEnum.PENDING:
      return 'warning.500';
    case PaymentStatusEnum.REFUNDED:
      return 'grey.400';
    default:
      return 'grey.400';
  }
};

export default TransactionSummaryBanner;
