import React from 'react';
import { FlatList } from 'react-native';
import { View } from '../../../components/new';
import TransactionListItem from '../../../components/new/custom/transaction/TransactionListItem';
import { getTheme } from '../../../themes/new/theme';
import { PaymentStatusEnum } from '../../../utilities/helper';

type PaymentStatusType = keyof typeof PaymentStatusEnum;

type Data = {
  orderId: string;
  paymentStatus: PaymentStatusType;
  amount: string;
  date: string;
};

interface TransactionListProps {
  onScrollEnd?: () => void;
  onItemPress?: (transaction: Data) => void;
  NoContentView?: React.FC;
  RefreshControl?: JSX.Element;
  data: Data[];
}

const spacing = getTheme().space;

const TransactionList = ({
  data,
  onScrollEnd,
  NoContentView,
  RefreshControl,
  onItemPress,
}: TransactionListProps) => {
  return (
    <View flex={1}>
      <FlatList
        onEndReached={() => {
          if (onScrollEnd) onScrollEnd();
        }}
        onEndReachedThreshold={0.2}
        contentContainerStyle={{
          paddingTop: spacing['2xl'],
          paddingHorizontal: spacing['2xl'],
        }}
        refreshControl={RefreshControl}
        data={data}
        keyExtractor={(item) => item.orderId}
        renderItem={({ item }) => {
          return (
            <View pb="2xl">
              <TransactionListItem
                orderId={item.orderId}
                paymentStatus={item.paymentStatus}
                amount={item.amount}
                time={item.date}
                onPress={() => {
                  if (onItemPress) onItemPress(item);
                }}
              />
            </View>
          );
        }}
        showsVerticalScrollIndicator
        ListEmptyComponent={NoContentView ?? <></>}
      />
    </View>
  );
};

export default TransactionList;
