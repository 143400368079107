/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Button, Text, View } from '../../components/new';
import photoMoments from '../../screens/Strings';
import { space } from '../../themes/new/theme';

const VMLanding = ({ checkPermissionFunction, openLibrary }) => {
  const { width } = useWindowDimensions();

  return (
    <View flex={1} justifyContent="center" alignItems="center">
      <View px="2xl" mt="9xl">
        <Text size="3xl" color="primary.10" textAlign="center" weight="medium">
          {photoMoments.captureMoment}
        </Text>
      </View>
      <View px="2xl" mt="2xl" width={width}>
        <Button
          size="lg"
          appearance="outline"
          status="primary"
          state="active"
          style={{ borderColor: 'transparent' }}
          onPress={() => checkPermissionFunction(false)}
        >
          {photoMoments.makeVideo}
        </Button>
        <Button
          size="lg"
          appearance="filled"
          status="primary"
          state="active"
          mt="2xl"
          onPress={openLibrary}
        >
          {photoMoments.videoLib}
        </Button>
      </View>
      <View height={space['9xl+9xl']} />
    </View>
  );
};
export default VMLanding;
