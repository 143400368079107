/* eslint-disable import/prefer-default-export */
import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

const defaultQuery = graphql`
  query findTaskQuery($filter: findTaskFilterInput!) {
    findTasks(filter: $filter) {
      _id
      vehicle {
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
      }
      user {
        _id
      }
    }
  }
`;

export const findTaskQuery = (variables, query = defaultQuery) => {
  return fetchQuery(environment, query, variables).toPromise();
};
