import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Button, Text, View } from '../../../components/new';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { FontWeights, State, Status } from '../../../themes/new/helper';

const ScanStatusIdle = ({ onPressContinue }) => {
  const { width, height } = useWindowDimensions();

  return (
    <View
      position="absolute"
      bg="opacity.80"
      top={0}
      bottom={0}
      left={0}
      right={0}
      width={width}
      height={height}
      alignItems="center"
      justifyContent="center"
      px="4xl"
    >
      <CustomLottieView
        autoPlay
        loop
        source={require('../../../../assets/lottie/scanning-image.json')}
        style={{ width: 200, height: 200 }}
        containerWidth={200}
      />
      <Text
        size="xl"
        weight={FontWeights.MEDIUM}
        color="primary.500"
        mt="sm"
        textAlign="center"
      >
        Please capture a clear photo of your bill, ensuring the entire bill is
        visible in the picture.
      </Text>
      <View width="100%" mt="4xl">
        <Button
          size="md"
          status={Status.PRIMARY}
          state={State.ACTIVE}
          onPress={onPressContinue}
        >
          Continue
        </Button>
      </View>
    </View>
  );
};

export default ScanStatusIdle;
