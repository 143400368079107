/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';
import { commitMutationPromise } from '../../../../utilities/helper';

export const recallQuery = (data) => {
  const mutation = graphql`
    mutation recallQuery($taskId: ID!, $taskEndLocationId: ID!) {
      addRecallTask(taskId: $taskId, taskEndLocationId: $taskEndLocationId) {
        _id
        isVehicleWhitelisted
        taskType
        vehicle {
          brand {
            name
            brandLogo
          }
          color
          registration {
            plate
          }
        }
        taskStartLocation {
          locationId
          name
          parkingSpaces {
            floor {
              floorName
              floorId
              bay {
                bayId
                bayName
              }
            }
          }
        }
        taskEndLocation {
          locationId
          name
          parkingSpaces {
            floor {
              floorName
              floorId
              bay {
                bayId
                bayName
              }
            }
          }
        }
        parkingActivity {
          _id
        }
        campus {
          _id
          name
        }
        history {
          _id
          event_at
          actor
          action
        }
        assignedTo
        vehicleId
        ownerId
        parkingId
        valet {
          name {
            first
            last
          }
          contact {
            phone {
              prefix
              number
            }
          }
        }
        user {
          name {
            first
            last
          }
          contact {
            phone {
              prefix
              number
            }
          }
        }
        parking {
          parkingInvoice {
            paymentDetails {
              isPaid
              amount
            }
            charges {
              chargesBreakup {
                duration
                payable
              }
              discountAmount
              totalPayable
              valetTip
            }
            invoicePdfPath
          }
        }
      }
    }
  `;
  const variables = {
    taskId: data?.taskId,
    taskEndLocationId: data?.taskEndLocationId,
  };

  return commitMutationPromise(mutation, variables);
};
