/* eslint-disable no-extra-boolean-cast */
import React, {
  useState,
  useRef,
  useReducer,
  useMemo,
  useCallback,
  memo,
  useLayoutEffect,
} from 'react';
import {
  StyleSheet,
  useWindowDimensions,
  RefreshControl,
  FlatList,
} from 'react-native';
import _, { keyBy, orderBy } from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { ActionSheetRef } from 'react-native-actions-sheet';
import PhotoContainer from './PhotoContainer';
import { Button, Divider, Text, View } from '../../components/new';
import FooterBar from './FooterBar';
import photoMoments from '../../screens/Strings';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import deleteImageApi from '../../relay/deleteImageForPhotoMomentsApi';
import { DateFormatsEnum, getFormatedDate } from '../../utilities/helper';
import useVideoMomentsStore from '../../stores/VideoMomentsStore';
import { groupPhotosOnDate } from '../../utilities/Utility';
import BottomSheetV2 from '../../components/new/composites/BottomSheet/BottomSheetV2';

let multiSelected = [];
let savedFavorites = [];
let deletedPictures = [];
let imageLoading = false;

const areEqual = (prevProps, nextProps) => {
  if (
    prevProps?.favoriteList?.length !== nextProps?.favoriteList?.length ||
    prevProps?.deletedMoments?.length !== nextProps?.deletedMoments?.length ||
    prevProps?.photoGallery?.length !== nextProps?.photoGallery?.length
  )
    return false;
  return true;
};

const PhotoLibrary = ({
  photoGallery = [],
  favoriteList = [],
  deletedMoments = [],
  noPhotosFound,
  navigateImageViewer,
  onRefresh,
  refreshing,
  checkDelete = () => {},
}) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const bottomSheetLibraryRef = useRef<ActionSheetRef>(null);
  const { width } = useWindowDimensions();
  const [photoArray, setPhotoArray] = useState([]);
  const { setPhotoMomentsForWidget } = useVideoMomentsStore((state) => state);
  const [galleryLength, setGalleryLength] = useState(photoGallery?.length);

  const gallerySorted = useMemo(() => {
    return orderBy(photoGallery, ['clickedOn'], ['desc']);
  }, [photoGallery?.length]);

  const navigation = useNavigation();

  useLayoutEffect(() => {
    modifyPhotoArray({
      getMatchingPhotosForPhotoMoments: gallerySorted,
      showLoading: true,
    });
    return () => {
      multiSelected = [];
      deletedPictures = [];
      savedFavorites = [];
      imageLoading = false;
    };
  }, [galleryLength, favoriteList, deletedMoments]);

  const modifyPhotoArray = ({
    getMatchingPhotosForPhotoMoments,
    showLoading,
  }) => {
    if (showLoading) {
      imageLoading = true;
    }
    const photosMainObj = {};
    const favoriteListing = ['Favorites', { list: [] }];
    if (deletedMoments?.length) {
      deletedPictures = deletedMoments;
    }
    if (favoriteList?.length) {
      savedFavorites = favoriteList;
    }
    const matchedPhotosMap = keyBy(getMatchingPhotosForPhotoMoments, 'assetId');
    const deletedPhotosMap = deletedPictures.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

    if (savedFavorites?.length) {
      savedFavorites?.forEach((id) => {
        if (matchedPhotosMap[id]) {
          favoriteListing?.[1]?.list.push(matchedPhotosMap[id]);
        }
      });
    }
    getMatchingPhotosForPhotoMoments.forEach((item) => {
      if (deletedPhotosMap[item?.assetId]) {
        return;
      }
      const eachItem = { ...item };
      const selecteddate = getFormatedDate(
        new Date(eachItem?.clickedOn),
        DateFormatsEnum.DateMonthYear,
      );
      const j = selecteddate;
      if (!photosMainObj[j]) {
        photosMainObj[j] = { list: [eachItem] };
      } else {
        photosMainObj[j].list.push(eachItem);
      }
    });
    const resData = _.isEmpty(photosMainObj)
      ? []
      : Object.entries(photosMainObj);
    const finalData = favoriteListing?.[1]?.list?.length
      ? [[...favoriteListing], ...resData]
      : resData;

    // update photo moment widget date
    deletedMoments?.forEach((assetId) => {
      if (matchedPhotosMap?.[assetId]) {
        delete matchedPhotosMap[assetId];
      }
    });
    const filteredPhotoMoments = Object.values(matchedPhotosMap);
    const { groupPhotoMomentsOnDate, photoMomentsWidgetHeader }: any =
      groupPhotosOnDate(filteredPhotoMoments);
    const totalPhotoMoments = filteredPhotoMoments?.length;
    setPhotoMomentsForWidget(
      groupPhotoMomentsOnDate,
      photoMomentsWidgetHeader,
      totalPhotoMoments,
    );

    if (finalData?.length) {
      imageLoading = false;
      setPhotoArray(finalData);
      return;
    }
    imageLoading = false;
    noPhotosFound(true);
  };

  const checkExist = (val) => {
    const res = multiSelected.find((item) => item?.assetId === val?.assetId);
    return !!res;
  };

  const processSelected = useCallback((picture) => {
    const eachPicture = { ...picture };
    if (checkExist(eachPicture)) {
      const res = multiSelected.filter(
        (item) => item?.assetId !== eachPicture?.assetId,
      );
      multiSelected = res;
    } else {
      multiSelected.push(eachPicture);
    }
    forceUpdate();
  }, []);

  const processImagePress = ({ picture, list = null }) => {
    if (multiSelected?.length > 0) {
      processSelected(picture);
    } else {
      navigateImageViewer({
        data: picture,
        photoGallery: list ?? gallerySorted,
      });
    }
  };

  const checkSelected = (item) => {
    const selected = multiSelected.find(
      (listItem) => listItem?.assetId === item?.assetId,
    );
    return !!selected;
  };

  const checkListItem = (val) => {
    const collectionlist = val?.[1]?.list ?? [];
    const newList = [];
    collectionlist.forEach((item) => {
      const eachItem = { ...item };
      if (multiSelected?.length) {
        if (checkSelected(eachItem)) {
          eachItem.isSelected = true;
        } else {
          eachItem.isSelected = false;
        }
      } else {
        eachItem.isSelected = false;
      }
      newList.push(eachItem);
    });
    return newList;
  };

  const onRemoveSelected = useCallback(() => {
    multiSelected = [];
    forceUpdate();
  }, []);

  const renderItem = ({ item, index }) => {
    const modifyListItem = checkListItem(item);
    const collectionName = item?.[0];
    return (
      <PhotoContainer
        key={`PhotoContainer_${item?.[0]}`}
        data={modifyListItem}
        collectionName={collectionName}
        firstItem={index === 0}
        isMultiSelected={!!multiSelected?.length}
        onCancel={onRemoveSelected}
        processLongPress={processSelected}
        processPress={processImagePress}
      />
    );
  };

  const deleteItem = () => {
    bottomSheetLibraryRef.current?.show();
  };

  const deletePicture = () => {
    firebaseEventLogger('photoMoments__deleteMultiple_Tap', {
      buttonName: 'deleteMultiple',
      screenName: 'photoMoments',
      userType: 'user',
      interactionType: 'tap',
      multiple: true,
    });
    const deletedListRes = [];
    multiSelected.map((item) => deletedListRes.push(item?.assetId));
    const data = {
      imageId: deletedListRes,
    };
    deleteImageApi(data)
      .then((res) => handleSuccess(res))
      .catch((err) => {
        onError();
      });
  };

  const handleSuccess = (response) => {
    if (response?.deleteImageForUserPhotoMoments?.success) {
      handleOnDismiss();
      modifyPhotoArray({
        getMatchingPhotosForPhotoMoments: gallerySorted,
        showLoading: true,
      });
      dispatchSnackbar({
        msg: `${multiSelected?.length > 1 ? 'Photos' : 'Photo'}${
          photoMoments.photoDeleted
        }`,
        status: SnackbarStatus.success,
        version: SnackbarVersion.v1,
      });
      multiSelected = [];
      checkDelete();
    } else {
      onError();
    }
  };

  const onError = () => {
    dispatchSnackbar({
      msg: 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v1,
    });
  };

  const handleOnDismiss = useCallback(() => {
    bottomSheetLibraryRef.current?.hide();
  }, []);

  const handlePhotoBannerClick = useCallback(() => {
    navigation.navigate('instagramShare');
  }, []);

  return (
    <View flex={1}>
      {imageLoading ? null : (
        <FlatList
          data={photoArray}
          contentContainerStyle={styles.container}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          renderItem={renderItem}
        />
      )}
      {multiSelected?.length ? (
        <FooterBar
          multiSelected={multiSelected}
          isLibrary
          onDelete={deleteItem}
          downloadSuccess={onRemoveSelected}
        />
      ) : null}
      <BottomSheetV2
        ref={bottomSheetLibraryRef}
        onClose={handleOnDismiss}
        insets={false}
      >
        <View justifyContent="center" px="2xl" pt="2xl">
          <Text size="xl" color="primary.200">
            {multiSelected?.length > 1
              ? photoMoments.deleteItemMultiple
              : photoMoments.deleteItem}
          </Text>
          <View mt="xl">
            <Divider level={1} />
          </View>
          <Text size="s" color="primary.200" mt="3xl">
            {multiSelected?.length > 1
              ? photoMoments.deleteStatementMultiple
              : photoMoments.deleteStatement}
          </Text>
          <View px="2xl" my="4xl">
            <Divider level={1} />
          </View>
          <View flexDirection="row" alignItems="center">
            <Button
              style={{
                width: (width - 3 * 16) / 2,
                marginRight: 16,
              }}
              state="active"
              status="error"
              appearance="filled"
              size="lg"
              onPress={() => {
                deletePicture();
              }}
            >
              Confirm
            </Button>
            <Button
              style={{ width: (width - 3 * 16) / 2 }}
              state="active"
              status="primary"
              appearance="filled"
              size="lg"
              onPress={() => {
                handleOnDismiss();
              }}
            >
              Cancel
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default memo(PhotoLibrary, areEqual);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
});
