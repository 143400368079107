/* eslint-disable no-unsafe-optional-chaining */
import React, { Suspense, useState } from 'react';
import { Linking, Platform, ScrollView } from 'react-native';
import {
  Divider,
  IconButton,
  Layout,
  TopNavigation,
  View,
} from '../../components/new';
import Loading from '../../components/loading.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { navigateBack } from '../../utilities/helper';
import useUserStore from '../../stores/userStore';
import CustomPressable from '../../components/new/primitive/CustomPressable/CustomPressable';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';

const About = ({ navigation, route }) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);
  const userRole = useUserStore((state) => state.role);
  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };
  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const redirectWebPage = (type) => {
    const url =
      type === 1
        ? 'https://club.32nd.com/t&c'
        : 'https://club.32nd.com/privacy-policy';
    if (Platform.OS === 'web') {
      Linking.openURL(url);
    } else {
      navigation?.navigate('CustomWebview', {
        title: type === 1 ? 'Terms and Conditions' : 'Privacy policy',
        url,
      });
    }
  };

  const onPressContactUs = () => {
    Linking.openURL('https://32nd.com/contact');
  };

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
        title="About"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <ScrollView
            contentContainerStyle={{ paddingHorizontal: 16, marginTop: 24 }}
          >
            <View>
              <CustomPressable
                appearance="ghost"
                size="md"
                textSize="md"
                textColor="primary.500"
                iconSize="md"
                shadow="md"
                state="active"
                status="primary"
                rounded="lg"
                // leftStyle={{ width: 28, height: 28 }}
                iconColor="primary.400"
                leftContent={null}
                rightContent="forward-outline-300"
                // mb="2xl"
                onPress={() => {
                  firebaseEventLogger('about__termsAndConditions_Tap', {
                    buttonName: 'termsAndConditions',
                    screenName: 'about',
                    userType: userRole,
                    interactionType: InteractionType.TAP,
                  });
                  redirectWebPage(1);
                }}
              >
                Terms and Conditions
              </CustomPressable>
              <Divider />
            </View>
            <View>
              <CustomPressable
                appearance="ghost"
                size="md"
                textSize="md"
                textColor="primary.500"
                iconSize="md"
                shadow="md"
                state="active"
                status="primary"
                rounded="lg"
                // leftStyle={{ width: 28, height: 28 }}
                iconColor="primary.400"
                leftContent={null}
                rightContent="forward-outline-300"
                // mb="2xl"
                onPress={() => {
                  firebaseEventLogger('about__privacyPolicy_Tap', {
                    buttonName: 'privacyPolicy',
                    screenName: 'about',
                    userType: userRole,
                    interactionType: InteractionType.TAP,
                  });
                  redirectWebPage(2);
                }}
              >
                Privacy Policy
              </CustomPressable>
              <Divider />
            </View>
            <View>
              <CustomPressable
                appearance="ghost"
                size="md"
                textSize="md"
                textColor="primary.500"
                iconSize="md"
                shadow="md"
                state="active"
                status="primary"
                rounded="lg"
                // leftStyle={{ width: 28, height: 28 }}
                iconColor="primary.400"
                leftContent={null}
                rightContent="forward-outline-300"
                // mb="2xl"
                onPress={() => {
                  firebaseEventLogger('about__contactUs_Tap', {
                    buttonName: 'contactUs',
                    screenName: 'about',
                    userType: userRole,
                    interactionType: InteractionType.TAP,
                  });
                  onPressContactUs();
                }}
              >
                Contact Us
              </CustomPressable>
              <Divider />
            </View>
          </ScrollView>
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};
export default About;
