/**
 * @generated SignedSource<<65d14cc49f0bef3cc99c2b19232b9e2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumParkingParkingInvoicePaymentDetailsFocReason = "CUSTOMER_ISSUE" | "EMPLOYEE_EXIT" | "FREE_PARKING_TICKET" | "MISSED_EXIT" | "OTHER" | "VALET_EXIT" | "VIP_EXIT" | "%future added value";
export type EnumParkingParkingInvoicePaymentDetailsMode = "APP" | "CASH" | "FASTAG" | "FOC" | "ONLINE" | "%future added value";
export type EnumParkingStatus = "FAULTY_PARK" | "FAULTY_RECALL" | "INCOMING" | "MISSED_EXIT" | "OUT" | "OUTGOING" | "PARKED" | "%future added value";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type cashierCarSearchScreenTasksFragment$data = ReadonlyArray<{
  readonly ETA: {
    readonly _id: any | null;
    readonly available: number | null;
    readonly busy: number | null;
    readonly delayAt: any | null;
    readonly originalPredictionTime: number | null;
    readonly pendingRecall: number | null;
    readonly startAt: any | null;
  } | null;
  readonly _id: any;
  readonly campus: {
    readonly _id: any;
  } | null;
  readonly history: ReadonlyArray<{
    readonly _id: any | null;
    readonly action: EnumtaskHistoryAction;
    readonly actor: any | null;
    readonly event_at: any | null;
  } | null>;
  readonly isVehicleWhitelisted: boolean | null;
  readonly parking: {
    readonly parkingInvoice: {
      readonly charges: {
        readonly chargesBreakup: ReadonlyArray<{
          readonly duration: number | null;
          readonly payable: number | null;
        } | null> | null;
        readonly discountAmount: number | null;
        readonly totalPayable: number | null;
      } | null;
      readonly paymentDetails: {
        readonly amount: number | null;
        readonly focReason: EnumParkingParkingInvoicePaymentDetailsFocReason | null;
        readonly isPaid: boolean;
        readonly mode: EnumParkingParkingInvoicePaymentDetailsMode | null;
      } | null;
      readonly timings: {
        readonly chargeTime: number | null;
        readonly entryTime: any;
        readonly exitTime: any | null;
      } | null;
    } | null;
    readonly status: EnumParkingStatus;
  } | null;
  readonly parkingId: any | null;
  readonly taskType: EnumtaskTaskType;
  readonly vehicle: {
    readonly registration: {
      readonly plate: string;
    } | null;
  } | null;
  readonly " $fragmentType": "cashierCarSearchScreenTasksFragment";
}>;
export type cashierCarSearchScreenTasksFragment$key = ReadonlyArray<{
  readonly " $data"?: cashierCarSearchScreenTasksFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cashierCarSearchScreenTasksFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "cashierCarSearchScreenTasksFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "taskVehicle",
      "kind": "LinkedField",
      "name": "vehicle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "taskVehicleRegistration",
          "kind": "LinkedField",
          "name": "registration",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "campusLocation",
      "kind": "LinkedField",
      "name": "campus",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "taskHistory",
      "kind": "LinkedField",
      "name": "history",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "event_at",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVehicleWhitelisted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "taskETA",
      "kind": "LinkedField",
      "name": "ETA",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "delayAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalPredictionTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pendingRecall",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "available",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "busy",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Parking",
      "kind": "LinkedField",
      "name": "parking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ParkingParkingInvoice",
          "kind": "LinkedField",
          "name": "parkingInvoice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ParkingParkingInvoiceTimings",
              "kind": "LinkedField",
              "name": "timings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entryTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "exitTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "chargeTime",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ParkingParkingInvoicePaymentDetails",
              "kind": "LinkedField",
              "name": "paymentDetails",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isPaid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "focReason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ParkingParkingInvoiceCharges",
              "kind": "LinkedField",
              "name": "charges",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                  "kind": "LinkedField",
                  "name": "chargesBreakup",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "duration",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "payable",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discountAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalPayable",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parkingId",
      "storageKey": null
    }
  ],
  "type": "task",
  "abstractKey": null
};
})();

(node as any).hash = "08272246f70a4a100dade77ede6f0458";

export default node;
