/**
 * @generated SignedSource<<c8a140ef64909b9201624673b4eae328>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnumParkingParkingInvoicePaymentDetailsMode = "APP" | "CASH" | "FASTAG" | "FOC" | "ONLINE" | "%future added value";
export type EnumParkingStatus = "FAULTY_PARK" | "FAULTY_RECALL" | "INCOMING" | "MISSED_EXIT" | "OUT" | "OUTGOING" | "PARKED" | "%future added value";
export type EnumRewardRewardType = "Event" | "FreeBubbleTea" | "FreeFridgeMagnet" | "FreeKeyChain" | "FreeParking" | "FreePostCard" | "FreeSticker" | "%future added value";
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumfeedbackFeedbacksRatingType = "EMOJI" | "NO_RATING" | "STARS" | "%future added value";
export type EnumfeedbackFeedbacksTags = "FOOD_QUANTITY" | "FOOD_SERVICE_DELAYED" | "FOOD_SERVICE_REQUESTED_TIP" | "FOOD_SERVICE_UNPROFESSIONAL" | "FOOD_STALE" | "FOOD_TASTE_ISSUES" | "PAYMENT_SERVICE_MODE_UNAVALIABLE" | "PAYMENT_SERVICE_TRANSACTION_FAILED" | "VALET_IMPOLITE" | "VALET_SERVICE_CAR_DAMAGED" | "VALET_SERVICE_DELAYED" | "VALET_SERVICE_INAPPRORIATE_PRICE" | "VALET_SERVICE_MISSING_ITEM" | "VALET_UNFAIR_TIP" | "VALET_UNHYGENIC" | "VALET_UNRESPONSIVE" | "%future added value";
export type EnumfeedbackFeedbacksTopic = "FOOD" | "FOOD_SERVICE" | "OTHER" | "OVERALL" | "PAYMENT_SERVICE" | "VALET_DRIVER" | "VALET_SERVICE" | "%future added value";
export type EnumfeedbackType = "DINE_OUT" | "EVENT" | "GENERAL" | "VALET" | "%future added value";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type EnumwallettransactionSourceType = "ADDONS" | "CAMPUS_VISIT" | "COUPON_POINTS" | "EVENT" | "EVENT_PROMOTION" | "FEEDBACK_GIVEN" | "FIRST_LOGIN" | "FIRST_TXN" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "OTHER" | "PARKING" | "PARK_DELAY" | "POINTS_EXPIRY" | "POPUP_ORDER" | "POPUP_STORE" | "REFUND" | "RESTAURANT" | "SLOT_SPIN" | "STORE" | "SUBSEQUENT_TXN" | "%future added value";
export type EnumwallettransactionType = "CREDIT" | "DEBIT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type trackMyVehicleScreenQueryParkingFragment$data = {
  readonly findParkingV2: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly campus: ReadonlyArray<{
          readonly _id: any;
          readonly contact: {
            readonly phone: {
              readonly number: string | null;
              readonly prefix: string | null;
            } | null;
          } | null;
        } | null> | null;
        readonly feedback: {
          readonly _id: any;
          readonly feedbacks: ReadonlyArray<{
            readonly rating: number | null;
            readonly ratingType: EnumfeedbackFeedbacksRatingType | null;
            readonly summary: string | null;
            readonly tags: ReadonlyArray<EnumfeedbackFeedbacksTags | null> | null;
            readonly topic: EnumfeedbackFeedbacksTopic;
          } | null> | null;
          readonly type: EnumfeedbackType;
        } | null;
        readonly invoicesData: ReadonlyArray<{
          readonly priceDetails: {
            readonly discountGiven: number;
            readonly netAmount: number;
            readonly rewardsUsed: number;
          };
        } | null> | null;
        readonly journey: ReadonlyArray<{
          readonly taskID: any;
        } | null> | null;
        readonly parked_at: any | null;
        readonly parkingInvoice: {
          readonly charges: {
            readonly chargesBreakup: ReadonlyArray<{
              readonly duration: number | null;
              readonly payable: number | null;
            } | null> | null;
            readonly discountAmount: number | null;
            readonly totalPayable: number | null;
            readonly valetTip: number | null;
          } | null;
          readonly invoicePdfPath: string | null;
          readonly paymentDetails: {
            readonly amount: number | null;
            readonly isPaid: boolean;
            readonly mode: EnumParkingParkingInvoicePaymentDetailsMode | null;
          } | null;
        } | null;
        readonly parkingRewardsGiven: number | null;
        readonly parkingTag: string | null;
        readonly pointsData: ReadonlyArray<{
          readonly amount: number;
          readonly source: {
            readonly type: EnumwallettransactionSourceType;
          };
        } | null> | null;
        readonly rewardData: ReadonlyArray<{
          readonly _id: any;
          readonly created_at: any | null;
          readonly deleted: boolean | null;
          readonly deletedAt: any | null;
          readonly expireAt: any;
          readonly isExpired: boolean | null;
          readonly isUsed: boolean | null;
          readonly parkingId: any | null;
          readonly rewardOrigin: string | null;
          readonly rewardType: EnumRewardRewardType;
          readonly rewardUniqueId: string | null;
          readonly uniqueHash: string | null;
          readonly updated_at: any | null;
        } | null> | null;
        readonly status: EnumParkingStatus;
        readonly tasks: ReadonlyArray<{
          readonly ETA: {
            readonly acceptedToArrivedCustomerTime: any | null;
            readonly acceptedToArrivedTime: any | null;
            readonly createToAcceptedTime: any | null;
            readonly createToArrivedTime: any | null;
            readonly delayAt: any | null;
          } | null;
          readonly _id: any;
          readonly campus: {
            readonly _id: any;
            readonly name: string;
          } | null;
          readonly createdByUser: {
            readonly name: {
              readonly _id: any | null;
              readonly first: string;
            };
            readonly role: {
              readonly name: EnumUserRoleName | null;
            } | null;
          } | null;
          readonly history: ReadonlyArray<{
            readonly action: EnumtaskHistoryAction;
            readonly event_at: any | null;
          } | null>;
          readonly isVehicleWhitelisted: boolean | null;
          readonly ownerId: any | null;
          readonly parkingId: any | null;
          readonly parkingTag: string | null;
          readonly points: ReadonlyArray<{
            readonly _id: any;
            readonly amount: number;
            readonly source: {
              readonly type: EnumwallettransactionSourceType;
            };
            readonly type: EnumwallettransactionType;
          } | null> | null;
          readonly taskEndLocation: {
            readonly locationId: any;
            readonly name: string | null;
            readonly parkingSpaces: {
              readonly floor: {
                readonly bay: {
                  readonly bayId: any;
                  readonly bayName: string | null;
                } | null;
                readonly floorId: any;
                readonly floorName: string | null;
              } | null;
            } | null;
          } | null;
          readonly taskStartLocation: {
            readonly locationId: any;
            readonly name: string | null;
            readonly parkingSpaces: {
              readonly floor: {
                readonly bay: {
                  readonly bayId: any;
                  readonly bayName: string | null;
                } | null;
                readonly floorId: any;
                readonly floorName: string | null;
              } | null;
            } | null;
          } | null;
          readonly taskType: EnumtaskTaskType;
          readonly user: {
            readonly contact: {
              readonly phone: {
                readonly number: string;
                readonly prefix: string;
              };
            };
            readonly name: {
              readonly first: string;
              readonly last: string | null;
            };
          } | null;
          readonly valet: {
            readonly contact: {
              readonly phone: {
                readonly number: string;
                readonly prefix: string;
              };
            };
            readonly name: {
              readonly first: string;
              readonly last: string | null;
            };
            readonly profileImage: string | null;
          } | null;
          readonly vehicle: {
            readonly brand: {
              readonly brandLogo: string | null;
              readonly name: string | null;
            } | null;
            readonly color: string | null;
            readonly registration: {
              readonly plate: string;
            } | null;
          } | null;
        } | null> | null;
        readonly vehicle: {
          readonly brand: {
            readonly brandLogo: string | null;
            readonly name: string;
          } | null;
          readonly color: string | null;
          readonly registration: {
            readonly plate: string;
          };
        };
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "trackMyVehicleScreenQueryParkingFragment";
};
export type trackMyVehicleScreenQueryParkingFragment$key = {
  readonly " $data"?: trackMyVehicleScreenQueryParkingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trackMyVehicleScreenQueryParkingFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "findParkingV2"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "brandLogo",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "plate",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "prefix",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "wallettransactionSource",
  "kind": "LinkedField",
  "name": "source",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "UserName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "UserContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserContactPhone",
      "kind": "LinkedField",
      "name": "phone",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parkingTag",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parkingId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./trackMyVehicleScreenParkingQuery.graphql')
    }
  },
  "name": "trackMyVehicleScreenQueryParkingFragment",
  "selections": [
    {
      "alias": "findParkingV2",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "ParkingConnection",
      "kind": "LinkedField",
      "name": "__trackMyVehicleScreenParkingFragment__findParkingV2_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ParkingEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Parking",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ParkingJourney",
                  "kind": "LinkedField",
                  "name": "journey",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taskID",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ParkingVehicle",
                  "kind": "LinkedField",
                  "name": "vehicle",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ParkingVehicleBrand",
                      "kind": "LinkedField",
                      "name": "brand",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ParkingVehicleRegistration",
                      "kind": "LinkedField",
                      "name": "registration",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parkingRewardsGiven",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "campusLocation",
                  "kind": "LinkedField",
                  "name": "campus",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "campusLocationContact",
                      "kind": "LinkedField",
                      "name": "contact",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "campusLocationContactPhone",
                          "kind": "LinkedField",
                          "name": "phone",
                          "plural": false,
                          "selections": (v6/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "feedback",
                  "kind": "LinkedField",
                  "name": "feedback",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "feedbackFeedbacks",
                      "kind": "LinkedField",
                      "name": "feedbacks",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "topic",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "summary",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ratingType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rating",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tags",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ParkingParkingInvoice",
                  "kind": "LinkedField",
                  "name": "parkingInvoice",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ParkingParkingInvoicePaymentDetails",
                      "kind": "LinkedField",
                      "name": "paymentDetails",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isPaid",
                          "storageKey": null
                        },
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "mode",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ParkingParkingInvoiceCharges",
                      "kind": "LinkedField",
                      "name": "charges",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                          "kind": "LinkedField",
                          "name": "chargesBreakup",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "duration",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "payable",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "discountAmount",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalPayable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "valetTip",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invoicePdfPath",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "wallettransaction",
                  "kind": "LinkedField",
                  "name": "pointsData",
                  "plural": true,
                  "selections": [
                    (v9/*: any*/),
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parked_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "task",
                  "kind": "LinkedField",
                  "name": "tasks",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taskType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isVehicleWhitelisted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v11/*: any*/),
                        (v12/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskTaskStartLocation",
                      "kind": "LinkedField",
                      "name": "taskStartLocation",
                      "plural": false,
                      "selections": [
                        (v13/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "taskTaskStartLocationParkingSpaces",
                          "kind": "LinkedField",
                          "name": "parkingSpaces",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                              "kind": "LinkedField",
                              "name": "floor",
                              "plural": false,
                              "selections": [
                                (v14/*: any*/),
                                (v15/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                                  "kind": "LinkedField",
                                  "name": "bay",
                                  "plural": false,
                                  "selections": (v16/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskTaskEndLocation",
                      "kind": "LinkedField",
                      "name": "taskEndLocation",
                      "plural": false,
                      "selections": [
                        (v13/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "taskTaskEndLocationParkingSpaces",
                          "kind": "LinkedField",
                          "name": "parkingSpaces",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                              "kind": "LinkedField",
                              "name": "floor",
                              "plural": false,
                              "selections": [
                                (v14/*: any*/),
                                (v15/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                                  "kind": "LinkedField",
                                  "name": "bay",
                                  "plural": false,
                                  "selections": (v16/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskETA",
                      "kind": "LinkedField",
                      "name": "ETA",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "delayAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createToArrivedTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createToAcceptedTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "acceptedToArrivedTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "acceptedToArrivedCustomerTime",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "valet",
                      "plural": false,
                      "selections": [
                        (v12/*: any*/),
                        (v11/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profileImage",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskHistory",
                      "kind": "LinkedField",
                      "name": "history",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "action",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "event_at",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskVehicle",
                      "kind": "LinkedField",
                      "name": "vehicle",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "taskVehicleBrand",
                          "kind": "LinkedField",
                          "name": "brand",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "taskVehicleRegistration",
                          "kind": "LinkedField",
                          "name": "registration",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v17/*: any*/),
                    (v18/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "campusLocation",
                      "kind": "LinkedField",
                      "name": "campus",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ownerId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "createdByUser",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserName",
                          "kind": "LinkedField",
                          "name": "name",
                          "plural": false,
                          "selections": [
                            (v10/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserRole",
                          "kind": "LinkedField",
                          "name": "role",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "wallettransaction",
                      "kind": "LinkedField",
                      "name": "points",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v8/*: any*/),
                        (v7/*: any*/),
                        (v9/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "invoice",
                  "kind": "LinkedField",
                  "name": "invoicesData",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "invoicePriceDetails",
                      "kind": "LinkedField",
                      "name": "priceDetails",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "discountGiven",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rewardsUsed",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "netAmount",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Reward",
                  "kind": "LinkedField",
                  "name": "rewardData",
                  "plural": true,
                  "selections": [
                    (v18/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rewardOrigin",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "uniqueHash",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rewardType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rewardUniqueId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isUsed",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isExpired",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "expireAt",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updated_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "created_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deleted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deletedAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2d53f73e9c4a420db1d5d7f57c624e33";

export default node;
