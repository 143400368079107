import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from '../../../relay/relayEnvironment';

export const createBill = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation scanBillApiCreateBillMutation($record: createBillInput!) {
      createBill(record: $record) {
        record {
          _id
          status
          processing {
            success
            msg
          }
          ocrResponseParsed {
            address {
              full
              street
              city
              zipCode
              addressBlock
            }
            vendor {
              name
              gstNumber
            }
            bill {
              date
              billId
              subTotal
              finalAmount
            }
          }
        }
      }
    }
  `;
  const variables = {
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};

export const submitBill = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation scanBillApiSubmitBillMutation($id: MongoID!) {
      submitBill(_id: $id) {
        record {
          _id
          status
          processing {
            success
            msg
          }
          invoiceId
          ocrResponseParsed {
            address {
              full
              street
              city
              zipCode
              addressBlock
            }
            vendor {
              name
              gstNumber
            }
            bill {
              date
              billId
              subTotal
              finalAmount
            }
          }
        }
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};
