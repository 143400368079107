/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  Button,
  IconButton,
  Input,
  Layout,
  TopNavigation,
  View,
} from '../../../../components/new';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { updateParking } from '../../../../relay/parkingApi';

const EnterTagIdScreen = ({ navigation, route }) => {
  const { parkingId, searchText, taskId } = route?.params ?? {};
  const scrollViewRef = useRef(null);
  const [tagID, setTagID] = useState(
    searchText && searchText?.length ? searchText : '',
  );
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  useEffect(() => {
    return () => {
      setTagID('');
      Keyboard.dismiss();
    };
  }, []);

  const handleUpdateParkingTag = (res) => {
    if (res) {
      setTimeout(() => {
        navigation.navigate('addParkingDetails', {
          taskId,
          parkingTag: tagID,
        });
      }, 200);
    }
  };

  const onError = (error) => {
    dispatchSnackbar({
      msg: `${error.res.errors[0].message}`,
      status:
        error &&
        error?.res &&
        error?.res?.errors[0] &&
        error?.res?.errors[0]?.extensions &&
        error?.res?.errors[0]?.extensions?.code === 100
          ? SnackbarStatus.warning
          : SnackbarStatus.error,
      version: SnackbarVersion.v2,
    });
  };

  const saveParkingTag = () => {
    const data = {
      id: parkingId,
      record: {
        parkingTag: tagID,
      },
    };
    updateParking(data, handleUpdateParkingTag, onError);
  };

  const onPressUpdate = () => {
    Keyboard.dismiss();
    saveParkingTag();
  };

  const onPressLeftIcon = () => {
    Keyboard.dismiss();
    navigation.popToTop();
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        title="Enter Tag ID"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            onPress={onPressLeftIcon}
          />
        }
      />
      <KeyboardAvoidingView
        // style={Platform.OS === 'web' ? {} : { flex: 1 }}
        style={{ flex: 1 }}
        behavior={
          Platform.OS === 'android'
            ? null
            : Platform.OS === 'ios'
            ? 'padding'
            : null
        }
        // {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
      >
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 30,
          }}
          ref={scrollViewRef}
          keyboardShouldPersistTaps="handled"
        >
          <TouchableWithoutFeedback
            onPress={() => {
              Keyboard.dismiss();
              scrollViewRef.current?.scrollTo({
                x: 10,
                y: 120,
                animated: true,
              });
            }}
          >
            <View px="2xl">
              <View>
                <View mt="7xl">
                  <Input
                    inputMode="words"
                    label="Tag ID"
                    autoCapitalize="characters"
                    returnKeyType="next"
                    placeholder="Eg: 123456"
                    helperText="Enter 6 digit tag ID"
                    value={tagID}
                    onChangeText={setTagID}
                    maxLength={6}
                    autoFocus
                  />
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
        <View m="2xl" bottom="sm">
          <Button
            size="lg"
            appearance="filled"
            status="primary"
            state={tagID?.length >= 6 ? 'active' : 'disabled'}
            onPress={onPressUpdate}
          >
            Continue
          </Button>
        </View>
      </KeyboardAvoidingView>
    </Layout>
  );
};

export default EnterTagIdScreen;
