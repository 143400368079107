import React from 'react';
import View from '../../../basic/View/View';
import Text from '../../../primitive/Typography/Text/Text';
import { Data } from './types';

interface ContentRowProps {
  content: Data;
}

const ContentRow = ({ content }: ContentRowProps) => {
  return (
    <View flexDirection="row" justifyContent="space-between">
      <Text
        size={content.size ?? 'sm'}
        weight={content.weight ?? 'medium'}
        color={content.color ?? 'primary.400'}
      >
        {content.leftTxt}
      </Text>
      <Text
        size={content.size ?? 'sm'}
        weight={content.weight ?? 'medium'}
        color={content.color ?? 'primary.400'}
      >
        {content.rightTxt}
      </Text>
    </View>
  );
};

export default ContentRow;
