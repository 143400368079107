import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

export const handlePhone = (phone) => {
  phone = 'tel:'.concat(phone);
  Linking.openURL(phone);
};

export const handleDirections = (lat, lng) => {
  const addr = lat.concat(', ', lng);
  const urlApple = 'http://maps.apple.com/?ll='.concat(addr);
  const urlGoogle = 'https://www.google.com/maps/search/?api=1&query='.concat(
    addr,
  );

  if (Platform.OS === 'ios') {
    Linking.openURL(urlApple);
  } else if (Platform.OS === 'web') {
    window.open(urlGoogle, '_blank');
  } else if (Platform.OS === 'android') {
    Linking.openURL(urlGoogle);
  }
};
