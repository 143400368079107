/**
 * @generated SignedSource<<1771c37e5315e18f1d1659e5b254cf5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnuminvoiceModelCreateTCStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type Referer = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type invoiceWorkshopDetailsInput = {
  slotId: any;
  tickets?: ReadonlyArray<invoiceWorkshopDetailsTicketsInput | null> | null;
};
export type invoiceWorkshopDetailsTicketsInput = {
  spaces: number;
  ticketId: any;
};
export type reservationConfirmationScreenInitiatePaymentMutation$variables = {
  orderId: string;
  orderType: Referer;
  useRewardPoints: boolean;
  workshopDetails?: invoiceWorkshopDetailsInput | null;
};
export type reservationConfirmationScreenInitiatePaymentMutation$data = {
  readonly initiatePayment: {
    readonly _id: any;
    readonly created_at: any | null;
    readonly displayName: string;
    readonly orderId: string | null;
    readonly priceDetails: {
      readonly _id: any | null;
      readonly discountGiven: number;
      readonly grossAmount: number;
      readonly netAmount: number;
      readonly rewardsUsed: number;
    };
    readonly status: EnuminvoiceModelCreateTCStatus | null;
    readonly userId: any | null;
  } | null;
};
export type reservationConfirmationScreenInitiatePaymentMutation = {
  response: reservationConfirmationScreenInitiatePaymentMutation$data;
  variables: reservationConfirmationScreenInitiatePaymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useRewardPoints"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workshopDetails"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      },
      {
        "kind": "Variable",
        "name": "orderType",
        "variableName": "orderType"
      },
      {
        "kind": "Variable",
        "name": "useRewardPoints",
        "variableName": "useRewardPoints"
      },
      {
        "kind": "Variable",
        "name": "workshopDetails",
        "variableName": "workshopDetails"
      }
    ],
    "concreteType": "invoiceModelCreateTC",
    "kind": "LinkedField",
    "name": "initiatePayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "invoicePriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountGiven",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardsUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netAmount",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "reservationConfirmationScreenInitiatePaymentMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "reservationConfirmationScreenInitiatePaymentMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "cc7eee898422ee3f37bfcfc6e8aa3e34",
    "id": null,
    "metadata": {},
    "name": "reservationConfirmationScreenInitiatePaymentMutation",
    "operationKind": "mutation",
    "text": "mutation reservationConfirmationScreenInitiatePaymentMutation(\n  $orderType: Referer!\n  $orderId: ID!\n  $useRewardPoints: Boolean!\n  $workshopDetails: invoiceWorkshopDetailsInput\n) {\n  initiatePayment(orderType: $orderType, orderId: $orderId, useRewardPoints: $useRewardPoints, workshopDetails: $workshopDetails) {\n    userId\n    displayName\n    priceDetails {\n      grossAmount\n      discountGiven\n      rewardsUsed\n      netAmount\n      _id\n    }\n    status\n    _id\n    created_at\n    orderId\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf2b21dd39384c2defdf55072c517fb0";

export default node;
