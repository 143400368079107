import React, { PropsWithChildren } from 'react';
import { ThemeProvider, useTheme } from 'styled-components/native';
import { getTheme, themeModes } from '../../../themes/new/theme';

export enum ThemeWrapperType {
  AUTO = 'auto',
  DARK = 'dark',
  LIGHT = 'light',
  INVERTED = 'inverted',
}

interface ThemeWrapperProps {
  type: ThemeWrapperType;
}

const ThemeWrapper = (props: PropsWithChildren<ThemeWrapperProps>) => {
  const { type = ThemeWrapperType.AUTO, children = null } = props;
  const theme = useTheme();
  let newTheme;

  switch (type) {
    case ThemeWrapperType.AUTO:
      newTheme = theme?.currentThemeMode;
      break;
    case ThemeWrapperType.DARK:
      newTheme = themeModes.dark;
      break;
    case ThemeWrapperType.LIGHT:
      newTheme = themeModes.light;
      break;
    case ThemeWrapperType.INVERTED:
      if (theme?.currentThemeMode === themeModes.light) {
        newTheme = themeModes.dark;
      } else {
        newTheme = themeModes.light;
      }
      break;
    default:
      newTheme = themeModes.light;
  }

  const finalTheme = getTheme(newTheme);

  if (!finalTheme) {
    return <>{children}</>;
  }

  return <ThemeProvider theme={finalTheme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
