/**
 * @generated SignedSource<<79cf4be5a7de6636d542f9468d611a94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type findVehicleBrandFilterInput = {
  brand?: FilterFindManyvehicleBrandBrandInput | null;
};
export type FilterFindManyvehicleBrandBrandInput = {
  name?: string | null;
};
export type addVehicleScreenQuery$variables = {
  filter?: findVehicleBrandFilterInput | null;
};
export type addVehicleScreenQuery$data = {
  readonly findVehicleBrand: ReadonlyArray<{
    readonly _id: any;
    readonly brand: {
      readonly brandLogo: string | null;
      readonly name: string | null;
    } | null;
  }>;
};
export type addVehicleScreenQuery = {
  response: addVehicleScreenQuery$data;
  variables: addVehicleScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "vehicleBrand",
    "kind": "LinkedField",
    "name": "findVehicleBrand",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "vehicleBrandBrand",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brandLogo",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addVehicleScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addVehicleScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9915f5adf7afc56eb77957f86bfd082a",
    "id": null,
    "metadata": {},
    "name": "addVehicleScreenQuery",
    "operationKind": "query",
    "text": "query addVehicleScreenQuery(\n  $filter: findVehicleBrandFilterInput\n) {\n  findVehicleBrand(filter: $filter) {\n    _id\n    brand {\n      name\n      brandLogo\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "489ede47e652685ba5be23bef44bd291";

export default node;
