const photoMoments = {
  moments: '32nd Moments',
  joinOver: 'Over ',
  joinDesc:
    ' customers have trusted us to capture their 32nd Moments.Enjoy private memories with our secure face scanning.',
  scanFaceTxt: 'Scan Face',
  joinOur: 'Join our community of over ',
  getClickedText: ` delighted customers who have celebrated life's special moments with us!`,
  rescan: 'Re-Scan',
  oneStep: 'You are one step away to find your 32nd moments!',
  downloadApp: 'Download the 32nd Club app to access this feature',
  downloadNow: 'Download Now',
  noPhotosFound: 'No Media Found',
  permissionDenied: 'Permission denied!',
  deleteItem: 'Are you sure you want to delete this media?',
  deleteItemMultiple: 'Are you sure you want to delete these media files?',
  deleteStatement:
    'This media will be permanently deleted from your 32nd Moments',
  deleteStatementMultiple:
    'These media files will be permanently deleted from your 32nd Moments',
  rescanStatement: 'Are you sure you want to rescan your face?',
  noFaceDetected: 'No Face detected.',
  cameraTimeout: 'Camera timeout!',
  photoDeleted: ' deleted',
  termsAndConditions:
    'We store pictures for a limited period. Save your media file before they are gone.',
  sorryText: `We’re sorry for the delay.\nWorking on getting your\npictures soon!`,
  notifyMe: 'Notify Me',
  willNotify: 'We will notify you, stay tuned!',
  makeVideo: 'Make A Video',
  videoLib: 'Video Library',
  captureMoment: 'Capture Every Moment with 32nd',
  confirmFace: 'Confirm your face',
  confirmFaceSubText: 'To get your video on the app confirm its you',
  record: 'Yes, Record',
  discard: 'Discard, Capture Again',
  clickToStart: 'Click to Start',
  recorded: 'Successfully recorded',
  confirmVideo:
    'Confirm the video to upload and view it on the 32nd application',
  saveUpload: 'Save',
  discardRecord: 'Discard & Record Again',
  noFace: 'No face detected',
  uploadVideos: 'Upload Videos',
  uploadedSuccessFully: 'Uploaded Successfully!',
  retry: 'Retry',
  retryStatement: 'Video not uploaded, do you want to retry?',
  noVideos: 'No pending videos',
  microPermission: 'Please allow microphone permission!',
  generateQR: 'Share this QR code on the booth to get your photos printed',
  preview: 'Preview Image',
  confirmPreview: 'Confirm picture to initiate the print',
  inititatePrint: 'Initiate Print',
  imageDownloaded: 'Image is downloaded',
};

export default photoMoments;
