/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';
import {
  DateFormatsEnum,
  dateUtils,
  daysDiffInArray,
  getFormatedDate,
} from '../../../../utilities/helper';

export const getDateText = ({ data = [] }) => {
  if (!data || data.length === 0) return '';

  const allDays = data
    .map((item) => new Date(item?.slot?.startTime)?.getDate())
    .filter((date) => date !== undefined);

  const diffInDays = daysDiffInArray(allDays, 1);
  const startDate = data?.[0]?.slot?.startTime;
  const endDate = data?.[data.length - 1]?.slot?.endTime;

  if (!startDate || !endDate) return '';

  const startDateMonthDate = new Date(startDate).getDate();
  const endDateMonthDate = new Date(endDate).getDate();
  const isStartEndDateSame = !(startDateMonthDate - endDateMonthDate);

  if (diffInDays) {
    return `Multiple Dates`;
  }

  if (isStartEndDateSame) {
    return getFormatedDate(new Date(startDate), DateFormatsEnum.DateMonthYear);
  }
  return `${getFormatedDate(
    new Date(startDate),
    DateFormatsEnum.DateMonthYear,
  )}-${getFormatedDate(new Date(endDate), DateFormatsEnum.DateMonthYear)}`;
};

export const isSoldOutEvent = (event) => {
  if (event) {
    if (Array.isArray(event?.eventTiming) && event?.eventTiming?.length) {
      const isNotSoldOut = event?.eventTiming.some(
        (e) => e?.slot?.slotTag !== 'Sold Out',
      );
      return !isNotSoldOut;
    }
    return true;
  }
  return true;
};

export const isPassedEvent = (event) => {
  if (event) {
    if (Array.isArray(event?.eventTiming) && event?.eventTiming?.length === 0)
      return true;
    return false;
  }
  return true;
};

export const getEventPrice = (item) => {
  try {
    let price = !isEmpty(item?.eventTiming[0]?.slot?.availableTickets)
      ? item?.eventTiming[0]?.slot?.availableTickets?.reduce((acc, curr) => {
          if (curr?.isActive) {
            const newPrice = curr?.price / curr?.personAllowed;
            if (newPrice < acc) {
              return newPrice;
            }
          }
          return acc;
        }, Number.MAX_SAFE_INTEGER)
      : 0;
    price = price === Number.MAX_SAFE_INTEGER ? 0 : price;
    return price;
  } catch (e) {
    return 0;
  }
};

export const getEventCancellationInfo = (
  eventDate: Date,
): {
  isRefundableIfCancelled: boolean; // currently cancellation is refundable one day before the event day.
  refundLastDate: {
    date: Date;
    formatOne: string; // 01 January, 11:59 PM
    formatTwo: string; // 11:59 PM, 01 January 2024
  };
} => {
  const isEventRefundable = dateUtils.isFutureDate(eventDate);
  let refundableTill = new Date(eventDate); // clone
  refundableTill.setDate(refundableTill.getDate() - 1); // subtracting one day
  refundableTill = dateUtils.toEndOfDay(new Date(refundableTill));
  const year = refundableTill.getFullYear().toString();
  const month = getFormatedDate(
    refundableTill,
    DateFormatsEnum.MonthFull,
  ) as string;
  const date = refundableTill.getDate().toString();
  const time = getFormatedDate(refundableTill, DateFormatsEnum.TIME) as string;
  return {
    isRefundableIfCancelled: isEventRefundable,
    refundLastDate: {
      date: refundableTill,
      formatOne: `${date} ${month}, ${time}`,
      formatTwo: `${date} ${month} ${year}`,
    },
  };
};
