import React from 'react';
import { Image } from 'react-native';

const SlotContent = ({ imgInfo }) => {
  return (
    <>
      <Image source={imgInfo.img} style={{ height: 50, width: 50 }} />
    </>
  );
};

export default SlotContent;
