import React, { PropsWithChildren } from 'react';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import { FontWeights } from '../../../../themes/new/helper';

type OverviewTileProps = PropsWithChildren<{
  txtPrimary?: string;
  txtSecondsry?: string;
}>;

const OverviewTile = ({
  txtPrimary,
  txtSecondsry,
  children: Content,
}: OverviewTileProps) => {
  return (
    <View
      minWidth={100}
      p="lg"
      borderWidth="xs"
      borderColor="primary.400"
      bg="primary.500"
      borderRadius="md"
    >
      <Text
        mb="sm"
        textAlign="center"
        size="xl"
        color="primary.10"
        weight={FontWeights.MEDIUM}
      >
        {txtPrimary}
      </Text>
      <Text textAlign="center" size="sm" color="primary.200">
        {txtSecondsry}
      </Text>
    </View>
  );
};

export default OverviewTile;
