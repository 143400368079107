/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useRef, useState } from 'react';
import {
  ScrollView,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  View,
  Text,
  Layout,
  TopNavigation,
  IconButton,
  Input,
} from '../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import useUserStore, { UserRole } from '../../stores/userStore';
import {
  PaymentGatewayType,
  navigateBack,
  paymentOrderTypeEnum,
} from '../../utilities/helper';
import { InputStates } from '../../components/new/primitive/Input/helpers';
import useCommonStore from '../../stores/commonStore';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';
import useAuthStore from '../../stores/authStore';

const AddAmountScreen = (props) => {
  const { amount } = props ?? {};
  const scrollViewRef = useRef(null);
  const navigation = useNavigation<any>();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const userId = useUserStore((state) => state.id);
  const [value, setValue] = useState(`${amount || ''}`);

  // ------ handle on pressing handleContinue will take you to payment screen------
  const handleContinue = () => {
    firebaseEventLogger('addAmount__continue_Screen', {
      buttonName: 'continue',
      screenName: 'addAmount',
      userType: UserRole.PARTNER,
      interactionType: InteractionType.TAP,
      isLoggedIn,
      partnerId: userId,
      amount: Number(value),
      orderType: paymentOrderTypeEnum.POPUPSTORE,
      paymentType: PaymentGatewayType.PAYTMQR,
    });
    navigation.replace('PartnerPaymentScreen', {
      amount: Number(value),
    });
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={
        Platform.OS === 'android'
          ? null
          : Platform.OS === 'ios'
          ? 'padding'
          : null
      }
    >
      <ScrollView
        contentContainerStyle={{
          flex: 1,
          paddingBottom: 30,
        }}
        ref={scrollViewRef}
        keyboardShouldPersistTaps="handled"
      >
        <TouchableWithoutFeedback
          onPress={() => {
            Keyboard.dismiss();
            scrollViewRef.current?.scrollTo({
              x: 10,
              y: 120,
              animated: true,
            });
          }}
        >
          <View>
            <View px="2xl">
              <View
                my="4xl"
                borderRadius="md"
                bg="primary.10"
                shadow="lg"
                p="2xl"
              >
                <Text size="lg" color="primary.300" textAlign="center">
                  Enter Bill Amount (₹)
                </Text>
                <View mt="2xl">
                  <Input
                    value={`${value}`}
                    placeholder="₹0"
                    state={InputStates.unstyled}
                    textSize="5xl"
                    onChangeText={(i) => {
                      const modifiedInput = i.replace(/[^0-9]/g, '');
                      setValue(modifiedInput);
                    }}
                    inputMode="numeric"
                    keyboardType="numeric"
                    textAlign="center"
                    style={{
                      textAlign: 'center',
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
        <View
          position="absolute"
          bottom="2xl"
          px="2xl"
          width="100%"
          bg="background.primary.base"
        >
          <Button
            appearance="filled"
            state={
              parseInt(value, 10) === 0 || Number.isNaN(parseInt(value, 10))
                ? 'disabled'
                : 'active'
            }
            size="lg"
            my="2xl"
            onPress={handleContinue}
          >
            Continue
          </Button>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const AddAmountScreenWrapper = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const { amount } = route?.params ?? {};
  const userId = useUserStore((state) => state.id);
  const campusID = useCommonStore((state) => state.campusId);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
        title="Payment"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <AddAmountScreen
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            amount={amount}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default AddAmountScreenWrapper;
