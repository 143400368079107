/**
 * @generated SignedSource<<93b932a423b1c076523f012a38987c95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumUserContactEmailType = "PERSONAL" | "WORK" | "%future added value";
export type EnumUserPartnerRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumUserRoleName = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type EnumUserRolePermission = "staff" | "%future added value";
export type EnumUserRoleSecondaryRole = "admin" | "auditor" | "billUploadReviewer" | "campusStaff" | "cashier" | "controller" | "kiosk" | "momentStaff" | "momentStaffPartner" | "partner" | "photoMomentsPartner" | "restaurantPartner" | "restaurantPartnerMod" | "restaurantPartnerStaff" | "user" | "valet" | "valetSupervisor" | "%future added value";
export type updateUserRecordInput = {
  contact?: UpdateByIdUserContactInput | null;
  createdBy?: any | null;
  deleted?: boolean | null;
  deletedAt?: any | null;
  devices?: ReadonlyArray<UpdateByIdUserDevicesInput | null> | null;
  firstLogin?: boolean | null;
  firstLoginAt?: any | null;
  identityCode?: string | null;
  isEmployee?: boolean | null;
  lastLogin?: any | null;
  name?: UpdateByIdUserNameInput | null;
  parkedCount?: number | null;
  partnerRefId?: any | null;
  partnerRefType?: EnumUserPartnerRefType | null;
  photoMomentsSearchImage?: string | null;
  pin?: string | null;
  profileImage?: string | null;
  role?: UpdateByIdUserRoleInput | null;
  typeForm?: UpdateByIdUserTypeFormInput | null;
  uniBoxCredentials?: UpdateByIdUserUniBoxCredentialsInput | null;
  visitCount?: number | null;
  wallet?: UpdateByIdUserWalletInput | null;
};
export type UpdateByIdUserNameInput = {
  _id?: any | null;
  first?: string | null;
  last?: string | null;
};
export type UpdateByIdUserContactInput = {
  _id?: any | null;
  email?: ReadonlyArray<UpdateByIdUserContactEmailInput | null> | null;
  phone?: UpdateByIdUserContactPhoneInput | null;
};
export type UpdateByIdUserContactPhoneInput = {
  _id?: any | null;
  number: string;
  prefix: string;
};
export type UpdateByIdUserContactEmailInput = {
  _id?: any | null;
  addr: string;
  type: EnumUserContactEmailType;
};
export type UpdateByIdUserDevicesInput = {
  OS?: string | null;
  _id?: any | null;
  deviceToken?: string | null;
  enabled?: boolean | null;
  expoToken?: string | null;
  modelName?: string | null;
  platform?: string | null;
};
export type UpdateByIdUserTypeFormInput = {
  _id?: any | null;
  landedAt?: any | null;
  submittedAt?: any | null;
};
export type UpdateByIdUserRoleInput = {
  _id?: any | null;
  meta?: UpdateByIdUserRoleMetaInput | null;
  name?: EnumUserRoleName | null;
  permission?: EnumUserRolePermission | null;
  secondaryRole?: ReadonlyArray<EnumUserRoleSecondaryRole | null> | null;
};
export type UpdateByIdUserRoleMetaInput = {
  _id?: any | null;
  campus?: UpdateByIdUserRoleMetaCampusInput | null;
};
export type UpdateByIdUserRoleMetaCampusInput = {
  _id?: any | null;
  name?: string | null;
};
export type UpdateByIdUserUniBoxCredentialsInput = {
  _id?: any | null;
  password?: string | null;
  username?: string | null;
};
export type UpdateByIdUserWalletInput = {
  _id?: any | null;
  currentBalance?: number | null;
  earnedAmount?: number | null;
  moneySaved?: number | null;
  totalBalance?: number | null;
};
export type EmailOtpVerificationScreenMutation$variables = {
  id: any;
  record: updateUserRecordInput;
};
export type EmailOtpVerificationScreenMutation$data = {
  readonly updateUser: {
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type EmailOtpVerificationScreenMutation = {
  response: EmailOtpVerificationScreenMutation$data;
  variables: EmailOtpVerificationScreenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "record",
    "variableName": "record"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailOtpVerificationScreenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateByIdUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailOtpVerificationScreenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateByIdUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "877b7b8ec5ae22780919b661983c69b7",
    "id": null,
    "metadata": {},
    "name": "EmailOtpVerificationScreenMutation",
    "operationKind": "mutation",
    "text": "mutation EmailOtpVerificationScreenMutation(\n  $id: MongoID!\n  $record: updateUserRecordInput!\n) {\n  updateUser(_id: $id, record: $record) {\n    error {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3be50cee996183a02d8c930282c92f1c";

export default node;
