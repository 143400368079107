import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from '../../index';

export interface CarouselDotsProps {
  numberOfDots: number;
  activeIndex: number;
  activeColor?: string;
  inactiveColor?: string;
  width?: string;
}

const CarouselDots = (props: CarouselDotsProps) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        width: props.width ?? '100%',
        justifyContent: 'center',
      }}
    >
      {Array.from({ length: props.numberOfDots }).map((_, index) => {
        const isActive = props.activeIndex === index;
        return (
          <View key={index} style={{ marginRight: 8 }}>
            {isActive ? (
              <View
                style={styles.dotActive}
                bg={props.activeColor ?? 'primary.300'}
              >
                {null}
              </View>
            ) : (
              <View
                style={styles.dotInactive}
                bg={props.inactiveColor ?? 'grey.200'}
              >
                {null}
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

export default CarouselDots;

const styles = StyleSheet.create({
  dotActive: {
    height: 10,
    width: 10,
    borderRadius: 5,
  },
  dotInactive: {
    height: 8,
    width: 8,
    borderRadius: 4,
  },
});
