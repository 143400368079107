import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

// eslint-disable-next-line import/prefer-default-export
export const updateTask = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation taskApiMutation($id: MongoID!, $record: updateTaskRecordInput!) {
      updateTask(record: $record, _id: $id) {
        record {
          _id
          vehicle {
            registration {
              plate
            }
            brand {
              name
              brandLogo
            }
            color
          }
          history {
            event_at
            action
          }
          taskStartLocation {
            name
            locationId
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          taskEndLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          assignedTo
          taskType
          user {
            name {
              first
              last
            }
            contact {
              phone {
                prefix
                number
              }
            }
          }
          parkingId
        }
      }
    }
  `;
  const variables = {
    id: data._id,
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};

export const updateTaskForSupervisor = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation taskApiUpdateMutation(
      $id: MongoID!
      $record: updateTaskRecordInput!
    ) {
      updateTask(record: $record, _id: $id) {
        record {
          _id
          vehicle {
            brand {
              name
              brandLogo
            }
            registration {
              plate
            }
            color
          }
          taskType
          taskStartLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          taskEndLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          campus {
            _id
            name
          }
          history {
            _id
            action
            actor
            event_at
          }
          assignedTo
          valet {
            _id
            name {
              first
              last
            }
            identityCode
            contact {
              phone {
                prefix
                number
              }
            }
          }
          user {
            name {
              first
              last
            }
            contact {
              phone {
                prefix
                number
              }
            }
          }
          ETA {
            _id
            startAt
            delayAt
            originalPredictionTime
            pendingRecall
            available
            busy
          }
          vehicleId
          ownerId
          parkingId
          parkingTag
          createdBy {
            status
          }
        }
      }
    }
  `;
  const variables = {
    id: data._id,
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};

export const addTask = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation taskApiaddMutation($record: addTaskRecordInput!) {
      addTask(record: $record) {
        record {
          _id
          taskType
          vehicle {
            brand {
              name
              brandLogo
            }
            color
            registration {
              plate
            }
          }
          taskStartLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          taskEndLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          parkingActivity {
            _id
          }
          campus {
            _id
            name
          }
          history {
            _id
            event_at
            actor
            action
          }
          assignedTo
          vehicleId
          ownerId
          parkingId
          valet {
            name {
              first
              last
            }
            contact {
              phone {
                prefix
                number
              }
            }
          }
          user {
            name {
              first
              last
            }
            contact {
              phone {
                prefix
                number
              }
            }
          }
          ETA {
            delayAt
          }
        }
      }
    }
  `;
  const variables = {
    record: data.record,
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};

export const findTask = (data) => {
  const query = graphql`
    query taskApiFindTaskQuery($filter: findTaskFilterInput!) {
      findTasks(filter: $filter) {
        _id
        vehicle {
          brand {
            name
            brandLogo
          }
          registration {
            plate
          }
          color
        }
        taskType
        taskStartLocation {
          locationId
          name
          parkingSpaces {
            floor {
              floorName
              floorId
              bay {
                bayName
                bayId
              }
            }
          }
        }
        taskEndLocation {
          locationId
          name
          parkingSpaces {
            floor {
              floorName
              floorId
            }
          }
        }
        campus {
          _id
          name
        }
        history {
          action
          actor
          event_at
        }
        assignedTo
        valet {
          name {
            first
            last
          }
        }
        user {
          name {
            first
            last
          }
          contact {
            phone {
              prefix
              number
            }
          }
        }
        vehicleId
        ownerId
        parkingId
        parkingTag
      }
    }
  `;
  const variables = {
    filter: data,
  };
  return fetchQuery(environment, query, variables).toPromise();
};
