export const enum feedbackType {
  DINE_OUT = 'DINE_OUT',
  VALET = 'VALET',
  GENERAL = 'GENERAL',
}

export const enum feedbacksTopics {
  OVERALL = 'OVERALL',
  VALET_DRIVER = 'VALET_DRIVER',
  VALET_SERVICE = 'VALET_SERVICE',
  FOOD = 'FOOD',
  FOOD_SERVICE = 'FOOD_SERVICE',
  PAYMENT_SERVICE = 'PAYMENT_SERVICE',
  DINE_OUT = 'DINE_OUT',
  VALET = 'VALET',
  OTHER = 'OTHER',
}

export const enum ratingType {
  STARS = 'STARS',
  EMOJI = 'EMOJI',
  NO_RATING = 'NO_RATING',
}

const selectHeading = (key) => {
  switch (key) {
    case 1:
      return 'We apologise for the bad experience, what went wrong?';
    case 2:
      return 'We appreciate your honest feedback, what issue did you face?';
    case 3:
      return 'We are glad you liked us! How can we make your experience better?';
    case 4:
      return 'Thrilled you had a nice time! Help us with what made your day?';
    default:
      return 'Please select atleast one option!';
  }
};

// eslint-disable-next-line consistent-return
const selectFeedbackService = (type) => {
  if (type === feedbacksTopics.VALET) {
    return [
      {
        title: 'Driver',
        titleEnum: feedbacksTopics.VALET_DRIVER,
        options: [
          { title: 'Unfair Tip', enum: 'VALET_UNFAIR_TIP' },
          { title: 'Unhygienic', enum: 'VALET_UNHYGENIC' },
          { title: 'Impolite', enum: 'VALET_IMPOLITE' },
          { title: 'Unresponsive On Call', enum: 'VALET_UNRESPONSIVE' },
        ],
      },
      {
        title: 'Valet Service',
        titleEnum: feedbacksTopics.VALET_SERVICE,
        options: [
          { title: 'Unnecessary Delay', enum: 'VALET_SERVICE_DELAYED' },
          { title: 'Car Damaged', enum: 'VALET_SERVICE_CAR_DAMAGED' },
          {
            title: 'Inappropriate Pricing',
            enum: 'VALET_SERVICE_INAPPRORIATE_PRICE',
          },
          { title: 'Missing Item(s)', enum: 'VALET_SERVICE_MISSING_ITEM' },
        ],
      },
    ];
  }
  if (type === feedbacksTopics.DINE_OUT) {
    return [
      {
        title: 'Food',
        titleEnum: feedbacksTopics.FOOD,
        options: [
          { title: 'Taste Issues', enum: 'FOOD_TASTE_ISSUES' },
          { title: 'Stale Food', enum: 'FOOD_STALE' },
          { title: 'Quantity', enum: 'FOOD_QUANTITY' },
        ],
      },
      {
        title: 'Service',
        titleEnum: feedbacksTopics.FOOD_SERVICE,
        options: [
          { title: 'Delayed Service', enum: 'FOOD_SERVICE_DELAYED' },
          { title: 'Requested Tip', enum: 'FOOD_SERVICE_REQUESTED_TIP' },
          { title: 'Unprofessional', enum: 'FOOD_SERVICE_UNPROFESSIONAL' },
        ],
      },
      {
        title: 'Payment Process',
        titleEnum: feedbacksTopics.PAYMENT_SERVICE,
        options: [
          {
            title: 'Transaction Failed',
            enum: 'PAYMENT_SERVICE_TRANSACTION_FAILED',
          },
          {
            title: 'Payment Mode Unavailable',
            enum: 'PAYMENT_SERVICE_MODE_UNAVALIABLE',
          },
        ],
      },
    ];
  }
};

export const reactions = [
  {
    key: 1,
    icon: 'sentiment-dissatisfied-300',
    selectedIcon: require('../../assets/images/ratings/dissatisfied.webp'),
    iconColor: 'primary.300',
    size: 'xl',
    path: require('../../assets/lottie/sad.json'),
  },
  {
    key: 2,
    icon: 'sentiment-neutral-300',
    selectedIcon: require('../../assets/images/ratings/neutral.webp'),
    iconColor: 'primary.300',
    size: 'xl',
    path: require('../../assets/lottie/average.json'),
  },
  {
    key: 3,
    icon: 'sentiment-satisfied-alt-300',
    selectedIcon: require('../../assets/images/ratings/satisfied.webp'),
    iconColor: 'primary.300',
    size: 'xl',
    path: require('../../assets/lottie/good.json'),
  },
  {
    key: 4,
    icon: 'sentiment-very-satisfied-300',
    selectedIcon: require('../../assets/images/ratings/very-satisfied.webp'),
    iconColor: 'primary.300',
    size: 'xl',
    path: require('../../assets/lottie/best.json'),
  },
];

export const getFeedbacks = (val) => {
  return {
    heading: selectHeading(val?.rating),
    optionsArray: selectFeedbackService(val?.option),
  };
};
