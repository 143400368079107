/**
 * @generated SignedSource<<00ea7c5288a96e8f6bca375efa1bc897>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type EnuminvoiceOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnuminvoicePaymentType = "BILL_UPLOAD" | "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnuminvoicePopUpStoreDetailsDeliveryStatus = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type partnerTransactionUpdatedSubscription$variables = {
  invoiceId: string;
  orderId?: string | null;
};
export type partnerTransactionUpdatedSubscription$data = {
  readonly invoiceUpdated: {
    readonly _id: any;
    readonly created_at: any | null;
    readonly orderId: any;
    readonly orderType: EnuminvoiceOrderType;
    readonly paymentDetails: {
      readonly paytmQr: {
        readonly transactionId: string | null;
      } | null;
      readonly razorpay: {
        readonly pgOrderId: string;
      } | null;
    } | null;
    readonly paymentType: EnuminvoicePaymentType | null;
    readonly popUpStoreDetails: {
      readonly deliveryStatus: EnuminvoicePopUpStoreDetailsDeliveryStatus | null;
      readonly displayName: string;
    } | null;
    readonly priceDetails: {
      readonly grossAmount: number;
    };
    readonly status: EnuminvoiceStatus | null;
    readonly user: {
      readonly _id: any;
      readonly contact: {
        readonly phone: {
          readonly number: string;
          readonly prefix: string;
        };
      };
    } | null;
  } | null;
};
export type partnerTransactionUpdatedSubscription = {
  response: partnerTransactionUpdatedSubscription$data;
  variables: partnerTransactionUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invoiceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "invoiceId",
        "variableName": "invoiceId"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      }
    ],
    "concreteType": "invoice",
    "kind": "LinkedField",
    "name": "invoiceUpdated",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "invoicePriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserContact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContactPhone",
                "kind": "LinkedField",
                "name": "phone",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prefix",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "invoicePopUpStoreDetails",
        "kind": "LinkedField",
        "name": "popUpStoreDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliveryStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiePaymentDetailsTC",
        "kind": "LinkedField",
        "name": "paymentDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "payment",
            "kind": "LinkedField",
            "name": "razorpay",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pgOrderId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaytmDqrOrders",
            "kind": "LinkedField",
            "name": "paytmQr",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "partnerTransactionUpdatedSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "partnerTransactionUpdatedSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f502d990e309584ebcb72a963193b48e",
    "id": null,
    "metadata": {},
    "name": "partnerTransactionUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription partnerTransactionUpdatedSubscription(\n  $invoiceId: ID!\n  $orderId: ID\n) {\n  invoiceUpdated(invoiceId: $invoiceId, orderId: $orderId) {\n    _id\n    status\n    priceDetails {\n      grossAmount\n    }\n    user {\n      _id\n      contact {\n        phone {\n          prefix\n          number\n        }\n      }\n    }\n    paymentType\n    orderType\n    orderId\n    popUpStoreDetails {\n      deliveryStatus\n      displayName\n    }\n    created_at\n    paymentDetails {\n      razorpay {\n        pgOrderId\n      }\n      paytmQr {\n        transactionId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1137825c429fed0a16f6d01708353d8a";

export default node;
