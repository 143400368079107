/* eslint-disable no-nested-ternary */
import { Linking, Platform } from 'react-native';
import React, { useCallback, useState, useRef } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, View, Text, Icon } from '../../../../components/new';
import {
  formatPlateNo,
  formatTimeToHrsMinSec,
  taskHistoryActionTypes,
} from '../../../../utilities/helper';
import { handlePhone } from '../../../../utilities/handleLinking';
import TaskCard from '../../../../components/new/primitive/TaskCard/TaskCard';
import RectangularTimer from '../../../../components/new/primitive/Timer/RectangularTimer';
import { updateTaskToArriveQuery } from '../../API/updateTaskToArriveQuery';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import AssignReparkingConfirmation from './AssignReparkingConfirmation';
import MarkExitConfirmation from './MarkExitConfirmation';
import ReparkOptions from '../ReparkOptions';
import useTaskStore from '../../../../stores/taskStore';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import { Appearance, State, Status } from '../../../../themes/new/helper';

const RenderItem = ({ item, isSupervisor, selectedIndex }) => {
  const navigation = useNavigation<any>();
  const [modalVisible, setModalVisible] = useState(false);
  const [carArrivedButtonDisabled, setCarArrivedButtonDisabled] =
    useState(false);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const bottomSheetModalRef = useRef(null);
  const MarkExitbottomSheetModalRef = useRef(null);
  const {
    recalledTasks,
    setRecalledTasks,
    recallTaskOpened,
    setRecallTaskOpened,
  } = useTaskStore((state) => state);

  const handleOnDismiss = React.useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  const MarkEditConfirmationHandleDismiss = React.useCallback(() => {
    MarkExitbottomSheetModalRef.current?.hide();
  }, []);

  const handlePresentModalPress = React.useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);
  const formattedVehicleNumber = formatPlateNo(
    item?.vehicle?.registration?.plate,
  );

  const parkingTag = `#${item?.parkingTag}`;
  const heading = isSupervisor ? formattedVehicleNumber : parkingTag;
  const subHeading = isSupervisor ? parkingTag : formattedVehicleNumber;
  const arrivedStatus =
    item &&
    item?.history &&
    item?.history?.length &&
    item?.history?.filter(
      (ele) => ele?.action === taskHistoryActionTypes.arrived,
    );
  const lastStatus = item && item?.history && item?.history.slice(-1)[0];

  const delayAt = isSupervisor
    ? new Date(item?.ETA?.delayAt).valueOf()
    : new Date(item?.history[0]?.event_at).valueOf();
  const currentTime =
    isSupervisor && arrivedStatus && arrivedStatus?.length
      ? new Date(arrivedStatus[0]?.event_at).valueOf()
      : new Date().valueOf();
  const isDelayed = currentTime > delayAt;
  const difference = isDelayed ? currentTime - delayAt : delayAt - currentTime;
  const timerObj = {
    title1: isSupervisor
      ? selectedIndex === 0
        ? 'ETA'
        : 'Valet Wait Time'
      : 'Wait Time',
    title2: formatTimeToHrsMinSec(difference / 1000),
    title2Color: 'primary.500',
  };
  if (isSupervisor) {
    if (
      !isDelayed &&
      difference / 1000 > 120 &&
      difference / 1000 <= 300 &&
      selectedIndex === 0
    ) {
      timerObj.title2Color = 'warning.500';
    }
    if (!isDelayed && difference / 1000 < 120 && selectedIndex === 0) {
      timerObj.title2Color = 'error.500';
    }
    if (isDelayed && selectedIndex === 0) {
      timerObj.title2Color = 'error.500';
      timerObj.title1 = 'Delayed';
    }
  }
  if (!isSupervisor) {
    if (difference / 1000 > 120 && difference / 1000 < 300) {
      timerObj.title2Color = 'warning.500';
    }
    if (difference / 1000 > 300) {
      timerObj.title2Color = 'error.500';
    }
  }
  const headerRightComponent = () =>
    item?.assignedTo !== null ? (
      selectedIndex === 0 ? (
        <></>
      ) : isSupervisor ? (
        <View flexDirection="row" alignItems="center">
          <View
            width="3xs"
            height="3xs"
            bg={isDelayed ? 'error.500' : 'success.500'}
            borderRadius="full"
          />
          <Text
            size="xs"
            color={isDelayed ? 'error.500' : 'success.500'}
            ml="sm"
          >
            {isDelayed ? 'Delayed' : 'On Time'}
          </Text>
        </View>
      ) : (
        <></>
      )
    ) : isSupervisor ? (
      // <Icon size="xl" name="no-man-400" color="error.500" />
      <></>
    ) : (
      <></>
    );

  const topRightCornerComponent = () =>
    item?.assignedTo !== null ? (
      item?.valet?.identityCode ? (
        <View
          borderRadius="full"
          borderWidth="xs"
          borderColor="primary.100"
          py="sm"
          px="md"
          bg="primary.50"
        >
          <Text size="sm" color="primary.500">
            {item?.valet?.identityCode}
          </Text>
        </View>
      ) : (
        <></>
      )
    ) : (
      <View
        borderRadius="full"
        borderWidth="xs"
        borderColor="primary.50"
        p="sm"
      >
        <Icon size="xl" name="car-filled-400" color="primary.100" />
      </View>
    );

  const bottomLeftCornerComponent = () => (
    <RectangularTimer
      title1={timerObj?.title1}
      title2={timerObj?.title2}
      title2Color={timerObj?.title2Color}
    />
  );

  const bottomRightCornerComponent = () =>
    isSupervisor ? (
      <View flexDirection="row" alignItems="center">
        <Icon size="md" name="locationon_outline" color="singletone.black" />
        <Text size="xs" ml="xs" color="singletone.black">
          {item?.taskStartLocation?.name}
        </Text>
      </View>
    ) : (
      <></>
    );

  const bottomLeftCTAComponentFor32nd = ({ taskId }) => {
    const handleCarArrivedBtn = () => {
      setCarArrivedButtonDisabled(true);
      const data = {
        _id: taskId,
        record: {
          history: { action: taskHistoryActionTypes.arrived },
        },
      };

      updateTaskToArriveQuery(
        data,
        () => {
          const removeItem = (id) => {
            if (isSupervisor) {
              const arr = recalledTasks.filter((i) => i._id !== id);
              setRecalledTasks({ data: arr });
            }
            // after removing the item, we start animation
          };

          removeItem(item._id);
          dispatchSnackbar({
            msg: `Task moved to arrived`,
            status: SnackbarStatus.success,
            version: SnackbarVersion.v2,
            position: 'top',
          });
          setCarArrivedButtonDisabled(false);
        },
        () => {
          dispatchSnackbar({
            msg: `Something went wrong!`,
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
            position: 'top',
          });
          setCarArrivedButtonDisabled(false);
        },
      );
    };
    const handleParkingAgainBtn = () => {
      // TODO
      // setShowReparkBottomSheet(true);
      handlePresentModalPress();
    };
    return selectedIndex === 0 ? (
      <Button
        size="md"
        appearance={
          carArrivedButtonDisabled ? Appearance.FILLED : Appearance.OUTLINE
        }
        status={Status.PRIMARY}
        onPress={() => handleCarArrivedBtn()}
        shadow="sm"
        state={
          (lastStatus?.action === taskHistoryActionTypes.assigned ||
            lastStatus?.action === taskHistoryActionTypes.accepted ||
            lastStatus?.action === taskHistoryActionTypes.started ||
            lastStatus?.action === taskHistoryActionTypes.arrived) &&
          !carArrivedButtonDisabled
            ? State.ACTIVE
            : State.DISABLED
        }
        loading={carArrivedButtonDisabled}
      >
        Car Arrived
      </Button>
    ) : (
      <Button
        size="md"
        appearance={Appearance.OUTLINE}
        status={Status.PRIMARY}
        onPress={() => handleParkingAgainBtn()}
        shadow="sm"
      >
        Park Again
      </Button>
    );
  };

  const bottomLeftCTAComponentForOtherParking = () => {
    const handleScanAssignValet = () => {
      if (Platform.OS !== 'web') {
        navigation.navigate('ScanQrCodeScreen', { task: item });
      } else {
        navigation.navigate('SearchValetScreen', {
          // ...response.addTask.record,
          ...item,
        });
      }
    };
    // const handleReassignTask = () => {
    //   navigation.navigate('assignTask', {
    //     // ...response.addTask.record,
    //     ...item,
    //   });
    // };
    return selectedIndex === 0 ? (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        onPress={() => handleScanAssignValet()}
        shadow="sm"
      >
        Scan & Assign Valet
      </Button>
    ) : (
      <Button
        size="md"
        appearance={Appearance.OUTLINE}
        status={Status.PRIMARY}
        onPress={() => handleScanAssignValet()}
        shadow="sm"
      >
        Re-Assign Task
      </Button>
    );
  };

  const bottomRightCTAComponentFor32nd = ({ phoneNumber }) => {
    const onPressCallOption = (itemData) => {
      if (itemData?.assignedTo !== null) {
        handlePhone(
          // eslint-disable-next-line no-unsafe-optional-chaining
          itemData?.valet?.contact?.phone?.prefix +
            // eslint-disable-next-line no-unsafe-optional-chaining
            itemData?.valet?.contact?.phone?.number,
        );
      } else {
        // need to update phone number of the controllers
        handlePhone(
          // eslint-disable-next-line no-unsafe-optional-chaining
          itemData?.taskStartLocation?.contact?.phone[0]?.prefix +
            // eslint-disable-next-line no-unsafe-optional-chaining
            itemData?.taskStartLocation?.contact?.phone[0]?.number,
        );
      }
    };
    const handleCallCustomer = () => {
      Linking.openURL(`tel:${phoneNumber}`);
    };
    return selectedIndex === 0 ? (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        shadow="sm"
        onPress={() => onPressCallOption(item)}
      >
        {item.valet ? `Call Valet` : `Call Controller`}
      </Button>
    ) : (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        shadow="sm"
        onPress={() => {
          handleCallCustomer();
        }}
      >
        Call Customer
      </Button>
    );
  };
  const bottomRightCTAComponentForOtherParking = () => {
    const handleCallValet = () => {
      const valetFullPhoneNumber = `${item?.valet?.contact?.phone?.prefix}${item?.valet?.contact?.phone?.number}`;
      Linking.openURL(`tel:${valetFullPhoneNumber}`);
    };
    return selectedIndex === 0 ? (
      <></>
    ) : (
      <Button
        size="md"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        shadow="sm"
        onPress={() => handleCallValet()}
      >
        Call Valet
      </Button>
    );
  };

  const onPressSwipeRight = useCallback(() => {
    MarkExitbottomSheetModalRef.current?.show();
  }, []);
  const fullCustomerPhoneNumber = `${item?.user?.contact?.phone?.prefix}${item?.user?.contact?.phone?.number}`;
  return (
    <>
      <View px="2xl" my="lg">
        <TaskCard
          blur={item?.blur}
          heading={heading}
          subHeading={subHeading}
          shadow="md"
          headingRight={headerRightComponent()}
          topRightCorner={topRightCornerComponent()}
          bottomLeftCorner={bottomLeftCornerComponent()}
          bottomRightCorner={bottomRightCornerComponent()}
          bottomLeftCTA={
            isSupervisor
              ? bottomLeftCTAComponentFor32nd({ taskId: item._id })
              : bottomLeftCTAComponentForOtherParking()
          }
          bottomRightCTA={
            isSupervisor
              ? bottomRightCTAComponentFor32nd({
                  phoneNumber: fullCustomerPhoneNumber,
                })
              : bottomRightCTAComponentForOtherParking()
          }
          swipeEnabled={isSupervisor && selectedIndex !== 0}
          // marginHorizontal="2xl"
          // eslint-disable-next-line no-alert
          onPressSwipeRight={onPressSwipeRight}
          onSwipeableOpen={() => {
            // setSwipeRightTaskOpened({ data: item, isSwipedRight: true });
          }}
          onSwipeableClose={() => {
            // setSwipeRightTaskOpened({ data: null });
          }}
          preExpanded={recallTaskOpened === item?._id}
          onCardPressCB={() => {
            if (recallTaskOpened !== item?._id) {
              setRecallTaskOpened({ id: item?._id });
            } else {
              setRecallTaskOpened({ id: '' });
            }
          }}
        />
      </View>
      <ReparkOptions
        bottomSheetModalRef={bottomSheetModalRef}
        handleOnDismiss={handleOnDismiss}
        onContinue={() => {
          setModalVisible(true);
        }}
      />
      <MarkExitConfirmation
        bottomSheetModalRef={MarkExitbottomSheetModalRef}
        handleOnDismiss={MarkEditConfirmationHandleDismiss}
        taskReadyToDelete={item}
        isSupervisor={isSupervisor}
      />
      <AssignReparkingConfirmation
        item={item}
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
      />
    </>
  );
};

export default RenderItem;
