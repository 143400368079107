import * as React from 'react';
import {
  RadioGroup,
  View,
  Text,
  Divider,
  Button,
} from '../../../components/new';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';

const tempData = [
  { name: 'Customer denied pick up' },
  { name: 'Customer is not coming' },
  {
    name: 'Customer is not reachable',
  },
  { name: 'Other reason' },
];
const ReparkOptions = ({
  bottomSheetModalRef,
  handleOnDismiss,
  onContinue,
}) => {
  const [data, setData] = React.useState(tempData);

  const anySelected = data.some((item) => item?.checked);
  return (
    <View>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Why do you want re-park this car?
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            <RadioGroup
              radio={data}
              setRadio={(arr) => setData(arr)}
              title=""
              isRequired
              size="lg"
              icon="right"
              collapsedCount={5}
            />
          </View>
          <View px="2xl" mt="xl">
            <Button
              size="lg"
              appearance="filled"
              state={anySelected ? '' : 'disabled'}
              onPress={() => {
                bottomSheetModalRef.current?.hide();
                onContinue();
              }}
            >
              Continue
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default ReparkOptions;
