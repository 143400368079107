/* eslint-disable no-nested-ternary */
import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import Barcode from 'react-native-barcode-svg';
import { ThemeConsumer } from 'styled-components/native';
import { ThemeProvider } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { Text, View, Icon } from '../index';
import { themeModes, getTheme } from '../../../themes/new/theme';
import { rewardTypeEnums } from '../../../screens/new/games/slotMachine/helpers/slotMachineHelpers';
import Congrats from '../../../../assets/lottie/light_lottie.json';
import useCommonStore from '../../../stores/commonStore';
import CustomLottieView from './CustomLottieView';

const ParkingCardNew = (props) => {
  const { rewardId, owner, rewardType, reward, showReedemedStatus } =
    props ?? {};
  const { width, height } = useWindowDimensions();
  const screenOrientationValue = useCommonStore((state) => state.orientation);

  const getFooterText = () => {
    if (rewardType === rewardTypeEnums.freeParking) {
      return 'Display this ticket at the exit gate to redeem your free parking';
    }
    if (rewardType === rewardTypeEnums.freeBubbleTea) {
      return 'Display this ticket at the Got Tea Cafe to claim your free bubble tea';
    }
    if (rewardType === rewardTypeEnums.freeFridgeMagnet) {
      return 'Display this ticket at the waiting lounge counter to claim your free fridge magnet';
    }
    if (rewardType === rewardTypeEnums.freeKeyChain) {
      return 'Display this ticket at the waiting lounge counter to claim your free key chain';
    }
    if (rewardType === rewardTypeEnums.freePostCard) {
      return 'Display this ticket at the waiting lounge counter to claim your free post card';
    }
    if (rewardType === rewardTypeEnums.freeSticker) {
      return 'Display this ticket at the waiting lounge counter to claim your free sticker';
    }
    return 'Display this ticket at the exit gate to redeem your free parking';
  };

  const getFullName = (firstname, lastname) =>
    `${firstname}${lastname !== null ? ` ${lastname}` : ''}`;
  const RenderContent = ({
    title1 = '',
    subtitle1 = '',
    title2 = '',
    subtitle2 = '',
  }) => (
    <>
      <View>
        <Text size="sm" color={getTheme(themeModes.light).colors.primary[200]}>
          {title1}
        </Text>
        <Text
          size="lg"
          color={getTheme(themeModes.light).colors.primary[400]}
          mt="sm"
        >
          {subtitle1}
        </Text>
      </View>
      <View>
        <View alignItems="flex-end">
          <Text
            size="sm"
            color={getTheme(themeModes.light).colors.primary[200]}
          >
            {title2}
          </Text>
        </View>
        <Text
          size="lg"
          color={getTheme(themeModes.light).colors.primary[400]}
          mt="sm"
        >
          {subtitle2}
        </Text>
      </View>
    </>
  );

  const pageContent = (theme) => (
    <>
      <View mt="4xl" alignItems="center">
        <Text color="primary.10" size="xl" weight="medium" numberOfLines={1}>
          {reward}
        </Text>
      </View>
      <View mt="4xl" flexDirection="row" alignItems="center">
        {/* <View
          height={sizes['2xs'] + sizes['2xs']}
          width={sizes['2xs'] + sizes['2xs']}
          borderRadius={sizes['2xs']}
          borderRightWidth="md"
          borderColor="primary.300"
          bg="singletone.fg"
          position="absolute"
          left={-15}
        /> */}
        <View height={2} px="sm" overflow="hidden" flex={1}>
          <View
            borderStyle="dashed"
            borderWidth="sm"
            borderColor={getTheme(themeModes.light).colors.primary[300]}
          />
        </View>
        {/* <View
          height={sizes['2xs'] + sizes['2xs']}
          width={sizes['2xs'] + sizes['2xs']}
          borderRadius={sizes['2xs']}
          borderLeftWidth="md"
          borderColor="primary.300"
          bg="#031208"
          position="absolute"
          right={-15}
        /> */}
      </View>
      {showReedemedStatus ? (
        <View mt="4xl" mb="9xl+9xl">
          <View mt="7xl" justifyContent="center" alignItems="center">
            {/* <View> */}
            <Icon name="check-filled-400" size="3xl+3xl" color="success.500" />
            <Text size="2xl" color="success.500" weight="medium">
              Offer Redeemed
            </Text>
            {/* </View> */}
          </View>
        </View>
      ) : (
        <>
          <View mt="7xl" alignItems="center">
            <Barcode
              format="CODE39"
              value={rewardId}
              singleBarWidth={3}
              maxWidth={
                width > 1200 && screenOrientationValue < 3
                  ? 375 - 3 * theme.space['4xl']
                  : width - 3 * theme.space['4xl']
              }
              height={60}
            />
            <Text size="xs" textAlign="center" color="primary.200">
              {rewardId}
            </Text>
          </View>
          <View mt="4xl" mb="4xl">
            <Text
              size="sm"
              color="primary.20"
              weight="medium"
              textAlign="center"
              px="2xl"
            >
              {getFooterText()}
            </Text>
          </View>
        </>
      )}
    </>
  );

  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider theme={getTheme(themeModes.light)}>
          <View
            mt="9xl"
            borderWidth="md"
            borderColor="primary.300"
            borderRadius="xl"
            width={
              width > 1200 && screenOrientationValue < 3
                ? 375 - 2 * theme.space['2xl']
                : width - 2 * theme.space['2xl']
            }
            zIndex={999}
            bg="gradient.50"
          >
            {Platform.OS === 'ios' ? (
              pageContent(theme)
            ) : (
              <BlurView style={{ borderRadius: 21 }} intensity={20}>
                {pageContent(theme)}
              </BlurView>
            )}
          </View>
          {/* <Image
            source={Contrats}
            style={{
              height: height / 1.7,
              width: 390,
              opacity: 0.15,
              position: 'absolute',
              alignSelf: 'center',
              top: 40,
            }}
          /> */}
          <View
            height={height / 1.7}
            opacity={0.25}
            position="absolute"
            alignSelf="center"
            top={40}
          >
            <CustomLottieView
              autoPlay
              loop
              source={Congrats}
              style={{ width: 390 }}
              containerWidth={390}
            />
          </View>
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

export default ParkingCardNew;
