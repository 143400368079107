import React, { memo } from 'react';
import { Image, Linking, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Icon, Pressable, Text, View } from '../../../../components/new';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';

const areEqual = () => {
  return true;
};

const InvestmentFileComponent = ({
  data,
  hidePadding = false,
  bgColor = '',
  propertyId,
}) => {
  const navigation = useNavigation();
  return (
    <View
      pt={hidePadding ? 0 : '9xl+9xl'}
      pb={hidePadding ? '2xl' : '9xl+9xl'}
      px="2xl"
      bg={bgColor}
    >
      {data?.heading ? (
        <Text size="xs" color="primary.300" pb="lg" textAlign="center">
          {data?.heading}
        </Text>
      ) : null}
      {data?.subHeading ? (
        <Text size="2xl" color="primary.500" textAlign="center">
          {data?.subHeading}
        </Text>
      ) : null}
      <View pt={hidePadding ? 0 : '9xl'}>
        {data?.data?.map((item) =>
          item?.link ? (
            <Pressable
              onPress={() => {
                firebaseEventLogger('PropertyDetails__downloadFile_Tap', {
                  buttonName: 'downloadFile',
                  screenName: 'PropertyDetails',
                  userType: 'user',
                  interactionType: 'tap',
                  propertyId,
                  fileType: item?.type,
                });
                if (Platform.OS === 'web') {
                  Linking.openURL(item?.link);
                } else {
                  navigation.navigate('CustomWebview', {
                    title: item?.name,
                    url: item?.link,
                    showLeft: true,
                  });
                }
              }}
              disabled={isValueNullOrEmpty(item?.link)}
              key={`InvestmentFileComponent_${item?._id}`}
            >
              <View
                flexDirection="row"
                mt="2xl"
                justifyContent="space-between"
                alignItems="center"
                key={`File_details${item?.key}`}
              >
                <View flexDirection="row">
                  <Image
                    source={
                      item?.type === 'PDF'
                        ? require('../../../../../assets/images/pdfFile.webp')
                        : require('../../../../../assets/images/file.webp')
                    }
                    style={{ height: 40, width: 40 }}
                  />
                  <View pl="2xl" justifyContent="center">
                    <Text size="md" color="primary.400">
                      {!isValueNullOrEmpty(item?.name) ? item?.name : 'File'}
                    </Text>
                    <Text size="xs" color="primary.200">
                      {item?.description}
                    </Text>
                  </View>
                </View>
                <Icon
                  name="download-outline-400"
                  color="primary.400"
                  size="2xl"
                />
              </View>
            </Pressable>
          ) : null,
        )}
      </View>
    </View>
  );
};

export default memo(InvestmentFileComponent, areEqual);
