/* eslint-disable import/prefer-default-export */
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../utilities/firbaseAnalytics';

type IParking = {
  _id: string;
  vehicle: {
    registration: {
      plate: string;
    };
  };
  owner: {
    _id: string;
    contact: {
      phone: {
        prefix: string;
        number: string;
      };
    };
  };
};
export type EventDataOutput = {
  parkingId: string;
  customerId: string;
  phone: string;
  vehicleNo: string;
};

const USER_TYPE = 'kiosk';

/**
 * ===============================
 *         Common Events
 * ===============================
 */

const logFirebaseTagScanInitiatedEvent = (screen: 'welcome' | 'scanTag') => {
  const eventName = `${screen}__scanInitiated_cardScan`;
  firebaseEventLogger(eventName, {
    buttonName: 'scanInitiated',
    screenName: screen,
    userType: USER_TYPE,
    interactionType: 'cardScan',
  });
};
const logFirebaseTagScanSuccessEvent = (
  screen: 'welcome' | 'scanTag',
  parking: IParking,
) => {
  const eventName = `${screen}__scanSuccess_Data`;
  const eventData = extractEventData(parking);
  firebaseEventLogger(eventName, {
    buttonName: 'scanSuccess',
    screenName: screen,
    userType: USER_TYPE,
    interactionType: InteractionType.DATA,
    ...eventData,
  });
};

const logFirebaseInvalidBarcodeEvent = (screen: 'welcome' | 'scanTag') => {
  const screenName = `${screen}__invalidBarcode_Data`;
  firebaseEventLogger(screenName, {
    buttonName: 'invalidBarcode',
    screenName: screen,
    userType: USER_TYPE,
    interactionType: InteractionType.DATA,
  });
};

const logFirebaseTagScanFailedEvent = (screen: 'welcome' | 'scanTag') => {
  const screenName = `${screen}__scanFailed_Data`;
  firebaseEventLogger(screenName, {
    buttonName: 'scanFailed',
    screenName: screen,
    userType: USER_TYPE,
    interactionType: InteractionType.DATA,
  });
};

/**
 * ========================================
 *         Screen specific events
 * ========================================
 */
const welcomeScreen = {
  scanBegan: (): void => {
    firebaseEventLogger('welcome__beginScan_Tap', {
      buttonName: 'beginScan',
      screenName: 'welcome',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
    });
  },
  scanInitiated: () => logFirebaseTagScanInitiatedEvent('welcome'),
  scanSuccessful: (parking: IParking) =>
    logFirebaseTagScanSuccessEvent('welcome', parking),
  scanFailed: () => logFirebaseTagScanFailedEvent('welcome'),
  invalidBarcodeScanned: () => logFirebaseInvalidBarcodeEvent('welcome'),
};

const scanTagScreen = {
  landed: () => {
    firebaseEventLogger('scanTag__land_Landing', {
      buttonName: 'land',
      screenName: 'scanTag',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
    });
  },
  scanInitiated: () => logFirebaseTagScanInitiatedEvent('scanTag'),
  scanSuccessful: (parking: IParking) =>
    logFirebaseTagScanSuccessEvent('scanTag', parking),
  scanFailed: () => logFirebaseTagScanFailedEvent('scanTag'),
  invalidBarcodeScanned: () => logFirebaseInvalidBarcodeEvent('scanTag'),
  backClicked: () => {
    firebaseEventLogger('scanTag__back_Tap', {
      buttonName: 'back',
      screenName: 'scanTag',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
    });
  },
};

const fetchingDetailsScreen = {
  landed: () => {
    firebaseEventLogger('fetchDetails__land_Landing', {
      buttonName: 'land',
      screenName: 'fetchDetails',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
    });
  },
  backClicked: () => {
    firebaseEventLogger('fetchDetails__back_Tap', {
      buttonName: 'back',
      screenName: 'fetchDetails',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
    });
  },
};

const reviewCarScreen = {
  landed: (parking: IParking) => {
    const eventData = extractEventData(parking);
    firebaseEventLogger('reviewCar__land_Landing', {
      buttonName: 'land',
      screenName: 'reviewCar',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
      ...eventData,
    });
  },
  sessionEnded: () => {
    firebaseEventLogger('reviewCar__endSession_Tap', {
      buttonName: 'endSession',
      screenName: 'reviewCar',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
    });
  },
  backClicked: (parking: IParking) => {
    const eventData = extractEventData(parking);
    firebaseEventLogger('reviewCar__back_Tap', {
      buttonName: 'back',
      screenName: 'reviewCar',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      ...eventData,
    });
  },
  proceedClicked: (parking: IParking) => {
    const eventData = extractEventData(parking);
    firebaseEventLogger('reviewCar__proceed_Tap', {
      buttonName: 'proceed',
      screenName: 'reviewCar',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      ...eventData,
    });
  },
};

const recallStatusScreen = {
  landed: (isFreshRecall: boolean, eventData: EventDataOutput) => {
    firebaseEventLogger('recallStatus__land_Landing', {
      buttonName: 'land',
      screenName: 'recallStatus',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
      freshRecall: isFreshRecall ? 'true' : 'false',
      ...eventData,
    });
  },
  backClicked: (isFreshRecall: boolean, eventData: EventDataOutput) => {
    firebaseEventLogger('recallStatus__back_Tap', {
      buttonName: 'back',
      screenName: 'recallStatus',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      freshRecall: isFreshRecall ? 'true' : 'false',
      ...eventData,
    });
  },
  sessionEnded: (isFreshRecall: boolean, eventData: EventDataOutput) => {
    firebaseEventLogger('recallStatus__endSession_Tap', {
      buttonName: 'endSession',
      screenName: 'recallStatus',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      freshRecall: isFreshRecall ? 'true' : 'false',
      ...eventData,
    });
  },
  joinClubClicked: (isFreshRecall: boolean, eventData: EventDataOutput) => {
    firebaseEventLogger('recallStatus__joinClub_Tap', {
      buttonName: 'joinClub',
      screenName: 'recallStatus',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      freshRecall: isFreshRecall ? 'true' : 'false',
      ...eventData,
    });
  },
};

const perksScreen = {
  landed: (eventData: EventDataOutput) => {
    firebaseEventLogger('perks__land_Landing', {
      buttonName: 'land',
      screenName: 'perks',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
      ...eventData,
    });
  },
  backClicked: (eventData: EventDataOutput) => {
    firebaseEventLogger('perks__back_Tap', {
      buttonName: 'back',
      screenName: 'perks',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      ...eventData,
    });
  },
  sessionEnded: (eventData: EventDataOutput) => {
    firebaseEventLogger('perks__endSession_Tap', {
      buttonName: 'endSession',
      screenName: 'perks',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      ...eventData,
    });
  },
};

const noCarFoundScreen = {
  landed: () => {
    firebaseEventLogger('noCarFound__land_Landing', {
      buttonName: 'land',
      screenName: 'noCarFound',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
    });
  },
  backClicked: () => {
    firebaseEventLogger('noCarFound__back_Tap', {
      buttonName: 'back',
      screenName: 'noCarFound',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
    });
  },
  sessionEnded: () => {
    firebaseEventLogger('noCarFound__endSession_Tap', {
      buttonName: 'endSession',
      screenName: 'noCarFound',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
    });
  },
};

const recallCarScreen = {
  landed: (parking: IParking) => {
    const eventData = extractEventData(parking);
    firebaseEventLogger('recallCar__land_Landing', {
      buttonName: 'land',
      screenName: 'recallCar',
      userType: USER_TYPE,
      interactionType: InteractionType.LANDING,
      ...eventData,
    });
  },
  backClicked: (parking: IParking) => {
    const eventData = extractEventData(parking);
    firebaseEventLogger('recallCar__back_Tap', {
      buttonName: 'back',
      screenName: 'recallCar',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      ...eventData,
    });
  },
  recallConfirmed: (parking: IParking) => {
    const eventData = extractEventData(parking);
    firebaseEventLogger('recallCar__confirmRecall_Tap', {
      buttonName: 'confirmRecall',
      screenName: 'recallCar',
      userType: USER_TYPE,
      interactionType: InteractionType.TAP,
      ...eventData,
    });
  },
  recallSuccessful: (parking: IParking) => {
    const eventData = extractEventData(parking);
    firebaseEventLogger('recallCar__recallSuccess_Data', {
      buttonName: 'recallSuccess',
      screenName: 'recallCar',
      userType: USER_TYPE,
      interactionType: InteractionType.DATA,
      ...eventData,
    });
  },
  recallFailed: (parking: IParking) => {
    const eventData = extractEventData(parking);
    firebaseEventLogger('recallCar__recallFailed_Data', {
      buttonName: 'recallFailed',
      screenName: 'recallCar',
      userType: USER_TYPE,
      interactionType: InteractionType.DATA,
      ...eventData,
    });
  },
};

export const analytics = {
  welcomeScreen,
  fetchingDetailsScreen,
  scanTagScreen,
  reviewCarScreen,
  recallStatusScreen,
  perksScreen,
  noCarFoundScreen,
  recallCarScreen,
};

export const extractEventData = (parking: IParking): EventDataOutput => {
  const { _id: parkingId, owner, vehicle } = parking ?? {};
  const { _id: customerId } = owner ?? {};
  const phone =
    (owner?.contact?.phone?.prefix ?? '') +
    (owner?.contact?.phone?.number ?? '');
  const vehicleNo = vehicle?.registration?.plate ?? '';

  return {
    parkingId,
    customerId,
    phone,
    vehicleNo,
  };
};
