import React, { useCallback, useEffect, useRef } from 'react';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { Keyboard } from 'react-native';
import { View, Avatar, Text, Pressable, Card, Button } from '..';
import { useTeamScreenStore } from '../../../stores/teamScreen/teamScreenStore';
import AutoBottomSheet from './AutoBottomSheet';
import { removeValet } from '../../../relay/userApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';

interface StatusTypes {
  text: string;
  color: string;
}

interface ValetCardProps {
  id: string;
  index: number;
  allowSwipeAction: boolean;
  imageLink: string;
  heading: string;
  subText: string;
  identityCode: string;
  onPress: null | (() => void);
  marginHorizontal: string;
  status: StatusTypes;
  contentRight: any;
  refresh: () => void;
}

const ValetCard = ({
  id = '',
  index,
  allowSwipeAction = false,
  imageLink = '',
  heading = '',
  subText = '',
  identityCode = '',
  onPress = () => {},
  marginHorizontal = '',
  status = {
    text: '',
    color: '',
  },
  contentRight = null,
  refresh = () => {},
}: ValetCardProps) => {
  const {
    currentSwipedCardIndex,
    updateCurrentSwipedCardIndex,
    resetCurrentSwipedCardIndex,
  } = useTeamScreenStore((state) => state);

  const SwipeableRef = useRef(null);

  const bottomSheetModalRef = useRef(null);

  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  useEffect(() => {
    if (index !== currentSwipedCardIndex) {
      SwipeableRef?.current?.close();
    }
  }, [currentSwipedCardIndex]);

  const handleWillSwipe = () => {
    if (currentSwipedCardIndex !== index) {
      updateCurrentSwipedCardIndex({ currentSwipedCardIndex: index });
    }
  };

  const showModal = useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);

  const handleCloseBottomSheet = useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  const RightSwipedComp = () => {
    if (!allowSwipeAction) {
      return null;
    }
    return (
      <View
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
        backgroundColor="error.500"
        px="2xl"
        justifyConent="center"
        alignItems="center"
      >
        <Pressable
          onPress={() => {
            Keyboard.dismiss();
            showModal();
          }}
          style={{ flex: 1 }}
        >
          <View flex={1} justifyContent="center" alignItems="center">
            <Text size="md" color="primary.10">
              Delete
            </Text>
          </View>
        </Pressable>
      </View>
    );
  };

  const handleRemoveAccount = (i) => {
    handleCloseBottomSheet();
    removeValet(i)
      .then(() => {
        resetCurrentSwipedCardIndex();
        refresh();
        dispatchSnackbar({
          msg: 'Successfully Deleted',
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
        });
      })
      .catch((error) => {
        dispatchSnackbar({
          msg: 'Oops! Something went wrong!😓',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
      });
  };

  return (
    <>
      <Card
        category="none"
        borderColor="primary.50"
        borderWidth="xs"
        mx={marginHorizontal}
      >
        <View borderRadius="md" overflow="hidden">
          <Swipeable
            ref={SwipeableRef}
            renderRightActions={RightSwipedComp}
            overshootRight={false}
            overshootFriction={0}
            containerStyle={{ zIndex: 1, overflow: 'hidden' }}
            childrenContainerStyle={{ zIndex: 1, overflow: 'hidden' }}
            onSwipeableWillOpen={handleWillSwipe}
          >
            <Pressable onPress={onPress}>
              <View
                px="2xl"
                py="2xl"
                bg="background.primary.elevation"
                borderRadius="md"
              >
                <View
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <View flexDirection="row" alignItems="center">
                    <Avatar level={1} source={imageLink} name={identityCode} />
                    <View ml="2xl">
                      <View flexDirection="row" alignItems="center">
                        <Text size="md" color="primary.500">
                          {heading}
                        </Text>
                        <View
                          justifyConent="center"
                          alignItems="center"
                          flex={1}
                          ml="lg"
                        >
                          <View
                            width="3xs"
                            height="3xs"
                            bg={status?.color}
                            borderRadius="full"
                          />
                        </View>
                      </View>
                      <View flexDirection="row" alignItems="center">
                        <Text size="xs" color="primary.100">
                          {subText}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {contentRight}
                </View>
              </View>
            </Pressable>
          </Swipeable>
        </View>
      </Card>
      <AutoBottomSheet
        bottomSheetRef={bottomSheetModalRef}
        handleClose={handleCloseBottomSheet}
      >
        <View bg="primary.10" px="2xl">
          <Text pt="xs" mb="3xl" size="xl" color="primary.200">
            {`Are you sure you want to delete ${heading} permanently?`}
          </Text>
          <View mb="2xl">
            <Button size="lg" onPress={() => handleRemoveAccount(id)}>
              {`Yes, Delete ${subText}`}
            </Button>
          </View>
          <View mb="2xl">
            <Button
              size="lg"
              appearance="outline"
              onPress={handleCloseBottomSheet}
            >
              Cancel
            </Button>
          </View>
        </View>
      </AutoBottomSheet>
    </>
  );
};

export default ValetCard;
