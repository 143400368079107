/**
 * @generated SignedSource<<dff224789c6f1622ced58481446dc848>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumbillUploadStatus = "CREATED" | "MATCHED" | "PROCESSED" | "PROCESSING" | "PROCESSING_FAILED" | "UNMATCHED" | "UNVERIFIED" | "VERIFICATION_FAILED" | "VERIFIED" | "%future added value";
export type updateBillInput = {
  approvalNotes: UpdateByIdbillUploadApprovalNotesInput;
  status?: EnumbillUploadStatus | null;
};
export type UpdateByIdbillUploadApprovalNotesInput = {
  data?: UpdateByIdbillUploadApprovalNotesDataInput | null;
  message?: string | null;
};
export type UpdateByIdbillUploadApprovalNotesDataInput = {
  _id?: any | null;
  bill?: UpdateByIdbillUploadApprovalNotesDataBillInput | null;
};
export type UpdateByIdbillUploadApprovalNotesDataBillInput = {
  billId?: string | null;
};
export type billUploadReviewerApiUpdateBillMutation$variables = {
  id: any;
  record: updateBillInput;
};
export type billUploadReviewerApiUpdateBillMutation$data = {
  readonly updateBill: {
    readonly record: {
      readonly userId: any;
    } | null;
  } | null;
};
export type billUploadReviewerApiUpdateBillMutation = {
  response: billUploadReviewerApiUpdateBillMutation$data;
  variables: billUploadReviewerApiUpdateBillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdbillUploadPayload",
    "kind": "LinkedField",
    "name": "updateBill",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "billUpload",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "billUploadReviewerApiUpdateBillMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "billUploadReviewerApiUpdateBillMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f01057bed56dd67042f7ce720c18457f",
    "id": null,
    "metadata": {},
    "name": "billUploadReviewerApiUpdateBillMutation",
    "operationKind": "mutation",
    "text": "mutation billUploadReviewerApiUpdateBillMutation(\n  $id: MongoID!\n  $record: updateBillInput!\n) {\n  updateBill(_id: $id, record: $record) {\n    record {\n      userId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5788639df7cb62747ff9f665fed5d6e0";

export default node;
