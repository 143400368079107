/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const auditorDayWiseCollectionQuery = graphql`
  query auditorDayWiseCollectionQuery(
    $collectionFilter: getPopupOrderCollectionsFilter
    $ordersFilter: findpaymentOrderfilterInput
    $sort: [PaymentOrderHistorySortEnum]
    $count: Int!
    $cursor: String
  ) {
    getPopupOrderCollections(filter: $collectionFilter, limit: 1) {
      date
      count
      cashTotal
    }
    ...auditorHomeScreenQuery__paymentHistoryFragmentV2
      @arguments(
        filter: $ordersFilter
        sort: $sort
        count: $count
        cursor: $cursor
      )
  }
`;

export const paymentHistoryFragment = graphql`
  fragment auditorDayWiseCollectionQuery__paymentHistoryFragment on invoice
  @relay(plural: true) {
    _id
    status
    priceDetails {
      grossAmount
    }
    user {
      _id
      contact {
        phone {
          prefix
          number
        }
      }
    }
    paymentType
    orderType
    orderId
    popUpStoreDetails {
      deliveryStatus
      displayName
    }
    sourceDetails {
      popUpStore {
        owner {
          name {
            first
            last
          }
        }
      }
    }
    created_at
    paymentDetails {
      razorpay {
        pgOrderId
      }
      paytmQr {
        transactionId
      }
    }
  }
`;
