/**
 * @generated SignedSource<<a4dd2c396b3f8e2815d708a5dc9d0635>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumrestaurantDiscountDays = "Weekday" | "Weekend" | "%future added value";
export type EnumrestaurantDiscountMealTime = "dinner" | "lunch" | "%future added value";
export type restaurantpartnerDiscountrecordInput = {
  days: EnumrestaurantDiscountDays;
  discounts: restaurantDiscountDiscountsInput;
  mealTime: EnumrestaurantDiscountMealTime;
};
export type restaurantDiscountDiscountsInput = {
  _id?: any | null;
  allUser: number;
  corporateUser: number;
  newUser: number;
};
export type discountApiMutation$variables = {
  record: restaurantpartnerDiscountrecordInput;
};
export type discountApiMutation$data = {
  readonly addRestaurantpartnerDiscount: {
    readonly record: {
      readonly _id: any;
      readonly days: EnumrestaurantDiscountDays;
      readonly discounts: {
        readonly allUser: number;
        readonly corporateUser: number;
        readonly newUser: number;
      };
      readonly isActive: boolean | null;
      readonly mealTime: EnumrestaurantDiscountMealTime;
      readonly restaurantId: any;
    } | null;
  } | null;
};
export type discountApiMutation = {
  response: discountApiMutation$data;
  variables: discountApiMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOnerestaurantDiscountPayload",
    "kind": "LinkedField",
    "name": "addRestaurantpartnerDiscount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "restaurantDiscount",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "restaurantId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurantDiscountDiscounts",
            "kind": "LinkedField",
            "name": "discounts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "corporateUser",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "days",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mealTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "discountApiMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "discountApiMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "821c59ac42231fad65984564c39124cf",
    "id": null,
    "metadata": {},
    "name": "discountApiMutation",
    "operationKind": "mutation",
    "text": "mutation discountApiMutation(\n  $record: restaurantpartnerDiscountrecordInput!\n) {\n  addRestaurantpartnerDiscount(record: $record) {\n    record {\n      restaurantId\n      discounts {\n        allUser\n        newUser\n        corporateUser\n      }\n      days\n      mealTime\n      isActive\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b44cb87e1fac4d8e13058adfede8b348";

export default node;
