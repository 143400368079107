import React from 'react';
import BottomSheetV2 from '../composites/BottomSheet/BottomSheetV2';
import View from '../basic/View/View';

const AutoBottomSheet = ({
  children,
  bottomSheetRef,
  handleOpen = () => {},
  handleClose = () => {},
  ...props
}) => {
  return (
    <BottomSheetV2 ref={bottomSheetRef} onClose={handleClose} {...props}>
      <View>{children}</View>
    </BottomSheetV2>
  );
};

export default AutoBottomSheet;
