import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface IRestaurantPartnerStore {
  restaurantId: string;
  setRestaurantId: (restaurantId: string) => void;
  restaurantName: string;
  setRestaurantName: (restaurantName: string) => void;
  isLoyaltyEnabled: boolean;
  setLoyalty: (loyaltyEnabled: boolean) => void;
  loyalCustomerLength: number;
  setLoyalCustomerLength: (loyalCustomerLength: number) => void;
}

const useRestaurantPartnerStore = create<IRestaurantPartnerStore>()(
  devtools(
    persist(
      (set) => ({
        restaurantId: '',
        isLoyaltyEnabled: false,
        restaurantName: '',
        loyalCustomerLength: 0,
        setRestaurantId: (restaurantId: string) =>
          set(() => ({ restaurantId })),
        setRestaurantName: (restaurantName: string) =>
          set(() => ({ restaurantName })),
        setLoyalty: (loyaltyEnabled: boolean) => {
          set(() => {
            return { isLoyaltyEnabled: loyaltyEnabled };
          });
        },
        setLoyalCustomerLength: (loyalCustomerLength: number) => {
          set(() => {
            return { loyalCustomerLength };
          });
        },
      }),
      {
        name: 'RestaurantPartnerStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'RestaurantPartnerStore',
    },
  ),
);

export default useRestaurantPartnerStore;
