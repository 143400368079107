/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { setBackgroundColorAsync } from 'expo-navigation-bar';
import { StatusBar } from 'expo-status-bar';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Platform } from 'react-native';
import { OneSignal } from 'react-native-onesignal';
import BottomTabNavigator from './bottomTabNavigator';
import ValetHomeTabs from './valetBottomTabNavigator';
import ValetSupervisorHomeTabs from './valetSupervisorBottomTabNavigator';

import Loading from '../components/loading.component';
import {
  setFirbaseUserProperties,
  setCrashlyticsCollectionEnabled,
} from '../utilities/firbaseAnalytics';
import AuthBottomSheetModal from '../components/modals/AuthBottomSheetModal';
import FeedbackBottomSheetModal from '../components/modals/FeedbackBottomSheetModal';
import useCommonStore, { AppTheme } from '../stores/commonStore';
import useUserStore, { UserRole } from '../stores/userStore';
import KioskNavigator from './kioskNavigator';
import findCampusLocation from '../relay/campusLocationApi';
import { navigationRef } from './RootNavigation';
import useBoundStore from '../stores';
import useCampusLocation from '../stores/campusLocation';
import { getTheme } from '../themes/new/theme';
import NotificationPermissionModal from '../components/modals/NotificationPermissionModal';
import NavigationConfig from './NavigationConfig';
import CashierTab from './CashierTabNavigator';
import useAuthStore from '../stores/authStore';
import ClubMembershipSheetModal from '../components/modals/ClubMembershipSheetModal';
import PartnerTabs from './partnerBottomTabNavigator';
import AuditorTabs from './auditorBottomTabNavigator';
import UpdateModel from '../components/new/custom/updateApp/UpdateModel';
import PhotoMomentsStaffNavigator from './photoMomentsStaffNavigator';
import BottomNudges from '../components/new/custom/BottomNudges';
import fetchParkingLocationsQuery from '../screens/valetSupervisor/API/parkingLocationQuery';
import RestaurantPartnerBottomTabNavigator from '../navigation/restaurantPartnerBottomTabNavigator';
import CampusStaffNavigator from './campusStaffNavigator';
import PhotoMomentsPartnerNavigator from './photoMomentsPartnerNavigator';
import { useBlackMembershipHelper } from '../hooks/useBlackMembershipHelper';
import ClubMemberBottomSheet from '../components/new/custom/ClubMemberBottomSheet';
import MomentPartnerTabs from './momentsPartnerBottomTabNavigator';
import BillUploadReviewerTabs from './billUploadReviewerTabNavigator';

const AppNavigator = () => {
  const appTheme = useCommonStore((state) => state.theme);
  const StatusBarStyle =
    appTheme === AppTheme.LIGHT ? AppTheme.DARK : AppTheme.LIGHT;

  // function to set background color of the bottom navigation bar with matching theme
  const setNavigationBarBackgroundColor = () => {
    if (Platform.OS === 'android' && appTheme) {
      setBackgroundColorAsync(
        getTheme(appTheme).colors.background.primary.base,
      );
    }
  };

  // everytime theme changed then we set background color of bottom navigation bar
  useEffect(() => {
    setNavigationBarBackgroundColor();
  }, [appTheme]);

  const commonStore = useCommonStore((state) => state);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const campusLocationStore = useCampusLocation((state) => state);
  const [isCampusLoaded, setIsCampusLoaded] = useState<boolean>(false);
  /** just to fetch membership status and store it in `userStore` everytime app loads */
  useBlackMembershipHelper();

  useEffect(() => {
    (async () => {
      try {
        let campusId = commonStore?.campusId;
        if (!commonStore.campusId) {
          const res = await findCampusLocation({});
          campusId = res?.findCampusLocation[0]?._id;
          commonStore.setCampusId(campusId);
          commonStore.setCampusDetails(res?.findCampusLocation[0]);
        }
        setIsCampusLoaded(true);

        if (
          !campusLocationStore?.campusLocations ||
          campusLocationStore.campusLocations.length === 0
        ) {
          const resTwo = await fetchParkingLocationsQuery({
            campus: { _id: campusId },
          });
          const parkingLocations = resTwo?.findParkingLocation;
          let modifiedParkingLocations = JSON.parse(
            JSON.stringify(parkingLocations),
          );

          modifiedParkingLocations = modifiedParkingLocations.map(
            (item, index) => {
              if (index === 0) {
                item.checked = true;
              } else {
                item.checked = false;
              }
              return item;
            },
          );
          campusLocationStore.setCampusLocations({
            data: modifiedParkingLocations,
            initial: true,
          });
        }
      } catch (err) {
        console.log(err);
        setIsCampusLoaded(true);
      }
    })();
  }, []);

  const userStore = useUserStore((state) => state);

  useEffect(() => {
    (async () => {
      await initFirebaseSDK();
      if (isLoggedIn) {
        if (Platform.OS !== 'web') {
          OneSignal.login(userStore.id);
        }
      }
    })();
  }, [isLoggedIn]);

  const initFirebaseSDK = async () => {
    await setFirbaseUserProperties({ role: userStore.role });
    await setCrashlyticsCollectionEnabled();
  };

  if (!isCampusLoaded) {
    return <Loading />;
  }

  return (
    <NavigationContainer ref={navigationRef} linking={NavigationConfig}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        {(userStore.role === UserRole.USER || !isLoggedIn) && (
          <BottomTabNavigator />
        )}
        {userStore.role === UserRole.VALET && isLoggedIn && <ValetHomeTabs />}
        {userStore.role === UserRole.VALET_SUPERVISOR && isLoggedIn && (
          <ValetSupervisorHomeTabs />
        )}
        {userStore.role === UserRole.CONTROLLER && isLoggedIn && (
          <ValetSupervisorHomeTabs />
        )}
        {userStore.role === UserRole.KIOSK && isLoggedIn && <KioskNavigator />}
        {userStore.role === UserRole.CASHIER && isLoggedIn && <CashierTab />}
        {userStore.role === UserRole.PARTNER && isLoggedIn && <PartnerTabs />}
        {userStore.role === UserRole.BILL_UPLOAD_REVIEWER && isLoggedIn && (
          <BillUploadReviewerTabs />
        )}
        {userStore.role === UserRole.AUDITOR && isLoggedIn && <AuditorTabs />}
        {userStore.role === UserRole.PHOTO_MOMENTS_STAFF && isLoggedIn && (
          <PhotoMomentsStaffNavigator />
        )}
        {userStore.role === UserRole.MOMENT_STAFF_PARTNER && isLoggedIn && (
          <MomentPartnerTabs />
        )}
        {userStore.role === UserRole.CAMPUS_STAFF && isLoggedIn && (
          <CampusStaffNavigator />
        )}
        {(userStore.role === UserRole.RESTAURANT_PARTNER ||
          userStore.role === UserRole.RESTAURANT_PARTNER_MOD ||
          userStore.role === UserRole.RESTAURANT_PARTNER_STAFF) &&
          isLoggedIn && <RestaurantPartnerBottomTabNavigator />}
        {userStore.role === UserRole.PHOTO_MOMENTS_PARTNER && isLoggedIn && (
          <PhotoMomentsPartnerNavigator />
        )}
        <BottomNudges />
        <UpdateModel />
        <ClubMemberBottomSheet />
        <StatusBar style={StatusBarStyle} />
        <AuthBottomSheetModal />
        <FeedbackBottomSheetModal />
        <NotificationPermissionModal />
        <ClubMembershipSheetModal />
      </GestureHandlerRootView>
    </NavigationContainer>
  );
};

export default AppNavigator;
