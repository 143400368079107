/* eslint-disable camelcase */

import {
  MediaGalleryTypes,
  RestaurantCollectionCategory,
} from '../../../utilities/helper';
import { homeScreenQuery_findRestaurantsFragment$data } from './API/__generated__/homeScreenQuery_findRestaurantsFragment.graphql';

export interface RestaurantCollectionViewData {
  id: string;
  title: string;
  rating: number;
  tags: { name: string }[];
  image: string;
  video: string;
}

export interface RestaurantOffersViewData {
  id: string;
  restaurantName: string;
  coverImage: string;
  cashback: number;
  discount: number;
}

export interface RestaurantCollectionViewInput {
  _id: string;
  displayName: string;
  overAllRating: number;
  description: {
    cusines: {
      name: string;
    }[];
  };
  mediaGallery: {
    sectionTitle: string;
    media: {
      cover: boolean;
      mediaType: string;
      mediaURL: string;
    }[];
  }[];
}

const extractCoverImage = (
  restaurant: Pick<RestaurantCollectionViewInput, 'mediaGallery'>,
) => {
  const { mediaGallery } = restaurant ?? {};

  try {
    const coverImageMedia =
      mediaGallery &&
      mediaGallery?.length &&
      mediaGallery.filter(
        (mg) => mg?.sectionTitle === MediaGalleryTypes.AMBIENCE,
      )[0]?.media;

    if (coverImageMedia && coverImageMedia?.length) {
      return coverImageMedia.filter(
        (cm) => cm?.cover && cm?.mediaType === 'image',
      )[0]?.mediaURL;
    }
    return '';
  } catch (e) {
    return '';
  }
};

const extractCoverVideo = (restaurant: RestaurantCollectionViewInput) => {
  const { mediaGallery } = restaurant ?? {};

  try {
    const coverVideoMedia =
      mediaGallery &&
      mediaGallery?.length &&
      mediaGallery.filter(
        (mg) => mg?.sectionTitle === MediaGalleryTypes.FOUNDER_STORY,
      )[0]?.media;

    if (coverVideoMedia && coverVideoMedia?.length) {
      return coverVideoMedia.filter((cm) => cm?.mediaType === 'video')[0]
        ?.mediaURL;
    }
    return '';
  } catch (e) {
    return '';
  }
};

export const buildRestaurantCollectionViewData = (
  restaurantCollection: RestaurantCollectionViewInput[],
): RestaurantCollectionViewData[] => {
  if (restaurantCollection instanceof Array) {
    return restaurantCollection.map(
      (restaurant: RestaurantCollectionViewInput) => {
        const { displayName, overAllRating, description } = restaurant ?? {};

        const coverImageUrl = extractCoverImage(restaurant);
        const coverVideoUrl = extractCoverVideo(restaurant); // founders video

        return {
          id: restaurant?._id,
          title: displayName,
          rating: overAllRating ?? 0,
          tags: description?.cusines.map((c) => ({ name: c?.name })),
          image: coverImageUrl,
          video: coverVideoUrl,
        };
      },
    );
  }
  return [];
};

export const getCollectionNavigationCategory = (
  category: RestaurantCollectionCategory,
): string => {
  switch (category) {
    case RestaurantCollectionCategory.DATE_NIGHT:
      return 'Date Night';
    case RestaurantCollectionCategory.OUTDOOR_SEATING:
      return 'outdoor seating';
    case RestaurantCollectionCategory.FINE_DINING:
      return 'Fine Dining';
    case RestaurantCollectionCategory.CAFES:
      return 'Cafes';
    case RestaurantCollectionCategory.SERVES_ALCOHOL:
      return 'serves alcohol';
    case RestaurantCollectionCategory.RECOMMENDED:
    case RestaurantCollectionCategory.UNIQUE_DINEIN:
      return '';
    default:
      return '';
  }
};

export const buildRestaurantOfferViewData = (
  restaurants: homeScreenQuery_findRestaurantsFragment$data[],
): RestaurantOffersViewData[] => {
  if (restaurants instanceof Array) {
    return restaurants.map(
      (restaurant: homeScreenQuery_findRestaurantsFragment$data) => {
        const { displayName } = restaurant ?? {};

        const coverImageUrl = extractCoverImage(restaurant as any);

        return {
          id: restaurant?._id,
          restaurantName: displayName,
          coverImage: coverImageUrl,
          cashback: restaurant?.payBillCashback,
          discount: restaurant?.payBillPercentage,
        };
      },
    );
  }
};
