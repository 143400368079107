/**
 * @generated SignedSource<<a5391129235077829dd768fcf710fbde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumvaletWorkLogWorkLogHistoryType = "break_end" | "break_start" | "punch_in" | "punch_out" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type profileCardQueryFragment_findWorkLogFragment_Query$data = {
  readonly workLogHistory: ReadonlyArray<{
    readonly time: any;
    readonly type: EnumvaletWorkLogWorkLogHistoryType | null;
  } | null> | null;
  readonly workSummary: {
    readonly tasksDone: number | null;
  } | null;
  readonly " $fragmentType": "profileCardQueryFragment_findWorkLogFragment_Query";
};
export type profileCardQueryFragment_findWorkLogFragment_Query$key = {
  readonly " $data"?: profileCardQueryFragment_findWorkLogFragment_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"profileCardQueryFragment_findWorkLogFragment_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileCardQueryFragment_findWorkLogFragment_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "valetWorkLogWorkLogHistory",
      "kind": "LinkedField",
      "name": "workLogHistory",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "valetWorkLogWorkSummary",
      "kind": "LinkedField",
      "name": "workSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tasksDone",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "valetWorkLog",
  "abstractKey": null
};

(node as any).hash = "8252e25c498f23413b1383c37d6c372f";

export default node;
