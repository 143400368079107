/* eslint-disable react/destructuring-assignment */
import React, { forwardRef } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  ChipGroup,
  IconButton,
  TopNavigation,
  View,
} from '../../../components/new';
import { applyEllipsis, navigateBack } from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const MenuScreenHeader = (
  {
    title,
    selectedIndex,
    setSelectedIndex,
    navigation,
    headersData,
    newMenuData,
  },
  ref,
) => {
  const insets = useSafeAreaInsets();
  const userRole = useUserStore((state) => state.role);

  return (
    <TopNavigation
      title={applyEllipsis(title, 25)}
      appearance="ghost"
      level="1"
      IconLeft={
        <IconButton
          name="back-outline-300"
          size="md"
          appearance="ghost"
          iconColor="primary.500"
          onPress={() => navigateBack(navigation, userRole)}
        />
      }
      ChildrenElement={
        <View pb="xl">
          <ChipGroup
            data={headersData}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            maxLength={40}
            callbackFn={(item) => {
              firebaseEventLogger('DigitalMenuFilterPress', {
                name: item.sectionTitle,
              });
              // setSelectedIndex(index);
              const index = newMenuData.findIndex(
                (ele) => ele._id === item._id,
              );
              setTimeout(() => {
                ref.current?.scrollToIndex({
                  animated: true,
                  index,
                });
              }, 100);
            }}
          />
        </View>
      }
    />
  );
};

export default forwardRef(MenuScreenHeader);
