import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface RedirectPayload {
  screenName: string;
  params: object;
}
export interface AuthStoreInterface {
  isLoggedIn: boolean;
  authToken: string;
  refreshToken: string;
  redirectUri: RedirectPayload;
  setRedirectUri: (redirectUri: RedirectPayload) => void;
  setLoginDetails: (
    isLoggedIn: boolean,
    authToken: string,
    refreshToken: string,
  ) => void;
}

const useAuthStore = create<AuthStoreInterface>()(
  devtools(
    persist(
      (set) => ({
        isLoggedIn: false,
        authToken: '',
        refreshToken: '',
        redirectUri: { screenName: '', params: {} },
        setRedirectUri: (redirectUri: RedirectPayload) =>
          set((state) => ({
            redirectUri,
          })),
        setLoginDetails: (
          isLoggedIn: boolean,
          authToken: string,
          refreshToken: string,
        ) =>
          set(() => ({
            isLoggedIn,
            authToken,
            refreshToken,
          })),
      }),
      {
        name: 'AuthStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'AuthStore',
    },
  ),
);

// useAuthStore.subscribe((state) => {
//   console.log(
//     '=====================================================================\n',
//     state,
//     '\n=====================================================================\n',
//   );
// });

export default useAuthStore;
