/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const paymentScreenQuery = graphql`
  query paymentScreenQuery(
    $amount: Float
    $useRewardPoints: Boolean!
    $orderId: ID!
    $orderType: Referer!
  ) {
    getNetPrice(
      amount: $amount
      useRewardPoints: $useRewardPoints
      orderId: $orderId
      orderType: $orderType
    ) {
      priceDetails {
        grossAmount
        discountGiven
        rewardsUsed
        netAmount
        discountPercentage
      }
      sourceDetails {
        restaurant {
          displayName
          payBill {
            payEnabled
          }
        }
        event {
          title
        }
        store {
          displayName
        }
        popUpStore {
          displayName
        }
      }
      discountPercentage
      cashbackPercentage
      cashbackAmount
    }
    findConfig {
      billUploadConfig {
        cashbackPercentage
      }
    }
  }
`;
