import React from 'react';
import { FlatList, FlatListProps, Platform } from 'react-native';
import Animated from 'react-native-reanimated';

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const CustomFlatList = <ItemT,>(props: FlatListProps<ItemT>) => {
  // Using this hack for support on web

  if (Platform.OS === 'web') {
    return <AnimatedFlatList {...props}>{props.children}</AnimatedFlatList>;
  }
  return <FlatList {...props}>{props.children}</FlatList>;
};

export default CustomFlatList;
