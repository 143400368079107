/* eslint-disable no-extra-boolean-cast */
import React, { useRef } from 'react';
import { useWindowDimensions, TouchableOpacity, FlatList } from 'react-native';
import { Image } from 'expo-image';
import { Text, View, Icon, Pressable } from '../../components/new';
import {
  DateFormatsEnum,
  getFormatedDate,
  imageTransformation,
} from '../../utilities/helper';
import { space } from '../../themes/new/theme';

const _renderItem = ({
  item,
  processLongPress,
  processPress,
  collectionName,
  data,
}) => {
  const { width } = useWindowDimensions();

  return item?.assetLink ? (
    <TouchableOpacity
      style={{
        width: (width - 32) / 4,
        height: (width - 32) / 4,
      }}
      delayLongPress={1700}
      onLongPress={() => processLongPress(item)}
      onPress={() =>
        processPress({
          picture: item,
          list: collectionName === 'Favorites' ? data : null,
        })
      }
    >
      <Image
        style={[
          {
            width: (width - 32) / 4 - 3,
            height: (width - 32) / 4 - 3,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2,
          },
        ]}
        source={imageTransformation(
          item?.assetType === 'IMAGE'
            ? `https://ik.imagekit.io/32ndhub/photoMoment/${item?.assetId}`
            : `https://ik.imagekit.io/32ndhub/videoMomentsThumbnail/${item?.thumbnailId}`,
          (width - 32) / 4,
          '1-1',
        )}
      />
      {item?.assetType === 'VIDEO' ? (
        <View
          position="absolute"
          top={((width - 32) / 4 - 3) / 2 - space['2xl']}
          left={((width - 32) / 4 - 3) / 2 - space['2xl']}
          zIndex={100}
        >
          <Icon size="2xl" name="play-filled-300" color="static.white" />
        </View>
      ) : null}
      {item?.isSelected ? (
        <View
          width={(width - 32) / 4 - 3}
          height={(width - 32) / 4 - 3}
          backgroundColor="rgba(0,0,0,0.5)"
          position="absolute"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Icon size="3xl" name="check-filled-400" color="static.white" />
        </View>
      ) : null}
      <View
        width={(width - 32) / 4 - 3}
        height={(width - 32) / 4 - 3}
        backgroundColor="rgba(0, 0, 0, 0.5)"
        position="absolute"
        justifyContent="center"
        alignItems="center"
        zIndex={1}
      >
        <Text>{item?.assetType === 'IMAGE' ? 'IMG' : 'VID'}</Text>
      </View>
    </TouchableOpacity>
  ) : null;
};

const MemoizedItem = React.memo(_renderItem);

const PhotoContainer = ({
  data,
  firstItem = false,
  processLongPress,
  processPress,
  collectionName,
  isMultiSelected = false,
  onCancel,
}) => {
  const ref = useRef();
  const currentDate = new Date();
  const checkTitle = () => {
    const res =
      // eslint-disable-next-line no-nested-ternary
      collectionName === 'Favorites'
        ? 'Favorites'
        : collectionName ===
          getFormatedDate(currentDate, DateFormatsEnum.DateMonthYear)
        ? 'Today'
        : getFormatedDate(
            data?.[0]?.clickedOn,
            DateFormatsEnum.DayDateMonthYear,
          );
    return res;
  };

  return (
    <View mb="4xl">
      <View
        flexDirection="row"
        mb="4xl"
        justifyContent="space-between"
        mt={firstItem ? '4xl' : 'xs'}
      >
        <Text color="primary.300" fontSize="lg">
          {checkTitle()}
        </Text>
        {firstItem && isMultiSelected ? (
          <Pressable
            onPress={onCancel}
            style={{
              height: space['9xl'],
              width: space['9xl+3xl'],
              position: 'absolute',
              right: 0,
            }}
          >
            <View
              justifyContent="center"
              borderColor="primary.300"
              borderWidth={1}
              px={6}
              py={2}
              borderRadius={12}
              backgroundColor="primary.300"
            >
              <Text color="primary.10" fontSize="sm">
                Cancel
              </Text>
            </View>
          </Pressable>
        ) : null}
      </View>
      <FlatList
        ref={ref}
        data={data}
        numColumns={4}
        renderItem={({ item }) => {
          return (
            <MemoizedItem
              data={data}
              item={item}
              processLongPress={processLongPress}
              processPress={processPress}
              collectionName={collectionName}
            />
          );
        }}
      />
    </View>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps.data) !== JSON.stringify(nextProps.data)) {
    return false;
  }
  return true;
};

export default React.memo(PhotoContainer, areEqual);
