/**
 * @generated SignedSource<<54c0eebb119fa8c551ed29555a6c45d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type cashierParkingUpdatedSubscription$variables = {
  parkingId: string;
  userId: string;
};
export type cashierParkingUpdatedSubscription$data = {
  readonly parkingUpdated: {
    readonly _id: any;
    readonly parkingInvoice: {
      readonly paymentDetails: {
        readonly amount: number | null;
        readonly isPaid: boolean;
      } | null;
    } | null;
  } | null;
};
export type cashierParkingUpdatedSubscription = {
  response: cashierParkingUpdatedSubscription$data;
  variables: cashierParkingUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "parkingId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "parkingId",
        "variableName": "parkingId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "Parking",
    "kind": "LinkedField",
    "name": "parkingUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ParkingParkingInvoice",
        "kind": "LinkedField",
        "name": "parkingInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ParkingParkingInvoicePaymentDetails",
            "kind": "LinkedField",
            "name": "paymentDetails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPaid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cashierParkingUpdatedSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cashierParkingUpdatedSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4cf6f500b692d7e299f7bfefc09cbe81",
    "id": null,
    "metadata": {},
    "name": "cashierParkingUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription cashierParkingUpdatedSubscription(\n  $parkingId: ID!\n  $userId: ID!\n) {\n  parkingUpdated(parkingId: $parkingId, userId: $userId) {\n    _id\n    parkingInvoice {\n      paymentDetails {\n        isPaid\n        amount\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84f6f70a665ce25638ecebd6e9196c62";

export default node;
