import { graphql, requestSubscription } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

const surveySubscriptionSubscription = graphql`
  subscription surveySubscriptionSubscription($userId: ID!, $formId: ID!) {
    surveySubmission(userId: $userId, formId: $formId) {
      form {
        eventId
        formId
        hidden
        landedAt
        submittedAt
      }
    }
  }
`;

const surveySubscriptionAPI = (data, callBack, cancelSubscription) => {
  const subscriptionConfig = {
    subscription: surveySubscriptionSubscription,
    variables: data,
    onNext: (response) => {
      callBack(response);
    },
    onError: (error) => {
      //   console.log('An error occured:', error)
    },
  };

  const disposable = requestSubscription(environment, subscriptionConfig);
  cancelSubscription(disposable);
};
export default surveySubscriptionAPI;
