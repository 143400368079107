/**
 * @generated SignedSource<<10c1c036eab7f9a5991e73a7ed4925bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DateGroupBy = "DAY" | "MONTH" | "%future added value";
export type PaymentReportFilter = {
  date: DateRange;
  groupBy: DateGroupBy;
  orderId: string;
};
export type DateRange = {
  end: string;
  start: string;
};
export type collectionsScreenQuery$variables = {
  filter: PaymentReportFilter;
};
export type collectionsScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"transactionsRevenueReportFragment">;
};
export type collectionsScreenQuery = {
  response: collectionsScreenQuery$data;
  variables: collectionsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionsScreenQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "transactionsRevenueReportFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionsScreenQuery",
    "selections": [
      {
        "alias": "reports",
        "args": (v1/*: any*/),
        "concreteType": "PaymentReport",
        "kind": "LinkedField",
        "name": "getPaymentReports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "period",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transactionsCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountGiven",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardPointsUsed",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08e04db1e934d1042bb12139861a52b5",
    "id": null,
    "metadata": {},
    "name": "collectionsScreenQuery",
    "operationKind": "query",
    "text": "query collectionsScreenQuery(\n  $filter: PaymentReportFilter!\n) {\n  ...transactionsRevenueReportFragment_Vt7Yj\n}\n\nfragment transactionsRevenueReportFragment_Vt7Yj on Query {\n  reports: getPaymentReports(filter: $filter) {\n    date\n    period\n    transactionsCount\n    grossAmount\n    netAmount\n    discountGiven\n    rewardPointsUsed\n  }\n}\n"
  }
};
})();

(node as any).hash = "884cd20ea98a4768d6a85ad14c1f5801";

export default node;
