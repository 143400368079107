/* eslint-disable no-extra-boolean-cast */
import React, {
  useState,
  Suspense,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Dimensions, Keyboard } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { ActionSheetRef } from 'react-native-actions-sheet';
import Loading from '../../components/loading.component';
import {
  Layout,
  TopNavigation,
  IconButton,
  View,
  Text,
  Divider,
  Button,
} from '../../components/new';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { navigateBack } from '../../utilities/helper';
import EditProfileUser from './editProfileUser';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useUserStore from '../../stores/userStore';
import BottomSheetV2 from '../../components/new/composites/BottomSheet/BottomSheetV2';
import useAuthStore from '../../stores/authStore';
import NotLoggedInPlaceholderV2 from '../../components/new/custom/NotLoggedInPlaceholderV2';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';

const homeScreenQuery = graphql`
  query editProfileScreenQuery($userId: MongoID!) {
    findUserById(_id: $userId) {
      _id
      name {
        first
        last
      }
      contact {
        phone {
          number
          prefix
        }
        email {
          addr
          verified
          type
        }
      }
    }
  }
`;

const RenderScreen = (props) => {
  const { variables, refreshedQueryOptions, setUserData, userId } = props;

  const { findUserById } = useLazyLoadQuery(homeScreenQuery, variables, {
    ...refreshedQueryOptions,
    networkCacheConfig: { force: true },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    firebaseEventLogger('editProfile__land_Landing', {
      buttonName: 'land',
      screenName: 'editProfile',
      userType: 'user',
      interactionType: InteractionType.LANDING,
    });
  }, []);

  const handleOnGoingBack = useCallback((name, phone, user) => {
    const lastName = user.name.last !== null ? user.name.last : '';
    const username = `${user.name.first} ${lastName}`;
    setUserData({ oldName: username, newName: name });
  }, []);

  const editProfile = () => {
    return (
      <EditProfileUser
        user={findUserById}
        userId={userId}
        onGoingback={(val, phone, user) => {
          handleOnGoingBack(val, phone, user);
        }}
      />
    );
  };

  return editProfile();
};

const EditProfileScreen = ({ navigation, route }) => {
  const userRole = useUserStore((state) => state.role);
  const userId = useUserStore((state) => state.id);
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const [userData, setUserData] = useState({ oldName: '', newName: '' });
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  const [netStatus, setNetStatus] = useState(true);
  const windowWidth = Dimensions.get('window').width;

  const { role } = route.params;

  const handleOnDismiss = useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  const handlePresentModalPress = useCallback(() => {
    if (bottomSheetModalRef.current) {
      bottomSheetModalRef.current?.show();
    }
  }, []);

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="Edit Profile" />;
  }

  return (
    <Layout level={2}>
      {/* <SafeAreaView edges={['top']} /> */}
      <TopNavigation
        title="Edit Profile"
        appearance="ghost"
        level="1"
        textSize="md"
        textColor="primary.500"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              Keyboard.dismiss();
              if (userData?.oldName !== userData?.newName) {
                handlePresentModalPress();
              } else {
                navigateBack(navigation, userRole);
              }
            }}
          />
        }
      />
      {userId !== '' && (
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <RenderScreen
              variables={{ userId }}
              refreshedQueryOptions={refreshedQueryOptions}
              userId={userId}
              setUserData={setUserData}
            />
          </Suspense>
        </NewErrorBoundary>
      )}
      <BottomSheetV2
        ref={bottomSheetModalRef}
        onClose={handleOnDismiss}
        insets={false}
      >
        <View justifyContent="center" px="2xl" pt="2xl">
          <Text size="xl" color="primary.200">
            You have unsaved changes are you sure you want to go back?
          </Text>
          <View mt="xl">
            <Divider level={1} />
          </View>
          <Text size="xs" color="primary.200" mt="3xl">
            You will lost all your changes done on this page. Are you sure you
            want to proceed?
          </Text>
          <View px="2xl" my="4xl">
            <Divider level={1} />
          </View>
          <View flexDirection="row" alignItems="center">
            <Button
              style={{
                width: (windowWidth - 3 * 16) / 2,
                marginRight: 16,
              }}
              state="active"
              status="error"
              appearance="filled"
              size="lg"
              onPress={() => {
                handleOnDismiss();
                navigateBack(navigation, userRole);
              }}
            >
              Yes
            </Button>
            <Button
              style={{ width: (windowWidth - 3 * 16) / 2 }}
              state="active"
              status="primary"
              appearance="filled"
              size="lg"
              onPress={() => {
                handleOnDismiss();
              }}
            >
              No
            </Button>
          </View>
        </View>
      </BottomSheetV2>
      <SafeAreaView edges={['bottom']} />
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default EditProfileScreen;
