// import {} from 'react-native'
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  Layout,
  View,
  Text,
  TopNavigation,
  IconButton,
  MobileInput,
  Button,
  Modal,
  Pressable,
} from '../../components/new';

import validateWrapper from '../../utilities/validationWrapper';
import { navigateBack, phoneFormat } from '../../utilities/helper';
import { checkUser, generateOTP } from '../../relay/authApi';
import { InputStates } from '../../components/new/primitive/MobileInput/helpers';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import useLoginModalStore from '../../stores/loginModalStore';
import useUserStore from '../../stores/userStore';
import useAuthStore from '../../stores/authStore';

const ChangeUserScreen = ({ navigation, route }) => {
  const [mobileInput, setMobileInput] = useState('');
  const [phoneCode, setPhoneCode] = useState('+91');
  const [userName, setUserName] = useState('');
  const [ModalVisible, setModalVisible] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const inputRef = useRef(null);
  const [inputStatus, setInputStatus] = useState(InputStates.default);
  const { params } = route;
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const userRole = useUserStore((state) => state.role);

  function showErrorSnack() {
    dispatchSnackbar({
      msg: `something went wrong`,
      status: SnackbarStatus.error,
      version: SnackbarVersion.v1,
    });
  }

  const handlePhoneInput = (value) => {
    const result = phoneFormat(value);
    setMobileInput(phoneFormat(value));

    if (result.length < 11) {
      setButtonDisabled(true);
    } else if (result.length > 11) {
      const error = validateWrapper('mobileNumber', result);
      // setHelperText('please enter the 10 digit mobile number')
      setHelperText(error);
      setInputStatus(InputStates.error);
      if (error) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    } else {
      setHelperText('');
      setInputStatus(InputStates.default);
      setButtonDisabled(false);
    }
  };

  const handleGenerateOtp = (response, data, opData) => {
    setButtonDisabled(false);
    if (response.generateOTP !== null) {
      navigation.navigate('phoneVerify', opData);
      // navigation.navigate('otp', data);
    } else {
      setHelperText('Please enter a valid phone number.');
    }
  };

  const sendOTP = (countryCode, mobileNumber) => {
    const data = {};

    const formattedNo = phoneFormat(mobileNumber).replace(/\D/g, '');
    // phoneFormat(phoneNumber).replace(/\D/g, '').toString();
    (data as any).phone = {
      prefix: countryCode,
      number: formattedNo.toString(),
    };

    if (!helperText) {
      setButtonDisabled(true);
      checkUser(data)
        .then((response) => {
          if (response.checkUser == null) {
            setButtonDisabled(false);
            // this number is new then proceed with otp page
            // creating data payload for generateOTP
            const userRecord = {
              id: params?.userId,
              role: userRole,
              record: {
                name: {
                  first: params?.first,
                  last: params?.last,
                },
                contact: {
                  phone: {
                    prefix: countryCode,
                    number: formattedNo.toString(),
                  },
                },
              },
            };
            generateOTP(
              userRecord.record.contact,
              handleGenerateOtp,
              userRecord,
            );
          } else {
            // now it is already existing user case then
            // show the pop up
            setModalVisible(true);
          }
        })
        .catch(() => {
          setButtonDisabled(false);
        });
    }
  };

  const onContinue = () => {
    // check that enter number exist in database if yes show popup if not then proceed with
    // update process with otp
    sendOTP(params.prefix, mobileInput);
  };

  const refCallback = useCallback(
    (InputRef) => (node) => {
      InputRef.current = node;
    },
    [],
  );

  useEffect(() => {
    if (params.mobile === mobileInput.replace(/\D/g, '').toString()) {
      setButtonDisabled(true);
    }
  }, [mobileInput, helperText]);

  useEffect(() => {
    setMobileInput(params.mobile);
    setPhoneCode(params.prefix || '+91');
    // setWhatsAppData();
  }, [params]);

  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus();
    }
  }, []);

  const componentState = useLoginModalStore((state) => state);
  const setLoginDetails = useAuthStore((state) => state.setLoginDetails);

  const { bottom } = useSafeAreaInsets();
  return (
    <Layout level={2}>
      <TopNavigation
        title="32nd"
        appearance="ghost"
        level="1"
        textSize="xl"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
            }}
          />
        }
      />

      <Modal
        visible={ModalVisible}
        onBackdropPress={() => {
          setModalVisible(!ModalVisible);
        }}
        onRequestClose={() => {
          setModalVisible(!ModalVisible);
        }}
      >
        <View
          width="90%"
          maxWidth={358}
          maxHeight={500}
          bg="background.primary.base"
          borderRadius={10}
          px="2xl"
          py="4xl"
        >
          <Text
            size="2xl"
            color="primary.500"
            category="text"
            textAlign="center"
            mb="xl"
          >
            This number already has an existing account
          </Text>
          <Text
            size="md"
            color="primary.300"
            category="text"
            weight="regular"
            textAlign="center"
            mb="4xl"
          >
            The new number you are trying to update already has an account,
            please retry or login again
          </Text>
          <View flexDirection="row" alignItems="center" justifyContent="center">
            <Button
              style={{
                width: (358 - 3 * 16) / 2,
                marginRight: 16,
              }}
              state="active"
              status="primary"
              appearance="outline"
              size="lg"
              onPress={() => {
                setModalVisible(false);
                setLoginDetails(false, '', '');
                componentState.setIsOpen(true);
                navigation.navigate('user');
              }}
            >
              Login Again
            </Button>
            <Button
              style={{ width: (358 - 3 * 16) / 2 }}
              state="active"
              status="primary"
              appearance="filled"
              size="lg"
              onPress={() => {
                setModalVisible(false);
              }}
            >
              Okay
            </Button>
          </View>
        </View>

        <View mt="2xl" width="90%" alignItems="center">
          <Pressable onPress={() => setModalVisible(!ModalVisible)}>
            <IconButton
              name="close-outline-300"
              size="2xl"
              bg="background.primary.base"
              iconColor="primary.500"
              iconSize="2xl"
              onPress={() => setModalVisible(!ModalVisible)}
            />
          </Pressable>
        </View>
      </Modal>

      {Platform.OS !== 'web' ? (
        <KeyboardAvoidingView
          {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
          style={{ flex: 1 }}
        >
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            {/* <View flex={1} px="2xl" justifyContent="space-between"> */}
            <ScrollView
              contentContainerStyle={{
                flex: 1,
                justifyContent: 'space-between',
                paddingHorizontal: 16,
              }}
              keyboardShouldPersistTaps="handled"
            >
              <View mt="4xl">
                <Text size="3xl" color="primary.200">
                  Change Number
                </Text>
                <Text mt="sm" size="xs" color="primary.200">
                  Enter your updated mobile number
                </Text>
                <View mt="7xl">
                  <View flexDirection="row" alignCenter="center" pt="xs">
                    <MobileInput
                      state={inputStatus}
                      value={mobileInput}
                      placeholder="12345 567890"
                      rightIconName={null}
                      rightIconColor="success.500"
                      label="Mobile Number"
                      // leftInputStyle={0.132}
                      helperText={helperText}
                      keyboardType="phone-pad"
                      countryCode={phoneCode}
                      autoFocus
                      // inputRef={inputRef}
                      inputRef={refCallback(inputRef)}
                      onChangeText={(value) => {
                        handlePhoneInput(value);
                      }}
                    />
                  </View>
                </View>
              </View>
              <View bottom="2xl">
                <Button
                  size="lg"
                  appearance="filled"
                  status="primary"
                  state={buttonDisabled === true ? 'disabled' : 'active'}
                  onPress={onContinue}
                >
                  Continue
                </Button>
              </View>
              {/* </View> */}
            </ScrollView>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      ) : (
        <View px="2xl">
          <View mt="4xl">
            <Text size="3xl" color="primary.200">
              Change Number
            </Text>
            <Text mt="sm" size="xs" color="primary.200">
              Enter your updated mobile number
            </Text>
            <View mt="7xl">
              <View flexDirection="row" alignCenter="center" pt="xs">
                <MobileInput
                  state={inputStatus}
                  value={mobileInput}
                  placeholder="12345 567890"
                  rightIconName={null}
                  rightIconColor="success.500"
                  label="Mobile Number"
                  // leftInputStyle={0.13}
                  helperText={helperText}
                  keyboardType="phone-pad"
                  countryCode={phoneCode}
                  // inputRef={inputRef}
                  inputRef={refCallback(inputRef)}
                  autoFocus
                  onChangeText={(value) => {
                    handlePhoneInput(value);
                  }}
                />
              </View>
            </View>
          </View>
          <View bottom="xl" style={{ paddingVertical: 60 }}>
            <Button
              size="lg"
              appearance="filled"
              status="primary"
              state={buttonDisabled === true ? 'disabled' : 'active'}
              onPress={onContinue}
            >
              Continue
            </Button>
          </View>
        </View>
      )}

      <SafeAreaView edges={['bottom']} />
      <DisconnectedDropover
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default ChangeUserScreen;
