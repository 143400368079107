import React from 'react';
import BlurView from '../../basic/BlurView/BlurView';
import Text from '../Typography/Text/Text';
import View from '../../basic/View/View';
import { formatPlateNo } from '../../../../utilities/helper';

interface VrnView {
  plateNumber: string;
}

const VrnView = ({ plateNumber }: VrnView) => {
  return (
    <BlurView level="1" borderRadius="lg+md">
      <View
        bg="rgba(133,133,135,0.2)"
        borderRadius="lg+md"
        px="auto"
        py="31"
        borderWidth="xs"
        borderColor="primary.400"
      >
        <Text
          textAlign="center"
          color="primary.500"
          size="8xl"
          lineHeight={90}
          weight="medium"
        >
          {formatPlateNo(plateNumber ?? '')}
        </Text>
      </View>
    </BlurView>
  );
};

export default VrnView;
