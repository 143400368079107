/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';
import { commitMutationPromise } from '../../../../utilities/helper';

const defaultMutation = graphql`
  mutation addUserMutation($record: addUserRecordInput!) {
    addUser(record: $record) {
      record {
        _id
      }
    }
  }
`;
export const addUserMutation = (
  variables,
  mutation = defaultMutation,
): Promise<any> => {
  return commitMutationPromise(mutation, variables);
};
