/* eslint-disable no-nested-ternary */
import React from 'react';
import { Text, View } from '../../../components/new';
import { NoContentView } from '../../../components/new/custom/bonus';
import TabSwitcher from '../../valetSupervisor/new/TabSwitcher';
import { useBillUploadViewerOverviewScreen } from './userBillUploadViewerOverview';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import ListItems from './ListItem';
import OverviewBanner from './OverviewBanner';

const BillUploadReviewOverviewScreenInner = ({
  queryOptions,
}: {
  queryOptions: NewErrorBoundaryParentState;
}) => {
  const {
    approvedBillsListItems,
    approvedBillsTab,
    rejectedBillsListItems,
    hasNoApprovedBills,
    hasNoRejectedBills,
    handleEarningTabSwitch,
    hasNext,
    loadNext,
    refetch: refetchHistory,
    verifiedCount,
    verificationFailedCount,
  } = useBillUploadViewerOverviewScreen(queryOptions);

  const hasNoApprovedAndRejectedItems =
    hasNoApprovedBills && hasNoRejectedBills;

  return (
    <View flex={1}>
      <OverviewBanner
        approvedBills={verifiedCount}
        rejectedBills={verificationFailedCount}
      />
      {hasNoApprovedAndRejectedItems ? (
        <View>
          <NoContentView />
          <Text textAlign="center" color="primary.100">
            No Bills yet
          </Text>
        </View>
      ) : (
        <>
          <View mt="4xl">
            <TabSwitcher
              tabOneText="Approved Bills"
              tabTwoText="Rejected Bills"
              handleTabSwitch={handleEarningTabSwitch}
              currentTab={approvedBillsTab}
              mx="2xl"
            />
          </View>
          {approvedBillsTab === 0 ? (
            <ListItems
              items={approvedBillsListItems}
              hasNext={hasNext}
              loadNext={loadNext}
            />
          ) : (
            <ListItems
              items={rejectedBillsListItems}
              hasNext={hasNext}
              loadNext={loadNext}
            />
          )}
        </>
      )}
    </View>
  );
};

export default BillUploadReviewOverviewScreenInner;
