import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const cashierPaymentScreenQuery = graphql`
  query cashierPaymentScreenQuery($taskFilter: findTaskFilterInput!) {
    findTasks(filter: $taskFilter) {
      _id
      history {
        _id
        action
        event_at
      }
      isVehicleWhitelisted
      vehicle {
        brand {
          name
          brandLogo
        }
        registration {
          plate
        }
        color
      }
      ETA {
        _id
        startAt
        delayAt
        originalPredictionTime
        pendingRecall
        available
        busy
      }
      parking {
        _id
        status
        parkingTag
        parkingInvoice {
          timings {
            entryTime
            exitTime
            chargeTime
          }
          paymentDetails {
            isPaid
            mode
            focReason
            message
            amount
          }
          charges {
            chargesBreakup {
              duration
              payable
            }
            discountAmount
            cashierDiscountAmount
            totalPayable
          }
        }
      }
    }
  }
`;
