import React, { useEffect, useRef } from 'react';
import { Image } from 'react-native';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { Button, Layout, Text, View } from '../../components/new';
import useNotificationModalStore from '../../stores/notificationModalStore';
import ControllerBottomSheet from '../../components/modals/ControllerBottomSheet.component';

const ControllerHomeScreen = () => {
  const { willOpen } = useNotificationModalStore((state) => state);
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!useNotificationModalStore.getState().willOpen) {
        bottomSheetModalRef?.current?.show();
      }
    }, 200);
  }, [willOpen]);

  const roleHandler = () => {
    bottomSheetModalRef?.current?.show();
  };

  return (
    <>
      <Layout level={2}>
        <View flex={1} justifyContent="center" px="2xl">
          <View alignItems="center">
            <Image
              style={{
                width: 226,
                height: 280,
              }}
              source={require('../../../assets/images/no-car-found.webp')}
            />
            <Text size="xl" color="primary.300" weight="medium">
              Punch-In to view tasks!
            </Text>
            <Text size="sm" color="primary.100" mt="lg" textAlign="center">
              Yours tasks will appear once you select a role and punch in
            </Text>
          </View>
          <Button
            appearance="filled"
            status="primary"
            size="lg"
            onPress={roleHandler}
            mt="4xl"
          >
            Select role & Punch-In
          </Button>
        </View>
      </Layout>
      <ControllerBottomSheet ref={bottomSheetModalRef} />
    </>
  );
};

export default ControllerHomeScreen;
