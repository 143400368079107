import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useFragment } from 'react-relay';
import { Pressable, Text, View } from '../../../../components/new';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import { homeScreenfindTypeFormResponsesFragment } from '../API/homeScreenQuery';
import useUserStore from '../../../../stores/userStore';
import { SURVEY_ID } from '../../../../utilities/helper';
import AppConstants from '../../../../utilities/AppConstants';
import CustomLottieView from '../../../../components/new/custom/CustomLottieView';

const TakeSurvey = ({ fragmentRef }) => {
  const navigation = useNavigation<any>();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { TYPEFORM_PRODUCT } = AppConstants;
  // @ts-ignore
  const userStore = useUserStore((state) => state);

  const data = useFragment(
    homeScreenfindTypeFormResponsesFragment,
    fragmentRef,
  );

  const typeForm = data?.[0];
  let fillingBeforeOneMonth = true;

  if (typeForm?.submittedAt) {
    const currentDate = new Date();
    const typeFormDate = new Date(typeForm.submittedAt);
    const miliseconds = currentDate - typeFormDate;
    const days = miliseconds / 1000 / 60 / 60 / 24;
    if (days > 30) {
      fillingBeforeOneMonth = false;
    } else {
      fillingBeforeOneMonth = true;
    }
  } else {
    fillingBeforeOneMonth = false;
  }

  const handleOnPress = useCallback(() => {
    if (fillingBeforeOneMonth) {
      dispatchSnackbar({
        msg: 'Looks like you have already filled out the Survey Form',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
    } else {
      firebaseEventLogger('PlayWinComponent__survey_Tap', {
        buttonName: 'survey',
        screenName: 'PlayWinComponent',
        userType: 'user',
        interactionType: 'tap',
      });
      navigation.navigate('surveyScreen', {
        phoneNumber: userStore?.contact?.phone?.number,
        TYPEFORMID: TYPEFORM_PRODUCT,
        SURVEYID: SURVEY_ID.PRODUCT,
      });
    }
  }, []);

  if (fillingBeforeOneMonth) return <></>; // Already filled

  return (
    <Pressable onPress={handleOnPress}>
      <View
        mt="4xl"
        p="2xl"
        shadow="sm"
        borderRadius="lg"
        bg="background.primary.elevation"
        borderColor="grey.50"
        borderWidth="xs"
        justifyContent="center"
        alignItems="center"
      >
        <CustomLottieView
          autoPlay
          loop
          source={require('../../../../../assets/lottie/man-filling-survey.json')}
          style={{
            height: 90,
            width: 116,
          }}
          containerWidth={90}
        />
        <Text size="xl" my="2xl" color="primary.400" weight="medium">
          Take A Survey
        </Text>
      </View>
    </Pressable>
  );
};

export default TakeSurvey;
