/* eslint-disable import/prefer-default-export */
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import collectionsScreenQuery from './collectionsScreenQuery';
import { TransactionsRevenueReport } from '../../types';
import {
  collectionsScreenQuery$data,
  collectionsScreenQuery$variables,
} from './__generated__/collectionsScreenQuery.graphql';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import {
  dateUtils,
  toISOStringWithTimezone,
} from '../../../../utilities/helper';
import useRestaurantPartnerStore from '../../../../stores/restaurantpartner/restaurantPartnerStore';
import { useTransactionsRevenueReport } from '../../API/fragments/transactionsRevenueReportFragment';

const today = new Date();

export const useCollectionsScreen = (
  queryOptions: NewErrorBoundaryParentState,
  refresh: () => void,
) => {
  const navigation = useNavigation();
  const restaurantId = useRestaurantPartnerStore((state) => state.restaurantId);
  const [refreshing, setRefreshing] = useState(false);
  const [todaysDate, setTodaysDate] = useState(today);
  const { startDate, endDate } = calculateQueryDates(todaysDate);

  const variables: collectionsScreenQuery$variables = {
    filter: {
      date: {
        start: startDate,
        end: endDate,
      },
      groupBy: 'DAY',
      orderId: restaurantId,
    },
  };

  useFocusEffect(
    useCallback(() => {
      setTodaysDate(new Date());
    }, []),
  );

  const transactionsRevenueReportRef: collectionsScreenQuery$data =
    useLazyLoadQuery(collectionsScreenQuery, variables, queryOptions);

  const { revenueReports, refetchReports } = useTransactionsRevenueReport(
    transactionsRevenueReportRef,
  );

  const navigateToRevenueOverviewScreen = (
    revenueReport: TransactionsRevenueReport,
  ) => {
    if (revenueReport.revenueAmount !== 0) {
      navigation.navigate('revenueOverview', {
        date: toISOStringWithTimezone(new Date(revenueReport.date)),
        restaurantId,
      });
    }
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    const { startDate: sD, endDate: eD } = calculateQueryDates(new Date());
    refetchReports(
      () => {
        setRefreshing(false);
      },
      {
        ...variables,
        filter: {
          ...variables.filter,
          date: {
            start: sD,
            end: eD,
          },
        },
      },
    );
  }, [refetchReports, variables]);

  return {
    revenueReports,
    refetchReports,
    pullToRefreshConf: { refreshing, onRefresh },
    navigateToRevenueOverviewScreen,
  };
};

const calculateQueryDates = (
  date: Date,
): { startDate: string; endDate: string } => {
  let startDate = new Date(date);
  startDate.setDate(startDate.getDate() - 30); // last 30 days
  startDate = dateUtils.toStartOfDay(startDate);
  const endDate = new Date(date);

  return {
    startDate: toISOStringWithTimezone(startDate),
    endDate: toISOStringWithTimezone(endDate),
  };
};
