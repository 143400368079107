import React from 'react';
import { useFragment } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { AnimatedFlashList } from '@shopify/flash-list';
import cashierCarSearchScreenTasksFragment from '../../API/fragments/cashierCarSearchScreenTasksFragment';
import RenderItem from '../../RenderItem';
import { View } from '../../../../components/new';
import { getTheme } from '../../../../themes/new/theme';

const TaskList = ({ tasks, navigation }) => {
  const fetchedTasks = useFragment(cashierCarSearchScreenTasksFragment, tasks);

  const renderItem = ({ item }) => (
    <RenderItem item={item} refresh={() => navigation.navigate('home')} />
  );

  return (
    <View>
      <AnimatedFlashList
        style={{
          flex: 1,
          paddingBottom: getTheme().space['4xl'],
        }}
        data={fetchedTasks}
        keyExtractor={(item) => item?._id}
        estimatedItemSize={200}
        renderItem={renderItem}
        showsVerticalScrollIndicator
      />
    </View>
  );
};

export default TaskList;
