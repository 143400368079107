/**
 * @generated SignedSource<<35060967046974a53eb3d3ccbac63c67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnumPopupOrdersTCPaymentType = "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnumPopupOrdersTCPopUpStoreDetailsDeliveryStatus = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type EnumPopupOrdersTCStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type EnuminvoiceOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type auditorSearchCodeScreenQuery__paymentHistoryFragmentV2$data = {
  readonly findPopupOrders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly amount: number;
        readonly created_at: any | null;
        readonly invoice: ReadonlyArray<{
          readonly _id: any;
          readonly created_at: any | null;
          readonly orderId: any;
          readonly orderType: EnuminvoiceOrderType;
          readonly paymentDetails: {
            readonly paytmQr: {
              readonly transactionId: string | null;
            } | null;
            readonly razorpay: {
              readonly pgOrderId: string;
            } | null;
          } | null;
        } | null> | null;
        readonly paymentType: EnumPopupOrdersTCPaymentType | null;
        readonly popUpStoreDetails: {
          readonly deliveryStatus: EnumPopupOrdersTCPopUpStoreDetailsDeliveryStatus | null;
          readonly displayName: string | null;
        } | null;
        readonly referenceModelName: string | null;
        readonly status: EnumPopupOrdersTCStatus | null;
        readonly user: {
          readonly _id: any;
          readonly contact: {
            readonly phone: {
              readonly number: string;
              readonly prefix: string;
            };
          };
        } | null;
      };
    }>;
  } | null;
  readonly " $fragmentType": "auditorSearchCodeScreenQuery__paymentHistoryFragmentV2";
};
export type auditorSearchCodeScreenQuery__paymentHistoryFragmentV2$key = {
  readonly " $data"?: auditorSearchCodeScreenQuery__paymentHistoryFragmentV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"auditorSearchCodeScreenQuery__paymentHistoryFragmentV2">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "findPopupOrders"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./auditorSearchCodeScreenQuery__paymentHistoryFragmentV2Query.graphql')
    }
  },
  "name": "auditorSearchCodeScreenQuery__paymentHistoryFragmentV2",
  "selections": [
    {
      "alias": "findPopupOrders",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "PopupOrdersTCConnection",
      "kind": "LinkedField",
      "name": "__auditorSearchCodeScreenQuery__paymentHistoryFragmentV2Query__findPopupOrders_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PopupOrdersTCEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PopupOrdersTC",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "referenceModelName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserContact",
                      "kind": "LinkedField",
                      "name": "contact",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserContactPhone",
                          "kind": "LinkedField",
                          "name": "phone",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "prefix",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "number",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "invoice",
                  "kind": "LinkedField",
                  "name": "invoice",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "orderType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "orderId",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "InvoiePaymentDetailsTC",
                      "kind": "LinkedField",
                      "name": "paymentDetails",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "payment",
                          "kind": "LinkedField",
                          "name": "razorpay",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "pgOrderId",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PaytmDqrOrders",
                          "kind": "LinkedField",
                          "name": "paytmQr",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "transactionId",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PopupOrdersTCPopUpStoreDetails",
                  "kind": "LinkedField",
                  "name": "popUpStoreDetails",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deliveryStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "028598222bec860f04fe24abc3c01010";

export default node;
