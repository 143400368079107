/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useRef,
} from 'react';
import { Animated, Image, Pressable, RefreshControl } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Layout, View, Text, IconButton } from '../../../components/new';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import RenderItem from './RenderItem';
import BottomBonusBoard from './BottomBonusBoard';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useCommonStore from '../../../stores/commonStore';
import {
  taskScreenFindTaskFragment,
  taskScreenQuery,
} from './API/taskScreenQuery';
import useValetTaskStore from '../../../stores/valet/taskStore';
import useUserStore from '../../../stores/userStore';
import { getTheme } from '../../../themes/new/theme';
import valetTaskCreatedSubscriptionApi from './API/valetTaskCreatedSubscriptionApi';
import valetTaskUpdatedSubscriptionApi from './API/valetTaskUpdatedSubscriptionApi';
import {
  findTaskHistoryLastAction,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../utilities/helper';
import {
  findAndBlurTask,
  findAndRemoveTask,
  findAndReplaceTask,
  isAlreadyInList,
} from '../../valetSupervisor/helpers/helpers';
import useSound from '../../../hooks/useSound';
import RefreshBubble from '../../../components/new/primitive/Bubble/RefreshBubble';
import { network } from '../../../utilities/Constant';

const { pullToRefreshFetchPolicy } = network;
const emptyTaskImg = require('../../../../assets/images/task-empty.webp');

const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);

const TaskScreen = (props) => {
  const {
    // ------ for valet task screen ------
    tasks,
    setTasks,
    newTasks, // for subscription bubble
    setNewTasks, // for subscription
    // ------ for getting and setting valet worklog ------
    setValetWorkLog,
  } = useValetTaskStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const userId = useUserStore((state) => state.id);

  const { queryOptions, variables, refresh } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { findValetWorkLog, ...findTasksRef } = useLazyLoadQuery(
    taskScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const [{ findTasks }, refetchTasks] = useRefetchableFragment(
    taskScreenFindTaskFragment,
    findTasksRef,
  );
  const [refreshing, setRefreshing] = useState(false);

  const setData = () => {
    setTasks({ data: findTasks, userId });
    setValetWorkLog({ data: findValetWorkLog });
  };

  useEffect(() => {
    // setTaskData({ data: findTasks });
    setData();
  }, [findTasks, findValetWorkLog, refreshing]);

  const noTasks = () => (
    <View justifyContent="center" alignItems="center" mt="50%">
      <Image
        source={emptyTaskImg}
        style={{ width: 80, height: 80 }}
        resizeMode="contain"
      />
      <Text size="xl" color="grey.200" weight="medium" mt="2xl">
        No tasks available
      </Text>
    </View>
  );

  const onPullToRefresh = useCallback(() => {
    setRefreshing(true);
    refetchTasks(
      {},
      {
        fetchPolicy: pullToRefreshFetchPolicy,
        onComplete: () => setRefreshing(false),
      },
    );
  }, []);

  const renderItem = ({ item }) => (
    <RenderItem
      item={item}
      dispatchSnackbar={dispatchSnackbar}
      refresh={refresh}
    />
  );

  return (
    <>
      <View alignItems="center" zIndex={1}>
        <RefreshBubble
          onPress={() => {
            refresh();
            setNewTasks({ data: [] });
          }}
          length={newTasks?.length}
        />
      </View>
      <View flex={1}>
        <AnimatedFlashList
          style={{
            flex: 1,
            marginTop: getTheme().space['2xl'],
            paddingBottom: getTheme().space['4xl'],
          }}
          data={tasks}
          keyExtractor={(item) => item?._id}
          estimatedItemSize={200}
          renderItem={renderItem}
          showsVerticalScrollIndicator
          ListEmptyComponent={noTasks}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onPullToRefresh}
            />
          }
        />
      </View>
    </>
  );
};

const TaskScreenWrapper = ({ navigation }) => {
  const campusID = useCommonStore((state) => state.campusId);
  const userId = useUserStore((state) => state.id);
  const { bottom } = useSafeAreaInsets();

  const {
    // ------ for valet task screen ------
    tasks,
    setTasks,
    newTasks, // for subscription bubble
    setNewTasks, // for subscription
    // ------ for setting card opened ------
    setOpenedCard,
    // ------ for getting and setting valet worklog ------
    valetWorkLog,
  } = useValetTaskStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });
  const [netStatus, setNetStatus] = useState(true);

  const taskFilter = {
    campus: { _id: campusID },
    history: [
      { action: taskHistoryActionTypes.created },
      { action: taskHistoryActionTypes.assigned },
      { action: taskHistoryActionTypes.accepted },
      { action: taskHistoryActionTypes.onboarded },
      { action: taskHistoryActionTypes.started },
      { action: taskHistoryActionTypes.arrived },
    ],
    assignedTo: userId,
  };
  const taskSubscriptionFilter = {
    history: [
      taskHistoryActionTypes.assigned,
      taskHistoryActionTypes.accepted,
      taskHistoryActionTypes.onboarded,
      taskHistoryActionTypes.started,
      taskHistoryActionTypes.arrived,
      taskHistoryActionTypes.cancelled,
      taskHistoryActionTypes.completed,
      taskHistoryActionTypes.incomplete,
      taskHistoryActionTypes.EXITED,
    ],
  };
  const sort = 'DELAY_AT_ASC';

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'store-and-network',
      }));
    },
    [
      /* ... */
    ],
  );

  const { playSound: playTaskArrivedSound } = useSound({
    soundOrigin: require('../../../../assets/notification.mp3'),
  });

  const handleTaskCreationSub = (res) => {
    const taskCreated = res?.taskCreated;
    const lastAction = findTaskHistoryLastAction(taskCreated);
    if (taskCreated) {
      // for valet
      if (taskCreated?.taskType === taskTypes.park) {
        if (
          !isAlreadyInList(taskCreated, tasks) &&
          taskCreated?.assignedTo !== userId
        ) {
          playTaskArrivedSound();
          setNewTasks({
            data: [...newTasks, taskCreated],
          });
        }
      } else if (taskCreated?.taskType === taskTypes.repark) {
        if (
          !isAlreadyInList(taskCreated, tasks) &&
          taskCreated?.assignedTo === userId
        ) {
          playTaskArrivedSound();
          setNewTasks({
            data: [...newTasks, taskCreated],
          });
        }
      }
    }
  };

  const handleTaskUpdateSubscription = (res) => {
    // ============
    const taskUpdated = res?.taskUpdated;
    const lastAction = findTaskHistoryLastAction(taskUpdated);
    if (taskUpdated) {
      if (
        taskUpdated?.taskType === taskTypes.park ||
        taskUpdated?.taskType === taskTypes.repark
      ) {
        // for valet if assigned or accepted
        if (
          lastAction === taskHistoryActionTypes.assigned ||
          lastAction === taskHistoryActionTypes.accepted
        ) {
          // blur card if in rendedred list, if not remove the item from new available list if its there
          if (
            isAlreadyInList(taskUpdated, tasks) &&
            taskUpdated?.assignedTo !== userId
          ) {
            const newList = findAndBlurTask(taskUpdated, tasks);
            setTasks({ data: newList, userId });
          } else if (
            !isAlreadyInList(taskUpdated, newTasks) &&
            taskUpdated?.assignedTo !== userId
          ) {
            const newBubbleList = findAndRemoveTask(taskUpdated, newTasks);
            setNewTasks({ data: newBubbleList });
          } else if (
            !isAlreadyInList(taskUpdated, tasks) &&
            !isAlreadyInList(taskUpdated, newTasks) &&
            taskUpdated?.assignedTo === userId
          ) {
            playTaskArrivedSound();
            setNewTasks({
              data: [...newTasks, taskUpdated],
            });
          } else if (isAlreadyInList(taskUpdated, tasks)) {
            const newList = findAndReplaceTask(taskUpdated, tasks);
            setTasks({ data: newList, userId });
          }
        }

        // if last action onboarded then update the task
        if (lastAction === taskHistoryActionTypes.onboarded) {
          // replace item when history action is onboarded
          const newList = findAndReplaceTask(taskUpdated, tasks);
          setTasks({ data: newList, userId });
        }

        // blur task if lastaction is cancelled or incomplete
        if (
          lastAction === taskHistoryActionTypes.incomplete ||
          lastAction === taskHistoryActionTypes.cancelled
        ) {
          const newList = findAndBlurTask(taskUpdated, tasks);
          setTasks({ data: newList, userId });
        }
      } else if (taskUpdated?.taskType === taskTypes.recall) {
        if (
          (lastAction === taskHistoryActionTypes.accepted ||
            lastAction === taskHistoryActionTypes.assigned) &&
          taskUpdated?.assignedTo === userId
        ) {
          if (!isAlreadyInList(taskUpdated, tasks)) {
            playTaskArrivedSound();
            setNewTasks({
              data: [...newTasks, taskUpdated],
            });
          }
        }

        if (
          lastAction === taskHistoryActionTypes.arrived &&
          taskUpdated?.assignedTo === userId
        ) {
          const newList = findAndReplaceTask(taskUpdated, tasks);
          setTasks({ data: newList, userId });
        }

        if (
          (lastAction === taskHistoryActionTypes.completed ||
            lastAction === taskHistoryActionTypes.incomplete ||
            lastAction === taskHistoryActionTypes.cancelled ||
            lastAction === taskHistoryActionTypes.EXITED) &&
          taskUpdated?.assignedTo === userId
        ) {
          const newList = findAndBlurTask(taskUpdated, tasks);
          setTasks({ data: newList, userId });
        }
      }
    }
  };

  const handleTaskCreationSubRef = useRef(handleTaskCreationSub);
  const handleTaskUpdationSubRef = useRef(handleTaskUpdateSubscription);

  useEffect(() => {
    handleTaskCreationSubRef.current = handleTaskCreationSub;
    handleTaskUpdationSubRef.current = handleTaskUpdateSubscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tasks,
    // -----------
    newTasks,
  ]);

  useFocusEffect(
    useCallback(() => {
      refresh();
      setOpenedCard('');

      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      // handleTaskCreationSubRef.current = handleTaskCreationSub;
      // handleTaskUpdationSubRef.current = handleTaskUpdateSubscription;
      // apply subscription
      let cancelSubscriptionCreateData = {
        dispose: () => {},
      };
      let cancelSubscriptionUpdateData = {
        dispose: () => {},
      };
      const cancelCreateTaskSubscription = (disposable) => {
        cancelSubscriptionCreateData = disposable;
      };
      const cancelUpdateTaskSubscription = (disposable) => {
        cancelSubscriptionUpdateData = disposable;
      };
      const taskCreatedReq = {
        campusId: campusID,
        userId,
        event_at: new Date().toISOString(),
      };
      const taskUpdatedDataReq = {
        campusId: campusID,
        userId,
        history: taskSubscriptionFilter.history,
        event_at: new Date().toISOString(),
        taskType: [taskTypes.park, taskTypes.recall, taskTypes.repark],
      };
      valetTaskCreatedSubscriptionApi(
        taskCreatedReq,
        (data) => handleTaskCreationSubRef.current(data),
        cancelCreateTaskSubscription,
      );
      valetTaskUpdatedSubscriptionApi(
        taskUpdatedDataReq,
        (data) => handleTaskUpdationSubRef.current(data),
        cancelUpdateTaskSubscription,
      );

      return () => {
        // Useful for cleanup functions
        cancelSubscriptionCreateData.dispose();
        cancelSubscriptionUpdateData.dispose();
      };
    }, []),
  );

  const todayDate = () => {
    const date = new Date();
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const checkWorkLog = () => {
    const worklog =
      valetWorkLog && Object.keys(valetWorkLog)?.length
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          valetWorkLog?.workLogHistory?.slice(-1)[0]
        : '';
    if (
      !valetWorkLog?.workLogHistory?.length ||
      worklog?.type === 'punch_out'
    ) {
      return true;
    }
    if (worklog?.type === 'break_start') {
      return true;
    }
    return false;
  };

  const hasRecallTaskAssigned = () => {
    const data = tasks?.filter((item) => {
      return item?.taskType === taskTypes.recall && item?.assignedTo === userId;
    });
    if (data?.length) {
      return true;
    }
    return false;
  };

  const navigateToValetOverviewScreen = () => {
    navigation.navigate('overview');
  };

  return (
    <Layout level={2} edges={['top']}>
      <View
        flexDirection="row"
        mb="4xl"
        px="2xl"
        mt="2xl"
        alignItems="center"
        justifyContent="space-between"
      >
        <View py="xs">
          <Text size="2xl" color="primary.300">
            All Tasks
          </Text>
        </View>
        <IconButton
          size="md"
          iconSize="2xl"
          appearance="filled"
          shape="rounded"
          name="scan-qr-outline-300"
          onPress={() => {
            navigation.navigate('QrCodeScreen');
          }}
          state={
            checkWorkLog()
              ? 'disabled'
              : hasRecallTaskAssigned()
              ? 'disabled'
              : 'active'
          }
        />
      </View>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <TaskScreen
            variables={{
              taskFilter,
              sort,
              logFilter: {
                valetID: userId,
                date: todayDate(),
              },
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
          />
          <View
            position="absolute"
            right="2xl"
            bottom={bottom + getTheme().space['5xl+6xl']}
          >
            <IconButton
              appearance="filled"
              size="3xl"
              iconColor="primary.10"
              // bg="primary.10"
              // text="SCAN"
              iconSize="xl"
              name="addcar-400"
              onPress={() => {
                navigation.navigate('anprDetectionScreen');
              }}
            />
          </View>
          <Pressable onPress={navigateToValetOverviewScreen}>
            <BottomBonusBoard fetchKey={refreshedQueryOptions.fetchKey} />
          </Pressable>
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default TaskScreenWrapper;
