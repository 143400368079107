import { graphql, fetchQuery } from 'react-relay';
import environment from './relayEnvironment';

const findCampusLocation = (data) => {
  const query = graphql`
    query campusLocationApiQuery($filter: findLocationFilterInput) {
      findCampusLocation(filter: $filter) {
        name
        address
        city
        state
        country
        geo {
          lat
          long
          _id
        }
        contact {
          phone {
            prefix
            number
          }
          email {
            addr
          }
          _id
        }
        _id
      }
    }
  `;
  const variables = {
    filter: data,
  };

  return fetchQuery(environment, query, variables, { force: true }).toPromise();
};

export default findCampusLocation;
