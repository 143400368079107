import React from 'react';
import styled from 'styled-components/native';
import { Animated } from 'react-native';
import { variant, color, typography, space } from 'styled-system';
import { FontWeights, ThemeVariables } from '../../../../../themes/new/helper';
import { TextCategories } from './helper';

const sizeVariants = ({ theme, ...props }) =>
  variant({
    prop: ThemeVariables.SIZE,
    variants: {
      '2xs': {
        'font-size': theme.fontSizes['2xs'],
        'line-height':
          props?.category !== TextCategories.TEXT
            ? theme.lineHeights.xs
            : theme.lineHeights['2xs'],
        'letter-spacing': theme.letterSpacings['2xs'],
      },
      xs: {
        'font-size': theme.fontSizes.xs,
        'line-height': theme.lineHeights.xs,
        'letter-spacing': theme.letterSpacings.xs,
      },
      sm: {
        'font-size': theme.fontSizes.sm,
        'line-height': theme.lineHeights.sm,
        'letter-spacing': theme.letterSpacings.sm,
      },
      md: {
        'font-size': theme.fontSizes.md,
        'line-height': theme.lineHeights.md,
        'letter-spacing': theme.letterSpacings.md,
      },
      lg: {
        'font-size': theme.fontSizes.lg,
        'line-height': theme.lineHeights.lg,
        'letter-spacing': theme.letterSpacings.lg,
      },
      xl: {
        'font-size': theme.fontSizes.xl,
        'line-height': theme.lineHeights.xl,
        'letter-spacing': theme.letterSpacings.xl,
      },
      '1xl': {
        'font-size': theme.fontSizes['1xl'],
        'line-height': theme.lineHeights['1xl'],
        'letter-spacing': theme.letterSpacings['1xl'],
      },
      '2xl': {
        'font-size': theme.fontSizes['2xl'],
        'line-height': theme.lineHeights['2xl'],
        'letter-spacing': theme.letterSpacings['2xl'],
      },
      '3xl': {
        'font-size': theme.fontSizes['3xl'],
        'line-height': theme.lineHeights['3xl'],
        'letter-spacing': theme.letterSpacings['3xl'],
      },
      '4xl': {
        'font-size': theme.fontSizes['4xl'],
        'line-height': theme.lineHeights['4xl'],
        'letter-spacing': theme.letterSpacings['4xl'],
      },
      '5xl': {
        'font-size': theme.fontSizes['5xl'],
        'line-height': theme.lineHeights['5xl'],
        'letter-spacing': theme.letterSpacings['5xl'],
      },
      '6xl': {
        'font-size': theme.fontSizes['6xl'],
        'line-height': theme.lineHeights['6xl'],
        'letter-spacing': theme.letterSpacings['6xl'],
      },
      '7xl': {
        'font-size': theme.fontSizes['7xl'],
        'line-height': theme.lineHeights['7xl'],
        'letter-spacing': theme.letterSpacings['7xl'],
      },
      '8xl': {
        'font-size': theme.fontSizes['8xl'],
        'line-height': theme.lineHeights['8xl'],
        'letter-spacing': theme.letterSpacings['8xl'],
      },
      '9xl': {
        'font-size': theme.fontSizes['9xl'],
        'line-height': theme.lineHeights['9xl'],
        'letter-spacing': theme.letterSpacings['9xl'],
      },
      '10xl': {
        'font-size': theme.fontSizes['10xl'],
        'line-height': theme.lineHeights['10xl'],
        'letter-spacing': theme.letterSpacings['10xl'],
      },
    },
  });

const categoryVariants = ({ theme, ...props }) =>
  variant({
    prop: ThemeVariables.CATEGORY,
    variants: {
      text: {
        'font-family':
          props.weight === FontWeights.REGULAR
            ? theme.fonts.body
            : theme.fonts.bodyMedium,
      },
      tag: {
        'font-family': theme.fonts.tag,
      },
      quote: {
        'font-family': theme.fonts.quote,
      },
      button: {
        'font-family':
          props.weight === FontWeights.REGULAR
            ? theme.fonts.body
            : theme.fonts.bodyMedium,
      },
      helpertext: {
        'font-family': theme.fonts.body,
      },
      inputLabel: {
        'font-family': theme.fonts.body,
      },
    },
  });

const TextStyled = styled.Text`
  ${color}
  ${space}
  ${(props) => sizeVariants(props)}
  ${(props) => categoryVariants(props)}
  ${typography}
`;

const TextStyledAnimated = styled(Animated.Text)`
  ${color}
  ${space}
  ${(props) => sizeVariants(props)}
  ${(props) => categoryVariants(props)}
  ${typography}
`;

const Text = ({ children, animated = false, ...rest }) => {
  return animated ? (
    <TextStyledAnimated selectable={false} {...rest}>
      {children}
    </TextStyledAnimated>
  ) : (
    <TextStyled selectable={false} {...rest}>
      {children}
    </TextStyled>
  );
};

Text.defaultProps = {
  color: 'primary.500',
  category: TextCategories.TEXT,
  weight: FontWeights.REGULAR,
};

export default Text;
