/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { Image } from 'expo-image';
import { Button, Text, View } from '../../../../../components/new';
import {
  KioskTheme,
  useKioskStore,
} from '../../../../../stores/kiosk/kioskStore';
import CustomLottieView from '../../../../../components/new/custom/CustomLottieView';

const RecallWelcomeScreen = ({ onBeginAction }) => {
  const theme = useKioskStore((state) => state.theme);

  return (
    <View flex={1} width="100%" justifyContent="space-between">
      <View>
        <View alignItems="center">
          <View
            width={200}
            height={200}
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            {/* car icon */}
            <CustomLottieView
              autoPlay
              source={require('../../../../../../assets/lottie/car-dispatch.json')}
              style={{
                width: 290,
                height: 290,
              }}
              containerWidth={290}
            />
          </View>
        </View>
        <Text
          color="primary.500"
          mt="4xl"
          textAlign="center"
          size="10xl"
          weight="medium"
        >
          Recall Your Car From Here!
        </Text>
        <View mt="9xl+3xl" alighItyem="center">
          <View mb="9xl" flexDirection="row" justifyContent="center">
            <View
              mr="3xl"
              p="lg"
              width={300}
              height={250}
              alignItems="center"
              justifyContent="center"
            >
              {/* Lightning icon */}
              <View
                mr="3xl"
                p="lg"
                pl="6xl"
                height={100}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  source={require('../../../../../../assets/images/kiosk_flash.webp')}
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
                {/* <CustomLottieView */}
                {/*   autoPlay */}
                {/*   // ref={(ref) => ref} */}
                {/*   loop */}
                {/*   source={require('../../../../../../assets/lottie/lightning.json')} */}
                {/*   style={{ width: 100 }} */}
                {/*   containerWidth={100} */}
                {/* /> */}
              </View>

              <Text
                size="3xl"
                mt="4xl"
                weight="medium"
                color="primary.500"
                textAlign="center"
              >
                Get Your Car Faster
              </Text>
            </View>

            <View
              ml="3xl"
              p="lg"
              width={300}
              height={250}
              alignItems="center"
              justifyContent="center"
            >
              {/* Trophy icon */}
              <View
                mr="3xl"
                p="lg"
                pl="6xl"
                height={100}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  source={require('../../../../../../assets/images/queue.webp')}
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
                {/* <CustomLottieView */}
                {/*   autoPlay */}
                {/*   // ref={(ref) => ref} */}
                {/*   loop */}
                {/*   source={require('../../../../../../assets/lottie/trophy.json')} */}
                {/*   style={{ width: 100 }} */}
                {/*   containerWidth={100} */}
                {/* /> */}
              </View>

              <Text
                size="3xl"
                mt="4xl"
                weight="medium"
                color="primary.500"
                textAlign="center"
              >
                Skip The Queue
              </Text>
            </View>
          </View>
          <View flexDirection="row" justifyContent="center">
            <View
              mr="3xl"
              p="lg"
              width={300}
              height={250}
              alignItems="center"
              justifyContent="center"
            >
              {/* Rupee tag icon */}
              <View
                mr="3xl"
                p="lg"
                pl="6xl"
                height={100}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  source={require('../../../../../../assets/images/kiosk_discount.webp')}
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
                {/* <CustomLottieView */}
                {/*   autoPlay */}
                {/*   // ref={(ref) => ref} */}
                {/*   loop */}
                {/*   source={require('../../../../../../assets/lottie/rupee-tag.json')} */}
                {/*   style={{ width: 100 }} */}
                {/*   containerWidth={100} */}
                {/* /> */}
              </View>

              <Text
                size="3xl"
                mt="4xl"
                weight="medium"
                color="primary.500"
                textAlign="center"
              >
                Save Money
              </Text>
            </View>

            <View
              ml="3xl"
              p="lg"
              width={300}
              height={250}
              alignItems="center"
              justifyContent="center"
            >
              {/* tracking icon */}
              <View
                mr="3xl"
                p="lg"
                pl="6xl"
                width={100}
                height={100}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  source={require('../../../../../../assets/images/kiosk_map.webp')}
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
                {/* <CustomLottieView */}
                {/*   autoPlay */}
                {/*   // ref={(ref) => ref} */}
                {/*   loop */}
                {/*   source={require('../../../../../../assets/lottie/tracking.json')} */}
                {/*   style={{ width: 60 }} */}
                {/*   containerWidth={60} */}
                {/* /> */}
              </View>
              <Text size="3xl" mt="4xl" color="primary.500" textAlign="center">
                Track Your Car
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Button
        rounded="md+lg"
        shadow={theme === KioskTheme.LIGHT ? 'md' : 'none'}
        onPress={onBeginAction}
        size="10xl"
      >
        Tap Here To Begin
      </Button>
    </View>
  );
};

export default RecallWelcomeScreen;
