/* eslint-disable no-nested-ternary */
import React from 'react';
import { ScrollView, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Button, Text, View } from '../../../components/new';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import DiscountCard from './DiscountCard';
import { getTheme } from '../../../themes/new/theme';
import { DiscountDaysEnum, MealTimeEnum } from './helper';
import { useDiscount } from './useDiscount';
import { isValueNullOrEmpty } from '../../../utilities/Utility';

const DiscountScreenInner = ({
  queryOptions,
  refresh,
}: {
  queryOptions: NewErrorBoundaryParentState;
  refresh: () => void;
}) => {
  const { lunchData, dinnerData } = useDiscount(queryOptions, refresh);
  const navigation = useNavigation();
  const { width } = useWindowDimensions();

  const SectionComponent = ({ sectionTitle, data, section }) => {
    const weekDayData = data?.filter(
      (item) => item?.days === DiscountDaysEnum.WEEKDAY,
    );
    const weekendData = data?.filter(
      (item) => item?.days === DiscountDaysEnum.WEEKEND,
    );
    return (
      <View>
        <Text size="md" color="primary.300" px="2xl">
          {sectionTitle}
        </Text>
        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <View my="2xl" ml="2xl">
            <DiscountCard
              allUserDiscount={weekDayData[0]?.discounts?.allUser}
              newUserDiscount={weekDayData[0]?.discounts?.newUser}
              corporateDiscount={weekDayData[0]?.discounts?.corporateUser}
              subTitle="Mon - Thu"
              onPress={() => {
                if (
                  isValueNullOrEmpty(weekDayData[0]?.discounts?.allUser) &&
                  isValueNullOrEmpty(weekDayData[0]?.discounts?.newUser) &&
                  isValueNullOrEmpty(weekDayData[0]?.discounts?.corporateUser)
                ) {
                  navigation.navigate('CreateDiscount', {
                    data: {
                      mealTime: section,
                      days: DiscountDaysEnum.WEEKDAY,
                      discountId: '645492bed8b6d12b41ab1e59',
                    },
                  });
                } else {
                  navigation.navigate('CreateDiscount', {
                    discountId: weekDayData[0]?._id,
                    data: {
                      mealTime: section,
                      days: DiscountDaysEnum.WEEKDAY,
                    },
                  });
                }
              }}
              widthProp={(width - getTheme().space['2xl']) * 0.44}
              aspectRatio={1.064}
            />
          </View>
          <View my="2xl" mx="2xl">
            <DiscountCard
              allUserDiscount={weekendData[0]?.discounts?.allUser}
              newUserDiscount={weekendData[0]?.discounts?.newUser}
              corporateDiscount={weekendData[0]?.discounts?.corporateUser}
              subTitle="Fri - Sun"
              onPress={() => {
                if (
                  isValueNullOrEmpty(weekendData[0]?.discounts?.allUser) &&
                  isValueNullOrEmpty(weekendData[0]?.discounts?.newUser) &&
                  isValueNullOrEmpty(weekendData[0]?.discounts?.corporateUser)
                ) {
                  navigation.navigate('CreateDiscount', {
                    data: {
                      mealTime: section,
                      days: DiscountDaysEnum.WEEKEND,
                      discountId: '645492bed8b6d12b41ab1e59',
                    },
                  });
                } else {
                  navigation.navigate('CreateDiscount', {
                    discountId: weekendData[0]?._id,
                    data: {
                      mealTime: section,
                      days: DiscountDaysEnum.WEEKEND,
                    },
                  });
                }
              }}
              widthProp={(width - getTheme().space['2xl']) * 0.44}
              aspectRatio={1.064}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <View flex={1}>
      <ScrollView
        contentContainerStyle={{ marginTop: getTheme().space['2xl'] }}
      >
        <View>
          <SectionComponent
            sectionTitle="Lunch Discounts (6AM - 6PM)"
            data={lunchData}
            section={MealTimeEnum.LUNCH}
          />
        </View>
        <View mt="2xl">
          <SectionComponent
            sectionTitle="Dinner Discounts (6PM - 6AM)"
            data={dinnerData}
            section={MealTimeEnum.DINNER}
          />
        </View>
        <View mt="2xl" px="2xl">
          <View flexDirection="row" alignItems="center">
            <Text size="sm" color="primary.200">
              To view active discounts
            </Text>
            <Button
              size="md"
              appearance="ghost"
              status="info"
              iconRight="keyboard-arrow-right-300"
              ml="sm"
              onPress={() => {
                navigation.navigate('TrackDiscount');
              }}
            >
              Go to Track Page
            </Button>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default DiscountScreenInner;
