/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  Button,
  IconButton,
  Input,
  Layout,
  TopNavigation,
  View,
} from '../../components/new';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { claimRewardByStaff } from '../../relay/rewardApi';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import { getTheme, themeModes } from '../../themes/new/theme';

const StaffAddManuallyBarcodeScreen = ({ navigation, route }) => {
  const scrollViewRef = useRef(null);
  const [rewardId, setRewardId] = useState('');
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  useEffect(() => {
    return () => {
      setRewardId('');
      Keyboard.dismiss();
    };
  }, []);

  const onPressUpdate = () => {
    Keyboard.dismiss();
    claimRewardByStaff(
      { rewardUniqueId: rewardId },
      (res) => {
        setTimeout(() => {
          navigation.replace('StaffRewardScreen', {
            data: {
              isUsed: res?.claimRewardByStaff?.isUsed,
              isExpired: res?.claimRewardByStaff?.isExpired,
              rewardUniqueId: res?.claimRewardByStaff?.rewardUniqueId,
              rewardType: res?.claimRewardByStaff?.rewardType,
              expireAt: res?.claimRewardByStaff?.expireAt,
              created_at: res?.claimRewardByStaff?.created_at,
              user: res?.claimRewardByStaff?.user,
              showReedemedStatus: true,
            },
          });
        }, 200);
      },
      (error) => {
        if (error?.res?.errors[0]?.extensions?.code === 103) {
          setTimeout(() => {
            navigation.navigate('StaffRewardScreen', {
              data: {
                isUsed: true,
                isExpired: false,
                rewardUniqueId:
                  error?.res?.errors[0]?.extensions?.data?.reward
                    ?.rewardUniqueId,
                rewardType:
                  error?.res?.errors[0]?.extensions?.data?.reward?.rewardType,
                expireAt:
                  error?.res?.errors[0]?.extensions?.data?.reward?.expireAt,
                created_at:
                  error?.res?.errors[0]?.extensions?.data?.reward?.created_at,
                user: error?.res?.errors[0]?.extensions?.data?.user,
                showAlreadyRedeemedStatus: true,
                gradientColors: [
                  {
                    offset: '0',
                    color: getTheme(themeModes.light).colors.warning[10],
                    opacity: 1,
                  },
                  {
                    offset: '1',
                    color: getTheme(themeModes.light).colors.warning[10],
                    opacity: 1,
                  },
                ],
              },
            });
          }, 200);
        } else if (error?.res?.errors[0]?.extensions?.code === 102) {
          setTimeout(() => {
            navigation.navigate('StaffRewardScreen', {
              data: {
                isUsed: false,
                isExpired: true,
                rewardUniqueId:
                  error?.res?.errors[0]?.extensions?.data?.reward
                    ?.rewardUniqueId,
                rewardType:
                  error?.res?.errors[0]?.extensions?.data?.reward?.rewardType,
                expireAt:
                  error?.res?.errors[0]?.extensions?.data?.reward?.expireAt,
                created_at:
                  error?.res?.errors[0]?.extensions?.data?.reward?.created_at,
                user: error?.res?.errors[0]?.extensions?.data?.user,
                showExpiredStatus: true,
                gradientColors: [
                  {
                    offset: '0',
                    color: getTheme(themeModes.light).colors.error[10],
                    opacity: 1,
                  },
                  {
                    offset: '1',
                    color: getTheme(themeModes.light).colors.error[10],
                    opacity: 1,
                  },
                ],
              },
            });
          }, 200);
        } else {
          dispatchSnackbar({
            msg: `${error?.res?.errors[0]?.message || 'Something Went Wrong!'}`,
            status: SnackbarStatus?.error,
            version: SnackbarVersion?.v2,
          });
        }
      },
    );
  };

  const onPressLeftIcon = () => {
    Keyboard.dismiss();
    navigation.replace('StaffBarcodeScannerScreen');
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        title="Add Manually"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            onPress={onPressLeftIcon}
          />
        }
      />
      <KeyboardAvoidingView
        // style={Platform.OS === 'web' ? {} : { flex: 1 }}
        style={{ flex: 1 }}
        behavior={
          Platform.OS === 'android'
            ? null
            : Platform.OS === 'ios'
            ? 'padding'
            : null
        }
        // {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
      >
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 30,
          }}
          ref={scrollViewRef}
          keyboardShouldPersistTaps="handled"
        >
          <TouchableWithoutFeedback
            onPress={() => {
              Keyboard.dismiss();
              scrollViewRef.current?.scrollTo({
                x: 10,
                y: 120,
                animated: true,
              });
            }}
          >
            <View px="2xl">
              <View>
                <View mt="7xl">
                  <Input
                    inputMode="words"
                    label="Enter Barcode Number"
                    autoCapitalize="characters"
                    returnKeyType="next"
                    placeholder="XXXXXXXX"
                    value={rewardId}
                    onChangeText={setRewardId}
                    maxLength={8}
                    autoFocus
                  />
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
        <View m="2xl" bottom="sm">
          <Button
            size="lg"
            appearance="filled"
            status="primary"
            state={rewardId?.length >= 8 ? 'active' : 'disabled'}
            onPress={onPressUpdate}
          >
            Continue
          </Button>
        </View>
      </KeyboardAvoidingView>
    </Layout>
  );
};

export default StaffAddManuallyBarcodeScreen;
