import React, { forwardRef } from 'react';
import LottieView, { LottieViewProps } from 'lottie-react-native';
import View from '../basic/View/View';

interface CustomLottieViewProps extends LottieViewProps {
  containerWidth?: number;
}

const CustomLottieView = forwardRef(
  (props: CustomLottieViewProps, ref: React.ForwardedRef<LottieView>) => {
    return (
      <View width={props?.containerWidth || '100%'}>
        <LottieView {...props} ref={ref} />
      </View>
    );
  },
);

export default CustomLottieView;
