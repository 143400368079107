/* eslint-disable no-nested-ternary */
/* eslint-disable no-extra-boolean-cast */
import React, { useState, useRef, useEffect } from 'react';
import {
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';
// import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import { View, Button, Input } from '../../components/new';
import validateWrapper from '../../utilities/validationWrapper';
import { updateUser } from '../../relay/userApi';
import { generateOTP } from '../../relay/authApi';
import { EmailTypes, navigateBack, phoneFormat } from '../../utilities/helper';
import { InputStates } from '../../components/new/primitive/MobileInput/helpers';
import useUserStore from '../../stores/userStore';
import InternationalPhoneInput from '../../components/new/custom/InternationalPhoneInput';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../utilities/firbaseAnalytics';

type Props = {
  userId: string;
  user: any;
  onGoingback;
};

const EditProfileUser = (props: Props) => {
  const { userId, user, onGoingback } = props;

  const userRole = useUserStore((state) => state.role);

  const navigation = useNavigation();

  const lastName = user.name.last !== null ? user.name.last : '';
  const name = `${user.name.first} ${lastName}`;
  const countryPhoneCode = user.contact.phone.prefix || '+91';
  //   const phone = user.contact.phone.prefix + user.contact.phone.number;
  const phone = phoneFormat(user.contact.phone.number);
  const primaryEmail = (() => {
    const pe = user?.contact?.email?.find(
      (e) => e.type === EmailTypes.PERSONAL,
    );
    if (pe) {
      return {
        address: pe.addr,
        verified: pe.verified,
      };
    }
    return null;
  })();

  const workEmail = (() => {
    const we = user?.contact?.email?.find((e) => e.type === EmailTypes.WORK);
    if (we) {
      return {
        address: we.addr,
        verified: we.verified,
      };
    }
    return null;
  })();

  const [fullName, setFullName] = useState(name);
  const [fullNameError, setFullNameError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [countryCode, setCountryCode] = useState(countryPhoneCode);
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const [userErrorText, setUserErrorText] = useState('');
  const [editButtonDisable, setEditButtonDisable] = useState(true);
  const [inputStatus, setInputStatus] = useState(InputStates.default);
  const textInputRef = useRef(null);
  const inputRef = useRef(null);
  const scrollViewRef = useRef(null);
  const validateProfile = (fieldName) => {
    let validationError = '';
    if (fieldName === 'fullName') {
      const error = validateWrapper(fieldName, fullName);
      if (error) {
        setFullNameError(error);
      } else {
        setFullNameError('');
      }

      validationError = error;
    }
    if (fieldName === 'mobileNumber') {
      const result = phoneFormat(phoneNumber).replace(/\D/g, '');
      //   const error = validateWrapper(fieldName, phoneNumber);
      const error = validateWrapper('mobileNumber', result);
      if (error) {
        setPhoneNumberError(error);
      } else {
        setPhoneNumberError('');
      }

      validationError = error;
    }
    return validationError;
  };

  const refCallback = (InputRef) => (node) => {
    InputRef.current = node;
  };
  const handleUpdateUser = (response) => {
    setEditButtonDisable(false);
    if (response.updateUser !== null) {
      navigateBack(navigation, userRole);
    }
  };

  const handleGenerateOtp = (response, data, opData) => {
    if (response.generateOTP !== null) {
      setEditButtonDisable(false);
      navigation.navigate('phoneVerify', opData);
    } else {
      setEditButtonDisable(false);
      setPhoneNumberError('Please enter a valid phone number.');
    }
  };
  const ApiErrorHandler = (error) => {
    console.log(error);
  };
  const editProfile = () => {
    firebaseEventLogger('editProfile__updateName_Tap', {
      buttonName: 'updateName',
      screenName: 'editProfile',
      userType: 'user',
      interactionType: InteractionType.TAP,
    });
    const formattedNo = phoneFormat(phoneNumber).replace(/\D/g, '').toString();
    const data = {};
    (data as any).id = userId;
    // const phoneError = validateProfile('mobileNumber');
    const nameError = validateProfile('fullName');
    // const nameError = validateUserName('fullName');
    (data as any).record = {};
    (data as any).record.name = {};
    // eslint-disable-next-line quotes
    const nameArr = fullName.trim().split(' ');
    if (nameArr.length > 1) {
      (data as any).record.name.first = nameArr[0];
      (data as any).record.name.last = nameArr
        .slice(1)
        .join(' ')
        .replace(/\s\s+/g, ' ')
        .trim();
    } else {
      (data as any).record.name.first = fullName;
    }
    if (!nameError && formattedNo.length === 10) {
      if (phone !== phoneNumber.trim()) {
        (data as any).record.contact = {};
        (data as any).record.contact.phone = {};
        // const countryCode = /[0-9+]{3}/.exec(phoneNumber)[0];
        // const formattedNo = Number(
        //   phoneNumber.replace(/\D/g, '').substr(2, 13),
        // );

        (data as any).record.contact.phone = {
          prefix: countryCode,
          number: formattedNo.toString(),
        };
        setEditButtonDisable(true);
        generateOTP((data as any).record.contact, handleGenerateOtp, data);
      } else {
        setEditButtonDisable(true);
        updateUser(data, handleUpdateUser, ApiErrorHandler);
      }
    }
  };

  const handleUserName = (val) => {
    setFullName(val);
    validateUserName(val);
    onGoingback(val, phoneNumber, user);
  };

  const validateUserName = (val) => {
    const error = validateWrapper('fullName', val);
    if (error) {
      setUserErrorText(error);

      setInputStatus(InputStates.error);
      setEditButtonDisable(true);
    } else {
      setUserErrorText('');

      setInputStatus(InputStates.default);
      setEditButtonDisable(false);
    }
    return error;
  };

  const handlePhoneInput = (value) => {
    const result = phoneFormat(value);
    setPhoneNumber(phoneFormat(value));

    if (result.length < 11) {
      setEditButtonDisable(true);
    } else if (result.length > 11) {
      const error = validateWrapper('mobileNumber', result);
      // setHelperText('please enter the 10 digit mobile number')
      setPhoneNumberError(error);
      setInputStatus(InputStates.error);
      if (error) {
        setEditButtonDisable(true);
      } else {
        setEditButtonDisable(false);
      }
    } else {
      setPhoneNumberError('');
      setInputStatus(InputStates.default);
      setEditButtonDisable(false);
    }
  };

  const validationClear = () => {
    setFullNameError('');
  };
  useEffect(() => {
    if (
      name.toLowerCase() === fullName.trim().toLowerCase() &&
      phone === phoneNumber.trim()
    ) {
      setEditButtonDisable(true);
    } else {
      // setEditButtonDisable(false);
    }
  }, [fullName, fullNameError, phoneNumber, phoneNumberError]);

  useEffect(() => {
    if (textInputRef.current) {
      // textInputRef.current.focus();
    }
  }, []);

  return (
    <KeyboardAvoidingView
      // style={Platform.OS === 'web' ? {} : { flex: 1 }}
      style={{ flex: 1 }}
      behavior={
        Platform.OS === 'android'
          ? null
          : Platform.OS === 'ios'
          ? 'padding'
          : null
      }
      // {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
    >
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 30,
        }}
        ref={scrollViewRef}
        keyboardShouldPersistTaps="handled"
      >
        <TouchableWithoutFeedback
          onPress={() => {
            Keyboard.dismiss();
            scrollViewRef.current?.scrollTo({
              x: 10,
              y: 120,
              animated: true,
            });
            //
          }}
        >
          <View px="2xl">
            <View>
              <View mt="7xl">
                <Input
                  state={
                    userErrorText === ''
                      ? InputStates.default
                      : InputStates.error
                  }
                  inputMode="text"
                  inputRef={textInputRef}
                  label="Full Name"
                  autoCapitalize="words"
                  returnKeyType="next"
                  placeholder="e.g. Robin Singh"
                  helperText={userErrorText}
                  value={fullName}
                  onChangeText={handleUserName}
                  borderColor="primary.50"
                  style={{ borderColor: 'white' }}
                  onBlur={() => {
                    // validateUserName(fullName);
                    validateProfile('fullName');
                    // scrollViewRef.current?.scrollTo({
                    //   x: 0,
                    //   y: 0,
                    //   animated: true,
                    // });
                  }}
                  onFocus={() => {
                    setUserErrorText('');
                    scrollViewRef.current?.scrollTo({
                      x: 10,
                      y: 205,
                      animated: true,
                    });
                  }}
                />
              </View>

              <View mt="4xl">
                <InternationalPhoneInput
                  label="Mobile Number"
                  onChangeNumber={() => {}}
                  onChangeCountry={() => {}}
                  defaultValue={phoneNumber}
                  defaultCallingCode={countryPhoneCode.slice(1)}
                  autofocus={false}
                  viewOnly
                />
              </View>

              {primaryEmail ? (
                <View mt="4xl">
                  <Button
                    style={{
                      paddingVertical: 0,
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 99999,
                    }}
                    status="info"
                    appearance="ghost"
                    state="active"
                    size="sm"
                    onPress={() => {
                      navigation.navigate('editEmail', {
                        editMode: true,
                        type: EmailTypes.PERSONAL,
                        email: primaryEmail?.address,
                        userId,
                      });
                    }}
                  >
                    Change
                  </Button>
                  <Input
                    state={InputStates.default}
                    autoFocus={false}
                    disabled
                    editable={false}
                    autoCapitalize="none"
                    label="Primary Email ID"
                    rightIconName={
                      primaryEmail?.verified ? 'check-outline-400' : ''
                    }
                    rightIconColor="success.500"
                    value={primaryEmail?.address}
                    borderColor="primary.50"
                    color="primary.100"
                    style={{ borderColor: 'white' }}
                  />
                </View>
              ) : (
                <View mt="4xl" alignItems="flex-start">
                  <Button
                    status="info"
                    appearance="ghost"
                    iconSize="md"
                    iconLeft="add-outline-300"
                    state="active"
                    size="md"
                    onPress={() => {
                      firebaseEventLogger('editProfile__primaryEmail_Tap', {
                        buttonName: 'primaryEmail',
                        screenName: 'editProfile',
                        userType: 'user',
                        interactionType: InteractionType.TAP,
                      });
                      navigation.navigate('editEmail', {
                        editMode: false,
                        type: EmailTypes.PERSONAL,
                        userId,
                      });
                    }}
                  >
                    Primary Email ID
                  </Button>
                </View>
              )}

              {workEmail ? (
                <View mt="4xl">
                  <Button
                    style={{
                      paddingVertical: 0,
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 99999,
                    }}
                    status="info"
                    appearance="ghost"
                    state="active"
                    size="sm"
                    onPress={() => {
                      firebaseEventLogger('editProfile__workEmail_Tap', {
                        buttonName: 'workEmail',
                        screenName: 'editProfile',
                        userType: 'user',
                        interactionType: InteractionType.TAP,
                      });
                      navigation.navigate('editEmail', {
                        editMode: true,
                        type: EmailTypes.WORK,
                        email: workEmail?.address,
                        userId,
                      });
                    }}
                  >
                    Change
                  </Button>
                  <Input
                    state={InputStates.default}
                    autoFocus={false}
                    disabled
                    editable={false}
                    autoCapitalize="none"
                    label="Work Email ID"
                    rightIconName={
                      workEmail?.verified ? 'check-outline-400' : ''
                    }
                    rightIconColor="success.500"
                    value={workEmail?.address}
                    borderColor="primary.50"
                    color="primary.100"
                    style={{ borderColor: 'white' }}
                  />
                </View>
              ) : (
                <View mt="4xl" alignItems="flex-start">
                  <Button
                    status="info"
                    appearance="ghost"
                    iconSize="md"
                    iconLeft="add-outline-300"
                    state="active"
                    size="md"
                    onPress={() => {
                      navigation.navigate('editEmail', {
                        editMode: false,
                        type: EmailTypes.WORK,
                        userId,
                      });
                    }}
                  >
                    Work Email ID
                  </Button>
                </View>
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
      <View m="2xl" bottom="sm">
        <Button
          size="lg"
          appearance="filled"
          status="primary"
          state={editButtonDisable === true ? 'disabled' : 'active'}
          onPress={editProfile}
        >
          Update
        </Button>
      </View>
    </KeyboardAvoidingView>
  );
};

export default EditProfileUser;
