/**
 * @generated SignedSource<<3a4fd75a04bb29951b5826c530b210b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumaddonType = "a_32ND_BLACK_MEMBERSHIP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type profileCardQueryAddonFragment_findAddonQuery$data = ReadonlyArray<{
  readonly _id: any;
  readonly type: EnumaddonType;
  readonly " $fragmentType": "profileCardQueryAddonFragment_findAddonQuery";
}>;
export type profileCardQueryAddonFragment_findAddonQuery$key = ReadonlyArray<{
  readonly " $data"?: profileCardQueryAddonFragment_findAddonQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"profileCardQueryAddonFragment_findAddonQuery">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "profileCardQueryAddonFragment_findAddonQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "addon",
  "abstractKey": null
};

(node as any).hash = "510bf7e4607dc2b357f2f8bf5e0ccef3";

export default node;
