import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import * as Linking from 'expo-linking';
import { useNavigation } from '@react-navigation/native';
import { Button, MobileInput, Text, View } from '../new';
import useLoginModalStore, {
  LoginModalScreenSteps,
} from '../../stores/loginModalStore';
import { InputStates } from '../new/primitive/MobileInput/helpers';
import { phoneFormat } from '../../utilities/helper';
import validateWrapper from '../../utilities/validationWrapper';
import { checkUser, CheckUserData, generateOTP } from '../../relay/authApi';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import CustomPressable from '../new/primitive/CustomPressable/CustomPressable';
import { navigationRef } from '../../navigation/RootNavigation';
import useCommonStore from '../../stores/commonStore';
import AppConstants from '../../utilities/AppConstants';
import InternationalPhoneInput from '../new/custom/InternationalPhoneInput';

const EnterPhoneNumber = () => {
  const navigation = useNavigation<any>();
  const source = navigationRef?.current?.getCurrentRoute()?.name;
  const componentState = useLoginModalStore((state) => state);
  const commonStore = useCommonStore((state) => state);

  const [phoneNumber, setPhoneNumber] = useState<string>(
    componentState.mobileNumber,
  );
  const [callingCode, setCallingCode] = useState<string>(
    componentState.countryCallingCode,
  );

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(
    componentState.mobileNumber.length !== 10,
  );

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
    if (value.length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const handleCountryChange = (countryCode: string) => {
    setCallingCode(countryCode);
  };

  const onContinue = () => {
    firebaseEventLogger('login__mobileLogin_Data', {
      buttonName: 'mobileLogin',
      screenName: 'login',
      userType: 'user',
      interactionType: 'data',
    });
    componentState.setMobileNumber(phoneNumber);

    const payload: CheckUserData = {
      phone: { number: phoneNumber, prefix: `+${callingCode}` },
    };

    checkUser(payload)
      .then((data) => {
        if (data?.checkUser === null || !data?.checkUser?.firstLogin) {
          // navigate to signup
          if (data?.checkUser?.firstLogin === false) {
            // set secondary signup
            componentState.setSecondarySignup(true);
          }
          componentState.setSignupPayload({
            name: {
              first: data?.checkUser?.name?.first,
              last: data?.checkUser?.name?.last,
            },
          });
          componentState.setCountryCallingCode(callingCode);
          commonStore.setWasSignupFlowActive(true);
          componentState.setCurrentScreen(LoginModalScreenSteps.SIGN_UP);
        } else {
          // send otp
          componentState.setCountryCallingCode(callingCode);
          commonStore.setWasSignupFlowActive(false);
          generateOTP(payload, () => {});
          componentState.setCurrentScreen(LoginModalScreenSteps.ENTER_OTP);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const whatsAppLogin = async () => {
    firebaseEventLogger('otp__whatsappLogin_Data', {
      buttonName: 'whatsappLogin',
      screenName: 'otp',
      userType: 'user',
      interactionType: 'data',
      source,
    });

    try {
      if (Platform.OS !== 'web') {
        const url = `https://32nd.authlink.me/?redirectUri=${AppConstants.SCHEME}://login`;
        await Linking.openURL(url);
      } else {
        const url = `https://32nd.authlink.me/?redirectUri=${AppConstants.WEB_URL}/login`;
        await Linking.openURL(url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const redirectWebPage = (type) => {
    const url =
      type === 1
        ? 'https://club.32nd.com/t&c'
        : 'https://club.32nd.com/privacy-policy';
    if (Platform.OS === 'web') {
      const baseUrl = window.location?.origin;
      window.open(`${baseUrl}/${type === 1 ? 't&c' : 'privacy-policy'}`);
    } else {
      componentState.setIsOpen(false);
      navigation?.navigate('CustomWebview', {
        title: type === 1 ? 'Terms and Conditions' : 'Privacy policy',
        url,
      });
    }
  };

  return (
    <View justifyContent="center" p="2xl">
      <Text size="3xl" color="primary.200">
        {componentState.loginScreenTitle}
      </Text>
      <View mt="2xl">
        <View pt="xs" mb="3xl">
          <InternationalPhoneInput
            autofocus={Platform.OS !== 'web'}
            label="Mobile Number"
            defaultValue={phoneNumber}
            defaultCallingCode={callingCode}
            onChangeNumber={handlePhoneNumberChange}
            onChangeCountry={handleCountryChange}
          />
        </View>
        {componentState.whatsappLoginEnabled && (
          <>
            <View
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <View width={85} height={1} backgroundColor="primary.50">
                {}
              </View>
              <View mx="2xl">
                <Text weight="medium" size="xs" color="primary.50">
                  OR
                </Text>
              </View>
              <View width={85} height={1} backgroundColor="primary.50">
                {}
              </View>
            </View>
            <View
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              my="md"
            >
              <CustomPressable
                appearance="ghost"
                size="lg"
                textSize="sm"
                iconSize="sm"
                leftStyle={{ width: 20, height: 20 }}
                state="active"
                status="primary"
                leftContent={require('../../../assets/whatsapp.png')}
                mb="2xl"
                onPress={() => {
                  whatsAppLogin();
                }}
              >
                Continue Via WhatsApp
              </CustomPressable>
            </View>
          </>
        )}
        <View bottom="xl" style={{ marginTop: 16 }}>
          <Button
            size="lg"
            appearance="filled"
            status="primary"
            state={buttonDisabled === true ? 'disabled' : 'active'}
            onPress={onContinue}
          >
            Continue
          </Button>
        </View>
        <View>
          <Text
            mx="2xl"
            mb={Platform.OS === 'web' ? '4xl' : 0}
            style={{ textAlign: 'center' }}
            size="xs"
            color="primary.200"
          >
            By Signing up, you agree to our{' '}
            <Text
              color="info.500"
              onPress={() => {
                firebaseEventLogger('login__termsAndConditions_tap', {
                  buttonName: 'termsAndConditions',
                  screenName: 'login',
                  userType: 'user',
                  interactionType: 'tap',
                });
                redirectWebPage(1);
              }}
            >
              Terms and Conditions
            </Text>{' '}
            and{' '}
            <Text
              color="info.500"
              onPress={() => {
                firebaseEventLogger('login__privacyPolicy_tap', {
                  buttonName: 'privacyPolicy',
                  screenName: 'loginSignupHome',
                  userType: 'user',
                  interactionType: 'tap',
                });
                redirectWebPage(2);
              }}
            >
              Privacy policy
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );
};

export default EnterPhoneNumber;
