/* eslint-disable no-extra-boolean-cast */
import React, { memo, useEffect, useRef, useState } from 'react';
import { Text, View } from '../../components/new';
import { space } from '../../themes/new/theme';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

const areEqual = () => {
  return true;
};

const PMLoader = () => {
  let interval = null;
  const animation = useRef(null);
  const [activeKey, setActiveKey] = useState(0);
  const data = [
    {
      id: 1,
      title: 'Finding your 32nd Moments...',
      lottiePath: require('../../../assets/lottie/pm_loader1.json'),
    },
    {
      id: 2,
      title: '98% people find their Moments within a few seconds.',
      lottiePath: require('../../../assets/lottie/pm_loader2.json'),
    },
    {
      id: 3,
      title: `It's taking a little longer, but it'll be worth the wait!`,
      lottiePath: require('../../../assets/lottie/pm_loader3.json'),
    },
  ];

  useEffect(() => {
    let current = activeKey;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    interval = setInterval(() => {
      if (activeKey < data.length - 1) {
        // eslint-disable-next-line no-plusplus
        setActiveKey(current++);
      } else {
        setActiveKey(0);
      }
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, [activeKey]);

  return (
    <>
      <View flex={1} justifyContent="center" alignItems="center" px="2xl">
        <Text size="2xl" color="primary.400" textAlign="center" weight="medium">
          {data?.[activeKey]?.title}
        </Text>
        <CustomLottieView
          autoPlay
          loop
          ref={animation}
          style={{
            height: 150,
            width: 150,
            paddingTop: space['2xl'],
          }}
          source={data?.[activeKey]?.lottiePath}
          containerWidth={150}
        />
        <View height={space['9xl+9xl']} />
      </View>
    </>
  );
};

export default memo(PMLoader, areEqual);
