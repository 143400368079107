import React, { Suspense, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { ImageBackground } from 'react-native';
import {
  Heading,
  IconButton,
  Layout,
  TopNavigation,
  View,
} from '../../components/new';
import Loading from '../../components/loading.component';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import { navigateBack } from '../../utilities/helper';
import { space } from '../../themes/new/theme';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import LightThemeWrapper from './wrapperComp/LightThemeWrapper';
import ParkingCardNew from '../../components/new/custom/ParkingCardNew';
import useUserStore from '../../stores/userStore';

const FreeParkingScreenNew = ({ navigation, route }) => {
  const { rewardId, showViewRewards, parkingId, rewardOrigin } =
    (route && route?.params) ?? {};
  const [netStatus, setNetStatus] = useState(true);

  const userRole = useUserStore((state) => state.role);
  const userId = useUserStore((state) => state.id);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const freeParkingScreenQuery = graphql`
    query FreeParkingScreenNewQuery($filter: findrewardFilterInput!) {
      findReward(filter: $filter) {
        _id
        parkingId
        expireAt
        rewardType
        created_at
        rewardUniqueId
        user {
          name {
            first
            last
          }
        }
      }
    }
  `;
  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  const RenderFreeParkingScreen = (props) => {
    const { variables } = props;
    const { findReward } =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useLazyLoadQuery(freeParkingScreenQuery, variables, {
        ...refreshedQueryOptions,
      });
    const {
      // parkingId,
      user,
      expireAt,
      created_at,
      rewardUniqueId,
      rewardType,
    } = findReward[0] ?? {};

    const reward = 'Free Parking Voucher';

    return (
      <View mt={space['9xl'] + space.lg} alignItems="center" flex={1}>
        <Heading size="3xl" color="primary.20" textAlign="center" px="2xl">
          Apologies for the delay Enjoy free parking
        </Heading>
        <ParkingCardNew
          reward={reward}
          rewardType={rewardType}
          rewardId={rewardUniqueId}
          owner={user?.name}
          expireAt={expireAt}
          createdAt={created_at}
        />
      </View>
    );
  };

  return (
    <Layout level={2}>
      <LightThemeWrapper>
        <ImageBackground
          source={{
            uri: 'https://ik.imagekit.io/32ndhub/assets/bg_ontime_blur.png',
          }}
          style={{
            height: '100%',
          }}
        >
          <View flex={1}>
            <TopNavigation
              IconRight={
                <IconButton
                  name="close-outline2-400"
                  size="md"
                  appearance="ghost"
                  iconSize="2xl"
                  iconColor="primary.10"
                  shape="square"
                  onPress={onPressLeftIcon}
                />
              }
              appearance="ghost"
              level="none"
              title=""
            />
            <NewErrorBoundary
              fetchKey={refreshedQueryOptions.fetchKey}
              fallback={
                <NewErrorView
                  errorMsg="Sorry something went wrong"
                  reload={refresh}
                />
              }
            >
              <Suspense fallback={<Loading />}>
                <RenderFreeParkingScreen
                  variables={{
                    filter: { ownerId: userId, parkingId, rewardOrigin },
                  }}
                  fetchKey={refreshedQueryOptions.fetchKey}
                />
              </Suspense>
            </NewErrorBoundary>
            <DisconnectedDropover
              setNetStatus={setNetStatus}
              text="No Internet Connection"
              icon="wifi-off-outline"
            />
          </View>
        </ImageBackground>
      </LightThemeWrapper>
    </Layout>
  );
};

export default FreeParkingScreenNew;
