import React, { useEffect } from 'react';
import {
  Animated,
  Easing,
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native';
import View from '../../basic/View/View';

// TODO remake this comopnent
const CustomToggle = (props) => {
  const animatedValue = React.useRef(new Animated.Value(0)).current;
  // const animatedValue = new Animated.Value(0);

  //  const moveToggle = React.useRef(null)

  const moveToggle = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 18],
  });

  const {
    isOn,
    onColor = '#4cd964',
    offColor = '#e5e5e5',
    onToggle,
    labelStyle,
    label,
  } = props;
  const color = isOn ? onColor : offColor;
  useEffect(() => {
    animatedValue.setValue(isOn ? 1 : 0);

    Animated.timing(animatedValue, {
      toValue: isOn ? 1 : 0,
      duration: 200,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  }, [isOn]);

  return (
    <View style={styles.container}>
      {!!label && <Text style={[styles.label, labelStyle]}>{label}</Text>}

      <TouchableOpacity
        activeOpacity={1}
        onPress={typeof onToggle === 'function' && onToggle}
      >
        <View
          p="xs"
          style={[styles.toggleContainer, { backgroundColor: color }]}
        >
          <Animated.View
            style={[
              styles.toggleWheelStyle,
              {
                marginLeft: moveToggle,
              },
            ]}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};

// CustomToggle.defaultProps = {
//   onColor: '#4cd964',
//   offColor: '#e5e5e5',
//   label: '',
//   onToggle: () => {},
//   style: {},
//   isOn: false,
//   labelStyle: {},
// };

export default React.memo(CustomToggle);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleContainer: {
    width: 40,
    // height: 31,
    // paddingRight:2,
    marginLeft: 3,
    borderRadius: 18,
    justifyContent: 'center',
    // alignItems:'flex-end'
  },
  label: {
    marginRight: 2,
  },
  toggleWheelStyle: {
    width: 18,
    height: 18,
    backgroundColor: 'white',
    borderRadius: 18,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.5,
    elevation: 1.5,
  },
});
