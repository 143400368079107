import React, { memo } from 'react';
import View from '../../../basic/View/View';
import TicketQrCode from '../components/TicketQrCode';
import BaseTicket, { BaseTicketProps } from '../BaseTicket';
import { RedeemMode } from '../enums';
import CouponCode from '../components/CouponCode';
import TicketStates, { State } from '../components/TicketStates';

type LocationType = { location: string; lat: string; long: string };
type QrConfig = { mode: RedeemMode.QR };
type CouponConfig = {
  mode: RedeemMode.COUPON;
  amount: string;
  disableBtn?: boolean;
  onApply: (...args: any) => void;
};

export interface RewardTicketProps
  extends Omit<BaseTicketProps, 'MoreLabels' | 'BottomContent'> {
  state?: State;
  redeemConfig?: QrConfig | CouponConfig;
}

const RewardTicket = ({
  state,
  redeemConfig,
  ...baseTicketProps
}: RewardTicketProps) => {
  const { mode } = redeemConfig ?? {};
  let height = 650;
  let RedeemModeComponent;

  if (mode === RedeemMode.QR) {
    height = 650;
    RedeemModeComponent = <TicketQrCode data={baseTicketProps.ticketId} />;
  } else if (mode === RedeemMode.COUPON) {
    const {
      onApply,
      amount,
      disableBtn = false,
    } = redeemConfig as CouponConfig;
    height = 600;
    RedeemModeComponent = (
      <CouponCode
        buttonText={`FLAT ${amount}`}
        disabled={disableBtn}
        onPress={onApply}
      />
    );
  }

  return (
    <View maxHeight={800} height={height}>
      <BaseTicket
        {...baseTicketProps}
        BottomContent={
          state ? <TicketStates state={state} /> : RedeemModeComponent
        }
      />
    </View>
  );
};

export default memo(RewardTicket);
