import React from 'react';
import { Icon, Text, View } from '../../../../components/new';

const NoContentView = ({ searchedVehicleNo = '' }) => {
  return (
    <View mt="112" alignItems="center">
      <Icon name="search-outline-400" color="primary.100" size="sm+9xl" />
      <Text mt="2xl" weight="medium" size="xl" color="primary.400">
        No Results for “{searchedVehicleNo}”
      </Text>
      <Text mt="sm" size="sm" color="primary.100">
        Check the VRN or try a new search
      </Text>
    </View>
  );
};

export default NoContentView;
