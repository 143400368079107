/**
 * @generated SignedSource<<9ea0f37ae035b5bd6138bccb09d0f3f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnuminvestmentPropertyIntroMediaMediaType = "IMAGE" | "VIDEO" | "%future added value";
export type EnuminvestmentPropertyInvestmentType = "FRACTIONAL_OWNERSHIP" | "FULL_OWNERSHIP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvestmentPageQueryInvestmentPropertiesFragment$data = {
  readonly findInvestmentProperties: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly about: {
          readonly name: string;
          readonly typeformId: string | null;
        } | null;
        readonly intro: {
          readonly cover: {
            readonly _id: any | null;
            readonly mediaType: EnuminvestmentPropertyIntroMediaMediaType;
            readonly mediaURL: string;
            readonly thumbnailURL: string | null;
          } | null;
          readonly heading: string | null;
          readonly media: {
            readonly _id: any | null;
            readonly autoPlayable: boolean;
            readonly cover: boolean;
            readonly description: string | null;
            readonly displayOrder: number;
            readonly mediaType: EnuminvestmentPropertyIntroMediaMediaType;
            readonly mediaURL: string;
            readonly thumbnailURL: string | null;
            readonly title: string | null;
          } | null;
          readonly subHeading: string | null;
        } | null;
        readonly investment: {
          readonly data: {
            readonly application: {
              readonly amount: number | null;
              readonly applied: number | null;
            } | null;
            readonly investment: {
              readonly roi: {
                readonly capitalAppriciation: number;
                readonly rentalYieldPostOps: number;
                readonly rentalYieldPreOps: number;
                readonly xirr: ReadonlyArray<{
                  readonly _id: any | null;
                  readonly percentage: number | null;
                  readonly year: number | null;
                } | null> | null;
              } | null;
              readonly units: {
                readonly activeUnits: number;
                readonly perUnitAmount: number;
                readonly soldUnits: number;
                readonly totalUnits: number;
              } | null;
              readonly years: {
                readonly default: number;
                readonly max: number;
                readonly min: number;
              } | null;
            } | null;
            readonly investmentAmount: {
              readonly min: number;
            } | null;
          } | null;
        } | null;
        readonly investmentType: EnuminvestmentPropertyInvestmentType;
        readonly location: {
          readonly name: string;
        } | null;
        readonly project: {
          readonly designer: string | null;
          readonly managedBy: {
            readonly name: string | null;
            readonly promise: {
              readonly _id: any | null;
              readonly heading: string | null;
              readonly subHeading: string | null;
              readonly values: ReadonlyArray<{
                readonly _id: any | null;
                readonly heading: string | null;
                readonly icon: string | null;
                readonly subHeading: string | null;
              } | null> | null;
            } | null;
          } | null;
        } | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "InvestmentPageQueryInvestmentPropertiesFragment";
};
export type InvestmentPageQueryInvestmentPropertiesFragment$key = {
  readonly " $data"?: InvestmentPageQueryInvestmentPropertiesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvestmentPageQueryInvestmentPropertiesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "findInvestmentProperties"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subHeading",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaURL",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailURL",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "min",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./InvestmentPageQueryInvestmentPropertiesQuery.graphql')
    }
  },
  "name": "InvestmentPageQueryInvestmentPropertiesFragment",
  "selections": [
    {
      "alias": "findInvestmentProperties",
      "args": null,
      "concreteType": "investmentPropertyConnection",
      "kind": "LinkedField",
      "name": "__InvestmentPageQueryInvestmentPropertiesFragment__findInvestmentProperties_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "investmentPropertyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "investmentProperty",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "investmentType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "investmentPropertyIntro",
                  "kind": "LinkedField",
                  "name": "intro",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "investmentPropertyIntroMedia",
                      "kind": "LinkedField",
                      "name": "media",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "autoPlayable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cover",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayOrder",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "investmentPropertyIntroMedia",
                      "kind": "LinkedField",
                      "name": "cover",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "investmentPropertyProject",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "investmentPropertyProjectManagedBy",
                      "kind": "LinkedField",
                      "name": "managedBy",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "investmentPropertyProjectManagedByPromise",
                          "kind": "LinkedField",
                          "name": "promise",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "investmentPropertyProjectManagedByPromiseValues",
                              "kind": "LinkedField",
                              "name": "values",
                              "plural": true,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "icon",
                                  "storageKey": null
                                },
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "designer",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "investmentPropertyInvestment",
                  "kind": "LinkedField",
                  "name": "investment",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "investmentPropertyInvestmentData",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "investmentPropertyInvestmentDataInvestmentAmount",
                          "kind": "LinkedField",
                          "name": "investmentAmount",
                          "plural": false,
                          "selections": [
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "investmentPropertyInvestmentDataApplication",
                          "kind": "LinkedField",
                          "name": "application",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "applied",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "amount",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "investmentPropertyInvestmentDataInvestment",
                          "kind": "LinkedField",
                          "name": "investment",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "investmentPropertyInvestmentDataInvestmentYears",
                              "kind": "LinkedField",
                              "name": "years",
                              "plural": false,
                              "selections": [
                                (v8/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "max",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "default",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "investmentPropertyInvestmentDataInvestmentUnits",
                              "kind": "LinkedField",
                              "name": "units",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "totalUnits",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "activeUnits",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "soldUnits",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "perUnitAmount",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "investmentPropertyInvestmentDataInvestmentRoi",
                              "kind": "LinkedField",
                              "name": "roi",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "rentalYieldPostOps",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "rentalYieldPreOps",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "capitalAppriciation",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "investmentPropertyInvestmentDataInvestmentRoiXirr",
                                  "kind": "LinkedField",
                                  "name": "xirr",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "year",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "percentage",
                                      "storageKey": null
                                    },
                                    (v6/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "investmentPropertyAbout",
                  "kind": "LinkedField",
                  "name": "about",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "typeformId",
                      "storageKey": null
                    },
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "investmentPropertyLocation",
                  "kind": "LinkedField",
                  "name": "location",
                  "plural": false,
                  "selections": [
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "89b0b962a5f67ef39289c1ecb72508a5";

export default node;
