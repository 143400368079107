import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import ValetTabBarNew from './valetBottomTabBarNew';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import ValetOverviewScreen from '../screens/valet/ValetOverviewScreen';
import ValetBonusTaskBreakDownScreen from '../screens/valet/ValetBonusTaskBreakDownScreen';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import AccountScreenWrapper from '../screens/new/account/AccountScreen';
import TeamScreenWrapper from '../screens/Team/TeamScreen';
import TaskScreen from '../screens/valet/new/TaskScreen';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import OTPInputScreen from '../screens/auth/OTPInputScreen';
import BarCodeScannerForAddTagScreen from '../screens/valet/barcodeScannerForAddTagScreen';
import QrCodeScreen from '../screens/valet/new/QrCodeScreen';
import ReachedDropLocationScreen from '../screens/valet/new/ReachedDropLocationScreen';
import FinishTaskScreen from '../screens/valet/new/FinishTaskScreen';
import TaskCompletionScreen from '../screens/TaskCompletionScreen';
import SelectParkingLocationScreen from '../screens/valet/new/SelectParkingLocationScreen';
import StartRideScreen from '../screens/valet/new/StartRideScreen';
import SubmitKeyScreen from '../screens/valet/new/SubmitKeyScreen';
import SelectCustomerScreen from '../screens/valet/new/SelectCustomerScreen';
import SearchUserScreen from '../screens/valet/new/SearchUserScreen';
import AddCustomerScreenWrapper from '../screens/valet/new/AddCustomerScreen';
import EndTaskScreen from '../screens/valet/new/EndTaskScreen';
import AddVehicleScreen from '../screens/common/AddVehicleScreen';
import AnprDetectionScreen from '../screens/new/AnprDetectionScren';

const { Navigator, Screen } = createBottomTabNavigator();
const Stack = createStackNavigator();

const ValetHomeTabBar = () => (
  <Navigator
    // @ts-expect-error TS(2769): No overload matches this call.
    keyboardHidesTabBar="true"
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <ValetTabBarNew {...props} />}
  >
    <Screen name="home" component={TaskScreen} />
    <Screen name="overview" component={ValetOverviewScreen} />
    {/* <Screen name="valetAccount" component={ValetAccountScreen} /> */}
    <Screen name="valetAccount" component={AccountScreenWrapper} />
  </Navigator>
);

const ValetHomeTabs = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="valet" component={ValetHomeTabBar} />
      <Stack.Screen name="team" component={TeamScreenWrapper} />
      <Stack.Screen name="editProfile" component={EditProfileScreen} />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />
      <Stack.Screen name="phoneVerify" component={OTPInputScreen} />
      <Stack.Screen
        name="BarCodeScannerForAddTagScreen"
        component={BarCodeScannerForAddTagScreen}
      />
      <Stack.Screen name="QrCodeScreen" component={QrCodeScreen} />
      <Stack.Screen
        name="ReachedDropLocationScreen"
        component={ReachedDropLocationScreen}
      />
      <Stack.Screen name="FinishTaskScreen" component={FinishTaskScreen} />
      <Stack.Screen
        name="TaskCompletionScreen"
        component={TaskCompletionScreen}
      />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
      <Stack.Screen
        name="bonusTasks"
        component={ValetBonusTaskBreakDownScreen}
      />
      <Stack.Screen
        name="SelectParkingLocationScreen"
        component={SelectParkingLocationScreen}
      />
      <Stack.Screen name="StartRideScreen" component={StartRideScreen} />
      <Stack.Screen name="SubmitKeyScreen" component={SubmitKeyScreen} />
      <Stack.Screen
        name="SelectCustomerScreen"
        component={SelectCustomerScreen}
      />
      <Stack.Screen name="SearchUserScreen" component={SearchUserScreen} />
      <Stack.Screen
        name="AddNewCustomerScreen"
        component={AddCustomerScreenWrapper}
      />
      <Stack.Screen name="EndTaskScreen" component={EndTaskScreen} />
      <Stack.Screen name="AddVehicleScreen" component={AddVehicleScreen} />
      <Stack.Screen
        name="anprDetectionScreen"
        component={AnprDetectionScreen}
      />
    </Stack.Navigator>
  );
};

export default ValetHomeTabs;
