import { graphql } from 'react-relay';

export const makeSearchScreenQueryvariable = (
  searchInputValue: string,
  campusID: string,
) => {
  const tempFilter = { campus: { _id: campusID } };
  if (searchInputValue) {
    tempFilter.search = {
      restaurantTitle: searchInputValue.trim(),
    };
  }
  return {
    filter: tempFilter,
  };
};
export const newSearchScreenQuery = graphql`
  query newSearchScreenQuery($filter: searchRestaurantsFilterInput!) {
    searchRestaurants2(filter: $filter) {
      # ...SearchResultSectionFragment @relay(mask: true)
      # ...TrendingListSection_Fragment @relay(mask: true)
      _id
      displayName
      amenities
      overAllRating
      logo
      trending
      description {
        tags {
          name
        }
        cusines {
          name
          primary
        }
      }
      menu {
        restaurantId
      }
    }
  }
`;
