/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const accountScreenQuery = graphql`
  query accountScreenQuery(
    $filter: findValetWorkLogFilterInput!
    $addonsFilter: FilterFindManyaddonInput
    $userId: MongoID!
    $isNotUser: Boolean!
    $isLoggedIn: Boolean!
  ) {
    findValetWorkLog(filter: $filter) @include(if: $isNotUser) {
      ...profileCardQueryFragment_findWorkLogFragment_Query
    }
    findUserById(_id: $userId) @include(if: $isLoggedIn) {
      ...profileCardQueryFragment_findUserById_Query
    }
    findAddons(filter: $addonsFilter) @skip(if: $isNotUser) {
      ...profileCardQueryAddonFragment_findAddonQuery
    }
  }
`;
