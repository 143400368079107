/* eslint-disable import/prefer-default-export */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface KioskInterface {
  darkModeEnabled: boolean;
  theme: KioskTheme;
  parking: Record<string, any>;
  activeTask: Record<string, any>;
  setLightTheme: () => void;
  setDarkTheme: () => void;
  enableDarkMode: () => void;
  enableLightMode: () => void;
  setParking: (parking: KioskInterface['parking']) => void;
  setActiveTask: (activeTask: KioskInterface['activeTask']) => void;
  resetRecallData: () => void;
}

export enum KioskTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export const useKioskStore = create<KioskInterface>()(
  devtools((set) => ({
    darkModeEnabled: false,
    theme: KioskTheme.LIGHT,
    parking: null,
    activeTask: null,
    setLightTheme: () => {
      set(() => {
        return { theme: KioskTheme.LIGHT };
      });
    },
    setDarkTheme: () => {
      set(() => {
        return { theme: KioskTheme.DARK };
      });
    },
    enableDarkMode: () => {
      set(() => {
        return { darkModeEnabled: true };
      });
    },
    enableLightMode: () => {
      set(() => {
        return { darkModeEnabled: false };
      });
    },
    setParking: (parking: KioskInterface['parking']) => {
      set(() => {
        return {
          parking,
        };
      });
    },
    setActiveTask: (activeTask: KioskInterface['activeTask']) => {
      set(() => {
        return {
          activeTask,
        };
      });
    },
    resetRecallData: () => {
      set(() => {
        return {
          parking: null,
          activeTask: null,
        };
      });
    },
  })),
);
