import React, { useState } from 'react';
import {
  View,
  Text,
  Button,
  RadioGroup,
  Divider,
} from '../../../components/new';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';

const options = [
  { name: 'Customer denied pick up', checked: false },
  { name: 'Customer is not coming', checked: false },
  {
    name: 'Customer is not reachable',
    checked: false,
  },
  { name: 'Other reason', checked: false },
];

const ReparkConfirmationBottomSheet = ({
  handleReparkTask,
  setShowTimer,
  minutesThresshold,
  timeOfArrival,
  bottomSheetModalRef,
  handleOnDismiss,
}) => {
  const [data, setData] = useState(options);

  const anySelected = data.some((item) => item?.checked);

  const handleNextPress = () => {
    const freezeFrom = timeOfArrival;
    const currentTime = new Date().valueOf();
    const timeDiff = Math.floor(
      (currentTime - new Date(freezeFrom).valueOf()) / 1000,
    );

    const thressholdOver = minutesThresshold - timeDiff <= 0;

    if (data[0]?.checked || thressholdOver) {
      handleReparkTask();
    } else {
      setShowTimer(true);
    }
  };

  return (
    <View>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Why do you want re-park this car?
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            <RadioGroup
              radio={data}
              setRadio={(arr) => setData(arr)}
              title=""
              isRequired
              size="lg"
              icon="right"
              collapsedCount={5}
            />
          </View>
          <View px="2xl" mt="xl">
            <Button
              size="lg"
              appearance="filled"
              state={anySelected ? '' : 'disabled'}
              onPress={() => {
                bottomSheetModalRef.current?.hide();
                handleNextPress();
              }}
              iconRight="arrow-forward-300"
            >
              Next
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

export default ReparkConfirmationBottomSheet;
