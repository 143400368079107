import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import CommonLayout from './components/CommonLayout';
import HiddenInput from './components/HiddenInput';
import { Text, View } from '../../../../components/new';
import BackButton from './components/BackButton';
import { useKioskStore } from '../../../../stores/kiosk/kioskStore';
import RecallStatusInfo from './components/RecallStatusInfo';
import { minutesDifferenceInDate } from '../../../../utilities/helper';
import CachedImage from '../../../../components/new/custom/CachedImage';
import WhatsappCount from './components/WhatsappCount';
import { FontWeights } from '../../../../themes/new/helper';
import useCountdown from '../../../../utilities/customHooks/useCountDown';
import { kiosk } from '../../../../utilities/Constant';
import { EventDataOutput, analytics, extractEventData } from './analytics';

const { SESSION_TIMEOUT } = kiosk;

const getMins = (task) => {
  if (task) {
    const mins = minutesDifferenceInDate(task.ETA?.delayAt ?? '');
    return Math.abs(Math.max(-16, Math.min(-1, mins)));
  }
  return 1;
};

interface ParamsProps {
  isFreshRecall: boolean;
  tagId: string;
}

const RecallStatusScreen = ({ navigation, route }) => {
  const { isFreshRecall }: ParamsProps = route.params;
  const { parking, activeTask } = useKioskStore((state) => state);
  const eventData = extractEventData(parking as any) ?? ({} as EventDataOutput);
  const arrivalTime = getMins(activeTask);
  const { countdown, startCountdown } = useCountdown(SESSION_TIMEOUT, {
    onComplete: () => {
      analytics.recallStatusScreen.sessionEnded(isFreshRecall, eventData);
      navigateToHomeScreen();
    },
  });
  useEffect(() => {
    startCountdown();
    analytics.recallStatusScreen.landed(isFreshRecall, eventData);
  }, []);

  const navigateToHomeScreen = () => {
    navigation.replace('kioskRecall');
  };

  return (
    <CommonLayout>
      <HiddenInput />
      <View
        flex={1}
        py="9xl"
        width="100%"
        overflow="hidden"
        justifyContent="space-between"
      >
        <View>
          <View px="9xl">
            <View position="absolute">
              <View pl={Platform.OS === 'web' ? 'none' : '9xl'}>
                <BackButton
                  onBack={() => {
                    analytics.recallStatusScreen.backClicked(
                      isFreshRecall,
                      eventData,
                    );
                    navigateToHomeScreen();
                  }}
                />
              </View>
            </View>
            <View mb="2xl" alignSelf="center">
              <RecallStatusInfo
                status="success"
                textPri={`Car Arriving in ${arrivalTime} min`}
              />
            </View>
            <View
              alignSelf="center"
              p="2xl"
              mb="9xl"
              bg="primary.10"
              maxWidth={640}
              width="100%"
              borderWidth="md"
              borderRadius="xl"
              borderColor="success.300"
              alignItems="center"
            >
              <WhatsappCount count={1} />
              <Text
                mt="7xl"
                textAlign="center"
                size="6xl"
                weight={FontWeights.MEDIUM}
                color="primary.500"
              >
                Pay via WhatsApp now to save upto 50% on parking fee!
              </Text>
            </View>
          </View>
          <View borderBottomWidth="xs" borderColor="primary.50" />
        </View>
        <View>
          <View px="9xl" width="100%" mt="4xl" alignItems="center">
            <CachedImage
              source={require('../../../../../assets/images/kiosk/recall-status-mockup.webp')}
              style={{ width: 640, height: 610 }}
            />
          </View>
          <View mt="4xl">
            <Text
              textAlign="center"
              size="2xl"
              weight="regular"
              color="primary.300"
            >
              Session ending in {countdown}s
            </Text>
          </View>
        </View>
      </View>
    </CommonLayout>
  );
};

export default RecallStatusScreen;
