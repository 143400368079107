/**
 * @generated SignedSource<<150c41e3e5961c20a9df36116d1d8fdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumStaffRewardCategory = "CONTROLLER_PARKING_ASSIGNMENT" | "CONTROLLER_PARKING_COMPLETION" | "CONTROLLER_PARKING_ONBOARDING" | "CONTROLLER_RECALL_ARRIVAL" | "CONTROLLER_RECALL_ASSIGNMENT" | "CONTROLLER_REPARKING_COMPLETION" | "VALET_PARK" | "VALET_RECALL" | "VALET_REPARK" | "%future added value";
export type EnumStaffRewardTaskTaskStatus = "DELAYED" | "ON_TIME" | "%future added value";
export type EnumStaffRewardTaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findDailyControllerRewardsFilter = {
  date: string;
};
export type controllerDailyBonusTasksQuery$variables = {
  filter?: findDailyControllerRewardsFilter | null;
};
export type controllerDailyBonusTasksQuery$data = {
  readonly findDailyControllerRewards: ReadonlyArray<{
    readonly category: EnumStaffRewardCategory;
    readonly task: {
      readonly delayedBy: number | null;
      readonly taskDate: any;
      readonly taskStatus: EnumStaffRewardTaskTaskStatus | null;
      readonly taskType: EnumStaffRewardTaskTaskType | null;
    } | null;
    readonly valetCode: string | null;
    readonly value: number;
  } | null> | null;
};
export type controllerDailyBonusTasksQuery = {
  response: controllerDailyBonusTasksQuery$data;
  variables: controllerDailyBonusTasksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "DailyControllerRewardsOutput",
    "kind": "LinkedField",
    "name": "findDailyControllerRewards",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StaffRewardTask",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "delayedBy",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "category",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "valetCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "controllerDailyBonusTasksQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "controllerDailyBonusTasksQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e71ef6b4fe542d675aabbbb8ef44dade",
    "id": null,
    "metadata": {},
    "name": "controllerDailyBonusTasksQuery",
    "operationKind": "query",
    "text": "query controllerDailyBonusTasksQuery(\n  $filter: findDailyControllerRewardsFilter\n) {\n  findDailyControllerRewards(filter: $filter) {\n    task {\n      taskDate\n      taskType\n      taskStatus\n      delayedBy\n    }\n    category\n    value\n    valetCode\n  }\n}\n"
  }
};
})();

(node as any).hash = "177a30a984f7186e767e282274801653";

export default node;
