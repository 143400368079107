import React from 'react';
import { Button, Icon, Text, View } from '../../../../components/new';
import { Appearance, Status } from '../../../../themes/new/helper';
import { radii } from '../../../../themes/new/theme';
import InvestmentBlurView from './InvestmentBlurView';
import { getStyledPremium } from '../../../../utilities/Utility';

const InvestmentSubscriptionComponents = ({
  data,
  consentGiven,
  openOfferDetails = () => {},
  bgColor = '',
  openCalculator = () => {},
  onPressUnlock = () => {},
}) => {
  return (
    <View bg={bgColor} alignItems="center" py="9xl+9xl" px="2xl">
      <Text size="xs" color="primary.300" pb="lg" textAlign="center">
        {data?.heading ?? 'EXCLUSIVE OPPORTUNITY'}
      </Text>
      <Text size="2xl" color="primary.500" textAlign="center">
        {data?.subHeading ?? 'Limited to 50 elite members'}
      </Text>
      <Button
        appearance={Appearance.GHOST}
        status={Status.INFO}
        size="md"
        mt="lg"
        onPress={openCalculator}
      >
        <View flexDirection="row" alignItems="center">
          <Text color="info.500" size="md" mr="sm" mt={-2}>
            Find Your Ideal Investment
          </Text>
          <Icon name="forward-outline-300" color="info.500" size="xs" />
        </View>
      </Button>
      <View
        mt="9xl"
        borderWidth={1}
        borderRadius="xl"
        borderColor="grey.10"
        width="100%"
        overflow="hidden"
      >
        <InvestmentBlurView
          blurHeight="100%"
          blurWidth="100%"
          showBlur={!consentGiven}
          BlurViewStyle={{ borderRadius: radii.xl }}
          iconSize="6xl"
          onPressUnlock={onPressUnlock}
        />
        <View px="2xl" py="4xl" bg="primary.10">
          <View
            flexDirection="row"
            justifyContent="space-between"
            pb="4xl"
            borderBottomWidth={1}
            borderColor="grey.50"
          >
            <View alignItems="center" flex={1}>
              <Text color="primary.200" size="xs">
                Min. Investment
              </Text>
              <Text pt="lg" color="primary.500" size="xl">
                {getStyledPremium(data?.data.investmentAmount.min)}
              </Text>
            </View>
            <View alignItems="center" flex={1}>
              <Text color="primary.200" size="xs">
                Assured buyback
              </Text>
              <Text pt="lg" color="primary.500" size="xl">
                {data?.data?.investment?.assuredBuyBack
                  ? getStyledPremium(
                      data?.data?.investment?.assuredBuyBack *
                        data?.data?.investment?.units.perUnitAmount,
                    )
                  : '₹62.5 lakh'}
              </Text>
            </View>
          </View>
          <View flexDirection="row" justifyContent="space-between" pt="4xl">
            <View alignItems="center" flex={1}>
              <Text color="primary.200" size="xs">
                Fixed Rental Yield
              </Text>
              <Text pt="lg" color="primary.500" size="xl">
                {data?.data.investment.roi.rentalYieldPostOps}% p.a
              </Text>
            </View>
            <View alignItems="center" flex={1}>
              <Text color="primary.200" size="xs">
                Est. Annual RoI
              </Text>
              <Text pt="lg" color="primary.500" size="xl">
                {data?.data?.investment?.roi?.xirr?.[0]?.percentage ===
                data?.data?.investment?.roi?.xirr?.slice(-1)[0]?.percentage
                  ? `${data?.data?.investment?.roi?.xirr?.[0]?.percentage}%`
                  : `${data?.data?.investment?.roi?.xirr?.[0]?.percentage}%-
                ${
                  data?.data?.investment?.roi?.xirr?.slice(-1)[0]?.percentage
                }%`}
              </Text>
            </View>
          </View>
          <Button
            appearance={Appearance.OUTLINE}
            status={Status.PRIMARY}
            size="md"
            mt="9xl"
            onPress={openOfferDetails}
          >
            View investment details
          </Button>
        </View>
      </View>
    </View>
  );
};

export default InvestmentSubscriptionComponents;
