import React from 'react';
import View from '../../../basic/View/View';
import Icon from '../../../primitive/Icon/Icon';
import Text from '../../../primitive/Typography/Text/Text';
import { FontWeights } from '../../../../../themes/new/helper';

export type State = 'REDEEMED' | 'ALREADY_REDEEMED' | 'EXPIRED' | 'CANCELLED';

interface TicketStates {
  state: State;
}

const TicketStates = ({ state }: TicketStates) => {
  const [color, label, icon] = getColorLabelAndIcon(state);

  return (
    <View mb="9xl" mt="3xl+9xl" alignItems="center">
      <Icon name={icon} color={color} size="sm+9xl" />
      <Text size="2xl" color={color} weight={FontWeights.MEDIUM}>
        {label}
      </Text>
    </View>
  );
};

export default TicketStates;

const getColorLabelAndIcon = (state: State): [string, string, string] => {
  switch (state) {
    case 'REDEEMED':
      return ['success.500', 'Redeemed', 'check-outline-400'];
    case 'ALREADY_REDEEMED':
      return ['warning.500', 'Already Redeemed', 'warning-outline-400'];
    case 'EXPIRED':
      return ['error.500', 'Expired', 'warning-outline-400'];
    case 'CANCELLED':
      return ['error.500', 'Ticket Cancelled', 'warning-outline-400'];
    default:
      return ['grey', '', ''];
  }
};
