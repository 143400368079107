/* eslint-disable import/prefer-default-export */
import { graphql, commitMutation } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

export const notifyEventMutation = (data, callback, onError) => {
  const mutation = graphql`
    mutation eventNotifyMutation($record: addSubscriptioNnotifyRecordInput!) {
      addSubscriptionNotify(record: $record) {
        record {
          topicId
        }
      }
    }
  `;
  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response, data);
    },
    onError: (err) => {
      console.error(err);
      onError(err);
    },
  });
};
