import React, { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import { View } from '../../../../../components/new';

interface DynamicAppQrProps {
  url: string;
  maxWidth?: number;
  borderPadding?: string;
}

const SIZE_ASPECT_RATIO = 0.28;

const DynamicAppQr = ({
  url,
  maxWidth = 200,
  borderPadding = '2xl',
}: DynamicAppQrProps) => {
  const { width } = useWindowDimensions();
  const size = useMemo(
    () => Math.min(Math.max(maxWidth, 100), width * SIZE_ASPECT_RATIO),
    [width],
  );

  return (
    <View
      p={borderPadding}
      bg="white"
      justifyContent="center"
      alignItems="center"
    >
      <View width={size} height={size}>
        <QRCode value={url} size={size} />
      </View>
    </View>
  );
};

export default DynamicAppQr;
