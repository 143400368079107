import React from 'react';
import { Pressable } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { View } from '..';
import ShortDetailsCard from './ShortDetailsCard';
import { getTheme } from '../../../themes/new/theme';

type ShortDetailsListItemType = {
  id: string;
  title: string;
  rating: number;
  tags: { name: string }[];
  image: string;
  video: string;
};

interface ShortDetailsCardListProps {
  listItems: ShortDetailsListItemType[];
  overlayAt?: number;
  onCardPress?: (
    item: ShortDetailsListItemType,
    isOverlayItem: boolean,
  ) => void;
}

const MIN_OVERLAY_ITEM = 3;

export const ShortDetailsCardList = ({
  listItems,
  overlayAt = 4,
  onCardPress,
}: ShortDetailsCardListProps) => {
  const totalItems = listItems.length;
  overlayAt = Math.max(MIN_OVERLAY_ITEM, overlayAt); // setting min limit for overlay
  const slicedListItems = listItems.slice(0, overlayAt + 1); // truncating exceeded items
  const slicedTotalItems = slicedListItems.length;

  return (
    <FlashList
      directionalLockEnabled
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{ paddingRight: getTheme().space['2xl'] }}
      // slice from 0 to `overlayAt`, as we want overlay if more than `overlayAt` items}
      data={slicedListItems}
      renderItem={({ item, index }) => {
        // to showing overlay if last element encountered
        const isOverlayItem =
          totalItems > overlayAt && index === slicedTotalItems - 1;

        return (
          <View pl="2xl" py="2xl">
            <Pressable
              onPress={() => {
                if (typeof onCardPress === 'function') {
                  onCardPress(item, isOverlayItem);
                }
              }}
            >
              <ShortDetailsCard
                showOverlay={isOverlayItem}
                overlayText="Explore All"
                title={item.title}
                rating={item.rating}
                image={item.image}
                tags={item.tags}
              />
            </Pressable>
          </View>
        );
      }}
      horizontal
      keyExtractor={(item, i) => item.title + i}
    />
  );
};

export default ShortDetailsCardList;
