import React, { Suspense, useCallback, useRef, useState } from 'react';
import { Linking, Keyboard, TouchableWithoutFeedback } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { debounce } from 'lodash';
import useTeamScreenData from './hooks/useTeamScreenData';
import useCommonStore from '../../stores/commonStore';
import {
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import Loading from '../../components/loading.component';
import useUserStore, { UserRole } from '../../stores/userStore';
import {
  getRole,
  getValetWorkLogStatus,
  navigateBack,
} from '../../utilities/helper';
import ValetCard from '../../components/new/custom/ValetCard';
import SearchInput from '../../components/new/composites/SearchInput/SearchInput';
import baseTheme from '../../themes/new/theme';
import { useTeamScreenStore } from '../../stores/teamScreen/teamScreenStore';
import AppConstants from '../../utilities/AppConstants';
import { pagination } from '../../utilities/Constant';
import PaginationLoader from '../../components/new/custom/PaginationLoader';

const getRequiredInfo = (item) => {
  const firstName = item?.name?.first ? `${item?.name?.first} ` : '';
  const lastName = item?.name?.last || '';
  const fullName = `${firstName}${lastName}`.trim();
  const identityCode = item?.identityCode;
  const title = `${fullName}${identityCode ? ` (${identityCode})` : ''}`;
  const status = getValetWorkLogStatus(item?.workLogStatus?.status);
  const profileImage = item?.profileImage;
  const { IMAGEKIT_PROD_URI } = AppConstants;
  const modifiedImageURI = profileImage
    ? IMAGEKIT_PROD_URI + profileImage
    : 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
  const phoneNumber = `${item?.contact?.phone?.prefix}${item?.contact?.phone?.number}`;
  const subText = getRole(item?.role?.name);

  return {
    fullName,
    subText,
    heading: title,
    status,
    imageLink: modifiedImageURI,
    phoneNumber,
    identityCode,
  };
};

const ContentRight = ({ phoneNumber = '' }) => {
  return (
    <View>
      <IconButton
        name="call-filled-300"
        onPress={() => {
          Keyboard.dismiss();
          Linking.openURL(`tel:${phoneNumber}`);
        }}
      />
    </View>
  );
};

const TeamScreen = ({ refreshAPI, refreshedQueryOptions, variables }) => {
  // const navigation = useNavigation();

  const { apiData, allowDeletion, hasMoreUsers, fetchMoreUserList } =
    useTeamScreenData({
      refreshedQueryOptions,
      variables,
    });

  return (
    <View flex={1}>
      <FlashList
        keyboardShouldPersistTaps="handled"
        data={apiData || []}
        onScroll={() => {
          Keyboard.dismiss();
        }}
        renderItem={({ item, index }) => {
          const {
            heading,
            phoneNumber,
            subText,
            identityCode,
            imageLink,
            status,
          } = getRequiredInfo(item);

          return (
            <TouchableWithoutFeedback
              activeOpacity={1}
              onPress={() => {
                Keyboard.dismiss();
              }}
              accessible={false}
            >
              <View mb="2xl">
                <ValetCard
                  allowSwipeAction={allowDeletion}
                  index={index}
                  id={item?._id}
                  marginHorizontal="2xl"
                  identityCode={identityCode}
                  imageLink={imageLink}
                  heading={heading}
                  subText={subText}
                  contentRight={<ContentRight phoneNumber={phoneNumber} />}
                  status={status}
                  refresh={refreshAPI}
                />
              </View>
            </TouchableWithoutFeedback>
          );
        }}
        estimatedItemSize={76}
        drawDistance={500}
        contentContainerStyle={{
          paddingBottom: baseTheme.space['4xl'],
          paddingTop: baseTheme.space['4xl'],
        }}
        ListEmptyComponent={() => (
          <View flex={1} alignItems="center" justifyContent="center">
            <Text size="md" color="primary.500">
              No Data Found!
            </Text>
          </View>
        )}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (hasMoreUsers) {
            fetchMoreUserList(pagination.defaultPageSize);
          }
        }}
        ListFooterComponent={() => {
          if (hasMoreUsers) return <PaginationLoader />;
          return null;
        }}
      />
    </View>
  );
};

const variableMaker = ({ campusId, value, userId }) => {
  const tempVariable = {
    count: pagination.defaultPageSize,
    filter: {
      role: {
        meta: { campus: { _id: campusId } },
      },
    },
    userId,
  };
  if (value?.length >= 2) {
    tempVariable.filter.search = {
      nameOrPhoneOrCode: value,
    };
  }

  return tempVariable;
};

const TeamScreenWrapper = ({ navigation }) => {
  const campusId = useCommonStore((state) => state.campusId);
  const inputRef = useRef();
  const { id: userId, role: userRole } = useUserStore((state) => state);
  const { resetCurrentSwipedCardIndex } = useTeamScreenStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
      networkCacheConfig: { force: true },
    });

  const [value, setValue] = useState('');

  const [variable, setVariable] = useState(
    variableMaker({ userId, campusId, value }),
  );

  const debouncedVariableMaker = useCallback(
    debounce((v) => {
      setVariable(variableMaker({ userId, campusId, value: v }));
    }, 1000),
    [],
  );

  const refreshAPI = () => {
    setRefreshedQueryOptions((prev) => {
      return {
        ...prev,
        fetchKey: (prev?.fetchKey || 0) + 1,
      };
    });
  };

  const combineValueSetter = (v) => {
    setValue(v);
    debouncedVariableMaker(v);
  };

  useFocusEffect(
    useCallback(() => {
      inputRef?.current?.focus();
      refreshAPI();
      resetCurrentSwipedCardIndex();
    }, []),
  );

  const handleDismissKeyboard = () => {
    Keyboard.dismiss();
  };

  return (
    <Layout level={2}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions?.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refreshAPI}
          />
        }
      >
        <TopNavigation
          title={
            userRole === UserRole.VALET_SUPERVISOR
              ? 'Manage Team'
              : 'Team Contacts'
          }
          textSize="md"
          appearance="ghost"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              onPress={() => navigateBack(navigation, userRole)}
            />
          }
          IconRight={
            userRole === 'valetSupervisor' ? (
              <IconButton
                name="add-person"
                iconColor="info.400"
                size="md"
                appearance="ghost"
                onPress={() => {
                  navigation.navigate('AddTeamMemberScreen');
                }}
              />
            ) : (
              <View />
            )
          }
          ChildrenElement={
            <View pt="xs" pb="2xl" mx="2xl">
              <SearchInput
                inputRef={inputRef}
                autoFocus
                leftIconName=""
                placeholder="Search by Name/Number/Code"
                value={value}
                onChangeText={(v) => {
                  combineValueSetter(v);
                }}
                onRightIconClick={() => {
                  combineValueSetter('');
                }}
              />
            </View>
          }
        />
        <Suspense fallback={<Loading />}>
          <View flex={1}>
            <TeamScreen
              refreshAPI={refreshAPI}
              refreshedQueryOptions={refreshedQueryOptions}
              variables={variable}
            />
          </View>
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default TeamScreenWrapper;
