import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Pressable,
  View,
  Text,
  Divider,
  RadioGroup,
  Button,
  Icon,
} from '../new';
import { InputStates } from '../new/primitive/OTPInput/helpers';
import BottomSheetV2 from '../new/composites/BottomSheet/BottomSheetV2';

interface DropDownDataType {
  name: string;
  value: string;
}

interface DropDownDataTypeFull extends DropDownDataType {
  checked?: boolean;
}

export enum CustomSelectSheetType {
  DROP_DOWN = 'DROP_DOWN',
  DROP_INPUT = 'DROP_INPUT',
}

interface CustomSelectSheetProps {
  label?: string;
  placeholder: string;
  title: string;
  actionButtonText: string;
  data: DropDownDataType[];
  onSelect: (data: DropDownDataType) => void;
  onInputClick?: () => void;
  disabled: boolean;
  style?: any;
  type?: CustomSelectSheetType;
}

const CustomSelectSheet = (props: CustomSelectSheetProps) => {
  const bottomSheetModalRef = React.useRef(null);
  const { onInputClick = () => {} } = props;
  const [dropDownData, setDropDownData] = useState<DropDownDataTypeFull[]>();
  const [selectedData, setSelectedData] = useState<DropDownDataTypeFull | null>(
    null,
  );
  const [selectedDataFinal, setSelectedDataFinal] =
    useState<DropDownDataTypeFull | null>(null);

  useEffect(() => {
    const oldData = dropDownData?.map((ele) => ({
      name: ele.name,
      value: ele.value,
    }));
    if (!_.isEqual(oldData, props.data)) {
      setDropDownData(props.data);
      setSelectedData(null);
      setSelectedDataFinal(null);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, [props.data]);

  const handleOnDismiss = () => {
    bottomSheetModalRef.current?.hide();
  };

  const handlePresentModalPress = () => {
    bottomSheetModalRef.current?.show();
  };

  const handleSelect = (data: DropDownDataTypeFull[]) => {
    data.forEach((ele) => {
      if (ele.checked) {
        setSelectedData(ele);
      }
    });
    setDropDownData(data);
  };

  const handleSubmit = () => {
    bottomSheetModalRef.current?.hide();
    props.onSelect(selectedData);
    setSelectedDataFinal(selectedData);
  };

  const renderInput = () => {
    switch (props.type) {
      case CustomSelectSheetType.DROP_DOWN:
        return (
          <View flexDirection="column">
            {props.label && (
              <Text size="sm" mb="lg" color="primary.300">
                {props.label}
              </Text>
            )}
            <View
              pb="xl"
              pr="lg"
              flexDirection="row"
              justifyContent="space-between"
              style={{ borderBottomWidth: 1, borderColor: '#ededed' }}
            >
              {selectedDataFinal ? (
                <Text size="sm" color="primary.300">
                  {selectedDataFinal.name}
                </Text>
              ) : (
                <Text size="sm" color="primary.100">
                  {props.placeholder}
                </Text>
              )}
              <Icon
                size="xl"
                shape="square"
                color="primary.100"
                name="down-outline-300"
              />
            </View>
          </View>
        );
      case CustomSelectSheetType.DROP_INPUT:
        return (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: 'center',
              borderRadius: 4,
              backgroundColor: '#EDEDED',
              paddingHorizontal: 8,
              paddingLeft: 16,
              paddingVertical: 7,
              minHeight: 45,
            }}
          >
            {selectedDataFinal ? (
              <Text size="md" color="primary.300">
                {selectedDataFinal.name}
              </Text>
            ) : (
              <Text size="md" color="primary.100">
                {props.placeholder}
              </Text>
            )}
          </View>
        );
      default:
        return null;
    }
  };

  return (
    <View style={{ ...props.style }}>
      <Pressable
        disabled={!!props.disabled}
        onPress={() => {
          handlePresentModalPress();
          onInputClick();
        }}
        hitSlop={{ top: 8, left: 8, right: 8, bottom: 8 }}
      >
        {renderInput()}
      </Pressable>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              {props.title}
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <RadioGroup
              radio={dropDownData}
              setRadio={(data) => handleSelect(data)}
              title=""
              size="lg"
              icon="right"
            />
          </View>
          <View px="2xl" mt="xl">
            <Button
              size="lg"
              appearance="filled"
              onPress={handleSubmit}
              state={!selectedData ? InputStates.disabled : InputStates.active}
            >
              {props.actionButtonText}
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

CustomSelectSheet.defaultProps = {
  type: CustomSelectSheetType.DROP_DOWN,
};

export default CustomSelectSheet;
