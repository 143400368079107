import React from 'react';
import { Image } from 'react-native';
import { View, Text } from '..';
import { FontWeights } from '../../../themes/new/helper';
import { transactionTypeEnum } from '../../../utilities/helper';

const PointsInfo: React.FC<{
  type: transactionTypeEnum.debit | transactionTypeEnum.credit;
  amount: number;
  isAvailable: boolean;
}> = ({
  type = transactionTypeEnum.credit,
  amount = 0,
  isAvailable = true,
}) => {
  let imageSource = require('../../../../assets/images/icon-up.webp');
  let iconColor = 'primary.100';
  if (type === transactionTypeEnum.credit) {
    if (isAvailable) {
      imageSource = require('../../../../assets/images/icon-up.webp');
      iconColor = 'success.500';
    } else {
      imageSource = require('../../../../assets/images/disable_icon.webp');
      iconColor = 'primary.100';
    }
  } else {
    imageSource = require('../../../../assets/images/icon-down.webp');
    if (isAvailable) {
      iconColor = 'error.500';
    } else {
      iconColor = 'primary.100';
    }
  }
  return (
    <View
      p="md"
      bg={
        type === transactionTypeEnum.credit
          ? isAvailable
            ? 'success.10'
            : 'grey.10'
          : 'error.10'
      }
      flexDirection="row"
      alignItems="center"
      borderRadius="md"
      justifyContent="center"
      minWidth={85}
    >
      <Image
        source={imageSource}
        resizeMode="contain"
        style={{ width: 16, height: 16 }}
      />
      <Text size="md" color={iconColor} mx="sm" weight={FontWeights.MEDIUM}>
        {/* {applyEllipsis(amount.toString(), 5)} */}
        {amount}
      </Text>
      <Image
        source={require('../../../../assets/images/coins.webp')}
        resizeMode="contain"
        style={{ width: 16, height: 16 }}
      />
    </View>
  );
};

export default PointsInfo;
