import styled from 'styled-components/native';
import { Button, Text, View } from '../../../components/new';
import Pressable from '../../../components/new/basic/Pressable/Pressable';

const StyledImage = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: 999px;
`;

const DinerItem = ({ data }) => {
  const { img, heading = '', tags = [] } = data;

  return (
    <View mb="4xl">
      <Pressable>
        <View flexDirection="row" alignItems="center">
          <View
            width={40}
            height={40}
            mr="2xl"
            alignItems="center"
            justifyContent="center"
          >
            <StyledImage source={{ uri: img }} />
          </View>
          <View flexDirection="row" justifyContent="space-between" flex={1}>
            <View>
              <Text size="md" color="primary.300" mb="sm">
                {/* do not capitalize this, because the owner can choose how he wants to decorize it */}
                {heading}
              </Text>
              <Text size="xs" color="primary.200">
                {tags.map((item, index) => {
                  if (tags.length === index + 1) {
                    return item;
                  }
                  return `${item}, `;
                })}
              </Text>
            </View>
            <View alignItems="center" justifyContent="center">
              <Button
                appearance="ghost"
                status="info"
                size="md"
                iconRight="keyboard-arrow-right-300"
              >
                See All Diners
              </Button>
            </View>
          </View>
        </View>
        {/* <Divider /> */}
      </Pressable>
    </View>
  );
};

export default DinerItem;
