/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */

export function calculateOnlineTime(timeStamps) {
  let totalOnlineTime = 0;
  const now = new Date(); // Current time

  for (let i = 0; i < timeStamps.length; i += 2) {
    const punchInTime = new Date(timeStamps[i].time);
    const punchOutTime = new Date(timeStamps[i + 1]?.time || now);

    if (punchInTime <= punchOutTime) {
      totalOnlineTime += punchOutTime - punchInTime;
    } else {
      console.error('Invalid timestamp pair at index:', i);
    }
  }

  const [hours, minutes, seconds] = new Date(totalOnlineTime)
    .toISOString()
    .substr(11, 8)
    .split(':');
  return { hours, minutes, seconds };
}
