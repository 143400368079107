import { graphql } from 'react-relay';

const sendMailMutation = graphql`
  mutation requestChangeMailMutation($record: SendMailInput!) {
    sendMail(record: $record) {
      status
      error {
        message
      }
    }
  }
`;

export default sendMailMutation;
