/**
 * @generated SignedSource<<5bf4a1dbe91bc04139cee11859cd8766>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SortFindManytypeformResponseInput = "_ID_ASC" | "_ID_DESC" | "landedAt_ASC" | "landedAt_DESC" | "submittedAt_ASC" | "submittedAt_DESC" | "%future added value";
export type findTypeFormResponseFilterInput = {
  AND?: ReadonlyArray<FilterFindManytypeformResponseInput> | null;
  OR?: ReadonlyArray<FilterFindManytypeformResponseInput> | null;
  _id?: any | null;
  _operators?: FilterFindManytypeformResponseOperatorsInput | null;
  created_at?: any | null;
  eventId?: string | null;
  eventType?: string | null;
  formId?: string | null;
  hidden?: any | null;
  landedAt?: any | null;
  submittedAt?: any | null;
  token?: string | null;
  updated_at?: any | null;
};
export type FilterFindManytypeformResponseOperatorsInput = {
  _id?: FilterFindManytypeformResponse_idOperatorsInput | null;
};
export type FilterFindManytypeformResponse_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FilterFindManytypeformResponseInput = {
  AND?: ReadonlyArray<FilterFindManytypeformResponseInput> | null;
  OR?: ReadonlyArray<FilterFindManytypeformResponseInput> | null;
  _id?: any | null;
  _operators?: FilterFindManytypeformResponseOperatorsInput | null;
  created_at?: any | null;
  eventId?: string | null;
  eventType?: string | null;
  formId?: string | null;
  hidden?: any | null;
  landedAt?: any | null;
  submittedAt?: any | null;
  token?: string | null;
  updated_at?: any | null;
};
export type findTypeFormResponsesApiQuery$variables = {
  typeFormLimit?: number | null;
  typeFormSort?: SortFindManytypeformResponseInput | null;
  typeFormfilter?: findTypeFormResponseFilterInput | null;
};
export type findTypeFormResponsesApiQuery$data = {
  readonly findTypeFormResponses: ReadonlyArray<{
    readonly eventId: string | null;
    readonly formId: string | null;
    readonly hidden: any | null;
    readonly landedAt: any | null;
    readonly submittedAt: any | null;
  }>;
};
export type findTypeFormResponsesApiQuery = {
  response: findTypeFormResponsesApiQuery$data;
  variables: findTypeFormResponsesApiQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeFormLimit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeFormSort"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeFormfilter"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "typeFormfilter"
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "typeFormLimit"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "typeFormSort"
      }
    ],
    "concreteType": "typeformResponse",
    "kind": "LinkedField",
    "name": "findTypeFormResponses",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eventId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "formId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hidden",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "landedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submittedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "findTypeFormResponsesApiQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "findTypeFormResponsesApiQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "28f817ea02ce7669ea1343f39669aae8",
    "id": null,
    "metadata": {},
    "name": "findTypeFormResponsesApiQuery",
    "operationKind": "query",
    "text": "query findTypeFormResponsesApiQuery(\n  $typeFormfilter: findTypeFormResponseFilterInput\n  $typeFormSort: SortFindManytypeformResponseInput\n  $typeFormLimit: Int\n) {\n  findTypeFormResponses(filter: $typeFormfilter, sort: $typeFormSort, limit: $typeFormLimit) {\n    eventId\n    formId\n    hidden\n    landedAt\n    submittedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "bfc1ae497dae3352aa62f6c89e5138b5";

export default node;
