import React, { Suspense, useCallback, useRef, useState } from 'react';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import moment from 'moment';
import { ScrollView } from 'react-native';
import useTaskJourneyScreenData from './hooks/useTaskJourneyScreenData';
import useCommonStore from '../../../../stores/commonStore';
import {
  Button,
  Card,
  Divider,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import Loading from '../../../../components/loading.component';
import useUserStore from '../../../../stores/userStore';
import {
  durationIntoHoursMinutes,
  getTimeDiff,
  navigateBack,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../../utilities/helper';
import VehicleListItem from '../../../../components/new/custom/VehicleListItem';
import VehicleStatusTag from '../../../../components/new/ui/VehicleStatusTags/VehicleStatusTag';
import TaskJourneyStep from './TaskJourneyStep';
import CancelTask from './CancelTask';
import TaskOptions from './TaskOptions';
import { addTaskFun } from '../../addTaskFun';
import { addTask } from '../../../../relay/taskApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import { handlePhone } from '../../../../utilities/handleLinking';
import { Appearance, State, Status } from '../../../../themes/new/helper';

const textMaker = (historyItem = {}, taskData = {}) => {
  const valetFirstName = taskData?.valet?.name?.first || '';
  const valetLastName = taskData?.valet?.name?.last || '';
  const valetFullName = `${valetFirstName} ${valetLastName}`;
  const actionEvent = moment(historyItem?.event_at).format('hh:mm A');
  const taskEndLocationName = taskData?.taskEndLocation?.name;
  const taskStartLocationName = taskData?.taskStartLocation?.name;

  const action = historyItem?.action;
  const taskType = taskData?.taskType;
  let title = '';
  let location = '';
  if (action === taskHistoryActionTypes.created) {
    title = 'Task Created';
    location = taskStartLocationName;
  } else if (action === taskHistoryActionTypes.onboarded) {
    title = `Customer Onboarded`;
    location = taskStartLocationName;
  } else if (action === taskHistoryActionTypes.assigned) {
    title = 'Task Assigned';
    location = taskStartLocationName;
  } else if (action === taskHistoryActionTypes.accepted) {
    title = 'Task Accepted';
    location = taskStartLocationName;
  } else if (action === taskHistoryActionTypes.started) {
    title = `Ride Started by ${valetFullName}`;
    location = taskStartLocationName;
  } else if (action === taskHistoryActionTypes.arrived) {
    title = 'Car Arrived';
    location = taskEndLocationName;
  } else if (action === taskHistoryActionTypes.completed) {
    title = 'Task Completed';
    location = taskEndLocationName;
  } else if (action === taskHistoryActionTypes.abort) {
    title = 'Task Aborted';
  } else if (action === taskHistoryActionTypes.cancelled) {
    title = 'Task Cancelled';
  } else if (action === taskHistoryActionTypes.incomplete) {
    title = 'Task Incomplete';
  } else if (action === taskHistoryActionTypes.EXITED) {
    title = 'Car Exited';
  }
  // =================

  // ==================
  const subText = `${location} | ${actionEvent}`;
  return { title, subText };
};

const TaskJourneyScreen = ({
  refreshAPI,
  refreshedQueryOptions,
  variables,
  showCancelTaskModal,
  bottomSheetModalRef,
  cancelTaskBottomSheetModalRef,
  handleCancelTaskCloseBottomSheet,
  handleCloseBottomSheet,
}) => {
  const navigation = useNavigation();
  const route = useRoute();

  const taskId = route?.params?.taskId || '';

  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [recallButtonDisabled, setRecallButtonDisabled] = useState(false);

  const { data, title, subText, taskTagFE, taskType } =
    useTaskJourneyScreenData({
      refreshedQueryOptions,
      variables,
    });

  const acceptedStatus =
    data?.history &&
    data?.history?.length &&
    data?.history?.filter(
      (ele) => ele?.action === taskHistoryActionTypes.accepted,
    );

  const handleRecalCallVehicle = (taskDetail) => {
    setRecallButtonDisabled(true);
    const recallTask = {
      ...taskDetail,
      campus: {
        _id: taskDetail.campus._id,
        name: taskDetail.campus.name,
      },
    };
    const dataTemp = addTaskFun(recallTask);
    addTask(
      dataTemp,
      () => {
        dispatchSnackbar({
          msg: `Task Recalled`,
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
        });
        setRecallButtonDisabled(false);
        refreshAPI();
        navigation.reset({
          index: 0,
          routes: [{ name: 'valetSupervisor' }],
        });
      },
      (err) => {
        dispatchSnackbar({
          msg: 'Oops! Something went wrong!😓',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
        setRecallButtonDisabled(false);
      },
    );
  };

  const onPressCallValet = (taskDetail) => {
    if (taskDetail?.assignedTo !== null) {
      handlePhone(
        // eslint-disable-next-line no-unsafe-optional-chaining
        taskDetail?.valet?.contact?.phone?.prefix +
          // eslint-disable-next-line no-unsafe-optional-chaining
          taskDetail?.valet?.contact?.phone?.number,
      );
    }
  };

  if (!data) {
    return (
      <View
        flex={1}
        justifyConent="center"
        alignItems="center"
        flexDirection="row"
      >
        <View flex={1}>
          <Text textAlign="center">No Data Found</Text>
        </View>
      </View>
    );
  }

  return (
    <View flex={1}>
      <View flex={1}>
        <ScrollView style={{ flex: 1 }}>
          <View py="4xl" px="2xl">
            <Card appearance="outline" borderRadius="xl">
              <View mb="lg">
                <VehicleListItem
                  heading={title}
                  subText={subText}
                  contentRight={<VehicleStatusTag status={taskTagFE} />}
                />
              </View>
              <Divider />
              <View mt="2xl">
                {data?.history?.map((item, index, a) => {
                  const { title: t, subText: s } = textMaker(item, data);
                  let timeStr = '';
                  if (index > 0) {
                    const DateOne = a[index - 1]?.event_at;
                    const DateTwo = a[index]?.event_at;
                    const duration = getTimeDiff(DateOne, DateTwo);
                    const formatedTime = durationIntoHoursMinutes(duration);
                    timeStr = formatedTime;
                  }

                  return (
                    <TaskJourneyStep
                      step={`${index + 1}`}
                      up={index !== 0}
                      down={data?.history?.length - 1 !== index}
                      title={t}
                      subText={s}
                      timeStr={timeStr}
                    />
                  );
                })}
              </View>
            </Card>
          </View>
        </ScrollView>
      </View>

      {!(data?.taskType === taskTypes.recall) && data?.withTerminalStatusFe && (
        <View pt="2xl" px="2xl" borderTopWidth="xs" borderColor="primary.50">
          <View mb="2xl">
            <Button
              onPress={() => handleRecalCallVehicle(data)}
              status={Status.PRIMARY}
              size="md"
              appearance={Appearance.FILLED}
              state={recallButtonDisabled ? State.DISABLED : State.ACTIVE}
              loading={recallButtonDisabled}
            >
              Recall
            </Button>
          </View>
        </View>
      )}
      {data?.taskType === taskTypes.recall &&
      data?.taskTagFE !== 'Out' &&
      acceptedStatus &&
      acceptedStatus?.length ? (
        <View pt="2xl" px="2xl" borderTopWidth="xs" borderColor="primary.50">
          <View mb="2xl">
            <Button onPress={() => onPressCallValet(data)}>Call Valet</Button>
          </View>
        </View>
      ) : (
        <></>
      )}

      <TaskOptions
        task={data}
        refresh={refreshAPI}
        bottomSheetModalRef={bottomSheetModalRef}
        handleOnDismiss={handleCloseBottomSheet}
        showCancelTaskModal={showCancelTaskModal}
      />
      <CancelTask
        bottomSheetModalRef={cancelTaskBottomSheetModalRef}
        handleOnDismiss={handleCancelTaskCloseBottomSheet}
        refresh={refreshAPI}
        task={{ _id: taskId }}
      />
    </View>
  );
};

const variableMaker = ({ campusId, taskId }) => {
  const tempVariable = {
    filter: {
      campus: { _id: campusId },
      _id: taskId,
    },
  };
  return tempVariable;
};

const TaskJourneyScreenWrapper = ({ navigation, route }) => {
  const campusId = useCommonStore((state) => state.campusId);
  const { id: userId, role: userRole } = useUserStore((state) => state);
  const taskId = route?.params?.taskId || '';

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
      networkCacheConfig: { force: true },
    });

  const variable = variableMaker({ campusId, taskId });

  const refreshAPI = () => {
    setRefreshedQueryOptions((prev) => {
      return {
        ...prev,
        fetchKey: (prev?.fetchKey || 0) + 1,
      };
    });
  };

  useFocusEffect(
    useCallback(() => {
      refreshAPI();
    }, []),
  );

  const bottomSheetModalRef = useRef(null);
  const cancelTaskBottomSheetModalRef = useRef(null);

  const showModal = useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);
  const showCancelTaskModal = useCallback(() => {
    cancelTaskBottomSheetModalRef.current?.show();
  }, []);

  const handleCloseBottomSheet = useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);
  const handleCancelTaskCloseBottomSheet = useCallback(() => {
    cancelTaskBottomSheetModalRef.current?.hide();
  }, []);

  return (
    <Layout level={2} edges={['bottom']}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions?.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refreshAPI}
          />
        }
      >
        <TopNavigation
          title="Car Status"
          textSize="md"
          appearance="ghost"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              onPress={() => navigateBack(navigation, userRole)}
            />
          }
          IconRight={
            <IconButton
              name="menu-dots-400"
              size="md"
              appearance="ghost"
              onPress={() => showModal()}
            />
          }
        />

        <Suspense fallback={<Loading />}>
          <View flex={1}>
            <TaskJourneyScreen
              refreshAPI={refreshAPI}
              refreshedQueryOptions={refreshedQueryOptions}
              variables={variable}
              bottomSheetModalRef={bottomSheetModalRef}
              cancelTaskBottomSheetModalRef={cancelTaskBottomSheetModalRef}
              handleCloseBottomSheet={handleCloseBottomSheet}
              handleCancelTaskCloseBottomSheet={
                handleCancelTaskCloseBottomSheet
              }
              showCancelTaskModal={showCancelTaskModal}
            />
          </View>
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default TaskJourneyScreenWrapper;
