import { useState, useCallback, useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { partnerOverviewQuery } from '../API/partnerOverview/partnerOverviewQuery';
import { isToday, toISOStringWithTimezone } from '../../../utilities/helper';

interface PartnerOverviewScreenHookTypes {
  bannerData: {
    amount: number;
    cashTotal: number;
    onlineTotal: number;
  };
  today: string;
  collectionList: {
    total: number;
    cashTotal: number;
    onlineTotal: number;
    date: string;
  }[];
}

const todaysDate = toISOStringWithTimezone(new Date());

/* eslint-disable-next-line import/prefer-default-export */
export const usePartnerOverviewScreen = (
  queryOptions: NewErrorBoundaryParentState,
): PartnerOverviewScreenHookTypes => {
  const [today, setToday] = useState(todaysDate);

  const { getPopupOrderCollections } = useLazyLoadQuery(partnerOverviewQuery, {
    filter: {
      date: today,
    },
    queryOptions,
  });

  useFocusEffect(
    useCallback(() => {
      setToday(toISOStringWithTimezone(new Date()));
    }, []),
  );

  /**
   * `getPopupOrderCollections` has sorted data by date, with first item of most recent date.
   * here we check if first item has today's data, if yes then that becomes `bannerData` else goes
   * in the collections list.
   */
  const { bannerData, dailyCollections } = useMemo(() => {
    const bnrData = {
      amount: 0,
      cashTotal: 0,
      onlineTotal: 0,
    };

    const dlyCollections = [];

    getPopupOrderCollections.forEach((collection) => {
      if (isToday(collection?.date)) {
        bnrData.amount = collection?.total ?? 0;
        bnrData.cashTotal = collection?.cashTotal ?? 0;
        bnrData.onlineTotal = collection?.onlineTotal ?? 0;
      } else {
        dlyCollections.push(collection);
      }
    });

    return {
      bannerData: bnrData,
      dailyCollections: dlyCollections,
    };
  }, [getPopupOrderCollections]);

  return {
    bannerData,
    today,
    collectionList: dailyCollections,
  };
};
