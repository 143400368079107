import React, { useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { useFragment } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { Pressable, Text, View } from '../../../../components/new';
import { homeScreenFindUserFragment } from '../API/homeScreenQuery';
import { getStyledPremiumSingleFixed } from '../../../../utilities/Utility';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import useUserStore from '../../../../stores/userStore';
import CachedImage from '../../../../components/new/custom/CachedImage';

const PointsWidget = ({ userDataFragmentRef }) => {
  const userData = useFragment(homeScreenFindUserFragment, userDataFragmentRef);
  const { wallet, setWalletBalance } = useUserStore((state) => state);
  const currentBalance = userData?.wallet?.currentBalance;
  const moneySaved = userData?.wallet?.earnedAmount;

  useEffect(() => {
    setWalletBalance({
      currentBalance: userData?.wallet?.currentBalance,
      moneySaved: userData?.wallet?.moneySaved,
    });
  }, [userData?.wallet?.currentBalance, userData?.wallet?.moneySaved]);

  const { width } = useWindowDimensions();
  const navigation = useNavigation();

  return (
    <Pressable
      onPress={() => {
        firebaseEventLogger('PlayWinComponent__points_Tap', {
          buttonName: 'points',
          screenName: 'PlayWinComponent',
          userType: 'user',
          interactionType: 'tap',
        });
        navigation.navigate('pointsScreen', { backToHome: true });
      }}
    >
      <View
        shadow="sm"
        borderRadius="lg"
        bg="background.primary.elevation"
        borderColor="grey.50"
        borderWidth="xs"
        height={200}
        width={width / 2 - 24}
        justifyContent="center"
        alignItems="flex-start"
      >
        <View mx="2xl" justifyContent="center" alignItems="flex-start">
          <CachedImage
            source={require('../../../../../assets/images/coins.webp')}
            style={{ width: 40, height: 40 }}
          />
          <Text size="3xl" mt="xs" color="primary.400" weight="medium">
            {currentBalance
              ? getStyledPremiumSingleFixed(currentBalance, 1)
              : '₹0'}
          </Text>
          <Text size="xs" mt="xs" color="primary.100">
            32nd Points Balance
          </Text>
          <Text size="lg" mt="lg" color="primary.400" weight="medium">
            {moneySaved ? getStyledPremiumSingleFixed(moneySaved, 1) : '₹0'}
          </Text>
          <Text size="xs" mt="xs" color="primary.100">
            Earned
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

export default PointsWidget;
