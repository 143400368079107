import { graphql } from 'react-relay';

const payoutOverviewScreenQuery = graphql`
  query payoutOverviewScreenQuery(
    $earningsReportfilter: PaymentReportFilter!
    $revenueReportFilter: PaymentReportFilter!
  ) {
    ...transactionsEarningReportFragment
      @arguments(filter: $earningsReportfilter)
    ...transactionsRevenueReportFragment
      @arguments(filter: $revenueReportFilter)
  }
`;

export default payoutOverviewScreenQuery;
