/**
 * @generated SignedSource<<bb8449d553b8d50139bf4515d790ce6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumTicketModelTCwoUserCanBeClaimedByRefType = "CAMPUS_LOCATION" | "RESTAURANT" | "%future added value";
export type EnumTicketModelTCwoUserIssuedByRefType = "CAMPUS_LOCATION" | "EVENT" | "POPUP_STORE" | "RESTAURANT" | "%future added value";
export type EnumticketDisplayType = "COUPON" | "QR" | "%future added value";
export type EnumticketStatus = "ACTIVE" | "CANCELLED" | "CLAIMED" | "EXPIRED" | "%future added value";
export type EnumticketTicketType = "EVENT_TICKET" | "PRODUCT_TICKET" | "REWARD_TICKET" | "%future added value";
export type FilterFindManyticketInput = {
  AND?: ReadonlyArray<FilterFindManyticketInput> | null;
  OR?: ReadonlyArray<FilterFindManyticketInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyticketOperatorsInput | null;
  additionalFields?: FilterFindManyTicketModelTCwoUserAdditionalFieldsInput | null;
  canBeClaimedBy?: ReadonlyArray<FilterFindManyTicketModelTCwoUserCanBeClaimedByInput | null> | null;
  couponId?: any | null;
  coverImg?: string | null;
  displayType?: EnumticketDisplayType | null;
  expires_on?: any | null;
  finePrint?: FilterFindManyTicketModelTCwoUserFinePrintInput | null;
  invoiceId?: any | null;
  isPrimary?: boolean | null;
  issuedBy?: FilterFindManyTicketModelTCwoUserIssuedByInput | null;
  popUpImg?: string | null;
  rewardId?: any | null;
  status?: EnumticketStatus | null;
  thumbnailImg?: string | null;
  ticketType?: EnumticketTicketType | null;
  title?: string | null;
  uId?: number | null;
  updated_at?: any | null;
};
export type FilterFindManyTicketModelTCwoUserIssuedByInput = {
  _id?: any | null;
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserIssuedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserCanBeClaimedByInput = {
  refId?: any | null;
  refType?: EnumTicketModelTCwoUserCanBeClaimedByRefType | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsInput = {
  _id?: any | null;
  event?: FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput | null;
};
export type FilterFindManyTicketModelTCwoUserAdditionalFieldsEventInput = {
  _id?: any | null;
  endTime?: any | null;
  invoicePdfPath?: string | null;
  spaces?: number | null;
  startTime?: any | null;
};
export type FilterFindManyTicketModelTCwoUserFinePrintInput = {
  _id?: any | null;
  details?: ReadonlyArray<string | null> | null;
  summary?: string | null;
};
export type FilterFindManyticketOperatorsInput = {
  _id?: FilterFindManyticket_idOperatorsInput | null;
};
export type FilterFindManyticket_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FreeParkingScreenQuery$variables = {
  filterTicket: FilterFindManyticketInput;
};
export type FreeParkingScreenQuery$data = {
  readonly tickets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly additionalFields: {
          readonly event: {
            readonly endTime: any;
            readonly spaces: number | null;
            readonly startTime: any;
          } | null;
        } | null;
        readonly canBeClaimedBy: ReadonlyArray<{
          readonly displayName: string;
          readonly venue: {
            readonly addressLine1: string | null;
            readonly geo: {
              readonly lat: string;
              readonly long: string;
            } | null;
          } | null;
        } | null> | null;
        readonly coverImg: string;
        readonly displayType: EnumticketDisplayType;
        readonly expires_on: any | null;
        readonly finePrint: {
          readonly details: ReadonlyArray<string | null> | null;
          readonly summary: string | null;
        } | null;
        readonly issuedBy: {
          readonly _id: any | null;
          readonly refType: EnumTicketModelTCwoUserIssuedByRefType;
        };
        readonly rewardId: any | null;
        readonly status: EnumticketStatus | null;
        readonly thumbnailImg: string;
        readonly ticketType: EnumticketTicketType;
        readonly title: string;
        readonly uId: number | null;
        readonly user: {
          readonly name: {
            readonly first: string;
            readonly last: string | null;
          };
        } | null;
      };
    }>;
  } | null;
};
export type FreeParkingScreenQuery = {
  response: FreeParkingScreenQuery$data;
  variables: FreeParkingScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterTicket"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": "tickets",
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filterTicket"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      }
    ],
    "concreteType": "ticketConnection",
    "kind": "LinkedField",
    "name": "findTickets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ticketEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ticket",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ticketType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coverImg",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailImg",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserName",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "first",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "last",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expires_on",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketModelTCwoUserIssuedBy",
                "kind": "LinkedField",
                "name": "issuedBy",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refType",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketModelTCwoUserFinePrint",
                "kind": "LinkedField",
                "name": "finePrint",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "summary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "details",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketModelTCwoUserAdditionalFields",
                "kind": "LinkedField",
                "name": "additionalFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserAdditionalFieldsEvent",
                    "kind": "LinkedField",
                    "name": "event",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "spaces",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketModelTCwoUserCanBeClaimedBy",
                "kind": "LinkedField",
                "name": "canBeClaimedBy",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketModelTCwoUserCanBeClaimedByVenue",
                    "kind": "LinkedField",
                    "name": "venue",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketModelTCwoUserCanBeClaimedByVenueGeo",
                        "kind": "LinkedField",
                        "name": "geo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lat",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "long",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "addressLine1",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rewardId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FreeParkingScreenQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FreeParkingScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b8608e509323194e9949758c9e67c3ca",
    "id": null,
    "metadata": {},
    "name": "FreeParkingScreenQuery",
    "operationKind": "query",
    "text": "query FreeParkingScreenQuery(\n  $filterTicket: FilterFindManyticketInput!\n) {\n  tickets: findTickets(filter: $filterTicket, first: 1) {\n    edges {\n      node {\n        _id\n        ticketType\n        coverImg\n        thumbnailImg\n        uId\n        displayType\n        user {\n          name {\n            first\n            last\n          }\n        }\n        expires_on\n        title\n        issuedBy {\n          refType\n          _id\n        }\n        finePrint {\n          summary\n          details\n        }\n        status\n        additionalFields {\n          event {\n            startTime\n            endTime\n            spaces\n          }\n        }\n        canBeClaimedBy {\n          venue {\n            geo {\n              lat\n              long\n            }\n            addressLine1\n          }\n          displayName\n        }\n        rewardId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a240014538523bfed29c5ffbd32c4dca";

export default node;
