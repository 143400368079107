import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum ScanSteps {
  VALET,
  KEY,
  REVIEW,
}

interface ActionType {
  valet?: any;
  tag?: string | null;
}

export interface InputData {
  valet: any;
  tag: string | null;
}
export interface ControllerModalStoreInterface {
  state: ScanSteps;
  setState: (action: ScanSteps) => void;
  data: InputData;
  setData: (action: ActionType) => void;
}

const useControllerModalStore = create<ControllerModalStoreInterface>()(
  devtools(
    (set) => ({
      state: ScanSteps.VALET,
      data: { valet: {}, tag: null },
      setState: (action: ScanSteps) =>
        set(() => {
          return {
            state: action,
          };
        }),
      setData: (action) =>
        set((s) => {
          return {
            data: { ...s.data, ...action },
          };
        }),
    }),
    {
      name: 'ControllerModalStore',
    },
  ),
);

export default useControllerModalStore;
