import { get } from 'lodash';
import React, { forwardRef } from 'react';
import { Platform, SafeAreaView } from 'react-native';
import styled from 'styled-components/native';
import {
  color,
  border,
  space,
  variant as variantStyle,
  shadow,
} from 'styled-system';
import { InputStates, InputTextType } from './helpers';

const variants = {
  // default: {
  //   bg: 'primary.10',
  //   borderWidth: 'xs',
  //   color: 'primary.500',
  //   borderColor: 'primary.50',
  //   borderRadius: 'md',
  // },
  // success: {
  //   bg: 'primary.10',
  //   borderWidth: 'sm',
  //   color: 'primary.500',
  //   borderColor: 'success.500',
  //   borderRadius: 'md',
  // },
  // error: {
  //   bg: 'primary.10',
  //   borderWidth: 'sm',
  //   color: 'primary.500',
  //   borderColor: 'error.500',
  //   borderRadius: 'md',
  // },
  // active: {
  //   bg: 'primary.10',
  //   borderWidth: 'xs',
  //   color: 'primary.500',
  //   // border
  //   borderColor: 'primary.100',
  //   borderRadius: 'md',
  //   // shadows
  //   shadowColor: 'rgba(0, 0, 0, 0.1)',
  //   shadowOffset: {
  //     width: 0,
  //     height: 2,
  //   },
  //   shadowRadius: 10,
  //   shadowOpacity: 1,
  // },
  [InputStates.disabled]: {
    bg: 'grey.50',
    borderWidth: '0px',
    color: 'primary.100',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.unstyled]: {
    bg: 'primary.10',
    borderWidth: '0px',
    borderColor: 'primary.50',
  },
};

const styleObj =
  Platform.OS === 'web'
    ? {
        outlineWidth: '0px',
        outlineStyle: 'none',
      }
    : {};

const StyledInput = styled.TextInput.attrs(
  ({ placeholderTextColor, theme, selectionColor }) => ({
    placeholderTextColor: get(theme.colors, placeholderTextColor),
    selectionColor: get(theme.colors, selectionColor),
  }),
)(
  {
    ...styleObj,
    width: '100%',
    color: ({ theme }) => theme.colors.primary['500'],
    // text styling
    fontFamily: ({ theme }) => theme.fonts.body,
    fontSize: ({ theme, ...props }) =>
      props.textSize ? theme.fontSizes[props.textSize] : theme.fontSizes.md,
    letterSpacing: ({ theme }) => theme.letterSpacings.sm,
    lineHeight: ({ theme, ...props }) =>
      props?.textSize ? theme.lineHeights[props?.textSize] : theme.fontSizes.xl,
  },
  color,
  border,
  space,
  shadow,
  variantStyle({ variants }),
);

function InputBase(
  {
    accessibilityLabel = '',
    onChangeText = () => {},
    type = InputTextType.TEXT,
    state = InputStates.unstyled,
    value = '',
    placeholder = 'placeholder...',
    ...props
  },
  ref,
) {
  return (
    <StyledInput
      type={type}
      placeholder={placeholder}
      borderStyle="solid"
      editable={!(state === InputStates.disabled)}
      secureTextEntry={type === InputTextType.PASSWORD}
      placeholderTextColor={
        state === InputStates.disabled ? 'primary.100' : 'grey.200'
      }
      value={value}
      onChangeText={onChangeText}
      variant={state}
      accessibilityLabel={accessibilityLabel}
      ref={ref}
      {...props}
      // === variants ===
      category="input"
      size="md"
      selectionColor="primary.500"
    />
  );
}

export default forwardRef(InputBase);
