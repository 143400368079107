/**
 * @generated SignedSource<<bc5036113a0a1289df9aa450f99121cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MapVideoToImageInput = {
  imageBase64: string;
  videoId: string;
};
export type mapVideoToImageForVideoMomentsApiMutation$variables = {
  data?: MapVideoToImageInput | null;
};
export type mapVideoToImageForVideoMomentsApiMutation$data = {
  readonly mapVideoToImageForVideoMoments: {
    readonly success: boolean;
  } | null;
};
export type mapVideoToImageForVideoMomentsApiMutation = {
  response: mapVideoToImageForVideoMomentsApiMutation$data;
  variables: mapVideoToImageForVideoMomentsApiMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "MapVideoToImage",
    "kind": "LinkedField",
    "name": "mapVideoToImageForVideoMoments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mapVideoToImageForVideoMomentsApiMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mapVideoToImageForVideoMomentsApiMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1baff93abcbed36b602740a360076524",
    "id": null,
    "metadata": {},
    "name": "mapVideoToImageForVideoMomentsApiMutation",
    "operationKind": "mutation",
    "text": "mutation mapVideoToImageForVideoMomentsApiMutation(\n  $data: MapVideoToImageInput\n) {\n  mapVideoToImageForVideoMoments(data: $data) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "314d1dcc90203bd6a49a5745aca2b0a8";

export default node;
