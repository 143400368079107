/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const transactionsScreenMainQuery = graphql`
  query transactionsScreenMainQuery(
    $filter: findInvoiceInput!
    $sort: SortConnectioninvoiceEnum
    $pageSize: Int
  ) {
    ...transactionsFragment
      @arguments(filter: $filter, sort: $sort, count: $pageSize)
  }
`;
