import React from 'react';
import { View, Text, Button } from '../../components/new';
import AutoBottomSheet from '../../components/new/custom/AutoBottomSheet';
import { State } from '../../themes/new/helper';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

const CollectCashBottomSheet = ({
  bottomSheetModalRef,
  handleOnDismiss,
  handleApprove,
  approveExitDisabled,
  amount,
}) => {
  return (
    <AutoBottomSheet bottomSheetRef={bottomSheetModalRef}>
      <View>
        <View mt="xl">
          <View justifyContent="center" alignItems="center">
            <CustomLottieView
              style={{ width: 200, height: 200 }}
              autoPlay
              loop
              source={require('../../../assets/lottie/money-animation.json')}
              containerWidth={200}
            />
          </View>
          {amount && amount > 0 ? (
            <View mt="2xl">
              <Text
                size="xl"
                weight="medium"
                color="primary.400"
                textAlign="center"
              >
                Collected ₹{amount} in cash?
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
        <View px="2xl" mt="xl" mb="xl" flexDirection="row" alignItems="center">
          <View flex={1}>
            <Button
              size="lg"
              appearance="outline"
              onPress={handleOnDismiss}
              status="primary"
            >
              No, Cancel
            </Button>
          </View>
          <View flex={1} ml="2xl">
            <Button
              size="lg"
              appearance="filled"
              state={approveExitDisabled ? State.DISABLED : State.ACTIVE}
              onPress={handleApprove}
              status="primary"
            >
              Yes, Approve
            </Button>
          </View>
        </View>
      </View>
    </AutoBottomSheet>
  );
};

export default CollectCashBottomSheet;
