import React from 'react';
import { useWindowDimensions } from 'react-native';
import * as Progress from 'react-native-progress';
import { Icon, Text, View } from '../../../../components/new';
import { colors, radii, space } from '../../../../themes/new/theme';
import InvestmentBlurView from './InvestmentBlurView';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';

const InvestmentSubscriptionDetails = ({
  data,
  consentGiven,
  onPressUnlock = () => {},
  bgColor = '',
  isOverApplied = false,
  verticlePadding = '9xl+9xl',
  headingSize = 'xs',
  headingColor = 'primary.300',
  headingWeight = 'regular',
  headingToContentMargin = '9xl',
  headingTopMargin = '',
  iconName = '',
  applied = 0,
  propertyViewCount = 0,
}) => {
  const { width } = useWindowDimensions();

  const returnBarWidth = () => {
    const res = width - space['9xl+4xl'];
    return res;
  };

  return (
    <View bg={bgColor} pt={headingTopMargin}>
      <View alignItems="center" py={verticlePadding} px="2xl">
        <View flexDirection="row" alignItems="center">
          {!isValueNullOrEmpty(iconName) ? (
            <Icon name={iconName} color="success.500" size="xl" />
          ) : null}
          <Text
            size={headingSize}
            color={headingColor}
            textAlign="center"
            weight={headingWeight}
            ml={isValueNullOrEmpty(iconName) ? 0 : 'lg'}
          >
            {data?.heading ?? 'EXCLUSIVE OPPORTUNITY'}
          </Text>
        </View>
        <Text size="2xl" color="primary.500" textAlign="center" pt="lg">
          {data?.subHeading ?? 'Limited to 50 elite members'}
        </Text>
        <View
          mt={headingToContentMargin}
          borderWidth={1}
          borderRadius="xl"
          borderColor="grey.50"
          width="100%"
          bg={
            bgColor === 'background.primary.base'
              ? 'background.secondary.base'
              : 'background.primary.base'
          }
        >
          <InvestmentBlurView
            blurHeight="100%"
            blurWidth="100%"
            showBlur={!consentGiven}
            BlurViewStyle={{ borderRadius: radii.xl }}
            iconSize="6xl"
            onPressUnlock={onPressUnlock}
          />
          <View px="2xl" py="4xl">
            {isOverApplied ? (
              <View justifyContent="center" alignItems="center" mb="2xl">
                <Text color="primary.300" size="md">
                  Investment over applied by
                </Text>
                <Text color="success.500" size="4xl" weight="medium" pt="2xl">
                  {applied / data?.data?.totalUnits}x
                </Text>
              </View>
            ) : (
              <View mb="2xl">
                <View mb="lg">
                  <Text color="primary.300" size="sm">
                    <Text color="success.500" size="xl" weight="medium">
                      {applied}{' '}
                    </Text>
                    {applied > 1 ? 'applications' : 'application'} received for{' '}
                    {data?.data?.totalUnits} units
                  </Text>
                </View>
                <Progress.Bar
                  progress={applied / data?.data?.totalUnits}
                  height={10}
                  width={returnBarWidth()}
                  unfilledColor={colors.primary[400]}
                  color={colors.success[500]}
                  borderWidth={0}
                  useNativeDriver
                  animationType="timing"
                  animationConfig={{ duration: 5000, bounciness: 0.5 }}
                />
              </View>
            )}
            {propertyViewCount > 10 ? (
              <View bg="success.10" borderRadius="md" py="sm" px="xl">
                <Text color="primary.400" size="sm">
                  {isOverApplied
                    ? `🤝 ${applied} people have applied in this opportunity`
                    : `🤝 ${propertyViewCount} people have viewed this opportunity`}
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};

export default InvestmentSubscriptionDetails;
