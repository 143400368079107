import { graphql, commitMutation, fetchQuery } from 'react-relay';

// eslint-disable-next-line import/prefer-default-export
export const discountsAndOfferScreenQuery = graphql`
  query discountsAndOfferScreenApiDiscountScreenQuery(
    $filter: restaurantpartnerDiscountfilterInput
  ) {
    getRestaurantpartnerDiscount(filter: $filter) {
      _id
      restaurantId
      discounts {
        allUser
        newUser
        corporateUser
      }
      days
      mealTime
      isActive
      user {
        isEmployee
        isNewUser
      }
    }
  }
`;
