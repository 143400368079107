import React, { PropsWithChildren } from 'react';
import View from '../../../basic/View/View';

type WrapperProps = PropsWithChildren<{
  withShadow: boolean;
}>;

const Wrapper = ({ withShadow, children }: WrapperProps) => {
  return (
    <View
      p="2xl"
      pb="none"
      shadow={withShadow ? 'md' : 'none'}
      borderRadius="lg"
      borderWidth="xs"
      borderColor={withShadow ? 'transparent' : 'primary.50'}
    >
      {children}
    </View>
  );
};

export default Wrapper;
