import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useRef,
} from 'react';
import { Animated, Image, RefreshControl } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { Layout, View, Text, Input } from '../../components/new';
import { Loading } from '../../components';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import RenderItem from './RenderItem';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import useCommonStore from '../../stores/commonStore';
import useUserStore from '../../stores/userStore';
import {
  findTaskHistoryLastAction,
  taskHistoryActionTypes,
  taskTypes,
} from '../../utilities/helper';
import useSound from '../../hooks/useSound';
import RefreshBubble from '../../components/new/primitive/Bubble/RefreshBubble';
import useCashierStore from '../../stores/cashier/taskStore';
import { getTheme } from '../../themes/new/theme';
import {
  cashierHomeScreenFindTaskFragment,
  cashierHomeScreenQuery,
} from './API/cashierHomeScreenQuery';
import {
  findAndBlurTask,
  findAndReplaceTask,
  isAlreadyInList,
} from '../valetSupervisor/helpers/helpers';
import cashierTaskUpdatedSubscriptionApi from './API/cashierTaskUpdatedSubscriptionApi';

const emptyTaskImg = require('../../../assets/images/task-empty.webp');

const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);

const CashierHomeScreen = (props) => {
  const {
    // ------ for cashier task screen ------
    tasks,
    setTasks,
    newTasks, // for subscription bubble
    setNewTasks, // for subscription
  } = useCashierStore((state) => state);

  const { queryOptions, variables, refresh, navigation } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const findTasksRef = useLazyLoadQuery(cashierHomeScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });

  const [{ findTasks }, refetchTasks] = useRefetchableFragment(
    cashierHomeScreenFindTaskFragment,
    findTasksRef,
  );
  const [refreshing, setRefreshing] = useState(false);

  const setData = () => {
    setTasks({ data: findTasks });
  };

  useEffect(() => {
    // setTaskData({ data: findTasks });
    setData();
  }, [findTasks, refreshing]);

  const noTasks = () => (
    <View justifyContent="center" alignItems="center" mt="50%">
      <Image
        source={emptyTaskImg}
        style={{ width: 80, height: 80 }}
        resizeMode="contain"
      />
      <Text size="xl" color="grey.200" weight="medium" mt="2xl">
        No tasks available
      </Text>
    </View>
  );

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    refetchTasks(
      {},
      {
        fetchPolicy: 'store-and-network',
        onComplete: () => setRefreshing(false),
      },
    );
  }, []);

  const renderItem = ({ item }) => <RenderItem item={item} refresh={refresh} />;

  const navigatToSearchScreen = () => {
    navigation.navigate('CarSearchScreen');
  };

  return (
    <>
      <View alignItems="center" zIndex={1}>
        <RefreshBubble
          onPress={() => {
            refresh();
            setNewTasks({ data: [] });
          }}
          length={newTasks?.length}
        />
      </View>
      <View flex={1}>
        <View px="2xl" mb="10" zIndex={10}>
          <Input
            editable={false}
            disabled
            rightIconName="search-outline-400"
            placeholder="Search..."
            onPressMiddleArea={navigatToSearchScreen}
          />
        </View>
        <AnimatedFlashList
          style={{
            flex: 1,
            paddingBottom: getTheme().space['4xl'],
          }}
          data={tasks}
          keyExtractor={(item) => item?._id}
          estimatedItemSize={200}
          renderItem={renderItem}
          showsVerticalScrollIndicator
          ListEmptyComponent={noTasks}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
      </View>
    </>
  );
};

const CashierHomeScreenWrapper = ({ navigation }) => {
  const campusID = useCommonStore((state) => state.campusId);
  const userId = useUserStore((state) => state.id);

  const {
    // ------ for cashier task screen ------
    tasks,
    setTasks,
    newTasks, // for subscription bubble
    setNewTasks, // for subscription
    // ------ for setting card opened ------
    setOpenedCard,
  } = useCashierStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'store-and-network',
    });
  const [netStatus, setNetStatus] = useState(true);

  const { playSound: playTaskArrivedSound } = useSound({
    soundOrigin: require('../../../assets/notification.mp3'),
  });

  const taskFilter = {
    campus: { _id: campusID },
    history: [
      { action: taskHistoryActionTypes.arrived },
      { action: taskHistoryActionTypes.completed },
    ],
    taskType: taskTypes.recall,
  };
  const taskSubscriptionFilter = {
    history: [
      taskHistoryActionTypes.arrived,
      taskHistoryActionTypes.completed,
      taskHistoryActionTypes.incomplete,
      taskHistoryActionTypes.EXITED,
    ],
  };

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'store-and-network',
      }));
    },
    [
      /* ... */
    ],
  );

  // ------ task updated subscription function for cashier ------
  const handleTaskUpdateSubscription = (res) => {
    // ============
    const taskUpdated = res?.taskUpdated;
    const lastAction = findTaskHistoryLastAction(taskUpdated);
    if (taskUpdated) {
      // ------- check if task type is recall ------
      if (taskUpdated?.taskType === taskTypes.recall) {
        // ------ if last action arrived then show bubble and play sound ------
        if (
          lastAction === taskHistoryActionTypes.arrived &&
          !isAlreadyInList(taskUpdated, newTasks)
        ) {
          playTaskArrivedSound();
          setNewTasks({
            data: [...newTasks, taskUpdated],
          });
        }

        /* ------ if last action completd and not in the task List
        then show bubble and play sound ------
        */
        if (
          lastAction === taskHistoryActionTypes.completed &&
          !isAlreadyInList(taskUpdated, tasks) &&
          !isAlreadyInList(taskUpdated, newTasks)
        ) {
          playTaskArrivedSound();
          setNewTasks({
            data: [...newTasks, taskUpdated],
          });
        }

        /* ------ if last action completd and task in the task List
        then update task in the list------
        */
        if (
          lastAction === taskHistoryActionTypes.completed &&
          isAlreadyInList(taskUpdated, tasks)
        ) {
          const newList = findAndReplaceTask(taskUpdated, tasks);
          setTasks({ data: newList });
        }

        // ------ if last action is incomplete or exited then blur task ------
        if (
          lastAction === taskHistoryActionTypes.incomplete ||
          lastAction === taskHistoryActionTypes.EXITED
        ) {
          const newList = findAndBlurTask(taskUpdated, tasks);
          setTasks({ data: newList });
        }
      }
    }
  };

  // ------ task updated subscription reference ------
  const handleTaskUpdationSubRef = useRef(handleTaskUpdateSubscription);

  useEffect(() => {
    handleTaskUpdationSubRef.current = handleTaskUpdateSubscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tasks,
    // -----------
    newTasks,
  ]);

  useFocusEffect(
    useCallback(() => {
      refresh();
      setOpenedCard('');

      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      // handleTaskUpdationSubRef.current = handleTaskUpdateSubscription;
      // apply subscription
      let cancelSubscriptionUpdateData = {
        dispose: () => {},
      };
      const cancelUpdateTaskSubscription = (disposable) => {
        cancelSubscriptionUpdateData = disposable;
      };
      const taskUpdatedDataReq = {
        campusId: campusID,
        userId,
        history: taskSubscriptionFilter.history,
        event_at: new Date().toISOString(),
        taskType: [taskTypes.recall, taskTypes.repark], // subscribed for recall or repark task type
      };
      cashierTaskUpdatedSubscriptionApi(
        taskUpdatedDataReq,
        (data) => handleTaskUpdationSubRef.current(data),
        cancelUpdateTaskSubscription,
      );

      return () => {
        // Useful for cleanup functions
        cancelSubscriptionUpdateData.dispose();
      };
    }, []),
  );

  return (
    <Layout level={2} edges={['top']}>
      <View
        flexDirection="row"
        mb="3xl"
        px="2xl"
        mt="2xl"
        alignItems="center"
        justifyContent="space-between"
      >
        <View py="xs">
          <Text size="2xl" color="primary.300">
            All Tasks
          </Text>
        </View>
      </View>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <CashierHomeScreen
            variables={{
              taskFilter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default CashierHomeScreenWrapper;
