import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import Loading from '../../../../components/loading.component';
import {
  Button,
  IconButton,
  Layout,
  TopNavigation,
  View,
} from '../../../../components/new';
import useUserStore from '../../../../stores/userStore';
import { ParkingStatus, navigateBack } from '../../../../utilities/helper';
import Drop from './Dropdown';
import useCampusLocation from '../../../../stores/campusLocation';
import useCommonStore from '../../../../stores/commonStore';
import { updateTaskForSupervisor } from '../../../../relay/taskApi';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import DisconnectedDropover from '../../../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import AddParkingDetailsScreenQuery from './AddParkingDetailsScreenQuery';
import useControllerModalStore, {
  ScanSteps,
} from '../../../../stores/controller/controllerModalStore';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../utilities/firbaseAnalytics';
import { updateParking } from '../../../../relay/parkingApi';
import { unwrapPagedData } from '../../../../utilities/paginationUtilities';
import { State } from '../../../../themes/new/helper';

const AddParkingDetailsScreen = ({
  taskId,
  parkingId,
  navigation,
  refreshedQueryOptions,
  parkingTag,
}) => {
  const { campusId } = useCommonStore((state) => state);
  const { role } = useUserStore((state) => state);
  const { currentLocation, campusLocations } = useCampusLocation(
    (state) => state,
  );
  const [endTaskDisabled, setEndTaskDisabled] = useState(false);
  // ---
  const [selectedLocation, setSelectedLocation] = useState(currentLocation);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [selectedBay, setSelectedBay] = useState(null);
  // ---
  const { setState, setData } = useControllerModalStore(
    (controllerState) => controllerState,
  );

  const parkingLocationOptions = campusLocations.filter((item) => {
    item.optionLabel = item.name;
    if (item.name === '32nd') {
      return false;
    }
    if (item?._id !== selectedLocation?._id) {
      return true;
    }
    return false;
  });

  const spotOptions = selectedLocation?.parkingSpaces?.floor.filter((item) => {
    item.optionLabel = item.floorName;
    if (item?._id !== selectedSpot?._id) {
      return true;
    }
    return false;
  });

  const bayOptions = selectedSpot?.bay?.filter((item) => {
    item.optionLabel = item.bayName;
    if (item?._id !== selectedBay?._id) {
      return true;
    }
    return false;
  });
  // ---

  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  currentLocation.optionLabel = currentLocation.name;

  const { findParking: findParkingEdges } = useLazyLoadQuery(
    AddParkingDetailsScreenQuery,
    { parkingFilter: { _id: parkingId, campus: { _id: campusId } }, count: 1 },
    {
      ...refreshedQueryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const findParking = unwrapPagedData(findParkingEdges.edges);
  // -----

  const preParkingLocation = findParking[0]?.parkingLocation;
  const preParkingLocationId = preParkingLocation?.locationId;
  const preFloorId = preParkingLocation?.parkingSpaces?.floor?.floorId;
  const preBayId = preParkingLocation?.parkingSpaces?.floor?.bay?.bayId;
  useEffect(() => {
    const tempParkingSpot = selectedLocation?.parkingSpaces?.floor.find(
      (item) => item?._id === preFloorId,
    );
    const tempParkingBay = tempParkingSpot?.bay?.find(
      (item) => item?._id === preBayId,
    );
    setSelectedSpot(tempParkingSpot);
    setSelectedBay(tempParkingBay);
  }, []);

  // -----

  const handleEndTask = () => {
    firebaseEventLogger('addParkingDetails__endTask_Tap', {
      buttonName: 'endTask',
      screenName: 'addParkingDetails',
      userType: `${role}`,
      interactionType: InteractionType.TAP,
      taskId: `${taskId}`,
      locationId: selectedLocation?._id,
      bayId: selectedSpot?._id,
      spotId: selectedBay?._id,
    });
    setEndTaskDisabled(true);
    const parkingSpaces = {
      floor: {
        floorId: selectedSpot._id,
        bay: {
          bayId: selectedBay._id,
        },
      },
    };

    const taskData = {
      _id: taskId,
      record: {
        history: [{ action: 'completed' }],
        taskEndLocation: {
          locationId: selectedLocation._id,
          parkingSpaces,
        },
      },
    };

    updateTaskForSupervisor(
      taskData,
      (res) => {
        const parkingData = {
          id: parkingId,
          record: {
            status: ParkingStatus.PARKED,
            parkingLocation: {
              locationId: selectedLocation._id,
              parkingSpaces,
            },
          },
        };
        updateParking(
          parkingData,
          (parkRes) => {
            navigation.navigate('parkingTaskEndScreen', {
              task: { ...res?.updateTask?.record, parkingTag },
            });

            dispatchSnackbar({
              msg: `Task Completed`,
              status: SnackbarStatus.success,
              version: SnackbarVersion.v2,
            });
            setEndTaskDisabled(false);
            setState(ScanSteps.VALET);
            setData({ valet: {}, tag: null });
          },
          (err) => {
            console.log('updateParking error', err);
            setEndTaskDisabled(false);
          },
        );
      },
      () => {
        dispatchSnackbar({
          msg: `Something Went Wrong.`,
          status: SnackbarStatus.warning,
          version: SnackbarVersion.v2,
        });
        setEndTaskDisabled(false);
      },
    );
  };

  return (
    <>
      <View px="2xl" mt="4xl" flex={1}>
        <View py="xs" mb="2xl">
          <Drop
            label="Parking Location"
            placeholder="Select location"
            data={parkingLocationOptions}
            selected={selectedLocation}
            onSelect={(...prop) => {
              firebaseEventLogger('addParkingDetails__editLocation_Tap', {
                buttonName: 'editLocation',
                screenName: 'addParkingDetails',
                userType: `${role}`,
                interactionType: InteractionType.TAP,
                taskId: `${taskId}`,
                locationId: selectedLocation?._id,
              });
              setSelectedLocation(...prop);
              setSelectedSpot(null);
              setSelectedBay(null);
            }}
          />
        </View>
        <View py="xs" mb="2xl">
          <Drop
            disabled={!selectedLocation}
            label="Parking Spot"
            placeholder="Select spot"
            data={spotOptions}
            selected={selectedSpot}
            onSelect={(...props) => {
              firebaseEventLogger('addParkingDetails__editBay_Tap', {
                buttonName: 'editBay',
                screenName: 'addParkingDetails',
                userType: `${role}`,
                interactionType: InteractionType.TAP,
                taskId: `${taskId}`,
                locationId: selectedLocation?._id,
                bayId: selectedSpot?._id,
              });
              setSelectedSpot(...props);
              setSelectedBay(null);
            }}
          />
        </View>
        <View py="xs" mb="2xl">
          <Drop
            disabled={!selectedSpot}
            label="Parking Bay"
            placeholder="Select bay"
            data={bayOptions}
            selected={selectedBay}
            onSelect={(...props) => {
              firebaseEventLogger('addParkingDetails__editSpot_Tap', {
                buttonName: 'editSpot',
                screenName: 'addParkingDetails',
                userType: `${role}`,
                interactionType: InteractionType.TAP,
                taskId: `${taskId}`,
                locationId: selectedLocation?._id,
                bayId: selectedSpot?._id,
                spotId: selectedBay?._id,
              });
              setSelectedBay(...props);
            }}
          />
        </View>
        {/* <View mt="9xl"> */}
        <View flex={1} justifyContent="flex-end" pb="9xl">
          <Button
            onPress={handleEndTask}
            state={
              selectedLocation &&
              selectedSpot &&
              selectedBay &&
              !endTaskDisabled
                ? State.ACTIVE
                : State.DISABLED
            }
            loading={endTaskDisabled}
            size="md"
          >
            End Task
          </Button>
        </View>
        {/* </View> */}
      </View>
    </>
  );
};

const AddParkingDetailsScreenWrapper = ({ navigation, route }) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);
  const userRole = useUserStore((state) => state.role);
  const { taskId, parkingTag, parkingId } = route?.params ?? {};

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  return (
    <Layout level={2}>
      <TopNavigation
        title="Add Parking Details"
        // appearance="ghost"
        level="none"
        // textColor="primary.10"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            // iconColor="primary.10"
            onPress={() => navigateBack(navigation, userRole)}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <AddParkingDetailsScreen
            navigation={navigation}
            refreshedQueryOptions={refreshedQueryOptions}
            taskId={taskId}
            parkingId={parkingId}
            parkingTag={parkingTag}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default AddParkingDetailsScreenWrapper;
