import React, { useState, useRef } from 'react';
import { AppState, ScrollView, useWindowDimensions } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import LottieView from 'lottie-react-native';
import moment from 'moment';
import { View, Text, Button, IconButton, Avatar } from '..';
import { makeTrackMyVehicleScreenQueryVars } from '../../../screens/new/trackMyVehicle/API/trackMyVehicleScreenQuery';
import DarkThemeWrapper from '../../../screens/new/wrapperComp/DarkThemeWrapper';
import { dateDiffInDays, formatPlateNo } from '../../../utilities/helper';
import useBoundStore from '../../../stores';
import ClearCartModal from '../../modals/ClearCartModal';
import useCommonStore from '../../../stores/commonStore';
import { findOrders } from '../../../relay/orderApi';
import useUserStore from '../../../stores/userStore';
import { getTheme, themeModes } from '../../../themes/new/theme';
import { getCount } from './SelectedCartFooter';
import findBookedEvents from '../../../relay/eventsApi';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const TrackingFooter = ({ routeName }) => {
  const [res, setRes] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const userId = useUserStore((state) => state.id);
  const animation = useRef(null);

  const campusID = useCommonStore((state) => state.campusId);
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const cart = useBoundStore((state) => state?.cart);

  const queryVars = makeTrackMyVehicleScreenQueryVars({ campusID, userId });

  const scrollRef = useRef(null);

  useFocusEffect(
    React.useCallback(() => {
      setSelectedIndex(0);
      const tasks = [];
      if (routeName === 'Home') {
        if (queryVars.filter?.ownerId) {
          const eventData = {
            userId,
            orderType: 'EVENT',
            status: 'SUCCESSFUL',
          };
          const promises = [
            findBookedEvents(eventData),
            findOrders(
              {
                userId,
                campus: { _id: campusID },
                status: ['CREATED', 'ACCEPTED'],
              },
              'UPDATED_AT_DESC',
              10,
            ),
          ];
          Promise.all(promises)
            .then((result) => {
              if (result[1].findOrders?.length) {
                const orders = result?.[1].findOrders;
                for (let i = 0; i < orders.length; i += 1) {
                  // skipping orders which are greater than 2 hours.
                  const element = orders[i];
                  const orderDate = new Date(element?.orderDate);
                  const dateNow = new Date();
                  const diffSec =
                    (dateNow.getTime() - orderDate.getTime()) / 1000;
                  if (diffSec > 3600 * 2) continue;
                  tasks.push({
                    _id: element._id,
                    type: 'orderPlaced',
                    count: getCount(cart),
                  });
                }
              }
              if (result?.[0]?.findInvoice?.length) {
                const bookings = result[0].findInvoice;
                bookings.forEach((booking) => {
                  const date = new Date(booking.workshopDetails.endTime);
                  const today = new Date();
                  if (dateDiffInDays(today, date) > 1) {
                    return;
                  }
                  if (date - today > 0) {
                    tasks.push({
                      type: 'event',
                      ...booking,
                    });
                  }
                });
              }
              if (cart?.length) {
                tasks.push({
                  type: 'cart',
                  _id: cart[0].restaurant._id,
                  restaurantName: cart[0].restaurant.displayName,
                  count: getCount(cart),
                });
              }
              setRes(tasks);
            })
            .catch((error) => {
              console.log('Promise error', error);
              if (cart.length) {
                tasks.push({
                  type: 'cart',
                  _id: cart[0].restaurant._id,
                  restaurantName: cart[0].restaurant.displayName,
                  count: getCount(cart),
                });
              }
              setRes(tasks);
            });
        } else {
          if (cart.length) {
            tasks.push({
              type: 'cart',
              _id: cart[0].restaurant._id,
              restaurantName: cart[0].restaurant.displayName,
              count: getCount(cart),
            });
          }
          setRes(tasks);
        }
      }

      return () => {};
    }, [routeName, cart.length]),
  );

  if (routeName !== 'Home') {
    return <View />;
  }

  const eventHandler = (id) => {
    firebaseEventLogger('trackingFooter__view_Tap', {
      buttonName: 'view',
      screenName: 'trackingFooter',
      userType: 'user',
      interactionType: 'tap',
      routeName,
    });
    navigation.navigate('eventTicket', {
      invoiceId: id,
      backToHome: true,
    });
  };

  const viewCartHandler = (i) => {
    if (i.type === 'orderPlaced') {
      navigation.navigate('orderSummary', {
        orderId: i._id,
      });
      return;
    }
    setVisible(false);
    navigation.navigate('order', {
      restaurant: i._id,
      restaurantName: i.restaurantName,
      // restaurantData: i.restaurant,
    });
  };

  const deleteCartHandler = () => {
    setVisible(true);
  };

  const handleOnScroll = (event) => {
    setSelectedIndex(parseInt(event.nativeEvent.contentOffset.x / width, 10));
  };

  return (
    <>
      {res.length ? (
        <DarkThemeWrapper>
          <View bg="opacity.90" height={72}>
            <ScrollView
              ref={scrollRef}
              // contentContainerStyle={{ paddingHorizontal: 16, paddingVertical: 8 }}
              horizontal
              showsHorizontalScrollIndicator={false}
              pagingEnabled
              directionalLockEnabled
              scrollEventThrottle={5}
              onScroll={handleOnScroll}
            >
              {res.map((item) => {
                if (item.type === 'event') {
                  return (
                    <View
                      key={item._id}
                      px="2xl"
                      pb="lg"
                      flex={1}
                      width={width}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <View flexDirection="row" alignItems="center">
                        <View
                          borderRadius="full"
                          backgroundColor={
                            getTheme(themeModes.dark).colors.opacity[90]
                          }
                        >
                          <Avatar
                            source={
                              // item.sourceDetails.event?.host?.media?.mediaURL ??
                              'https://ik.imagekit.io/32ndhub/assets/32nd_logo_black.png'
                            }
                          />
                        </View>
                        <View ml="lg">
                          <Text size="md" weight="medium" mb="sm">
                            Upcoming Event
                          </Text>
                          <Text size="xs" color="primary.100">
                            {`${moment(item.workshopDetails.startTime).format(
                              'MMM Do, YYYY',
                            )} | ${moment(
                              item.workshopDetails.startTime,
                            ).format('hh:mm A')}`}
                          </Text>
                        </View>
                      </View>
                      <Button
                        size="md"
                        status="info"
                        appearance="ghost"
                        iconRight="keyboard-arrow-right-300"
                        onPress={() => eventHandler(item._id)}
                      >
                        View
                      </Button>
                    </View>
                  );
                }
                if (item.type === 'cart' || item.type === 'orderPlaced') {
                  return (
                    <View
                      key={item._id}
                      px="2xl"
                      pb="lg"
                      flex={1}
                      width={width}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/* <View flexDirection="row"> */}
                      <View ml="lg" flex={2}>
                        <Text size="md" weight="medium" numberOfLines={1}>
                          {item.type === 'cart'
                            ? item.restaurantName
                            : 'Order has been placed'}
                        </Text>
                        <Text size="xs" color="primary.100" numberOfLines={1}>
                          {item.type === 'cart'
                            ? `${item.count ?? ''} ${
                                item.count > 1 ? 'items added' : 'item added'
                              }`
                            : 'Your order will be served soon'}
                        </Text>
                      </View>
                      {/* </View> */}
                      <View flexDirection="row">
                        <Button
                          size="md"
                          ml="md"
                          appearance="filled"
                          status="primary"
                          onPress={() => viewCartHandler(item)}
                        >
                          {item.type === 'cart' ? 'View Cart' : 'View Details'}
                        </Button>
                        {item.type === 'cart' ? (
                          <IconButton
                            name={
                              item.type === 'cart'
                                ? 'delete-400'
                                : 'close-outline2-400'
                            }
                            size="md"
                            appearance="ghost"
                            iconColor="primary.500"
                            onPress={deleteCartHandler}
                            shape={undefined}
                            bg={undefined}
                            state={undefined}
                            iconSize="md"
                            text={undefined}
                          />
                        ) : null}
                      </View>
                    </View>
                  );
                }
              })}
            </ScrollView>
            <View alignItems="center">
              <View position="absolute" bottom="md">
                <View flexDirection="row" justifyContent="center">
                  {res.length > 1
                    ? res.map((item, index) => {
                        return (
                          <View
                            key={`dash-${item._id}`}
                            width={12}
                            height={2}
                            bg={
                              selectedIndex === index
                                ? 'primary.500'
                                : 'grey.50'
                            }
                            mr="lg"
                          />
                        );
                      })
                    : null}
                </View>
              </View>
            </View>
          </View>
        </DarkThemeWrapper>
      ) : null}
      {cart.length ? (
        <ClearCartModal
          visible={visible}
          setVisible={setVisible}
          restaurantName={cart[0].restaurantName}
          restaurantId={cart[0].restaurantId}
          navigation={navigation}
          clear
        />
      ) : null}
    </>
  );
};

export default React.memo(TrackingFooter);
