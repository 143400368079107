import React from 'react';
import { variant, layout, space, color, border } from 'styled-system';
import styled from 'styled-components/native';
import { Appearance } from '../../../../themes/new/helper';

const categoryVariants = ({ theme }) => {
  return variant({
    prop: 'category',
    variants: {
      none: {},
      lg: {
        padding: theme.space.lg,
      },
      '2xl': {
        padding: theme.space['2xl'],
      },
      '4xl': {
        padding: theme.space['4xl'],
      },
    },
  });
};

const radiusVariants = ({ theme }) => {
  return variant({
    prop: 'radius',
    variants: {
      md: {
        borderRadius: theme.radii.md,
      },
    },
  });
};

const appearanceVariants = ({ theme, withBorder }) => {
  return variant({
    prop: 'appearance',
    variants: {
      filled: {
        backgroundColor: theme.colors.background.primary.elevation,
        ...theme.boxShadows.md,
        borderColor: withBorder ? theme.colors.grey[50] : 'transparent',
        borderWidth: theme.borderWidths.xs,
      },
      outline: {
        bg: 'transparent',
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors.primary[50],
      },
    },
  });
};

const StyledCard = styled.View`
  ${(props) => categoryVariants(props)};
  ${(props) => radiusVariants(props)};
  ${(props) => appearanceVariants(props)};
  ${color}
  ${layout}
  ${space}
  ${border}
`;

const Card = ({ children, ...rest }) => {
  return (
    <StyledCard
      category="2xl"
      radius="md"
      appearance={Appearance.FILLED}
      withBorder={false}
      {...rest}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
