import React, { memo, useCallback, useEffect, useState } from 'react';
import { Image } from 'react-native';
import useFeedbackModalStore, {
  FeedbackScreenSteps,
} from '../../stores/FeedbackModalStore';
import { Text, View } from '../new';
import IconComponent from './IconComponent';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { feedbacksTopics } from '../../utilities/ratingOptions';

const areEqual = (prevProps, nextProps) => {
  if (prevProps?.selectedVal !== nextProps?.selectedVal) return false;
  return true;
};

const SelectRating = ({
  isTYCScreen = false,
  parkingId = '',
  hasFeedBack = false,
  selectedVal = -1,
}) => {
  const componentState = useFeedbackModalStore((state) => state);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(componentState?.rating);
    if (selectedVal > -1) {
      setSelected(selectedVal);
    }
  }, [componentState?.rating, selectedVal]);

  const handleOnPress = useCallback((val) => {
    firebaseEventLogger('SelectRating__rating_Tap', {
      buttonName: 'rating',
      screenName: 'SelectRating',
      userType: 'user',
      interactionType: 'tap',
      rating: val?.key,
      ratingType: componentState.feedbackType,
    });
    setSelected(val?.key);
    componentState.setRating(val?.key);
    if (isTYCScreen) {
      componentState.setParkingId(parkingId);
      componentState.setFeedbackType(feedbacksTopics.VALET);
      componentState.setIsOpen(true);
      componentState.setCurrentScreen(FeedbackScreenSteps.RATING_DETAILS);
    } else {
      setTimeout(() => {
        componentState.setCurrentScreen(FeedbackScreenSteps.RATING_DETAILS);
      }, 500);
    }
  }, []);

  return (
    <View
      justifyContent="center"
      px="2xl"
      mt={isTYCScreen ? 0 : '2xl'}
      alignItems="center"
    >
      {isTYCScreen ? null : (
        <Text size="xl" color="primary.500">
          Enjoyed your visit to 32nd?
        </Text>
      )}
      {isTYCScreen ? null : (
        <View flexDirection="row" mt="lg">
          <Text size="md" color="primary.300" mr="xs">
            Drop a feedback and help us improve
          </Text>
        </View>
      )}
      <View
        mt={isTYCScreen ? 0 : '3xl'}
        justifyContent={isTYCScreen ? 'space-between' : 'center'}
        flexDirection="row"
        alignItems="center"
        width="100%"
      >
        {isTYCScreen ? (
          <Text size="lg" color="primary.300" numberOfLines={1} mr="lg">
            Rate Valet
          </Text>
        ) : null}
        <IconComponent
          onPress={(val) => handleOnPress(val)}
          selected={selected}
          isTYCScreen={isTYCScreen}
          hasFeedBack={hasFeedBack}
        />
      </View>
    </View>
  );
};

export default memo(SelectRating, areEqual);
