import { Text, View } from '../../../components/new';
import BreakupPanel from '../../../components/new/custom/transaction/BreakupPanel';
import { FontWeights } from '../../../themes/new/helper';
import { DateFormatsEnum, getFormatedDate } from '../../../utilities/helper';
import { TransactionsEarningsReport } from '../types';

const PayoutCalculationPanel = ({
  earningsReport,
}: {
  earningsReport: TransactionsEarningsReport;
}) => {
  const { payoutAmount, collectedAmount, compensatedLoyaltyPoints } =
    earningsReport.formated;

  return (
    <BreakupPanel.Wrapper>
      <View pb="2xl" flexDirection="row" justifyContent="space-between">
        <View p="sm" alignItems="center">
          <Text size="sm" weight={FontWeights.MEDIUM}>
            {payoutAmount}
          </Text>
          <Text size="2xs" color="primary.100">
            {getFormatedDate(earningsReport.date, DateFormatsEnum.MonthFull)}{' '}
            Earnings
          </Text>
        </View>
        <View pt="sm" pr="sm">
          <Text>=</Text>
        </View>
        <View p="sm" alignItems="center">
          <Text size="sm" weight={FontWeights.MEDIUM} color="success.500">
            {collectedAmount}
          </Text>
          <Text size="2xs" color="primary.100">
            My Earnings
          </Text>
        </View>
        <View pt="sm" pl="md">
          <Text>+</Text>
        </View>
        <View p="sm" alignItems="center">
          <Text size="sm" weight={FontWeights.MEDIUM} color="success.500">
            {compensatedLoyaltyPoints}
          </Text>
          <Text size="2xs" color="primary.100">
            Points Compensation
          </Text>
        </View>
      </View>
    </BreakupPanel.Wrapper>
  );
};

export default PayoutCalculationPanel;
