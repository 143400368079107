import React from 'react';
import { StyleSheet } from 'react-native';
import { Text, View } from '../../../../../../components/new';
import { FontWeights } from '../../../../../../themes/new/helper';
import { daysToMonths, toRupees } from '../../../../../../utilities/helper';
import GoldButton from '../../../components/GoldButton';
import { blackMembership } from '../../../../../../utilities/Constant';
import DarkThemeWrapper from '../../../../wrapperComp/DarkThemeWrapper';

const { BASE_PRICE } = blackMembership;

interface BMPaymentFooterProps {
  price: number;
  duration: number; // days
  onBuyMembership: () => void;
}

const BMPaymentFooter = ({
  price,
  duration,
  onBuyMembership,
}: BMPaymentFooterProps) => {
  return (
    <DarkThemeWrapper>
      <View
        p="2xl"
        pb="5xl"
        flexDirection="row"
        justifyContent="space-between"
        bg="background.primary.elevation"
        borderTopWidth="xs"
        borderColor="primary.50"
        color="primary.100"
      >
        <View flex={1}>
          <View mb="sm" flexDirection="row" alignItems="center">
            <Text
              style={styles.slashedText}
              mr="md"
              size="md"
              weight={FontWeights.MEDIUM}
              color="primary.100"
            >
              {toRupees(BASE_PRICE)}
            </Text>
            <Text size="xl" weight={FontWeights.MEDIUM} color="primary.500">
              {toRupees(price)}
            </Text>
          </View>
          <View>
            <Text
              numberOfLines={1}
              ellipsizemode="tail"
              size="xs"
              color="primary.200"
            >
              {daysToMonths(duration)} membership
            </Text>
          </View>
        </View>
        <View justifyContent="center">
          <View maxWidth={186}>
            <GoldButton onPress={onBuyMembership} text="Buy 32nd Plus" />
          </View>
        </View>
      </View>
    </DarkThemeWrapper>
  );
};

const styles = StyleSheet.create({
  slashedText: {
    textDecorationLine: 'line-through',
  },
});

export default BMPaymentFooter;
