import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import AppConstants from '../utilities/AppConstants';
import { firebaseEventLogger } from '../utilities/firbaseAnalytics';
import useLoginModalStore from '../stores/loginModalStore';
import { handleWhatsappLogin } from '../utilities/loginHelper';

const NavigationConfig = {
  prefixes: [
    Linking.createURL('/'),
    AppConstants.APP_ENV === 'production'
      ? 'https://club.32nd.com/'
      : 'https://staging.club.32nd.com/',
  ],
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();

    if (url != null) {
      if (Platform.OS !== 'web' && url.includes('redirectToPlatformStore')) {
        const deepLinkUrl = new URL(url);
        let newDeepLink = deepLinkUrl.searchParams.get('deeplink');
        newDeepLink = newDeepLink.replace(/:::/g, '?');
        newDeepLink = newDeepLink.replace(/::/g, '=');
        newDeepLink = newDeepLink.replace(/:/g, '&');
        if (newDeepLink) {
          const baseURL =
            AppConstants.APP_ENV === 'production'
              ? 'https://club.32nd.com/'
              : 'https://staging.club.32nd.com/';
          return baseURL + newDeepLink;
        }
      }
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();

    if (response?.notification?.request?.content?.data?.url) {
      firebaseEventLogger('NOTIFICATION_CLICKED', {
        ...response?.notification?.request?.content,
      });
    }

    return response?.notification?.request?.content?.data?.url || '';
  },
  subscribe(listener: any) {
    const onReceiveURL = ({ url }: { url: string }) => {
      let newUrl = url;
      if (newUrl != null && Platform.OS !== 'web') {
        const isLoginModalOpen = useLoginModalStore.getState().isOpen;
        if (url.includes('login') && url.includes('waId') && isLoginModalOpen) {
          const waIdUrl = new URL(url);
          const waId = waIdUrl.searchParams.get('waId');
          handleWhatsappLogin(waId);
          return;
        }
        if (url.includes('redirectToPlatformStore')) {
          const deepLinkUrl = new URL(url);
          let newDeepLink = deepLinkUrl.searchParams.get('deeplink');
          newDeepLink = newDeepLink.replace(/:::/g, '?');
          newDeepLink = newDeepLink.replace(/::/g, '=');
          newDeepLink = newDeepLink.replace(/:/g, '&');
          if (newDeepLink) {
            const baseURL =
              AppConstants.APP_ENV === 'production'
                ? 'https://club.32nd.com/'
                : 'https://staging.club.32nd.com/';
            newUrl = baseURL + newDeepLink;
          }
        }
      }
      listener(newUrl);
    };

    // Listen to incoming links from deep linking
    const eventListenerSubscription = Linking.addEventListener(
      'url',
      onReceiveURL,
    );

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        const url = response?.notification?.request?.content?.data?.url || '';

        if (response?.notification?.request?.content?.data?.url) {
          firebaseEventLogger('NOTIFICATION_CLICKED', {
            ...response?.notification?.request?.content,
          });
        }

        // Let React Navigation handle the URL
        listener(url);
      },
    );

    return () => {
      // Clean up the event listeners
      eventListenerSubscription.remove();
      subscription.remove();
    };
  },
};

export default NavigationConfig;
