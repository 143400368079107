/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import React, { useState, Suspense, useCallback, useRef } from 'react';
import { Platform, Dimensions, Image } from 'react-native';
import { graphql, useLazyLoadQuery, useFragment } from 'react-relay';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FlashList } from '@shopify/flash-list';
import Animated from 'react-native-reanimated';
import { Layout, View, Text } from '../../components/new';
import DetailCard from '../../components/new/custom/DetailCard';
import Loading from '../../components/loading.component';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import theme from '../../themes/new/theme';
import RenderHeader from '../../components/new/custom/RestaurantsHeader';
import NewSearchScreen from './Search/NewSearchScreen';
import useCommonStore from '../../stores/commonStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import NewErrorView from '../../utilities/NewErrorView';
import { dynamicHeightMaker } from '../../utilities/helper';

// const sort = '_ID_ASC';

const storesQuery = graphql`
  query storesQuery($filter: findGalleryFilterInput) {
    findStore(filter: $filter) {
      ...stores_findStore
    }
  }
`;

const RenderStoresScreen = (props) => {
  const { queryOptions, variables, navigation, scrollHandler, route } = props;
  // const [selectedIndex, setSelectedIndex] = useState(0);
  // const bottomSheetModalRef = useRef(null);
  const { findStore } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLazyLoadQuery(storesQuery, variables, {
      ...queryOptions,
      networkCacheConfig: { force: true },
    });

  const AnimatedFlashlist = Animated.createAnimatedComponent(FlashList);
  const sortFn = (a, b) => {
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    return 0;
  };

  const sortByPrimary = (a, b) => {
    return b.primary - a.primary;
  };

  const renderItem = ({ item, index }) => {
    const coverImages =
      item?.mediaGallery &&
      item?.mediaGallery?.length &&
      JSON.parse(JSON.stringify(item?.mediaGallery))
        .filter(
          (ele) =>
            ele?.sectionTitle !== 'Awards___Recognitions' &&
            ele?.sectionTitle !== 'Founder_Story',
        )
        .sort(sortFn)
        .map((ele) => ele?.media && ele?.media.sort(sortFn))
        .flat()
        .sort((a, b) => b.cover - a.cover);
    // const tagLine =
    //   item?.description &&
    //   item?.description?.cusines?.length &&
    //   JSON.parse(JSON.stringify(item?.description?.cusines))
    //     .sort(sortByPrimary)
    //     .map((ele) => {
    //       return ele?.name === 'fastfood'
    //         ? 'Fast Food'
    //         : SplitAndCapitalise(ele.name);
    //     });
    return (
      <View mt={index === 0 ? 0 : '4xl'} mx="2xl" key={item?._id}>
        <DetailCard
          key={item?._id}
          displayName={item?.displayName}
          tagLine={item?.description?.tagLine}
          // description={item?.description?.summary}
          coverImages={coverImages && coverImages?.length ? coverImages : []}
          //   ratingScore={item?.overAllRating > 0 ? item?.overAllRating : 0}
          onPress={() => {
            navigation.navigate('Storescreen', { storeId: item._id });
          }}
        />
      </View>
    );
  };

  const { top, bottom } = useSafeAreaInsets();

  const storeList = useFragment(
    graphql`
      fragment stores_findStore on stores @relay(plural: true) {
        _id
        displayName
        logo
        description {
          summary
          tags {
            _id
            name
            icon {
              iconName
              color
              _id
            }
          }
          tagLine
        }
        overAllRating
        campus {
          _id
        }
        mediaGallery {
          _id
          sectionTitle
          description
          displayOrder
          media {
            _id
            cover
            prime
            mediaURL
            mediaType
            sectionCover
            displayOrder
          }
        }
      }
    `,
    findStore,
  );

  const RenderFlashList = useCallback(() => {
    return (
      <AnimatedFlashlist
        // style={{ flex: 1 }}
        contentContainerStyle={{
          paddingTop: top + 62 + theme.space['2xl'],
          paddingBottom:
            navigation.getState()?.type !== 'tab'
              ? Platform.OS === 'web'
                ? 80 + theme.space['4xl']
                : bottom + theme.space['4xl']
              : Platform.OS === 'web'
              ? useBottomTabBarHeight() && useBottomTabBarHeight() !== 0
                ? useBottomTabBarHeight() + 80 + theme.space['4xl']
                : 80 + theme.space['4xl']
              : bottom + theme.space['4xl'],
        }}
        data={storeList}
        keyExtractor={(item) => item?._id}
        // ListHeaderComponent={RenderHeader}
        renderItem={renderItem}
        showsVerticalScrollIndicator
        // pinchGestureEnabled={false}
        ListEmptyComponent={() => (
          <View alignItems="center" justifyContent="center">
            <Image
              source={require('../../../assets/images/businessman-searching-for-an-answer.webp')}
              style={{
                width: Dimensions.get('screen').width * 0.497,
                height: dynamicHeightMaker(
                  Dimensions.get('screen').width * 0.497,
                  1,
                ),
                resizeMode: 'contain',
              }}
              progressiveRenderingEnabled
            />
            <View alignItems="center" justifyContent="center">
              <Text size="md" color="primary.100">
                Oh No!
              </Text>
              <Text size="md" color="primary.100">
                Sorry, no stores found
              </Text>
            </View>
          </View>
        )}
        // renderScrollComponent={(extraProps) => <ScrollView {...extraProps} />}
        scrollIndicatorInsets={{ top: 64 }}
        // stickyHeaderIndices={[0]}
        estimatedItemSize={481}
        drawDistance={1000}
        // onScroll={scrollHandler}
        scrollEventThrottle={16}
        // onEndReached={() => {
        //   setLimit(limit + 2);
        // }}
      />
    );
  }, []);

  return (
    <>
      <View height={Platform.OS === 'web' ? '100vh' : '100%'}>
        <RenderFlashList />
      </View>
    </>
  );
};

const StoresScreen = ({ navigation, route }) => {
  const campusID = useCommonStore((state) => state.campusId);
  const { searchText } = route?.params ?? '';
  const filter =
    searchText?.length > 0
      ? {
          campus: { _id: campusID || '' },
          search: { restaurantTitleOrMenuItem: searchText },
        }
      : { campus: { _id: campusID || '' } };
  const bottomSheetModalRef = useRef(null);
  const [netStatus, setNetStatus] = useState(true);
  const [storeFilter, setStoreFilter] = useState(filter);
  // const [sortFilter, setSortFilter] = useState(sort);
  const [openSearchScreen, setOpenSearchScreen] = useState(false);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  const onLeftIconClick = () => {
    setOpenSearchScreen(false);
  };

  return (
    <Layout level={openSearchScreen ? '3' : '1'}>
      {openSearchScreen && (
        <NewSearchScreen
          navigation={navigation}
          onLeftIconClick={onLeftIconClick}
          openKeyboardOnMount
          // removeSafeArea
        />
      )}
      {!openSearchScreen && (
        <>
          <NewErrorBoundary
            fetchKey={refreshedQueryOptions.fetchKey}
            fallback={
              <NewErrorView
                errorMsg="Sorry something went wrong"
                reload={refresh}
              />
            }
          >
            <Suspense fallback={<Loading />}>
              <RenderStoresScreen
                queryOptions={refreshedQueryOptions ?? {}}
                variables={{
                  filter: storeFilter,
                }}
                fetchKey={refreshedQueryOptions.fetchKey}
                navigation={navigation}
                route={route}
              />
            </Suspense>
          </NewErrorBoundary>
          <View
            // onLayout={(e) => onLayout(e)}
            position="absolute"
            top={0}
            left={0}
            right={0}
            // zIndex={10}
          >
            <RenderHeader
              //   chipData={chipData}
              //   setChipData={setChipData}
              navigation={navigation}
              //   actionBarStyle={actionBarStyle}
              //   setSelectedFilter={setSelectedFilter}
              //   handlePresentModalPress={handlePresentModalPress}
              //   setRadioBtn={setRadioBtn}
              //   setCheckboxes={setCheckboxes}
              //   setRestaurantFilter={setStoreFilter}
              //   setData={setData}
              //   restaurantFilter={storeFilter}
              refresh={refresh}
              //   filter={filter}
              searchText={searchText}
              setOpenSearchScreen={setOpenSearchScreen}
              //   hideChips={hideChips}
              placeholderText="Search something..."
            />
          </View>
          <DisconnectedDropover
            setNetStatus={setNetStatus}
            text="No Internet Connection"
            icon="wifi-off-outline"
          />
        </>
      )}
    </Layout>
  );
};

export default StoresScreen;
