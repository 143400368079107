/**
 * @generated SignedSource<<089304349e008ca9e91502ee3709ae58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumstoresCategories = "accessories" | "all" | "fashion" | "skincare" | "%future added value";
export type EnumstoresDescriptionTagsIconColor = "error" | "info" | "success" | "%future added value";
export type EnumstoresMediaGalleryMediaMediaType = "image" | "video" | "%future added value";
export type EnumstoresMediaGallerySectionTitle = "Ambience" | "Awards___Recognitions" | "Founder_Story" | "Product" | "%future added value";
export type EnumstoresOpenHoursDay = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type storeDetail_findStore$data = ReadonlyArray<{
  readonly _id: any;
  readonly address: {
    readonly _id: any | null;
    readonly addressLine1: string;
    readonly geo: {
      readonly lat: string;
      readonly long: string;
    };
  };
  readonly campus: {
    readonly _id: any;
  };
  readonly categories: ReadonlyArray<EnumstoresCategories | null> | null;
  readonly contact: {
    readonly phone: ReadonlyArray<{
      readonly number: string;
      readonly prefix: string;
    } | null> | null;
  };
  readonly description: {
    readonly summary: string | null;
    readonly tagLine: ReadonlyArray<string | null> | null;
    readonly tags: ReadonlyArray<{
      readonly _id: any | null;
      readonly icon: {
        readonly _id: any | null;
        readonly color: EnumstoresDescriptionTagsIconColor | null;
        readonly iconName: string | null;
      } | null;
      readonly name: string | null;
    } | null> | null;
  };
  readonly displayName: string;
  readonly externalLinks: ReadonlyArray<{
    readonly _id: any | null;
    readonly posterURL: string;
    readonly publisher: string | null;
    readonly readingTime: number | null;
    readonly title: string;
    readonly webURL: string;
  } | null> | null;
  readonly logo: string | null;
  readonly mediaGallery: ReadonlyArray<{
    readonly _id: any | null;
    readonly description: string | null;
    readonly displayOrder: number;
    readonly media: ReadonlyArray<{
      readonly _id: any | null;
      readonly cover: boolean | null;
      readonly coverVideo: boolean | null;
      readonly description: string | null;
      readonly displayOrder: number;
      readonly mediaType: EnumstoresMediaGalleryMediaMediaType;
      readonly mediaURL: string;
      readonly prime: boolean | null;
      readonly sectionCover: boolean | null;
      readonly title: string | null;
    } | null> | null;
    readonly sectionTitle: EnumstoresMediaGallerySectionTitle;
  } | null> | null;
  readonly openHours: ReadonlyArray<{
    readonly closesAt: string | null;
    readonly day: EnumstoresOpenHoursDay | null;
    readonly opensAt: string | null;
  } | null> | null;
  readonly overAllRating: number | null;
  readonly ratingTagLine: string | null;
  readonly ratings: ReadonlyArray<{
    readonly _id: any | null;
    readonly displayIcon: string;
    readonly platform: string;
    readonly ratingCount: number | null;
    readonly ratingScore: number | null;
  } | null> | null;
  readonly " $fragmentType": "storeDetail_findStore";
}>;
export type storeDetail_findStore$key = ReadonlyArray<{
  readonly " $data"?: storeDetail_findStore$data;
  readonly " $fragmentSpreads": FragmentRefs<"storeDetail_findStore">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "storeDetail_findStore",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesDescription",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "storesDescriptionTags",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "storesDescriptionTagsIcon",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "iconName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "color",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tagLine",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overAllRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesOpenHours",
      "kind": "LinkedField",
      "name": "openHours",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "day",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "opensAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closesAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesRatings",
      "kind": "LinkedField",
      "name": "ratings",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayIcon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratingScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratingCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ratingTagLine",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesAddress",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "storesAddressGeo",
          "kind": "LinkedField",
          "name": "geo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "long",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesCampus",
      "kind": "LinkedField",
      "name": "campus",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesMediaGallery",
      "kind": "LinkedField",
      "name": "mediaGallery",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sectionTitle",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "storesMediaGalleryMedia",
          "kind": "LinkedField",
          "name": "media",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaURL",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sectionCover",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cover",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverVideo",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "storesExternalLinks",
      "kind": "LinkedField",
      "name": "externalLinks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readingTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "posterURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publisher",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "taskTaskStartLocationContact",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "taskTaskStartLocationContactPhone",
          "kind": "LinkedField",
          "name": "phone",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prefix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categories",
      "storageKey": null
    }
  ],
  "type": "stores",
  "abstractKey": null
};
})();

(node as any).hash = "bbd53df37d24c89d65cc25e3418c0fe1";

export default node;
