import { useState, useCallback, useEffect, useReducer, useMemo } from 'react';
import moment from 'moment';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useFocusEffect } from '@react-navigation/native';
import { StateType, RefreshQueryOptions, TaskDataType } from './types';
import overviewQueries from '../../../relay/supervisorOverviewApi';
import {
  parkingOverviewReducer,
  initialState,
  actionTypes,
} from './parkingOveriewReducer';
import {
  getPickerTimeStamps,
  buildPickerData,
  buildMetricsData,
  buildTasksData,
  dateFormat,
  getFilteredTasks,
} from './utils';
import useCommonStore from '../../../stores/commonStore';
import { filterComponentConfig, taskType } from './constants';

const { mainQuery, tasksQuery } = overviewQueries;

const useParkingOverview = (
  queryOptions,
  refresh,
  navigation,
): {
  state: StateType;
  filteredTasks: Array<TaskDataType>;
  handleDaySelection: (date: string) => void;
  setTaskType: (taskType: string) => void;
  handleTaskfilterSelection: (idx: number) => void;
  navigateToParkingOverviewScreen: () => void;
  findParkingLocation: any;
} => {
  const [state, dispatch] = useReducer(parkingOverviewReducer, initialState);
  const campusID = useCommonStore((s) => s.campusId);

  /** --------------- Setting up queries ---------- */
  const today = moment().format(dateFormat);
  const { sTS, eTS } = getPickerTimeStamps();
  const mainQueryData = {
    /** for metrics query */
    startTimeStamp: sTS,
    endTimeStamp: eTS,
    /** for parking location query */
    parkingLocationFilter: { campus: { _id: campusID } },
  };

  const tasksQueryData = {
    taskLimit: 100,
    sort: 'DELAY_AT_DESC',
    taskFilter: {
      taskType: state.taskType,
      campus: { _id: campusID },
      created_at: state.selectedDate || today,
      history: [{ action: 'completed' }, { action: 'exited' }],
    } as Record<string, any>,
  };

  if (state.taskType === taskType.RECALL) {
    tasksQueryData.taskFilter.metrics = {
      isAcceptedDelayed: true,
      isWithinETA: false,
    };
  } else {
    tasksQueryData.taskFilter.metrics = {
      isParkingDelayed: true,
      isOnbardingDelayed: true,
    };
  }

  /** Main query fetching metrices and parking location */
  const { getTaskMetricsDateWise, findParkingLocation } = useLazyLoadQuery(
    mainQuery,
    mainQueryData,
    {
      fetchKey: queryOptions.fetchKey,
      fetchPolicy: 'store-and-network',
      networkCacheConfig: { force: true },
    },
  );

  /** Tasks query fetching tasks for the selected day */
  const { findTasks } = useLazyLoadQuery(tasksQuery, tasksQueryData, {
    fetchKey: queryOptions.fetchKey,
    fetchPolicy: 'store-and-network',
    networkCacheConfig: { force: true },
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        refresh();
        if (getTaskMetricsDateWise?.metrics) {
          const fetchedMetrics = getTaskMetricsDateWise?.metrics;
          const allMetricsData = fetchedMetrics.map((m) => buildMetricsData(m));
          const lastMetricItemData = allMetricsData[allMetricsData.length - 1];
          const pickerData = buildPickerData(fetchedMetrics);

          dispatch({ type: actionTypes.SET_SELECTED_DATE, payload: today });
          dispatch({
            type: actionTypes.SET_DAYPICKER_DATA,
            payload: pickerData,
          });
          dispatch({
            type: actionTypes.SET_METRICS_DATA,
            payload: lastMetricItemData,
          });
          dispatch({
            type: actionTypes.SET_ALL_METRICS_DATA,
            payload: allMetricsData,
          });
        }
      })();
    }, []),
  );

  /** handling fetched tasks */
  useFocusEffect(
    useCallback(() => {
      if (findTasks) {
        const fetchedTasks = findTasks.map((t) => buildTasksData(t));
        dispatch({
          type: actionTypes.SET_TASKS_DATA,
          payload: fetchedTasks || [],
        });
      }
    }, [findTasks]),
  );

  /** tasks get filtered everytime `taskFilter` changes */
  const filteredTasks = useMemo(() => {
    const { tasksData, taskFilter } = state;
    if (tasksData) {
      return getFilteredTasks(taskFilter, tasksData);
    }
    return [];
  }, [state.tasksData, state.taskFilter]);

  const handleDaySelection = (selectedDate: string) => {
    const selectedDayMetrics = state.allMetricsData.filter(
      (m) => m.date === selectedDate,
    )[0];

    if (selectedDayMetrics) {
      dispatch({
        type: actionTypes.SET_METRICS_DATA,
        payload: selectedDayMetrics,
      });
    }
    dispatch({ type: actionTypes.SET_SELECTED_DATE, payload: selectedDate });
  };

  const setTaskType = (taskTyp: string) => {
    dispatch({ type: actionTypes.SET_TASK_TYPE, payload: taskTyp });
  };

  const handleTaskfilterSelection = (idx: number) => {
    const filterSelected = filterComponentConfig.find((f) => f.id === idx).name;
    dispatch({ type: actionTypes.SET_TASK_FILTER, payload: filterSelected });
  };

  const navigateToParkingOverviewScreen = useCallback(() => {
    navigation.navigate('parkingOverview', {
      campusId: campusID,
    });
  }, [navigation, campusID]);

  return {
    state,
    filteredTasks,
    findParkingLocation,
    handleDaySelection,
    setTaskType,
    handleTaskfilterSelection,
    navigateToParkingOverviewScreen,
  };
};

export default useParkingOverview;
