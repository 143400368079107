import React from 'react';
import { Image, Platform, useWindowDimensions } from 'react-native';
import { Text, View, Pressable } from '../index';
import useCommonStore from '../../../stores/commonStore';
import { dynamicHeightMaker } from '../../../utilities/helper';
// import theme from '../../../themes/new/theme';

const NotableMentionCard = (props) => {
  const {
    posterURL,
    readingTime,
    description,
    webURL,
    publisher,
    title,
    theme,
    onPress,
  } = props ?? {};
  const { width, height } = useWindowDimensions();
  const screenOrientationValue = useCommonStore((state) => state.orientation);
  const imageURL =
    Platform.OS !== 'web'
      ? Image.resolveAssetSource(posterURL)?.uri
      : posterURL;
  return (
    <Pressable
      style={{
        paddingHorizontal: theme.space.lg,
        paddingVertical: theme.space.lg,
        borderRadius: theme.radii.md,
        backgroundColor: theme.colors.background.primary.base,
        ...theme.boxShadows.sm,
      }}
      onPress={onPress}
    >
      <View flexDirection="row" alignItems="center">
        <Image
          source={{ uri: imageURL !== undefined ? imageURL : posterURL }}
          style={{
            width:
              width > 1200 && screenOrientationValue < 3
                ? 375 * 0.274
                : width * 0.274,
            height: dynamicHeightMaker(
              width > 1200 && screenOrientationValue < 3
                ? 375 * 0.274
                : width * 0.274,
              1.04,
            ),
            borderRadius: theme.radii.sm,
          }}
          resizeMode="cover"
        />
        <View flex={1} ml="lg">
          <View ml="lg">
            <Text size="md" color="primary.500" numberOfLines={2}>
              {title}
            </Text>
            {publisher && publisher !== '' ? (
              <Text size="xs" color="primary.300" mt="sm">
                {publisher}
              </Text>
            ) : (
              <></>
            )}
            {description && description !== '' ? (
              <Text size="sm" color="primary.200" mt="sm">
                {description}
              </Text>
            ) : (
              <></>
            )}
          </View>
          {readingTime && readingTime > 0 ? (
            <View mt="2xl" alignItems="flex-end">
              <Text size="xs" color="primary.200">
                {readingTime} min read
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    </Pressable>
  );
};

export default NotableMentionCard;
