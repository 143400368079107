import React, { useState } from 'react';
import { SafeAreaView } from 'react-native';
import { get } from 'lodash';
import styled from 'styled-components/native';
import View from '../../basic/View/View';
import Text from '../Typography/Text/Text';
import {
  InputLabelTextColorResolver,
  InputStates,
  InputHelperTextColorResolver,
} from './helpers';

import InputWithMobileLabel from './InputWithMobileLabel';
import { TextCategories } from '../Typography/Text/helper';

const InputLabelText = styled(Text)`
  color: ${({ variant, theme }) =>
    get(theme.colors, InputLabelTextColorResolver(variant))};
`;

const InputHelpetText = styled(Text)`
  color: ${({ variant, theme }) =>
    get(theme.colors, InputHelperTextColorResolver(variant))};
`;

function MobileInput({
  state = InputStates.default,
  label = '',
  countryCode = '+91',
  helperText = '',
  inputRef,
  onFocus = () => {},
  onBlur = () => {},
  ...props
}) {
  const [localState, setLocalInputState] = useState('');
  return (
    <View flexDirection="column" flex={1}>
      {!!label && (
        <View mb="lg">
          <InputLabelText
            category={TextCategories.INPUTLABEL}
            variant={state}
            color="primary.200"
            numberOfLines={1}
            size="sm"
            ellipsizemode="tail"
          >
            {label}
          </InputLabelText>
        </View>
      )}

      <View>
        <InputWithMobileLabel
          state={localState || state}
          onFocus={() => {
            if (state === InputStates.default)
              setLocalInputState(InputStates.active);
            onFocus();
          }}
          onBlur={() => {
            setLocalInputState('');
            onBlur();
          }}
          countryCode={countryCode}
          inputRef={inputRef}
          {...props}
        />
      </View>
      {!!helperText && (
        <View mt="sm">
          <InputHelpetText
            variant={state}
            category={TextCategories.HELPERTEXT}
            numberOfLines={2}
            ellipsizemode="tail"
          >
            {helperText}
          </InputHelpetText>
        </View>
      )}
    </View>
  );
}

export default MobileInput;
