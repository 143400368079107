import { useLazyLoadQuery } from 'react-relay';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { accountScreenQuery } from '../API/accountScreenQuery';
import OTAConfig from '../../../../../react-native.config';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import useUserStore, {
  UserPermissions,
  UserRole,
} from '../../../../stores/userStore';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../../utilities/firbaseAnalytics';
import useCommonStore from '../../../../stores/commonStore';
import { openWhatsApp } from '../../../../utilities/helper';

const useAccountScreenData = ({ refreshedQueryOptions, variables }) => {
  const apiData = useLazyLoadQuery(
    accountScreenQuery,
    variables,
    refreshedQueryOptions,
  );

  const userRole = useUserStore((state) => state.role);
  const { shouldUpdate } = useCommonStore((state) => state);
  const blackMembership = useUserStore((state) => state?.blackMembership);

  const { version } = Constants.expoConfig;
  const modifiedAppVersion =
    !isValueNullOrEmpty(OTAConfig.ota) && Platform.OS !== 'web'
      ? `${version}-${OTAConfig.ota}`
      : version;

  const { role, permission } = useUserStore((state) => state);

  const options = [
    {
      label: 'Edit Profile',
      navigation: { screenName: 'editProfile', params: { role } },
      onPress: () => {},
    },
    {
      label:
        userRole === UserRole.VALET_SUPERVISOR
          ? 'Manage Team'
          : 'Team Contacts',
      navigation: { screenName: 'team' },
      onPress: () => {},
    },
    {
      label: 'Settings',
      navigation: { screenName: 'usersettings' },
      onPress: () => {},
    },
  ];

  const staffOption =
    permission === UserPermissions.STAFF
      ? {
          label: 'Scan & Redeem Ticket',
          navigation: {
            screenName: 'StaffBarcodeScannerScreen',
            params: {
              hideManual: true,
            },
          },
          onPress: () => {
            firebaseEventLogger('account__Scan&Redeem_Tap', {
              buttonName: 'Scan&Redeem',
              screenName: 'account',
              userType: 'user',
              interactionType: 'tap',
              isLoggedIn: true,
            });
          },
        }
      : {};

  const blackMemberOptions = [
    {
      label: 'Edit Profile',
      navigation: { screenName: 'editProfile', params: { role } },
      onPress: () => {
        firebaseEventLogger('account__editProfile_Tap', {
          buttonName: 'editProfile',
          screenName: 'account',
          userType: 'user',
          interactionType: InteractionType.TAP,
        });
      },
    },
    {
      label: '32nd Orders',
      navigation: { screenName: 'pastOrders' },
      onPress: () => {
        firebaseEventLogger('account__32ndOrders_Tap', {
          buttonName: '32ndOrders',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: '32nd Transactions',
      navigation: { screenName: 'transactionsScreen' },
      onPress: () => {
        firebaseEventLogger('ACCOUNT_TRANSACTION_BUTTON', {
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'Event Bookings',
      navigation: { screenName: 'myBookings' },
      onPress: () => {
        firebaseEventLogger('account__eventBookings_Tap', {
          buttonName: 'eventBookings',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'Feedback',
      navigation: { screenName: 'feedback' },
      onPress: () => {
        firebaseEventLogger('account__feedback_Tap', {
          buttonName: 'feedback',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'Help Center',
      navigation: { screenName: '' },
      onPress: () => {
        openWhatsApp(`I need help!`);
        firebaseEventLogger('account__helpCenter_Tap', {
          buttonName: 'helpCenter',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'About',
      navigation: { screenName: 'About' },
      onPress: () => {
        firebaseEventLogger('account__about_Tap', {
          buttonName: 'about',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'Settings',
      navigation: { screenName: 'usersettings' },
      onPress: () => {
        firebaseEventLogger('account__settings_Tap', {
          buttonName: 'settings',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
  ];

  const userOptions = [
    {
      label: 'Edit Profile',
      navigation: { screenName: 'editProfile', params: { role } },
      onPress: () => {
        firebaseEventLogger('account__editProfile_Tap', {
          buttonName: 'editProfile',
          screenName: 'account',
          userType: 'user',
          interactionType: InteractionType.TAP,
        });
      },
    },
    // {
    //   label: '32nd Points',
    //   navigation: {
    //     screenName: 'pointsScreen',
    //     params: { showBackIcon: true },
    //   },
    //   onPress: () => {
    //     firebaseEventLogger('account__32ndPoints_Tap', {
    //       buttonName: '32ndPoints',
    //       screenName: 'account',
    //       userType: 'user',
    //       interactionType: 'tap',
    //       isLoggedIn: true,
    //     });
    //   },
    // },
    {
      label: '32nd Orders',
      navigation: { screenName: 'pastOrders' },
      onPress: () => {
        firebaseEventLogger('account__32ndOrders_Tap', {
          buttonName: '32ndOrders',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: '32nd Transactions',
      navigation: { screenName: 'transactionsScreen' },
      onPress: () => {
        firebaseEventLogger('ACCOUNT_TRANSACTION_BUTTON', {
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'Event Bookings',
      navigation: { screenName: 'myBookings' },
      onPress: () => {
        firebaseEventLogger('account__eventBookings_Tap', {
          buttonName: 'eventBookings',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'Feedback',
      navigation: { screenName: 'feedback' },
      onPress: () => {
        firebaseEventLogger('account__feedback_Tap', {
          buttonName: 'feedback',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'About',
      navigation: { screenName: 'About' },
      onPress: () => {
        firebaseEventLogger('account__about_Tap', {
          buttonName: 'about',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
    {
      label: 'Settings',
      navigation: { screenName: 'usersettings' },
      onPress: () => {
        firebaseEventLogger('account__settings_Tap', {
          buttonName: 'settings',
          screenName: 'account',
          userType: 'user',
          interactionType: 'tap',
          isLoggedIn: true,
        });
      },
    },
  ];

  return {
    apiData,
    isUser: userRole === UserRole.USER,
    appVersionText: modifiedAppVersion,
    options:
      userRole === UserRole.USER
        ? blackMembership?.active
          ? blackMemberOptions
          : userOptions
        : options,
    shouldUpdate,
  };
};

export default useAccountScreenData;
