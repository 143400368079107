/* eslint-disable no-nested-ternary */
import React from 'react';
import { FlatList } from 'react-native';
import { Text, View } from '../../../components/new';
import { NoContentView } from '../../../components/new/custom/bonus';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import {
  DateFormatsEnum,
  PaymentGatewayType,
  getFormatedDate,
  maskedNumber,
  PaymentStatusEnum,
} from '../../../utilities/helper';
import { getTheme } from '../../../themes/new/theme';
import TaskCard from '../../../components/new/primitive/TaskCard/TaskCard';
import { pagination } from '../../../utilities/Constant';
import PaginationLoader from '../../../components/new/custom/PaginationLoader';

const DayWiseCollectionList = ({
  collectionList,
  hasNext,
  loadNext,
  refetch,
}) => {
  const renderItem = ({ item, index }) => {
    const prefix = item?.user?.contact?.phone?.prefix ?? {};
    const number = item?.user?.contact?.phone?.number ?? {};
    const isPaid = item?.status === PaymentStatusEnum.SUCCESSFUL;
    const heading =
      isPaid && item?.paymentType === PaymentGatewayType.PAYTMQR
        ? `#${item?.invoice[0].paymentDetails?.paytmQr?.transactionId.slice(
            -4,
          )}`
        : item?.user
        ? `${prefix} ${maskedNumber(number)}`
        : `#${item?._id?.slice(0, 6)}`;
    const createdAt = new Date(item?.created_at);

    const subHeading = `${
      item?.paymentType === PaymentGatewayType.PAYTMQR
        ? 'QR'
        : item?.paymentType
    } | ${getFormatedDate(createdAt, DateFormatsEnum.TIME)}`;

    const headerRightComponent = () => (
      <View flexDirection="row" alignItems="center">
        <View
          width="3xs"
          height="3xs"
          bg={isPaid ? 'success.500' : 'error.500'}
          borderRadius="full"
        />
        <Text size="xs" color={isPaid ? 'success.500' : 'error.500'} ml="sm">
          {isPaid ? 'Paid' : 'Unpaid'}
        </Text>
      </View>
    );

    const topRightCornerComponent = () => (
      <View
        borderRadius="sm"
        borderWidth="xs"
        borderColor={isPaid ? 'success.10' : 'error.10'}
        p="sm"
        bg={isPaid ? 'success.10' : 'error.10'}
      >
        <Text size="sm" color="primary.500">
          ₹{item?.amount}
        </Text>
      </View>
    );
    return (
      <View mt={index !== 0 ? '2xl' : 0}>
        <TaskCard
          heading={heading}
          subHeading={subHeading}
          headingRight={headerRightComponent()}
          topRightCorner={topRightCornerComponent()}
          swipeEnabled={false}
          shadow="md"
          blur={item?.blur}
        />
      </View>
    );
  };

  return (
    <FlatList
      data={collectionList}
      renderItem={renderItem}
      keyExtractor={(item) => item?._id}
      style={{
        paddingHorizontal: getTheme().space['2xl'],
        paddingVertical: getTheme().space['4xl'],
      }}
      onEndReached={() => {
        if (hasNext) {
          loadNext(pagination.defaultPageSize);
        }
      }}
      ListFooterComponentStyle={{
        margin: 0,
        padding: 0,
      }}
      ListFooterComponent={() => {
        if (hasNext) return <PaginationLoader />;
        return null;
      }}
      ListEmptyComponent={
        <View>
          <NoContentView
            src={require('../../../../assets/images/no-transaction.webp')}
          />
          <Text
            size="2xl"
            weight="medium"
            textAlign="center"
            color="primary.400"
          >
            No Collections yet
          </Text>
        </View>
      }
    />
  );
};

export default DayWiseCollectionList;
