import React, { useState } from 'react';
import { FlatList } from 'react-native';
import { IconButton, Layout, TopNavigation } from '../../../components/new';
import useCommonStore, { FaqScreenData } from '../../../stores/commonStore';
import { navigateBack } from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import FaqItem from './FaqItem';

const FaqScreen = ({ navigation }) => {
  const faqData: FaqScreenData[] = useCommonStore(
    (state) => state.faqScreenData,
  );
  const userRole = useUserStore((state) => state.role);

  const [openIndex, setOpenIndex] = useState(0);

  const onBackPress = () => {
    navigateBack(navigation, userRole);
  };

  const handleItemPress = (index: number) => {
    // Toggle the selected item and close all others
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <Layout level={2}>
      {/* @ts-ignore */}
      <TopNavigation
        IconLeft={
          // @ts-ignore
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onBackPress}
          />
        }
        appearance="ghost"
        level="1"
        title="FAQs"
      />
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingTop: 24,
          paddingHorizontal: 16,
        }}
        renderItem={({ item, index }) => {
          return (
            <FaqItem
              title={item.title}
              about={item.about}
              index={index}
              isOpen={index === openIndex}
              onPress={handleItemPress}
            />
          );
        }}
        data={faqData}
      />
    </Layout>
  );
};

export default FaqScreen;
