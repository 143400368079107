import React, { memo } from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { Divider, Text, View } from '../../../../components/new';
import { imageTransformation } from '../../../../utilities/helper';
import VideoWithThumbnail from '../../../../components/new/custom/VideoWithThumbnail';

const areEqual = () => {
  return true;
};

const InvestmentPartners = ({ bgColor = '' }) => {
  const { width } = useWindowDimensions();

  return (
    <View py="9xl+9xl" bg={bgColor} alignItems="center">
      <View
        borderRadius="2xl"
        borderWidth={1}
        borderColor="primary.20"
        pt="4xl"
        pb="7xl"
        mx="2xl"
        bg={
          bgColor === 'background.primary.base'
            ? 'background.secondary.base'
            : 'background.primary.base'
        }
      >
        <Text size="xs" color="primary.300" pb="lg" textAlign="center">
          OUR ASSOCIATIONS
        </Text>
        <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
          Trusted by world leaders and new age innovators
        </Text>
        <View mt="9xl+lg" px="2xl" alignItems="center">
          <Image
            source={require('../../../../../assets/images/google.webp')}
            style={{ width: 94, height: 54, resizeMode: 'contain' }}
          />
          <Text color="primary.300" size="sm" textAlign="center">
            32nd Leased 1.1 million sq. ft. of land to Google Inc. for their
            regional headquarter
          </Text>
          <View height={1} width="100%" bg="primary.20" my="4xl" />
          <Image
            source={require('../../../../../assets/images/mg.webp')}
            style={{ width: 94, height: 54, resizeMode: 'contain' }}
          />
          <Text color="primary.300" size="sm" textAlign="center">
            32nd has leased 1.1 million sq. ft. to MG Motors for their country
            headquarters
          </Text>
        </View>
      </View>
    </View>
  );
};

export default memo(InvestmentPartners, areEqual);
