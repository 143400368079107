import moment from 'moment';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { NewErrorBoundaryParentState } from '../../../utilities/NewErrorBoundary';
import { partnerDayWiseCollectionQuery } from '../API/partnerOverview/partnerDayWiseCollectionQuery';
import { PaymentStatusEnum } from '../../../utilities/helper';
import { paymentHistoryFragmentV2 } from '../API/partnerHomeScreenQuery';
import { pagination } from '../../../utilities/Constant';
import useCommonStore from '../../../stores/commonStore';
import { useNodeFromPagination } from '../../../hooks/useNodeFromPagination';
import { PaymentOrderHistorySortEnum } from '../API/__generated__/partnerHomeScreenQuery.graphql';

interface PartnerDayWiseScreenHookTypes {
  bannerData: {
    amount: number;
    cashTotal: number;
    onlineTotal: number;
  };
  dayWiseCollectionList: any[];
  hasNext?: boolean;
  loadNext?: any;
  refetch?: any;
}

interface PaymentCollectionData {
  total: number;
  cashTotal: number;
  onlineTotal: number;
  count: number;
  date: string; // YYYY-MM-DD
}

const buildBannerData = (
  getPopupOrderCollections: PaymentCollectionData[],
  date: string,
) => {
  let amount = 0;
  let cashTotal = 0;
  let onlineTotal = 0;
  if (getPopupOrderCollections.length) {
    const bannerDataCollection = getPopupOrderCollections[0];
    if (moment(bannerDataCollection?.date, 'YYYY-MM-DD').isSame(date, 'day')) {
      amount = bannerDataCollection?.total ?? 0;
      cashTotal = bannerDataCollection?.cashTotal ?? 0;
      onlineTotal = bannerDataCollection?.onlineTotal ?? 0;
    }
  }
  return {
    amount,
    onlineTotal,
    cashTotal,
  };
};

/* eslint-disable-next-line import/prefer-default-export */
export const usePartnerDayWiseCollectionScreen = (
  queryOptions: NewErrorBoundaryParentState,
  date: string,
): PartnerDayWiseScreenHookTypes => {
  const { campusId } = useCommonStore((state) => state);
  const variables = {
    collectionFilter: {
      date,
    },
    ordersFilter: {
      date: {
        start: date,
      },
      status: PaymentStatusEnum.SUCCESSFUL,
      campus: {
        _id: campusId,
      },
    },
    count: pagination.defaultPageSize,
    sort: ['STATUS_DESC', 'ASC'] as PaymentOrderHistorySortEnum[],
  };

  const { getPopupOrderCollections, ...findPopupOrdersRef } = useLazyLoadQuery(
    partnerDayWiseCollectionQuery,
    variables,
    queryOptions,
  );

  const {
    data: getPaymentHistoryPaginated,
    hasNext,
    loadNext,
    refetch: refetchHistory,
  } = usePaginationFragment(paymentHistoryFragmentV2, findPopupOrdersRef);

  const paymentHistory = useNodeFromPagination(
    getPaymentHistoryPaginated?.findPopupOrders,
  );

  const bannerData = buildBannerData(getPopupOrderCollections, date);

  return {
    bannerData,
    dayWiseCollectionList: paymentHistory,
    hasNext,
    loadNext,
    refetch: refetchHistory,
  };
};
