import React from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { Text, View } from '../../../../components/new';

const InvestNextSteps = ({ bgColor = '', data }) => {
  const { width } = useWindowDimensions();
  return (
    <View py="9xl" bg={bgColor} px="2xl">
      {data?.heading && (
        <Text size="xs" color="primary.300" pb="lg" textAlign="center">
          {data?.heading}
        </Text>
      )}
      {data?.subHeading && (
        <Text size="2xl" color="primary.500" textAlign="center" px="2xl">
          {data?.subHeading}
        </Text>
      )}
      <View
        bg="primary.10"
        borderColor="grey.10"
        borderRadius="lg"
        borderWidth={1}
        px="2xl"
        py="3xl"
        mt="2xl"
      >
        {data?.data?.map((item, index) => (
          <View
            alignItems="center"
            flexDirection="row"
            key={`Inv_next_steps${item?.description}`}
            pt={index === 0 ? 0 : '7xl'}
          >
            <Image source={item.img} style={{ height: 40, width: 40 }} />
            <Text color="primary.400" size="sm" pl="2xl" flex={1}>
              {item?.description}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default InvestNextSteps;
